import { getState } from "./stateFunctions";
import {
  getTargetObj,
  getTargetParent,
  getCustomCss,
  getFirstChildWithAttr,
  getFirstChildByTagname,
  getFirstChildByClassname,
  getTargetCustomClassname,
  getTargetCustomCssRules,
  getAllChildrenByTagname,
  getSelectedComponentId,
  getAnimateElements,
  getParentWithAttr,
  getInputfieldLabel,
  getSelectPlaceholderOption,
  getComponentRelativeToCurrent,
} from "./componentObjectFunctions";
import { ensureRgba, isColorString } from "./colorFunctions";

export const getStartStyles = (editForms) => {
  // Each style to be in format { editFormField, value }
  try {
    // Get the target in the sbPages object
    let selectedElement = getState("sb", "selectedElement");
    let splitSelectedElementId = selectedElement.split("-");
    let customCss = getCustomCss(splitSelectedElementId[0]);
    let target = getTargetObj(splitSelectedElementId);
    // console.log(target);
    // console.log(customCss);
    // Loop through all editForms to get starting values
    let startStyles = {};
    startStyles = { ...startStyles, ...elementId(target, customCss) };
    editForms.forEach((editForm) => {
      editForm === "containerWidth" && (startStyles = { ...startStyles, ...containerWidth(target, customCss) });
      editForm === "responsiveLayout" && (startStyles = { ...startStyles, ...responsiveLayout(target, customCss) });
      editForm === "alignCol" && (startStyles = { ...startStyles, ...alignCol(target, customCss) });
      editForm === "alignRow" && (startStyles = { ...startStyles, ...alignRow(target, customCss) });
      editForm === "alignHori" && (startStyles = { ...startStyles, ...alignHori(target, customCss) });
      editForm === "width" && (startStyles = { ...startStyles, ...width(target, customCss) });
      editForm === "bgColor" && (startStyles = { ...startStyles, ...bgColor(target, customCss) });
      editForm === "textColor" && (startStyles = { ...startStyles, ...textColor(target, customCss) });
      editForm === "border" && (startStyles = { ...startStyles, ...border(target, customCss) });
      editForm === "borderRadius" && (startStyles = { ...startStyles, ...borderRadius(target, customCss) });
      editForm === "boxShadow" && (startStyles = { ...startStyles, ...boxShadow(target, customCss) });
      editForm === "textShadow" && (startStyles = { ...startStyles, ...textShadow(target, customCss) });
      editForm === "linkEditor" && (startStyles = { ...startStyles, ...linkEditor(target, customCss) });
      editForm === "padding" && (startStyles = { ...startStyles, ...padding(target, customCss) });
      editForm === "margin" && (startStyles = { ...startStyles, ...margin(target, customCss) });
      editForm === "imgSource" && (startStyles = { ...startStyles, ...imgSource(target, customCss) });
      editForm === "imgAdjust" && (startStyles = { ...startStyles, ...imgAdjust(target, customCss) });
      editForm === "imgResize" && (startStyles = { ...startStyles, ...imgResize(target, customCss) });
      editForm === "buttonEditor" && (startStyles = { ...startStyles, ...buttonEditor(target, customCss) });
      editForm === "transition" && (startStyles = { ...startStyles, ...transition(target, customCss) });
      editForm === "iconEditor" && (startStyles = { ...startStyles, ...iconEditor(target, customCss) });
      editForm === "listMarker2" && (startStyles = { ...startStyles, ...listMarker2(target, customCss) });
      editForm === "googlemaps" && (startStyles = { ...startStyles, ...googlemaps(target, customCss) });
      editForm === "hero1" && (startStyles = { ...startStyles, ...hero1(target, customCss) });
      editForm === "card2" && (startStyles = { ...startStyles, ...card2(target, customCss) });
      editForm === "card3" && (startStyles = { ...startStyles, ...card3(target, customCss) });
      editForm === "card4" && (startStyles = { ...startStyles, ...card4(target, customCss) });
      editForm === "card6" && (startStyles = { ...startStyles, ...card6(target, customCss) });
      editForm === "card16" && (startStyles = { ...startStyles, ...card16(target, customCss) });
      editForm === "card23" && (startStyles = { ...startStyles, ...card23(target, customCss) });
      editForm === "parallax" && (startStyles = { ...startStyles, ...parallax(target, customCss) });
      editForm === "process1" && (startStyles = { ...startStyles, ...process1(target, customCss) });
      editForm === "process2" && (startStyles = { ...startStyles, ...process2(target, customCss) });
      editForm === "callout_testimonial" && (startStyles = { ...startStyles, ...callout_testimonial(target, customCss) });
      editForm === "section_divider" && (startStyles = { ...startStyles, ...section_divider(target, customCss) });
      editForm === "component" && (startStyles = { ...startStyles, ...component(target, customCss) });
      editForm === "animate" && (startStyles = { ...startStyles, ...animate(target, customCss) });
      editForm === "navbar" && (startStyles = { ...startStyles, ...navbar(target, customCss) });
      editForm === "img_gallery1" && (startStyles = { ...startStyles, ...img_gallery1(target, customCss) });
      editForm === "img_gallery2" && (startStyles = { ...startStyles, ...img_gallery2(target, customCss) });
      editForm === "carousel" && (startStyles = { ...startStyles, ...carousel(target, customCss) });
      editForm === "carousel_thumbnail" && (startStyles = { ...startStyles, ...carousel_thumbnail(target, customCss) });
      editForm === "backtotop" && (startStyles = { ...startStyles, ...backtotop(target, customCss) });
      editForm === "inputfield" && (startStyles = { ...startStyles, ...inputfield(target, customCss) });
      editForm === "headingline" && (startStyles = { ...startStyles, ...headingline(target, customCss) });
      editForm === "sectionline" && (startStyles = { ...startStyles, ...sectionline(target, customCss) });
    });
    return startStyles;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const ELEMENTS_TO_GET_ALIGNMENTWRAPPER = ["element_col", "statistics3a"];

const elementId = (target, customCss) => {
  try {
    return {
      elementId: (target.attributes.filter((attr) => attr.property === "id")[0] || { value: "" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const containerWidth = (target, customCss) => {
  try {
    return {
      containerWidth: target.classes.includes("container") ? "container" : "container-fluid",
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const responsiveLayout = (target, customCss) => {
  try {
    // In case the element_col is set a 1 element per row, the actual .col-[x] sizing would be at a child div. For example, latestnews-1
    if (target.attributes.filter((attr) => attr.property === "data-checkalignmentwrapper" && attr.value === "true").length > 0) {
      target = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    }
    return {
      colMobile: parseInt((target.classes.join(" ").match(/col-(\d+)/) || ["", 12])[1]),
      colTablet: parseInt((target.classes.join(" ").match(/col-md-(\d+)/) || ["", 12])[1]),
      colDesktop: parseInt((target.classes.join(" ").match(/col-lg-(\d+)/) || ["", 12])[1]),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const alignCol = (target, customCss) => {
  try {
    // Get child with data-elementalignmentwrapper="true"
    // target = target.children.filter((child) => child.attributes.filter((attr) => attr.property === "data-elementalignmentwrapper").length > 0)[0];
    target = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content
    return {
      alignCol: (customClassRulesNormal.filter((rule) => rule.property === "align-items")[0] || { value: "start" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const alignRow = (target, customCss) => {
  try {
    // target currently is the element_col => move to its block parent
    target = getTargetParent(target.childId);
    // Get class content (align-items-start/end/center/baseline/stretch & justify-content-start/end/center/between/around/evenly)
    return {
      alignRowAlign: (target.classes.join(" ").match(/align-items-(\w+)/) || ["", "start"])[1],
      alignRowJustify: (target.classes.join(" ").match(/justify-content-(\w+)/) || ["", "start"])[1],
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const alignHori = (target, customCss) => {
  try {
    // Horizontal alignment done via align-self css property. Parent needs to be display:grid for it to work
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return {
      alignHori: (customClassRulesNormal.filter((rule) => rule.property === "align-self")[0] || { value: "start" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const width = (target, customCss) => {
  try {
    return {
      widthMobile: parseInt((target.classes.join(" ").match(/w-(\d+)/) || ["", 100])[1]),
      widthTablet: parseInt((target.classes.join(" ").match(/w-md-(\d+)/) || ["", 100])[1]),
      widthDesktop: parseInt((target.classes.join(" ").match(/w-lg-(\d+)/) || ["", 100])[1]),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const bgColor = (target, customCss) => {
  try {
    if (
      target.attributes.filter((attr) => attr.property === "data-name" && ELEMENTS_TO_GET_ALIGNMENTWRAPPER.includes(attr.value)).length > 0 &&
      typeof getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true") !== "undefined"
    ) {
      target = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    }
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal, customClassRulesHover } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return {
      bgColor: (customClassRulesNormal.filter((rule) => rule.property === "background")[0] || { value: "rgba(255, 255, 255, 0)" }).value,
      bgColorHover: (customClassRulesHover.filter((rule) => rule.property === "background")[0] || { value: "rgba(255, 255, 255, 0)" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const border = (target, customCss) => {
  try {
    if (
      target.attributes.filter((attr) => attr.property === "data-name" && ELEMENTS_TO_GET_ALIGNMENTWRAPPER.includes(attr.value)).length > 0 &&
      typeof getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true") !== "undefined"
    ) {
      target = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    }
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal, customClassRulesHover } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return getBorderCssRules(customClassRulesNormal, customClassRulesHover);
    // // Get class content
    // // format: border-style: [topStyle] [rightStyle] [bottomStyle] [leftStyle]
    // let borderStyleClass = (
    //   customClassRulesNormal.filter((rule) => rule.property === "border-style")[0] || { value: "none none none none" }
    // ).value.split(" ");
    // // borderSide = none, all, left, right, top, bottom, topbottom, leftright
    // let borders = {
    //   top: borderStyleClass[0],
    //   right: borderStyleClass[1],
    //   bottom: borderStyleClass[2],
    //   left: borderStyleClass[3],
    // };
    // let borderSide = "none";
    // if (borders.top !== "none" && borders.right !== "none" && borders.bottom !== "none" && borders.left !== "none") {
    //   borderSide = "all";
    // }
    // if (borders.top === "none" && borders.right === "none" && borders.bottom === "none" && borders.left !== "none") {
    //   borderSide = "left";
    // }
    // if (borders.top === "none" && borders.right !== "none" && borders.bottom === "none" && borders.left === "none") {
    //   borderSide = "right";
    // }
    // if (borders.top !== "none" && borders.right === "none" && borders.bottom === "none" && borders.left === "none") {
    //   borderSide = "top";
    // }
    // if (borders.top === "none" && borders.right === "none" && borders.bottom !== "none" && borders.left === "none") {
    //   borderSide = "bottom";
    // }
    // if (borders.top !== "none" && borders.right === "none" && borders.bottom !== "none" && borders.left === "none") {
    //   borderSide = "topbottom";
    // }
    // if (borders.top === "none" && borders.right !== "none" && borders.bottom === "none" && borders.left !== "none") {
    //   borderSide = "leftright";
    // }
    // return {
    //   borderSide: borderSide,
    //   borderType: borderStyleClass.filter((style) => style !== "none")[0] || "solid",
    //   borderWidth: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "border-width")[0] || { value: 0 }).value),
    //   borderColor: (customClassRulesNormal.filter((rule) => rule.property === "border-color")[0] || { value: "rgba(0, 0, 0, 0)" }).value,
    //   borderColorHover: (customClassRulesHover.filter((rule) => rule.property === "border-color")[0] || { value: "rgba(0, 0, 0, 0)" }).value,
    // };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const borderRadius = (target, customCss) => {
  try {
    if (
      target.attributes.filter((attr) => attr.property === "data-name" && ELEMENTS_TO_GET_ALIGNMENTWRAPPER.includes(attr.value)).length > 0 &&
      typeof getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true") !== "undefined"
    ) {
      target = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    }
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content
    return {
      borderRadius: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "border-radius")[0] || { value: 0 }).value),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const boxShadow = (target, customCss) => {
  try {
    if (
      target.attributes.filter((attr) => attr.property === "data-name" && ELEMENTS_TO_GET_ALIGNMENTWRAPPER.includes(attr.value)).length > 0 &&
      typeof getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true") !== "undefined"
    ) {
      target = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    }
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal, customClassRulesHover } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content (format: hori vert blur spread color => 2px 2px 2px 1px rgba(0, 0, 0, 0.2) )
    let ruleNormal = (customClassRulesNormal.filter((rule) => rule.property === "box-shadow")[0] || { value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" })
      .value;
    let ruleHover = (customClassRulesHover.filter((rule) => rule.property === "box-shadow")[0] || { value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" })
      .value;
    let matchNormal = ruleNormal.match(/(\d+)px (\d+)px (\d+)px (\d+)px rgba\((\d+), (\d+), (\d+), (.+)\)/);
    let matchHover = ruleHover.match(/(\d+)px (\d+)px (\d+)px (\d+)px rgba\((\d+), (\d+), (\d+), (.+)\)/);
    return {
      boxShadowHori: parseInt(matchNormal[1]),
      boxShadowHoriHover: parseInt(matchHover[1]),
      boxShadowVert: parseInt(matchNormal[2]),
      boxShadowVertHover: parseInt(matchHover[2]),
      boxShadowBlur: parseInt(matchNormal[3]),
      boxShadowBlurHover: parseInt(matchHover[3]),
      boxShadowSpread: parseInt(matchNormal[4]),
      boxShadowSpreadHover: parseInt(matchHover[4]),
      boxShadowRgba: `rgba(${matchNormal[5]}, ${matchNormal[6]}, ${matchNormal[7]}, ${matchNormal[8]})`,
      boxShadowRgbaHover: `rgba(${matchHover[5]}, ${matchHover[6]}, ${matchHover[7]}, ${matchHover[8]})`,
      boxShadowChangeOnHover: ruleNormal !== ruleHover,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const textShadow = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal, customClassRulesHover } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content (format: hori vert blur color => 2px 2px 2px rgba(0, 0, 0, 0.2) )
    let ruleNormal = (customClassRulesNormal.filter((rule) => rule.property === "text-shadow")[0] || { value: "0px 0px 0px rgba(0, 0, 0, 0)" }).value;
    let ruleHover = (customClassRulesHover.filter((rule) => rule.property === "text-shadow")[0] || { value: "0px 0px 0px rgba(0, 0, 0, 0)" }).value;
    let matchNormal = ruleNormal.match(/(\d+)px (\d+)px (\d+)px rgba\((\d+), (\d+), (\d+), (.+)\)/);
    let matchHover = ruleHover.match(/(\d+)px (\d+)px (\d+)px rgba\((\d+), (\d+), (\d+), (.+)\)/);
    return {
      textShadowHori: parseInt(matchNormal[1]),
      textShadowHoriHover: parseInt(matchHover[1]),
      textShadowVert: parseInt(matchNormal[2]),
      textShadowVertHover: parseInt(matchHover[2]),
      textShadowBlur: parseInt(matchNormal[3]),
      textShadowBlurHover: parseInt(matchHover[3]),
      textShadowRgba: `rgba(${matchNormal[4]}, ${matchNormal[5]}, ${matchNormal[6]}, ${matchNormal[7]})`,
      textShadowRgbaHover: `rgba(${matchHover[4]}, ${matchHover[5]}, ${matchHover[6]}, ${matchHover[7]})`,
      textShadowChangeOnHover: ruleNormal !== ruleHover,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const textColor = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal, customClassRulesHover } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return {
      textColor: (customClassRulesNormal.filter((rule) => rule.property === "color")[0] || { value: "rgba(0, 0, 0, 1)" }).value,
      textColorHover: (customClassRulesHover.filter((rule) => rule.property === "color")[0] || { value: "rgba(0, 0, 0, 1)" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const linkEditor = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal, customClassRulesHover } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return {
      linkDest: (target.attributes.filter((attr) => attr.property === "data-href")[0] || { value: "#!" }).value,
      linkTarget: (target.attributes.filter((attr) => attr.property === "data-target")[0] || { value: "_self" }).value,
      ...textColor(target, customCss),
      linkDeco: (customClassRulesNormal.filter((rule) => rule.property === "text-decoration")[0] || { value: "none" }).value,
      linkDecoHover: (customClassRulesHover.filter((rule) => rule.property === "text-decoration")[0] || { value: "none" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const padding = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content (format: top right bottom left)
    let padding = (customClassRulesNormal.filter((rule) => rule.property === "padding")[0] || { value: "0 0 0 0" }).value.split(" ");
    return {
      paddingTop: removeUnit(padding[0]),
      paddingRight: removeUnit(padding[1]),
      paddingBottom: removeUnit(padding[2]),
      paddingLeft: removeUnit(padding[3]),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const margin = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content (format: top right bottom left)
    let margin = (customClassRulesNormal.filter((rule) => rule.property === "margin")[0] || { value: "0 0 0 0" }).value.split(" ");
    return {
      marginTop: removeUnit(margin[0]),
      marginRight: removeUnit(margin[1]),
      marginBottom: removeUnit(margin[2]),
      marginLeft: removeUnit(margin[3]),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const imgSource = (target, customCss) => {
  try {
    return {
      imgSource: (target.attributes.filter((attr) => attr.property === "src")[0] || { value: "" }).value,
      imgDesc: (target.attributes.filter((attr) => attr.property === "alt")[0] || { value: "" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const imgAdjust = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal, customClassRulesHover } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content
    // Filter: (format: filter: contrast(175%) brightness(3%); ) (in add style function, also add rules for -webkit-filter: ...; -moz-filter: ...;)
    // https://developer.mozilla.org/en-US/docs/Web/CSS/filter
    let brightnessNormal = 100;
    let blurNormal = 0;
    let contrastNormal = 100;
    let grayscaleNormal = 0;
    let sepiaNormal = 0;
    let saturateNormal = 100;
    let hueRotateNormal = 0;
    let invertNormal = 0;
    let brightnessHover = 100;
    let blurHover = 0;
    let contrastHover = 100;
    let grayscaleHover = 0;
    let sepiaHover = 0;
    let saturateHover = 100;
    let hueRotateHover = 0;
    let invertHover = 0;
    let ruleNormal = (customClassRulesNormal.filter((rule) => rule.property === "filter")[0] || { value: "" }).value;
    let ruleHover = (customClassRulesHover.filter((rule) => rule.property === "filter")[0] || { value: "" }).value;
    ruleNormal.split(" ").forEach((filterRule) => {
      let filter = (filterRule.match(/(.+?)\((.+?)\)/) || ["", ""])[1];
      let val = (filterRule.match(/(.+?)\((.+?)\)/) || ["", "", ""])[2];
      if (filter !== "" && val !== "") {
        filter === "brightness" && (brightnessNormal = removeUnit(val));
        filter === "blur" && (blurNormal = removeUnit(val));
        filter === "contrast" && (contrastNormal = removeUnit(val));
        filter === "grayscale" && (grayscaleNormal = removeUnit(val));
        filter === "sepia" && (sepiaNormal = removeUnit(val));
        filter === "saturate" && (saturateNormal = removeUnit(val));
        filter === "hue-rotate" && (hueRotateNormal = removeUnit(val));
        filter === "invert" && (invertNormal = removeUnit(val));
      }
    });
    ruleHover.split(" ").forEach((filterRule) => {
      let filter = (filterRule.match(/(.+?)\((.+?)\)/) || ["", ""])[1];
      let val = (filterRule.match(/(.+?)\((.+?)\)/) || ["", "", ""])[2];
      if (filter !== "" && val !== "") {
        filter === "brightness" && (brightnessHover = removeUnit(val));
        filter === "blur" && (blurHover = removeUnit(val));
        filter === "contrast" && (contrastHover = removeUnit(val));
        filter === "grayscale" && (grayscaleHover = removeUnit(val));
        filter === "sepia" && (sepiaHover = removeUnit(val));
        filter === "saturate" && (saturateHover = removeUnit(val));
        filter === "hue-rotate" && (hueRotateHover = removeUnit(val));
        filter === "invert" && (invertHover = removeUnit(val));
      }
    });
    // Transform
    let imgTransformHover = Math.round(
      parseFloat(
        ((customClassRulesHover.filter((rule) => rule.property === "transform")[0] || { value: "scale(1)" }).value.match(/\((.+?)\)/) || ["", "1"])[1]
      ) * 100
    );
    // Opacity
    let imgOpacity = parseFloat((customClassRulesNormal.filter((rule) => rule.property === "opacity")[0] || { value: "1" }).value);
    let imgOpacityHover = parseFloat((customClassRulesHover.filter((rule) => rule.property === "opacity")[0] || { value: "1" }).value);
    return {
      imgOpacity,
      imgOpacityHover,
      imgTransformHover,
      imgBlur: removeUnit(blurNormal),
      imgBrightness: removeUnit(brightnessNormal),
      imgContrast: removeUnit(contrastNormal),
      imgGrayscale: removeUnit(grayscaleNormal),
      imgHueRotate: removeUnit(hueRotateNormal),
      imgInvert: removeUnit(invertNormal),
      imgSaturate: removeUnit(saturateNormal),
      imgSepia: removeUnit(sepiaNormal),
      imgBlurHover: removeUnit(blurHover),
      imgBrightnessHover: removeUnit(brightnessHover),
      imgContrastHover: removeUnit(contrastHover),
      imgGrayscaleHover: removeUnit(grayscaleHover),
      imgHueRotateHover: removeUnit(hueRotateHover),
      imgInvertHover: removeUnit(invertHover),
      imgSaturateHover: removeUnit(saturateHover),
      imgSepiaHover: removeUnit(sepiaHover),
      imgAdjustChangeOnHover: ruleNormal !== ruleHover || imgOpacity !== imgOpacityHover || imgTransformHover !== 100,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const imgResize = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content (either a size or 0 for "auto")
    let imgResizeWidth = (customClassRulesNormal.filter((rule) => rule.property === "width")[0] || { value: 0 }).value;
    imgResizeWidth === "auto" && (imgResizeWidth = 0);
    let imgResizeHeight = (customClassRulesNormal.filter((rule) => rule.property === "height")[0] || { value: 0 }).value;
    imgResizeHeight === "auto" && (imgResizeHeight = 0);
    return {
      imgResizeWidth: removeUnit(imgResizeWidth),
      imgResizeHeight: removeUnit(imgResizeHeight),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const listMarker2 = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesPseudo } = getTargetCustomCssRules(customCss, targetCustomClassname, "before");
    // Get class content
    let markerBorderRadius = removeUnit((customClassRulesPseudo.filter((rule) => rule.property === "border-radius")[0] || { value: "0" }).value);
    let markerHeight = removeUnit((customClassRulesPseudo.filter((rule) => rule.property === "height")[0] || { value: "0" }).value);
    let markerWidth = removeUnit((customClassRulesPseudo.filter((rule) => rule.property === "width")[0] || { value: "0" }).value);
    let markerBgColor = (customClassRulesPseudo.filter((rule) => rule.property === "background")[0] || { value: "rgba(0, 0, 0, 0)" }).value;
    let markerBorderColor = (customClassRulesPseudo.filter((rule) => rule.property === "border-color")[0] || { value: "rgba(0, 0, 0, 0)" }).value;
    let markerMarginRight = removeUnit((customClassRulesPseudo.filter((rule) => rule.property === "margin-right")[0] || { value: "0" }).value);
    let bgColorAlpha = parseFloat((markerBgColor.match(/rgba\(.+, (.+?)\)/) || ["", "0"])[1]);
    let borderColorAlpha = parseFloat((markerBorderColor.match(/rgba\(.+, (.+?)\)/) || ["", "0"])[1]);
    // Get form
    // Form           height === width  borderRadius      borderColorAlpha  bgColorAlpha
    // none           true                 0              0                 0
    // filledsquare   true                 0              >0                >0
    // opensquare     true                 0              >0                0
    // filledcircle   true                 50             >0                >0
    // opencircle     true                 50             >0                0
    // line           false                0              >0                >0
    let markerForm = "none";
    if (borderColorAlpha > 0) {
      if (markerHeight === markerWidth) {
        if (markerBorderRadius === 0) {
          if (bgColorAlpha === 0) {
            markerForm = "opensquare";
          } else {
            markerForm = "filledsquare";
          }
        } else {
          if (bgColorAlpha === 0) {
            markerForm = "opencircle";
          } else {
            markerForm = "filledcircle";
          }
        }
      } else {
        markerForm = "line";
      }
    }
    return {
      markerBorderColor,
      markerMarginRight,
      markerForm,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const buttonEditor = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get button text
    let btnText = getFirstChildByTagname(target, "textNode");
    return {
      btnIsLink: target.htmlTagName === "a",
      linkDest: (target.attributes.filter((attr) => attr.property === "data-href")[0] || { value: "#!" }).value,
      linkTarget: (target.attributes.filter((attr) => attr.property === "data-target")[0] || { value: "_self" }).value,
      btnIsBold: customClassRulesNormal.filter((rule) => rule.property === "font-weight" && rule.value === "bold").length > 0,
      btnIsItalic: customClassRulesNormal.filter((rule) => rule.property === "font-style" && rule.value === "italic").length > 0,
      btnIsUnderlined: customClassRulesNormal.filter((rule) => rule.property === "text-decoration" && rule.value === "underline").length > 0,
      ...textColor(target, customCss),
      ...bgColor(target, customCss),
      ...border(target, customCss),
      ...borderRadius(target, customCss),
      ...padding(target, customCss),
      btnText: btnText === null || typeof btnText === "undefined" ? null : btnText.content,
      btnFullWidth: customClassRulesNormal.filter((rule) => rule.property === "width" && rule.value === "100%").length > 0,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const transition = (target, customCss) => {
  try {
    if (
      target.attributes.filter((attr) => attr.property === "data-name" && ELEMENTS_TO_GET_ALIGNMENTWRAPPER.includes(attr.value)).length > 0 &&
      typeof getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true") !== "undefined"
    ) {
      target = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    }
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content (format: all 0.5s ease-in-out)
    let transition = (customClassRulesNormal.filter((rule) => rule.property === "transition")[0] || { value: "all 0s ease-in-out" }).value.split(" ");
    return {
      transition: removeUnit(transition[1]),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const iconEditor = (target, customCss) => {
  try {
    // target = data-iconwrapper="true"
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get the actual icon
    let icon = target.children.filter((child) => child.classes.includes("icon"))[0];
    return {
      selectedIcon: icon.classes.filter((classname) => classname !== "icon").join(" "),
      ...alignHori(target, customCss),
      textColor: (customClassRulesNormal.filter((rule) => rule.property === "color")[0] || { value: "rgba(0, 0, 0, 1)" }).value,
      fontSize: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "font-size")[0] || { value: "1rem" }).value),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const overlayColor = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return {
      overlayColor: (customClassRulesNormal.filter((rule) => rule.property === "background")[0] || { value: "rgba(0, 0, 0, 0.3)" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const googlemaps = (target, customCss) => {
  try {
    return {
      googlemaps: (target.attributes.filter((attr) => attr.property === "src")[0] || { value: "" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const hero1 = (target, customCss) => {
  try {
    // target = div.hero-1-content, which has the overlay
    // Also get the image
    let hero1Wrapper = getTargetParent(target.childId);
    let hero1Img = getFirstChildByTagname(hero1Wrapper, "img");
    // Get css to find overlay color
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal: cssRulesOverlay } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get css for the wrapper
    let wrapperCustomClassname = getTargetCustomClassname(hero1Wrapper.classes, customCss);
    let { customClassRulesNormal: cssRulesWrapper } = getTargetCustomCssRules(customCss, wrapperCustomClassname);
    return {
      ...imgSource(hero1Img, customCss),
      hero1_overlay: (cssRulesOverlay.filter((rule) => rule.property === "background")[0] || { value: "rgba(0, 0, 0, 0.3)" }).value,
      heroHeightVh: removeUnit((cssRulesWrapper.filter((rule) => rule.property === "height")[0] || { value: "100vh" }).value),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const card2 = (target, customCss) => {
  try {
    // target = .col-[x].card-2-col
    // Get related elements
    let overlayWrapper = getFirstChildWithAttr(target, "data-overlaywrapper", "true");
    let overlayContent = getFirstChildWithAttr(target, "data-overlaycontent", "true");
    let img = getFirstChildByTagname(overlayWrapper, "img");
    return {
      ...margin(target, customCss),
      ...padding(target, customCss),
      ...alignRow(target, customCss),
      ...responsiveLayout(target, customCss),
      ...border(overlayWrapper, customCss),
      ...borderRadius(overlayWrapper, customCss),
      ...imgResize(overlayWrapper, customCss),
      ...boxShadow(overlayWrapper, customCss),
      ...alignCol(overlayContent, customCss),
      ...transition(overlayContent, customCss),
      ...overlayColor(overlayContent, customCss),
      ...imgSource(img, customCss),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const card3 = (target, customCss) => {
  try {
    // target = .col-[x].card-3-col
    // Get related elements
    let overlayWrapper = getFirstChildWithAttr(target, "data-overlaywrapper", "true");
    let overlayContent = getFirstChildWithAttr(target, "data-overlaycontent", "true");
    let img = getFirstChildByTagname(overlayWrapper, "img");
    // Get target's custom css rules
    let componentId = getSelectedComponentId();
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, "card-3-desc");
    let card3_desc_bgColor = (customClassRulesNormal.filter((rule) => rule.property === "background")[0] || { value: "rgba(255, 255, 255, 1)" })
      .value;
    let { customClassRulesPseudo } = getTargetCustomCssRules(customCss, `card-3-overlay-wrapper-${componentId}`, `hover .card-3-desc-${componentId}`);
    let card3_desc_bgColorHover = (customClassRulesPseudo.filter((rule) => rule.property === "background")[0] || { value: "rgba(0, 0, 0, 1)" }).value;
    return {
      ...margin(target, customCss),
      ...padding(target, customCss),
      ...alignRow(target, customCss),
      ...responsiveLayout(target, customCss),
      ...border(overlayWrapper, customCss),
      ...borderRadius(overlayWrapper, customCss),
      ...boxShadow(overlayWrapper, customCss),
      ...transition(overlayWrapper, customCss),
      ...alignCol(overlayContent, customCss),
      ...overlayColor(overlayContent, customCss),
      card3_desc_bgColor,
      card3_desc_bgColorHover,
      ...imgSource(img, customCss),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const card4 = (target, customCss) => {
  try {
    // target = .col-[x].card-4-col
    // Also get the overlaywrapper and overlaycontent divs
    let overlayWrapper = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    let overlayContent = getFirstChildWithAttr(target, "data-overlaycontent", "true");
    // Get target's custom css rules
    let componentId = getSelectedComponentId();
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, "card-4-content");
    let card4_popupHeight = removeUnit((customClassRulesNormal.filter((rule) => rule.property === "height")[0] || { value: "80px" }).value);
    let { customClassRulesPseudo } = getTargetCustomCssRules(
      customCss,
      `card-4-element-wrapper-${componentId}`,
      `hover .card-4-content-${componentId}`
    );
    let card4_popupHeightHover = removeUnit((customClassRulesPseudo.filter((rule) => rule.property === "height")[0] || { value: "120px" }).value);
    return {
      ...margin(target, customCss),
      ...padding(target, customCss),
      ...alignRow(target, customCss),
      ...responsiveLayout(target, customCss),
      ...border(overlayWrapper, customCss),
      ...borderRadius(overlayWrapper, customCss),
      ...boxShadow(overlayWrapper, customCss),
      ...alignCol(overlayContent, customCss),
      ...transition(overlayContent, customCss),
      ...overlayColor(overlayContent, customCss),
      card4_popupHeight,
      card4_popupHeightHover,
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const card6 = (target, customCss) => {
  try {
    // target = .col-[x].card-6-col
    // Get related divs
    let cardWrapper = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    let cardBorder = getFirstChildWithAttr(target, "data-elementgetter2", "true");
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(cardBorder.classes, customCss);
    // let { customClassRulesNormal } = getTargetCustomCssRules(customCss, "card-6-border");
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    let height = (customClassRulesNormal.filter((rule) => rule.property === "height")[0] || { value: "100%" }).value;
    let width = (customClassRulesNormal.filter((rule) => rule.property === "width")[0] || { value: "100%" }).value;
    let top = (customClassRulesNormal.filter((rule) => rule.property === "top")[0] || { value: undefined }).value;
    let bottom = (customClassRulesNormal.filter((rule) => rule.property === "bottom")[0] || { value: undefined }).value;
    let left = (customClassRulesNormal.filter((rule) => rule.property === "left")[0] || { value: undefined }).value;
    let right = (customClassRulesNormal.filter((rule) => rule.property === "right")[0] || { value: undefined }).value;
    let card6_size = 10;
    let card6_pos = "top";
    // Options:
    // - Left:    top === 0     left === 0    height === 100%
    if (top === "0" && left === "0" && height === "100%") {
      card6_size = removeUnit(width);
      card6_pos = "left";
    }
    // - Right:   top === 0     right === 0   height === 100%
    if (top === "0" && right === "0" && height === "100%") {
      card6_size = removeUnit(width);
      card6_pos = "right";
    }
    // - Top:     top === 0     left === 0    width === 100%
    if (top === "0" && left === "0" && width === "100%") {
      card6_size = removeUnit(height);
      card6_pos = "top";
    }
    // - Bottom:  bottom === 0  left === 0    width === 100%
    if (bottom === "0" && left === "0" && width === "100%") {
      card6_size = removeUnit(height);
      card6_pos = "bottom";
    }
    return {
      ...margin(target, customCss),
      ...padding(target, customCss),
      ...alignRow(target, customCss),
      ...responsiveLayout(target, customCss),
      ...border(cardWrapper, customCss),
      ...borderRadius(cardWrapper, customCss),
      ...boxShadow(cardWrapper, customCss),
      ...transition(cardWrapper, customCss),
      ...bgColor(cardBorder, customCss),
      card6_size,
      card6_pos,
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const card16 = (target, customCss) => {
  try {
    // target = .col-[x] => get child
    let cardElement = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    return {
      ...padding(cardElement, customCss),
      ...border(cardElement, customCss),
      ...borderRadius(cardElement, customCss),
      ...boxShadow(cardElement, customCss),
      ...transition(cardElement, customCss),
      ...bgColor(cardElement, customCss),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const card23 = (target, customCss) => {
  try {
    // target = .col-[x].card-2-col
    // Get related elements
    let overlayWrapper = getFirstChildWithAttr(target, "data-overlaywrapper", "true");
    let overlayContent = getFirstChildWithAttr(target, "data-overlaycontent", "true");
    let img = getFirstChildByTagname(overlayWrapper, "img");
    // Get css for the gradient overlay
    let targetCustomClassname = getTargetCustomClassname(overlayContent.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return {
      ...margin(target, customCss),
      ...padding(target, customCss),
      ...alignRow(target, customCss),
      ...responsiveLayout(target, customCss),
      ...border(overlayWrapper, customCss),
      ...borderRadius(overlayWrapper, customCss),
      ...boxShadow(overlayWrapper, customCss),
      ...alignCol(overlayContent, customCss),
      ...transition(overlayContent, customCss),
      card23_overlayGradient: ((
        customClassRulesNormal.filter((rule) => rule.property === "background")[0] || {
          value: "linear-gradient(rgba(28, 139, 183, 0.2), rgba(28, 139, 183, 0.6))",
        }
      ).value.match(/^linear-gradient.+\),\s(.+?)\)$/) || ["", "rgba(28, 139, 183, 0.6)"])[1],
      ...imgSource(img, customCss),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const parallax = (target, customCss) => {
  try {
    // target = .parallax-[x]-wrapper
    // Get related divs
    let parallaxContent = getFirstChildWithAttr(target, "data-parallaxcontent", "true");
    // Get css for the wrapper
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal: cssRulesWrapper } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get css for the content div
    targetCustomClassname = getTargetCustomClassname(parallaxContent.classes, customCss);
    let { customClassRulesNormal: cssRulesContent } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return {
      ...overlayColor(parallaxContent, customCss),
      parallaxHeightVh: removeUnit((cssRulesWrapper.filter((rule) => rule.property === "height")[0] || { value: "50vh" }).value),
      parallaxImgSrc: ((cssRulesWrapper.filter((rule) => rule.property === "background-image")[0] || { value: "" }).value.match(/url\("(.+?)"\)/) || [
        "",
        "",
      ])[1],
      parallaxVertAlign: (cssRulesContent.filter((rule) => rule.property === "justify-content")[0] || { value: "center" }).value,
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const process1 = (target, customCss) => {
  try {
    // target = .process-1-col
    // Get related elements
    // let alignmentWrapper = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    let svg = getFirstChildByTagname(target, "svg");
    return {
      ...alignCol(target, customCss),
      // ...alignRow(target, customCss),
      ...responsiveLayout(target, customCss),
      process1_arrow_color: (svg.attributes.filter((attr) => attr.property === "stroke")[0] || { value: "rgba(108, 117, 125, 1)" }).value,
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const process2 = (target, customCss) => {
  try {
    // target = .process-2-col
    // Get related elements
    // let process2Circle = getFirstChildWithAttr(target, "data-elementgetter2", "true");
    // Get css
    let componentId = getSelectedComponentId();
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, `process-2-step-circle-${componentId}`);
    return {
      ...alignCol(target, customCss),
      // ...alignRow(target, customCss),
      ...responsiveLayout(target, customCss),
      process2_lineColor: (customClassRulesNormal.filter((rule) => rule.property === "background")[0] || { value: "rgba(0, 0, 0, 1)" }).value,
      process2_iconColor: (customClassRulesNormal.filter((rule) => rule.property === "color")[0] || { value: "rgba(255, 255, 255, 1)" }).value,
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const callout_testimonial = (target, customCss) => {
  try {
    // target = .col-[x].quote-5-col|quote-6-col
    // Get related divs
    // let alignmentWrapper = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    let calloutDiv = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    let targetCustomClassname = getTargetCustomClassname(calloutDiv.classes, customCss);
    let { customClassRulesNormal, customClassRulesPseudo } = getTargetCustomCssRules(customCss, targetCustomClassname, "after");
    let callout_testimonial_shadowColor = `rgba(${
      ((customClassRulesNormal.filter((rule) => rule.property === "box-shadow")[0] || { value: "0px 0px 0px 0px rgba(0, 0, 0, 0.5)" }).value.match(
        /.+?rgba\((.+?)\)/
      ) || ["", "0, 0, 0, 0.5"])[1]
    })`;
    let callout_testimonial_posLeft = removeUnit((customClassRulesPseudo.filter((rule) => rule.property === "left")[0] || { value: "50%" }).value);
    return {
      ...margin(target, customCss),
      ...padding(target, customCss),
      ...alignRow(target, customCss),
      ...responsiveLayout(target, customCss),
      ...borderRadius(calloutDiv, customCss),
      callout_testimonial_bgColor: (customClassRulesNormal.filter((rule) => rule.property === "background")[0] || { value: "rgba(250, 250, 250, 0)" })
        .value,
      callout_testimonial_shadowColor,
      callout_testimonial_posLeft,
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const section_divider = (target, customCss) => {
  try {
    // target = sbtype=component
    return {
      ...getSectionDividerSpaceTopBottom(target),
      ...getSectionDividerDirection(target),
      sdColors: getSectionDividerColors(target),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const component = (target, customCss) => {
  try {
    // target = sbtype=component
    let targetCustomClassname = `section.${target.classes.filter((className) => className.match(/-component-/) !== null)[0] || ""}`;
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    let { backgroundColor, boolBgIsGradient, bgGradientDirection, bgGradientColor1, bgGradientColor2, bgGradientStop1, bgGradientStop2 } =
      getBgGradientVars(customClassRulesNormal);
    // console.log(
    //   "getBgGradientVars",
    //   backgroundColor,
    //   boolBgIsGradient,
    //   bgGradientDirection,
    //   bgGradientColor1,
    //   bgGradientColor2,
    //   bgGradientStop1,
    //   bgGradientStop2
    // );
    return {
      ...containerWidth(target, customCss),
      componentBgColor: backgroundColor,
      componentBoolBgIsGradient: boolBgIsGradient,
      componentBgGradientDirection: bgGradientDirection,
      componentBgGradientColor1: bgGradientColor1,
      componentBgGradientColor2: bgGradientColor2,
      componentBgGradientStop1: bgGradientStop1,
      componentBgGradientStop2: bgGradientStop2,
      componentPaddingTop: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "padding-top")[0] || { value: "0" }).value),
      componentPaddingBottom: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "padding-bottom")[0] || { value: "0" }).value),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const navbar = (target, customCss) => {
  try {
    // Editable:
    // - target:        size (padding-top/bottom), background, box-shadow, transition, position ("", "fixed-top", "sticky-top"), scrolledpast (true/false)
    // - scrolledPast:  size (padding-top/bottom), background, imageSize
    // - logoImg:       max-height, transition
    // - toggler:       font-size, background, border-radius, padding, color, border-style, border-width, border-color
    // - toggler:hover: background, color, border-color
    // - links:         color, text-decoration, transition
    // - links:hover:   color
    // - links:         [ { dest: "", text: "" }, ... ]
    // - animate:       animate the navbar div

    // target = .navbar
    // Get related nodes
    let navbarLogoImg = getFirstChildByTagname(target, "img");
    let navbarToggler = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    let navbarLinkWrapper = getFirstChildWithAttr(target, "data-elementgetter2", "true");
    let navbarLinkTag = getFirstChildByTagname(navbarLinkWrapper, "a");
    // Get all relevant css rules
    let classnameTarget = getTargetCustomClassname(target.classes, customCss);
    let classnameLogoImg = getTargetCustomClassname(navbarLogoImg.classes, customCss);
    let classnameToggler = getTargetCustomClassname(navbarToggler.classes, customCss);
    let classnameLink = getTargetCustomClassname(navbarLinkTag.classes, customCss);
    let { customClassRulesNormal: cssRulesTarget } = getTargetCustomCssRules(customCss, classnameTarget);
    let { customClassRulesNormal: cssRulesScrolledpast } = getTargetCustomCssRules(
      customCss,
      `scrolledpast-${target.componentId}.${classnameTarget}`
    );
    let { customClassRulesNormal: cssRulesLogoImg } = getTargetCustomCssRules(customCss, classnameLogoImg);
    let { customClassRulesNormal: cssRulesScrolledpastLogoImg } = getTargetCustomCssRules(
      customCss,
      `scrolledpast-${target.componentId} .${classnameLogoImg}`
    );
    let { customClassRulesNormal: cssRulesToggler, customClassRulesHover: cssRulesTogglerHover } = getTargetCustomCssRules(
      customCss,
      classnameToggler
    );
    let { customClassRulesNormal: cssRulesLink, customClassRulesHover: cssRulesLinkHover } = getTargetCustomCssRules(customCss, classnameLink);
    // Get styles for navbar
    let navbarExpand = (target.classes.join(" ").match(/expand-(\w{2})/) || ["", ""])[1];
    let navbarSize = removeUnit((cssRulesTarget.filter((rule) => rule.property === "padding-top")[0] || { value: "1rem" }).value);
    let navbarBgColor = (cssRulesTarget.filter((rule) => rule.property === "background")[0] || { value: "rgba(255, 255, 255, 0)" }).value;
    let navbarTransition = removeUnit(
      (cssRulesTarget.filter((rule) => rule.property === "transition")[0] || { value: "all 0s ease-in-out" }).value.split(" ")[1]
    );
    let navbarPosition = (target.attributes.filter((attr) => attr.property === "data-navbarposition")[0] || { value: "" }).value;
    let matchNormal = (
      cssRulesTarget.filter((rule) => rule.property === "box-shadow")[0] || { value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" }
    ).value.match(/(\d+)px (\d+)px (\d+)px (\d+)px rgba\((\d+), (\d+), (\d+), (.+)\)/);
    let navbarBoxShadowHori = parseInt(matchNormal[1]);
    let navbarBoxShadowVert = parseInt(matchNormal[2]);
    let navbarBoxShadowBlur = parseInt(matchNormal[3]);
    let navbarBoxShadowSpread = parseInt(matchNormal[4]);
    let navbarBoxShadowRgba = `rgba(${matchNormal[5]}, ${matchNormal[6]}, ${matchNormal[7]}, ${matchNormal[8]})`;
    // Get styles for scrolledpast
    let navbarScrolledpastBool =
      (target.attributes.filter((attr) => attr.property === "data-changeonscrollpast")[0] || { value: "" }).value.toString() === "true";
    let navbarScrolledpastSize = removeUnit((cssRulesScrolledpast.filter((rule) => rule.property === "padding-top")[0] || { value: "1rem" }).value);
    let navbarScrolledpastBgColor = (cssRulesScrolledpast.filter((rule) => rule.property === "background")[0] || { value: "rgba(230, 230, 230, 1)" })
      .value;
    // Get styles for logo
    let navbarLogoMaxHeight = removeUnit((cssRulesLogoImg.filter((rule) => rule.property === "max-height")[0] || { value: "50px" }).value);
    let navbarScrolledpastLogoMaxHeight = removeUnit(
      (cssRulesScrolledpastLogoImg.filter((rule) => rule.property === "max-height")[0] || { value: "50px" }).value
    );
    // Get styles for toggler
    let navbarTogglerFontSize = removeUnit((cssRulesToggler.filter((rule) => rule.property === "font-size")[0] || { value: "1rem" }).value);
    let navbarTogglerBgColor = (cssRulesToggler.filter((rule) => rule.property === "background")[0] || { value: "rgba(230, 230, 230, 1)" }).value;
    // let navbarTogglerPadding = (cssRulesToggler.filter((rule) => rule.property === "padding")[0] || { value: "0 0 0 0" }).value.split(" ");
    // let navbarTogglerPaddingTop = removeUnit(navbarTogglerPadding[0]);
    // let navbarTogglerPaddingRight = removeUnit(navbarTogglerPadding[1]);
    // let navbarTogglerPaddingBottom = removeUnit(navbarTogglerPadding[2]);
    // let navbarTogglerPaddingLeft = removeUnit(navbarTogglerPadding[3]);
    let navbarTogglerBorderRadius = removeUnit((cssRulesToggler.filter((rule) => rule.property === "border-radius")[0] || { value: 0 }).value);
    let navbarTogglerColor = (cssRulesToggler.filter((rule) => rule.property === "color")[0] || { value: "rgba(255, 255, 255, 1)" }).value;
    let navbarTogglerBorder = border(navbarToggler, customCss);
    let navbarTogglerBgColorHover = (cssRulesTogglerHover.filter((rule) => rule.property === "background")[0] || { value: "rgba(230, 230, 230, 1)" })
      .value;
    let navbarTogglerColorHover = (cssRulesTogglerHover.filter((rule) => rule.property === "color")[0] || { value: "rgba(255, 255, 255, 1)" }).value;
    // Get styles for links
    let navbarLinkColor = (cssRulesLink.filter((rule) => rule.property === "color")[0] || { value: "rgba(0, 0, 0, 1)" }).value;
    let navbarLinkColorHover = (cssRulesLinkHover.filter((rule) => rule.property === "color")[0] || { value: "rgba(0, 0, 0, 1)" }).value;
    let navbarLinkFontName = (cssRulesLink.filter((rule) => rule.property === "font-family")[0] || { value: "var(--font-body)" }).value;
    let navbarLinkFontSize = removeUnit((cssRulesLink.filter((rule) => rule.property === "font-size")[0] || { value: "1rem" }).value);
    let navbarLinkDeco = (cssRulesLink.filter((rule) => rule.property === "text-decoration")[0] || { value: "none" }).value;
    let navbarLinkBold = (cssRulesLink.filter((rule) => rule.property === "font-weight")[0] || { value: "inherit" }).value;
    let navbarLinkData = getNavbarLinkData(navbarLinkWrapper);
    return {
      // target
      navbarPosition,
      navbarExpand,
      navbarSize,
      navbarBgColor,
      navbarTransition,
      navbarBoxShadowHori,
      navbarBoxShadowVert,
      navbarBoxShadowBlur,
      navbarBoxShadowSpread,
      navbarBoxShadowRgba,
      // scrolledpast
      navbarScrolledpastBool,
      navbarScrolledpastSize,
      navbarScrolledpastBgColor,
      // logo
      ...imgSource(navbarLogoImg, customCss),
      navbarLogoMaxHeight,
      navbarScrolledpastLogoMaxHeight,
      // toggler
      navbarTogglerFontSize,
      navbarTogglerBgColor,
      navbarTogglerBorderRadius,
      navbarTogglerColor,
      ...navbarTogglerBorder,
      navbarTogglerBgColorHover,
      navbarTogglerColorHover,
      // links
      navbarLinkColor,
      navbarLinkColorHover,
      navbarLinkFontName,
      navbarLinkFontSize,
      navbarLinkDeco,
      navbarLinkBold,
      navbarLinkData,
      // animate
      ...animate(target, customCss),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const img_gallery1 = (target, customCss) => {
  try {
    // target = .col-[x].imggallery-1-col
    // Get related elements
    let overlayWrapper = getFirstChildWithAttr(target, "data-overlaywrapper", "true");
    let overlayContent = getFirstChildWithAttr(target, "data-overlaycontent", "true");
    let img = getFirstChildByTagname(target, "img");
    return {
      ...imgSource(img, customCss),
      ...responsiveLayout(target, customCss),
      ...margin(overlayWrapper, customCss),
      ...border(overlayWrapper, customCss),
      ...borderRadius(overlayWrapper, customCss),
      ...boxShadow(overlayWrapper, customCss),
      ...transition(overlayContent, customCss),
      ...overlayColor(overlayContent, customCss),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const img_gallery2 = (target, customCss) => {
  try {
    // target = .col-[x].imggallery-2-wrapper
    // Get related elements
    let img = getFirstChildByTagname(target, "img");
    return {
      ...imgSource(img, customCss),
      ...border(target, customCss),
      ...borderRadius(target, customCss),
      ...boxShadow(target, customCss),
      ...transition(target, customCss),
      ...imgAdjust(img, customCss),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const carousel = (target, customCss) => {
  try {
    // target = component
    // Get carousel and the options obj
    let carousel = getFirstChildByClassname(target, "splide");
    let carouselOptions = JSON.parse(carousel.attributes.filter((attr) => attr.property === "data-splide")[0].value);
    // Get options that are not explicitly set in the carouselOptions:
    // - Format: slides (.carousel-slides) || slides + thumbnail (.carousel-thumbnail-thumbnail && .carousel-thumbnail-main) || slides autoscroll (.carousel-autoscroll)
    let carouselFormat = carousel.classes.filter((className) =>
      ["carousel-slides", "carousel-thumbnail-main", "carousel-autoscroll"].includes(className)
    )[0];
    // - Autoplay (manual, autoplay, autoscroll)
    let carouselAutoplay = carouselFormat === "carousel-autoscroll" ? "autoscroll" : carouselOptions.autoplay ? "autoplay" : "manual";
    // - Slide setup (image || image&text || text)
    let carouselSlideSetup = carousel.attributes.filter((attr) => attr.property === "data-carousel-slidesetup")[0].value;
    // - Carousel size: full page (width = "100vw" && height = "100vh") or not
    let carouselSize = carouselOptions.width === "100vw" && carouselOptions.height === "100vh" ? "fullPage" : "auto";

    // Get arrow styles
    let { customClassRulesNormal: cssRulesNormalArrows, customClassRulesHover: cssRulesHoverArrows } = getTargetCustomCssRules(
      customCss,
      carouselOptions.classes.arrow.split(" ")[1]
    );
    let { customClassRulesNormal: cssRulesNormalSvg, customClassRulesHover: cssRulesHoverSvg } = getTargetCustomCssRules(
      customCss,
      `${carouselOptions.classes.arrow.split(" ")[1]} svg`
    );
    let { customClassRulesNormal: cssRulesNormalPrev } = getTargetCustomCssRules(customCss, carouselOptions.classes.prev.split(" ")[1]);
    // Get pagination styles
    let { customClassRulesNormal: cssRulesNormalPag, customClassRulesHover: cssRulesHoverPag } = getTargetCustomCssRules(
      customCss,
      carouselOptions.classes.page.split(" ")[1]
    );
    // fixedHeight
    if (typeof carouselOptions.fixedHeight === "undefined" || carouselOptions.fixedHeight === "auto") {
      carouselOptions = { ...carouselOptions, fixedHeight: 0, fixedWidth: "auto" };
    }
    if (typeof carouselOptions.fixedHeightCss !== "undefined") {
      carouselOptions = { ...carouselOptions, fixedHeight: carouselOptions.fixedHeightCss };
    }
    return {
      ...component(target, customCss),
      carouselOptions: { ...carouselOptions, carouselAutoplay, carouselSlideSetup, carouselSize },
      carouselFormat,
      // carouselNavStyles: {
      //   includeNavButtons: carouselOptions.arrows ? true : false,
      //   bgColor: (cssRulesNormalArrows.filter((rule) => rule.property === "background")[0] || { value: "rgba(255, 255, 255, 0)" }).value,
      //   bgColorHover: (cssRulesHoverArrows.filter((rule) => rule.property === "background")[0] || { value: "rgba(255, 255, 255, 0)" }).value,
      //   ...getBorderCssRules(cssRulesNormalArrows, cssRulesHoverArrows),
      //   borderRadius: removeUnit((cssRulesNormalArrows.filter((rule) => rule.property === "border-radius")[0] || { value: 0 }).value),
      //   transition: removeUnit(
      //     (cssRulesNormalArrows.filter((rule) => rule.property === "transition")[0] || { value: "all 0s ease-in-out" }).value.split(" ")[1]
      //   ),
      //   iconSize: removeUnit((cssRulesNormalSvg.filter((rule) => rule.property === "height")[0] || { value: "1.2rem" }).value),
      //   fillColor: (cssRulesNormalSvg.filter((rule) => rule.property === "fill")[0] || { value: "rgba(0, 0, 0, 1)" }).value,
      //   fillColorHover: (cssRulesHoverSvg.filter((rule) => rule.property === "fill")[0] || { value: "rgba(0, 0, 0, 1)" }).value,
      //   pos: (cssRulesNormalPrev.filter((rule) => rule.property === "left")[0] || { value: "1rem" }).value === "1rem" ? "inside" : "outside",
      // },
      carouselNavStyles: getCarouselNavStyles(
        carouselOptions,
        cssRulesNormalArrows,
        cssRulesHoverArrows,
        cssRulesNormalSvg,
        cssRulesHoverSvg,
        cssRulesNormalPrev
      ),
      carouselPagStyles: {
        includePagButtons: carouselOptions.pagination ? true : false,
        bgColor: (cssRulesNormalPag.filter((rule) => rule.property === "background")[0] || { value: "rgba(255, 255, 255, 0)" }).value,
        bgColorHover: (cssRulesHoverPag.filter((rule) => rule.property === "background")[0] || { value: "rgba(255, 255, 255, 0)" }).value,
        ...getBorderCssRules(cssRulesNormalPag, cssRulesHoverPag),
        borderRadius: removeUnit((cssRulesNormalPag.filter((rule) => rule.property === "border-radius")[0] || { value: 0 }).value),
        transition: removeUnit(
          (cssRulesNormalPag.filter((rule) => rule.property === "transition")[0] || { value: "all 0s ease-in-out" }).value.split(" ")[1]
        ),
        btnSize: removeUnit((cssRulesNormalPag.filter((rule) => rule.property === "width")[0] || { value: "1rem" }).value),
        btnForm:
          (cssRulesNormalPag.filter((rule) => rule.property === "height")[0] || { value: "1rem" }).value ===
          (cssRulesNormalPag.filter((rule) => rule.property === "width")[0] || { value: "1rem" }).value
            ? "square"
            : "bar",
      },
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const carousel_thumbnail = (target, customCss) => {
  try {
    // target = component
    // Get carousel and the options obj
    let carouselMain = getFirstChildByClassname(target, "carousel-thumbnail-main");
    let carouselThumbnail = getFirstChildByClassname(target, "carousel-thumbnail-thumbnail");
    let carouselOptionsMain = JSON.parse(carouselMain.attributes.filter((attr) => attr.property === "data-splide")[0].value);
    let carouselOptionsThumbnail = JSON.parse(carouselThumbnail.attributes.filter((attr) => attr.property === "data-splide")[0].value);
    // Get additional options
    let carouselAutoplay = carouselOptionsThumbnail.autoplay ? "autoplay" : "manual";
    // Get arrow styles from carouselOptionsThumbnail
    let { customClassRulesNormal: cssRulesNormalArrows, customClassRulesHover: cssRulesHoverArrows } = getTargetCustomCssRules(
      customCss,
      carouselOptionsThumbnail.classes.arrow.split(" ")[1]
    );
    let { customClassRulesNormal: cssRulesNormalSvg, customClassRulesHover: cssRulesHoverSvg } = getTargetCustomCssRules(
      customCss,
      `${carouselOptionsThumbnail.classes.arrow.split(" ")[1]} svg`
    );
    let { customClassRulesNormal: cssRulesNormalPrev } = getTargetCustomCssRules(customCss, carouselOptionsThumbnail.classes.prev.split(" ")[1]);
    // No pagination for thumbnail carousels
    return {
      ...component(target, customCss),
      carouselOptionsThumbnail: { ...carouselOptionsThumbnail, carouselAutoplay },
      carouselOptionsMain,
      carouselNavStyles: getCarouselNavStyles(
        carouselOptionsThumbnail,
        cssRulesNormalArrows,
        cssRulesHoverArrows,
        cssRulesNormalSvg,
        cssRulesHoverSvg,
        cssRulesNormalPrev
      ),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const backtotop = (target, customCss) => {
  try {
    // target = type = component
    // Get related elements
    let btnBackToTop = getFirstChildWithAttr(target, "data-name", "backtotop_btn");
    let targetCustomClassname = getTargetCustomClassname(btnBackToTop.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Position: left/right & top/bottom
    let top = (customClassRulesNormal.filter((rule) => rule.property === "top")[0] || { value: undefined }).value;
    let bottom = (customClassRulesNormal.filter((rule) => rule.property === "bottom")[0] || { value: undefined }).value;
    let left = (customClassRulesNormal.filter((rule) => rule.property === "left")[0] || { value: undefined }).value;
    let right = (customClassRulesNormal.filter((rule) => rule.property === "right")[0] || { value: undefined }).value;
    let backToTop_pos = "bottom-right";
    if (typeof bottom !== "undefined" && typeof left !== "undefined") {
      backToTop_pos = "bottom-left";
    }
    if (typeof top !== "undefined" && typeof left !== "undefined") {
      backToTop_pos = "top-left";
    }
    if (typeof top !== "undefined" && typeof right !== "undefined") {
      backToTop_pos = "top-right";
    }
    return {
      ...bgColor(btnBackToTop, customCss),
      ...textColor(btnBackToTop, customCss),
      ...border(btnBackToTop, customCss),
      ...borderRadius(btnBackToTop, customCss),
      ...boxShadow(btnBackToTop, customCss),
      ...transition(btnBackToTop, customCss),
      backToTop_pos,
      backToTop_sizeBtn: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "height")[0] || { value: "3rem" }).value),
      backToTop_sizeIcon: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "font-size")[0] || { value: "1rem" }).value),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const inputfield = (target, customCss) => {
  try {
    // target = .form-control
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal, customClassRulesPseudo: customClassRulesFocus } = getTargetCustomCssRules(
      customCss,
      targetCustomClassname,
      "focus"
    );
    let { customClassRulesPseudo: customClassRulesPlaceholder } = getTargetCustomCssRules(customCss, targetCustomClassname, ":placeholder");
    // border
    let { borderSide, borderType, borderWidth, borderColor } = getBorderCssRules(customClassRulesNormal, []);
    // boxShadow
    let boxShadowMatchNormal = (
      customClassRulesNormal.filter((rule) => rule.property === "box-shadow")[0] || { value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" }
    ).value.match(/(\d+)px (\d+)px (\d+)px (\d+)px rgba\((\d+), (\d+), (\d+), (.+)\)/);
    let boxShadowMatchFocus = (
      customClassRulesFocus.filter((rule) => rule.property === "box-shadow")[0] || { value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" }
    ).value.match(/(\d+)px (\d+)px (\d+)px (\d+)px rgba\((\d+), (\d+), (\d+), (.+)\)/);
    // Input field wrapper
    let inputFieldWrapper = getParentWithAttr("data-inputfieldwrapper", "true");
    // Form wrapping div
    let sbFormDiv = getParentWithAttr("data-sbform", "true");
    return {
      // Form wrapping div
      formDiv_action: getFormAction(sbFormDiv),
      formDiv_successMsg:
        sbFormDiv === null ? null : (sbFormDiv.attributes.filter((attr) => attr.property === "data-sbformmsg")[0] || { value: "" }).value,
      // Input field overall
      inputfield_name: (target.attributes.filter((attr) => attr.property === "name")[0] || { value: "text" }).value,
      inputfield_required:
        (target.attributes.filter((attr) => attr.property === "data-required")[0] || { value: "false" }).value.toString() === "true",
      inputfield_type: getInputfieldType(target),
      inputfield_dropdownOptions: getInputfieldDropdownOptions(target),
      inputfield_fontSize: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "font-size")[0] || { value: "1rem" }).value),
      // Placeholder
      inputfield_placeholderText: getInputfieldPlaceholderText(target),
      inputfield_placeholderColor: (customClassRulesPlaceholder.filter((rule) => rule.property === "color")[0] || { value: "rgba(108, 117, 125, 1)" })
        .value,
      // Label
      inputfield_showLabel: getInputfieldShowLabel(customCss),
      inputfield_labelText: getInputfieldLabelText(),
      // Styles
      bgColor: (customClassRulesNormal.filter((rule) => rule.property === "background")[0] || { value: "rgba(255, 255, 255, 0)" }).value,
      bgColorFocus: (customClassRulesFocus.filter((rule) => rule.property === "background")[0] || { value: "rgba(255, 255, 255, 0)" }).value,
      textColor: (customClassRulesNormal.filter((rule) => rule.property === "color")[0] || { value: "rgba(0, 0, 0, 1)" }).value,
      textColorFocus: (customClassRulesFocus.filter((rule) => rule.property === "color")[0] || { value: "rgba(0, 0, 0, 1)" }).value,
      borderSide,
      borderType,
      borderWidth,
      borderColor,
      borderColorFocus: (customClassRulesFocus.filter((rule) => rule.property === "border-color")[0] || { value: "rgba(0, 0, 0, 0)" }).value,
      boxShadowHori: parseInt(boxShadowMatchNormal[1]),
      boxShadowVert: parseInt(boxShadowMatchNormal[2]),
      boxShadowBlur: parseInt(boxShadowMatchNormal[3]),
      boxShadowSpread: parseInt(boxShadowMatchNormal[4]),
      boxShadowRgba: `rgba(${boxShadowMatchNormal[5]}, ${boxShadowMatchNormal[6]}, ${boxShadowMatchNormal[7]}, ${boxShadowMatchNormal[8]})`,
      boxShadowHoriFocus: parseInt(boxShadowMatchFocus[1]),
      boxShadowVertFocus: parseInt(boxShadowMatchFocus[2]),
      boxShadowBlurFocus: parseInt(boxShadowMatchFocus[3]),
      boxShadowSpreadFocus: parseInt(boxShadowMatchFocus[4]),
      boxShadowRgbaFocus: `rgba(${boxShadowMatchFocus[5]}, ${boxShadowMatchFocus[6]}, ${boxShadowMatchFocus[7]}, ${boxShadowMatchFocus[8]})`,
      ...margin(inputFieldWrapper, customCss),
      ...padding(target, customCss),
      ...borderRadius(target, customCss),
      ...transition(target, customCss),
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const animate = (target, customCss) => {
  try {
    // target = sbtype=component
    let animateElements = getAnimateElements(target);
    return {
      animationType: (animateElements[0].attributes.filter((attr) => attr.property === "data-aos")[0] || { value: "" }).value,
      animationDelay:
        parseFloat((animateElements[0].attributes.filter((attr) => attr.property === "data-aos-delay")[0] || { value: 100 }).value) / 1000,
      animationDuration:
        parseFloat((animateElements[0].attributes.filter((attr) => attr.property === "data-aos-duration")[0] || { value: 500 }).value) / 1000,
      animationRepeat: (animateElements[0].attributes.filter((attr) => attr.property === "data-aos-once")[0] || { value: "true" }).value,
      animationStaggeredDelay:
        animateElements.length > 1
          ? (parseInt((animateElements[1].attributes.filter((attr) => attr.property === "data-aos-delay")[0] || { value: 0 }).value) -
              parseInt((animateElements[0].attributes.filter((attr) => attr.property === "data-aos-delay")[0] || { value: 0 }).value)) /
            1000
          : 0,
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const headingline = (target, customCss) => {
  try {
    // target = headingline's parent
    let divHeadingLine = target.children[0];
    let targetCustomClassname = getTargetCustomClassname(divHeadingLine.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return {
      ...width(target, customCss),
      ...alignHori(divHeadingLine, customCss),
      height: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "height")[0] || { value: "0" }).value),
      width: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "width")[0] || { value: "0" }).value),
      ...bgColor(divHeadingLine, customCss),
      ...borderRadius(divHeadingLine, customCss),
      ...border(divHeadingLine, customCss),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const sectionline = (target, customCss) => {
  try {
    // target = sectionline's parent
    let divSectionLine = target.children[0];
    let targetCustomClassname = getTargetCustomClassname(divSectionLine.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return {
      ...width(target, customCss),
      ...alignHori(divSectionLine, customCss),
      height: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "height")[0] || { value: "0" }).value),
      width: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "width")[0] || { value: "0" }).value),
      ...bgColor(divSectionLine, customCss),
      ...borderRadius(divSectionLine, customCss),
      ...border(divSectionLine, customCss),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

// =============
// == Helpers ==
// =============

const removeUnit = (str) => {
  try {
    // str = e.g. 2px or 0.125rem
    return parseFloat(str);
  } catch (error) {
    console.error(error);
    return str;
  }
};

const getSectionDividerColors = (target) => {
  try {
    // target = data-sbtype=component
    // Get colors
    let foundColors = [];
    let str = JSON.stringify(target);
    // 1) Background colors   {"property":"background","value":"#ffffff"}
    foundColors.push(ensureRgba((str.match(/{"property":"background","value":"(.+?)"}/) || ["", "rgba(255, 255, 255, 1)"])[1]));
    // 2) Gradient color      {"property":"stop-color","value":"#cccccc"}
    if ((str.match(/{"property":"stop-color","value":"(.+?)"}/) || ["", ""])[1] !== "") {
      foundColors.push(ensureRgba((str.match(/{"property":"stop-color","value":"(.+?)"}/) || ["", ""])[1]));
    }
    // 3) Fill colors         {"property":"fill","value":"#cccccc"}
    [...str.matchAll(/{"property":"fill","value":"(.+?)"}/g)].forEach((fillColor) => {
      isColorString(fillColor[1]) && foundColors.push(ensureRgba(fillColor[1]));
    });
    // Return unique colors
    return [...new Set(foundColors)];
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getSectionDividerSpaceTopBottom = (target) => {
  try {
    // target = data-sbtype=component => get components before & after
    let componentBefore = getComponentRelativeToCurrent(target.componentId, -1);
    let componentAfter = getComponentRelativeToCurrent(target.componentId, 1);

    let { customClassRulesNormal: cssRulesBefore } = getTargetCustomCssRules(
      getCustomCss(componentBefore.componentId),
      `section.${componentBefore.classes.filter((className) => className.match(/-component-/) !== null)[0] || ""}`
    );
    let { customClassRulesNormal: cssRulesAfter } = getTargetCustomCssRules(
      getCustomCss(componentAfter.componentId),
      `section.${componentAfter.classes.filter((className) => className.match(/-component-/) !== null)[0] || ""}`
    );

    return {
      sdSpaceTop: removeUnit((cssRulesBefore.filter((rule) => rule.property === "padding-bottom")[0] || { value: "0" }).value),
      sdSpaceBottom: removeUnit((cssRulesAfter.filter((rule) => rule.property === "padding-top")[0] || { value: "0" }).value),
    };
  } catch (error) {
    return null;
  }
};

const getSectionDividerDirection = (target) => {
  try {
    // target = data-sbtype=component
    // Get the SVG's transform style
    target = getFirstChildByTagname(target, "svg");
    let transformStyle = target.styles.filter((style) => style.property === "transform")[0].value;
    return {
      sdScaleHor: parseFloat((transformStyle.match(/scaleX\((.+?)\)/) || ["", "1"])[1]),
      sdScaleVer: parseFloat((transformStyle.match(/scaleY\((.+?)\)/) || ["", "1"])[1]),
    };
  } catch (error) {
    return {
      sdScaleHor: 1,
      sdScaleVer: 1,
    };
  }
};

const getNavbarLinkData = (linkWrapper) => {
  try {
    let linkTags = getAllChildrenByTagname(linkWrapper, "a");
    return linkTags.map((link) => ({
      childId: link.childId,
      dest: link.attributes.filter((attr) => attr.property === "data-href")[0].value,
      text: link.children[0].content,
    }));
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getBorderCssRules = (customClassRulesNormal, customClassRulesHover) => {
  try {
    // Get class content
    // format: border-style: [topStyle] [rightStyle] [bottomStyle] [leftStyle]
    let borderStyleClass = (
      customClassRulesNormal.filter((rule) => rule.property === "border-style")[0] || { value: "none none none none" }
    ).value.split(" ");
    // borderSide = none, all, left, right, top, bottom, topbottom, leftright
    let borders = {
      top: borderStyleClass[0],
      right: borderStyleClass[1],
      bottom: borderStyleClass[2],
      left: borderStyleClass[3],
    };
    let borderSide = "none";
    if (borders.top !== "none" && borders.right !== "none" && borders.bottom !== "none" && borders.left !== "none") {
      borderSide = "all";
    }
    if (borders.top === "none" && borders.right === "none" && borders.bottom === "none" && borders.left !== "none") {
      borderSide = "left";
    }
    if (borders.top === "none" && borders.right !== "none" && borders.bottom === "none" && borders.left === "none") {
      borderSide = "right";
    }
    if (borders.top !== "none" && borders.right === "none" && borders.bottom === "none" && borders.left === "none") {
      borderSide = "top";
    }
    if (borders.top === "none" && borders.right === "none" && borders.bottom !== "none" && borders.left === "none") {
      borderSide = "bottom";
    }
    if (borders.top !== "none" && borders.right === "none" && borders.bottom !== "none" && borders.left === "none") {
      borderSide = "topbottom";
    }
    if (borders.top === "none" && borders.right !== "none" && borders.bottom === "none" && borders.left !== "none") {
      borderSide = "leftright";
    }
    return {
      borderSide: borderSide,
      borderType: borderStyleClass.filter((style) => style !== "none")[0] || "solid",
      borderWidth: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "border-width")[0] || { value: 0 }).value),
      borderColor: (customClassRulesNormal.filter((rule) => rule.property === "border-color")[0] || { value: "rgba(0, 0, 0, 0)" }).value,
      borderColorHover: (customClassRulesHover.filter((rule) => rule.property === "border-color")[0] || { value: "rgba(0, 0, 0, 0)" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getCarouselNavStyles = (
  carouselOptions,
  cssRulesNormalArrows,
  cssRulesHoverArrows,
  cssRulesNormalSvg,
  cssRulesHoverSvg,
  cssRulesNormalPrev
) => {
  try {
    return {
      includeNavButtons: carouselOptions.arrows ? true : false,
      bgColor: (cssRulesNormalArrows.filter((rule) => rule.property === "background")[0] || { value: "rgba(255, 255, 255, 0)" }).value,
      bgColorHover: (cssRulesHoverArrows.filter((rule) => rule.property === "background")[0] || { value: "rgba(255, 255, 255, 0)" }).value,
      ...getBorderCssRules(cssRulesNormalArrows, cssRulesHoverArrows),
      borderRadius: removeUnit((cssRulesNormalArrows.filter((rule) => rule.property === "border-radius")[0] || { value: 0 }).value),
      transition: removeUnit(
        (cssRulesNormalArrows.filter((rule) => rule.property === "transition")[0] || { value: "all 0s ease-in-out" }).value.split(" ")[1]
      ),
      iconSize: removeUnit((cssRulesNormalSvg.filter((rule) => rule.property === "height")[0] || { value: "1.2rem" }).value),
      fillColor: (cssRulesNormalSvg.filter((rule) => rule.property === "fill")[0] || { value: "rgba(0, 0, 0, 1)" }).value,
      fillColorHover: (cssRulesHoverSvg.filter((rule) => rule.property === "fill")[0] || { value: "rgba(0, 0, 0, 1)" }).value,
      pos: (cssRulesNormalPrev.filter((rule) => rule.property === "left")[0] || { value: "1rem" }).value === "1rem" ? "inside" : "outside",
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const getInputfieldShowLabel = (customCss) => {
  try {
    let label = getInputfieldLabel();
    let targetCustomClassname = getTargetCustomClassname(label.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return (customClassRulesNormal.filter((rule) => rule.property === "display")[0] || { value: "none" }).value === "block";
  } catch (error) {
    return "";
  }
};

const getInputfieldLabelText = () => {
  try {
    // let inputFieldWrapper = getParentWithAttr("data-inputfieldwrapper", "true");
    // return inputFieldWrapper.children.filter((child) => child.htmlTagName === "label")[0].children[0].content;
    let label = getInputfieldLabel();
    return label.children[0].content;
  } catch (error) {
    return "";
  }
};

const getInputfieldPlaceholderText = (inputField) => {
  try {
    if (inputField.htmlTagName === "input" || inputField.htmlTagName === "textarea") {
      return (inputField.attributes.filter((attr) => attr.property === "placeholder")[0] || { value: "" }).value;
    }
    if (inputField.htmlTagName === "select") {
      let placeholderOption = getSelectPlaceholderOption(inputField);
      return placeholderOption.children[0].content;
    }
    if (inputField.attributes.filter((attr) => attr.property === "data-formcheckwrapper" && attr.value === "true").length > 0) {
      return "";
    }
  } catch (error) {
    return "";
  }
};

const getInputfieldType = (inputField) => {
  try {
    if (inputField.htmlTagName === "input") {
      return (inputField.attributes.filter((attr) => attr.property === "type")[0] || { value: "text" }).value;
    }
    if (inputField.htmlTagName === "textarea" || inputField.htmlTagName === "select") {
      return inputField.htmlTagName;
    }
    if (inputField.attributes.filter((attr) => attr.property === "data-formcheckwrapper" && attr.value === "true").length > 0) {
      return "check";
    }
  } catch (error) {
    return "";
  }
};

const getInputfieldDropdownOptions = (inputField) => {
  // options = [ { val: "", label: "" }, ... ]
  try {
    if (inputField.htmlTagName === "select") {
      let options = inputField.children
        .filter((child) => child.htmlTagName === "option")
        .filter((o, i) => i !== 0)
        .map((option) => {
          return { val: option.attributes.filter((attr) => attr.property === "value")[0].value, label: option.children[0].content };
        });
      return JSON.stringify(options);
    }
    throw new Error("Inputfield is not a select dropdown");
  } catch (error) {
    return JSON.stringify([]);
  }
};

const getFormAction = (sbFormDiv) => {
  try {
    let val = sbFormDiv.attributes.filter((attr) => attr.property === "data-sbformaction")[0].value;
    return val === "" ? JSON.stringify([]) : val;
  } catch (error) {
    return JSON.stringify([]);
  }
};

const getBgGradientVars = (arrCssRules) => {
  let backgroundColor = "rgba(255, 255, 255, 0)";
  let boolBgIsGradient = false;
  let bgGradientColor1 = backgroundColor;
  let bgGradientColor2 = backgroundColor;
  let bgGradientStop1 = 0;
  let bgGradientStop2 = 0;
  let bgGradientDirection = 0;
  try {
    // get background-color value
    // Possibilities: 1) single bg color via background-color prop, 2) single bg color via background prop, 3) gradient via background prop, 4) nothing/error: return transparent bg
    if (typeof arrCssRules.filter((rule) => rule.property === "background-color")[0] !== "undefined") {
      // Option 1
      backgroundColor = (arrCssRules.filter((rule) => rule.property === "background-color")[0] || { value: "rgba(255, 255, 255, 0)" }).value;
    } else {
      if (
        typeof arrCssRules.filter((rule) => rule.property === "background")[0] === "undefined" ||
        arrCssRules.filter((rule) => rule.property === "background")[0].value.match(/deg/) !== null
      ) {
        // Options 3 & 4 (get gradient stop colors below)
        backgroundColor = "rgba(255, 255, 255, 0)";
      } else {
        // Option 2
        backgroundColor = arrCssRules.filter((rule) => rule.property === "background")[0].value;
      }
    }
    // check whether background is gradient and if so, get values
    let bgCssString = (arrCssRules.filter((rule) => rule.property === "background")[0] || { value: "" }).value;
    bgGradientColor1 = backgroundColor;
    bgGradientColor2 = backgroundColor;
    let values = bgCssString.match(/(\d+)deg.+?rgba\((.+?)\).+?(\d+)%.+?rgba\((.+?)\).+?(\d+)%/);
    if (values !== null) {
      // If background is gradient, set values
      boolBgIsGradient = true;
      bgGradientDirection = parseInt(values[1]);
      bgGradientColor1 = `rgba(${values[2]})`;
      bgGradientStop1 = parseInt(values[3]);
      bgGradientColor2 = `rgba(${values[4]})`;
      bgGradientStop2 = parseInt(values[5]);
    }
    return { backgroundColor, boolBgIsGradient, bgGradientDirection, bgGradientColor1, bgGradientColor2, bgGradientStop1, bgGradientStop2 };
  } catch (error) {
    console.error(error);
    return { backgroundColor, boolBgIsGradient, bgGradientDirection, bgGradientColor1, bgGradientColor2, bgGradientStop1, bgGradientStop2 };
  }
};
