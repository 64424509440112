import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./formComponents/Select";
import Slider from "./formComponents/Slider";
import ColorPicker from "./formComponents/ColorPicker";
import Divider from "./formComponents/Divider";
import Width from "./Width";
import AlignHori from "./AlignHori";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const SectionLine = ({ sb: { startStyles } }) => {
  return (
    <>
      <Width />
      <Divider />
      <AlignHori />
      <Divider />
      <Slider
        label={translate("cEditForms.sectionLine_height", false, null)}
        id={"height"}
        min={1}
        max={150}
        step={1}
        startValue={formGetStartValue(startStyles, "height")}
        tooltipText={translate("cEditForms.sectionLine_ttHeight", false, null)}
      />
      <Slider
        label={translate("cEditForms.sectionLine_width", false, null)}
        id={"width"}
        min={1}
        max={10}
        step={1}
        startValue={formGetStartValue(startStyles, "width")}
        tooltipText={translate("cEditForms.sectionLine_ttWidth", false, null)}
      />
      <Divider />
      <ColorPicker
        label={translate("cEditForms.color", false, null)}
        id={"bgColor"}
        startValue={formGetStartValue(startStyles, "bgColor")}
        tooltipText={translate("cEditForms.sectionLine_ttColor", false, null)}
      />
      <Divider />
      {/* Border */}
      <Select
        label={translate("cEditForms.borderSides", false, null)}
        id={"borderSide"}
        options={[
          { val: "none", label: translate("cEditForms.noBorder", false, null) },
          { val: "all", label: translate("cEditForms.allSides", false, null) },
          { val: "left", label: translate("cEditForms.left", false, null) },
          { val: "right", label: translate("cEditForms.right", false, null) },
          { val: "top", label: translate("cEditForms.top", false, null) },
          { val: "bottom", label: translate("cEditForms.bottom", false, null) },
          { val: "topbottom", label: translate("cEditForms.topAndBottom", false, null) },
          { val: "leftright", label: translate("cEditForms.leftAndRight", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "borderSide")}
        tooltipText={translate("cEditForms.sectionLine_ttBorder", false, null)}
      />
      <Select
        label={translate("cEditForms.borderType", false, null)}
        id={"borderType"}
        options={[
          { val: "hidden", label: translate("cEditForms.hidden", false, null) },
          { val: "dotted", label: translate("cEditForms.dotted", false, null) },
          { val: "dashed", label: translate("cEditForms.dashed", false, null) },
          { val: "solid", label: translate("cEditForms.solid", false, null) },
          { val: "double", label: translate("cEditForms.double", false, null) },
          { val: "groove", label: translate("cEditForms.groove", false, null) },
          { val: "ridge", label: translate("cEditForms.ridge", false, null) },
          { val: "inset", label: translate("cEditForms.inset", false, null) },
          { val: "outset", label: translate("cEditForms.outset", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "borderType")}
        tooltipText={translate("cEditForms.tooltipBorderType", false, null)}
      />
      <Slider
        label={translate("cEditForms.borderSize", false, null)}
        id={"borderWidth"}
        min={0}
        max={10}
        step={1}
        startValue={formGetStartValue(startStyles, "borderWidth")}
        tooltipText={translate("cEditForms.tooltipBorderSize", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.borderColor", false, null)}
        id={"borderColor"}
        startValue={formGetStartValue(startStyles, "borderColor")}
        tooltipText={translate("cEditForms.tooltipBorderColor", false, null)}
      />
      <Divider />
      <Select
        label={translate("cEditForms.borderRadius", false, null)}
        id={"borderRadius"}
        options={[
          { val: 0, label: translate("cEditForms.square", false, null) },
          { val: 0.2, label: translate("cEditForms.roundedSmall", false, null) },
          { val: 0.4, label: translate("cEditForms.roundedMedium", false, null) },
          { val: 0.75, label: translate("cEditForms.roundedLarge", false, null) },
          { val: 1, label: translate("cEditForms.roundedExtraLarge", false, null) },
          { val: 1000, label: translate("cEditForms.pill", false, null) },
          { val: 50, label: translate("cEditForms.circle", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "borderRadius")}
        tooltipText={translate("cEditForms.sectionLine_ttBorderRadius", false, null)}
      />
    </>
  );
};

SectionLine.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(SectionLine);
