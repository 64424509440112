export const imggallery4 = {
  id: "imggallery-4",
  name: "Image gallery 4",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "imggallery-4-component"],
    styles: [],
    attributes: [
      { property: "data-name", value: "carousel_thumbnail" },
      { property: "data-editable", value: "true" },
    ],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["splide", "carousel-thumbnail-main", "imggallery-4-splide-main"],
        styles: [],
        attributes: [
          { property: "aria-label", value: "Carousel" },
          {
            property: "data-splide",
            value:
              '{"width":"100vw","heightRatio":0.6,"type":"slide","rewind":true,"speed":500,"rewindSpeed":1000,"easing":"linear","pagination":false,"arrows":false}',
          },
          { property: "data-carousel-slidesetup", value: "image" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["splide__track"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["splide__list"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-main-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-main-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature1.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-main-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-main-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature2.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-main-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-main-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature3.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-main-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-main-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature4.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-main-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-main-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature5.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-main-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-main-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature6.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-main-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-main-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature7.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-main-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-main-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature8.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-main-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-main-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature9.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-main-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-main-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature10.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-main-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-main-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature11.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-main-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-main-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature12.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["splide", "carousel-thumbnail-thumbnail", "imggallery-4-splide-thumbnail"],
        styles: [],
        attributes: [
          { property: "aria-label", value: "Carousel" },
          {
            property: "data-splide",
            value:
              '{"fixedWidth":200,"fixedHeight":120,"gap":10,"rewind":true,"speed":500,"rewindSpeed":1000,"easing":"linear","autoplay":true,"interval":2000,"pauseOnHover":true,"arrows":true,"pagination":false,"isNavigation":true,"focus":"center","keyboard":true,"breakpoints":{"600":{"fixedWidth":60,"fixedHeight":44}},"classes":{"arrow":"splide__arrow imggallery-4-arrow","prev":"splide__arrow--prev imggallery-4-prev","next":"splide__arrow--next imggallery-4-next","page":"splide__pagination__page imggallery-4-pagination"}}',
          },
          { property: "data-carousel-slidesetup", value: "image" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["splide__track"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["splide__list"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-thumbnail-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-thumbnail-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature1.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-thumbnail-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-thumbnail-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature2.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-thumbnail-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-thumbnail-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature3.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-thumbnail-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-thumbnail-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature4.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-thumbnail-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-thumbnail-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature5.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-thumbnail-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-thumbnail-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature6.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-thumbnail-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-thumbnail-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature7.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-thumbnail-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-thumbnail-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature8.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-thumbnail-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-thumbnail-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature9.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-thumbnail-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-thumbnail-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature10.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-thumbnail-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-thumbnail-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature11.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["splide__slide", "imggallery-4-thumbnail-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["imggallery-4-thumbnail-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/nature12.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.imggallery-4-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.imggallery-4-component", pseudo: "hover", rules: [] },
      {
        className: "imggallery-4-main-img",
        pseudo: "",
        rules: [
          { property: "width", value: "100%" },
          { property: "height", value: "100%" },
          { property: "object-fit", value: "cover" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "imggallery-4-main-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "imggallery-4-thumbnail-img",
        pseudo: "",
        rules: [
          { property: "width", value: "100%" },
          { property: "height", value: "100%" },
          { property: "object-fit", value: "cover" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "imggallery-4-thumbnail-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "imggallery-4-thumbnail-slide",
        pseudo: "",
        rules: [
          { property: "opacity", value: "0.4" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "imggallery-4-thumbnail-slide",
        pseudo: "hover",
        rules: [
          { property: "opacity", value: "1" },
          { property: "border-color", value: "rgba(0, 0, 0, 0) !important" },
        ],
      },
      {
        className: "is-active.imggallery-4-thumbnail-slide",
        pseudo: "",
        rules: [
          { property: "opacity", value: "1" },
          { property: "border-color", value: "rgba(0, 0, 0, 0) !important" },
        ],
      },
      {
        className: "imggallery-4-arrow",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.5)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-radius", value: "50%" },
          { property: "height", value: "2rem" },
          { property: "width", value: "2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "imggallery-4-arrow",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "imggallery-4-arrow svg",
        pseudo: "",
        rules: [
          { property: "fill", value: "rgba(0, 0, 0, 0.7)" },
          { property: "height", value: "1.2rem" },
          { property: "width", value: "1.2rem" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "imggallery-4-arrow svg",
        pseudo: "hover",
        rules: [{ property: "fill", value: "rgba(0, 0, 0, 1)" }],
      },
      {
        className: "imggallery-4-prev",
        pseudo: "",
        rules: [{ property: "left", value: "1rem" }],
      },
      {
        className: "imggallery-4-next",
        pseudo: "",
        rules: [{ property: "right", value: "1rem" }],
      },
      //   {
      //     className: "imggallery-4-pagination",
      //     pseudo: "",
      //     rules: [
      //       { property: "height", value: "0.7rem" },
      //       { property: "width", value: "0.7rem" },
      //       { property: "background", value: "rgba(255, 255, 255, 0.5)" },
      //       { property: "margin", value: "0.25rem" },
      //       { property: "border-radius", value: "50%" },
      //       { property: "display", value: "inline-block" },
      //       { property: "border-style", value: "none none none none" },
      //       { property: "border-width", value: "1px" },
      //       { property: "border-color", value: "rgba(255, 255, 255, 1)" },
      //       { property: "transition", value: "all 0.3s ease" },
      //       { property: "opacity", value: "1" },
      //     ],
      //   },
      //   {
      //     className: "imggallery-4-pagination",
      //     pseudo: "hover",
      //     rules: [
      //       { property: "background", value: "rgba(255, 255, 255, 0.8)" },
      //       { property: "border-color", value: "rgba(255, 255, 255, 1)" },
      //       { property: "transform", value: "scale(1)" },
      //       { property: "opacity", value: "1" },
      //     ],
      //   },
      //   {
      //     className: "is-active.imggallery-4-pagination",
      //     pseudo: "",
      //     rules: [
      //       { property: "background", value: "rgba(255, 255, 255, 0.8)" },
      //       { property: "border-color", value: "rgba(255, 255, 255, 1)" },
      //       { property: "transform", value: "scale(1)" },
      //       { property: "opacity", value: "1" },
      //     ],
      //   },
    ],
  },
};
