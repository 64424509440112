import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ColorPicker from "./formComponents/ColorPicker";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const TextColor = ({ sb: { startStyles } }) => {
  const [bool, setBool] = useState(false);
  useEffect(() => {
    if (formGetStartValue(startStyles, "textColor") === formGetStartValue(startStyles, "textColorHover")) {
      setBool(false);
    } else {
      setBool(true);
    }
  }, [startStyles]);

  return (
    <>
      <ColorPicker
        label={translate("cEditForms.textColor", false, null)}
        id={"textColor"}
        startValue={formGetStartValue(startStyles, "textColor")}
        tooltipText={translate("cEditForms.setTextColor", false, null)}
      />
      <div className={`form-check form-switch mt-3 ${bool ? "mb-n2" : "mb-3"}`}>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="switchTextColorHover"
          value={bool}
          checked={bool}
          onChange={(e) => setBool(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="switchTextColorHover">
          {translate("cEditForms.changeTextColorHover", false, null)}
        </label>
      </div>
      {bool && (
        <ColorPicker
          label={translate("cEditForms.textColorHover", false, null)}
          id={"textColorHover"}
          startValue={formGetStartValue(startStyles, "textColorHover")}
          tooltipText={translate("cEditForms.setTextColorHover", false, null)}
        />
      )}
    </>
  );
};

TextColor.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(TextColor);
