export const sectiondividers28 = {
  id: "sectiondividers-28",
  name: "Section dividers 28",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "svg",
        classes: [],
        styles: [{ property: "background", value: "rgba(255, 255, 255, 1)" }],
        attributes: [
          { property: "xmlns", value: "http://www.w3.org/2000/svg" },
          { property: "version", value: "1.1" },
          { property: "width", value: "100%" },
          { property: "height", value: "25" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "defs",
            classes: [],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "pattern",
                classes: [],
                styles: [],
                attributes: [
                  { property: "id", value: "svgSimpleHalfcircleSmallPattern" },
                  { property: "x", value: "0" },
                  { property: "y", value: "25" },
                  { property: "width", value: "50" },
                  { property: "height", value: "50" },
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "patternUnits", value: "userSpaceOnUse" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "ellipse",
                    classes: [],
                    styles: [],
                    attributes: [
                      { property: "cx", value: "25" },
                      { property: "cy", value: "25" },
                      { property: "rx", value: "25" },
                      { property: "ry", value: "25" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "rect",
            classes: [],
            styles: [],
            attributes: [
              { property: "x", value: "0" },
              { property: "y", value: "0" },
              { property: "width", value: "100%" },
              { property: "height", value: "25" },
              { property: "fill", value: "url(#svgSimpleHalfcircleSmallPattern)" },
            ],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
