import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import Spinner from "../components/layout/Spinner";
import setAuthToken from "../utils/setAuthToken";
import setWorkspace from "../utils/setWorkspace";

import { loadProject } from "../actions/sb";
import { loadUser } from "../actions/auth";
import { publishSite } from "../lib/publishSite";
import { setGlobalLoading, removeGlobalLoading } from "../actions/globalLoading";
import { translate } from "../translations/translations";

const Publish = ({ globalLoading: { globalLoading }, sb: { project }, loadProject, loadUser, setGlobalLoading, removeGlobalLoading }) => {
  let { token } = useParams();
  let { wsId } = useParams();
  let { projectId } = useParams();
  let { domainName } = useParams();
  let { dir } = useParams(); // base64 encoded string
  const [publishResult, setPublishResult] = useState({ success: false, msg: "" });
  const [tokensBeenSet, setTokensBeenSet] = useState(false);
  const [loadingId, setLoadingId] = useState("");

  useEffect(() => {
    if (token !== null && wsId !== null && projectId !== null && domainName !== null && dir !== null) {
      setLoadingId(setGlobalLoading(translate("aSb.loadingProject", false, null)));
      setTokens();
    }
    // eslint-disable-next-line
  }, [token, wsId, projectId, domainName, dir]);

  useEffect(() => {
    if (tokensBeenSet) {
      // console.log("tokensBeenSet:", tokensBeenSet, "=> loadProject");
      loadProject(projectId);
    }
    // eslint-disable-next-line
  }, [tokensBeenSet]);

  useEffect(() => {
    if (project !== null) {
      if (project.status === "Production") {
        setPublishResult({ success: false, msg: translate("pPublish.projectAlreadyPublished", false, null) });
      } else {
        startPublishSite();
      }
    }
    // eslint-disable-next-line
  }, [project]);

  const setTokens = async () => {
    // console.log("setTokens ..");
    localStorage.setItem("token", token);
    setAuthToken(token);
    localStorage.setItem("workspace", wsId);
    setWorkspace(wsId);
    // console.log(`setTokens pre loadUser // ${Date.now()}`);
    await loadUser();
    // console.log(`setTokens after loadUser // ${Date.now()}`);
    setTokensBeenSet(true);
  };

  const startPublishSite = async () => {
    // console.log("startPublishSite...");
    const result = await publishSite(projectId, domainName, window.atob(dir));
    setPublishResult(result);
    removeGlobalLoading(loadingId);
    setLoadingId("");
  };

  return globalLoading.length > 0 ? (
    <Spinner fullPage={true} msg={globalLoading[0].msg} />
  ) : (
    <div className="container-fluid mt-5">
      <div className="px-3 py-4 bg-light rounded-3 shadow d-flex flex-column align-items-center">
        <span
          className={`mt-3 bg-light border border-${publishResult.success ? "success" : "danger"} text-${
            publishResult.success ? "success" : "danger"
          } rounded-circle d-flex align-items-center justify-content-center fontSize25`}
          style={{ height: "75px", width: "75px", boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)" }}
        >
          <i className={publishResult.success ? "fa-solid fa-check" : "fa-solid fa-exclamation"} />
        </span>
        <p className="mt-4 mb-3 text-center">{publishResult.msg}</p>
      </div>
    </div>
  );
};

Publish.propTypes = {
  globalLoading: PropTypes.object.isRequired,
  sb: PropTypes.object.isRequired,
  loadProject: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  setGlobalLoading: PropTypes.func.isRequired,
  removeGlobalLoading: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  globalLoading: state.globalLoading,
  sb: state.sb,
});

export default connect(mapStateToProps, { loadProject, loadUser, setGlobalLoading, removeGlobalLoading })(Publish);
