import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Border from "./Border";
import Margin from "./Margin";
import Padding from "./Padding";
import AlignCol from "./AlignCol";
import AlignRow from "./AlignRow";
import ResponsiveLayout from "./ResponsiveLayout";
import BorderRadius from "./BorderRadius";
import BoxShadow from "./BoxShadow";
import Transition from "./Transition";
import OverlayColor from "./OverlayColor";
import ColorPicker from "./formComponents/ColorPicker";
import Divider from "./formComponents/Divider";
import ImgSource from "./ImgSource";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const Card3 = ({ sb: { startStyles } }) => {
  return (
    <>
      <ImgSource />
      {/* Layout */}
      <ResponsiveLayout />
      <Divider />
      <AlignCol />
      <Divider />
      <AlignRow />
      <Divider />
      <Padding />
      <Divider />
      <Margin />
      <Divider />
      {/* Style */}
      <Border />
      <Divider />
      <BorderRadius />
      <Divider />
      <BoxShadow />
      <Divider />
      {/* Component */}
      <Transition />
      <Divider />
      <OverlayColor />
      <Divider />
      <ColorPicker
        label={translate("cEditForms.card3_footerColor", false, null)}
        id={"card3_desc_bgColor"}
        startValue={formGetStartValue(startStyles, "card3_desc_bgColor")}
        tooltipText={translate("cEditForms.card3_tooltipFooterColor", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.card3_footerColorHover", false, null)}
        id={"card3_desc_bgColorHover"}
        startValue={formGetStartValue(startStyles, "card3_desc_bgColorHover")}
        tooltipText={translate("cEditForms.card3_tooltipFooterColorHover", false, null)}
      />
    </>
  );
};

Card3.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Card3);
