import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";
import Select from "./formComponents/Select";
import Divider from "./formComponents/Divider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const CarouselThumbnailSettings = ({ sb: { startStyles } }) => {
  const [carouselOptions, setCarouselOptions] = useState(null);

  useEffect(() => {
    try {
      let options = formGetStartValue(startStyles, "carouselOptionsThumbnail");
      if (options !== null && typeof options !== "undefined" && Object.keys(options).length > 0) {
        setCarouselOptions(options);
      } else {
        setCarouselOptions(null);
      }
    } catch (error) {
      setCarouselOptions(null);
    }
  }, [startStyles]);

  const getCarouselStartVal = (varName) => {
    try {
      if (varName === "autoscrollSpeed") {
        return typeof carouselOptions.autoScroll === "undefined" ? 1 : carouselOptions.autoScroll.speed;
      }
      if (varName === "fixedWidth" || varName === "fixedHeight" || varName === "gap") {
        return parseInt(typeof carouselOptions[varName] === "undefined" ? 0 : carouselOptions[varName]);
      }
      return carouselOptions[varName];
    } catch (error) {
      return "";
    }
  };

  return (
    <>
      {/* Hidden tracker for carousel options starting values */}
      <input
        type="hidden"
        id={`${EDIT_FORM_ID_PREFIX}carouselOptionsThumbnail`}
        value={JSON.stringify(formGetStartValue(startStyles, "carouselOptionsThumbnail"))}
      />
      <input
        type="hidden"
        id={`${EDIT_FORM_ID_PREFIX}carouselOptionsMain`}
        value={JSON.stringify(formGetStartValue(startStyles, "carouselOptionsMain"))}
      />
      <Select
        label={translate("cEditForms.carouselSettings_autoplay", false, null)}
        id={"carousel_thumbnail_autoplay"}
        // No continuous loop for thumbnail carousels
        options={[
          { val: "manual", label: translate("cEditForms.carouselSettings_manualClicks", false, null) },
          { val: "autoplay", label: translate("cEditForms.carouselSettings_autoplay", false, null) },
        ]}
        startValue={getCarouselStartVal("carouselAutoplay")}
        tooltipText={translate("cEditForms.carouselThumbnail_autoplay", false, null)}
      />
      <Divider />
      <Slider
        label={translate("cEditForms.carouselSettings_transSpeed", false, null)}
        id={"carousel_thumbnail_transitionSpeed"}
        min={0}
        max={2}
        step={0.1}
        startValue={parseFloat(getCarouselStartVal("speed") / 1000)}
        tooltipText={translate("cEditForms.carouselSettings_ttTransSpeed", false, null)}
      />
      <Slider
        label={translate("cEditForms.carouselSettings_rewindSpeed", false, null)}
        id={"carousel_thumbnail_rewindSpeed"}
        min={0}
        max={2}
        step={0.1}
        startValue={parseFloat(getCarouselStartVal("rewindSpeed") / 1000)}
        tooltipText={translate("cEditForms.carouselSettings_ttRewindSpeed", false, null)}
      />
      <Slider
        label={translate("cEditForms.carouselSettings_autoplaySpeed", false, null)}
        id={"carousel_thumbnail_interval"}
        min={0.5}
        max={5}
        step={0.1}
        startValue={parseFloat(getCarouselStartVal("interval") / 1000)}
        tooltipText={translate("cEditForms.carouselSettings_ttAutoplaySpeed", false, null)}
      />
    </>
  );
};

CarouselThumbnailSettings.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(CarouselThumbnailSettings);
