export const basics2 = {
  id: "basics-2",
  name: "Basic building blocks - Paragraph",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "basics-2-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "p",
        classes: ["basics-2-text", "w-100", "w-md-70", "w-lg-50"],
        styles: [],
        attributes: [
          { property: "data-editable", value: "true" },
          { property: "data-texteditable", value: "true" },
          { property: "data-name", value: "p" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "textNode",
            classes: [],
            styles: [],
            attributes: [],
            content:
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.basics-2-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.basics-2-component", pseudo: "hover", rules: [] },
      {
        className: "basics-2-component",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "basics-2-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "basics-2-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "0rem 0rem 1rem 0rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "basics-2-text",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
