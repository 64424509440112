export const template6 = {
  id: "638cc577340b4dc84daf0f6a",
  name: { en: "Restaurant Roche", nl: "Restaurant Roche" },
  desc: { en: "Website for a restaurant, showing its menu", nl: "Website voor een restaurant met haar menu" },
  categories: ["hospitality"],
  sbPages: [
    {
      pageId: "W558zz49ms",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "eZxp5U",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-6-component-eZxp5U"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "L6R1gp",
              type: "",
              htmlTagName: "div",
              classes: ["hero-6-wrapper-eZxp5U"],
              styles: [],
              attributes: [{ property: "data-herowrapper", value: "true" }],
              content: "",
              children: [
                {
                  childId: "Pa0bVb",
                  type: "",
                  htmlTagName: "img",
                  classes: ["hero-6-img-eZxp5U"],
                  styles: [],
                  attributes: [
                    { property: "alt", value: "Image" },
                    { property: "src", value: "https://cdn.satonda.com/eb/assets/restaurant-1.png" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "JlosRP",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-6-content-eZxp5U"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "hero1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "LeJbPd",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-6-title-eZxp5U", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Restaurant Roche",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "EkLAN9",
                      type: "",
                      htmlTagName: "h3",
                      classes: ["uc61Cm2MHn1euxIXuVgOHDXcm81b81", "w-100", "w-md-80", "w-lg-60"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "h" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "EfRSbs",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-EfRSbs"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "EMXpuC",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-EfRSbs", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Delicious journey",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "s0f5hb",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-s0f5hb"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "UoTXMu",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-s0f5hb", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "vx1Fc4",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-10-component-vx1Fc4"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "NkK6vq",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-10-row-vx1Fc4"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "lZUvS8",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-10-col-vx1Fc4"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "zuMj93",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["card-10-title-vx1Fc4", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Authentic heritage",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "sfUloL",
                      type: "",
                      htmlTagName: "p",
                      classes: ["card-10-desc-text-vx1Fc4", "w-90", "w-md-90", "w-lg-90"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "WvkfB2",
                      type: "",
                      htmlTagName: "p",
                      classes: ["xUir4K0P8nD0dOKaC1puQnpDsBDcQ4", "w-90", "w-md-90", "w-lg-90"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "span",
                          classes: [],
                          styles: [{ property: "color", value: "rgb(10,10,10)" }],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                              children: [],
                            },
                          ],
                        },
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: " ", children: [] },
                      ],
                    },
                  ],
                },
                {
                  childId: "HefVcY",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-10-col-vx1Fc4"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "gCEbsk",
                      type: "",
                      htmlTagName: "img",
                      classes: ["card-10-img-vx1Fc4"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/food-3.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "lkVPNb",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-lkVPNb"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Re6IfN",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-lkVPNb", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Our menu", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "IPlgKK",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-IPlgKK"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "hBsODR",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-IPlgKK", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "zYeQsP",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "menu-2-component-zYeQsP"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "suGFDF",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "nWRVkF",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "menu-2-col-zYeQsP"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "USMZxU",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["menu-2-col-zYeQsPumn-title", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Starters",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "aSAsKZ",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["menu-2-wrapper-menu-items"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "ihJJZx",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-zYeQsP"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "q7IYxv",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "cMZo12",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-zYeQsP", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Fines De Claires",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "eUzcf7",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-zYeQsP", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€25",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "qcMbDE",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-zYeQsP", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "French Fines De Claires Oysters Nr.3",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "sXzhiY",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-zYeQsP"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "F5oJav",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "tPAyEq",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-zYeQsP", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Wild Atlantics Fines De Claires",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "cwap0H",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-zYeQsP", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€30",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "fFUfkE",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-zYeQsP", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Irish Wild Atlantics Fines De Claires Oysters Nr.3",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "uGskRv",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-zYeQsP"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "D1MAJ3",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Xr26UL",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-zYeQsP", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Dégustation des Huîtres",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "LzVtim",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-zYeQsP", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€45",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "P7dK1C",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-zYeQsP", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "'Dégustation des Huîtres' Mix of 12 oysters",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "qw8xT5",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "menu-2-component-qw8xT5"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "H5Zvd2",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "zeKPYl",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "menu-2-col-qw8xT5"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "Mrgb26",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["menu-2-col-qw8xT5umn-title", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Mains", children: [] },
                      ],
                    },
                    {
                      childId: "A9He3Q",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["menu-2-wrapper-menu-items"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "zZR9eH",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-qw8xT5"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "xyJhKS",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "XNcPaL",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-qw8xT5", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Entrecôte",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "imY6oD",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-qw8xT5", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€25",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "QIbmHg",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-qw8xT5", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Entrecôte with French fries, vegetables and Béarnaise sauce or Madeira gravy",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "Y0B88h",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-qw8xT5"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mob3wj",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "xlq5uN",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-qw8xT5", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Gougère",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "flrjcR",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-qw8xT5", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€30",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "lRknDr",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-qw8xT5", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Gougère with cream of red beetroot, green asparagus, King boleet, pickled Chioggia beet, pistachio and Comté cheese",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "srvBSj",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-qw8xT5"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mDlwy5",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Rxc0NL",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-qw8xT5", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Lobster",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "N1foZL",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-qw8xT5", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€45",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "KwvKtZ",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-qw8xT5", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Whole lobster (700 grams) served lukewarm with green salad, parsley mayonaise and fries",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "SzWroT",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "menu-2-component-SzWroT"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Q7XdZY",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "u1Ngoj",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "menu-2-col-SzWroT"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "wtJxyk",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["menu-2-col-SzWroTumn-title", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Desserts",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "ITEqDd",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["menu-2-wrapper-menu-items"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "nmyHzZ",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-SzWroT"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "qLoVrV",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "kP3kDH",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-SzWroT", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Assortment of French cheeses",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "oLqZc5",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-SzWroT", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€25",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "x2j0zj",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-SzWroT", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Assortment of French cheeses",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "kA6Juw",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-SzWroT"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "b6PNzo",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "KKsJbR",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-SzWroT", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Crème brûlée with vanilla and ice cream",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "idoHre",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-SzWroT", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€30",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "C1Jvob",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-SzWroT", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Crème brûlée with vanilla and ice cream",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "czU7lT",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-SzWroT"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "daDECp",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "QLnl1F",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-SzWroT", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Dessert of the day",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "eundVj",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-SzWroT", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€45",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "fQb6HW",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-SzWroT", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Dessert of the day",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "GT3aFH",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-GT3aFH"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Kmy7Si",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-GT3aFH", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "yTSgLr",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-3-component-yTSgLr"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "F4Sptx",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "E0QFt5",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "p-2", "contactus-3-googlemaps-col-yTSgLr"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "qnzpzR",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-3-googlemaps-wrapper-yTSgLr"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "googlemaps_wrapper" },
                        { property: "data-editable", value: "true" },
                      ],
                      content: "",
                      children: [],
                    },
                    {
                      childId: "C7WXE7",
                      type: "",
                      htmlTagName: "iframe",
                      classes: [],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "googlemaps" },
                        {
                          property: "src",
                          value:
                            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8680.352904042613!2d4.899406522082844!3d52.37329199453203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609b70dd81623%3A0xcae71b8d3adfd142!2sAmsterdam%20Centraal!5e0!3m2!1sen!2snl!4v1662818615871!5m2!1sen!2snl",
                        },
                        { property: "scrolling", value: "no" },
                        { property: "loading", value: "lazy" },
                        { property: "referrerpolicy", value: "no-referrer-when-downgrade" },
                        { property: "width", value: "100%" },
                        { property: "height", value: "100%" },
                        { property: "frameborder", value: "0" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "HKVkbc",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "p-2"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "dP2E4X",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["row", "justify-content-center", "align-items-stretch", "contactus-3-row"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "TWuRpc",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "contactus-3-col-yTSgLr"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "bz1Z8B",
                              type: "",
                              htmlTagName: "button",
                              classes: ["contactus-3-iconwrapper-yTSgLr"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "Ih8hOx",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-location-dot"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "WVKk4x",
                              type: "",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "HFrVCn",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["contactus-3-text-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Street 1337",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "by3S4A",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["contactus-3-text-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Postal code, City",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "ld4UmQ",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "contactus-3-col-yTSgLr"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "VrsfmB",
                              type: "",
                              htmlTagName: "button",
                              classes: ["contactus-3-iconwrapper-yTSgLr"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "tslOga",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-envelope"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "nuBInS",
                              type: "",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "BT6sj7",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["contactus-3-text-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "email@domain.com",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "JkftDk",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "contactus-3-col-yTSgLr"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "oXqh9Q",
                              type: "",
                              htmlTagName: "button",
                              classes: ["contactus-3-iconwrapper-yTSgLr"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "vqj2tt",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-phone"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "aWjx0V",
                              type: "",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "zueZH1",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["contactus-3-text-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "02013 37 000",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "A4GZ5L",
                          type: "",
                          htmlTagName: "div",
                          classes: ["col-12", "p-2"],
                          styles: [],
                          attributes: [
                            { property: "data-sbform", value: "true" },
                            { property: "data-sbformaction", value: "" },
                            { property: "data-sbformmsg", value: "Your message has been received!" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "CyQkzJ",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "contactus-3-inputwrapper-yTSgLr"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "Lgpva1",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "contactus-3-label-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your name",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "YSpoIu",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "contactus-3-inputfield-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "name", value: "name" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                    { property: "type", value: "text" },
                                    { property: "placeholder", value: "Your name" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "gv3r8R",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "contactus-3-inputwrapper-yTSgLr"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "OXWW1X",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "contactus-3-label-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your email",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "mpdNfa",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "contactus-3-inputfield-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "type", value: "email" },
                                    { property: "name", value: "email" },
                                    { property: "placeholder", value: "Your email address" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "ICJlvj",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "contactus-3-inputwrapper-yTSgLr"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "vLegoT",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "contactus-3-label-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Subject",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "gfDcyf",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "contactus-3-inputfield-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "type", value: "text" },
                                    { property: "name", value: "subject" },
                                    { property: "placeholder", value: "Subject" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "b8SrNP",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "contactus-3-inputwrapper-yTSgLr"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "FJKmhA",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "contactus-3-label-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your message",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "CwJfZg",
                                  type: "",
                                  htmlTagName: "textarea",
                                  classes: ["form-control", "contactus-3-inputfield-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "rows", value: "3" },
                                    { property: "name", value: "message" },
                                    { property: "placeholder", value: "Message" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Gf8O9a",
                              type: "",
                              htmlTagName: "div",
                              classes: ["contactus-3-button-yTSgLrwrapper-yTSgLr"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "yuPKCX",
                                  type: "",
                                  htmlTagName: "button",
                                  classes: ["contactus-3-button-yTSgLr"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "button" },
                                    { property: "data-sbformbtn", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Send message!",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "dgLEJe",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "footer-3-component-dgLEJe"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "orNPkZ",
              type: "",
              htmlTagName: "h1",
              classes: ["footer-3-title-dgLEJe", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Restaurant Roche",
                  children: [],
                },
              ],
            },
            {
              childId: "nmbU2S",
              type: "",
              htmlTagName: "p",
              classes: ["footer-3-text1-dgLEJe", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Streetname number",
                  children: [],
                },
              ],
            },
            {
              childId: "mbrY2O",
              type: "",
              htmlTagName: "p",
              classes: ["footer-3-text2-dgLEJe", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Postal code, city",
                  children: [],
                },
              ],
            },
            {
              childId: "lhJmlp",
              type: "",
              htmlTagName: "p",
              classes: ["footer-3-text3-dgLEJe", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "© Copyright Restaurant Roche 2025. All rights reserved.",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      _id: "638cc2b5464e96eddcfa65d0",
    },
  ],
  sbCustomCss: [
    {
      componentId: "Y1i5zi",
      classes: [
        {
          className: "section.menu-3-component-Y1i5zi",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.menu-3-component-Y1i5zi", pseudo: "hover", rules: [] },
        {
          className: "menu-3-menu-textwrapper-Y1i5zi",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          ],
        },
        { className: "menu-3-menu-textwrapper-Y1i5zi", pseudo: "hover", rules: [] },
        {
          className: "menu-3-col-Y1i5ziumn-title",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 1.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "menu-3-col-Y1i5ziumn-title", pseudo: "hover", rules: [] },
        {
          className: "menu-3-line1-Y1i5zi",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "menu-3-line1-Y1i5zi", pseudo: "hover", rules: [] },
        {
          className: "menu-3-line2-Y1i5zi",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "font-style", value: "italic" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "menu-3-line2-Y1i5zi", pseudo: "hover", rules: [] },
        {
          className: "menu-3-line3-Y1i5zi",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "menu-3-line3-Y1i5zi", pseudo: "hover", rules: [] },
        { className: "menu-3-menu-imgwrapper-Y1i5zi", pseudo: "", rules: [{ property: "height", value: "160px" }] },
        {
          className: "menu-3-img-Y1i5zi",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "menu-3-img-Y1i5zi", pseudo: "hover", rules: [] },
        {
          className: "menu-3-wrapper-Y1i5zi",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "overflow", value: "hidden" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 211, 217, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "align-items", value: "center" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "menu-3-wrapper-Y1i5zi",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-color", value: "rgba(206, 211, 217, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "menu-3-col-Y1i5zi",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          ],
        },
        { className: "menu-3-col-Y1i5zi", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "eZxp5U",
      classes: [
        {
          className: "section.hero-6-component-eZxp5U",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-6-component-eZxp5U", pseudo: "hover", rules: [] },
        {
          className: "hero-6-component-eZxp5U",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-6-component-eZxp5U", pseudo: "hover", rules: [] },
        {
          className: "hero-6-wrapper-eZxp5U",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
          ],
        },
        {
          className: "hero-6-img-eZxp5U",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-6-content-eZxp5U",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "hero-6-text-eZxp5U",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "hero-6-text-eZxp5U", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "uc61Cm2MHn1euxIXuVgOHDXcm81b81",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(255, 200, 142, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 4px rgba(0, 0, 0, 0.3)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "uc61Cm2MHn1euxIXuVgOHDXcm81b81",
          pseudo: "hover",
          rules: [{ property: "text-shadow", value: "0px 0px 4px rgba(0, 0, 0, 0.3)" }],
        },
        {
          className: "hero-6-title-eZxp5U",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(244, 126, 0, 1)" },
            { property: "font-family", value: "Courgette" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 4px rgba(0, 0, 0, 0.3)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-6-title-eZxp5U", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 4px rgba(0, 0, 0, 0.3)" }] },
      ],
    },
    {
      componentId: "EfRSbs",
      classes: [
        {
          className: "section.basics-1-component-EfRSbs",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-EfRSbs", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-EfRSbs",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-EfRSbs", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-EfRSbs",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(244, 126, 0, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-EfRSbs", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "s0f5hb",
      classes: [
        {
          className: "section.basics-2-component-s0f5hb",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "1.5rem" },
          ],
        },
        { className: "section.basics-2-component-s0f5hb", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-s0f5hb",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-s0f5hb", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-s0f5hb",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-s0f5hb", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "XpUCfn",
      classes: [
        {
          className: "section.basics-7-component-XpUCfn",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-7-component-XpUCfn", pseudo: "hover", rules: [] },
        {
          className: "basics-7-col-XpUCfn",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-7-img-XpUCfn",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "basics-7-img-XpUCfn", pseudo: "hover", rules: [] },
        {
          className: "basics-7-title-XpUCfn",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(244, 126, 0, 1)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "basics-7-title-XpUCfn", pseudo: "hover", rules: [] },
        {
          className: "basics-7-text-XpUCfn",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "basics-7-text-XpUCfn", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "vx1Fc4",
      classes: [
        {
          className: "section.card-10-component-vx1Fc4",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "background", value: "rgba(254, 229, 204, 1)" },
          ],
        },
        { className: "section.card-10-component-vx1Fc4", pseudo: "hover", rules: [] },
        { className: "card-10-row-vx1Fc4", pseudo: "", rules: [] },
        {
          className: "card-10-title-vx1Fc4",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(244, 126, 0, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-10-title-vx1Fc4", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-10-col-vx1Fc4",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(214, 214, 214, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        { className: "card-10-col-vx1Fc4", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }] },
        {
          className: "card-10-img-vx1Fc4",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-img-vx1Fc4",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-10-desc-text-vx1Fc4",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "xUir4K0P8nD0dOKaC1puQnpDsBDcQ4",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0.5rem 0rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "xUir4K0P8nD0dOKaC1puQnpDsBDcQ4", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "zYeQsP",
      classes: [
        {
          className: "section.menu-2-component-zYeQsP",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.menu-2-component-zYeQsP", pseudo: "hover", rules: [] },
        {
          className: "menu-2-col-zYeQsP",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "menu-2-col-zYeQsP", pseudo: "hover", rules: [] },
        {
          className: "menu-2-menu-item-zYeQsP",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "menu-2-menu-item-zYeQsP", pseudo: "hover", rules: [] },
        {
          className: "menu-2-line1-zYeQsP",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "menu-2-line1-zYeQsP", pseudo: "hover", rules: [] },
        {
          className: "menu-2-line2-zYeQsP",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 1.5rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "font-style", value: "italic" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "menu-2-line2-zYeQsP", pseudo: "hover", rules: [] },
        {
          className: "menu-2-line3-zYeQsP",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "end" },
            { property: "align-self", value: "right" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "menu-2-line3-zYeQsP", pseudo: "hover", rules: [] },
        {
          className: "menu-2-col-zYeQsPumn-title",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(244, 126, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "menu-2-col-zYeQsPumn-title", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "lkVPNb",
      classes: [
        {
          className: "section.basics-1-component-lkVPNb",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-lkVPNb", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-lkVPNb",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-lkVPNb", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-lkVPNb",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(244, 126, 0, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-lkVPNb", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "IPlgKK",
      classes: [
        {
          className: "section.basics-2-component-IPlgKK",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-IPlgKK", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-IPlgKK",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-IPlgKK", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-IPlgKK",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-IPlgKK", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "qw8xT5",
      classes: [
        {
          className: "section.menu-2-component-qw8xT5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.menu-2-component-qw8xT5", pseudo: "hover", rules: [] },
        {
          className: "menu-2-col-qw8xT5",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "menu-2-col-qw8xT5", pseudo: "hover", rules: [] },
        {
          className: "menu-2-menu-item-qw8xT5",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "menu-2-menu-item-qw8xT5", pseudo: "hover", rules: [] },
        {
          className: "menu-2-line1-qw8xT5",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "menu-2-line1-qw8xT5", pseudo: "hover", rules: [] },
        {
          className: "menu-2-line2-qw8xT5",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 1.5rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "font-style", value: "italic" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "menu-2-line2-qw8xT5", pseudo: "hover", rules: [] },
        {
          className: "menu-2-line3-qw8xT5",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "end" },
            { property: "align-self", value: "right" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "menu-2-line3-qw8xT5", pseudo: "hover", rules: [] },
        {
          className: "menu-2-col-qw8xT5umn-title",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(244, 126, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "menu-2-col-qw8xT5umn-title", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "SzWroT",
      classes: [
        {
          className: "section.menu-2-component-SzWroT",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.menu-2-component-SzWroT", pseudo: "hover", rules: [] },
        {
          className: "menu-2-col-SzWroT",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "menu-2-col-SzWroT", pseudo: "hover", rules: [] },
        {
          className: "menu-2-menu-item-SzWroT",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "menu-2-menu-item-SzWroT", pseudo: "hover", rules: [] },
        {
          className: "menu-2-line1-SzWroT",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "menu-2-line1-SzWroT", pseudo: "hover", rules: [] },
        {
          className: "menu-2-line2-SzWroT",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 1.5rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "font-style", value: "italic" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "menu-2-line2-SzWroT", pseudo: "hover", rules: [] },
        {
          className: "menu-2-line3-SzWroT",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "end" },
            { property: "align-self", value: "right" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "menu-2-line3-SzWroT", pseudo: "hover", rules: [] },
        {
          className: "menu-2-col-SzWroTumn-title",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(244, 126, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "menu-2-col-SzWroTumn-title", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "hgk64o",
      classes: [
        {
          className: "section.contactus-3-component-hgk64o",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-3-component-hgk64o", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-col-hgk64o",
          pseudo: "",
          rules: [
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "text-align", value: "center" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        {
          className: "contactus-3-iconwrapper-hgk64o",
          pseudo: "",
          rules: [
            { property: "height", value: "65px" },
            { property: "width", value: "65px" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "cursor", value: "default !important" },
          ],
        },
        {
          className: "contactus-3-iconwrapper-hgk64o",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "contactus-3-text-hgk64o", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "contactus-3-text-hgk64o", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-googlemaps-col-hgk64o",
          pseudo: "",
          rules: [
            { property: "overflow", value: "hidden" },
            { property: "position", value: "relative" },
          ],
        },
        {
          className: "contactus-3-googlemaps-wrapper-hgk64o",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "bottom", value: "0" },
          ],
        },
        {
          className: "contactus-3-buttonwrapper-hgk64o",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-3-buttonwrapper-hgk64o", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-button-hgk64o",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-3-button-hgk64o",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "contactus-3-inputwrapper-hgk64o", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        {
          className: "contactus-3-inputfield-hgk64o",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-3-inputfield-hgk64o",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
          ],
        },
        {
          className: "contactus-3-inputfield-hgk64o",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-3-inputfield-hgk64o", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-label-hgk64o",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-3-label-hgk64o", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "dgLEJe",
      classes: [
        {
          className: "section.footer-3-component-dgLEJe",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "background", value: "rgba(32, 35, 39, 1)" },
          ],
        },
        { className: "section.footer-3-component-dgLEJe", pseudo: "hover", rules: [] },
        {
          className: "footer-3-component-dgLEJe",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "footer-3-component-dgLEJe", pseudo: "hover", rules: [] },
        {
          className: "footer-3-text1-dgLEJe",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(233, 236, 239, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-3-text1-dgLEJe", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "footer-3-text2-dgLEJe",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(233, 236, 239, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 3rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-3-text2-dgLEJe", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "footer-3-title-dgLEJe",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(244, 126, 0, 1)" },
            { property: "font-size", value: "48px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 3rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-3-title-dgLEJe", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "footer-3-text3-dgLEJe",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(233, 236, 239, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-3-text3-dgLEJe", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "GT3aFH",
      classes: [
        {
          className: "section.basics-1-component-GT3aFH",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-GT3aFH", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-GT3aFH",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-GT3aFH", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-GT3aFH",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(244, 126, 0, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-GT3aFH", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "yTSgLr",
      classes: [
        {
          className: "section.contactus-3-component-yTSgLr",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-3-component-yTSgLr", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-col-yTSgLr",
          pseudo: "",
          rules: [
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "text-align", value: "center" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        { className: "contactus-3-text-yTSgLr", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "contactus-3-text-yTSgLr", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-googlemaps-col-yTSgLr",
          pseudo: "",
          rules: [
            { property: "overflow", value: "hidden" },
            { property: "position", value: "relative" },
          ],
        },
        {
          className: "contactus-3-googlemaps-wrapper-yTSgLr",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "bottom", value: "0" },
          ],
        },
        {
          className: "contactus-3-button-yTSgLrwrapper-yTSgLr",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-3-button-yTSgLrwrapper-yTSgLr", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-label-yTSgLr",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-3-label-yTSgLr", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-button-yTSgLr",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(245, 126, 0, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(245, 126, 0, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "contactus-3-button-yTSgLr",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(245, 126, 0, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-3-inputfield-yTSgLr",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1rem" },
            { property: "outline", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(109, 118, 126, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease-in-out" },
          ],
        },
        {
          className: "contactus-3-inputfield-yTSgLr",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(109, 118, 126, 1)" },
            { property: "border-color", value: "rgba(245, 126, 0, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(245, 126, 0, 0.2)" },
          ],
        },
        {
          className: "contactus-3-inputfield-yTSgLr",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(171, 181, 191, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-3-inputfield-yTSgLr", pseudo: "hover", rules: [] },
        { className: "contactus-3-inputwrapper-yTSgLr", pseudo: "", rules: [{ property: "margin", value: "0rem 0rem 1rem 0rem" }] },
        {
          className: "contactus-3-iconwrapper-yTSgLr",
          pseudo: "",
          rules: [
            { property: "height", value: "65px" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "cursor", value: "default !important" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(245, 126, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(245, 126, 0, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "padding", value: "0.5rem 1.5rem 0.5rem 1.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        {
          className: "contactus-3-iconwrapper-yTSgLr",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(245, 126, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(245, 126, 0, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Courgette:100,300,400,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Courgette",
    fontHeadersWeight: "400",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(244, 126, 0, 1)",
    color2: "rgba(255, 200, 142, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
