export const footer4 = {
  id: "footer-4",
  name: "Footer 4",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "footer-4-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["footer-4-wrapper", "px-sm-0", "px-md-4"],
        styles: [],
        attributes: [
          { property: "data-editable", value: "true" },
          { property: "data-name", value: "footer4_wrapper" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["footer-4-line", "mx-n3", "mx-md-0"],
            styles: [],
            attributes: [
              { property: "data-editable", value: "true" },
              { property: "data-name", value: "footer4" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["row", "align-items-top", "justify-content-center", "footer-4-row"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h4",
                    classes: ["footer-4-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Phone", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["footer-4-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "123 456 7890",
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h4",
                    classes: ["footer-4-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Email", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["footer-4-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "email@site.com",
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h4",
                    classes: ["footer-4-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Follow me", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "block",
                    htmlTagName: "div",
                    classes: [],
                    styles: [],
                    attributes: [{ property: "data-name", value: "block" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "a",
                        classes: ["footer-4-social-link"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_blank" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                          { property: "data-name", value: "a" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-brands", "fa-twitter"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "a",
                        classes: ["footer-4-social-link"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_blank" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                          { property: "data-name", value: "a" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-brands", "fa-linkedin"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "a",
                        classes: ["footer-4-social-link"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_blank" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                          { property: "data-name", value: "a" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-brands", "fa-facebook-f"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["footer-4-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "© 2022 By Elisa Taylor",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.footer-4-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "3rem" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.footer-4-component", pseudo: "hover", rules: [] },
      // { className: "footer-4-component", pseudo: "", rules: [{ property: "overflow-x", value: "hidden" }] },
      { className: "footer-4-wrapper", pseudo: "", rules: [] },
      {
        className: "footer-4-line",
        pseudo: "",
        rules: [
          { property: "border-style", value: "solid none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(200, 200, 200, 1)" },
          { property: "margin", value: "0 0 2rem 0" },
        ],
      },
      {
        className: "footer-4-line",
        pseudo: "hover",
        rules: [{ property: "border-color", value: "rgba(200, 200, 200, 1)" }],
      },
      {
        className: "footer-4-title",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0.5rem 0" },
        ],
      },
      { className: "footer-4-text", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
      {
        className: "footer-4-social-link",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0.5rem 0 0" },
          { property: "color", value: "rgba(0, 0, 0, 1)" },
          { property: "text-decoration", value: "none" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "font-size", value: "1.5rem" },
        ],
      },
      { className: "footer-4-social-link", pseudo: "hover", rules: [{ property: "color", value: "rgba(60, 60, 60, 1)" }] },
      { className: "footer-4-social-link", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
    ],
  },
};
