import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Frame from "react-frame-component";

import { FixedCss } from "../../lib/css/FixedCss";
import { DevCss } from "../../lib/css/DevCss";
import { compileCssVarsObj } from "../../lib/css/CssVars";
import { parseComponentHtml, getSectionClassname, parseCss } from "../../lib/parse";
import { clickIframeElement, injectIframeJS } from "../../lib/domFunctions";
// import { onDragOver, onDragEnter, onDragLeave, onDragEnd, onDrop } from "../../lib/dragDrop";

import { dropComponent } from "../../actions/sb";
import { translate } from "../../translations/translations";

const BuilderAreaCanvas = ({ sb: { sbPages, activePageId, sbCustomCss, sbCssVars }, dropComponent }) => {
  const iframeRef = useRef();

  // When adding a new component from the menu to the list: 1: add below; 0: add above the hovered component
  const belowOrAbove = 1;
  // Keep track of how many enters & leaves on a div
  let hoverCounter = 0;

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    hoverCounter++;
    if (hoverCounter > 0) {
      e.currentTarget.classList.add("dragdrop-hover");
    }
  };

  const onDragLeave = (e) => {
    hoverCounter--;
    if (hoverCounter === 0) {
      e.currentTarget.classList.remove("dragdrop-hover");
    }
  };

  const onDrop = (e) => {
    e.preventDefault();
    // Get the position in the state.items array the new component should be placed
    let components = getSelectedPageComponents();
    let posToInsert = parseInt(components.map((component) => component.componentId).indexOf(e.currentTarget.dataset.componentid)) + belowOrAbove;
    dropComponent(posToInsert);
    // Re-load iframeJS to capture any newly inserted components
    injectIframeJS();
    // // addComponentButtons() and updateHtmlCssState() are done in useEffect to ensure new component has been rendered
    hoverCounter = 0;
    // Remove all hover classes to be sure
    [...document.getElementById("iframe").contentWindow.document.querySelectorAll(".dragdrop-hover")].forEach((child) =>
      child.classList.remove("dragdrop-hover")
    );
  };

  const getSelectedPageComponents = () => {
    try {
      return sbPages.filter((page) => page.pageId === activePageId)[0].components;
    } catch (error) {
      return [];
    }
  };

  const onIframeClick = (e) => {
    e.preventDefault();
    if (e.target !== null) {
      clickIframeElement(e.target);
    }
  };

  const iframeLoad = () => {
    injectIframeJS(iframeRef.current.contentDocument.body);
  };

  return (
    <Frame
      id="iframe"
      head={<style>{compileCssVarsObj(sbCssVars) + FixedCss + DevCss + parseCss(sbCustomCss)}</style>}
      onClick={(e) => onIframeClick(e)}
      ref={iframeRef}
      contentDidMount={iframeLoad}
    >
      {getSelectedPageComponents().length > 0 ? (
        getSelectedPageComponents().map((component, i) => (
          <section
            key={component.componentId}
            data-componentid={component.componentId}
            dangerouslySetInnerHTML={{
              __html: parseComponentHtml(component, true),
            }}
            className={getSectionClassname(component)}
            draggable="false"
            onDragOver={(e) => onDragOver(e)}
            onDragEnter={(e) => onDragEnter(e)}
            onDragLeave={(e) => onDragLeave(e)}
            // onDragEnd={(e) => onDragEnd(e)}
            onDrop={(e) => onDrop(e)}
          />
        ))
      ) : (
        <div
          key="0"
          data-componentid="0"
          draggable="false"
          className="d-flex h-100vh bg-light"
          style={{ height: "100vh", backgroundColor: "rgb(233, 236, 239)", display: "flex" }}
          onDragOver={(e) => onDragOver(e)}
          onDragEnter={(e) => onDragEnter(e)}
          onDragLeave={(e) => onDragLeave(e)}
          // onDragEnd={(e) => onDragEnd(e)}
          onDrop={(e) => onDrop(e)}
        >
          <p style={{ margin: "auto" }}>{translate("cBuilderArea.dragDropFirstComponent", false, null)}</p>
        </div>
      )}
    </Frame>
  );
};

BuilderAreaCanvas.propTypes = {
  sb: PropTypes.object.isRequired,
  dropComponent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, { dropComponent })(BuilderAreaCanvas);
