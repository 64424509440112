// Css for development
export const DevCss = `
.dragdrop-hover {
  background-color: #a8a8a8 !important;
  transition: all 300ms;
}

.dragdrop-hover {
  margin-bottom: 16px !important;
}

[data-sbtype="component"], [data-sbtype="element"], .order-first, .order-last, [data-inputfieldwrapper="true"] {
  position: relative;
}

.componentActions, .elementActions {
  position: absolute;
  opacity: 0;
  right: 3px;
  top: 0;
  transition: all 300ms;
  z-index: 500;
}

.componentActions {  
  top: 0;
}

.inputfieldActions {
  position: absolute;
  opacity: 0;
  right: 0%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  transition: all 300ms;
  z-index: 500;
}

.elementActions {  
  top: 24px;
}

[data-sbtype="component"]:hover .componentActions, [data-sbtype="element"]:hover>.elementActions, .order-first:hover>.elementActions, .order-last:hover>.elementActions, [data-inputfieldwrapper="true"]:hover .inputfieldActions {
  opacity: 1;
}

.componentActions span:hover, .elementActions span:hover, .inputfieldActions span:hover {
  cursor: pointer;
}

.componentActions>.moveup,
.componentActions>.movedown,
.componentActions>.edit,
.elementActions>.edit,
.elementActions>.duplicate,
.elementActions>.reorder,
.inputfieldActions>.moveup,
.inputfieldActions>.movedown,
.inputfieldActions>.duplicate {
  color: #28a745;
  margin-right: 0.5rem;
  display: inline;
}

.componentActions>.delete, .elementActions>.delete, .inputfieldActions>.delete {
  color: #dc3545;
  display: inline;
}

[data-texteditable="true"] {
  border: 1px solid transparent;
}

[data-texteditable="true"]:hover {
  border-color: #007bff !important;
}

[data-aos] {
  pointer-events: auto !important;
}`;
