export const sectiondividers39 = {
  id: "sectiondividers-39",
  name: "Section dividers 39",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: [],
        styles: [
          { property: "position", value: "relative" },
          { property: "height", value: "310px" },
        ],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "svg",
            classes: [],
            styles: [
              { property: "position", value: "absolute" },
              { property: "top", value: "0" },
              { property: "left", value: "0" },
              { property: "width", value: "100%" },
              { property: "height", value: "310px" },
              { property: "transform", value: "scaleY(-1)" },
              { property: "background", value: "rgba(255, 255, 255, 1)" },
            ],
            attributes: [
              { property: "xmlns", value: "http://www.w3.org/2000/svg" },
              { property: "viewBox", value: "0 0 1155 188" },
              { property: "preserveAspectRatio", value: "xMidYMin slice" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M8.61 134.59A8.62 8.62 0 0 0 0 143.2V188h17.21v-44.8a8.61 8.61 0 0 0-8.6-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M8.61 77.92A8.62 8.62 0 0 0 0 86.53v28.33a8.61 8.61 0 1 0 17.21 0V86.53a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M8.61 42.5A8.62 8.62 0 0 0 0 51.11v7.08a8.61 8.61 0 1 0 17.21 0v-7.08a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M27.89 120.42a8.62 8.62 0 0 0-8.61 8.58v59H36.5v-59a8.62 8.62 0 0 0-8.61-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M27.89 35.42A8.61 8.61 0 0 0 19.28 44v56.67a8.61 8.61 0 1 0 17.22 0V44a8.61 8.61 0 0 0-8.61-8.58z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M47.17 92.09a8.61 8.61 0 0 0-8.6 8.6V188h17.21v-87.31a8.61 8.61 0 0 0-8.61-8.6z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M47.17 35.42a8.61 8.61 0 0 0-8.6 8.6v28.34a8.61 8.61 0 1 0 17.21 0V44a8.61 8.61 0 0 0-8.61-8.58z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M47.17 0a8.61 8.61 0 0 0-8.6 8.61v7.08a8.61 8.61 0 1 0 17.21 0V8.61A8.62 8.62 0 0 0 47.17 0z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M66.46 120.42a8.62 8.62 0 0 0-8.61 8.58v59h17.21v-59a8.61 8.61 0 0 0-8.6-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M66.46 77.92a8.62 8.62 0 0 0-8.61 8.61v14.16a8.61 8.61 0 1 0 17.21 0V86.53a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M85.74 134.59a8.62 8.62 0 0 0-8.61 8.61V188h17.22v-44.8a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M85.74 92.09a8.62 8.62 0 0 0-8.61 8.6v14.17a8.61 8.61 0 0 0 17.22 0v-14.17a8.61 8.61 0 0 0-8.61-8.6z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M93.69 69.08a8 8 0 0 0-.79-1.5 9 9 0 0 0-1.07-1.31 9.7 9.7 0 0 0-1.31-1.07 9.59 9.59 0 0 0-1.52-.8 8 8 0 0 0-1.58-.47 8.4 8.4 0 0 0-3.35 0 8.89 8.89 0 0 0-1.67.5 10 10 0 0 0-1.4.77 9 9 0 0 0-1.3 1.07 8.39 8.39 0 0 0-1.07 1.31 8.12 8.12 0 0 0-.82 1.42 8.38 8.38 0 0 0-.51 1.66 8.21 8.21 0 0 0-.17 1.66 8.63 8.63 0 0 0 2.53 6.08 9.57 9.57 0 0 0 1.25 1h.06a8.1 8.1 0 0 0 1.44.77 7.79 7.79 0 0 0 1.61.5 8.12 8.12 0 0 0 1.73.18 7.45 7.45 0 0 0 1.64-.17 7.29 7.29 0 0 0 1.61-.37 8.67 8.67 0 0 0 1.56-.82 10.46 10.46 0 0 0 1.26-1 8.68 8.68 0 0 0 2.52-6.08 9.51 9.51 0 0 0-.17-1.73 9.1 9.1 0 0 0-.48-1.6z",
                  },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M105 134.59a8.62 8.62 0 0 0-8.61 8.61V188h17.21v-44.8a8.61 8.61 0 0 0-8.6-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M105 77.92a8.62 8.62 0 0 0-8.61 8.61v28.33a8.61 8.61 0 1 0 17.21 0V86.53a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M105 42.5a8.62 8.62 0 0 0-8.61 8.61v7.08a8.61 8.61 0 1 0 17.21 0v-7.08a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M124.31 106.25a8.62 8.62 0 0 0-8.61 8.61V188h17.22v-73.14a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M124.31 35.42A8.61 8.61 0 0 0 115.7 44v42.53a8.61 8.61 0 0 0 17.22 0V44a8.61 8.61 0 0 0-8.61-8.58z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M143.59 35.42A8.61 8.61 0 0 0 135 44v144h17.2V44a8.61 8.61 0 0 0-8.61-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M143.59 0A8.61 8.61 0 0 0 135 8.61v7.08a8.61 8.61 0 1 0 17.21 0V8.61A8.62 8.62 0 0 0 143.59 0z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M162.88 106.25a8.62 8.62 0 0 0-8.61 8.61V188h17.21v-73.14a8.62 8.62 0 0 0-8.6-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M162.87 95.14a9.7 9.7 0 0 0 1.74-.18 8.38 8.38 0 0 0 1.53-.48 7.86 7.86 0 0 0 1.51-.8 7.72 7.72 0 0 0 1.35-1.07 8.65 8.65 0 0 0 2.52-6.08 8.81 8.81 0 0 0-.17-1.73 8.61 8.61 0 0 0-.5-1.61 8.83 8.83 0 0 0-.77-1.44 8.1 8.1 0 0 0-1.08-1.31 8.64 8.64 0 0 0-7.73-2.35 9.19 9.19 0 0 0-1.68.5 8.76 8.76 0 0 0-1.41.76 8.57 8.57 0 0 0-2.41 2.39 8.58 8.58 0 0 0-.79 1.48 7.4 7.4 0 0 0-.49 1.58 8.12 8.12 0 0 0-.18 1.73 8.64 8.64 0 0 0 8.6 8.61z",
                  },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M182.16 148.76a8.62 8.62 0 0 0-8.61 8.6V188h17.22v-30.64a8.61 8.61 0 0 0-8.61-8.6z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M182.16 77.92a8.62 8.62 0 0 0-8.61 8.61V129a8.61 8.61 0 0 0 17.22 0V86.53a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M182.16 42.5a8.62 8.62 0 0 0-8.61 8.61v7.08a8.61 8.61 0 0 0 17.22 0v-7.08a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M201.45 134.59a8.62 8.62 0 0 0-8.61 8.61V188h17.21v-44.8a8.61 8.61 0 0 0-8.6-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M201.45 92.09a8.61 8.61 0 0 0-8.61 8.6v14.17a8.61 8.61 0 1 0 17.21 0v-14.17a8.61 8.61 0 0 0-8.6-8.6z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M201.45 56.67a8.62 8.62 0 0 0-8.61 8.61v7.08a8.61 8.61 0 1 0 17.21 0v-7.08a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M220.73 120.42a8.62 8.62 0 0 0-8.61 8.61v59h17.22V129a8.62 8.62 0 0 0-8.61-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M220.73 35.42a8.61 8.61 0 0 0-8.61 8.6v56.67a8.61 8.61 0 0 0 17.22 0V44a8.61 8.61 0 0 0-8.61-8.58z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M240 85a8.61 8.61 0 0 0-8.6 8.61V188h17.21V93.61A8.62 8.62 0 0 0 240 85z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M240 28.33a8.62 8.62 0 0 0-8.6 8.61v28.34a8.61 8.61 0 0 0 17.21 0V36.94a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M232.05 11.88a9 9 0 0 0 .75 1.42 7.61 7.61 0 0 0 1.11 1.34 8.38 8.38 0 0 0 1.3 1.08 7.75 7.75 0 0 0 1.45.76 7.41 7.41 0 0 0 1.68.52 9.14 9.14 0 0 0 1.64.17 9 9 0 0 0 1.66-.17 7.82 7.82 0 0 0 1.63-.5 8 8 0 0 0 1.55-.82 10.26 10.26 0 0 0 1.26-1 8.7 8.7 0 0 0 2.51-6.08 7.63 7.63 0 0 0-.14-1.6 7.9 7.9 0 0 0-.45-1.69 8 8 0 0 0-.79-1.48 8.55 8.55 0 0 0-1.06-1.31 8.22 8.22 0 0 0-1.32-1.07 8 8 0 0 0-1.52-.8 8.65 8.65 0 0 0-1.61-.48 8.84 8.84 0 0 0-5 .5 9.49 9.49 0 0 0-1.4.75 8.7 8.7 0 0 0-2.43 2.41 8.63 8.63 0 0 0-1.26 3.05 8.44 8.44 0 0 0 0 3.37 8.81 8.81 0 0 0 .44 1.63z",
                  },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M259.3 120.42a8.62 8.62 0 0 0-8.61 8.61v59h17.21V129a8.61 8.61 0 0 0-8.6-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M259.3 77.92a8.62 8.62 0 0 0-8.61 8.61v14.16a8.61 8.61 0 1 0 17.21 0V86.53a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M278.58 134.59a8.62 8.62 0 0 0-8.58 8.61V188h17.22v-44.8a8.62 8.62 0 0 0-8.64-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M278.58 77.92a8.62 8.62 0 0 0-8.58 8.61v28.33a8.61 8.61 0 0 0 17.22 0V86.53a8.62 8.62 0 0 0-8.64-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M278.58 42.5a8.62 8.62 0 0 0-8.58 8.61v7.08a8.61 8.61 0 0 0 17.22 0v-7.08a8.62 8.62 0 0 0-8.64-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M297.87 162.92a8.62 8.62 0 0 0-8.61 8.61V188h17.21v-16.47a8.62 8.62 0 0 0-8.6-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M297.87 106.25a8.62 8.62 0 0 0-8.61 8.61v28.34a8.61 8.61 0 0 0 17.21 0v-28.34a8.62 8.62 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M297.87 70.84a8.61 8.61 0 0 0-8.61 8.6v7.09a8.61 8.61 0 0 0 17.21 0v-7.09a8.61 8.61 0 0 0-8.6-8.6z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M317.15 120.42a8.62 8.62 0 0 0-8.61 8.61v59h17.22V129a8.62 8.62 0 0 0-8.61-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M317.15 63.75a8.62 8.62 0 0 0-8.61 8.61v28.33a8.61 8.61 0 0 0 17.22 0V72.36a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M336.43 63.75a8.61 8.61 0 0 0-8.6 8.61V188H345V72.36a8.62 8.62 0 0 0-8.57-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M336.43 29.75a8.61 8.61 0 0 0-8.6 8.61V44A8.61 8.61 0 1 0 345 44v-5.64a8.62 8.62 0 0 0-8.57-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M336.43 0a8.61 8.61 0 0 0-8.6 8.61v2.83a8.61 8.61 0 1 0 17.21 0V8.61A8.62 8.62 0 0 0 336.43 0z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M355.72 120.42a8.62 8.62 0 0 0-8.61 8.61v59h17.21V129a8.61 8.61 0 0 0-8.6-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M355.72 77.92a8.62 8.62 0 0 0-8.61 8.61v14.16a8.61 8.61 0 1 0 17.21 0V86.53a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M375 106.25a8.62 8.62 0 0 0-8.6 8.61V188h17.21v-73.14a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M375 49.58a8.62 8.62 0 0 0-8.6 8.61v28.34a8.61 8.61 0 0 0 17.21 0V58.19a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M375 14.17a8.61 8.61 0 0 0-8.6 8.6v7.09a8.61 8.61 0 0 0 17.21 0v-7.09a8.61 8.61 0 0 0-8.61-8.6z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M394.31 134.59a8.62 8.62 0 0 0-8.61 8.61V188h17.21v-44.8a8.61 8.61 0 0 0-8.6-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M394.31 77.92a8.62 8.62 0 0 0-8.61 8.61v28.33a8.61 8.61 0 1 0 17.21 0V86.53a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M394.31 42.5a8.62 8.62 0 0 0-8.61 8.61v7.08a8.61 8.61 0 1 0 17.21 0v-7.08a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M413.59 120.42A8.62 8.62 0 0 0 405 129v59h17.2v-59a8.62 8.62 0 0 0-8.61-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M413.59 35.42A8.61 8.61 0 0 0 405 44v56.67a8.61 8.61 0 0 0 17.22 0V44a8.62 8.62 0 0 0-8.63-8.58z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M432.87 92.09a8.61 8.61 0 0 0-8.6 8.6V188h17.21v-87.31a8.61 8.61 0 0 0-8.61-8.6z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M432.87 35.42a8.61 8.61 0 0 0-8.6 8.6v28.34a8.61 8.61 0 1 0 17.21 0V44a8.61 8.61 0 0 0-8.61-8.58z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M432.87 0a8.61 8.61 0 0 0-8.6 8.61v7.08a8.61 8.61 0 1 0 17.21 0V8.61A8.62 8.62 0 0 0 432.87 0z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M452.16 120.42a8.62 8.62 0 0 0-8.61 8.61v59h17.21V129a8.61 8.61 0 0 0-8.6-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M452.16 77.92a8.62 8.62 0 0 0-8.61 8.61v14.16a8.61 8.61 0 1 0 17.21 0V86.53a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M471.44 134.59a8.62 8.62 0 0 0-8.61 8.61V188h17.22v-44.8a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M471.44 92.09a8.61 8.61 0 0 0-8.61 8.6v14.17a8.61 8.61 0 1 0 17.22 0v-14.17a8.61 8.61 0 0 0-8.61-8.6z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M479.39 69.08a8.66 8.66 0 0 0-.79-1.5 8.43 8.43 0 0 0-2.38-2.38 9.59 9.59 0 0 0-1.51-.8 8 8 0 0 0-1.58-.47 8.4 8.4 0 0 0-3.35 0 8.89 8.89 0 0 0-1.67.5 10 10 0 0 0-1.45.78 8.43 8.43 0 0 0-2.38 2.38 8 8 0 0 0-.77 1.46 8.59 8.59 0 0 0-.51 1.65 9 9 0 0 0-.16 1.66 8.65 8.65 0 0 0 2.53 6.08 9.57 9.57 0 0 0 1.25 1h.06a8.75 8.75 0 0 0 3.05 1.27 8.12 8.12 0 0 0 1.73.18 7.45 7.45 0 0 0 1.64-.17 7.29 7.29 0 0 0 1.62-.49 8.67 8.67 0 0 0 1.56-.82 10.46 10.46 0 0 0 1.26-1 8.56 8.56 0 0 0 2.34-7.81 8.29 8.29 0 0 0-.49-1.52z",
                  },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M490.72 134.59a8.61 8.61 0 0 0-8.6 8.61V188h17.21v-44.8a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M490.72 77.92a8.61 8.61 0 0 0-8.6 8.61v28.33a8.61 8.61 0 1 0 17.21 0V86.53a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M490.72 42.5a8.61 8.61 0 0 0-8.6 8.61v7.08a8.61 8.61 0 1 0 17.21 0v-7.08a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M510 106.25a8.62 8.62 0 0 0-8.61 8.61V188h17.21v-73.14a8.62 8.62 0 0 0-8.6-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M510 35.42a8.61 8.61 0 0 0-8.6 8.58v42.53a8.61 8.61 0 0 0 17.21 0V44a8.61 8.61 0 0 0-8.61-8.58z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M529.29 35.42a8.61 8.61 0 0 0-8.6 8.6V188h17.21V44a8.61 8.61 0 0 0-8.61-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M529.29 0a8.61 8.61 0 0 0-8.6 8.61v7.08a8.61 8.61 0 1 0 17.21 0V8.61A8.62 8.62 0 0 0 529.29 0z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M548.58 106.25a8.62 8.62 0 0 0-8.61 8.61V188h17.21v-73.14a8.62 8.62 0 0 0-8.6-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M548.57 95.14a9.7 9.7 0 0 0 1.74-.18 8.64 8.64 0 0 0 1.53-.48 7.86 7.86 0 0 0 1.51-.8 8.14 8.14 0 0 0 1.31-1.07 8.58 8.58 0 0 0 1.85-9.42 10.2 10.2 0 0 0-.77-1.44 8.1 8.1 0 0 0-1.08-1.31 8.65 8.65 0 0 0-7.73-2.35 9 9 0 0 0-1.68.5 8.76 8.76 0 0 0-1.41.76 7.59 7.59 0 0 0-1.35 1.09 8.35 8.35 0 0 0-1.06 1.3 8.58 8.58 0 0 0-.79 1.48 7.4 7.4 0 0 0-.49 1.58 8 8 0 0 0-.18 1.73 8.62 8.62 0 0 0 8.6 8.61z",
                  },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M567.86 148.76a8.61 8.61 0 0 0-8.61 8.6V188h17.22v-30.64a8.61 8.61 0 0 0-8.61-8.6z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M567.86 77.92a8.62 8.62 0 0 0-8.61 8.61V129a8.61 8.61 0 0 0 17.22 0V86.53a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M567.86 42.5a8.62 8.62 0 0 0-8.61 8.61v7.08a8.61 8.61 0 1 0 17.22 0v-7.08a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M587.14 134.59a8.61 8.61 0 0 0-8.6 8.61V188h17.21v-44.8a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M587.14 92.09a8.61 8.61 0 0 0-8.6 8.6v14.17a8.61 8.61 0 1 0 17.21 0v-14.17a8.61 8.61 0 0 0-8.61-8.6z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M587.14 56.67a8.61 8.61 0 0 0-8.6 8.61v7.08a8.61 8.61 0 1 0 17.21 0v-7.08a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M606.43 120.42a8.62 8.62 0 0 0-8.61 8.61v59H615V129a8.61 8.61 0 0 0-8.57-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M606.43 35.42a8.61 8.61 0 0 0-8.61 8.6v56.67a8.61 8.61 0 1 0 17.21 0V44a8.61 8.61 0 0 0-8.6-8.58z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M625.71 85a8.61 8.61 0 0 0-8.6 8.61V188h17.21V93.61a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M625.71 28.33a8.62 8.62 0 0 0-8.6 8.61v28.34a8.61 8.61 0 0 0 17.21 0V36.94a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M617.75 11.87v.06a9 9 0 0 0 .76 1.42 7.61 7.61 0 0 0 1.11 1.34 8 8 0 0 0 2.74 1.84 7.65 7.65 0 0 0 1.69.52 8.09 8.09 0 0 0 3.3 0 7.56 7.56 0 0 0 1.62-.5 7.79 7.79 0 0 0 1.56-.82 9.44 9.44 0 0 0 1.25-1 8.67 8.67 0 0 0 2.52-6.08 7.63 7.63 0 0 0-.15-1.65 8.08 8.08 0 0 0-.49-1.64 8.63 8.63 0 0 0-.79-1.48 8.55 8.55 0 0 0-1.06-1.31 8.69 8.69 0 0 0-1.32-1.07A8 8 0 0 0 629 .65a8.65 8.65 0 0 0-1.6-.48 8.84 8.84 0 0 0-5 .5 8.63 8.63 0 0 0-1.4.76 7.45 7.45 0 0 0-1.31 1.05 7.88 7.88 0 0 0-1.12 1.35 8.66 8.66 0 0 0-.8 1.52 7.78 7.78 0 0 0-.46 1.53 8 8 0 0 0 0 3.37 8.72 8.72 0 0 0 .44 1.62z",
                  },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M645 120.42a8.62 8.62 0 0 0-8.61 8.61v59h17.21V129a8.61 8.61 0 0 0-8.6-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M645 77.92a8.62 8.62 0 0 0-8.61 8.61v14.16a8.61 8.61 0 1 0 17.21 0V86.53a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M664.28 134.59a8.62 8.62 0 0 0-8.61 8.61V188h17.22v-44.8a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M664.28 77.92a8.62 8.62 0 0 0-8.61 8.61v28.33a8.61 8.61 0 0 0 17.22 0V86.53a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M664.28 42.5a8.62 8.62 0 0 0-8.61 8.61v7.08a8.61 8.61 0 0 0 17.22 0v-7.08a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M683.56 162.92a8.62 8.62 0 0 0-8.6 8.61V188h17.21v-16.47a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M683.56 106.25a8.62 8.62 0 0 0-8.6 8.61v28.34a8.61 8.61 0 0 0 17.21 0v-28.34a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M683.56 70.84a8.61 8.61 0 0 0-8.6 8.6v7.09a8.61 8.61 0 0 0 17.21 0v-7.09a8.61 8.61 0 0 0-8.61-8.6z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M702.85 120.42a8.62 8.62 0 0 0-8.61 8.61v59h17.21V129a8.61 8.61 0 0 0-8.6-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M702.85 63.75a8.62 8.62 0 0 0-8.61 8.61v28.33a8.61 8.61 0 1 0 17.21 0V72.36a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M722.13 63.75a8.61 8.61 0 0 0-8.6 8.61V188h17.21V72.36a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M722.13 29.75a8.61 8.61 0 0 0-8.6 8.61V44a8.61 8.61 0 1 0 17.21 0v-5.64a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M722.13 0a8.61 8.61 0 0 0-8.6 8.61v2.83a8.61 8.61 0 1 0 17.21 0V8.61A8.62 8.62 0 0 0 722.13 0z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M741.42 120.42a8.62 8.62 0 0 0-8.61 8.61v59H750V129a8.61 8.61 0 0 0-8.58-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M741.42 77.92a8.62 8.62 0 0 0-8.61 8.61v14.16a8.61 8.61 0 1 0 17.21 0V86.53a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M760.7 106.25a8.62 8.62 0 0 0-8.61 8.61V188h17.22v-73.14a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M760.7 49.58a8.62 8.62 0 0 0-8.61 8.61v28.34a8.61 8.61 0 0 0 17.22 0V58.19a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M760.7 14.17a8.61 8.61 0 0 0-8.61 8.6v7.09a8.61 8.61 0 0 0 17.22 0v-7.09a8.61 8.61 0 0 0-8.61-8.6z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M780 134.59a8.61 8.61 0 0 0-8.6 8.61V188h17.21v-44.8a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M780 77.92a8.61 8.61 0 0 0-8.6 8.61v28.33a8.61 8.61 0 1 0 17.21 0V86.53a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M780 42.5a8.61 8.61 0 0 0-8.6 8.61v7.08a8.61 8.61 0 1 0 17.21 0v-7.08A8.62 8.62 0 0 0 780 42.5z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M799.29 120.42a8.62 8.62 0 0 0-8.61 8.61v59h17.21V129a8.61 8.61 0 0 0-8.6-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M799.29 35.42a8.61 8.61 0 0 0-8.61 8.6v56.67a8.61 8.61 0 1 0 17.21 0V44a8.61 8.61 0 0 0-8.6-8.58z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M818.57 92.09a8.61 8.61 0 0 0-8.61 8.6V188h17.22v-87.31a8.62 8.62 0 0 0-8.61-8.6z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M818.57 35.42A8.61 8.61 0 0 0 810 44v28.36a8.61 8.61 0 1 0 17.22 0V44a8.62 8.62 0 0 0-8.65-8.58z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M818.57 0A8.62 8.62 0 0 0 810 8.61v7.08a8.61 8.61 0 1 0 17.22 0V8.61A8.62 8.62 0 0 0 818.57 0z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M837.85 120.42a8.61 8.61 0 0 0-8.6 8.61v59h17.21V129a8.62 8.62 0 0 0-8.61-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M837.85 77.92a8.61 8.61 0 0 0-8.6 8.61v14.16a8.61 8.61 0 1 0 17.21 0V86.53a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M857.14 134.59a8.62 8.62 0 0 0-8.61 8.61V188h17.21v-44.8a8.61 8.61 0 0 0-8.6-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M857.14 92.09a8.61 8.61 0 0 0-8.61 8.6v14.17a8.61 8.61 0 1 0 17.21 0v-14.17a8.61 8.61 0 0 0-8.6-8.6z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M865.09 69.08a8.09 8.09 0 0 0-.8-1.5 8.39 8.39 0 0 0-1.07-1.31 8.6 8.6 0 0 0-1.31-1.07 9 9 0 0 0-1.5-.8 8.1 8.1 0 0 0-1.59-.47 8.35 8.35 0 0 0-3.34 0 8.67 8.67 0 0 0-1.67.5 10 10 0 0 0-1.45.78 8.6 8.6 0 0 0-1.31 1.07 8.39 8.39 0 0 0-1.05 1.3 7.71 7.71 0 0 0-.77 1.46 8.59 8.59 0 0 0-.52 1.66 9 9 0 0 0-.16 1.66 8.67 8.67 0 0 0 2.52 6.08 9.07 9.07 0 0 0 1.26 1h.06a8.64 8.64 0 0 0 1.43.76 7.87 7.87 0 0 0 1.62.5 8 8 0 0 0 1.72.18 7.54 7.54 0 0 0 1.65-.17 7.45 7.45 0 0 0 1.62-.49 8.67 8.67 0 0 0 1.56-.82 10.33 10.33 0 0 0 1.25-1 8.65 8.65 0 0 0 2.53-6.08 8.56 8.56 0 0 0-.66-3.28z",
                  },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M876.42 134.59a8.62 8.62 0 0 0-8.61 8.61V188H885v-44.8a8.62 8.62 0 0 0-8.58-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M876.42 77.92a8.62 8.62 0 0 0-8.61 8.61v28.33a8.61 8.61 0 1 0 17.22 0V86.53a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M876.42 42.5a8.62 8.62 0 0 0-8.61 8.61v7.08a8.61 8.61 0 1 0 17.22 0v-7.08a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M895.71 106.25a8.62 8.62 0 0 0-8.61 8.61V188h17.21v-73.14a8.62 8.62 0 0 0-8.6-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M895.71 35.42A8.61 8.61 0 0 0 887.1 44v42.53a8.61 8.61 0 0 0 17.21 0V44a8.61 8.61 0 0 0-8.6-8.58z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M915 35.42a8.61 8.61 0 0 0-8.61 8.6V188h17.21V44a8.62 8.62 0 0 0-8.6-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M915 0a8.62 8.62 0 0 0-8.61 8.61v7.08a8.61 8.61 0 0 0 17.22 0V8.61A8.62 8.62 0 0 0 915 0z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M934.27 106.25a8.62 8.62 0 0 0-8.6 8.61V188h17.21v-73.14a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M934.27 95.14A9.68 9.68 0 0 0 936 95a8.76 8.76 0 0 0 1.54-.48 7.86 7.86 0 0 0 1.51-.8 8.14 8.14 0 0 0 1.31-1.07 8.65 8.65 0 0 0 2.52-6.08 8.44 8.44 0 0 0-.67-3.34 9.29 9.29 0 0 0-.78-1.44 7.55 7.55 0 0 0-1.07-1.31 8.65 8.65 0 0 0-7.74-2.35 9.11 9.11 0 0 0-1.67.5 8.35 8.35 0 0 0-1.41.76 7.59 7.59 0 0 0-1.35 1.09 7.73 7.73 0 0 0-1.06 1.3 8 8 0 0 0-.8 1.48 7.67 7.67 0 0 0-.48 1.58 7.46 7.46 0 0 0-.19 1.73 8.64 8.64 0 0 0 8.61 8.61z",
                  },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M953.56 148.76a8.61 8.61 0 0 0-8.61 8.6V188h17.21v-30.64a8.61 8.61 0 0 0-8.6-8.6z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M953.56 77.92a8.62 8.62 0 0 0-8.56 8.61V129a8.61 8.61 0 0 0 17.21 0V86.53a8.61 8.61 0 0 0-8.65-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M953.56 42.5a8.62 8.62 0 0 0-8.56 8.61v7.08a8.61 8.61 0 1 0 17.21 0v-7.08a8.61 8.61 0 0 0-8.65-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M972.84 134.59a8.62 8.62 0 0 0-8.61 8.61V188h17.22v-44.8a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M972.84 92.09a8.62 8.62 0 0 0-8.61 8.6v14.17a8.61 8.61 0 1 0 17.22 0v-14.17a8.61 8.61 0 0 0-8.61-8.6z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M972.84 56.67a8.62 8.62 0 0 0-8.61 8.61v7.08a8.61 8.61 0 1 0 17.22 0v-7.08a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M992.13 120.42a8.62 8.62 0 0 0-8.61 8.61v59h17.21V129a8.61 8.61 0 0 0-8.6-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M992.13 35.42a8.61 8.61 0 0 0-8.61 8.6v56.67a8.61 8.61 0 1 0 17.21 0V44a8.61 8.61 0 0 0-8.6-8.58z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1011.41 85a8.62 8.62 0 0 0-8.61 8.61V188h17.2V93.61a8.62 8.62 0 0 0-8.59-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1011.41 28.33a8.62 8.62 0 0 0-8.61 8.61v28.34a8.61 8.61 0 0 0 17.22 0V36.94a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M1003.45 11.87v.06a8.21 8.21 0 0 0 .76 1.42 7.56 7.56 0 0 0 1.1 1.34 8.87 8.87 0 0 0 1.3 1.08 8.42 8.42 0 0 0 1.45.76 7.49 7.49 0 0 0 1.69.52 8.09 8.09 0 0 0 3.3 0 7.56 7.56 0 0 0 1.62-.5 7.46 7.46 0 0 0 1.55-.82 9 9 0 0 0 1.26-1 8.67 8.67 0 0 0 2.52-6.12 8.31 8.31 0 0 0-.17-1.66 8.16 8.16 0 0 0-.5-1.64 8 8 0 0 0-.78-1.48 8.43 8.43 0 0 0-2.38-2.38 8.32 8.32 0 0 0-1.52-.8 8.45 8.45 0 0 0-4.92-.48 8.5 8.5 0 0 0-1.67.5 9.21 9.21 0 0 0-1.41.76 7.76 7.76 0 0 0-1.3 1.05 7.45 7.45 0 0 0-1.12 1.35 9.4 9.4 0 0 0-.81 1.52 8.29 8.29 0 0 0-.46 1.53 8.12 8.12 0 0 0-.18 1.73 7.45 7.45 0 0 0 .17 1.64 8.5 8.5 0 0 0 .5 1.62z",
                  },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1030.69 120.42a8.61 8.61 0 0 0-8.6 8.61v59h17.21V129a8.62 8.62 0 0 0-8.61-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1030.69 77.92a8.61 8.61 0 0 0-8.6 8.61v14.16a8.61 8.61 0 1 0 17.21 0V86.53a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1050 134.59a8.62 8.62 0 0 0-8.61 8.61V188h17.21v-44.8a8.61 8.61 0 0 0-8.6-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1050 77.92a8.62 8.62 0 0 0-8.61 8.61v28.33a8.61 8.61 0 1 0 17.21 0V86.53a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1050 42.5a8.62 8.62 0 0 0-8.61 8.61v7.08a8.61 8.61 0 1 0 17.21 0v-7.08a8.61 8.61 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1069.26 162.92a8.63 8.63 0 0 0-8.61 8.61V188h17.22v-16.47a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value: "M1069.26 106.25a8.63 8.63 0 0 0-8.61 8.61v28.34a8.61 8.61 0 0 0 17.22 0v-28.34a8.62 8.62 0 0 0-8.61-8.61z",
                  },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1069.26 70.84a8.62 8.62 0 0 0-8.61 8.6v7.09a8.61 8.61 0 0 0 17.22 0v-7.09a8.61 8.61 0 0 0-8.61-8.6z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1088.54 120.42a8.61 8.61 0 0 0-8.6 8.61v59h17.21V129a8.62 8.62 0 0 0-8.61-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1088.54 63.75a8.61 8.61 0 0 0-8.6 8.61v28.33a8.61 8.61 0 1 0 17.21 0V72.36a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1107.83 63.75a8.62 8.62 0 0 0-8.61 8.61V188h17.22V72.36a8.62 8.62 0 0 0-8.61-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1107.83 29.75a8.62 8.62 0 0 0-8.61 8.61V44a8.61 8.61 0 0 0 17.22 0v-5.64a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1107.83 0a8.62 8.62 0 0 0-8.61 8.61v2.83a8.61 8.61 0 0 0 17.22 0V8.61a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1127.11 120.42a8.61 8.61 0 0 0-8.6 8.61v59h17.21V129a8.62 8.62 0 0 0-8.61-8.58z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1127.11 77.92a8.61 8.61 0 0 0-8.6 8.61v14.16a8.61 8.61 0 1 0 17.21 0V86.53a8.62 8.62 0 0 0-8.61-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1146.4 106.25a8.62 8.62 0 0 0-8.61 8.61V188H1155v-73.14a8.62 8.62 0 0 0-8.6-8.61z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1146.4 49.58a8.62 8.62 0 0 0-8.61 8.61v28.34a8.61 8.61 0 0 0 17.21 0V58.19a8.62 8.62 0 0 0-8.6-8.61z" },
                  { property: "opacity", value: "0.4" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1146.4 14.17a8.61 8.61 0 0 0-8.61 8.6v7.09a8.61 8.61 0 0 0 17.21 0v-7.09a8.61 8.61 0 0 0-8.6-8.6z" },
                  { property: "opacity", value: "0.2" },
                ],
                content: "",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
