import axios from "axios";
// Bring in action types
import {
  // Manage project
  LOAD_PROJECT,
  LOAD_TEMPLATE,
  UPDATE_PROJECT_GALLERY,
  UPDATE_GALLERY_FILE_DESC,
  AUTH_ERROR,
  UPDATE_SITEMETA,
  CLEAN_CUSTOM_CSS,
  // Product tour
  OPEN_PRODUCT_TOUR,
  CLOSE_PRODUCT_TOUR,
  // Drag & drop
  START_DRAG_FROM_MENU,
  END_DRAG_FROM_MENU,
  DROP_COMPONENT,
  // Edit component
  REORDER_COMPONENT,
  DELETE_COMPONENT,
  SET_SELECTED_ELEMENT,
  // Manage elements
  DELETE_ELEMENT,
  DUPLICATE_ELEMENT,
  REORDER_COL,
  // Input fields
  REORDER_INPUTFIELD,
  TOGGLE_CHECKBOX,
  // Manage pages
  ADD_NEW_PAGE,
  ADD_NEW_VERSION,
  SET_ACTIVE_PAGE,
  EDIT_PAGE_INFO,
  EDIT_PAGE_SOCIAL_PREVIEW,
  DELETE_PAGE,
  COPY_COMPONENT_TO_OTHER_PAGE,
  // Track changes
  SAVE_TRACK_CHANGES,
  UNDO_REDO,
  // Edit styles
  START_EDITING,
  OPEN_COLOR_PICKER,
  UPDATE_CSS_VAR,
  CONFIRM_COLOR_SCHEME,
  CHANGE_SITE_COLORS,
  UPDATE_COMPONENT_CSS,
  UPDATE_COMPONENT_CLASSES,
  UPDATE_COMPONENT_ATTRIBUTES,
  UPDATE_COMPONENT_HTMLTAGNAME,
  UPDATE_EXISTING_TEXT_ELEMENT,
  ADD_NEW_TEXT_ELEMENT,
  UPDATE_LIST_MARKER2,
  SELECT_IMG_FROM_GALLERY,
  UPDATE_CUSTOMS_ICON_EDITOR,
  UPDATE_CUSTOMS_ROW_ALIGN_JUSTIFY,
  UPDATE_CUSTOMS_ROW_LAYOUT,
  UPDATE_CUSTOMS_ELEMENT_WIDTH,
  UPDATE_CUSTOMS_HERO1_OVERLAY,
  UPDATE_CUSTOMS_CARD3_DESC_BGCOLOR,
  UPDATE_CUSTOMS_CARD3_DESC_BGCOLORHOVER,
  UPDATE_CUSTOMS_CARD4_POPUPHEIGHT,
  UPDATE_CUSTOMS_CARD4_POPUPHEIGHTHOVER,
  UPDATE_CUSTOMS_CARD6_POS_SIZE,
  UPDATE_CUSTOMS_HERO_PARALLAX_HEIGHT,
  UPDATE_CUSTOMS_PARALLAX_SRC,
  UPDATE_CUSTOMS_PROCESS1_ARROW_COLOR,
  UPDATE_CUSTOMS_PROCESS2_LINE_COLOR,
  UPDATE_CUSTOMS_PROCESS2_ICON_COLOR,
  UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_BGCOLOR,
  UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_SHADOWCOLOR,
  UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_POSLEFT,
  FLIP_SECTION_DIVIDER,
  UPDATE_CUSTOMS_SECTIONDIVIDER_COLORS,
  UPDATE_COMPONENT_BGCOLOR,
  UPDATE_CUSTOMS_COMPONENT_SPACE_TOP,
  UPDATE_CUSTOMS_COMPONENT_SPACE_BOTTOM,
  UPDATE_TEXTCONTENT,
  UPDATE_NAVBAR_POSITION,
  UPDATE_NAVBAR_SCROLLEDPAST_BOOL,
  UPDATE_NAVBAR_SCROLLEDPAST_SIZE_BGCOLOR,
  UPDATE_NAVBAR_SCROLLEDPAST_LOGOHEIGHT,
  UPDATE_NAVBAR_LINK_DATA,
  UPDATE_CAROUSEL_NAV_STYLES,
  UPDATE_CAROUSEL_PAG_STYLES,
  UPDATE_CUSTOMS_BACKTOTOP_POS,
  UPDATE_CUSTOMS_BACKTOTOP_SIZE_ICON,
  UPDATE_CUSTOMS_BACKTOTOP_SIZE_BTN,
  UPDATE_INPUTFIELD_FONTSIZE,
  UPDATE_INPUTFIELD_PLACEHOLDER_COLOR,
  UPDATE_INPUTFIELD_TYPE_SELECT,
  UPDATE_INPUTFIELD_TYPE_CHECK,
  UPDATE_CAROUSEL_SLIDE_CSS,
  OBJECT_REMOVE_CHILDREN,
  // xxx
  // Other
  CHANGE_SCREEN_SIZE,
} from "./types";

import { setGlobalLoading, removeGlobalLoading } from "./globalLoading";

import { SITE_COLOR_VAR_NAMES, API_URL } from "../lib/generalVars";
import { updateEditFormColorpickerValue } from "../lib/editFunctions";
import { templates } from "../lib/templates";
import { getRandomString } from "../lib/domFunctions";
import {
  POST_CONFIG,
  loadProjectInitSbPagesArray,
  inputfield_getDropdownOptionsObjects,
  inputfield_getCheckboxObjects,
} from "../lib/reducerFunctions";
import { getTargetObj, getFirstChildByTagname, getConcatElementId, getInputfieldLabel } from "../lib/componentObjectFunctions";

import { translate } from "../translations/translations";

export const changeScreenSize = (screenSize) => (dispatch) => {
  dispatch({
    type: CHANGE_SCREEN_SIZE,
    payload: screenSize,
  });
};

// ====================
// == Manage project ==
// ====================

export const reduxCleanCustomCss = (cleanedCss) => (dispatch) => {
  dispatch({
    type: CLEAN_CUSTOM_CSS,
    payload: cleanedCss,
  });
};

export const loadProject = (projectId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aSb.loadingProject", false, null)));
  try {
    const res = await axios.get(`${API_URL}/sitebuilder/edit/project/${projectId}`);
    dispatch({
      type: LOAD_PROJECT,
      payload: loadProjectInitSbPagesArray(res.data),
    });
    dispatch(removeGlobalLoading(loadingId));
  } catch (error) {
    console.error(error);
    dispatch({ type: AUTH_ERROR });
    dispatch(removeGlobalLoading(loadingId));
  }
};

export const saveProject = (projectId, gallery, siteMeta, sbPages, sbCustomCss, sbCssVars) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aSb.savingProject", false, null)));
  try {
    const body = JSON.stringify({ projectId, gallery, siteMeta, sbPages, sbCustomCss, sbCssVars });
    await axios.put(`${API_URL}/sitebuilder/edit/save`, body, POST_CONFIG);
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const loadTemplate = (templateId) => (dispatch) => {
  let selectedTemplate = templates.filter((template) => template.id === templateId)[0];
  if (typeof selectedTemplate !== "undefined") {
    // Set pageIds
    selectedTemplate.sbPages = selectedTemplate.sbPages.map((page) => ({ ...page, pageId: getRandomString(10) }));
    // Send to reducer
    dispatch({
      type: LOAD_TEMPLATE,
      payload: selectedTemplate,
    });
  }
};

export const updateProjectGallery = (gallery) => (dispatch) => {
  dispatch({
    type: UPDATE_PROJECT_GALLERY,
    payload: gallery,
  });
};

export const updateGalleryFileDesc = (fileId, newDesc) => (dispatch) => {
  dispatch({
    type: UPDATE_GALLERY_FILE_DESC,
    payload: { fileId, newDesc },
  });
};

export const setSiteMetaValue = (metaVariable, metaValue) => async (dispatch) => {
  dispatch({
    type: UPDATE_SITEMETA,
    payload: { metaVariable, metaValue },
  });
};

// export const setSiteMetaValue = (projectId, metaVariable, metaValue) => async (dispatch) => {
//   const body = JSON.stringify({ projectId, metaVariable, metaValue });
//   await axios.put(`${API_URL}/sitebuilder/edit/setSiteMetaValue`, body, POST_CONFIG);
//   dispatch({
//     type: UPDATE_SITEMETA,
//     payload: { metaVariable, metaValue },
//   });
// };

// =============================
// == Open/close product tour ==
// =============================

export const openProductTour = () => (dispatch) => {
  dispatch({ type: OPEN_PRODUCT_TOUR });
};

export const closeProductTour = () => (dispatch) => {
  dispatch({ type: CLOSE_PRODUCT_TOUR });
};

// =================
// == Drag & drop ==
// =================

export const startDragFromMenu = (componentId) => (dispatch) => {
  dispatch({
    type: START_DRAG_FROM_MENU,
    payload: componentId,
  });
};

export const endDragFromMenu = () => (dispatch) => {
  dispatch({
    type: END_DRAG_FROM_MENU,
  });
};

export const dropComponent = (posToInsert) => (dispatch) => {
  dispatch({
    type: DROP_COMPONENT,
    payload: posToInsert,
  });
};

// ====================
// == Edit component ==
// ====================

export const reorderComponent = (componentId, direction) => (dispatch) => {
  dispatch({
    type: REORDER_COMPONENT,
    payload: { componentId, direction },
  });
};

export const deleteComponent = (componentId) => (dispatch) => {
  dispatch({
    type: DELETE_COMPONENT,
    payload: componentId,
  });
};

export const setSelectedElement = (elementId) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_ELEMENT,
    payload: elementId,
  });
};

// =====================
// == Manage elements ==
// =====================

export const duplicateElement = (elementId) => (dispatch) => {
  dispatch({
    type: DUPLICATE_ELEMENT,
    payload: elementId,
  });
};

export const deleteElement = (elementId) => (dispatch) => {
  dispatch({
    type: DELETE_ELEMENT,
    payload: elementId,
  });
};

export const reorderColumn = (elementId) => (dispatch) => {
  dispatch({
    type: REORDER_COL,
    payload: elementId,
  });
};

// ==================
// == Input fields ==
// ==================

export const reorderInputfield = (splitElementId, direction) => (dispatch) => {
  dispatch({
    type: REORDER_INPUTFIELD,
    payload: { splitElementId, direction },
  });
};

export const toggleCheckbox = (splitElementId, val) => (dispatch) => {
  // Need to update in shadow DOM, else it doesn't show up to user which will be confusing..
  dispatch({
    type: TOGGLE_CHECKBOX,
    payload: { splitElementId, val },
  });
};

// ==================
// == Manage pages ==
// ==================

export const addNewPage = (newPageName) => (dispatch) => {
  dispatch({
    type: ADD_NEW_PAGE,
    payload: newPageName,
  });
};

export const addNewVersion = (pageToAdd, customCssToAdd) => (dispatch) => {
  dispatch({
    type: ADD_NEW_VERSION,
    payload: { pageToAdd, customCssToAdd },
  });
};

export const setActivePage = (pageId) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_PAGE,
    payload: pageId,
  });
};

export const editPageInfo = (pageId, pageName, pageTitle, pageDesc, pageType, oldPageName) => (dispatch) => {
  dispatch({
    type: EDIT_PAGE_INFO,
    payload: { pageId, pageName, pageTitle, pageDesc, pageType, oldPageName },
  });
};

export const editPageSocialPreview = (pageId, fileName) => (dispatch) => {
  dispatch({
    type: EDIT_PAGE_SOCIAL_PREVIEW,
    payload: { pageId, fileName },
  });
};

export const deletePage = (pageId) => (dispatch) => {
  dispatch({
    type: DELETE_PAGE,
    payload: pageId,
  });
};

export const copyComponentToOtherPage = (splitElementId, pageIdToCopyTo) => (dispatch) => {
  dispatch({
    type: COPY_COMPONENT_TO_OTHER_PAGE,
    payload: { splitElementId, pageIdToCopyTo },
  });
};

// ===================
// == Track changes ==
// ===================

export const saveTrackChanges = () => (dispatch) => {
  dispatch({
    type: SAVE_TRACK_CHANGES,
  });
};

export const clickUndoRedo = (undoOrRedo) => (dispatch) => {
  dispatch({
    type: UNDO_REDO,
    payload: undoOrRedo,
  });
};

// =================
// == Edit styles ==
// =================

export const startEditingStyles = (editFormsToShow, startStyles) => (dispatch) => {
  dispatch({
    type: START_EDITING,
    payload: { editFormsToShow, startStyles },
  });
};

export const openColorPicker = (title, color, varName) => (dispatch) => {
  // title = modal title
  // color = color to load in color picker
  // varName = name of variable to update after selecting new color
  dispatch({
    type: OPEN_COLOR_PICKER,
    payload: { title, color, varName },
  });
};

export const selectColor = (value, varName) => (dispatch) => {
  if (SITE_COLOR_VAR_NAMES.includes(varName)) {
    dispatch({
      type: CHANGE_SITE_COLORS,
      payload: [{ varName, newRgb: (value.match(/\((\d+, \d+, \d+).+?\)/) || ["", "0, 0, 0"])[1] }],
    });
    dispatch({
      type: UPDATE_CSS_VAR,
      payload: { value, varName },
    });
  } else {
    updateEditFormColorpickerValue(varName, value);
  }
};

export const confirmColorScheme = (arrRgbaStrings) => (dispatch) => {
  dispatch({
    type: CHANGE_SITE_COLORS,
    payload: arrRgbaStrings.map((rgba, i) => ({ varName: `color${i + 1}`, newRgb: (rgba.match(/\((\d+, \d+, \d+).+?\)/) || ["", "0, 0, 0"])[1] })),
  });
  dispatch({
    type: CONFIRM_COLOR_SCHEME,
    payload: arrRgbaStrings,
  });
};

export const selectFont = (value, varName) => (dispatch) => {
  dispatch({
    type: UPDATE_CSS_VAR,
    payload: { value, varName },
  });
};

export const updateCss = (splitElementId, updatedCssRules) => (dispatch) => {
  // updatedCssRules = [ { property, pseudo, value }, ... ]
  dispatch({
    type: UPDATE_COMPONENT_CSS,
    payload: { splitElementId, updatedCssRules },
  });
};

export const updateClasses = (splitElementId, updatedClasses) => (dispatch) => {
  dispatch({
    type: UPDATE_COMPONENT_CLASSES,
    payload: { splitElementId, updatedClasses },
  });
};

export const updateAttributes = (splitElementId, updatedAttributes) => (dispatch) => {
  // updateAttributes = [ {attr, oldVal, newVal }, ... ]
  dispatch({
    type: UPDATE_COMPONENT_ATTRIBUTES,
    payload: { splitElementId, updatedAttributes },
  });
};

export const updateHtmlTagname = (splitElementId, updatedHtmlTagname) => (dispatch) => {
  dispatch({
    type: UPDATE_COMPONENT_HTMLTAGNAME,
    payload: { splitElementId, updatedHtmlTagname },
  });
};

export const updateExistingTextElement = (elementObj, cssRules) => (dispatch) => {
  dispatch({
    type: UPDATE_EXISTING_TEXT_ELEMENT,
    payload: { elementObj, cssRules },
  });
};

export const addNewTextElement = (elementObj, cssRules) => (dispatch) => {
  dispatch({
    type: ADD_NEW_TEXT_ELEMENT,
    payload: { elementObj, cssRules },
  });
};

export const selectImgFromGallery = (newSrc, targetStartStyleProp, fileDesc) => (dispatch) => {
  dispatch({
    type: SELECT_IMG_FROM_GALLERY,
    payload: { newSrc, targetStartStyleProp, fileDesc },
  });
};

export const updateCustoms = (splitElementId, updatedCustoms, i) => (dispatch) => {
  // updatedCustoms = [ { property, oldVal, newVal }, { ... } ]
  updatedCustoms.forEach((custom) => {
    if (custom.property === "colMobile") {
      dispatch({
        type: UPDATE_CUSTOMS_ROW_LAYOUT,
        payload: { ...custom, oldVal: `col-${custom.oldVal}`, newVal: `col-${custom.newVal}`, splitElementId },
      });
    }
    if (custom.property === "colTablet") {
      dispatch({
        type: UPDATE_CUSTOMS_ROW_LAYOUT,
        payload: { ...custom, oldVal: `col-md-${custom.oldVal}`, newVal: `col-md-${custom.newVal}`, splitElementId },
      });
    }
    if (custom.property === "colDesktop") {
      dispatch({
        type: UPDATE_CUSTOMS_ROW_LAYOUT,
        payload: { ...custom, oldVal: `col-lg-${custom.oldVal}`, newVal: `col-lg-${custom.newVal}`, splitElementId },
      });
    }
    if (custom.property === "widthMobile") {
      dispatch({
        type: UPDATE_CUSTOMS_ELEMENT_WIDTH,
        payload: { ...custom, oldVal: `w-${custom.oldVal}`, newVal: `w-${custom.newVal}`, splitElementId },
      });
    }
    if (custom.property === "widthTablet") {
      dispatch({
        type: UPDATE_CUSTOMS_ELEMENT_WIDTH,
        payload: { ...custom, oldVal: `w-md-${custom.oldVal}`, newVal: `w-md-${custom.newVal}`, splitElementId },
      });
    }
    if (custom.property === "widthDesktop") {
      dispatch({
        type: UPDATE_CUSTOMS_ELEMENT_WIDTH,
        payload: { ...custom, oldVal: `w-lg-${custom.oldVal}`, newVal: `w-lg-${custom.newVal}`, splitElementId },
      });
    }
    if (custom.property === "alignRowAlign") {
      dispatch({
        type: UPDATE_CUSTOMS_ROW_ALIGN_JUSTIFY,
        payload: { ...custom, oldVal: `align-items-${custom.oldVal}`, newVal: `align-items-${custom.newVal}`, splitElementId },
      });
    }
    if (custom.property === "alignRowJustify") {
      dispatch({
        type: UPDATE_CUSTOMS_ROW_ALIGN_JUSTIFY,
        payload: { ...custom, oldVal: `justify-content-${custom.oldVal}`, newVal: `justify-content-${custom.newVal}`, splitElementId },
      });
    }
    if (custom.property === "selectedIcon") {
      dispatch({
        type: UPDATE_CUSTOMS_ICON_EDITOR,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "hero1_overlay") {
      dispatch({
        type: UPDATE_CUSTOMS_HERO1_OVERLAY,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "card3_desc_bgColor") {
      dispatch({
        type: UPDATE_CUSTOMS_CARD3_DESC_BGCOLOR,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "card3_desc_bgColorHover") {
      dispatch({
        type: UPDATE_CUSTOMS_CARD3_DESC_BGCOLORHOVER,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "card4_popupHeight") {
      dispatch({
        type: UPDATE_CUSTOMS_CARD4_POPUPHEIGHT,
        payload: { splitElementId, custom: { ...custom, oldVal: `${custom.oldVal}px`, newVal: `${custom.newVal}px` } },
      });
    }
    if (custom.property === "card4_popupHeightHover") {
      dispatch({
        type: UPDATE_CUSTOMS_CARD4_POPUPHEIGHTHOVER,
        payload: { splitElementId, custom: { ...custom, oldVal: `${custom.oldVal}px`, newVal: `${custom.newVal}px` } },
      });
    }
    if (custom.property === "card6_size" || custom.property === "card6_pos") {
      let card6_size = updatedCustoms.filter((item) => item.property === "card6_size")[0].newVal;
      let card6_pos = updatedCustoms.filter((item) => item.property === "card6_pos")[0].newVal;
      // Prepare css rules in standard css rule format
      let cssRules = [];
      // Options:
      // - Left:    top === 0     left === 0    height === 100%
      if (card6_pos === "left") {
        cssRules = [
          { property: "top", value: "0" },
          { property: "left", value: "0" },
          { property: "height", value: "100%" },
          { property: "width", value: `${card6_size}px` },
        ];
      }
      // - Right:   top === 0     right === 0   height === 100%
      if (card6_pos === "right") {
        cssRules = [
          { property: "top", value: "0" },
          { property: "right", value: "0" },
          { property: "height", value: "100%" },
          { property: "width", value: `${card6_size}px` },
        ];
      }
      // - Top:     top === 0     left === 0    width === 100%
      if (card6_pos === "top") {
        cssRules = [
          { property: "top", value: "0" },
          { property: "left", value: "0" },
          { property: "width", value: "100%" },
          { property: "height", value: `${card6_size}px` },
        ];
      }
      // - Bottom:  bottom === 0  left === 0    width === 100%
      if (card6_pos === "bottom") {
        cssRules = [
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "width", value: "100%" },
          { property: "height", value: `${card6_size}px` },
        ];
      }
      dispatch({
        type: UPDATE_CUSTOMS_CARD6_POS_SIZE,
        payload: { splitElementId, cssRules },
      });
    }
    if (custom.property === "parallaxHeightVh" || custom.property === "heroHeightVh") {
      dispatch({
        type: UPDATE_CUSTOMS_HERO_PARALLAX_HEIGHT,
        payload: { splitElementId, custom: { ...custom, oldVal: `${custom.oldVal}vh`, newVal: `${custom.newVal}vh` } },
      });
    }
    if (custom.property === "parallaxImgSrc") {
      dispatch({
        type: UPDATE_CUSTOMS_PARALLAX_SRC,
        payload: { splitElementId, custom: { ...custom, oldVal: `url("${custom.oldVal}")`, newVal: `url("${custom.newVal}")` } },
      });
    }
    if (custom.property === "process1_arrow_color") {
      dispatch({
        type: UPDATE_CUSTOMS_PROCESS1_ARROW_COLOR,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "process2_lineColor") {
      dispatch({
        type: UPDATE_CUSTOMS_PROCESS2_LINE_COLOR,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "process2_iconColor") {
      dispatch({
        type: UPDATE_CUSTOMS_PROCESS2_ICON_COLOR,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "callout_testimonial_bgColor") {
      dispatch({
        type: UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_BGCOLOR,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "callout_testimonial_shadowColor") {
      dispatch({
        type: UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_SHADOWCOLOR,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "callout_testimonial_posLeft") {
      dispatch({
        type: UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_POSLEFT,
        payload: { splitElementId, custom: { ...custom, oldVal: `${custom.oldVal}%`, newVal: `${custom.newVal}%` } },
      });
    }
    if (custom.property === "sdColors") {
      dispatch({
        type: UPDATE_CUSTOMS_SECTIONDIVIDER_COLORS,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "componentBgColor") {
      // Get variables for both background and background-color css props (all need to be included in ALWAYS_INCLUDE_CUSTOMS)
      let componentBgColor = custom.newVal;
      let componentBoolBgIsGradient = updatedCustoms.filter((item) => item.property === "componentBoolBgIsGradient")[0].newVal;
      let componentBgGradientDirection = updatedCustoms.filter((item) => item.property === "componentBgGradientDirection")[0].newVal;
      let componentBgGradientColor1 = updatedCustoms.filter((item) => item.property === "componentBgGradientColor1")[0].newVal;
      let componentBgGradientColor2 = updatedCustoms.filter((item) => item.property === "componentBgGradientColor2")[0].newVal;
      let componentBgGradientStop1 = updatedCustoms.filter((item) => item.property === "componentBgGradientStop1")[0].newVal;
      let componentBgGradientStop2 = updatedCustoms.filter((item) => item.property === "componentBgGradientStop2")[0].newVal;

      let cssRules = [
        { property: "background", pseudo: "", value: componentBgColor },
        { property: "background-color", pseudo: "", value: componentBgColor },
      ];
      if (componentBoolBgIsGradient) {
        cssRules = [
          {
            property: "background",
            pseudo: "",
            value: `linear-gradient(${componentBgGradientDirection}deg, ${componentBgGradientColor1} ${componentBgGradientStop1}%, ${componentBgGradientColor2} ${componentBgGradientStop2}%)`,
          },
          { property: "background-color", pseudo: "", value: componentBgGradientColor1 },
        ];
      }
      dispatch({
        type: UPDATE_COMPONENT_BGCOLOR,
        payload: { splitElementId, cssRules },
      });
    }
    if (custom.property === "componentPaddingTop" || custom.property === "sdSpaceBottom") {
      dispatch({
        type: UPDATE_CUSTOMS_COMPONENT_SPACE_TOP,
        payload: { splitElementId, custom: { ...custom, oldVal: `${custom.oldVal}rem`, newVal: `${custom.newVal}rem` } },
      });
    }
    if (custom.property === "componentPaddingBottom" || custom.property === "sdSpaceTop") {
      dispatch({
        type: UPDATE_CUSTOMS_COMPONENT_SPACE_BOTTOM,
        payload: { splitElementId, custom: { ...custom, oldVal: `${custom.oldVal}rem`, newVal: `${custom.newVal}rem` } },
      });
    }
    if (custom.property === "btnText" || custom.property === "inputfield_labelText") {
      if (custom.oldVal !== null && custom.newVal !== null) {
        dispatch({
          type: UPDATE_TEXTCONTENT,
          payload: { splitElementId, custom },
        });
      }
    }
    if (custom.property === "navbarPosition") {
      dispatch({
        type: UPDATE_NAVBAR_POSITION,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "navbarScrolledpastBool") {
      dispatch({
        type: UPDATE_NAVBAR_SCROLLEDPAST_BOOL,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "navbarScrolledpastSize" || custom.property === "navbarScrolledpastBgColor") {
      let navbarScrolledpastSize = updatedCustoms.filter((item) => item.property === "navbarScrolledpastSize")[0].newVal;
      let navbarScrolledpastBgColor = updatedCustoms.filter((item) => item.property === "navbarScrolledpastBgColor")[0].newVal;
      // Prepare css rules in standard css rule format ( { property: "", value: "" } )
      let cssRules = [
        { property: "padding-top", value: `${navbarScrolledpastSize}rem` },
        { property: "padding-bottom", value: `${navbarScrolledpastSize}rem` },
        { property: "background", value: navbarScrolledpastBgColor },
      ];
      dispatch({
        type: UPDATE_NAVBAR_SCROLLEDPAST_SIZE_BGCOLOR,
        payload: { splitElementId, cssRules },
      });
    }
    if (custom.property === "navbarScrolledpastLogoMaxHeight") {
      let navbarScrolledpastLogoMaxHeight = updatedCustoms.filter((item) => item.property === "navbarScrolledpastLogoMaxHeight")[0].newVal;
      // Prepare css rules in standard css rule format ( { property: "", value: "" } )
      let cssRules = [{ property: "max-height", value: `${navbarScrolledpastLogoMaxHeight}px` }];
      dispatch({
        type: UPDATE_NAVBAR_SCROLLEDPAST_LOGOHEIGHT,
        payload: { splitElementId, cssRules },
      });
    }
    if (custom.property === "navbarLinkData") {
      dispatch({
        type: UPDATE_NAVBAR_LINK_DATA,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "markerForm" || custom.property === "markerBorderColor" || custom.property === "markerMarginRight") {
      let markerForm = updatedCustoms.filter((item) => item.property === "markerForm")[0].newVal;
      let markerBorderColor = updatedCustoms.filter((item) => item.property === "markerBorderColor")[0].newVal;
      let markerMarginRight = updatedCustoms.filter((item) => item.property === "markerMarginRight")[0].newVal;
      // Prepare css rules in standard css rule format
      let cssRules = [];
      let rgb = (markerBorderColor.match(/rgba\((.+),.+?\)/) || ["", "0, 0, 0"])[1];
      // Form           height === width     borderRadius   borderColorAlpha  bgColorAlpha
      // none           true                 0              0                 0
      // filledsquare   true                 0              >0                >0
      // opensquare     true                 0              >0                0
      // filledcircle   true                 50             >0                >0
      // opencircle     true                 50             >0                0
      // line           false                0              >0                >0
      if (markerForm === "none") {
        cssRules = [
          { property: "height", value: "13px" },
          { property: "width", value: "13px" },
          { property: "border-radius", value: "0rem" },
          { property: "background", value: `rgba(0, 0, 0, 0)` },
          { property: "border-color", value: `rgba(0, 0, 0, 0)` },
          { property: "margin-right", value: `0rem` },
        ];
      }
      if (markerForm === "filledsquare") {
        cssRules = [
          { property: "height", value: "13px" },
          { property: "width", value: "13px" },
          { property: "border-radius", value: "0rem" },
          { property: "background", value: markerBorderColor },
          { property: "border-color", value: markerBorderColor },
          { property: "margin-right", value: `${markerMarginRight}rem` },
        ];
      }
      if (markerForm === "opensquare") {
        cssRules = [
          { property: "height", value: "13px" },
          { property: "width", value: "13px" },
          { property: "border-radius", value: "0rem" },
          { property: "background", value: `rgba(${rgb}, 0)` },
          { property: "border-color", value: markerBorderColor },
          { property: "margin-right", value: `${markerMarginRight}rem` },
        ];
      }
      if (markerForm === "filledcircle") {
        cssRules = [
          { property: "height", value: "13px" },
          { property: "width", value: "13px" },
          { property: "border-radius", value: "50%" },
          { property: "background", value: markerBorderColor },
          { property: "border-color", value: markerBorderColor },
          { property: "margin-right", value: `${markerMarginRight}rem` },
        ];
      }
      if (markerForm === "opencircle") {
        cssRules = [
          { property: "height", value: "13px" },
          { property: "width", value: "13px" },
          { property: "border-radius", value: "50%" },
          { property: "background", value: `rgba(${rgb}, 0)` },
          { property: "border-color", value: markerBorderColor },
          { property: "margin-right", value: `${markerMarginRight}rem` },
        ];
      }
      if (markerForm === "line") {
        cssRules = [
          { property: "height", value: "2px" },
          { property: "width", value: "13px" },
          { property: "border-radius", value: "0rem" },
          { property: "background", value: markerBorderColor },
          { property: "border-color", value: markerBorderColor },
          { property: "margin-right", value: `${markerMarginRight}rem` },
        ];
      }
      dispatch({
        type: UPDATE_LIST_MARKER2,
        payload: { splitElementId, cssRules },
      });
    }
    if (custom.property === "carouselOptions" || custom.property === "carouselOptionsThumbnail" || custom.property === "carouselOptionsMain") {
      // updatedAttributes = [ { attr: "id", oldVal: "xxx", newVal: "yyy" }, ... ]
      dispatch(updateAttributes(splitElementId, [{ attr: "data-splide", newVal: JSON.stringify(custom.newVal) }]));
      // Ensure consistency between fixedHeight/Width on carousel and css
      // - For type=slide/fade   => always have perPage
      // - For type="continuous" => get height from editForms
      let cssRules = [];
      if (typeof custom.newVal.autoScroll !== "undefined") {
        // 1) If type=continuous  => set css height:100% and width:auto
        cssRules = [
          { property: "height", value: "100%" },
          { property: "width", value: "auto" },
        ];
      } else {
        // 2) If type!=continuous =>
        if (typeof custom.newVal.height !== "undefined") {
          // a) If carousel height option is set  => set css to height:100%, width:100%, object-fit:cover
          cssRules = [
            { property: "height", value: "100%" },
            { property: "width", value: "auto" },
            { property: "object-fit", value: "cover" },
          ];
        } else {
          // b) If carousel height option not set => set max-height:[x]px, max-width:100%, width:auto, height:auto, object-fit:cover
          cssRules = [
            { property: "max-width", value: "100%" },
            {
              property: "max-height",
              value: typeof custom.newVal.fixedHeight === "undefined" ? custom.newVal.fixedHeightCss : custom.newVal.fixedHeight,
            },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ];
        }
      }
      dispatch({
        type: UPDATE_CAROUSEL_SLIDE_CSS,
        payload: { splitElementId, cssRules },
      });
    }
    if (custom.property === "carouselNavStyles") {
      if (custom.newVal !== "") {
        // Set border radius css value
        custom = {
          ...custom,
          newVal: {
            ...custom.newVal,
            borderRadius:
              custom.newVal.borderRadius < 50
                ? `${custom.newVal.borderRadius}rem`
                : custom.newVal.borderRadius < 100
                ? `${custom.newVal.borderRadius}%`
                : `${custom.newVal.borderRadius}px`,
          },
        };
        dispatch({
          type: UPDATE_CAROUSEL_NAV_STYLES,
          payload: { splitElementId, custom },
        });
      }
    }
    if (custom.property === "carouselPagStyles") {
      if (custom.newVal !== "") {
        // Set border radius css value
        custom = {
          ...custom,
          newVal: {
            ...custom.newVal,
            borderRadius:
              custom.newVal.borderRadius < 50
                ? `${custom.newVal.borderRadius}rem`
                : custom.newVal.borderRadius < 100
                ? `${custom.newVal.borderRadius}%`
                : `${custom.newVal.borderRadius}px`,
          },
        };
        dispatch({
          type: UPDATE_CAROUSEL_PAG_STYLES,
          payload: { splitElementId, custom },
        });
      }
    }
    if (custom.property === "backToTop_pos") {
      // Prepare css rules in standard css rule format
      let cssRules = [];
      if (custom.newVal === "top-left") {
        cssRules = [
          { property: "top", value: "1rem" },
          { property: "left", value: "1rem" },
        ];
      }
      if (custom.newVal === "top-right") {
        cssRules = [
          { property: "top", value: "1rem" },
          { property: "right", value: "1rem" },
        ];
      }
      if (custom.newVal === "bottom-left") {
        cssRules = [
          { property: "bottom", value: "1rem" },
          { property: "left", value: "1rem" },
        ];
      }
      if (custom.newVal === "bottom-right") {
        cssRules = [
          { property: "bottom", value: "1rem" },
          { property: "right", value: "1rem" },
        ];
      }
      dispatch({
        type: UPDATE_CUSTOMS_BACKTOTOP_POS,
        payload: { splitElementId, cssRules },
      });
    }
    if (custom.property === "backToTop_sizeBtn") {
      dispatch({
        type: UPDATE_CUSTOMS_BACKTOTOP_SIZE_BTN,
        payload: { splitElementId, custom: { ...custom, oldVal: `${custom.oldVal}rem`, newVal: `${custom.newVal}rem` } },
      });
    }
    if (custom.property === "backToTop_sizeIcon") {
      dispatch({
        type: UPDATE_CUSTOMS_BACKTOTOP_SIZE_ICON,
        payload: { splitElementId, custom: { ...custom, oldVal: `${custom.oldVal}rem`, newVal: `${custom.newVal}rem` } },
      });
    }
    if (custom.property === "inputfield_fontSize") {
      dispatch({
        type: UPDATE_INPUTFIELD_FONTSIZE,
        payload: { splitElementId, custom: { ...custom, oldVal: `${custom.oldVal}rem`, newVal: `${custom.newVal}rem` } },
      });
    }
    if (custom.property === "inputfield_placeholderColor") {
      dispatch({
        type: UPDATE_INPUTFIELD_PLACEHOLDER_COLOR,
        payload: { splitElementId, custom },
      });
    }
    if (custom.property === "inputfield_showLabel") {
      dispatch(updateCss(splitElementId, [{ property: "display", pseudo: "", value: custom.newVal ? "block" : "none" }]));
    }
    if (custom.property === "inputfield_type") {
      // Set inputfield settings values: type & placeholder text. Field name and label text are done separately
      // Get values for "inputfield_dropdownOptions" & "inputfield_placeholderText"
      let dropdownOptions = updatedCustoms.filter((item) => item.property === "inputfield_dropdownOptions")[0];
      let placeholderText = updatedCustoms.filter((item) => item.property === "inputfield_placeholderText")[0];

      // Change type & placeholder
      // Don't allow changing of input type when it is a checkbox. Has a completely different DOM structure so simplifies to not allow changing
      if (custom.oldVal === "check") {
        dispatch({
          type: UPDATE_INPUTFIELD_TYPE_CHECK,
          payload: { splitElementId, checkObject: inputfield_getCheckboxObjects(getInputfieldLabel()) },
        });
      } else {
        if (custom.newVal === "select") {
          dispatch({
            type: UPDATE_INPUTFIELD_TYPE_SELECT,
            payload: {
              splitElementId,
              optionObjects: inputfield_getDropdownOptionsObjects([
                { val: "", label: placeholderText.newVal },
                ...JSON.parse(dropdownOptions.newVal),
              ]),
            },
          });
        } else if (custom.newVal === "textarea") {
          dispatch(
            updateHtmlTagname(splitElementId, {
              oldVal: custom.oldVal === "textarea" ? "textarea" : custom.oldVal === "select" ? "select" : "input",
              newVal: custom.newVal === "textarea" ? "textarea" : custom.newVal === "select" ? "select" : "input",
            })
          );
          dispatch(updateAttributes(splitElementId, [{ attr: "placeholder", oldVal: placeholderText.oldVal, newVal: placeholderText.newVal }]));
          dispatch({
            type: OBJECT_REMOVE_CHILDREN,
            payload: { splitElementId },
          });
        } else {
          // text, number, password, email, date
          dispatch(updateAttributes(splitElementId, [{ attr: "type", oldVal: custom.oldVal, newVal: custom.newVal }]));
          dispatch(
            updateHtmlTagname(splitElementId, {
              oldVal: custom.oldVal === "textarea" ? "textarea" : custom.oldVal === "select" ? "select" : "input",
              newVal: custom.newVal === "textarea" ? "textarea" : custom.newVal === "select" ? "select" : "input",
            })
          );
          dispatch(updateAttributes(splitElementId, [{ attr: "placeholder", oldVal: placeholderText.oldVal, newVal: placeholderText.newVal }]));
          dispatch({
            type: OBJECT_REMOVE_CHILDREN,
            payload: { splitElementId },
          });
        }
      }
    }
    if (custom.property === "animationType") {
      // updatedAttributes = [ { attr: "id", oldVal: "xxx", newVal: "yyy" }, ... ]
      dispatch(updateAttributes(splitElementId, [{ attr: "data-aos", newVal: custom.newVal }]));
    }
    if (custom.property === "animationDelay") {
      // updatedAttributes = [ { attr: "id", oldVal: "xxx", newVal: "yyy" }, ... ]
      let staggeredDelay = (updatedCustoms.filter((c) => c.property === "animationStaggeredDelay")[0] || { newVal: 0 }).newVal * Math.max(0, i - 1);
      dispatch(updateAttributes(splitElementId, [{ attr: "data-aos-delay", newVal: custom.newVal + staggeredDelay }]));
    }
    if (custom.property === "animationDuration") {
      // updatedAttributes = [ { attr: "id", oldVal: "xxx", newVal: "yyy" }, ... ]
      dispatch(updateAttributes(splitElementId, [{ attr: "data-aos-duration", newVal: custom.newVal }]));
    }
    if (custom.property === "animationRepeat") {
      // updatedAttributes = [ { attr: "id", oldVal: "xxx", newVal: "yyy" }, ... ]
      dispatch(updateAttributes(splitElementId, [{ attr: "data-aos-once", newVal: custom.newVal }]));
    }
    // Additional customs ...
  });
};

export const flipSectionDivider = (stringComponentId, newStyleValue) => (dispatch) => {
  // Get component and the first svg child
  let component = getTargetObj([stringComponentId]);
  let svg = getFirstChildByTagname(component, "svg");
  let splitElementId = getConcatElementId(svg.childId).split("-");
  dispatch({
    type: FLIP_SECTION_DIVIDER,
    payload: { splitElementId, newStyleValue },
  });
};
