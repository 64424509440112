import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ColorPicker from "./formComponents/ColorPicker";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const BgColor = ({ sb: { startStyles } }) => {
  const [bool, setBool] = useState(false);
  useEffect(() => {
    if (formGetStartValue(startStyles, "bgColor") === formGetStartValue(startStyles, "bgColorHover")) {
      setBool(false);
    } else {
      setBool(true);
    }
  }, [startStyles]);

  return (
    <>
      <ColorPicker
        label={translate("cEditForms.bgColor", false, null)}
        id={"bgColor"}
        startValue={formGetStartValue(startStyles, "bgColor")}
        tooltipText={translate("cEditForms.tooltipBgColor", false, null)}
      />
      <div className={`form-check form-switch mt-3 ${bool ? "mb-n2" : "mb-3"}`}>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="switchBgColorHover"
          value={bool}
          checked={bool}
          onChange={(e) => setBool(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="switchBgColorHover">
          {translate("cEditForms.changeBgColorOnHover", false, null)}
        </label>
      </div>
      {bool && (
        <ColorPicker
          label={translate("cEditForms.bgColorHover", false, null)}
          id={"bgColorHover"}
          startValue={formGetStartValue(startStyles, "bgColorHover")}
          tooltipText={translate("cEditForms.tooltipBgColorHover", false, null)}
        />
      )}
    </>
  );
};

BgColor.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(BgColor);
