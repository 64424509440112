import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addNewPage, addNewVersion, setActivePage, editPageInfo, editPageSocialPreview, deletePage, setSiteMetaValue } from "../../actions/sb";
import { injectIframeJS, getRandomId } from "../../lib/domFunctions";
import { getNumberOfPages, getSubscriptionMaxPages, getGalleryAssetLink, getVersionNameFromIndex } from "../../lib/generalFunctions";
import { metaLangCodes } from "../../lib/metaLangCodes";
import { renewClassnamesNewVersion } from "../../lib/parse";

import FormatDate from "../layout/FormatDate";
import Spinner from "../layout/Spinner";
import Tooltip from "../editPane/editComponent/editForms/formComponents/Tooltip";
import { translate } from "../../translations/translations";

const ModalPageOptions = ({
  auth: { workspace, langCode },
  sb: { project, sbPages, sbCustomCss, activePageId },
  addNewPage,
  addNewVersion,
  setActivePage,
  editPageInfo,
  editPageSocialPreview,
  deletePage,
  setSiteMetaValue,
}) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("select");
  const [localAlert, setLocalAlert] = useState(null);
  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });

  const closeBtn = useRef();

  const clickClose = () => {
    closeBtn.current.click();
  };

  const siteMetaVal = (metaVar) => {
    return project !== null && typeof project.siteMeta !== "undefined" && typeof project.siteMeta[metaVar] !== "undefined"
      ? project.siteMeta[metaVar]
      : "";
  };

  // Site lang code meta data
  const SiteLangCode = () => {
    useEffect(() => {
      let langCode = siteMetaVal("langCode");
      if (langCode !== "") {
        let currLang = metaLangCodes.filter((lang) => lang.subtag === langCode)[0];
        setLangCodesToShow([currLang]);
        // setSearchTerm(currLang.description);
        setSelectedLangCode(langCode);
      }
      // eslint-disable-next-line
    }, []);

    const [searchTerm, setSearchTerm] = useState("");
    const [langCodesToShow, setLangCodesToShow] = useState([]);
    const [selectedLangCode, setSelectedLangCode] = useState("");

    const onType = (val) => {
      setSearchTerm(val);
      setLangCodesToShow(metaLangCodes.filter((lang) => lang.description.toLowerCase().includes(val.toLowerCase())));
    };

    const clickUpdateLangCode = async () => {
      setSiteMetaValue("langCode", selectedLangCode);
      makeAlert(translate("mModalPageOptions.langCodeUpdated", false, null), "success");
    };

    return (
      <>
        <h4>{translate("mModalPageOptions.setSiteLangCode", false, null)}</h4>
        <div className="row mt-4">
          <div className="col-6 d-flex align-items-center">{translate("mModalPageOptions.searchLanguage", false, null)}</div>
          <div className="col-6 d-flex align-items-center">
            <input
              className="form-control"
              type="text"
              value={searchTerm}
              onChange={(e) => onType(e.target.value)}
              placeholder={translate("mModalPageOptions.searchLanguage", false, null)}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 d-flex align-items-center">{translate("mModalPageOptions.selectYourLang", false, null)}</div>
          <div className="col-6 d-flex align-items-center">
            <select className="form-select" value={selectedLangCode} onChange={(e) => setSelectedLangCode(e.target.value)}>
              <option value="">{translate("mModalPageOptions.noSpecificLang", false, null)}</option>
              {langCodesToShow.map((lang) => (
                <option key={lang.subtag} value={lang.subtag}>
                  {lang.description}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button className="mt-3 px-4 btn btn-success" onClick={clickUpdateLangCode}>
          {translate("mModalPageOptions.updateLangCode", false, null)}
        </button>
      </>
    );
  };

  // Site favicon
  const SiteFavicon = () => {
    const PER_PAGE = 12;

    const [selectedFileId, setSelectedFileId] = useState("");
    const [imagesToRender, setImagesToRender] = useState([]);
    const [currPage, setCurrPage] = useState(0);

    let favicon = siteMetaVal("favicon");
    let allGalleryImages = project !== null && typeof project.gallery !== "undefined" ? project.gallery : [];
    let maxPages = Math.ceil(allGalleryImages.length / PER_PAGE);

    useEffect(() => {
      renderImages();
      // eslint-disable-next-line
    }, [allGalleryImages, currPage]);

    const renderImages = () => {
      let start = currPage * PER_PAGE;
      let end = start + PER_PAGE;
      setImagesToRender(allGalleryImages.slice(start, end));
    };

    const clickPageBtn = (inc) => {
      setCurrPage((prev) => prev + inc);
    };

    const clickImg = (fileId) => {
      if (selectedFileId === fileId) {
        setSelectedFileId("");
      } else {
        setSelectedFileId(fileId);
      }
    };

    const GalleryFile = ({ file }) => {
      return (
        <div
          className={`col-12 col-md-4 col-lg-3 p-2 card-browse-templates${selectedFileId === file._id ? " selected" : ""}`}
          onClick={() => clickImg(file._id)}
        >
          <div className="flexSameHeight">
            <img
              src={getGalleryAssetLink(project._id, file.fileName)}
              alt={file.fileName}
              style={{ maxHeight: "250px", width: "100%", height: "auto", objectFit: "cover" }}
            />
            <div className="toGrow d-flex flex-column justify-content-end px-2 py-2">
              <div className="d-flex justify-content-between text-dark">
                <p className="fontSize09 m-0">
                  <i className="fa-regular fa-file-image me-1" />
                  {file.fileName}
                </p>
                <p className="fontSize09 m-0">
                  <i className="fa-regular fa-clock me-1" />
                  <FormatDate dateString={file.updatedAt} lang={langCode} formatStr={"d/m/yyyy"} />
                </p>
              </div>
            </div>
          </div>
          <div className="card-browse-selecticon">
            <i className="fa-solid fa-check"></i>
          </div>
        </div>
      );
    };

    const clickConfirmFavicon = async () => {
      let fileName = allGalleryImages.filter((img) => img._id === selectedFileId)[0].fileName;
      typeof fileName === "undefined" && (fileName = "");
      setSiteMetaValue("favicon", fileName);
      makeAlert(translate("mModalPageOptions.faviconUpdated", false, null), "success");
    };

    return (
      <>
        <h4>{translate("mModalPageOptions.setFavicon", false, null)}</h4>
        {/* Current favicon */}
        <div className="mt-4 mb-4 d-flex align-items-center">
          <span className="me-2">{translate("mModalPageOptions.currentFavicon", false, null)}: </span>
          {favicon === "" ? (
            <span className="text-italic">{translate("mModalPageOptions.noFaviconSelected", false, null)}</span>
          ) : (
            <img alt={favicon} src={getGalleryAssetLink(project._id, favicon)} style={{ height: "16px", width: "16px" }} />
          )}
        </div>

        {/* Browse available images */}
        <h5 className="mt-5">{translate("mModalPageOptions.selectNewFaviconFromGallery", false, null)}</h5>
        <div className="row">
          {allGalleryImages.length === 0 ? (
            <p className="text-italic">{translate("mImgGalleryListFiles.noImgsYet", false, null)}</p>
          ) : (
            imagesToRender.map((file) => <GalleryFile key={file._id} file={file} />)
          )}
        </div>
        {/* Pagination */}
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button
            className="btn btn-outline-primary trans-3"
            onClick={() => clickPageBtn(-1)}
            title={
              currPage === 0
                ? translate("mImgGalleryListFiles.theseAreFirstImgs", false, null)
                : translate("mImgGalleryListFiles.seeMoreImgs", false, null)
            }
            disabled={currPage === 0}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <span className="fontSize08 text-dark">
            {translate("mImgGalleryListFiles.showingImgs", false, null)}{" "}
            <span className="text-bold">
              {currPage * PER_PAGE + 1} - {Math.min((currPage + 1) * PER_PAGE, allGalleryImages.length)}
            </span>{" "}
            {translate("mImgGalleryListFiles.of", false, null)} <span className="text-bold">{allGalleryImages.length}</span>
          </span>
          <button
            className="btn btn-outline-primary trans-3"
            onClick={() => clickPageBtn(1)}
            title={
              currPage + 1 === maxPages
                ? translate("mImgGalleryListFiles.theseAreLatestImgs", false, null)
                : translate("mImgGalleryListFiles.seeMoreImgs", false, null)
            }
            disabled={currPage + 1 === maxPages}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
        {/* Confirm favicon */}
        <div className="d-flex justify-content-center align-items-center mt-3">
          <button className="btn btn-success px-4" disabled={selectedFileId === ""} onClick={clickConfirmFavicon}>
            {translate("mModalPageOptions.confirmSelectedImage", false, null)}
          </button>
        </div>
      </>
    );
  };

  // Google Analytics tracker
  const GoogleAnalytics = () => {
    const [gaId, setGaId] = useState("");

    useEffect(() => {
      let gaId = siteMetaVal("gaId");
      setGaId(gaId);
      // eslint-disable-next-line
    }, []);

    const clickUpdateGaId = async () => {
      setSiteMetaValue("gaId", gaId);
      makeAlert(translate("mModalPageOptions.gaIdUpdated", false, null), "success");
    };

    return (
      <>
        <h4>{translate("mModalPageOptions.setSiteGaId", false, null)}</h4>
        <div className="row mt-4">
          <div className="col-6 d-flex align-items-center">{translate("mModalPageOptions.gaId", false, null)}</div>
          <div className="col-6 d-flex align-items-center">
            <input
              className="form-control"
              type="text"
              value={gaId}
              onChange={(e) => setGaId(e.target.value)}
              placeholder={translate("mModalPageOptions.gaId", false, null)}
            />
          </div>
        </div>
        <button className="mt-3 px-4 btn btn-success" onClick={clickUpdateGaId}>
          {translate("mModalPageOptions.updateGaId", false, null)}
        </button>
      </>
    );
  };

  // Select page
  const SelectPage = () => {
    const selectPageId = (pageId) => {
      setActivePage(pageId);
      clickClose();
      injectIframeJS();
    };

    return (
      <>
        <h4>{translate("mModalPageOptions.selectPageToEditInEditor", false, null)}</h4>
        <div className="row mt-4">
          <div className="col-12 col-lg-8 col-xl-6">
            <select className="form-select" value={activePageId} onChange={(e) => selectPageId(e.target.value)}>
              {sbPages
                .sort((a, b) => (a.pageLink < b.pageLink ? -1 : a.pageLink > b.pageLink ? 1 : 0))
                .map((page) => (
                  <option key={page.pageId} value={page.pageId}>
                    {translate("mModalPageOptions.page", false, null)} "{page.pageLink}" ({translate("mModalPageOptions.version", false, null)}{" "}
                    {page.version})
                  </option>
                ))}
            </select>
          </div>
        </div>
      </>
    );
  };

  // Edit page
  const EditPage = () => {
    const [selectedPageId, setSelectedPageId] = useState(activePageId);
    const [updatedPageName, setUpdatedPageName] = useState((sbPages.filter((page) => page.pageId === activePageId)[0] || { pageLink: "" }).pageLink);
    const [errMsg_editPageName, setErrMsg_editPageName] = useState("");
    const [updatedPageTitle, setUpdatedPageTitle] = useState(
      (sbPages.filter((page) => page.pageId === activePageId)[0] || { pageTitle: "" }).pageTitle
    );
    const [errMsg_editPageTitle, setErrMsg_editPageTitle] = useState("");
    const [updatedPageDesc, setUpdatedPageDesc] = useState((sbPages.filter((page) => page.pageId === activePageId)[0] || { pageDesc: "" }).pageDesc);
    const [errMsg_editPageDesc, setErrMsg_editPageDesc] = useState("");
    const [selectedPageType, setSelectedPageType] = useState(
      (sbPages.filter((page) => page.pageId === activePageId)[0] || { pageType: "" }).pageType
    );
    const [selectedPagePreview, setSelectedPagePreview] = useState(
      typeof (sbPages.filter((page) => page.pageId === activePageId)[0] || { pagePreview: "" }).pagePreview === "undefined"
        ? ""
        : (sbPages.filter((page) => page.pageId === activePageId)[0] || { pagePreview: "" }).pagePreview
    );

    const typePageName = (str) => {
      setUpdatedPageName(str.toLowerCase().replace(/\s+?/g, "-"));
    };

    const clickEditPageInfo = () => {
      resetErrorMsgs();
      let errors = 0;
      if (updatedPageName === "") {
        setErrMsg_editPageName(translate("mModalPageOptions.pleaseEnterPageName", false, null));
        errors++;
      }
      if (sbPages.filter((page) => page.pageLink === updatedPageName && page.pageId !== selectedPageId).length > 0) {
        setErrMsg_editPageName(translate("mModalPageOptions.pageNameAlreadyExists", false, null));
        errors++;
      }
      if (updatedPageTitle === "") {
        setErrMsg_editPageTitle(translate("mModalPageOptions.pleaseEnterPageTitle", false, null));
        errors++;
      }
      //   if (updatedPageDesc === "") {
      //     setErrMsg_editPageDesc("Please enter a page description");
      //     errors++
      //   }
      if (errors === 0) {
        let oldPageName = sbPages.filter((page) => page.pageId === selectedPageId)[0].pageLink;
        editPageInfo(selectedPageId, updatedPageName, updatedPageTitle, updatedPageDesc, selectedPageType, oldPageName);
        makeAlert(translate("mModalPageOptions.pageInfoUpdated", false, null), "success");
        resetStateVars();
      }
    };

    const resetStateVars = () => {
      //   setUpdatedPageName("");
      //   setUpdatedPageTitle("")
      // setUpdatedPageDesc("")
      resetErrorMsgs();
    };

    const resetErrorMsgs = () => {
      setErrMsg_editPageName("");
      setErrMsg_editPageTitle("");
      setErrMsg_editPageDesc("");
    };

    const changeSelectedPageId = (pageId) => {
      setSelectedPageId(pageId);
      setUpdatedPageName((sbPages.filter((page) => page.pageId === pageId)[0] || { pageLink: "" }).pageLink);
      setUpdatedPageTitle((sbPages.filter((page) => page.pageId === pageId)[0] || { pageTitle: "" }).pageTitle);
      setUpdatedPageDesc((sbPages.filter((page) => page.pageId === pageId)[0] || { pageDesc: "" }).pageDesc);
    };

    return (
      <>
        <h4>{translate("mModalPageOptions.editPageInfo", false, null)}</h4>
        <div className="row mt-4">
          <div className="col-12 col-lg-8 col-xl-6">
            {/* Select page to edit */}
            <div>
              <label htmlFor="editPageSelectPageId" className="form-label">
                {translate("mModalPageOptions.selectPageToEdit", false, null)}:
              </label>
              <select id="editPageSelectPageId" className="form-select" value={selectedPageId} onChange={(e) => changeSelectedPageId(e.target.value)}>
                {sbPages
                  .sort((a, b) => (a.pageLink < b.pageLink ? -1 : a.pageLink > b.pageLink ? 1 : 0))
                  .map((page) => (
                    <option key={page.pageId} value={page.pageId}>
                      {translate("mModalPageOptions.page", false, null)} "{page.pageLink}" ({translate("mModalPageOptions.version", false, null)}{" "}
                      {page.version})
                    </option>
                  ))}
              </select>
            </div>
            {/* Edit page name */}
            <div className="my-2">
              <label htmlFor="editPageEditName" className="form-label">
                {translate("mModalPageOptions.pageName", false, null)}:
              </label>
              <input
                id="editPageEditName"
                className={`form-control${errMsg_editPageName === "" ? "" : " is-invalid"}`}
                type="text"
                placeholder={translate("mModalPageOptions.newPageName", false, null)}
                value={updatedPageName}
                onChange={(e) => typePageName(e.target.value)}
              />
              <div className="invalid-feedback">{errMsg_editPageName}</div>
            </div>
            {/* Edit page title */}
            <div className="my-2">
              <label htmlFor="editPageEditTitle" className="form-label d-flex align-items-center">
                {translate("mModalPageOptions.pageTitle", false, null)}:
                <Tooltip text={translate("mModalPageOptions.ttPageTitle", false, null)} />
                <span className="ms-auto fontSize08">
                  {updatedPageTitle.length} {translate("mModalPageOptions.chars", false, null)}
                </span>
              </label>
              <input
                id="editPageEditTitle"
                className={`form-control${errMsg_editPageTitle === "" ? "" : " is-invalid"}`}
                type="text"
                placeholder={translate("mModalPageOptions.newPageTitle", false, null)}
                value={updatedPageTitle}
                onChange={(e) => setUpdatedPageTitle(e.target.value)}
              />
              <div className="invalid-feedback">{errMsg_editPageTitle}</div>
            </div>
            {/* Edit page title */}
            <div className="my-2">
              <label htmlFor="editPageEditDesc" className="form-label d-flex align-items-center">
                {translate("mModalPageOptions.pageDesc", false, null)}:
                <Tooltip text={translate("mModalPageOptions.ttPageDesc", false, null)} />
                <span className="ms-auto fontSize08">
                  {updatedPageDesc.length} {translate("mModalPageOptions.chars", false, null)}
                </span>
              </label>
              <textarea
                id="editPageEditDesc"
                className={`form-control${errMsg_editPageDesc === "" ? "" : " is-invalid"}`}
                placeholder={translate("mModalPageOptions.newPageDesc", false, null)}
                value={updatedPageDesc}
                onChange={(e) => setUpdatedPageDesc(e.target.value)}
              />
              <div className="invalid-feedback">{errMsg_editPageDesc}</div>
            </div>
            {/* Page type */}
            <div className="my-2">
              <label htmlFor="editPageSelectType" className="form-label d-flex align-items-center">
                {translate("mModalPageOptions.pageType", false, null)}:
                <Tooltip text={translate("mModalPageOptions.ttPageType", false, null)} />
              </label>
              <select id="editPageSelectType" className="form-select" value={selectedPageType} onChange={(e) => setSelectedPageType(e.target.value)}>
                <option value="website">{translate("mModalPageOptions.pageType_website", false, null)}</option>
                <option value="article">{translate("mModalPageOptions.pageType_article", false, null)}</option>
                <option value="book">{translate("mModalPageOptions.pageType_book", false, null)}</option>
                <option value="profile">{translate("mModalPageOptions.pageType_profile", false, null)}</option>
              </select>
            </div>
            {/* Page preview */}
            <div className="my-2">
              <p className="mb-2">
                {translate("mModalPageOptions.socialPagePreview", false, null)}{" "}
                <Tooltip text={translate("mModalPageOptions.ttSocialPagePreview", false, null)} />
              </p>
              {selectedPagePreview === "" ? (
                <p className="m-0 text-italic">{translate("mModalPageOptions.noPreviewSet", false, null)}</p>
              ) : (
                <img
                  alt={selectedPagePreview}
                  src={getGalleryAssetLink(project._id, selectedPagePreview)}
                  style={{ maxHeight: "300px", maxWidth: "100%", height: "auto", width: "auto" }}
                />
              )}
              <button className="btn btn-sm btn-outline-dark mt-2" onClick={() => setSelectedMenuItem("browseSocialPreview")}>
                {translate("mModalPageOptions.browseAvailableImages", false, null)}
              </button>
            </div>
            {/* Buttons */}
            <div className="d-flex justify-content-between mt-3">
              <button className="btn btn-gray" onClick={resetStateVars}>
                {translate("mModalPageOptions.cancel", false, null)}
              </button>
              <button className="btn btn-success" onClick={clickEditPageInfo} disabled={updatedPageName === "" || updatedPageTitle === ""}>
                {translate("mModalPageOptions.editPageInfo", false, null)}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  // Delete page
  const DeletePage = () => {
    const [selectedPageId, setSelectedPageId] = useState(activePageId);

    const clickDelete = () => {
      let msg = translate("mModalPageOptions.confirmDeletePageVersion", false, {
        pageLink: sbPages.filter((page) => page.pageId === selectedPageId)[0].pageLink,
        version: sbPages.filter((page) => page.pageId === selectedPageId)[0].version,
      });
      if (window.confirm(msg)) {
        deletePage(selectedPageId);
        makeAlert(translate("mModalPageOptions.pageDeleted", false, null), "success");
      }
    };

    return (
      <>
        <h4>{translate("mModalPageOptions.deletePage", false, null)}</h4>
        <div className="row mt-4">
          <div className="col-12 col-lg-8 col-xl-6">
            {/* Select page to delete */}
            <div>
              <label htmlFor="deletePageSelectPageId" className="form-label">
                {translate("mModalPageOptions.selectPageToDelete", false, null)}:
              </label>
              <select id="deletePageSelectPageId" className="form-select" value={selectedPageId} onChange={(e) => setSelectedPageId(e.target.value)}>
                {sbPages
                  .sort((a, b) => (a.pageLink < b.pageLink ? -1 : a.pageLink > b.pageLink ? 1 : 0))
                  .map((page) => (
                    <option key={page.pageId} value={page.pageId}>
                      {translate("mModalPageOptions.page", false, null)} "{page.pageLink}" ({translate("mModalPageOptions.version", false, null)}{" "}
                      {page.version})
                    </option>
                  ))}
              </select>
            </div>
            {/* Buttons */}
            <div className="d-flex justify-content-between mt-3">
              <button className="btn btn-danger" onClick={clickDelete} disabled={sbPages.length === 1}>
                {translate("mModalPageOptions.deletePage", false, null)}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  // Add new page
  const AddNewPage = () => {
    const [newPageName, setNewPageName] = useState("");
    const [errMsg_addNewPage, setErrMsg_addNewPage] = useState("");

    const typePageName = (str) => {
      setNewPageName(
        str
          .toLowerCase()
          .replace(/\s+?/g, "-")
          .replace(/[^A-Za-z0-9-]/g, "")
          .replace(/[-]{2,}/, "-")
          .replace(/^-/, "")
      );
    };

    const clickAddNewPage = () => {
      setErrMsg_addNewPage("");
      if (getNumberOfPages(sbPages) < getSubscriptionMaxPages(workspace.subscriptions)) {
        if (newPageName === "") {
          setErrMsg_addNewPage(translate("mModalPageOptions.pleaseEnterPageName", false, null));
        } else {
          if (sbPages.filter((page) => page.pageLink === newPageName).length > 0) {
            setErrMsg_addNewPage(translate("mModalPageOptions.pageNameAlreadyExists", false, null));
          } else {
            addNewPage(newPageName);
            makeAlert(translate("mModalPageOptions.newPageAdded", false, null), "success");
            resetStateVars();
          }
        }
      } else {
        makeAlert(translate("mModalPageOptions.pleaseUpgradeSub", false, null), "danger");
        resetStateVars();
      }
    };

    const resetStateVars = () => {
      setNewPageName("");
      setErrMsg_addNewPage("");
    };

    return (
      <>
        <h4>{translate("mModalPageOptions.addANewPage", false, null)}</h4>
        <div className="row mt-4">
          <div className="col-12 col-lg-8 col-xl-6">
            <input
              className={`form-control${errMsg_addNewPage === "" ? "" : " is-invalid"}`}
              type="text"
              placeholder={translate("mModalPageOptions.newPageName", false, null)}
              value={newPageName}
              onChange={(e) => typePageName(e.target.value)}
            />
            <div className="invalid-feedback">{errMsg_addNewPage}</div>
            <div className="d-flex justify-content-between mt-3">
              <button className="btn btn-gray" onClick={resetStateVars}>
                {translate("mModalPageOptions.cancel", false, null)}
              </button>
              <button className="btn btn-success" onClick={clickAddNewPage} disabled={newPageName === ""}>
                {translate("mModalPageOptions.addNewPage", false, null)}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  // Browse page social preview
  const BrowsePageSocialPreview = () => {
    const PER_PAGE = 12;

    const [selectedFileId, setSelectedFileId] = useState("");
    const [imagesToRender, setImagesToRender] = useState([]);
    const [currPage, setCurrPage] = useState(0);

    let allGalleryImages = project !== null && typeof project.gallery !== "undefined" ? project.gallery : [];
    let maxPages = Math.ceil(allGalleryImages.length / PER_PAGE);

    useEffect(() => {
      renderImages();
      // eslint-disable-next-line
    }, [allGalleryImages, currPage]);

    const renderImages = () => {
      let start = currPage * PER_PAGE;
      let end = start + PER_PAGE;
      setImagesToRender(allGalleryImages.slice(start, end));
    };

    const clickPageBtn = (inc) => {
      setCurrPage((prev) => prev + inc);
    };

    const clickImg = (fileId) => {
      if (selectedFileId === fileId) {
        setSelectedFileId("");
      } else {
        setSelectedFileId(fileId);
      }
    };

    const GalleryFile = ({ file }) => {
      return (
        <div
          className={`col-12 col-md-4 col-lg-3 p-2 card-browse-templates${selectedFileId === file._id ? " selected" : ""}`}
          onClick={() => clickImg(file._id)}
        >
          <div className="flexSameHeight">
            <img
              src={getGalleryAssetLink(project._id, file.fileName)}
              alt={file.fileName}
              style={{ maxHeight: "250px", width: "100%", height: "auto", objectFit: "cover" }}
            />
            <div className="toGrow d-flex flex-column justify-content-end px-2 py-2">
              <div className="d-flex justify-content-between text-dark">
                <p className="fontSize09 m-0">
                  <i className="fa-regular fa-file-image me-1" />
                  {file.fileName}
                </p>
                <p className="fontSize09 m-0">
                  <i className="fa-regular fa-clock me-1" />
                  <FormatDate dateString={file.updatedAt} lang={langCode} formatStr={"d/m/yyyy"} />
                </p>
              </div>
            </div>
          </div>
          <div className="card-browse-selecticon">
            <i className="fa-solid fa-check"></i>
          </div>
        </div>
      );
    };

    const clickConfirmPageSocialPreview = async () => {
      let fileName = allGalleryImages.filter((img) => img._id === selectedFileId)[0].fileName;
      if (typeof fileName === "undefined") {
        fileName = "";
      }
      editPageSocialPreview(activePageId, fileName);
      setSelectedMenuItem("edit");
      makeAlert(translate("mModalPageOptions.pageSocialPreviewUpdated", false, null), "success");
    };

    return (
      <>
        <h4>
          {translate("mModalPageOptions.selectSocialPreviewFor", false, null)}{" "}
          <span className="text-italic">{(sbPages.filter((page) => page.pageId === activePageId)[0] || { pageLink: "" }).pageLink}</span>
        </h4>
        <div className="row">
          {allGalleryImages.length === 0 ? (
            <p className="text-italic">{translate("mImgGalleryListFiles.noImgsYet", false, null)}</p>
          ) : (
            imagesToRender.map((file) => <GalleryFile key={file._id} file={file} />)
          )}
        </div>
        {/* Pagination */}
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button
            className="btn btn-outline-primary trans-3"
            onClick={() => clickPageBtn(-1)}
            title={
              currPage === 0
                ? translate("mImgGalleryListFiles.theseAreFirstImgs", false, null)
                : translate("mImgGalleryListFiles.seeMoreImgs", false, null)
            }
            disabled={currPage === 0}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <span className="fontSize08 text-dark">
            {translate("mImgGalleryListFiles.showingImgs", false, null)}{" "}
            <span className="text-bold">
              {currPage * PER_PAGE + 1} - {Math.min((currPage + 1) * PER_PAGE, allGalleryImages.length)}
            </span>{" "}
            {translate("mImgGalleryListFiles.of", false, null)} <span className="text-bold">{allGalleryImages.length}</span>
          </span>
          <button
            className="btn btn-outline-primary trans-3"
            onClick={() => clickPageBtn(1)}
            title={
              currPage + 1 === maxPages
                ? translate("mImgGalleryListFiles.theseAreLatestImgs", false, null)
                : translate("mImgGalleryListFiles.seeMoreImgs", false, null)
            }
            disabled={currPage + 1 === maxPages}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
        {/* Go back / confirm buttons */}
        <div className="d-flex justify-content-between mt-3">
          <button className="btn btn-gray" onClick={() => setSelectedMenuItem("edit")}>
            {translate("mModalPageOptions.goBack", false, null)}
          </button>
          <button className="btn btn-success" disabled={selectedFileId === ""} onClick={clickConfirmPageSocialPreview}>
            {translate("mModalPageOptions.selectPreviewImage", false, null)}
          </button>
        </div>
      </>
    );
  };

  // Select page
  const PageVersions = () => {
    const [versionToDuplicate, setVersionToDuplicate] = useState("");

    const clickAddNewVersion = () => {
      let pageToDuplicate = structuredClone(sbPages.filter((p) => p.pageId === versionToDuplicate)[0]);

      let componentIdsToDuplicate = pageToDuplicate.components.map((c) => c.componentId);
      let newComponentIds = componentIdsToDuplicate.map((old) => getRandomId());

      let customCssToDuplicate = structuredClone(sbCustomCss.filter((css) => componentIdsToDuplicate.includes(css.componentId))).map((component) => ({
        ...component,
        classes: component.classes.map((c) => ({
          ...c,
          className: c.className.replace(component.componentId, newComponentIds[componentIdsToDuplicate.indexOf(component.componentId)]),
        })),
        componentId: newComponentIds[componentIdsToDuplicate.indexOf(component.componentId)],
      }));
      pageToDuplicate = {
        ...pageToDuplicate,
        pageId: uuidv4(),
        version: getVersionNameFromIndex(sbPages.filter((p) => p.pageLink === pageToDuplicate.pageLink).length + 1),
        components: renewClassnamesNewVersion(
          pageToDuplicate.components.map((component) => ({
            ...component,
            componentId: newComponentIds[componentIdsToDuplicate.indexOf(component.componentId)],
          })),
          componentIdsToDuplicate,
          newComponentIds
        ),
      };

      if (versionToDuplicate !== "") {
        if (sbPages.length < getSubscriptionMaxPages(workspace.subscriptions)) {
          addNewVersion(pageToDuplicate, customCssToDuplicate);
          makeAlert(translate("mModalPageOptions.newVersionAdded", false, null), "success");
        } else {
          makeAlert(translate("mModalPageOptions.pleaseUpgradeSub", false, null), "danger");
        }
      }
    };

    return (
      <>
        <h4>{translate("mModalPageOptions.addNewPageVersion", false, null)}</h4>
        <div className="row mt-4">
          <div className="col-12 col-lg-8 col-xl-6 d-flex align-items-center">
            <select className="form-select" value={versionToDuplicate} onChange={(e) => setVersionToDuplicate(e.target.value)}>
              <option value="">{translate("mModalPageOptions.selectVersionToDuplicate", false, null)}</option>
              {sbPages
                .sort((a, b) => (a.pageLink < b.pageLink ? -1 : a.pageLink > b.pageLink ? 1 : 0))
                .map((page) => (
                  <option key={page.pageId} value={page.pageId}>
                    {translate("mModalPageOptions.page", false, null)} "{page.pageLink}" ({translate("mModalPageOptions.version", false, null)}{" "}
                    {page.version})
                  </option>
                ))}
            </select>
          </div>
        </div>
        <button className="btn btn-success px-4 mt-2 mb-4" disabled={versionToDuplicate === ""} onClick={clickAddNewVersion}>
          {translate("mModalPageOptions.addAdditionalVersion", false, null)}
        </button>
      </>
    );
  };

  const makeAlert = (text, className) => {
    setLocalAlert({ text, className });
    setTimeout(() => setLocalAlert(null), 3000);
  };

  const LocalAlert = () => {
    return (
      localAlert !== null && (
        <div className={`alert alert-${localAlert.className} mb-3 py-2`} role="alert">
          {localAlert.text}
        </div>
      )
    );
  };

  return (
    <div
      className="modal fade"
      id="ModalPageOptions"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="ModalPageOptionsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
        <div className="modal-content" style={{ height: "75vh" }}>
          <div className="modal-header">
            <h3 className="modal-title" id="ModalPageOptionsLabel">
              {translate("mModalPageOptions.sitePageOptions", false, null)}
            </h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeBtn}></button>
          </div>
          <div className="modal-body p-0">
            <div className="row h-100 mx-0">
              <div className="col-3 ps-0 pe-2">
                <div className="d-flex flex-column m-0 h-100 editResult">
                  <p className="modalMenuItem" onClick={() => setSelectedMenuItem("siteLanguage")}>
                    {translate("mModalPageOptions.setSiteLang", false, null)}
                  </p>
                  <p className="modalMenuItem" onClick={() => setSelectedMenuItem("favicon")}>
                    {translate("mModalPageOptions.setSiteFavicon", false, null)}
                  </p>
                  <p className="modalMenuItem" onClick={() => setSelectedMenuItem("googleAnalytics")}>
                    {translate("mModalPageOptions.setSiteGaId", false, null)}
                  </p>
                  <p className="modalMenuItem" onClick={() => setSelectedMenuItem("select")}>
                    {translate("mModalPageOptions.selectPageToEdit", false, null)}
                  </p>
                  <p className="modalMenuItem" onClick={() => setSelectedMenuItem("edit")}>
                    {translate("mModalPageOptions.editPageNameMetaData", false, null)}
                  </p>
                  <p className="modalMenuItem" onClick={() => setSelectedMenuItem("pageVersions")}>
                    {translate("mModalPageOptions.managePageVersions", false, null)}
                  </p>
                  <p className="modalMenuItem" onClick={() => setSelectedMenuItem("add")}>
                    {translate("mModalPageOptions.addANewPage", false, null)}
                  </p>
                  <p className="modalMenuItem" onClick={() => setSelectedMenuItem("delete")}>
                    {translate("mModalPageOptions.deleteAPage", false, null)}
                  </p>
                </div>
              </div>
              <div className="col-9 ps-3 pe-0">
                <div className="m-0 p-3 h-100 editResult">
                  {localLoading.isLoading ? (
                    <div className="mt-5">
                      <Spinner msg={localLoading.msg} />
                    </div>
                  ) : (
                    <>
                      <LocalAlert />
                      {selectedMenuItem === "siteLanguage" && <SiteLangCode />}
                      {selectedMenuItem === "favicon" && <SiteFavicon />}
                      {selectedMenuItem === "googleAnalytics" && <GoogleAnalytics />}
                      {selectedMenuItem === "select" && <SelectPage />}
                      {selectedMenuItem === "add" && <AddNewPage />}
                      {selectedMenuItem === "edit" && <EditPage />}
                      {selectedMenuItem === "pageVersions" && <PageVersions />}
                      {selectedMenuItem === "delete" && <DeletePage />}
                      {selectedMenuItem === "browseSocialPreview" && <BrowsePageSocialPreview />}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-gray" data-bs-dismiss="modal">
              {translate("mModalPageOptions.close", false, null)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalPageOptions.propTypes = {
  auth: PropTypes.object.isRequired,
  sb: PropTypes.object.isRequired,
  addNewPage: PropTypes.func.isRequired,
  addNewVersion: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  editPageInfo: PropTypes.func.isRequired,
  editPageSocialPreview: PropTypes.func.isRequired,
  deletePage: PropTypes.func.isRequired,
  setSiteMetaValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sb: state.sb,
});

export default connect(mapStateToProps, {
  addNewPage,
  addNewVersion,
  setActivePage,
  editPageInfo,
  editPageSocialPreview,
  deletePage,
  setSiteMetaValue,
})(ModalPageOptions);
