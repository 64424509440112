export const forms2 = {
  id: "forms-2",
  name: "Forms 2",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "forms-2-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["forms-2-wrapper"],
        styles: [],
        attributes: [{ property: "data-herowrapper", value: "true" }],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "img",
            classes: ["forms-2-img"],
            styles: [],
            attributes: [
              {
                property: "src",
                value: "https://cdn.satonda.com/eb/assets/beach6.jpg",
              },
              { property: "alt", value: "Image" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["p-3", "forms-2-content"],
            styles: [],
            attributes: [
              { property: "data-name", value: "hero1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["row", "justify-content-center", "align-items-stretch"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-6"],
                    styles: [],
                    attributes: [
                      { property: "data-sbform", value: "true" },
                      { property: "data-sbformaction", value: "" },
                      { property: "data-sbformmsg", value: "Your message has been received!" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["w-100", "forms-2-inputwrapper"],
                        styles: [],
                        attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "label",
                            classes: ["form-control", "forms-2-label"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "inputlabel" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Your name",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "input",
                            classes: ["form-control", "forms-2-inputfield"],
                            styles: [],
                            attributes: [
                              { property: "type", value: "text" },
                              { property: "name", value: "name" },
                              { property: "placeholder", value: "Name" },
                              { property: "data-name", value: "inputfield" },
                              { property: "data-required", value: "true" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["w-100", "forms-2-inputwrapper"],
                        styles: [],
                        attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "label",
                            classes: ["form-control", "forms-2-label"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "inputlabel" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Your email address",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "input",
                            classes: ["form-control", "forms-2-inputfield"],
                            styles: [],
                            attributes: [
                              { property: "type", value: "email" },
                              { property: "name", value: "email" },
                              { property: "placeholder", value: "Email address" },
                              { property: "data-name", value: "inputfield" },
                              { property: "data-required", value: "true" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["forms-2-buttonwrapper"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "button",
                            classes: ["forms-2-button"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "button" },
                              { property: "data-sbformbtn", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Subscribe!",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.forms-2-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      {
        className: "section.forms-2-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-2-component",
        pseudo: "",
        rules: [
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "forms-2-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-2-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "height", value: "50vh" },
        ],
      },
      {
        className: "forms-2-img",
        pseudo: "",
        rules: [
          { property: "display", value: "block" },
          { property: "height", value: "100%" },
          { property: "width", value: "100%" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "forms-2-content",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "background", value: "rgba(255, 255, 255, 0.3)" },
        ],
      },
      {
        className: "forms-2-buttonwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "forms-2-buttonwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-2-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
          { property: "width", value: "100%" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "forms-2-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "forms-2-inputwrapper",
        pseudo: "",
        rules: [{ property: "margin", value: "0 0 1rem 0" }],
      },
      {
        className: "forms-2-inputfield",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(206, 212, 218, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.2s ease" },
          { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
          { property: "font-size", value: "1rem" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
          { property: "outline", value: "0" },
        ],
      },
      {
        className: "forms-2-inputfield",
        pseudo: "focus",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
        ],
      },
      {
        className: "forms-2-inputfield",
        pseudo: ":placeholder",
        rules: [
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "forms-2-inputfield",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-2-label",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.25rem 0" },
          { property: "display", value: "none" },
        ],
      },
      {
        className: "forms-2-label",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
