export const cEditComponent = {
  elementSettings: {
    en: "Element settings",
    nl: "Element instellingen",
  },
  textEditor: {
    en: "Text editor",
    nl: "Tekstopmaak",
  },
  alignColItems: {
    en: "Align column items",
    nl: "Lijn kolomitems uit",
  },
  horiAlignment: {
    en: "Horizontal alignment",
    nl: "Horizontale uitlijning",
  },
  elementWidth: {
    en: "Element width",
    nl: "Element breedte",
  },
  alignColsWithinRow: {
    en: "Align columns within row",
    nl: "Lijn kolommen uit binnen rij",
  },
  bgColor: {
    en: "Background color",
    nl: "Achtergrondskleur",
  },
  border: {
    en: "Border",
    nl: "Kant",
  },
  borderRadius: {
    en: "Border radius",
    nl: "Hoekradius",
  },
  boxShadow: {
    en: "Shadow",
    nl: "Schaduw",
  },
  btnStyles: {
    en: "Button styles",
    nl: "Knop stijl",
  },
  containerWidth: {
    en: "Container width",
    nl: "Container breedte",
  },
  selectIcon: {
    en: "Select icon",
    nl: "Selecteer icoon",
  },
  imgSrc: {
    en: "Image source",
    nl: "Afbeelding bron",
  },
  imgFilters: {
    en: "Image filters",
    nl: "Afbeelding filters",
  },
  imgSize: {
    en: "Image size",
    nl: "Afbeelding grootte",
  },
  linkSettingsStyles: {
    en: "Link settings and styles",
    nl: "Link instellingen en stijl",
  },
  listMarker: {
    en: "List marker style",
    nl: "Opsommingsteken stijl",
  },
  margin: {
    en: "Margin",
    nl: "Marge",
  },
  padding: {
    en: "Padding",
    nl: "Opvulling",
  },
  layout: {
    en: "Layout",
    nl: "Layout",
  },
  textShadow: {
    en: "Shadow",
    nl: "Schaduw",
  },
  textColor: {
    en: "Text color",
    nl: "Tekst kleur",
  },
  hoverTransitionSpeed: {
    en: "Hover transition speed",
    nl: "Transitiesnelheid",
  },
  googleMaps: {
    en: "Google Maps",
    nl: "Google Maps",
  },
  hero: {
    en: "Hero component",
    nl: "Hero component",
  },
  card: {
    en: "Card component",
    nl: "Kaart component",
  },
  parallax: {
    en: "Parallax component",
    nl: "Parallax component",
  },
  process: {
    en: "Process component",
    nl: "Proces component",
  },
  testimonial: {
    en: "Testimonial component",
    nl: "Getuigenis component",
  },
  section: {
    en: "Section divider",
    nl: "Sectie verdeler",
  },
  component: {
    en: "Component",
    nl: "Component",
  },
  animate: {
    en: "Animate",
    nl: "Animatie",
  },
  navbarMain: {
    en: "Main navbar component",
    nl: "Navbar component",
  },
  navbarLogo: {
    en: "Navbar logo",
    nl: "Navbar logo",
  },
  navbarScrolledPast: {
    en: "Navbar styles after scrolled past",
    nl: "Navbar stijl na scrol",
  },
  navbarToggler: {
    en: "Navbar toggler",
    nl: "Navbar schakelaar",
  },
  navbarLinks: {
    en: "Navbar links",
    nl: "Navbar links",
  },
  imgGallery: {
    en: "Image gallery",
    nl: "Fotogallerij",
  },
  carouselSettings: {
    en: "Carousel settings",
    nl: "Carousel instellingen",
  },
  carouselNav: {
    en: "Carousel navigation styles",
    nl: "Carousel navigatie stijl",
  },
  carouselPag: {
    en: "Carousel pagination styles",
    nl: "Carousel paginering stijl",
  },
  backToTop: {
    en: "Back to top button",
    nl: "Scroll omhoog knop",
  },
  formActions: {
    en: "Form actions",
    nl: "Formulier acties",
  },
  inputFieldSettings: {
    en: "Input field settings",
    nl: "Invoerveld instellingen",
  },
  inputFieldStyles: {
    en: "Input field styles",
    nl: "Invoerveld stijl",
  },
  headingLine: {
    en: "Heading line",
    nl: "Koptekst lijn",
  },
  sectionLine: {
    en: "Section line",
    nl: "Sectielijn",
  },
  applyChanges: {
    en: "Apply changes",
    nl: "Veranderingen toepassen",
  },
  noEditableElementSelected: {
    en: "No editable element selected.",
    nl: "Geen bewerkbaar element geselecteerd.",
  },
};
