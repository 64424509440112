export const quotes11 = {
  id: "quotes-11",
  name: "Quotes 11",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "quote-11-component"],
    styles: [],
    attributes: [
      { property: "data-name", value: "carousel" },
      { property: "data-editable", value: "true" },
    ],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["splide", "carousel-slides", "quote-11-splide"],
        styles: [],
        attributes: [
          { property: "aria-label", value: "Carousel" },
          {
            property: "data-splide",
            value:
              '{"type":"loop","rewindSpeed":1000,"speed":500,"easing":"linear","drag":"free","perPage":2,"arrows":true,"pagination":true,"breakpoints":{"640":{"perPage":1,"perMove":1}},"perMove":1,"keyboard":true,"autoplay":true,"interval":2000,"pauseOnHover":true,"classes":{"arrow":"splide__arrow quote-11-arrow","prev":"splide__arrow--prev quote-11-prev","next":"splide__arrow--next quote-11-next","page":"splide__pagination__page quote-11-pagination"}}',
          },
          { property: "data-carousel-slidesetup", value: "image&text" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["splide__track"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["splide__list"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "quote-11-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "quote-11-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-12", "col-lg-12", "quote-11-col"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "callout_testimonial" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["flexSameHeight", "quote-11-wrapper"],
                                styles: [],
                                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["toGrow", "quote-11-balloon"],
                                    styles: [],
                                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "p",
                                        classes: ["quote-11-text3", "w-100", "w-md-100", "w-lg-100"],
                                        styles: [],
                                        attributes: [
                                          { property: "data-editable", value: "true" },
                                          { property: "data-texteditable", value: "true" },
                                          { property: "data-name", value: "p" },
                                        ],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "textNode",
                                            classes: [],
                                            styles: [],
                                            attributes: [],
                                            content:
                                              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                                            children: [],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["d-flex", "align-items-center", "w-100"],
                                    styles: [],
                                    attributes: [],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "img",
                                        classes: ["quote-11-avatar"],
                                        styles: [],
                                        attributes: [
                                          { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                                          { property: "data-editable", value: "true" },
                                          { property: "data-name", value: "img" },
                                        ],
                                        content: "",
                                        children: [],
                                      },
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "div",
                                        classes: ["quote-5-captionwrapper"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["quote-11-text1", "w-100", "w-md-100", "w-lg-100"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-name", value: "p" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Name",
                                                children: [],
                                              },
                                            ],
                                          },
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["quote-11-text2", "w-100", "w-md-100", "w-lg-100"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-name", value: "p" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Function / company /  location",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "quote-11-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "quote-11-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-12", "col-lg-12", "quote-11-col"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "callout_testimonial" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["flexSameHeight", "quote-11-wrapper"],
                                styles: [],
                                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["toGrow", "quote-11-balloon"],
                                    styles: [],
                                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "p",
                                        classes: ["quote-11-text3", "w-100", "w-md-100", "w-lg-100"],
                                        styles: [],
                                        attributes: [
                                          { property: "data-editable", value: "true" },
                                          { property: "data-texteditable", value: "true" },
                                          { property: "data-name", value: "p" },
                                        ],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "textNode",
                                            classes: [],
                                            styles: [],
                                            attributes: [],
                                            content:
                                              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                                            children: [],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["d-flex", "align-items-center", "w-100"],
                                    styles: [],
                                    attributes: [],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "img",
                                        classes: ["quote-11-avatar"],
                                        styles: [],
                                        attributes: [
                                          { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                                          { property: "data-editable", value: "true" },
                                          { property: "data-name", value: "img" },
                                        ],
                                        content: "",
                                        children: [],
                                      },
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "div",
                                        classes: ["quote-5-captionwrapper"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["quote-11-text1", "w-100", "w-md-100", "w-lg-100"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-name", value: "p" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Name",
                                                children: [],
                                              },
                                            ],
                                          },
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["quote-11-text2", "w-100", "w-md-100", "w-lg-100"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-name", value: "p" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Function / company /  location",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "quote-11-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "quote-11-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-12", "col-lg-12", "quote-11-col"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "callout_testimonial" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["flexSameHeight", "quote-11-wrapper"],
                                styles: [],
                                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["toGrow", "quote-11-balloon"],
                                    styles: [],
                                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "p",
                                        classes: ["quote-11-text3", "w-100", "w-md-100", "w-lg-100"],
                                        styles: [],
                                        attributes: [
                                          { property: "data-editable", value: "true" },
                                          { property: "data-texteditable", value: "true" },
                                          { property: "data-name", value: "p" },
                                        ],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "textNode",
                                            classes: [],
                                            styles: [],
                                            attributes: [],
                                            content:
                                              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                                            children: [],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["d-flex", "align-items-center", "w-100"],
                                    styles: [],
                                    attributes: [],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "img",
                                        classes: ["quote-11-avatar"],
                                        styles: [],
                                        attributes: [
                                          { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                                          { property: "data-editable", value: "true" },
                                          { property: "data-name", value: "img" },
                                        ],
                                        content: "",
                                        children: [],
                                      },
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "div",
                                        classes: ["quote-5-captionwrapper"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["quote-11-text1", "w-100", "w-md-100", "w-lg-100"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-name", value: "p" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Name",
                                                children: [],
                                              },
                                            ],
                                          },
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["quote-11-text2", "w-100", "w-md-100", "w-lg-100"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-name", value: "p" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Function / company /  location",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "quote-11-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "quote-11-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-12", "col-lg-12", "quote-11-col"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "callout_testimonial" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["flexSameHeight", "quote-11-wrapper"],
                                styles: [],
                                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["toGrow", "quote-11-balloon"],
                                    styles: [],
                                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "p",
                                        classes: ["quote-11-text3", "w-100", "w-md-100", "w-lg-100"],
                                        styles: [],
                                        attributes: [
                                          { property: "data-editable", value: "true" },
                                          { property: "data-texteditable", value: "true" },
                                          { property: "data-name", value: "p" },
                                        ],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "textNode",
                                            classes: [],
                                            styles: [],
                                            attributes: [],
                                            content:
                                              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                                            children: [],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["d-flex", "align-items-center", "w-100"],
                                    styles: [],
                                    attributes: [],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "img",
                                        classes: ["quote-11-avatar"],
                                        styles: [],
                                        attributes: [
                                          { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                                          { property: "data-editable", value: "true" },
                                          { property: "data-name", value: "img" },
                                        ],
                                        content: "",
                                        children: [],
                                      },
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "div",
                                        classes: ["quote-5-captionwrapper"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["quote-11-text1", "w-100", "w-md-100", "w-lg-100"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-name", value: "p" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Name",
                                                children: [],
                                              },
                                            ],
                                          },
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["quote-11-text2", "w-100", "w-md-100", "w-lg-100"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-name", value: "p" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Function / company /  location",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "quote-11-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "quote-11-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-12", "col-lg-12", "quote-11-col"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "callout_testimonial" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["flexSameHeight", "quote-11-wrapper"],
                                styles: [],
                                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["toGrow", "quote-11-balloon"],
                                    styles: [],
                                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "p",
                                        classes: ["quote-11-text3", "w-100", "w-md-100", "w-lg-100"],
                                        styles: [],
                                        attributes: [
                                          { property: "data-editable", value: "true" },
                                          { property: "data-texteditable", value: "true" },
                                          { property: "data-name", value: "p" },
                                        ],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "textNode",
                                            classes: [],
                                            styles: [],
                                            attributes: [],
                                            content:
                                              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                                            children: [],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["d-flex", "align-items-center", "w-100"],
                                    styles: [],
                                    attributes: [],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "img",
                                        classes: ["quote-11-avatar"],
                                        styles: [],
                                        attributes: [
                                          { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                                          { property: "data-editable", value: "true" },
                                          { property: "data-name", value: "img" },
                                        ],
                                        content: "",
                                        children: [],
                                      },
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "div",
                                        classes: ["quote-5-captionwrapper"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["quote-11-text1", "w-100", "w-md-100", "w-lg-100"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-name", value: "p" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Name",
                                                children: [],
                                              },
                                            ],
                                          },
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["quote-11-text2", "w-100", "w-md-100", "w-lg-100"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-name", value: "p" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Function / company /  location",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "quote-11-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "quote-11-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-12", "col-lg-12", "quote-11-col"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "callout_testimonial" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["flexSameHeight", "quote-11-wrapper"],
                                styles: [],
                                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["toGrow", "quote-11-balloon"],
                                    styles: [],
                                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "p",
                                        classes: ["quote-11-text3", "w-100", "w-md-100", "w-lg-100"],
                                        styles: [],
                                        attributes: [
                                          { property: "data-editable", value: "true" },
                                          { property: "data-texteditable", value: "true" },
                                          { property: "data-name", value: "p" },
                                        ],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "textNode",
                                            classes: [],
                                            styles: [],
                                            attributes: [],
                                            content:
                                              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                                            children: [],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["d-flex", "align-items-center", "w-100"],
                                    styles: [],
                                    attributes: [],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "img",
                                        classes: ["quote-11-avatar"],
                                        styles: [],
                                        attributes: [
                                          { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                                          { property: "data-editable", value: "true" },
                                          { property: "data-name", value: "img" },
                                        ],
                                        content: "",
                                        children: [],
                                      },
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "div",
                                        classes: ["quote-5-captionwrapper"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["quote-11-text1", "w-100", "w-md-100", "w-lg-100"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-name", value: "p" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Name",
                                                children: [],
                                              },
                                            ],
                                          },
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["quote-11-text2", "w-100", "w-md-100", "w-lg-100"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-name", value: "p" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Function / company /  location",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.quote-11-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.quote-11-component", pseudo: "hover", rules: [] },
      {
        className: "quote-11-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "2rem 2rem 2rem 2rem" },
          { property: "display", value: "flex" },
        ],
      },
      {
        className: "quote-11-col",
        pseudo: "hover",
        rules: [],
      },
      { className: "quote-11-wrapper", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
      {
        className: "quote-11-wrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-11-avatar",
        pseudo: "",
        rules: [
          { property: "border-radius", value: "50%" },
          { property: "height", value: "100px" },
          { property: "width", value: "auto" },
          { property: "margin", value: "1rem 0 0 0" },
        ],
      },
      {
        className: "quote-11-avatar",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-11-captionwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "quote-11-captionwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-11-text1",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0.5rem 1rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "quote-11-text1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-11-text2",
        pseudo: "",
        rules: [
          { property: "font-style", value: "italic" },
          { property: "margin", value: "0 0 0 1rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "quote-11-text2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-11-quote-left",
        pseudo: "",
        rules: [
          { property: "display", value: "inline" },
          { property: "font-size", value: "2rem" },
          { property: "color", value: "rgba(100, 100, 100, 1)" },
        ],
      },
      {
        className: "quote-11-balloon",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "box-shadow", value: "10px 10px 5px -5px rgba(0, 0, 0, 0.5)" },
          { property: "background", value: "rgba(250, 250, 250, 1)" },
          { property: "border-radius", value: "0.4rem" },
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "width", value: "100%" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "quote-11-balloon",
        pseudo: "hover",
        rules: [
          { property: "box-shadow", value: "10px 10px 5px -5px rgba(0, 0, 0, 0.5)" },
          { property: "background", value: "rgba(250, 250, 250, 1)" },
        ],
      },
      {
        className: "quote-11-balloon",
        pseudo: "after",
        rules: [
          { property: "content", value: '""' },
          { property: "position", value: "absolute" },
          { property: "top", value: "100%" },
          { property: "width", value: "0" },
          { property: "height", value: "0" },
          { property: "border-top", value: "25px solid rgba(250, 250, 250, 1)" },
          { property: "border-right", value: "40px solid transparent" },
          { property: "left", value: "17%" },
          { property: "filter", value: "drop-shadow(1px 5px 2px rgba(0, 0, 0, 0.5))" },
        ],
      },
      {
        className: "quote-11-text3",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 1rem 1rem 1rem" },
          { property: "text-align", value: "center" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "quote-11-text3",
        pseudo: "hover",
        rules: [],
      },
      // Pagination & navigation
      {
        className: "quote-11-arrow",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.5)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-radius", value: "50%" },
          { property: "height", value: "2rem" },
          { property: "width", value: "2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "quote-11-arrow",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "quote-11-arrow svg",
        pseudo: "",
        rules: [
          { property: "fill", value: "rgba(0, 0, 0, 0.7)" },
          { property: "height", value: "1.2rem" }, // (size - 0.8)rem
          { property: "width", value: "1.2rem" }, // (size - 0.8)rem
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "quote-11-arrow svg",
        pseudo: "hover",
        rules: [{ property: "fill", value: "rgba(0, 0, 0, 1)" }],
      },
      {
        className: "quote-11-prev",
        pseudo: "",
        rules: [
          { property: "left", value: "1rem" }, // Inside: 1rem || Outside: -(size + 1)rem
        ],
      },
      {
        className: "quote-11-next",
        pseudo: "",
        rules: [
          { property: "right", value: "1rem" }, // Inside: 1rem, outside: -(size+1)rem
        ],
      },
      {
        className: "quote-11-pagination",
        pseudo: "",
        rules: [
          { property: "height", value: "0.7rem" },
          { property: "width", value: "0.7rem" },
          { property: "background", value: "rgba(255, 255, 255, 0.5)" },
          { property: "margin", value: "0.25rem" },
          { property: "border-radius", value: "50%" },
          { property: "display", value: "inline-block" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "quote-11-pagination",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.8)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scale(1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "is-active.quote-11-pagination",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.8)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scale(1)" },
          { property: "opacity", value: "1" },
        ],
      },
    ],
  },
};
