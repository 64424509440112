import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Tooltip from "./Tooltip";

import { EDIT_FORM_ID_PREFIX } from "../../../../../lib/editFunctions";

const ImgSizeInput = ({ sb: { selectedElement }, label, id, startValue, tooltipText, infoText = "" }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(startValue);
  }, [startValue, selectedElement]);

  return (
    <div className="w-100 d-flex align-items-center my-3">
      <div className="w-50">
        {label}
        <Tooltip text={tooltipText} />
      </div>
      <div className="w-50 d-flex flex-column justify-content-center">
        <div className="input-group input-group-sm">
          <input type="number" className="form-control" id={EDIT_FORM_ID_PREFIX + id} value={value} onChange={(e) => setValue(e.target.value)} />
          <span className="input-group-text">px</span>
        </div>
        {infoText !== "" && <p className="m-0 text-italic fontSize08 text-center">{infoText}</p>}
      </div>
    </div>
  );
};

ImgSizeInput.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(ImgSizeInput);
