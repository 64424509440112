export const lPublishSite = {
  publishSite: {
    en: "Publishing site..",
    nl: "Site publiceren..",
  },
  websiteDeployedTo: {
    en: "Website successfully deployed to %domainName%!",
    nl: "Website succesvol gepubliceerd op %domainName%!",
  },
  errorWhileDeploying: {
    en: "An error occured while deploying your project. Please try again.",
    nl: "Er is een fout opgetreden tijdens het publiceren van uw project. Probeer het aub opnieuw.",
  },
  filesUploaded: {
    en: "Files successfully uploaded!",
    nl: "Bestanden geupload!",
  },
  errorWhileUploading: {
    en: "Server error while uploading your files. Please try again.",
    nl: "Er is een serverfout opgetreden tijdens het uploaden van uw bestanden. Probeer het aub opnieuw.",
  },
};
