export const sectiondividers81 = {
  id: "sectiondividers-81",
  name: "Section dividers 81",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "svg",
        classes: [],
        styles: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scaleY(-1)" },
        ],
        attributes: [
          { property: "xmlns", value: "http://www.w3.org/2000/svg" },
          { property: "version", value: "1.0" },
          { property: "width", value: "100%" },
          { property: "height", value: "140" },
          { property: "viewBox", value: "0 0 1280 140" },
          { property: "preserveAspectRatio", value: "none" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              {
                property: "d",
                value:
                  "M 0 140 L 40 100 L 80 140 L 120 100 L 160 140 L 200 100 L 240 140 L 280 100 L 320 140 L 360 100 L 400 140 L 440 100 L 480 140 L 520 100 L 560 140 L 600 100 L 640 140 L 680 100 L 720 140 L 760 100 L 800 140 L 840 100 L 880 140 L 920 100 L 960 140 L 1000 100 L 1040 140 L 1080 100 L 1120 140 L 1160 100 L 1200 140 L 1240 100 L 1280 140 Z",
              },
              { property: "opacity", value: "1" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              {
                property: "d",
                value:
                  "M 0 140 L 0 60 L 40 100 L 80 60 L 120 100 L 160 60 L 200 100 L 240 60 L 280 100 L 320 60 L 360 100 L 400 60 L 440 100 L 480 60 L 520 100 L 560 60 L 600 100 L 640 60 L 680 100 L 720 60 L 760 100 L 800 60 L 840 100 L 880 60 L 920 100 L 960 60 L 1000 100 L 1040 60 L 1080 100 L 1120 60 L 1160 100 L 1200 60 L 1240 100 L 1280 60 L 1280 140 Z",
              },
              { property: "opacity", value: "0.5" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              {
                property: "d",
                value:
                  "M 0 140 L 0 60 L 40 20 L 80 60 L 120 20 L 160 60 L 200 20 L 240 60 L 280 20 L 320 60 L 360 20 L 400 60 L 440 20 L 480 60 L 520 20 L 560 60 L 600 20 L 640 60 L 680 20 L 720 60 L 760 20 L 800 60 L 840 20 L 880 60 L 920 20 L 960 60 L 1000 20 L 1040 60 L 1080 20 L 1120 60 L 1160 20 L 1200 60 L 1240 20 L 1280 60 L 1280 140 Z",
              },
              { property: "opacity", value: "0.25" },
            ],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
