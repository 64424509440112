export const cSiteSyle = {
  siteColorScheme: {
    en: "Site color scheme",
    nl: "Site kleurenpallet",
  },
  color: {
    en: "Color",
    nl: "Kleur",
  },
  editColor: {
    en: "Edit color",
    nl: "Bewerk kleur",
  },
  needInspiration: {
    en: "Need inspiration?",
    nl: "Inspiratie nodig?",
  },
  openColorSchemeGenerator: {
    en: "Open color scheme generator",
    nl: "Open kleurenpallet generator",
  },
  siteFonts: {
    en: "Site fonts",
    nl: "Site lettertypes",
  },
  headers: {
    en: "Headers",
    nl: "Tekstkoppen",
  },
  changeFont: {
    en: "Change font",
    nl: "Verander lettertype",
  },
  body: {
    en: "Body",
    nl: "Normale tekst",
  },
  switchHeadersBodyFont: {
    en: "Switch headers and body fonts",
    nl: "Wissel koppen en tekst lettertypes",
  },
};
