export const basics7 = {
  id: "basics-7",
  name: "Basic building blocks - Button",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "basics-7-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "a",
        classes: ["basics-7-link"],
        styles: [],
        attributes: [
          { property: "href", value: "#!" },
          { property: "data-href", value: "#!" },
          { property: "data-target", value: "_self" },
          { property: "data-editable", value: "true" },
          { property: "data-name", value: "button" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "textNode",
            classes: [],
            styles: [],
            attributes: [],
            content: "Button link",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.basics-7-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.basics-7-component", pseudo: "hover", rules: [] },
      {
        className: "basics-7-component",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "basics-7-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "basics-7-link",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
          { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
          { property: "width", value: "auto" },
          { property: "text-decoration", value: "none" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "basics-7-link",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(250, 89, 61, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(250, 89, 61, 1)" },
        ],
      },
    ],
  },
};
