import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FormatDate from "../layout/FormatDate";

import { selectImgFromGallery } from "../../actions/sb";
import { getGalleryAssetLink } from "../../lib/generalFunctions";
import { translate } from "../../translations/translations";

const ModalSelectImgFromGallery = ({ auth: { langCode }, sb: { project, startStyles }, selectImgFromGallery }) => {
  const PER_PAGE = 12;

  const [selectedFileId, setSelectedFileId] = useState("");
  const [imagesToRender, setImagesToRender] = useState([]);
  const [currPage, setCurrPage] = useState(0);

  let allGalleryImages = project !== null && typeof project.gallery !== "undefined" ? project.gallery : [];
  let maxPages = Math.ceil(allGalleryImages.length / PER_PAGE);

  useEffect(() => {
    renderImages();
    // eslint-disable-next-line
  }, [allGalleryImages, currPage]);

  const renderImages = () => {
    let start = currPage * PER_PAGE;
    let end = start + PER_PAGE;
    setImagesToRender(allGalleryImages.slice(start, end));
  };

  const clickPageBtn = (inc) => {
    setCurrPage((prev) => prev + inc);
  };

  const clickImg = (fileId) => {
    if (selectedFileId === fileId) {
      setSelectedFileId("");
    } else {
      setSelectedFileId(fileId);
    }
  };

  const GalleryFile = ({ file }) => {
    return (
      <div
        className={`col-12 col-md-4 col-lg-2 p-2 card-browse-templates${selectedFileId === file._id ? " selected" : ""}`}
        onClick={() => clickImg(file._id)}
      >
        <div className="flexSameHeight">
          <img
            src={getGalleryAssetLink(project._id, file.fileName)}
            alt={file.fileName}
            style={{ maxHeight: "250px", width: "100%", height: "auto", objectFit: "cover" }}
          />
          <div className="toGrow d-flex flex-column justify-content-end px-2 py-2">
            <div className="d-flex justify-content-between text-dark">
              <p className="fontSize09 m-0">
                <i className="fa-regular fa-file-image me-1" />
                {file.fileName}
              </p>
              <p className="fontSize09 m-0">
                <i className="fa-regular fa-clock me-1" />
                <FormatDate dateString={file.updatedAt} lang={langCode} formatStr={"d/m/yyyy"} />
              </p>
            </div>
          </div>
        </div>
        <div className="card-browse-selecticon">
          <i className="fa-solid fa-check"></i>
        </div>
      </div>
    );
  };

  const clickSelectImage = () => {
    try {
      let selectedFile = project.gallery.filter((file) => file._id === selectedFileId)[0];
      if (typeof startStyles.parallaxImgSrc !== "undefined") {
        selectImgFromGallery(getGalleryAssetLink(project._id, selectedFile.fileName), "parallaxImgSrc", selectedFile.fileDesc);
      } else {
        selectImgFromGallery(getGalleryAssetLink(project._id, selectedFile.fileName), "imgSource", selectedFile.fileDesc);
      }
      setSelectedFileId("");
    } catch (error) {
      console.error(error);
      setSelectedFileId("");
    }
  };

  const clickClose = () => {
    setSelectedFileId("");
  };

  return (
    <div
      className="modal fade"
      id="ModalSelectImgFromGallery"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="ModalSelectImgFromGalleryLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" style={{ maxWidth: "80vw" }}>
        <div className="modal-content" style={{ height: "90vh" }}>
          <div className="modal-header">
            <h3 className="modal-title" id="ModalSelectImgFromGalleryLabel">
              {translate("mModalSelectImgFromGallery.selectImgFromGallery", false, null)}
            </h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clickClose}></button>
          </div>
          <div className="modal-body">
            <h4 className="text-primary">{translate("mImgGalleryListFiles.yourImgs", false, null)}</h4>
            {/* Browse available images */}
            <div className="row">
              {allGalleryImages.length === 0 ? (
                <p className="text-italic">{translate("mImgGalleryListFiles.noImgsYet", false, null)}</p>
              ) : (
                imagesToRender.map((file) => <GalleryFile key={file._id} file={file} />)
              )}
            </div>
            {/* Pagination */}
            <div className="d-flex justify-content-between align-items-center mt-3">
              <button
                className="btn btn-outline-primary trans-3"
                onClick={() => clickPageBtn(-1)}
                title={
                  currPage === 0
                    ? translate("mImgGalleryListFiles.theseAreFirstImgs", false, null)
                    : translate("mImgGalleryListFiles.seeMoreImgs", false, null)
                }
                disabled={currPage === 0}
              >
                <i className="fa-solid fa-chevron-left"></i>
              </button>
              <span className="fontSize08 text-dark">
                {translate("mImgGalleryListFiles.showingImgs", false, null)}{" "}
                <span className="text-bold">
                  {currPage * PER_PAGE + 1} - {Math.min((currPage + 1) * PER_PAGE, allGalleryImages.length)}
                </span>{" "}
                {translate("mImgGalleryListFiles.of", false, null)} <span className="text-bold">{allGalleryImages.length}</span>
              </span>
              <button
                className="btn btn-outline-primary trans-3"
                onClick={() => clickPageBtn(1)}
                title={
                  currPage + 1 === maxPages
                    ? translate("mImgGalleryListFiles.theseAreLatestImgs", false, null)
                    : translate("mImgGalleryListFiles.seeMoreImgs", false, null)
                }
                disabled={currPage + 1 === maxPages}
              >
                <i className="fa-solid fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-gray px-4" data-bs-dismiss="modal" onClick={clickClose}>
              {translate("mModalSelectImgFromGallery.close", false, null)}
            </button>
            <button
              type="button"
              className="btn btn-success px-4"
              data-bs-dismiss="modal"
              disabled={selectedFileId === ""}
              onClick={clickSelectImage}
            >
              {translate("mModalSelectImgFromGallery.selectImg", false, null)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalSelectImgFromGallery.propTypes = {
  auth: PropTypes.object.isRequired,
  sb: PropTypes.object.isRequired,
  selectImgFromGallery: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sb: state.sb,
});

export default connect(mapStateToProps, { selectImgFromGallery })(ModalSelectImgFromGallery);
