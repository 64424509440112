export const sectiondividers72 = {
  id: "sectiondividers-72",
  name: "Section dividers 72",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "svg",
        classes: [],
        styles: [{ property: "background", value: "rgba(255, 255, 255, 1)" }],
        attributes: [
          { property: "xmlns", value: "http://www.w3.org/2000/svg" },
          { property: "width", value: "100%" },
          { property: "height", value: "150" },
          { property: "viewBox", value: "0 0 1280 140" },
          { property: "preserveAspectRatio", value: "none" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              {
                property: "d",
                value:
                  "M156 35.41l95.46 34.73 120.04.25 71.5 33.24 90.09-3.89L640 137.25l102.39-37.06 85.55 10.61 88.11-7.17L992 65.08l73.21 5.31L1132 48.35l77-.42L1280 0H0l64.8 38.57 91.2-3.16z",
              },
              { property: "opacity", value: "0.5" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              {
                property: "d",
                value:
                  "M156 28.32l95.46 27.79 120.04.2L443 82.9l90.09-3.11L640 109.8l102.39-29.65 85.55 8.49 88.11-5.74L992 52.07l73.21 4.24L1132 38.68l77-.34L1280 0H0l64.8 30.86 91.2-2.54z",
              },
            ],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
