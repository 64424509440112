export const cAddNewComponent = {
  // Component template names
  compName_buildingBlock_title: {
    en: "Building block - Header",
    nl: "Bouwsteen - Tekstkop",
  },
  compName_buildingBlock_headingLine: {
    en: "Building block - Heading line",
    nl: "Bouwsteen - Koplijn",
  },
  compName_buildingBlock_sectionLine: {
    en: "Building block - Section line",
    nl: "Bouwsteen - Sectielijn",
  },
  compName_buildingBlock_para: {
    en: "Building block - Paragraph",
    nl: "Bouwsteen - Paragraaf",
  },
  compName_buildingBlock_btn: {
    en: "Building block - Button link",
    nl: "Bouwsteen - Link als knop",
  },
  compName_buildingBlock_img: {
    en: "Building block - Image",
    nl: "Bouwsteen - Afbeelding",
  },
  compName_buildingBlock_cols1: {
    en: "Building block - Columns (1)",
    nl: "Bouwsteen - Kolommen (1)",
  },
  compName_buildingBlock_cols2: {
    en: "Building block - Columns (2)",
    nl: "Bouwsteen - Kolommen (2)",
  },
  compName_backToTop: {
    en: "Back to top button",
    nl: `"Scroll omhoog" knop`,
  },
  // Other
  basics: {
    en: "Basic building blocks",
    nl: "Bouwstenen",
  },
  navbar: {
    en: "Navbar",
    nl: "Navigatiebar",
  },
  hero: {
    en: "Hero",
    nl: "Hero",
  },
  cards: {
    en: "Cards",
    nl: "Kaarten",
  },
  latestNews: {
    en: "Latest news",
    nl: "Laatste nieuws",
  },
  clients: {
    en: "Clients",
    nl: "Klanten",
  },
  contactUs: {
    en: "Contact us",
    nl: "Contact",
  },
  footer: {
    en: "Footer",
    nl: "Footer",
  },
  callToAction: {
    en: "Call to action",
    nl: "Roep tot actie",
  },
  parallax: {
    en: "Parallax",
    nl: "Parallax",
  },
  imgGallery: {
    en: "Image gallery",
    nl: "Afbeeldinggallerij",
  },
  pricetables: {
    en: "Price tables",
    nl: "Prijstabellen",
  },
  process: {
    en: "Process",
    nl: "Proces",
  },
  testimonials: {
    en: "Testimonials",
    nl: "Recensies",
  },
  statistics: {
    en: "Statistics",
    nl: "Statistieken",
  },
  resumes: {
    en: "CVs / resumes",
    nl: "CVs",
  },
  restaurantMenus: {
    en: "Restaurant menus",
    nl: "Restaurant menus",
  },
  forms: {
    en: "Forms",
    nl: "Formulieren",
  },
  others: {
    en: "Others",
    nl: "Andere",
  },
  sectionDividers: {
    en: "Section dividers",
    nl: "Sectie afscheiders",
  },
};
