export const clients1 = {
  id: "clients-1",
  name: "Clients 1",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "clients-1-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["d-flex", "flex-wrap", "align-items-center", "justify-content-center", "clients-1-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["clients-1-logo-wrapper"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["clients-1-logo-img"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                  { property: "alt", value: "logo" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                ],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["clients-1-logo-wrapper"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["clients-1-logo-img"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                  { property: "alt", value: "logo" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                ],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["clients-1-logo-wrapper"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["clients-1-logo-img"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/client-3.png" },
                  { property: "alt", value: "logo" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                ],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["clients-1-logo-wrapper"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["clients-1-logo-img"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/client-4.png" },
                  { property: "alt", value: "logo" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                ],
                content: "",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.clients-1-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.clients-1-component", pseudo: "hover", rules: [] },
      {
        className: "clients-1-logo-img",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 1.5rem 1.5rem 0" },
          { property: "max-height", value: "50px" },
          { property: "max-width", value: "100px" },
          { property: "height", value: "auto" },
          { property: "width", value: "auto" },
          { property: "filter", value: "grayscale(100%)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "opacity", value: "0.5" },
        ],
      },
      {
        className: "clients-1-logo-img",
        pseudo: "hover",
        rules: [
          { property: "opacity", value: "1" },
          { property: "filter", value: "grayscale(0%)" },
        ],
      },
    ],
  },
};
