export const basics4 = {
  id: "basics-4",
  name: "Basic building blocks 4 - Colums",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "basics-4-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "basics-4-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "basics4" }],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-6", "order-first", "basics-4-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["basics-4-img"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/work-1.png" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                  { property: "data-imgresizable", value: "false" },
                ],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-6", "basics-4-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["basics-4-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "This is a title",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["basics-4-text", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "a",
                classes: ["basics-4-link-readmore"],
                styles: [],
                attributes: [
                  { property: "href", value: "#!" },
                  { property: "data-href", value: "#!" },
                  { property: "data-target", value: "_self" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "button" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Read more",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.basics-4-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.basics-4-component", pseudo: "hover", rules: [] },
      {
        className: "basics-4-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "2rem 2rem 2rem 2rem" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "overflow", value: "hidden" },
        ],
      },
      {
        className: "basics-4-img",
        pseudo: "",
        rules: [
          { property: "width", value: "100%" },
          { property: "max-height", value: "350px" },
          { property: "height", value: "auto" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "basics-4-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "basics-4-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "margin", value: "0rem 0rem 1rem 0rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "basics-4-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "basics-4-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(10, 10, 10, 1)" },
          { property: "margin", value: "1rem 0rem 1rem 0rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "basics-4-text",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "basics-4-link-readmore",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
          { property: "padding", value: "0.5rem 1.5rem 0.5rem 1.5rem" },
          { property: "width", value: "auto" },
          { property: "text-decoration", value: "none" },
          { property: "align-self", value: "start" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "basics-4-link-readmore",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
        ],
      },
    ],
  },
};
