export const sectiondividers38 = {
  id: "sectiondividers-38",
  name: "Section dividers 38",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: [],
        styles: [
          { property: "position", value: "relative" },
          { property: "height", value: "302px" },
        ],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "svg",
            classes: [],
            styles: [
              { property: "position", value: "absolute" },
              { property: "top", value: "0" },
              { property: "left", value: "0" },
              { property: "width", value: "100%" },
              { property: "height", value: "302px" },
              { property: "transform", value: "scaleY(-1)" },
              { property: "background", value: "rgba(255, 255, 255, 1)" },
            ],
            attributes: [
              { property: "xmlns", value: "http://www.w3.org/2000/svg" },
              { property: "viewBox", value: "0 0 1150 183" },
              { property: "preserveAspectRatio", value: "xMidYMin slice" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M12.21 140.7a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M6.11 118.47a6.11 6.11 0 0 0 6.1-6.11V84A6.11 6.11 0 1 0 0 84v28.33a6.11 6.11 0 0 0 6.11 6.14z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M6.11 61.8a6.11 6.11 0 0 0 6.1-6.11v-7.08a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M31.5 126.53a6.11 6.11 0 1 0-12.22 0V183H31.5z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M25.39 104.3a6.11 6.11 0 0 0 6.11-6.11V41.52a6.11 6.11 0 0 0-12.22 0v56.67a6.11 6.11 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M50.78 98.19a6.11 6.11 0 0 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M44.67 76a6.11 6.11 0 0 0 6.11-6.11V41.52a6.11 6.11 0 0 0-12.21 0v28.34a6.11 6.11 0 0 0 6.1 6.14z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M44.67 19.3a6.11 6.11 0 0 0 6.11-6.11V6.11a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M70.06 126.53a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M64 104.3a6.1 6.1 0 0 0 6.1-6.11V84a6.11 6.11 0 1 0-12.21 0v14.19A6.11 6.11 0 0 0 64 104.3z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M89.35 140.7a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M77.14 98.19v14.17a6.11 6.11 0 1 0 12.21 0V98.19a6.11 6.11 0 0 0-12.21 0z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M79.85 74.94a5.54 5.54 0 0 0 1.05.55 5.9 5.9 0 0 0 1.15.36 6 6 0 0 0 1.2.12 5.93 5.93 0 0 0 2.33-.48 5.25 5.25 0 0 0 1-.55 6.92 6.92 0 0 0 .93-.77 6 6 0 0 0 1.67-5.51 6.42 6.42 0 0 0-.91-2.19 6 6 0 0 0-1.69-1.69 6.59 6.59 0 0 0-1-.56 5.83 5.83 0 0 0-1.15-.34 6 6 0 0 0-2.38 0 5.83 5.83 0 0 0-1.15.34 7.05 7.05 0 0 0-1.05.56 6.09 6.09 0 0 0-.92.76 5.62 5.62 0 0 0-.76.93 5.11 5.11 0 0 0-.56 1.06 6.46 6.46 0 0 0-.36 1.13 7 7 0 0 0-.12 1.2 6.13 6.13 0 0 0 1.8 4.31 6.82 6.82 0 0 0 .92.77z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M108.63 140.7a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M102.53 118.47a6.11 6.11 0 0 0 6.1-6.11V84a6.11 6.11 0 1 0-12.21 0v28.33a6.11 6.11 0 0 0 6.11 6.14z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M102.53 61.8a6.11 6.11 0 0 0 6.1-6.11v-7.08a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M127.92 112.36a6.11 6.11 0 0 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M121.81 90.13a6.11 6.11 0 0 0 6.11-6.1V41.52a6.11 6.11 0 0 0-12.22 0V84a6.11 6.11 0 0 0 6.11 6.13z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M147.2 41.52a6.11 6.11 0 0 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M141.09 19.3a6.11 6.11 0 0 0 6.11-6.11V6.11a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M166.48 112.36a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M160.37 90.14a6 6 0 0 0 1.2-.13 6.08 6.08 0 0 0 1.15-.35 5.87 5.87 0 0 0 1.05-.56 6.06 6.06 0 0 0 2.71-5.1 5.91 5.91 0 0 0-.46-2.35 7.05 7.05 0 0 0-.56-1.05 6.11 6.11 0 0 0-6.27-2.58 5.83 5.83 0 0 0-1.15.34 7.05 7.05 0 0 0-1 .56 5.23 5.23 0 0 0-.93.76 6 6 0 0 0-.75.92 6.29 6.29 0 0 0-.57 1.05 6.08 6.08 0 0 0-.35 1.15 5.37 5.37 0 0 0-.12 1.2 6.13 6.13 0 0 0 6.1 6.11z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M185.77 154.86a6.11 6.11 0 0 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M179.66 132.63a6.11 6.11 0 0 0 6.11-6.1V84a6.11 6.11 0 0 0-12.22 0v42.5a6.11 6.11 0 0 0 6.11 6.13z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M179.66 61.8a6.12 6.12 0 0 0 6.11-6.11v-7.08a6.11 6.11 0 0 0-12.22 0v7.08a6.12 6.12 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M205.05 140.7a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M199 118.47a6.11 6.11 0 0 0 6.1-6.11V98.19a6.11 6.11 0 0 0-12.21 0v14.17a6.11 6.11 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M199 76a6.11 6.11 0 0 0 6.1-6.11v-7.11a6.11 6.11 0 1 0-12.21 0v7.08A6.11 6.11 0 0 0 199 76z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M224.34 126.53a6.11 6.11 0 0 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M218.23 104.3a6.11 6.11 0 0 0 6.11-6.11V41.52a6.11 6.11 0 0 0-12.22 0v56.67a6.11 6.11 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M243.62 91.11a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M237.51 28.33a6.11 6.11 0 0 0-6.1 6.11v28.34a6.11 6.11 0 0 0 12.21 0V34.44a6.11 6.11 0 0 0-6.11-6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M231.87 8.44a6.59 6.59 0 0 0 .56 1.05 5.29 5.29 0 0 0 .77.93 6.34 6.34 0 0 0 .93.77 5.83 5.83 0 0 0 1.05.55 5.17 5.17 0 0 0 1.14.35 6 6 0 0 0 1.19.13 6 6 0 0 0 1.2-.13 5.17 5.17 0 0 0 1.14-.35 5.92 5.92 0 0 0 1.06-.55 6.92 6.92 0 0 0 .93-.77 6.17 6.17 0 0 0 1.78-4.31 6 6 0 0 0-.12-1.2 7 7 0 0 0-.34-1.13 6.42 6.42 0 0 0-.57-1.07 6 6 0 0 0-.75-.92 5.62 5.62 0 0 0-.93-.76 6.69 6.69 0 0 0-1.06-.56 6.53 6.53 0 0 0-1.14-.35 6.4 6.4 0 0 0-2.39 0 6.21 6.21 0 0 0-2.19.91 5.23 5.23 0 0 0-.93.76 5.61 5.61 0 0 0-.77.92 6.38 6.38 0 0 0-.56 1.07 5.46 5.46 0 0 0-.34 1.13 5.37 5.37 0 0 0-.12 1.2 5.25 5.25 0 0 0 .12 1.18 5.64 5.64 0 0 0 .34 1.15z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M262.9 126.53a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M256.8 104.3a6.1 6.1 0 0 0 6.1-6.11V84a6.11 6.11 0 1 0-12.21 0v14.19a6.1 6.1 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M282.19 140.7a6.11 6.11 0 0 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M276.08 118.47a6.12 6.12 0 0 0 6.11-6.11V84A6.11 6.11 0 0 0 270 84v28.33a6.12 6.12 0 0 0 6.08 6.14z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M276.08 61.8a6.12 6.12 0 0 0 6.11-6.11v-7.08a6.11 6.11 0 0 0-12.22 0v7.08a6.12 6.12 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M301.47 169a6.11 6.11 0 1 0-12.21 0v14h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M295.37 146.8a6.1 6.1 0 0 0 6.1-6.1v-28.34a6.11 6.11 0 1 0-12.21 0v28.34a6.1 6.1 0 0 0 6.11 6.1z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M295.37 90.13a6.1 6.1 0 0 0 6.1-6.1v-7.09a6.11 6.11 0 0 0-12.21 0V84a6.1 6.1 0 0 0 6.11 6.13z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M320.76 126.53a6.11 6.11 0 0 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M314.65 104.3a6.11 6.11 0 0 0 6.11-6.11V69.86a6.11 6.11 0 0 0-12.22 0v28.33a6.11 6.11 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M340 69.86a6.11 6.11 0 1 0-12.21 0V183H340z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M333.93 47.63a6.1 6.1 0 0 0 6.07-6.11v-5.66a6.11 6.11 0 1 0-12.21 0v5.66a6.1 6.1 0 0 0 6.14 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M333.93 15.05A6.11 6.11 0 0 0 340 8.94V6.11a6.11 6.11 0 1 0-12.21 0v2.83a6.11 6.11 0 0 0 6.14 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M359.32 126.53a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M353.22 104.3a6.1 6.1 0 0 0 6.1-6.11V84a6.11 6.11 0 1 0-12.21 0v14.19a6.1 6.1 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M378.61 112.36a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M372.5 90.13a6.11 6.11 0 0 0 6.11-6.1V55.69a6.11 6.11 0 0 0-12.21 0V84a6.1 6.1 0 0 0 6.1 6.13z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M372.5 33.46a6.11 6.11 0 0 0 6.11-6.1v-7.09a6.11 6.11 0 0 0-12.21 0v7.09a6.1 6.1 0 0 0 6.1 6.1z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M397.91 140.7a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M391.81 118.47a6.11 6.11 0 0 0 6.1-6.11V84a6.11 6.11 0 1 0-12.21 0v28.33a6.11 6.11 0 0 0 6.11 6.14z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M391.81 61.8a6.11 6.11 0 0 0 6.1-6.11v-7.08a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M417.2 126.53a6.11 6.11 0 0 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M411.09 104.3a6.11 6.11 0 0 0 6.11-6.11V41.52a6.11 6.11 0 0 0-12.22 0v56.67a6.11 6.11 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M436.48 98.19a6.11 6.11 0 0 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M430.37 76a6.11 6.11 0 0 0 6.11-6.11V41.52a6.11 6.11 0 0 0-12.21 0v28.34a6.11 6.11 0 0 0 6.1 6.14z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M430.37 19.3a6.11 6.11 0 0 0 6.11-6.11V6.11a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M455.76 126.53a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M449.66 104.3a6.1 6.1 0 0 0 6.1-6.11V84a6.11 6.11 0 1 0-12.21 0v14.19a6.1 6.1 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M475.05 140.7a6.11 6.11 0 0 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M462.83 98.19v14.17a6.11 6.11 0 0 0 12.22 0V98.19a6.11 6.11 0 0 0-12.22 0z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M465.55 74.94a5.54 5.54 0 0 0 1.05.55 5.9 5.9 0 0 0 1.15.36 6 6 0 0 0 2.38 0 5.9 5.9 0 0 0 1.15-.36 5.25 5.25 0 0 0 1.05-.55 6.92 6.92 0 0 0 .93-.77 6.08 6.08 0 0 0 1.67-5.51 6.42 6.42 0 0 0-.91-2.19 6 6 0 0 0-1.69-1.69 6.59 6.59 0 0 0-1.05-.56 5.83 5.83 0 0 0-1.15-.34 6 6 0 0 0-2.38 0 5.83 5.83 0 0 0-1.15.34 7.05 7.05 0 0 0-1.05.56 6.17 6.17 0 0 0-.93.76 6.11 6.11 0 0 0-.75.93 5.67 5.67 0 0 0-.57 1.06 7.39 7.39 0 0 0-.35 1.13 7 7 0 0 0-.12 1.2 6.16 6.16 0 0 0 1.79 4.31 6.92 6.92 0 0 0 .93.77z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M494.33 140.7a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M488.22 118.47a6.11 6.11 0 0 0 6.11-6.11V84a6.11 6.11 0 1 0-12.21 0v28.33a6.11 6.11 0 0 0 6.1 6.14z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M488.22 61.8a6.11 6.11 0 0 0 6.11-6.11v-7.08a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M513.61 112.36a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M507.51 90.13a6.1 6.1 0 0 0 6.1-6.1V41.52a6.11 6.11 0 0 0-12.21 0V84a6.11 6.11 0 0 0 6.11 6.13z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M532.9 41.52a6.11 6.11 0 0 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M526.79 19.3a6.11 6.11 0 0 0 6.11-6.11V6.11a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M552.18 112.36a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M546.07 90.14a6 6 0 0 0 1.2-.13 6.08 6.08 0 0 0 1.15-.35 5.87 5.87 0 0 0 1.05-.56 6.06 6.06 0 0 0 2.71-5.1 5.91 5.91 0 0 0-.46-2.35 7.11 7.11 0 0 0-.57-1.05 6 6 0 0 0-.75-.92 6.12 6.12 0 0 0-5.51-1.66 5.83 5.83 0 0 0-1.15.34 6.59 6.59 0 0 0-1 .56 5.23 5.23 0 0 0-.93.76 5.49 5.49 0 0 0-.75.92 5.64 5.64 0 0 0-.57 1.05 5.43 5.43 0 0 0-.35 1.15A5.37 5.37 0 0 0 540 84a6.13 6.13 0 0 0 6.1 6.11z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M571.47 154.86a6.11 6.11 0 0 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M565.36 132.63a6.11 6.11 0 0 0 6.11-6.1V84a6.11 6.11 0 1 0-12.22 0v42.5a6.11 6.11 0 0 0 6.11 6.13z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M565.36 61.8a6.12 6.12 0 0 0 6.11-6.11v-7.08a6.11 6.11 0 1 0-12.22 0v7.08a6.12 6.12 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M590.75 140.7a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M584.64 118.47a6.11 6.11 0 0 0 6.11-6.11V98.19a6.11 6.11 0 0 0-12.21 0v14.17a6.11 6.11 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M584.64 76a6.11 6.11 0 0 0 6.11-6.11v-7.11a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.1 6.14z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M610 126.53a6.11 6.11 0 1 0-12.21 0V183H610z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M603.93 104.3a6.1 6.1 0 0 0 6.1-6.11V41.52a6.11 6.11 0 0 0-12.21 0v56.67a6.11 6.11 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M629.32 91.11a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M623.21 28.33a6.11 6.11 0 0 0-6.1 6.11v28.34a6.11 6.11 0 0 0 12.21 0V34.44a6.11 6.11 0 0 0-6.11-6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M617.57 8.44a6.59 6.59 0 0 0 .56 1.05 5.29 5.29 0 0 0 .77.93 6.34 6.34 0 0 0 .93.77 5.83 5.83 0 0 0 1 .55 5 5 0 0 0 1.14.35 5.56 5.56 0 0 0 2.39 0 4.94 4.94 0 0 0 1.13-.35 5.68 5.68 0 0 0 1.07-.55 6.82 6.82 0 0 0 .92-.77 6.14 6.14 0 0 0 1.79-4.31 6 6 0 0 0-.12-1.2 6.17 6.17 0 0 0-.35-1.13 5.72 5.72 0 0 0-.56-1.07 5.55 5.55 0 0 0-.76-.92 5.55 5.55 0 0 0-.92-.76 6.38 6.38 0 0 0-1.07-.56 6.17 6.17 0 0 0-1.13-.35 6.4 6.4 0 0 0-2.39 0 6.27 6.27 0 0 0-1.14.35 6.59 6.59 0 0 0-1 .56 5.23 5.23 0 0 0-.93.76 5.61 5.61 0 0 0-.77.92 6.38 6.38 0 0 0-.56 1.07 5.46 5.46 0 0 0-.34 1.13 5.38 5.38 0 0 0-.13 1.2 5.27 5.27 0 0 0 .13 1.18 5.64 5.64 0 0 0 .34 1.15z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M648.6 126.53a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M642.5 104.3a6.1 6.1 0 0 0 6.1-6.11V84a6.11 6.11 0 1 0-12.21 0v14.19a6.1 6.1 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M667.89 140.7a6.11 6.11 0 0 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M661.78 118.47a6.12 6.12 0 0 0 6.11-6.11V84a6.11 6.11 0 0 0-12.22 0v28.33a6.12 6.12 0 0 0 6.11 6.14z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M661.78 61.8a6.12 6.12 0 0 0 6.11-6.11v-7.08a6.11 6.11 0 0 0-12.22 0v7.08a6.12 6.12 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M687.17 169a6.11 6.11 0 1 0-12.17 0v14h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M681.06 146.8a6.1 6.1 0 0 0 6.11-6.1v-28.34a6.11 6.11 0 1 0-12.21 0v28.34a6.1 6.1 0 0 0 6.1 6.1z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M681.06 90.13a6.1 6.1 0 0 0 6.11-6.1v-7.09a6.11 6.11 0 0 0-12.21 0V84a6.1 6.1 0 0 0 6.1 6.13z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M706.45 126.53a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M700.35 104.3a6.1 6.1 0 0 0 6.1-6.11V69.86a6.11 6.11 0 1 0-12.21 0v28.33a6.1 6.1 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M725.74 69.86a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M719.63 47.63a6.1 6.1 0 0 0 6.11-6.11v-5.66a6.11 6.11 0 1 0-12.21 0v5.66a6.1 6.1 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M719.63 15.05a6.11 6.11 0 0 0 6.11-6.11V6.11a6.11 6.11 0 1 0-12.21 0v2.83a6.11 6.11 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M745 126.53a6.11 6.11 0 1 0-12.21 0V183H745z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M738.92 104.3a6.1 6.1 0 0 0 6.1-6.11V84a6.11 6.11 0 1 0-12.21 0v14.19a6.1 6.1 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M764.31 112.36a6.11 6.11 0 1 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M758.2 90.13a6.11 6.11 0 0 0 6.11-6.1V55.69a6.11 6.11 0 0 0-12.22 0V84a6.11 6.11 0 0 0 6.11 6.13z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M758.2 33.46a6.11 6.11 0 0 0 6.11-6.1v-7.09a6.11 6.11 0 0 0-12.22 0v7.09a6.11 6.11 0 0 0 6.11 6.1z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M783.61 140.7a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M777.5 118.47a6.11 6.11 0 0 0 6.11-6.11V84a6.11 6.11 0 1 0-12.21 0v28.33a6.11 6.11 0 0 0 6.1 6.14z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M777.5 61.8a6.11 6.11 0 0 0 6.11-6.11v-7.08a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M802.89 126.53a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M796.79 104.3a6.1 6.1 0 0 0 6.1-6.11V41.52a6.11 6.11 0 0 0-12.21 0v56.67a6.1 6.1 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M822.18 98.19a6.11 6.11 0 0 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M816.07 76a6.12 6.12 0 0 0 6.11-6.11V41.52a6.11 6.11 0 0 0-12.22 0v28.34a6.12 6.12 0 0 0 6.11 6.14z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M816.07 19.3a6.12 6.12 0 0 0 6.11-6.11V6.11a6.11 6.11 0 1 0-12.22 0v7.08a6.12 6.12 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M841.46 126.53a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M835.35 104.3a6.1 6.1 0 0 0 6.11-6.11V84a6.11 6.11 0 1 0-12.21 0v14.19a6.1 6.1 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M860.74 140.7a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M848.53 98.19v14.17a6.11 6.11 0 1 0 12.21 0V98.19a6.11 6.11 0 0 0-12.21 0z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M851.25 74.94a5.25 5.25 0 0 0 1 .55 5.93 5.93 0 0 0 2.34.48 6 6 0 0 0 1.19-.12 5.9 5.9 0 0 0 1.15-.36 5.54 5.54 0 0 0 1-.55 6.82 6.82 0 0 0 .92-.77 6.13 6.13 0 0 0 1.8-4.31 7 7 0 0 0-.12-1.2 6.46 6.46 0 0 0-.36-1.13 5.11 5.11 0 0 0-.56-1.06 5.62 5.62 0 0 0-.76-.93 6.09 6.09 0 0 0-.92-.76 7.05 7.05 0 0 0-1-.56 5.83 5.83 0 0 0-1.15-.34 6 6 0 0 0-2.38 0 5.83 5.83 0 0 0-1.15.34 6.59 6.59 0 0 0-1 .56 6 6 0 0 0-1.69 1.69 6.42 6.42 0 0 0-.91 2.19 6.08 6.08 0 0 0 1.67 5.51 6.92 6.92 0 0 0 .93.77z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M880 140.7a6.11 6.11 0 1 0-12.21 0V183H880z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M873.92 118.47a6.12 6.12 0 0 0 6.11-6.11V84a6.11 6.11 0 1 0-12.21 0v28.33a6.11 6.11 0 0 0 6.1 6.14z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M873.92 61.8a6.12 6.12 0 0 0 6.08-6.11v-7.08a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.13 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M899.31 112.36a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M893.21 90.13a6.1 6.1 0 0 0 6.1-6.1V41.52a6.11 6.11 0 0 0-12.21 0V84a6.1 6.1 0 0 0 6.11 6.13z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M918.6 41.52a6.11 6.11 0 0 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M912.49 19.3a6.12 6.12 0 0 0 6.11-6.11V6.11a6.11 6.11 0 0 0-12.22 0v7.08a6.12 6.12 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M937.88 112.36a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M931.77 90.14A6 6 0 0 0 933 90a5.77 5.77 0 0 0 1.14-.35 6 6 0 0 0 1.06-.56 6.15 6.15 0 0 0 2.68-5.09 6.87 6.87 0 0 0-.11-1.2 7 7 0 0 0-.36-1.15 7.05 7.05 0 0 0-.56-1.05 5.55 5.55 0 0 0-.76-.92 6.09 6.09 0 0 0-5.5-1.66 5.46 5.46 0 0 0-1.15.34 6.59 6.59 0 0 0-1.05.56 5.23 5.23 0 0 0-.93.76 6.09 6.09 0 0 0-.76.92 6.23 6.23 0 0 0-.56 1.05 6.43 6.43 0 0 0-.48 2.35 6.15 6.15 0 0 0 6.11 6.11z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M957.16 154.86a6.11 6.11 0 0 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M951.06 132.63a6.1 6.1 0 0 0 6.1-6.1V84A6.11 6.11 0 1 0 945 84v42.5a6.1 6.1 0 0 0 6.06 6.13z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M951.06 61.8a6.11 6.11 0 0 0 6.1-6.11v-7.08a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M976.45 140.7a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M970.34 118.47a6.12 6.12 0 0 0 6.11-6.11V98.19a6.11 6.11 0 0 0-12.21 0v14.17a6.11 6.11 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M970.34 76a6.12 6.12 0 0 0 6.11-6.11v-7.11a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.1 6.14z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M995.73 126.53a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M989.63 104.3a6.1 6.1 0 0 0 6.1-6.11V41.52a6.11 6.11 0 0 0-12.21 0v56.67a6.1 6.1 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1015 91.11a6.11 6.11 0 0 0-12.22 0V183H1015z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1008.91 28.33a6.12 6.12 0 0 0-6.11 6.11v28.34a6.11 6.11 0 0 0 12.22 0V34.44a6.12 6.12 0 0 0-6.11-6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M1003.27 8.44a5.87 5.87 0 0 0 .56 1.05 4.88 4.88 0 0 0 .77.93 5.73 5.73 0 0 0 2 1.32 4.94 4.94 0 0 0 1.13.35 6.2 6.2 0 0 0 1.2.13 6.12 6.12 0 0 0 1.2-.13 4.94 4.94 0 0 0 1.13-.35 5.6 5.6 0 0 0 1.06-.55 6.92 6.92 0 0 0 .93-.77 6.14 6.14 0 0 0 1.75-4.31 5.38 5.38 0 0 0-.13-1.2 5.46 5.46 0 0 0-.34-1.13 6.38 6.38 0 0 0-.56-1.07 6.09 6.09 0 0 0-.76-.92 5.62 5.62 0 0 0-.93-.76 6.28 6.28 0 0 0-1.06-.56 6.17 6.17 0 0 0-1.13-.35 6.46 6.46 0 0 0-2.4 0 6.17 6.17 0 0 0-1.13.35 6.59 6.59 0 0 0-1.05.56 4.9 4.9 0 0 0-.93.76 5.15 5.15 0 0 0-.77.92 5.72 5.72 0 0 0-.56 1.07 6.17 6.17 0 0 0-.35 1.13 6 6 0 0 0-.12 1.2 5.91 5.91 0 0 0 .12 1.18 6.38 6.38 0 0 0 .37 1.15z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1034.3 126.53a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1028.19 104.3a6.1 6.1 0 0 0 6.11-6.11V84a6.11 6.11 0 1 0-12.21 0v14.19a6.1 6.1 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1053.58 140.7a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1047.48 118.47a6.11 6.11 0 0 0 6.1-6.11V84a6.11 6.11 0 1 0-12.21 0v28.33a6.11 6.11 0 0 0 6.11 6.14z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1047.48 61.8a6.11 6.11 0 0 0 6.1-6.11v-7.08a6.11 6.11 0 1 0-12.21 0v7.08a6.11 6.11 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1072.87 169a6.11 6.11 0 1 0-12.22 0v14h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1066.76 146.8a6.11 6.11 0 0 0 6.11-6.1v-28.34a6.11 6.11 0 0 0-12.22 0v28.34a6.11 6.11 0 0 0 6.11 6.1z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1066.76 90.13a6.11 6.11 0 0 0 6.11-6.1v-7.09a6.11 6.11 0 0 0-12.22 0V84a6.11 6.11 0 0 0 6.11 6.13z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1092.15 126.53a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1086 104.3a6.1 6.1 0 0 0 6.11-6.11V69.86a6.11 6.11 0 1 0-12.21 0v28.33a6.1 6.1 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1111.44 69.86a6.11 6.11 0 0 0-12.22 0V183h12.22z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1105.33 47.63a6.11 6.11 0 0 0 6.11-6.11v-5.66a6.11 6.11 0 0 0-12.22 0v5.66a6.11 6.11 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1105.33 15.05a6.12 6.12 0 0 0 6.11-6.11V6.11a6.11 6.11 0 0 0-12.22 0v2.83a6.12 6.12 0 0 0 6.11 6.11z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1130.72 126.53a6.11 6.11 0 1 0-12.21 0V183h12.21z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1124.61 104.3a6.1 6.1 0 0 0 6.11-6.11V84a6.11 6.11 0 1 0-12.21 0v14.19a6.1 6.1 0 0 0 6.1 6.11z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1150 112.36a6.11 6.11 0 1 0-12.21 0V183H1150z" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1143.9 90.13A6.1 6.1 0 0 0 1150 84V55.69a6.11 6.11 0 0 0-12.21 0V84a6.1 6.1 0 0 0 6.11 6.13z" },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1143.9 33.46a6.1 6.1 0 0 0 6.1-6.1v-7.09a6.11 6.11 0 0 0-12.21 0v7.09a6.1 6.1 0 0 0 6.11 6.1z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
