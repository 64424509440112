export const template27 = {
  id: "66004413abd27773018c6994",
  name: { en: "Site under construction", nl: "Website in de maak" },
  desc: { en: "Placeholder for a website under construction", nl: "Placeholder voor een website in opbouw" },
  categories: ["underConstruction"],
  sbPages: [
    {
      pageId: "723831c6-b430-4a03-85ee-a1c277f0fefb",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "ef1SVY",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "forms-6-component-ef1SVY"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "d75JAb",
              type: "",
              htmlTagName: "div",
              classes: ["forms-6-wrapper-ef1SVY"],
              styles: [],
              attributes: [{ property: "data-herowrapper", value: "true" }],
              content: "",
              children: [
                {
                  childId: "Edppnf",
                  type: "",
                  htmlTagName: "img",
                  classes: ["forms-6-img-ef1SVY"],
                  styles: [],
                  attributes: [
                    { property: "src", value: "https://cdn.satonda.com/eb/assets/abstract3.jpg" },
                    { property: "alt", value: "Image" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "xzdJyL",
                  type: "",
                  htmlTagName: "div",
                  classes: ["p-3", "forms-6-content-ef1SVY"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "hero1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "xCoGYM",
                      type: "",
                      htmlTagName: "img",
                      classes: ["forms-6-logo-img-ef1SVY"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/under-construction-white.png" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                    {
                      childId: "Mlghpk",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["forms-6-title-ef1SVY", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "UNDER CONSTRUCTION",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "U6LwzR",
                      type: "",
                      htmlTagName: "p",
                      classes: ["forms-6-text-ef1SVY", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Sign up to be the first to know when we go live!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "pA3olj",
                      type: "",
                      htmlTagName: "div",
                      classes: ["row", "justify-content-center", "align-items-stretch"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "vvyJG3",
                          type: "",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-6"],
                          styles: [],
                          attributes: [
                            { property: "data-sbform", value: "true" },
                            { property: "data-sbformaction", value: "" },
                            { property: "data-sbformmsg", value: "Your message has been received!" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "wYCIi3",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "forms-6-inputwrapper-ef1SVY"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "O301EY",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "forms-6-label-ef1SVY"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your email address",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "l77Nqy",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "forms-6-inputfield-ef1SVY"],
                                  styles: [],
                                  attributes: [
                                    { property: "type", value: "email" },
                                    { property: "name", value: "email" },
                                    { property: "placeholder", value: "Email address" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "upYoAf",
                              type: "",
                              htmlTagName: "div",
                              classes: ["forms-6-button-ef1SVYwrapper-ef1SVY"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "LJioGv",
                                  type: "",
                                  htmlTagName: "button",
                                  classes: ["forms-6-button-ef1SVY"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "button" },
                                    { property: "data-sbformbtn", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Subscribe!",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  sbCustomCss: [
    {
      componentId: "ef1SVY",
      classes: [
        {
          className: "section.forms-6-component-ef1SVY",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.forms-6-component-ef1SVY", pseudo: "hover", rules: [] },
        {
          className: "forms-6-component-ef1SVY",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "forms-6-component-ef1SVY", pseudo: "hover", rules: [] },
        {
          className: "forms-6-wrapper-ef1SVY",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
          ],
        },
        {
          className: "forms-6-img-ef1SVY",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "forms-6-content-ef1SVY",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 0.3)" },
          ],
        },
        {
          className: "forms-6-logo-img-ef1SVY",
          pseudo: "",
          rules: [
            { property: "width", value: "auto" },
            { property: "height", value: "100px" },
            { property: "object-fit", value: "cover" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0rem 0rem 4.5rem 0rem" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "forms-6-logo-img-ef1SVY", pseudo: "hover", rules: [] },
        {
          className: "forms-6-title-ef1SVY",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(255, 255, 255, 0.4)" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "forms-6-title-ef1SVY", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(255, 255, 255, 0.4)" }] },
        {
          className: "forms-6-text-ef1SVY",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(255, 255, 255, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "forms-6-text-ef1SVY", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(255, 255, 255, 0.4)" }] },
        {
          className: "forms-6-button-ef1SVYwrapper-ef1SVY",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "forms-6-button-ef1SVYwrapper-ef1SVY", pseudo: "hover", rules: [] },
        {
          className: "forms-6-button-ef1SVY",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "0.4rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "100%" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "forms-6-button-ef1SVY",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        { className: "forms-6-inputwrapper-ef1SVY", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        {
          className: "forms-6-inputfield-ef1SVY",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.3)" },
            { property: "color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 0.5)" },
            { property: "border-radius", value: "0.4rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.5rem 1rem 0.5rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(255, 255, 255, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "forms-6-inputfield-ef1SVY",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "color", value: "rgba(109, 118, 126, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(255, 255, 255, 0.3)" },
          ],
        },
        {
          className: "forms-6-inputfield-ef1SVY",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(206, 212, 218, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "forms-6-inputfield-ef1SVY", pseudo: "hover", rules: [] },
        {
          className: "forms-6-label-ef1SVY",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "forms-6-label-ef1SVY", pseudo: "hover", rules: [] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Montserrat",
    fontHeadersWeight: "300",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(229, 42, 5, 1)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
