import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Border from "./Border";
import Margin from "./Margin";
import Padding from "./Padding";
import AlignRow from "./AlignRow";
import ResponsiveLayout from "./ResponsiveLayout";
import BorderRadius from "./BorderRadius";
import BoxShadow from "./BoxShadow";
import Transition from "./Transition";
import Slider from "./formComponents/Slider";
import ColorPicker from "./formComponents/ColorPicker";
import Select from "./formComponents/Select";
import Divider from "./formComponents/Divider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const Card6 = ({ sb: { startStyles } }) => {
  return (
    <>
      {/* Layout */}
      <ResponsiveLayout />
      <Divider />
      <AlignRow />
      <Divider />
      <Padding />
      <Divider />
      <Margin />
      <Divider />
      {/* Style */}
      <Border />
      <Divider />
      <BorderRadius />
      <Divider />
      <BoxShadow />
      <Divider />
      {/* Component */}
      <Transition />
      <Divider />
      <Select
        label={translate("cEditForms.card6_linePos", false, null)}
        id={"card6_pos"}
        options={[
          { val: "left", label: translate("cEditForms.left", false, null) },
          { val: "right", label: translate("cEditForms.right", false, null) },
          { val: "top", label: translate("cEditForms.top", false, null) },
          { val: "bottom", label: translate("cEditForms.bottom", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "card6_pos")}
        tooltipText={translate("cEditForms.card6_tooltipLinePos", false, null)}
      />
      <Slider
        label={translate("cEditForms.card6_lineSize", false, null)}
        id={"card6_size"}
        min={0}
        max={20}
        step={1}
        startValue={formGetStartValue(startStyles, "card6_size")}
        tooltipText={translate("cEditForms.card6_tooltipLineSize", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.card6_lineColor", false, null)}
        id={"bgColor"}
        startValue={formGetStartValue(startStyles, "bgColor")}
        tooltipText={translate("cEditForms.card6_tooltipLineColor", false, null)}
      />
    </>
  );
};

Card6.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Card6);
