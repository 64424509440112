export const card19 = {
  id: "card-19",
  name: "Card 19",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "card-19-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "align-items-start", "justify-content-center"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-4", "col-lg-4", "card-19-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "card-19-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["toGrow", "card-19-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Services",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["card-19-subtitle"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content:
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget eros dictum, semper enim id, vulputate turpis. Etiam sapien risus, ullamcorper in metus quis, imperdiet tristique felis.",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "a",
                    classes: ["card-19-button"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_self" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "More info",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-4", "col-lg-4", "card-19-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "card-19-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["toGrow", "card-19-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Projects",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["card-19-subtitle"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content:
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget eros dictum, semper enim id, vulputate turpis. Etiam sapien risus, ullamcorper in metus quis, imperdiet tristique felis.",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "a",
                    classes: ["card-19-button"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_self" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "More info",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-4", "col-lg-4", "card-19-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "card-19-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["toGrow", "card-19-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Team",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["card-19-subtitle"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content:
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget eros dictum, semper enim id, vulputate turpis. Etiam sapien risus, ullamcorper in metus quis, imperdiet tristique felis.",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "a",
                    classes: ["card-19-button"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_self" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "More info",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.card-19-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.card-19-component", pseudo: "hover", rules: [] },
      { className: "card-19-col", pseudo: "", rules: [] },
      { className: "card-19-col", pseudo: "hover", rules: [] },
      { className: "card-19-wrapper", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
      { className: "card-19-wrapper", pseudo: "hover", rules: [] },
      {
        className: "card-19-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "text-align", value: "center" },
          { property: "font-weight", value: "bold" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
        ],
      },
      {
        className: "card-19-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-19-subtitle",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "text-align", value: "center" },
        ],
      },
      { className: "card-19-subtitle", pseudo: "hover", rules: [] },
      {
        className: "card-19-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
          { property: "padding", value: "0.5rem 1.5rem 0.5rem 1.5rem" },
          { property: "width", value: "auto" },
          { property: "text-decoration", value: "none" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "card-19-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
        ],
      },
    ],
  },
};
