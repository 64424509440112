import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AlignCol from "./AlignCol";
import ResponsiveLayout from "./ResponsiveLayout";
import ColorPicker from "./formComponents/ColorPicker";
import Divider from "./formComponents/Divider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const Process2 = ({ sb: { startStyles } }) => {
  return (
    <>
      {/* Layout */}
      <ResponsiveLayout />
      <Divider />
      {/* <AlignRow /> */}
      {/* <Divider /> */}
      <AlignCol />
      <Divider />
      {/* Component */}
      <ColorPicker
        label={translate("cEditForms.process2_lineColor", false, null)}
        id={"process2_lineColor"}
        startValue={formGetStartValue(startStyles, "process2_lineColor")}
        tooltipText={translate("cEditForms.process2_ttLineColor", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.process2_iconColor", false, null)}
        id={"process2_iconColor"}
        startValue={formGetStartValue(startStyles, "process2_iconColor")}
        tooltipText={translate("cEditForms.process2_ttIconColor", false, null)}
      />
    </>
  );
};

Process2.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Process2);
