import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import TextInput from "./formComponents/TextInput";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const GoogleMaps = ({ sb: { startStyles } }) => {
  return (
    <>
      <TextInput
        label={translate("cEditForms.googleMapsLink", false, null)}
        id={"googlemaps"}
        startValue={formGetStartValue(startStyles, "googlemaps")}
        tooltipText={translate("cEditForms.ttGoogleMapsLink", false, null)}
      />
    </>
  );
};

GoogleMaps.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(GoogleMaps);
