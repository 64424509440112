export const template22 = {
  id: "65ff320f104c34244b8ef902",
  name: { en: "Creative agency", nl: "Design studio" },
  desc: { en: "Website for a creative agency", nl: "Website voor een design studio" },
  categories: ["services", "tech"],
  sbPages: [
    {
      pageId: "MxY8Nzskvp",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "yr29dk",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-yr29dk", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "true" },
            { property: "data-navbarposition", value: "fixed-top" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "IK4B26",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "NK6zqV",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "xw210C",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-yr29dk"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-kjp.png" },
                        { property: "alt", value: "Logo" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "sdQDyY",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-yr29dk", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                    { property: "type", value: "button" },
                    { property: "aria-expanded", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "PeK4z3",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "Da0S49",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "fvAFSS",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "GSXOkp",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-yr29dk"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Home",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "m6Xn2v",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-yr29dk"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#services" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Services",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ezQzPZ",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-yr29dk"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#about" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "About us",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "RHczYr",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-yr29dk"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#team" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Our team",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "m3OzaN",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-1-component-m3OzaN"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "qYvIXX",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-1-wrapper-m3OzaN"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "g9kCk6",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-1-content-m3OzaN"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "ECr9XW",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["parallax-1-title-m3OzaN", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Your creative agency",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "aH5QfG",
                      type: "",
                      htmlTagName: "p",
                      classes: ["parallax-1-text-m3OzaN", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "ycM8ph",
                      type: "",
                      htmlTagName: "a",
                      classes: ["parallax-1-button-m3OzaN"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Read what we can do for you",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "xuXTUQ",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "hero-8-component-xuXTUQ"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "services" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "uOTSMx",
              type: "",
              htmlTagName: "div",
              classes: ["row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "DDKzND",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "p-3", "hero-8-col-xuXTUQ"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Ef7DTo",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-8-title-xuXTUQ", "w-100", "w-md-100", "w-lg-80"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "We are a group of creatives, ready to help you break through to the next level",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "u9tJiv",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "p-3", "hero-8-col-xuXTUQ"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "o6pO4s",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-8-text-xuXTUQ", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Etiam est odio, volutpat eu aliquam non, condimentum et odio. Suspendisse potenti. Nam euismod porttitor dui ac dignissim. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam sodales, lectus sed sagittis feugiat, massa dui volutpat magna, et luctus turpis arcu ac enim.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Of3UuG",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-13-component-Of3UuG"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Bd5ZUd",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "puBFvP",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-Of3UuG"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "srkO15",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-Of3UuG"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "cmQB11",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-Of3UuG"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "WRu8Ry",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-chart-simple"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "JxJxNZ",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-Of3UuG", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem Ipsum",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "EOeTb1",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-Of3UuG", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "iCyPDc",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-Of3UuG"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "Q1xRIS",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-Of3UuG"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "x4RGoE",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-Of3UuG"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "lfbHQU",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-regular", "fa-paper-plane"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "pScour",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-Of3UuG", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Dolor Sitema",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Vnev5V",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-Of3UuG", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "llpmNf",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-Of3UuG"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "rZnBgB",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-Of3UuG"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "Dboz9n",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-Of3UuG"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "UOtdIN",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-layer-group"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "NMQXEM",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-Of3UuG", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Sed ut perspiciatis",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "fPRzT7",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-Of3UuG", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "QzjPVO",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-Of3UuG"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "dN0XzT",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-Of3UuG"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "w5lWfG",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-Of3UuG"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "gcnljH",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-users-line"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "UjqlPX",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-Of3UuG", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Magni Dolores",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "nQCmaY",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-Of3UuG", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ePAjdC",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-5-component-ePAjdC"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "about" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "hUqbYU",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-5-wrapper-ePAjdC"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "wdyW78",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-5-overlaywrapper-ePAjdC"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "AlSkG5",
                      type: "",
                      htmlTagName: "div",
                      classes: ["container", "parallax-5-containercontent-ePAjdC"],
                      styles: [],
                      attributes: [{ property: "data-parallax-containercontent", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "pTaVcK",
                          type: "",
                          htmlTagName: "div",
                          classes: ["parallax-5-content-ePAjdC", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "parallax3_content" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "HXwZdr",
                              type: "",
                              htmlTagName: "h4",
                              classes: ["parallax-5-subtitle-ePAjdC", "w-100", "w-md-80", "w-lg-50"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Who we are",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "btMGrd",
                              type: "",
                              htmlTagName: "h1",
                              classes: ["parallax-5-title-ePAjdC", "w-100", "w-md-80", "w-lg-50"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "About our company",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "VhPtHl",
                              type: "",
                              htmlTagName: "p",
                              classes: ["parallax-5-text-ePAjdC", "w-100", "w-md-80", "w-lg-50"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim. Nisl nisi scelerisque eu ultrices vitae auctor eu augue. Nunc lobortis mattis aliquam faucibus purus.",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "BxkCfy",
                              type: "block",
                              htmlTagName: "div",
                              classes: ["row", "justify-content-start", "align-items-stretch", "parallax-5-statswrapper", "w-100"],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "MMtJZ4",
                                  type: "element",
                                  htmlTagName: "div",
                                  classes: ["col-6", "col-md-4", "col-lg-2", "parallax-5-stat"],
                                  styles: [],
                                  attributes: [{ property: "data-name", value: "statistics2" }],
                                  content: "",
                                  children: [
                                    {
                                      childId: "kbOx50",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "parallax-5-stat-col-ePAjdC"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "u39Aec",
                                          type: "",
                                          htmlTagName: "h1",
                                          classes: ["toGrow", "parallax-5-stat-number-ePAjdC", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "4",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "RLb3xQ",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["parallax-5-stat-caption-ePAjdC", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Years active",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "PtpmyK",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "headingline" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "D4KjB7",
                                              type: "",
                                              htmlTagName: "div",
                                              classes: ["parallax-5-headingline-ePAjdC"],
                                              styles: [],
                                              attributes: [{ property: "data-checkparent", value: "true" }],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  childId: "e2jYk4",
                                  type: "element",
                                  htmlTagName: "div",
                                  classes: ["col-6", "col-md-4", "col-lg-2", "parallax-5-stat"],
                                  styles: [],
                                  attributes: [{ property: "data-name", value: "statistics2" }],
                                  content: "",
                                  children: [
                                    {
                                      childId: "qIM9vo",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "parallax-5-stat-col-ePAjdC"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "x0RI7K",
                                          type: "",
                                          htmlTagName: "h1",
                                          classes: ["toGrow", "parallax-5-stat-number-ePAjdC", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "27",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "sZ6fIT",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["parallax-5-stat-caption-ePAjdC", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Employees",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "dhW7Li",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "headingline" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "kFGaWE",
                                              type: "",
                                              htmlTagName: "div",
                                              classes: ["parallax-5-headingline-ePAjdC"],
                                              styles: [],
                                              attributes: [{ property: "data-checkparent", value: "true" }],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  childId: "uZHRLi",
                                  type: "element",
                                  htmlTagName: "div",
                                  classes: ["col-6", "col-md-4", "col-lg-2", "parallax-5-stat"],
                                  styles: [],
                                  attributes: [{ property: "data-name", value: "statistics2" }],
                                  content: "",
                                  children: [
                                    {
                                      childId: "UL2XXo",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "parallax-5-stat-col-ePAjdC"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "vIcFxn",
                                          type: "",
                                          htmlTagName: "h1",
                                          classes: ["toGrow", "parallax-5-stat-number-ePAjdC", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "200+",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "yNhxqQ",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["parallax-5-stat-caption-ePAjdC", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Projects delivered",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "PE6WL3",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "headingline" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "x24EX1",
                                              type: "",
                                              htmlTagName: "div",
                                              classes: ["parallax-5-headingline-ePAjdC"],
                                              styles: [],
                                              attributes: [{ property: "data-checkparent", value: "true" }],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  childId: "EeSKmu",
                                  type: "element",
                                  htmlTagName: "div",
                                  classes: ["col-6", "col-md-4", "col-lg-2", "parallax-5-stat"],
                                  styles: [],
                                  attributes: [{ property: "data-name", value: "statistics2" }],
                                  content: "",
                                  children: [
                                    {
                                      childId: "OYMsY5",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "parallax-5-stat-col-ePAjdC"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "U94mp1",
                                          type: "",
                                          htmlTagName: "h1",
                                          classes: ["toGrow", "parallax-5-stat-number-ePAjdC", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "€20m",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "GWoUf2",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["parallax-5-stat-caption-ePAjdC", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Funding",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "JbO12m",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "headingline" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "Oc3mko",
                                              type: "",
                                              htmlTagName: "div",
                                              classes: ["parallax-5-headingline-ePAjdC"],
                                              styles: [],
                                              attributes: [{ property: "data-checkparent", value: "true" }],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Ou0Pgm",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-Ou0Pgm"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "team" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "WUVREy",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-Ou0Pgm", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Meet the team", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "OZnin3",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-4-component-OZnin3"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "pwdG4p",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-center", "card-4-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "zgKwLy",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-4-col-OZnin3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card4" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade-down" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "EOhnqX",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-4-element-wrapper-OZnin3"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "PpiDOl",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-4-avatar-OZnin3"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "ulZvi7",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-4-content-OZnin3"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "nyuZ15",
                              type: "",
                              htmlTagName: "h5",
                              classes: ["card-4-title-OZnin3"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name Lastname",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "WzVnUm",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-4-subtitle-OZnin3"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "uGSWia",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "EXADLm",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "bMtrE9",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "Pnrm85",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "TlRwhD",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "AxJ50D",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "CLEQjc",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "ivJocJ",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "fe9SxL",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "ATspHV",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-4-col-OZnin3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card4" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade-down" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "R805lT",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-4-element-wrapper-OZnin3"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "XQsjqx",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-4-avatar-OZnin3"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "klTB3I",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-4-content-OZnin3"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "bF68le",
                              type: "",
                              htmlTagName: "h5",
                              classes: ["card-4-title-OZnin3"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name Lastname",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "UDJoLN",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-4-subtitle-OZnin3"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "PaZP8H",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "zGKeS8",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ERoA38",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "xT5cxE",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "sPsSX9",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "esIFt1",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "bcOQK3",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "g7RTqn",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "sB3i6r",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "epzNSl",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-4-col-OZnin3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card4" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade-down" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "QRdeiF",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-4-element-wrapper-OZnin3"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "y9eBEm",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-4-avatar-OZnin3"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "LSimH8",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-4-content-OZnin3"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "FyLqI7",
                              type: "",
                              htmlTagName: "h5",
                              classes: ["card-4-title-OZnin3"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name Lastname",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "jzZpFY",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-4-subtitle-OZnin3"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "dTOS1Q",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "zdkka5",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "urcYZb",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "pHY0Sd",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "hVwmD7",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "OHvLcN",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "HqKzSF",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "MWPBrk",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "JeLqbg",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "DTDJWd",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-4-col-OZnin3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card4" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade-down" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "TxT6JN",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-4-element-wrapper-OZnin3"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "iYWgxE",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-4-avatar-OZnin3"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "J2rBhi",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-4-content-OZnin3"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "CEQpdh",
                              type: "",
                              htmlTagName: "h5",
                              classes: ["card-4-title-OZnin3"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name Lastname",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "jrDX7a",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-4-subtitle-OZnin3"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "SUgBbE",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "AYVN11",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "J8zjES",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "SNu0Yf",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "gTJww4",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "qiyvCo",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "VDmB7L",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "BrJP78",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-OZnin3"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "OMI4Wg",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "BwXH4R",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-BwXH4R"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "clients" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "AnLeAu",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-BwXH4R", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Selected clients",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "SSKxdg",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "clients-1-component-SSKxdg"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "pKn1WS",
              type: "block",
              htmlTagName: "div",
              classes: ["d-flex", "flex-wrap", "align-items-center", "justify-content-center", "clients-1-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "XKpoFP",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-up" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "HjvuT6",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-SSKxdg"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "iEUXxG",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-up" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "vhX3HT",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-SSKxdg"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "xIlHWr",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-up" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "VmMHSn",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-SSKxdg"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-3.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "RpmAKY",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-up" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "fA1yXz",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-SSKxdg"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-4.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "NL8tz8",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "footer-6-component-NL8tz8"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "EIuITb",
              type: "block",
              htmlTagName: "div",
              classes: ["d-flex", "flex-wrap", "justify-content-center", "align-items-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "FP32sI",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-NL8tz8"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "YGfyy9",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-solid", "fa-envelope"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "mYiNGn",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-NL8tz8"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "VkOQ9q",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-brands", "fa-twitter"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "DTKblI",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-NL8tz8"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ygn6qk",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-brands", "fa-linkedin"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "xuOWw2",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-NL8tz8"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "IgDCPc",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              childId: "i1aLXq",
              type: "",
              htmlTagName: "p",
              classes: ["footer-6-text-NL8tz8", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "© Copyright Katrina Jones & Partners. All rights reserved.",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      _id: "65ff2ef7ce19f27d767050d0",
    },
  ],
  sbCustomCss: [
    {
      componentId: "ePAjdC",
      classes: [
        {
          className: "section.parallax-5-component-ePAjdC",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "6rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.parallax-5-component-ePAjdC", pseudo: "hover", rules: [] },
        {
          className: "parallax-5-component-ePAjdC",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-5-wrapper-ePAjdC",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/work-2.png")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-5-overlaywrapper-ePAjdC",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.65)" },
          ],
        },
        {
          className: "parallax-5-containercontent-ePAjdC",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "padding", value: "1.5rem 1.5rem 1.5rem 1.5rem" },
          ],
        },
        {
          className: "parallax-5-content-ePAjdC",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "border-radius", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "start" },
          ],
        },
        {
          className: "parallax-5-content-ePAjdC",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "parallax-5-subtitle-ePAjdC",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "margin", value: "4.5rem 0rem 0rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "parallax-5-subtitle-ePAjdC",
          pseudo: "hover",
          rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }],
        },
        {
          className: "parallax-5-text-ePAjdC",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "margin", value: "1rem 0rem 2rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "parallax-5-text-ePAjdC", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-5-stat-col-ePAjdC",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "parallax-5-stat-col-ePAjdC", pseudo: "hover", rules: [] },
        {
          className: "parallax-5-stat-number-ePAjdC",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
          ],
        },
        {
          className: "parallax-5-stat-caption-ePAjdC",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
          ],
        },
        {
          className: "parallax-5-headingline-ePAjdC",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(250, 250, 250, 1)" },
            { property: "width", value: "40px" },
            { property: "height", value: "1px" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "parallax-5-headingline-ePAjdC", pseudo: "hover", rules: [] },
        {
          className: "parallax-5-title-ePAjdC",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(247, 156, 43, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-5-title-ePAjdC", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
      ],
    },
    {
      componentId: "m3OzaN",
      classes: [
        {
          className: "section.parallax-1-component-m3OzaN",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-1-component-m3OzaN", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-component-m3OzaN",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-1-wrapper-m3OzaN",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/creative1.jpg")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-1-text-m3OzaN",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "parallax-1-text-m3OzaN", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-content-m3OzaN",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "parallax-1-button-m3OzaN",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "parallax-1-button-m3OzaN",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "parallax-1-title-m3OzaN",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-1-title-m3OzaN", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "yr29dk",
      classes: [
        {
          className: "scrolledpast-yr29dk.navbar-1-wrapper-yr29dk",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(255, 255, 255, 0.85)" },
          ],
        },
        { className: "scrolledpast-yr29dk .navbar-1-logo-yr29dk", pseudo: "", rules: [{ property: "max-height", value: "50px" }] },
        {
          className: "navbar-1-wrapper-yr29dk",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "navbar-1-logo-yr29dk",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "50px" },
          ],
        },
        { className: "navbar-1-logo-yr29dk", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-yr29dk",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 0)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(230, 230, 230, 0)" },
            { property: "color", value: "rgba(240, 240, 240, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-yr29dk",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 0)" },
            { property: "background", value: "rgba(180, 180, 180, 0)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "navbar-1-link-yr29dk",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(247, 156, 43, 1)" },
            { property: "font-family", value: "var(--font-body)" },
            { property: "font-size", value: "1rem" },
            { property: "text-decoration", value: "none" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "navbar-1-link-yr29dk", pseudo: "hover", rules: [{ property: "color", value: "rgba(212, 120, 8, 1)" }] },
      ],
    },
    {
      componentId: "xuXTUQ",
      classes: [
        {
          className: "section.hero-8-component-xuXTUQ",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "6rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.hero-8-component-xuXTUQ", pseudo: "hover", rules: [] },
        { className: "hero-8-col-xuXTUQ", pseudo: "", rules: [] },
        { className: "hero-8-col-xuXTUQ", pseudo: "hover", rules: [] },
        {
          className: "hero-8-text-xuXTUQ",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "hero-8-text-xuXTUQ", pseudo: "hover", rules: [] },
        {
          className: "hero-8-title-xuXTUQ",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-8-title-xuXTUQ", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Of3UuG",
      classes: [
        {
          className: "section.card-13-component-Of3UuG",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.card-13-component-Of3UuG", pseudo: "hover", rules: [] },
        { className: "card-13-col-Of3UuG", pseudo: "", rules: [] },
        { className: "card-13-col-Of3UuG", pseudo: "hover", rules: [] },
        { className: "card-13-wrapper-Of3UuG", pseudo: "", rules: [] },
        { className: "card-13-wrapper-Of3UuG", pseudo: "hover", rules: [] },
        {
          className: "card-13-text-Of3UuG",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-13-text-Of3UuG", pseudo: "hover", rules: [] },
        {
          className: "card-13-title-Of3UuG",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(247, 156, 43, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-13-title-Of3UuG", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-13-iconwrapper-Of3UuG",
          pseudo: "",
          rules: [
            { property: "align-self", value: "center" },
            { property: "color", value: "rgba(247, 156, 43, 1)" },
            { property: "font-size", value: "2.5rem" },
          ],
        },
        { className: "card-13-iconwrapper-Of3UuG", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "Ou0Pgm",
      classes: [
        {
          className: "section.basics-1-component-Ou0Pgm",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-Ou0Pgm", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-Ou0Pgm",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-Ou0Pgm", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-Ou0Pgm",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(247, 156, 43, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-Ou0Pgm", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "BwXH4R",
      classes: [
        {
          className: "section.basics-1-component-BwXH4R",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-BwXH4R", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-BwXH4R",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-BwXH4R", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-BwXH4R",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(247, 156, 43, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-BwXH4R", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "OZnin3",
      classes: [
        {
          className: "section.card-4-component-OZnin3",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.card-4-component-OZnin3", pseudo: "hover", rules: [] },
        {
          className: "card-4-avatar-OZnin3",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
          ],
        },
        { className: "card-4-avatar-OZnin3", pseudo: "hover", rules: [] },
        {
          className: "card-4-subtitle-OZnin3",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 1rem 0" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-size", value: "13px" },
          ],
        },
        { className: "card-4-subtitle-OZnin3", pseudo: "hover", rules: [] },
        {
          className: "card-4-social-link-OZnin3",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0.5rem 0 0" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "card-4-social-link-OZnin3", pseudo: "hover", rules: [{ property: "color", value: "rgba(180, 180, 180, 1)" }] },
        { className: "card-4-social-link-OZnin3", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "card-4-col-OZnin3",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
          ],
        },
        {
          className: "card-4-element-wrapper-OZnin3",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "padding", value: "0 0 0 0" },
            { property: "width", value: "100%" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0.4rem" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "card-4-element-wrapper-OZnin3",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" },
          ],
        },
        { className: "card-4-element-wrapper-OZnin3", pseudo: "hover .card-4-content-OZnin3", rules: [{ property: "height", value: "120px" }] },
        {
          className: "card-4-content-OZnin3",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "start" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "bottom", value: "0" },
            { property: "height", value: "80px" },
            { property: "padding", value: "0 0 0 0" },
            { property: "align-items", value: "center" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "background", value: "rgba(247, 156, 43, 0.65)" },
          ],
        },
        {
          className: "card-4-title-OZnin3",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-4-title-OZnin3", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "SSKxdg",
      classes: [
        {
          className: "section.clients-1-component-SSKxdg",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "4.5rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.clients-1-component-SSKxdg", pseudo: "hover", rules: [] },
        {
          className: "clients-1-logo-img-SSKxdg",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1.5rem 1.5rem 0" },
            { property: "max-height", value: "50px" },
            { property: "max-width", value: "100px" },
            { property: "height", value: "auto" },
            { property: "width", value: "auto" },
            { property: "filter", value: "grayscale(100%)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "0.5" },
          ],
        },
        {
          className: "clients-1-logo-img-SSKxdg",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: "1" },
            { property: "filter", value: "grayscale(0%)" },
          ],
        },
      ],
    },
    {
      componentId: "NL8tz8",
      classes: [
        {
          className: "section.footer-6-component-NL8tz8",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "4.5rem" },
            { property: "background", pseudo: "", value: "rgba(247, 156, 43, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(247, 156, 43, 1)" },
          ],
        },
        { className: "section.footer-6-component-NL8tz8", pseudo: "hover", rules: [] },
        {
          className: "footer-6-component-NL8tz8",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "footer-6-component-NL8tz8", pseudo: "hover", rules: [] },
        {
          className: "footer-6-text-NL8tz8",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "3rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-6-text-NL8tz8", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "footer-6-social-link-NL8tz8",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "height", value: "45px" },
            { property: "aspect-ratio", value: "1" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(247, 156, 43, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "font-size", value: "1.3rem" },
          ],
        },
        {
          className: "footer-6-social-link-NL8tz8",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(212, 120, 8, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "footer-6-social-link-NL8tz8", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Nunito+Sans:300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Nunito Sans",
    fontHeadersWeight: "400",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(247, 156, 43, 1)",
    color2: "rgba(247, 203, 43, 1)",
    color3: "rgba(212, 120, 8, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
