export const template14 = {
  id: "63a22a534fdf1376a41d4582",
  name: { en: "Dog hotel", nl: "Hondenhotel" },
  desc: { en: "Website for a dog hotel", nl: "Een website voor een hondenhotel" },
  categories: ["pets"],
  sbPages: [
    {
      pageId: "9d75f37d-3a48-4879-9c1d-75d2f0846da1",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "KFiFN6",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-3-component-KFiFN6"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ny8RGz",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-3-wrapper-KFiFN6"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "WopuJL",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-3-overlaywrapper-KFiFN6"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "mlBiBd",
                      type: "",
                      htmlTagName: "div",
                      classes: ["container", "parallax-3-containercontent-KFiFN6"],
                      styles: [],
                      attributes: [{ property: "data-parallax-containercontent", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "ryiQxS",
                          type: "",
                          htmlTagName: "div",
                          classes: ["parallax-3-content-KFiFN6", "w-100", "w-md-80", "w-lg-60"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "parallax3_content" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "bkDFiP",
                              type: "",
                              htmlTagName: "h1",
                              classes: ["parallax-3-title-KFiFN6", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "The best dog hotel",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "GJuvxK",
                              type: "",
                              htmlTagName: "p",
                              classes: ["parallax-3-text-KFiFN6", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "d7uT38",
                              type: "",
                              htmlTagName: "a",
                              classes: ["parallax-3-button-KFiFN6"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_self" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Book a stay",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "fW5CN5",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-fW5CN5"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "lKSdGa",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-fW5CN5", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Your best friend will have the time of its life!",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "QoUtf6",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-18-component-QoUtf6"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "H8OBC9",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "cmP7KF",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-18-col-QoUtf6"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "LHaYwM",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-18-wrapper-QoUtf6"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "MpDA32",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "QSmuyJ",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-18-iconwrapper-QoUtf6"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "kX3F0B",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-dog"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "C4cihB",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "v5dRVN",
                                  type: "",
                                  htmlTagName: "h4",
                                  classes: ["card-18-title-QoUtf6", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Play with others",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "UXMe2b",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-18-col-QoUtf6"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "sKl8hq",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-18-wrapper-QoUtf6"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "ypGTy5",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "U4ugGv",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-18-iconwrapper-QoUtf6"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "Wy0sBP",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-bowl-food"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "TPQoSe",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "wZnhZb",
                                  type: "",
                                  htmlTagName: "h4",
                                  classes: ["card-18-title-QoUtf6", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Great food",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "XFOPsZ",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-18-col-QoUtf6"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "cc0Gel",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-18-wrapper-QoUtf6"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "eYjqUj",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "s05zDh",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-18-iconwrapper-QoUtf6"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "BnmUhv",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-bath"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "sqRTjv",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "JT1moC",
                                  type: "",
                                  htmlTagName: "h4",
                                  classes: ["card-18-title-QoUtf6", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Get pampered",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "fyOxa1",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "section-divider-fyOxa1"],
          styles: [],
          attributes: [{ property: "data-name", value: "section_divider" }],
          content: "",
          children: [
            {
              childId: "Dtg9e6",
              type: "",
              htmlTagName: "svg",
              classes: [],
              styles: [
                { property: "background", value: "rgba(255, 255, 255, 1)" },
                { property: "transform", value: "scaleY(1) scaleX(1)" },
              ],
              attributes: [
                { property: "xmlns", value: "http://www.w3.org/2000/svg" },
                { property: "version", value: "1.0" },
                { property: "width", value: "100%" },
                { property: "height", value: "86" },
                { property: "viewBox", value: "0 0 1280 140" },
                { property: "preserveAspectRatio", value: "none" },
              ],
              content: "",
              children: [
                {
                  childId: "gMXEr4",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(250, 229, 246, 1)" },
                    {
                      property: "d",
                      value:
                        "M1269.61,52.83a48.82,48.82,0,0,0-16,14.48A48.6,48.6,0,0,0,1229.45,69a70.88,70.88,0,0,0-134.21-25.66,49.11,49.11,0,0,0-82.51,34.9c-.44.23-.88.45-1.31.69a40.18,40.18,0,0,0-9.36,7.24,64.35,64.35,0,0,0-76.25-24.43A64.34,64.34,0,0,0,818.36,39.85a24.53,24.53,0,0,0-26.61-5A90,90,0,0,0,634.48,64.55a48.89,48.89,0,0,0-14.21,3.95A49.12,49.12,0,0,0,526.79,68a71.07,71.07,0,0,0-40.34,17A70.91,70.91,0,0,0,361,64.68a45.07,45.07,0,0,0-39.73.6,27.31,27.31,0,0,0-29-18.37c-.16-.29-.31-.59-.47-.89a90.06,90.06,0,0,0-155.12-3A80.23,80.23,0,0,0,12.64,99.75a80.1,80.1,0,0,0-12.64,2V140H1280V48.48A49.22,49.22,0,0,0,1269.61,52.83Z",
                    },
                    { property: "opacity", value: "0.5" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "zp5Ld3",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(250, 229, 246, 1)" },
                    {
                      property: "d",
                      value:
                        "M1280,66.1a81.63,81.63,0,0,0-11.42.81,67.71,67.71,0,0,0-102.21-19,86,86,0,0,0-150.47-1.2c-.16.28-.29.57-.45.85a26.07,26.07,0,0,0-27.65,17.54,43,43,0,0,0-37.93-.57A67.66,67.66,0,0,0,830.13,84a67.85,67.85,0,0,0-38.51-16.19,46.9,46.9,0,0,0-89.25.45,46.66,46.66,0,0,0-13.56-3.77A86,86,0,0,0,538.66,36.07a23.42,23.42,0,0,0-25.4,4.8A61.36,61.36,0,0,0,410.68,61.74a61.44,61.44,0,0,0-72.79,23.32A38.37,38.37,0,0,0,329,78.15c-.41-.23-.83-.45-1.25-.66a46.88,46.88,0,0,0-78.77-33.31A67.65,67.65,0,0,0,120.69,70.29a46.76,46.76,0,0,0-21.82-1.62,46.91,46.91,0,0,0-78.8.07A79.46,79.46,0,0,0,0,66.17C-.07,66.17,0,140,0,140H1280Z",
                    },
                  ],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "w65Wi3",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "imggallery-2-component-w65Wi3"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "iKlXj7",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "imggallery-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "Iaqxxn",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-w65Wi3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "Q3TCHn",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-w65Wi3"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "rfudwt",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-w65Wi3"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://images.pexels.com/photos/1633522/pexels-photo-1633522.jpeg" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "ALy2h4",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-w65Wi3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "Kx9x2h",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-w65Wi3"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "T4JLBx",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-w65Wi3"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://images.pexels.com/photos/2607544/pexels-photo-2607544.jpeg" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "UeQQWx",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-w65Wi3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "zoeeJe",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-w65Wi3"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "suE91h",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-w65Wi3"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://images.pexels.com/photos/3114143/pexels-photo-3114143.jpeg" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "MgmpUV",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-w65Wi3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "CspymH",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-w65Wi3"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "zAZyym",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-w65Wi3"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://images.pexels.com/photos/1108099/pexels-photo-1108099.jpeg" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Ff19yQ",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "section-divider-Ff19yQ"],
          styles: [],
          attributes: [{ property: "data-name", value: "section_divider" }],
          content: "",
          children: [
            {
              childId: "PY4SFP",
              type: "",
              htmlTagName: "svg",
              classes: [],
              styles: [
                { property: "background", value: "rgba(255, 255, 255, 1)" },
                { property: "transform", value: "scaleY(-1)" },
              ],
              attributes: [
                { property: "xmlns", value: "http://www.w3.org/2000/svg" },
                { property: "version", value: "1.0" },
                { property: "width", value: "100%" },
                { property: "height", value: "86" },
                { property: "viewBox", value: "0 0 1280 86" },
                { property: "preserveAspectRatio", value: "none" },
              ],
              content: "",
              children: [
                {
                  childId: "laGCgq",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(250, 229, 246, 1)" },
                    {
                      property: "d",
                      value:
                        "M1280 66.1c-3.8 0-7.6.3-11.4.8-18.3-32.6-59.6-44.2-92.2-25.9-3.5 2-6.9 4.3-10 6.9-22.7-41.7-74.9-57.2-116.6-34.5-14.2 7.7-25.9 19.3-33.8 33.3-.2.3-.3.6-.5.8-12.2-1.4-23.7 5.9-27.7 17.5-11.9-6.1-25.9-6.3-37.9-.6-21.7-30.4-64-37.5-94.4-15.7-12.1 8.6-21 21-25.4 35.2-10.8-9.3-24.3-15-38.5-16.2-8.1-24.6-34.6-38-59.2-29.9-14.3 4.7-25.5 16-30 30.3-4.3-1.9-8.9-3.2-13.6-3.8-13.6-45.5-61.5-71.4-107-57.8a86.38 86.38 0 0 0-43.2 29.4c-8.7-3.6-18.7-1.8-25.4 4.8-23.1-24.8-61.9-26.2-86.7-3.1-7.1 6.6-12.5 14.8-15.9 24-26.7-10.1-56.9-.4-72.8 23.3-2.6-2.7-5.6-5.1-8.9-6.9-.4-.2-.8-.4-1.2-.7-.6-25.9-22-46.4-47.9-45.8-11.5.3-22.5 4.7-30.9 12.5-16.5-33.5-57.1-47.3-90.6-30.8-21.9 11-36.3 32.7-37.6 57.1-7-2.3-14.5-2.8-21.8-1.6C84.8 47 55.7 40.7 34 54.8c-5.6 3.6-10.3 8.4-13.9 14-6.6-1.7-13.3-2.6-20.1-2.6-.1 0 0 19.8 0 19.8h1280V66.1z",
                    },
                    { property: "opacity", value: "0.5" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "b8WaUH",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(250, 229, 246, 1)" },
                    {
                      property: "d",
                      value:
                        "M15.6 86H1280V48.5c-3.6 1.1-7.1 2.5-10.4 4.4-6.3 3.6-11.8 8.5-16 14.5-8.1-1.5-16.4-.9-24.2 1.7-3.2-39-37.3-68.1-76.4-64.9-24.8 2-46.8 16.9-57.9 39.3-19.9-18.5-51-17.3-69.4 2.6-8.2 8.8-12.8 20.3-13.1 32.3-.4.2-.9.4-1.3.7-3.5 1.9-6.6 4.4-9.4 7.2-16.6-24.9-48.2-35-76.2-24.4-12.2-33.4-49.1-50.6-82.5-38.4-9.5 3.5-18.1 9.1-25 16.5-7.1-6.9-17.5-8.8-26.6-5-30.4-39.3-87-46.3-126.2-15.8-14.8 11.5-25.6 27.4-31 45.4-4.9.6-9.7 1.9-14.2 3.9-8.2-25.9-35.8-40.2-61.7-32-15 4.8-26.9 16.5-31.8 31.5-14.9 1.3-29 7.2-40.3 17-11.5-37.4-51.2-58.4-88.7-46.8-14.8 4.6-27.7 13.9-36.7 26.5-12.6-6-27.3-5.7-39.7.6-4.1-12.2-16.2-19.8-29-18.4-.2-.3-.3-.6-.5-.9-24.4-43.3-79.4-58.6-122.7-34.2-13.3 7.5-24.4 18.2-32.4 31.2C99.8 18.5 50 28.5 25.4 65.4c-4.3 6.4-7.5 13.3-9.8 20.6z",
                    },
                  ],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "Zgb718",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-Zgb718"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "OlOHYd",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-Zgb718", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "What our happy guests have to say",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "v0n8ex",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "quote-4-component-v0n8ex"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "x2zEJn",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "quote-4-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "elip19",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "quote-4-col-v0n8ex", "col-md-6", "col-lg-4"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "NwoYbm",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-4-wrapper-v0n8ex"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "kZt9lS",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "lP3acE",
                              type: "",
                              htmlTagName: "span",
                              classes: ["quote-4-quote-left-v0n8ex"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "Pv40Yf",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-quote-left"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "bOslBa",
                              type: "",
                              htmlTagName: "p",
                              classes: ["quote-4-text3-v0n8ex"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus necessitatibus culpa ex odio quam atquereiciendis ducimus earum.Fugit, recusandae!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "zBF8Bo",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex", "align-items-center", "w-100"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "gIwbIw",
                              type: "",
                              htmlTagName: "img",
                              classes: ["quote-4-avatar-v0n8ex"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "img" },
                                { property: "src", value: "https://images.pexels.com/photos/662417/pexels-photo-662417.jpeg" },
                              ],
                              content: "",
                              children: [],
                            },
                            {
                              childId: "KYGsS3",
                              type: "",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "OcWXK2",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["quote-4-text1-v0n8ex"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Charlie, Brooklyn",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Ssxtty",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "quote-4-col-v0n8ex", "col-md-6", "col-lg-4"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "u9UgVo",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-4-wrapper-v0n8ex"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "PYKwJO",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "RQwbtr",
                              type: "",
                              htmlTagName: "span",
                              classes: ["quote-4-quote-left-v0n8ex"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "Tl572b",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-quote-left"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "LIlUus",
                              type: "",
                              htmlTagName: "p",
                              classes: ["quote-4-text3-v0n8ex"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus necessitatibus culpa ex odio quam atquereiciendis ducimus earum.Fugit, recusandae!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "J2pe5W",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex", "align-items-center", "w-100"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "bwJ1eS",
                              type: "",
                              htmlTagName: "img",
                              classes: ["quote-4-avatar-v0n8ex"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "img" },
                                { property: "src", value: "https://images.pexels.com/photos/3523317/pexels-photo-3523317.jpeg" },
                              ],
                              content: "",
                              children: [],
                            },
                            {
                              childId: "fhp0J1",
                              type: "",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "fUZXGH",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["quote-4-text1-v0n8ex"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Buddy, Harlem",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Z8uTJl",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "quote-4-col-v0n8ex", "col-md-6", "col-lg-4"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "kJTCPu",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-4-wrapper-v0n8ex"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "r2RIga",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "JDRj9c",
                              type: "",
                              htmlTagName: "span",
                              classes: ["quote-4-quote-left-v0n8ex"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "BZ13yo",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-quote-left"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "aZ0965",
                              type: "",
                              htmlTagName: "p",
                              classes: ["quote-4-text3-v0n8ex"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus necessitatibus culpa ex odio quam atquereiciendis ducimus earum.Fugit, recusandae!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "BNU8ka",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex", "align-items-center", "w-100"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "MZucM6",
                              type: "",
                              htmlTagName: "img",
                              classes: ["quote-4-avatar-v0n8ex"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "img" },
                                { property: "src", value: "https://images.pexels.com/photos/7974654/pexels-photo-7974654.jpeg" },
                              ],
                              content: "",
                              children: [],
                            },
                            {
                              childId: "w8HrVS",
                              type: "",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "g9PI3i",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["quote-4-text1-v0n8ex"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Archie, Manhattan",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "TlQHnD",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-6-component-TlQHnD"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "v7eNfW",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-6-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "kHGwI9",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "contactus-6-col-TlQHnD"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "tIDDq2",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["contactus-6-contacttitle-TlQHnD"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "h" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Book a stay",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "aYnDLQ",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-contacttext-TlQHnD"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Looking forward to welcoming you!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "dV1Ou2",
                      type: "",
                      htmlTagName: "h5",
                      classes: ["contactus-6-subtitle-TlQHnD"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Phone", children: [] },
                      ],
                    },
                    {
                      childId: "AIk1XC",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-subtext-TlQHnD"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "123 456 7890",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "E2eUTu",
                      type: "",
                      htmlTagName: "h5",
                      classes: ["contactus-6-subtitle-TlQHnD"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Email", children: [] },
                      ],
                    },
                    {
                      childId: "VNWkPZ",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-subtext-TlQHnD"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "email@site.com",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "FBHmuV",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-TlQHnD"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-TlQHnD"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-TlQHnD"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "name" },
                            { property: "placeholder", value: "Your name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-TlQHnD"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-TlQHnD"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-TlQHnD"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-TlQHnD"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-TlQHnD"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-TlQHnD"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-TlQHnD"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-TlQHnD"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-6-inputfield-TlQHnD"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-6-buttonwrapper-TlQHnD"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-6-button-TlQHnD"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "cQgBcv",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-7-component-cQgBcv"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "etwBK3",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-7-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "dRwdX9",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "contactus-7-col-cQgBcv"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "zuleJe",
                      type: "",
                      htmlTagName: "button",
                      classes: ["contactus-7-iconwrapper-cQgBcv"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "s2sAmH",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-solid", "fa-location-dot"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "A8ybAh",
                      type: "",
                      htmlTagName: "div",
                      classes: [],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "EkcMjm",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["contactus-7-title-cQgBcv"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Visit us",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "aynNIG",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-7-text-cQgBcv"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Street 1337",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "lqwngz",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-7-text-cQgBcv"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Postal code, City",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "lw5KCh",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "contactus-7-col-cQgBcv"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "XpsYeY",
                      type: "",
                      htmlTagName: "button",
                      classes: ["contactus-7-iconwrapper-cQgBcv"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "xGPoJN",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-solid", "fa-phone"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "dfKvoz",
                      type: "",
                      htmlTagName: "div",
                      classes: [],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "GOOhcE",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["contactus-7-title-cQgBcv"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Call us",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "QePWVE",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-7-text-cQgBcv"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "020 13 37 000",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "lxUspQ",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "contactus-7-col-cQgBcv"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "eRkPXF",
                      type: "",
                      htmlTagName: "button",
                      classes: ["contactus-7-iconwrapper-cQgBcv"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "h9cCn2",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-solid", "fa-envelope"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "bQ8TOj",
                      type: "",
                      htmlTagName: "div",
                      classes: [],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "QvTf8T",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["contactus-7-title-cQgBcv"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email us",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "sYNB9r",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-7-text-cQgBcv"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "email@domain.com",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "63a2209ad79b56e728b70dbb",
    },
  ],
  sbCustomCss: [
    {
      componentId: "IWlJ1c",
      classes: [
        {
          className: "section.hero-4-component-IWlJ1c",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "section.hero-4-component-IWlJ1c", pseudo: "hover", rules: [] },
        {
          className: "hero-4-component-IWlJ1c",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "hero-4-wrapper-IWlJ1c",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "80vh" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "hero-4-img-IWlJ1c",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "hero-4-img-IWlJ1c", pseudo: "hover", rules: [] },
        {
          className: "hero-4-content-IWlJ1c",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "bottom", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-4-content-IWlJ1c",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-4-text-IWlJ1c",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "margin", value: "0 4.5rem 2rem 4.5rem" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        {
          className: "hero-4-button-IWlJ1c",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "text-decoration", value: "none" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "hero-4-button-IWlJ1c",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
        {
          className: "hero-4-title-IWlJ1c",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "2rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
      ],
    },
    {
      componentId: "RqlhTk",
      classes: [
        {
          className: "section.hero-6-component-RqlhTk",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-6-component-RqlhTk", pseudo: "hover", rules: [] },
        {
          className: "hero-6-component-RqlhTk",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-6-component-RqlhTk", pseudo: "hover", rules: [] },
        {
          className: "hero-6-wrapper-RqlhTk",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
          ],
        },
        {
          className: "hero-6-img-RqlhTk",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-6-content-RqlhTk",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "hero-6-text-RqlhTk",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-6-text-RqlhTk", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-6-title-RqlhTk",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-6-title-RqlhTk", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
      ],
    },
    {
      componentId: "EPNdOI",
      classes: [
        {
          className: "section.parallax-3-component-EPNdOI",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-3-component-EPNdOI", pseudo: "hover", rules: [] },
        {
          className: "parallax-3-component-EPNdOI",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-3-wrapper-EPNdOI",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "70vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/parallax-3.png")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-3-containercontent-EPNdOI",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "parallax-3-title-EPNdOI",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "margin", value: "4.5rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "parallax-3-title-EPNdOI", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-3-text-EPNdOI",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "margin", value: "1.5rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "parallax-3-text-EPNdOI", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-3-button-EPNdOI",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(40, 40, 40, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "1.5rem 0rem 4.5rem 0rem" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "text-decoration", value: "none" },
            { property: "align-self", value: "start" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "parallax-3-button-EPNdOI",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(40, 40, 40, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(40, 40, 40, 1)" },
          ],
        },
        {
          className: "parallax-3-overlaywrapper-EPNdOI",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "parallax-3-content-EPNdOI",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "start" },
            { property: "background", value: "rgba(255, 255, 255, 0.4)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "1rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "parallax-3-content-EPNdOI",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.4)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "dnnwh5",
      classes: [
        {
          className: "section.hero-1-component-dnnwh5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-1-component-dnnwh5", pseudo: "hover", rules: [] },
        {
          className: "hero-1-component-dnnwh5",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-1-component-dnnwh5", pseudo: "hover", rules: [] },
        {
          className: "hero-1-wrapper-dnnwh5",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
          ],
        },
        {
          className: "hero-1-img-dnnwh5",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-1-content-dnnwh5",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-1-title-dnnwh5",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "hero-1-title-dnnwh5", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-1-text-dnnwh5",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "hero-1-text-dnnwh5", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-1-button-dnnwh5",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "none" },
          ],
        },
        {
          className: "hero-1-button-dnnwh5",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
      ],
    },
    {
      componentId: "KLB02v",
      classes: [
        {
          className: "section.parallax-3-component-KLB02v",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-3-component-KLB02v", pseudo: "hover", rules: [] },
        {
          className: "parallax-3-component-KLB02v",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-3-wrapper-KLB02v",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/dogs1.jpg")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-3-containercontent-KLB02v",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "parallax-3-overlaywrapper-KLB02v",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "justify-content", value: "end" },
          ],
        },
        {
          className: "parallax-3-content-KLB02v",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "start" },
            { property: "background", value: "rgba(255, 255, 255, 0.25)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "1rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "parallax-3-content-KLB02v",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.25)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "parallax-3-title-KLB02v",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-3-title-KLB02v", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-3-text-KLB02v",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1.5rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-3-text-KLB02v", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-3-button-KLB02v",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(40, 40, 40, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1.5rem 0rem 1rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "parallax-3-button-KLB02v",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(40, 40, 40, 1)" },
            { property: "border-color", value: "rgba(40, 40, 40, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "fW5CN5",
      classes: [
        {
          className: "section.basics-1-component-fW5CN5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-fW5CN5", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-fW5CN5",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-fW5CN5", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-fW5CN5",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "4.5rem 0rem 1.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-fW5CN5", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "QoUtf6",
      classes: [
        {
          className: "section.card-18-component-QoUtf6",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "4.5rem" },
          ],
        },
        { className: "section.card-18-component-QoUtf6", pseudo: "hover", rules: [] },
        {
          className: "card-18-text-QoUtf6",
          pseudo: "",
          rules: [
            { property: "margin", value: "0.5rem 0 0 1rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-18-text-QoUtf6", pseudo: "hover", rules: [] },
        {
          className: "card-18-iconwrapper-QoUtf6",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        { className: "card-18-iconwrapper-QoUtf6", pseudo: "hover", rules: [] },
        {
          className: "card-18-wrapper-QoUtf6",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-items", value: "center" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-18-wrapper-QoUtf6",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-18-col-QoUtf6",
          pseudo: "",
          rules: [
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        { className: "card-18-col-QoUtf6", pseudo: "hover", rules: [] },
        {
          className: "card-18-title-QoUtf6",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0.25rem 0rem 0rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-18-title-QoUtf6", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "KFiFN6",
      classes: [
        {
          className: "section.parallax-3-component-KFiFN6",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-3-component-KFiFN6", pseudo: "hover", rules: [] },
        {
          className: "parallax-3-component-KFiFN6",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-3-wrapper-KFiFN6",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
            { property: "background-image", value: 'url("https://images.pexels.com/photos/4588010/pexels-photo-4588010.jpeg")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-3-containercontent-KFiFN6",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "padding", value: "1.5rem 1.5rem 1.5rem 1.5rem" },
          ],
        },
        {
          className: "parallax-3-overlaywrapper-KFiFN6",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "justify-content", value: "end" },
          ],
        },
        {
          className: "parallax-3-content-KFiFN6",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "start" },
            { property: "background", value: "rgba(255, 255, 255, 0.25)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "1rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "parallax-3-content-KFiFN6",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.25)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "parallax-3-text-KFiFN6",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1.5rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-3-text-KFiFN6", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-3-title-KFiFN6",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "4.5rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-3-title-KFiFN6", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-3-button-KFiFN6",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(230, 111, 214, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(230, 111, 214, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1.5rem 0rem 4.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "parallax-3-button-KFiFN6",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(230, 111, 214, 1)" },
            { property: "border-color", value: "rgba(230, 111, 214, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "w65Wi3",
      classes: [
        {
          className: "section.imggallery-2-component-w65Wi3",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "6rem" },
            { property: "padding-bottom", value: "6rem" },
            { property: "background", value: "rgba(250, 229, 246, 1)" },
          ],
        },
        { className: "section.imggallery-2-component-w65Wi3", pseudo: "hover", rules: [] },
        {
          className: "imggallery-2-col-w65Wi3",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "stretch" },
            { property: "justify-content", value: "center" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0rem 0.5rem 0rem 0.5rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "imggallery-2-wrapper-w65Wi3",
          pseudo: "",
          rules: [
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(230, 111, 214, 1)" },
            { property: "border-radius", value: "0.4rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.4s ease-in-out" },
          ],
        },
        {
          className: "imggallery-2-wrapper-w65Wi3",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(230, 111, 214, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "imggallery-2-img-w65Wi3",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "transition", value: "all 0.4s ease-in-out" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
          ],
        },
        {
          className: "imggallery-2-img-w65Wi3",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1.15)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
          ],
        },
      ],
    },
    {
      componentId: "v0n8ex",
      classes: [
        {
          className: "section.quote-4-component-v0n8ex",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.quote-4-component-v0n8ex", pseudo: "hover", rules: [] },
        {
          className: "quote-4-text2-v0n8ex",
          pseudo: "",
          rules: [
            { property: "font-style", value: "italic" },
            { property: "margin", value: "0 0 0 1rem" },
          ],
        },
        { className: "quote-4-text2-v0n8ex", pseudo: "hover", rules: [] },
        {
          className: "quote-4-text3-v0n8ex",
          pseudo: "",
          rules: [
            { property: "display", value: "inline" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "font-style", value: "italic" },
            { property: "color", value: "rgba(100, 100, 100, 1)" },
          ],
        },
        { className: "quote-4-text3-v0n8ex", pseudo: "hover", rules: [] },
        {
          className: "quote-4-quote-left-v0n8ex",
          pseudo: "",
          rules: [
            { property: "display", value: "inline" },
            { property: "font-size", value: "2rem" },
            { property: "color", value: "rgba(100, 100, 100, 1)" },
          ],
        },
        { className: "quote-4-quote-left-v0n8ex", pseudo: "hover", rules: [] },
        {
          className: "quote-4-text1-v0n8ex",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(232, 114, 218, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "quote-4-text1-v0n8ex", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "quote-4-avatar-v0n8ex",
          pseudo: "",
          rules: [
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "100px" },
            { property: "height", value: "100px" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(230, 111, 214, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "quote-4-avatar-v0n8ex",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(230, 111, 214, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "quote-4-wrapper-v0n8ex",
          pseudo: "",
          rules: [
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "background", value: "rgba(250, 250, 250, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(230, 111, 214, 1)" },
            { property: "box-shadow", value: "0px 0px 5px 2px rgba(241, 176, 232, 0.3)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-items", value: "center" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "quote-4-wrapper-v0n8ex",
          pseudo: "hover",
          rules: [
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "background", value: "rgba(250, 250, 250, 1)" },
            { property: "border-color", value: "rgba(230, 111, 214, 1)" },
            { property: "box-shadow", value: "0px 0px 5px 2px rgba(241, 176, 232, 0.3)" },
          ],
        },
        {
          className: "quote-4-col-v0n8ex",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
          ],
        },
        { className: "quote-4-col-v0n8ex", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "Zgb718",
      classes: [
        {
          className: "section.basics-1-component-Zgb718",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0.5rem" },
          ],
        },
        { className: "section.basics-1-component-Zgb718", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-Zgb718",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-Zgb718", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-Zgb718",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-Zgb718", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "TlQHnD",
      classes: [
        {
          className: "section.contactus-6-component-TlQHnD",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-6-component-TlQHnD", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-col-TlQHnD",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-6-contacttitle-TlQHnD",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
          ],
        },
        {
          className: "contactus-6-contacttitle-TlQHnD",
          pseudo: "hover",
          rules: [],
        },
        { className: "contactus-6-contacttext-TlQHnD", pseudo: "", rules: [{ property: "margin", value: "0 0 2rem 0" }] },
        { className: "contactus-6-contacttext-TlQHnD", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-subtitle-TlQHnD",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
          ],
        },
        {
          className: "contactus-6-subtitle-TlQHnD",
          pseudo: "hover",
          rules: [],
        },
        { className: "contactus-6-subtext-TlQHnD", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        { className: "contactus-6-subtext-TlQHnD", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-buttonwrapper-TlQHnD",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "contactus-6-buttonwrapper-TlQHnD",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-6-button-TlQHnD",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(232, 114, 218, 0)" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(232, 114, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "font-weight", value: "bold" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-6-button-TlQHnD",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(232, 114, 218, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(232, 114, 218, 1)" },
          ],
        },
        {
          className: "contactus-6-inputwrapper-TlQHnD",
          pseudo: "",
          rules: [{ property: "margin", value: "0 0 1rem 0" }],
        },
        {
          className: "contactus-6-inputfield-TlQHnD",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(232, 114, 218, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-6-inputfield-TlQHnD",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
            { property: "border-color", value: "rgba(232, 114, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(232, 114, 218, 0.2)" },
          ],
        },
        {
          className: "contactus-6-inputfield-TlQHnD",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "contactus-6-inputfield-TlQHnD",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-6-label-TlQHnD",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        {
          className: "contactus-6-label-TlQHnD",
          pseudo: "hover",
          rules: [],
        },
      ],
    },
    {
      componentId: "cQgBcv",
      classes: [
        {
          className: "section.contactus-7-component-cQgBcv",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-7-component-cQgBcv", pseudo: "hover", rules: [] },
        {
          className: "contactus-7-col-cQgBcv",
          pseudo: "",
          rules: [
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "text-align", value: "center" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        {
          className: "contactus-7-iconwrapper-cQgBcv",
          pseudo: "",
          rules: [
            { property: "height", value: "65px" },
            { property: "width", value: "65px" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(232, 114, 218, 1)" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0 0 0 0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "cursor", value: "default !important" },
          ],
        },
        {
          className: "contactus-7-iconwrapper-cQgBcv",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(232, 114, 218, 1)" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "contactus-7-text-cQgBcv", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "contactus-7-text-cQgBcv", pseudo: "hover", rules: [] },
        {
          className: "contactus-7-title-cQgBcv",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(232, 114, 218, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "contactus-7-title-cQgBcv", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Ff19yQ",
      classes: [
        {
          className: "section-divider-Ff19yQ",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
            { property: "width", value: "100%" },
          ],
        },
      ],
    },
    {
      componentId: "fyOxa1",
      classes: [
        {
          className: "section-divider-fyOxa1",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
            { property: "width", value: "100%" },
          ],
        },
      ],
    },
    {
      componentId: "PctFg6",
      classes: [
        {
          className: "section-divider-PctFg6",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
            { property: "width", value: "100%" },
          ],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Pacifico:100,300,400,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Poppins:100,300,700&display=swap",
    fontHeadersName: "Pacifico",
    fontHeadersWeight: "400",
    fontBodyName: "Poppins",
    fontBodyWeight: "400",
    color1: "rgba(232, 114, 218, 1)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
