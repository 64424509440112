export const cBuilderArea = {
  areYouSureExit: {
    en: "Are you sure you want to exit Sitebuilder? Any unsaved progress will be lost.",
    nl: "Weet u zeker dat u Sitebuilder wilt afsluiten? Niet opgeslagen veranderingen zullen worden verloren.",
  },
  saveAndExit: {
    en: "Save and exit Sitebuilder",
    nl: "Bewaar project en sluit Sitebuilder af",
  },
  version: {
    en: "version",
    nl: "versie",
  },
  pageOptions: {
    en: "Site & page options",
    nl: "Site & pagina opties",
  },
  desktopScreen: {
    en: "See how your website looks like on a desktop screen",
    nl: "Bekijk uw website op een computerscherm",
  },
  tabletScreen: {
    en: "See how your website looks like on a tablet screen",
    nl: "Bekijk uw website op een tabletscherm",
  },
  mobileScreen: {
    en: "See how your website looks like on a mobile screen",
    nl: "Bekijk uw website op een telefoonscherm",
  },
  undoChange: {
    en: "Undo most recent change",
    nl: "Meest recente verandering ongedaan maken",
  },
  redoChange: {
    en: "Redo most recent change",
    nl: "Meest recente verandering terugzetten",
  },
  saveProject: {
    en: "Save project",
    nl: "Sla project op",
  },
  dragDropFirstComponent: {
    en: "Drag & drop your first component!",
    nl: "Drag & drop uw eerste component!",
  },
};
