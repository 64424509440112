import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { EDIT_FORM_ID_PREFIX } from "../../../../../lib/editFunctions";

import Tooltip from "./Tooltip";

const Slider = ({ sb: { selectedElement }, label, id, min, max, step, startValue, tooltipText }) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    setValue(startValue);
  }, [startValue, selectedElement]);

  return (
    <div className="w-100 d-flex align-items-center my-3">
      <div className="w-50">
        {label}
        <Tooltip text={tooltipText} />
      </div>
      <div className="w-50 d-flex justify-content-center">
        <input
          type="range"
          className="form-range"
          min={min}
          max={max}
          step={step}
          id={EDIT_FORM_ID_PREFIX + id}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <span className="slider-value">{value}</span>
      </div>
    </div>
  );
};

Slider.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Slider);
