import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const ImgAdjust = ({ sb: { startStyles } }) => {
  const [bool, setBool] = useState(false);
  useEffect(() => {
    setBool(formGetStartValue(startStyles, "imgAdjustChangeOnHover"));
  }, [startStyles]);

  return (
    <>
      <Slider
        label={translate("cEditForms.opacity", false, null)}
        id={"imgOpacity"}
        min={0}
        max={1}
        step={0.01}
        startValue={formGetStartValue(startStyles, "imgOpacity")}
        tooltipText={translate("cEditForms.ttOpacity", false, null)}
      />
      <Slider
        label={translate("cEditForms.blur", false, null)}
        id={"imgBlur"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "imgBlur")}
        tooltipText={translate("cEditForms.ttBlur", false, null)}
      />
      <Slider
        label={translate("cEditForms.brightNess", false, null)}
        id={"imgBrightness"}
        min={0}
        max={500}
        step={1}
        startValue={formGetStartValue(startStyles, "imgBrightness")}
        tooltipText={translate("cEditForms.ttBrightness", false, null)}
      />
      <Slider
        label={translate("cEditForms.contrast", false, null)}
        id={"imgContrast"}
        min={0}
        max={500}
        step={1}
        startValue={formGetStartValue(startStyles, "imgContrast")}
        tooltipText={translate("cEditForms.ttContrast", false, null)}
      />
      <Slider
        label={translate("cEditForms.grayscale", false, null)}
        id={"imgGrayscale"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "imgGrayscale")}
        tooltipText={translate("cEditForms.ttGrayscale", false, null)}
      />
      <Slider
        label={translate("cEditForms.hue", false, null)}
        id={"imgHueRotate"}
        min={0}
        max={360}
        step={1}
        startValue={formGetStartValue(startStyles, "imgHueRotate")}
        tooltipText={translate("cEditForms.ttHue", false, null)}
      />
      <Slider
        label={translate("cEditForms.invert", false, null)}
        id={"imgInvert"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "imgInvert")}
        tooltipText={translate("cEditForms.ttInvert", false, null)}
      />
      <Slider
        label={translate("cEditForms.saturate", false, null)}
        id={"imgSaturate"}
        min={0}
        max={500}
        step={1}
        startValue={formGetStartValue(startStyles, "imgSaturate")}
        tooltipText={translate("cEditForms.ttSaturate", false, null)}
      />
      <Slider
        label={translate("cEditForms.sepia", false, null)}
        id={"imgSepia"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "imgSepia")}
        tooltipText={translate("cEditForms.ttSepia", false, null)}
      />

      <div className={`form-check form-switch mt-3 ${bool ? "mb-n2" : "mb-3"}`}>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="switchImgAdjustHover"
          value={bool}
          checked={bool}
          onChange={(e) => setBool(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="switchImgAdjustHover">
          {translate("cEditForms.imgAdjustChangeOnHover", false, null)}
        </label>
      </div>
      {bool && (
        <>
          <Slider
            label={translate("cEditForms.opacity", false, null)}
            id={"imgOpacityHover"}
            min={0}
            max={1}
            step={0.01}
            startValue={formGetStartValue(startStyles, "imgOpacityHover")}
            tooltipText={translate("cEditForms.ttOpacityHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.blur", false, null)}
            id={"imgBlurHover"}
            min={0}
            max={100}
            step={1}
            startValue={formGetStartValue(startStyles, "imgBlurHover")}
            tooltipText={translate("cEditForms.ttBlurHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.brightNess", false, null)}
            id={"imgBrightnessHover"}
            min={0}
            max={500}
            step={1}
            startValue={formGetStartValue(startStyles, "imgBrightnessHover")}
            tooltipText={translate("cEditForms.ttBrightnessHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.contrast", false, null)}
            id={"imgContrastHover"}
            min={0}
            max={500}
            step={1}
            startValue={formGetStartValue(startStyles, "imgContrastHover")}
            tooltipText={translate("cEditForms.ttContrastHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.grayscale", false, null)}
            id={"imgGrayscaleHover"}
            min={0}
            max={100}
            step={1}
            startValue={formGetStartValue(startStyles, "imgGrayscaleHover")}
            tooltipText={translate("cEditForms.ttGrayscaleHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.hue", false, null)}
            id={"imgHueRotateHover"}
            min={0}
            max={360}
            step={1}
            startValue={formGetStartValue(startStyles, "imgHueRotateHover")}
            tooltipText={translate("cEditForms.ttHueHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.invert", false, null)}
            id={"imgInvertHover"}
            min={0}
            max={100}
            step={1}
            startValue={formGetStartValue(startStyles, "imgInvertHover")}
            tooltipText={translate("cEditForms.ttInvertHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.saturate", false, null)}
            id={"imgSaturateHover"}
            min={0}
            max={500}
            step={1}
            startValue={formGetStartValue(startStyles, "imgSaturateHover")}
            tooltipText={translate("cEditForms.ttSaturateHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.sepia", false, null)}
            id={"imgSepiaHover"}
            min={0}
            max={100}
            step={1}
            startValue={formGetStartValue(startStyles, "imgSepiaHover")}
            tooltipText={translate("cEditForms.ttSepiaHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.shrinkOrExpand", false, null)}
            id={"imgTransformHover"}
            min={0}
            max={200}
            step={1}
            startValue={formGetStartValue(startStyles, "imgTransformHover")}
            tooltipText={translate("cEditForms.ttShrinkOrExpand", false, null)}
          />
        </>
      )}
    </>
  );
};

ImgAdjust.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(ImgAdjust);
