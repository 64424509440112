export const template16 = {
  id: "642494bfdbe139b78ceb6c82",
  name: { en: "Car garage", nl: "Auto garage" },
  desc: { en: "Website for a garage", nl: "Een website voor een garage" },
  categories: ["services", "hobby"],
  sbPages: [
    {
      pageId: "7a4c4a27-d777-4f27-93f4-e7c3e1983d85",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "iZlP18",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-2-wrapper-iZlP18", "flex-column", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "sticky-top" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "xetY7S",
              type: "",
              htmlTagName: "div",
              classes: ["container", "justify-content-between", "justify-content-lg-center", "mb-0", "mb-lg-3"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "ttD3Rs",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "wWg8YD",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-2-logo-iZlP18"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo.png" },
                        { property: "alt", value: "Total Car Solutions" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "xlF6Ay",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-2-toggler-iZlP18", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-2" },
                    { property: "data-elementgetter1", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "TLzUK4",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              childId: "yq2Uf1",
              type: "",
              htmlTagName: "div",
              classes: ["collapse", "navbar-collapse", "justify-content-center"],
              styles: [],
              attributes: [{ property: "id", value: "navbarCollapse-2" }],
              content: "",
              children: [
                {
                  childId: "g8uL6i",
                  type: "",
                  htmlTagName: "ul",
                  classes: ["navbar-nav"],
                  styles: [],
                  attributes: [{ property: "data-elementgetter2", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "li",
                      classes: ["nav-item"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "Ytjy05",
                          type: "",
                          htmlTagName: "a",
                          classes: ["nav-link", "navbar-2-link-iZlP18"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "/index.html#about" },
                            { property: "data-target", value: "_self" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "About us",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "li",
                      classes: ["nav-item"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "Ks0GIN",
                          type: "",
                          htmlTagName: "a",
                          classes: ["nav-link", "navbar-2-link-iZlP18"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "/index.html#services" },
                            { property: "data-target", value: "_self" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Our services",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "li",
                      classes: ["nav-item"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "CZrRvL",
                          type: "",
                          htmlTagName: "a",
                          classes: ["nav-link", "navbar-2-link-iZlP18"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "/index.html#contact" },
                            { property: "data-target", value: "_self" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Contact",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "lhntHP",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-3-component-lhntHP"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "JilUrt",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-3-wrapper-lhntHP"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "oBjPWE",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-3-overlaywrapper-lhntHP"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "uNqOqT",
                      type: "",
                      htmlTagName: "div",
                      classes: ["container", "parallax-3-containercontent-lhntHP"],
                      styles: [],
                      attributes: [{ property: "data-parallax-containercontent", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "q6Xffx",
                          type: "",
                          htmlTagName: "div",
                          classes: ["parallax-3-content-lhntHP", "w-100", "w-md-70", "w-lg-50"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "parallax3_content" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "u8ZdTr",
                              type: "",
                              htmlTagName: "h1",
                              classes: ["parallax-3-title-lhntHP", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Welcome to Total Car Solutions!",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "pAJ9RO",
                              type: "",
                              htmlTagName: "p",
                              classes: ["parallax-3-text-lhntHP", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "vrgcOe",
                              type: "",
                              htmlTagName: "a",
                              classes: ["parallax-3-button-lhntHP"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-target", value: "_self" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "button" },
                                { property: "data-href", value: "/index.html#services" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Read more",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "vAByFL",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-vAByFL"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "services" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "y5FfaA",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-vAByFL", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Services", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "M9dTH3",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-6-component-M9dTH3"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "XTZ8bk",
              type: "",
              htmlTagName: "div",
              classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [
                {
                  childId: "muNibl",
                  type: "",
                  htmlTagName: "div",
                  classes: ["basics-6-headingline-M9dTH3"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "S6wjfz",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-10-component-S6wjfz"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ojBqFa",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "ccZilD",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-S6wjfz"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "eU9DRE",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-S6wjfz"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "yezL0l",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-S6wjfz"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/garage1.jpg" },
                            { property: "alt", value: "Car wash" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "OTTsMk",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-S6wjfz", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Car wash",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "XxdmRc",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-S6wjfz", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "bdvpWy",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-S6wjfz"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "NzKwoM",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-S6wjfz"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "xAKt4f",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-S6wjfz"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/garage2.jpg" },
                            { property: "alt", value: "Paint jobs" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "e4DyQB",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-S6wjfz", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Paint jobs",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "SOCxER",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-S6wjfz", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "zgD6ye",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-S6wjfz"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "WmJiQe",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-S6wjfz"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "sOvzAJ",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-S6wjfz"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/car1.jpg" },
                            { property: "alt", value: "Upholstery" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "AuxP34",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-S6wjfz", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Upholstery",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "yyFUiR",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-S6wjfz", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "k6N0jB",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-k6N0jB"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "r0tS2f",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-k6N0jB", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Successful jobs", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "IaVVQP",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-6-component-IaVVQP"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "KZffi5",
              type: "",
              htmlTagName: "div",
              classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [
                {
                  childId: "dnW3iY",
                  type: "",
                  htmlTagName: "div",
                  classes: ["basics-6-headingline-IaVVQP"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "Psylzo",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-Psylzo"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "XnvC5x",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-Psylzo", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "p9qV8i",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "imggallery-1-component-p9qV8i"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "DfjTvo",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "imggallery-1-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "rrTVtj",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "imggallery-1-col-p9qV8i", "col-lg-4"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "eCSMDh",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-p9qV8i"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "l5ShQ4",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-p9qV8i"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/car8.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "xJ9bRz",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-p9qV8i"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "s3GGag",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-p9qV8i", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "HN0Dgb",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-p9qV8i", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "z9IpvK",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "imggallery-1-col-p9qV8i", "col-lg-4"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "anrA4U",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-p9qV8i"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "iwcT2m",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-p9qV8i"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/car6.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "cExn7q",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-p9qV8i"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "fnOuXp",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-p9qV8i", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "FNFkCY",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-p9qV8i", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "rgpkhm",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "imggallery-1-col-p9qV8i", "col-lg-4"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "pAm8n8",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-p9qV8i"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "fh5q5h",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-p9qV8i"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/car7.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "u9e597",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-p9qV8i"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "lcK56A",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-p9qV8i", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "iwQYJ6",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-p9qV8i", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "lL2zMM",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "imggallery-1-col-p9qV8i", "col-lg-4"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "QkO6Pj",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-p9qV8i"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "bWjQsR",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-p9qV8i"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/car4.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "AEaLWQ",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-p9qV8i"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "FlgCbM",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-p9qV8i", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "PedQ1c",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-p9qV8i", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Y0raP9",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "imggallery-1-col-p9qV8i", "col-lg-4"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "mm49yO",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-p9qV8i"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "udJKk5",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-p9qV8i"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/car3.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "EP9l1D",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-p9qV8i"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "ZA9oqK",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-p9qV8i", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "SMdZku",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-p9qV8i", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "BJg7ZD",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "imggallery-1-col-p9qV8i", "col-lg-4"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "E1n29m",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-p9qV8i"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "qp9lnJ",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-p9qV8i"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/car5.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "Cf14OB",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-p9qV8i"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "OjVO1e",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-p9qV8i", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "tYqWl8",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-p9qV8i", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "rYOsQv",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-2-component-rYOsQv"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "sjGePr",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-2-wrapper-rYOsQv"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "JY4q1x",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-2-content-rYOsQv"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "iB2g0o",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-iB2g0o"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "about" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "GurVQq",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-iB2g0o", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Cars are our passion",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "dvdiq2",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-6-component-dvdiq2"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "J5rQdg",
              type: "",
              htmlTagName: "div",
              classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [
                {
                  childId: "WuQpye",
                  type: "",
                  htmlTagName: "div",
                  classes: ["basics-6-headingline-dvdiq2"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "QO9qiA",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-QO9qiA"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "OPzXYE",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-QO9qiA", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "civHPL",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-civHPL"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "mgsETK",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-civHPL", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "GMNvCg",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-GMNvCg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "contact" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "xU32xf",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-GMNvCg", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Visit us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "qZ64Em",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-6-component-qZ64Em"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "nhZnV7",
              type: "",
              htmlTagName: "div",
              classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [
                {
                  childId: "tEvAa7",
                  type: "",
                  htmlTagName: "div",
                  classes: ["basics-6-headingline-qZ64Em"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "SlF45F",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-6-component-SlF45F"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "djE43g",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-6-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "ily0WG",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "contactus-6-col-SlF45F"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "U9y8jI",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["contactus-6-contacttitle-SlF45F"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "h" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact", children: [] },
                      ],
                    },
                    {
                      childId: "scNFV7",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-contacttext-SlF45F"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Looking forward to hearing from you!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "cnwCCf",
                      type: "",
                      htmlTagName: "h5",
                      classes: ["contactus-6-subtitle-SlF45F"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Phone", children: [] },
                      ],
                    },
                    {
                      childId: "hfpcnQ",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-subtext-SlF45F"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "123 456 7890",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "dzJ356",
                      type: "",
                      htmlTagName: "h5",
                      classes: ["contactus-6-subtitle-SlF45F"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Email", children: [] },
                      ],
                    },
                    {
                      childId: "ZYwtIJ",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-subtext-SlF45F"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "email@site.com",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "WA3fyJ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-SlF45F"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-SlF45F"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-SlF45F"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "name" },
                            { property: "placeholder", value: "Your name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-SlF45F"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-SlF45F"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-SlF45F"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-SlF45F"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-SlF45F"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-SlF45F"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-SlF45F"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-SlF45F"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-6-inputfield-SlF45F"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-6-buttonwrapper-SlF45F"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-6-button-SlF45F"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "s9IlUT",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "footer-6-component-s9IlUT"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "CQtFJ2",
              type: "block",
              htmlTagName: "div",
              classes: ["d-flex", "flex-wrap", "justify-content-center", "align-items-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "L3F28P",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-s9IlUT"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "HHqFh5",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-solid", "fa-envelope"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "olB6Tz",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-s9IlUT"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "LmGE4j",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-brands", "fa-twitter"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "uuGlOz",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-s9IlUT"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "PPhcju",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-brands", "fa-linkedin"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "l7KSNo",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-s9IlUT"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "eiRGyA",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              childId: "xqUbeX",
              type: "",
              htmlTagName: "p",
              classes: ["footer-6-text-s9IlUT", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "© Copyright Total Car Solutions. All rights reserved.",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      _id: "642484e2444b0c1ceff7a611",
    },
  ],
  sbCustomCss: [
    {
      componentId: "iZlP18",
      classes: [
        {
          className: "scrolledpast-iZlP18.navbar-2-wrapper-iZlP18",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "rem" },
            { property: "padding-bottom", value: "rem" },
            { property: "background", value: "" },
          ],
        },
        { className: "scrolledpast-iZlP18 .navbar-2-logo-iZlP18", pseudo: "", rules: [{ property: "max-height", value: "px" }] },
        {
          className: "navbar-2-wrapper-iZlP18",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "0.5rem" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 5px 7px 3px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "navbar-2-logo-iZlP18",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "50px" },
          ],
        },
        {
          className: "navbar-2-toggler-iZlP18",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "color", value: "rgba(5, 73, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-2-toggler-iZlP18",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(178, 178, 178, 0)" },
            { property: "color", value: "rgba(3, 43, 129, 1)" },
          ],
        },
        {
          className: "navbar-2-link-iZlP18",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
            { property: "font-family", value: "var(--font-body)" },
            { property: "font-size", value: "1rem" },
            { property: "text-decoration", value: "none" },
            { property: "font-weight", value: "inherit" },
          ],
        },
        { className: "navbar-2-link-iZlP18", pseudo: "hover", rules: [{ property: "color", value: "rgba(5, 73, 218, 1)" }] },
      ],
    },
    {
      componentId: "lhntHP",
      classes: [
        {
          className: "section.parallax-3-component-lhntHP",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-3-component-lhntHP", pseudo: "hover", rules: [] },
        {
          className: "parallax-3-component-lhntHP",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-3-wrapper-lhntHP",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/garage3.jpg")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-3-containercontent-lhntHP",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "padding", value: "1.5rem 1.5rem 1.5rem 1.5rem" },
          ],
        },
        {
          className: "parallax-3-overlaywrapper-lhntHP",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "justify-content", value: "start" },
          ],
        },
        {
          className: "parallax-3-content-lhntHP",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "center" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "1rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "parallax-3-content-lhntHP",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "parallax-3-text-lhntHP",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1.5rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-3-text-lhntHP", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-3-title-lhntHP",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(33, 37, 41, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "4.5rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-3-title-lhntHP", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-3-button-lhntHP",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(40, 40, 40, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1.5rem 0rem 4.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "parallax-3-button-lhntHP",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(40, 40, 40, 1)" },
            { property: "border-color", value: "rgba(40, 40, 40, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "vAByFL",
      classes: [
        {
          className: "section.basics-1-component-vAByFL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", value: "rgba(254, 239, 214, 1)" },
          ],
        },
        { className: "section.basics-1-component-vAByFL", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-vAByFL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-vAByFL", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-vAByFL",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 71, 223, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-vAByFL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "M9dTH3",
      classes: [
        {
          className: "section.basics-6-component-M9dTH3",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.25rem" },
            { property: "padding-bottom", value: "0.25rem" },
            { property: "background", value: "rgba(254, 239, 214, 1)" },
          ],
        },
        { className: "section.basics-6-component-M9dTH3", pseudo: "hover", rules: [] },
        {
          className: "basics-6-component-M9dTH3",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-6-component-M9dTH3", pseudo: "hover", rules: [] },
        {
          className: "basics-6-headingline-M9dTH3",
          pseudo: "",
          rules: [
            { property: "align-self", value: "center" },
            { property: "height", value: "2px" },
            { property: "width", value: "75px" },
            { property: "background", value: "rgba(5, 71, 223, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-6-headingline-M9dTH3",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(5, 71, 223, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "S6wjfz",
      classes: [
        {
          className: "section.card-10-component-S6wjfz",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "4.5rem" },
            { property: "background", value: "rgba(254, 239, 214, 1)" },
          ],
        },
        { className: "section.card-10-component-S6wjfz", pseudo: "hover", rules: [] },
        {
          className: "card-10-text-S6wjfz",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-10-text-S6wjfz", pseudo: "hover", rules: [] },
        {
          className: "card-10-avatar-S6wjfz",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "150px" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-avatar-S6wjfz",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-10-title-S6wjfz",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 71, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0.5rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-10-title-S6wjfz", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-10-wrapper-S6wjfz",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-items", value: "center" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-wrapper-S6wjfz",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-10-col-S6wjfz",
          pseudo: "",
          rules: [
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "1rem 0rem 1rem 0rem" },
          ],
        },
        { className: "card-10-col-S6wjfz", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "k6N0jB",
      classes: [
        {
          className: "section.basics-1-component-k6N0jB",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-k6N0jB", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-k6N0jB",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-k6N0jB", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-k6N0jB",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 71, 223, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-k6N0jB", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "IaVVQP",
      classes: [
        {
          className: "section.basics-6-component-IaVVQP",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.25rem" },
            { property: "padding-bottom", value: "0.25rem" },
          ],
        },
        { className: "section.basics-6-component-IaVVQP", pseudo: "hover", rules: [] },
        {
          className: "basics-6-component-IaVVQP",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-6-component-IaVVQP", pseudo: "hover", rules: [] },
        {
          className: "basics-6-headingline-IaVVQP",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(5, 71, 223, 1)" },
            { property: "width", value: "50px" },
            { property: "height", value: "3px" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "basics-6-headingline-IaVVQP", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "Psylzo",
      classes: [
        {
          className: "section.basics-2-component-Psylzo",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-Psylzo", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-Psylzo",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-Psylzo", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-Psylzo",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-Psylzo", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "p9qV8i",
      classes: [
        {
          className: "section.imggallery-1-component-p9qV8i",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.imggallery-1-component-p9qV8i", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-col-p9qV8i",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "stretch" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "imggallery-1-img-p9qV8i",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "imggallery-1-img-p9qV8i", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-title-p9qV8i",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "imggallery-1-title-p9qV8i", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-text-p9qV8i",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(230, 230, 230, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "imggallery-1-text-p9qV8i", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-wrapper-p9qV8i",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "imggallery-1-wrapper-p9qV8i",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)" },
          ],
        },
        {
          className: "imggallery-1-wrapper-p9qV8i",
          pseudo: "hover .imggallery-1-overlay-content-p9qV8i",
          rules: [
            { property: "visibility", value: "visible" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "imggallery-1-overlay-content-p9qV8i",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "visibility", value: "hidden" },
            { property: "opacity", value: "0" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "background", value: "rgba(0, 0, 0, 0.5)" },
          ],
        },
        { className: "imggallery-1-overlay-content-p9qV8i", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "d1lpLV",
      classes: [
        {
          className: "section.parallax-4-component-d1lpLV",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-4-component-d1lpLV", pseudo: "hover", rules: [] },
        {
          className: "parallax-4-component-d1lpLV",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-4-wrapper-d1lpLV",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "70vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/city1.jpg")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-4-content-d1lpLV",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "parallax-4-title-d1lpLV",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 3rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "parallax-4-title-d1lpLV", pseudo: "hover", rules: [] },
        {
          className: "parallax-4-text-d1lpLV",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "parallax-4-text-d1lpLV", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "rYOsQv",
      classes: [
        {
          className: "section.parallax-2-component-rYOsQv",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-2-component-rYOsQv", pseudo: "hover", rules: [] },
        {
          className: "parallax-2-component-rYOsQv",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-2-wrapper-rYOsQv",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "50vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/car2.jpg")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-2-content-rYOsQv",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "justify-content", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "iB2g0o",
      classes: [
        {
          className: "section.basics-1-component-iB2g0o",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", value: "rgba(254, 239, 214, 1)" },
          ],
        },
        { className: "section.basics-1-component-iB2g0o", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-iB2g0o",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-iB2g0o", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-iB2g0o",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 71, 223, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-iB2g0o", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "dvdiq2",
      classes: [
        {
          className: "section.basics-6-component-dvdiq2",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.25rem" },
            { property: "padding-bottom", value: "0.25rem" },
            { property: "background", value: "rgba(254, 239, 214, 1)" },
          ],
        },
        { className: "section.basics-6-component-dvdiq2", pseudo: "hover", rules: [] },
        {
          className: "basics-6-component-dvdiq2",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-6-component-dvdiq2", pseudo: "hover", rules: [] },
        {
          className: "basics-6-headingline-dvdiq2",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(5, 71, 223, 1)" },
            { property: "width", value: "50px" },
            { property: "height", value: "3px" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "basics-6-headingline-dvdiq2", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "QO9qiA",
      classes: [
        {
          className: "section.basics-2-component-QO9qiA",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "background", value: "rgba(254, 239, 214, 1)" },
          ],
        },
        { className: "section.basics-2-component-QO9qiA", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-QO9qiA",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-QO9qiA", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-QO9qiA",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1.5rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-QO9qiA", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "civHPL",
      classes: [
        {
          className: "section.basics-2-component-civHPL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(254, 239, 214, 1)" },
          ],
        },
        { className: "section.basics-2-component-civHPL", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-civHPL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-civHPL", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-civHPL",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-civHPL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "SlF45F",
      classes: [
        {
          className: "section.contactus-6-component-SlF45F",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-6-component-SlF45F", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-col-SlF45F",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-6-contacttitle-SlF45F",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "color", value: "rgba(5, 71, 223, 1)" },
          ],
        },
        {
          className: "contactus-6-contacttitle-SlF45F",
          pseudo: "hover",
          rules: [],
        },
        { className: "contactus-6-contacttext-SlF45F", pseudo: "", rules: [{ property: "margin", value: "0 0 2rem 0" }] },
        { className: "contactus-6-contacttext-SlF45F", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-subtitle-SlF45F",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "color", value: "rgba(5, 71, 223, 1)" },
          ],
        },
        {
          className: "contactus-6-subtitle-SlF45F",
          pseudo: "hover",
          rules: [],
        },
        { className: "contactus-6-subtext-SlF45F", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        { className: "contactus-6-subtext-SlF45F", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-buttonwrapper-SlF45F",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "contactus-6-buttonwrapper-SlF45F",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-6-button-SlF45F",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(5, 71, 223, 0)" },
            { property: "color", value: "rgba(5, 71, 223, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(5, 71, 223, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "font-weight", value: "bold" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-6-button-SlF45F",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(5, 71, 223, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(5, 71, 223, 1)" },
          ],
        },
        {
          className: "contactus-6-inputwrapper-SlF45F",
          pseudo: "",
          rules: [{ property: "margin", value: "0 0 1rem 0" }],
        },
        {
          className: "contactus-6-inputfield-SlF45F",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(5, 71, 223, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(5, 71, 223, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-6-inputfield-SlF45F",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(5, 71, 223, 1)" },
            { property: "border-color", value: "rgba(5, 71, 223, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(5, 71, 223, 0.2)" },
          ],
        },
        {
          className: "contactus-6-inputfield-SlF45F",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "contactus-6-inputfield-SlF45F",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-6-label-SlF45F",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        {
          className: "contactus-6-label-SlF45F",
          pseudo: "hover",
          rules: [],
        },
      ],
    },
    {
      componentId: "GMNvCg",
      classes: [
        {
          className: "section.basics-1-component-GMNvCg",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-GMNvCg", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-GMNvCg",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-GMNvCg", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-GMNvCg",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 71, 223, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-GMNvCg", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "qZ64Em",
      classes: [
        {
          className: "section.basics-6-component-qZ64Em",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.25rem" },
            { property: "padding-bottom", value: "0.25rem" },
          ],
        },
        { className: "section.basics-6-component-qZ64Em", pseudo: "hover", rules: [] },
        {
          className: "basics-6-component-qZ64Em",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-6-component-qZ64Em", pseudo: "hover", rules: [] },
        {
          className: "basics-6-headingline-qZ64Em",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(5, 71, 223, 1)" },
            { property: "width", value: "50px" },
            { property: "height", value: "3px" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "basics-6-headingline-qZ64Em", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "s9IlUT",
      classes: [
        {
          className: "section.footer-6-component-s9IlUT",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "4.5rem" },
            { property: "background", value: "rgba(5, 73, 218, 1)" },
          ],
        },
        { className: "section.footer-6-component-s9IlUT", pseudo: "hover", rules: [] },
        {
          className: "footer-6-component-s9IlUT",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "footer-6-component-s9IlUT", pseudo: "hover", rules: [] },
        {
          className: "footer-6-text-s9IlUT",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(233, 236, 239, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "3rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-6-text-s9IlUT", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "footer-6-social-link-s9IlUT",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "height", value: "45px" },
            { property: "aspect-ratio", value: "1" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(5, 73, 218, 1)" },
            { property: "background", value: "rgba(255, 254, 254, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "font-size", value: "1.2rem" },
          ],
        },
        {
          className: "footer-6-social-link-s9IlUT",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(3, 48, 139, 1)" },
            { property: "background", value: "rgba(231, 234, 237, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "footer-6-social-link-s9IlUT", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Playfair+Display:700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Montserrat:100,300,700&display=swap",
    fontHeadersName: "Playfair Display",
    fontHeadersWeight: "700",
    fontBodyName: "Montserrat",
    fontBodyWeight: "400",
    color1: "rgba(5, 71, 223, 1)",
    color2: "rgba(188, 116, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
