import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ColorPicker from "./formComponents/ColorPicker";
import Select from "./formComponents/Select";
import Slider from "./formComponents/Slider";
import Divider from "./formComponents/Divider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const CarouselNavStyles = ({ sb: { startStyles } }) => {
  const [carouselNavStyles, setCarouselNavStyles] = useState(null);
  const [boolIncludeNavButtons, setBoolIncludeNavButtons] = useState(false);

  useEffect(() => {
    try {
      let navStyles = formGetStartValue(startStyles, "carouselNavStyles");
      if (navStyles !== null && typeof navStyles !== "undefined" && Object.keys(navStyles).length > 0) {
        setCarouselNavStyles(navStyles);
        setBoolIncludeNavButtons(navStyles.includeNavButtons);
      } else {
        setCarouselNavStyles(null);
      }
    } catch (error) {
      setCarouselNavStyles(null);
    }
  }, [startStyles]);

  const getCarouselStartVal = (varName) => {
    try {
      return carouselNavStyles[varName];
    } catch (error) {
      return "";
    }
  };

  return (
    <>
      {/* Show  */}
      <div className={`form-check form-switch my-3`}>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id={`${EDIT_FORM_ID_PREFIX}carousel_nav_includeNavButtons`}
          value={boolIncludeNavButtons}
          checked={boolIncludeNavButtons}
          onChange={(e) => setBoolIncludeNavButtons(e.target.checked)}
        />
        <label className="form-check-label" htmlFor={`${EDIT_FORM_ID_PREFIX}carousel_nav_includeNavButtons`}>
          {translate("cEditForms.carouselNav_includeNavBtns", false, null)}
        </label>
      </div>
      {boolIncludeNavButtons && (
        <>
          <Divider />
          {/* Position */}
          <Select
            label={translate("cEditForms.carouselNav_btnPos", false, null)}
            id={"carousel_nav_pos"}
            options={[
              { val: "inside", label: translate("cEditForms.inside", false, null) },
              { val: "outside", label: translate("cEditForms.outside", false, null) },
            ]}
            startValue={getCarouselStartVal("pos")}
            tooltipText={translate("cEditForms.carouselNav_ttNavPos", false, null)}
          />
          <Divider />
          {/* Size */}
          <Slider
            label={translate("cEditForms.carouselNav_btnSize", false, null)}
            id={"carousel_nav_iconSize"}
            min={1}
            max={3}
            step={0.1}
            startValue={getCarouselStartVal("iconSize")}
            tooltipText={translate("cEditForms.carouselNav_ttBtnSize", false, null)}
          />
          <Divider />
          {/* Icon color */}
          <ColorPicker
            label={translate("cEditForms.carouselNav_iconColor", false, null)}
            id={"carousel_nav_fillColor"}
            startValue={getCarouselStartVal("fillColor")}
            tooltipText={translate("cEditForms.carouselNav_ttIconColor", false, null)}
          />
          <ColorPicker
            label={translate("cEditForms.carouselNav_iconColorHover", false, null)}
            id={"carousel_nav_fillColorHover"}
            startValue={getCarouselStartVal("fillColorHover")}
            tooltipText={translate("cEditForms.carouselNav_ttIconColorHover", false, null)}
          />
          <Divider />
          {/* Bg color */}
          <ColorPicker
            label={translate("cEditForms.carouselNav_bgColor", false, null)}
            id={"carousel_nav_bgColor"}
            startValue={getCarouselStartVal("bgColor")}
            tooltipText={translate("cEditForms.carouselNav_ttBgColor", false, null)}
          />
          <ColorPicker
            label={translate("cEditForms.carouselNav_bgColorHover", false, null)}
            id={"carousel_nav_bgColorHover"}
            startValue={getCarouselStartVal("bgColorHover")}
            tooltipText={translate("cEditForms.carouselNav_ttBgColorHover", false, null)}
          />
          <Divider />
          {/* Border */}
          <Select
            label={translate("cEditForms.borderSides", false, null)}
            id={"carousel_nav_borderSide"}
            options={[
              { val: "none", label: translate("cEditForms.noBorder", false, null) },
              { val: "all", label: translate("cEditForms.allSides", false, null) },
              { val: "left", label: translate("cEditForms.left", false, null) },
              { val: "right", label: translate("cEditForms.right", false, null) },
              { val: "top", label: translate("cEditForms.top", false, null) },
              { val: "bottom", label: translate("cEditForms.bottom", false, null) },
              { val: "topbottom", label: translate("cEditForms.topAndBottom", false, null) },
              { val: "leftright", label: translate("cEditForms.leftAndRight", false, null) },
            ]}
            startValue={getCarouselStartVal("borderSide")}
            tooltipText={translate("cEditForms.carouselNav_ttBorder", false, null)}
          />
          <Select
            label={translate("cEditForms.borderType", false, null)}
            id={"carousel_nav_borderType"}
            options={[
              { val: "hidden", label: translate("cEditForms.hidden", false, null) },
              { val: "dotted", label: translate("cEditForms.dotted", false, null) },
              { val: "dashed", label: translate("cEditForms.dashed", false, null) },
              { val: "solid", label: translate("cEditForms.solid", false, null) },
              { val: "double", label: translate("cEditForms.double", false, null) },
              { val: "groove", label: translate("cEditForms.groove", false, null) },
              { val: "ridge", label: translate("cEditForms.ridge", false, null) },
              { val: "inset", label: translate("cEditForms.inset", false, null) },
              { val: "outset", label: translate("cEditForms.outset", false, null) },
            ]}
            startValue={getCarouselStartVal("borderType")}
            tooltipText={translate("cEditForms.tooltipBorderType", false, null)}
          />
          <Slider
            label={translate("cEditForms.borderSize", false, null)}
            id={"carousel_nav_borderWidth"}
            min={0}
            max={10}
            step={1}
            startValue={getCarouselStartVal("borderWidth")}
            tooltipText={translate("cEditForms.tooltipBorderSize", false, null)}
          />
          <ColorPicker
            label={translate("cEditForms.borderColor", false, null)}
            id={"carousel_nav_borderColor"}
            startValue={getCarouselStartVal("borderColor")}
            tooltipText={translate("cEditForms.tooltipBorderColor", false, null)}
          />
          <ColorPicker
            label={translate("cEditForms.borderColorHover", false, null)}
            id={"carousel_nav_borderColorHover"}
            startValue={getCarouselStartVal("borderColorHover")}
            tooltipText={translate("cEditForms.tooltipBorderColorHover", false, null)}
          />
          <Divider />
          <Select
            label={translate("cEditForms.borderRadius", false, null)}
            id={"carousel_nav_borderRadius"}
            options={[
              { val: 0, label: translate("cEditForms.square", false, null) },
              { val: 0.2, label: translate("cEditForms.roundedSmall", false, null) },
              { val: 0.4, label: translate("cEditForms.roundedMedium", false, null) },
              { val: 0.75, label: translate("cEditForms.roundedLarge", false, null) },
              { val: 1, label: translate("cEditForms.roundedExtraLarge", false, null) },
              { val: 1000, label: translate("cEditForms.pill", false, null) },
              { val: 50, label: translate("cEditForms.circle", false, null) },
            ]}
            startValue={getCarouselStartVal("borderRadius")}
            tooltipText={translate("cEditForms.tooltipBorderRadius", false, null)}
          />
          <Divider />
          <Slider
            label={translate("cEditForms.carouselNav_speed", false, null)}
            id={"carousel_nav_transition"}
            min={0}
            max={3}
            step={0.1}
            startValue={getCarouselStartVal("transition")}
            tooltipText={translate("cEditForms.carouselNav_ttSpeed", false, null)}
          />
        </>
      )}
    </>
  );
};

CarouselNavStyles.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(CarouselNavStyles);
