import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./formComponents/Select";
import ColorPicker from "./formComponents/ColorPicker";
import LinkDestTarget from "./formComponents/LinkDestTarget";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const LinkEditor = ({ sb: { startStyles } }) => {
  return (
    <>
      <LinkDestTarget />
      <ColorPicker
        label={translate("cEditForms.linkColorNormal", false, null)}
        id={"textColor"}
        startValue={formGetStartValue(startStyles, "textColor")}
        tooltipText={translate("cEditForms.ttLinkColorNormal", false, null)}
      />
      <Select
        label={translate("cEditForms.linkStyleNormal", false, null)}
        id={"linkDeco"}
        options={[
          { val: "none", label: translate("cEditForms.none", false, null) },
          { val: "underline", label: translate("cEditForms.underlined", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "linkDeco")}
        tooltipText={translate("cEditForms.ttLinkStyleNormal", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.linkColorHover", false, null)}
        id={"textColorHover"}
        startValue={formGetStartValue(startStyles, "textColorHover")}
        tooltipText={translate("cEditForms.ttLinkColorHover", false, null)}
      />
      <Select
        label={translate("cEditForms.linkStyleHover", false, null)}
        id={"linkDecoHover"}
        options={[
          { val: "none", label: translate("cEditForms.none", false, null) },
          { val: "underline", label: translate("cEditForms.underlined", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "linkDecoHover")}
        tooltipText={translate("cEditForms.ttLinkStyleHover", false, null)}
      />
    </>
  );
};

LinkEditor.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(LinkEditor);
