export const sectiondividers41 = {
  id: "sectiondividers-41",
  name: "Section dividers 41",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: [],
        styles: [
          { property: "position", value: "relative" },
          { property: "height", value: "250px" },
        ],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "svg",
            classes: [],
            styles: [
              { property: "position", value: "absolute" },
              { property: "top", value: "0" },
              { property: "left", value: "0" },
              { property: "width", value: "100%" },
              { property: "height", value: "250px" },
              { property: "transform", value: "scaleY(-1)" },
              { property: "background", value: "rgba(255, 255, 255, 1)" },
            ],
            attributes: [
              { property: "xmlns", value: "http://www.w3.org/2000/svg" },
              { property: "viewBox", value: "0 0 1000 122.06" },
              { property: "preserveAspectRatio", value: "xMidYMin slice" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M959.06 68.88l-23.18 23.18h-.42l-23.18-23.18-23.18 23.18h-.42L865.5 68.88l-23.18 23.18h-.43l-23.18-23.18-23.18 23.18h-.42l-23.18-23.18-23.18 23.18h-.42l-23.18-23.18L702 92.06h-.43l-23.21-23.18-23.18 23.18h-.42l-23.18-23.18-23.18 23.18h-.4l-23.21-23.18-23.17 23.18h-.43L538 68.88l-23.17 23.18h-.42l-23.18-23.18-23.18 23.18h-.43l-23.18-23.18-23.18 23.18h-.42l-23.18-23.18-23.18 23.18h-.42l-23.18-23.18-23.18 23.18h-.43l-23.18-23.18-23.18 23.18h-.42l-23.18-23.18-23.18 23.18h-.42l-23.18-23.18-23.18 23.18h-.43l-23.18-23.18-23.18 23.18h-.42L117 68.88 93.78 92.06h-.42L70.17 68.88 47 92.06h-.43L23.39 68.88.21 92.06H0v30h1000v-30h-17.76z",
                  },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [{ property: "isolation", value: "isolate" }],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M964.91 68.66l17.55-17.55L1000 68.66 982.46 86.2zm-46.77 0l17.54-17.54 17.54 17.54-17.54 17.54zm-46.8 0l17.54-17.54 17.55 17.54-17.55 17.54zm-46.78 0l17.54-17.55 17.55 17.55L842.1 86.2zm-46.78 0l17.55-17.56 17.54 17.55-17.54 17.54zm-46.79 0l17.54-17.54 17.54 17.54-17.54 17.54zm-46.78 0l17.54-17.54 17.54 17.54-17.54 17.53zm-46.78 0L655 51.11l17.55 17.54L655 86.19zm-46.78 0l17.54-17.54 17.54 17.54-17.54 17.54zm-46.79 0l17.54-17.55 17.54 17.54L561.4 86.2zm-46.79 0l17.54-17.55 17.55 17.55-17.55 17.54zm-46.78 0l17.55-17.55 17.54 17.55-17.54 17.54zm-46.78 0l17.55-17.55 17.54 17.55-17.54 17.54zm-46.79 0l17.54-17.54 17.54 17.54-17.54 17.54zm-46.78 0l17.54-17.54L345 68.66 327.48 86.2zm-46.78 0l17.54-17.55 17.55 17.54-17.55 17.54zm-46.78 0l17.54-17.54 17.54 17.54-17.54 17.54zm-46.79 0l17.54-17.54 17.54 17.54-17.54 17.53zm-46.78 0l17.54-17.54 17.55 17.53-17.55 17.54zm-46.81 0l17.57-17.55 17.54 17.55L93.57 86.2zm-46.77 0l17.56-17.55 17.54 17.54L46.79 86.2z",
                  },
                  { property: "opacity", value: "0.85" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [{ property: "isolation", value: "isolate" }],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M944.45 49.94l14.62-14.63 14.62 14.63-14.62 14.62zm-46.79 0l14.62-14.62 14.62 14.62-14.62 14.63zm-46.79 0l14.62-14.62 14.63 14.62-14.63 14.63zm-46.78 0l14.62-14.62 14.63 14.62-14.63 14.62zm-46.78 0l14.62-14.63 14.62 14.63-14.62 14.62zm-46.78 0l14.62-14.62 14.62 14.62-14.62 14.62zm-46.8 0l14.63-14.62L693 49.94l-14.64 14.62zm-46.78 0l14.63-14.63 14.62 14.63-14.62 14.62zm-46.77 0l14.62-14.63 14.62 14.63-14.62 14.62zm-46.79 0L538 35.32l14.63 14.62L538 64.57zm-46.79 0l14.62-14.62 14.63 14.62-14.63 14.62zm-46.78 0l14.63-14.62 14.62 14.62-14.62 14.62zm-46.78 0l14.62-14.62 14.62 14.62-14.62 14.63zm-46.78 0l14.62-14.62 14.63 14.62-14.63 14.63zm-46.79 0l14.62-14.62 14.63 14.62-14.63 14.62zm-46.78 0l14.62-14.63 14.62 14.63-14.62 14.62zm-46.78 0l14.62-14.63 14.62 14.63-14.62 14.62zm-46.79 0l14.62-14.62 14.63 14.62-14.63 14.62zm-46.79 0L117 35.32l14.62 14.62L117 64.56zm-46.77 0l14.62-14.63L84.8 49.94 70.18 64.56zm-46.79 0l14.62-14.62L38 49.94 23.39 64.57z",
                  },
                  { property: "opacity", value: "0.7" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [{ property: "isolation", value: "isolate" }],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M970.77 35.91l11.7-11.7 11.69 11.7-11.69 11.69zm-46.8 0l11.7-11.69 11.7 11.69-11.7 11.7zm-46.77 0l11.69-11.69 11.7 11.69-11.7 11.7zm-46.78 0l11.69-11.69 11.7 11.69-11.7 11.7zm-46.8 0l11.7-11.7L807 35.91 795.32 47.6zm-46.78 0l11.7-11.69 11.69 11.68-11.69 11.7zm-46.78 0l11.69-11.7 11.7 11.7-11.7 11.7zm-46.78 0L655 24.21l11.69 11.7L655 47.6zm-46.79 0l11.7-11.7 11.69 11.7-11.69 11.69zm-46.78 0l11.69-11.69 11.7 11.69-11.7 11.7zm-46.78 0l11.69-11.69 11.7 11.69-11.7 11.7zm-46.78 0l11.7-11.7 11.69 11.7-11.69 11.69zm-46.8 0l11.7-11.7 11.7 11.7-11.7 11.69zm-46.77 0l11.69-11.69L386 35.9l-11.7 11.7zm-46.78 0l11.69-11.69 11.7 11.69-11.7 11.7zm-46.79 0l11.7-11.7 11.69 11.7-11.69 11.69zm-46.79 0l11.7-11.7 11.69 11.7-11.69 11.69zm-46.78 0l11.69-11.69 11.7 11.69-11.7 11.7zm-46.78 0l11.7-11.7 11.69 11.7-11.69 11.69zm-46.78 0l11.7-11.7 11.69 11.7L93.58 47.6zm-46.8 0l11.7-11.69 11.7 11.69-11.7 11.7z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [{ property: "isolation", value: "isolate" }],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M950.29 21.88l8.78-8.78 8.78 8.78-8.78 8.77zm-46.77 0l8.77-8.78 8.78 8.78-8.78 8.77zm-46.8 0l8.77-8.77 8.78 8.77-8.78 8.78zm-46.78 0l8.78-8.78 8.77 8.78-8.77 8.77zm-46.78 0l8.78-8.78 8.77 8.78-8.77 8.77zm-46.78 0l8.77-8.77 8.78 8.77-8.78 8.78zm-46.79 0l8.77-8.77 8.78 8.77-8.78 8.78zm-46.78 0l8.78-8.77 8.77 8.77-8.77 8.78zm-46.78 0l8.77-8.78 8.78 8.78-8.78 8.77zm-46.79 0L538 13.1l8.78 8.77-8.78 8.78zm-46.78 0l8.77-8.77 8.77 8.76-8.78 8.78zm-46.79 0l8.78-8.78 8.77 8.78-8.77 8.77zm-46.78 0l8.78-8.78 8.78 8.78-8.78 8.77zm-46.79 0l8.77-8.77 8.78 8.77-8.78 8.78zm-46.78 0l8.78-8.77 8.77 8.77-8.77 8.78zm-46.78 0l8.78-8.78 8.77 8.78-8.77 8.77zm-46.78 0l8.77-8.77 8.78 8.77-8.78 8.78zm-46.79 0l8.77-8.77 8.78 8.77-8.78 8.78zm-46.78 0L117 13.1l8.77 8.77-8.77 8.78zm-46.79 0l8.78-8.78L79 21.88l-8.77 8.77zm-46.77 0l8.77-8.78 8.77 8.78-8.77 8.77z",
                  },
                  { property: "opacity", value: "0.3" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [{ property: "isolation", value: "isolate" }],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M976.61 12.29l5.85-5.85 5.84 5.85-5.84 5.84zm-46.78 0l5.85-5.84 5.85 5.84-5.85 5.85zm-46.79 0l5.84-5.85 5.85 5.85-5.85 5.85zm-46.78 0l5.85-5.85 5.89 5.84-5.84 5.85zm-46.78 0l5.85-5.85 5.84 5.85-5.84 5.85zm-46.79 0l5.85-5.85 5.85 5.85-5.85 5.85zm-46.78 0l5.85-5.85 5.85 5.85-5.85 5.85zm-46.78 0L655 6.43l5.85 5.85-5.85 5.85zm-46.79 0l5.85-5.84 5.81 5.83-5.85 5.85zm-46.78 0l5.85-5.84 5.85 5.84-5.85 5.85zm-46.79 0l5.84-5.85 5.85 5.85-5.85 5.85zm-46.78 0l5.85-5.85 5.84 5.85-5.84 5.85zm-46.78 0l5.85-5.85 5.84 5.85-5.84 5.84zm-46.79 0l5.85-5.85 5.85 5.85-5.85 5.85zm-46.78 0l5.84-5.85 5.85 5.85-5.85 5.85zm-46.78 0l5.84-5.85 5.85 5.85-5.85 5.85zm-46.79 0l5.85-5.84 5.85 5.84-5.85 5.85zm-46.78 0l5.85-5.85 5.86 5.84-5.85 5.85zm-46.78 0l5.84-5.85 5.85 5.85-5.85 5.85zm-46.79 0l5.85-5.85 5.85 5.85-5.85 5.84zm-46.78 0l5.85-5.84 5.85 5.84-5.85 5.85z",
                  },
                  { property: "opacity", value: "0.15" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [{ property: "isolation", value: "isolate" }],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M956.15 2.93L959.08 0 962 2.93l-2.92 2.92zm-46.8 0L912.28 0l2.93 2.93-2.93 2.93zm-46.76 0L865.51 0l2.93 2.93-2.93 2.93zm-46.79 0L818.72 0l2.93 2.93-2.93 2.93zm-46.8 0L771.94 0l2.93 2.92-2.93 2.93zm-46.78 0L725.16 0l2.93 2.92-2.93 2.93zm-46.78 0L678.38 0l2.92 2.93-2.92 2.92zm-46.79 0L631.59 0l2.92 2.93-2.92 2.93zm-46.78 0L584.81 0l2.92 2.93-2.92 2.92zm-46.79 0L538 0l2.93 2.93L538 5.86zm-46.78 0L491.23 0l2.93 2.93-2.93 2.93zm-46.78 0L444.46 0l2.92 2.93-2.92 2.93zm-46.79 0L397.67 0l2.93 2.92-2.93 2.93zm-46.73 0L350.88 0l2.93 2.93-2.93 2.93zm-46.77 0L304.1 0l2.9 2.93-2.9 2.93zm-46.79 0L257.32 0l2.92 2.93-2.92 2.93zm-46.78 0L210.54 0l2.93 2.92-2.93 2.93zm-46.79 0L163.75 0l2.93 2.92-2.93 2.93zm-46.87 0L117 0l2.93 2.93L117 5.86zm-46.78 0L70.19 0l2.92 2.93-2.92 2.92zm-46.8 0L23.39 0l2.93 2.93-2.93 2.93z",
                  },
                  { property: "opacity", value: "0.07" },
                ],
                content: "",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
