import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./formComponents/Select";
import Slider from "./formComponents/Slider";
import ColorPicker from "./formComponents/ColorPicker";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const Border = ({ sb: { startStyles } }) => {
  const [bool, setBool] = useState(false);
  useEffect(() => {
    if (formGetStartValue(startStyles, "borderColorHover") === formGetStartValue(startStyles, "borderColor")) {
      setBool(false);
    } else {
      setBool(true);
    }
  }, [startStyles]);

  return (
    <>
      <Select
        label={translate("cEditForms.borderSides", false, null)}
        id={"borderSide"}
        options={[
          { val: "none", label: translate("cEditForms.noBorder", false, null) },
          { val: "all", label: translate("cEditForms.allSides", false, null) },
          { val: "left", label: translate("cEditForms.left", false, null) },
          { val: "right", label: translate("cEditForms.right", false, null) },
          { val: "top", label: translate("cEditForms.top", false, null) },
          { val: "bottom", label: translate("cEditForms.bottom", false, null) },
          { val: "topbottom", label: translate("cEditForms.topAndBottom", false, null) },
          { val: "leftright", label: translate("cEditForms.leftAndRight", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "borderSide")}
        tooltipText={translate("cEditForms.tooltipBorderSides", false, null)}
      />
      <Select
        label={translate("cEditForms.borderType", false, null)}
        id={"borderType"}
        options={[
          { val: "hidden", label: translate("cEditForms.hidden", false, null) },
          { val: "dotted", label: translate("cEditForms.dotted", false, null) },
          { val: "dashed", label: translate("cEditForms.dashed", false, null) },
          { val: "solid", label: translate("cEditForms.solid", false, null) },
          { val: "double", label: translate("cEditForms.double", false, null) },
          { val: "groove", label: translate("cEditForms.groove", false, null) },
          { val: "ridge", label: translate("cEditForms.ridge", false, null) },
          { val: "inset", label: translate("cEditForms.inset", false, null) },
          { val: "outset", label: translate("cEditForms.outset", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "borderType")}
        tooltipText={translate("cEditForms.tooltipBorderType", false, null)}
      />
      <Slider
        label={translate("cEditForms.borderSize", false, null)}
        id={"borderWidth"}
        min={0}
        max={10}
        step={1}
        startValue={formGetStartValue(startStyles, "borderWidth")}
        tooltipText={translate("cEditForms.tooltipBorderSize", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.borderColor", false, null)}
        id={"borderColor"}
        startValue={formGetStartValue(startStyles, "borderColor")}
        tooltipText={translate("cEditForms.tooltipBorderColor", false, null)}
      />
      <div className={`form-check form-switch mt-3 ${bool ? "mb-n2" : "mb-3"}`}>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="switchBorderColorHover"
          value={bool}
          checked={bool}
          onChange={(e) => setBool(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="switchBorderColorHover">
          {translate("cEditForms.changeBorderColorOnHover", false, null)}
        </label>
      </div>
      {bool && (
        <ColorPicker
          label={translate("cEditForms.borderColorHover", false, null)}
          id={"borderColorHover"}
          startValue={formGetStartValue(startStyles, "borderColorHover")}
          tooltipText={translate("cEditForms.tooltipBorderColorHover", false, null)}
        />
      )}
    </>
  );
};

Border.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Border);
