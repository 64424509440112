export const imggallery6 = {
  id: "imggallery-6",
  name: "Image gallery 6",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "imggallery-6-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "imggallery-6-row"],
        styles: [],
        attributes: [
          { property: "data-name", value: "block" },
          {
            property: "data-masonry",
            value: '{"percentPosition":true,"itemSelector":".imggallery-6-col"}',
          },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "img_gallery1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-6-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-overlaywrapper", value: "true" },
                  { property: "data-checkparent", value: "true" },
                  { property: "data-editable", value: "false" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-6-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/masonry1.jpg" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["imggallery-6-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["imggallery-6-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Title", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["imggallery-6-text", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "img_gallery1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-6-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-overlaywrapper", value: "true" },
                  { property: "data-checkparent", value: "true" },
                  { property: "data-editable", value: "false" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-6-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/masonry2.jpg" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["imggallery-6-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["imggallery-6-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Title", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["imggallery-6-text", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "img_gallery1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-6-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-overlaywrapper", value: "true" },
                  { property: "data-checkparent", value: "true" },
                  { property: "data-editable", value: "false" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-6-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/masonry3.jpg" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["imggallery-6-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["imggallery-6-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Title", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["imggallery-6-text", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "img_gallery1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-6-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-overlaywrapper", value: "true" },
                  { property: "data-checkparent", value: "true" },
                  { property: "data-editable", value: "false" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-6-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/masonry4.jpg" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["imggallery-6-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["imggallery-6-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Title", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["imggallery-6-text", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "img_gallery1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-6-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-overlaywrapper", value: "true" },
                  { property: "data-checkparent", value: "true" },
                  { property: "data-editable", value: "false" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-6-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/masonry5.jpg" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["imggallery-6-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["imggallery-6-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Title", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["imggallery-6-text", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "img_gallery1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-6-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-overlaywrapper", value: "true" },
                  { property: "data-checkparent", value: "true" },
                  { property: "data-editable", value: "false" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-6-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/masonry6.jpg" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["imggallery-6-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["imggallery-6-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Title", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["imggallery-6-text", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "img_gallery1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-6-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-overlaywrapper", value: "true" },
                  { property: "data-checkparent", value: "true" },
                  { property: "data-editable", value: "false" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-6-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/masonry7.jpg" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["imggallery-6-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["imggallery-6-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Title", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["imggallery-6-text", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "img_gallery1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-6-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-overlaywrapper", value: "true" },
                  { property: "data-checkparent", value: "true" },
                  { property: "data-editable", value: "false" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-6-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/masonry8.jpg" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["imggallery-6-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["imggallery-6-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Title", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["imggallery-6-text", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "img_gallery1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-6-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-overlaywrapper", value: "true" },
                  { property: "data-checkparent", value: "true" },
                  { property: "data-editable", value: "false" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-6-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/masonry9.jpg" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["imggallery-6-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                      { property: "data-editable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["imggallery-6-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Title", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["imggallery-6-text", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.imggallery-6-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.imggallery-6-component", pseudo: "hover", rules: [] },
      {
        className: "imggallery-6-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "0rem 0rem 0rem 0rem" },
          { property: "display", value: "flex" },
        ],
      },
      {
        className: "imggallery-6-wrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "justify-content", value: "center" },
          { property: "align-items", value: "center" },
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "width", value: "100%" },
          { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          { property: "border-radius", value: "1rem" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(0, 0, 0, 1)" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.2)" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "imggallery-6-wrapper",
        pseudo: "hover",
        rules: [
          { property: "border-color", value: "rgba(0, 0, 0, 1)" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.3)" },
        ],
      },
      {
        className: "imggallery-6-wrapper",
        pseudo: "hover .imggallery-6-overlay-content",
        rules: [
          { property: "visibility", value: "visible" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "imggallery-6-overlay-content",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "background", value: "rgba(0, 0, 0, 0.5)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "visibility", value: "hidden" },
          { property: "opacity", value: "0" },
        ],
      },
      {
        className: "imggallery-6-overlay-content",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "imggallery-6-img",
        pseudo: "",
        rules: [
          { property: "width", value: "100%" },
          { property: "height", value: "auto" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "imggallery-6-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "imggallery-6-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "text-align", value: "center" },
          { property: "font-weight", value: "bold" },
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "imggallery-6-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "imggallery-6-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "text-align", value: "center" },
          { property: "color", value: "rgba(230, 230, 230, 1)" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "imggallery-6-text",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
