export const MARGIN_VALUES = [-8, -6, -4.5, -3, -2, -1.5, -1, -0.5, -0.25, -0.125, 0, 0.125, 0.25, 0.5, 1, 1.5, 2, 3, 4.5, 6, 8];
export const PADDING_VALUES = [0, 0.125, 0.25, 0.5, 1, 1.5, 2, 3, 4.5, 6, 8];
export const BORDER_RADIUS_VALUES = [0, 0.2, 0.4, 0.75, 1, 50, 1000];
export const CAROUSEL_STANDARD_OPTIONS = {
  keyboard: true,
  autoplay: false,
  interval: 2000,
  pauseOnHover: true,
  breakpoints: {
    640: {
      perPage: 1,
      perMove: 1,
    },
  },
  drag: "free",
  easing: "linear",
};
