import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import OverlayColor from "./OverlayColor";
import Slider from "./formComponents/Slider";
import Custom from "./formComponents/Custom";
import Divider from "./formComponents/Divider";
import Select from "./formComponents/Select";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const Parallax = ({ sb: { startStyles } }) => {
  const ParallaxImgSrc = () => {
    const [value, setValue] = useState("");
    useEffect(() => {
      setValue(formGetStartValue(startStyles, "parallaxImgSrc"));
    }, [formGetStartValue(startStyles, "parallaxImgSrc")]);

    return (
      <>
        <div className="d-flex mb-2">
          <span className="editPane-btn trans-3 cursorPointer px-3 py-2 rounded-4" data-bs-toggle="modal" data-bs-target="#ModalSelectImgFromGallery">
            <i className="fa-solid fa-images me-2"></i> {translate("cEditForms.selectFromGallery", false, null)}
          </span>
        </div>
        <input
          type="text"
          className="form-control form-control-sm"
          id={`${EDIT_FORM_ID_PREFIX}parallaxImgSrc`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </>
    );
  };

  return (
    <>
      <Custom
        label={translate("cEditForms.imgSource", false, null)}
        column={true}
        customComponent={ParallaxImgSrc}
        tooltipText={translate("cEditForms.ttSelectFromGallery", false, null)}
      />
      <Divider />
      <Slider
        label={translate("cEditForms.height", false, null)}
        id={"parallaxHeightVh"}
        min={1}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "parallaxHeightVh")}
        tooltipText={translate("cEditForms.ttParallaxHeight", false, null)}
      />
      <OverlayColor />
      <Select
        label={translate("cEditForms.alignParallaxContent", false, null)}
        id={"parallaxVertAlign"}
        options={[
          { val: "start", label: translate("cEditForms.top", false, null) },
          { val: "center", label: translate("cEditForms.middle", false, null) },
          { val: "end", label: translate("cEditForms.bottom", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "parallaxVertAlign")}
        tooltipText={translate("cEditForms.ttAlignParallaxContent", false, null)}
      />
    </>
  );
};

Parallax.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Parallax);
