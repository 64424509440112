import React from "react";

import PreviewTemplate from "./PreviewTemplate";
import { translate } from "../../../translations/translations";

const AddNewComponent = () => {
  return (
    <div className="accordion accordion-flush trans-3 border rounded-4 overflow-hidden" id="accordion-AddNewComponent">
      {/* Basics */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-basics">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-basics"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-basics"
          >
            {translate("cAddNewComponent.basics", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-basics"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-basics"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-1", name: `${translate("cAddNewComponent.compName_buildingBlock_title", false, null)}` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate
                component={{ id: "basics-6", name: `${translate("cAddNewComponent.compName_buildingBlock_headingLine", false, null)}` }}
              />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate
                component={{ id: "basics-8", name: `${translate("cAddNewComponent.compName_buildingBlock_sectionLine", false, null)}` }}
              />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-2", name: `${translate("cAddNewComponent.compName_buildingBlock_para", false, null)}` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-7", name: `${translate("cAddNewComponent.compName_buildingBlock_btn", false, null)}` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-3", name: `${translate("cAddNewComponent.compName_buildingBlock_img", false, null)}` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-4", name: `${translate("cAddNewComponent.compName_buildingBlock_cols1", false, null)}` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "basics-5", name: `${translate("cAddNewComponent.compName_buildingBlock_cols2", false, null)}` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Navbar */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-navbar">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-navbar"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-navbar"
          >
            {translate("cAddNewComponent.navbar", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-navbar"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-navbar"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "navbar-1", name: `${translate("cAddNewComponent.navbar", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "navbar-2", name: `${translate("cAddNewComponent.navbar", false, null)} 2` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "navbar-3", name: `${translate("cAddNewComponent.navbar", false, null)} 3` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Hero */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-hero">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-hero"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-hero"
          >
            {translate("cAddNewComponent.hero", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-hero"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-hero"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-1", name: `${translate("cAddNewComponent.hero", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-6", name: `${translate("cAddNewComponent.hero", false, null)} 6` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-11", name: `${translate("cAddNewComponent.hero", false, null)} 11` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-12", name: `${translate("cAddNewComponent.hero", false, null)} 12` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-2", name: `${translate("cAddNewComponent.hero", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-7", name: `${translate("cAddNewComponent.hero", false, null)} 7` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-9", name: `${translate("cAddNewComponent.hero", false, null)} 9` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-10", name: `${translate("cAddNewComponent.hero", false, null)} 10` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-3", name: `${translate("cAddNewComponent.hero", false, null)} 3` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-4", name: `${translate("cAddNewComponent.hero", false, null)} 4` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-8", name: `${translate("cAddNewComponent.hero", false, null)} 8` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "hero-5", name: `${translate("cAddNewComponent.hero", false, null)} 5` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Cards */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-cards">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-cards"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-cards"
          >
            {translate("cAddNewComponent.cards", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-cards"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-cards"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-19", name: `${translate("cAddNewComponent.cards", false, null)} 19` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-20", name: `${translate("cAddNewComponent.cards", false, null)} 20` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-1", name: `${translate("cAddNewComponent.cards", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-2", name: `${translate("cAddNewComponent.cards", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-3", name: `${translate("cAddNewComponent.cards", false, null)} 3` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-4", name: `${translate("cAddNewComponent.cards", false, null)} 4` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-23", name: `${translate("cAddNewComponent.cards", false, null)} 23` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-24", name: `${translate("cAddNewComponent.cards", false, null)} 24` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-25", name: `${translate("cAddNewComponent.cards", false, null)} 25` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-10", name: `${translate("cAddNewComponent.cards", false, null)} 10` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-13", name: `${translate("cAddNewComponent.cards", false, null)} 13` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-18", name: `${translate("cAddNewComponent.cards", false, null)} 18` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-14", name: `${translate("cAddNewComponent.cards", false, null)} 14` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-15", name: `${translate("cAddNewComponent.cards", false, null)} 15` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-9", name: `${translate("cAddNewComponent.cards", false, null)} 9` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-16", name: `${translate("cAddNewComponent.cards", false, null)} 16` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-5", name: `${translate("cAddNewComponent.cards", false, null)} 5` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-17", name: `${translate("cAddNewComponent.cards", false, null)} 17` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-6", name: `${translate("cAddNewComponent.cards", false, null)} 6` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-7", name: `${translate("cAddNewComponent.cards", false, null)} 7` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-8", name: `${translate("cAddNewComponent.cards", false, null)} 8` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-21", name: `${translate("cAddNewComponent.cards", false, null)} 21` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-22", name: `${translate("cAddNewComponent.cards", false, null)} 22` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "card-11", name: `${translate("cAddNewComponent.cards", false, null)} 11` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "card-12", name: `${translate("cAddNewComponent.cards", false, null)} 12` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Latest news */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-latestnews">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-latestnews"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-latestnews"
          >
            {translate("cAddNewComponent.latestNews", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-latestnews"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-latestnews"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "latestnews-1", name: `${translate("cAddNewComponent.latestNews", false, null)} 1` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "card-5", name: `${translate("cAddNewComponent.latestNews", false, null)} 2` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Clients */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-clients">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-clients"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-clients"
          >
            {translate("cAddNewComponent.clients", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-clients"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-clients"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "clients-1", name: `${translate("cAddNewComponent.clients", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "clients-2", name: `${translate("cAddNewComponent.clients", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "clients-3", name: `${translate("cAddNewComponent.clients", false, null)} 3` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "clients-4", name: `${translate("cAddNewComponent.clients", false, null)} 4` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Contact us */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-contactus">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-contactus"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-contactus"
          >
            {translate("cAddNewComponent.contactUs", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-contactus"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-contactus"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "contactus-1", name: `${translate("cAddNewComponent.contactUs", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "contactus-2", name: `${translate("cAddNewComponent.contactUs", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "contactus-3", name: `${translate("cAddNewComponent.contactUs", false, null)} 3` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "contactus-4", name: `${translate("cAddNewComponent.contactUs", false, null)} 4` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "contactus-5", name: `${translate("cAddNewComponent.contactUs", false, null)} 5` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "contactus-6", name: `${translate("cAddNewComponent.contactUs", false, null)} 6` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "contactus-7", name: `${translate("cAddNewComponent.contactUs", false, null)} 7` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "contactus-8", name: `${translate("cAddNewComponent.contactUs", false, null)} 8` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "contactus-9", name: `${translate("cAddNewComponent.contactUs", false, null)} 9` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-footer">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-footer"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-footer"
          >
            {translate("cAddNewComponent.footer", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-footer"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-footer"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-1", name: `${translate("cAddNewComponent.footer", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-2", name: `${translate("cAddNewComponent.footer", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-3", name: `${translate("cAddNewComponent.footer", false, null)} 3` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-4", name: `${translate("cAddNewComponent.footer", false, null)} 4` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-5", name: `${translate("cAddNewComponent.footer", false, null)} 5` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "footer-6", name: `${translate("cAddNewComponent.footer", false, null)} 6` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Call to action */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-cta">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-cta"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-cta"
          >
            {translate("cAddNewComponent.callToAction", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-cta"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-cta"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cta-1", name: `${translate("cAddNewComponent.callToAction", false, null)} 1` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "cta-2", name: `${translate("cAddNewComponent.callToAction", false, null)} 2` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Parallax */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-parallax">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-parallax"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-parallax"
          >
            {translate("cAddNewComponent.parallax", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-parallax"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-parallax"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "parallax-1", name: `${translate("cAddNewComponent.parallax", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "parallax-4", name: `${translate("cAddNewComponent.parallax", false, null)} 4` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "parallax-2", name: `${translate("cAddNewComponent.parallax", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "parallax-3", name: `${translate("cAddNewComponent.parallax", false, null)} 3` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "parallax-5", name: `${translate("cAddNewComponent.parallax", false, null)} 5` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Image gallery */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-imggallery">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-imggallery"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-imggallery"
          >
            {translate("cAddNewComponent.imgGallery", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-imggallery"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-imggallery"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "imggallery-1", name: `${translate("cAddNewComponent.imgGallery", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "imggallery-2", name: `${translate("cAddNewComponent.imgGallery", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "imggallery-3", name: `${translate("cAddNewComponent.imgGallery", false, null)} 3` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "imggallery-4", name: `${translate("cAddNewComponent.imgGallery", false, null)} 4` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "imggallery-5", name: `${translate("cAddNewComponent.imgGallery", false, null)} 5` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "imggallery-6", name: `${translate("cAddNewComponent.imgGallery", false, null)} 6` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "imggallery-7", name: `${translate("cAddNewComponent.imgGallery", false, null)} 7` }} />
            </div>
          </div>
        </div>
      </div>
      {/* ${translate("cAddNewComponent.pricetables", false, null)} */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-pricetables">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-pricetables"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-pricetables"
          >
            {translate("cAddNewComponent.pricetables", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-pricetables"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-pricetables"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "pricetables-1", name: `${translate("cAddNewComponent.pricetables", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "pricetables-2", name: `${translate("cAddNewComponent.pricetables", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "pricetables-3", name: `${translate("cAddNewComponent.pricetables", false, null)} 3` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "pricetables-4", name: `${translate("cAddNewComponent.pricetables", false, null)} 4` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "pricetables-5", name: `${translate("cAddNewComponent.pricetables", false, null)} 5` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Process */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-process">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-process"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-process"
          >
            {translate("cAddNewComponent.process", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-process"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-process"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "process-1", name: `${translate("cAddNewComponent.process", false, null)} 1` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "process-2", name: `${translate("cAddNewComponent.process", false, null)} 2` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Testimonials */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-quotes">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-quotes"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-quotes"
          >
            {translate("cAddNewComponent.testimonials", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-quotes"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-quotes"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "quotes-1", name: `${translate("cAddNewComponent.testimonials", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "quotes-2", name: `${translate("cAddNewComponent.testimonials", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "quotes-3", name: `${translate("cAddNewComponent.testimonials", false, null)} 3` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "quotes-4", name: `${translate("cAddNewComponent.testimonials", false, null)} 4` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "quotes-5", name: `${translate("cAddNewComponent.testimonials", false, null)} 5` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "quotes-6", name: `${translate("cAddNewComponent.testimonials", false, null)} 6` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "quotes-7", name: `${translate("cAddNewComponent.testimonials", false, null)} 7` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "quotes-8", name: `${translate("cAddNewComponent.testimonials", false, null)} 8` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "quotes-9", name: `${translate("cAddNewComponent.testimonials", false, null)} 9` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "quotes-10", name: `${translate("cAddNewComponent.testimonials", false, null)} 10` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "quotes-11", name: `${translate("cAddNewComponent.testimonials", false, null)} 11` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "quotes-12", name: `${translate("cAddNewComponent.testimonials", false, null)} 12` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "quotes-13", name: `${translate("cAddNewComponent.testimonials", false, null)} 13` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Statistics */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-statistics">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-statistics"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-statistics"
          >
            {translate("cAddNewComponent.statistics", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-statistics"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-statistics"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "statistics-1", name: `${translate("cAddNewComponent.statistics", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "statistics-2", name: `${translate("cAddNewComponent.statistics", false, null)} 2` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "statistics-3", name: `${translate("cAddNewComponent.statistics", false, null)} 3` }} />
            </div>
          </div>
        </div>
      </div>
      {/* CVs */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-cv">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-cv"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-cv"
          >
            {translate("cAddNewComponent.resumes", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-cv"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-cv"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cv-1", name: `${translate("cAddNewComponent.resumes", false, null)} 1` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "cv-2", name: `${translate("cAddNewComponent.resumes", false, null)} 2` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Restaurant menus */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-foodmenu">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-foodmenu"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-foodmenu"
          >
            {translate("cAddNewComponent.restaurantMenus", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-foodmenu"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-foodmenu"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "foodmenu-1", name: `${translate("cAddNewComponent.restaurantMenus", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "foodmenu-2", name: `${translate("cAddNewComponent.restaurantMenus", false, null)} 2` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "foodmenu-3", name: `${translate("cAddNewComponent.restaurantMenus", false, null)} 3` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Forms */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-forms">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-forms"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-forms"
          >
            {translate("cAddNewComponent.forms", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-forms"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-forms"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "forms-1", name: `${translate("cAddNewComponent.forms", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "forms-2", name: `${translate("cAddNewComponent.forms", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "forms-3", name: `${translate("cAddNewComponent.forms", false, null)} 3` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "forms-4", name: `${translate("cAddNewComponent.forms", false, null)} 4` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "forms-5", name: `${translate("cAddNewComponent.forms", false, null)} 5` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "forms-6", name: `${translate("cAddNewComponent.forms", false, null)} 6` }} />
            </div>
            {/* <div className="p-2">
              <PreviewTemplate component={{ id: "forms-7", name: `${translate("cAddNewComponent.forms", false, null)} 7` }} />
            </div> */}
          </div>
        </div>
      </div>
      {/* Others */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-others">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-others"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-others"
          >
            {translate("cAddNewComponent.others", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-others"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-others"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "other-1", name: `${translate("cAddNewComponent.compName_backToTop", false, null)}` }} />
            </div>
            {/* <div className="p-2">
              <PreviewTemplate component={{ id: "cv-2", name: `CVs / resumes 2` }} />
            </div> */}
          </div>
        </div>
      </div>
      {/* ${translate("cAddNewComponent.sectionDividers", false, null)} */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-sectiondividers">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-sectiondividers"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-sectiondividers"
          >
            {translate("cAddNewComponent.sectionDividers", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-sectiondividers"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-sectiondividers"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-1", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-2", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-3", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 3` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-4", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 4` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-5", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 5` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-6", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 6` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-7", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 7` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-8", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 8` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-9", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 9` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-10", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 10` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-11", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 11` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-12", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 12` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-13", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 13` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-14", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 14` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-15", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 15` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-16", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 16` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-17", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 17` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-18", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 18` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-19", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 19` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-20", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 20` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-21", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 21` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-22", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 22` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-23", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 23` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-24", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 24` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-25", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 25` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-26", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 26` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-27", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 27` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-28", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 28` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-29", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 29` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-30", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 30` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-31", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 31` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-32", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 32` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-33", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 33` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-34", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 34` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-35", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 35` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-36", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 36` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-37", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 37` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-38", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 38` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-39", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 39` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-40", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 40` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-41", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 41` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-42", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 42` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-43", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 43` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-44", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 44` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-45", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 45` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-46", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 46` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-47", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 47` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-48", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 48` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-49", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 49` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-50", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 50` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-51", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 51` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-52", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 52` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-53", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 53` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-54", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 54` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-55", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 55` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-56", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 56` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-57", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 57` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-58", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 58` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-59", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 59` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-60", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 60` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-61", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 61` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-62", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 62` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-63", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 63` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-64", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 64` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-65", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 65` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-66", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 66` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-67", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 67` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-68", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 68` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-69", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 69` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-70", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 70` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-71", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 71` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-72", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 72` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-73", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 73` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-74", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 74` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-75", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 75` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-76", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 76` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-77", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 77` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-78", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 78` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-79", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 79` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-80", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 80` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-81", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 81` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-82", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 82` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-83", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 83` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-84", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 84` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-85", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 85` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-86", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 86` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-87", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 87` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-88", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 88` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-89", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 89` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "sectiondividers-90", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 90` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "sectiondividers-91", name: `${translate("cAddNewComponent.sectionDividers", false, null)} 91` }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewComponent;
