export const template24 = {
  id: "65ff5214136efcfc65c3e6e1",
  name: { en: "Virtual assistant", nl: "Virtuele assistent" },
  desc: { en: "Website for an virtual assistant", nl: "Website voor een virtuele assistent" },
  categories: ["services", "coaching"],
  sbPages: [
    {
      pageId: "ug5ciDAQn0",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "xLBApE",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-1-component-xLBApE"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "RrnYLr",
              type: "",
              htmlTagName: "div",
              classes: ["hero-1-wrapper-xLBApE"],
              styles: [],
              attributes: [{ property: "data-herowrapper", value: "true" }],
              content: "",
              children: [
                {
                  childId: "mHxuGN",
                  type: "",
                  htmlTagName: "img",
                  classes: ["hero-1-img-xLBApE"],
                  styles: [],
                  attributes: [
                    { property: "alt", value: "Image" },
                    { property: "src", value: "https://cdn.satonda.com/eb/assets/work-17.jpg" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "M7254i",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-1-content-xLBApE"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "hero1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "WuyiC9",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-1-title-xLBApE", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Your Virtual Assistant",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "vYqI8W",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-1-text-xLBApE", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "zWRP8m",
                      type: "",
                      htmlTagName: "a",
                      classes: ["hero-1-button-xLBApE"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "What I can do for you",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "kBQ0YQ",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "hero-3-component-kBQ0YQ"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-up" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "U41esI",
              type: "",
              htmlTagName: "div",
              classes: ["row", "align-items-top", "justify-content-center"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "ekMGFH",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "order-md-2", "p-3", "hero-3-contentwrapper-kBQ0YQ"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "wrm1Ki",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-3-title-kBQ0YQ", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "I'm Claire",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "fGXcnH",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["hero-3-subtitle-kBQ0YQ", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "A bit about me",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "NNBV9q",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-3-text-kBQ0YQ", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem omnis, eligendi quae eum facilis error!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "fpTYwX",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["row", "justify-content-center", "align-items-stretch", "mt-4", "hero-3-row"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "fxzRAY",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-4", "col-md-4", "col-lg-4", "p-2", "hero-3-col"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "bYYr5Q",
                              type: "",
                              htmlTagName: "div",
                              classes: ["hero-3-square-btn-kBQ0YQ"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "oCe8o4",
                                  type: "",
                                  htmlTagName: "a",
                                  classes: ["hero-3-button1-kBQ0YQ"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "button" },
                                    { property: "href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-uniqueclassname", value: "true" },
                                    { property: "data-href", value: "/index.html#services" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Services",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "wuMAWc",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-4", "col-md-4", "col-lg-4", "p-2", "hero-3-col"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "bJ0XPQ",
                              type: "",
                              htmlTagName: "div",
                              classes: ["hero-3-square-btn-kBQ0YQ"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "sBkgdO",
                                  type: "",
                                  htmlTagName: "a",
                                  classes: ["hero-3-button2-kBQ0YQ"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "button" },
                                    { property: "href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-uniqueclassname", value: "true" },
                                    { property: "data-href", value: "/index.html#testimonials" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Reviews",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "QoOast",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-4", "col-md-4", "col-lg-4", "p-2", "hero-3-col"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "YHN28M",
                              type: "",
                              htmlTagName: "div",
                              classes: ["hero-3-square-btn-kBQ0YQ"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "JKWMgm",
                                  type: "",
                                  htmlTagName: "a",
                                  classes: ["hero-3-button3-kBQ0YQ"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "button" },
                                    { property: "href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-uniqueclassname", value: "true" },
                                    { property: "data-href", value: "/index.html#contact" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Contact",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "BUI7aS",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-5", "col-lg-4", "order-md-1", "p-3", "hero-3-img-kBQ0YQwrapper-kBQ0YQ"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "hAjgXj",
                      type: "",
                      htmlTagName: "img",
                      classes: ["hero-3-img-kBQ0YQ"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic2.png" },
                        { property: "alt", value: "Image" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "p5WtvK",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-p5WtvK"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "services" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "HAxlR1",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-p5WtvK", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "How I make your life easier",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "ulVzxR",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-25-component-ulVzxR"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "gvXpa2",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-25-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "VY0hsF",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-25-col-ulVzxR"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade-down" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "WVcJ4O",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-25-wrapper-ulVzxR"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "UBAzZk",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-25-iconwrapper-ulVzxR"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "z1zSKG",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-regular", "fa-address-card"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "F7P7kK",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["card-25-title-ulVzxR"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Administrative tasks",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "VYMKOk",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "nQRLNF",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-25-text-ulVzxR", "w-md-90", "w-lg-80"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "eDWWfB",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-25-col-ulVzxR"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade-down" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "pH9SWp",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-25-wrapper-ulVzxR"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "pVnYcG",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-25-iconwrapper-ulVzxR"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "OFK0NS",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-regular", "fa-calendar-days"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "DQG3cx",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["card-25-title-ulVzxR"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Calendar management",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "R2Hle1",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "eAJQJK",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-25-text-ulVzxR", "w-md-90", "w-lg-80"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "r4wc82",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-25-col-ulVzxR"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade-down" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "gmKThS",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-25-wrapper-ulVzxR"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "IIjKZg",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-25-iconwrapper-ulVzxR"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "xeKMxN",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-magnifying-glass-chart"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "juP4aQ",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["card-25-title-ulVzxR"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Data analysis",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "tWBuhZ",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "PZgVJ2",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-25-text-ulVzxR", "w-md-90", "w-lg-80"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "JUW08i",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-25-component-JUW08i"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "axPrLm",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-25-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "ULHl6V",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-25-col-JUW08i"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade-up" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "XC6s9K",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-25-wrapper-JUW08i"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "efmF9r",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-25-iconwrapper-JUW08i"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "W80qzx",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-weixin"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "CJXmC8",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["card-25-title-JUW08i"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Customer contact",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "gVT7CH",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "JRLQic",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-25-text-JUW08i", "w-md-90", "w-lg-80"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "OXooH7",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-25-col-JUW08i"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade-up" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "xuati5",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-25-wrapper-JUW08i"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "fJHsGX",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-25-iconwrapper-JUW08i"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "HexHjH",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-plane-departure"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Es5fuZ",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["card-25-title-JUW08i"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Trip management",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "n9VbR2",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ctXQMX",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-25-text-JUW08i", "w-md-90", "w-lg-80"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "MKhaPk",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-25-col-JUW08i"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade-up" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "yav8DT",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-25-wrapper-JUW08i"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "zxR5hC",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-25-iconwrapper-JUW08i"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "Mg9xD9",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-dollar-sign"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "T1WLpS",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["card-25-title-JUW08i"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Expense management",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "T2arrl",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Wx6GLg",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-25-text-JUW08i", "w-md-90", "w-lg-80"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "JaS5Fq",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "section-divider-JaS5Fq"],
          styles: [],
          attributes: [{ property: "data-name", value: "section_divider" }],
          content: "",
          children: [
            {
              childId: "HdOqOZ",
              type: "",
              htmlTagName: "svg",
              classes: [],
              styles: [
                { property: "transform", value: "scaleY(-1)" },
                { property: "background", value: "rgba(255, 255, 255, 1)" },
              ],
              attributes: [
                { property: "xmlns", value: "http://www.w3.org/2000/svg" },
                { property: "height", value: "150px" },
                { property: "width", value: "100%" },
                { property: "viewBox", value: "0 0 1000 355" },
                { property: "preserveAspectRatio", value: "none" },
              ],
              content: "",
              children: [
                {
                  childId: "iTGmVs",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    {
                      property: "d",
                      value: "M379.95 298.18l28.47 9.92L1000 118.75V0h-.55l-619.5 298.18zM492.04 337.25L1000 252.63V118.75L408.42 308.1l83.62 29.15z",
                    },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "lbrtBu",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M492.04 337.25L1000 252.63V118.75L408.42 308.1l83.62 29.15z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "P0sAKZ",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "d", value: "M530.01 350.49l20.22 4.51H1000V252.63l-507.96 84.62 37.97 13.24z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "Iwbov9",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M530.01 350.49l20.22 4.51H1000V252.63l-507.96 84.62 37.97 13.24z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "V6Ifzc",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M530.01 350.49l20.22 4.51H1000V252.63l-507.96 84.62 37.97 13.24z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "ckJMFd",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "d", value: "M542.94 355h7.29l-20.22-4.51 12.93 4.51z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "oX37lQ",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M542.94 355h7.29l-20.22-4.51 12.93 4.51z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "cnJxQs",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.3" },
                    { property: "d", value: "M542.94 355h7.29l-20.22-4.51 12.93 4.51z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "bVobTh",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M542.94 355h7.29l-20.22-4.51 12.93 4.51z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "eJzJeW",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.3" },
                    { property: "d", value: "M379.95 298.18L0 165.72v66.59l353.18 78.75 26.77-12.88z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "g0Gw2t",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.3" },
                    { property: "d", value: "M353.18 311.06L0 232.31v71.86l288.42 38.06 64.76-31.17z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "eiRDBW",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.3" },
                    { property: "d", value: "M353.18 311.06L0 232.31v71.86l288.42 38.06 64.76-31.17z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "wSXjhp",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M380.28 317.11l28.14-9.01-28.47-9.92-26.77 12.88 27.1 6.05z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "pWSpTk",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.3" },
                    { property: "d", value: "M380.28 317.11l28.14-9.01-28.47-9.92-26.77 12.88 27.1 6.05z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "nRwpYx",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M479.79 339.29l12.25-2.04-83.62-29.15-28.14 9.01 99.51 22.18z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "l9u9FF",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M479.79 339.29l12.25-2.04-83.62-29.15-28.14 9.01 99.51 22.18z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "U5Mkbw",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.3" },
                    { property: "d", value: "M479.79 339.29l12.25-2.04-83.62-29.15-28.14 9.01 99.51 22.18z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "YZKNco",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "d", value: "M530.01 350.49l-37.97-13.24-12.25 2.04 50.22 11.2z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "kOtpkz",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M530.01 350.49l-37.97-13.24-12.25 2.04 50.22 11.2z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "EtIGFy",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M530.01 350.49l-37.97-13.24-12.25 2.04 50.22 11.2z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "GnnRI3",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.3" },
                    {
                      property: "d",
                      value: "M530.01 350.49l-37.97-13.24-12.25 2.04 50.22 11.2zM288.42 342.23l9.46 1.25 82.4-26.37-27.1-6.05-64.76 31.17z",
                    },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "g6XrAq",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M288.42 342.23l9.46 1.25 82.4-26.37-27.1-6.05-64.76 31.17z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "lRoLh6",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.3" },
                    { property: "d", value: "M288.42 342.23l9.46 1.25 82.4-26.37-27.1-6.05-64.76 31.17z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "pVWQ3O",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M380.28 317.11l-82.4 26.37 87.3 11.52h.34l94.27-15.71-99.51-22.18z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "wMiih1",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.3" },
                    { property: "d", value: "M380.28 317.11l-82.4 26.37 87.3 11.52h.34l94.27-15.71-99.51-22.18z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "xyV1ag",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M380.28 317.11l-82.4 26.37 87.3 11.52h.34l94.27-15.71-99.51-22.18z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "CqpJww",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.3" },
                    { property: "d", value: "M380.28 317.11l-82.4 26.37 87.3 11.52h.34l94.27-15.71-99.51-22.18z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "ivYr9Q",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "d", value: "M479.79 339.29L385.52 355h157.42l-12.93-4.51-50.22-11.2z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "TtavBd",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M479.79 339.29L385.52 355h157.42l-12.93-4.51-50.22-11.2z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "qIFApe",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.3" },
                    { property: "d", value: "M479.79 339.29L385.52 355h157.42l-12.93-4.51-50.22-11.2z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "Bbrn6H",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.5" },
                    { property: "d", value: "M479.79 339.29L385.52 355h157.42l-12.93-4.51-50.22-11.2z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "n4BwvH",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "opacity", value: "0.3" },
                    { property: "d", value: "M479.79 339.29L385.52 355h157.42l-12.93-4.51-50.22-11.2z" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "NLWsHT",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(249, 246, 241, 1)" },
                    { property: "d", value: "M288.42 342.23L0 304.17V355h385.18l-87.3-11.52-9.46-1.25z" },
                  ],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "oQe0KL",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "quote-7-component-oQe0KL"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "testimonials" },
          ],
          content: "",
          children: [
            {
              childId: "LzplIe",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "quote-7-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "rkBKND",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-7-col-oQe0KL"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-up" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "Xkp5yR",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-7-wrappertitle-oQe0KL"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "quote7" },
                        { property: "data-editable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "QOO8Q3",
                          type: "",
                          htmlTagName: "h2",
                          classes: ["quote-7-title-oQe0KL", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Testimonials",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "yZYgsE",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-7-col-oQe0KL"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-up" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "zqQKRq",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-7-wrapperquote-oQe0KL"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "quote7" },
                        { property: "data-editable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "n054fX",
                          type: "",
                          htmlTagName: "p",
                          classes: ["quote-7-quotetext-oQe0KL", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "IMMSHv",
                          type: "",
                          htmlTagName: "h5",
                          classes: ["quote-7-by-oQe0KL"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Someone said this",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "uzlBzs",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-7-col-oQe0KL"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-up" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "qAEr4J",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-7-wrapperquote-oQe0KL"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "quote7" },
                        { property: "data-editable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "K8wzpM",
                          type: "",
                          htmlTagName: "p",
                          classes: ["quote-7-quotetext-oQe0KL", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "FedevZ",
                          type: "",
                          htmlTagName: "h5",
                          classes: ["quote-7-by-oQe0KL"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Someone said this",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "B15xsL",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-7-col-oQe0KL"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-up" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "E1ZZaY",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-7-wrapperquote-oQe0KL"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "quote7" },
                        { property: "data-editable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Cqm0MQ",
                          type: "",
                          htmlTagName: "p",
                          classes: ["quote-7-quotetext-oQe0KL", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "fzQmpL",
                          type: "",
                          htmlTagName: "h5",
                          classes: ["quote-7-by-oQe0KL"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Someone said this",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "eo9qva",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-eo9qva"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "contact" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "riKMMj",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-eo9qva", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Reach out!", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "EIVGdN",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-1-component-EIVGdN"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "XBwq8d",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-1-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "kiKlKJ",
                  type: "block",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "contactus-1-col-EIVGdN"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "block" }],
                  content: "",
                  children: [
                    {
                      childId: "dngwU0",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-1-contact-info-block-EIVGdN"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "ogth0b",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-icon-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "QhNytm",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-location-dot"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Zonlnv",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-content-EIVGdN"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "qdug74",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-EIVGdN-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Street 1337",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "FOkIri",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-EIVGdN-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Postal code, City",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "rQMjz6",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-EIVGdN-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Country",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "ZZ7FH3",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-1-contact-info-block-EIVGdN"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "YwwVzy",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-icon-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "MdRA1K",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-envelope"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "XUvWDr",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-content-EIVGdN"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "l2JRu9",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-EIVGdN-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "email@domain.com",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "ZxfAhz",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-1-contact-info-block-EIVGdN"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "isy6Wh",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-icon-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "GHRL9Q",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-phone"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "XpiGFh",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-content-EIVGdN"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "VD9BAk",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-EIVGdN-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "020 13 37 000",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "C4bdPg",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["d-flex", "flex-wrap"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "S2tVpw",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "dSbXpy",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-envelope"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "iDOhgJ",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "FHXcFM",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-linkedin"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "JMb82h",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "YmZ3Ku",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-EIVGdN"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "pzlSmr",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "r1zvdA",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-1-inputfield-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "name", value: "name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                            { property: "type", value: "text" },
                            { property: "placeholder", value: "Your name" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "XBoBXi",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-EIVGdN"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "DYY7zJ",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "bjdI9B",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-1-inputfield-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "qAL5Gq",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-EIVGdN"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "M6tbzG",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Ybw1J5",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-1-inputfield-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "W6H2IL",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-EIVGdN"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "upKUJy",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "hGTBUz",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-1-inputfield-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                            { property: "placeholder", value: "Message" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "DRD0N2",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-1-button-EIVGdNwrapper-EIVGdN"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "dQD9f0",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-1-button-EIVGdN"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "65ff3c02ce19f27d76705206",
    },
  ],
  sbCustomCss: [
    {
      componentId: "xLBApE",
      classes: [
        {
          className: "section.hero-1-component-xLBApE",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-1-component-xLBApE", pseudo: "hover", rules: [] },
        {
          className: "hero-1-component-xLBApE",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-1-component-xLBApE", pseudo: "hover", rules: [] },
        {
          className: "hero-1-wrapper-xLBApE",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "70vh" },
          ],
        },
        {
          className: "hero-1-img-xLBApE",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-1-content-xLBApE",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "hero-1-title-xLBApE",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-1-title-xLBApE", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-1-text-xLBApE",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-1-text-xLBApE", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-1-button-xLBApE",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "1rem 3rem 1rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "3rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-1-button-xLBApE",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "kBQ0YQ",
      classes: [
        {
          className: "section.hero-3-component-kBQ0YQ",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.hero-3-component-kBQ0YQ", pseudo: "hover", rules: [] },
        { className: "hero-3-wrapper-kBQ0YQ", pseudo: "", rules: [] },
        {
          className: "hero-3-img-kBQ0YQwrapper-kBQ0YQ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "flex-start" },
          ],
        },
        {
          className: "hero-3-contentwrapper-kBQ0YQ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "hero-3-subtitle-kBQ0YQ",
          pseudo: "",
          rules: [
            { property: "margin", value: "1.5rem 0 1rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "hero-3-subtitle-kBQ0YQ", pseudo: "hover", rules: [] },
        {
          className: "hero-3-text-kBQ0YQ",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "hero-3-text-kBQ0YQ", pseudo: "hover", rules: [] },
        {
          className: "hero-3-square-btn-kBQ0YQ",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "width", value: "100%" },
          ],
        },
        {
          className: "hero-3-square-btn-kBQ0YQ",
          pseudo: "after",
          rules: [
            { property: "content", value: '""' },
            { property: "display", value: "block" },
            { property: "padding-bottom", value: "100%" },
          ],
        },
        {
          className: "hero-3-title-kBQ0YQ",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(200, 174, 121, 1)" },
            { property: "font-size", value: "60px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1.5rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-3-title-kBQ0YQ", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "hero-3-button2-kBQ0YQ",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "height", value: "100%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(200, 174, 121, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(170, 137, 70, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-3-button2-kBQ0YQ",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(170, 137, 70, 1)" },
            { property: "border-color", value: "rgba(170, 137, 70, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-3-button3-kBQ0YQ",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "height", value: "100%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(200, 174, 121, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(20, 20, 20, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-3-button3-kBQ0YQ",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(170, 137, 70, 1)" },
            { property: "border-color", value: "rgba(20, 20, 20, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-3-button1-kBQ0YQ",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "height", value: "100%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(200, 174, 121, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(20, 20, 20, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-3-button1-kBQ0YQ",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(170, 137, 70, 1)" },
            { property: "border-color", value: "rgba(20, 20, 20, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-3-img-kBQ0YQ",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "10px" },
            { property: "border-color", value: "rgba(200, 174, 121, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "hero-3-img-kBQ0YQ",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(200, 174, 121, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "p5WtvK",
      classes: [
        {
          className: "section.basics-1-component-p5WtvK",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(249, 246, 241, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(249, 246, 241, 1)" },
          ],
        },
        { className: "section.basics-1-component-p5WtvK", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-p5WtvK",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-p5WtvK", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-p5WtvK",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(200, 174, 121, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-p5WtvK", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "ulVzxR",
      classes: [
        {
          className: "section.card-25-component-ulVzxR",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(249, 246, 241, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(249, 246, 241, 1)" },
          ],
        },
        { className: "section.card-25-component-ulVzxR", pseudo: "hover", rules: [] },
        {
          className: "card-25-col-ulVzxR",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "display", value: "flex" },
            { property: "border-style", value: "none solid none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
          ],
        },
        { className: "card-25-col-ulVzxR", pseudo: "last-child", rules: [{ property: "border", value: "none" }] },
        {
          className: "card-25-wrapper-ulVzxR",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0" },
            { property: "padding", value: "0 0 0 0" },
          ],
        },
        { className: "card-25-wrapper-ulVzxR", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)" }] },
        {
          className: "card-25-border-ulVzxR",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "height", value: "100%" },
            { property: "width", value: "10px" },
            { property: "background", value: "rgba(229, 42, 5, 1)" },
          ],
        },
        { className: "card-25-border-ulVzxR", pseudo: "hover", rules: [{ property: "background", value: "rgba(229, 42, 5, 1)" }] },
        {
          className: "card-25-text-ulVzxR",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-25-text-ulVzxR", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-25-iconwrapper-ulVzxR",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "color", value: "rgba(170, 137, 70, 1)" },
            { property: "font-size", value: "2.5rem" },
          ],
        },
        { className: "card-25-iconwrapper-ulVzxR", pseudo: "hover", rules: [] },
        {
          className: "card-25-title-ulVzxR",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(200, 174, 121, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-25-title-ulVzxR", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "JUW08i",
      classes: [
        {
          className: "section.card-25-component-JUW08i",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "6rem" },
            { property: "background", pseudo: "", value: "rgba(249, 246, 241, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(249, 246, 241, 1)" },
          ],
        },
        { className: "section.card-25-component-JUW08i", pseudo: "hover", rules: [] },
        {
          className: "card-25-col-JUW08i",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "display", value: "flex" },
            { property: "border-style", value: "none solid none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
          ],
        },
        { className: "card-25-col-JUW08i", pseudo: "last-child", rules: [{ property: "border", value: "none" }] },
        {
          className: "card-25-wrapper-JUW08i",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0" },
            { property: "padding", value: "0 0 0 0" },
          ],
        },
        { className: "card-25-wrapper-JUW08i", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)" }] },
        {
          className: "card-25-border-JUW08i",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "height", value: "100%" },
            { property: "width", value: "10px" },
            { property: "background", value: "rgba(229, 42, 5, 1)" },
          ],
        },
        { className: "card-25-border-JUW08i", pseudo: "hover", rules: [{ property: "background", value: "rgba(229, 42, 5, 1)" }] },
        {
          className: "card-25-text-JUW08i",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-25-text-JUW08i", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-25-title-JUW08i",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(200, 174, 121, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-25-title-JUW08i", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-25-iconwrapper-JUW08i",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "color", value: "rgba(170, 137, 70, 1)" },
            { property: "font-size", value: "2.5rem" },
          ],
        },
        { className: "card-25-iconwrapper-JUW08i", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "EjFyvg",
      classes: [
        {
          className: "section.basics-1-component-EjFyvg",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-EjFyvg", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-EjFyvg",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-EjFyvg", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-EjFyvg",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "basics-1-title-EjFyvg", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "oQe0KL",
      classes: [
        {
          className: "section.quote-7-component-oQe0KL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "6rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.quote-7-component-oQe0KL", pseudo: "hover", rules: [] },
        {
          className: "quote-7-col-oQe0KL",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
          ],
        },
        {
          className: "quote-7-wrappertitle-oQe0KL",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "background", value: "rgba(200, 174, 121, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(200, 174, 121, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "quote-7-wrappertitle-oQe0KL",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(200, 174, 121, 1)" },
            { property: "border-color", value: "rgba(200, 174, 121, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "quote-7-wrapperquote-oQe0KL",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(200, 174, 121, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "quote-7-wrapperquote-oQe0KL",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-color", value: "rgba(200, 174, 121, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "quote-7-title-oQe0KL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 1rem 1rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "quote-7-title-oQe0KL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "quote-7-by-oQe0KL",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(200, 174, 121, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 1rem 1rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "quote-7-by-oQe0KL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "quote-7-quotetext-oQe0KL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "flex-start" },
            { property: "color", value: "rgba(20, 20, 20, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 1rem 1rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "quote-7-quotetext-oQe0KL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "EIVGdN",
      classes: [
        {
          className: "section.contactus-1-component-EIVGdN",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.contactus-1-component-EIVGdN", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-col-EIVGdN",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "start" },
            { property: "justify-content", value: "center" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-1-contact-info-block-EIVGdN",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "margin", value: "0 0 1.5rem 0" },
          ],
        },
        {
          className: "contactus-1-contact-info-content-EIVGdN",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 1.5rem" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "contactus-1-contact-info-content-EIVGdN > *", pseudo: "", rules: [{ property: "margin", value: "0" }] },
        {
          className: "contactus-1-button-EIVGdNwrapper-EIVGdN",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-1-button-EIVGdNwrapper-EIVGdN", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-label-EIVGdN",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-1-label-EIVGdN", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-contact-info-icon-EIVGdN",
          pseudo: "",
          rules: [
            { property: "height", value: "25px" },
            { property: "width", value: "25px" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "center" },
            { property: "color", value: "rgba(170, 137, 70, 1)" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        { className: "contactus-1-contact-info-icon-EIVGdN", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-social-link-EIVGdN",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "height", value: "45px" },
            { property: "aspect-ratio", value: "1" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(200, 174, 121, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 1.5rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "font-size", value: "1rem" },
          ],
        },
        {
          className: "contactus-1-social-link-EIVGdN",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(170, 137, 70, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "contactus-1-social-link-EIVGdN", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "contactus-1-inputfield-EIVGdN",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1rem" },
            { property: "outline", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(224, 209, 179, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(224, 209, 179, 0.5)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease-in-out" },
          ],
        },
        {
          className: "contactus-1-inputfield-EIVGdN",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(200, 174, 121, 1)" },
            { property: "border-color", value: "rgba(224, 209, 179, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(200, 174, 121, 0.2)" },
          ],
        },
        {
          className: "contactus-1-inputfield-EIVGdN",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(224, 209, 179, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-1-inputfield-EIVGdN", pseudo: "hover", rules: [] },
        { className: "contactus-1-inputwrapper-EIVGdN", pseudo: "", rules: [{ property: "margin", value: "0rem 0rem 1rem 0rem" }] },
        {
          className: "contactus-1-button-EIVGdN",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(200, 174, 121, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(200, 174, 121, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "contactus-1-button-EIVGdN",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(170, 137, 70, 1)" },
            { property: "border-color", value: "rgba(200, 174, 121, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "eo9qva",
      classes: [
        {
          className: "section.basics-1-component-eo9qva",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "6rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-eo9qva", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-eo9qva",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-eo9qva", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-eo9qva",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(200, 174, 121, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-eo9qva", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "MHIsal",
      classes: [
        {
          className: "section-divider-MHIsal",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
            { property: "width", value: "100%" },
          ],
        },
      ],
    },
    {
      componentId: "JaS5Fq",
      classes: [
        {
          className: "section-divider-JaS5Fq",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
            { property: "width", value: "100%" },
          ],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Quicksand:300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Poppins:100,300,700&display=swap",
    fontHeadersName: "Quicksand",
    fontHeadersWeight: "400",
    fontBodyName: "Poppins",
    fontBodyWeight: "300",
    color1: "rgba(200, 174, 121, 1)",
    color2: "rgba(170, 137, 70, 1)",
    color3: "rgba(224, 209, 179, 1)",
    color4: "rgba(50, 149, 169, 1)",
    color5: "rgba(249, 246, 241, 1)",
    color6: "rgba(157, 214, 226, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
