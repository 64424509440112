export const basics6 = {
  id: "basics-6",
  name: "Basic building blocks - Heading line",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "basics-6-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-50"],
        styles: [],
        attributes: [
          { property: "data-editable", value: "true" },
          { property: "data-name", value: "headingline" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["basics-6-headingline"],
            styles: [],
            attributes: [{ property: "data-checkparent", value: "true" }],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.basics-6-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "1rem" },
          { property: "padding-bottom", value: "1rem" },
        ],
      },
      { className: "section.basics-6-component", pseudo: "hover", rules: [] },
      {
        className: "basics-6-component",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "justify-content", value: "center" },
        ],
      },
      {
        className: "basics-6-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "basics-6-headingline",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "width", value: "50px" },
          { property: "height", value: "3px" },
          { property: "border-radius", value: "0rem" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "basics-6-headingline",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
