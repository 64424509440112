import { getState } from "./stateFunctions";
import { getTargetElement, getIframeWindow } from "./domFunctions";

export const getSelectedComponentId = () => {
  try {
    let selectedElement = getState("sb", "selectedElement");
    return selectedElement.split("-")[0];
  } catch (error) {
    return "";
  }
};

export const getTargetObj = (splitSelectedElementId, target = null) => {
  try {
    if (target === null) {
      // Get state info
      let activePageId = getState("sb", "activePageId");
      // Get component being updated
      target = getState("sb", "sbPages")
        .filter((page) => page.pageId === activePageId)[0]
        .components.filter((component) => component.componentId === splitSelectedElementId[0])[0];
    }
    // Move down its children if needed
    splitSelectedElementId.shift();
    for (let i = 0; i < splitSelectedElementId.length; i++) {
      target = target.children.filter((child) => child.childId === splitSelectedElementId[i])[0];
    }
    return target;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const reduxGetSelectedElement = (splitSelectedElementId, selectedComponent) => {
  return getTargetObj(splitSelectedElementId, selectedComponent);
};

export const getTargetParent = (targetId) => {
  try {
    let selectedElement = getState("sb", "selectedElement");
    let splitSelectedElementId = selectedElement.split("-");
    let indexTargetId = splitSelectedElementId.indexOf(targetId);
    // This should give you all elementIds up to and including the target's parent
    splitSelectedElementId = splitSelectedElementId.slice(0, indexTargetId);
    return getTargetObj(splitSelectedElementId);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getParentWithAttr = (attrProp, attrVal = null) => {
  try {
    let result = null;
    let selectedElement = getState("sb", "selectedElement");
    let splitSelectedElementId = selectedElement.split("-");
    splitSelectedElementId = splitSelectedElementId.slice(0, splitSelectedElementId.length - 1);
    for (let i = splitSelectedElementId.length - 1; i >= 0; i--) {
      let target = getTargetObj(splitSelectedElementId.slice(0, i + 1));
      if (attrVal === null) {
        if (target.attributes.filter((attr) => attr.property === attrProp).length > 0) {
          result = target;
        }
      } else {
        if (target.attributes.filter((attr) => attr.property === attrProp && attr.value === attrVal).length > 0) {
          result = target;
        }
      }
    }
    return result;
  } catch (error) {
    return null;
  }
};

export const getCustomCss = (componentId) => {
  try {
    let customCss = getState("sb", "sbCustomCss");
    return customCss.filter((component) => component.componentId === componentId)[0].classes;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getFirstChildWithAttr = (target, attrProp, attrVal) => {
  try {
    if (target.attributes.filter((attr) => attr.property === attrProp && attr.value === attrVal).length > 0) {
      return target;
    }
    if (target.children.length > 0) {
      let found = [];
      target.children.forEach((child) => {
        found.push(getFirstChildWithAttr(child, attrProp, attrVal));
      });
      return found.filter((el) => el !== null && typeof el !== "undefined")[0];
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getFirstChildByClassname = (target, classNameToFind) => {
  try {
    if (target.classes.filter((className) => className === classNameToFind).length > 0) {
      return target;
    }
    if (target.children.length > 0) {
      let found = [];
      target.children.forEach((child) => {
        found.push(getFirstChildByClassname(child, classNameToFind));
      });
      return found.filter((el) => el !== null && typeof el !== "undefined")[0];
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getFirstChildByTagname = (target, tagName) => {
  try {
    if (target.htmlTagName === tagName) {
      return target;
    }
    if (target.children.length > 0) {
      let found = [];
      target.children.forEach((child) => {
        found.push(getFirstChildByTagname(child, tagName));
      });
      return found.filter((el) => el !== null && typeof el !== "undefined")[0];
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getAllChildrenByTagname = (target, tagName) => {
  try {
    if (target.htmlTagName === tagName) {
      return target;
    }
    if (target.children.length > 0) {
      let found = [];
      target.children.forEach((child) => {
        found.push(getAllChildrenByTagname(child, tagName));
      });
      // Take out null/undefined and flatten array
      return [].concat(...found.filter((el) => el !== null && typeof el !== "undefined"));
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getAllChildrenUniqueClassnames = (target, customCss) => {
  try {
    let found = [];
    if (target.attributes.filter((attr) => attr.property === "data-uniqueclassname" && attr.value === "true").length > 0) {
      found.push(getTargetCustomClassname(target.classes, customCss));
    }
    if (target.children.length > 0) {
      target.children.forEach((child) => {
        found.push(getAllChildrenUniqueClassnames(child, customCss));
      });
      // Take out null/undefined and flatten array
      return [].concat(...found.filter((el) => el !== null && typeof el !== "undefined"));
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getConcatElementId = (targetId) => {
  try {
    let selectedElement = getState("sb", "selectedElement");
    let componentId = selectedElement.split("-")[0];
    let component = getTargetElement(componentId);
    let foundId = [...component.innerHTML.matchAll(/data-id="(.+?)"/g)].filter((row) => {
      let splitStr = row[1].split("-");
      if (splitStr[splitStr.length - 1] === targetId) {
        return true;
      }
      return false;
    })[0][1];
    return foundId;
  } catch (error) {
    return "";
  }
};

export const getElementCssRules = (target) => {
  try {
    let componentId = "";
    if (target.type === "component") {
      componentId = target.componentId;
    } else {
      let iframeBodyHtml = getIframeWindow().document.body.innerHTML;
      let re = new RegExp(`data-id="(\\w+?)-${target.childId}"`);
      componentId = (iframeBodyHtml.match(re) || ["", ""])[1];
    }
    let customCss = getCustomCss(componentId);
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    return customCss.filter((row) => row.className === targetCustomClassname)[0].rules;
  } catch (error) {
    return [];
  }
};

export const getTargetCustomClassname = (targetClasses, customCss) => {
  try {
    let customClasses = customCss.map((custom) => custom.className);
    return targetClasses.filter((className) => customClasses.includes(className))[0];
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const getTargetCustomCssRules = (customCss, classname, pseudo = "") => {
  try {
    return {
      customClassRulesNormal: (customCss.filter((custom) => custom.className === classname && custom.pseudo === "")[0] || { rules: [] }).rules,
      customClassRulesHover: (customCss.filter((custom) => custom.className === classname && custom.pseudo === "hover")[0] || { rules: [] }).rules,
      customClassRulesPseudo:
        pseudo === "" ? [] : (customCss.filter((custom) => custom.className === classname && custom.pseudo === pseudo)[0] || { rules: [] }).rules,
    };
  } catch (error) {
    console.error(error);
    return {
      customClassRulesNormal: [],
      customClassRulesHover: [],
    };
  }
};

export const getComponentRelativeToCurrent = (currentComponentId, diff) => {
  try {
    let activePageId = getState("sb", "activePageId");
    let components = getState("sb", "sbPages").filter((page) => page.pageId === activePageId)[0].components;
    let posCurrentComponent = 0;
    components.forEach((component, i) => {
      if (component.componentId === currentComponentId) {
        posCurrentComponent = i;
      }
    });
    return components[posCurrentComponent + diff] || null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAnimateElements = (target) => {
  try {
    // target = sbtype=component
    // If component consists of a number of elements, check the animation on those elements
    // Only on the main elements, which are found for components where a type=block is its only child
    if (target.children.length === 1 && target.children[0].type === "block") {
      return target.children[0].children;
    }
    return [target];
  } catch (error) {
    console.error(error);
    return [target];
  }
};

export const getInputfieldLabel = () => {
  try {
    let inputFieldWrapper = getParentWithAttr("data-inputfieldwrapper", "true");
    return getFirstChildByTagname(inputFieldWrapper, "label");
    // return inputFieldWrapper.children.filter((child) => child.htmlTagName === "label")[0];
  } catch (error) {
    return null;
  }
};

export const getSelectPlaceholderOption = (inputField) => {
  try {
    return inputField.children
      .filter((child) => child.htmlTagName === "option")
      .filter((option) => option.attributes.filter((attr) => attr.property === "value" && attr.value === "").length > 0)[0];
  } catch (error) {
    return null;
  }
};
