export const cv1 = {
  id: "cv-1",
  name: "CV 1",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "resume-1-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "h1",
        classes: ["resume-1-title", "w-100", "w-md-100", "w-lg-100"],
        styles: [],
        attributes: [
          { property: "data-name", value: "h" },
          { property: "data-editable", value: "true" },
          { property: "data-texteditable", value: "true" },
        ],
        content: "",
        children: [{ childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Resume", children: [] }],
      },
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["resume-1-elementwrapper"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["resume-1-element"],
            styles: [],
            attributes: [
              { property: "data-name", value: "resume1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["row", "justify-content-center", "align-items-top"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-4", "col-lg-6", "p-3", "resume-1-col1"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["resume-1-elementtype", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "h" },
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Work experience",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "block",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-8", "col-lg-6", "p-3"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "block" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-top"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-4", "col-lg-4", "resume-1-col2"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["resume-1-entryperiod", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "p" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "2021 - present",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-8", "col-lg-8", "resume-1-col3"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "h5",
                                classes: ["resume-1-entrytitle", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "h" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Digital marketeer",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["resume-1-entrytext", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "p" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content:
                                      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga voluptatibus id deleniti quam sequi suscipit ipsum voluptas explicabo aliquid. Deserunt.",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-top"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-4", "col-lg-4", "resume-1-col2"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["resume-1-entryperiod", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "p" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "2019 - 2021",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-8", "col-lg-8", "resume-1-col3"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "h5",
                                classes: ["resume-1-entrytitle", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "h" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Content strategist",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["resume-1-entrytext", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "p" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content:
                                      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga voluptatibus id deleniti quam sequi suscipit ipsum voluptas explicabo aliquid. Deserunt.",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["resume-1-element"],
            styles: [],
            attributes: [
              { property: "data-name", value: "resume1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["row", "justify-content-center", "align-items-top"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-4", "col-lg-6", "p-3", "resume-1-col1"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["resume-1-elementtype", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "h" },
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Education",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "block",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-8", "col-lg-6", "p-3"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "block" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-top"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-4", "col-lg-4", "resume-1-col2"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["resume-1-entryperiod", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "p" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "2018 - 2019",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-8", "col-lg-8", "resume-1-col3"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "h5",
                                classes: ["resume-1-entrytitle", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "h" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "MSc Specialization - University of Life",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["resume-1-entrytext", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "p" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content:
                                      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga voluptatibus id deleniti quam sequi suscipit ipsum voluptas explicabo aliquid. Deserunt.",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-top"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-4", "col-lg-4", "resume-1-col2"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["resume-1-entryperiod", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "p" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "2015 - 2018",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-8", "col-lg-8", "resume-1-col3"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "h5",
                                classes: ["resume-1-entrytitle", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "h" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "BSc Life - University of Life",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["resume-1-entrytext", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "p" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content:
                                      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga voluptatibus id deleniti quam sequi suscipit ipsum voluptas explicabo aliquid. Deserunt.",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["resume-1-element"],
            styles: [],
            attributes: [
              { property: "data-name", value: "resume1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["row", "justify-content-center", "align-items-top"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-4", "col-lg-6", "p-3", "resume-1-col1"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["resume-1-elementtype", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "h" },
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Skills",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-8", "col-lg-6", "p-3"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-top"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-4", "col-lg-4", "resume-1-col2"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["resume-1-entryperiod", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "p" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-8", "col-lg-8", "resume-1-col3"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "block",
                                htmlTagName: "ul",
                                classes: ["resume-1-listwrapper"],
                                styles: [],
                                attributes: [{ property: "data-name", value: "block" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "element",
                                    htmlTagName: "li",
                                    classes: ["resume-1-listitem"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "li" },
                                      { property: "data-editable", value: "false" },
                                      { property: "data-texteditable", value: "false" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "p",
                                        classes: ["resume-1-listtext"],
                                        styles: [],
                                        attributes: [
                                          { property: "data-name", value: "p" },
                                          { property: "data-editable", value: "true" },
                                          { property: "data-texteditable", value: "true" },
                                        ],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "textNode",
                                            classes: [],
                                            styles: [],
                                            attributes: [],
                                            content: "Lorem ipsum dolor sit amet.",
                                            children: [],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "element",
                                    htmlTagName: "li",
                                    classes: ["resume-1-listitem"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "li" },
                                      { property: "data-editable", value: "false" },
                                      { property: "data-texteditable", value: "false" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "p",
                                        classes: ["resume-1-listtext"],
                                        styles: [],
                                        attributes: [
                                          { property: "data-name", value: "p" },
                                          { property: "data-editable", value: "true" },
                                          { property: "data-texteditable", value: "true" },
                                        ],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "textNode",
                                            classes: [],
                                            styles: [],
                                            attributes: [],
                                            content: "Lorem ipsum dolor sit amet.",
                                            children: [],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "element",
                                    htmlTagName: "li",
                                    classes: ["resume-1-listitem"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "li" },
                                      { property: "data-editable", value: "false" },
                                      { property: "data-texteditable", value: "false" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "p",
                                        classes: ["resume-1-listtext"],
                                        styles: [],
                                        attributes: [
                                          { property: "data-name", value: "p" },
                                          { property: "data-editable", value: "true" },
                                          { property: "data-texteditable", value: "true" },
                                        ],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "textNode",
                                            classes: [],
                                            styles: [],
                                            attributes: [],
                                            content: "Lorem ipsum dolor sit amet.",
                                            children: [],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "element",
                                    htmlTagName: "li",
                                    classes: ["resume-1-listitem"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "li" },
                                      { property: "data-editable", value: "false" },
                                      { property: "data-texteditable", value: "false" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "p",
                                        classes: ["resume-1-listtext"],
                                        styles: [],
                                        attributes: [
                                          { property: "data-name", value: "p" },
                                          { property: "data-editable", value: "true" },
                                          { property: "data-texteditable", value: "true" },
                                        ],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "textNode",
                                            classes: [],
                                            styles: [],
                                            attributes: [],
                                            content: "Lorem ipsum dolor sit amet.",
                                            children: [],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.resume-1-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.resume-1-component", pseudo: "hover", rules: [] },
      {
        className: "resume-1-component",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "resume-1-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-1-col1",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "resume-1-col1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-1-col2",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "resume-1-col2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-1-col3",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "resume-1-col3",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-1-title",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "resume-1-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-1-element",
        pseudo: "",
        rules: [
          { property: "border-style", value: "none none solid none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(200, 200, 200, 1)" },
          { property: "padding", value: "1rem 0 1rem 0" },
          { property: "margin", value: "1rem 0 1rem 0" },
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0rem" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
        ],
      },
      {
        className: "resume-1-element",
        pseudo: "hover",
        rules: [
          { property: "border-color", value: "rgba(200, 200, 200, 1)" },
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
        ],
      },
      { className: "resume-1-element", pseudo: "last-child", rules: [{ property: "border-style", value: "none none none none" }] },
      {
        className: "resume-1-elementtype",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "resume-1-elementtype",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-1-entryperiod",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      { className: "resume-1-entryperiod", pseudo: "hover", rules: [] },
      {
        className: "resume-1-entrytitle",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "resume-1-entrytitle",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-1-entrytext",
        pseudo: "",
        rules: [
          { property: "margin", value: "0.5rem 0 1.5rem 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      { className: "resume-1-entrytext", pseudo: "hover", rules: [] },
      { className: "resume-1-listwrapper", pseudo: "", rules: [{ property: "padding-left", value: "1rem" }] },
      { className: "resume-1-listwrapper", pseudo: "hover", rules: [] },
      {
        className: "resume-1-listtext",
        pseudo: "",
        rules: [
          { property: "margin", value: "0rem 0 0.5rem 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      { className: "resume-1-listtext", pseudo: "hover", rules: [] },
    ],
  },
};
