import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Frame from "react-frame-component";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

// import { formGetStartValue } from "../../../../lib/editFunctions";

import { compileCssVarsObj } from "../../../../lib/css/CssVars";
import { parseCss } from "../../../../lib/parse";
import { FixedCss } from "../../../../lib/css/FixedCss";
import { TextEditorCss } from "../../../../lib/css/TextEditorCss";
import {
  LABELS_OVERWRITES,
  getEditorConfig,
  importHtml,
  updateTextEditorChanges,
  wrapperClassName,
  editorClassName,
  toolbarClassName,
  customStyleMap,
} from "../../../../lib/textEditorFunctions";
import { getTargetElement, elementIsEditableTextElement } from "../../../../lib/domFunctions";
import { htmlToDraft } from "../../../../lib/draftHtmlConvert";

import { translate } from "../../../../translations/translations";

const TextEditor = ({ sb: { selectedElement, sbCustomCss, sbCssVars }, textEditorRef }) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [selectedTextElement, setSelectedTextElement] = useState(null);

  useEffect(() => {
    getSelectedTextElement();
  }, [selectedElement]);

  useEffect(() => {
    loadEditor();
  }, [selectedTextElement]);

  const getSelectedTextElement = () => {
    let targetElement = getTargetElement(selectedElement);
    setSelectedTextElement(elementIsEditableTextElement(targetElement) ? targetElement : null);
  };

  const loadEditor = () => {
    if (selectedTextElement) {
      let transformedHtml = importHtml(selectedTextElement, sbCustomCss);
      const contentBlock = htmlToDraft(transformedHtml);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setEditorState(() => EditorState.createWithContent(contentState));
    } else {
      setEditorState(() => EditorState.createEmpty());
    }
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const clickUpdateTextEditorChanges = () => {
    updateTextEditorChanges(editorState);
  };

  return (
    <div className="w-100 mt-3" id="textEditorWrapper" data-bgcolor="#fff">
      <Frame id="iframe_textEditor" head={<style>{compileCssVarsObj(sbCssVars) + FixedCss + TextEditorCss + parseCss(sbCustomCss)}</style>}>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          wrapperClassName={wrapperClassName}
          editorClassName={editorClassName}
          toolbarClassName={toolbarClassName}
          toolbar={getEditorConfig(sbCssVars)}
          localization={{ locale: "en", translations: LABELS_OVERWRITES["en"] }}
          customStyleMap={customStyleMap}
        />
      </Frame>
      {/* Not shown - only used to simulate a click and share editorState with textEditorFunctions */}
      <button className="btn btn-success d-none" onClick={clickUpdateTextEditorChanges} ref={textEditorRef}>
        {translate("cEditForms.editText", false, null)}
      </button>
    </div>
  );
};

TextEditor.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(TextEditor);
