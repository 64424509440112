import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";
import ColorPicker from "./formComponents/ColorPicker";
import ImgSizeInput from "./formComponents/ImgSizeInput";

import { formGetStartValue, EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { PADDING_VALUES } from "../../../../lib/editStyleVars";
import { translate } from "../../../../translations/translations";

const NavbarScrolledpast = ({ sb: { startStyles } }) => {
  const [bool, setBool] = useState(false);
  useEffect(() => {
    setBool(formGetStartValue(startStyles, "navbarScrolledpastBool"));
  }, [startStyles]);

  return (
    <>
      <div className={`form-check form-switch mt-3 ${bool ? "mb-n2" : "mb-3"}`}>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id={`${EDIT_FORM_ID_PREFIX}navbarScrolledpastBool`}
          value={bool}
          checked={bool}
          onChange={(e) => setBool(e.target.checked)}
        />
        <label className="form-check-label" htmlFor={`${EDIT_FORM_ID_PREFIX}navbarScrolledpastBool`}>
          {translate("cEditForms.navbar_changeScrollPast", false, null)}
        </label>
      </div>
      {bool && (
        <>
          <Slider
            label={translate("cEditForms.navbar_scrollPastSize", false, null)}
            id={"navbarScrolledpastSize"}
            min={0}
            max={10}
            step={1}
            startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "navbarScrolledpastSize"))}
            tooltipText={translate("cEditForms.navbar_ttScrollpastSize", false, null)}
          />
          <ColorPicker
            label={translate("cEditForms.bgColor", false, null)}
            id={"navbarScrolledpastBgColor"}
            startValue={formGetStartValue(startStyles, "navbarScrolledpastBgColor")}
            tooltipText={translate("cEditForms.navbar_ttScrollpastBgColor", false, null)}
          />
          <ImgSizeInput
            label={translate("cEditForms.navbar_logoHeight", false, null)}
            id={"navbarScrolledpastLogoMaxHeight"}
            startValue={formGetStartValue(startStyles, "navbarScrolledpastLogoMaxHeight")}
            tooltipText={translate("cEditForms.navbar_ttScrollpastHeight", false, null)}
          />
        </>
      )}
    </>
  );
};

NavbarScrolledpast.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(NavbarScrolledpast);
