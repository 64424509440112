export const other1 = {
  id: "other-1",
  name: "Back to top button",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "other-1-component"],
    styles: [],
    attributes: [
      { property: "data-name", value: "backtotop" },
      { property: "data-editable", value: "true" },
    ],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["backtotop", "other-1-button", "shown"],
        styles: [],
        attributes: [
          { property: "data-name", value: "backtotop_btn" },
          { property: "title", value: "Back to top" },
          { property: "data-checkparent", value: "true" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "span",
            classes: ["icon", "fa-solid", "fa-chevron-up"],
            styles: [],
            attributes: [{ property: "data-checkparent", value: "true" }],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.other-1-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.other-1-component", pseudo: "hover", rules: [] },
      {
        className: "other-1-button",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
          { property: "position", value: "fixed" },
          { property: "z-index", value: "99" },
          { property: "bottom", value: "1rem" },
          { property: "right", value: "1rem" },
          { property: "opacity", value: "0" },
          { property: "visibility", value: "hidden" },
          { property: "cursor", value: "pointer" },
          { property: "background", value: "rgba(229, 42, 5, 0.8)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "50%" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.2)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "height", value: "3rem" },
          { property: "width", value: "3rem" },
          { property: "font-size", value: "1rem" },
        ],
      },
      {
        className: "shown.other-1-button",
        pseudo: "",
        rules: [
          { property: "opacity", value: "1" },
          { property: "visibility", value: "visible" },
        ],
      },
      {
        className: "other-1-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.3)" },
        ],
      },
    ],
  },
};
