export const template11 = {
  id: "6398e7a6be8e2428650d8c8c",
  name: { en: "Personal coach", nl: "Persoonlijke coach" },
  desc: { en: "Single-page site for a personal coach", nl: "Een-pagina site voor een persoonlijke coach" },
  categories: ["services", "coaching"],
  sbPages: [
    {
      pageId: "a2fb863f-3b06-4932-98fe-08cf90ce3bed",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "FLXZjS",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-1-component-FLXZjS"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "lJtfa2",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-1-wrapper-FLXZjS"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "j3PCx6",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-1-content-FLXZjS"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "xtRW67",
                      type: "",
                      htmlTagName: "p",
                      classes: ["parallax-1-title-FLXZjS", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Let us help you unlock your",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "Me74cf",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["KcPjnznC8AVcZh92S7BYI50ROYCRCV", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "h" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "POTENTIAL",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "lez5uk",
                      type: "",
                      htmlTagName: "p",
                      classes: ["parallax-1-text-FLXZjS", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "R0WEVT",
                      type: "",
                      htmlTagName: "a",
                      classes: ["parallax-1-button-FLXZjS"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Read how I can help you",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "I0MNeo",
          type: "component",
          htmlTagName: "div",
          classes: ["basics-5-component-I0MNeo", "container-fluid"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "WYxtmz",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-5-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "JHgfMO",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-first", "basics-5-col-I0MNeo"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "lHxpOG",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-5-img-I0MNeo"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-1.jpg" },
                        { property: "alt", value: "Myself" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "SGK47l",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-5-col-I0MNeo"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "bbNlGC",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["basics-5-title-I0MNeo", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "About me",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "xWmxNf",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-5-text-I0MNeo", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Nam sollicitudin elit enim, et consectetur leo ornare at. Quisque ligula enim, euismod eu porta in, tincidunt eu lacus. Donec sit amet lorem tempor, consequat est vitae, sollicitudin urna. Pellentesque venenatis nulla a turpis viverra dapibus.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "U4hNh1",
                      type: "",
                      htmlTagName: "p",
                      classes: ["YLwa8MzMGgtZbptk51CFWcfDKHlwIh", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Nam sollicitudin elit enim, et consectetur leo ornare at. Quisque ligula enim, euismod eu porta in, tincidunt eu lacus. Donec sit amet lorem tempor, consequat est vitae, sollicitudin urna. Pellentesque venenatis nulla a turpis viverra dapibus.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ByKNDM",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-ByKNDM"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "pakMfW",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-ByKNDM", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "How I can help you",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "m3VDjj",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-18-component-m3VDjj"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "uBj4eH",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "n45I29",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-18-col-m3VDjj"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "BXhxrj",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-18-wrapper-m3VDjj"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "aWoBGV",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "CIKrQ3",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-18-iconwrapper-m3VDjj"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "sKmlOt",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-regular", "fa-user"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "eLT5rr",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "eRdrp4",
                                  type: "",
                                  htmlTagName: "h3",
                                  classes: ["card-18-title-m3VDjj", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Online coaching",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "nA0aOw",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-18-text-m3VDjj", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "vB0c7X",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-18-col-m3VDjj"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "WUEO2g",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-18-wrapper-m3VDjj"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "ivW8nm",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "K7dN9L",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-18-iconwrapper-m3VDjj"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "aBpJZg",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-regular", "fa-comment"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Gi46Z2",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "ySHY6N",
                                  type: "",
                                  htmlTagName: "h3",
                                  classes: ["card-18-title-m3VDjj", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Resume review",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "JeYZzd",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-18-text-m3VDjj", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "QeZdO4",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-18-col-m3VDjj"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "k8Uqvh",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-18-wrapper-m3VDjj"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "kMXvuc",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "deO8vx",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-18-iconwrapper-m3VDjj"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "JloZcl",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-regular", "fa-bell"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "KUxksc",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "VenyJg",
                                  type: "",
                                  htmlTagName: "h3",
                                  classes: ["card-18-title-m3VDjj", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Mock interviews",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "B0qtw2",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-18-text-m3VDjj", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "qfqlRZ",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-1-component-qfqlRZ"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Ijjen5",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-1-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "DjQ35L",
                  type: "block",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "contactus-1-col-qfqlRZ"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "block" }],
                  content: "",
                  children: [
                    {
                      childId: "O4Fh2H",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-1-contact-info-block-qfqlRZ"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "h06qCR",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-icon-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "SiJC8e",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-location-dot"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "mKz7lO",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-content-qfqlRZ"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "NMqqve",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-qfqlRZ-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Street 1337",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "berLuY",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-qfqlRZ-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Postal code, City",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "oOM9Wj",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-qfqlRZ-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Country",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "IPkl4K",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-1-contact-info-block-qfqlRZ"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "NM6hBs",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-icon-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "Hi2Jwm",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-envelope"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "wmIbYg",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-content-qfqlRZ"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "EoAdzy",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-qfqlRZ-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "email@domain.com",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "AR9sNg",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-1-contact-info-block-qfqlRZ"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "yUKBlJ",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-icon-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "ycwzrg",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-phone"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "fjhFnw",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-content-qfqlRZ"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "GaLTrP",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-qfqlRZ-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "020 13 37 000",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "EbpLdg",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["d-flex", "flex-wrap"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "dZiL6D",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "MKoUkY",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-envelope"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "wqWaB4",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "LhyuYa",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-twitter"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "YqX2se",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "y7PXfL",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-linkedin"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "WIcxk9",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "SkUTyp",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-facebook-f"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "CoparD",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-qfqlRZ"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-1-inputfield-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "name" },
                            { property: "placeholder", value: "Your name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-qfqlRZ"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-1-inputfield-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-qfqlRZ"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-1-inputfield-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-qfqlRZ"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-1-inputfield-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-1-buttonwrapper-qfqlRZ"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-1-button-qfqlRZ"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  sbCustomCss: [
    {
      componentId: "FLXZjS",
      classes: [
        {
          className: "section.parallax-1-component-FLXZjS",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-1-component-FLXZjS", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-component-FLXZjS",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-1-wrapper-FLXZjS",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "50vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/rock-climbing.png")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-1-text-FLXZjS",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 3rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(249, 249, 249, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-1-text-FLXZjS", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(249, 249, 249, 0)" }] },
        {
          className: "KcPjnznC8AVcZh92S7BYI50ROYCRCV",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-size", value: "48px" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0.5rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "KcPjnznC8AVcZh92S7BYI50ROYCRCV", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "parallax-1-title-FLXZjS",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(249, 249, 249, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-1-title-FLXZjS", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(249, 249, 249, 0)" }] },
        {
          className: "parallax-1-button-FLXZjS",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "parallax-1-button-FLXZjS",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "parallax-1-content-FLXZjS",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
            { property: "justify-content", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "reXUEg",
      classes: [
        {
          className: "section.card-8-component-reXUEg",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-8-component-reXUEg", pseudo: "hover", rules: [] },
        { className: "card-8-row-reXUEg", pseudo: "", rules: [] },
        {
          className: "card-8-col-reXUEg",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(214, 214, 214, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-8-col-reXUEg", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }] },
        {
          className: "card-8-img-reXUEg",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "card-8-img-reXUEg", pseudo: "hover", rules: [] },
        {
          className: "card-8-title-reXUEg",
          pseudo: "",
          rules: [
            { property: "font-family", value: '"Montserrat"' },
            { property: "color", value: "rgba(184, 117, 219, 1)" },
            { property: "margin", value: "0 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-8-title-reXUEg", pseudo: "hover", rules: [] },
        {
          className: "card-8-desc-text-reXUEg",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "I0MNeo",
      classes: [
        {
          className: "section.basics-5-component-I0MNeo",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-5-component-I0MNeo", pseudo: "hover", rules: [] },
        {
          className: "basics-5-col-I0MNeo",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        { className: "YLwa8MzMGgtZbptk51CFWcfDKHlwIh", pseudo: "", rules: [{ property: "color", value: "rgba(10, 10, 10, 1)" }] },
        { className: "YLwa8MzMGgtZbptk51CFWcfDKHlwIh", pseudo: "hover", rules: [] },
        {
          className: "basics-5-text-I0MNeo",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-5-text-I0MNeo", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-5-title-I0MNeo",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(184, 117, 219, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-5-title-I0MNeo", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-5-img-I0MNeo",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-5-img-I0MNeo",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "m3VDjj",
      classes: [
        {
          className: "section.card-18-component-m3VDjj",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-18-component-m3VDjj", pseudo: "hover", rules: [] },
        { className: "card-18-col-m3VDjj", pseudo: "", rules: [] },
        { className: "card-18-col-m3VDjj", pseudo: "hover", rules: [] },
        { className: "card-18-wrapper-m3VDjj", pseudo: "", rules: [] },
        { className: "card-18-wrapper-m3VDjj", pseudo: "hover", rules: [] },
        {
          className: "card-18-text-m3VDjj",
          pseudo: "",
          rules: [
            { property: "margin", value: "0.5rem 0 0 1rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-18-text-m3VDjj", pseudo: "hover", rules: [] },
        {
          className: "card-18-iconwrapper-m3VDjj",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "color", value: "rgba(184, 117, 219, 1)" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        { className: "card-18-iconwrapper-m3VDjj", pseudo: "hover", rules: [] },
        {
          className: "card-18-title-m3VDjj",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(184, 117, 219, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-18-title-m3VDjj", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "ByKNDM",
      classes: [
        {
          className: "section.basics-1-component-ByKNDM",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-ByKNDM", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-ByKNDM",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-ByKNDM", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-ByKNDM",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(184, 117, 219, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-ByKNDM", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "qfqlRZ",
      classes: [
        {
          className: "section.contactus-1-component-qfqlRZ",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-1-component-qfqlRZ", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-col-qfqlRZ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "start" },
            { property: "justify-content", value: "center" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-1-contact-info-block-qfqlRZ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "margin", value: "0 0 1.5rem 0" },
          ],
        },
        {
          className: "contactus-1-contact-info-icon-qfqlRZ",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(184, 117, 219, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "height", value: "25px" },
            { property: "width", value: "25px" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "contactus-1-contact-info-icon-qfqlRZ",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-1-contact-info-content-qfqlRZ",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 1.5rem" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "contactus-1-contact-info-content-qfqlRZ > *", pseudo: "", rules: [{ property: "margin", value: "0" }] },
        {
          className: "contactus-1-social-link-qfqlRZ",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0.5rem 0 0" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "background", value: "rgba(108, 117, 125, 1)" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "height", value: "45px" },
            { property: "aspect-ratio", value: "1" },
            { property: "width", value: "45px" },
            { property: "border-radius", value: "50%" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        {
          className: "contactus-1-social-link-qfqlRZ",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(184, 117, 219, 1)" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
          ],
        },
        { className: "contactus-1-social-link-qfqlRZ", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "contactus-1-buttonwrapper-qfqlRZ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "contactus-1-buttonwrapper-qfqlRZ",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-1-button-qfqlRZ",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(184, 117, 219, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(184, 117, 219, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-1-button-qfqlRZ",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(184, 117, 219, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "contactus-1-inputwrapper-qfqlRZ",
          pseudo: "",
          rules: [{ property: "margin", value: "0 0 1rem 0" }],
        },
        {
          className: "contactus-1-inputfield-qfqlRZ",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(184, 117, 219, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(184, 117, 219, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-1-inputfield-qfqlRZ",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(184, 117, 219, 1)" },
            { property: "border-color", value: "rgba(184, 117, 219, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(184, 117, 219, 0.2)" },
          ],
        },
        {
          className: "contactus-1-inputfield-qfqlRZ",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "contactus-1-inputfield-qfqlRZ",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-1-label-qfqlRZ",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        {
          className: "contactus-1-label-qfqlRZ",
          pseudo: "hover",
          rules: [],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Quicksand:300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Quicksand",
    fontHeadersWeight: "700",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(184, 117, 219, 1)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
