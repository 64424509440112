export const navbar2 = {
  id: "navbar-2",
  name: "Navbar 2",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["navbar", "navbar-expand-lg", "navbar-2-wrapper", "flex-column"],
    styles: [],
    attributes: [
      { property: "data-name", value: "navbar" },
      { property: "data-editable", value: "true" },
      { property: "data-changeonscrollpast", value: "true" }, // Toggle true/false
      { property: "data-navbarposition", value: "" }, // "" || "fixed-top" || "sticky-top"
    ],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["container", "justify-content-between", "justify-content-lg-center", "mb-0", "mb-lg-3"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["navbar-brand"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["navbar-2-logo"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-companyname.png" },
                  { property: "alt", value: "Logo" },
                ],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["d-block", "d-lg-none", "navbar-2-toggler"],
            styles: [],
            attributes: [
              { property: "data-bs-toggle", value: "collapse" },
              { property: "data-bs-target", value: "#navbarCollapse-2" },
              { property: "data-elementgetter1", value: "true" },
            ],
            content: "",
            children: [
              { childId: "", type: "", htmlTagName: "i", classes: ["fa-solid", "fa-bars"], styles: [], attributes: [], content: "", children: [] },
            ],
          },
        ],
      },
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["collapse", "navbar-collapse", "justify-content-center"],
        styles: [],
        attributes: [{ property: "id", value: "navbarCollapse-2" }],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "ul",
            classes: ["navbar-nav"],
            styles: [],
            attributes: [{ property: "data-elementgetter2", value: "true" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "li",
                classes: ["nav-item"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "a",
                    classes: ["nav-link", "navbar-2-link"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_self" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Home", children: [] },
                    ],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "li",
                classes: ["nav-item"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "a",
                    classes: ["nav-link", "navbar-2-link"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_self" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Products", children: [] },
                    ],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "li",
                classes: ["nav-item"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "a",
                    classes: ["nav-link", "navbar-2-link"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_self" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "About", children: [] },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "navbar-2-wrapper",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "1.5rem" },
          { property: "padding-bottom", value: "1.5rem" },
          { property: "background", value: "rgba(230, 230, 230, 1)" },
          { property: "box-shadow", value: "0px 5px 7px 3px rgba(0, 0, 0, 0.3)" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "scrolledpast.navbar-2-wrapper",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "1rem" },
          { property: "padding-bottom", value: "1rem" },
          { property: "background", value: "rgba(230, 230, 230, 0.5)" },
        ],
      },
      {
        className: "navbar-2-logo",
        pseudo: "",
        rules: [
          { property: "max-height", value: "50px" },
          { property: "max-width", value: "33vw" },
          { property: "width", value: "auto" },
          { property: "height", value: "auto" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      { className: "scrolledpast .navbar-2-logo", pseudo: "", rules: [{ property: "max-height", value: "40px" }] },
      {
        className: "navbar-2-link",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(70, 70, 70, 1)" },
          { property: "margin", value: "0 1rem 0 0" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "text-decoration", value: "none" },
        ],
      },
      { className: "navbar-2-link", pseudo: "hover", rules: [{ property: "color", value: "rgba(0, 0, 0, 1)" }] },
      {
        className: "navbar-2-toggler",
        pseudo: "",
        rules: [
          { property: "cursor", value: "pointer" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "font-size", value: "2rem" },
          { property: "background", value: "rgba(230, 230, 230, 1)" },
          { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
          { property: "border-radius", value: "0.2rem" },
          { property: "color", value: "rgba(70, 70, 70, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(180, 180, 180, 1)" },
        ],
      },
      {
        className: "navbar-2-toggler",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(180, 180, 180, 1)" },
          { property: "color", value: "rgba(0, 0, 0, 1)" },
          { property: "border-color", value: "rgba(180, 180, 180, 1)" },
        ],
      },
    ],
  },
};
