export const hero3 = {
  id: "hero-3",
  name: "Hero 3",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "hero-3-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "align-items-top", "justify-content-center"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "order-md-2", "p-3", "hero-3-contentwrapper"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["hero-3-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Hello", children: [] },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "h2",
                classes: ["hero-3-subtitle", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "A bit about me",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["hero-3-text", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem omnis, eligendi quae eum facilis error!",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "block",
                htmlTagName: "div",
                classes: ["row", "justify-content-center", "align-items-stretch", "mt-4", "hero-3-row"],
                styles: [],
                attributes: [{ property: "data-name", value: "block" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["col-4", "col-md-4", "col-lg-4", "p-2", "hero-3-col"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "element_col" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["hero-3-square-btn"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "a",
                            classes: ["hero-3-button1"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "button" },
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-uniqueclassname", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Resume",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["col-4", "col-md-4", "col-lg-4", "p-2", "hero-3-col"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "element_col" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["hero-3-square-btn"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "a",
                            classes: ["hero-3-button2"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "button" },
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-uniqueclassname", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Projects",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["col-4", "col-md-4", "col-lg-4", "p-2", "hero-3-col"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "element_col" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["hero-3-square-btn"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "a",
                            classes: ["hero-3-button3"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "button" },
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-uniqueclassname", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Contact",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-5", "col-lg-4", "order-md-1", "p-3", "hero-3-imgwrapper"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["hero-3-img"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic2.png" },
                  { property: "alt", value: "Image" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                  { property: "data-imgresizable", value: "false" },
                ],
                content: "",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.hero-3-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.hero-3-component", pseudo: "hover", rules: [] },
      { className: "hero-3-wrapper", pseudo: "", rules: [] },
      {
        className: "hero-3-imgwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "justify-content", value: "center" },
          { property: "align-items", value: "flex-start" },
        ],
      },
      {
        className: "hero-3-img",
        pseudo: "",
        rules: [
          { property: "width", value: "100%" },
          { property: "height", value: "auto" },
          { property: "border-radius", value: "50%" },
        ],
      },
      {
        className: "hero-3-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-3-contentwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "hero-3-title",
        pseudo: "",
        rules: [
          { property: "font-size", value: "60px" },
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "1.5rem 0 0 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "hero-3-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-3-subtitle",
        pseudo: "",
        rules: [
          { property: "margin", value: "1.5rem 0 1rem 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      { className: "hero-3-subtitle", pseudo: "hover", rules: [] },
      {
        className: "hero-3-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      { className: "hero-3-text", pseudo: "hover", rules: [] },
      {
        className: "hero-3-square-btn",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "width", value: "100%" },
        ],
      },
      {
        className: "hero-3-square-btn",
        pseudo: "after",
        rules: [
          { property: "content", value: '""' },
          { property: "display", value: "block" },
          { property: "padding-bottom", value: "100%" },
        ],
      },
      {
        className: "hero-3-button1",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "width", value: "100%" },
          { property: "height", value: "100%" },
          { property: "background", value: "rgba(244, 162, 97, 1)" },
          { property: "color", value: "rgba(20, 20, 20, 1)" },
          { property: "border-radius", value: "50%" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
          { property: "font-weight", value: "bold" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(20, 20, 20, 1)" },
          { property: "text-decoration", value: "none" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "hero-3-button1",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255,1)" },
          { property: "color", value: "rgba(20, 20, 20, 1)" },
          { property: "border-color", value: "rgba(20, 20, 20, 1)" },
        ],
      },
      {
        className: "hero-3-button2",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "width", value: "100%" },
          { property: "height", value: "100%" },
          { property: "background", value: "rgba(231, 111, 81, 1)" },
          { property: "color", value: "rgba(20, 20, 20, 1)" },
          { property: "border-radius", value: "50%" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
          { property: "font-weight", value: "bold" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(20, 20, 20, 1)" },
          { property: "text-decoration", value: "none" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "hero-3-button2",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255,1)" },
          { property: "color", value: "rgba(20, 20, 20, 1)" },
          { property: "border-color", value: "rgba(20, 20, 20, 1)" },
        ],
      },
      {
        className: "hero-3-button3",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "width", value: "100%" },
          { property: "height", value: "100%" },
          { property: "background", value: "rgba(233, 196, 106, 1)" },
          { property: "color", value: "rgba(20, 20, 20, 1)" },
          { property: "border-radius", value: "50%" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
          { property: "font-weight", value: "bold" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(20, 20, 20, 1)" },
          { property: "text-decoration", value: "none" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "hero-3-button3",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255,1)" },
          { property: "color", value: "rgba(20, 20, 20, 1)" },
          { property: "border-color", value: "rgba(20, 20, 20, 1)" },
        ],
      },
    ],
  },
};
