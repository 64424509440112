// https://observablehq.com/@hugodf/mongodb-objectid-generator

import { template1 } from "./template1";
import { template2 } from "./template2";
import { template3 } from "./template3";
import { template4 } from "./template4";
import { template5 } from "./template5";
import { template6 } from "./template6";
import { template7 } from "./template7";
import { template8 } from "./template8";
import { template9 } from "./template9";
import { template10 } from "./template10";
import { template11 } from "./template11";
import { template12 } from "./template12";
import { template13 } from "./template13";
import { template14 } from "./template14";
import { template15 } from "./template15";
import { template16 } from "./template16";
import { template17 } from "./template17";
import { template18 } from "./template18";
import { template19 } from "./template19";
import { template20 } from "./template20";
import { template21 } from "./template21";
import { template22 } from "./template22";
import { template23 } from "./template23";
import { template24 } from "./template24";
import { template25 } from "./template25";
import { template26 } from "./template26";
import { template27 } from "./template27";
import { template28 } from "./template28";
import { template29 } from "./template29";

export const templates = [
  template1,
  template2,
  template3,
  template4,
  template5,
  template6,
  template7,
  template8,
  template9,
  template10,
  template11,
  template12,
  template13,
  template14,
  template15,
  template16,
  template17,
  template18,
  template19,
  template20,
  template21,
  template22,
  template23,
  template24,
  template25,
  template26,
  template27,
  template28,
  template29,
];
