export const quotes3 = {
  id: "quotes-3",
  name: "Quotes 3",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "quote-3-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "quote-3-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-6", "quote-3-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-3-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["quote-3-avatar"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["quote-3-text1", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Name", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h6",
                    classes: ["quote-3-text2", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Function / company / location",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["quote-3-quotewrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["quote-3-quote-left"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "span" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-quote-left"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["quote-3-text3", "toGrow", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content:
                              "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus necessitatibus culpa ex odio quam atque reiciendis ducimus earum. Fugit, recusandae!",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["quote-3-quote-right"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "span" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-quote-right"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.quote-3-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.quote-3-component", pseudo: "hover", rules: [] },
      {
        className: "quote-3-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "display", value: "flex" },
        ],
      },
      {
        className: "quote-3-col",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-3-wrapper",
        pseudo: "",
        rules: [
          { property: "align-items", value: "center" },
          { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
        ],
      },
      { className: "quote-3-wrapper", pseudo: "hover", rules: [] },
      {
        className: "quote-3-avatar",
        pseudo: "",
        rules: [
          { property: "border-radius", value: "50%" },
          { property: "height", value: "150px" },
          { property: "width", value: "auto" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "2px" },
          { property: "border-color", value: "rgba(150, 150, 150, 1)" },
          { property: "margin", value: "0 0 1rem 0" },
        ],
      },
      {
        className: "quote-3-avatar",
        pseudo: "hover",
        rules: [
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "2px" },
          { property: "border-color", value: "rgba(150, 150, 150, 1)" },
        ],
      },
      {
        className: "quote-3-text1",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "quote-3-text1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-3-text2",
        pseudo: "",
        rules: [
          { property: "font-style", value: "italic" },
          { property: "margin", value: "0 0 1rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "quote-3-text2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-3-text3",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "text-align", value: "center" },
          { property: "font-style", value: "italic" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "quote-3-text3",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-3-quotewrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "padding", value: "0 1rem 0 1rem" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "quote-3-quotewrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-3-quote-left",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "-15px" },
          { property: "left", value: "0" },
          { property: "font-size", value: "1.5rem" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
        ],
      },
      {
        className: "quote-3-quote-left",
        pseudo: "hover",
        rules: [{ property: "color", value: "rgba(229, 42, 5, 1)" }],
      },
      {
        className: "quote-3-quote-right",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "right", value: "0" },
          { property: "bottom", value: "-10px" },
          { property: "font-size", value: "1.5rem" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
        ],
      },
      {
        className: "quote-3-quote-right",
        pseudo: "hover",
        rules: [{ property: "color", value: "rgba(229, 42, 5, 1)" }],
      },
    ],
  },
};
