import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";
import Select from "./formComponents/Select";
import ImgSizeInput from "./formComponents/ImgSizeInput";
import Divider from "./formComponents/Divider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const CarouselSettings = ({ sb: { startStyles } }) => {
  const [carouselOptions, setCarouselOptions] = useState(null);

  useEffect(() => {
    try {
      let options = formGetStartValue(startStyles, "carouselOptions");
      if (options !== null && typeof options !== "undefined" && Object.keys(options).length > 0) {
        setCarouselOptions(options);
      } else {
        setCarouselOptions(null);
      }
    } catch (error) {
      setCarouselOptions(null);
    }
  }, [startStyles]);

  const getCarouselStartVal = (varName) => {
    try {
      if (varName === "autoscrollSpeed") {
        return typeof carouselOptions.autoScroll === "undefined" ? 1 : carouselOptions.autoScroll.speed;
      }
      if (varName === "fixedWidth" || varName === "fixedHeight" || varName === "gap") {
        return parseInt(typeof carouselOptions[varName] === "undefined" ? 0 : carouselOptions[varName]);
      }
      return carouselOptions[varName];
    } catch (error) {
      return "";
    }
  };

  return (
    <>
      {/* Hidden tracker for carouselFormat & classes */}
      <input type="hidden" id={`${EDIT_FORM_ID_PREFIX}carouselFormat`} value={formGetStartValue(startStyles, "carouselFormat")} />
      <input type="hidden" id={`${EDIT_FORM_ID_PREFIX}carouselClasses`} value={JSON.stringify(getCarouselStartVal("classes"))} />
      {/* carouselOptions forms */}
      <Select
        label={translate("cEditForms.carouselSettings_size", false, null)}
        id={"carousel_size"}
        options={[
          { val: "auto", label: translate("cEditForms.carouselSettings_autoSize", false, null) },
          { val: "fullPage", label: translate("cEditForms.carouselSettings_fullScreen", false, null) },
        ]}
        startValue={getCarouselStartVal("carouselSize")}
        tooltipText={translate("cEditForms.carouselSettings_ttSize", false, null)}
      />
      <Divider />
      <Select
        label={translate("cEditForms.carouselSettings_autoplay", false, null)}
        id={"carousel_autoplay"}
        // Don't allow autoscroll on carousels with thumbnails
        options={
          getCarouselStartVal() === "carousel-thumbnail-main"
            ? [
                { val: "manual", label: translate("cEditForms.carouselSettings_manualClicks", false, null) },
                { val: "autoplay", label: translate("cEditForms.carouselSettings_autoplay", false, null) },
              ]
            : [
                { val: "manual", label: translate("cEditForms.carouselSettings_manualClicks", false, null) },
                { val: "autoplay", label: translate("cEditForms.carouselSettings_autoplay", false, null) },
                { val: "autoscroll", label: translate("cEditForms.carouselSettings_contAutoScroll", false, null) },
              ]
        }
        startValue={getCarouselStartVal("carouselAutoplay")}
        tooltipText={translate("cEditForms.carouselSettings_ttAutoplay", false, null)}
      />
      <Divider />
      <Select
        label={translate("cEditForms.carouselSettings_transType", false, null)}
        id={"carousel_transitionType"}
        options={[
          { val: "slide", label: translate("cEditForms.carouselSettings_slideRewind", false, null) },
          { val: "loop", label: translate("cEditForms.carouselSettings_slideLoop", false, null) },
          { val: "fade", label: translate("cEditForms.carouselSettings_fade", false, null) },
        ]}
        startValue={getCarouselStartVal("type")}
        tooltipText={translate("cEditForms.carouselSettings_ttTransType", false, null)}
      />
      <Slider
        label={translate("cEditForms.carouselSettings_transSpeed", false, null)}
        id={"carousel_transitionSpeed"}
        min={0}
        max={2}
        step={0.1}
        startValue={parseFloat(getCarouselStartVal("speed") / 1000)}
        tooltipText={translate("cEditForms.carouselSettings_ttTransSpeed", false, null)}
      />
      <Slider
        label={translate("cEditForms.carouselSettings_rewindSpeed", false, null)}
        id={"carousel_rewindSpeed"}
        min={0}
        max={2}
        step={0.1}
        startValue={parseFloat(getCarouselStartVal("rewindSpeed") / 1000)}
        tooltipText={translate("cEditForms.carouselSettings_ttRewindSpeed", false, null)}
      />
      <Slider
        label={translate("cEditForms.carouselSettings_autoplaySpeed", false, null)}
        id={"carousel_interval"}
        min={0.5}
        max={5}
        step={0.1}
        startValue={parseFloat(getCarouselStartVal("interval") / 1000)}
        tooltipText={translate("cEditForms.carouselSettings_ttAutoplaySpeed", false, null)}
      />
      <Slider
        label={translate("cEditForms.carouselSettings_autoScrollSpeed", false, null)}
        id={"carousel_autoscrollSpeed"}
        min={1}
        max={10}
        step={1}
        startValue={parseInt(getCarouselStartVal("autoscrollSpeed") || 1)}
        tooltipText={translate("cEditForms.carouselSettings_ttAutoScrollSpeed", false, null)}
      />
      <Divider />
      <Slider
        label={translate("cEditForms.carouselSettings_slidesShown", false, null)}
        id={"carousel_perPage"}
        min={1}
        max={5}
        step={1}
        startValue={parseInt(getCarouselStartVal("perPage"))}
        tooltipText={translate("cEditForms.carouselSettings_ttSlidesShown", false, null)}
      />
      <Slider
        label={translate("cEditForms.carouselSettings_slidesMoved", false, null)}
        id={"carousel_perMove"}
        min={1}
        max={5}
        step={1}
        startValue={parseInt(getCarouselStartVal("perMove"))}
        tooltipText={translate("cEditForms.carouselSettings_ttSlidesMoved", false, null)}
      />
      <Divider />
      <ImgSizeInput
        label={translate("cEditForms.carouselSettings_height", false, null)}
        id={"carousel_fixedHeight"}
        startValue={formGetStartValue(startStyles, "carousel_fixedHeight")}
        tooltipText={translate("cEditForms.carouselSettings_ttHeight", false, null)}
      />
      <Slider
        label={translate("cEditForms.carouselSettings_space", false, null)}
        id={"carousel_gap"}
        min={1}
        max={100}
        step={1}
        startValue={parseInt(getCarouselStartVal("gap"))}
        tooltipText={translate("cEditForms.carouselSettings_ttSpace", false, null)}
      />
    </>
  );
};

CarouselSettings.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(CarouselSettings);
