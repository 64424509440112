export const quotes1 = {
  id: "quotes-1",
  name: "Quotes 1",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "quote-1-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "quote-1-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "quote-1-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card6" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-1-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["quote-1-border"],
                    styles: [],
                    attributes: [{ property: "data-elementgetter2", value: "true" }],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h4",
                    classes: ["quote-1-text", "toGrow"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content:
                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h6",
                    classes: ["quote-1-quote"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "em",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "— Someone said somewhere",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "quote-1-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card6" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-1-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["quote-1-border"],
                    styles: [],
                    attributes: [{ property: "data-elementgetter2", value: "true" }],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h4",
                    classes: ["quote-1-text", "toGrow"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora rerum rem ducimus illum!",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h6",
                    classes: ["quote-1-quote"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "em",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "— Someone said somewhere",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "quote-1-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card6" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-1-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["quote-1-border"],
                    styles: [],
                    attributes: [{ property: "data-elementgetter2", value: "true" }],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h4",
                    classes: ["quote-1-text", "toGrow"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores iste porro facere beatae totam.",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h6",
                    classes: ["quote-1-quote"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "em",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "— Someone said somewhere",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.quote-1-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.quote-1-component", pseudo: "hover", rules: [] },
      {
        className: "quote-1-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "display", value: "flex" },
        ],
      },
      {
        className: "quote-1-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0.75rem" },
          { property: "padding", value: "0 0 0 0" },
        ],
      },
      { className: "quote-1-wrapper", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" }] },
      {
        className: "quote-1-border",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "left", value: "0" },
          { property: "height", value: "100%" },
          { property: "width", value: "10px" },
          { property: "background", value: "rgba(229, 42, 5, 1)" },
        ],
      },
      {
        className: "quote-1-border",
        pseudo: "hover",
        rules: [{ property: "background", value: "rgba(229, 42, 5, 1)" }],
      },
      {
        className: "quote-1-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 1rem 1rem 1.5rem" },
          { property: "color", value: "rgba(109, 118, 126, 1)" },
        ],
      },
      {
        className: "quote-1-text",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-1-quote",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 1rem 1rem 1.5rem" },
          { property: "color", value: "rgba(109, 118, 126, 1)" },
        ],
      },
      {
        className: "quote-1-quote",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
