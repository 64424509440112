export const process2 = {
  id: "process-2",
  name: "Process 2",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "process-2-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "process-2-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-3", "process-2-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "process2" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "process-2-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["process-2-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Do this", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["process-2-stepswrapper", "py-3", "py-lg-4", "w-100", "posRelative"],
                    styles: [],
                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["d-none", "d-lg-block", "process-2-step-line"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["process-2-step-circle"],
                        styles: [],
                        attributes: [{ property: "data-elementgetter2", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["toGrow", "process-2-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, suscipit.",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-3", "process-2-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "process2" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "process-2-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["process-2-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Do that", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["process-2-stepswrapper", "py-3", "py-lg-4", "w-100", "posRelative"],
                    styles: [],
                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["d-none", "d-lg-block", "process-2-step-line"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["process-2-step-circle"],
                        styles: [],
                        attributes: [{ property: "data-elementgetter2", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["toGrow", "process-2-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam sapiente ipsa molestiae eaque voluptate.",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-3", "process-2-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "process2" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "process-2-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["process-2-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Almost done",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["process-2-stepswrapper", "py-3", "py-lg-4", "w-100", "posRelative"],
                    styles: [],
                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["d-none", "d-lg-block", "process-2-step-line"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["process-2-step-circle"],
                        styles: [],
                        attributes: [{ property: "data-elementgetter2", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["toGrow", "process-2-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Libero!",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-3", "process-2-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "process2" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "process-2-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["process-2-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Finished!", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["process-2-stepswrapper", "py-3", "py-lg-4", "w-100", "posRelative"],
                    styles: [],
                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["d-none", "d-lg-block", "process-2-step-line"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["process-2-step-circle"],
                        styles: [],
                        attributes: [{ property: "data-elementgetter2", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["toGrow", "process-2-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae dolorem repudiandae fugiat.",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.process-2-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.process-2-component", pseudo: "hover", rules: [] },
      {
        className: "process-2-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 0 1rem 0" },
          { property: "display", value: "flex" },
        ],
      },
      { className: "process-2-wrapper", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
      {
        className: "process-2-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 1rem 0" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "font-weight", value: "bold" },
          { property: "text-align", value: "center" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "process-2-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "process-2-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "process-2-text",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "process-2-step-line",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "position", value: "absolute" },
          { property: "left", value: "0" },
          { property: "top", value: "50%" },
          { property: "transform", value: "translateY(-50%)" },
          { property: "width", value: "100%" },
          { property: "height", value: "2px" },
        ],
      },
      {
        className: "process-2-step-circle",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "left", value: "50%" },
          { property: "top", value: "50%" },
          { property: "transform", value: "translate(-50%, -50%)" },
          { property: "height", value: "12px" },
          { property: "width", value: "12px" },
          { property: "border-radius", value: "50%" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "font-size", value: "0" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
          { property: "background", value: "rgba(229, 42, 5, 1)" },
        ],
      },
      {
        className: "process-2-wrapper",
        pseudo: "hover .process-2-step-circle",
        rules: [
          { property: "height", value: "36px" },
          { property: "width", value: "36px" },
        ],
      },
      { className: "process-2-wrapper", pseudo: "hover .process-2-step-circle", rules: [{ property: "font-size", value: "1.3rem" }] },
      { className: "process-2-wrapper", pseudo: "hover>*:first-child", rules: [{ property: "transform", value: "translateY(-12px)" }] },
      { className: "process-2-wrapper", pseudo: "hover>*:last-child", rules: [{ property: "transform", value: "translateY(12px)" }] },
      {
        className: "process-2-col",
        pseudo: "first-of-type span.process-2-step-line",
        rules: [
          { property: "left", value: "50%" },
          { property: "width", value: "50%" },
        ],
      },
      { className: "process-2-col", pseudo: "last-of-type span.process-2-step-line", rules: [{ property: "width", value: "50%" }] },
    ],
  },
};
