export const contactus1 = {
  id: "contactus-1",
  name: "Contact us 1",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "contactus-1-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "contactus-1-row"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "block",
            htmlTagName: "div",
            classes: ["col-12", "col-md-4", "col-lg-3", "contactus-1-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "block" }],
            content: "",
            children: [
              {
                childId: "",
                type: "element",
                htmlTagName: "div",
                classes: ["contactus-1-contact-info-block"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["contactus-1-contact-info-icon"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                      { property: "data-name", value: "div" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-location-dot"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["contactus-1-contact-info-content"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["contactus-1-contact-info-content-text"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Street 1337",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["contactus-1-contact-info-content-text"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Postal code, City",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["contactus-1-contact-info-content-text"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Country",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                childId: "",
                type: "element",
                htmlTagName: "div",
                classes: ["contactus-1-contact-info-block"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["contactus-1-contact-info-icon"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                      { property: "data-name", value: "div" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-envelope"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["contactus-1-contact-info-content"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["contactus-1-contact-info-content-text"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "email@domain.com",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                childId: "",
                type: "element",
                htmlTagName: "div",
                classes: ["contactus-1-contact-info-block"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["contactus-1-contact-info-icon"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                      { property: "data-name", value: "div" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-phone"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["contactus-1-contact-info-content"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["contactus-1-contact-info-content-text"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "020 13 37 000",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                childId: "",
                type: "block",
                htmlTagName: "div",
                classes: ["d-flex", "flex-wrap"],
                styles: [],
                attributes: [{ property: "data-name", value: "block" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "a",
                    classes: ["contactus-1-social-link"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_blank" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-envelope"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "a",
                    classes: ["contactus-1-social-link"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_blank" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-brands", "fa-twitter"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "a",
                    classes: ["contactus-1-social-link"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_blank" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-brands", "fa-linkedin"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "a",
                    classes: ["contactus-1-social-link"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_blank" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-brands", "fa-facebook-f"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
            styles: [],
            attributes: [
              { property: "data-sbform", value: "true" },
              { property: "data-sbformaction", value: "" },
              { property: "data-sbformmsg", value: "Your message has been received!" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["w-100", "contactus-1-inputwrapper"],
                styles: [],
                attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "label",
                    classes: ["form-control", "contactus-1-label"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "inputlabel" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Your name",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "input",
                    classes: ["form-control", "contactus-1-inputfield"],
                    styles: [],
                    attributes: [
                      { property: "type", value: "text" },
                      { property: "name", value: "name" },
                      { property: "placeholder", value: "Your name" },
                      { property: "data-name", value: "inputfield" },
                      { property: "data-required", value: "true" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["w-100", "contactus-1-inputwrapper"],
                styles: [],
                attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "label",
                    classes: ["form-control", "contactus-1-label"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "inputlabel" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Your email",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "input",
                    classes: ["form-control", "contactus-1-inputfield"],
                    styles: [],
                    attributes: [
                      { property: "type", value: "email" },
                      { property: "name", value: "email" },
                      { property: "placeholder", value: "Your email address" },
                      { property: "data-name", value: "inputfield" },
                      { property: "data-required", value: "true" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["w-100", "contactus-1-inputwrapper"],
                styles: [],
                attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "label",
                    classes: ["form-control", "contactus-1-label"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "inputlabel" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Subject",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "input",
                    classes: ["form-control", "contactus-1-inputfield"],
                    styles: [],
                    attributes: [
                      { property: "type", value: "text" },
                      { property: "name", value: "subject" },
                      { property: "placeholder", value: "Subject" },
                      { property: "data-name", value: "inputfield" },
                      { property: "data-required", value: "true" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["w-100", "contactus-1-inputwrapper"],
                styles: [],
                attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "label",
                    classes: ["form-control", "contactus-1-label"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "inputlabel" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Your message",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textarea",
                    classes: ["form-control", "contactus-1-inputfield"],
                    styles: [],
                    attributes: [
                      { property: "rows", value: "3" },
                      { property: "name", value: "message" },
                      { property: "placeholder", value: "Message" },
                      { property: "data-name", value: "inputfield" },
                      { property: "data-required", value: "true" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["contactus-1-buttonwrapper"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "button",
                    classes: ["contactus-1-button"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "button" },
                      { property: "data-sbformbtn", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Send message!",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.contactus-1-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.contactus-1-component", pseudo: "hover", rules: [] },
      {
        className: "contactus-1-col",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "align-items", value: "start" },
          { property: "justify-content", value: "center" },
          { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
        ],
      },
      {
        className: "contactus-1-contact-info-block",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "margin", value: "0 0 1.5rem 0" },
        ],
      },
      {
        className: "contactus-1-contact-info-icon",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "font-size", value: "1.5rem" },
          { property: "height", value: "25px" },
          { property: "width", value: "25px" },
          { property: "display", value: "flex" },
          { property: "justify-content", value: "center" },
        ],
      },
      {
        className: "contactus-1-contact-info-icon",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "contactus-1-contact-info-content",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 1.5rem" },
          { property: "align-self", value: "center" },
        ],
      },
      { className: "contactus-1-contact-info-content > *", pseudo: "", rules: [{ property: "margin", value: "0" }] },
      {
        className: "contactus-1-social-link",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0.5rem 0 0" },
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "background", value: "rgba(108, 117, 125, 1)" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
          { property: "height", value: "45px" },
          { property: "aspect-ratio", value: "1" },
          { property: "width", value: "45px" },
          { property: "border-radius", value: "50%" },
          { property: "text-decoration", value: "none" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "contactus-1-social-link",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(250, 250, 250, 1)" },
        ],
      },
      { className: "contactus-1-social-link", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      {
        className: "contactus-1-buttonwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "contactus-1-buttonwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "contactus-1-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
          { property: "width", value: "auto" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "contactus-1-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "contactus-1-inputwrapper",
        pseudo: "",
        rules: [{ property: "margin", value: "0 0 1rem 0" }],
      },
      {
        className: "contactus-1-inputfield",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(206, 212, 218, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.2s ease" },
          { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
          { property: "font-size", value: "1rem" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
          { property: "outline", value: "0" },
        ],
      },
      {
        className: "contactus-1-inputfield",
        pseudo: "focus",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
        ],
      },
      {
        className: "contactus-1-inputfield",
        pseudo: ":placeholder",
        rules: [
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "contactus-1-inputfield",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "contactus-1-label",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.25rem 0" },
          { property: "display", value: "none" },
        ],
      },
      {
        className: "contactus-1-label",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
