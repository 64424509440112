import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import useDebounce from "../../../lib/useDebounce";
import { FONT_WEIGHT_VALUES, FONT_WEIGHT_CSS, FONT_WEIGHT_NAMES } from "../../../lib/generalVars";
import { selectFont } from "../../../actions/sb";
import { translate } from "../../../translations/translations";

/* ===
== Due to async nature of useState, use useEffect to trigger functions:
== change in fontSelectedTarget => getFontsFromApi() => changes sortedFonts => loadFonts()
==    onChange() => change refilter => filterFonts() => changes sortedFonts => loadFonts()
==                                     onScroll() => change debouncedScroll => loadFonts()
===== */

// target = "headers" || "body"
const SiteFonts = ({ sb: { sbCssVars }, target, selectFont }) => {
  const closeBtn = useRef();

  // Key vars
  const NUMBER_FONTS_LOAD = 18;
  const categoryNames = [
    translate("mSiteFonts.all", false, null),
    translate("mSiteFonts.serif", false, null),
    translate("mSiteFonts.sansSerif", false, null),
    translate("mSiteFonts.display", false, null),
    translate("mSiteFonts.handwriting", false, null),
    translate("mSiteFonts.monospace", false, null),
  ];
  const categoryApiNames = ["all", "serif", "sans-serif", "display", "handwriting", "monospace"];
  // https://developers.google.com/fonts/docs/getting_started
  const baseUrl = "https://fonts.googleapis.com/css?family=";
  const finalizeUrl = "&display=swap";
  const initialPreviewText = translate("mSiteFonts.initialPreviewText", false, null);

  const [state, setState] = useState({
    searchTerm: "",
    categoriesSelected: [true, false, false, false, false, false],
    allFonts: [],
    sortedFonts: [],
    showUntilNumber: 0,
    refilter: 0,
    scrollPos: 0,
    scrollPosMax: 0,
    stylesheets: [],
    fontWeight: 400,
    previewText: initialPreviewText,
    selectedFont: null,
    errorText: "",
  });
  const {
    searchTerm,
    previewText,
    categoriesSelected,
    allFonts,
    sortedFonts,
    showUntilNumber,
    scrollPos,
    scrollPosMax,
    stylesheets,
    fontWeight,
    refilter,
    selectedFont,
    errorText,
  } = state;

  // useEffects to trigger actions when state vars change
  // Retrieve fonts from API only when fontSelectedTarget changes (which happens when the modal is launched)
  useEffect(() => {
    getFontsFromApi();
    // eslint-disable-next-line
  }, []);

  // When searchTerm or selected tags change, trigger a refilter
  useEffect(() => {
    filterFonts();
    closeFontSelectionPane();
    // eslint-disable-next-line
  }, [refilter]);

  // getFontsFromApi() and filterFonts() change sortedFonts => trigger loadFonts
  useEffect(() => {
    sortedFonts.length > 0 && loadFonts(true);
    // eslint-disable-next-line
  }, [sortedFonts]);

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onScroll = (e) => {
    setState({ ...state, scrollPos: e.target.scrollTop, scrollPosMax: e.target.scrollTopMax });
  };

  let debouncedSearchTerm = useDebounce(searchTerm, 200);
  useEffect(() => {
    // debouncedSearchTerm gets updated => update refilter => triggers filterFonts()
    setState({ ...state, refilter: state.refilter + 1 });
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  let debouncedScrollPos = useDebounce(scrollPos, 200);
  useEffect(() => {
    if (debouncedScrollPos > 0 && scrollPosMax - debouncedScrollPos < 200) {
      loadFonts(false);
    }
    // eslint-disable-next-line
  }, [debouncedScrollPos]);

  const selectCategory = (i) => {
    let newArray = [];
    // If "all" is active and another category is clicked => set "all" to inactive
    if (i === 0 && !categoriesSelected[0]) {
      // "all": true // others: false
      categoriesSelected.forEach((cat, j) => (j === 0 ? newArray.push(true) : newArray.push(false)));
    } else if (i > 0 && !categoriesSelected[i] && categoriesSelected[0]) {
      // "all": false // others: their actual value
      categoriesSelected.forEach((cat, j) => (j === i ? newArray.push(!categoriesSelected[i]) : newArray.push(categoriesSelected[j])));
      newArray[0] = false;
    } else {
      // All their actual value
      categoriesSelected.forEach((cat, j) => (j === i ? newArray.push(!categoriesSelected[i]) : newArray.push(categoriesSelected[j])));
    }
    setState({
      ...state,
      categoriesSelected: newArray,
      refilter: state.refilter + 1,
    });
  };

  const clickConfirmFont = () => {
    try {
      let selectedFontStylesheet =
        stylesheets.map((sheet) => sheet.href).filter((link) => link.includes(`family=${selectedFont.fontName.replace(/\s/g, "+")}:`))[0] || "";
      selectFont(selectedFont.fontName, target === "headers" ? "fontHeadersName" : "fontBodyName");
      selectFont(selectedFont.fontWeight, target === "headers" ? "fontHeadersWeight" : "fontBodyWeight");
      selectFont(selectedFontStylesheet, target === "headers" ? "fontHeadersLink" : "fontBodyLink");
      closeModal();
    } catch (error) {
      console.log(`Error in clickConfirmFont // selectedFont: ${selectedFont.fontName} // loadded style sheets:`, stylesheets, error);
    }
  };

  const getFontsFromApi = async () => {
    const apiKey = "AIzaSyDZUc5i24aZlHMn5N2KJbvmej_9yZi724I";
    try {
      const response = await fetch(`https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=${apiKey}`);
      const res = await response.json();
      setState({
        ...state,
        allFonts: res.items,
        sortedFonts: res.items,
        stylesheets: [
          ...state.stylesheets,
          loadDetailedStylesheet(sbCssVars[target === "headers" ? "fontHeadersName" : "fontBodyName"], FONT_WEIGHT_CSS),
        ],
        errorText: "",
      });
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        errorText: translate("mSiteFonts.connectionError", false, null),
      });
    }
  };

  const filterFonts = () => {
    if (allFonts.length > 0) {
      // Get categories to include
      let categories = [];
      if (categoriesSelected[0]) {
        // Take all the api names except "all"
        categories = categoryApiNames.slice(1);
      } else {
        if (categoriesSelected.length > 0) {
          categoriesSelected.forEach((cat, i) => cat && categories.push(categoryApiNames[i]));
        }
      }
      // Filter through all fonts
      setState({
        ...state,
        sortedFonts: allFonts.filter((font) => categories.includes(font.category) && font.family.toLowerCase().includes(searchTerm.toLowerCase())),
      });
    }
  };

  const loadFonts = (reset) => {
    // Only load new stylesheets if fonts are either reset or, if not reset, the end of sortedFonts hasn't been reached yet
    if (reset || Math.min(NUMBER_FONTS_LOAD, sortedFonts.length - showUntilNumber) > 0) {
      // What fonts are newly added => need to get stylesheets
      let newFonts = [];
      if (reset) {
        newFonts = sortedFonts.slice(0, NUMBER_FONTS_LOAD);
      } else {
        newFonts = sortedFonts.slice(showUntilNumber, showUntilNumber + NUMBER_FONTS_LOAD);
      }
      let stylesheet = loadStyleSheets(newFonts);
      // Update shownUntilNumber state var
      setState({
        ...state,
        showUntilNumber: state.showUntilNumber * (reset ? 0 : 1) + NUMBER_FONTS_LOAD,
        stylesheets: [...state.stylesheets, stylesheet],
      });
    }
  };

  const loadStyleSheets = (fonts) => {
    // Set up url
    let url = baseUrl;
    fonts.forEach((font, i) => {
      url = `${url}${i === 0 ? "" : "|"}${font.family.replace(/ /g, "+")}`;
    });
    url = `${url}${finalizeUrl}`;
    // Set up style element
    let stylesheet = document.createElement("link");
    stylesheet.setAttribute("rel", "stylesheet");
    stylesheet.setAttribute("href", url);
    stylesheet = document.head.appendChild(stylesheet);
    return stylesheet;
  };

  // const removeStylesheets = () => {
  //   if (stylesheets.length > 0) {
  //     // Remove from the DOM
  //     stylesheets.forEach((stylesheet) => stylesheet.remove());
  //   }
  // };

  const closeModal = () => {
    // Remove all the stylesheets
    // removeStylesheets();
    // Reset state
    setState({
      ...state,
      searchTerm: "",
      categoriesSelected: [true, false, false, false, false, false],
      previewText: initialPreviewText,
      selectedFont: null,
      errorText: "",
    });
    // Close fontSelectionPanes
    closeFontSelectionPane();
  };

  const openFontSelectionPane = (e, i) => {
    // Close currently open
    closeFontSelectionPane();
    // Get the div that is being clicked
    let clickedDiv = e.currentTarget;
    // Find the position to insert the selection pane
    let colsPerRow = 3;
    let endOfRow = (i + 1) % colsPerRow;
    endOfRow > 0 && (endOfRow = colsPerRow - endOfRow);
    let insertBefore = clickedDiv.parentElement;
    for (let i = 0; i <= endOfRow; i++) {
      insertBefore !== null && (insertBefore = insertBefore.nextSibling);
    }
    // Set up the content of the selection pane
    let selectionPane = document.createElement("div");
    selectionPane.className = "col-12 p-2 fontSelectionPane";
    let innerDiv = document.createElement("div");
    innerDiv.className = "pt-2 rounded shadow-light trans-3";
    innerDiv.innerHTML = `<h5 class="text-primary m-0 px-2">${sortedFonts[i].family}<i class="fa-solid fa-xmark float-end trans-3 cursorPointer text-gray textHover-primary fontSelectionPaneClose"></i></h5><p class="p-2 m-0">Available styles</p>`;
    // Add the available weights
    FONT_WEIGHT_VALUES.forEach(
      (weight, j) =>
        sortedFonts[i].variants.includes(weight) &&
        (innerDiv.innerHTML = `${innerDiv.innerHTML}<div class="p-2 bgHover-light border-top fontSelectionPanePreview"><p class="fontSize09 text-gray mb-1">${FONT_WEIGHT_NAMES[j]}</p><div class="d-flex align-items-center"><p class="m-0 fontSize15" style="font-family: ${sortedFonts[i].family}; font-weight: ${FONT_WEIGHT_CSS[j]}; width: 80%;">${previewText}</p><span class="text-success ms-auto trans-3 cursorPointer fontSelectionPaneSelect" data-font="${sortedFonts[i].family}" data-weight="${FONT_WEIGHT_CSS[j]}"><i class="fa-solid fa-circle-check me-1 fontSize11"></i>Select this font</span></div></div>`)
    );
    // Insert the selection pane
    selectionPane.appendChild(innerDiv);
    clickedDiv.parentElement.parentElement.insertBefore(selectionPane, insertBefore);
    // Set up the stylesheet (if only regular/400 exists, no need to add additional stylesheet)
    if (FONT_WEIGHT_VALUES.filter((weight) => sortedFonts[i].variants.includes(weight)).length > 1) {
      stylesheets.length === 0 &&
        setState({ ...state, stylesheets: state.stylesheets.push(loadDetailedStylesheet(sortedFonts[i].family, sortedFonts[i].variants)) });
      stylesheets.length > 0 &&
        setState({ ...state, stylesheets: [...state.stylesheets, loadDetailedStylesheet(sortedFonts[i].family, sortedFonts[i].variants)] });
    }
  };

  const onClickModal = (e) => {
    // If e.target is the icon to close the fontSelectionPane, close it
    e.target.className.includes("fontSelectionPaneClose") && closeFontSelectionPane();
    // If e.target or e.target.parentElement is fontSelectionPaneSelect, select the font
    if (e.target.className.includes("fontSelectionPaneSelect") || e.target.parentElement.className.includes("fontSelectionPaneSelect")) {
      let span = e.target;
      span.localName === "i" && (span = e.target.parentElement);
      setState({ ...state, selectedFont: { fontName: span.dataset.font, fontWeight: span.dataset.weight } });
      closeFontSelectionPane();
    }
  };

  const getDetailedStylesheetUrl = (fontFamily, variants) => {
    let url = baseUrl;
    url = `${url}${fontFamily.replace(/ /g, "+")}`;
    let k = 0;
    FONT_WEIGHT_CSS.forEach((weight, j) => {
      if (variants.includes(weight)) {
        url = `${url}${k === 0 ? ":" : ","}${FONT_WEIGHT_CSS[j]}`;
        k++;
      }
    });
    url = `${url}${finalizeUrl}`;
    return url;
  };

  const loadDetailedStylesheet = (fontFamily, variants) => {
    // Get stylesheet link element
    let stylesheet = document.createElement("link");
    stylesheet.setAttribute("rel", "stylesheet");
    stylesheet.setAttribute("href", getDetailedStylesheetUrl(fontFamily, variants));
    // Append the stylesheet to the head and return the element to be saved to the state
    let returnElement = document.head.appendChild(stylesheet);
    return returnElement;
  };

  const closeFontSelectionPane = () => {
    const openPanes = document.querySelectorAll(".fontSelectionPane");
    openPanes.forEach((pane) => pane.remove());
  };

  return (
    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" onClick={onClickModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title">
            {target === "headers"
              ? translate("mSiteFonts.selectNewFontHeaders", false, null)
              : translate("mSiteFonts.selectNewFontBody", false, null)}
          </h3>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeBtn} onClick={closeModal}></button>
        </div>
        <div className="modal-body" onScroll={onScroll}>
          <div className="row">
            <div className="col-3">
              <p className="m-0">{translate("mSiteFonts.searchFontName", false, null)}</p>
              <input
                className="form-control"
                type="text"
                value={searchTerm}
                name="searchTerm"
                onChange={onChange}
                placeholder={translate("mSiteFonts.enterFontName", false, null)}
              />
              <p className="mt-3 mb-0">{translate("mSiteFonts.selectCategory", false, null)}</p>
              <div className="mx-n1">
                {categoryNames.map((categoryName, i) => (
                  <span key={i} className={`fontSelectorCategory badge ${categoriesSelected[i] ? "selected" : ""}`} onClick={() => selectCategory(i)}>
                    {categoryName}
                  </span>
                ))}
              </div>
              <p className="mt-2 mb-0 fontSize08">
                <span className="text-bold">{sortedFonts.length}</span> {translate("mSiteFonts.xFontsMatchSelection", false, null)}
              </p>
              <p className="mt-3 mb-0">{translate("mSiteFonts.previewText", false, null)}</p>
              <input
                className="form-control"
                type="text"
                value={previewText}
                name="previewText"
                onChange={onChange}
                placeholder={translate("mSiteFonts.previewText", false, null)}
              />
            </div>
            <div className="col-9">
              <div className="row mr-0">
                {errorText !== "" ? (
                  <p className="text-italic">
                    {translate("mSiteFonts.error", false, null)}: {errorText}
                  </p>
                ) : sortedFonts.length > 0 ? (
                  sortedFonts.slice(0, showUntilNumber).map((font, i) => (
                    <div className="col-4 p-2" key={i}>
                      <div
                        className="previewFontDiv p-2 rounded shadow-light trans-3 cursorPointer flexSameHeight"
                        onClick={(e) => openFontSelectionPane(e, i)}
                      >
                        <p className="fontSize15 mb-2 toGrow" style={{ fontFamily: font.family, fontWeight: fontWeight }}>
                          {previewText}
                        </p>
                        <p className="text-muted small mb-0">
                          {font.family} - <span className="text-italic">{font.category}</span>
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-italic">{translate("mSiteFonts.noFontsMatchSelection", false, null)}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-between">
          <p className="m-0 me-auto">
            <span className="text-bold">{translate("mSiteFonts.selectedFont", false, null)}: </span>
            <span
              className="fontSize12"
              style={{
                fontFamily: selectedFont === null ? sbCssVars[target === "headers" ? "fontHeadersName" : "fontBodyName"] : selectedFont.fontName,
                fontWeight: selectedFont === null ? sbCssVars[target === "headers" ? "fontHeadersName" : "fontBodyName"] : selectedFont.fontName,
              }}
            >
              {selectedFont === null ? sbCssVars[target === "headers" ? "fontHeadersName" : "fontBodyName"] : selectedFont.fontName} (
              {translate("mSiteFonts.weight", false, null)}:{" "}
              {
                FONT_WEIGHT_NAMES[
                  FONT_WEIGHT_CSS.indexOf(
                    selectedFont === null ? sbCssVars[target === "headers" ? "fontHeadersWeight" : "fontBodyWeight"] : selectedFont.fontWeight
                  )
                ]
              }
              )
            </span>
          </p>
          <button type="button" className="btn btn-midgray px-4" onClick={getFontsFromApi}>
            {translate("mSiteFonts.refreshFonts", false, null)}
          </button>
          <button type="button" className="btn btn-gray px-4" data-bs-dismiss="modal" onClick={closeModal}>
            {translate("mSiteFonts.close", false, null)}
          </button>
          <button type="button" className="btn btn-success px-4" data-bs-dismiss="modal" onClick={clickConfirmFont}>
            {translate("mSiteFonts.confirmFont", false, null)}
          </button>
        </div>
      </div>
    </div>
  );
};

SiteFonts.propTypes = {
  sb: PropTypes.object.isRequired,
  selectFont: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, { selectFont })(SiteFonts);
