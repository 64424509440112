import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadTemplate } from "../../actions/sb";
import { templates } from "../../lib/templates";
import { translate } from "../../translations/translations";
import { injectIframeJS } from "../../lib/domFunctions";

import { TEMPLATE_CATEGORIES } from "../../lib/templateVars";

const ModalBrowseTemplates = ({ auth: { langCode }, loadTemplate }) => {
  const [allTemplates, setAllTemplates] = useState(templates);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedTemplateId, setSelectedTemplateId] = useState("");

  // Pagination
  const PER_PAGE = 12;
  const [templatesToRender, setTemplatesToRender] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const clickPageBtn = (inc) => {
    setCurrPage((prev) => prev + inc);
  };

  useEffect(() => {
    const selectedTemplates = selectedCategory === "all" ? templates : templates.filter((template) => template.categories.includes(selectedCategory));
    const maxPagesSelectedTemplates = Math.ceil(selectedTemplates.length / PER_PAGE);
    setMaxPages(maxPagesSelectedTemplates);
    if (currPage > maxPagesSelectedTemplates) {
      setCurrPage(0);
    }
    setAllTemplates(selectedTemplates);
    setTemplatesToRender(selectedTemplates.slice(currPage * PER_PAGE, currPage * PER_PAGE + PER_PAGE));
    // eslint-disable-next-line
  }, [selectedCategory, currPage]);

  const clickTemplate = (templateId) => {
    if (selectedTemplateId === templateId) {
      setSelectedTemplateId("");
    } else {
      setSelectedTemplateId(templateId);
    }
  };

  const Template = ({ template }) => {
    return (
      <div
        className={`col-12 col-md-6 col-lg-3 p-3 card-browse-templates${selectedTemplateId === template.id ? " selected" : ""}`}
        onClick={() => clickTemplate(template.id)}
      >
        <div className="flexSameHeight">
          <img className="templatePreview" src={`/assets/templatePreviews/${template.id}.png`} alt={template.name[langCode]} />
          <div className="toGrow px-2">
            <h5>{template.name[langCode]}</h5>
            <p>{template.desc[langCode]}</p>
          </div>
        </div>
        <div className="card-browse-selecticon">
          <i className="fa-solid fa-check"></i>
        </div>
      </div>
    );
  };

  const Pagination = () => {
    return (
      <div className="d-flex justify-content-center align-items-center mt-4">
        <button
          className="btn btn-outline-primary trans-3 me-4"
          onClick={() => clickPageBtn(-1)}
          title={
            currPage === 0
              ? translate("mModalBrowseTemplates.lastTemplates", false, null)
              : translate("mModalBrowseTemplates.seeAddnlTemplates", false, null)
          }
          disabled={currPage === 0}
        >
          <i className="fa-solid fa-chevron-left"></i>
        </button>
        <span className="fontSize08 text-dark">
          {translate("mModalBrowseTemplates.showingTemplates", false, null)}{" "}
          <span className="text-bold">
            {Math.min(currPage * PER_PAGE + 1, allTemplates.length)} - {Math.min((currPage + 1) * PER_PAGE, allTemplates.length)}
          </span>{" "}
          {translate("mModalBrowseTemplates.of", false, null)} <span className="text-bold">{allTemplates.length}</span>
        </span>
        <button
          className="btn btn-outline-primary trans-3 ms-4"
          onClick={() => clickPageBtn(1)}
          title={
            currPage + 1 === maxPages
              ? translate("mModalBrowseTemplates.lastTemplates", false, null)
              : translate("mModalBrowseTemplates.seeAddnlTemplates", false, null)
          }
          disabled={currPage + 1 === maxPages}
        >
          <i className="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    );
  };

  const clickLoadTemplate = () => {
    loadTemplate(selectedTemplateId);
    resetStateVars();
    injectIframeJS();
  };

  const resetStateVars = () => {
    setSelectedCategory("all");
    setSelectedTemplateId("");
  };

  return (
    <div
      className="modal fade"
      id="ModalBrowseTemplates"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="ModalBrowseTemplatesLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" style={{ maxWidth: "90%" }}>
        <div className="modal-content" style={{ height: "90vh" }}>
          {/* Header */}
          <div className="modal-header">
            <h3 className="modal-title" id="ModalBrowseTemplatesLabel">
              {translate("mModalBrowseTemplates.browseTemplates", false, null)}
            </h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          {/* Body */}
          <div className="modal-body">
            {/* Select category & link to preview site */}
            <div className="row justify-content-between align-items-center">
              <div className="col-12 col-md-6 col-lg-3">
                <select className="form-select form-select-sm" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                  <option value="all">{translate("mModalBrowseTemplates.allCategories", false, null)}</option>
                  {TEMPLATE_CATEGORIES.map((cat) => (
                    <option key={cat.val} value={cat.val}>
                      {cat.label[langCode]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-6 col-lg-3 text-end">
                <a href={`/templates/browse/${langCode}`} target="_blank" rel="noreferrer" className="btn btn-sm btn-gray px-4 rounded-3">
                  {translate("mModalBrowseTemplates.previewAllTemplates", false, null)} <i className="ms-2 fa-solid fa-right-from-bracket"></i>
                </a>
              </div>
            </div>
            {/* Browse templates */}
            <div className="row">
              {allTemplates.length === 0 ? (
                <p className="text-italic my-3">{translate("mModalBrowseTemplates.noTemplatesFound", false, null)}</p>
              ) : (
                templatesToRender.map((template) => <Template key={template.id} template={template} />)
              )}
            </div>
            {/* Pagination */}
            <Pagination />
          </div>
          {/* Footer */}
          <div className="modal-footer">
            <button type="button" className="btn btn-gray" data-bs-dismiss="modal">
              {translate("mModalBrowseTemplates.close", false, null)}
            </button>
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              disabled={selectedTemplateId === ""}
              onClick={clickLoadTemplate}
            >
              {translate("mModalBrowseTemplates.loadSelectedTemplate", false, null)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalBrowseTemplates.propTypes = {
  auth: PropTypes.object.isRequired,
  loadTemplate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { loadTemplate })(ModalBrowseTemplates);
