import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fromEvent } from "file-selector";

import { translate } from "../../../translations/translations";

const ImgGalleryUpload = ({ setLocalLoading, makeAlert, uploadToBackend }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesToRender, setFilesToRender] = useState([]);
  const fileInputRef = useRef();

  useEffect(() => {
    // Turn selectedFiles array into an array of items to be rendered inside the drag/drop container
    // filesToRender should only show 1 folder for any file that has the same top level parent folder
    let arrToRender = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      let obj = {
        type: "file",
        name: selectedFiles[i].name,
      };
      arrToRender.push(obj);
    }
    setFilesToRender(arrToRender);
    // eslint-disable-next-line
  }, [selectedFiles]);

  const clickUpload = async () => {
    setLocalLoading({ isLoading: true, msg: translate("mImgGalleryUpload.uploadingFiles", false, null) });
    if (selectedFiles.length === 0) {
      makeAlert(translate("mImgGalleryUpload.addFiles", false, null), "danger");
    } else {
      try {
        await uploadToBackend(selectedFiles);
        makeAlert(translate("mImgGalleryUpload.uploadSuccess", false, null), "success");
      } catch (error) {
        console.error(error);
        makeAlert(translate("mImgGalleryUpload.serverErrorUploading", false, null), "danger");
      }
    }
    setLocalLoading({ isLoading: false, msg: "" });
    setSelectedFiles([]);
  };

  const clearFiles = () => {
    setSelectedFiles([]);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = async (e) => {
    e.preventDefault();
    const files = await fromEvent(e);
    if (files.length > 0) {
      handleFiles(files);
    }
  };

  const handleFiles = (files) => {
    setSelectedFiles((prevArray) => [...prevArray, ...files.filter((file) => acceptFile(file))]);
  };

  const MIME_TYPES = ["image/bmp", "image/gif", "image/jpeg", "image/png", "image/svg", "image/tiff"];
  const acceptFile = (file) => {
    return MIME_TYPES.includes(file.type) && selectedFiles.filter((existingFile) => existingFile.name === file.name).length === 0;
  };

  const fileInputClicked = (e) => {
    // If the click was on a button, don't open the file prompt
    if (!e.target.classList.contains("fileDnD_btn")) {
      fileInputRef.current.click();
    }
  };

  const filesSelected = async (e) => {
    const files = await fromEvent(e);
    if (files.length > 0) {
      handleFiles(files);
    }
  };

  const removeItemsFromSelection = (itemToRemove) => {
    // items = an array of {type: "file" or "folder", name: "name"} from filedToRender state var
    setSelectedFiles((prevArray) => prevArray.filter((file) => file.path !== itemToRemove.name));
  };

  return (
    <>
      <h4 className="text-primary mt-5 mb-3">{translate("mImgGalleryUpload.uploadMoreImgs", false, null)}</h4>
      <div
        className="posRelative d-flex justify-content-center align-items-center py-4 bg-light border border-gray rounded-3 cursorPointer"
        style={{ minHeight: "200px" }}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
      >
        {filesToRender.length === 0 ? (
          <div className="text-center text-primary">
            <i className="fas fa-upload fontSize20 mb-3" />
            <p className="m-0">{translate("mImgGalleryUpload.browseFiles", false, null)}</p>
          </div>
        ) : (
          <>
            <span
              className="z-99 posAbs posTop posRight mt-1 me-2 cursorPointer text-danger"
              title={translate("mImgGalleryUpload.clearAll", false, null)}
              onClick={clearFiles}
            >
              <i className="fas fa-times fileDnD_btn" />
            </span>
            <div className="row m-0 p-0 h-100 w-100">
              {filesToRender.map((file, i) => (
                <div key={`${file.name}${i}`} className="col-3 col-md-2 col-lg-1 p-2 text-center posRelative">
                  <span
                    className="z-99 posAbs posTop posRight me-2 cursorPointer text-danger fontSize09"
                    title={translate("mImgGalleryUpload.removeFromSelection", false, null)}
                    onClick={() => removeItemsFromSelection(file)}
                  >
                    <i className="fas fa-times fileDnD_btn"></i>
                  </span>
                  <i className={`far fa-${file.type} fontSize15`}></i>
                  {file.name.length > 20 ? (
                    <p className="m-0 fontSize08 text-break" title={file.name}>
                      {file.name.substring(0, 20)}...
                    </p>
                  ) : (
                    <p className="m-0 fontSize08 text-break" title={file.name}>
                      {file.name}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
        <input ref={fileInputRef} className="d-none" type="file" multiple onChange={filesSelected} />
      </div>
      <button className="btn btn-success mt-3 px-4" disabled={selectedFiles.length === 0} onClick={clickUpload}>
        {translate("mImgGalleryUpload.uploadImgs", false, null)}
      </button>
    </>
  );
};

ImgGalleryUpload.propTypes = {
  sb: PropTypes.object.isRequired,
  setLocalLoading: PropTypes.func.isRequired,
  makeAlert: PropTypes.func.isRequired,
  uploadToBackend: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(ImgGalleryUpload);
