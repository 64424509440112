export const template29 = {
  id: "66004db37045e61d63d9fe3b",
  name: { en: "Download an e-book", nl: "Download een e-book" },
  desc: { en: "Download an e-book", nl: "Download een e-book" },
  categories: ["downloadResource"],
  sbPages: [
    {
      pageId: "63aec8dc-7df2-4384-802a-00dcdb09fc1e",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "Ea0LTR",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-3-component-Ea0LTR"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "wIGLqu",
              type: "",
              htmlTagName: "img",
              classes: ["basics-3-img-Ea0LTR"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "img" },
                { property: "src", value: "https://cdn.satonda.com/eb/assets/logo5.png" },
                { property: "alt", value: "" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "I6y75l",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-I6y75l"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "f19ttm",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-I6y75l", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "x3gT6Z",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "forms-5-component-x3gT6Z"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "SEzvUm",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "J0NPBo",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "order-first", "p-3", "forms-5-imgwrapper1"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "MylcoU",
                      type: "",
                      htmlTagName: "div",
                      classes: ["forms-5-imgwrapper2-x3gT6Z"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "pUgJFK",
                          type: "",
                          htmlTagName: "img",
                          classes: ["forms-5-img1-x3gT6Z"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/book2.jpg" },
                            { property: "alt", value: "Image" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "QYHFCW",
                          type: "",
                          htmlTagName: "img",
                          classes: ["forms-5-img2-x3gT6Z"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/book1.jpg" },
                            { property: "alt", value: "Image" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "FfPrgz",
                  type: "",
                  htmlTagName: "div",
                  classes: ["forms-5-contentcol-x3gT6Z", "col-12", "col-md-6", "col-lg-5", "p-3"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "WmspuK",
                      type: "",
                      htmlTagName: "div",
                      classes: ["row", "justify-content-center", "align-items-stretch"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "THpxdJ",
                          type: "",
                          htmlTagName: "div",
                          classes: ["col-12"],
                          styles: [],
                          attributes: [
                            { property: "data-sbform", value: "true" },
                            { property: "data-sbformaction", value: "" },
                            { property: "data-sbformmsg", value: "Your message has been received!" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "OMAh9F",
                              type: "",
                              htmlTagName: "h2",
                              classes: ["forms-5-title-x3gT6Z", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Get your copy now!",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "HTrQgz",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "forms-5-inputwrapper-x3gT6Z"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "Tpx32h",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "forms-5-label-x3gT6Z"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your name",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "OjuiKj",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "forms-5-inputfield-x3gT6Z"],
                                  styles: [],
                                  attributes: [
                                    { property: "name", value: "name" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                    { property: "type", value: "text" },
                                    { property: "placeholder", value: "Name" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "gos5tR",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "forms-5-inputwrapper-x3gT6Z"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "qckBJd",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "forms-5-label-x3gT6Z"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your email address",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "FsKusX",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "forms-5-inputfield-x3gT6Z"],
                                  styles: [],
                                  attributes: [
                                    { property: "type", value: "email" },
                                    { property: "name", value: "email" },
                                    { property: "placeholder", value: "Email address" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "NAdAVJ",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "forms-5-inputwrapper-x3gT6Z"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "VzVGPC",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["form-check", "forms-5-check-x3gT6Z"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-formcheckwrapper", value: "true" },
                                    { property: "name", value: "subscribe" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "YdC1Fn",
                                      type: "",
                                      htmlTagName: "input",
                                      classes: ["form-check-input"],
                                      styles: [],
                                      attributes: [
                                        { property: "type", value: "checkbox" },
                                        { property: "data-checkparent", value: "true" },
                                        { property: "checked", value: "true" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                    {
                                      childId: "SkqKCr",
                                      type: "",
                                      htmlTagName: "label",
                                      classes: ["form-check-label", "forms-5-label-x3gT6Z"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "inputlabel" },
                                        { property: "data-editable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Subscribe to our newsletter",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "ylcxqx",
                              type: "",
                              htmlTagName: "div",
                              classes: ["forms-5-button-x3gT6Zwrapper-x3gT6Z"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "ShXbSt",
                                  type: "",
                                  htmlTagName: "button",
                                  classes: ["forms-5-button-x3gT6Z"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "button" },
                                    { property: "data-sbformbtn", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Download copy!",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "TIM8OJ",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "footer-6-component-TIM8OJ"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "ImfPLz",
              type: "block",
              htmlTagName: "div",
              classes: ["d-flex", "flex-wrap", "justify-content-center", "align-items-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "bvSuMv",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-TIM8OJ"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "UIyCFf",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-solid", "fa-envelope"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "gUjsT1",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-TIM8OJ"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "DLrLUT",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-brands", "fa-twitter"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "XxgJf5",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-TIM8OJ"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "OwadWr",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-brands", "fa-linkedin"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "gS1esy",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-TIM8OJ"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "WPf347",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              childId: "aH5RIY",
              type: "",
              htmlTagName: "p",
              classes: ["footer-6-text-TIM8OJ", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "© Copyright Company name. All rights reserved.",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  sbCustomCss: [
    {
      componentId: "x3gT6Z",
      classes: [
        {
          className: "section.forms-5-component-x3gT6Z",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "2rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.forms-5-component-x3gT6Z", pseudo: "hover", rules: [] },
        {
          className: "forms-5-contentcol-x3gT6Z",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "forms-5-imgwrapper2-x3gT6Z",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "width", value: "100%" },
            { property: "aspect-ratio", value: "0.65" },
          ],
        },
        { className: "forms-5-imgwrapper2-x3gT6Z", pseudo: "hover", rules: [] },
        {
          className: "forms-5-img1-x3gT6Z",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "right", value: "0" },
            { property: "width", value: "70%" },
            { property: "aspect-ratio", value: "0.8" },
            { property: "object-fit", value: "cover" },
            { property: "transform", value: "scale(1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "3px" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
        { className: "forms-5-img1-x3gT6Z", pseudo: "hover", rules: [] },
        {
          className: "forms-5-img2-x3gT6Z",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "width", value: "70%" },
            { property: "aspect-ratio", value: "0.8" },
            { property: "object-fit", value: "cover" },
            { property: "transform", value: "scale(1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "3px" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
        { className: "forms-5-img2-x3gT6Z", pseudo: "hover", rules: [] },
        {
          className: "forms-5-title-x3gT6Z",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(245, 82, 0, 1)" },
            { property: "margin", value: "0 0 3rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "forms-5-title-x3gT6Z", pseudo: "hover", rules: [] },
        {
          className: "forms-5-button-x3gT6Zwrapper-x3gT6Z",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "forms-5-button-x3gT6Zwrapper-x3gT6Z", pseudo: "hover", rules: [] },
        {
          className: "forms-5-label-x3gT6Z",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "forms-5-label-x3gT6Z", pseudo: "hover", rules: [] },
        { className: "forms-5-check-x3gT6Z", pseudo: "", rules: [] },
        { className: "forms-5-check-x3gT6Z", pseudo: "hover", rules: [] },
        { className: "forms-5-check-x3gT6Z > label", pseudo: "", rules: [{ property: "display", value: "inline-block !important" }] },
        {
          className: "forms-5-button-x3gT6Z",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(245, 82, 0, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(245, 82, 0, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "forms-5-button-x3gT6Z",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(245, 82, 0, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "forms-5-inputfield-x3gT6Z",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1rem" },
            { property: "outline", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(245, 82, 0, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(245, 82, 0, 0)" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.2s ease-in-out" },
          ],
        },
        {
          className: "forms-5-inputfield-x3gT6Z",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(245, 82, 0, 1)" },
            { property: "border-color", value: "rgba(245, 82, 0, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(245, 82, 0, 0.2)" },
          ],
        },
        {
          className: "forms-5-inputfield-x3gT6Z",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "forms-5-inputfield-x3gT6Z", pseudo: "hover", rules: [] },
        { className: "forms-5-inputwrapper-x3gT6Z", pseudo: "", rules: [{ property: "margin", value: "0rem 0rem 1.5rem 0rem" }] },
      ],
    },
    {
      componentId: "Ea0LTR",
      classes: [
        {
          className: "section.basics-3-component-Ea0LTR",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", pseudo: "", value: "rgba(234, 237, 240, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(234, 237, 240, 1)" },
          ],
        },
        { className: "section.basics-3-component-Ea0LTR", pseudo: "hover", rules: [] },
        {
          className: "basics-3-component-Ea0LTR",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-3-component-Ea0LTR", pseudo: "hover", rules: [] },
        {
          className: "basics-3-img-Ea0LTR",
          pseudo: "",
          rules: [
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "50px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(245, 82, 0, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-self", value: "start" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-3-img-Ea0LTR",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "I6y75l",
      classes: [
        {
          className: "section.basics-2-component-I6y75l",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-2-component-I6y75l", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-I6y75l",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-I6y75l", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-I6y75l",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(245, 82, 0, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-I6y75l", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "TIM8OJ",
      classes: [
        {
          className: "section.footer-6-component-TIM8OJ",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "2rem" },
            { property: "background", pseudo: "", value: "rgba(245, 126, 0, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(245, 126, 0, 1)" },
          ],
        },
        { className: "section.footer-6-component-TIM8OJ", pseudo: "hover", rules: [] },
        {
          className: "footer-6-component-TIM8OJ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "footer-6-component-TIM8OJ", pseudo: "hover", rules: [] },
        {
          className: "footer-6-social-link-TIM8OJ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "height", value: "45px" },
            { property: "aspect-ratio", value: "1" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(245, 126, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "font-size", value: "1.2rem" },
          ],
        },
        {
          className: "footer-6-social-link-TIM8OJ",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(245, 82, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "footer-6-social-link-TIM8OJ", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-6-text-TIM8OJ",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(233, 236, 239, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "2rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-6-text-TIM8OJ", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Quicksand:300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Poppins:100,300,700&display=swap",
    fontHeadersName: "Quicksand",
    fontHeadersWeight: "700",
    fontBodyName: "Poppins",
    fontBodyWeight: "400",
    color1: "rgba(245, 126, 0, 1)",
    color2: "rgba(245, 82, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
