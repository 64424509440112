export const footer3 = {
  id: "footer-3",
  name: "Footer 3",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "footer-3-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "h1",
        classes: ["footer-3-title", "w-100", "w-md-100", "w-lg-100"],
        styles: [],
        attributes: [
          { property: "data-editable", value: "true" },
          { property: "data-texteditable", value: "true" },
          { property: "data-name", value: "h" },
        ],
        content: "",
        children: [
          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Company name", children: [] },
        ],
      },
      {
        childId: "",
        type: "",
        htmlTagName: "p",
        classes: ["footer-3-text1", "w-100", "w-md-100", "w-lg-100"],
        styles: [],
        attributes: [
          { property: "data-editable", value: "true" },
          { property: "data-texteditable", value: "true" },
          { property: "data-name", value: "p" },
        ],
        content: "",
        children: [
          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Streetname number", children: [] },
        ],
      },
      {
        childId: "",
        type: "",
        htmlTagName: "p",
        classes: ["footer-3-text2", "w-100", "w-md-100", "w-lg-100"],
        styles: [],
        attributes: [
          { property: "data-editable", value: "true" },
          { property: "data-texteditable", value: "true" },
          { property: "data-name", value: "p" },
        ],
        content: "",
        children: [
          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Postal code, city", children: [] },
        ],
      },
      {
        childId: "",
        type: "",
        htmlTagName: "p",
        classes: ["footer-3-text3", "w-100", "w-md-100", "w-lg-100"],
        styles: [],
        attributes: [
          { property: "data-editable", value: "true" },
          { property: "data-texteditable", value: "true" },
          { property: "data-name", value: "p" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "textNode",
            classes: [],
            styles: [],
            attributes: [],
            content: "© Copyright Company name. All rights reserved.",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.footer-3-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.footer-3-component", pseudo: "hover", rules: [] },
      {
        className: "footer-3-component",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "footer-3-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-3-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 3rem 0" },
          { property: "font-size", value: "48px" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "footer-3-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-3-text1",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.25rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "footer-3-text1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-3-text2",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 3rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "footer-3-text2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-3-text3",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 1rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "footer-3-text3",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
