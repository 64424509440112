export const sectiondividers45 = {
  id: "sectiondividers-45",
  name: "Section dividers 45",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "svg",
        classes: [],
        styles: [
          { property: "transform", value: "scaleY(-1)" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
        ],
        attributes: [
          { property: "xmlns", value: "http://www.w3.org/2000/svg" },
          { property: "width", value: "100%" },
          { property: "height", value: "200px" },
          { property: "viewBox", value: "0 0 1000 279.24" },
          { property: "preserveAspectRatio", value: "none" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              { property: "d", value: "M1000 0S331.54-4.18 0 279.24h1000z" },
              { property: "opacity", value: "0.25" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              { property: "d", value: "M1000 279.24s-339.56-44.3-522.95-109.6S132.86 23.76 0 25.15v254.09z" },
            ],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
