export const clients2 = {
  id: "clients-2",
  name: "Clients 2",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "clients-2-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "align-items-center", "justify-content-center", "clients-2-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["clients-2-logo"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                  { property: "alt", value: "logo" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                ],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["clients-2-logo"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                  { property: "alt", value: "logo" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                ],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["clients-2-logo"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/client-3.png" },
                  { property: "alt", value: "logo" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                ],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["clients-2-logo"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/client-4.png" },
                  { property: "alt", value: "logo" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                ],
                content: "",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.clients-2-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.clients-2-component", pseudo: "hover", rules: [] },
      {
        className: "clients-2-wrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgb(194, 197, 199)" },
          { property: "height", value: "75px" },
        ],
      },
      {
        className: "clients-2-logo",
        pseudo: "",
        rules: [
          { property: "max-height", value: "50px" },
          { property: "max-width", value: "100px" },
          { property: "height", value: "auto" },
          { property: "width", value: "auto" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      { className: "clients-2-logo", pseudo: "hover", rules: [{ property: "transform", value: "scale(1.15)" }] },
    ],
  },
};
