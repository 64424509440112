export const clients4 = {
  id: "clients-4",
  name: "Clients 4",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "clients-4-component"],
    styles: [],
    attributes: [
      { property: "data-name", value: "carousel" },
      { property: "data-editable", value: "true" },
    ],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["splide", "carousel-autoscroll", "clients-4-splide"],
        styles: [],
        attributes: [
          { property: "aria-label", value: "Carousel" },
          {
            property: "data-splide",
            value:
              '{"perPage":1,"perMove":1,"type":"loop","fixedHeight":"80px","fixedWidth":"auto","gap":80,"drag":"free","focus":"center","arrows":false,"pagination":false,"autoScroll":{"speed":2},"interval":2000,"pauseOnHover":true,"rewindSpeed":1000,"speed":500,"autoplay":false,"easing":"linear","keyboard":true,"breakpoints":{"640":{"perPage":1,"perMove":1}},"classes":{"arrow":"splide__arrow clients-4-arrow","prev":"splide__arrow--prev clients-4-prev","next":"splide__arrow--next clients-4-next","page":"splide__pagination__page clients-4-pagination"}}',
          },
          { property: "data-carousel-slidesetup", value: "image" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["splide__track"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["splide__list"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "clients-4-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["clients-4-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "clients-4-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["clients-4-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "clients-4-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["clients-4-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/client-3.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "clients-4-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["clients-4-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/client-4.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "clients-4-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["clients-4-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/client-5.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "clients-4-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["clients-4-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/client-6.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "clients-4-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["clients-4-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/client-7.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "clients-4-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["clients-4-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/client-8.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "imgSrcOnly" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.clients-4-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.clients-4-component", pseudo: "hover", rules: [] },
      {
        className: "clients-4-slide",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
        ],
      },
      {
        className: "clients-4-img",
        pseudo: "",
        rules: [
          { property: "height", value: "100%" },
          { property: "width", value: "auto" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "clients-4-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "clients-4-arrow",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.5)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-radius", value: "50%" },
          { property: "height", value: "2rem" },
          { property: "width", value: "2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "clients-4-arrow",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "clients-4-arrow svg",
        pseudo: "",
        rules: [
          { property: "fill", value: "rgba(0, 0, 0, 0.7)" },
          { property: "height", value: "1.2rem" }, // (size - 0.8)rem
          { property: "width", value: "1.2rem" }, // (size - 0.8)rem
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "clients-4-arrow svg",
        pseudo: "hover",
        rules: [{ property: "fill", value: "rgba(0, 0, 0, 1)" }],
      },
      {
        className: "clients-4-prev",
        pseudo: "",
        rules: [
          { property: "left", value: "1rem" }, // Inside: 1rem || Outside: -(size + 1)rem
        ],
      },
      {
        className: "clients-4-next",
        pseudo: "",
        rules: [
          { property: "right", value: "1rem" }, // Inside: 1rem, outside: -(size+1)rem
        ],
      },
      {
        className: "clients-4-pagination",
        pseudo: "",
        rules: [
          { property: "height", value: "0.7rem" },
          { property: "width", value: "0.7rem" },
          { property: "background", value: "rgba(255, 255, 255, 0.5)" },
          { property: "margin", value: "0.25rem" },
          { property: "border-radius", value: "50%" },
          { property: "display", value: "inline-block" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "clients-4-pagination",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.8)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scale(1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "is-active.clients-4-pagination",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.8)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scale(1)" },
          { property: "opacity", value: "1" },
        ],
      },
    ],
  },
};
