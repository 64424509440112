export const contactus5 = {
  id: "contactus-5",
  name: "Contact us 5",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "contactus-5-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["contactus-5-googlemaps-wrapper"],
        styles: [],
        attributes: [
          { property: "data-name", value: "googlemaps_wrapper" },
          { property: "data-editable", value: "true" },
        ],
        content: "",
        children: [],
      },
      {
        childId: "",
        type: "",
        htmlTagName: "iframe",
        classes: [],
        styles: [],
        attributes: [
          { property: "data-editable", value: "true" },
          { property: "data-name", value: "googlemaps" },
          {
            property: "src",
            value:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8680.352904042613!2d4.899406522082844!3d52.37329199453203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609b70dd81623%3A0xcae71b8d3adfd142!2sAmsterdam%20Centraal!5e0!3m2!1sen!2snl!4v1662818615871!5m2!1sen!2snl",
          },
          { property: "scrolling", value: "no" },
          { property: "loading", value: "lazy" },
          { property: "referrerpolicy", value: "no-referrer-when-downgrade" },
          { property: "width", value: "100%" },
          { property: "height", value: "100%" },
          { property: "frameborder", value: "0" },
        ],
        content: "",
        children: [],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.contactus-5-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "section.contactus-5-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "contactus-5-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
          { property: "overflow", value: "hidden" },
          { property: "height", value: "50vh" },
          { property: "position", value: "relative" },
        ],
      },
      {
        className: "contactus-5-googlemaps-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "bottom", value: "0" },
        ],
      },
    ],
  },
};
