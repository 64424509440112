import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import BuilderArea from "../components/layout/BuilderArea";
import EditPane from "../components/layout/EditPane";

import ModalPageOptions from "../components/modals/ModalPageOptions";
import ModalColorPicker from "../components/modals/ModalColorPicker";
import ModalColorSchemeGenerator from "../components/modals/ModalColorSchemeGenerator";
import ModalSiteFontsHeaders from "../components/modals/ModalSiteFontsHeaders";
import ModalSiteFontsBody from "../components/modals/ModalSiteFontsBody";
import ModalSelectIcon from "../components/modals/ModalSelectIcon";
import ModalBrowseTemplates from "../components/modals/ModalBrowseTemplates";
import ModalImageGallery from "../components/modals/ModalImageGallery";
import ModalSelectImgFromGallery from "../components/modals/ModalSelectImgFromGallery";

import Spinner from "../components/layout/Spinner";

import { loadProject } from "../actions/sb";
import { authError } from "../actions/auth";
import { translate } from "../translations/translations";

const Main = ({ auth: { user, workspace, loading }, globalLoading: { globalLoading }, sb: { project }, loadProject, authError }) => {
  let { projectId } = useParams();
  useEffect(() => {
    if (!loading) {
      // If authentication is done
      if (user !== null && workspace !== null && projectId !== "") {
        loadProject(projectId);
      } else {
        authError();
      }
    }
    // eslint-disable-next-line
  }, [user, workspace, projectId]);

  return globalLoading.length > 0 ? (
    <Spinner fullPage={true} msg={globalLoading[0].msg} />
  ) : project === null ? (
    <div className="vh-100 vw-100 d-flex flex-column justify-content-center align-items-center">
      {translate("pMain.errorLoadingProject", false, null)}
    </div>
  ) : (
    <>
      <div className="d-block d-lg-none mt-4 text-center text-italic">{translate("pMain.useLargerScreenSize", false, null)}</div>
      <div className="d-none d-lg-block" data-tour="full-page">
        <div className="container-fluid" data-tour="tour-end">
          <div className="row">
            <div className="col-8 p-3 d-flex flex-column align-items-center" style={{ height: "100vh" }}>
              <BuilderArea />
            </div>
            <div className="col-4 p-0 editPane posFixed posTop posRight h-100 editPane" data-tour="sb-editor-menu">
              <EditPane />
            </div>
          </div>
        </div>
        {/* Modals */}
        <ModalPageOptions />
        <ModalColorPicker />
        <ModalColorSchemeGenerator />
        <ModalSiteFontsHeaders />
        <ModalSiteFontsBody />
        <ModalSelectIcon />
        <ModalBrowseTemplates />
        <ModalImageGallery />
        <ModalSelectImgFromGallery />
      </div>
    </>
  );
};

Main.propTypes = {
  auth: PropTypes.object.isRequired,
  sb: PropTypes.object.isRequired,
  globalLoading: PropTypes.object.isRequired,
  loadProject: PropTypes.func.isRequired,
  authError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sb: state.sb,
  globalLoading: state.globalLoading,
});

export default connect(mapStateToProps, { loadProject, authError })(Main);
