import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Border from "./Border";
import Margin from "./Margin";
import Padding from "./Padding";
import AlignCol from "./AlignCol";
import AlignRow from "./AlignRow";
import ResponsiveLayout from "./ResponsiveLayout";
import BorderRadius from "./BorderRadius";
import BoxShadow from "./BoxShadow";
import Transition from "./Transition";
import Divider from "./formComponents/Divider";
import ImgSource from "./ImgSource";
import ColorPicker from "./formComponents/ColorPicker";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const Card23 = ({ sb: { startStyles } }) => {
  return (
    <>
      {/* Layout */}
      <ResponsiveLayout />
      <Divider />
      <AlignCol />
      <Divider />
      <AlignRow />
      <Divider />
      <Padding />
      <Divider />
      <Margin />
      <Divider />
      {/* Style */}
      <Border />
      <Divider />
      <BorderRadius />
      <Divider />
      <BoxShadow />
      <Divider />
      {/* Component */}
      <ImgSource />
      <Divider />
      <Transition />
      <Divider />
      <ColorPicker
        label={translate("cEditForms.card23_overlayColor", false, null)}
        id={"card23_overlayGradient"}
        startValue={formGetStartValue(startStyles, "card23_overlayGradient")}
        tooltipText={translate("cEditForms.card23_tooltipOverlayColor", false, null)}
      />
    </>
  );
};

Card23.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Card23);
