export const template17 = {
  id: "65fde8e9b35abe52ab806284",
  name: { en: "Yoga studio", nl: "Yoga studio" },
  desc: { en: "Website for a yoga studio", nl: "Een website voor een yoga studio" },
  categories: ["beauty", "hobby"],
  sbPages: [
    {
      pageId: "L4RXGEfLAe",
      version: "A",
      pageLink: "index",
      pageTitle: "Studio Namaste",
      pageDesc: "Studio Namaste",
      pagePreview: "",
      components: [
        {
          componentId: "fhXTxL",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-3-wrapper-fhXTxL", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "UINxGT",
              type: "",
              htmlTagName: "div",
              classes: ["container", "navbar-3-borderwrapper-fhXTxL"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "IKD0gI",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "nn5cGs",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-3-logo-fhXTxL"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-studio-namaste-gray.png" },
                        { property: "alt", value: "Logo" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "Nxm5KF",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-3-toggler-fhXTxL", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                    { property: "type", value: "button" },
                    { property: "aria-expanded", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ijEOxB",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "JtDygR",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse", "navbar-3-collapse-fhXTxL"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "WXpzC6",
                      type: "",
                      htmlTagName: "div",
                      classes: ["d-none", "d-lg-block", "navbar-3-inside-line-fhXTxL"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                    {
                      childId: "IpYgXq",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "K5XicD",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-3-link-fhXTxL"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#about" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "About",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "qBZAtn",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-3-link-fhXTxL"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#classes" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Classes",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "D3Zr7z",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-3-link-fhXTxL"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#contact" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "lM1jFp",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "section-divider-lM1jFp"],
          styles: [],
          attributes: [{ property: "data-name", value: "section_divider" }],
          content: "",
          children: [
            {
              childId: "HeGXXH",
              type: "",
              htmlTagName: "svg",
              classes: [],
              styles: [{ property: "background", value: "rgba(255, 255, 255, 1)" }],
              attributes: [
                { property: "xmlns", value: "http://www.w3.org/2000/svg" },
                { property: "version", value: "1.0" },
                { property: "width", value: "100%" },
                { property: "height", value: "140" },
                { property: "viewBox", value: "0 0 1280 140" },
                { property: "preserveAspectRatio", value: "none" },
              ],
              content: "",
              children: [
                {
                  childId: "PCJQVe",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(204, 234, 228, 1)" },
                    { property: "d", value: "M0 51.76c36.21-2.25 77.57-3.58 126.42-3.58 320 0 320 57 640 57 271.15 0 312.58-40.91 513.58-53.4V0H0z" },
                    { property: "opacity", value: "0.3" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "Za43vg",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(204, 234, 228, 1)" },
                    {
                      property: "d",
                      value: "M0 24.31c43.46-5.69 94.56-9.25 158.42-9.25 320 0 320 89.24 640 89.24 256.13 0 307.28-57.16 481.58-80V0H0z",
                    },
                    { property: "opacity", value: "0.5" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "tHQEAi",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(204, 234, 228, 1)" },
                    { property: "d", value: "M0 0v3.4C28.2 1.6 59.4.59 94.42.59c320 0 320 84.3 640 84.3 285 0 316.17-66.85 545.58-81.49V0z" },
                  ],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "UKfZtp",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-UKfZtp"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "c3slUn",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-UKfZtp", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Find your zen", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "ZmS4vV",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-ZmS4vV"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "E878EU",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-ZmS4vV", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores.",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "Ca3zWk",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-7-component-Ca3zWk"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Fi1ubI",
              type: "",
              htmlTagName: "a",
              classes: ["basics-7-link-readmore-Ca3zWk"],
              styles: [],
              attributes: [
                { property: "href", value: "#!" },
                { property: "data-target", value: "_self" },
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "button" },
                { property: "data-href", value: "/index.html#classes" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Explore classes", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "R1zbya",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-2-component-R1zbya"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "klX024",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-2-wrapper-R1zbya"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "gahGbH",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-2-content-R1zbya"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "zo1XkN",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-5-component-zo1XkN"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "NjHsIs",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-5-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "EuCIoO",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-first", "basics-5-col-zo1XkN"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "l9Axff",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-5-img-zo1XkN"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/yoga2.jpg" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "saUFjU",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-5-col-zo1XkN"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "dSJYeD",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["basics-5-title-zo1XkN", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Unwind at Studio Namaste",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "wRoLbQ",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-5-text-zo1XkN", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "nYY8uU",
                      type: "",
                      htmlTagName: "p",
                      classes: ["nJEfyZixRbL0shqV96rWDaqw1KvUyr", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "span",
                          classes: [],
                          styles: [{ property: "color", value: "rgb(183,200,187)" }],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "em",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: " ", children: [] },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Xh8MtE",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-5-component-Xh8MtE"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "k99RWE",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-5-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "gxYuHD",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "basics-5-col-Xh8MtE"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "FhrY0Y",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-5-img-Xh8MtE"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/yoga7.jpg" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "cjYxSp",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-5-col-Xh8MtE"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "YTNZak",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["basics-5-title-Xh8MtE", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Our classes",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "F8ycRF",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-5-text-Xh8MtE", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "strong",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Vinyasa yoga",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: ": Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "qWewiJ",
                      type: "",
                      htmlTagName: "p",
                      classes: ["rV4YK0h6C0I9QUddKaktEaq6Vn1gV2", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "span",
                          classes: [],
                          styles: [{ property: "color", value: "rgb(72,177,156)" }],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "strong",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Hatha yoga",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: ": Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                              children: [],
                            },
                          ],
                        },
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: " ", children: [] },
                      ],
                    },
                    {
                      childId: "VNnNN4",
                      type: "",
                      htmlTagName: "p",
                      classes: ["zqaKQxo8fLd7ZXiN20Nuh6YqZNLyka", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "span",
                          classes: [],
                          styles: [{ property: "color", value: "rgb(72,177,156)" }],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "strong",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Yin yoga",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: ": Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                              children: [],
                            },
                          ],
                        },
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: " ", children: [] },
                      ],
                    },
                    {
                      childId: "EIYrXN",
                      type: "",
                      htmlTagName: "p",
                      classes: ["TYJo6DiC2a8moUOIRmuXfNsHDO2MAC", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "span",
                          classes: [],
                          styles: [{ property: "color", value: "rgb(72,177,156)" }],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "strong",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Restorative yoga",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: ": Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                              children: [],
                            },
                          ],
                        },
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: " ", children: [] },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Op7I1Q",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-Op7I1Q"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "uKUzTG",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-Op7I1Q", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "What our regulars say",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "sN5wFE",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "quote-13-component-sN5wFE"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "GrmkoA",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "quote-13-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "plgdWc",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-13-col-sN5wFE"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "h5O6eq",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-13-wrapper-sN5wFE"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "INYS9Y",
                          type: "",
                          htmlTagName: "span",
                          classes: ["quote-13-quote-sN5wFEicon-sN5wFE"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "span" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "dST6bW",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-quote-left"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "vMBUZU",
                          type: "",
                          htmlTagName: "p",
                          classes: ["quote-13-text-sN5wFE", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "QhvnrF",
                          type: "",
                          htmlTagName: "p",
                          classes: ["quote-13-quote-sN5wFE"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Sandra, 33",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "OaTLGn",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-13-col-sN5wFE"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "UF0Hp8",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-13-wrapper-sN5wFE"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "MpRwQP",
                          type: "",
                          htmlTagName: "span",
                          classes: ["quote-13-quote-sN5wFEicon-sN5wFE"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "span" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "hy5fDW",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-quote-left"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "PmEe17",
                          type: "",
                          htmlTagName: "p",
                          classes: ["quote-13-text-sN5wFE", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "WSzHqR",
                          type: "",
                          htmlTagName: "p",
                          classes: ["quote-13-quote-sN5wFE"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "e10WQv",
                              type: "",
                              htmlTagName: "em",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Jenny, 41",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "J3CnCg",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-13-col-sN5wFE"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "eu7ZRr",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-13-wrapper-sN5wFE"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "kMAFgN",
                          type: "",
                          htmlTagName: "span",
                          classes: ["quote-13-quote-sN5wFEicon-sN5wFE"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "span" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "gNUxEi",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-quote-left"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "njxT2l",
                          type: "",
                          htmlTagName: "p",
                          classes: ["quote-13-text-sN5wFE", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "h3fkjC",
                          type: "",
                          htmlTagName: "p",
                          classes: ["quote-13-quote-sN5wFE"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "Z4mG93",
                              type: "",
                              htmlTagName: "em",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Jerome, 39",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "wi6t7n",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-4-component-wi6t7n"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "contact" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "deuML0",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "eZ24Xh",
                  type: "block",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "order-1", "order-md-1", "contactus-4-col-wi6t7n"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "block" }],
                  content: "",
                  children: [
                    {
                      childId: "eeQgDl",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-4-col-wi6t7numn-title"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Studio Namaste",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "Pf9Zix",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-4-contact-info-block-wi6t7n"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "BgcyD6",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-4-contact-info-icon-wi6t7n"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "QXZAzD",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-location-dot"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "zRtdFe",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-4-contact-info-content-wi6t7n"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "PSFUKo",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-4-contact-info-content-wi6t7n-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Street 1337",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "vlKN3x",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-4-contact-info-content-wi6t7n-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Postal code, City",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "SjzjRl",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-4-contact-info-content-wi6t7n-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Country",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "ZHC5kr",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-4-contact-info-block-wi6t7n"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "xvdlnE",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-4-contact-info-icon-wi6t7n"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "BF8Hk6",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-envelope"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "M7Rwft",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-4-contact-info-content-wi6t7n"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Iy7NhD",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-4-contact-info-content-wi6t7n-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "email@domain.com",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "cwezvO",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-4-contact-info-block-wi6t7n"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "zBFCOD",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-4-contact-info-icon-wi6t7n"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "gVWmgG",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-phone"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "hn6nHi",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-4-contact-info-content-wi6t7n"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "IlzVFc",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-4-contact-info-content-wi6t7n-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "020 13 37 000",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "y5wHWW",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "order-3", "order-md-2", "contactus-4-col-wi6t7n"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "jSycRk",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-4-inputwrapper-wi6t7n"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "y3W3Ip",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-4-label-wi6t7n"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "SpOPcV",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-4-inputfield-wi6t7n"],
                          styles: [],
                          attributes: [
                            { property: "name", value: "name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                            { property: "type", value: "text" },
                            { property: "placeholder", value: "Your name" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "TG51nO",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-4-inputwrapper-wi6t7n"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "peV3u0",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-4-label-wi6t7n"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "NNnr6d",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-4-inputfield-wi6t7n"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "mJyZPI",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-4-inputwrapper-wi6t7n"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "UwvdjY",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-4-label-wi6t7n"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "s8Vkby",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-4-inputfield-wi6t7n"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "mMqPNu",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-4-inputwrapper-wi6t7n"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "CcJwjc",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-4-label-wi6t7n"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "TqrcIa",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-4-inputfield-wi6t7n"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "AYIUli",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-4-button-wi6t7nwrapper-wi6t7n"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "Ae0vlV",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-4-button-wi6t7n"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Get in contact",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Q3FgO2",
                  type: "block",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "order-2", "order-md-3", "contactus-4-col-wi6t7n"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "block" }],
                  content: "",
                  children: [
                    {
                      childId: "KQTLdc",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-4-col-wi6t7numn-title"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Follow us on",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "YBoK86",
                      type: "element",
                      htmlTagName: "a",
                      classes: ["contactus-4-social-link-wi6t7n"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_blank" },
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "a" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "erVlng",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-brands", "fa-twitter"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "a4EVFh",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-4-social-link-wi6t7n-text"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "     Twitter",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "r6ROn3",
                      type: "element",
                      htmlTagName: "a",
                      classes: ["contactus-4-social-link-wi6t7n"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_blank" },
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "a" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "EsCKrG",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-brands", "fa-facebook-f"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "SlTZc7",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-4-social-link-wi6t7n-text"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "     Facebook",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "VhX60z",
                      type: "element",
                      htmlTagName: "a",
                      classes: ["contactus-4-social-link-wi6t7n"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_blank" },
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "a" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "ua7D9g",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-brands", "fa-instagram"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "fMJbSi",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-4-social-link-wi6t7n-text"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "    Instagram",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "63a2209ad79b56e728b70dbb",
    },
  ],
  sbCustomCss: [
    {
      componentId: "fhXTxL",
      classes: [
        {
          className: "scrolledpast-fhXTxL.navbar-3-wrapper-fhXTxL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "rem" },
            { property: "padding-bottom", value: "rem" },
            { property: "background", value: "" },
          ],
        },
        {
          className: "navbar-3-borderwrapper-fhXTxL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "border-style", value: "solid none solid none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
          ],
        },
        {
          className: "scrolledpast-fhXTxL .navbar-3-logo-fhXTxL",
          pseudo: "",
          rules: [
            { property: "max-width", value: "150px" },
            { property: "max-height", value: "px" },
          ],
        },
        { className: "navbar-3-collapse-fhXTxL", pseudo: "", rules: [{ property: "position", value: "relative" }] },
        {
          className: "navbar-3-inside-line-fhXTxL",
          pseudo: "",
          rules: [
            { property: "height", value: "250%" },
            { property: "width", value: "1px" },
            { property: "background-color", value: "rgba(180, 180, 180, 1)" },
            { property: "position", value: "absolute" },
            { property: "top", value: "-75%" },
            { property: "margin-left", value: "1.5rem" },
          ],
        },
        {
          className: "navbar-3-wrapper-fhXTxL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(204, 234, 228, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "navbar-3-logo-fhXTxL",
          pseudo: "",
          rules: [
            { property: "max-width", value: "180px" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "30px" },
          ],
        },
        { className: "navbar-3-logo-fhXTxL", pseudo: "hover", rules: [] },
        {
          className: "navbar-3-toggler-fhXTxL",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 0)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(180, 180, 180, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-3-toggler-fhXTxL",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(70, 70, 70, 0)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
          ],
        },
        {
          className: "navbar-3-link-fhXTxL",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
            { property: "font-family", value: "var(--font-body)" },
            { property: "font-size", value: "1rem" },
            { property: "text-decoration", value: "none" },
            { property: "font-weight", value: "inherit" },
          ],
        },
        { className: "navbar-3-link-fhXTxL", pseudo: "hover", rules: [{ property: "color", value: "rgba(0, 0, 0, 1)" }] },
      ],
    },
    {
      componentId: "UKfZtp",
      classes: [
        {
          className: "section.basics-1-component-UKfZtp",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-UKfZtp", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-UKfZtp",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-UKfZtp", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-UKfZtp",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(72, 177, 156, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-UKfZtp", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "ZmS4vV",
      classes: [
        {
          className: "section.basics-2-component-ZmS4vV",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.25rem" },
            { property: "padding-bottom", value: "0.25rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-2-component-ZmS4vV", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-ZmS4vV",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-ZmS4vV", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-ZmS4vV",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(183, 200, 187, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-ZmS4vV", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Ca3zWk",
      classes: [
        {
          className: "section.basics-7-component-Ca3zWk",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "3rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-7-component-Ca3zWk", pseudo: "hover", rules: [] },
        {
          className: "basics-7-component-Ca3zWk",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-7-component-Ca3zWk", pseudo: "hover", rules: [] },
        {
          className: "basics-7-link-readmore-Ca3zWk",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(72, 177, 156, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(72, 177, 156, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-7-link-readmore-Ca3zWk",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(72, 177, 156, 1)" },
            { property: "border-color", value: "rgba(72, 177, 156, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "lM1jFp",
      classes: [
        {
          className: "section-divider-lM1jFp",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
            { property: "width", value: "100%" },
          ],
        },
      ],
    },
    {
      componentId: "R1zbya",
      classes: [
        {
          className: "section.parallax-2-component-R1zbya",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-2-component-R1zbya", pseudo: "hover", rules: [] },
        {
          className: "parallax-2-component-R1zbya",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-2-wrapper-R1zbya",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/yoga5.jpg")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-2-content-R1zbya",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.4)" },
            { property: "justify-content", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "Op7I1Q",
      classes: [
        {
          className: "section.basics-1-component-Op7I1Q",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-Op7I1Q", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-Op7I1Q",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-Op7I1Q", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-Op7I1Q",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(72, 177, 156, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-Op7I1Q", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "sN5wFE",
      classes: [
        {
          className: "section.quote-13-component-sN5wFE",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.quote-13-component-sN5wFE", pseudo: "hover", rules: [] },
        {
          className: "quote-13-col-sN5wFE",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "display", value: "flex" },
            { property: "border-style", value: "none solid none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
          ],
        },
        { className: "quote-13-col-sN5wFE", pseudo: "last-child", rules: [{ property: "border", value: "none" }] },
        {
          className: "quote-13-wrapper-sN5wFE",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0" },
            { property: "padding", value: "0 0 0 0" },
          ],
        },
        { className: "quote-13-wrapper-sN5wFE", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)" }] },
        {
          className: "quote-13-border-sN5wFE",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "height", value: "100%" },
            { property: "width", value: "10px" },
            { property: "background", value: "rgba(229, 42, 5, 1)" },
          ],
        },
        { className: "quote-13-border-sN5wFE", pseudo: "hover", rules: [{ property: "background", value: "rgba(229, 42, 5, 1)" }] },
        {
          className: "quote-13-quote-sN5wFEicon-sN5wFE",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 1rem 0.25rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
            { property: "color", value: "rgba(72, 177, 156, 1)" },
            { property: "font-size", value: "2rem" },
          ],
        },
        {
          className: "quote-13-quote-sN5wFEicon-sN5wFE",
          pseudo: "hover",
          rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }],
        },
        {
          className: "quote-13-text-sN5wFE",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(183, 200, 187, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0.25rem 1rem 1rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "quote-13-text-sN5wFE", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "quote-13-quote-sN5wFE",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(183, 200, 187, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 1rem 1rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "quote-13-quote-sN5wFE", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "wi6t7n",
      classes: [
        {
          className: "section.contactus-4-component-wi6t7n",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.contactus-4-component-wi6t7n", pseudo: "hover", rules: [] },
        { className: "contactus-4-col-wi6t7n", pseudo: "", rules: [{ property: "padding", value: "1rem 0.5rem 1rem 0.5rem" }] },
        {
          className: "contactus-4-contact-info-block-wi6t7n",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "margin", value: "0 0 0.5rem 0" },
          ],
        },
        { className: "contactus-4-contact-info-block-wi6t7n", pseudo: "hover", rules: [] },
        {
          className: "contactus-4-contact-info-content-wi6t7n",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 1.5rem" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "contactus-4-contact-info-content-wi6t7n", pseudo: "hover", rules: [] },
        { className: "contactus-4-contact-info-content-wi6t7n > *", pseudo: "", rules: [{ property: "margin", value: "0" }] },
        {
          className: "contactus-4-button-wi6t7nwrapper-wi6t7n",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-4-button-wi6t7nwrapper-wi6t7n", pseudo: "hover", rules: [] },
        { className: "contactus-4-social-link-wi6t7n > *", pseudo: "", rules: [{ property: "display", value: "inline-block" }] },
        {
          className: "contactus-4-social-link-wi6t7n span",
          pseudo: "",
          rules: [
            { property: "width", value: "20px" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-4-label-wi6t7n",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-4-label-wi6t7n", pseudo: "hover", rules: [] },
        {
          className: "contactus-4-col-wi6t7numn-title",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(72, 177, 156, 1)" },
            { property: "font-size", value: "14px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "contactus-4-col-wi6t7numn-title",
          pseudo: "hover",
          rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }],
        },
        {
          className: "contactus-4-contact-info-content-wi6t7n-text",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(183, 200, 187, 1)" },
            { property: "font-size", value: "14px" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "contactus-4-contact-info-icon-wi6t7n",
          pseudo: "",
          rules: [
            { property: "height", value: "15px" },
            { property: "width", value: "15px" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "center" },
            { property: "color", value: "rgba(183, 200, 187, 1)" },
            { property: "font-size", value: "1rem" },
          ],
        },
        { className: "contactus-4-contact-info-icon-wi6t7n", pseudo: "hover", rules: [] },
        {
          className: "contactus-4-social-link-wi6t7n",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "color", value: "rgba(183, 200, 187, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "align-self", value: "center" },
            { property: "font-size", value: "1rem" },
          ],
        },
        {
          className: "contactus-4-social-link-wi6t7n",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(72, 177, 156, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-4-social-link-wi6t7n-text",
          pseudo: "",
          rules: [
            { property: "font-size", value: "14px" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0.25rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "contactus-4-inputfield-wi6t7n",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1rem" },
            { property: "outline", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(183, 200, 187, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease-in-out" },
          ],
        },
        {
          className: "contactus-4-inputfield-wi6t7n",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(183, 200, 187, 1)" },
            { property: "border-color", value: "rgba(183, 200, 187, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(183, 200, 187, 0.2)" },
          ],
        },
        {
          className: "contactus-4-inputfield-wi6t7n",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-4-inputfield-wi6t7n", pseudo: "hover", rules: [] },
        { className: "contactus-4-inputwrapper-wi6t7n", pseudo: "", rules: [{ property: "margin", value: "0rem 0rem 1rem 0rem" }] },
        {
          className: "contactus-4-button-wi6t7n",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(72, 177, 156, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(72, 177, 156, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "contactus-4-button-wi6t7n",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(72, 177, 156, 1)" },
            { property: "border-color", value: "rgba(72, 177, 156, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "zo1XkN",
      classes: [
        {
          className: "section.basics-5-component-zo1XkN",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-5-component-zo1XkN", pseudo: "hover", rules: [] },
        {
          className: "basics-5-col-zo1XkN",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-5-title-zo1XkN",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(72, 177, 156, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-5-title-zo1XkN", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        { className: "nJEfyZixRbL0shqV96rWDaqw1KvUyr", pseudo: "", rules: [] },
        { className: "nJEfyZixRbL0shqV96rWDaqw1KvUyr", pseudo: "hover", rules: [] },
        {
          className: "basics-5-text-zo1XkN",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(183, 200, 187, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-5-text-zo1XkN", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-5-img-zo1XkN",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-5-img-zo1XkN",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "Xh8MtE",
      classes: [
        {
          className: "section.basics-5-component-Xh8MtE",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-5-component-Xh8MtE", pseudo: "hover", rules: [] },
        {
          className: "basics-5-col-Xh8MtE",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-5-title-Xh8MtE",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(72, 177, 156, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-5-title-Xh8MtE", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "rV4YK0h6C0I9QUddKaktEaq6Vn1gV2",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "margin", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "rV4YK0h6C0I9QUddKaktEaq6Vn1gV2", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-5-text-Xh8MtE",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(72, 177, 156, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-5-text-Xh8MtE", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "zqaKQxo8fLd7ZXiN20Nuh6YqZNLyka",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "margin", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "zqaKQxo8fLd7ZXiN20Nuh6YqZNLyka", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "TYJo6DiC2a8moUOIRmuXfNsHDO2MAC",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "margin", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "TYJo6DiC2a8moUOIRmuXfNsHDO2MAC", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-5-img-Xh8MtE",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-5-img-Xh8MtE",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Work+Sans:100,300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Poppins:100,300,700&display=swap",
    fontHeadersName: "Work Sans",
    fontHeadersWeight: "700",
    fontBodyName: "Poppins",
    fontBodyWeight: "400",
    color1: "rgba(204, 234, 228, 1)",
    color2: "rgba(72, 177, 156, 1)",
    color3: "rgba(183, 200, 187, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
