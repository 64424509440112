export const template21 = {
  id: "65feead1581bdcbba68d7fd5",
  name: { en: "Gym", nl: "Sportschool" },
  desc: { en: "Website for a gym", nl: "Website voor een sportschool" },
  categories: ["sports", "hobby"],
  sbPages: [
    {
      pageId: "9a767922-5269-4c9e-b0d2-e6392cfe8ade",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "wyaMSo",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-wyaMSo", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "y8u7uP",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "Njrcwl",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "mqWi8Y",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-wyaMSo"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-trainhard-blue.png" },
                        { property: "alt", value: "Logo" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "AjLrnR",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-wyaMSo", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                    { property: "type", value: "button" },
                    { property: "aria-expanded", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "w9CbbC",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "hTIaLF",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "zbCHNk",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "xBP5rx",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-wyaMSo"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "HOME",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "dW1YXY",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-wyaMSo"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#gym" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "OUR GYM",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "qzg0IQ",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-wyaMSo"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#offer" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "OUR OFFER",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ZHjvOI",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-wyaMSo"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#visit" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "VISIT US",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ZkAu1n",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-3-component-ZkAu1n"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "gRPWVk",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-3-wrapper-ZkAu1n"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "bYb8uF",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-3-overlaywrapper-ZkAu1n"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "qTLNJE",
                      type: "",
                      htmlTagName: "div",
                      classes: ["container", "parallax-3-containercontent-ZkAu1n"],
                      styles: [],
                      attributes: [{ property: "data-parallax-containercontent", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "NjECiO",
                          type: "",
                          htmlTagName: "div",
                          classes: ["parallax-3-content-ZkAu1n", "w-100", "w-md-70", "w-lg-50"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "parallax3_content" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "pkND3y",
                              type: "",
                              htmlTagName: "h1",
                              classes: ["parallax-3-title-ZkAu1n", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "WELCOME TO TRAIN HARD",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "qCS2kC",
                              type: "",
                              htmlTagName: "p",
                              classes: ["parallax-3-text-ZkAu1n", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "iiyaX5",
                              type: "",
                              htmlTagName: "a",
                              classes: ["parallax-3-button-ZkAu1n"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-target", value: "_self" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "button" },
                                { property: "data-href", value: "/index.html#gym" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "See our gym",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Pc7xec",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-Pc7xec"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "S82vKn",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-Pc7xec", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "JO0KZ4",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-7-component-JO0KZ4"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "rjY9Xz",
              type: "",
              htmlTagName: "a",
              classes: ["basics-7-link-readmore-JO0KZ4"],
              styles: [],
              attributes: [
                { property: "href", value: "#!" },
                { property: "data-target", value: "_self" },
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "button" },
                { property: "data-href", value: "/index.html#visit" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Come visit us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "Xct5f8",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-10-component-Xct5f8"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "tb0eil",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "h-100", "posRelative"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "bqnhXE",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-none", "d-md-block", "col-12", "col-md-6", "col-lg-6", "hero-10-bgcol-left-Xct5f8"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "bgdiv" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "mo7m8B",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "hero-10-bgcol-right-Xct5f8"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "bgdiv" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "zzyU7i",
                  type: "",
                  htmlTagName: "div",
                  classes: ["w-100", "w-md-90", "w-lg-60", "hero-10-fg-imgwrapper-Xct5f8", "posRelative"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "N9NGbk",
                      type: "",
                      htmlTagName: "img",
                      classes: ["hero-10-img-Xct5f8", "w-100", "w-md-70", "w-lg-70"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/gym22.jpg" },
                        { property: "alt", value: "Image" },
                      ],
                      content: "",
                      children: [],
                    },
                    {
                      childId: "t33JgY",
                      type: "",
                      htmlTagName: "div",
                      classes: ["hero-10-fg-textdiv-Xct5f8", "d-none", "d-md-flex", "w-40"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "bgdiv" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "JswFXb",
                          type: "",
                          htmlTagName: "h1",
                          classes: ["hero-10-text-Xct5f8", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "NKGVio",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-NKGVio"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "gym" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "nAynQ1",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-NKGVio", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "The gym", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "z3kVhx",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "imggallery-7-component-z3kVhx"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "hWo7C2",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "imggallery-7-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "nkDCD0",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "order-first", "imggallery-7-col-z3kVhx"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "B7hhNz",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-7-img-z3kVhx"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/gym18.jpg" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "Q3bIGX",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-8", "col-lg-8", "imggallery-7-col-z3kVhx"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "dKCOII",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-7-img-z3kVhx"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/gym17.jpg" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              childId: "QTjDbW",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "imggallery-7-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "z03vKT",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-8", "col-lg-8", "order-first", "imggallery-7-col-z3kVhx"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "SvfgQk",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-7-img-z3kVhx"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/gym14.jpg" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "FhKgc0",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "imggallery-7-col-z3kVhx"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "C6PKQq",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-7-img-z3kVhx"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/gym11.jpg" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "jOkURX",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-jOkURX"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "offer" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "eaqths",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-jOkURX", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "How we work you", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "Kdhvvj",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-10-component-Kdhvvj"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Trxl4h",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "zKbygN",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-Kdhvvj"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "Dm4My8",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-Kdhvvj"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "GqEeV3",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-Kdhvvj"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/gym18.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "E6WOm3",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-Kdhvvj", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Free weights",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "O9OX0c",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-Kdhvvj", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "fcYbY5",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-Kdhvvj"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "oXcQja",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-Kdhvvj"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "SVIdaw",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-Kdhvvj"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/gym16.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "nxvf1n",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-Kdhvvj", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Personal trainer",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "ElxXwh",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-Kdhvvj", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "OUnMVI",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-Kdhvvj"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "a2aW4b",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-Kdhvvj"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "j797Si",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-Kdhvvj"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/gym10.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "IRddE2",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-Kdhvvj", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Cardio",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "ZuVXfj",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-Kdhvvj", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "RP8WhN",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-Kdhvvj"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "UiyiO7",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-Kdhvvj"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "PgP1zk",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-Kdhvvj"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/gym21.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "he3Blt",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-Kdhvvj", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Group lessons",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Sp2dEo",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-Kdhvvj", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "RqmAyq",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-RqmAyq"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "visit" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "q3ePYH",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-RqmAyq", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Come visit us and sign up!",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "hmw4kY",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-3-component-hmw4kY"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Ci841L",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "y0Zauw",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "p-2", "contactus-3-googlemaps-col-hmw4kY"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "GPtR5C",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-3-googlemaps-wrapper-hmw4kY"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "googlemaps_wrapper" },
                        { property: "data-editable", value: "true" },
                      ],
                      content: "",
                      children: [],
                    },
                    {
                      childId: "UUeL05",
                      type: "",
                      htmlTagName: "iframe",
                      classes: [],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "googlemaps" },
                        {
                          property: "src",
                          value:
                            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8680.352904042613!2d4.899406522082844!3d52.37329199453203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609b70dd81623%3A0xcae71b8d3adfd142!2sAmsterdam%20Centraal!5e0!3m2!1sen!2snl!4v1662818615871!5m2!1sen!2snl",
                        },
                        { property: "scrolling", value: "no" },
                        { property: "loading", value: "lazy" },
                        { property: "referrerpolicy", value: "no-referrer-when-downgrade" },
                        { property: "width", value: "100%" },
                        { property: "height", value: "100%" },
                        { property: "frameborder", value: "0" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "okf3zP",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "p-2"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "GPHy7g",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["row", "justify-content-center", "align-items-stretch", "contactus-3-row"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "HYNb4m",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "contactus-3-col-hmw4kY"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "am0NiW",
                              type: "",
                              htmlTagName: "button",
                              classes: ["contactus-3-iconwrapper-hmw4kY"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "mOb6Cs",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-location-dot"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Ge2f5O",
                              type: "",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "qFj7Ih",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["contactus-3-text-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Street 1337",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "DZhGQx",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["contactus-3-text-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Postal code, City",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "I7Sa3O",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "contactus-3-col-hmw4kY"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "kIAo4i",
                              type: "",
                              htmlTagName: "button",
                              classes: ["contactus-3-iconwrapper-hmw4kY"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "WTPjlB",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-envelope"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "R89svS",
                              type: "",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "dsehHE",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["contactus-3-text-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "email@domain.com",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "rwZVzd",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "contactus-3-col-hmw4kY"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "l8EtIn",
                              type: "",
                              htmlTagName: "button",
                              classes: ["contactus-3-iconwrapper-hmw4kY"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "yDWa0T",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-phone"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "t8Lc1K",
                              type: "",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "WDv6eQ",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["contactus-3-text-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "02013 37 000",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "ThJlXZ",
                          type: "",
                          htmlTagName: "div",
                          classes: ["col-12", "p-2"],
                          styles: [],
                          attributes: [
                            { property: "data-sbform", value: "true" },
                            { property: "data-sbformaction", value: "" },
                            { property: "data-sbformmsg", value: "Your message has been received!" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "ct8pxU",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "contactus-3-inputwrapper-hmw4kY"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "hn8lRs",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "contactus-3-label-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your name",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "UlLgnV",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "contactus-3-inputfield-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "type", value: "text" },
                                    { property: "name", value: "name" },
                                    { property: "placeholder", value: "Your name" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "joaERL",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "contactus-3-inputwrapper-hmw4kY"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "Nm8bQT",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "contactus-3-label-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your email",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "Z4x0gd",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "contactus-3-inputfield-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "type", value: "email" },
                                    { property: "name", value: "email" },
                                    { property: "placeholder", value: "Your email address" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "zE4ZZr",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "contactus-3-inputwrapper-hmw4kY"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "hnk5GF",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "contactus-3-label-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Subject",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "WwlSZ2",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "contactus-3-inputfield-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "type", value: "text" },
                                    { property: "name", value: "subject" },
                                    { property: "placeholder", value: "Subject" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Apo92V",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "contactus-3-inputwrapper-hmw4kY"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "CnFzfP",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "contactus-3-label-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your message",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "zHaEoD",
                                  type: "",
                                  htmlTagName: "textarea",
                                  classes: ["form-control", "contactus-3-inputfield-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "rows", value: "3" },
                                    { property: "name", value: "message" },
                                    { property: "placeholder", value: "Message" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Mn2bej",
                              type: "",
                              htmlTagName: "div",
                              classes: ["contactus-3-button-hmw4kYwrapper-hmw4kY"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "yqH56Z",
                                  type: "",
                                  htmlTagName: "button",
                                  classes: ["contactus-3-button-hmw4kY"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "button" },
                                    { property: "data-sbformbtn", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Send message!",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "65fededfce19f27d76704d3b",
    },
  ],
  sbCustomCss: [
    {
      componentId: "wyaMSo",
      classes: [
        {
          className: "scrolledpast-wyaMSo.navbar-1-wrapper-wyaMSo",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "rem" },
            { property: "padding-bottom", value: "rem" },
            { property: "background", value: "" },
          ],
        },
        { className: "scrolledpast-wyaMSo .navbar-1-logo-wyaMSo", pseudo: "", rules: [{ property: "max-height", value: "px" }] },
        {
          className: "navbar-1-wrapper-wyaMSo",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "navbar-1-logo-wyaMSo",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "35px" },
          ],
        },
        { className: "navbar-1-logo-wyaMSo", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-wyaMSo",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-wyaMSo",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(121, 163, 210, 1)" },
          ],
        },
        {
          className: "navbar-1-link-wyaMSo",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "font-family", value: "var(--font-headers)" },
            { property: "font-size", value: "1rem" },
            { property: "text-decoration", value: "none" },
            { property: "font-weight", value: "inherit" },
          ],
        },
        { className: "navbar-1-link-wyaMSo", pseudo: "hover", rules: [{ property: "color", value: "rgba(121, 163, 210, 1)" }] },
      ],
    },
    {
      componentId: "ZkAu1n",
      classes: [
        {
          className: "section.parallax-3-component-ZkAu1n",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-3-component-ZkAu1n", pseudo: "hover", rules: [] },
        {
          className: "parallax-3-component-ZkAu1n",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-3-wrapper-ZkAu1n",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/gym20.jpg")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-3-containercontent-ZkAu1n",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "padding", value: "1.5rem 1.5rem 1.5rem 1.5rem" },
          ],
        },
        {
          className: "parallax-3-text-ZkAu1n",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "margin", value: "1.5rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "parallax-3-text-ZkAu1n", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-3-overlaywrapper-ZkAu1n",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "parallax-3-content-ZkAu1n",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "start" },
            { property: "background", value: "rgba(234, 237, 240, 0.6)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "1rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "parallax-3-content-ZkAu1n",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(234, 237, 240, 0.6)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "parallax-3-title-ZkAu1n",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "4.5rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-3-title-ZkAu1n", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-3-button-ZkAu1n",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(75, 118, 206, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1.5rem 0rem 4.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "parallax-3-button-ZkAu1n",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(75, 118, 206, 1)" },
            { property: "border-color", value: "rgba(75, 118, 206, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "Pc7xec",
      classes: [
        {
          className: "section.basics-2-component-Pc7xec",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-2-component-Pc7xec", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-Pc7xec",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-Pc7xec", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-Pc7xec",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(121, 163, 210, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-Pc7xec", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "JO0KZ4",
      classes: [
        {
          className: "section.basics-7-component-JO0KZ4",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-7-component-JO0KZ4", pseudo: "hover", rules: [] },
        {
          className: "basics-7-component-JO0KZ4",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-7-component-JO0KZ4", pseudo: "hover", rules: [] },
        {
          className: "basics-7-link-readmore-JO0KZ4",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(75, 118, 206, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(75, 118, 206, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-7-link-readmore-JO0KZ4",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(121, 163, 210, 1)" },
            { property: "border-color", value: "rgba(121, 163, 210, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "Xct5f8",
      classes: [
        {
          className: "section.hero-10-component-Xct5f8",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.hero-10-component-Xct5f8", pseudo: "hover", rules: [] },
        { className: "hero-10-component-Xct5f8", pseudo: "", rules: [{ property: "height", value: "80vh" }] },
        {
          className: "hero-10-bgcol-left-Xct5f8",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "hero-10-bgcol-left-Xct5f8",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "hero-10-fg-imgwrapper-Xct5f8",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "20%" },
            { property: "height", value: "60%" },
          ],
        },
        {
          className: "hero-10-img-Xct5f8",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "10px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "hero-10-img-Xct5f8",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-10-bgcol-right-Xct5f8",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(121, 163, 210, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "hero-10-bgcol-right-Xct5f8",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(121, 163, 210, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "hero-10-fg-textdiv-Xct5f8",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "50%" },
            { property: "transform", value: "translateY(-50%)" },
            { property: "right", value: "0" },
            { property: "height", value: "70%" },
            { property: "align-items", value: "center" },
            { property: "border-radius", value: "0" },
            { property: "background", value: "rgba(237, 186, 18, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "10px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "hero-10-fg-textdiv-Xct5f8",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(237, 186, 18, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "hero-10-text-Xct5f8",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(121, 163, 210, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0.25rem 0.25rem 0.25rem 0.25rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-10-text-Xct5f8", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "NKGVio",
      classes: [
        {
          className: "section.basics-1-component-NKGVio",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "8rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-NKGVio", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-NKGVio",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-NKGVio", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-NKGVio",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-NKGVio", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "z3kVhx",
      classes: [
        {
          className: "section.imggallery-7-component-z3kVhx",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.imggallery-7-component-z3kVhx", pseudo: "hover", rules: [] },
        {
          className: "imggallery-7-col-z3kVhx",
          pseudo: "",
          rules: [
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "imggallery-7-img-z3kVhx",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "350px" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "imggallery-7-img-z3kVhx", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "jOkURX",
      classes: [
        {
          className: "section.basics-1-component-jOkURX",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-jOkURX", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-jOkURX",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-jOkURX", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-jOkURX",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-jOkURX", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Kdhvvj",
      classes: [
        {
          className: "section.card-10-component-Kdhvvj",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-10-component-Kdhvvj", pseudo: "hover", rules: [] },
        { className: "card-10-col-Kdhvvj", pseudo: "", rules: [] },
        { className: "card-10-col-Kdhvvj", pseudo: "hover", rules: [] },
        { className: "card-10-wrapper-Kdhvvj", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
        { className: "card-10-wrapper-Kdhvvj", pseudo: "hover", rules: [] },
        {
          className: "card-10-title-Kdhvvj",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0.5rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-10-title-Kdhvvj", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-10-avatar-Kdhvvj",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "200px" },
            { property: "height", value: "200px" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "5px" },
            { property: "border-color", value: "rgba(75, 118, 206, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-avatar-Kdhvvj",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(75, 118, 206, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-10-text-Kdhvvj",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(121, 163, 210, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-10-text-Kdhvvj", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "hmw4kY",
      classes: [
        {
          className: "section.contactus-3-component-hmw4kY",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-3-component-hmw4kY", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-col-hmw4kY",
          pseudo: "",
          rules: [
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "text-align", value: "center" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        {
          className: "contactus-3-iconwrapper-hmw4kY",
          pseudo: "",
          rules: [
            { property: "height", value: "65px" },
            { property: "width", value: "65px" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(75, 118, 206, 1)" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "cursor", value: "default !important" },
          ],
        },
        {
          className: "contactus-3-iconwrapper-hmw4kY",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(75, 118, 206, 1)" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "contactus-3-googlemaps-col-hmw4kY",
          pseudo: "",
          rules: [
            { property: "overflow", value: "hidden" },
            { property: "position", value: "relative" },
          ],
        },
        {
          className: "contactus-3-googlemaps-wrapper-hmw4kY",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "bottom", value: "0" },
          ],
        },
        {
          className: "contactus-3-button-hmw4kYwrapper-hmw4kY",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-3-button-hmw4kYwrapper-hmw4kY", pseudo: "hover", rules: [] },
        { className: "contactus-3-inputwrapper-hmw4kY", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        {
          className: "contactus-3-inputfield-hmw4kY",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(75, 118, 206, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-3-inputfield-hmw4kY",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "border-color", value: "rgba(75, 118, 206, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(75, 118, 206, 0.2)" },
          ],
        },
        {
          className: "contactus-3-inputfield-hmw4kY",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-3-inputfield-hmw4kY", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-label-hmw4kY",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-3-label-hmw4kY", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-button-hmw4kY",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(75, 118, 206, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "contactus-3-button-hmw4kY",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(75, 118, 206, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-3-text-hmw4kY",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(121, 163, 210, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "contactus-3-text-hmw4kY", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "RqmAyq",
      classes: [
        {
          className: "section.basics-1-component-RqmAyq",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-RqmAyq", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-RqmAyq",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-RqmAyq", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-RqmAyq",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(75, 118, 206, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-RqmAyq", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Poppins:100,300,700&display=swap",
    fontHeadersName: "Fjalla One",
    fontHeadersWeight: "400",
    fontBodyName: "Poppins",
    fontBodyWeight: "300",
    color1: "rgba(75, 118, 206, 1)",
    color2: "rgba(121, 163, 210, 1)",
    color3: "rgba(128, 128, 128, 1)",
    color4: "rgba(237, 186, 18, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
