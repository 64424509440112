export const template3 = {
  id: "638671ca225265b79312ae6e",
  name: { en: "Consultancy agency", nl: "Adviesbureay" },
  desc: { en: "Single-page site for a consultancy agency", nl: "Een-pagina site voor een adviesbureau" },
  categories: ["services"],
  sbPages: [
    {
      pageId: "UDlDfbrMqS",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "ER8cel",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-ER8cel", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "true" },
            { property: "data-navbarposition", value: "fixed-top" },
            { property: "id", value: "home" },
          ],
          content: "",
          children: [
            {
              childId: "S5aM1b",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "TTzvm0",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "V9VaqV",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-ER8cel"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Logo" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-company-name-white.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "DVzIXa",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-ER8cel", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "bEqiMI",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "I04IKc",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "cGJtFO",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "CAfsJe",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ER8cel"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#home" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Home",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "St9kbv",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ER8cel"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#about" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "About us",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "bnlsVT",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ER8cel"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#features" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Features",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "WrzC4f",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ER8cel"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#pricing" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Pricing",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "BFmFCY",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ER8cel"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#team" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Team",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "grA4NI",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ER8cel"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#contact" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "W2doXH",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-4-component-W2doXH"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "UT5vX2",
              type: "",
              htmlTagName: "div",
              classes: ["hero-4-wrapper-W2doXH"],
              styles: [],
              attributes: [{ property: "data-herowrapper", value: "true" }],
              content: "",
              children: [
                {
                  childId: "NhxU5y",
                  type: "",
                  htmlTagName: "img",
                  classes: ["hero-4-img-W2doXH"],
                  styles: [],
                  attributes: [
                    { property: "alt", value: "Image" },
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "img" },
                    { property: "data-imgresizable", value: "false" },
                    { property: "src", value: "https://cdn.satonda.com/eb/assets/hero-4.png" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "Q6Sep4",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-4-content-W2doXH"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "hero4_contentwrapper" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ZluFqh",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-4-title-W2doXH", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Some appealing title!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "LiIO3g",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-4-text-W2doXH", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "f1kB7Q",
                      type: "",
                      htmlTagName: "a",
                      classes: ["hero-4-button-W2doXH"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                        { property: "data-href", value: "#about" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Continue",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "pm0EGp",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-pm0EGp"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "about" },
            { property: "data-aos", value: "fade-right" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "S7P7jH",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-pm0EGp", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "About us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "K86YPt",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-K86YPt"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-right" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "ycQLL8",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-K86YPt"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "X4D006",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-X4D006"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "gfjZqX",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-X4D006", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! ",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "TywMI4",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-6-component-TywMI4"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-right" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "nRQlE9",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-6-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "mozoSG",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-6-col-TywMI4"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "q8C9zH",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-6-img-TywMI4"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/work-4.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "FBrwpF",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-6-col-TywMI4"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "fKaSUe",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["basics-6-title-TywMI4", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "This is a title",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "T2Atdr",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-6-text-TywMI4", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "iu2hiN",
                      type: "",
                      htmlTagName: "p",
                      classes: ["Rn1ks1gxQoNza6PtJtLwGgvVtxZ14O", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "MW5Ig1",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-MW5Ig1"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "features" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "jiR6rH",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-MW5Ig1", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Features", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "wysUvN",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-wysUvN"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "v7vm5I",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-wysUvN"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "oY7u7t",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-14-component-oY7u7t"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "rHg8mV",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-14-row-oY7u7t"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "cJQN1t",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "card-14-col-oY7u7t"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "CSCm4D",
                      type: "",
                      htmlTagName: "img",
                      classes: ["card-14-img-oY7u7t"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/creative2.jpg" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "caXOmG",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "card-14-col-oY7u7t"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "cJvI25",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["row", "align-items-start", "justify-content-center"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "bKZk45",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-6", "col-md-6", "col-lg-6", "card-14-card-col-oY7u7t"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "fdMGsT",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-14-card-wrapper-oY7u7t"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "mowkB8",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["card-14-iconwrapper-oY7u7t"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "div" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "a6CsQU",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-chart-simple"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "tziNqC",
                                  type: "",
                                  htmlTagName: "h4",
                                  classes: ["card-14-title-oY7u7t", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Lorem Ipsum",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "rW9zPu",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-14-text-oY7u7t", "w-100", "w-md-90", "w-lg-90"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "LAplXS",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-6", "col-md-6", "col-lg-6", "card-14-card-col-oY7u7t"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "ftZEle",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-14-card-wrapper-oY7u7t"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "j3VI5A",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["card-14-iconwrapper-oY7u7t"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "div" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "mcUqPc",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-regular", "fa-paper-plane"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "jrXmak",
                                  type: "",
                                  htmlTagName: "h4",
                                  classes: ["card-14-title-oY7u7t", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Dolor Sitema",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "wfvemS",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-14-text-oY7u7t", "w-100", "w-md-90", "w-lg-90"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "EkrDeO",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-6", "col-md-6", "col-lg-6", "card-14-card-col-oY7u7t"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "epH35C",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-14-card-wrapper-oY7u7t"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "YwBZFa",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["card-14-iconwrapper-oY7u7t"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "div" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ZTw19B",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-layer-group"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "FpqdYy",
                                  type: "",
                                  htmlTagName: "h4",
                                  classes: ["card-14-title-oY7u7t", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Sed ut perspiciatis",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "stcHjk",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-14-text-oY7u7t", "w-100", "w-md-90", "w-lg-90"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "LJk7ZK",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-6", "col-md-6", "col-lg-6", "card-14-card-col-oY7u7t"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "JreWod",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-14-card-wrapper-oY7u7t"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "EqRQqt",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["card-14-iconwrapper-oY7u7t"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "div" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "HirUjU",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-users-line"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "lugcQq",
                                  type: "",
                                  htmlTagName: "h4",
                                  classes: ["card-14-title-oY7u7t", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Magni Dolores",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "CSAWTp",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-14-text-oY7u7t", "w-100", "w-md-90", "w-lg-90"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "LFouXR",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-1-component-LFouXR"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "LdIirI",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-1-wrapper-LFouXR"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "mNccLX",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-1-content-LFouXR"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "aG3mj6",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["parallax-1-title-LFouXR", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Great products, low pricing! ",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "EVltIu",
                      type: "",
                      htmlTagName: "p",
                      classes: ["parallax-1-text-LFouXR", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "BwxqgQ",
                      type: "",
                      htmlTagName: "a",
                      classes: ["parallax-1-button-LFouXR"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                        { property: "data-href", value: "#pricing" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "See our pricing",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "l4pHOA",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-l4pHOA"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-left" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "mOcQda",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-l4pHOA", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Trusted by our customers",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "rf5X0O",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-rf5X0O"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-left" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "br8sah",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-rf5X0O"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "sSkegj",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "clients-1-component-sSkegj"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "lyMWqW",
              type: "block",
              htmlTagName: "div",
              classes: ["d-flex", "flex-wrap", "align-items-center", "justify-content-center", "clients-1-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "lioIYe",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-left" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "tWdhS6",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-sSkegj"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "CRssDQ",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-left" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "sngt8I",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-sSkegj"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "jqlbg6",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-left" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "zv40pe",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-sSkegj"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-3.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "o3Rmnn",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-left" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "uSlGtN",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-sSkegj"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-4.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Hsf5fp",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-Hsf5fp"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "pricing" },
            { property: "data-aos", value: "zoom-in" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "uXFV1B",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-Hsf5fp", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Our pricing", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "RXaVuc",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-RXaVuc"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "zoom-in" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "AHvC9L",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-RXaVuc"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "jwhxlD",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-jwhxlD"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "zoom-in" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "ozfpZw",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-jwhxlD", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui!",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "e6GL2E",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "pricetable-5-component-e6GL2E"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "SPGNr5",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "pricetable-5-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "xahCaZ",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "pricetable-5-col-e6GL2E"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "zoom-in" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "MyZGf4",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "pricetable-5-wrapper-e6GL2E"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "vK9nGm",
                          type: "",
                          htmlTagName: "div",
                          classes: ["pricetable-5-pricewrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "VpyYaF",
                              type: "",
                              htmlTagName: "p",
                              classes: ["pricetable-5-price1-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "Rti2KN",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: [],
                                  styles: [{ property: "font-size", value: "24px" }],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "Mdph3r",
                              type: "",
                              htmlTagName: "p",
                              classes: ["pricetable-5-price2-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "mHQv89",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: [],
                                  styles: [{ property: "font-size", value: "48px" }],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "35",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "/month",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "RBPOLM",
                          type: "",
                          htmlTagName: "h2",
                          classes: ["pricetable-5-title-e6GL2E"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Basic plan",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "DMP9Bv",
                          type: "block",
                          htmlTagName: "div",
                          classes: ["toGrow", "pricetable-5-list-wrapper"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "FpD5IM",
                              type: "element",
                              htmlTagName: "p",
                              classes: ["pricetable-5-list-text-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "iS23dQ",
                              type: "element",
                              htmlTagName: "p",
                              classes: ["pricetable-5-list-text-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "rJcDya",
                              type: "element",
                              htmlTagName: "p",
                              classes: ["pricetable-5-list-text-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "tcUhet",
                          type: "",
                          htmlTagName: "a",
                          classes: ["pricetable-5-button-e6GL2E"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_self" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: " Get started! ",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "g4uRdb",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "pricetable-5-col-e6GL2E"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "zoom-in" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "JVc7v7",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "pricetable-5-wrapper-e6GL2E"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "wp2VuA",
                          type: "",
                          htmlTagName: "div",
                          classes: ["pricetable-5-pricewrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "T7PZtb",
                              type: "",
                              htmlTagName: "p",
                              classes: ["pricetable-5-price1-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "w88Mk6",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: [],
                                  styles: [{ property: "font-size", value: "24px" }],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "ErtRSm",
                              type: "",
                              htmlTagName: "p",
                              classes: ["pricetable-5-price2-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "uvY4XX",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: [],
                                  styles: [{ property: "font-size", value: "48px" }],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "45",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "/month",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "INP8Uk",
                          type: "",
                          htmlTagName: "h2",
                          classes: ["pricetable-5-title-e6GL2E"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Regular plan",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "CAjRid",
                          type: "block",
                          htmlTagName: "div",
                          classes: ["toGrow", "pricetable-5-list-wrapper"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "cf7QnA",
                              type: "element",
                              htmlTagName: "p",
                              classes: ["pricetable-5-list-text-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "ldsUBl",
                              type: "element",
                              htmlTagName: "p",
                              classes: ["pricetable-5-list-text-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "b1XC7D",
                              type: "element",
                              htmlTagName: "p",
                              classes: ["pricetable-5-list-text-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "V7jeHH",
                              type: "element",
                              htmlTagName: "p",
                              classes: ["pricetable-5-list-text-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "t6Hgy3",
                          type: "",
                          htmlTagName: "a",
                          classes: ["pricetable-5-button-e6GL2E"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_self" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: " Get started! ",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "o9SWtN",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "pricetable-5-col-e6GL2E"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "zoom-in" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "VVEAJE",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "pricetable-5-wrapper-e6GL2E"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "UdePip",
                          type: "",
                          htmlTagName: "div",
                          classes: ["pricetable-5-pricewrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "PsWnFi",
                              type: "",
                              htmlTagName: "p",
                              classes: ["pricetable-5-price1-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "kyazPL",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: [],
                                  styles: [{ property: "font-size", value: "24px" }],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "caLHlH",
                              type: "",
                              htmlTagName: "p",
                              classes: ["pricetable-5-price2-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "WOzl4B",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: [],
                                  styles: [{ property: "font-size", value: "48px" }],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "55",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "/month",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "gVka8F",
                          type: "",
                          htmlTagName: "h2",
                          classes: ["pricetable-5-title-e6GL2E"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Premium plan",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "rAeo5S",
                          type: "block",
                          htmlTagName: "div",
                          classes: ["toGrow", "pricetable-5-list-wrapper"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "wNcixb",
                              type: "element",
                              htmlTagName: "p",
                              classes: ["pricetable-5-list-text-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "T60v2K",
                              type: "element",
                              htmlTagName: "p",
                              classes: ["pricetable-5-list-text-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "cRKNiW",
                              type: "element",
                              htmlTagName: "p",
                              classes: ["pricetable-5-list-text-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "lhf1tu",
                              type: "element",
                              htmlTagName: "p",
                              classes: ["pricetable-5-list-text-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "XFc2g6",
                              type: "element",
                              htmlTagName: "p",
                              classes: ["pricetable-5-list-text-e6GL2E"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "UHa9GB",
                          type: "",
                          htmlTagName: "a",
                          classes: ["pricetable-5-button-e6GL2E"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_self" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: " Get started! ",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "U1grdC",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-U1grdC"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "team" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "fu0EGl",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-U1grdC", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Our experienced team",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "Atx0Mc",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-Atx0Mc"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "qvsprP",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-Atx0Mc"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "OjLs38",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-OjLs38"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "S19u5N",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-OjLs38", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui!",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "NR1xln",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-3-component-NR1xln"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Js7WxV",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-3-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "k6RFRz",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-3-col-NR1xln"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card3" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "B3f5SS",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-3-overlay-wrapper-NR1xln"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "YHjdJx",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-3-avatar-NR1xln"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-1.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "vl0DBI",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-3-desc-NR1xln"],
                          styles: [],
                          attributes: [{ property: "data-elementgetter1", value: "true" }],
                          content: "",
                          children: [
                            {
                              childId: "rULgXm",
                              type: "",
                              htmlTagName: "h4",
                              classes: ["card-3-title-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "IWgtds",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-3-subtitle-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "yiwOuz",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-3-overlay-content-NR1xln"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "kN8sCt",
                              type: "",
                              htmlTagName: "h6",
                              classes: ["card-3-overlay-title-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Connect with me",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "KSFQJH",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "lG9cow",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "TQGu5f",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "NEQ3NF",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "idtk82",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "JJLIeq",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "WhvQdc",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "TL8E3F",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "XkpenE",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "cRV1TR",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-3-overlay-subtitle-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non autem nisi, ex in enim tempora.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "iu6vuB",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-3-col-NR1xln"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card3" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "RzGXmM",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-3-overlay-wrapper-NR1xln"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "tFDgnc",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-3-avatar-NR1xln"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-2.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "M9T181",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-3-desc-NR1xln"],
                          styles: [],
                          attributes: [{ property: "data-elementgetter1", value: "true" }],
                          content: "",
                          children: [
                            {
                              childId: "ziEvmd",
                              type: "",
                              htmlTagName: "h4",
                              classes: ["card-3-title-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "sNyDaN",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-3-subtitle-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "luuXRh",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-3-overlay-content-NR1xln"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "VBHsJx",
                              type: "",
                              htmlTagName: "h6",
                              classes: ["card-3-overlay-title-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Connect with me",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "EILpLw",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "kM0AZe",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Rbyt2M",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "eD37Om",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ewqmbK",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "HtPbTZ",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ysZgDN",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "zPxDuw",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "WCqiaT",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "ZjykwR",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-3-overlay-subtitle-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non autem nisi, ex in enim tempora.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "whzJSJ",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-3-col-NR1xln"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card3" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "N1G4Pa",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-3-overlay-wrapper-NR1xln"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "mtSWPi",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-3-avatar-NR1xln"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "Fnh4Y2",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-3-desc-NR1xln"],
                          styles: [],
                          attributes: [{ property: "data-elementgetter1", value: "true" }],
                          content: "",
                          children: [
                            {
                              childId: "npG1Ph",
                              type: "",
                              htmlTagName: "h4",
                              classes: ["card-3-title-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "vrIu6P",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-3-subtitle-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "dE69oX",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-3-overlay-content-NR1xln"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "n6IBuG",
                              type: "",
                              htmlTagName: "h6",
                              classes: ["card-3-overlay-title-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Connect with me",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "jWZobd",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "xg7Fia",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "qORj0V",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "AEArgi",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Oe0zDE",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "IB8vzR",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "SFzGO2",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "oGiRvr",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "cIjCZQ",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "GwCbpK",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-3-overlay-subtitle-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non autem nisi, ex in enim tempora.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "DyLuvm",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-3-col-NR1xln"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card3" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "sQbn4q",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-3-overlay-wrapper-NR1xln"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "cbKn0m",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-3-avatar-NR1xln"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-4.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "RFzPgJ",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-3-desc-NR1xln"],
                          styles: [],
                          attributes: [{ property: "data-elementgetter1", value: "true" }],
                          content: "",
                          children: [
                            {
                              childId: "i2qZ9E",
                              type: "",
                              htmlTagName: "h4",
                              classes: ["card-3-title-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "sYBEC8",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-3-subtitle-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "WnHxxz",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-3-overlay-content-NR1xln"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "cbcpUd",
                              type: "",
                              htmlTagName: "h6",
                              classes: ["card-3-overlay-title-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Connect with me",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "arNI6j",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "flds1P",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "K1hAvj",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "cf2UQO",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "rRjrSA",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "cpkslE",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "CnTLho",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "Xx69sf",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-3-social-link-NR1xln"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "H1p6pv",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "YzPzc4",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-3-overlay-subtitle-NR1xln"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non autem nisi, ex in enim tempora.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "JFwrQK",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-JFwrQK"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-right" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "rzfKNw",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-JFwrQK", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "G7O9bL",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-G7O9bL"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-right" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "pVJoK0",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-G7O9bL"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "cjwqG2",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-4-component-cjwqG2"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "DvbiCp",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "thQQ5w",
                  type: "block",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "order-1", "order-md-1", "contactus-4-col-cjwqG2"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "block" }],
                  content: "",
                  children: [
                    {
                      childId: "NH4e68",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-4-col-cjwqG2umn-title"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Company name",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "SFRdOj",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-4-contact-info-block-cjwqG2"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "dt2zyv",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-4-contact-info-icon-cjwqG2"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "pp21Ru",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-location-dot"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "HOjFpR",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-4-contact-info-content-cjwqG2"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "J94GKA",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-4-contact-info-content-cjwqG2-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Street 1337",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "R3S3n2",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-4-contact-info-content-cjwqG2-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Postal code, City",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "lS7Xdd",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-4-contact-info-content-cjwqG2-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Country",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "iEuVAv",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-4-contact-info-block-cjwqG2"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "cXuTAb",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-4-contact-info-icon-cjwqG2"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "Z5peUy",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-envelope"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "MnEqeX",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-4-contact-info-content-cjwqG2"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "KF1woF",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-4-contact-info-content-cjwqG2-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "email@domain.com",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "li2iHT",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-4-contact-info-block-cjwqG2"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "uOtLzc",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-4-contact-info-icon-cjwqG2"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "wC7LqV",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-phone"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Z0SACX",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-4-contact-info-content-cjwqG2"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "eQG1UA",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-4-contact-info-content-cjwqG2-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "020 13 37 000",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "oO9Ka2",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "order-3", "order-md-2", "contactus-4-col-cjwqG2"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "a15Adw",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-4-inputwrapper-cjwqG2"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "avXBsH",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-4-label-cjwqG2"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "tglucR",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-4-inputfield-cjwqG2"],
                          styles: [],
                          attributes: [
                            { property: "name", value: "name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                            { property: "type", value: "text" },
                            { property: "placeholder", value: "Your name" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "YCBj38",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-4-inputwrapper-cjwqG2"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "KMU6Ub",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-4-label-cjwqG2"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "FGWnwi",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-4-inputfield-cjwqG2"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "KyMx0b",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-4-inputwrapper-cjwqG2"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "Z2OI4a",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-4-label-cjwqG2"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "cTjgPE",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-4-inputfield-cjwqG2"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "ofZ6Yj",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-4-inputwrapper-cjwqG2"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "RwBfLi",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-4-label-cjwqG2"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "jFAAit",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-4-inputfield-cjwqG2"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "afasAe",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-4-button-cjwqG2wrapper-cjwqG2"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "SaOFyy",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-4-button-cjwqG2"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "NVErVm",
                  type: "block",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "order-2", "order-md-3", "contactus-4-col-cjwqG2"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "block" }],
                  content: "",
                  children: [
                    {
                      childId: "UBzNwm",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-4-col-cjwqG2umn-title"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Follow us on",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "D2OxMQ",
                      type: "element",
                      htmlTagName: "a",
                      classes: ["contactus-4-social-link-cjwqG2"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_blank" },
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "a" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "ukuVJn",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-brands", "fa-twitter"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "o0As7I",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-4-social-link-cjwqG2-text"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "     Twitter",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "d6RqBT",
                      type: "element",
                      htmlTagName: "a",
                      classes: ["contactus-4-social-link-cjwqG2"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_blank" },
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "a" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "bqbUGx",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-brands", "fa-facebook-f"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "DGUVNR",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-4-social-link-cjwqG2-text"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "     Facebook",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "SuQqtS",
                      type: "element",
                      htmlTagName: "a",
                      classes: ["contactus-4-social-link-cjwqG2"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_blank" },
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "a" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "HDdAoV",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-brands", "fa-linkedin"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "PB9kGv",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-4-social-link-cjwqG2-text"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "     LinkedIn",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "63836da71b777d452ef9e44a",
    },
  ],
  sbCustomCss: [
    {
      componentId: "W2doXH",
      classes: [
        {
          className: "section.hero-4-component-W2doXH",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "section.hero-4-component-W2doXH", pseudo: "hover", rules: [] },
        {
          className: "hero-4-component-W2doXH",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "hero-4-wrapper-W2doXH",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "80vh" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "hero-4-content-W2doXH",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "bottom", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-4-content-W2doXH",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-4-text-W2doXH",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "margin", value: "0 4.5rem 2rem 4.5rem" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        {
          className: "hero-4-title-W2doXH",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 182, 226, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "2rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "hero-4-button-W2doXH",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(76, 182, 224, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(76, 182, 224, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-4-button-W2doXH",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-color", value: "rgba(76, 182, 224, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-4-img-W2doXH",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "hero-4-img-W2doXH",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "ylw1F3",
      classes: [
        {
          className: "scrolledpast-ylw1F3.navbar-1-wrapper-ylw1F3",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(76, 182, 224, 1)" },
          ],
        },
        { className: "scrolledpast-ylw1F3 .navbar-1-logo-ylw1F3", pseudo: "", rules: [{ property: "max-height", value: "40px" }] },
        {
          className: "navbar-1-wrapper-ylw1F3",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(229, 229, 229, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "navbar-1-logo-ylw1F3",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "40px" },
          ],
        },
        { className: "navbar-1-logo-ylw1F3", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-ylw1F3",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(230, 230, 230, 1)" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-ylw1F3",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(180, 180, 180, 1)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
        {
          className: "navbar-1-link-ylw1F3",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
            { property: "text-decoration", value: "none" },
          ],
        },
        { className: "navbar-1-link-ylw1F3", pseudo: "hover", rules: [{ property: "color", value: "rgba(0, 0, 0, 1)" }] },
      ],
    },
    {
      componentId: "pm0EGp",
      classes: [
        {
          className: "section.basics-1-component-pm0EGp",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "6rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-pm0EGp", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-pm0EGp",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-pm0EGp", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-pm0EGp",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 182, 226, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-pm0EGp", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "X4D006",
      classes: [
        {
          className: "section.basics-2-component-X4D006",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-X4D006", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-X4D006",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-X4D006", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-X4D006",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-X4D006", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "TywMI4",
      classes: [
        {
          className: "section.basics-6-component-TywMI4",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-6-component-TywMI4", pseudo: "hover", rules: [] },
        {
          className: "basics-6-col-TywMI4",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-6-title-TywMI4",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(79, 182, 226, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-6-title-TywMI4", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-6-text-TywMI4",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-6-text-TywMI4", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "Rn1ks1gxQoNza6PtJtLwGgvVtxZ14O",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "Rn1ks1gxQoNza6PtJtLwGgvVtxZ14O", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-6-img-TywMI4",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-6-img-TywMI4",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "ER8cel",
      classes: [
        {
          className: "scrolledpast-ER8cel.navbar-1-wrapper-ER8cel",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(76, 182, 224, 1)" },
          ],
        },
        { className: "scrolledpast-ER8cel .navbar-1-logo-ER8cel", pseudo: "", rules: [{ property: "max-height", value: "30px" }] },
        {
          className: "navbar-1-wrapper-ER8cel",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(229, 229, 229, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "navbar-1-logo-ER8cel",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "40px" },
          ],
        },
        { className: "navbar-1-logo-ER8cel", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-ER8cel",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(124, 124, 124, 0)" },
            { property: "color", value: "rgba(222, 224, 226, 1)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "navbar-1-toggler-ER8cel",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(178, 178, 178, 0)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "navbar-1-link-ER8cel",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(244, 244, 244, 1)" },
            { property: "text-decoration", value: "none" },
          ],
        },
        { className: "navbar-1-link-ER8cel", pseudo: "hover", rules: [{ property: "color", value: "rgba(255, 255, 255, 1)" }] },
      ],
    },
    {
      componentId: "K86YPt",
      classes: [
        {
          className: "section.basics-8-component-K86YPt",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.25rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-8-component-K86YPt", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-K86YPt",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-K86YPt", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-K86YPt",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "75px" },
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-K86YPt",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "oY7u7t",
      classes: [
        {
          className: "section.card-14-component-oY7u7t",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-14-component-oY7u7t", pseudo: "hover", rules: [] },
        { className: "card-14-row-oY7u7t", pseudo: "", rules: [] },
        {
          className: "card-14-col-oY7u7t",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0.5rem 0 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-14-col-oY7u7t", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(0, 0, 0, 0)" }] },
        { className: "card-14-card-col-oY7u7t", pseudo: "", rules: [{ property: "padding", value: "0.5rem 0 0.5rem 0" }] },
        { className: "card-14-card-col-oY7u7t", pseudo: "hover", rules: [] },
        { className: "card-14-card-wrapper-oY7u7t", pseudo: "", rules: [] },
        { className: "card-14-card-wrapper-oY7u7t", pseudo: "hover", rules: [] },
        {
          className: "card-14-iconwrapper-oY7u7t",
          pseudo: "",
          rules: [
            { property: "align-self", value: "center" },
            { property: "color", value: "rgba(76, 182, 224, 1)" },
            { property: "font-size", value: "2rem" },
          ],
        },
        { className: "card-14-iconwrapper-oY7u7t", pseudo: "hover", rules: [] },
        {
          className: "card-14-title-oY7u7t",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 182, 226, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0.5rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-14-title-oY7u7t", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-14-text-oY7u7t",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-size", value: "14px" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0.5rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-14-text-oY7u7t", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-14-img-oY7u7t",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-self", value: "start" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-14-img-oY7u7t",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "XAHFUO",
      classes: [
        {
          className: "section.card-10-component-XAHFUO",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-10-component-XAHFUO", pseudo: "hover", rules: [] },
        { className: "card-10-row-XAHFUO", pseudo: "", rules: [] },
        {
          className: "card-10-col-XAHFUO",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(214, 214, 214, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-10-col-XAHFUO", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }] },
        {
          className: "card-10-img-XAHFUO",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "card-10-img-XAHFUO", pseudo: "hover", rules: [] },
        {
          className: "card-10-title-XAHFUO",
          pseudo: "",
          rules: [
            { property: "font-family", value: '"Montserrat"' },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "margin", value: "0 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-10-title-XAHFUO", pseudo: "hover", rules: [] },
        {
          className: "card-10-desc-text-XAHFUO",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "LFouXR",
      classes: [
        {
          className: "section.parallax-1-component-LFouXR",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-1-component-LFouXR", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-component-LFouXR",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-1-wrapper-LFouXR",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "50vh" },
            {
              property: "background-image",
              value: 'url("https://s1.travix.com/no/north-america-america-new-york-bridge-and-river-tree-medium.jpg")',
            },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-1-content-LFouXR",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "parallax-1-text-LFouXR",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "parallax-1-text-LFouXR", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-button-LFouXR",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "parallax-1-button-LFouXR",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "box-shadow", value: "0px 0px 6px 3px rgba(255, 255, 255, 0.21)" },
          ],
        },
        {
          className: "parallax-1-title-LFouXR",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-1-title-LFouXR", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "sSkegj",
      classes: [
        {
          className: "section.clients-1-component-sSkegj",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.clients-1-component-sSkegj", pseudo: "hover", rules: [] },
        {
          className: "clients-1-logo-img-sSkegj",
          pseudo: "",
          rules: [
            { property: "max-height", value: "50px" },
            { property: "max-width", value: "100px" },
            { property: "opacity", value: 0.5 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(100%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 1.5rem 1.5rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "clients-1-logo-img-sSkegj",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(100%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "e6GL2E",
      classes: [
        {
          className: "section.pricetable-5-component-e6GL2E",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.pricetable-5-component-e6GL2E", pseudo: "hover", rules: [] },
        {
          className: "pricetable-5-col-e6GL2E",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "display", value: "flex" },
          ],
        },
        {
          className: "pricetable-5-wrapper-e6GL2E",
          pseudo: "",
          rules: [
            { property: "overflow", value: "hidden" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0.2rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 0.1)" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
          ],
        },
        {
          className: "pricetable-5-wrapper-e6GL2E",
          pseudo: "hover",
          rules: [
            { property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" },
            { property: "transform", value: "scale(1.05)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 0.1)" },
          ],
        },
        {
          className: "pricetable-5-price1-e6GL2E",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "margin", value: "1rem 0 1rem 0" },
            { property: "top", value: "-20px" },
            { property: "position", value: "relative" },
          ],
        },
        { className: "pricetable-5-price1-e6GL2E", pseudo: "hover", rules: [] },
        {
          className: "pricetable-5-price2-e6GL2E",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "margin", value: "1rem 0 1rem 0" },
          ],
        },
        { className: "pricetable-5-price2-e6GL2E", pseudo: "hover", rules: [] },
        { className: "pricetable-5-list-text-e6GL2E", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        { className: "pricetable-5-list-text-e6GL2E", pseudo: "hover", rules: [] },
        {
          className: "pricetable-5-button-e6GL2E",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(80, 182, 226, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(80, 182, 226, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1.5rem 0rem 1rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "pricetable-5-button-e6GL2E",
          pseudo: "hover",
          rules: [
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(31, 145, 193, 1)" },
            { property: "border-color", value: "rgba(80, 182, 226, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "pricetable-5-title-e6GL2E",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 182, 226, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "pricetable-5-title-e6GL2E", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Hsf5fp",
      classes: [
        {
          className: "section.basics-1-component-Hsf5fp",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-Hsf5fp", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-Hsf5fp",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-Hsf5fp", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-Hsf5fp",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 182, 226, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-Hsf5fp", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "RXaVuc",
      classes: [
        {
          className: "section.basics-8-component-RXaVuc",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.25rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-8-component-RXaVuc", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-RXaVuc",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-RXaVuc", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-RXaVuc",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "75px" },
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-RXaVuc",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "jwhxlD",
      classes: [
        {
          className: "section.basics-2-component-jwhxlD",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.25rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-jwhxlD", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-jwhxlD",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-jwhxlD", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-jwhxlD",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-jwhxlD", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "NR1xln",
      classes: [
        {
          className: "section.card-3-component-NR1xln",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-3-component-NR1xln", pseudo: "hover", rules: [] },
        {
          className: "card-3-avatar-NR1xln",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
          ],
        },
        { className: "card-3-avatar-NR1xln", pseudo: "hover", rules: [] },
        {
          className: "card-3-title-NR1xln",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(50, 50, 50, 1)" },
          ],
        },
        { className: "card-3-title-NR1xln", pseudo: "hover", rules: [] },
        {
          className: "card-3-overlay-title-NR1xln",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "margin", value: "1rem 0 1rem 0" },
          ],
        },
        { className: "card-3-overlay-title-NR1xln", pseudo: "hover", rules: [] },
        {
          className: "card-3-overlay-subtitle-NR1xln",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(200, 200, 200, 1)" },
            { property: "font-size", value: "13px" },
            { property: "text-align", value: "center" },
            { property: "margin", value: "1rem 0 1rem 0" },
          ],
        },
        { className: "card-3-overlay-subtitle-NR1xln", pseudo: "hover", rules: [] },
        {
          className: "card-3-subtitle-NR1xln",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(50, 50, 50, 1)" },
          ],
        },
        { className: "card-3-subtitle-NR1xln", pseudo: "hover", rules: [] },
        {
          className: "card-3-social-link-NR1xln",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 0.5rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "align-self", value: "start" },
            { property: "font-size", value: "1rem" },
          ],
        },
        {
          className: "card-3-social-link-NR1xln",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(76, 182, 224, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "card-3-social-link-NR1xln", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "card-3-col-NR1xln",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
          ],
        },
        {
          className: "card-3-overlay-wrapper-NR1xln",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "padding", value: "0 0 0 0" },
            { property: "width", value: "100%" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0.4rem" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "card-3-overlay-wrapper-NR1xln",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" },
          ],
        },
        {
          className: "card-3-overlay-wrapper-NR1xln",
          pseudo: "hover .card-3-overlay-content-NR1xln",
          rules: [
            { property: "visibility", value: "visible" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "card-3-overlay-wrapper-NR1xln",
          pseudo: "hover .card-3-desc-NR1xln",
          rules: [
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
          ],
        },
        {
          className: "card-3-overlay-wrapper-NR1xln",
          pseudo: "hover .card-3-title-NR1xln",
          rules: [{ property: "color", value: "rgba(250, 250, 250, 1)" }],
        },
        {
          className: "card-3-overlay-wrapper-NR1xln",
          pseudo: "hover .card-3-subtitle-NR1xln",
          rules: [{ property: "color", value: "rgba(250, 250, 250, 1)" }],
        },
        {
          className: "card-3-overlay-content-NR1xln",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "start" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "visibility", value: "hidden" },
            { property: "opacity", value: "0" },
            { property: "padding", value: "2rem 1rem 0 1rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "align-items", value: "center" },
            { property: "background", value: "rgba(0, 0, 0, 0.7)" },
          ],
        },
        {
          className: "card-3-desc-NR1xln",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 0 1rem 0" },
            { property: "z-index", value: "9" },
            { property: "position", value: "relative" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        { className: "card-3-desc-NR1xln", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "U1grdC",
      classes: [
        {
          className: "section.basics-1-component-U1grdC",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-U1grdC", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-U1grdC",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-U1grdC", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-U1grdC",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 182, 226, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-U1grdC", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Atx0Mc",
      classes: [
        {
          className: "section.basics-8-component-Atx0Mc",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.25rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-8-component-Atx0Mc", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-Atx0Mc",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-Atx0Mc", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-Atx0Mc",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "75px" },
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-Atx0Mc",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "OjLs38",
      classes: [
        {
          className: "section.basics-2-component-OjLs38",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-OjLs38", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-OjLs38",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-OjLs38", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-OjLs38",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-OjLs38", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "n37UaF",
      classes: [
        {
          className: "section.contactus-4-component-n37UaF",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-4-component-n37UaF", pseudo: "hover", rules: [] },
        { className: "contactus-4-col", pseudo: "", rules: [{ property: "padding", value: "1rem 0.5rem 1rem 0.5rem" }] },
        {
          className: "contactus-4-column-title-n37UaF",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "font-size", value: "14px" },
          ],
        },
        { className: "contactus-4-column-title-n37UaF", pseudo: "hover", rules: [] },
        {
          className: "contactus-4-contact-info-block-n37UaF",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "margin", value: "0 0 0.5rem 0" },
          ],
        },
        { className: "contactus-4-contact-info-block-n37UaF", pseudo: "hover", rules: [] },
        {
          className: "contactus-4-contact-info-content-n37UaF",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 1.5rem" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "contactus-4-contact-info-content-n37UaF", pseudo: "hover", rules: [] },
        { className: "contactus-4-contact-info-content-n37UaF > *", pseudo: "", rules: [{ property: "margin", value: "0" }] },
        { className: "contactus-4-contact-info-content-text-n37UaF", pseudo: "", rules: [{ property: "font-size", value: "14px" }] },
        {
          className: "contactus-4-buttonwrapper-n37UaF",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-4-buttonwrapper-n37UaF", pseudo: "hover", rules: [] },
        {
          className: "contactus-4-button-n37UaF",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-4-button-n37UaF",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "contactus-4-social-link-n37UaF",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "contactus-4-social-link-n37UaF", pseudo: "hover", rules: [{ property: "color", value: "rgba(246, 129, 0, 1)" }] },
        { className: "contactus-4-social-link-n37UaF > *", pseudo: "", rules: [{ property: "display", value: "inline-block" }] },
        {
          className: "contactus-4-social-link span-n37UaF",
          pseudo: "",
          rules: [
            { property: "width", value: "20px" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-4-social-link-text-n37UaF",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0.25rem" },
            { property: "font-size", value: "14px" },
          ],
        },
        { className: "contactus-4-inputwrapper-n37UaF", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        {
          className: "contactus-4-inputfield-n37UaF",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-4-inputfield-n37UaF",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
          ],
        },
        {
          className: "contactus-4-inputfield-n37UaF",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-4-inputfield-n37UaF", pseudo: "hover", rules: [] },
        {
          className: "contactus-4-contact-info-icon-n37UaF",
          pseudo: "",
          rules: [
            { property: "height", value: "15px" },
            { property: "width", value: "15px" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "center" },
            { property: "color", value: "rgba(80, 182, 226, 1)" },
            { property: "font-size", value: "1rem" },
          ],
        },
        { className: "contactus-4-contact-info-icon-n37UaF", pseudo: "hover", rules: [] },
        {
          className: "contactus-4-label-n37UaF",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(80, 182, 226, 0)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0s ease-in-out" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-4-label-n37UaF", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "MW5Ig1",
      classes: [
        {
          className: "section.basics-1-component-MW5Ig1",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-MW5Ig1", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-MW5Ig1",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-MW5Ig1", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-MW5Ig1",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 182, 226, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-MW5Ig1", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "wysUvN",
      classes: [
        {
          className: "section.basics-8-component-wysUvN",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.25rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-8-component-wysUvN", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-wysUvN",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-wysUvN", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-wysUvN",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "75px" },
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-wysUvN",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "l4pHOA",
      classes: [
        {
          className: "section.basics-1-component-l4pHOA",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-l4pHOA", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-l4pHOA",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-l4pHOA", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-l4pHOA",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 182, 226, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-l4pHOA", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "rf5X0O",
      classes: [
        {
          className: "section.basics-8-component-rf5X0O",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.25rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-8-component-rf5X0O", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-rf5X0O",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-rf5X0O", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-rf5X0O",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "75px" },
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-rf5X0O",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "JFwrQK",
      classes: [
        {
          className: "section.basics-1-component-JFwrQK",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-JFwrQK", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-JFwrQK",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-JFwrQK", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-JFwrQK",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 182, 226, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-JFwrQK", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "G7O9bL",
      classes: [
        {
          className: "section.basics-8-component-G7O9bL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-8-component-G7O9bL", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-G7O9bL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-G7O9bL", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-G7O9bL",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "75px" },
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-G7O9bL",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(76, 182, 224, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "cjwqG2",
      classes: [
        {
          className: "section.contactus-4-component-cjwqG2",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-4-component-cjwqG2", pseudo: "hover", rules: [] },
        { className: "contactus-4-col-cjwqG2", pseudo: "", rules: [{ property: "padding", value: "1rem 0.5rem 1rem 0.5rem" }] },
        {
          className: "contactus-4-col-cjwqG2umn-title",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "font-size", value: "14px" },
          ],
        },
        { className: "contactus-4-col-cjwqG2umn-title", pseudo: "hover", rules: [] },
        {
          className: "contactus-4-contact-info-block-cjwqG2",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "margin", value: "0 0 0.5rem 0" },
          ],
        },
        { className: "contactus-4-contact-info-block-cjwqG2", pseudo: "hover", rules: [] },
        {
          className: "contactus-4-contact-info-content-cjwqG2",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 1.5rem" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "contactus-4-contact-info-content-cjwqG2", pseudo: "hover", rules: [] },
        { className: "contactus-4-contact-info-content-cjwqG2 > *", pseudo: "", rules: [{ property: "margin", value: "0" }] },
        { className: "contactus-4-contact-info-content-cjwqG2-text", pseudo: "", rules: [{ property: "font-size", value: "14px" }] },
        {
          className: "contactus-4-button-cjwqG2wrapper-cjwqG2",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-4-button-cjwqG2wrapper-cjwqG2", pseudo: "hover", rules: [] },
        { className: "contactus-4-social-link-cjwqG2 > *", pseudo: "", rules: [{ property: "display", value: "inline-block" }] },
        {
          className: "contactus-4-social-link-cjwqG2 span",
          pseudo: "",
          rules: [
            { property: "width", value: "20px" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-4-social-link-cjwqG2-text",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0.25rem" },
            { property: "font-size", value: "14px" },
          ],
        },
        {
          className: "contactus-4-label-cjwqG2",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-4-label-cjwqG2", pseudo: "hover", rules: [] },
        {
          className: "contactus-4-contact-info-icon-cjwqG2",
          pseudo: "",
          rules: [
            { property: "height", value: "15px" },
            { property: "width", value: "15px" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "center" },
            { property: "color", value: "rgba(80, 182, 226, 1)" },
            { property: "font-size", value: "1rem" },
          ],
        },
        { className: "contactus-4-contact-info-icon-cjwqG2", pseudo: "hover", rules: [] },
        {
          className: "contactus-4-inputfield-cjwqG2",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1rem" },
            { property: "outline", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(34, 38, 42, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease-in-out" },
          ],
        },
        {
          className: "contactus-4-inputfield-cjwqG2",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(34, 38, 42, 1)" },
            { property: "border-color", value: "rgba(80, 182, 226, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(80, 182, 226, 0)" },
          ],
        },
        {
          className: "contactus-4-inputfield-cjwqG2",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(132, 141, 148, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-4-inputfield-cjwqG2", pseudo: "hover", rules: [] },
        { className: "contactus-4-inputwrapper-cjwqG2", pseudo: "", rules: [{ property: "margin", value: "0rem 0rem 1rem 0rem" }] },
        {
          className: "contactus-4-button-cjwqG2",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(80, 182, 226, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(80, 182, 226, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "contactus-4-button-cjwqG2",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(80, 182, 226, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-4-social-link-cjwqG2",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "color", value: "rgba(80, 182, 226, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "align-self", value: "start" },
            { property: "font-size", value: "1rem" },
          ],
        },
        {
          className: "contactus-4-social-link-cjwqG2",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(29, 131, 175, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Montserrat",
    fontHeadersWeight: "300",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(79, 182, 226, 1)",
    color2: "rgba(76, 224, 202, 1)",
    color3: "rgba(242, 185, 239, 1)",
    color4: "rgba(171, 160, 160, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
