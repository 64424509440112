export const process1 = {
  id: "process-1",
  name: "Process 1",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "process-1-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "process-1-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-3", "process-1-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "process1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "process-1-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["process-1-iconwrapper"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "div" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-regular", "fa-bell"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["process-1-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Start", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["toGrow", "process-1-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-3", "process-1-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "process1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "process-1-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["process-1-iconwrapper"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "div" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-bullseye"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["process-1-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Do something",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["toGrow", "process-1-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content:
                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad molestiae quos voluptatibus saepe libero accusamus officia provident, quasineque pariatur!",
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "svg",
                classes: ["d-none", "d-lg-block"],
                styles: [
                  { property: "position", value: "absolute" },
                  { property: "top", value: "16px" },
                  { property: "left", value: "0" },
                  { property: "transform", value: "translateX(-50%)" },
                ],
                attributes: [
                  { property: "xmlns", value: "http://www.w3.org/2000/svg" },
                  { property: "version", value: "1.1" },
                  { property: "width", value: "100" },
                  { property: "viewBox", value: "0 0 72.3 26.7" },
                  { property: "fill", value: "none" },
                  { property: "stroke", value: "rgba(108, 117, 125, 1)" },
                  { property: "stroke-linecap", value: "round" },
                  { property: "stroke-width", value: "3" },
                  { property: "stroke-miterlimit", value: "10" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "path",
                    classes: [],
                    styles: [],
                    attributes: [
                      { property: "d", value: "m5.8 17.5c8.6-5.6 18.8-8.9 29.8-8.9 9.4 0 18.3 2.4 26 6.6" },
                      { property: "stroke-dasharray", value: "8" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "path",
                    classes: [],
                    styles: [],
                    attributes: [{ property: "d", value: "m66.4 13.1l1.9 6.5h-6.8" }],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "svg",
                classes: ["d-block", "d-lg-none"],
                styles: [
                  { property: "position", value: "absolute" },
                  { property: "top", value: "0px" },
                  { property: "right", value: "0" },
                  { property: "transform", value: "translateY(50%) rotate(90deg)" },
                ],
                attributes: [
                  { property: "xmlns", value: "http://www.w3.org/2000/svg" },
                  { property: "version", value: "1.1" },
                  { property: "width", value: "100" },
                  { property: "viewBox", value: "0 0 72.3 26.7" },
                  { property: "fill", value: "none" },
                  { property: "stroke", value: "rgba(108, 117, 125, 1)" },
                  { property: "stroke-linecap", value: "round" },
                  { property: "stroke-width", value: "3" },
                  { property: "stroke-miterlimit", value: "10" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "path",
                    classes: [],
                    styles: [],
                    attributes: [
                      { property: "d", value: "m5.8 17.5c8.6-5.6 18.8-8.9 29.8-8.9 9.4 0 18.3 2.4 26 6.6" },
                      { property: "stroke-dasharray", value: "8" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "path",
                    classes: [],
                    styles: [],
                    attributes: [{ property: "d", value: "m66.4 13.1l1.9 6.5h-6.8" }],
                    content: "",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-3", "process-1-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "process1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "process-1-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["process-1-iconwrapper"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "div" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-laptop-code"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["process-1-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Do something else",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["toGrow", "process-1-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content:
                          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam cupiditate mollitia praesentium molestias aliquam eveniet, dolorumquidem deleniti.",
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "svg",
                classes: ["d-none", "d-lg-block"],
                styles: [
                  { property: "position", value: "absolute" },
                  { property: "top", value: "16px" },
                  { property: "left", value: "0" },
                  { property: "transform", value: "translateX(-50%)" },
                ],
                attributes: [
                  { property: "xmlns", value: "http://www.w3.org/2000/svg" },
                  { property: "version", value: "1.1" },
                  { property: "width", value: "100" },
                  { property: "viewBox", value: "0 0 72.3 26.7" },
                  { property: "fill", value: "none" },
                  { property: "stroke", value: "rgba(108, 117, 125, 1)" },
                  { property: "stroke-linecap", value: "round" },
                  { property: "stroke-width", value: "3" },
                  { property: "stroke-miterlimit", value: "10" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "path",
                    classes: [],
                    styles: [],
                    attributes: [
                      { property: "d", value: "m5.8 17.5c8.6-5.6 18.8-8.9 29.8-8.9 9.4 0 18.3 2.4 26 6.6" },
                      { property: "stroke-dasharray", value: "8" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "path",
                    classes: [],
                    styles: [],
                    attributes: [{ property: "d", value: "m66.4 13.1l1.9 6.5h-6.8" }],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "svg",
                classes: ["d-block", "d-lg-none"],
                styles: [
                  { property: "position", value: "absolute" },
                  { property: "top", value: "0px" },
                  { property: "right", value: "0" },
                  { property: "transform", value: "translateY(50%) rotate(90deg)" },
                ],
                attributes: [
                  { property: "xmlns", value: "http://www.w3.org/2000/svg" },
                  { property: "version", value: "1.1" },
                  { property: "width", value: "100" },
                  { property: "viewBox", value: "0 0 72.3 26.7" },
                  { property: "fill", value: "none" },
                  { property: "stroke", value: "rgba(108, 117, 125, 1)" },
                  { property: "stroke-linecap", value: "round" },
                  { property: "stroke-width", value: "3" },
                  { property: "stroke-miterlimit", value: "10" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "path",
                    classes: [],
                    styles: [],
                    attributes: [
                      { property: "d", value: "m5.8 17.5c8.6-5.6 18.8-8.9 29.8-8.9 9.4 0 18.3 2.4 26 6.6" },
                      { property: "stroke-dasharray", value: "8" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "path",
                    classes: [],
                    styles: [],
                    attributes: [{ property: "d", value: "m66.4 13.1l1.9 6.5h-6.8" }],
                    content: "",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-3", "process-1-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "process1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "process-1-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["process-1-iconwrapper"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "div" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-check"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["process-1-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "And done!", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["toGrow", "process-1-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, quas! Aut porro expedita nulla iste impedit.",
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "svg",
                classes: ["d-none", "d-lg-block"],
                styles: [
                  { property: "position", value: "absolute" },
                  { property: "top", value: "16px" },
                  { property: "left", value: "0" },
                  { property: "transform", value: "translateX(-50%)" },
                ],
                attributes: [
                  { property: "xmlns", value: "http://www.w3.org/2000/svg" },
                  { property: "version", value: "1.1" },
                  { property: "width", value: "100" },
                  { property: "viewBox", value: "0 0 72.3 26.7" },
                  { property: "fill", value: "none" },
                  { property: "stroke", value: "rgba(108, 117, 125, 1)" },
                  { property: "stroke-linecap", value: "round" },
                  { property: "stroke-width", value: "3" },
                  { property: "stroke-miterlimit", value: "10" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "path",
                    classes: [],
                    styles: [],
                    attributes: [
                      { property: "d", value: "m5.8 17.5c8.6-5.6 18.8-8.9 29.8-8.9 9.4 0 18.3 2.4 26 6.6" },
                      { property: "stroke-dasharray", value: "8" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "path",
                    classes: [],
                    styles: [],
                    attributes: [{ property: "d", value: "m66.4 13.1l1.9 6.5h-6.8" }],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "svg",
                classes: ["d-block", "d-lg-none"],
                styles: [
                  { property: "position", value: "absolute" },
                  { property: "top", value: "0px" },
                  { property: "right", value: "0" },
                  { property: "transform", value: "translateY(50%) rotate(90deg)" },
                ],
                attributes: [
                  { property: "xmlns", value: "http://www.w3.org/2000/svg" },
                  { property: "version", value: "1.1" },
                  { property: "width", value: "100" },
                  { property: "viewBox", value: "0 0 72.3 26.7" },
                  { property: "fill", value: "none" },
                  { property: "stroke", value: "rgba(108, 117, 125, 1)" },
                  { property: "stroke-linecap", value: "round" },
                  { property: "stroke-width", value: "3" },
                  { property: "stroke-miterlimit", value: "10" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "path",
                    classes: [],
                    styles: [],
                    attributes: [
                      { property: "d", value: "m5.8 17.5c8.6-5.6 18.8-8.9 29.8-8.9 9.4 0 18.3 2.4 26 6.6" },
                      { property: "stroke-dasharray", value: "8" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "path",
                    classes: [],
                    styles: [],
                    attributes: [{ property: "d", value: "m66.4 13.1l1.9 6.5h-6.8" }],
                    content: "",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.process-1-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.process-1-component", pseudo: "hover", rules: [] },
      {
        className: "process-1-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1.5rem 1rem 1.5rem" },
          { property: "display", value: "flex" },
          { property: "position", value: "relative" },
        ],
      },
      { className: "process-1-wrapper", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
      {
        className: "process-1-iconwrapper",
        pseudo: "",
        rules: [
          { property: "font-size", value: "2.5rem" },
          { property: "color", value: "rgba(108, 117, 125, 1)" },
        ],
      },
      {
        className: "process-1-iconwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "process-1-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 0 0 0" },
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "font-weight", value: "bold" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "process-1-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "process-1-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 0 0 0" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "process-1-text",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
