export const template7 = {
  id: "638fb8ddaf84536d43d2cfea",
  name: { en: "Consultancy agency", nl: "Adviesbureay" },
  desc: { en: "Single-page site for a consultancy agency", nl: "Een-pagina site voor een adviesbureau" },
  categories: ["services"],
  sbPages: [
    {
      pageId: "0acbdc64-cb83-44cc-9aa4-ddff6a8d5c89",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "SBJBDW",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-SBJBDW", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "true" },
            { property: "data-navbarposition", value: "sticky-top" },
          ],
          content: "",
          children: [
            {
              childId: "LowqOw",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "zrIrM6",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "ipaI8T",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-SBJBDW"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Logo" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-companyname.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "mxiuK5",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-SBJBDW", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "HjUDTc",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "NLMMwu",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "CSjn5K",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "zWnqXV",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-SBJBDW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Home",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "jFXIsu",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-SBJBDW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Products",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "yU4L8M",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-SBJBDW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "About",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "wT3hjW",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "hero-2-component-wT3hjW"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Gzmdxd",
              type: "",
              htmlTagName: "div",
              classes: ["row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "naYbPA",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-2-contentcol-wT3hjW", "col-12", "col-md-6", "col-lg-6", "p-3"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "m3NDOD",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-2-title-wT3hjW", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "We provide",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "wsKl4n",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["EHdY834LaK1UQdzcUnfNFB3vhN1VU0", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "h" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "solutions",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "jHkTIl",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["YPQSexlmbWCZftZ9TlJeurlRYUNB0u", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "h" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "for your business",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "KwUPk0",
                      type: "",
                      htmlTagName: "a",
                      classes: ["hero-2-button-wT3hjW"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                        { property: "data-href", value: "#services" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Learn more",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "KWa1uP",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "p-3", "hero-2-img-wT3hjWwrapper"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "DRPKgq",
                      type: "",
                      htmlTagName: "img",
                      classes: ["hero-2-img-wT3hjW"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/hero-2.png" },
                        { property: "alt", value: "Image" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "D4BZQT",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-D4BZQT"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Uj0bhC",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-D4BZQT", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "About us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "x6IBTS",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-x6IBTS"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "EiHoxu",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-x6IBTS", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores.",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "P9R2t0",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-15-component-P9R2t0"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-up" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "vvWpot",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-15-row-P9R2t0"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "dlxhBN",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "card-15-col-P9R2t0"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "PZMR5I",
                      type: "",
                      htmlTagName: "img",
                      classes: ["card-15-img-P9R2t0"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/work-12.png" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "NXKYmB",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "card-15-col-P9R2t0"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "wxc1IE",
                      type: "",
                      htmlTagName: "h4",
                      classes: ["card-15-title-P9R2t0", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "LgNcxP",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["d-flex", "flex-column", "align-items-start"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "aAvCFM",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "uLreAM",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-center", "card-15-iconwrapper-P9R2t0"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "p3yZm1",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-regular", "fa-star"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "QYJgRU",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-15-wrappertext"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "DjhtrY",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["card-15-text-P9R2t0", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "BxGQp8",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ICAHxX",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-center", "card-15-iconwrapper-P9R2t0"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "YZ0usJ",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-shield-halved"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "SK9xTd",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-15-wrappertext"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "tvAzeC",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["card-15-text-P9R2t0", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "aki4ia",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Viu368",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-center", "card-15-iconwrapper-P9R2t0"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "rOG3AM",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-regular", "fa-bell"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "xlLbaQ",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-15-wrappertext"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "nqIIeZ",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["card-15-text-P9R2t0", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "K90OMj",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-9-component-K90OMj"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-right" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "vDVWkj",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-9-row-K90OMj"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "DBe8Cm",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-9-col-K90OMj"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "bCj1zY",
                      type: "",
                      htmlTagName: "img",
                      classes: ["card-9-img-K90OMj"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/work-1.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "lCAeBa",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-9-col-K90OMj"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ONff8g",
                      type: "",
                      htmlTagName: "h3",
                      classes: ["card-9-title-K90OMj", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "nUzpZk",
                      type: "",
                      htmlTagName: "p",
                      classes: ["card-9-desc-text-K90OMj", "w-90", "w-md-90", "w-lg-90"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "AGm1mO",
                      type: "",
                      htmlTagName: "p",
                      classes: ["sLa54Rht7mKiKz84xLwkbnbdqMpWED", "w-90", "w-md-90", "w-lg-90"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "span",
                          classes: [],
                          styles: [{ property: "color", value: "rgb(10,10,10)" }],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                              children: [],
                            },
                          ],
                        },
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: " ", children: [] },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "QHBb0P",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-10-component-QHBb0P"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-left" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "TnGc9x",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-10-row-QHBb0P"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "t1D4ip",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-10-col-QHBb0P"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "XWyQzT",
                      type: "",
                      htmlTagName: "h3",
                      classes: ["card-10-title-QHBb0P", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Neque saepe temporibus repellat ea ipsum et. Id vel et quia tempora facere reprehenderit.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "pc3zcv",
                      type: "",
                      htmlTagName: "p",
                      classes: ["card-10-desc-text-QHBb0P", "w-90", "w-md-90", "w-lg-90"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "NvbCiV",
                      type: "",
                      htmlTagName: "p",
                      classes: ["DvoJePt6WoBSA59eRRRxhvX0L01ziy", "w-90", "w-md-90", "w-lg-90"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "span",
                          classes: [],
                          styles: [{ property: "color", value: "rgb(10,10,10)" }],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                              children: [],
                            },
                          ],
                        },
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: " ", children: [] },
                      ],
                    },
                  ],
                },
                {
                  childId: "C0w6eR",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-10-col-QHBb0P"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "mcwQJj",
                      type: "",
                      htmlTagName: "img",
                      classes: ["card-10-img-QHBb0P"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/work-1.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "NPepJo",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-NPepJo"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "tDwbz8",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-NPepJo", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Services", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "BsI36b",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-BsI36b"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "GXb1Uk",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-BsI36b", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores.",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "wFH9Nx",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-16-component-wFH9Nx"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "sifTil",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "WMyVVj",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "p-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card16" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "pVuVfr",
                      type: "",
                      htmlTagName: "div",
                      classes: ["d-flex", "card-16-element-wFH9Nx"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "zT6ceP",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex", "justify-content-start", "card-16-iconwrapper-wFH9Nx"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "Qocq6I",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-bullseye"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "zWzfFH",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-16-wrapper-wFH9Nxtext"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "QiQVj6",
                              type: "",
                              htmlTagName: "h4",
                              classes: ["card-16-title-wFH9Nx", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem Ipsum",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "vh3Mff",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-16-text-wFH9Nx", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "GBuMNZ",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "p-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card16" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 400 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "RIa1vQ",
                      type: "",
                      htmlTagName: "div",
                      classes: ["d-flex", "card-16-element-wFH9Nx"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "M7WmTi",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex", "justify-content-start", "card-16-iconwrapper-wFH9Nx"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "y3nKU8",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-dollar-sign"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "k38XLV",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-16-wrapper-wFH9Nxtext"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "n6sGSE",
                              type: "",
                              htmlTagName: "h4",
                              classes: ["card-16-title-wFH9Nx", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Dolor Sitema",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Ttiwma",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-16-text-wFH9Nx", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "ZwlAjT",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "p-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card16" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "o1WrP5",
                      type: "",
                      htmlTagName: "div",
                      classes: ["d-flex", "card-16-element-wFH9Nx"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "X8AxTn",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex", "justify-content-start", "card-16-iconwrapper-wFH9Nx"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "rD7XPA",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-panorama"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "D8kZNm",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-16-wrapper-wFH9Nxtext"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "NUKBIj",
                              type: "",
                              htmlTagName: "h4",
                              classes: ["card-16-title-wFH9Nx", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Sed ut perspiciatis",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "TBNGTo",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-16-text-wFH9Nx", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "c03gIc",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "p-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card16" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 1000 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "p4S0uN",
                      type: "",
                      htmlTagName: "div",
                      classes: ["d-flex", "card-16-element-wFH9Nx"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "MGBtor",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex", "justify-content-start", "card-16-iconwrapper-wFH9Nx"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "TOTJsr",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-handshake-simple"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "GAVBOC",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-16-wrapper-wFH9Nxtext"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "nqkk8s",
                              type: "",
                              htmlTagName: "h4",
                              classes: ["card-16-title-wFH9Nx", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Magni Dolores",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "yJtQZN",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-16-text-wFH9Nx", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "w8vwI9",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-w8vwI9"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "c3SaEf",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-w8vwI9", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Why choose us?", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "xA60bp",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-xA60bp"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "IO11CQ",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-xA60bp", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores.",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "LYvljM",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-13-component-LYvljM"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Cxj9eW",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "oyxbMM",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-LYvljM"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade-right" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "pkgTK1",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-LYvljM"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "wRnwRn",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-LYvljM"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "ZkwxSE",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-chart-simple"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "hrg0rf",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-LYvljM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem Ipsum",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "IQYUuq",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-LYvljM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "QHNRcW",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-LYvljM"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade-right" },
                    { property: "data-aos-delay", value: 400 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "hNVjiz",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-LYvljM"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "Qca0NB",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-LYvljM"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "ERJtXl",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-regular", "fa-paper-plane"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "MtWp3J",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-LYvljM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Dolor Sitema",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "MZKfpJ",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-LYvljM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "FAlM0o",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-LYvljM"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade-right" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ekUbQD",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-LYvljM"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "k7RrBW",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-LYvljM"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "mjVgD7",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-layer-group"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "JZhg1N",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-LYvljM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Sed ut perspiciatis",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "HUVbrd",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-LYvljM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "vdzaEO",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-LYvljM"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade-right" },
                    { property: "data-aos-delay", value: 1000 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "RJIjoX",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-LYvljM"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "dhR3sW",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-LYvljM"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "u3dX6K",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-users-line"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "kSbKdA",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-LYvljM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Magni Dolores",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "rwWhY3",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-LYvljM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "IYa9AC",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "statistics-2-component-IYa9AC"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "F2UjZw",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "statistics-2-statswrapper", "w-100"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "whxX6v",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-right" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "OaNC82",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-IYa9AC"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "C9Xa9I",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-2-stat-number-IYa9AC", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "4", children: [] },
                          ],
                        },
                        {
                          childId: "eq6CHA",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-IYa9AC", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Years active",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "NskJ1O",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-right" },
                    { property: "data-aos-delay", value: 400 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "uBVWzd",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-IYa9AC"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "CqO1vw",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-2-stat-number-IYa9AC", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "27", children: [] },
                          ],
                        },
                        {
                          childId: "ayt3W3",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-IYa9AC", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Employees",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "aMKJta",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-right" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "JxDiMG",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-IYa9AC"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "kmaUWl",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-2-stat-number-IYa9AC", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "200+",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "NaECDy",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-IYa9AC", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Projects delivered",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "jHGm1C",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "fade-right" },
                    { property: "data-aos-delay", value: 1000 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ERips3",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-IYa9AC"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "uaGKZw",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-2-stat-number-IYa9AC", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "€20m",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "IYdSzS",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-IYa9AC", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Funding",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "uG8g5r",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-uG8g5r"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "DXifT3",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-uG8g5r", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Testimonials", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "vRzrMU",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "quote-11-component-vRzrMU"],
          styles: [],
          attributes: [
            { property: "data-name", value: "carousel" },
            { property: "data-editable", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "JL2D2T",
              type: "",
              htmlTagName: "div",
              classes: ["splide", "carousel-slides", "quote-11-splide"],
              styles: [],
              attributes: [
                { property: "aria-label", value: "Carousel" },
                { property: "data-carousel-slidesetup", value: "image&text" },
                {
                  property: "data-splide",
                  value:
                    '{"keyboard":true,"autoplay":true,"interval":2000,"pauseOnHover":true,"breakpoints":{"640":{"perPage":1,"perMove":1}},"drag":"free","easing":"linear","arrows":true,"pagination":false,"type":"loop","speed":500,"rewindSpeed":1000,"perPage":2,"perMove":1,"classes":{"arrow":"splide__arrow quote-11-arrow-vRzrMU","prev":"splide__arrow--prev quote-11-prev-vRzrMU","next":"splide__arrow--next quote-11-next-vRzrMU","page":"splide__pagination__page quote-11-pagination-vRzrMU"},"fixedHeightCss":"auto"}',
                },
              ],
              content: "",
              children: [
                {
                  childId: "L7p5ur",
                  type: "",
                  htmlTagName: "div",
                  classes: ["splide__track"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "gCetPA",
                      type: "",
                      htmlTagName: "div",
                      classes: ["splide__list"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "t4HcHX",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "quote-11-slide-wrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "wL5koj",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "justify-content-center", "align-items-stretch", "quote-11-row"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Nq2XDO",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-12", "col-lg-12", "quote-11-col-vRzrMU"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "callout_testimonial" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "n850FD",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "quote-11-wrapper-vRzrMU"],
                                      styles: [],
                                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "RjGCIV",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["toGrow", "quote-11-balloon-vRzrMU"],
                                          styles: [],
                                          attributes: [{ property: "data-elementgetter1", value: "true" }],
                                          content: "",
                                          children: [
                                            {
                                              childId: "eh7h4N",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["quote-11-text3-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                                { property: "data-name", value: "p" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content:
                                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "G9DTO7",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["d-flex", "align-items-center", "w-100"],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "k1XJq5",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["quote-11-avatar-vRzrMU"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-name", value: "img" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                            {
                                              childId: "CA6c0s",
                                              type: "",
                                              htmlTagName: "div",
                                              classes: ["quote-5-captionwrapper"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "Gh2syJ",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["quote-11-text1-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-name", value: "p" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Name",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                                {
                                                  childId: "OJx71j",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["quote-11-text2-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-name", value: "p" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Function / company /  location",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "Hs6uqh",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "quote-11-slide-wrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "SJpwUW",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "justify-content-center", "align-items-stretch", "quote-11-row"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "e18Np0",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-12", "col-lg-12", "quote-11-col-vRzrMU"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "callout_testimonial" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "K6aPV0",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "quote-11-wrapper-vRzrMU"],
                                      styles: [],
                                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "bz6pCF",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["toGrow", "quote-11-balloon-vRzrMU"],
                                          styles: [],
                                          attributes: [{ property: "data-elementgetter1", value: "true" }],
                                          content: "",
                                          children: [
                                            {
                                              childId: "sqMNq4",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["quote-11-text3-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                                { property: "data-name", value: "p" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content:
                                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "Cui0W5",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["d-flex", "align-items-center", "w-100"],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "uuX5lf",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["quote-11-avatar-vRzrMU"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-name", value: "img" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                            {
                                              childId: "oJYIZS",
                                              type: "",
                                              htmlTagName: "div",
                                              classes: ["quote-5-captionwrapper"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "rnIcHs",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["quote-11-text1-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-name", value: "p" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Name",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                                {
                                                  childId: "TXxpfu",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["quote-11-text2-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-name", value: "p" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Function / company /  location",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "vcJapx",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "quote-11-slide-wrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mcqhiZ",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "justify-content-center", "align-items-stretch", "quote-11-row"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "oHdo9G",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-12", "col-lg-12", "quote-11-col-vRzrMU"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "callout_testimonial" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Q5WlFi",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "quote-11-wrapper-vRzrMU"],
                                      styles: [],
                                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "AqGGzV",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["toGrow", "quote-11-balloon-vRzrMU"],
                                          styles: [],
                                          attributes: [{ property: "data-elementgetter1", value: "true" }],
                                          content: "",
                                          children: [
                                            {
                                              childId: "wHjq3U",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["quote-11-text3-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                                { property: "data-name", value: "p" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content:
                                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "EdP9fq",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["d-flex", "align-items-center", "w-100"],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "EAdFM0",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["quote-11-avatar-vRzrMU"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-name", value: "img" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                            {
                                              childId: "pa6ZRZ",
                                              type: "",
                                              htmlTagName: "div",
                                              classes: ["quote-5-captionwrapper"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "ZYMXOU",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["quote-11-text1-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-name", value: "p" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Name",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                                {
                                                  childId: "qQX9lW",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["quote-11-text2-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-name", value: "p" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Function / company /  location",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "AmEMLC",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "quote-11-slide-wrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "i55dMp",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "justify-content-center", "align-items-stretch", "quote-11-row"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "mzza9M",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-12", "col-lg-12", "quote-11-col-vRzrMU"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "callout_testimonial" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ZEhCPD",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "quote-11-wrapper-vRzrMU"],
                                      styles: [],
                                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "xQqlFx",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["toGrow", "quote-11-balloon-vRzrMU"],
                                          styles: [],
                                          attributes: [{ property: "data-elementgetter1", value: "true" }],
                                          content: "",
                                          children: [
                                            {
                                              childId: "qqTbyY",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["quote-11-text3-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                                { property: "data-name", value: "p" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content:
                                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "xmu6cd",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["d-flex", "align-items-center", "w-100"],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "cAbVOs",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["quote-11-avatar-vRzrMU"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-name", value: "img" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                            {
                                              childId: "IIRPOD",
                                              type: "",
                                              htmlTagName: "div",
                                              classes: ["quote-5-captionwrapper"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "Qn3ZCE",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["quote-11-text1-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-name", value: "p" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Name",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                                {
                                                  childId: "HMUHWE",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["quote-11-text2-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-name", value: "p" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Function / company /  location",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "MpfaB0",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "quote-11-slide-wrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "cdRg7J",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "justify-content-center", "align-items-stretch", "quote-11-row"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "z7qTSs",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-12", "col-lg-12", "quote-11-col-vRzrMU"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "callout_testimonial" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "AqLAHf",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "quote-11-wrapper-vRzrMU"],
                                      styles: [],
                                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "l8mFDZ",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["toGrow", "quote-11-balloon-vRzrMU"],
                                          styles: [],
                                          attributes: [{ property: "data-elementgetter1", value: "true" }],
                                          content: "",
                                          children: [
                                            {
                                              childId: "q3k771",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["quote-11-text3-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                                { property: "data-name", value: "p" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content:
                                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "LNv04r",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["d-flex", "align-items-center", "w-100"],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "h8j3pb",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["quote-11-avatar-vRzrMU"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-name", value: "img" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                            {
                                              childId: "D4Mep7",
                                              type: "",
                                              htmlTagName: "div",
                                              classes: ["quote-5-captionwrapper"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "LRWSUz",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["quote-11-text1-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-name", value: "p" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Name",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                                {
                                                  childId: "mMDDSM",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["quote-11-text2-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-name", value: "p" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Function / company /  location",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "Mzt4iD",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "quote-11-slide-wrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "cQU8ys",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "justify-content-center", "align-items-stretch", "quote-11-row"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "wpLXTc",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-12", "col-lg-12", "quote-11-col-vRzrMU"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "callout_testimonial" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "NLMEwu",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "quote-11-wrapper-vRzrMU"],
                                      styles: [],
                                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "smaGAs",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["toGrow", "quote-11-balloon-vRzrMU"],
                                          styles: [],
                                          attributes: [{ property: "data-elementgetter1", value: "true" }],
                                          content: "",
                                          children: [
                                            {
                                              childId: "zjVdT3",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["quote-11-text3-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                                { property: "data-name", value: "p" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content:
                                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "cLVjtO",
                                          type: "",
                                          htmlTagName: "div",
                                          classes: ["d-flex", "align-items-center", "w-100"],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "wPROMH",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["quote-11-avatar-vRzrMU"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-name", value: "img" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                            {
                                              childId: "o7tEfE",
                                              type: "",
                                              htmlTagName: "div",
                                              classes: ["quote-5-captionwrapper"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "NDcAXD",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["quote-11-text1-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-name", value: "p" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Name",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                                {
                                                  childId: "z4wTHd",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["quote-11-text2-vRzrMU", "w-100", "w-md-100", "w-lg-100"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-name", value: "p" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Function / company /  location",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "dU1RRj",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-dU1RRj"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "pRRdDn",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-dU1RRj", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [{ childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Team", children: [] }],
            },
          ],
        },
        {
          componentId: "nDkoQl",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-nDkoQl"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "A0Xwga",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-nDkoQl", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. ",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "Y8WvUa",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-2-component-Y8WvUa"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "MDNFJy",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "MEMp7J",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-2-col-Y8WvUa"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card2" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "fw1C6G",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-2-overlay-wrapper-Y8WvUa"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "kpbHwV",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-2-avatar-Y8WvUa"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-1.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "AX3vv8",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-2-overlay-content-Y8WvUa"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "f1NEho",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-2-title-Y8WvUa"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "AbRr1J",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-2-subtitle-Y8WvUa"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "FrriqP",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "MRPDqE",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-Y8WvUa"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "zTRLQZ",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "gGbrOv",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-Y8WvUa"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "t6nIDk",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "bJ0CzN",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-Y8WvUa"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "zeUbIj",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "ytb1Nq",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-Y8WvUa"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "avWm2e",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "TGok4y",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-2-col-Y8WvUa"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card2" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 400 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "d9A0Ft",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-2-overlay-wrapper-Y8WvUa"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "VQRyiI",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-2-avatar-Y8WvUa"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-2.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "JsfbJ0",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-2-overlay-content-Y8WvUa"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "zzdK9t",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-2-title-Y8WvUa"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "gPmaDe",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-2-subtitle-Y8WvUa"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "t27MmA",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "JkN7TM",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-Y8WvUa"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "uH4pVW",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "k66Dc3",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-Y8WvUa"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "k8t0Rs",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "auKyMc",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-Y8WvUa"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "PVlDwX",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "VYCZ7F",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-Y8WvUa"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "uukDqb",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "OWd3me",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-2-col-Y8WvUa"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card2" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "x7MW4q",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-2-overlay-wrapper-Y8WvUa"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "vzJimd",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-2-avatar-Y8WvUa"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "FwPEFj",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-2-overlay-content-Y8WvUa"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "MDM3c2",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-2-title-Y8WvUa"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "kSuIzz",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-2-subtitle-Y8WvUa"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "yqzNsT",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "iJzbwW",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-Y8WvUa"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "VeF5BR",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "P2qPuu",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-Y8WvUa"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "xYJqQi",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "TGOYL4",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-Y8WvUa"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "A2mlAT",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "dupGb7",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-Y8WvUa"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "RTXU9f",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "XQde9n",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-XQde9n"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "fLiOT4",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-XQde9n", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Our customers", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "SSQb74",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-SSQb74"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "QBQZeI",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-SSQb74", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores.",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "f005Cr",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "clients-2-component-f005Cr"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "zVp8Q3",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-center", "justify-content-center", "clients-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "adYkL0",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper-f005Cr"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade-down" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "Yg5gzs",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-f005Cr"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "YdIVd3",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper-f005Cr"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade-down" },
                    { property: "data-aos-delay", value: 400 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "NtU6Zb",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-f005Cr"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "VVYeE2",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper-f005Cr"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade-down" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ApnEhK",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-f005Cr"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-3.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "IdxTFx",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper-f005Cr"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade-down" },
                    { property: "data-aos-delay", value: 1000 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "I4eIaB",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-f005Cr"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-4.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "FGMiTd",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-FGMiTd"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "ldXt3x",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-FGMiTd", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Get in touch", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "Px83l5",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-3-component-Px83l5"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "bMKChP",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "yeuUPD",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "p-2", "contactus-3-googlemaps-col-Px83l5"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "kY2e49",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-3-googlemaps-wrapper-Px83l5"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "googlemaps_wrapper" },
                        { property: "data-editable", value: "true" },
                      ],
                      content: "",
                      children: [],
                    },
                    {
                      childId: "ncL2Wt",
                      type: "",
                      htmlTagName: "iframe",
                      classes: [],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "googlemaps" },
                        {
                          property: "src",
                          value:
                            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8680.352904042613!2d4.899406522082844!3d52.37329199453203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609b70dd81623%3A0xcae71b8d3adfd142!2sAmsterdam%20Centraal!5e0!3m2!1sen!2snl!4v1662818615871!5m2!1sen!2snl",
                        },
                        { property: "scrolling", value: "no" },
                        { property: "loading", value: "lazy" },
                        { property: "referrerpolicy", value: "no-referrer-when-downgrade" },
                        { property: "width", value: "100%" },
                        { property: "height", value: "100%" },
                        { property: "frameborder", value: "0" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "Mq56Zs",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "p-2"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "jKdp76",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["row", "justify-content-center", "align-items-stretch", "contactus-3-row"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "UBYQxB",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "contactus-3-col-Px83l5"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "wkG4uN",
                              type: "",
                              htmlTagName: "button",
                              classes: ["contactus-3-iconwrapper-Px83l5"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "Sr8hZD",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-location-dot"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "URYidn",
                              type: "",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "od3rUy",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["contactus-3-text-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Street 1337",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "d6u8WT",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["contactus-3-text-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Postal code, City",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "fyTaLW",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "contactus-3-col-Px83l5"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "r6F314",
                              type: "",
                              htmlTagName: "button",
                              classes: ["contactus-3-iconwrapper-Px83l5"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "Be9q4S",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-envelope"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "qP3Diq",
                              type: "",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "ivy3x1",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["contactus-3-text-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "email@domain.com",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "I9Jrqg",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "contactus-3-col-Px83l5"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "X7rfNy",
                              type: "",
                              htmlTagName: "button",
                              classes: ["contactus-3-iconwrapper-Px83l5"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "nJ2Yjy",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-phone"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "TEMv8a",
                              type: "",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "UT8W5S",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["contactus-3-text-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "02013 37 000",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "hOL6Ov",
                          type: "",
                          htmlTagName: "div",
                          classes: ["col-12", "p-2"],
                          styles: [],
                          attributes: [
                            { property: "data-sbform", value: "true" },
                            { property: "data-sbformaction", value: "" },
                            { property: "data-sbformmsg", value: "Your message has been received!" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "contactus-3-inputwrapper-Px83l5"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "contactus-3-label-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your name",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "contactus-3-inputfield-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "type", value: "text" },
                                    { property: "name", value: "name" },
                                    { property: "placeholder", value: "Your name" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "contactus-3-inputwrapper-Px83l5"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "contactus-3-label-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your email",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "contactus-3-inputfield-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "type", value: "email" },
                                    { property: "name", value: "email" },
                                    { property: "placeholder", value: "Your email address" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "contactus-3-inputwrapper-Px83l5"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "contactus-3-label-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Subject",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "contactus-3-inputfield-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "type", value: "text" },
                                    { property: "name", value: "subject" },
                                    { property: "placeholder", value: "Subject" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "contactus-3-inputwrapper-Px83l5"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "contactus-3-label-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your message",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textarea",
                                  classes: ["form-control", "contactus-3-inputfield-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "rows", value: "3" },
                                    { property: "name", value: "message" },
                                    { property: "placeholder", value: "Message" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "div",
                              classes: ["contactus-3-buttonwrapper-Px83l5"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "button",
                                  classes: ["contactus-3-button-Px83l5"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "button" },
                                    { property: "data-sbformbtn", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Send message!",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  sbCustomCss: [
    {
      componentId: "SBJBDW",
      classes: [
        {
          className: "scrolledpast-SBJBDW.navbar-1-wrapper-SBJBDW",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "scrolledpast-SBJBDW .navbar-1-logo-SBJBDW", pseudo: "", rules: [{ property: "max-height", value: "40px" }] },
        {
          className: "navbar-1-wrapper-SBJBDW",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 10px 24px 0px rgba(0, 0, 0, 0.2)" },
          ],
        },
        {
          className: "navbar-1-logo-SBJBDW",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "50px" },
          ],
        },
        { className: "navbar-1-logo-SBJBDW", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-SBJBDW",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "background", value: "rgba(229, 229, 229, 0)" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-SBJBDW",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(178, 178, 178, 0)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
        {
          className: "navbar-1-link-SBJBDW",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(5, 112, 218, 1)" },
            { property: "text-decoration", value: "none" },
          ],
        },
        { className: "navbar-1-link-SBJBDW", pseudo: "hover", rules: [{ property: "color", value: "rgba(119, 181, 232, 1)" }] },
      ],
    },
    {
      componentId: "wT3hjW",
      classes: [
        {
          className: "section.hero-2-component-wT3hjW",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(150, 184, 196, 0.55)" },
          ],
        },
        { className: "section.hero-2-component-wT3hjW", pseudo: "hover", rules: [] },
        {
          className: "hero-2-contentcol-wT3hjW",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "hero-2-img-wT3hjW",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "border-radius", value: "0.75rem" },
          ],
        },
        { className: "hero-2-img-wT3hjW", pseudo: "hover", rules: [] },
        {
          className: "hero-2-title-wT3hjW",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-2-title-wT3hjW", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "hero-2-text-wT3hjW",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 3rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-2-text-wT3hjW", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "EHdY834LaK1UQdzcUnfNFB3vhN1VU0",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-decoration", value: "underline" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "EHdY834LaK1UQdzcUnfNFB3vhN1VU0", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "YPQSexlmbWCZftZ9TlJeurlRYUNB0u",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "YPQSexlmbWCZftZ9TlJeurlRYUNB0u", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "hero-2-button-wT3hjW",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(5, 112, 218, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(5, 112, 218, 1)" },
            { property: "border-radius", value: "0.4rem" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "4.5rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-2-button-wT3hjW",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(5, 112, 218, 1)" },
            { property: "border-color", value: "rgba(5, 112, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "D4BZQT",
      classes: [
        {
          className: "section.basics-1-component-D4BZQT",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-D4BZQT", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-D4BZQT",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-D4BZQT", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-D4BZQT",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-D4BZQT", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "x6IBTS",
      classes: [
        {
          className: "section.basics-2-component-x6IBTS",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-x6IBTS", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-x6IBTS",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-x6IBTS", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-x6IBTS",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(123, 182, 233, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-x6IBTS", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "P9R2t0",
      classes: [
        {
          className: "section.card-15-component-P9R2t0",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "2rem" },
          ],
        },
        { className: "section.card-15-component-P9R2t0", pseudo: "hover", rules: [] },
        { className: "card-15-row-P9R2t0", pseudo: "", rules: [] },
        {
          className: "card-15-col-P9R2t0",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0.5rem 0 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-15-col-P9R2t0", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(0, 0, 0, 0)" }] },
        {
          className: "card-15-img-P9R2t0",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "card-15-img-P9R2t0", pseudo: "hover", rules: [] },
        { className: "card-15-card-col-P9R2t0", pseudo: "", rules: [{ property: "padding", value: "0.5rem 0 0.5rem 0" }] },
        { className: "card-15-card-col-P9R2t0", pseudo: "hover", rules: [] },
        { className: "card-15-card-wrapper-P9R2t0", pseudo: "", rules: [] },
        { className: "card-15-card-wrapper-P9R2t0", pseudo: "hover", rules: [] },
        {
          className: "card-15-title-P9R2t0",
          pseudo: "",
          rules: [
            { property: "margin", value: "0.5rem 0 0 0" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-15-title-P9R2t0", pseudo: "hover", rules: [] },
        {
          className: "card-15-iconwrapper-P9R2t0",
          pseudo: "",
          rules: [
            { property: "font-size", value: "3rem" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "align-self", value: "center" },
            { property: "width", value: "7rem" },
          ],
        },
        { className: "card-15-iconwrapper-P9R2t0", pseudo: "hover", rules: [] },
        {
          className: "card-15-text-P9R2t0",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 1rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-15-text-P9R2t0", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "K90OMj",
      classes: [
        {
          className: "section.card-9-component-K90OMj",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", value: "rgba(147, 182, 194, 0.55)" },
          ],
        },
        { className: "section.card-9-component-K90OMj", pseudo: "hover", rules: [] },
        { className: "card-9-row-K90OMj", pseudo: "", rules: [] },
        {
          className: "sLa54Rht7mKiKz84xLwkbnbdqMpWED",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "sLa54Rht7mKiKz84xLwkbnbdqMpWED", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-9-desc-text-K90OMj",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-9-title-K90OMj",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-9-title-K90OMj", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-9-col-K90OMj",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(214, 214, 214, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        { className: "card-9-col-K90OMj", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }] },
        {
          className: "card-9-img-K90OMj",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "3rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-9-img-K90OMj",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "QHBb0P",
      classes: [
        {
          className: "section.card-10-component-QHBb0P",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "background", value: "rgba(147, 182, 194, 0.55)" },
          ],
        },
        { className: "section.card-10-component-QHBb0P", pseudo: "hover", rules: [] },
        { className: "card-10-row-QHBb0P", pseudo: "", rules: [] },
        {
          className: "DvoJePt6WoBSA59eRRRxhvX0L01ziy",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "DvoJePt6WoBSA59eRRRxhvX0L01ziy", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-10-desc-text-QHBb0P",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-title-QHBb0P",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-10-title-QHBb0P", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-10-col-QHBb0P",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(214, 214, 214, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        { className: "card-10-col-QHBb0P", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }] },
        {
          className: "card-10-img-QHBb0P",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "2rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-img-QHBb0P",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "NPepJo",
      classes: [
        {
          className: "section.basics-1-component-NPepJo",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-NPepJo", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-NPepJo",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-NPepJo", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-NPepJo",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-NPepJo", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "BsI36b",
      classes: [
        {
          className: "section.basics-2-component-BsI36b",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-BsI36b", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-BsI36b",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-BsI36b", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-BsI36b",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(123, 182, 233, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-BsI36b", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "wFH9Nx",
      classes: [
        {
          className: "section.card-16-component-wFH9Nx",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "3rem" },
          ],
        },
        { className: "section.card-16-component-wFH9Nx", pseudo: "hover", rules: [] },
        { className: "card-16-wrapper-wFH9Nx", pseudo: "", rules: [] },
        { className: "card-16-wrapper-wFH9Nx", pseudo: "hover", rules: [] },
        {
          className: "card-16-iconwrapper-wFH9Nx",
          pseudo: "",
          rules: [
            { property: "font-size", value: "3rem" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "align-self", value: "start" },
            { property: "width", value: "8rem" },
          ],
        },
        { className: "card-16-iconwrapper-wFH9Nx", pseudo: "hover", rules: [] },
        {
          className: "card-16-title-wFH9Nx",
          pseudo: "",
          rules: [
            { property: "margin", value: "0.5rem 0 0 0" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-16-title-wFH9Nx", pseudo: "hover", rules: [] },
        {
          className: "card-16-text-wFH9Nx",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 1rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-16-text-wFH9Nx", pseudo: "hover", rules: [] },
        {
          className: "card-16-element-wFH9Nx",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(150, 150, 150, 0)" },
            { property: "border-radius", value: "0.75rem" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "background", value: "rgba(246, 246, 246, 1)" },
          ],
        },
        {
          className: "card-16-element-wFH9Nx",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(150, 150, 150, 0)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
            { property: "background", value: "rgba(119, 181, 232, 0.3)" },
          ],
        },
      ],
    },
    {
      componentId: "w8vwI9",
      classes: [
        {
          className: "section.basics-1-component-w8vwI9",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", value: "rgba(147, 182, 194, 0.55)" },
          ],
        },
        { className: "section.basics-1-component-w8vwI9", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-w8vwI9",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-w8vwI9", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-w8vwI9",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "2rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-w8vwI9", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "xA60bp",
      classes: [
        {
          className: "section.basics-2-component-xA60bp",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "background", value: "rgba(147, 182, 194, 0.55)" },
          ],
        },
        { className: "section.basics-2-component-xA60bp", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-xA60bp",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-xA60bp", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-xA60bp",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(123, 182, 233, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-xA60bp", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "LYvljM",
      classes: [
        {
          className: "section.card-13-component-LYvljM",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", value: "rgba(147, 182, 194, 0.55)" },
          ],
        },
        { className: "section.card-13-component-LYvljM", pseudo: "hover", rules: [] },
        { className: "card-13-col-LYvljM", pseudo: "", rules: [] },
        { className: "card-13-col-LYvljM", pseudo: "hover", rules: [] },
        { className: "card-13-wrapper-LYvljM", pseudo: "", rules: [] },
        { className: "card-13-wrapper-LYvljM", pseudo: "hover", rules: [] },
        {
          className: "card-13-iconwrapper-LYvljM",
          pseudo: "",
          rules: [
            { property: "font-size", value: "2.5rem" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-13-iconwrapper-LYvljM", pseudo: "hover", rules: [] },
        {
          className: "card-13-title-LYvljM",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 0 0" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-13-title-LYvljM", pseudo: "hover", rules: [] },
        {
          className: "card-13-text-LYvljM",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-13-text-LYvljM", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "IYa9AC",
      classes: [
        {
          className: "section.statistics-2-component-IYa9AC",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", value: "rgba(147, 182, 194, 0.55)" },
          ],
        },
        { className: "section.statistics-2-component-IYa9AC", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-component-IYa9AC",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-2-component-IYa9AC", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-stat-col-IYa9AC",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-2-stat-col-IYa9AC", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-stat-number-IYa9AC",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(123, 182, 233, 1)" },
            { property: "font-size", value: "20px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "2rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "statistics-2-stat-caption-IYa9AC",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
      ],
    },
    {
      componentId: "vRzrMU",
      classes: [
        {
          className: "section.quote-11-component-vRzrMU",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.quote-11-component-vRzrMU", pseudo: "hover", rules: [] },
        { className: "quote-11-wrapper-vRzrMU", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
        { className: "quote-11-wrapper-vRzrMU", pseudo: "hover", rules: [] },
        {
          className: "quote-11-avatar-vRzrMU",
          pseudo: "",
          rules: [
            { property: "border-radius", value: "50%" },
            { property: "height", value: "100px" },
            { property: "width", value: "auto" },
            { property: "margin", value: "1rem 0 0 0" },
          ],
        },
        { className: "quote-11-avatar-vRzrMU", pseudo: "hover", rules: [] },
        {
          className: "quote-11-captionwrapper-vRzrMU",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "quote-11-captionwrapper-vRzrMU", pseudo: "hover", rules: [] },
        {
          className: "quote-11-quote-left-vRzrMU",
          pseudo: "",
          rules: [
            { property: "display", value: "inline" },
            { property: "font-size", value: "2rem" },
            { property: "color", value: "rgba(100, 100, 100, 1)" },
          ],
        },
        {
          className: "quote-11-text3-vRzrMU",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 1rem 1rem 1rem" },
            { property: "text-align", value: "center" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "quote-11-text3-vRzrMU", pseudo: "hover", rules: [] },
        {
          className: "quote-11-arrow-vRzrMU",
          pseudo: "",
          rules: [
            { property: "opacity", value: "1" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "height", value: "3.3rem" },
            { property: "width", value: "3.3rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "quote-11-arrow-vRzrMU",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: "1" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "quote-11-arrow-vRzrMU svg",
          pseudo: "",
          rules: [
            { property: "fill", value: "rgba(5, 112, 218, 1)" },
            { property: "height", value: "1.8rem" },
            { property: "width", value: "1.8rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        { className: "quote-11-arrow-vRzrMU svg", pseudo: "hover", rules: [{ property: "fill", value: "rgba(119, 181, 232, 1)" }] },
        { className: "quote-11-prev-vRzrMU", pseudo: "", rules: [{ property: "left", value: "-3.3rem" }] },
        { className: "quote-11-next-vRzrMU", pseudo: "", rules: [{ property: "right", value: "-3.3rem" }] },
        {
          className: "quote-11-pagination-vRzrMU",
          pseudo: "",
          rules: [
            { property: "height", value: "0.7rem" },
            { property: "width", value: "0.7rem" },
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "margin", value: "0.25rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "inline-block" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "quote-11-pagination-vRzrMU",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "is-active.quote-11-pagination-vRzrMU",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "quote-11-text1-vRzrMU",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "quote-11-text1-vRzrMU", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "quote-11-text2-vRzrMU",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(123, 182, 233, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "quote-11-text2-vRzrMU", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "quote-11-col-vRzrMU",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
          ],
        },
        { className: "quote-11-col-vRzrMU", pseudo: "hover", rules: [] },
        {
          className: "quote-11-balloon-vRzrMU",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "box-shadow", value: "10px 10px 5px -5px rgba(115, 179, 231, 0.3)" },
            { property: "background", value: "rgba(119, 181, 232, 1)" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "width", value: "100%" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "border-radius", value: "0.4rem" },
          ],
        },
        {
          className: "quote-11-balloon-vRzrMU",
          pseudo: "hover",
          rules: [
            { property: "box-shadow", value: "10px 10px 5px -5px rgba(115, 179, 231, 0.3)" },
            { property: "background", value: "rgba(119, 181, 232, 1)" },
          ],
        },
        {
          className: "quote-11-balloon-vRzrMU",
          pseudo: "after",
          rules: [
            { property: "content", value: '""' },
            { property: "position", value: "absolute" },
            { property: "top", value: "100%" },
            { property: "width", value: "0" },
            { property: "height", value: "0" },
            { property: "border-top", value: "25px solid rgba(119, 181, 232, 1)" },
            { property: "border-right", value: "40px solid transparent" },
            { property: "left", value: "17%" },
            { property: "filter", value: "drop-shadow(1px 5px 2px rgba(115, 179, 231, 0.3))" },
          ],
        },
      ],
    },
    {
      componentId: "uG8g5r",
      classes: [
        {
          className: "section.basics-1-component-uG8g5r",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-uG8g5r", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-uG8g5r",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-uG8g5r", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-uG8g5r",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-uG8g5r", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "dU1RRj",
      classes: [
        {
          className: "section.basics-1-component-dU1RRj",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-dU1RRj", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-dU1RRj",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-dU1RRj", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-dU1RRj",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-dU1RRj", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "nDkoQl",
      classes: [
        {
          className: "section.basics-2-component-nDkoQl",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-nDkoQl", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-nDkoQl",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-nDkoQl", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-nDkoQl",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(123, 182, 233, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-nDkoQl", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "XQde9n",
      classes: [
        {
          className: "section.basics-1-component-XQde9n",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-XQde9n", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-XQde9n",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-XQde9n", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-XQde9n",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-XQde9n", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "SSQb74",
      classes: [
        {
          className: "section.basics-2-component-SSQb74",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-SSQb74", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-SSQb74",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-SSQb74", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-SSQb74",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(123, 182, 233, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-SSQb74", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Y8WvUa",
      classes: [
        {
          className: "section.card-2-component-Y8WvUa",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-2-component-Y8WvUa", pseudo: "hover", rules: [] },
        {
          className: "card-2-avatar-Y8WvUa",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
          ],
        },
        { className: "card-2-avatar-Y8WvUa", pseudo: "hover", rules: [] },
        {
          className: "card-2-subtitle-Y8WvUa",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(230, 230, 230, 1)" },
          ],
        },
        { className: "card-2-subtitle-Y8WvUa", pseudo: "hover", rules: [] },
        {
          className: "card-2-col-Y8WvUa",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
          ],
        },
        {
          className: "card-2-overlay-wrapper-Y8WvUa",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "width", value: "250px" },
            { property: "height", value: "250px" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "card-2-overlay-wrapper-Y8WvUa",
          pseudo: "hover .card-2-overlay-content-Y8WvUa",
          rules: [
            { property: "visibility", value: "visible" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "card-2-overlay-content-Y8WvUa",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "visibility", value: "hidden" },
            { property: "opacity", value: "0" },
            { property: "align-items", value: "center" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "background", value: "rgba(119, 181, 232, 0.4)" },
          ],
        },
        {
          className: "card-2-title-Y8WvUa",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(233, 236, 239, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-2-title-Y8WvUa", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-2-social-link-Y8WvUa",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 0.5rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "align-self", value: "start" },
            { property: "font-size", value: "1rem" },
          ],
        },
        {
          className: "card-2-social-link-Y8WvUa",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(5, 112, 218, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "card-2-social-link-Y8WvUa", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      ],
    },
    {
      componentId: "f005Cr",
      classes: [
        {
          className: "section.clients-2-component-f005Cr",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.clients-2-component-f005Cr", pseudo: "hover", rules: [] },
        {
          className: "clients-2-wrapper-f005Cr",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(194, 197, 199)" },
            { property: "height", value: "75px" },
          ],
        },
        {
          className: "clients-2-logo-f005Cr",
          pseudo: "",
          rules: [
            { property: "max-height", value: "50px" },
            { property: "max-width", value: "100px" },
            { property: "height", value: "auto" },
            { property: "width", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "clients-2-logo-f005Cr", pseudo: "hover", rules: [{ property: "transform", value: "scale(1.15)" }] },
      ],
    },
    {
      componentId: "FGMiTd",
      classes: [
        {
          className: "section.basics-1-component-FGMiTd",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-FGMiTd", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-FGMiTd",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-FGMiTd", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-FGMiTd",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-FGMiTd", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Px83l5",
      classes: [
        {
          className: "section.contactus-3-component-Px83l5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-3-component-Px83l5", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-col-Px83l5",
          pseudo: "",
          rules: [
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "text-align", value: "center" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        {
          className: "contactus-3-iconwrapper-Px83l5",
          pseudo: "",
          rules: [
            { property: "height", value: "65px" },
            { property: "width", value: "65px" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(5, 114, 223, 1)" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "cursor", value: "default !important" },
          ],
        },
        {
          className: "contactus-3-iconwrapper-Px83l5",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(5, 114, 223, 1)" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "contactus-3-text-Px83l5", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "contactus-3-text-Px83l5", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-googlemaps-col-Px83l5",
          pseudo: "",
          rules: [
            { property: "overflow", value: "hidden" },
            { property: "position", value: "relative" },
          ],
        },
        {
          className: "contactus-3-googlemaps-wrapper-Px83l5",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "bottom", value: "0" },
          ],
        },
        {
          className: "contactus-3-buttonwrapper-Px83l5",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "contactus-3-buttonwrapper-Px83l5",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-3-button-Px83l5",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(5, 114, 223, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-3-button-Px83l5",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(5, 114, 223, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "contactus-3-inputwrapper-Px83l5",
          pseudo: "",
          rules: [{ property: "margin", value: "0 0 1rem 0" }],
        },
        {
          className: "contactus-3-inputfield-Px83l5",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(5, 114, 223, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-3-inputfield-Px83l5",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(5, 114, 223, 1)" },
            { property: "border-color", value: "rgba(5, 114, 223, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(5, 114, 223, 0.2)" },
          ],
        },
        {
          className: "contactus-3-inputfield-Px83l5",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "contactus-3-inputfield-Px83l5",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-3-label-Px83l5",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        {
          className: "contactus-3-label-Px83l5",
          pseudo: "hover",
          rules: [],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Montserrat",
    fontHeadersWeight: "300",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(5, 114, 223, 1)",
    color2: "rgba(123, 182, 233, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
