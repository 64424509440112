export const sectiondividers44 = {
  id: "sectiondividers-44",
  name: "Section dividers 44",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: [],
        styles: [
          { property: "position", value: "relative" },
          { property: "height", value: "190px" },
        ],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "svg",
            classes: [],
            styles: [
              { property: "position", value: "absolute" },
              { property: "top", value: "0" },
              { property: "left", value: "0" },
              { property: "width", value: "100%" },
              { property: "height", value: "190px" },
              { property: "transform", value: "scaleY(-1)" },
              { property: "background", value: "rgba(255, 255, 255, 1)" },
            ],
            attributes: [
              { property: "xmlns", value: "http://www.w3.org/2000/svg" },
              { property: "viewBox", value: "0 0 1000 99.387" },
              { property: "preserveAspectRatio", value: "xMidYMin slice" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "opacity", value: "0.12" },
                  {
                    property: "d",
                    value: "M983.871 32.258A16.129 16.129 0 0 1 1000 16.129a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129z",
                  },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "opacity", value: "0.12" },
                  {
                    property: "d",
                    value: "M951.613 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129z",
                  },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value: "M919.355 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129z",
                  },
                  { property: "opacity", value: "0.12" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M887.1 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0A16.129 16.129 0 0 1 887.1 32.258zM854.839 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM822.581 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM790.323 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129z",
                  },
                  { property: "opacity", value: "0.12" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M758.065 32.258a16.129 16.129 0 0 1 16.129-16.129 16.13 16.13 0 0 0-32.259 0 16.13 16.13 0 0 1 16.13 16.129zM725.806 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM693.548 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM661.29 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129z",
                  },
                  { property: "opacity", value: "0.12" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M629.032 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM596.774 32.258A16.129 16.129 0 0 1 612.9 16.129a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.132 16.129z",
                  },
                  { property: "opacity", value: "0.12" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value: "M564.516 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129z",
                  },
                  { property: "opacity", value: "0.12" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M532.258 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM500 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0A16.129 16.129 0 0 1 500 32.258z",
                  },
                  { property: "opacity", value: "0.12" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M467.742 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM435.484 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM403.226 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129z",
                  },
                  { property: "opacity", value: "0.12" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M370.968 32.258A16.129 16.129 0 0 1 387.1 16.129a16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.126 16.129zM338.71 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM306.452 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129z",
                  },
                  { property: "opacity", value: "0.12" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M274.194 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM241.935 32.258a16.13 16.13 0 0 1 16.13-16.129 16.13 16.13 0 0 0-32.259 0 16.129 16.129 0 0 1 16.129 16.129zM209.677 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129z",
                  },
                  { property: "opacity", value: "0.12" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M177.419 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM145.161 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM112.9 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0A16.129 16.129 0 0 1 112.9 32.258z",
                  },
                  { property: "opacity", value: "0.12" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value: "M80.645 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129z",
                  },
                  { property: "opacity", value: "0.12" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "opacity", value: "0.12" },
                  {
                    property: "d",
                    value:
                      "M16.129 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129zM48.387 32.258a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129z",
                  },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M951.613 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0zM919.355 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M887.1 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0zM854.839 32.258a16.129 16.129 0 0 1 16.129 16.129A16.129 16.129 0 0 1 887.1 32.258a16.129 16.129 0 0 0-32.258 0zM822.581 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0zM790.323 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M758.065 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0zM725.806 32.258a16.129 16.129 0 0 1 16.129 16.129 16.13 16.13 0 0 1 16.13-16.129 16.13 16.13 0 0 0-32.259 0zM693.548 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0zM661.29 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M629.032 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0zM596.774 32.258A16.129 16.129 0 0 1 612.9 48.387a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value: "M564.516 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M532.258 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0zM500 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0zM467.742 32.258a16.129 16.129 0 0 1 16.129 16.129A16.129 16.129 0 0 1 500 32.258a16.129 16.129 0 0 0-32.258 0zM435.484 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value: "M403.226 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M370.968 32.258A16.129 16.129 0 0 1 387.1 48.387a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0zM338.71 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value: "M306.452 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M274.194 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0zM241.935 32.258a16.13 16.13 0 0 1 16.13 16.129 16.129 16.129 0 0 1 16.129-16.129 16.13 16.13 0 0 0-32.259 0zM209.677 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M177.419 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0zM145.161 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-32.258 0zM112.9 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value: "M80.645 32.258a16.129 16.129 0 0 1 16.129 16.129A16.129 16.129 0 0 1 112.9 32.258a16.129 16.129 0 0 0-32.258 0z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M48.387 32.258a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0zM32.258 48.387a16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "opacity", value: "0.5" },
                  {
                    property: "d",
                    value:
                      "M983.871 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129A16.129 16.129 0 0 1 1000 48.387a16.129 16.129 0 0 0-16.129-16.129z",
                  },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "opacity", value: "0.5" },
                  {
                    property: "d",
                    value:
                      "M951.613 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129z",
                  },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M919.355 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M887.1 32.258a16.129 16.129 0 0 0-16.129 16.129A16.129 16.129 0 0 1 887.1 64.516a16.129 16.129 0 0 1 16.129-16.129A16.129 16.129 0 0 0 887.1 32.258zM854.839 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM822.581 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM790.323 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M758.065 32.258a16.13 16.13 0 0 0-16.13 16.129 16.13 16.13 0 0 1 16.13 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM725.806 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM693.548 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM661.29 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM629.032 32.258A16.129 16.129 0 0 0 612.9 48.387a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.126-16.129z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M596.774 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129A16.129 16.129 0 0 1 612.9 48.387a16.129 16.129 0 0 0-16.126-16.129zM564.516 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M532.258 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM500 32.258a16.129 16.129 0 0 0-16.129 16.129A16.129 16.129 0 0 1 500 64.516a16.129 16.129 0 0 1 16.129-16.129A16.129 16.129 0 0 0 500 32.258z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M467.742 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM435.484 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M403.226 32.258A16.129 16.129 0 0 0 387.1 48.387a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.132-16.129z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M370.968 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129A16.129 16.129 0 0 1 387.1 48.387a16.129 16.129 0 0 0-16.132-16.129zM338.71 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M306.452 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M274.194 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM241.935 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.13 16.13 0 0 1 16.13-16.129 16.13 16.13 0 0 0-16.13-16.129zM209.677 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM177.419 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM145.161 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM112.9 32.258a16.129 16.129 0 0 0-16.126 16.129A16.129 16.129 0 0 1 112.9 64.516a16.129 16.129 0 0 1 16.129-16.129A16.129 16.129 0 0 0 112.9 32.258z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M80.645 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "opacity", value: "0.5" },
                  {
                    property: "d",
                    value:
                      "M16.129 32.258A16.129 16.129 0 0 0 0 48.387a16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129z",
                  },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "opacity", value: "0.5" },
                  {
                    property: "d",
                    value:
                      "M48.387 32.258a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129z",
                  },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value: "M951.613 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M967.742 48.387a16.129 16.129 0 0 0-16.129 16.129 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129 16.129 16.129 0 0 0-16.129-16.129zM919.355 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M887.1 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129A16.129 16.129 0 0 1 887.1 64.516zM854.839 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129zM822.581 64.516a16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129zM790.323 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M758.065 64.516a16.13 16.13 0 0 0-32.259 0 16.129 16.129 0 0 1 16.129 16.129 16.13 16.13 0 0 1 16.13-16.129zM725.806 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129zM693.548 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129zM661.29 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M629.032 64.516a16.129 16.129 0 1 0-32.258 0A16.129 16.129 0 0 1 612.9 80.645a16.129 16.129 0 0 1 16.132-16.129zM596.774 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129zM564.516 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M532.258 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129zM500 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129A16.129 16.129 0 0 1 500 64.516z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value: "M467.742 64.516a16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M435.484 64.516a16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129zM403.226 64.516a16.129 16.129 0 0 0-32.258 0A16.129 16.129 0 0 1 387.1 80.645a16.129 16.129 0 0 1 16.126-16.129z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M370.968 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129zM338.71 64.516a16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M306.452 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129zM274.194 64.516a16.13 16.13 0 0 0-32.259 0 16.13 16.13 0 0 1 16.13 16.129 16.129 16.129 0 0 1 16.129-16.129zM241.935 64.516a16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129zM209.677 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M177.419 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129zM145.161 64.516a16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129zM112.9 64.516a16.129 16.129 0 0 0-32.258 0 16.129 16.129 0 0 1 16.132 16.129A16.129 16.129 0 0 1 112.9 64.516z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value: "M80.645 64.516a16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value: "M48.387 64.516a16.129 16.129 0 1 0-32.258 0 16.129 16.129 0 0 1 16.129 16.129 16.129 16.129 0 0 1 16.129-16.129z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M999.987 80.387a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.023a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.233 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.029a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.233 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0H64.5a16.118 16.118 0 0 0-32.232 0h-.026a16.118 16.118 0 0 0-32.232 0H0v19h1000v-19z",
                  },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1000 16.129a16.129 16.129 0 0 0-16.129 16.129A16.129 16.129 0 0 1 1000 48.387z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M1000 48.387a16.129 16.129 0 0 0-16.129 16.129A16.129 16.129 0 0 1 1000 80.645z" },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M0 16.129v32.257a16.129 16.129 0 0 1 16.129-16.128A16.129 16.129 0 0 0 0 16.129z" },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  { property: "d", value: "M0 48.387v32.257a16.129 16.129 0 0 1 16.129-16.128A16.129 16.129 0 0 0 0 48.387z" },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
