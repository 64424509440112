import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { getGalleryAssetLink } from "../../../lib/generalFunctions";
import { translate } from "../../../translations/translations";

const ImgGalleryCrop = ({ sb: { project }, imgToCrop, setImageCropping, setLocalLoading, makeAlert, uploadToBackend }) => {
  const [crop, setCrop] = useState({ unit: "%" });
  const [circularCrop, setCircularCrop] = useState(false);
  const [ruleOfThirds, setRuleOfThirds] = useState(true);
  const [aspectRatioForm, setAspectRatioForm] = useState("free");
  const [aspectRatioCrop, setAspectRatioCrop] = useState(undefined);
  const [locked, setLocked] = useState(false);

  const originalImg = useRef();
  const croppedWidth = useRef();
  const croppedHeight = useRef();

  const changeAspectRatio = (val) => {
    setAspectRatioForm(val);
    setAspectRatioCrop(val === "free" ? undefined : parseInt(val.match(/(\d+)\/(\d+)/)[1]) / parseInt(val.match(/(\d+)\/(\d+)/)[2]));
  };

  const clickCrop = async () => {
    setLocalLoading({ isLoading: true, msg: translate("mImgGalleryCrop.croppingImg", false, null) });
    try {
      // Get the right scaleX/Y given image may be resized due to being part of a col-[x]
      let cropImgOriginal = document.getElementById("cropImgOriginal");
      const scaleX = cropImgOriginal.naturalWidth / cropImgOriginal.width;
      const scaleY = cropImgOriginal.naturalHeight / cropImgOriginal.height;
      // Get data url and load in an image element
      let dataUrl = await getImgDataUrl();
      let image = await loadImage(dataUrl);
      if (image && crop.width && crop.height) {
        // Always change file to png, to allow transparent backgrounds
        let newFileName = changeFileExt(imgToCrop.fileName, "png");
        // Get a Blob/File that can be uploaded to backend
        let croppedImg = await getCroppedImg(image, crop, newFileName, "image/png", scaleX, scaleY);
        // Replace existing image on backend with newly cropped image
        await uploadToBackend([croppedImg]);
        // Go back to gallery page
        setImageCropping(null);
        makeAlert(translate("mImgGalleryCrop.cropSuccess", false, null), "success");
      }
    } catch (error) {
      console.error(error);
      makeAlert(translate("mImgGalleryCrop.errorWhileCropping", false, null), "danger");
    }
    setLocalLoading({ isLoading: false, msg: "" });
  };

  const getImgDataUrl = async () => {
    let response = await fetch(getGalleryAssetLink(project._id, imgToCrop.fileName));
    let blob = await response.blob();
    return await getBlobDataUrl(blob);
  };

  const getBlobDataUrl = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const loadImage = (src) => {
    // Have to await image having been fully loaded, else you get a blank croppedImg
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };

  const getCroppedImg = async (image, crop, fileName, mimeType, scaleX, scaleY) => {
    const canvas = document.createElement("canvas");
    // scaleX/Y determined based on the original image, to account for the image's sizing through layout/css
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          console.error("Canvas is empty");
          return;
        }
        blob.lastModifiedDate = new Date();
        blob.name = fileName;
        resolve(blob);
      }, mimeType);
    });
  };

  const changeFileExt = (fileName, newExt) => {
    return `${fileName.split(".").slice(0, -1).join(".")}.${newExt}`;
  };

  const onChangeCrop = (val) => {
    setCrop(val);
    croppedWidth.current.textContent = `${parseInt((val.width / originalImg.current.width) * originalImg.current.naturalWidth)}px`;
    croppedWidth.current.style.left = `${parseInt(val.x + val.width)}px`;
    croppedWidth.current.style.top = `${parseInt(val.y)}px`;
    croppedHeight.current.textContent = `${parseInt((val.height / originalImg.current.height) * originalImg.current.naturalHeight)}px`;
    croppedHeight.current.style.left = `${parseInt(val.x + val.width + 5)}px`;
    croppedHeight.current.style.top = `${parseInt(val.y + val.height)}px`;
    // If labels fall outside of the image, set to black color
    if (val.y < 10) {
      croppedWidth.current.style.color = "#000";
      croppedWidth.current.style.textShadow = "";
    } else {
      croppedWidth.current.style.color = "#e9ecef";
      croppedWidth.current.style.textShadow = "0px 0px 5px #000";
    }
    if (val.x + val.width > originalImg.current.width - 25) {
      croppedHeight.current.style.color = "#000";
      croppedHeight.current.style.textShadow = "";
    } else {
      croppedHeight.current.style.color = "#e9ecef";
      croppedHeight.current.style.textShadow = "0px 0px 5px #000";
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-6">
          <h4 className="text-primary">{translate("mImgGalleryCrop.cropImage", false, null)}</h4>
          {/* Crop window */}
          <div className="col-12 text-center posRelative" id="imgCropContainer">
            {imgToCrop && (
              <ReactCrop
                crop={crop}
                aspect={aspectRatioCrop}
                ruleOfThirds={ruleOfThirds}
                circularCrop={circularCrop}
                locked={locked}
                // onChange={(val) => setCrop(val)}
                onChange={(val) => onChangeCrop(val)}
              >
                <img
                  id="cropImgOriginal"
                  ref={originalImg}
                  alt={imgToCrop.fileName}
                  src={getGalleryAssetLink(project._id, imgToCrop.fileName)}
                  style={{ width: "auto", height: "auto" }}
                />
              </ReactCrop>
            )}
            <span
              className="posAbs fontSize08"
              ref={croppedWidth}
              style={{ transform: "translate(-100%, -100%)", color: "#e9ecef", textShadow: "0px 0px 5px #000" }}
            ></span>
            <span
              className="posAbs fontSize08"
              ref={croppedHeight}
              style={{ transform: "translate(0%, -100%)", color: "#e9ecef", textShadow: "0px 0px 5px #000" }}
            ></span>
          </div>
          {/* Options */}
          <h4 className="text-primary mt-4 mb-0">{translate("mImgGalleryCrop.cropOptions", false, null)}</h4>
          <div className="row">
            {/* 1) Aspect ratio */}
            <div className="col-12 col-md-6 px-3 py-2 d-flex align-items-center">
              <div className="w-50">{translate("mImgGalleryCrop.aspectRatio", false, null)}</div>
              <div className="w-50 d-flex justify-content-center">
                <select className="form-select form-select-sm" value={aspectRatioForm} onChange={(e) => changeAspectRatio(e.target.value)}>
                  <option value="free">{translate("mImgGalleryCrop.free", false, null)}</option>
                  <option value="1/1">{translate("mImgGalleryCrop.square", false, null)}</option>
                  <option value="16/9">16 : 9</option>
                  <option value="19/10">1.90 : 1</option>
                </select>
              </div>
            </div>
            {/* 2) Crop form */}
            <div className="col-12 col-md-6 px-3 py-2 d-flex align-items-center">
              <div className="w-50">{translate("mImgGalleryCrop.cropForm", false, null)}</div>
              <div className="w-50 d-flex justify-content-center">
                <select
                  className="form-select form-select-sm"
                  value={circularCrop === true}
                  onChange={(e) => setCircularCrop(e.target.value === "true")}
                >
                  <option value="false">{translate("mImgGalleryCrop.square", false, null)}</option>
                  <option value="true">{translate("mImgGalleryCrop.circular", false, null)}</option>
                </select>
              </div>
            </div>
            {/* 3) Rule of thirds */}
            <div className="col-12 col-md-6 px-3 py-2 d-flex align-items-center">
              <div className="w-50">{translate("mImgGalleryCrop.showRulers", false, null)}</div>
              <div className="w-50 d-flex justify-content-center">
                <select
                  className="form-select form-select-sm"
                  value={ruleOfThirds === true}
                  onChange={(e) => setRuleOfThirds(e.target.value === "true")}
                >
                  <option value="true">{translate("mImgGalleryCrop.yes", false, null)}</option>
                  <option value="false">{translate("mImgGalleryCrop.no", false, null)}</option>
                </select>
              </div>
            </div>
            {/* 4) Locked size */}
            <div className="col-12 col-md-6 px-3 py-2 d-flex align-items-center">
              <div className="w-50">{translate("mImgGalleryCrop.lockedSize", false, null)}</div>
              <div className="w-50 d-flex justify-content-center">
                <select className="form-select form-select-sm" value={locked === true} onChange={(e) => setLocked(e.target.value === "true")}>
                  <option value="true">{translate("mImgGalleryCrop.yes", false, null)}</option>
                  <option value="false">{translate("mImgGalleryCrop.no", false, null)}</option>
                </select>
              </div>
            </div>
          </div>
          {/* Buttons */}
          <div className="row mt-4">
            <div className="col-6">
              <button className="btn btn-danger w-100" onClick={() => setImageCropping(null)}>
                {translate("mImgGalleryCrop.cancel", false, null)}
              </button>
            </div>
            <div className="col-6">
              <button className="btn btn-success w-100" disabled={!(crop.width && crop.height)} onClick={clickCrop}>
                {translate("mImgGalleryCrop.cropImage", false, null)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ImgGalleryCrop.propTypes = {
  sb: PropTypes.object.isRequired,
  imgToCrop: PropTypes.object.isRequired,
  setImageCropping: PropTypes.func.isRequired,
  setLocalLoading: PropTypes.func.isRequired,
  makeAlert: PropTypes.func.isRequired,
  uploadToBackend: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(ImgGalleryCrop);
