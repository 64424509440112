import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Border from "./Border";
import Margin from "./Margin";
import ResponsiveLayout from "./ResponsiveLayout";
import BorderRadius from "./BorderRadius";
import BoxShadow from "./BoxShadow";
import Transition from "./Transition";
import OverlayColor from "./OverlayColor";
import Divider from "./formComponents/Divider";
import ImgSource from "./ImgSource";

const ImgGallery1 = ({ sb: { startStyles } }) => {
  return (
    <>
      {/* Layout */}
      <ResponsiveLayout />
      <Divider />
      <Margin />
      <Divider />
      {/* Image */}
      <ImgSource />
      <Divider />
      {/* Style */}
      <Border />
      <Divider />
      <BorderRadius />
      <Divider />
      <BoxShadow />
      <Divider />
      {/* Component */}
      <Transition />
      <Divider />
      <OverlayColor />
    </>
  );
};

ImgGallery1.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(ImgGallery1);
