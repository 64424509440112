import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";
import Select from "./formComponents/Select";
import Divider from "./formComponents/Divider";
import ColorPicker from "./formComponents/ColorPicker";

import Margin from "./Margin";
import Padding from "./Padding";
import BorderRadius from "./BorderRadius";
import Transition from "./Transition";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const InputFieldStyles = ({ sb: { startStyles } }) => {
  return (
    <>
      {/* Text size & color */}
      <Slider
        label={translate("cEditForms.inputfield_textSize", false, null)}
        id={"inputfield_fontSize"}
        min={0.5}
        max={2.5}
        step={0.1}
        startValue={formGetStartValue(startStyles, "inputfield_fontSize")}
        tooltipText={translate("cEditForms.inputfield_ttTextSize", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.inputfield_textColor", false, null)}
        id={"textColor"}
        startValue={formGetStartValue(startStyles, "textColor")}
        tooltipText={translate("cEditForms.inputfield_ttTextColor", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.inputfield_textColorFocus", false, null)}
        id={"textColorFocus"}
        startValue={formGetStartValue(startStyles, "textColorFocus")}
        tooltipText={translate("cEditForms.inputfield_ttTextColorFocus", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.inputfield_placeholderColor", false, null)}
        id={"inputfield_placeholderColor"}
        startValue={formGetStartValue(startStyles, "inputfield_placeholderColor")}
        tooltipText={translate("cEditForms.inputfield_ttPlaceholderColor", false, null)}
      />
      <Divider />
      {/* Background color */}
      <ColorPicker
        label={translate("cEditForms.bgColor", false, null)}
        id={"bgColor"}
        startValue={formGetStartValue(startStyles, "bgColor")}
        tooltipText={translate("cEditForms.inputfield_ttBgColor", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.inputfield_bgColorFocus", false, null)}
        id={"bgColorFocus"}
        startValue={formGetStartValue(startStyles, "bgColorFocus")}
        tooltipText={translate("cEditForms.inputfield_ttBgColorFocus", false, null)}
      />
      <Divider />
      {/* Border */}
      <Select
        label={translate("cEditForms.borderSides", false, null)}
        id={"borderSide"}
        options={[
          { val: "none", label: translate("cEditForms.noBorder", false, null) },
          { val: "all", label: translate("cEditForms.allSides", false, null) },
          { val: "left", label: translate("cEditForms.left", false, null) },
          { val: "right", label: translate("cEditForms.right", false, null) },
          { val: "top", label: translate("cEditForms.top", false, null) },
          { val: "bottom", label: translate("cEditForms.bottom", false, null) },
          { val: "topbottom", label: translate("cEditForms.topAndBottom", false, null) },
          { val: "leftright", label: translate("cEditForms.leftAndRight", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "borderSide")}
        tooltipText={translate("cEditForms.tooltipBorderSides", false, null)}
      />
      <Select
        label={translate("cEditForms.borderType", false, null)}
        id={"borderType"}
        options={[
          { val: "hidden", label: translate("cEditForms.hidden", false, null) },
          { val: "dotted", label: translate("cEditForms.dotted", false, null) },
          { val: "dashed", label: translate("cEditForms.dashed", false, null) },
          { val: "solid", label: translate("cEditForms.solid", false, null) },
          { val: "double", label: translate("cEditForms.double", false, null) },
          { val: "groove", label: translate("cEditForms.groove", false, null) },
          { val: "ridge", label: translate("cEditForms.ridge", false, null) },
          { val: "inset", label: translate("cEditForms.inset", false, null) },
          { val: "outset", label: translate("cEditForms.outset", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "borderType")}
        tooltipText={translate("cEditForms.tooltipBorderType", false, null)}
      />
      <Slider
        label={translate("cEditForms.borderSize", false, null)}
        id={"borderWidth"}
        min={0}
        max={10}
        step={1}
        startValue={formGetStartValue(startStyles, "borderWidth")}
        tooltipText={translate("cEditForms.tooltipBorderSize", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.borderColor", false, null)}
        id={"borderColor"}
        startValue={formGetStartValue(startStyles, "borderColor")}
        tooltipText={translate("cEditForms.tooltipBorderColor", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.inputfield_borderColorFocus", false, null)}
        id={"borderColorFocus"}
        startValue={formGetStartValue(startStyles, "borderColorFocus")}
        tooltipText={translate("cEditForms.inputfield_ttBorderColorFocus", false, null)}
      />
      <Divider />
      <BorderRadius />
      <Divider />
      {/* Box shadow */}
      <Slider
        label={translate("cEditForms.shadowHoriLength", false, null)}
        id={"boxShadowHori"}
        min={-100}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "boxShadowHori")}
        tooltipText={translate("cEditForms.tooltipShadowHoriLength", false, null)}
      />
      <Slider
        label={translate("cEditForms.shadowVerticalLength", false, null)}
        id={"boxShadowVert"}
        min={-100}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "boxShadowVert")}
        tooltipText={translate("cEditForms.tooltipShadowVerticalLength", false, null)}
      />
      <Slider
        label={translate("cEditForms.blurRadius", false, null)}
        id={"boxShadowBlur"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "boxShadowBlur")}
        tooltipText={translate("cEditForms.shadowBlur", false, null)}
      />
      <Slider
        label={translate("cEditForms.spreadRadius", false, null)}
        id={"boxShadowSpread"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "boxShadowSpread")}
        tooltipText={translate("cEditForms.shadowSpread", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.shadowColorOpacity", false, null)}
        id={"boxShadowRgba"}
        startValue={formGetStartValue(startStyles, "boxShadowRgba")}
        tooltipText={translate("cEditForms.shadowColor", false, null)}
      />
      <Slider
        label={translate("cEditForms.inputfield_shadowHoriLengthFocus", false, null)}
        id={"boxShadowHoriFocus"}
        min={-100}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "boxShadowHoriFocus")}
        tooltipText={translate("cEditForms.tooltipShadowHoriLength", false, null)}
      />
      <Slider
        label={translate("cEditForms.inputfield_shadowVertLengthFocus", false, null)}
        id={"boxShadowVertFocus"}
        min={-100}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "boxShadowVertFocus")}
        tooltipText={translate("cEditForms.tooltipShadowVerticalLength", false, null)}
      />
      <Slider
        label={translate("cEditForms.inputfield_shadowBlurFocus", false, null)}
        id={"boxShadowBlurFocus"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "boxShadowBlurFocus")}
        tooltipText={translate("cEditForms.shadowBlur", false, null)}
      />
      <Slider
        label={translate("cEditForms.inputfield_shadowSpreadFocus", false, null)}
        id={"boxShadowSpreadFocus"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "boxShadowSpreadFocus")}
        tooltipText={translate("cEditForms.shadowSpread", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.inputfield_shadowColorFocus", false, null)}
        id={"boxShadowRgbaFocus"}
        startValue={formGetStartValue(startStyles, "boxShadowRgbaFocus")}
        tooltipText={translate("cEditForms.shadowColor", false, null)}
      />
      <Divider />
      <Margin />
      <Divider />
      <Padding />
      <Divider />
      <Transition />
    </>
  );
};

InputFieldStyles.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(InputFieldStyles);
