import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";
import ColorPicker from "./formComponents/ColorPicker";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const TextShadow = ({ sb: { startStyles } }) => {
  const [bool, setBool] = useState(false);
  useEffect(() => {
    setBool(formGetStartValue(startStyles, "textShadowChangeOnHover"));
  }, [startStyles]);

  return (
    <>
      <Slider
        label={translate("cEditForms.shadowHoriLength", false, null)}
        id={"textShadowHori"}
        min={-100}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "textShadowHori")}
        tooltipText={translate("cEditForms.txtShadow_ttHorLen", false, null)}
      />
      <Slider
        label={translate("cEditForms.shadowVerticalLength", false, null)}
        id={"textShadowVert"}
        min={-100}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "textShadowVert")}
        tooltipText={translate("cEditForms.txtShadow_ttVerLen", false, null)}
      />
      <Slider
        label={translate("cEditForms.blurRadius", false, null)}
        id={"textShadowBlur"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "textShadowBlur")}
        tooltipText={translate("cEditForms.txtShadow_ttSize", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.shadowColorOpacity", false, null)}
        id={"textShadowRgba"}
        startValue={formGetStartValue(startStyles, "textShadowRgba")}
        tooltipText={translate("cEditForms.shadowColor", false, null)}
      />

      <div className={`form-check form-switch mt-3 ${bool ? "mb-n2" : "mb-3"}`}>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="switchTextShadowHover"
          value={bool}
          checked={bool}
          onChange={(e) => setBool(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="switchTextShadowHover">
          {translate("cEditForms.txtShadow_ttChangeHover", false, null)}
        </label>
      </div>
      {bool && (
        <>
          <Slider
            label={translate("cEditForms.shadowHoriLength", false, null)}
            id={"textShadowHoriHover"}
            min={-100}
            max={100}
            step={1}
            startValue={formGetStartValue(startStyles, "textShadowHoriHover")}
            tooltipText={translate("cEditForms.txtShadow_ttHorLenHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.shadowVerticalLength", false, null)}
            id={"textShadowVertHover"}
            min={-100}
            max={100}
            step={1}
            startValue={formGetStartValue(startStyles, "textShadowVertHover")}
            tooltipText={translate("cEditForms.txtShadow_ttVerLenHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.blurRadius", false, null)}
            id={"textShadowBlurHover"}
            min={0}
            max={100}
            step={1}
            startValue={formGetStartValue(startStyles, "textShadowBlurHover")}
            tooltipText={translate("cEditForms.txtShadow_ttSizeHover", false, null)}
          />
          <ColorPicker
            label={translate("cEditForms.shadowColorOpacity", false, null)}
            id={"textShadowRgbaHover"}
            startValue={formGetStartValue(startStyles, "textShadowRgbaHover")}
            tooltipText={translate("cEditForms.txtShadow_ttColorHover", false, null)}
          />
        </>
      )}
    </>
  );
};

TextShadow.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(TextShadow);
