export const quotes6 = {
  id: "quotes-6",
  name: "Quotes 6",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "quote-6-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "quote-6-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-6", "quote-6-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "callout_testimonial" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-6-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["toGrow", "quote-6-balloon"],
                    styles: [],
                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["quote-6-text3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus atque at fugiat aliquam iusto quasi",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["quote-6-avatar"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h3",
                    classes: ["quote-6-text1", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Name", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h6",
                    classes: ["quote-6-text2", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Function / company / location",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.quote-6-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.quote-6-component", pseudo: "hover", rules: [] },
      {
        className: "quote-6-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "display", value: "flex" },
        ],
      },
      {
        className: "quote-6-col",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-6-wrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
        ],
      },
      {
        className: "quote-6-wrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-6-avatar",
        pseudo: "",
        rules: [
          { property: "border-radius", value: "50%" },
          { property: "height", value: "200px" },
          { property: "width", value: "auto" },
          { property: "margin", value: "2rem 0 1rem 0" },
        ],
      },
      {
        className: "quote-6-avatar",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-6-text1",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0.5rem 1rem" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "quote-6-text1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-6-text2",
        pseudo: "",
        rules: [
          { property: "font-style", value: "italic" },
          { property: "margin", value: "0 0 0 1rem" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "quote-6-text2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-6-quote-left",
        pseudo: "",
        rules: [
          { property: "display", value: "inline" },
          { property: "font-size", value: "2rem" },
          { property: "color", value: "rgba(100, 100, 100, 1)" },
        ],
      },
      {
        className: "quote-6-balloon",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "box-shadow", value: "10px 10px 5px -5px rgba(0, 0, 0, 0)" },
          { property: "background", value: "rgba(200, 200, 200, 1)" },
          { property: "border-radius", value: "0.4rem" },
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "width", value: "100%" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "quote-6-balloon",
        pseudo: "hover",
        rules: [
          { property: "box-shadow", value: "10px 10px 5px -5px rgba(0, 0, 0, 0)" },
          { property: "background", value: "rgba(200, 200, 200, 1)" },
        ],
      },
      {
        className: "quote-6-balloon",
        pseudo: "after",
        rules: [
          { property: "content", value: '""' },
          { property: "position", value: "absolute" },
          { property: "top", value: "100%" },
          { property: "width", value: "0" },
          { property: "height", value: "0" },
          { property: "border-top", value: "25px solid rgba(200, 200, 200, 1)" },
          { property: "border-right", value: "40px solid transparent" },
          { property: "left", value: "50%" },
          { property: "filter", value: "drop-shadow(1px 5px 2px rgba(0, 0, 0, 0))" },
        ],
      },
      {
        className: "quote-6-text3",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 1rem 1rem 1rem" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "quote-6-text3",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
