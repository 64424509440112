export const template4 = {
  id: "638b0b66ea698baf91d08be2",
  name: { en: "Architecture firm", nl: "Architecten" },
  desc: { en: "Single-page site for an architecture firm", nl: "Een-pagina site voor een architectuurbureau" },
  categories: ["services"],
  sbPages: [
    {
      pageId: "BUDZgIXtte",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "eynAtB",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-1-component-eynAtB"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "home" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "bjB0Zg",
              type: "",
              htmlTagName: "div",
              classes: ["hero-1-wrapper-eynAtB"],
              styles: [],
              attributes: [{ property: "data-herowrapper", value: "true" }],
              content: "",
              children: [
                {
                  childId: "ZT85Ze",
                  type: "",
                  htmlTagName: "img",
                  classes: ["hero-1-img-eynAtB"],
                  styles: [],
                  attributes: [
                    { property: "alt", value: "Image" },
                    { property: "src", value: "https://cdn.satonda.com/eb/assets/city5.png" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "KSuFrr",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-1-content-eynAtB"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "hero1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "nDqL7V",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-1-title-eynAtB", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Your favorite architects!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "g9TsdJ",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-1-text-eynAtB", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "u4yI5R",
                      type: "",
                      htmlTagName: "a",
                      classes: ["hero-1-button-eynAtB"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                        { property: "data-href", value: "#about" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Read more",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "N9QNZI",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-N9QNZI", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "sticky-top" },
          ],
          content: "",
          children: [
            {
              childId: "jjqANL",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "yKlJH2",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "YY0xy0",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-N9QNZI"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Logo" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-companyname.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "Y1YMOQ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-N9QNZI", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "Wc4AEe",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "rz4yOX",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "u4QaFE",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "SfjT7s",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-N9QNZI"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#home" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Home",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "RQp9ZH",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-N9QNZI"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#about" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "About us",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "iKvEZb",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-N9QNZI"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#services" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Services",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "kxk3jf",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-N9QNZI"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#projects" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Projects",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "gg7XRJ",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-N9QNZI"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#team" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Team",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "V9nkJP",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-N9QNZI"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#contact" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "AqOQ2z",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-AqOQ2z"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "about" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "FSkxGv",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-AqOQ2z", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "About us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "PRu0jd",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-PRu0jd"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "nIREiN",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-PRu0jd", "w-100", "w-md-80", "w-lg-70"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "pTnrLX",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "statistics-3-component-pTnrLX"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "VP6DTZ",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-evenly", "align-items-stretch", "statistics-3-statswrapper", "w-100"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "jvSUY0",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-3-col"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "statistics3a" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "Qvdrur",
                      type: "",
                      htmlTagName: "div",
                      classes: ["statistics-3-statwrapper-pTnrLX"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "nAUk4Y",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-3-stat-caption-pTnrLX", "w-100", "w-md-90", "w-lg-70"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Satisfied customers",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "US8zZF",
                          type: "",
                          htmlTagName: "div",
                          classes: ["statistics-3-stat-numbercircle-pTnrLX"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "statistics3b" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "Aiw8jw",
                              type: "",
                              htmlTagName: "p",
                              classes: ["statistics-3-stat-numbertext-pTnrLX", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "379",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "F2UCaF",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-3-col"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "statistics3a" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "zllZr1",
                      type: "",
                      htmlTagName: "div",
                      classes: ["statistics-3-statwrapper-pTnrLX"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "aLy7dM",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-3-stat-caption-pTnrLX", "w-100", "w-md-90", "w-lg-70"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Released projects",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Ww0wnT",
                          type: "",
                          htmlTagName: "div",
                          classes: ["statistics-3-stat-numbercircle-pTnrLX"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "statistics3b" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "lW7oPY",
                              type: "",
                              htmlTagName: "p",
                              classes: ["statistics-3-stat-numbertext-pTnrLX", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "582",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "FblE0a",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-3-col"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "statistics3a" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "k8T18s",
                      type: "",
                      htmlTagName: "div",
                      classes: ["statistics-3-statwrapper-pTnrLX"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "EqjMHo",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-3-stat-caption-pTnrLX", "w-100", "w-md-90", "w-lg-70"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Hours of support ",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "hUOEY3",
                          type: "",
                          htmlTagName: "div",
                          classes: ["statistics-3-stat-numbercircle-pTnrLX"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "statistics3b" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "Oa5zqg",
                              type: "",
                              htmlTagName: "p",
                              classes: ["statistics-3-stat-numbertext-pTnrLX", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "4,410",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "jUTr4p",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-jUTr4p"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "services" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "OwuuJ2",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-jUTr4p", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Our services", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "GIVfiz",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-13-component-GIVfiz"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "zitqA5",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "qYWVQP",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-GIVfiz"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "qBa2LL",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-GIVfiz"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "swkiDl",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-GIVfiz"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "tF57nJ",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-chart-simple"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "TzWuPa",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-GIVfiz", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem Ipsum",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "STayvl",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-GIVfiz", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "MdVy8I",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-GIVfiz"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "nO19OB",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-GIVfiz"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "myKP93",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-GIVfiz"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "O1pBbn",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-regular", "fa-paper-plane"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "TJABtc",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-GIVfiz", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Dolor Sitema",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "SjT8kW",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-GIVfiz", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "uoZ7Oz",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-GIVfiz"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "pxoCB1",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-GIVfiz"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "HBkhqd",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-GIVfiz"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "C7LevY",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-layer-group"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Zm4Kl0",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-GIVfiz", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Sed ut perspiciatis",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "gqS7aN",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-GIVfiz", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Lvxqf5",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-13-component-Lvxqf5"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "CRxNdm",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "KrVDWe",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-Lvxqf5"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "NdVcge",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-Lvxqf5"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "LCVyVp",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-Lvxqf5"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "VutOmm",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-chart-simple"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "CfMTyg",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-Lvxqf5", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem Ipsum",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "TrsWRu",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-Lvxqf5", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "P75myi",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-Lvxqf5"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ogYiVd",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-Lvxqf5"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "NpyhFy",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-Lvxqf5"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "kbhyhD",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-regular", "fa-paper-plane"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "EZ6Sgm",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-Lvxqf5", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Dolor Sitema",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "QPb4KO",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-Lvxqf5", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "uoZDTF",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-Lvxqf5"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "nCje5w",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-Lvxqf5"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "zevMfX",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-Lvxqf5"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "YqMMow",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-users-line"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "ynzqKV",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-13-title-Lvxqf5", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Magni Dolores",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "b2tzi2",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-Lvxqf5", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "HcOUGi",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "cta-1-component-HcOUGi"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "jWXbd9",
              type: "",
              htmlTagName: "h1",
              classes: ["cta-1-title-HcOUGi", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Looking for the best architect?",
                  children: [],
                },
              ],
            },
            {
              childId: "JF41X4",
              type: "",
              htmlTagName: "p",
              classes: ["cta-1-text-HcOUGi", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos saepe expedita nemo molestiae repudiandae, dolor aperiam velit esse veronam!",
                  children: [],
                },
              ],
            },
            {
              childId: "PBBCTO",
              type: "",
              htmlTagName: "a",
              classes: ["cta-1-button-HcOUGi"],
              styles: [],
              attributes: [
                { property: "href", value: "#!" },
                { property: "data-target", value: "_self" },
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "button" },
                { property: "data-href", value: "#contact" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "feOm7o",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-feOm7o"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "projects" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "ztefPN",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-feOm7o", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Recent projects", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "hIhpB3",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "imggallery-2-component-hIhpB3"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "kgyI3W",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "imggallery-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "oHjRU2",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-hIhpB3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "oZj0Qh",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-hIhpB3"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Eh2IND",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-hIhpB3"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior1.png" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "u0ni0G",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-hIhpB3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ubJhWY",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-hIhpB3"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "bEH2nM",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-hIhpB3"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior2.png" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "IMKCog",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-hIhpB3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "lKmyaX",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-hIhpB3"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "lNCrB9",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-hIhpB3"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior3.png" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "x8Dyl3",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-hIhpB3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "WNeiTQ",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-hIhpB3"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "IZ449x",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-hIhpB3"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior4.png" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "VSV8K9",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-VSV8K9"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "team" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "jHRavf",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-VSV8K9", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Our architects", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "UgYUNH",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-4-component-UgYUNH"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "E7CtSc",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-center", "card-4-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "xFadfK",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-4-col-UgYUNH"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card4" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "bKTQh2",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-4-element-wrapper-UgYUNH"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "Rc73XS",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-4-avatar-UgYUNH"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-1.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "oADqVX",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-4-content-UgYUNH"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "rId4q1",
                              type: "",
                              htmlTagName: "h5",
                              classes: ["card-4-title-UgYUNH"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name Lastname",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "COLM2c",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-4-subtitle-UgYUNH"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "B7Mkms",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "vCRIom",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "LamGFN",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "O3e5dj",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ZoQfYL",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "tH4L8d",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "fRS4x7",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "BYaC7L",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "IzgKyW",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "JvdlwI",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-4-col-UgYUNH"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card4" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "GJWShH",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-4-element-wrapper-UgYUNH"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "jCaHrt",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-4-avatar-UgYUNH"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-2.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "drp8k5",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-4-content-UgYUNH"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "ARGzrZ",
                              type: "",
                              htmlTagName: "h5",
                              classes: ["card-4-title-UgYUNH"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name Lastname",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "LhCUxN",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-4-subtitle-UgYUNH"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "FGLO0G",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "yzKoeW",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "TtDtRi",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "d8c2nU",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "GksgRD",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "JWjxhU",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "vAEkLi",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "UbyWw1",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "L4QzJO",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "YvNTkw",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-4-col-UgYUNH"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card4" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "VH1d02",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-4-element-wrapper-UgYUNH"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "iZriqs",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-4-avatar-UgYUNH"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-3.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "ZBvJyV",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-4-content-UgYUNH"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "kOph6d",
                              type: "",
                              htmlTagName: "h5",
                              classes: ["card-4-title-UgYUNH"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name Lastname",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "tqXtcm",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-4-subtitle-UgYUNH"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "XIVqKr",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "GwH0KD",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "X8Yz2c",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "O4AhsK",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "A6Axuw",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "ygFjZ8",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "uMA1bt",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "y0Zg9D",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "FIoo3l",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "fAz364",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-4-col-UgYUNH"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card4" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "AKesPe",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-4-element-wrapper-UgYUNH"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "cA7JqN",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-4-avatar-UgYUNH"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-4.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "pgZuIG",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-4-content-UgYUNH"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "jBBd5U",
                              type: "",
                              htmlTagName: "h5",
                              classes: ["card-4-title-UgYUNH"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name Lastname",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "m7Zeig",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-4-subtitle-UgYUNH"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "CJRs33",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "JRKdNe",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "dzAmTa",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "ilThiN",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "BKOYYn",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "UR6swb",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "njyHdD",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "bzLQbi",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-4-social-link-UgYUNH"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "KTa4Ai",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "vbkKUL",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-vbkKUL"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "contact" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "BxodXi",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-vbkKUL", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "HqyeOE",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-1-component-HqyeOE"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "VldzjI",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-1-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "g5jNxa",
                  type: "block",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "contactus-1-col-HqyeOE"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "block" }],
                  content: "",
                  children: [
                    {
                      childId: "n8or6m",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-1-contact-info-block-HqyeOE"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "BBKlrS",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-icon-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "pq2rpG",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-location-dot"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Wr6T4w",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-content-HqyeOE"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "a7hnim",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-HqyeOE-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Street 1337",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "dYhusu",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-HqyeOE-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Postal code, City",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "vryQKg",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-HqyeOE-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Country",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "o8tSAy",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-1-contact-info-block-HqyeOE"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "z0o7vQ",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-icon-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "WBuSaT",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-envelope"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "FklmKH",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-content-HqyeOE"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "myNzhn",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-HqyeOE-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "email@domain.com",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "mUbdjt",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-1-contact-info-block-HqyeOE"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "cfyb6m",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-icon-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "UjgkE3",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-phone"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "hODtP7",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-content-HqyeOE"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Th00PQ",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-HqyeOE-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "020 13 37 000",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "j59x69",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["d-flex", "flex-wrap"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "gBeJeO",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "TqhmEj",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-envelope"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "WbAOZ1",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "MNfToj",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-twitter"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "QlSIz8",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "WvHXLW",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-linkedin"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "RpgU3T",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "zpkUdp",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-facebook-f"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "iVJzKN",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "OLLTa5",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-HqyeOE"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "uccRpa",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "ARJIRM",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-1-inputfield-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "name", value: "name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                            { property: "type", value: "text" },
                            { property: "placeholder", value: "Your name" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "RKErgK",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-HqyeOE"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "XciKiJ",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "rM1Kek",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-1-inputfield-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "XPbIn4",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-HqyeOE"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "GZINXA",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "YMwstT",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-1-inputfield-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "bJMKvc",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-HqyeOE"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "YRsTWH",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "wmwAJh",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-1-inputfield-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "cSVHDK",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-1-button-HqyeOEwrapper-HqyeOE"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "u9GaLd",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-1-button-HqyeOE"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "PQV1L7",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "other-1-component-PQV1L7"],
          styles: [],
          attributes: [
            { property: "data-name", value: "backtotop" },
            { property: "data-editable", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "fZnDe7",
              type: "",
              htmlTagName: "div",
              classes: ["backtotop", "other-1-button-PQV1L7", "shown"],
              styles: [],
              attributes: [
                { property: "data-name", value: "backtotop_btn" },
                { property: "title", value: "Back to top" },
                { property: "data-checkparent", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "K0iOQF",
                  type: "",
                  htmlTagName: "span",
                  classes: ["icon", "fa-solid", "fa-chevron-up"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      _id: "63868132013c979ecfd15c59",
    },
  ],
  sbCustomCss: [
    {
      componentId: "eynAtB",
      classes: [
        {
          className: "section.hero-1-component-eynAtB",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-1-component-eynAtB", pseudo: "hover", rules: [] },
        {
          className: "hero-1-component-eynAtB",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-1-component-eynAtB", pseudo: "hover", rules: [] },
        {
          className: "hero-1-wrapper-eynAtB",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
          ],
        },
        {
          className: "hero-1-img-eynAtB",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-1-content-eynAtB",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "hero-1-title-eynAtB",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(249, 249, 249, 0.2)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-1-title-eynAtB", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(249, 249, 249, 0.2)" }] },
        {
          className: "hero-1-text-eynAtB",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-1-text-eynAtB", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-1-button-eynAtB",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(239, 173, 81, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(239, 173, 81, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-1-button-eynAtB",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "background", value: "rgba(239, 173, 81, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 8px 4px rgba(239, 173, 81, 0.25)" },
          ],
        },
      ],
    },
    {
      componentId: "N9QNZI",
      classes: [
        {
          className: "scrolledpast-N9QNZI.navbar-1-wrapper-N9QNZI",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "rem" },
            { property: "padding-bottom", value: "rem" },
            { property: "background", value: "" },
          ],
        },
        { className: "scrolledpast-N9QNZI .navbar-1-logo-N9QNZI", pseudo: "", rules: [{ property: "max-height", value: "px" }] },
        {
          className: "navbar-1-wrapper-N9QNZI",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(239, 173, 81, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 5px 7px 3px rgba(239, 173, 81, 0.25)" },
          ],
        },
        {
          className: "navbar-1-logo-N9QNZI",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "50px" },
          ],
        },
        { className: "navbar-1-logo-N9QNZI", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-N9QNZI",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(230, 230, 230, 1)" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-N9QNZI",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(180, 180, 180, 1)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
        {
          className: "navbar-1-link-N9QNZI",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "text-decoration", value: "none" },
          ],
        },
        { className: "navbar-1-link-N9QNZI", pseudo: "hover", rules: [{ property: "color", value: "rgba(0, 0, 0, 1)" }] },
      ],
    },
    {
      componentId: "AqOQ2z",
      classes: [
        {
          className: "section.basics-1-component-AqOQ2z",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-AqOQ2z", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-AqOQ2z",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-AqOQ2z", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-AqOQ2z",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-AqOQ2z", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "PRu0jd",
      classes: [
        {
          className: "section.basics-2-component-PRu0jd",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-PRu0jd", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-PRu0jd",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-PRu0jd", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-PRu0jd",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-PRu0jd", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "pTnrLX",
      classes: [
        {
          className: "section.statistics-3-component-pTnrLX",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.statistics-3-component-pTnrLX", pseudo: "hover", rules: [] },
        {
          className: "statistics-3-component-pTnrLX",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-3-component-pTnrLX", pseudo: "hover", rules: [] },
        {
          className: "statistics-3-statwrapper-pTnrLX",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "width", value: "100%" },
            { property: "aspect-ratio", value: "1" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(241, 178, 85, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "position", value: "relative" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "statistics-3-statwrapper-pTnrLX",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(241, 178, 85, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "statistics-3-stat-numbercircle-pTnrLX",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "right", value: "0" },
            { property: "transform", value: "translate(25%, -25%)" },
            { property: "width", value: "40%" },
            { property: "aspect-ratio", value: "1" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(241, 178, 85, 1)" },
            { property: "background", value: "rgba(241, 178, 85, 1)" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        {
          className: "statistics-3-stat-numbercircle-pTnrLX",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(241, 178, 85, 1)" },
            { property: "background", value: "rgba(241, 178, 85, 1)" },
          ],
        },
        {
          className: "statistics-3-stat-numbertext-pTnrLX",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0 0 0 0" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "statistics-3-stat-numbertext-pTnrLX", pseudo: "hover", rules: [] },
        {
          className: "statistics-3-stat-caption-pTnrLX",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0 0 0 0" },
          ],
        },
        { className: "statistics-3-stat-caption-pTnrLX", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "jUTr4p",
      classes: [
        {
          className: "section.basics-1-component-jUTr4p",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-jUTr4p", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-jUTr4p",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-jUTr4p", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-jUTr4p",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-jUTr4p", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "GIVfiz",
      classes: [
        {
          className: "section.card-13-component-GIVfiz",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-13-component-GIVfiz", pseudo: "hover", rules: [] },
        { className: "card-13-col-GIVfiz", pseudo: "", rules: [] },
        { className: "card-13-col-GIVfiz", pseudo: "hover", rules: [] },
        { className: "card-13-wrapper-GIVfiz", pseudo: "", rules: [] },
        { className: "card-13-wrapper-GIVfiz", pseudo: "hover", rules: [] },
        {
          className: "card-13-iconwrapper-GIVfiz",
          pseudo: "",
          rules: [
            { property: "font-size", value: "2.5rem" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-13-iconwrapper-GIVfiz", pseudo: "hover", rules: [] },
        {
          className: "card-13-title-GIVfiz",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 0 0" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-13-title-GIVfiz", pseudo: "hover", rules: [] },
        {
          className: "card-13-text-GIVfiz",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-13-text-GIVfiz", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "Lvxqf5",
      classes: [
        {
          className: "section.card-13-component-Lvxqf5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "2rem" },
          ],
        },
        { className: "section.card-13-component-Lvxqf5", pseudo: "hover", rules: [] },
        { className: "card-13-col-Lvxqf5", pseudo: "", rules: [] },
        { className: "card-13-col-Lvxqf5", pseudo: "hover", rules: [] },
        { className: "card-13-wrapper-Lvxqf5", pseudo: "", rules: [] },
        { className: "card-13-wrapper-Lvxqf5", pseudo: "hover", rules: [] },
        {
          className: "card-13-iconwrapper-Lvxqf5",
          pseudo: "",
          rules: [
            { property: "font-size", value: "2.5rem" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-13-iconwrapper-Lvxqf5", pseudo: "hover", rules: [] },
        {
          className: "card-13-title-Lvxqf5",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 0 0" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-13-title-Lvxqf5", pseudo: "hover", rules: [] },
        {
          className: "card-13-text-Lvxqf5",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-13-text-Lvxqf5", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "UG4y7L",
      classes: [
        {
          className: "section.cta-2-component-UG4y7L",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.cta-2-component-UG4y7L", pseudo: "hover", rules: [] },
        {
          className: "cta-2-component-UG4y7L",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "cta-2-component-UG4y7L", pseudo: "hover", rules: [] },
        {
          className: "cta-2-title-UG4y7L",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "cta-2-title-UG4y7L", pseudo: "hover", rules: [] },
        {
          className: "cta-2-button-UG4y7L",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(201, 36, 3, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(201, 36, 3, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "text-decoration", value: "none" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cta-2-button-UG4y7L",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(201, 36, 3, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(201, 36, 3, 1)" },
          ],
        },
      ],
    },
    {
      componentId: "HcOUGi",
      classes: [
        {
          className: "section.cta-1-component-HcOUGi",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "4.5rem" },
            { property: "background", value: "rgba(238, 168, 77, 0.25)" },
          ],
        },
        { className: "section.cta-1-component-HcOUGi", pseudo: "hover", rules: [] },
        {
          className: "cta-1-component-HcOUGi",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "cta-1-component-HcOUGi", pseudo: "hover", rules: [] },
        {
          className: "cta-1-text-HcOUGi",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "cta-1-text-HcOUGi", pseudo: "hover", rules: [] },
        {
          className: "cta-1-title-HcOUGi",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "cta-1-title-HcOUGi", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "cta-1-button-HcOUGi",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(239, 173, 81, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(239, 173, 81, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "cta-1-button-HcOUGi",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "background", value: "rgba(239, 173, 81, 1)" },
            { property: "border-color", value: "rgba(239, 173, 81, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "UgYUNH",
      classes: [
        {
          className: "section.card-4-component-UgYUNH",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-4-component-UgYUNH", pseudo: "hover", rules: [] },
        {
          className: "card-4-col-UgYUNH",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "card-4-element-wrapper-UgYUNH",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0.4rem" },
            { property: "padding", value: "0 0 0 0" },
            { property: "width", value: "100%" },
          ],
        },
        {
          className: "card-4-element-wrapper-UgYUNH",
          pseudo: "hover",
          rules: [{ property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" }],
        },
        { className: "card-4-element-wrapper-UgYUNH", pseudo: "hover .card-4-content-UgYUNH", rules: [{ property: "height", value: "120px" }] },
        {
          className: "card-4-content-UgYUNH",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "start" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "bottom", value: "0" },
            { property: "height", value: "80px" },
            { property: "background", value: "rgba(0, 0, 0, 0.5)" },
            { property: "padding", value: "0 0 0 0" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        {
          className: "card-4-subtitle-UgYUNH",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 1rem 0" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-size", value: "13px" },
          ],
        },
        { className: "card-4-subtitle-UgYUNH", pseudo: "hover", rules: [] },
        {
          className: "card-4-avatar-UgYUNH",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-4-avatar-UgYUNH",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-4-social-link-UgYUNH",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 0.5rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "align-self", value: "start" },
            { property: "font-size", value: "1rem" },
          ],
        },
        {
          className: "card-4-social-link-UgYUNH",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(239, 173, 81, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "card-4-social-link-UgYUNH", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "card-4-title-UgYUNH",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-4-title-UgYUNH", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "VSV8K9",
      classes: [
        {
          className: "section.basics-1-component-VSV8K9",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-VSV8K9", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-VSV8K9",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-VSV8K9", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-VSV8K9",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-VSV8K9", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "vbkKUL",
      classes: [
        {
          className: "section.basics-1-component-vbkKUL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0rem" },
          ],
        },
        { className: "section.basics-1-component-vbkKUL", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-vbkKUL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-vbkKUL", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-vbkKUL",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-vbkKUL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "ecN1v7",
      classes: [
        {
          className: "section.contactus-1-component-ecN1v7",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-1-component-ecN1v7", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-col-ecN1v7",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "start" },
            { property: "justify-content", value: "center" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-1-contact-info-block-ecN1v7",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "margin", value: "0 0 1.5rem 0" },
          ],
        },
        {
          className: "contactus-1-contact-info-icon-ecN1v7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "height", value: "25px" },
            { property: "width", value: "25px" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "contactus-1-contact-info-icon-ecN1v7", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-contact-info-content-ecN1v7",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 1.5rem" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "contactus-1-contact-info-content-ecN1v7 > *", pseudo: "", rules: [{ property: "margin", value: "0" }] },
        {
          className: "contactus-1-social-link-ecN1v7",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0.5rem 0 0" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "background", value: "rgba(108, 117, 125, 1)" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "height", value: "45px" },
            { property: "aspect-ratio", value: "1" },
            { property: "width", value: "45px" },
            { property: "border-radius", value: "50%" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        {
          className: "contactus-1-social-link-ecN1v7",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
          ],
        },
        { className: "contactus-1-social-link-ecN1v7", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "contactus-1-buttonwrapper-ecN1v7",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-1-buttonwrapper-ecN1v7", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-button-ecN1v7",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-1-button-ecN1v7",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "contactus-1-inputwrapper-ecN1v7", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        {
          className: "contactus-1-inputfield-ecN1v7",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-1-inputfield-ecN1v7",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
          ],
        },
        {
          className: "contactus-1-inputfield-ecN1v7",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-1-inputfield-ecN1v7", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-label-ecN1v7",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-1-label-ecN1v7", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "hIhpB3",
      classes: [
        {
          className: "section.imggallery-2-component-hIhpB3",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.imggallery-2-component-hIhpB3", pseudo: "hover", rules: [] },
        {
          className: "imggallery-2-col-hIhpB3",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "stretch" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "imggallery-2-wrapper-hIhpB3",
          pseudo: "",
          rules: [
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.4s ease-in-out" },
          ],
        },
        {
          className: "imggallery-2-wrapper-hIhpB3",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "imggallery-2-img-hIhpB3",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "transition", value: "all 0.4s ease-in-out" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
          ],
        },
        {
          className: "imggallery-2-img-hIhpB3",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1.15)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
          ],
        },
      ],
    },
    {
      componentId: "feOm7o",
      classes: [
        {
          className: "section.basics-1-component-feOm7o",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-feOm7o", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-feOm7o",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-feOm7o", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-feOm7o",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(241, 178, 85, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-feOm7o", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "q9u6q7",
      classes: [
        {
          className: "section.contactus-1-component-q9u6q7",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-1-component-q9u6q7", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-col-q9u6q7",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "start" },
            { property: "justify-content", value: "center" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-1-contact-info-block-q9u6q7",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "margin", value: "0 0 1.5rem 0" },
          ],
        },
        {
          className: "contactus-1-contact-info-content-q9u6q7",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 1.5rem" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "contactus-1-contact-info-content-q9u6q7 > *", pseudo: "", rules: [{ property: "margin", value: "0" }] },
        {
          className: "contactus-1-button-q9u6q7wrapper-q9u6q7",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-1-button-q9u6q7wrapper-q9u6q7", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-contact-info-icon-q9u6q7",
          pseudo: "",
          rules: [
            { property: "height", value: "25px" },
            { property: "width", value: "25px" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "center" },
            { property: "color", value: "rgba(239, 173, 81, 1)" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        { className: "contactus-1-contact-info-icon-q9u6q7", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-social-link-q9u6q7",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "height", value: "45px" },
            { property: "aspect-ratio", value: "1" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "background", value: "rgba(239, 173, 81, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0.5rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "font-size", value: "1rem" },
          ],
        },
        {
          className: "contactus-1-social-link-q9u6q7",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(239, 173, 81, 1)" },
            { property: "background", value: "rgba(249, 224, 189, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "contactus-1-social-link-q9u6q7", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "contactus-1-inputfield-q9u6q7",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1rem" },
            { property: "outline", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(239, 173, 81, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease-in-out" },
          ],
        },
        {
          className: "contactus-1-inputfield-q9u6q7",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(239, 173, 81, 1)" },
            { property: "border-color", value: "rgba(239, 173, 81, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(239, 173, 81, 0.2)" },
          ],
        },
        {
          className: "contactus-1-inputfield-q9u6q7",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-1-inputfield-q9u6q7", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-button-q9u6q7",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(239, 173, 81, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(239, 173, 81, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "contactus-1-button-q9u6q7",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "background", value: "rgba(239, 173, 81, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "PQV1L7",
      classes: [
        {
          className: "section.other-1-component-PQV1L7",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.other-1-component-PQV1L7", pseudo: "hover", rules: [] },
        {
          className: "shown.other-1-button-PQV1L7",
          pseudo: "",
          rules: [
            { property: "opacity", value: "1" },
            { property: "visibility", value: "visible" },
          ],
        },
        {
          className: "other-1-button-PQV1L7",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "fixed" },
            { property: "z-index", value: "99" },
            { property: "bottom", value: "1rem" },
            { property: "right", value: "1rem" },
            { property: "opacity", value: "0" },
            { property: "visibility", value: "hidden" },
            { property: "cursor", value: "pointer" },
            { property: "height", value: "3rem" },
            { property: "width", value: "3rem" },
            { property: "font-size", value: "1rem" },
            { property: "background", value: "rgba(245, 204, 147, 1)" },
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.2)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "other-1-button-PQV1L7",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(239, 173, 81, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.3)" },
          ],
        },
      ],
    },
    {
      componentId: "HqyeOE",
      classes: [
        {
          className: "section.contactus-1-component-HqyeOE",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-1-component-HqyeOE", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-col-HqyeOE",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "start" },
            { property: "justify-content", value: "center" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-1-contact-info-block-HqyeOE",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "margin", value: "0 0 1.5rem 0" },
          ],
        },
        {
          className: "contactus-1-contact-info-content-HqyeOE",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 1.5rem" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "contactus-1-contact-info-content-HqyeOE > *", pseudo: "", rules: [{ property: "margin", value: "0" }] },
        {
          className: "contactus-1-button-HqyeOEwrapper-HqyeOE",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-1-button-HqyeOEwrapper-HqyeOE", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-label-HqyeOE",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-1-label-HqyeOE", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-contact-info-icon-HqyeOE",
          pseudo: "",
          rules: [
            { property: "height", value: "25px" },
            { property: "width", value: "25px" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "center" },
            { property: "color", value: "rgba(241, 179, 85, 1)" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        { className: "contactus-1-contact-info-icon-HqyeOE", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-inputfield-HqyeOE",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1rem" },
            { property: "outline", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(109, 118, 126, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease-in-out" },
          ],
        },
        {
          className: "contactus-1-inputfield-HqyeOE",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(109, 118, 126, 1)" },
            { property: "border-color", value: "rgba(241, 179, 85, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(241, 179, 85, 0)" },
          ],
        },
        {
          className: "contactus-1-inputfield-HqyeOE",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(179, 184, 189, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-1-inputfield-HqyeOE", pseudo: "hover", rules: [] },
        { className: "contactus-1-inputwrapper-HqyeOE", pseudo: "", rules: [{ property: "margin", value: "0rem 0rem 1rem 0rem" }] },
        {
          className: "contactus-1-button-HqyeOE",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(241, 179, 85, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(241, 179, 85, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "contactus-1-button-HqyeOE",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(241, 179, 85, 1)" },
            { property: "border-color", value: "rgba(241, 179, 85, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-1-social-link-HqyeOE",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "height", value: "45px" },
            { property: "aspect-ratio", value: "1" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "background", value: "rgba(241, 179, 85, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0.5rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "font-size", value: "1rem" },
          ],
        },
        {
          className: "contactus-1-social-link-HqyeOE",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(217, 137, 18, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "contactus-1-social-link-HqyeOE", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Quicksand:300,700&display=swap",
    fontBodyLink: "",
    fontHeadersName: "Quicksand",
    fontHeadersWeight: "400",
    fontBodyName: "Open Sans",
    fontBodyWeight: "300",
    color1: "rgba(241, 178, 85, 1)",
    color2: "rgba(247, 154, 104, 1)",
    color3: "rgba(244, 75, 56, 1)",
    color4: "rgba(247, 225, 104, 1)",
    color5: "rgba(225, 244, 56, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
