import { v4 as uuidv4 } from "uuid";
// Bring in action types
import {
  // Manage project
  LOAD_PROJECT,
  LOAD_TEMPLATE,
  UPDATE_PROJECT_GALLERY,
  UPDATE_GALLERY_FILE_DESC,
  UPDATE_SITEMETA,
  CLEAN_CUSTOM_CSS,
  // Product tour
  OPEN_PRODUCT_TOUR,
  CLOSE_PRODUCT_TOUR,
  // Drag & drop
  START_DRAG_FROM_MENU,
  END_DRAG_FROM_MENU,
  DROP_COMPONENT,
  // Edit component
  REORDER_COMPONENT,
  DELETE_COMPONENT,
  SET_SELECTED_ELEMENT,
  // Manage elements
  DELETE_ELEMENT,
  DUPLICATE_ELEMENT,
  REORDER_COL,
  // Input fields
  REORDER_INPUTFIELD,
  TOGGLE_CHECKBOX,
  // Manage pages
  ADD_NEW_PAGE,
  ADD_NEW_VERSION,
  SET_ACTIVE_PAGE,
  EDIT_PAGE_INFO,
  EDIT_PAGE_SOCIAL_PREVIEW,
  DELETE_PAGE,
  COPY_COMPONENT_TO_OTHER_PAGE,
  // Track changes
  SAVE_TRACK_CHANGES,
  UNDO_REDO,
  // Edit styles
  START_EDITING,
  OPEN_COLOR_PICKER,
  UPDATE_CSS_VAR,
  CONFIRM_COLOR_SCHEME,
  CHANGE_SITE_COLORS,
  UPDATE_COMPONENT_CSS,
  UPDATE_COMPONENT_CLASSES,
  UPDATE_COMPONENT_ATTRIBUTES,
  UPDATE_COMPONENT_HTMLTAGNAME,
  UPDATE_EXISTING_TEXT_ELEMENT,
  ADD_NEW_TEXT_ELEMENT,
  UPDATE_LIST_MARKER2,
  SELECT_IMG_FROM_GALLERY,
  UPDATE_CUSTOMS_ICON_EDITOR,
  UPDATE_CUSTOMS_ROW_ALIGN_JUSTIFY,
  UPDATE_CUSTOMS_ROW_LAYOUT,
  UPDATE_CUSTOMS_ELEMENT_WIDTH,
  UPDATE_CUSTOMS_HERO1_OVERLAY,
  UPDATE_CUSTOMS_CARD3_DESC_BGCOLOR,
  UPDATE_CUSTOMS_CARD3_DESC_BGCOLORHOVER,
  UPDATE_CUSTOMS_CARD4_POPUPHEIGHT,
  UPDATE_CUSTOMS_CARD4_POPUPHEIGHTHOVER,
  UPDATE_CUSTOMS_CARD6_POS_SIZE,
  UPDATE_CUSTOMS_HERO_PARALLAX_HEIGHT,
  UPDATE_CUSTOMS_PARALLAX_SRC,
  UPDATE_CUSTOMS_PROCESS1_ARROW_COLOR,
  UPDATE_CUSTOMS_PROCESS2_LINE_COLOR,
  UPDATE_CUSTOMS_PROCESS2_ICON_COLOR,
  UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_BGCOLOR,
  UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_SHADOWCOLOR,
  UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_POSLEFT,
  FLIP_SECTION_DIVIDER,
  UPDATE_CUSTOMS_SECTIONDIVIDER_COLORS,
  UPDATE_COMPONENT_BGCOLOR,
  UPDATE_CUSTOMS_COMPONENT_SPACE_TOP,
  UPDATE_CUSTOMS_COMPONENT_SPACE_BOTTOM,
  UPDATE_TEXTCONTENT,
  UPDATE_NAVBAR_POSITION,
  UPDATE_NAVBAR_SCROLLEDPAST_BOOL,
  UPDATE_NAVBAR_SCROLLEDPAST_SIZE_BGCOLOR,
  UPDATE_NAVBAR_SCROLLEDPAST_LOGOHEIGHT,
  UPDATE_NAVBAR_LINK_DATA,
  UPDATE_CAROUSEL_NAV_STYLES,
  UPDATE_CAROUSEL_PAG_STYLES,
  UPDATE_CUSTOMS_BACKTOTOP_POS,
  UPDATE_CUSTOMS_BACKTOTOP_SIZE_ICON,
  UPDATE_CUSTOMS_BACKTOTOP_SIZE_BTN,
  UPDATE_INPUTFIELD_FONTSIZE,
  UPDATE_INPUTFIELD_PLACEHOLDER_COLOR,
  UPDATE_INPUTFIELD_TYPE_SELECT,
  UPDATE_INPUTFIELD_TYPE_CHECK,
  UPDATE_CAROUSEL_SLIDE_CSS,
  OBJECT_REMOVE_CHILDREN,
  // xxx
  // Other
  CHANGE_SCREEN_SIZE,
} from "../actions/types";

import { CssVarsObj } from "../lib/css/CssVars";
import { getRandomId } from "../lib/domFunctions";
import { getComponentHtmlCss, populateElementIds, duplicate_getElementsRequiringUniqueClassnames, duplicate_renewClassnamesHtml } from "../lib/parse";
import { reorderArray } from "../lib/generalFunctions";
import { reduxGetSelectedElement, getFirstChildByClassname } from "../lib/componentObjectFunctions";
import { TEXT_EDITOR_EDITABLE_CSS_PROPS } from "../lib/textEditorFunctions";
import { getBorderStyleCssString } from "../lib/editFunctions";
import { welcomeToSb } from "../lib/productTour/welcomeToSb";

const INIT_PAGE_ID = "5099803df3f4948bd2f98391";
const INIT_SBPAGES = [
  {
    pageId: INIT_PAGE_ID,
    version: "A",
    pageLink: "index",
    pageTitle: "Template website built with SiteBuilder",
    pageDesc: "Template website built with SiteBuilder",
    pageType: "website",
    pagePreview: "",
    components: [],
  },
];

const INIT_SBCUSTOMCSS = [];
const EMPTY_COLOR_PICKER_OBJ = {
  title: "",
  color: "",
  varName: "",
};

// Set initialState to an empty array
const initialState = {
  // Overall
  project: null,
  sbPages: INIT_SBPAGES,
  sbCustomCss: INIT_SBCUSTOMCSS,
  sbCssVars: CssVarsObj,
  // Drag & drop
  draggedId: -1,
  isDraggingFromMenu: false,
  idSelectedComponentFromMenu: "",
  // Product tour
  productTourIsOpen: false,
  prevData: { sbPages: INIT_SBPAGES, sbCustomCss: INIT_SBCUSTOMCSS, sbCssVars: CssVarsObj },
  // Edit component
  selectedElement: "",
  editFormsToShow: [],
  startStyles: null,
  // History / track changes
  trackChanges: [],
  posTrackChanges: 0,
  // Manage pages
  activePageId: INIT_PAGE_ID,
  activeVersion: "A", // change in activeVersion should change the activePageId. You probably don't even need this anymore; just take page.version from the activePage which is unique through its unique activePageId
  // Edit styles
  colorPicker: EMPTY_COLOR_PICKER_OBJ,
  // Other
  screen: "desktop",
};

const arrInsertAt = (arr, pos, itemToInsert) => [...arr.slice(0, pos), itemToInsert, ...arr.slice(pos)];

// Reducer functionality
export default function sb(state = initialState, action) {
  // Destructure action
  const { type, payload } = action;

  // Re-used vars
  let prev;
  let splitSelectedComponentId;
  let splitElementId;
  let selectedElement;
  let customCss;
  let customClasses;
  let targetClassname;

  const getSelectedElement = (splitElementId) => {
    try {
      // 1) Get selected component
      let selectedElement = state.sbPages
        .filter((page) => page.pageId === state.activePageId)[0]
        .components.filter((component) => component.componentId === splitElementId[0])[0];
      // 2) Move down the component tree to the selected element
      for (let i = 1; i < splitElementId.length; i++) {
        selectedElement = selectedElement.children.filter((child) => child.childId === splitElementId[i])[0];
      }
      return selectedElement;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  switch (type) {
    // Manage project
    case CLEAN_CUSTOM_CSS:
      // payload = cleaned custom css array
      return {
        ...state,
        sbCustomCss: payload,
      };
    case LOAD_PROJECT:
      // payload = project object
      return {
        ...state,
        project: {
          _id: payload._id,
          projectName: payload.projectName,
          gallery: payload.gallery,
          wsId: payload.workspace,
          status: payload.status,
          siteMeta: payload.siteMeta,
        },
        sbPages: payload.sbPages,
        sbCustomCss: payload.sbCustomCss,
        sbCssVars: payload.sbCssVars,
        activePageId: payload.sbPages[0].pageId,
        trackChanges: [],
        posTrackChanges: 0,
        selectedElement: "",
        editFormsToShow: [],
        startStyles: null,
      };
    case LOAD_TEMPLATE:
      // payload = { sbPages, sbCustomCss, sbCssVars }
      return {
        ...state,
        sbPages: payload.sbPages,
        sbCustomCss: payload.sbCustomCss,
        sbCssVars: payload.sbCssVars,
        activePageId: payload.sbPages[0].pageId,
        trackChanges: [],
        posTrackChanges: 0,
        selectedElement: "",
        editFormsToShow: [],
        startStyles: null,
      };
    case UPDATE_PROJECT_GALLERY:
      // payload = gallery array
      return {
        ...state,
        project: { ...state.project, gallery: payload },
      };
    case UPDATE_GALLERY_FILE_DESC:
      // payload = { fileId, newDesc }
      return {
        ...state,
        project: {
          ...state.project,
          gallery: state.project.gallery.map((item) => (item._id === payload.fileId ? { ...item, fileDesc: payload.newDesc } : item)),
        },
      };
    case UPDATE_SITEMETA:
      // payload = { metaVariable, metaValue }
      return {
        ...state,
        project: { ...state.project, siteMeta: { ...state.project.siteMeta, [payload.metaVariable]: payload.metaValue } },
      };
    // Drag & drop
    case START_DRAG_FROM_MENU:
      return {
        ...state,
        // Payload is the componentId
        isDraggingFromMenu: true,
        idSelectedComponentFromMenu: payload,
      };
    case END_DRAG_FROM_MENU:
      return {
        ...state,
        isDraggingFromMenu: false,
        idSelectedComponentFromMenu: "",
      };
    case DROP_COMPONENT:
      prev = {
        // sbPages: structuredClone(state.sbPages.filter((page) => page.pageId === state.activePageId)[0].components),
        sbPages: structuredClone(state.sbPages),
        sbCustomCss: structuredClone(state.sbCustomCss),
      };
      let newComponentId = getRandomId();
      let { html, css } = getComponentHtmlCss(state.idSelectedComponentFromMenu, newComponentId);
      let activePageComponents = state.sbPages.filter((page) => page.pageId === state.activePageId)[0].components;
      activePageComponents = arrInsertAt(activePageComponents, payload, html);
      return {
        ...state,
        // Payload = posToInsert
        sbPages: state.sbPages.map((page) => (page.pageId === state.activePageId ? { ...page, components: activePageComponents } : page)),
        sbCustomCss: [...state.sbCustomCss, css],
        newlyAddedComponent: html,
        draggedId: -1,
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    // Product tour
    case OPEN_PRODUCT_TOUR:
      let prevData = {
        sbPages: structuredClone(state.sbPages),
        sbCustomCss: structuredClone(state.sbCustomCss),
        sbCssVars: structuredClone(state.sbCssVars),
      };
      return state.productTourIsOpen
        ? state
        : {
            ...state,
            sbPages: welcomeToSb.sbPages,
            sbCustomCss: welcomeToSb.sbCustomCss,
            sbCssVars: welcomeToSb.sbCssVars,
            prevData,
            productTourIsOpen: true,
          };
    case CLOSE_PRODUCT_TOUR:
      return state.productTourIsOpen
        ? {
            ...state,
            sbPages: state.prevData.sbPages,
            sbCustomCss: state.prevData.sbCustomCss,
            sbCssVars: state.prevData.sbCssVars,
            prevData: { sbPages: INIT_SBPAGES, sbCustomCss: INIT_SBCUSTOMCSS, sbCssVars: CssVarsObj },
            productTourIsOpen: false,
          }
        : state;
    // Edit component
    case REORDER_COMPONENT:
      // Payload = { componentId, direction }
      // Save current version for track changes history
      prev = {
        // sbPages: structuredClone(state.sbPages.filter((page) => page.pageId === state.activePageId)[0].components),
        sbPages: structuredClone(state.sbPages),
        sbCustomCss: structuredClone(state.sbCustomCss),
      };
      // Prepare reordered array
      let reorderedComponents = structuredClone(state.sbPages.filter((page) => page.pageId === state.activePageId)[0].components);
      let from = reorderedComponents.map((component) => component.componentId).indexOf(payload.componentId);
      let to = from + payload.direction;
      // if (from + to >= 0 && from + to < reorderedComponents.length) {
      if (to >= 0 && to <= reorderedComponents.length) {
        return {
          ...state,
          sbPages: state.sbPages.map((page) =>
            page.pageId === state.activePageId ? { ...page, components: reorderArray(reorderedComponents, from, to) } : page
          ),
          trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
          posTrackChanges: 0,
        };
      } else {
        return {
          ...state,
        };
      }
    case DELETE_COMPONENT:
      // Save current version for track changes history
      prev = {
        // sbPages: structuredClone(state.sbPages.filter((page) => page.pageId === state.activePageId)[0].components),
        sbPages: structuredClone(state.sbPages),
        sbCustomCss: structuredClone(state.sbCustomCss),
      };
      return {
        ...state,
        // Payload is componentId
        sbPages: state.sbPages.map((page) =>
          page.pageId === state.activePageId
            ? { ...page, components: page.components.filter((component) => component.componentId !== payload) }
            : page
        ),
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    case SET_SELECTED_ELEMENT:
      return {
        ...state,
        // Payload is the data-id of the selectedElement
        selectedElement: payload,
        editFormsToShow: payload === "" ? [] : state.editFormsToShow,
      };
    // Manage elements
    case DELETE_ELEMENT:
      prev = {
        // sbPages: structuredClone(state.sbPages.filter((page) => page.pageId === state.activePageId)[0].components),
        sbPages: structuredClone(state.sbPages),
        sbCustomCss: structuredClone(state.sbCustomCss),
      };
      // payload is concatenated elementId (i.e., "xx-xx-xx-...")
      splitSelectedComponentId = payload.split("-");
      let deleteElement = (target, deleteElementId, i) => {
        // Remove the specified element from the sbPages stack
        // 1) find the parent of the deleteElementId and 2) remove the deleteElementId from the parent's children array
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 2] !== "undefined") {
            // If 2 levels deeper exist, you're not at the parent yet
            return { ...target, children: target.children.map((child) => deleteElement(child, deleteElementId, i + 1)) };
          } else {
            // This is the selectedElement's parent => remove the element that's to be deleted from its children array
            return { ...target, children: target.children.filter((child) => child.childId !== deleteElementId) };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0]
              ? deleteElement(component, splitSelectedComponentId[splitSelectedComponentId.length - 1], 0)
              : component
          ),
        })),
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    case DUPLICATE_ELEMENT:
      prev = {
        // sbPages: structuredClone(state.sbPages.filter((page) => page.pageId === state.activePageId)[0].components),
        sbPages: structuredClone(state.sbPages),
        sbCustomCss: structuredClone(state.sbCustomCss),
      };
      // payload is concatenated elementId of the sbtype=element (i.e., "xx-xx-xx-...")
      splitSelectedComponentId = payload.split("-");
      // Set up array of classnames that need to be unique
      let duplicateUniqueClassnames = [];
      // Duplicate element in sbPages
      let duplicateElement = (target, elementId, i) => {
        // Copy the specified element and add the copied element next to the specified element
        // 1) find the parent of the elementId, 2) find the pos of elementId,
        // 3) generate copy of elementId and update IDs of it and its children and 4) insert copy of elementId after elementId
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 2] !== "undefined") {
            // If 2 levels deeper exist, you're not at the parent yet
            return { ...target, children: target.children.map((child) => duplicateElement(child, elementId, i + 1)) };
          } else {
            // This is the selectedElement's parent
            // Find position of elementId
            let pos = target.children.map((child) => child.childId).indexOf(splitSelectedComponentId[i + 1]);
            // Get copy of element to be inserted and update IDs
            let copiedElementToAdd = populateElementIds(structuredClone(target.children.filter((child) => child.childId === elementId)[0]), true);
            // Get classnames that need to be unique
            duplicateUniqueClassnames = duplicate_getElementsRequiringUniqueClassnames(
              structuredClone(target.children.filter((child) => child.childId === elementId)[0]),
              state.sbCustomCss.filter((component) => component.componentId === splitSelectedComponentId[0])[0].classes
            );
            copiedElementToAdd = duplicate_renewClassnamesHtml(copiedElementToAdd, duplicateUniqueClassnames);
            // Insert copied element
            return { ...target, children: arrInsertAt(target.children, pos + 1, copiedElementToAdd) };
          }
        } else {
          return target;
        }
      };
      // Duplicate css in sbCustomCss
      let duplicateCss = (classes) => {
        // Get all css items whose className need to be updated
        let cssToDuplicate = classes.filter((item) =>
          duplicateUniqueClassnames.map((uniqueClassname) => uniqueClassname.oldClassname).includes(item.className)
        );
        // Update the classNames
        cssToDuplicate = cssToDuplicate.map((item) => ({
          ...item,
          className: duplicateUniqueClassnames.filter((uniqueClassname) => uniqueClassname.oldClassname === item.className)[0].newClassname,
        }));
        return cssToDuplicate;
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0]
              ? duplicateElement(component, splitSelectedComponentId[splitSelectedComponentId.length - 1], 0)
              : component
          ),
        })),
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitSelectedComponentId[0]
            ? {
                ...customCssState,
                classes: [...customCssState.classes, ...duplicateCss(customCssState.classes)],
              }
            : customCssState
        ),
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    case REORDER_COL:
      prev = {
        sbPages: structuredClone(state.sbPages),
        sbCustomCss: structuredClone(state.sbCustomCss),
      };
      // payload is concatenated elementId (i.e., "xx-xx-xx-...")
      splitSelectedComponentId = payload.split("-");
      let reorderCol = (target, reorderColId, i) => {
        // 1) find the element to be reordered
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // If a level deeper exist, you're not at the element yet
            return { ...target, children: target.children.map((child) => reorderCol(child, reorderColId, i + 1)) };
          } else {
            // This is the col to be reordered => change class name
            return {
              ...target,
              classes: target.classes.map((className) =>
                className === "order-first" ? "order-last" : className === "order-last" ? "order-first" : className
              ),
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0]
              ? reorderCol(component, splitSelectedComponentId[splitSelectedComponentId.length - 1], 0)
              : component
          ),
        })),
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    // Edit forms / input fields
    case REORDER_INPUTFIELD:
      prev = {
        sbPages: structuredClone(state.sbPages),
        sbCustomCss: structuredClone(state.sbCustomCss),
      };
      // payload = { splitElementId, direction }
      splitSelectedComponentId = payload.splitElementId;
      let reorderInputfield = (target, elementId, i) => {
        // Move the specified element's position within its parent div to the specified direction
        // 1) find the parent of the elementId, 2) find the current pos of elementId, 3) reorder array
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 2] !== "undefined") {
            // If 2 levels deeper exist, you're not at the parent yet
            return { ...target, children: target.children.map((child) => reorderInputfield(child, elementId, i + 1)) };
          } else {
            // This is the selectedElement's parent
            // Find position of elementId
            let copiedChildren = structuredClone(target.children);
            // let pos = target.children.map((child) => child.childId).indexOf(splitSelectedComponentId[i + 1]);
            let currPosInputfield = copiedChildren.map((child) => child.childId).indexOf(splitSelectedComponentId[i + 1]);
            let toPosInputfield = currPosInputfield + payload.direction;
            // Reorder the selectedElement's parent children array
            if (toPosInputfield >= 0 && toPosInputfield <= copiedChildren.length) {
              return { ...target, children: reorderArray(copiedChildren, currPosInputfield, toPosInputfield) };
            } else {
              return target;
            }
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0]
              ? reorderInputfield(component, splitSelectedComponentId[splitSelectedComponentId.length - 1], 0)
              : component
          ),
        })),
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    case TOGGLE_CHECKBOX:
      // payload = { splitElementId, val }
      splitSelectedComponentId = payload.splitElementId;
      let toggleCheckbox = (target, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => toggleCheckbox(child, i + 1)) };
          } else {
            return {
              ...target,
              attributes: payload.val
                ? [...target.attributes, { property: "checked", value: "true" }]
                : target.attributes.filter((attr) => attr.property !== "checked"),
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? toggleCheckbox(component, 0) : component
          ),
        })),
      };
    // Manage pages
    case ADD_NEW_PAGE:
      // payload = newPageName
      let newPageObj = {
        pageId: uuidv4(),
        version: "A",
        pageLink: payload,
        pageTitle: payload,
        pageDesc: "",
        components: [],
      };
      return {
        ...state,
        sbPages: [...state.sbPages, newPageObj],
      };
    case ADD_NEW_VERSION:
      // payload = { pageToAdd, customCssToAdd }
      return {
        ...state,
        sbPages: [...state.sbPages, payload.pageToAdd],
        sbCustomCss: [...state.sbCustomCss, ...payload.customCssToAdd],
      };
    case SET_ACTIVE_PAGE:
      // payload = pageId
      return {
        ...state,
        activePageId: payload,
      };
    case EDIT_PAGE_INFO:
      return {
        ...state,
        // Payload = { pageId, pageName, pageTitle, pageDesc, pageType, oldPageName }
        sbPages: state.sbPages.map((page) =>
          page.pageId === payload.pageId
            ? { ...page, pageLink: payload.pageName, pageTitle: payload.pageTitle, pageDesc: payload.pageDesc, pageType: payload.pageType }
            : page.pageLink === payload.oldPageName
            ? { ...page, pageLink: payload.pageName }
            : page
        ),
      };
    case EDIT_PAGE_SOCIAL_PREVIEW:
      return {
        ...state,
        // Payload = { pageId, fileName }
        sbPages: state.sbPages.map((page) => (page.pageId === payload.pageId ? { ...page, pagePreview: payload.fileName } : page)),
      };
    case DELETE_PAGE:
      return {
        ...state,
        // Payload = pageId
        sbPages: state.sbPages.filter((page) => page.pageId !== payload),
      };
    case COPY_COMPONENT_TO_OTHER_PAGE:
      // Payload = { splitElementId, pageIdToCopyTo }
      selectedElement = getSelectedElement(payload.splitElementId);
      return {
        ...state,
        sbPages: state.sbPages.map((page) =>
          page.pageId === payload.pageIdToCopyTo ? { ...page, components: [...page.components, selectedElement] } : page
        ),
      };
    // Track changes
    case SAVE_TRACK_CHANGES:
      prev = {
        // sbPages: structuredClone(state.sbPages.filter((page) => page.pageId === state.activePageId)[0].components),
        sbPages: structuredClone(state.sbPages),
        sbCustomCss: structuredClone(state.sbCustomCss),
      };
      return {
        ...state,
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    case UNDO_REDO:
      // Payload is either +1 to undo a change or -1 to redo a change
      let restoreTo =
        payload === 1
          ? structuredClone(state.trackChanges[state.posTrackChanges])
          : structuredClone(state.trackChanges[state.posTrackChanges + payload]);
      // If posTrackChanges === 0 & undo was clicked: Save current version into trackChanges so the undo can be restored through redo
      if (state.posTrackChanges === 0 && payload === 1) {
        let curr = {
          // sbPages: structuredClone(state.sbPages.filter((page) => page.pageId === state.activePageId)[0].components),
          sbPages: structuredClone(state.sbPages),
          sbCustomCss: structuredClone(state.sbCustomCss),
        };
        return {
          ...state,
          // sbPages: state.sbPages.map((page) => (page.pageId === state.activePageId ? { ...page, components: restoreTo.sbPages } : page)),
          sbPages: restoreTo.sbPages,
          sbCustomCss: restoreTo.sbCustomCss,
          posTrackChanges: state.posTrackChanges + payload,
          trackChanges: [curr, ...state.trackChanges],
        };
      }
      // If posTrackChanges === 1 & redo was clicked: remove trackChanges[0] from the stack, as that was only added to allow restoration via redo
      if (state.posTrackChanges === 1 && payload === -1) {
        return {
          ...state,
          // sbPages: state.sbPages.map((page) => (page.pageId === state.activePageId ? { ...page, components: restoreTo.sbPages } : page)),
          sbPages: restoreTo.sbPages,
          sbCustomCss: restoreTo.sbCustomCss,
          posTrackChanges: state.posTrackChanges + payload,
          trackChanges: state.trackChanges.slice(1),
        };
      }
      // Else: Normal behavior
      else {
        return {
          ...state,
          // sbPages: state.sbPages.map((page) => (page.pageId === state.activePageId ? { ...page, components: restoreTo.sbPages } : page)),
          sbPages: restoreTo.sbPages,
          sbCustomCss: restoreTo.sbCustomCss,
          posTrackChanges: state.posTrackChanges + payload,
        };
      }
    // Edit styles
    case START_EDITING:
      return {
        ...state,
        editFormsToShow: payload.editFormsToShow,
        startStyles: payload.startStyles,
      };
    case OPEN_COLOR_PICKER:
      return {
        ...state,
        // Payload = { title, color, varName }
        colorPicker: {
          title: payload.title,
          color: payload.color,
          varName: payload.varName,
        },
      };
    case UPDATE_CSS_VAR:
      return {
        ...state,
        sbCssVars: { ...state.sbCssVars, [payload.varName]: payload.value },
        colorPicker: EMPTY_COLOR_PICKER_OBJ,
      };
    case CONFIRM_COLOR_SCHEME:
      // payload = [ "rgba(x, x, x, x)", "rgba(x, x, x, x)", "rgba(x, x, x, x)", "rgba(x, x, x, x)", "rgba(x, x, x, x)" ]
      return {
        ...state,
        sbCssVars: { ...state.sbCssVars, color1: payload[0], color2: payload[1], color3: payload[2], color4: payload[3], color5: payload[4] },
      };
    case CHANGE_SITE_COLORS:
      // payload is [ { varName: "color1-10", newRgb: "x, x, x" } ]
      // Add oldRgb value to the array
      let changeSiteColorRgbs = payload.map((color) => ({
        ...color,
        oldRgb: (state.sbCssVars[color.varName].match(/\((\d+, \d+, \d+).+?\)/) || ["", "0, 0, 0"])[1],
      }));
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((componentCss) => ({
          ...componentCss,
          classes: componentCss.classes.map((className) => ({
            ...className,
            rules: className.rules.map((rule) => {
              if (rule.value.toString().includes("rgba(")) {
                let val = rule.value;
                changeSiteColorRgbs.forEach((color) => {
                  if (rule.value.includes(color.oldRgb)) {
                    val = rule.value.replace(color.oldRgb, color.newRgb);
                  }
                });
                return { ...rule, value: val };
              } else {
                return rule;
              }
            }),
          })),
        })),
      };
    case UPDATE_COMPONENT_CSS:
      // payload = { splitElementId, updatedCssRules } => updatedCssRules has format: [ { property: "margin", pseudo: "", value: "0px 0px 0.5px 8px" } ]
      // Get the selected component ID
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === state.selectedElement.split("-")[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      if (typeof targetClassname === "undefined") {
        // If targetClassname is undefined, it means the classname doesn't have rules set yet => take selectedElement's 1st class as targetClassname
        targetClassname = selectedElement.classes[0];
      }
      // Get existing element class css rules
      customCss = customCss.filter((custom) => custom.className === targetClassname);
      if (customCss.length === 0) {
        // Found targetClassname doesn't have rules yet => add them
        customCss = [
          {
            className: targetClassname,
            pseudo: "",
            rules: payload.updatedCssRules.filter((rule) => rule.pseudo === "").map((rule) => ({ property: rule.property, value: rule.value })),
          },
          {
            className: targetClassname,
            pseudo: "hover",
            rules: payload.updatedCssRules.filter((rule) => rule.pseudo === "hover").map((rule) => ({ property: rule.property, value: rule.value })),
          },
        ];
      } else {
        // Found targetClassname has existing rules =>
        // 1) Remove old rules
        customCss = customCss.map((custom) => {
          // Get updated rules of same pseudo
          let updatedRulesCssProps = payload.updatedCssRules.filter((rule) => rule.pseudo === custom.pseudo).map((rule) => rule.property);
          // Remove updatedRules CSS properties
          return { ...custom, rules: custom.rules.filter((rule) => !updatedRulesCssProps.includes(rule.property)) };
        });
        // 2) Add new rules
        customCss = customCss.map((custom) => ({
          ...custom,
          rules: [
            ...custom.rules,
            ...payload.updatedCssRules
              .filter((rule) => rule.pseudo === custom.pseudo)
              .map((rule) => ({ property: rule.property, value: rule.value })),
          ],
        }));
      }
      // Update in state
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: [...customCssState.classes.filter((customClass) => customClass.className !== targetClassname), ...customCss],
              }
            : customCssState
        ),
      };
    case UPDATE_COMPONENT_CLASSES:
      // payload = { splitElementId, updatedClasses } => updatedClasses = [ { oldClassname: "container", newClassname: "container-fluid" }, ... ]
      // Get the selected component ID
      splitSelectedComponentId = payload.splitElementId;
      let updateComponentClasses = (target, updatedClasses, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateComponentClasses(child, updatedClasses, i + 1)) };
          } else {
            let classesToRemove = updatedClasses.map((item) => item.oldClassname);
            let classesToAdd = updatedClasses.map((item) => item.newClassname);
            return { ...target, classes: [...target.classes.filter((className) => !classesToRemove.includes(className)), ...classesToAdd] };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateComponentClasses(component, payload.updatedClasses, 0) : component
          ),
        })),
      };
    case UPDATE_COMPONENT_ATTRIBUTES:
      // payload = { splitElementId, updatedAttributes } => updatedAttributes = [ { attr: "id", oldVal: "xxx", newVal: "yyy" }, ... ]
      // Get the selected component ID
      splitSelectedComponentId = payload.splitElementId;
      let updateComponentAttributes = (target, updatedAttributes, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateComponentAttributes(child, updatedAttributes, i + 1)) };
          } else {
            let attrsToRemove = updatedAttributes.map((item) => item.attr);
            let attrsToAdd = updatedAttributes.map((item) => ({ property: item.attr, value: item.newVal }));
            return { ...target, attributes: [...target.attributes.filter((attr) => !attrsToRemove.includes(attr.property)), ...attrsToAdd] };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateComponentAttributes(component, payload.updatedAttributes, 0) : component
          ),
        })),
      };
    case UPDATE_COMPONENT_HTMLTAGNAME:
      // payload = { splitElementId, updatedHtmlTagname } => updatedHtmlTagname = { oldVal: "xxx", newVal: "yyy" }
      // Get the selected component ID
      splitSelectedComponentId = payload.splitElementId;
      let updateComponentHtmlTagname = (target, updatedHtmlTagname, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateComponentHtmlTagname(child, updatedHtmlTagname, i + 1)) };
          } else {
            return { ...target, htmlTagName: updatedHtmlTagname.newVal };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateComponentHtmlTagname(component, payload.updatedHtmlTagname, 0) : component
          ),
        })),
      };
    case UPDATE_EXISTING_TEXT_ELEMENT:
      // payload = { elementObj, cssRules }
      // elementObj
      splitSelectedComponentId = state.selectedElement.split("-");
      let updateTextElement = (target, elementObj, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateTextElement(child, elementObj, i + 1)) };
          } else {
            return elementObj;
          }
        } else {
          return target;
        }
      };
      // Determine which css style props can be updated
      let canUpdExtTextElStyleProp = (cssProp) => {
        if (payload.elementObj.htmlTagName === "a") {
          return !TEXT_EDITOR_EDITABLE_CSS_PROPS.filter(
            (prop) => !["text-decoration", "color", "background", "background-color"].includes(prop)
          ).includes(cssProp);
        }
        return !TEXT_EDITOR_EDITABLE_CSS_PROPS.includes(cssProp);
      };
      // cssRules
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === splitSelectedComponentId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = payload.elementObj.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateTextElement(component, payload.elementObj, 0) : component
          ),
        })),
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitSelectedComponentId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((item) =>
                  item.className === targetClassname && item.pseudo === ""
                    ? {
                        ...item,
                        // rules: [...item.rules.filter((rule) => !TEXT_EDITOR_EDITABLE_CSS_PROPS.includes(rule.property)), ...payload.cssRules],
                        rules: [...item.rules.filter((rule) => canUpdExtTextElStyleProp(rule.property)), ...payload.cssRules],
                      }
                    : item
                ),
              }
            : customCssState
        ),
      };
    case ADD_NEW_TEXT_ELEMENT:
      // payload = { elementObj, cssRules }
      splitSelectedComponentId = state.selectedElement.split("-");
      let addTextElement = (target, textElementObjToAdd, i) => {
        // Add the dynamically created text element after the text element that was being edited (= selectedElement)
        // So, you need to 1) find the parent of the selectedElement, 2) find selectedElement's position within its parent's children array and
        //     3) insert the new text element after that position
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 2] !== "undefined") {
            // If 2 levels deeper exist, you're not at the parent yet
            return { ...target, children: target.children.map((child) => addTextElement(child, textElementObjToAdd, i + 1)) };
          } else {
            // This is the selectedElement's parent => find selectedElement's position within the children array and add new text element after
            let pos = target.children.map((child) => child.childId).indexOf(splitSelectedComponentId[i + 1]);
            // Get the text element that is being split up in multiple pieces (= selectedElement)
            let textElementBeingDuplicated = target.children.filter((child) => child.childId === splitSelectedComponentId[i + 1])[0];
            // Get that text element's non-unique classes (e.g. .w-[x])
            customClasses = state.sbCustomCss
              .filter((customCss) => customCss.componentId === splitSelectedComponentId[0])[0]
              .classes.map((custom) => custom.className);
            let textElementBeingDuplicated_classes = textElementBeingDuplicated.classes.filter((className) => !customClasses.includes(className));
            // Add those non-unique classes to the textElementObjToAdd
            return {
              ...target,
              children: arrInsertAt(target.children, pos + 1, {
                ...textElementObjToAdd,
                classes: [...textElementObjToAdd.classes, ...textElementBeingDuplicated_classes],
              }),
            };
          }
        } else {
          return target;
        }
      };
      // cssRules
      let classesToAdd = [
        { className: payload.elementObj.classes[0], pseudo: "", rules: payload.cssRules },
        { className: payload.elementObj.classes[0], pseudo: "hover", rules: [] },
      ];
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? addTextElement(component, payload.elementObj, 0) : component
          ),
        })),
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitSelectedComponentId[0]
            ? {
                ...customCssState,
                classes: [...customCssState.classes, ...classesToAdd],
              }
            : customCssState
        ),
      };
    case UPDATE_LIST_MARKER2:
      // payload = { splitElementId, cssRules }
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === splitElementId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === "before"
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !payload.cssRules.map((rule) => rule.property).includes(rule.property)),
                          ...payload.cssRules,
                        ],
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case SELECT_IMG_FROM_GALLERY:
      // payload = { newSrc, targetStartStyleProp, fileDesc }
      // targetStartStyleProp = "imgSource" || "parallaxImgSrc"
      return {
        ...state,
        startStyles: { ...state.startStyles, [payload.targetStartStyleProp]: payload.newSrc, imgDesc: payload.fileDesc },
      };
    case UPDATE_CUSTOMS_ICON_EDITOR:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      // splitSelectedComponentId = state.selectedElement.split("-");
      splitSelectedComponentId = payload.splitElementId;
      let updateCustom_iconEditor = (target, custom, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateCustom_iconEditor(child, custom, i + 1)) };
          } else {
            // Found selectedElement => find its icon child, remove old icon classes and add new icon classes
            return {
              ...target,
              children: target.children.map((child) =>
                child.classes.includes("icon")
                  ? {
                      ...child,
                      classes: [...child.classes.filter((className) => !custom.oldVal.split(" ").includes(className)), ...custom.newVal.split(" ")],
                    }
                  : child
              ),
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateCustom_iconEditor(component, payload.custom, 0) : component
          ),
        })),
      };
    case UPDATE_CUSTOMS_ROW_ALIGN_JUSTIFY:
      // payload = { property, oldVal, newVal, splitElementId }
      splitSelectedComponentId = payload.splitElementId;
      let updateBlockRowAlignJustify = (target, payload, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 2] !== "undefined") {
            // selectedElement is the element_col => get parent
            return { ...target, children: target.children.map((child) => updateBlockRowAlignJustify(child, payload, i + 1)) };
          } else {
            // This is the block div => remove old class and add new one
            return { ...target, classes: [...target.classes.filter((className) => className !== payload.oldVal), payload.newVal] };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateBlockRowAlignJustify(component, payload, 0) : component
          ),
        })),
      };
    case UPDATE_CUSTOMS_ROW_LAYOUT:
      // payload = { property, oldVal, newVal, splitElementId } where oldVal/newVal are classes .col[-md/lg]-[x]
      splitSelectedComponentId = payload.splitElementId;
      let updateColClass = (target, payload) => {
        // target = element && no need to check alignWrapper
        if (
          target.type === "element" &&
          target.classes.join(" ").includes("col-") &&
          target.attributes.filter((attr) => attr.property === "data-checkalignmentwrapper" && attr.value === "true").length === 0
        ) {
          return { ...target, classes: [...target.classes.filter((className) => className !== payload.oldVal), payload.newVal] };
        }
        // target = elementalignmentwrapper
        if (target.attributes.filter((attr) => attr.property === "data-elementalignmentwrapper" && attr.value === "true").length > 0) {
          return { ...target, classes: [...target.classes.filter((className) => className !== payload.oldVal), payload.newVal] };
        }
        // If target is neither the element_col nor the alignmentWrapper, call recursively on its children
        return { ...target, children: target.children.map((child) => updateColClass(child, payload)) };
      };
      let updateBlockRowLayout = (target, payload, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 2] !== "undefined") {
            // selectedElement is the element_col => get parent
            return { ...target, children: target.children.map((child) => updateBlockRowLayout(child, payload, i + 1)) };
          } else {
            // update the classes of its element_col children
            // This is the block div => Check whether the div to be updated are its element_col children, or the elementalignmentwrapper children of the element_col div
            return updateColClass(target, payload);
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateBlockRowLayout(component, payload, 0) : component
          ),
        })),
      };
    case UPDATE_CUSTOMS_ELEMENT_WIDTH:
      // payload = { property, oldVal, newVal, splitElementId } where oldVal/newVal are classes .w[-md/lg]-[x]
      splitSelectedComponentId = payload.splitElementId;
      let updateElementWidthClass = (target, payload, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // found selectedElement
            return { ...target, children: target.children.map((child) => updateElementWidthClass(child, payload, i + 1)) };
          } else {
            // This is the element => update its w- classes
            return {
              ...target,
              classes: [...target.classes.filter((className) => className !== payload.oldVal), payload.newVal],
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateElementWidthClass(component, payload, 0) : component
          ),
        })),
      };
    case UPDATE_CUSTOMS_HERO1_OVERLAY:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      // selectedElement = div.hero-1-content
      // Get the hero's className that is to be updated
      // Get the selected component ID
      splitSelectedComponentId = payload.splitElementId;
      // Get selected element
      selectedElement = reduxGetSelectedElement(
        splitSelectedComponentId,
        state.sbPages
          .filter((page) => page.pageId === state.activePageId)[0]
          .components.filter((component) => component.componentId === splitSelectedComponentId[0])[0]
      );
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === state.selectedElement.split("-")[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "background" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_CARD3_DESC_BGCOLOR:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className.includes("card-3-desc-") && className.pseudo === ""
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "background" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_CARD3_DESC_BGCOLORHOVER:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className.includes("card-3-overlay-wrapper-") && className.pseudo.includes("hover .card-3-desc-")
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "background" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_CARD4_POPUPHEIGHT:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className.includes("card-4-content-") && className.pseudo === ""
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "height" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_CARD4_POPUPHEIGHTHOVER:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className.includes("card-4-element-wrapper-") && className.pseudo.includes("hover .card-4-content-")
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "height" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_CARD6_POS_SIZE:
      // payload = { splitElementId, cssRules }
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  (className.className.includes("card-6-border-") || className.className.includes("quote-1-border-")) && className.pseudo === ""
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !["top", "bottom", "left", "right", "width", "height"].includes(rule.property)),
                          ...payload.cssRules,
                        ],
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_HERO_PARALLAX_HEIGHT:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === splitElementId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === ""
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "height" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_PARALLAX_SRC:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === splitElementId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === ""
                    ? {
                        ...className,
                        rules: className.rules.map((rule) =>
                          rule.property === "background-image" ? { ...rule, value: payload.custom.newVal } : rule
                        ),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_PROCESS1_ARROW_COLOR:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      splitSelectedComponentId = payload.splitElementId;
      let process1ArrowColor = (target, custom, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 2] !== "undefined") {
            // Find process1's block parent
            return { ...target, children: target.children.map((child) => process1ArrowColor(child, custom, i + 1)) };
          } else {
            // We're at the block div, which has the process1 divs, which in turn has the SVGs as children
            // Change the attributes on the SVGs
            return {
              ...target,
              children: [
                ...target.children.map((child) =>
                  child.attributes.filter((attr) => attr.property === "data-name" && attr.value === "process1").length > 0
                    ? {
                        ...child,
                        children: [
                          ...child.children.map((child) =>
                            child.htmlTagName === "svg"
                              ? {
                                  ...child,
                                  attributes: child.attributes.map((attr) => (attr.property === "stroke" ? { ...attr, value: custom.newVal } : attr)),
                                }
                              : child
                          ),
                        ],
                      }
                    : child
                ),
              ],
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? process1ArrowColor(component, payload.custom, 0) : component
          ),
        })),
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === "process-1-iconwrapper" && className.pseudo === ""
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "color" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_PROCESS2_LINE_COLOR:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  (className.className.includes("process-2-step-line-") || className.className.includes("process-2-step-circle-")) &&
                  className.pseudo === ""
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "background" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_PROCESS2_ICON_COLOR:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className.includes("process-2-step-circle-") && className.pseudo === ""
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "color" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_BGCOLOR:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === state.selectedElement.split("-")[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && (className.pseudo === "" || className.pseudo === "hover")
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "background" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className.className === targetClassname && className.pseudo === "after"
                    ? {
                        ...className,
                        rules: className.rules.map((rule) =>
                          rule.property === "border-top" ? { ...rule, value: rule.value.replace(payload.custom.oldVal, payload.custom.newVal) } : rule
                        ),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_SHADOWCOLOR:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === state.selectedElement.split("-")[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && (className.pseudo === "" || className.pseudo === "hover")
                    ? {
                        ...className,
                        rules: className.rules.map((rule) =>
                          rule.property === "box-shadow" ? { ...rule, value: rule.value.replace(payload.custom.oldVal, payload.custom.newVal) } : rule
                        ),
                      }
                    : className.className === targetClassname && className.pseudo === "after"
                    ? {
                        ...className,
                        rules: className.rules.map((rule) =>
                          rule.property === "filter" ? { ...rule, value: rule.value.replace(payload.custom.oldVal, payload.custom.newVal) } : rule
                        ),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_POSLEFT:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === state.selectedElement.split("-")[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === "after"
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "left" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case FLIP_SECTION_DIVIDER:
      // payload = { splitElementId, newStyleValue }
      splitSelectedComponentId = payload.splitElementId;
      // Find the component's SVG child and change the SVG's transform style value (remove existing, if any, and add new)
      prev = {
        sbPages: structuredClone(state.sbPages),
        sbCustomCss: structuredClone(state.sbCustomCss),
      };
      let flipSectionDivider = (target, newStyleValue, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => flipSectionDivider(child, newStyleValue, i + 1)) };
          } else {
            // Found selectedElement = svg => update its "transform" style property
            return {
              ...target,
              styles: [...target.styles.filter((style) => style.property !== "transform"), { property: "transform", value: newStyleValue }],
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? flipSectionDivider(component, payload.newStyleValue, 0) : component
          ),
        })),
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    case UPDATE_CUSTOMS_SECTIONDIVIDER_COLORS:
      // payload = { splitElementId, custom }
      // splitElementId = id of the component
      let sdColorsJSON = JSON.stringify(
        state.sbPages
          .filter((page) => page.pageId === state.activePageId)[0]
          .components.filter((component) => component.componentId === payload.splitElementId[0])[0]
      );
      payload.custom.newVal.forEach((newColor, i) => {
        // First replace color values by a string. Needed for when colors are being flipped, as else color1=>color2 and then color2=>color1 sets all colors to color1
        sdColorsJSON = sdColorsJSON.replaceAll(payload.custom.oldVal[i], `SD_COLOR_${i}`);
      });
      payload.custom.newVal.forEach((newColor, i) => {
        sdColorsJSON = sdColorsJSON.replaceAll(`SD_COLOR_${i}`, newColor);
      });
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === payload.splitElementId[0] ? JSON.parse(sdColorsJSON) : component
          ),
        })),
      };
    case UPDATE_COMPONENT_BGCOLOR:
      // payload = { splitElementId, cssRules }
      // splitElementId = id of the component
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      targetClassname = `section.${selectedElement.classes.filter((className) => className.match(/-component-/) !== null)[0] || ""}`;
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === ""
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !["background", "background-color"].includes(rule.property)),
                          ...payload.cssRules,
                          // { property: "background", value: payload.custom.newVal },
                        ],
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_COMPONENT_SPACE_TOP:
      // payload = { splitElementId, custom }
      // splitElementId = id of the component
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      targetClassname = `section.${selectedElement.classes.filter((className) => className.match(/-component-/) !== null)[0] || ""}`;
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === ""
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "padding-top" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_COMPONENT_SPACE_BOTTOM:
      // payload = { splitElementId, custom }
      // splitElementId = id of the component
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      targetClassname = `section.${selectedElement.classes.filter((className) => className.match(/-component-/) !== null)[0] || ""}`;
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === ""
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "padding-bottom" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_TEXTCONTENT:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      splitSelectedComponentId = payload.splitElementId;
      let updateButtonText = (target, custom, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateButtonText(child, custom, i + 1)) };
          } else {
            // Found selectedElement = button => find its textNode child and update its content
            return {
              ...target,
              children: target.children.map((child) =>
                child.htmlTagName === "textNode"
                  ? {
                      ...child,
                      content: custom.newVal,
                    }
                  : child
              ),
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateButtonText(component, payload.custom, 0) : component
          ),
        })),
      };
    case UPDATE_NAVBAR_POSITION:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      // splitSelectedComponentId = the componentId
      splitSelectedComponentId = payload.splitElementId;
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0]
              ? {
                  ...component,
                  attributes: component.attributes.map((attr) =>
                    attr.property === "data-navbarposition" ? { ...attr, value: payload.custom.newVal } : attr
                  ),
                }
              : component
          ),
        })),
      };
    case UPDATE_NAVBAR_SCROLLEDPAST_BOOL:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      // splitSelectedComponentId = the componentId
      splitSelectedComponentId = payload.splitElementId;
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0]
              ? {
                  ...component,
                  attributes: component.attributes.map((attr) =>
                    attr.property === "data-changeonscrollpast" ? { ...attr, value: payload.custom.newVal ? "true" : "false" } : attr
                  ),
                }
              : component
          ),
        })),
      };
    case UPDATE_NAVBAR_SCROLLEDPAST_SIZE_BGCOLOR:
      // payload = { splitElementId, cssRules }
      // splitElementId = componentId of the navbar
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === splitElementId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === `scrolledpast-${splitElementId[0]}.${targetClassname}` && className.pseudo === ""
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !["padding-top", "padding-bottom", "background"].includes(rule.property)),
                          ...payload.cssRules,
                        ],
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_NAVBAR_SCROLLEDPAST_LOGOHEIGHT:
      // payload = { splitElementId, cssRules }
      // splitElementId = componentId of the navbar
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === splitElementId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === `scrolledpast-${splitElementId[0]} .${targetClassname}` && className.pseudo === ""
                    ? {
                        ...className,
                        rules: [...className.rules.filter((rule) => !["max-height"].includes(rule.property)), ...payload.cssRules],
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_NAVBAR_LINK_DATA:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      // splitElementId = ul[data-elementgetter2]
      splitSelectedComponentId = payload.splitElementId;
      let updateLinkData = (target, custom, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateLinkData(child, custom, i + 1)) };
          } else {
            // Found selectedElement = ul[data-elementgetter2] => update its children array
            return {
              ...target,
              children: custom.newVal,
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateLinkData(component, payload.custom, 0) : component
          ),
        })),
      };
    case UPDATE_CAROUSEL_NAV_STYLES:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      // splitElementId = div.splide
      splitElementId = payload.splitElementId;
      // Get selected element = carousel
      selectedElement = getSelectedElement(splitElementId);
      // Get classes to be updated
      let carouselNavClasses = JSON.parse(selectedElement.attributes.filter((attr) => attr.property === "data-splide")[0].value).classes;
      let carouselNavClass_arrow = carouselNavClasses.arrow.split(" ")[1];
      let carouselNavClass_svg = `${carouselNavClass_arrow} svg`;
      let carouselNavClass_prev = carouselNavClasses.prev.split(" ")[1];
      let carouselNavClass_next = carouselNavClasses.next.split(" ")[1];
      // Which attributes to update for which class
      let carouselNav_arrow_cssProps = [
        "background",
        "border-style",
        "border-width",
        "border-color",
        "border-radius",
        "height",
        "width",
        "transition",
      ];
      let carouselNav_svg_cssProps = ["fill", "height", "width", "transition"];
      let carouselNav_prev_cssProps = ["left"];
      let carouselNav_next_cssProps = ["right"];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  // Arrow - Normal
                  // Arrow - Hover
                  // SVG   - Normal
                  // SVG   - Hover
                  // Prev  - Normal
                  // Next  - Normal
                  className.className === carouselNavClass_arrow && className.pseudo === ""
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !carouselNav_arrow_cssProps.includes(rule.property)),
                          { property: "background", value: payload.custom.newVal.bgColor },
                          {
                            property: "border-style",
                            value: getBorderStyleCssString(payload.custom.newVal.borderSide, payload.custom.newVal.borderType),
                          },
                          { property: "border-width", value: `${payload.custom.newVal.borderWidth}px` },
                          { property: "border-color", value: payload.custom.newVal.borderColor },
                          { property: "border-radius", value: payload.custom.newVal.borderRadius },
                          { property: "height", value: `${payload.custom.newVal.iconSize + 1.5}rem` },
                          { property: "width", value: `${payload.custom.newVal.iconSize + 1.5}rem` },
                          { property: "transition", value: `all ${payload.custom.newVal.transition}s ease-in-out` },
                        ],
                      }
                    : className.className === carouselNavClass_arrow && className.pseudo === "hover"
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !carouselNav_arrow_cssProps.includes(rule.property)),
                          { property: "background", value: payload.custom.newVal.bgColorHover },
                          { property: "border-color", value: payload.custom.newVal.borderColorHover },
                        ],
                      }
                    : className.className === carouselNavClass_svg && className.pseudo === ""
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !carouselNav_svg_cssProps.includes(rule.property)),
                          { property: "fill", value: payload.custom.newVal.fillColor },
                          { property: "height", value: `${payload.custom.newVal.iconSize}rem` },
                          { property: "width", value: `${payload.custom.newVal.iconSize}rem` },
                          { property: "transition", value: `all ${payload.custom.newVal.transition}s ease-in-out` },
                        ],
                      }
                    : className.className === carouselNavClass_svg && className.pseudo === "hover"
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !carouselNav_svg_cssProps.includes(rule.property)),
                          { property: "fill", value: payload.custom.newVal.fillColorHover },
                        ],
                      }
                    : className.className === carouselNavClass_prev && className.pseudo === ""
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !carouselNav_prev_cssProps.includes(rule.property)),
                          {
                            property: "left",
                            value: payload.custom.newVal.pos === "inside" ? "1rem" : `-${payload.custom.newVal.iconSize + 1.5}rem`,
                          },
                        ],
                      }
                    : className.className === carouselNavClass_next && className.pseudo === ""
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !carouselNav_next_cssProps.includes(rule.property)),
                          {
                            property: "right",
                            value: payload.custom.newVal.pos === "inside" ? "1rem" : `-${payload.custom.newVal.iconSize + 1.5}rem`,
                          },
                        ],
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CAROUSEL_PAG_STYLES:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      // splitElementId = div.splide
      splitElementId = payload.splitElementId;
      // Get selected element = carousel
      selectedElement = getSelectedElement(splitElementId);
      // Get classes to be updated
      let carouselPagClass = JSON.parse(selectedElement.attributes.filter((attr) => attr.property === "data-splide")[0].value).classes.page.split(
        " "
      )[1];
      // Which attributes to update for which class
      let carouselPag_cssProps = ["background", "border-style", "border-width", "border-color", "border-radius", "height", "width", "transition"];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === carouselPagClass && className.pseudo === ""
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !carouselPag_cssProps.includes(rule.property)),
                          { property: "background", value: payload.custom.newVal.bgColor },
                          {
                            property: "border-style",
                            value: getBorderStyleCssString(payload.custom.newVal.borderSide, payload.custom.newVal.borderType),
                          },
                          { property: "border-width", value: `${payload.custom.newVal.borderWidth}px` },
                          { property: "border-color", value: payload.custom.newVal.borderColor },
                          { property: "border-radius", value: payload.custom.newVal.borderRadius },
                          {
                            property: "height",
                            value:
                              payload.custom.newVal.btnForm === "square"
                                ? `${payload.custom.newVal.btnSize}rem`
                                : `${(payload.custom.newVal.btnSize * 0.4).toFixed(1)}rem`,
                          },
                          { property: "width", value: `${payload.custom.newVal.btnSize}rem` },
                          { property: "transition", value: `all ${payload.custom.newVal.transition}s ease-in-out` },
                        ],
                      }
                    : className.className === carouselPagClass && className.pseudo === "hover"
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !carouselPag_cssProps.includes(rule.property)),
                          { property: "background", value: payload.custom.newVal.bgColorHover },
                          { property: "border-color", value: payload.custom.newVal.borderColorHover },
                        ],
                      }
                    : className.className === `is-active.${carouselPagClass}` && className.pseudo === ""
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !carouselPag_cssProps.includes(rule.property)),
                          { property: "background", value: payload.custom.newVal.bgColorHover },
                          { property: "border-color", value: payload.custom.newVal.borderColorHover },
                        ],
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_BACKTOTOP_POS:
      // payload = { splitElementId, cssRules }
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === splitElementId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === ""
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !["top", "bottom", "left", "right"].includes(rule.property)),
                          ...payload.cssRules,
                        ],
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_BACKTOTOP_SIZE_BTN:
      // payload = { splitElementId, custom }
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === splitElementId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === ""
                    ? {
                        ...className,
                        rules: className.rules.map((rule) =>
                          rule.property === "height" || rule.property === "width" ? { ...rule, value: payload.custom.newVal } : rule
                        ),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_CUSTOMS_BACKTOTOP_SIZE_ICON:
      // payload = { splitElementId, custom }
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === splitElementId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === ""
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "font-size" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_INPUTFIELD_FONTSIZE:
      // payload = { splitElementId, custom }
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === splitElementId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === ""
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "font-size" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_INPUTFIELD_PLACEHOLDER_COLOR:
      // payload = { splitElementId, custom }
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === splitElementId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === ":placeholder"
                    ? {
                        ...className,
                        rules: className.rules.map((rule) => (rule.property === "color" ? { ...rule, value: payload.custom.newVal } : rule)),
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case UPDATE_INPUTFIELD_TYPE_SELECT:
      // payload = { splitElementId, optionObjects }
      // Get the selected component ID
      splitSelectedComponentId = payload.splitElementId;
      let updateInputfieldSelect = (target, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateInputfieldSelect(child, i + 1)) };
          } else {
            // Input field found =>
            // 1) Set htmlTagname to select
            // 2) Change class from "form-control" to "form-select"
            // 3) Add options arr as child
            return {
              ...target,
              htmlTagName: "select",
              classes: [...target.classes.filter((className) => !["form-control", "form-select", "form-check"].includes(className)), "form-select"],
              children: payload.optionObjects,
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateInputfieldSelect(component, 0) : component
          ),
        })),
      };
    case UPDATE_INPUTFIELD_TYPE_CHECK:
      // payload = { splitElementId, checkObject }
      // Get the selected component ID
      splitSelectedComponentId = payload.splitElementId;
      let updateInputfieldCheck = (target, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateInputfieldCheck(child, i + 1)) };
          } else {
            // Input field found =>
            // 1) Set htmlTagname to div
            // 2) Set children to object nee
            // 3) Add attr { property: "data-formcheckwrapper", value: "true" }
            return {
              ...target,
              htmlTagName: "div",
              classes: [...target.classes.filter((className) => !["form-control", "form-select", "form-check"].includes(className)), "form-check"],
              attributes: [...target.attributes, { property: "data-formcheckwrapper", value: "true" }],
              children: payload.checkObject,
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateInputfieldCheck(component, 0) : component
          ),
        })),
      };
    case UPDATE_CAROUSEL_SLIDE_CSS:
      // payload = { splitElementId, cssRules }
      splitElementId = payload.splitElementId;
      // Get selected element = .splide div
      selectedElement = getSelectedElement(splitElementId);
      // Get the image
      let carouselImg = getFirstChildByClassname(selectedElement, "splide__slide");
      if (carouselImg === null) {
        return state;
      }
      carouselImg = carouselImg.children[0];
      if (carouselImg.htmlTagName !== "img") {
        return state;
      }
      // Get selected element classname that is to be updated
      customCss = state.sbCustomCss.filter((customCss) => customCss.componentId === splitElementId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = carouselImg.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        sbCustomCss: state.sbCustomCss.map((customCssState) =>
          customCssState.componentId === splitElementId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname && className.pseudo === ""
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !["max-width", "max-height", "width", "height", "object-fit"].includes(rule.property)),
                          ...payload.cssRules,
                        ],
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case OBJECT_REMOVE_CHILDREN:
      // payload = { splitElementId }
      splitSelectedComponentId = payload.splitElementId;
      let objectRemoveChildren = (target, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => objectRemoveChildren(child, i + 1)) };
          } else {
            return {
              ...target,
              children: [],
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        sbPages: state.sbPages.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? objectRemoveChildren(component, 0) : component
          ),
        })),
      };
    // Other
    case CHANGE_SCREEN_SIZE:
      return {
        ...state,
        // Payload is screenSize
        screen: payload,
      };
    default:
      return state;
  }
}
