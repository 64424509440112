export const hero10 = {
  id: "hero-10",
  name: "Hero 10",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "hero-10-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "h-100", "posRelative"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["d-none", "d-md-block", "col-12", "col-md-6", "col-lg-6", "hero-10-bgcol-left"],
            styles: [],
            attributes: [
              { property: "data-editable", value: "true" },
              { property: "data-name", value: "bgdiv" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-6", "hero-10-bgcol-right"],
            styles: [],
            attributes: [
              { property: "data-editable", value: "true" },
              { property: "data-name", value: "bgdiv" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["w-100", "w-md-90", "w-lg-60", "hero-10-fg-imgwrapper", "posRelative"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["hero-10-img", "w-100", "w-md-70", "w-lg-70"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-1.jpg" },
                  { property: "alt", value: "Image" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                  { property: "data-imgresizable", value: "false" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["hero-10-fg-textdiv", "d-none", "d-md-flex", "w-40"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "bgdiv" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h1",
                    classes: ["hero-10-text", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.hero-10-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.hero-10-component", pseudo: "hover", rules: [] },
      {
        className: "hero-10-component",
        pseudo: "",
        rules: [{ property: "height", value: "80vh" }],
      },
      {
        className: "hero-10-bgcol-left",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "hero-10-bgcol-left",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "hero-10-bgcol-right",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(172, 223, 232, 1)" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "hero-10-bgcol-right",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(172, 223, 232, 1)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "hero-10-fg-imgwrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "20%" }, // half of 1-height
          { property: "height", value: "60%" },
        ],
      },
      {
        className: "hero-10-img",
        pseudo: "",
        rules: [
          { property: "height", value: "100%" },
          { property: "object-fit", value: "cover" },
          { property: "border-radius", value: "0" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "10px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "hero-10-img",
        pseudo: "hover",
        rules: [{ property: "border-color", value: "rgba(255, 255, 255, 1)" }],
      },
      {
        className: "hero-10-fg-textdiv",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "50%" },
          { property: "transform", value: "translateY(-50%)" },
          { property: "right", value: "0" },
          { property: "height", value: "70%" },
          { property: "align-items", value: "center" },
          { property: "background", value: "rgba(235, 235, 235, 1)" },
          { property: "border-radius", value: "0" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "10px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "hero-10-fg-textdiv",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(235, 235, 235, 1)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "hero-10-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "margin", value: "0.25rem 0.25rem 0.25rem 0.25rem" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "hero-10-text",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
