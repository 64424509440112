import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./formComponents/Select";
import ColorPicker from "./formComponents/ColorPicker";
import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";

import { PADDING_VALUES } from "../../../../lib/editStyleVars";
import { translate } from "../../../../translations/translations";

const ListMarker2 = ({ sb: { startStyles } }) => {
  return (
    <>
      <Select
        label={translate("cEditForms.markerStyle", false, null)}
        id={"markerForm"}
        options={[
          { val: "none", label: translate("cEditForms.noMarker", false, null) },
          { val: "opensquare", label: translate("cEditForms.openSquare", false, null) },
          { val: "filledsquare", label: translate("cEditForms.closedSquare", false, null) },
          { val: "opencircle", label: translate("cEditForms.openCircle", false, null) },
          { val: "filledcircle", label: translate("cEditForms.filledCircle", false, null) },
          { val: "line", label: translate("cEditForms.line", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "markerForm")}
        tooltipText={translate("cEditForms.ttMarkerStyle", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.markerColor", false, null)}
        id={"markerBorderColor"}
        startValue={formGetStartValue(startStyles, "markerBorderColor")}
        tooltipText={translate("cEditForms.ttMarkerColor", false, null)}
      />
      <Slider
        label={translate("cEditForms.spaceRightMarker", false, null)}
        id={"markerMarginRight"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "markerMarginRight"))}
        tooltipText={translate("cEditForms.ttSpaceRightMarker", false, null)}
      />
    </>
  );
};

ListMarker2.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(ListMarker2);
