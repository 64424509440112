export const template5 = {
  id: "638b2ed6fc67a1df0b9474e6",
  name: { en: "Architecture firm", nl: "Architecten" },
  desc: { en: "Single-page site for an architecture firm", nl: "Een-pagina site voor een architectuurbureau" },
  categories: ["services"],
  sbPages: [
    {
      pageId: "cb05e5e9-96ee-424e-a250-679eb3f40f72",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "cn04LX",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-5-component-cn04LX"],
          styles: [],
          attributes: [
            { property: "data-name", value: "carousel" },
            { property: "data-editable", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "LBm2Go",
              type: "",
              htmlTagName: "div",
              classes: ["splide", "carousel-slides", "hero-5-splide"],
              styles: [],
              attributes: [
                { property: "aria-label", value: "Carousel" },
                { property: "data-carousel-slidesetup", value: "image" },
                {
                  property: "data-splide",
                  value:
                    '{"keyboard":true,"autoplay":true,"interval":3000,"pauseOnHover":true,"breakpoints":{"640":{"perPage":1,"perMove":1}},"drag":"free","easing":"linear","arrows":true,"pagination":true,"type":"fade","speed":500,"rewindSpeed":1000,"perPage":1,"perMove":1,"classes":{"arrow":"splide__arrow hero-5-arrow-cn04LX","prev":"splide__arrow--prev hero-5-prev-cn04LX","next":"splide__arrow--next hero-5-next-cn04LX","page":"splide__pagination__page hero-5-pagination-cn04LX"},"width":"100vw","height":"100vh","heightRatio":0.5,"focus":"center","rewind":true,"fixedHeightCss":"auto"}',
                },
              ],
              content: "",
              children: [
                {
                  childId: "yrTQqh",
                  type: "",
                  htmlTagName: "div",
                  classes: ["splide__track"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "qUyFYA",
                      type: "",
                      htmlTagName: "div",
                      classes: ["splide__list"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "hpXSfO",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "hero-5-slide"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "nNlRPW",
                              type: "",
                              htmlTagName: "div",
                              classes: ["hero-5-wrapper-cn04LX"],
                              styles: [],
                              attributes: [{ property: "data-herowrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "qUdzeS",
                                  type: "",
                                  htmlTagName: "img",
                                  classes: ["hero-5-img-cn04LX"],
                                  styles: [],
                                  attributes: [
                                    { property: "alt", value: "Image" },
                                    { property: "src", value: "https://cdn.satonda.com/eb/assets/work-1.png" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                                {
                                  childId: "adyAQ4",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["hero-5-content-cn04LX"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "hero1" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "htkSwG",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["hero-5-title-cn04LX", "w-100", "w-md-100", "w-lg-100"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "mSUfdW",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["hero-5-text-cn04LX", "w-100", "w-md-100", "w-lg-100"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "iJA9bO",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["hero-5-button-cn04LX"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_self" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Read more",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "KlSnuF",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "hero-5-slide"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "xtXpXT",
                              type: "",
                              htmlTagName: "div",
                              classes: ["hero-5-wrapper-cn04LX"],
                              styles: [],
                              attributes: [{ property: "data-herowrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "yApf1i",
                                  type: "",
                                  htmlTagName: "img",
                                  classes: ["hero-5-img-cn04LX"],
                                  styles: [],
                                  attributes: [
                                    { property: "alt", value: "Image" },
                                    { property: "src", value: "https://cdn.satonda.com/eb/assets/work-2.png" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                                {
                                  childId: "GVV7I1",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["hero-5-content-cn04LX"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "hero1" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "JUbXzB",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["hero-5-title-cn04LX", "w-100", "w-md-100", "w-lg-100"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Consectetur adipiscing elit",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "mD3Iue",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["hero-5-text-cn04LX", "w-100", "w-md-100", "w-lg-100"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "cjWRsa",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["hero-5-button-cn04LX"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_self" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Read more",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "eLGzeE",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "hero-5-slide"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "iw4RSb",
                              type: "",
                              htmlTagName: "div",
                              classes: ["hero-5-wrapper-cn04LX"],
                              styles: [],
                              attributes: [{ property: "data-herowrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "tNjNLj",
                                  type: "",
                                  htmlTagName: "img",
                                  classes: ["hero-5-img-cn04LX"],
                                  styles: [],
                                  attributes: [
                                    { property: "alt", value: "Image" },
                                    { property: "src", value: "https://cdn.satonda.com/eb/assets/work-3.png" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                                {
                                  childId: "vtV9ZZ",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["hero-5-content-cn04LX"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "hero1" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "I0yVgB",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["hero-5-title-cn04LX", "w-100", "w-md-100", "w-lg-100"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Sed do eiusmod tempor",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "lftzzA",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["hero-5-text-cn04LX", "w-100", "w-md-100", "w-lg-100"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "N3T0Nv",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["hero-5-button-cn04LX"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_self" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Read more",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "wT8E7e",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-wT8E7e"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "alNYBp",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-wT8E7e", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Our services", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "sIsrAN",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-sIsrAN"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "OxJkxd",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-sIsrAN"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "DSN7gk",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-DSN7gk"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "KGIFYN",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-DSN7gk", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "p0cnDL",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-17-component-p0cnDL"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "J5aQ7p",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-17-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "y85LV9",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-17-col-p0cnDL"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "VftAm6",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-17-wrapper-p0cnDL"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "ks7Kfu",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-17-img-p0cnDL"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/work-1.png" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "oMTia2",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-17-paddingwrapper-p0cnDL", "toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "PaBi4M",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-17-icondiv-p0cnDL"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "cPQ0Av",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["card-17-iconwrapper-p0cnDL"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "div" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "sCiy4P",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-regular", "fa-heart"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "O8zXT7",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-17-spacer-p0cnDL"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                            {
                              childId: "mEwrZA",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-17-title-p0cnDL", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Dvm7pC",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow", "card-17-desc-wrapper-p0cnDL"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "PNbwbZ",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["card-17-desc-text-p0cnDL", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod odio sed possimus assumenda reprehenderit optio, vero fuga?",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "fV2PCW",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-17-col-p0cnDL"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "YtwOYb",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-17-wrapper-p0cnDL"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "Zo3cJi",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-17-img-p0cnDL"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/work-2.png" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "R2PSzZ",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-17-paddingwrapper-p0cnDL", "toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "DuPOJD",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-17-icondiv-p0cnDL"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "pqG25T",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["card-17-iconwrapper-p0cnDL"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "div" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "zPecMA",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-regular", "fa-thumbs-up"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "g2HYOm",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-17-spacer-p0cnDL"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                            {
                              childId: "N7RUK4",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-17-title-p0cnDL", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Dolor sit amet",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "oYFGqX",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow", "card-17-desc-wrapper-p0cnDL"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "slkt0H",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["card-17-desc-text-p0cnDL", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod odio sed possimus assumenda reprehenderit optio, vero fuga?",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "VSVcmP",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-17-col-p0cnDL"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "kevWWv",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-17-wrapper-p0cnDL"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "ZAG30G",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-17-img-p0cnDL"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/work-3.png" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "mntCNp",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-17-paddingwrapper-p0cnDL", "toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "o3zlEf",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-17-icondiv-p0cnDL"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "aIeDt0",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["card-17-iconwrapper-p0cnDL"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "div" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "htlyNv",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-regular", "fa-handshake"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "khR0zt",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-17-spacer-p0cnDL"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                            {
                              childId: "PcmwAG",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-17-title-p0cnDL", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Excepteur sint occaecat",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Cmv1Vj",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow", "card-17-desc-wrapper-p0cnDL"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "WbDRvX",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["card-17-desc-text-p0cnDL", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod odio sed possimus assumenda reprehenderit optio, vero fuga?",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "DUsXBa",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-DUsXBa"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "WT4yRb",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-DUsXBa", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Our skills", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "uUo54x",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-uUo54x"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Um54rL",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-uUo54x"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "aPTxYk",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-aPTxYk"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "f5PzWR",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-aPTxYk", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. ",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "NtibCf",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-18-component-NtibCf"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Y0sawf",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "icPN72",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-18-col-NtibCf"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "hQKFNI",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-18-wrapper-NtibCf"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "giDlc3",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "I8tUbS",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-18-iconwrapper-NtibCf"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "Q42ODM",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-regular", "fa-user"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "yiwVGU",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "RI59Nt",
                                  type: "",
                                  htmlTagName: "h3",
                                  classes: ["card-18-title-NtibCf", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Lorem Ipsum",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "ojDBNp",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-18-text-NtibCf", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "wypxpd",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-18-col-NtibCf"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "J7yImb",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-18-wrapper-NtibCf"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "J9KKI5",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "uwumkp",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-18-iconwrapper-NtibCf"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "HpZq4z",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-regular", "fa-comment"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "KmhZTS",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "tsXFUt",
                                  type: "",
                                  htmlTagName: "h3",
                                  classes: ["card-18-title-NtibCf", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Lorem Ipsum",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "qg7puH",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-18-text-NtibCf", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor r sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit,  r sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit,  sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "XyJjQq",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-18-col-NtibCf"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "SnY5d2",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-18-wrapper-NtibCf"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "sAe99h",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "wiCA7e",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-18-iconwrapper-NtibCf"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "ZeNuLb",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-regular", "fa-bell"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "L4Qloo",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "fAJnjM",
                                  type: "",
                                  htmlTagName: "h3",
                                  classes: ["card-18-title-NtibCf", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Lorem Ipsum",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "ogZWjy",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-18-text-NtibCf", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "cpz2nU",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-18-col-NtibCf"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "hbZCJd",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-18-wrapper-NtibCf"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "OBfDQs",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Y52jrl",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-18-iconwrapper-NtibCf"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "MkWUFt",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-bars-staggered"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "gkb2mN",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "vlr0PF",
                                  type: "",
                                  htmlTagName: "h3",
                                  classes: ["card-18-title-NtibCf", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Lorem Ipsum",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "VpCs2M",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-18-text-NtibCf", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "bXDT1n",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-18-col-NtibCf"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "aeCzcS",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-18-wrapper-NtibCf"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "gOws1i",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "WkQrkt",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-18-iconwrapper-NtibCf"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "NdZ127",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-check"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "lmonXR",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "GC9N48",
                                  type: "",
                                  htmlTagName: "h3",
                                  classes: ["card-18-title-NtibCf", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Lorem Ipsum",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "drkMdQ",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-18-text-NtibCf", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "LX7D46",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-18-col-NtibCf"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "ch07Sz",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-18-wrapper-NtibCf"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "LoaIMQ",
                          type: "",
                          htmlTagName: "div",
                          classes: ["d-flex"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "XBfqeq",
                              type: "",
                              htmlTagName: "div",
                              classes: ["card-18-iconwrapper-NtibCf"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "div" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "bYour6",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-dollar-sign"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "yjyV7a",
                              type: "",
                              htmlTagName: "div",
                              classes: ["toGrow"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "JGqezr",
                                  type: "",
                                  htmlTagName: "h3",
                                  classes: ["card-18-title-NtibCf", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "h" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Lorem Ipsum",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "GAX5v0",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["toGrow", "card-18-text-NtibCf", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content:
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "GWM7u3",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-1-component-GWM7u3"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "LlMNC7",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-1-wrapper-GWM7u3"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "ucLhRg",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-1-content-GWM7u3"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "CMzV6A",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["parallax-1-title-GWM7u3", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Call to action",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "Sv2ymt",
                      type: "",
                      htmlTagName: "p",
                      classes: ["parallax-1-text-GWM7u3", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "hsYZ0v",
                      type: "",
                      htmlTagName: "a",
                      classes: ["parallax-1-button-GWM7u3"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Call to action",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "SuvyOB",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-SuvyOB"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "pIAMp8",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-SuvyOB", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Facts & figures", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "PYvrrb",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-PYvrrb"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "SnLkFE",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-PYvrrb"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "ngTnv0",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "statistics-2-component-ngTnv0"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "bjUU5x",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "statistics-2-statswrapper", "w-100"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "Pb9dQ2",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "RoLJqk",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-ngTnv0"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "nwOiek",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-2-stat-number-ngTnv0", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "4", children: [] },
                          ],
                        },
                        {
                          childId: "J5YCDZ",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-ngTnv0", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Years active",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "rVBWrE",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "ewOGkZ",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-ngTnv0"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "cqsxP0",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-2-stat-number-ngTnv0", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "27", children: [] },
                          ],
                        },
                        {
                          childId: "LOabNC",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-ngTnv0", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Employees",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Cz0z5z",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "uMHEUJ",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-ngTnv0"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "vSpEI2",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-2-stat-number-ngTnv0", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "200+",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "hQ6gj5",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-ngTnv0", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Projects delivered",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "ze6t7a",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "WKrQ0t",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-ngTnv0"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "o0o8UN",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-2-stat-number-ngTnv0", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "€20m",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Dfgd8g",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-ngTnv0", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Funding",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "aiWFXG",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-aiWFXG"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "mAamk5",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-aiWFXG", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Our clients", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "o0xRiQ",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-o0xRiQ"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Zt64m2",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-o0xRiQ"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "HR5SXZ",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "clients-5-component-HR5SXZ"],
          styles: [],
          attributes: [
            { property: "data-name", value: "carousel" },
            { property: "data-editable", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "v1DwKN",
              type: "",
              htmlTagName: "div",
              classes: ["splide", "carousel-autoscroll", "clients-5-splide"],
              styles: [],
              attributes: [
                { property: "aria-label", value: "Carousel" },
                {
                  property: "data-splide",
                  value:
                    '{"perPage":1,"perMove":1,"type":"loop","fixedHeight":"80px","fixedWidth":"auto","gap":80,"drag":"free","focus":"center","arrows":false,"pagination":false,"autoScroll":{"speed":2},"interval":2000,"pauseOnHover":true,"rewindSpeed":1000,"speed":500,"autoplay":false,"easing":"linear","keyboard":true,"breakpoints":{"640":{"perPage":1,"perMove":1}},"classes":{"arrow":"splide__arrow clients-5-arrow-HR5SXZ","prev":"splide__arrow--prev clients-5-prev-HR5SXZ","next":"splide__arrow--next clients-5-next-HR5SXZ","page":"splide__pagination__page clients-5-pagination-HR5SXZ"}}',
                },
                { property: "data-carousel-slidesetup", value: "image" },
              ],
              content: "",
              children: [
                {
                  childId: "pzKDdw",
                  type: "",
                  htmlTagName: "div",
                  classes: ["splide__track"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "GGvO02",
                      type: "",
                      htmlTagName: "div",
                      classes: ["splide__list"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "guHp5b",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "clients-5-slide-HR5SXZ"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "MjxRoy",
                              type: "",
                              htmlTagName: "img",
                              classes: ["clients-5-img-HR5SXZ"],
                              styles: [],
                              attributes: [
                                { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "imgSrcOnly" },
                              ],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "FmtOIK",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "clients-5-slide-HR5SXZ"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "VeVQZN",
                              type: "",
                              htmlTagName: "img",
                              classes: ["clients-5-img-HR5SXZ"],
                              styles: [],
                              attributes: [
                                { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "imgSrcOnly" },
                              ],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "HS8ClH",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "clients-5-slide-HR5SXZ"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ft1I1P",
                              type: "",
                              htmlTagName: "img",
                              classes: ["clients-5-img-HR5SXZ"],
                              styles: [],
                              attributes: [
                                { property: "src", value: "https://cdn.satonda.com/eb/assets/client-3.png" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "imgSrcOnly" },
                              ],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "wL9RhS",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "clients-5-slide-HR5SXZ"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "UhcBDO",
                              type: "",
                              htmlTagName: "img",
                              classes: ["clients-5-img-HR5SXZ"],
                              styles: [],
                              attributes: [
                                { property: "src", value: "https://cdn.satonda.com/eb/assets/client-4.png" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "imgSrcOnly" },
                              ],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "aXRCVn",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "clients-5-slide-HR5SXZ"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ofz164",
                              type: "",
                              htmlTagName: "img",
                              classes: ["clients-5-img-HR5SXZ"],
                              styles: [],
                              attributes: [
                                { property: "src", value: "https://cdn.satonda.com/eb/assets/client-5.png" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "imgSrcOnly" },
                              ],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Q5Rvy3",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "clients-5-slide-HR5SXZ"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "uKi7YZ",
                              type: "",
                              htmlTagName: "img",
                              classes: ["clients-5-img-HR5SXZ"],
                              styles: [],
                              attributes: [
                                { property: "src", value: "https://cdn.satonda.com/eb/assets/client-6.png" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "imgSrcOnly" },
                              ],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "pNQ4g9",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "clients-5-slide-HR5SXZ"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "C0JRMK",
                              type: "",
                              htmlTagName: "img",
                              classes: ["clients-5-img-HR5SXZ"],
                              styles: [],
                              attributes: [
                                { property: "src", value: "https://cdn.satonda.com/eb/assets/client-7.png" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "imgSrcOnly" },
                              ],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "NSWIVW",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "clients-5-slide-HR5SXZ"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "O0N01l",
                              type: "",
                              htmlTagName: "img",
                              classes: ["clients-5-img-HR5SXZ"],
                              styles: [],
                              attributes: [
                                { property: "src", value: "https://cdn.satonda.com/eb/assets/client-8.png" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "imgSrcOnly" },
                              ],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ZyC6s9",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-ZyC6s9"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "n3tUZW",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-ZyC6s9", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Testimonials", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "gJKhaq",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-gJKhaq"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "jF16NL",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-gJKhaq"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "SN3LO6",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "quote-3-component-SN3LO6"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "PddMk5",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "quote-3-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "dmkMYO",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-12", "col-lg-6", "quote-3-col-SN3LO6"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "wpysQy",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-3-wrapper-SN3LO6"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "Jh6MN5",
                          type: "",
                          htmlTagName: "img",
                          classes: ["quote-3-avatar-SN3LO6"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-1.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "Dbn1Cv",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["quote-3-text1-SN3LO6", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "WyUIdp",
                          type: "",
                          htmlTagName: "h6",
                          classes: ["quote-3-text2-SN3LO6", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Function / company / location",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "a74CwW",
                          type: "",
                          htmlTagName: "div",
                          classes: ["quote-3-quotewrapper-SN3LO6"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "iwU6gR",
                              type: "",
                              htmlTagName: "span",
                              classes: ["quote-3-quote-left-SN3LO6"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "ZUFj3u",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-quote-left"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "zGUF5W",
                              type: "",
                              htmlTagName: "p",
                              classes: ["quote-3-text3-SN3LO6", "toGrow", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus necessitatibus culpa ex odio quam atque reiciendis ducimus earum. Fugit, recusandae!",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "pLCbEV",
                              type: "",
                              htmlTagName: "span",
                              classes: ["quote-3-quote-right-SN3LO6"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "RKkI2G",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-quote-right"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "tul5S6",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-12", "col-lg-6", "quote-3-col-SN3LO6"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "TAcH2z",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-3-wrapper-SN3LO6"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "ymOQkG",
                          type: "",
                          htmlTagName: "img",
                          classes: ["quote-3-avatar-SN3LO6"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-2.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "XbRMZQ",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["quote-3-text1-SN3LO6", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "s8dHfX",
                          type: "",
                          htmlTagName: "h6",
                          classes: ["quote-3-text2-SN3LO6", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Function / company / location",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "t4Q5HK",
                          type: "",
                          htmlTagName: "div",
                          classes: ["quote-3-quotewrapper-SN3LO6"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "fwXr9D",
                              type: "",
                              htmlTagName: "span",
                              classes: ["quote-3-quote-left-SN3LO6"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "ZSqVUX",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-quote-left"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "JMbpEj",
                              type: "",
                              htmlTagName: "p",
                              classes: ["quote-3-text3-SN3LO6", "toGrow", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content:
                                    "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus necessitatibus culpa ex odio quam atque reiciendis ducimus earum. Fugit, recusandae!",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "YTaIeV",
                              type: "",
                              htmlTagName: "span",
                              classes: ["quote-3-quote-right-SN3LO6"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "Olc7Ju",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-quote-right"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "tOyHRs",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-tOyHRs"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "eYJZdc",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-tOyHRs", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Our team", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "oxrqY5",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-oxrqY5"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "rp2NLg",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-oxrqY5"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "OCPT7p",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-2-component-OCPT7p"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "XOHXzR",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "prAyw1",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-2-col-OCPT7p"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card2" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "tNfU4L",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-2-overlay-wrapper-OCPT7p"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "PKPiZA",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-2-avatar-OCPT7p"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "TGOn5l",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-2-overlay-content-OCPT7p"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "rrwIHY",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-2-title-OCPT7p"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "oFDIdH",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-2-subtitle-OCPT7p"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "mbBDP8",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "ml9RLL",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-OCPT7p"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "qTdpzS",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "BdY2Sv",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-OCPT7p"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "VBqiyZ",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "dufW7m",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-OCPT7p"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "g3bChK",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "WqJd3A",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-OCPT7p"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Uf7hbg",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "bBFmSv",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-2-col-OCPT7p"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card2" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "WS7wll",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-2-overlay-wrapper-OCPT7p"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "Cy1jOY",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-2-avatar-OCPT7p"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "iFBbJI",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-2-overlay-content-OCPT7p"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "LIJHEi",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-2-title-OCPT7p"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "K52TPA",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-2-subtitle-OCPT7p"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "KPD43u",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "JcU3P6",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-OCPT7p"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "dzYn0q",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "rhNjoA",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-OCPT7p"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "FdN6lJ",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "P5LcIe",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-OCPT7p"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "nCRNIl",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "zpDGBt",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-OCPT7p"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "x4fpk3",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "wZT6Y2",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-2-col-OCPT7p"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card2" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "pSKqyK",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-2-overlay-wrapper-OCPT7p"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "lb4SRp",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-2-avatar-OCPT7p"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "N77VHH",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-2-overlay-content-OCPT7p"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "y6fTrw",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-2-title-OCPT7p"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "HUQY4j",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-2-subtitle-OCPT7p"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "KauYEi",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "MQj3uS",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-OCPT7p"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Xo2o1j",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "AbLyFL",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-OCPT7p"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "evAxxY",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "Nf20eu",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-OCPT7p"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "mTCJhh",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "nex1MR",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-OCPT7p"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "LLbs6a",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "VAqw8Z",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-VAqw8Z"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "hersn1",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-VAqw8Z", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "zZzv4m",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-zZzv4m"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Y2h7qb",
              type: "",
              htmlTagName: "div",
              classes: ["basics-8-headingline-zZzv4m"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "UN5zUA",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-7-component-UN5zUA"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "jZKyVV",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-7-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "dGUDgb",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "contactus-7-col-UN5zUA"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "iW2VMi",
                      type: "",
                      htmlTagName: "button",
                      classes: ["contactus-7-iconwrapper-UN5zUA"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "zGO4kT",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-solid", "fa-location-dot"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "ImTWfF",
                      type: "",
                      htmlTagName: "div",
                      classes: [],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "ziOOHj",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["contactus-7-title-UN5zUA"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Visit us",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "tfBmN9",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-7-text-UN5zUA"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Street 1337",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "mQNlIE",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-7-text-UN5zUA"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Postal code, City",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "thPAQr",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "contactus-7-col-UN5zUA"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "MLB4Pu",
                      type: "",
                      htmlTagName: "button",
                      classes: ["contactus-7-iconwrapper-UN5zUA"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Txdnbm",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-solid", "fa-phone"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "NShZ2S",
                      type: "",
                      htmlTagName: "div",
                      classes: [],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "c9kP8P",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["contactus-7-title-UN5zUA"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Call us",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "ykV6aE",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-7-text-UN5zUA"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "020 13 37 000",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "D6xb5j",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "contactus-7-col-UN5zUA"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "GaBZOf",
                      type: "",
                      htmlTagName: "button",
                      classes: ["contactus-7-iconwrapper-UN5zUA"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "EKqQnq",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-solid", "fa-envelope"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "Mlhamz",
                      type: "",
                      htmlTagName: "div",
                      classes: [],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "SzyVwv",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["contactus-7-title-UN5zUA"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email us",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "HpKpHd",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-7-text-UN5zUA"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "email@domain.com",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ZHcfCS",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-8-component-ZHcfCS"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "oxRNkl",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-8-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "s3BD1A",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-ZHcfCS"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-ZHcfCS"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-ZHcfCS"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "name" },
                            { property: "placeholder", value: "Your name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-ZHcfCS"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-ZHcfCS"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-ZHcfCS"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-ZHcfCS"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-ZHcfCS"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-ZHcfCS"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-ZHcfCS"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-ZHcfCS"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-8-inputfield-ZHcfCS"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-8-buttonwrapper-ZHcfCS"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-8-button-ZHcfCS"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "D1ntmd",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "footer-1-component-D1ntmd"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "rzn0CW",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "footer-1-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "A1AS1h",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "footer-1-col-D1ntmd"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "XLYM2t",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-1-title1-D1ntmd"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Company name",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "uIAxpb",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-1-text1-D1ntmd"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod laborum libero vitae maxime distinctio optio, saepe nemo? Modi nam adarchitecto repudiandae, odit similique soluta!",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "ExVTnF",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "footer-1-col-D1ntmd"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "MfoFjm",
                      type: "",
                      htmlTagName: "h6",
                      classes: ["footer-1-title2-D1ntmd"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "USEFUL LINKS",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "sSKSaC",
                      type: "block",
                      htmlTagName: "ul",
                      classes: ["footer-1-list-wrapper-D1ntmd"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "subBcy",
                          type: "element",
                          htmlTagName: "li",
                          classes: ["footer-1-list-item"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "footer1_list" }],
                          content: "",
                          children: [
                            {
                              childId: "v7sdAr",
                              type: "",
                              htmlTagName: "a",
                              classes: ["footer-1-list-link-D1ntmd"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "WhsnbH",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-caret-right"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                                {
                                  childId: "M2tIVZ",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["footer-1-list-text-D1ntmd"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Home",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "ha35u8",
                          type: "element",
                          htmlTagName: "li",
                          classes: ["footer-1-list-item"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "footer1_list" }],
                          content: "",
                          children: [
                            {
                              childId: "EsxvRz",
                              type: "",
                              htmlTagName: "a",
                              classes: ["footer-1-list-link-D1ntmd"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "Q3AbHI",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-caret-right"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                                {
                                  childId: "u8nWtF",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["footer-1-list-text-D1ntmd"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "About",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "Xcp0cY",
                          type: "element",
                          htmlTagName: "li",
                          classes: ["footer-1-list-item"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "footer1_list" }],
                          content: "",
                          children: [
                            {
                              childId: "liEmnr",
                              type: "",
                              htmlTagName: "a",
                              classes: ["footer-1-list-link-D1ntmd"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "hvgCtm",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-caret-right"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                                {
                                  childId: "FnJzqy",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["footer-1-list-text-D1ntmd"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Services",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "eLSLA3",
                          type: "element",
                          htmlTagName: "li",
                          classes: ["footer-1-list-item"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "footer1_list" }],
                          content: "",
                          children: [
                            {
                              childId: "HUIKgC",
                              type: "",
                              htmlTagName: "a",
                              classes: ["footer-1-list-link-D1ntmd"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "bF8qMX",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-caret-right"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                                {
                                  childId: "UbTOB6",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["footer-1-list-text-D1ntmd"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Team",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "GZeCk8",
                          type: "element",
                          htmlTagName: "li",
                          classes: ["footer-1-list-item"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "footer1_list" }],
                          content: "",
                          children: [
                            {
                              childId: "Yt71Ac",
                              type: "",
                              htmlTagName: "a",
                              classes: ["footer-1-list-link-D1ntmd"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "cQmhzu",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-solid", "fa-caret-right"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                                {
                                  childId: "TXpWm7",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["footer-1-list-text-D1ntmd"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Clients",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "aKQKio",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "footer-1-col-D1ntmd"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "e435ji",
                      type: "",
                      htmlTagName: "h6",
                      classes: ["footer-1-title2-D1ntmd"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "OUR NEWSLETTER",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "PfRKnx",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-1-text1-D1ntmd"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod laborum libero vitae maxime distinctio optio, saepe nemo? Modi nam adarchitecto repudiandae, odit similique soluta!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "qoaZS1",
                      type: "",
                      htmlTagName: "div",
                      classes: ["input-group", "input-group-sm"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "Rq0v4b",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "footer-1-inputfield-D1ntmd"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "aria-label", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "JKDUdM",
                          type: "",
                          htmlTagName: "button",
                          classes: ["btn", "footer-1-button-D1ntmd"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subscribe",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "jklIO8",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "footer-1-col-D1ntmd"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "bJvfbR",
                      type: "",
                      htmlTagName: "h6",
                      classes: ["footer-1-title2-D1ntmd"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "CONTACT US",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "rMSK5K",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-1-text2-D1ntmd"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Street name and number",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "ZZlfVq",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-1-text2-D1ntmd"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Postal code and city",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "c2xwcA",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-1-text2-D1ntmd"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Country", children: [] },
                      ],
                    },
                    {
                      childId: "laPUT3",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-1-text2-D1ntmd"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "mgLdAR",
                          type: "",
                          htmlTagName: "strong",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Phone: ",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "100 200 3000",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "QUXK0O",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-1-text2-D1ntmd"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "F6sfA3",
                          type: "",
                          htmlTagName: "strong",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email: ",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "email@address.com",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "zykufI",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["footer-1-socialwrapper"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "mnKnwN",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["footer-1-social-link-D1ntmd"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "JrHFxz",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-envelope"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "RevSfa",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["footer-1-social-link-D1ntmd"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "CSO8zT",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-twitter"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "wkikYN",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["footer-1-social-link-D1ntmd"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "vZibQx",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-linkedin"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "ajkvNQ",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["footer-1-social-link-D1ntmd"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "wvJQ6G",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-facebook-f"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ilt9cu",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "other-1-component-ilt9cu"],
          styles: [],
          attributes: [
            { property: "data-name", value: "backtotop" },
            { property: "data-editable", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "rrRml1",
              type: "",
              htmlTagName: "div",
              classes: ["backtotop", "other-1-button-ilt9cu", "shown"],
              styles: [],
              attributes: [
                { property: "data-name", value: "backtotop_btn" },
                { property: "title", value: "Back to top" },
                { property: "data-checkparent", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "wpZsRz",
                  type: "",
                  htmlTagName: "span",
                  classes: ["icon", "fa-solid", "fa-chevron-up"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      _id: "638b10e272266e802f6fb18f",
    },
  ],
  sbCustomCss: [
    {
      componentId: "cn04LX",
      classes: [
        {
          className: "section.hero-5-component-cn04LX",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-5-component-cn04LX", pseudo: "hover", rules: [] },
        {
          className: "hero-5-component-cn04LX",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-5-component-cn04LX", pseudo: "hover", rules: [] },
        {
          className: "hero-5-wrapper-cn04LX",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
          ],
        },
        {
          className: "hero-5-img-cn04LX",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-5-content-cn04LX",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "hero-5-text-cn04LX",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "hero-5-text-cn04LX", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-5-arrow-cn04LX",
          pseudo: "",
          rules: [
            { property: "opacity", value: "1" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "height", value: "3.5rem" },
            { property: "width", value: "3.5rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-5-arrow-cn04LX",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: "1" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "hero-5-arrow-cn04LX svg",
          pseudo: "",
          rules: [
            { property: "fill", value: "rgba(255, 255, 255, 0.75)" },
            { property: "height", value: "2rem" },
            { property: "width", value: "2rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        { className: "hero-5-arrow-cn04LX svg", pseudo: "hover", rules: [{ property: "fill", value: "rgba(255, 255, 255, 1)" }] },
        { className: "hero-5-prev-cn04LX", pseudo: "", rules: [{ property: "left", value: "1rem" }] },
        { className: "hero-5-next-cn04LX", pseudo: "", rules: [{ property: "right", value: "1rem" }] },
        {
          className: "hero-5-pagination-cn04LX",
          pseudo: "",
          rules: [
            { property: "margin", value: "0.25rem" },
            { property: "display", value: "inline-block" },
            { property: "opacity", value: "1" },
            { property: "background", value: "rgba(255, 255, 255, 0.75)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "height", value: "0.4rem" },
            { property: "width", value: "1rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-5-pagination-cn04LX",
          pseudo: "hover",
          rules: [
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "is-active.hero-5-pagination-cn04LX",
          pseudo: "",
          rules: [
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "hero-5-title-cn04LX",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-5-title-cn04LX", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-5-button-cn04LX",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(218, 69, 5, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-5-button-cn04LX",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(218, 69, 5, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "wT8E7e",
      classes: [
        {
          className: "section.basics-1-component-wT8E7e",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-wT8E7e", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-wT8E7e",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-wT8E7e", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-wT8E7e",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-wT8E7e", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "sIsrAN",
      classes: [
        {
          className: "section.basics-8-component-sIsrAN",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-8-component-sIsrAN", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-sIsrAN",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-sIsrAN", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-sIsrAN",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "100px" },
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-sIsrAN",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "DSN7gk",
      classes: [
        {
          className: "section.basics-2-component-DSN7gk",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-DSN7gk", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-DSN7gk",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-DSN7gk", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-DSN7gk",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-DSN7gk", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "p0cnDL",
      classes: [
        {
          className: "section.card-17-component-p0cnDL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-17-component-p0cnDL", pseudo: "hover", rules: [] },
        {
          className: "card-17-col-p0cnDL",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "display", value: "flex" },
          ],
        },
        {
          className: "card-17-wrapper-p0cnDL",
          pseudo: "",
          rules: [
            { property: "overflow", value: "hidden" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0.75rem" },
            { property: "padding", value: "0 0 0 0" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 0.1)" },
          ],
        },
        { className: "card-17-wrapper-p0cnDL", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" }] },
        {
          className: "card-17-wrapper-p0cnDL > img",
          pseudo: "",
          rules: [
            { property: "width", value: "100% !important" },
            { property: "height", value: "200px !important" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "card-17-img-p0cnDL", pseudo: "", rules: [] },
        { className: "card-17-img-p0cnDL", pseudo: "hover", rules: [] },
        {
          className: "card-17-paddingwrapper-p0cnDL",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "padding", value: "0 0.5rem 0 0.5rem" },
          ],
        },
        { className: "card-17-paddingwrapper-p0cnDL", pseudo: "hover", rules: [] },
        {
          className: "card-17-desc-wrapper-p0cnDL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "card-17-desc-wrapper-p0cnDL", pseudo: "hover", rules: [] },
        {
          className: "card-17-title-p0cnDL",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "start" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "card-17-title-p0cnDL", pseudo: "hover", rules: [] },
        {
          className: "card-17-desc-text-p0cnDL",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "0 0rem 0.5rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-17-desc-text-p0cnDL", pseudo: "hover", rules: [] },
        {
          className: "card-17-iconwrapper-p0cnDL",
          pseudo: "",
          rules: [
            { property: "height", value: "4rem" },
            { property: "aspect-ratio", value: "1" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "font-size", value: "2.5rem" },
            { property: "border-radius", value: "50%" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
          ],
        },
        { className: "card-17-iconwrapper-p0cnDL", pseudo: "hover", rules: [] },
        {
          className: "card-17-icondiv-p0cnDL",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "left", value: "50%" },
            { property: "transform", value: "translate(-50%, -50%)" },
          ],
        },
        { className: "card-17-icondiv-p0cnDL", pseudo: "hover", rules: [] },
        { className: "card-17-spacer-p0cnDL", pseudo: "", rules: [{ property: "height", value: "2.5rem" }] },
        { className: "card-17-spacer-p0cnDL", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "DUsXBa",
      classes: [
        {
          className: "section.basics-1-component-DUsXBa",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-DUsXBa", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-DUsXBa",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-DUsXBa", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-DUsXBa",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-DUsXBa", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "uUo54x",
      classes: [
        {
          className: "section.basics-8-component-uUo54x",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-8-component-uUo54x", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-uUo54x",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-uUo54x", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-uUo54x",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "100px" },
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-uUo54x",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "aPTxYk",
      classes: [
        {
          className: "section.basics-2-component-aPTxYk",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-aPTxYk", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-aPTxYk",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-aPTxYk", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-aPTxYk",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-aPTxYk", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "wlrFMV",
      classes: [
        {
          className: "section.card-16-component-wlrFMV",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-16-component-wlrFMV", pseudo: "hover", rules: [] },
        { className: "card-16-wrapper-wlrFMV", pseudo: "", rules: [] },
        { className: "card-16-wrapper-wlrFMV", pseudo: "hover", rules: [] },
        {
          className: "card-16-iconwrapper-wlrFMV",
          pseudo: "",
          rules: [
            { property: "font-size", value: "3rem" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "align-self", value: "start" },
            { property: "width", value: "8rem" },
          ],
        },
        { className: "card-16-iconwrapper-wlrFMV", pseudo: "hover", rules: [] },
        {
          className: "card-16-title-wlrFMV",
          pseudo: "",
          rules: [
            { property: "margin", value: "0.5rem 0 0 0" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-16-title-wlrFMV", pseudo: "hover", rules: [] },
        {
          className: "card-16-text-wlrFMV",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 1rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-16-text-wlrFMV", pseudo: "hover", rules: [] },
        {
          className: "card-16-element-wlrFMV",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(150, 150, 150, 0)" },
            { property: "border-radius", value: "0.75rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "card-16-element-wlrFMV",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(150, 150, 150, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
      ],
    },
    {
      componentId: "NtibCf",
      classes: [
        {
          className: "section.card-18-component-NtibCf",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-18-component-NtibCf", pseudo: "hover", rules: [] },
        {
          className: "card-18-title-NtibCf",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 1rem" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-18-title-NtibCf", pseudo: "hover", rules: [] },
        {
          className: "card-18-text-NtibCf",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "margin", value: "0.5rem 0rem 0rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-18-text-NtibCf", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-18-wrapper-NtibCf",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-items", value: "flex-start" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-18-wrapper-NtibCf",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-18-col-NtibCf",
          pseudo: "",
          rules: [
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "1rem 0rem 1rem 0rem" },
          ],
        },
        { className: "card-18-col-NtibCf", pseudo: "hover", rules: [] },
        {
          className: "card-18-iconwrapper-NtibCf",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "font-size", value: "2rem" },
          ],
        },
        { className: "card-18-iconwrapper-NtibCf", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "GWM7u3",
      classes: [
        {
          className: "section.parallax-1-component-GWM7u3",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-1-component-GWM7u3", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-component-GWM7u3",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-1-wrapper-GWM7u3",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "30vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/city9.png")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-1-text-GWM7u3",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "parallax-1-text-GWM7u3", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-button-GWM7u3",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "parallax-1-button-GWM7u3",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "parallax-1-title-GWM7u3",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-1-title-GWM7u3", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "parallax-1-content-GWM7u3",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
      ],
    },
    {
      componentId: "SuvyOB",
      classes: [
        {
          className: "section.basics-1-component-SuvyOB",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-SuvyOB", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-SuvyOB",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-SuvyOB", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-SuvyOB",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-SuvyOB", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "PYvrrb",
      classes: [
        {
          className: "section.basics-8-component-PYvrrb",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-8-component-PYvrrb", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-PYvrrb",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-PYvrrb", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-PYvrrb",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "100px" },
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-PYvrrb",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "hI1fD4",
      classes: [
        {
          className: "section.statistics-2-component-hI1fD4",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.statistics-2-component-hI1fD4", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-component-hI1fD4",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-2-component-hI1fD4", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-stat-col-hI1fD4",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-2-stat-col-hI1fD4", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-title-hI1fD4",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "margin", value: "1rem 0rem 2rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        {
          className: "statistics-2-stat-number-hI1fD4",
          pseudo: "",
          rules: [
            { property: "font-size", value: "20px" },
            { property: "font-family", value: '"Montserrat"' },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        {
          className: "statistics-2-stat-caption-hI1fD4",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "ngTnv0",
      classes: [
        {
          className: "section.statistics-2-component-ngTnv0",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.statistics-2-component-ngTnv0", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-component-ngTnv0",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-2-component-ngTnv0", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-stat-col-ngTnv0",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-2-stat-col-ngTnv0", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-stat-caption-ngTnv0",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        {
          className: "statistics-2-stat-number-ngTnv0",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "font-size", value: "20px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
      ],
    },
    {
      componentId: "aiWFXG",
      classes: [
        {
          className: "section.basics-1-component-aiWFXG",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-aiWFXG", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-aiWFXG",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-aiWFXG", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-aiWFXG",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-aiWFXG", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "o0xRiQ",
      classes: [
        {
          className: "section.basics-8-component-o0xRiQ",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "1.5rem" },
          ],
        },
        { className: "section.basics-8-component-o0xRiQ", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-o0xRiQ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-o0xRiQ", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-o0xRiQ",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "100px" },
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-o0xRiQ",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "HR5SXZ",
      classes: [
        {
          className: "section.clients-5-component-HR5SXZ",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.clients-5-component-HR5SXZ", pseudo: "hover", rules: [] },
        {
          className: "clients-5-slide-HR5SXZ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "clients-5-img-HR5SXZ",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "width", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "clients-5-img-HR5SXZ", pseudo: "hover", rules: [] },
        {
          className: "clients-5-arrow-HR5SXZ",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "height", value: "2rem" },
            { property: "width", value: "2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "clients-5-arrow-HR5SXZ",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "clients-5-arrow-HR5SXZ svg",
          pseudo: "",
          rules: [
            { property: "fill", value: "rgba(0, 0, 0, 0.7)" },
            { property: "height", value: "1.2rem" },
            { property: "width", value: "1.2rem" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "clients-5-arrow-HR5SXZ svg", pseudo: "hover", rules: [{ property: "fill", value: "rgba(0, 0, 0, 1)" }] },
        { className: "clients-5-prev-HR5SXZ", pseudo: "", rules: [{ property: "left", value: "1rem" }] },
        { className: "clients-5-next-HR5SXZ", pseudo: "", rules: [{ property: "right", value: "1rem" }] },
        {
          className: "clients-5-pagination-HR5SXZ",
          pseudo: "",
          rules: [
            { property: "height", value: "0.7rem" },
            { property: "width", value: "0.7rem" },
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "margin", value: "0.25rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "inline-block" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "clients-5-pagination-HR5SXZ",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "is-active.clients-5-pagination-HR5SXZ",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
      ],
    },
    {
      componentId: "ZyC6s9",
      classes: [
        {
          className: "section.basics-1-component-ZyC6s9",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-ZyC6s9", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-ZyC6s9",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-ZyC6s9", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-ZyC6s9",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-ZyC6s9", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "gJKhaq",
      classes: [
        {
          className: "section.basics-8-component-gJKhaq",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-8-component-gJKhaq", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-gJKhaq",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-gJKhaq", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-gJKhaq",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "100px" },
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-gJKhaq",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "SN3LO6",
      classes: [
        {
          className: "section.quote-3-component-SN3LO6",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.quote-3-component-SN3LO6", pseudo: "hover", rules: [] },
        {
          className: "quote-3-col-SN3LO6",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "display", value: "flex" },
          ],
        },
        { className: "quote-3-col-SN3LO6", pseudo: "hover", rules: [] },
        {
          className: "quote-3-wrapper-SN3LO6",
          pseudo: "",
          rules: [
            { property: "align-items", value: "center" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          ],
        },
        { className: "quote-3-wrapper-SN3LO6", pseudo: "hover", rules: [] },
        {
          className: "quote-3-text2-SN3LO6",
          pseudo: "",
          rules: [
            { property: "font-style", value: "italic" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "quote-3-text2-SN3LO6", pseudo: "hover", rules: [] },
        {
          className: "quote-3-text3-SN3LO6",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "font-style", value: "italic" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "quote-3-text3-SN3LO6", pseudo: "hover", rules: [] },
        {
          className: "quote-3-quotewrapper-SN3LO6",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "padding", value: "0 1rem 0 1rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "quote-3-quotewrapper-SN3LO6", pseudo: "hover", rules: [] },
        {
          className: "quote-3-quote-left-SN3LO6",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "-15px" },
            { property: "left", value: "0" },
            { property: "font-size", value: "1.5rem" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
          ],
        },
        { className: "quote-3-quote-left-SN3LO6", pseudo: "hover", rules: [{ property: "color", value: "rgba(223, 74, 5, 1)" }] },
        {
          className: "quote-3-quote-right-SN3LO6",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "right", value: "0" },
            { property: "bottom", value: "-10px" },
            { property: "font-size", value: "1.5rem" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
          ],
        },
        { className: "quote-3-quote-right-SN3LO6", pseudo: "hover", rules: [{ property: "color", value: "rgba(223, 74, 5, 1)" }] },
        {
          className: "quote-3-avatar-SN3LO6",
          pseudo: "",
          rules: [
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "150px" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(150, 150, 150, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "quote-3-avatar-SN3LO6",
          pseudo: "hover",
          rules: [
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(150, 150, 150, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "quote-3-text1-SN3LO6",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "quote-3-text1-SN3LO6", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "tOyHRs",
      classes: [
        {
          className: "section.basics-1-component-tOyHRs",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-tOyHRs", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-tOyHRs",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-tOyHRs", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-tOyHRs",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-tOyHRs", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "oxrqY5",
      classes: [
        {
          className: "section.basics-8-component-oxrqY5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-8-component-oxrqY5", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-oxrqY5",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-oxrqY5", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-oxrqY5",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "100px" },
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-oxrqY5",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "OCPT7p",
      classes: [
        {
          className: "section.card-2-component-OCPT7p",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-2-component-OCPT7p", pseudo: "hover", rules: [] },
        {
          className: "card-2-col-OCPT7p",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "card-2-overlay-wrapper-OCPT7p",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "border-radius", value: "50%" },
            { property: "height", value: "250px" },
            { property: "width", value: "250px" },
          ],
        },
        {
          className: "card-2-overlay-wrapper-OCPT7p",
          pseudo: "hover .card-2-overlay-content-OCPT7p",
          rules: [
            { property: "visibility", value: "visible" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "card-2-overlay-content-OCPT7p",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.5)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "visibility", value: "hidden" },
            { property: "opacity", value: "0" },
          ],
        },
        {
          className: "card-2-avatar-OCPT7p",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
          ],
        },
        { className: "card-2-avatar-OCPT7p", pseudo: "hover", rules: [] },
        {
          className: "card-2-title-OCPT7p",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
          ],
        },
        { className: "card-2-title-OCPT7p", pseudo: "hover", rules: [] },
        {
          className: "card-2-subtitle-OCPT7p",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(230, 230, 230, 1)" },
          ],
        },
        { className: "card-2-subtitle-OCPT7p", pseudo: "hover", rules: [] },
        {
          className: "card-2-social-link-OCPT7p",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0.5rem 0 0" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "card-2-social-link-OCPT7p", pseudo: "hover", rules: [{ property: "color", value: "rgba(200, 200, 200, 1)" }] },
        { className: "card-2-social-link-OCPT7p", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      ],
    },
    {
      componentId: "UN5zUA",
      classes: [
        {
          className: "section.contactus-7-component-UN5zUA",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-7-component-UN5zUA", pseudo: "hover", rules: [] },
        {
          className: "contactus-7-col-UN5zUA",
          pseudo: "",
          rules: [
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "text-align", value: "center" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        {
          className: "contactus-7-iconwrapper-UN5zUA",
          pseudo: "",
          rules: [
            { property: "height", value: "65px" },
            { property: "width", value: "65px" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(223, 74, 5, 1)" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0 0 0 0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "cursor", value: "default !important" },
          ],
        },
        {
          className: "contactus-7-iconwrapper-UN5zUA",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(223, 74, 5, 1)" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "contactus-7-text-UN5zUA", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "contactus-7-text-UN5zUA", pseudo: "hover", rules: [] },
        {
          className: "contactus-7-title-UN5zUA",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "contactus-7-title-UN5zUA", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "ZHcfCS",
      classes: [
        {
          className: "section.contactus-8-component-ZHcfCS",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-8-component-ZHcfCS", pseudo: "hover", rules: [] },
        {
          className: "contactus-8-col-ZHcfCS",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-8-buttonwrapper-ZHcfCS",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "contactus-8-buttonwrapper-ZHcfCS",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-8-button-ZHcfCS",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "contactus-8-button-ZHcfCS",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "contactus-8-inputwrapper-ZHcfCS",
          pseudo: "",
          rules: [{ property: "margin", value: "0 0 1rem 0" }],
        },
        {
          className: "contactus-8-inputfield-ZHcfCS",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "border-style", value: "none none solid none" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-8-inputfield-ZHcfCS",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(106, 115, 122, 1)" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-8-inputfield-ZHcfCS",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "contactus-8-inputfield-ZHcfCS",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-8-label-ZHcfCS",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        {
          className: "contactus-8-label-ZHcfCS",
          pseudo: "hover",
          rules: [],
        },
      ],
    },
    {
      componentId: "VAqw8Z",
      classes: [
        {
          className: "section.basics-1-component-VAqw8Z",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-VAqw8Z", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-VAqw8Z",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-VAqw8Z", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-VAqw8Z",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-VAqw8Z", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "zZzv4m",
      classes: [
        {
          className: "section.basics-8-component-zZzv4m",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-8-component-zZzv4m", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-zZzv4m",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-zZzv4m", pseudo: "hover", rules: [] },
        {
          className: "basics-8-headingline-zZzv4m",
          pseudo: "",
          rules: [
            { property: "height", value: "2px" },
            { property: "width", value: "100px" },
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-headingline-zZzv4m",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "blEUOy",
      classes: [
        {
          className: "section.footer-1-component-blEUOy",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.footer-1-component-blEUOy", pseudo: "hover", rules: [] },
        { className: "footer-1-col-blEUOy", pseudo: "", rules: [{ property: "padding", value: "1rem 1rem 1rem 1rem" }] },
        {
          className: "footer-1-text1-blEUOy",
          pseudo: "",
          rules: [
            { property: "font-size", value: "13px" },
            { property: "text-align", value: "justify" },
          ],
        },
        { className: "footer-1-text1-blEUOy", pseudo: "hover", rules: [] },
        {
          className: "footer-1-text2-blEUOy",
          pseudo: "",
          rules: [
            { property: "font-size", value: "13px" },
            { property: "margin", value: "0 0 0 0" },
          ],
        },
        { className: "footer-1-text2-blEUOy", pseudo: "hover", rules: [] },
        {
          className: "footer-1-list-wrapper-blEUOy",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "list-style", value: "none" },
          ],
        },
        {
          className: "footer-1-list-link-blEUOy",
          pseudo: "",
          rules: [
            { property: "border-style", value: "none none solid none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(108, 117, 125, 1)" },
            { property: "padding", value: "0.25rem 0 0.25rem 0" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "footer-1-list-link-blEUOy",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "footer-1-list-text-blEUOy",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0.5rem" },
            { property: "font-size", value: "13px" },
          ],
        },
        { className: "footer-1-list-text-blEUOy", pseudo: "hover", rules: [] },
        {
          className: "footer-1-button-blEUOy",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(223, 74, 5, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        {
          className: "footer-1-button-blEUOy",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(223, 74, 5, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "footer-1-inputfield-blEUOy",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(223, 74, 5, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "footer-1-inputfield-blEUOy",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "border-color", value: "rgba(223, 74, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(223, 74, 5, 0.2)" },
          ],
        },
        {
          className: "footer-1-inputfield-blEUOy",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "footer-1-inputfield-blEUOy", pseudo: "hover", rules: [] },
        {
          className: "footer-1-title1-blEUOy",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "font-size", value: "16px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-1-title1-blEUOy", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "footer-1-title2-blEUOy",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-1-title2-blEUOy", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "footer-1-social-link-blEUOy",
          pseudo: "",
          rules: [
            { property: "height", value: "35px" },
            { property: "display", value: "inline-flex" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "background", value: "rgba(218, 69, 5, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0.5rem 0.25rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "font-size", value: "1rem" },
          ],
        },
        {
          className: "footer-1-social-link-blEUOy",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "background", value: "rgba(106, 115, 122, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "footer-1-social-link-blEUOy", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      ],
    },
    {
      componentId: "D1ntmd",
      classes: [
        {
          className: "section.footer-1-component-D1ntmd",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.footer-1-component-D1ntmd", pseudo: "hover", rules: [] },
        { className: "footer-1-col-D1ntmd", pseudo: "", rules: [{ property: "padding", value: "1rem 1rem 1rem 1rem" }] },
        {
          className: "footer-1-text1-D1ntmd",
          pseudo: "",
          rules: [
            { property: "font-size", value: "13px" },
            { property: "text-align", value: "justify" },
          ],
        },
        { className: "footer-1-text1-D1ntmd", pseudo: "hover", rules: [] },
        {
          className: "footer-1-text2-D1ntmd",
          pseudo: "",
          rules: [
            { property: "font-size", value: "13px" },
            { property: "margin", value: "0 0 0 0" },
          ],
        },
        { className: "footer-1-text2-D1ntmd", pseudo: "hover", rules: [] },
        {
          className: "footer-1-list-wrapper-D1ntmd",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "list-style", value: "none" },
          ],
        },
        {
          className: "footer-1-list-text-D1ntmd",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0.5rem" },
            { property: "font-size", value: "13px" },
          ],
        },
        { className: "footer-1-list-text-D1ntmd", pseudo: "hover", rules: [] },
        {
          className: "footer-1-button-D1ntmd",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        {
          className: "footer-1-button-D1ntmd",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "footer-1-title2-D1ntmd",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-1-title2-D1ntmd", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "footer-1-social-link-D1ntmd",
          pseudo: "",
          rules: [
            { property: "height", value: "35px" },
            { property: "aspect-ratio", value: "1" },
            { property: "display", value: "inline-flex" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "background", value: "rgba(218, 69, 5, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0.5rem 0.25rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "font-size", value: "1rem" },
          ],
        },
        {
          className: "footer-1-social-link-D1ntmd",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "background", value: "rgba(106, 115, 122, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "footer-1-social-link-D1ntmd", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-1-inputfield-D1ntmd",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1rem" },
            { property: "outline", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease-in-out" },
          ],
        },
        {
          className: "footer-1-inputfield-D1ntmd",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "border-color", value: "rgba(218, 69, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(218, 69, 5, 0.2)" },
          ],
        },
        {
          className: "footer-1-inputfield-D1ntmd",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "footer-1-inputfield-D1ntmd", pseudo: "hover", rules: [] },
        {
          className: "footer-1-list-link-D1ntmd",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "none none solid none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(108, 117, 125, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.25rem 0rem 0.25rem 0rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "font-size", value: "1rem" },
          ],
        },
        {
          className: "footer-1-list-link-D1ntmd",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(218, 69, 5, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(108, 117, 125, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "footer-1-title1-D1ntmd",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(223, 74, 5, 1)" },
            { property: "font-size", value: "16px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-1-title1-D1ntmd", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "ilt9cu",
      classes: [
        {
          className: "section.other-1-component-ilt9cu",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.other-1-component-ilt9cu", pseudo: "hover", rules: [] },
        {
          className: "shown.other-1-button-ilt9cu",
          pseudo: "",
          rules: [
            { property: "opacity", value: "1" },
            { property: "visibility", value: "visible" },
          ],
        },
        {
          className: "other-1-button-ilt9cu",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "fixed" },
            { property: "z-index", value: "99" },
            { property: "bottom", value: "1rem" },
            { property: "right", value: "1rem" },
            { property: "opacity", value: "0" },
            { property: "visibility", value: "hidden" },
            { property: "cursor", value: "pointer" },
            { property: "height", value: "3rem" },
            { property: "width", value: "3rem" },
            { property: "font-size", value: "1rem" },
            { property: "background", value: "rgba(218, 69, 5, 0.55)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.2)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "other-1-button-ilt9cu",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(218, 69, 5, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.3)" },
          ],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Poppins:100,300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Poppins",
    fontHeadersWeight: "400",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(223, 74, 5, 1)",
    color2: "rgba(250, 142, 95, 1)",
    color3: "rgba(244, 87, 231, 1)",
    color4: "rgba(249, 169, 242, 1)",
    color5: "rgba(10, 193, 117, 1)",
    color6: "rgba(95, 246, 183, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
