// Cards
import { card1 } from "./cards/card-1";
import { card2 } from "./cards/card-2";
import { card3 } from "./cards/card-3";
import { card4 } from "./cards/card-4";
import { card5 } from "./cards/card-5";
import { card6 } from "./cards/card-6";
import { card7 } from "./cards/card-7";
import { card8 } from "./cards/card-8";
import { card9 } from "./cards/card-9";
import { card10 } from "./cards/card-10";
import { card11 } from "./cards/card-11";
import { card12 } from "./cards/card-12";
import { card13 } from "./cards/card-13";
import { card14 } from "./cards/card-14";
import { card15 } from "./cards/card-15";
import { card16 } from "./cards/card-16";
import { card17 } from "./cards/card-17";
import { card18 } from "./cards/card-18";
import { card19 } from "./cards/card-19";
import { card20 } from "./cards/card-20";
import { card21 } from "./cards/card-21";
import { card22 } from "./cards/card-22";
import { card23 } from "./cards/card-23";
import { card24 } from "./cards/card-24";
import { card25 } from "./cards/card-25";
// Clients
import { clients1 } from "./clients/clients-1";
import { clients2 } from "./clients/clients-2";
import { clients3 } from "./clients/clients-3";
import { clients4 } from "./clients/clients-4";
// Contact us
import { contactus1 } from "./contactus/contactus-1";
import { contactus2 } from "./contactus/contactus-2";
import { contactus3 } from "./contactus/contactus-3";
import { contactus4 } from "./contactus/contactus-4";
import { contactus5 } from "./contactus/contactus-5";
import { contactus6 } from "./contactus/contactus-6";
import { contactus7 } from "./contactus/contactus-7";
import { contactus8 } from "./contactus/contactus-8";
import { contactus9 } from "./contactus/contactus-9";
// Footer
import { footer1 } from "./footer/footer-1";
import { footer2 } from "./footer/footer-2";
import { footer3 } from "./footer/footer-3";
import { footer4 } from "./footer/footer-4";
import { footer5 } from "./footer/footer-5";
import { footer6 } from "./footer/footer-6";
// Hero
import { hero1 } from "./hero/hero-1";
import { hero2 } from "./hero/hero-2";
import { hero3 } from "./hero/hero-3";
import { hero4 } from "./hero/hero-4";
import { hero5 } from "./hero/hero-5";
import { hero6 } from "./hero/hero-6";
import { hero7 } from "./hero/hero-7";
import { hero8 } from "./hero/hero-8";
import { hero9 } from "./hero/hero-9";
import { hero10 } from "./hero/hero-10";
import { hero11 } from "./hero/hero-11";
import { hero12 } from "./hero/hero-12";
// Latest news
import { latestnews1 } from "./latestnews/latestnews-1";
// Parallax
import { parallax1 } from "./parallax/parallax-1";
import { parallax2 } from "./parallax/parallax-2";
import { parallax3 } from "./parallax/parallax-3";
import { parallax4 } from "./parallax/parallax-4";
import { parallax5 } from "./parallax/parallax-5";
// Price tables
import { pricetables1 } from "./pricetables/pricetables-1";
import { pricetables2 } from "./pricetables/pricetables-2";
import { pricetables3 } from "./pricetables/pricetables-3";
import { pricetables4 } from "./pricetables/pricetables-4";
import { pricetables5 } from "./pricetables/pricetables-5";
// Process
import { process1 } from "./process/process-1";
import { process2 } from "./process/process-2";
// Quotes
import { quotes1 } from "./quotes/quotes-1";
import { quotes2 } from "./quotes/quotes-2";
import { quotes3 } from "./quotes/quotes-3";
import { quotes4 } from "./quotes/quotes-4";
import { quotes5 } from "./quotes/quotes-5";
import { quotes6 } from "./quotes/quotes-6";
import { quotes7 } from "./quotes/quotes-7";
import { quotes8 } from "./quotes/quotes-8";
import { quotes9 } from "./quotes/quotes-9";
import { quotes10 } from "./quotes/quotes-10";
import { quotes11 } from "./quotes/quotes-11";
import { quotes12 } from "./quotes/quotes-12";
import { quotes13 } from "./quotes/quotes-13";
// Section dividers
import { sectiondividers1 } from "./sectiondividers/sd-1";
import { sectiondividers2 } from "./sectiondividers/sd-2";
import { sectiondividers3 } from "./sectiondividers/sd-3";
import { sectiondividers4 } from "./sectiondividers/sd-4";
import { sectiondividers5 } from "./sectiondividers/sd-5";
import { sectiondividers6 } from "./sectiondividers/sd-6";
import { sectiondividers7 } from "./sectiondividers/sd-7";
import { sectiondividers8 } from "./sectiondividers/sd-8";
import { sectiondividers9 } from "./sectiondividers/sd-9";
import { sectiondividers10 } from "./sectiondividers/sd-10";
import { sectiondividers11 } from "./sectiondividers/sd-11";
import { sectiondividers12 } from "./sectiondividers/sd-12";
import { sectiondividers13 } from "./sectiondividers/sd-13";
import { sectiondividers14 } from "./sectiondividers/sd-14";
import { sectiondividers15 } from "./sectiondividers/sd-15";
import { sectiondividers16 } from "./sectiondividers/sd-16";
import { sectiondividers17 } from "./sectiondividers/sd-17";
import { sectiondividers18 } from "./sectiondividers/sd-18";
import { sectiondividers19 } from "./sectiondividers/sd-19";
import { sectiondividers20 } from "./sectiondividers/sd-20";
import { sectiondividers21 } from "./sectiondividers/sd-21";
import { sectiondividers22 } from "./sectiondividers/sd-22";
import { sectiondividers23 } from "./sectiondividers/sd-23";
import { sectiondividers24 } from "./sectiondividers/sd-24";
import { sectiondividers25 } from "./sectiondividers/sd-25";
import { sectiondividers26 } from "./sectiondividers/sd-26";
import { sectiondividers27 } from "./sectiondividers/sd-27";
import { sectiondividers28 } from "./sectiondividers/sd-28";
import { sectiondividers29 } from "./sectiondividers/sd-29";
import { sectiondividers30 } from "./sectiondividers/sd-30";
import { sectiondividers31 } from "./sectiondividers/sd-31";
import { sectiondividers32 } from "./sectiondividers/sd-32";
import { sectiondividers33 } from "./sectiondividers/sd-33";
import { sectiondividers34 } from "./sectiondividers/sd-34";
import { sectiondividers35 } from "./sectiondividers/sd-35";
import { sectiondividers36 } from "./sectiondividers/sd-36";
import { sectiondividers37 } from "./sectiondividers/sd-37";
import { sectiondividers38 } from "./sectiondividers/sd-38";
import { sectiondividers39 } from "./sectiondividers/sd-39";
import { sectiondividers40 } from "./sectiondividers/sd-40";
import { sectiondividers41 } from "./sectiondividers/sd-41";
import { sectiondividers42 } from "./sectiondividers/sd-42";
import { sectiondividers43 } from "./sectiondividers/sd-43";
import { sectiondividers44 } from "./sectiondividers/sd-44";
import { sectiondividers45 } from "./sectiondividers/sd-45";
import { sectiondividers46 } from "./sectiondividers/sd-46";
import { sectiondividers47 } from "./sectiondividers/sd-47";
import { sectiondividers48 } from "./sectiondividers/sd-48";
import { sectiondividers49 } from "./sectiondividers/sd-49";
import { sectiondividers50 } from "./sectiondividers/sd-50";
import { sectiondividers51 } from "./sectiondividers/sd-51";
import { sectiondividers52 } from "./sectiondividers/sd-52";
import { sectiondividers53 } from "./sectiondividers/sd-53";
import { sectiondividers54 } from "./sectiondividers/sd-54";
import { sectiondividers55 } from "./sectiondividers/sd-55";
import { sectiondividers56 } from "./sectiondividers/sd-56";
import { sectiondividers57 } from "./sectiondividers/sd-57";
import { sectiondividers58 } from "./sectiondividers/sd-58";
import { sectiondividers59 } from "./sectiondividers/sd-59";
import { sectiondividers60 } from "./sectiondividers/sd-60";
import { sectiondividers61 } from "./sectiondividers/sd-61";
import { sectiondividers62 } from "./sectiondividers/sd-62";
import { sectiondividers63 } from "./sectiondividers/sd-63";
import { sectiondividers64 } from "./sectiondividers/sd-64";
import { sectiondividers65 } from "./sectiondividers/sd-65";
import { sectiondividers66 } from "./sectiondividers/sd-66";
import { sectiondividers67 } from "./sectiondividers/sd-67";
import { sectiondividers68 } from "./sectiondividers/sd-68";
import { sectiondividers69 } from "./sectiondividers/sd-69";
import { sectiondividers70 } from "./sectiondividers/sd-70";
import { sectiondividers71 } from "./sectiondividers/sd-71";
import { sectiondividers72 } from "./sectiondividers/sd-72";
import { sectiondividers73 } from "./sectiondividers/sd-73";
import { sectiondividers74 } from "./sectiondividers/sd-74";
import { sectiondividers75 } from "./sectiondividers/sd-75";
import { sectiondividers76 } from "./sectiondividers/sd-76";
import { sectiondividers77 } from "./sectiondividers/sd-77";
import { sectiondividers78 } from "./sectiondividers/sd-78";
import { sectiondividers79 } from "./sectiondividers/sd-79";
import { sectiondividers80 } from "./sectiondividers/sd-80";
import { sectiondividers81 } from "./sectiondividers/sd-81";
import { sectiondividers82 } from "./sectiondividers/sd-82";
import { sectiondividers83 } from "./sectiondividers/sd-83";
import { sectiondividers84 } from "./sectiondividers/sd-84";
import { sectiondividers85 } from "./sectiondividers/sd-85";
import { sectiondividers86 } from "./sectiondividers/sd-86";
import { sectiondividers87 } from "./sectiondividers/sd-87";
import { sectiondividers88 } from "./sectiondividers/sd-88";
import { sectiondividers89 } from "./sectiondividers/sd-89";
import { sectiondividers90 } from "./sectiondividers/sd-90";
import { sectiondividers91 } from "./sectiondividers/sd-91";
// Navbar
import { navbar1 } from "./navbar/navbar-1";
import { navbar2 } from "./navbar/navbar-2";
import { navbar3 } from "./navbar/navbar-3";
// Statistics
import { statistics1 } from "./statistics/statistics-1";
import { statistics2 } from "./statistics/statistics-2";
import { statistics3 } from "./statistics/statistics-3";
// Call to action
import { cta1 } from "./cta/cta-1";
import { cta2 } from "./cta/cta-2";
// Basics
import { basics1 } from "./basics/basics-1";
import { basics2 } from "./basics/basics-2";
import { basics3 } from "./basics/basics-3";
import { basics4 } from "./basics/basics-4";
import { basics5 } from "./basics/basics-5";
import { basics6 } from "./basics/basics-6";
import { basics7 } from "./basics/basics-7";
import { basics8 } from "./basics/basics-8";
// CVs
import { cv1 } from "./cv/cv-1";
import { cv2 } from "./cv/cv-2";
// Image gallery
import { imggallery1 } from "./imggallery/imggallery-1";
import { imggallery2 } from "./imggallery/imggallery-2";
import { imggallery3 } from "./imggallery/imggallery-3";
import { imggallery4 } from "./imggallery/imggallery-4";
import { imggallery5 } from "./imggallery/imggallery-5";
import { imggallery6 } from "./imggallery/imggallery-6";
import { imggallery7 } from "./imggallery/imggallery-7";
// Food menu
import { foodmenu1 } from "./foodmenus/foodmenu-1";
import { foodmenu2 } from "./foodmenus/foodmenu-2";
import { foodmenu3 } from "./foodmenus/foodmenu-3";
// Forms
import { forms1 } from "./forms/forms-1";
import { forms2 } from "./forms/forms-2";
import { forms3 } from "./forms/forms-3";
import { forms4 } from "./forms/forms-4";
import { forms5 } from "./forms/forms-5";
import { forms6 } from "./forms/forms-6";
// Other
import { other1 } from "./other/other-1";

export const templateComponents = [
  card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  card7,
  card8,
  card9,
  card10,
  card11,
  card12,
  card13,
  card14,
  card15,
  card16,
  card17,
  card18,
  card19,
  card20,
  card21,
  card22,
  card23,
  card24,
  card25,
  clients1,
  clients2,
  clients3,
  clients4,
  contactus1,
  contactus2,
  contactus3,
  contactus4,
  contactus5,
  contactus6,
  contactus7,
  contactus8,
  contactus9,
  footer1,
  footer2,
  footer3,
  footer4,
  footer5,
  footer6,
  hero1,
  hero2,
  hero3,
  hero4,
  hero5,
  hero6,
  hero7,
  hero8,
  hero9,
  hero10,
  hero11,
  hero12,
  latestnews1,
  parallax1,
  parallax2,
  parallax3,
  parallax4,
  parallax5,
  pricetables1,
  pricetables2,
  pricetables3,
  pricetables4,
  pricetables5,
  process1,
  process2,
  quotes1,
  quotes2,
  quotes3,
  quotes4,
  quotes5,
  quotes6,
  quotes7,
  quotes8,
  quotes9,
  quotes10,
  quotes11,
  quotes12,
  quotes13,
  sectiondividers1,
  sectiondividers2,
  sectiondividers3,
  sectiondividers4,
  sectiondividers5,
  sectiondividers6,
  sectiondividers7,
  sectiondividers8,
  sectiondividers9,
  sectiondividers10,
  sectiondividers11,
  sectiondividers12,
  sectiondividers13,
  sectiondividers14,
  sectiondividers15,
  sectiondividers16,
  sectiondividers17,
  sectiondividers18,
  sectiondividers19,
  sectiondividers20,
  sectiondividers21,
  sectiondividers22,
  sectiondividers23,
  sectiondividers24,
  sectiondividers25,
  sectiondividers26,
  sectiondividers27,
  sectiondividers28,
  sectiondividers29,
  sectiondividers30,
  sectiondividers31,
  sectiondividers32,
  sectiondividers33,
  sectiondividers34,
  sectiondividers35,
  sectiondividers36,
  sectiondividers37,
  sectiondividers38,
  sectiondividers39,
  sectiondividers40,
  sectiondividers41,
  sectiondividers42,
  sectiondividers43,
  sectiondividers44,
  sectiondividers45,
  sectiondividers46,
  sectiondividers47,
  sectiondividers48,
  sectiondividers49,
  sectiondividers50,
  sectiondividers51,
  sectiondividers52,
  sectiondividers53,
  sectiondividers54,
  sectiondividers55,
  sectiondividers56,
  sectiondividers57,
  sectiondividers58,
  sectiondividers59,
  sectiondividers60,
  sectiondividers61,
  sectiondividers62,
  sectiondividers63,
  sectiondividers64,
  sectiondividers65,
  sectiondividers66,
  sectiondividers67,
  sectiondividers68,
  sectiondividers69,
  sectiondividers70,
  sectiondividers71,
  sectiondividers72,
  sectiondividers73,
  sectiondividers74,
  sectiondividers75,
  sectiondividers76,
  sectiondividers77,
  sectiondividers78,
  sectiondividers79,
  sectiondividers80,
  sectiondividers81,
  sectiondividers82,
  sectiondividers83,
  sectiondividers84,
  sectiondividers85,
  sectiondividers86,
  sectiondividers87,
  sectiondividers88,
  sectiondividers89,
  sectiondividers90,
  sectiondividers91,
  navbar1,
  navbar2,
  navbar3,
  statistics1,
  statistics2,
  statistics3,
  cta1,
  cta2,
  basics1,
  basics2,
  basics3,
  basics4,
  basics5,
  basics6,
  basics7,
  basics8,
  cv1,
  cv2,
  imggallery1,
  imggallery2,
  imggallery3,
  imggallery4,
  imggallery5,
  imggallery6,
  imggallery7,
  foodmenu1,
  foodmenu2,
  foodmenu3,
  forms1,
  forms2,
  forms3,
  forms4,
  forms5,
  forms6,
  other1,
];
