import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { changeScreenSize, clickUndoRedo, saveProject } from "../../actions/sb";
import { MYSATONDA_RETURN_URL } from "../../lib/generalVars";
import { translate } from "../../translations/translations";

const BuilderAreaTop = ({
  sb: { project, sbPages, sbCustomCss, sbCssVars, activePageId, trackChanges, posTrackChanges, screen },
  changeScreenSize,
  clickUndoRedo,
  saveProject,
}) => {
  const clickSaveProject = () => {
    let cleanedCustomCss = cleanupCustomCss(sbPages, sbCustomCss);
    saveProject(project._id, project.gallery, project.siteMeta, sbPages, cleanedCustomCss, sbCssVars);
    // console.log("clickSaveProject // data for template saving:");
    // console.log("sbPages", JSON.stringify(sbPages));
    // console.log("sbCustomCss", JSON.stringify(sbCustomCss));
    // console.log(JSON.stringify(cleanedCustomCss));
    // console.log("sbCssVars", JSON.stringify(sbCssVars));
    // console.log(JSON.stringify(project.siteMeta));
  };

  const cleanupCustomCss = (pages, customCss) => {
    try {
      let activeComponentIds = pages.map((page) => page.components.map((component) => component.componentId)).flat();
      return customCss.filter((component) => activeComponentIds.includes(component.componentId));
    } catch (error) {
      return customCss;
    }
  };

  const clickSaveAndClose = async () => {
    if (window.confirm(translate("cBuilderArea.areYouSureExit", false, null))) {
      // await saveProject(project._id, sbPages, sbCustomCss, sbCssVars);
      window.location.replace(MYSATONDA_RETURN_URL);
    }
  };

  const getCurrPageName = () => {
    try {
      return sbPages.filter((page) => page.pageId === activePageId)[0].pageLink;
    } catch (error) {
      return "";
    }
  };

  const getCurrPageVersion = () => {
    try {
      return sbPages.filter((page) => page.pageId === activePageId)[0].version;
    } catch (error) {
      return "";
    }
  };

  const Left = () => {
    return (
      <div className="d-flex align-items-center">
        {/* Exit SB button */}
        <i
          className="fa-solid fa-arrow-left-long trans-3 cursorPointer fontSize15 text-danger"
          title={translate("cBuilderArea.saveAndExit", false, null)}
          onClick={clickSaveAndClose}
          data-tour="save-and-exit"
        />
        {/* Project / page name */}
        <span data-tour="active-page-version">
          <span className="text-gray text-bold ms-4">{project === null ? "projectName" : project.projectName}/</span>
          <span>{getCurrPageName()}</span>
          <span className="fontSize09 text-italic ms-1">
            ({translate("cBuilderArea.version", false, null)}: {getCurrPageVersion()})
          </span>
        </span>
        {/* Page options */}
        <i
          className="fa-solid fa-bars trans-3 cursorPointer text-gray textHover-primary ms-3"
          title={translate("cBuilderArea.pageOptions", false, null)}
          data-bs-toggle="modal"
          data-bs-target="#ModalPageOptions"
          data-tour="site-page-options"
        ></i>
      </div>
    );
  };

  const Mid = () => {
    return (
      <div className="d-flex justify-content-center align-items-center" data-tour="change-screen-size">
        <div>
          <i
            className={`fa-solid fa-display me-3 changeScreen trans-3 cursorPointer ${
              screen === "desktop" ? "text-primary" : "text-gray"
            } textHover-primary`}
            data-toggle="tooltip"
            data-placement="bottom"
            title={translate("cBuilderArea.desktopScreen", false, null)}
            onClick={() => changeScreenSize("desktop")}
          />
          <i
            className={`fa-solid fa-tablet-screen-button me-3 changeScreen trans-3 cursorPointer ${
              screen === "tablet" ? "text-primary" : "text-gray"
            } textHover-primary`}
            data-toggle="tooltip"
            data-placement="bottom"
            title={translate("cBuilderArea.tabletScreen", false, null)}
            onClick={() => changeScreenSize("tablet")}
          />
          <i
            className={`fa-solid fa-mobile-screen-button changeScreen trans-3 cursorPointer ${
              screen === "mobile" ? "text-primary" : "text-gray"
            } textHover-primary`}
            data-toggle="tooltip"
            data-placement="bottom"
            title={translate("cBuilderArea.mobileScreen", false, null)}
            onClick={() => changeScreenSize("mobile")}
          />
        </div>
      </div>
    );
  };

  const Right = () => {
    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
          <div data-tour="undo-redo">
            {/* Undo button */}
            {trackChanges.length > 0 && posTrackChanges < trackChanges.length ? (
              <i
                className={`fa-solid fa-rotate-left trans-3 cursorPointer text-gray textHover-primary me-3`}
                title={translate("cBuilderArea.undoChange", false, null)}
                onClick={() => clickUndoRedo(1)}
              ></i>
            ) : (
              <i className={`fa-solid fa-rotate-left trans-3 text-midgray me-3`} title="There is no change to undo"></i>
            )}
            {/* Redo button */}
            {posTrackChanges > 0 ? (
              <i
                className={`fa-solid fa-rotate-right trans-3 cursorPointer text-gray textHover-primary me-5`}
                title={translate("cBuilderArea.redoChange", false, null)}
                onClick={() => clickUndoRedo(-1)}
              ></i>
            ) : (
              <i className={`fa-solid fa-rotate-right trans-3 text-midgray me-5`} title="There is no change to redo"></i>
            )}
          </div>
          {/* Save and save as buttons */}
          <i
            className={`fa-solid fa-floppy-disk trans-3 cursorPointer text-gray textHover-primary me-1`}
            title={translate("cBuilderArea.saveProject", false, null)}
            onClick={clickSaveProject}
            data-tour="save-project"
          ></i>
          {/* <i className={`fa-solid fa-caret-down trans-3 cursorPointer text-gray textHover-primary me-2 fontSize08`} title="Save as..."></i> */}
        </div>
      </div>
    );
  };

  return (
    <div className="row mb-3 w-100" data-tour="top-menu">
      <div className="col-4">
        <Left sbPages={sbPages} />
      </div>
      <div className="col-4">
        <Mid screen={screen} changeScreenSize={changeScreenSize} />
      </div>
      <div className="col-4">
        <Right />
      </div>
    </div>
  );
};

BuilderAreaTop.propTypes = {
  sb: PropTypes.object.isRequired,
  changeScreenSize: PropTypes.func.isRequired,
  clickUndoRedo: PropTypes.func.isRequired,
  saveProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, { changeScreenSize, clickUndoRedo, saveProject })(BuilderAreaTop);
