export const hero7 = {
  id: "hero-7",
  name: "Hero 7",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "hero-7-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["hero-7-contentcol", "col-12", "col-md-6", "col-lg-6", "p-3"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["hero-7-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["hero-7-text", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content:
                      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos saepe expedita nemo molestiae repudiandae, dolor aperiam velit esse veronam!",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "a",
                classes: ["hero-7-button"],
                styles: [],
                attributes: [
                  { property: "href", value: "#!" },
                  { property: "data-href", value: "#!" },
                  { property: "data-target", value: "_self" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "button" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Get started!",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "block",
                htmlTagName: "div",
                classes: ["row", "align-items-start", "justify-content-center"],
                styles: [],
                attributes: [{ property: "data-name", value: "block" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-4", "col-lg-4", "card-18-icon-col"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "element_col" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["flexSameHeight", "card-18-icon-wrapper"],
                        styles: [],
                        attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["d-flex"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["card-18-iconwrapper"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "div" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-iconwrapper", value: "true" },
                                  { property: "data-texteditable", value: "false" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "span",
                                    classes: ["icon", "fa-regular", "fa-user"],
                                    styles: [],
                                    attributes: [{ property: "data-checkparent", value: "true" }],
                                    content: "",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["toGrow"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["toGrow", "card-18-icon-text", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "p" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Donec bibendum diam ante",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-4", "col-lg-4", "card-18-icon-col"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "element_col" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["flexSameHeight", "card-18-icon-wrapper"],
                        styles: [],
                        attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["d-flex"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["card-18-iconwrapper"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "div" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-iconwrapper", value: "true" },
                                  { property: "data-texteditable", value: "false" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "span",
                                    classes: ["icon", "fa-regular", "fa-comment"],
                                    styles: [],
                                    attributes: [{ property: "data-checkparent", value: "true" }],
                                    content: "",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["toGrow"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["toGrow", "card-18-icon-text", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "p" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Donec bibendum diam ante",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-4", "col-lg-4", "card-18-icon-col"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "element_col" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["flexSameHeight", "card-18-icon-wrapper"],
                        styles: [],
                        attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["d-flex"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["card-18-iconwrapper"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "div" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-iconwrapper", value: "true" },
                                  { property: "data-texteditable", value: "false" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "span",
                                    classes: ["icon", "fa-regular", "fa-bell"],
                                    styles: [],
                                    attributes: [{ property: "data-checkparent", value: "true" }],
                                    content: "",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["toGrow"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["toGrow", "card-18-icon-text", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "p" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Donec bibendum diam ante",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "p-3", "hero-7-imgwrapper"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["hero-7-img"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/gym7.png" },
                  { property: "alt", value: "Image" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                  { property: "data-imgresizable", value: "false" },
                ],
                content: "",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.hero-7-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.hero-7-component", pseudo: "hover", rules: [] },
      {
        className: "hero-7-contentcol",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "hero-7-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "margin", value: "0 0 1.5rem 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "hero-7-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-7-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "margin", value: "0 0 1.5rem 0" },
          { property: "font-style", value: "italic" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "hero-7-text",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-7-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-radius", value: "0.4rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 3rem 0" },
          { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
          { property: "align-self", value: "start" },
          { property: "text-align", value: "center" },
          { property: "text-decoration", value: "none" },
        ],
      },
      {
        className: "hero-7-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(201, 36, 3, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "hero-7-img",
        pseudo: "",
        rules: [
          { property: "height", value: "100%" },
          { property: "width", value: "100%" },
          { property: "object-fit", value: "cover" },
          { property: "border-radius", value: "0.75rem" },
        ],
      },
      {
        className: "hero-7-img",
        pseudo: "hover",
        rules: [],
      },
      { className: "card-18-icon-col", pseudo: "", rules: [] },
      { className: "card-18-icon-col", pseudo: "hover", rules: [] },
      { className: "card-18-icon-wrapper", pseudo: "", rules: [] },
      { className: "card-18-icon-wrapper", pseudo: "hover", rules: [] },
      {
        className: "card-18-iconwrapper",
        pseudo: "",
        rules: [
          { property: "font-size", value: "1.5rem" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "card-18-iconwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-18-icon-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0 0.5rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
          { property: "font-size", value: "13px" },
        ],
      },
      {
        className: "card-18-icon-text",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
