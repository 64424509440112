import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Border from "./Border";
import BgColor from "./BgColor";
import BorderRadius from "./BorderRadius";
import BoxShadow from "./BoxShadow";
import Transition from "./Transition";
import Slider from "./formComponents/Slider";
import Select from "./formComponents/Select";
import Divider from "./formComponents/Divider";
import ColorPicker from "./formComponents/ColorPicker";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const BackToTop = ({ sb: { startStyles } }) => {
  return (
    <>
      <Select
        label={translate("cEditForms.btp_btnPosition", false, null)}
        id={"backToTop_pos"}
        options={[
          { val: "top-left", label: translate("cEditForms.topLeft", false, null) },
          { val: "top-right", label: translate("cEditForms.topRight", false, null) },
          { val: "bottom-left", label: translate("cEditForms.bottomLeft", false, null) },
          { val: "bottom-right", label: translate("cEditForms.bottomRight", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "backToTop_pos")}
        tooltipText={translate("cEditForms.btp_tooltipBtnPos", false, null)}
      />
      <Slider
        label={translate("cEditForms.btp_btnSize", false, null)}
        id={"backToTop_sizeBtn"}
        min={1}
        max={5}
        step={0.1}
        startValue={formGetStartValue(startStyles, "backToTop_sizeBtn")}
        tooltipText={translate("cEditForms.btp_tooltipBtnSize", false, null)}
      />
      <Slider
        label={translate("cEditForms.btp_iconSize", false, null)}
        id={"backToTop_sizeIcon"}
        min={0.5}
        max={5}
        step={0.1}
        startValue={formGetStartValue(startStyles, "backToTop_sizeIcon")}
        tooltipText={translate("cEditForms.btp_tooltipIconSize", false, null)}
      />
      <Divider />
      <ColorPicker
        label={translate("cEditForms.btp_iconColor", false, null)}
        id={"textColor"}
        startValue={formGetStartValue(startStyles, "textColor")}
        tooltipText={translate("cEditForms.btp_tooltipIconColor", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.btp_iconColorHover", false, null)}
        id={"textColorHover"}
        startValue={formGetStartValue(startStyles, "textColorHover")}
        tooltipText={translate("cEditForms.btp_tooltipIconColorHover", false, null)}
      />
      <Divider />
      <BgColor />
      <Divider />
      <Border />
      <Divider />
      <BorderRadius />
      <Divider />
      <BoxShadow />
      <Divider />
      <Transition />
    </>
  );
};

BackToTop.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(BackToTop);
