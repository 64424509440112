import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";
import ColorPicker from "./formComponents/ColorPicker";

import { formGetStartValue, EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { PADDING_VALUES } from "../../../../lib/editStyleVars";
import {
  getTargetObj,
  getFirstChildByTagname,
  getComponentRelativeToCurrent,
  getTargetCustomCssRules,
  getCustomCss,
} from "../../../../lib/componentObjectFunctions";

import { flipSectionDivider } from "../../../../actions/sb";
import { translate } from "../../../../translations/translations";

const SectionDivider = ({ sb: { startStyles, selectedElement }, flipSectionDivider }) => {
  const [sdColors, setSdColors] = useState([]);
  const [sdScaleHor, setSdScaleHor] = useState(1);
  const [sdScaleVer, setSdScaleVer] = useState(1);
  useEffect(() => {
    let data = formGetStartValue(startStyles, "sdColors");
    if (typeof data !== "undefined") {
      setSdColors(data);
    } else {
      setSdColors([]);
    }
    setSdScaleHor(formGetStartValue(startStyles, "sdScaleHor"));
    setSdScaleVer(formGetStartValue(startStyles, "sdScaleVer"));
  }, [startStyles, selectedElement]);

  const clickFlip = (direction) => {
    let newStyleText = "";
    if (direction === "hor") {
      newStyleText = `scaleY(${sdScaleVer}) scaleX(${sdScaleHor * -1})`;
      setSdScaleHor((prev) => prev * -1);
    } else {
      newStyleText = `scaleY(${sdScaleVer * -1}) scaleX(${sdScaleHor})`;
      setSdScaleVer((prev) => prev * -1);
    }
    flipSectionDivider(selectedElement.split("-")[0], newStyleText);
  };

  const syncSdColors = () => {
    let componentBefore = getComponentRelativeToCurrent(selectedElement.split("-")[0], -1);
    let componentAfter = getComponentRelativeToCurrent(selectedElement.split("-")[0], 1);

    let { customClassRulesNormal: cssRulesBefore } = getTargetCustomCssRules(
      getCustomCss(componentBefore.componentId),
      `section.${componentBefore.classes.filter((className) => className.match(/-component-/) !== null)[0] || ""}`
    );
    let { customClassRulesNormal: cssRulesAfter } = getTargetCustomCssRules(
      getCustomCss(componentAfter.componentId),
      `section.${componentAfter.classes.filter((className) => className.match(/-component-/) !== null)[0] || ""}`
    );

    let beforeBgColor = (cssRulesBefore.filter((rule) => rule.property === "background-color")[0] || { value: "rgba(255, 255, 255, 0)" }).value;
    let afterBgColor = (cssRulesAfter.filter((rule) => rule.property === "background-color")[0] || { value: "rgba(255, 255, 255, 0)" }).value;

    let o = getSdVerticalOrientation();
    document.getElementById(`${EDIT_FORM_ID_PREFIX}sdColors1`).style.backgroundColor = o === -1 ? beforeBgColor : afterBgColor;
    document.getElementById(`${EDIT_FORM_ID_PREFIX}sdColors0`).style.backgroundColor = o === -1 ? afterBgColor : beforeBgColor;
  };

  const getSdVerticalOrientation = () => {
    try {
      let svg = getFirstChildByTagname(getTargetObj(selectedElement.split("-")), "svg");
      return parseInt(svg.styles.filter((s) => s.property === "transform")[0].value.match(/scaleY\((.+?)\)/)[1]);
    } catch (error) {
      return 1;
    }
  };

  const swapSdColors = () => {
    const sdColors1 = document.getElementById(`${EDIT_FORM_ID_PREFIX}sdColors1`).style.backgroundColor;
    const sdColors0 = document.getElementById(`${EDIT_FORM_ID_PREFIX}sdColors0`).style.backgroundColor;
    document.getElementById(`${EDIT_FORM_ID_PREFIX}sdColors1`).style.backgroundColor = sdColors0;
    document.getElementById(`${EDIT_FORM_ID_PREFIX}sdColors0`).style.backgroundColor = sdColors1;
  };

  return (
    <>
      <div className="d-flex justify-content-start mt-3">
        <span className="editPane-btn trans-3 cursorPointer px-3 py-2 rounded-4" onClick={() => clickFlip("ver")}>
          <i style={{ transform: "rotate(90deg)" }} className="fa-solid fa-arrow-right-arrow-left me-2"></i>{" "}
          {translate("cEditForms.flipVert", false, null)}
        </span>
        <span className="editPane-btn trans-3 cursorPointer px-3 py-2 rounded-4 ms-4" onClick={() => clickFlip("hor")}>
          <i className="fa-solid fa-arrow-right-arrow-left me-2"></i> {translate("cEditForms.flipHori", false, null)}
        </span>
      </div>
      <Slider
        label={translate("cEditForms.spaceTop", false, null)}
        id={"sdSpaceTop"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "sdSpaceTop"))}
        tooltipText={translate("cEditForms.ttSdSpaceTop", false, null)}
      />
      <Slider
        label={translate("cEditForms.spaceBottom", false, null)}
        id={"sdSpaceBottom"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "sdSpaceBottom"))}
        tooltipText={translate("cEditForms.ttSdSpaceBottom", false, null)}
      />
      {sdColors.length > 0 &&
        sdColors.map((sdColor, i) => (
          <ColorPicker
            key={`sdColors${i}`}
            label={`${translate("cEditForms.color", false, null)} ${i + 1}`}
            id={`sdColors${i}`}
            startValue={sdColor}
          />
        ))}
      <div className="d-flex justify-content-start my-3">
        <span className="editPane-btn trans-3 cursorPointer px-3 py-2 rounded-4" onClick={() => syncSdColors()}>
          <i className="fa-solid fa-arrows-rotate me-2"></i> {translate("cEditForms.sdSyncColors", false, null)}
        </span>
        {sdColors.length === 2 && (
          <span className="editPane-btn trans-3 cursorPointer px-3 py-2 rounded-4 ms-4" onClick={() => swapSdColors()}>
            <i style={{ transform: "rotate(90deg)" }} className="fa-solid fa-arrow-right-arrow-left me-2"></i>{" "}
            {translate("cEditForms.swapColors", false, null)}
          </span>
        )}
      </div>
    </>
  );
};

SectionDivider.propTypes = {
  sb: PropTypes.object.isRequired,
  flipSectionDivider: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, { flipSectionDivider })(SectionDivider);
