export const quotes7 = {
  id: "quotes-7",
  name: "Quotes 7",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "quote-7-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "quote-7-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "quote-7-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-7-wrappertitle"],
                styles: [],
                attributes: [
                  { property: "data-name", value: "quote7" },
                  { property: "data-editable", value: "true" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h2",
                    classes: ["quote-7-title", "toGrow"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Testimonials",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "quote-7-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-7-wrapperquote"],
                styles: [],
                attributes: [
                  { property: "data-name", value: "quote7" },
                  { property: "data-editable", value: "true" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["quote-7-quotetext", "toGrow"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content:
                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h5",
                    classes: ["quote-7-by"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Someone said this",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "quote-7-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-7-wrapperquote"],
                styles: [],
                attributes: [
                  { property: "data-name", value: "quote7" },
                  { property: "data-editable", value: "true" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["quote-7-quotetext", "toGrow"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content:
                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h5",
                    classes: ["quote-7-by"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Someone said this",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "quote-7-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-7-wrapperquote"],
                styles: [],
                attributes: [
                  { property: "data-name", value: "quote7" },
                  { property: "data-editable", value: "true" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["quote-7-quotetext", "toGrow"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content:
                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h5",
                    classes: ["quote-7-by"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Someone said this",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.quote-7-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.quote-7-component", pseudo: "hover", rules: [] },
      {
        className: "quote-7-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "0rem 0rem 0rem 0rem" },
          { property: "display", value: "flex" },
        ],
      },
      {
        className: "quote-7-wrappertitle",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0rem" },
          { property: "padding", value: "0 0 0 0" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(141, 215, 177, 1)" },
          { property: "background", value: "rgba(141, 215, 177, 1)" },
        ],
      },
      {
        className: "quote-7-wrappertitle",
        pseudo: "hover",
        rules: [
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          { property: "border-color", value: "rgba(141, 215, 177, 1)" },
          { property: "background", value: "rgba(141, 215, 177, 1)" },
        ],
      },
      {
        className: "quote-7-wrapperquote",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0rem" },
          { property: "padding", value: "0 0 0 0" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(141, 215, 177, 1)" },
        ],
      },
      {
        className: "quote-7-wrapperquote",
        pseudo: "hover",
        rules: [
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          { property: "border-color", value: "rgba(141, 215, 177, 1)" },
        ],
      },
      {
        className: "quote-7-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 1rem 1rem 1rem" },
          { property: "color", value: "rgba(20, 20, 20, 1)" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
          { property: "font-weight", value: "bold" },
        ],
      },
      {
        className: "quote-7-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-7-quotetext",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 1rem 1rem 1rem" },
          { property: "color", value: "rgba(20, 20, 20, 1)" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "flex-start" },
        ],
      },
      {
        className: "quote-7-quotetext",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-7-by",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 1rem 1rem 1rem" },
          { property: "color", value: "rgba(20, 20, 20, 1)" },
        ],
      },
      {
        className: "quote-7-by",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
