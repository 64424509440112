import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ImgSizeInput from "./formComponents/ImgSizeInput";
import ImgSource from "./ImgSource";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const NavbarLogo = ({ sb: { startStyles } }) => {
  return (
    <>
      <ImgSource />
      <ImgSizeInput
        label={translate("cEditForms.navbar_logoHeight", false, null)}
        id={"navbarLogoMaxHeight"}
        startValue={formGetStartValue(startStyles, "navbarLogoMaxHeight")}
        tooltipText={translate("cEditForms.navbar_ttLogoHeight", false, null)}
      />
    </>
  );
};

NavbarLogo.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(NavbarLogo);
