// TODO: Allow changing of border lines (see footer 4 which has a similar thing)
export const card25 = {
  id: "card-25",
  name: "Card 25",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "card-25-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "card-25-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-25-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card6" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "card-25-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-25-iconwrapper"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "div" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-chart-simple"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h4",
                    classes: ["card-25-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Lorem Ipsum",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["toGrow"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-25-text"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "em",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content:
                                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-25-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card6" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "card-25-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-25-iconwrapper"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "div" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-chart-simple"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h4",
                    classes: ["card-25-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Lorem Ipsum",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["toGrow"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-25-text"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "em",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content:
                                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-25-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card6" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "card-25-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-25-iconwrapper"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "div" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-chart-simple"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h4",
                    classes: ["card-25-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Lorem Ipsum",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["toGrow"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-25-text"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "em",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content:
                                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.card-25-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.card-25-component", pseudo: "hover", rules: [] },
      {
        className: "card-25-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "display", value: "flex" },
          { property: "border-style", value: "none solid none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(180, 180, 180, 1)" },
        ],
      },
      { className: "card-25-col", pseudo: "last-child", rules: [{ property: "border", value: "none" }] },
      {
        className: "card-25-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0" },
          { property: "padding", value: "0 0 0 0" },
        ],
      },
      { className: "card-25-wrapper", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)" }] },
      {
        className: "card-25-border",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "left", value: "0" },
          { property: "height", value: "100%" },
          { property: "width", value: "10px" },
          { property: "background", value: "rgba(229, 42, 5, 1)" },
        ],
      },
      {
        className: "card-25-border",
        pseudo: "hover",
        rules: [{ property: "background", value: "rgba(229, 42, 5, 1)" }],
      },
      {
        className: "card-25-iconwrapper",
        pseudo: "",
        rules: [
          { property: "font-size", value: "2.5rem" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "card-25-iconwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-25-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 0 0 0" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "font-weight", value: "bold" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "card-25-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-25-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 0 0 0" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "card-25-text",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
