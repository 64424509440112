export const template1 = {
  id: "625a78b9f821481e4aeb667d",
  name: { en: "Portfolio site", nl: "Portfolio site" },
  desc: { en: "A site to showcase personal resume and portfolio", nl: "Een website om persoonlijke CV en portfolio te laten zien" },
  categories: ["personal", "services"],
  sbPages: [
    {
      pageId: "43c3c5f0-43ee-4d00-b9bb-cd9d603bce95",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "ZSsYM5",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-ZSsYM5", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "true" },
            { property: "data-navbarposition", value: "" },
          ],
          content: "",
          children: [
            {
              childId: "WmQHk2",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "Deq9Go",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "f6nPnz",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-ZSsYM5"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Logo" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/elisa-taylor.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "bSXVgj",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-ZSsYM5", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "EmzLB9",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "cTVGDz",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "ZS2q7u",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "bqSvru",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ZSsYM5"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "resume.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Resume",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "n67HKQ",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ZSsYM5"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "projects.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Projects",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mKtvOh",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ZSsYM5"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "contact.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "QBUEm2",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "hero-3-component-QBUEm2"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "oQB15F",
              type: "",
              htmlTagName: "div",
              classes: ["row", "align-items-top", "justify-content-center"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "gnynV7",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "order-md-2", "p-3", "hero-3-contentwrapper"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Qunq51",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-3-title-QBUEm2"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Welcome", children: [] },
                      ],
                    },
                    {
                      childId: "k6WOLy",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["hero-3-subtitle-QBUEm2"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "This is what I do",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "ysQ8I6",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-3-text-QBUEm2"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem omnis, eligendi quae eum facilis error!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "EkDqNM",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["row", "justify-content-center", "align-items-stretch", "mt-4", "hero-3-row"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "gdfqCB",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-4", "col-md-4", "col-lg-4", "p-2", "hero-3-col"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "H1KJZz",
                              type: "",
                              htmlTagName: "div",
                              classes: ["hero-3-square-btn-QBUEm2"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "LTQXcO",
                                  type: "",
                                  htmlTagName: "a",
                                  classes: ["hero-3-button1-QBUEm2"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "button" },
                                    { property: "href", value: "#!" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-uniqueclassname", value: "true" },
                                    { property: "data-target", value: "_self" },
                                    { property: "data-href", value: "resume.html" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Resume",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "Kj4oMp",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-4", "col-md-4", "col-lg-4", "p-2", "hero-3-col"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "or9Uu1",
                              type: "",
                              htmlTagName: "div",
                              classes: ["hero-3-square-btn-QBUEm2"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "G05SQn",
                                  type: "",
                                  htmlTagName: "a",
                                  classes: ["hero-3-button2-QBUEm2"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "button" },
                                    { property: "href", value: "#!" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-uniqueclassname", value: "true" },
                                    { property: "data-href", value: "projects.html" },
                                    { property: "data-target", value: "_self" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Projects",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "ik3rPD",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-4", "col-md-4", "col-lg-4", "p-2", "hero-3-col"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "w2U4Rb",
                              type: "",
                              htmlTagName: "div",
                              classes: ["hero-3-square-btn-QBUEm2"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "wPRO0x",
                                  type: "",
                                  htmlTagName: "a",
                                  classes: ["hero-3-button3-QBUEm2"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "button" },
                                    { property: "href", value: "#!" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-uniqueclassname", value: "true" },
                                    { property: "data-target", value: "_self" },
                                    { property: "data-href", value: "test.html" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Contact",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "gNf38y",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-5", "col-lg-4", "order-md-1", "p-3", "hero-3-img-QBUEm2wrapper-QBUEm2"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "vVmVcu",
                      type: "",
                      htmlTagName: "img",
                      classes: ["hero-3-img-QBUEm2"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic2.png" },
                        { property: "alt", value: "avatar" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "H7MSJW",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "footer-4-component-H7MSJW"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "CTmRlj",
              type: "",
              htmlTagName: "div",
              classes: ["footer-4-wrapper-H7MSJW", "px-sm-0", "px-md-4"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "footer4_wrapper" },
              ],
              content: "",
              children: [
                {
                  childId: "yqD3C5",
                  type: "",
                  htmlTagName: "div",
                  classes: ["footer-4-line-H7MSJW", "mx-n3", "mx-md-0"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "footer4" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "teU19Y",
                  type: "",
                  htmlTagName: "div",
                  classes: ["row", "align-items-top", "justify-content-center", "footer-4-row"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "MSMzjM",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "LBJJx9",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Phone",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "CyGOlB",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "123 456 7890",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "GwTj1z",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "hWX81f",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "DUzLoJ",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "email@site.com",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "JR7p56",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "rvQ6JJ",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Follow me",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "W8WEIB",
                          type: "block",
                          htmlTagName: "div",
                          classes: [],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "HO0ClK",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-H7MSJW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "ZjpRZk",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-twitter"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "nqtwcQ",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-H7MSJW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "byH3Cb",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-linkedin"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "SEdV3C",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-H7MSJW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "gww16y",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-facebook-f"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "t6yxOk",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "Y4Irbi",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "© 2022 By Elisa Taylor",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "635fbdd9ce17d7c2603e937a",
    },
    {
      pageId: "342d434f-0415-4695-9d2b-b7b968a67208",
      version: "A",
      pageLink: "resume",
      pageTitle: "resume",
      pageDesc: "",
      components: [
        {
          componentId: "ZSsYM5",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-ZSsYM5", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "true" },
            { property: "data-navbarposition", value: "" },
          ],
          content: "",
          children: [
            {
              childId: "WmQHk2",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "Deq9Go",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "f6nPnz",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-ZSsYM5"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Logo" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/elisa-taylor.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "bSXVgj",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-ZSsYM5", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "EmzLB9",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "cTVGDz",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "ZS2q7u",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "bqSvru",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ZSsYM5"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "resume.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Resume",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "n67HKQ",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ZSsYM5"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "projects.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Projects",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mKtvOh",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ZSsYM5"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "contact.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "oN9e6H",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "resume-1-component-oN9e6H"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "UNF9La",
              type: "",
              htmlTagName: "h1",
              classes: ["resume-1-title-oN9e6H"],
              styles: [],
              attributes: [
                { property: "data-name", value: "h" },
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Resume", children: [] },
              ],
            },
            {
              childId: "IAu0Gz",
              type: "block",
              htmlTagName: "div",
              classes: ["resume-1-element-oN9e6Hwrapper"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "nxSiip",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["resume-1-element-oN9e6H"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "resume1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "s2JIZ1",
                      type: "",
                      htmlTagName: "div",
                      classes: ["row", "justify-content-center", "align-items-top"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "wXQYDL",
                          type: "",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-6", "p-3"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "W6PCqO",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["resume-1-element-oN9e6Htype"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "h" },
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Work experience",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "peOqKv",
                          type: "block",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-8", "col-lg-6", "p-3"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "LbrZKp",
                              type: "element",
                              htmlTagName: "div",
                              classes: ["row", "justify-content-center", "align-items-top"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Fpqee5",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-4", "col-lg-4"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "olhhdz",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["resume-1-element-oN9e6Hperiod"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "2021 - present",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  childId: "SH5rlR",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-8", "col-lg-8"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "dctk6m",
                                      type: "",
                                      htmlTagName: "h5",
                                      classes: ["resume-1-entrytitle-oN9e6H"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "h" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Digital marketeer",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "ccbf9Z",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["resume-1-entrytext-oN9e6H"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga voluptatibus id deleniti quam sequi suscipit ipsum voluptas explicabo aliquid. Deserunt.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "DVecBH",
                              type: "element",
                              htmlTagName: "div",
                              classes: ["row", "justify-content-center", "align-items-top"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "yBVdd9",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-4", "col-lg-4"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "JdZZc2",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["resume-1-element-oN9e6Hperiod"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "2019 - 2021",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  childId: "S8q66U",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-8", "col-lg-8"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Pm6vBe",
                                      type: "",
                                      htmlTagName: "h5",
                                      classes: ["resume-1-entrytitle-oN9e6H"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "h" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Content strategist",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "wnr5Sj",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["resume-1-entrytext-oN9e6H"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga voluptatibus id deleniti quam sequi suscipit ipsum voluptas explicabo aliquid. Deserunt.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Zh3Rel",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["resume-1-element-oN9e6H"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "resume1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "gXYmoh",
                      type: "",
                      htmlTagName: "div",
                      classes: ["row", "justify-content-center", "align-items-top"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "JPE96k",
                          type: "",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-6", "p-3"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mXMooG",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["resume-1-element-oN9e6Htype"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "h" },
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Education",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "axPMx4",
                          type: "block",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-8", "col-lg-6", "p-3"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "OlSaZh",
                              type: "element",
                              htmlTagName: "div",
                              classes: ["row", "justify-content-center", "align-items-top"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "juupQq",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-4", "col-lg-4"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "W4WoqR",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["resume-1-element-oN9e6Hperiod"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "2018 - 2019",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  childId: "ScPNIt",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-8", "col-lg-8"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "oygOOa",
                                      type: "",
                                      htmlTagName: "h5",
                                      classes: ["resume-1-entrytitle-oN9e6H"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "h" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "MSc Specialization - University of Life",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "nwwGAV",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["resume-1-entrytext-oN9e6H"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga voluptatibus id deleniti quam sequi suscipit ipsum voluptas explicabo aliquid. Deserunt.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "wTaWbY",
                              type: "element",
                              htmlTagName: "div",
                              classes: ["row", "justify-content-center", "align-items-top"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "NB9th8",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-4", "col-lg-4"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "or4CJG",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["resume-1-element-oN9e6Hperiod"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "2015 - 2018",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  childId: "hHqxza",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-8", "col-lg-8"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "F7b61v",
                                      type: "",
                                      htmlTagName: "h5",
                                      classes: ["resume-1-entrytitle-oN9e6H"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "h" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "BSc Life - University of Life",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "mCOPR4",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["resume-1-entrytext-oN9e6H"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga voluptatibus id deleniti quam sequi suscipit ipsum voluptas explicabo aliquid. Deserunt.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "ngEjTt",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["resume-1-element-oN9e6H"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "resume1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "Z4DQpi",
                      type: "",
                      htmlTagName: "div",
                      classes: ["row", "justify-content-center", "align-items-top"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "ifQb1x",
                          type: "",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-6", "p-3"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Eop3iy",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["resume-1-element-oN9e6Htype"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "h" },
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Skills",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "UoPIZ5",
                          type: "",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-8", "col-lg-6", "p-3"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "gcyNwc",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "justify-content-center", "align-items-top"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "tenfRI",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-4", "col-lg-4"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "j8uQUm",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["resume-1-element-oN9e6Hperiod"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "YiYjnt",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-8", "col-lg-8"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "vaSO0l",
                                      type: "block",
                                      htmlTagName: "ul",
                                      classes: ["resume-1-listwrapper-oN9e6H"],
                                      styles: [],
                                      attributes: [{ property: "data-name", value: "block" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "GzbTO3",
                                          type: "element",
                                          htmlTagName: "li",
                                          classes: ["resume-1-listitem"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "li" },
                                            { property: "data-editable", value: "false" },
                                            { property: "data-texteditable", value: "false" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "tJDs7l",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["resume-1-listtext-oN9e6H"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-name", value: "p" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "Lorem ipsum dolor sit amet.",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "Ny1EcT",
                                          type: "element",
                                          htmlTagName: "li",
                                          classes: ["resume-1-listitem"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "li" },
                                            { property: "data-editable", value: "false" },
                                            { property: "data-texteditable", value: "false" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "Hc2lla",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["resume-1-listtext-oN9e6H"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-name", value: "p" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "Lorem ipsum dolor sit amet.",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "GpRjuu",
                                          type: "element",
                                          htmlTagName: "li",
                                          classes: ["resume-1-listitem"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "li" },
                                            { property: "data-editable", value: "false" },
                                            { property: "data-texteditable", value: "false" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "A2m1g6",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["resume-1-listtext-oN9e6H"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-name", value: "p" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "Lorem ipsum dolor sit amet.",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "fzafiR",
                                          type: "element",
                                          htmlTagName: "li",
                                          classes: ["resume-1-listitem"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "li" },
                                            { property: "data-editable", value: "false" },
                                            { property: "data-texteditable", value: "false" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "mK54A0",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["resume-1-listtext-oN9e6H"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-name", value: "p" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "Lorem ipsum dolor sit amet.",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "H7MSJW",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "footer-4-component-H7MSJW"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "CTmRlj",
              type: "",
              htmlTagName: "div",
              classes: ["footer-4-wrapper-H7MSJW", "px-sm-0", "px-md-4"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "footer4_wrapper" },
              ],
              content: "",
              children: [
                {
                  childId: "yqD3C5",
                  type: "",
                  htmlTagName: "div",
                  classes: ["footer-4-line-H7MSJW", "mx-n3", "mx-md-0"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "footer4" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "teU19Y",
                  type: "",
                  htmlTagName: "div",
                  classes: ["row", "align-items-top", "justify-content-center", "footer-4-row"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "MSMzjM",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "LBJJx9",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Phone",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "CyGOlB",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "123 456 7890",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "GwTj1z",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "hWX81f",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "DUzLoJ",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "email@site.com",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "JR7p56",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "rvQ6JJ",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Follow me",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "W8WEIB",
                          type: "block",
                          htmlTagName: "div",
                          classes: [],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "HO0ClK",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-H7MSJW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "ZjpRZk",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-twitter"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "nqtwcQ",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-H7MSJW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "byH3Cb",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-linkedin"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "SEdV3C",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-H7MSJW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "gww16y",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-facebook-f"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "t6yxOk",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "Y4Irbi",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "© 2022 By Elisa Taylor",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "6375598c15b1737070002365",
    },
    {
      pageId: "66e0f7c9-f510-4bb2-b614-057afb8db7da",
      version: "A",
      pageLink: "projects",
      pageTitle: "projects",
      pageDesc: "",
      components: [
        {
          componentId: "ZSsYM5",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-ZSsYM5", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "true" },
            { property: "data-navbarposition", value: "" },
          ],
          content: "",
          children: [
            {
              childId: "WmQHk2",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "Deq9Go",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "f6nPnz",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-ZSsYM5"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Logo" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/elisa-taylor.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "bSXVgj",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-ZSsYM5", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "EmzLB9",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "cTVGDz",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "ZS2q7u",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "bqSvru",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ZSsYM5"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "resume.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Resume",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "n67HKQ",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ZSsYM5"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "projects.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Projects",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mKtvOh",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ZSsYM5"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "contact.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "FWULDA",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-FWULDA"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 0 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "aXEEXe",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-FWULDA"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Projects", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "XfTVDV",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-6-component-XfTVDV"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 0 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "IS8jNQ",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-6-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "svkvwO",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-6-col-XfTVDV"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "jJw2Vq",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-6-img-XfTVDV"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/work-2.png" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "fhnGOU",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-6-col-XfTVDV"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "FCLOGG",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["basics-6-title-XfTVDV"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Project 1",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "CnnqKB",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-6-text-XfTVDV"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "uFMiz8",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-7-component-uFMiz8"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 0 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "G5BKt1",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-7-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "MyhEuv",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-7-col-uFMiz8"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "CO1Jzj",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["basics-7-title-uFMiz8"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Project 2",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "RQFSFI",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-7-text-uFMiz8"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "GvSB0t",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-7-col-uFMiz8"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Qz78XJ",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-7-img-uFMiz8"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/work-2.png" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "RV1492",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-6-component-RV1492"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "eALGoK",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-6-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "zMg5E0",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-6-col-RV1492"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "dyTnQ9",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-6-img-RV1492"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/work-2.png" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "K7tepB",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-6-col-RV1492"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "A2FoKo",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["basics-6-title-RV1492"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Project 3",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "bXnypx",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-6-text-RV1492"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "gTj9o2",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-7-component-gTj9o2"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "ypix0C",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-7-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "xy3Rlq",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-7-col-gTj9o2"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "xkRbmX",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["basics-7-title-gTj9o2"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Project 4",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "lcHMla",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-7-text-gTj9o2"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "OUzjs2",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-7-col-gTj9o2"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "KFZSeB",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-7-img-gTj9o2"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/work-2.png" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "H7MSJW",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "footer-4-component-H7MSJW"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "CTmRlj",
              type: "",
              htmlTagName: "div",
              classes: ["footer-4-wrapper-H7MSJW", "px-sm-0", "px-md-4"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "footer4_wrapper" },
              ],
              content: "",
              children: [
                {
                  childId: "yqD3C5",
                  type: "",
                  htmlTagName: "div",
                  classes: ["footer-4-line-H7MSJW", "mx-n3", "mx-md-0"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "footer4" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "teU19Y",
                  type: "",
                  htmlTagName: "div",
                  classes: ["row", "align-items-top", "justify-content-center", "footer-4-row"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "MSMzjM",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "LBJJx9",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Phone",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "CyGOlB",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "123 456 7890",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "GwTj1z",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "hWX81f",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "DUzLoJ",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "email@site.com",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "JR7p56",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "rvQ6JJ",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Follow me",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "W8WEIB",
                          type: "block",
                          htmlTagName: "div",
                          classes: [],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "HO0ClK",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-H7MSJW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "ZjpRZk",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-twitter"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "nqtwcQ",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-H7MSJW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "byH3Cb",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-linkedin"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "SEdV3C",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-H7MSJW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "gww16y",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-facebook-f"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "t6yxOk",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "Y4Irbi",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "© 2022 By Elisa Taylor",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "6375598c15b1737070002366",
    },
    {
      pageId: "79f7dd55-753c-4776-8cd6-c8dd99efb569",
      version: "A",
      pageLink: "contact",
      pageTitle: "contact",
      pageDesc: "",
      components: [
        {
          componentId: "ZSsYM5",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-ZSsYM5", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "true" },
            { property: "data-navbarposition", value: "" },
          ],
          content: "",
          children: [
            {
              childId: "WmQHk2",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "Deq9Go",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "f6nPnz",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-ZSsYM5"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Logo" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/elisa-taylor.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "bSXVgj",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-ZSsYM5", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "EmzLB9",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "cTVGDz",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "ZS2q7u",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "bqSvru",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ZSsYM5"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "resume.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Resume",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "n67HKQ",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ZSsYM5"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "projects.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Projects",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mKtvOh",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-ZSsYM5"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "contact.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "WflPpk",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-6-component-WflPpk"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 0 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "n3LVES",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-6-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "TQvpbD",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "contactus-6-col-WflPpk"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "aMtN8q",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["contactus-6-contacttitle-WflPpk"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "h" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact", children: [] },
                      ],
                    },
                    {
                      childId: "GS5i14",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-contacttext-WflPpk"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Looking forward to hearing from you!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "I2qqz7",
                      type: "",
                      htmlTagName: "h5",
                      classes: ["contactus-6-subtitle-WflPpk"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Phone", children: [] },
                      ],
                    },
                    {
                      childId: "WWg7XB",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-subtext-WflPpk"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "123 456 7890",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "NdVKCM",
                      type: "",
                      htmlTagName: "h5",
                      classes: ["contactus-6-subtitle-WflPpk"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Email", children: [] },
                      ],
                    },
                    {
                      childId: "H4EpxT",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-subtext-WflPpk"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "email@site.com",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "G0GCle",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-WflPpk"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-WflPpk"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-WflPpk"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "name" },
                            { property: "placeholder", value: "Your name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-WflPpk"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-WflPpk"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-WflPpk"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-WflPpk"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-WflPpk"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-WflPpk"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-WflPpk"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-WflPpk"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-6-inputfield-WflPpk"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-6-buttonwrapper-WflPpk"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-6-button-WflPpk"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "H7MSJW",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "footer-4-component-H7MSJW"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "CTmRlj",
              type: "",
              htmlTagName: "div",
              classes: ["footer-4-wrapper-H7MSJW", "px-sm-0", "px-md-4"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "footer4_wrapper" },
              ],
              content: "",
              children: [
                {
                  childId: "yqD3C5",
                  type: "",
                  htmlTagName: "div",
                  classes: ["footer-4-line-H7MSJW", "mx-n3", "mx-md-0"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "footer4" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "teU19Y",
                  type: "",
                  htmlTagName: "div",
                  classes: ["row", "align-items-top", "justify-content-center", "footer-4-row"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "MSMzjM",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "LBJJx9",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Phone",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "CyGOlB",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "123 456 7890",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "GwTj1z",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "hWX81f",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "DUzLoJ",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "email@site.com",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "JR7p56",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "rvQ6JJ",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Follow me",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "W8WEIB",
                          type: "block",
                          htmlTagName: "div",
                          classes: [],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "HO0ClK",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-H7MSJW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "ZjpRZk",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-twitter"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "nqtwcQ",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-H7MSJW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "byH3Cb",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-linkedin"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "SEdV3C",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-H7MSJW"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "gww16y",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-facebook-f"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "t6yxOk",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "Y4Irbi",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-H7MSJW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "© 2022 By Elisa Taylor",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  sbCustomCss: [
    {
      componentId: "yJ7FsP",
      classes: [
        {
          className: "section.hero-1-component-yJ7FsP",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-1-component-yJ7FsP", pseudo: "hover", rules: [] },
        {
          className: "hero-1-component-yJ7FsP",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-1-component-yJ7FsP", pseudo: "hover", rules: [] },
        {
          className: "hero-1-wrapper-yJ7FsP",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
          ],
        },
        {
          className: "hero-1-img-yJ7FsP",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-1-content-yJ7FsP",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "hero-1-title-yJ7FsP",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 1rem 0" },
          ],
        },
        { className: "hero-1-title-yJ7FsP", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-1-text-yJ7FsP",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        { className: "hero-1-text-yJ7FsP", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-1-button-yJ7FsP",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
          ],
        },
        {
          className: "hero-1-button-yJ7FsP",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
      ],
    },
    {
      componentId: "gg9pC9",
      classes: [
        {
          className: "section.card-1-component-gg9pC9",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-1-component-gg9pC9", pseudo: "hover", rules: [] },
        { className: "card-1-col-gg9pC9", pseudo: "", rules: [] },
        { className: "card-1-col-gg9pC9", pseudo: "hover", rules: [] },
        { className: "card-1-wrapper-gg9pC9", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
        { className: "card-1-wrapper-gg9pC9", pseudo: "hover", rules: [] },
        {
          className: "card-1-avatar-gg9pC9",
          pseudo: "",
          rules: [
            { property: "border-radius", value: "50%" },
            { property: "height", value: "200px" },
            { property: "width", value: "200px" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "display", value: "block" },
          ],
        },
        { className: "card-1-avatar-gg9pC9", pseudo: "hover", rules: [] },
        {
          className: "card-1-title-gg9pC9",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "card-1-title-gg9pC9", pseudo: "hover", rules: [] },
        {
          className: "card-1-subtitle-gg9pC9",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
          ],
        },
        { className: "card-1-subtitle-gg9pC9", pseudo: "hover", rules: [] },
        {
          className: "card-1-social-link-gg9pC9",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0.5rem 0 0" },
            { property: "color", value: "rgba(249, 114, 31, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "card-1-social-link-gg9pC9", pseudo: "hover", rules: [{ property: "color", value: "rgba(255, 161, 0, 1)" }] },
        { className: "card-1-social-link-gg9pC9", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      ],
    },
    {
      componentId: "DivrFJ",
      classes: [
        {
          className: "section.parallax-1-component-DivrFJ",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-1-component-DivrFJ", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-component-DivrFJ",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-1-wrapper-DivrFJ",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "50vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/nature4.png")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-1-title-DivrFJ",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 1rem 0" },
          ],
        },
        { className: "parallax-1-title-DivrFJ", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-text-DivrFJ",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        { className: "parallax-1-text-DivrFJ", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-button-DivrFJ",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
          ],
        },
        {
          className: "parallax-1-button-DivrFJ",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
        {
          className: "parallax-1-content-DivrFJ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
      ],
    },
    {
      componentId: "sjiKgD",
      classes: [
        {
          className: "scrolledpast-sjiKgD.navbar-1-wrapper-sjiKgD",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(230, 230, 230, 0.5)" },
          ],
        },
        { className: "scrolledpast-sjiKgD .navbar-1-logo-sjiKgD", pseudo: "", rules: [{ property: "max-height", value: "40px" }] },
        {
          className: "navbar-1-wrapper-sjiKgD",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(230, 230, 230, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 5px 7px 3px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "navbar-1-logo-sjiKgD",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "50px" },
          ],
        },
        { className: "navbar-1-logo-sjiKgD", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-sjiKgD",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(230, 230, 230, 1)" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-sjiKgD",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(180, 180, 180, 1)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
        {
          className: "navbar-1-link-sjiKgD",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
            { property: "text-decoration", value: "none" },
          ],
        },
        { className: "navbar-1-link-sjiKgD", pseudo: "hover", rules: [{ property: "color", value: "rgba(0, 0, 0, 1)" }] },
      ],
    },
    {
      componentId: "P12KL0",
      classes: [
        {
          className: "section.hero-2-component-P12KL0",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "4.5rem" },
          ],
        },
        { className: "section.hero-2-component-P12KL0", pseudo: "hover", rules: [] },
        {
          className: "hero-2-title-P12KL0",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(255, 161, 0, 1)" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        { className: "hero-2-title-P12KL0", pseudo: "hover", rules: [] },
        {
          className: "hero-2-text-P12KL0",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "margin", value: "0 0 3rem 0" },
            { property: "font-style", value: "italic" },
          ],
        },
        { className: "hero-2-text-P12KL0", pseudo: "hover", rules: [] },
        {
          className: "hero-2-button-P12KL0",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 161, 0, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "0.4rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
          ],
        },
        {
          className: "hero-2-button-P12KL0",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(201, 36, 3, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "hero-2-img-P12KL0",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "border-radius", value: "0.75rem" },
          ],
        },
        { className: "hero-2-img-P12KL0", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "j9dIiF",
      classes: [
        {
          className: "section.clients-4-component-j9dIiF",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "background", value: "rgba(177, 208, 245, 1)" },
          ],
        },
        { className: "section.clients-4-component-j9dIiF", pseudo: "hover", rules: [] },
        {
          className: "clients-4-title-j9dIiF",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(255, 161, 0, 1)" },
            { property: "margin", value: "1rem 0 2rem 0" },
          ],
        },
        { className: "clients-4-title-j9dIiF", pseudo: "hover", rules: [] },
        { className: "clients-4-text-j9dIiF", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "clients-4-text-j9dIiF", pseudo: "hover", rules: [] },
        { className: "clients-4-clientcol-j9dIiF", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
        {
          className: "clients-4-logo-j9dIiF",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 1rem 0" },
            { property: "max-height", value: "50px" },
            { property: "max-width", value: "100px" },
            { property: "height", value: "auto" },
            { property: "width", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "clients-4-logo-j9dIiF", pseudo: "hover", rules: [] },
        { className: "clients-4-clientname-j9dIiF", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "clients-4-clientname-j9dIiF", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "CYwQCx",
      classes: [
        {
          className: "section-divider-CYwQCx",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
            { property: "width", value: "100%" },
          ],
        },
      ],
    },
    {
      componentId: "ZSsYM5",
      classes: [
        {
          className: "scrolledpast-ZSsYM5.navbar-1-wrapper-ZSsYM5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(230, 230, 230, 0.5)" },
          ],
        },
        { className: "scrolledpast-ZSsYM5 .navbar-1-logo-ZSsYM5", pseudo: "", rules: [{ property: "max-height", value: "40px" }] },
        {
          className: "navbar-1-wrapper-ZSsYM5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(255, 255, 255, 0.3)" },
          ],
        },
        {
          className: "navbar-1-logo-ZSsYM5",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "50px" },
          ],
        },
        { className: "navbar-1-logo-ZSsYM5", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-ZSsYM5",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-ZSsYM5",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
        {
          className: "navbar-1-link-ZSsYM5",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
            { property: "text-decoration", value: "none" },
          ],
        },
        { className: "navbar-1-link-ZSsYM5", pseudo: "hover", rules: [{ property: "color", value: "rgba(0, 0, 0, 1)" }] },
      ],
    },
    {
      componentId: "QBUEm2",
      classes: [
        {
          className: "section.hero-3-component-QBUEm2",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "4.5rem" },
          ],
        },
        { className: "section.hero-3-component-QBUEm2", pseudo: "hover", rules: [] },
        { className: "hero-3-wrapper-QBUEm2", pseudo: "", rules: [] },
        {
          className: "hero-3-img-QBUEm2wrapper-QBUEm2",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "flex-start" },
          ],
        },
        { className: "hero-3-text-QBUEm2", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "hero-3-text-QBUEm2", pseudo: "hover", rules: [] },
        {
          className: "hero-3-square-btn-QBUEm2",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "width", value: "100%" },
          ],
        },
        {
          className: "hero-3-square-btn-QBUEm2",
          pseudo: "after",
          rules: [
            { property: "content", value: '""' },
            { property: "display", value: "block" },
            { property: "padding-bottom", value: "100%" },
          ],
        },
        {
          className: "hero-3-subtitle-QBUEm2",
          pseudo: "",
          rules: [
            { property: "margin", value: "1.5rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-3-subtitle-QBUEm2", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "hero-3-button1-QBUEm2",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(20, 20, 20, 1)" },
            { property: "background", value: "rgba(255, 157, 0, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(20, 20, 20, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "text-decoration", value: "none" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "hero-3-button1-QBUEm2",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(20, 20, 20, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(20, 20, 20, 1)" },
          ],
        },
        {
          className: "hero-3-button2-QBUEm2",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(20, 20, 20, 1)" },
            { property: "background", value: "rgba(247, 108, 27, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(20, 20, 20, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "text-decoration", value: "none" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "hero-3-button2-QBUEm2",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(20, 20, 20, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(20, 20, 20, 1)" },
          ],
        },
        {
          className: "hero-3-button3-QBUEm2",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(20, 20, 20, 1)" },
            { property: "background", value: "rgba(247, 218, 27, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(20, 20, 20, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "text-decoration", value: "none" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "hero-3-button3-QBUEm2",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(20, 20, 20, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(20, 20, 20, 1)" },
          ],
        },
        {
          className: "hero-3-img-QBUEm2",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "hero-3-img-QBUEm2",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-3-title-QBUEm2",
          pseudo: "",
          rules: [
            { property: "font-size", value: "60px" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-3-title-QBUEm2", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "p17U85",
      classes: [
        {
          className: "section.contactus-2-component-p17U85",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-2-component-p17U85", pseudo: "hover", rules: [] },
        {
          className: "contactus-2-col-p17U85",
          pseudo: "",
          rules: [
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "text-align", value: "center" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        {
          className: "contactus-2-iconwrapper-p17U85",
          pseudo: "",
          rules: [
            { property: "height", value: "65px" },
            { property: "width", value: "65px" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "cursor", value: "default !important" },
          ],
        },
        {
          className: "contactus-2-iconwrapper-p17U85",
          pseudo: "hover",
          rules: [
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "contactus-2-text-p17U85", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "contactus-2-text-p17U85", pseudo: "hover", rules: [] },
        { className: "contactus-2-googlemaps-p17U85", pseudo: "", rules: [{ property: "overflow", value: "hidden" }] },
        {
          className: "contactus-2-button-p17U85",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
          ],
        },
        {
          className: "contactus-2-button-p17U85",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "contactus-2-inputfield-p17U85",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-2-inputfield-p17U85",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
          ],
        },
        {
          className: "contactus-2-inputfield-p17U85",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-2-inputfield-p17U85", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "VM8sTt",
      classes: [
        {
          className: "section.contactus-3-component-VM8sTt",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-3-component-VM8sTt", pseudo: "hover", rules: [] },
        {
          className: "contactus-3-col-VM8sTt",
          pseudo: "",
          rules: [
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "text-align", value: "center" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        {
          className: "contactus-3-iconwrapper-VM8sTt",
          pseudo: "",
          rules: [
            { property: "height", value: "65px" },
            { property: "width", value: "65px" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "cursor", value: "default !important" },
          ],
        },
        {
          className: "contactus-3-iconwrapper-VM8sTt",
          pseudo: "hover",
          rules: [
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "contactus-3-text-VM8sTt", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "contactus-3-text-VM8sTt", pseudo: "hover", rules: [] },
        { className: "contactus-3-googlemaps-VM8sTt", pseudo: "", rules: [{ property: "overflow", value: "hidden" }] },
        {
          className: "contactus-3-button-VM8sTt",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
          ],
        },
        {
          className: "contactus-3-button-VM8sTt",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "contactus-3-inputfield-VM8sTt",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-3-inputfield-VM8sTt",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
          ],
        },
        {
          className: "contactus-3-inputfield-VM8sTt",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-3-inputfield-VM8sTt", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "RnsagT",
      classes: [
        {
          className: "section.card-1-component-RnsagT",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-1-component-RnsagT", pseudo: "hover", rules: [] },
        { className: "card-1-col-RnsagT", pseudo: "", rules: [] },
        { className: "card-1-col-RnsagT", pseudo: "hover", rules: [] },
        { className: "card-1-wrapper-RnsagT", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
        { className: "card-1-wrapper-RnsagT", pseudo: "hover", rules: [] },
        {
          className: "card-1-title-RnsagT",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "card-1-title-RnsagT", pseudo: "hover", rules: [] },
        {
          className: "card-1-subtitle-RnsagT",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
          ],
        },
        { className: "card-1-subtitle-RnsagT", pseudo: "hover", rules: [] },
        {
          className: "card-1-social-link-RnsagT",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0.5rem 0 0" },
            { property: "color", value: "rgba(246, 129, 0, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "card-1-social-link-RnsagT", pseudo: "hover", rules: [{ property: "color", value: "rgba(229, 42, 5, 1)" }] },
        { className: "card-1-social-link-RnsagT", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "card-1-avatar-RnsagT",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "100px" },
            { property: "height", value: "200px" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-1-avatar-RnsagT",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "I2xf2C",
      classes: [
        {
          className: "section.clients-5-component-I2xf2C",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.clients-5-component-I2xf2C", pseudo: "hover", rules: [] },
        {
          className: "clients-5-slide-I2xf2C",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "clients-5-img-I2xf2C",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "width", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "clients-5-img-I2xf2C", pseudo: "hover", rules: [] },
        {
          className: "clients-5-arrow-I2xf2C",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "height", value: "2rem" },
            { property: "width", value: "2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "clients-5-arrow-I2xf2C",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "clients-5-arrow-I2xf2C svg",
          pseudo: "",
          rules: [
            { property: "fill", value: "rgba(0, 0, 0, 0.7)" },
            { property: "height", value: "1.2rem" },
            { property: "width", value: "1.2rem" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "clients-5-arrow-I2xf2C svg", pseudo: "hover", rules: [{ property: "fill", value: "rgba(0, 0, 0, 1)" }] },
        { className: "clients-5-prev-I2xf2C", pseudo: "", rules: [{ property: "left", value: "1rem" }] },
        { className: "clients-5-next-I2xf2C", pseudo: "", rules: [{ property: "right", value: "1rem" }] },
        {
          className: "clients-5-pagination-I2xf2C",
          pseudo: "",
          rules: [
            { property: "height", value: "0.7rem" },
            { property: "width", value: "0.7rem" },
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "margin", value: "0.25rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "inline-block" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "clients-5-pagination-I2xf2C",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "is-active.clients-5-pagination-I2xf2C",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
      ],
    },
    {
      componentId: "H7MSJW",
      classes: [
        {
          className: "section.footer-4-component-H7MSJW",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.footer-4-component-H7MSJW", pseudo: "hover", rules: [] },
        { className: "footer-4-wrapper-H7MSJW", pseudo: "", rules: [] },
        {
          className: "footer-4-line-H7MSJW",
          pseudo: "",
          rules: [
            { property: "border-style", value: "solid none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(200, 200, 200, 1)" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        { className: "footer-4-line-H7MSJW", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(200, 200, 200, 1)" }] },
        {
          className: "footer-4-title-H7MSJW",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
          ],
        },
        { className: "footer-4-text-H7MSJW", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        {
          className: "footer-4-social-link-H7MSJW",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0.5rem 0 0" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        { className: "footer-4-social-link-H7MSJW", pseudo: "hover", rules: [{ property: "color", value: "rgba(60, 60, 60, 1)" }] },
        { className: "footer-4-social-link-H7MSJW", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      ],
    },
    {
      componentId: "oN9e6H",
      classes: [
        {
          className: "section.resume-1-component-oN9e6H",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "4.5rem" },
          ],
        },
        { className: "section.resume-1-component-oN9e6H", pseudo: "hover", rules: [] },
        {
          className: "resume-1-title-oN9e6H",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0 0" },
          ],
        },
        { className: "resume-1-title-oN9e6H", pseudo: "hover", rules: [] },
        {
          className: "resume-1-element-oN9e6H",
          pseudo: "",
          rules: [
            { property: "border-style", value: "none none solid none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(200, 200, 200, 1)" },
            { property: "padding", value: "1rem 0 1rem 0" },
            { property: "margin", value: "1rem 0 1rem 0" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "resume-1-element-oN9e6H",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(200, 200, 200, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "resume-1-element-oN9e6H", pseudo: "last-child", rules: [{ property: "border-style", value: "none none none none" }] },
        {
          className: "resume-1-element-oN9e6Htype",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0 0" },
          ],
        },
        { className: "resume-1-element-oN9e6Htype", pseudo: "hover", rules: [] },
        { className: "resume-1-element-oN9e6Hperiod", pseudo: "", rules: [{ property: "margin", value: "0 0 0.5rem 0" }] },
        { className: "resume-1-element-oN9e6Hperiod", pseudo: "hover", rules: [] },
        {
          className: "resume-1-entrytitle-oN9e6H",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0 0" },
          ],
        },
        { className: "resume-1-entrytitle-oN9e6H", pseudo: "hover", rules: [] },
        { className: "resume-1-entrytext-oN9e6H", pseudo: "", rules: [{ property: "margin", value: "0.5rem 0 1.5rem 0" }] },
        { className: "resume-1-entrytext-oN9e6H", pseudo: "hover", rules: [] },
        { className: "resume-1-listwrapper-oN9e6H", pseudo: "", rules: [{ property: "padding-left", value: "1rem" }] },
        { className: "resume-1-listwrapper-oN9e6H", pseudo: "hover", rules: [] },
        { className: "resume-1-listtext-oN9e6H", pseudo: "", rules: [{ property: "margin", value: "0rem 0 0.5rem 0" }] },
        { className: "resume-1-listtext-oN9e6H", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "Huk3RS",
      classes: [
        {
          className: "section.basics-1-component-Huk3RS",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-Huk3RS", pseudo: "hover", rules: [] },
        {
          className: "basics-1-col-Huk3RS",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "flex-start" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-1-title-Huk3RS",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-Huk3RS", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "YUqXlf",
      classes: [
        {
          className: "section.basics-6-component-YUqXlf",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-6-component-YUqXlf", pseudo: "hover", rules: [] },
        {
          className: "basics-6-col-YUqXlf",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-6-img-YUqXlf",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "basics-6-img-YUqXlf", pseudo: "hover", rules: [] },
        {
          className: "basics-6-title-YUqXlf",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "basics-6-title-YUqXlf", pseudo: "hover", rules: [] },
        {
          className: "basics-6-text-YUqXlf",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
          ],
        },
        { className: "basics-6-text-YUqXlf", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "pNK0ks",
      classes: [
        {
          className: "section.basics-7-component-pNK0ks",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-7-component-pNK0ks", pseudo: "hover", rules: [] },
        {
          className: "basics-7-col-pNK0ks",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-7-img-pNK0ks",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "basics-7-img-pNK0ks", pseudo: "hover", rules: [] },
        {
          className: "basics-7-title-pNK0ks",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "basics-7-title-pNK0ks", pseudo: "hover", rules: [] },
        {
          className: "basics-7-text-pNK0ks",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
          ],
        },
        { className: "basics-7-text-pNK0ks", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "uRnilF",
      classes: [
        {
          className: "section.basics-6-component-uRnilF",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-6-component-uRnilF", pseudo: "hover", rules: [] },
        {
          className: "basics-6-col-uRnilF",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-6-img-uRnilF",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "basics-6-img-uRnilF", pseudo: "hover", rules: [] },
        {
          className: "basics-6-title-uRnilF",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "basics-6-title-uRnilF", pseudo: "hover", rules: [] },
        {
          className: "basics-6-text-uRnilF",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
          ],
        },
        { className: "basics-6-text-uRnilF", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "PCCGdg",
      classes: [
        {
          className: "section.basics-7-component-PCCGdg",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-7-component-PCCGdg", pseudo: "hover", rules: [] },
        {
          className: "basics-7-col-PCCGdg",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-7-img-PCCGdg",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "basics-7-img-PCCGdg", pseudo: "hover", rules: [] },
        {
          className: "basics-7-title-PCCGdg",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "basics-7-title-PCCGdg", pseudo: "hover", rules: [] },
        {
          className: "basics-7-text-PCCGdg",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
          ],
        },
        { className: "basics-7-text-PCCGdg", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "FWULDA",
      classes: [
        {
          className: "section.basics-1-component-FWULDA",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-FWULDA", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-FWULDA",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(255, 161, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-FWULDA", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "XfTVDV",
      classes: [
        {
          className: "section.basics-6-component-XfTVDV",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-6-component-XfTVDV", pseudo: "hover", rules: [] },
        {
          className: "basics-6-col-XfTVDV",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-6-img-XfTVDV",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "basics-6-img-XfTVDV", pseudo: "hover", rules: [] },
        {
          className: "basics-6-text-XfTVDV",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "width", value: "100%" },
          ],
        },
        { className: "basics-6-text-XfTVDV", pseudo: "hover", rules: [] },
        {
          className: "basics-6-title-XfTVDV",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(255, 161, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-6-title-XfTVDV", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "uFMiz8",
      classes: [
        {
          className: "section.basics-7-component-uFMiz8",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-7-component-uFMiz8", pseudo: "hover", rules: [] },
        {
          className: "basics-7-col-uFMiz8",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-7-img-uFMiz8",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "basics-7-img-uFMiz8", pseudo: "hover", rules: [] },
        {
          className: "basics-7-text-uFMiz8",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "width", value: "100%" },
          ],
        },
        { className: "basics-7-text-uFMiz8", pseudo: "hover", rules: [] },
        {
          className: "basics-7-title-uFMiz8",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(255, 161, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-7-title-uFMiz8", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "RV1492",
      classes: [
        {
          className: "section.basics-6-component-RV1492",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-6-component-RV1492", pseudo: "hover", rules: [] },
        {
          className: "basics-6-col-RV1492",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-6-img-RV1492",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "basics-6-img-RV1492", pseudo: "hover", rules: [] },
        {
          className: "basics-6-text-RV1492",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "width", value: "100%" },
          ],
        },
        { className: "basics-6-text-RV1492", pseudo: "hover", rules: [] },
        {
          className: "basics-6-title-RV1492",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(255, 161, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-6-title-RV1492", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "gTj9o2",
      classes: [
        {
          className: "section.basics-7-component-gTj9o2",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-7-component-gTj9o2", pseudo: "hover", rules: [] },
        {
          className: "basics-7-col-gTj9o2",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-7-img-gTj9o2",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "basics-7-img-gTj9o2", pseudo: "hover", rules: [] },
        {
          className: "basics-7-text-gTj9o2",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "width", value: "100%" },
          ],
        },
        { className: "basics-7-text-gTj9o2", pseudo: "hover", rules: [] },
        {
          className: "basics-7-title-gTj9o2",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(255, 161, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-7-title-gTj9o2", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "WflPpk",
      classes: [
        {
          className: "section.contactus-6-component-WflPpk",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-6-component-WflPpk", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-col-WflPpk",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-6-contacttitle-WflPpk",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
          ],
        },
        {
          className: "contactus-6-contacttitle-WflPpk",
          pseudo: "hover",
          rules: [],
        },
        { className: "contactus-6-contacttext-WflPpk", pseudo: "", rules: [{ property: "margin", value: "0 0 2rem 0" }] },
        { className: "contactus-6-contacttext-WflPpk", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-subtitle-WflPpk",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
          ],
        },
        {
          className: "contactus-6-subtitle-WflPpk",
          pseudo: "hover",
          rules: [],
        },
        { className: "contactus-6-subtext-WflPpk", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        { className: "contactus-6-subtext-WflPpk", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-buttonwrapper-WflPpk",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "contactus-6-buttonwrapper-WflPpk",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-6-button-WflPpk",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "font-weight", value: "bold" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-6-button-WflPpk",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
        {
          className: "contactus-6-inputwrapper-WflPpk",
          pseudo: "",
          rules: [{ property: "margin", value: "0 0 1rem 0" }],
        },
        {
          className: "contactus-6-inputfield-WflPpk",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-6-inputfield-WflPpk",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
          ],
        },
        {
          className: "contactus-6-inputfield-WflPpk",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "contactus-6-inputfield-WflPpk",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-6-label-WflPpk",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        {
          className: "contactus-6-label-WflPpk",
          pseudo: "hover",
          rules: [],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Raleway:100,300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Inter:100,300,700&display=swap",
    fontHeadersName: "Raleway",
    fontHeadersWeight: "300",
    fontBodyName: "Inter",
    fontBodyWeight: "300",
    color1: "rgba(255, 161, 0, 1)",
    color2: "rgba(249, 114, 31, 1)",
    color3: "rgba(223, 34, 5, 1)",
    color4: "rgba(249, 223, 31, 1)",
    color5: "rgba(194, 223, 5, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
