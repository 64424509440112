export const template9 = {
  id: "63979ed8cec08e8a8fa365b5",
  name: { en: "Tech company", nl: "Techbedrijf" },
  desc: { en: "Single-page site for a tech company", nl: "Een-pagina site voor een techbedrijf" },
  categories: ["tech", "services"],
  sbPages: [
    {
      pageId: "67e25702-3690-44a2-bc01-c249593b980d",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "U3EH2C",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-U3EH2C", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "" },
            { property: "id", value: "home" },
          ],
          content: "",
          children: [
            {
              childId: "JltVQE",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "a0Cier",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "BqxQIO",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-U3EH2C"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Logo" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-companyname.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "oowTe8",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-U3EH2C", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "hSNvXH",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "fiy0Gz",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "ZMtwfl",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "pySOZ4",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-U3EH2C"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#home" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Home",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ZxUj4S",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-U3EH2C"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#offer" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Products",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "qtkD2n",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-U3EH2C"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#benefits" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Benefits",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "pPTRhy",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-U3EH2C"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#contact" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact us",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "FUGfGo",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "hero-7-component-FUGfGo"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "wlBWjz",
              type: "",
              htmlTagName: "div",
              classes: ["row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "ohikHF",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-7-contentcol-FUGfGo", "col-12", "col-md-6", "col-lg-6", "p-3"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "qETVN8",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-7-title-FUGfGo", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "SKH0Fy",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-7-text-FUGfGo", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos saepe expedita nemo molestiae repudiandae, dolor aperiam velit esse veronam!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "ajivIJ",
                      type: "",
                      htmlTagName: "a",
                      classes: ["hero-7-button-FUGfGo"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Get started!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "dGUltw",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["row", "align-items-start", "justify-content-center"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "pp0sY0",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "card-18-icon-col-FUGfGo"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "afvLZq",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-18-icon-wrapper-FUGfGo"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "ldxL3S",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["d-flex"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "RWCIRU",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["card-18-iconwrapper-FUGfGo"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "div" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-iconwrapper", value: "true" },
                                        { property: "data-texteditable", value: "false" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "OGDasr",
                                          type: "",
                                          htmlTagName: "span",
                                          classes: ["icon", "fa-regular", "fa-user"],
                                          styles: [],
                                          attributes: [{ property: "data-checkparent", value: "true" }],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "VctaDB",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["toGrow"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "y7af8A",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["toGrow", "card-18-icon-text-FUGfGo", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Donec bibendum diam ante",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "HbYJBs",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "card-18-icon-col-FUGfGo"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "RRfnSM",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-18-icon-wrapper-FUGfGo"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "GE9UpB",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["d-flex"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "WO46ge",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["card-18-iconwrapper-FUGfGo"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "div" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-iconwrapper", value: "true" },
                                        { property: "data-texteditable", value: "false" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "EBUjmh",
                                          type: "",
                                          htmlTagName: "span",
                                          classes: ["icon", "fa-regular", "fa-comment"],
                                          styles: [],
                                          attributes: [{ property: "data-checkparent", value: "true" }],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "VJwZ3v",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["toGrow"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "IFSivM",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["toGrow", "card-18-icon-text-FUGfGo", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Donec bibendum diam ante",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "EmiWYJ",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "card-18-icon-col-FUGfGo"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "Pg56Rr",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-18-icon-wrapper-FUGfGo"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "qLswAE",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["d-flex"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "das0qg",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["card-18-iconwrapper-FUGfGo"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "div" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-iconwrapper", value: "true" },
                                        { property: "data-texteditable", value: "false" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "zEPBqw",
                                          type: "",
                                          htmlTagName: "span",
                                          classes: ["icon", "fa-regular", "fa-bell"],
                                          styles: [],
                                          attributes: [{ property: "data-checkparent", value: "true" }],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "jChXij",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["toGrow"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "hpAY1I",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["toGrow", "card-18-icon-text-FUGfGo", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Donec bibendum diam ante",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "VYi416",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "p-3", "hero-7-img-FUGfGowrapper"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "kC659I",
                      type: "",
                      htmlTagName: "img",
                      classes: ["hero-7-img-FUGfGo"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Image" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/illustration-7.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "q9aj6k",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "section-divider-q9aj6k"],
          styles: [],
          attributes: [{ property: "data-name", value: "section_divider" }],
          content: "",
          children: [
            {
              childId: "iuvzPd",
              type: "",
              htmlTagName: "svg",
              classes: [],
              styles: [
                { property: "background", value: "rgba(255, 255, 255, 1)" },
                { property: "transform", value: "scaleY(1) scaleX(1)" },
              ],
              attributes: [
                { property: "xmlns", value: "http://www.w3.org/2000/svg" },
                { property: "version", value: "1.0" },
                { property: "width", value: "100%" },
                { property: "height", value: "100" },
                { property: "viewBox", value: "0 0 1280 140" },
                { property: "preserveAspectRatio", value: "none" },
              ],
              content: "",
              children: [
                {
                  childId: "HlWRTj",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(243, 218, 164, 1)" },
                    { property: "d", value: "M0 140h1280C573.08 140 0 0 0 0z" },
                    { property: "opacity", value: "0.3" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "IVAPSq",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(243, 218, 164, 1)" },
                    { property: "d", value: "M0 140h1280C573.08 140 0 30 0 30z" },
                    { property: "opacity", value: "0.5" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "cfJnlU",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(243, 218, 164, 1)" },
                    { property: "d", value: "M0 140h1280C573.08 140 0 60 0 60z" },
                  ],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "NqbLBN",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "hero-8-component-NqbLBN"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "n5I7C4",
              type: "",
              htmlTagName: "div",
              classes: ["row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "kQUvrn",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "p-3", "hero-8-col-NqbLBN"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "A0QPR4",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-8-title-NqbLBN", "w-100", "w-md-80", "w-lg-60"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Etiam ut ipsum sed ipsum luctus rutrum finibus.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "YAGANA",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "p-3", "hero-8-col-NqbLBN"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "kCB83h",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-8-text-NqbLBN", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Etiam est odio, volutpat eu aliquam non, condimentum et odio. Suspendisse potenti. Nam euismod porttitor dui ac dignissim. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam sodales, lectus sed sagittis feugiat, massa dui volutpat magna, et luctus turpis arcu ac enim.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "thNPZL",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-thNPZL"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "benefits" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "ua9wkF",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-thNPZL", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "What we offer", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "JqBin9",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-JqBin9"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "hCr3i7",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-JqBin9", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "F3hIDr",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-10-component-F3hIDr"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ahaF5n",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "p2uw7s",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-F3hIDr"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "t9nGmn",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-F3hIDr"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "wDaNSs",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-F3hIDr"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/illustration-1.png" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "W4hzGG",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-F3hIDr", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem Ipsum",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "OPiJKN",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-F3hIDr", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "QlApmN",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-F3hIDr"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "aFbCyA",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-F3hIDr"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "cZFGYJ",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-F3hIDr"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/illustration-2.png" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "sM2PR5",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-F3hIDr", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem Ipsum",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "pFM9pU",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-F3hIDr", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "QOYaTZ",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-F3hIDr"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "zMyGAV",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-F3hIDr"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "hxaW28",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-F3hIDr"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/illustration-3.png" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "DGrMdD",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-F3hIDr", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem Ipsum",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "V5lIR1",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-F3hIDr", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Sooxaf",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-F3hIDr"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "fGjPTW",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-F3hIDr"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "cUH7em",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-F3hIDr"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/illustration-4.png" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "wuxnzt",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-F3hIDr", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem Ipsum",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "n6nn0G",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-F3hIDr", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Gxd78i",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-9-component-Gxd78i"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "offer" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "g7Wgrj",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-9-row-Gxd78i"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "OvugqL",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-first", "card-9-col-Gxd78i"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "RAHqnv",
                      type: "",
                      htmlTagName: "img",
                      classes: ["card-9-img-Gxd78i"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/illustration-5.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "pWKZ4n",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "card-9-col-Gxd78i"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "CGTD3R",
                      type: "",
                      htmlTagName: "h4",
                      classes: ["card-9-title-Gxd78i", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "CNBreR",
                      type: "",
                      htmlTagName: "p",
                      classes: ["card-9-text-Gxd78i", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "AUSdg0",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["row", "align-items-start", "justify-content-center"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "OIF4mP",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "card-9-icon-col-Gxd78i"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "A9Ys5v",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-9-icon-wrapper-Gxd78i"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "zzrm1e",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["d-flex"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "rxznpy",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["card-9-iconwrapper-Gxd78i"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "div" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-iconwrapper", value: "true" },
                                        { property: "data-texteditable", value: "false" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "fE3hR7",
                                          type: "",
                                          htmlTagName: "span",
                                          classes: ["icon", "fa-solid", "fa-bolt"],
                                          styles: [],
                                          attributes: [{ property: "data-checkparent", value: "true" }],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "ptVZAd",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["toGrow"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "nxP4D4",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["toGrow", "card-9-icon-text-Gxd78i", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Lorem ipsum dolor sit amet.",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "b5Fo8F",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "card-9-icon-col-Gxd78i"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "iCfcJH",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-9-icon-wrapper-Gxd78i"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "M8jNsc",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["d-flex"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "U72MfU",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["card-9-iconwrapper-Gxd78i"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "div" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-iconwrapper", value: "true" },
                                        { property: "data-texteditable", value: "false" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "dYgvpF",
                                          type: "",
                                          htmlTagName: "span",
                                          classes: ["icon", "fa-solid", "fa-minimize"],
                                          styles: [],
                                          attributes: [{ property: "data-checkparent", value: "true" }],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "l4zo4t",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["toGrow"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "HV1SJb",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["toGrow", "card-9-icon-text-Gxd78i", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Lorem ipsum dolor sit amet.",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "kOT3JB",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "card-9-icon-col-Gxd78i"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "u8IBhc",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-9-icon-wrapper-Gxd78i"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "k7xZjx",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["d-flex"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "vhqGlp",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["card-9-iconwrapper-Gxd78i"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "div" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-iconwrapper", value: "true" },
                                        { property: "data-texteditable", value: "false" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "jabvns",
                                          type: "",
                                          htmlTagName: "span",
                                          classes: ["icon", "fa-solid", "fa-check"],
                                          styles: [],
                                          attributes: [{ property: "data-checkparent", value: "true" }],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "WWUp0k",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["toGrow"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "D7YTCY",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["toGrow", "card-9-icon-text-Gxd78i", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Lorem ipsum dolor sit amet.",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "WUnuGX",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "section-divider-WUnuGX"],
          styles: [],
          attributes: [{ property: "data-name", value: "section_divider" }],
          content: "",
          children: [
            {
              childId: "oZ5aUQ",
              type: "",
              htmlTagName: "svg",
              classes: [],
              styles: [
                { property: "background", value: "rgba(255, 255, 255, 1)" },
                { property: "transform", value: "scaleY(1) scaleX(1)" },
              ],
              attributes: [
                { property: "xmlns", value: "http://www.w3.org/2000/svg" },
                { property: "version", value: "1.1" },
                { property: "width", value: "100%" },
                { property: "height", value: "130" },
                { property: "viewBox", value: "0 0 100 100" },
                { property: "preserveAspectRatio", value: "none" },
              ],
              content: "",
              children: [
                {
                  childId: "skR0Uu",
                  type: "",
                  htmlTagName: "path",
                  classes: [],
                  styles: [],
                  attributes: [
                    { property: "fill", value: "rgba(234, 208, 245, 1)" },
                    { property: "d", value: "M0 100 C 20 0 20 0 100 100 Z" },
                  ],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "J2xg0K",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-J2xg0K"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "dBsjyt",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-J2xg0K", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Trusted by great companies",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "TPnbQN",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-TPnbQN"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "hjuSmV",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-TPnbQN", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "crcn5R",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "clients-2-component-crcn5R"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "wCZ002",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-center", "justify-content-center", "clients-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "aa9EhW",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "clients-2-wrapper-crcn5R", "col-lg-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "yB4aSm",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-crcn5R"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "c0lLUm",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "clients-2-wrapper-crcn5R", "col-lg-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "OcOEIz",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-crcn5R"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "qSv1nW",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "clients-2-wrapper-crcn5R", "col-lg-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "hPijQV",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-crcn5R"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-3.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "IZEWb9",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "clients-2-wrapper-crcn5R", "col-lg-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "gQFQzA",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-crcn5R"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-4.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "JCZIaq",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "clients-2-wrapper-crcn5R", "col-lg-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "c7swjM",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-crcn5R"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-5.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "nPVinJ",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "clients-2-wrapper-crcn5R", "col-lg-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "JHqOsd",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-crcn5R"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-6.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "nzZU7X",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "clients-2-wrapper-crcn5R", "col-lg-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "O1TSRe",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-crcn5R"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-7.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "kSrJy1",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "clients-2-wrapper-crcn5R", "col-lg-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "shoOOx",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-crcn5R"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-8.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "EquVRL",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-EquVRL"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "x2w6QL",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-EquVRL", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Our customers are impressed",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "dcE0Av",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-dcE0Av"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "dTwTMj",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-dcE0Av", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "MG8j4K",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "quote-1-component-MG8j4K"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ahH8kP",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-stretch", "quote-1-row", "justify-content-around"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "DM2zEY",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-1-col-MG8j4K"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "zkAimx",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-1-wrapper-MG8j4K"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "fRz5WK",
                          type: "",
                          htmlTagName: "div",
                          classes: ["quote-1-border-MG8j4K"],
                          styles: [],
                          attributes: [{ property: "data-elementgetter2", value: "true" }],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "j1UHlH",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["quote-1-text-MG8j4K", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "KJIkFy",
                          type: "",
                          htmlTagName: "h6",
                          classes: ["quote-1-quote-MG8j4K"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "— Rick Long",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "dagMP9",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-1-col-MG8j4K"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "Sf3Tzg",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-1-wrapper-MG8j4K"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "N0AnHC",
                          type: "",
                          htmlTagName: "div",
                          classes: ["quote-1-border-MG8j4K"],
                          styles: [],
                          attributes: [{ property: "data-elementgetter2", value: "true" }],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "hUbeQj",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["quote-1-text-MG8j4K", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora rerum rem ducimus illum!",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "V1uutc",
                          type: "",
                          htmlTagName: "h6",
                          classes: ["quote-1-quote-MG8j4K"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "— Julia Scott",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "HGI6GP",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-1-col-MG8j4K"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "sOSnqj",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-1-wrapper-MG8j4K"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "VGNPKf",
                          type: "",
                          htmlTagName: "div",
                          classes: ["quote-1-border-MG8j4K"],
                          styles: [],
                          attributes: [{ property: "data-elementgetter2", value: "true" }],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "FTqwXy",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["quote-1-text-MG8j4K", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores iste porro facere beatae totam.",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "alQYPK",
                          type: "",
                          htmlTagName: "h6",
                          classes: ["quote-1-quote-MG8j4K"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "— Rebecca Collier",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "DNUk4l",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-DNUk4l"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "khOdLy",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-DNUk4l", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Join countless others in improving your efficiency!",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "rSoXNq",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-7-component-rSoXNq"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "tdfQtK",
              type: "",
              htmlTagName: "a",
              classes: ["basics-7-link-readmore-rSoXNq"],
              styles: [],
              attributes: [
                { property: "href", value: "#!" },
                { property: "data-href", value: "#!" },
                { property: "data-target", value: "_self" },
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "button" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact us now", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "hiYRDT",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-3-component-hiYRDT"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "bCEkE6",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-3-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics1" }],
              content: "",
              children: [
                {
                  childId: "o2azlj",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-3-col-hiYRDT"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "QmKUGm",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-3-img-hiYRDT"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/illustration-6.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "wSawC5",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "footer-5-component-wSawC5"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "contact" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "KFkt8I",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "footer-5-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "X9SVFx",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "footer-5-col-wSawC5"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "I1g9ZM",
                      type: "",
                      htmlTagName: "h4",
                      classes: ["footer-5-title-wSawC5"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "COMPANY NAME",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "XwmJeO",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-5-text-wSawC5"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Street name and number",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "TB4PSN",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-5-text-wSawC5"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Postal code and city",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "s7FYJH",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-5-text-wSawC5"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Country", children: [] },
                      ],
                    },
                    {
                      childId: "zmuL0z",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-5-text-wSawC5"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Zkda1U",
                          type: "",
                          htmlTagName: "strong",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Phone: ",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "100 200 3000",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "AXJQMV",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-5-text-wSawC5"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "oeKPid",
                          type: "",
                          htmlTagName: "strong",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email: ",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "email@address.com",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "zVWEaS",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "footer-5-col-wSawC5"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "WZdeaV",
                      type: "",
                      htmlTagName: "h4",
                      classes: ["footer-5-title-wSawC5"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "MENU", children: [] },
                      ],
                    },
                    {
                      childId: "afXoXy",
                      type: "",
                      htmlTagName: "a",
                      classes: ["footer-5-menu-link-wSawC5"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "a" },
                        { property: "data-href", value: "#home" },
                        { property: "data-target", value: "_self" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Home", children: [] },
                      ],
                    },
                    {
                      childId: "Q3gab7",
                      type: "",
                      htmlTagName: "a",
                      classes: ["footer-5-menu-link-wSawC5"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "a" },
                        { property: "data-href", value: "#offer" },
                        { property: "data-target", value: "_self" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Products",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "yGSsEV",
                      type: "",
                      htmlTagName: "a",
                      classes: ["footer-5-menu-link-wSawC5"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "a" },
                        { property: "data-href", value: "#benefits" },
                        { property: "data-target", value: "_self" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Benefits",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "Oedryt",
                      type: "",
                      htmlTagName: "a",
                      classes: ["footer-5-menu-link-wSawC5"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "a" },
                        { property: "data-href", value: "#contact" },
                        { property: "data-target", value: "_self" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Contact us",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "uhtGNs",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "footer-5-col-wSawC5"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "PVCqDI",
                      type: "",
                      htmlTagName: "h4",
                      classes: ["footer-5-title-wSawC5"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "SOCIALS", children: [] },
                      ],
                    },
                    {
                      childId: "JCOdOH",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["footer-5-socialwrapper"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "PLOrmF",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["footer-5-social-link-wSawC5"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "a" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "T81mv5",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-twitter"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "zYt6fm",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["footer-5-social-link-wSawC5"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "a" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "LITqXi",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-linkedin"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "IP43Dx",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["footer-5-social-link-wSawC5"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "a" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "JukHis",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-facebook-f"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "63978ccadaaff698fa232018",
    },
  ],
  sbCustomCss: [
    {
      componentId: "FUGfGo",
      classes: [
        {
          className: "section.hero-7-component-FUGfGo",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "2rem" },
          ],
        },
        { className: "section.hero-7-component-FUGfGo", pseudo: "hover", rules: [] },
        {
          className: "hero-7-contentcol-FUGfGo",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "hero-7-title-FUGfGo",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(53, 53, 53, 1)" },
            { property: "margin", value: "0 0 1.5rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "hero-7-title-FUGfGo", pseudo: "hover", rules: [] },
        {
          className: "hero-7-text-FUGfGo",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "margin", value: "0 0 1.5rem 0" },
            { property: "font-style", value: "italic" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "hero-7-text-FUGfGo", pseudo: "hover", rules: [] },
        { className: "card-18-icon-col-FUGfGo", pseudo: "", rules: [] },
        { className: "card-18-icon-col-FUGfGo", pseudo: "hover", rules: [] },
        { className: "card-18-icon-wrapper-FUGfGo", pseudo: "", rules: [] },
        { className: "card-18-icon-wrapper-FUGfGo", pseudo: "hover", rules: [] },
        {
          className: "card-18-iconwrapper-FUGfGo",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1.5rem" },
            { property: "color", value: "rgba(53, 53, 53, 1)" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-18-iconwrapper-FUGfGo", pseudo: "hover", rules: [] },
        {
          className: "card-18-icon-text-FUGfGo",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "font-size", value: "13px" },
          ],
        },
        { className: "card-18-icon-text-FUGfGo", pseudo: "hover", rules: [] },
        {
          className: "hero-7-button-FUGfGo",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(53, 53, 53, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 3rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-7-button-FUGfGo",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(20, 20, 20, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-7-img-FUGfGo",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0.75rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "hero-7-img-FUGfGo",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "rMnStZ",
      classes: [
        {
          className: "section.hero-2-component-rMnStZ",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-2-component-rMnStZ", pseudo: "hover", rules: [] },
        {
          className: "hero-2-contentcol-rMnStZ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "hero-2-title-rMnStZ",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(53, 53, 53, 1)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "hero-2-title-rMnStZ", pseudo: "hover", rules: [] },
        {
          className: "hero-2-text-rMnStZ",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "margin", value: "0 0 3rem 0" },
            { property: "font-style", value: "italic" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "hero-2-text-rMnStZ", pseudo: "hover", rules: [] },
        {
          className: "hero-2-button-rMnStZ",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(53, 53, 53, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "0.4rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "align-self", value: "start" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "none" },
          ],
        },
        {
          className: "hero-2-button-rMnStZ",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(201, 36, 3, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "hero-2-img-rMnStZ",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "border-radius", value: "0.75rem" },
          ],
        },
        { className: "hero-2-img-rMnStZ", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "NqbLBN",
      classes: [
        {
          className: "section.hero-8-component-NqbLBN",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "4.5rem" },
            { property: "background", value: "rgba(243, 218, 164, 1)" },
          ],
        },
        { className: "section.hero-8-component-NqbLBN", pseudo: "hover", rules: [] },
        { className: "hero-8-col-NqbLBN", pseudo: "", rules: [] },
        { className: "hero-8-col-NqbLBN", pseudo: "hover", rules: [] },
        {
          className: "hero-8-title-NqbLBN",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "hero-8-title-NqbLBN", pseudo: "hover", rules: [] },
        {
          className: "hero-8-text-NqbLBN",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "hero-8-text-NqbLBN", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "q9aj6k",
      classes: [
        {
          className: "section-divider-q9aj6k",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
            { property: "width", value: "100%" },
          ],
        },
      ],
    },
    {
      componentId: "thNPZL",
      classes: [
        {
          className: "section.basics-1-component-thNPZL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-thNPZL", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-thNPZL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-thNPZL", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-thNPZL",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(53, 53, 53, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-thNPZL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "JqBin9",
      classes: [
        {
          className: "section.basics-2-component-JqBin9",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-JqBin9", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-JqBin9",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-JqBin9", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-JqBin9",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-JqBin9", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "F3hIDr",
      classes: [
        {
          className: "section.card-10-component-F3hIDr",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-10-component-F3hIDr", pseudo: "hover", rules: [] },
        { className: "card-10-col-F3hIDr", pseudo: "", rules: [] },
        { className: "card-10-col-F3hIDr", pseudo: "hover", rules: [] },
        { className: "card-10-wrapper-F3hIDr", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
        { className: "card-10-wrapper-F3hIDr", pseudo: "hover", rules: [] },
        {
          className: "card-10-avatar-F3hIDr",
          pseudo: "",
          rules: [
            { property: "border-radius", value: "0" },
            { property: "height", value: "200px" },
            { property: "width", value: "200px" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "display", value: "block" },
          ],
        },
        { className: "card-10-avatar-F3hIDr", pseudo: "hover", rules: [] },
        {
          className: "card-10-title-F3hIDr",
          pseudo: "",
          rules: [
            { property: "margin", value: "0.5rem 0 0 0" },
            { property: "color", value: "rgba(53, 53, 53, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-10-title-F3hIDr", pseudo: "hover", rules: [] },
        {
          className: "card-10-text-F3hIDr",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-10-text-F3hIDr", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "Gxd78i",
      classes: [
        {
          className: "section.card-9-component-Gxd78i",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-9-component-Gxd78i", pseudo: "hover", rules: [] },
        { className: "card-9-row-Gxd78i", pseudo: "", rules: [] },
        {
          className: "card-9-col-Gxd78i",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0.5rem 0 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-9-col-Gxd78i", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(0, 0, 0, 0)" }] },
        { className: "card-9-card-col-Gxd78i", pseudo: "", rules: [{ property: "padding", value: "0.5rem 0 0.5rem 0" }] },
        { className: "card-9-card-col-Gxd78i", pseudo: "hover", rules: [] },
        { className: "card-9-card-wrapper-Gxd78i", pseudo: "", rules: [] },
        { className: "card-9-card-wrapper-Gxd78i", pseudo: "hover", rules: [] },
        {
          className: "card-9-title-Gxd78i",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 1.5rem 0" },
            { property: "color", value: "rgba(53, 53, 53, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-9-title-Gxd78i", pseudo: "hover", rules: [] },
        {
          className: "card-9-text-Gxd78i",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-9-text-Gxd78i", pseudo: "hover", rules: [] },
        { className: "card-9-icon-col-Gxd78i", pseudo: "", rules: [] },
        { className: "card-9-icon-col-Gxd78i", pseudo: "hover", rules: [] },
        { className: "card-9-icon-wrapper-Gxd78i", pseudo: "", rules: [] },
        { className: "card-9-icon-wrapper-Gxd78i", pseudo: "hover", rules: [] },
        {
          className: "card-9-icon-title-Gxd78i",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 1rem" },
            { property: "color", value: "rgba(53, 53, 53, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-9-icon-title-Gxd78i", pseudo: "hover", rules: [] },
        {
          className: "card-9-icon-text-Gxd78i",
          pseudo: "",
          rules: [
            { property: "margin", value: "0.5rem 0 0 1rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-9-icon-text-Gxd78i", pseudo: "hover", rules: [] },
        {
          className: "card-9-img-Gxd78i",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-9-img-Gxd78i",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-9-iconwrapper-Gxd78i",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "color", value: "rgba(53, 53, 53, 1)" },
            { property: "font-size", value: "2rem" },
          ],
        },
        { className: "card-9-iconwrapper-Gxd78i", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "hr2fzh",
      classes: [
        {
          className: "section.clients-1-component-hr2fzh",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.clients-1-component-hr2fzh", pseudo: "hover", rules: [] },
        {
          className: "clients-1-logo-img-hr2fzh",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1.5rem 1.5rem 0" },
            { property: "max-height", value: "50px" },
            { property: "max-width", value: "100px" },
            { property: "height", value: "auto" },
            { property: "width", value: "auto" },
            { property: "filter", value: "grayscale(100%)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "0.5" },
          ],
        },
        {
          className: "clients-1-logo-img-hr2fzh",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: "1" },
            { property: "filter", value: "grayscale(0%)" },
          ],
        },
      ],
    },
    {
      componentId: "crcn5R",
      classes: [
        {
          className: "section.clients-2-component-crcn5R",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "2rem" },
            { property: "background", value: "rgba(234, 208, 245, 1)" },
          ],
        },
        { className: "section.clients-2-component-crcn5R", pseudo: "hover", rules: [] },
        {
          className: "clients-2-wrapper-crcn5R",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "height", value: "75px" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(194, 197, 199, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "padding", value: "2rem 0rem 2rem 0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "clients-2-logo-crcn5R",
          pseudo: "",
          rules: [
            { property: "max-height", value: "50px" },
            { property: "max-width", value: "100px" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(0%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "clients-2-logo-crcn5R",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(0%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "J2xg0K",
      classes: [
        {
          className: "section.basics-1-component-J2xg0K",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", value: "rgba(234, 208, 245, 1)" },
          ],
        },
        { className: "section.basics-1-component-J2xg0K", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-J2xg0K",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-J2xg0K", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-J2xg0K",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(53, 53, 53, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-J2xg0K", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "TPnbQN",
      classes: [
        {
          className: "section.basics-2-component-TPnbQN",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", value: "rgba(234, 208, 245, 1)" },
          ],
        },
        { className: "section.basics-2-component-TPnbQN", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-TPnbQN",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-TPnbQN", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-TPnbQN",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-TPnbQN", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "WUnuGX",
      classes: [
        {
          className: "section-divider-WUnuGX",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "margin", value: "0 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
            { property: "width", value: "100%" },
          ],
        },
      ],
    },
    {
      componentId: "MG8j4K",
      classes: [
        {
          className: "section.quote-1-component-MG8j4K",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.quote-1-component-MG8j4K", pseudo: "hover", rules: [] },
        {
          className: "quote-1-text-MG8j4K",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 1rem 1rem 1.5rem" },
            { property: "color", value: "rgba(109, 118, 126, 1)" },
          ],
        },
        { className: "quote-1-text-MG8j4K", pseudo: "hover", rules: [] },
        {
          className: "quote-1-quote-MG8j4K",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(109, 118, 126, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 1rem 1rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "quote-1-quote-MG8j4K", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "quote-1-col-MG8j4K",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
          ],
        },
        {
          className: "quote-1-wrapper-MG8j4K",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "padding", value: "0 0 0 0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0.75rem" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "quote-1-wrapper-MG8j4K",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" },
          ],
        },
        {
          className: "quote-1-border-MG8j4K",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "background", value: "rgba(53, 53, 53, 1)" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "height", value: "100%" },
            { property: "width", value: "10px" },
          ],
        },
        { className: "quote-1-border-MG8j4K", pseudo: "hover", rules: [{ property: "background", value: "rgba(53, 53, 53, 1)" }] },
      ],
    },
    {
      componentId: "EquVRL",
      classes: [
        {
          className: "section.basics-1-component-EquVRL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-EquVRL", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-EquVRL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-EquVRL", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-EquVRL",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(53, 53, 53, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-EquVRL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "dcE0Av",
      classes: [
        {
          className: "section.basics-2-component-dcE0Av",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-dcE0Av", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-dcE0Av",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-dcE0Av", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-dcE0Av",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-dcE0Av", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "DNUk4l",
      classes: [
        {
          className: "section.basics-1-component-DNUk4l",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-DNUk4l", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-DNUk4l",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-DNUk4l", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-DNUk4l",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(53, 53, 53, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-DNUk4l", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "rSoXNq",
      classes: [
        {
          className: "section.basics-7-component-rSoXNq",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-7-component-rSoXNq", pseudo: "hover", rules: [] },
        {
          className: "basics-7-component-rSoXNq",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-7-component-rSoXNq", pseudo: "hover", rules: [] },
        {
          className: "basics-7-link-readmore-rSoXNq",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(53, 53, 53, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(53, 53, 53, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-7-link-readmore-rSoXNq",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(20, 20, 20, 1)" },
            { property: "border-color", value: "rgba(20, 20, 20, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "hiYRDT",
      classes: [
        {
          className: "section.basics-3-component-hiYRDT",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-3-component-hiYRDT", pseudo: "hover", rules: [] },
        {
          className: "basics-3-col-hiYRDT",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-3-img-hiYRDT",
          pseudo: "",
          rules: [
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "400px" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-3-img-hiYRDT",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "wSawC5",
      classes: [
        {
          className: "section.footer-5-component-wSawC5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "2rem" },
            { property: "background", value: "rgba(242, 216, 160, 1)" },
          ],
        },
        { className: "section.footer-5-component-wSawC5", pseudo: "hover", rules: [] },
        { className: "footer-5-col-wSawC5", pseudo: "", rules: [{ property: "padding", value: "1rem 1rem 1rem 1rem" }] },
        {
          className: "footer-5-text-wSawC5",
          pseudo: "",
          rules: [
            { property: "text-align", value: "justify" },
            { property: "margin", value: "0 0 0 0" },
            { property: "font-size", value: "15px" },
          ],
        },
        {
          className: "footer-5-social-link-wSawC5",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "color", value: "rgba(51, 51, 51, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0.5rem 0.5rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "align-self", value: "start" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        {
          className: "footer-5-social-link-wSawC5",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(20, 20, 20, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "footer-5-social-link-wSawC5", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-5-title-wSawC5",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(53, 53, 53, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "footer-5-menu-link-wSawC5",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "font-size", value: "15px" },
            { property: "color", value: "rgba(51, 51, 51, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "footer-5-menu-link-wSawC5",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(20, 20, 20, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "U3EH2C",
      classes: [
        {
          className: "scrolledpast-U3EH2C.navbar-1-wrapper-U3EH2C",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "rem" },
            { property: "padding-bottom", value: "rem" },
            { property: "background", value: "" },
          ],
        },
        { className: "scrolledpast-U3EH2C .navbar-1-logo-U3EH2C", pseudo: "", rules: [{ property: "max-height", value: "px" }] },
        {
          className: "navbar-1-wrapper-U3EH2C",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 5px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "navbar-1-logo-U3EH2C",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "35px" },
          ],
        },
        { className: "navbar-1-logo-U3EH2C", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-U3EH2C",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(51, 51, 51, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-U3EH2C",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(178, 178, 178, 0)" },
            { property: "color", value: "rgba(20, 20, 20, 1)" },
          ],
        },
        {
          className: "navbar-1-link-U3EH2C",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "font-weight", value: "inherit" },
          ],
        },
        { className: "navbar-1-link-U3EH2C", pseudo: "hover", rules: [{ property: "color", value: "rgba(0, 0, 0, 1)" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Ubuntu:300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Ubuntu",
    fontHeadersWeight: "700",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(53, 53, 53, 1)",
    color2: "rgba(22, 22, 22, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
