export const forms6 = {
  id: "forms-6",
  name: "Forms 6",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "forms-6-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["forms-6-wrapper"],
        styles: [],
        attributes: [{ property: "data-herowrapper", value: "true" }],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "img",
            classes: ["forms-6-img"],
            styles: [],
            attributes: [
              {
                property: "src",
                value: "https://cdn.satonda.com/eb/assets/abstract3.jpg",
              },
              { property: "alt", value: "Image" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["p-3", "forms-6-content"],
            styles: [],
            attributes: [
              { property: "data-name", value: "hero1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["forms-6-logo-img"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/under-construction-white.png" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["forms-6-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "UNDER CONSTRUCTION",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["forms-6-text", "w-100", "w-md-70", "w-lg-50"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Sign up to be the first to know when we go live!",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["row", "justify-content-center", "align-items-stretch"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-6"],
                    styles: [],
                    attributes: [
                      { property: "data-sbform", value: "true" },
                      { property: "data-sbformaction", value: "" },
                      { property: "data-sbformmsg", value: "Your message has been received!" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["w-100", "forms-6-inputwrapper"],
                        styles: [],
                        attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "label",
                            classes: ["form-control", "forms-6-label"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "inputlabel" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Your email address",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "input",
                            classes: ["form-control", "forms-6-inputfield"],
                            styles: [],
                            attributes: [
                              { property: "type", value: "email" },
                              { property: "name", value: "email" },
                              { property: "placeholder", value: "Email address" },
                              { property: "data-name", value: "inputfield" },
                              { property: "data-required", value: "true" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["forms-6-buttonwrapper"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "button",
                            classes: ["forms-6-button"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "button" },
                              { property: "data-sbformbtn", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Subscribe!",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.forms-6-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      {
        className: "section.forms-6-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-6-component",
        pseudo: "",
        rules: [
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "forms-6-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-6-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "height", value: "100vh" },
        ],
      },
      {
        className: "forms-6-img",
        pseudo: "",
        rules: [
          { property: "display", value: "block" },
          { property: "height", value: "100%" },
          { property: "width", value: "100%" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "forms-6-content",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "background", value: "rgba(255, 255, 255, 0.3)" },
        ],
      },
      {
        className: "forms-6-logo-img",
        pseudo: "",
        rules: [
          { property: "width", value: "auto" },
          { property: "height", value: "100px" },
          { property: "object-fit", value: "cover" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0rem 0rem 4.5rem 0rem" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "forms-6-logo-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-6-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(255, 255, 255, 0.4)" },
          { property: "margin", value: "0 0 1rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
          { property: "font-weight", value: "bold" },
        ],
      },
      {
        className: "forms-6-title",
        pseudo: "hover",
        rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(255, 255, 255, 0.4)" }],
      },
      {
        className: "forms-6-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(255, 255, 255, 0.4)" },
          { property: "margin", value: "0 0 2rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "forms-6-text",
        pseudo: "hover",
        rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(255, 255, 255, 0.4)" }],
      },
      {
        className: "forms-6-buttonwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "forms-6-buttonwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-6-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-radius", value: "0.4rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
          { property: "width", value: "100%" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "forms-6-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(108, 117, 125, 1)" },
        ],
      },
      {
        className: "forms-6-inputwrapper",
        pseudo: "",
        rules: [{ property: "margin", value: "0 0 1rem 0" }],
      },
      {
        className: "forms-6-inputfield",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.3)" },
          { property: "color", value: "rgba(206, 212, 218, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 0.5)" },
          { property: "border-radius", value: "0.4rem" },
          { property: "transition", value: "all 0.2s ease" },
          { property: "padding", value: "0.5rem 1rem 0.5rem 1rem" },
          { property: "font-size", value: "1rem" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(255, 255, 255, 0)" },
          { property: "outline", value: "0" },
        ],
      },
      {
        className: "forms-6-inputfield",
        pseudo: "focus",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.8)" },
          { property: "color", value: "rgba(109, 118, 126, 1)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(255, 255, 255, 0.3)" },
        ],
      },
      {
        className: "forms-6-inputfield",
        pseudo: ":placeholder",
        rules: [
          { property: "color", value: "rgba(206, 212, 218, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "forms-6-inputfield",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-6-label",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.25rem 0" },
          { property: "display", value: "none" },
        ],
      },
      {
        className: "forms-6-label",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
