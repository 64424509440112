export const quotes4 = {
  id: "quotes-4",
  name: "Quotes 4",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "quote-4-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "quote-4-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-6", "quote-4-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-4-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["toGrow"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["quote-4-quote-left"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "span" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-quote-left"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["quote-4-text3"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content:
                              "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus necessitatibus culpa ex odio quam atquereiciendis ducimus earum.Fugit, recusandae!",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["d-flex", "align-items-center", "w-100"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["quote-4-avatar"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "img" },
                        ],
                        content: "",
                        children: [],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["quote-4-text1"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Name",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["quote-4-text2"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Function / company /  location",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.quote-4-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.quote-4-component", pseudo: "hover", rules: [] },
      {
        className: "quote-4-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "display", value: "flex" },
        ],
      },
      {
        className: "quote-4-col",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-4-wrapper",
        pseudo: "",
        rules: [
          { property: "align-items", value: "center" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(150, 150, 150, 1)" },
          { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          { property: "background", value: "rgba(250, 250, 250, 1)" },
        ],
      },
      {
        className: "quote-4-wrapper",
        pseudo: "hover",
        rules: [
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(150, 150, 150, 1)" },
          { property: "background", value: "rgba(250, 250, 250, 1)" },
        ],
      },
      {
        className: "quote-4-avatar",
        pseudo: "",
        rules: [
          { property: "border-radius", value: "50%" },
          { property: "height", value: "100px" },
          { property: "width", value: "auto" },
          { property: "margin", value: "1rem 0 0 0" },
        ],
      },
      {
        className: "quote-4-avatar",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-4-text1",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0.5rem 1rem" },
        ],
      },
      {
        className: "quote-4-text1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-4-text2",
        pseudo: "",
        rules: [
          { property: "font-style", value: "italic" },
          { property: "margin", value: "0 0 0 1rem" },
        ],
      },
      {
        className: "quote-4-text2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-4-text3",
        pseudo: "",
        rules: [
          { property: "display", value: "inline" },
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "font-style", value: "italic" },
          { property: "color", value: "rgba(100, 100, 100, 1)" },
        ],
      },
      {
        className: "quote-4-text3",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-4-quote-left",
        pseudo: "",
        rules: [
          { property: "display", value: "inline" },
          { property: "font-size", value: "2rem" },
          { property: "color", value: "rgba(100, 100, 100, 1)" },
        ],
      },
      {
        className: "quote-4-quote-left",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
