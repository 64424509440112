import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useTour } from "@reactour/tour";

import AddNewComponent from "../editPane/addNewComponent/AddNewComponent";
import SiteStyle from "../editPane/siteStyle/SiteStyle";
import EditComponent from "../editPane/editComponent/EditComponent";

import { LANGUAGES, translate } from "../../translations/translations";

import { setLangCode } from "../../actions/auth";

const EditPane = ({ auth: { langCode }, setLangCode }) => {
  const { setIsOpen } = useTour();

  return (
    <>
      <div className="d-flex justify-content-between p-3">
        {/* Browse templates */}
        <span
          className="editPane-btn trans-3 cursorPointer px-3 py-2 rounded-4"
          data-bs-toggle="modal"
          data-bs-target="#ModalBrowseTemplates"
          data-tour="browse-templates"
        >
          <i className="fa-solid fa-download me-2"></i> {translate("cEditPane.browseTemplates", false, null)}
        </span>
        {/* Browse image gallery */}
        <span
          className="editPane-btn trans-3 cursorPointer px-3 py-2 rounded-4 ms-4"
          data-bs-toggle="modal"
          data-bs-target="#ModalImageGallery"
          data-tour="open-img-gallery"
        >
          <i className="fa-solid fa-images me-2"></i> {translate("cEditPane.openImgGallery", false, null)}
        </span>
        {/* Settings */}
        <div className="nav-item dropdown navbar-icon ms-auto d-flex align-items-center">
          {/* <img
            src={`/assets/img/flags/${langCode}.png`}
            alt={LANGUAGES[langCode]}
            id="navbarDropdown_language"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            title={translate("cEditPane.selectLanguage", false, null)}
            className="cursorPointer"
          /> */}
          <i
            id="navbarDropdown_settings"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            title={translate("cEditPane.sbSettings", false, null)}
            className="fa-solid fa-gear cursorPointer textHover-primary trans-3"
            data-tour="sb-settings"
          ></i>
          <ul className="dropdown-menu p-0 fontSize09" aria-labelledby="navbarDropdown_settings">
            <p className="dropdown-item cursorPointer p-2 m-0" onClick={() => setIsOpen(true)}>
              {translate("cEditPane.takeTour", false, null)}
            </p>
            <p className="p-2 m-0">{translate("cEditPane.selectLanguage", false, null)}:</p>
            {Object.keys(LANGUAGES).map((lang) => (
              <li key={`lang_${lang}`}>
                <div className="dropdown-item cursorPointer p-2" onClick={() => setLangCode(lang)}>
                  <img src={`/assets/img/flags/${lang}.png`} alt={LANGUAGES[lang]} />
                  <span className="ms-2">{LANGUAGES[lang]}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="accordion accordion-flush trans-3" id="accordion-EditPane">
        <div className="accordion-item" data-tour="site-style">
          <h2 className="accordion-header" id="siteStyleSettings">
            <button
              className="accordion-button editPane-accordion collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-SiteStyleSettings"
              aria-expanded="false"
              aria-controls="collapse-SiteStyleSettings"
            >
              {translate("cEditPane.siteStyle", false, null)}
            </button>
          </h2>
          <div
            id="collapse-SiteStyleSettings"
            className="accordion-collapse collapse"
            aria-labelledby="siteStyleSettings"
            data-bs-parent="#accordion-EditPane"
          >
            <div className="accordion-body">
              <SiteStyle />
            </div>
          </div>
        </div>
        <div className="accordion-item" data-tour="add-component">
          <h2 className="accordion-header" id="addNewComponent">
            <button
              className="accordion-button editPane-accordion collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-AddNewComponent"
              aria-expanded="false"
              aria-controls="collapse-AddNewComponent"
            >
              {translate("cEditPane.addNewComponent", false, null)}
            </button>
          </h2>
          <div
            id="collapse-AddNewComponent"
            className="accordion-collapse collapse"
            aria-labelledby="addNewComponent"
            data-bs-parent="#accordion-EditPane"
          >
            <div className="accordion-body">
              <AddNewComponent />
            </div>
          </div>
        </div>
        <div className="accordion-item" data-tour="edit-component">
          <h2 className="accordion-header" id="editComponent">
            <button
              className="accordion-button editPane-accordion collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-EditComponent"
              aria-expanded="false"
              aria-controls="collapse-EditComponent"
            >
              {translate("cEditPane.editComponent", false, null)}
            </button>
          </h2>
          <div
            id="collapse-EditComponent"
            className="accordion-collapse collapse"
            aria-labelledby="editComponent"
            data-bs-parent="#accordion-EditPane"
          >
            <div className="accordion-body">
              <EditComponent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

EditPane.propTypes = {
  auth: PropTypes.object.isRequired,
  setLangCode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setLangCode })(EditPane);
