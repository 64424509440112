import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./formComponents/Select";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const AlignCol = ({ sb: { startStyles } }) => {
  return (
    <>
      <Select
        label={translate("cEditForms.horiAlignItemsInCol", false, null)}
        id={"alignCol"}
        options={[
          { val: "flex-start", label: translate("cEditForms.left", false, null) },
          { val: "center", label: translate("cEditForms.center", false, null) },
          { val: "flex-end", label: translate("cEditForms.right", false, null) },
          { val: "stretch", label: translate("cEditForms.stretch", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "alignCol")}
      />
    </>
  );
};

AlignCol.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(AlignCol);
