export const foodmenu1 = {
  id: "foodmenu-1",
  name: "Menu 1",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "menu-1-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "align-items-start", "justify-content-center"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-4", "col-lg-4", "menu-1-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h2",
                classes: ["menu-1-column-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Starters",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "block",
                htmlTagName: "div",
                classes: ["menu-1-wrapper-menu-items"],
                styles: [],
                attributes: [{ property: "data-name", value: "block" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["menu-1-menu-item"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Fines De Claires",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "French Fines De Claires Oysters Nr.3",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€25",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["menu-1-menu-item"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Wild Atlantics Fines De Claires",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Irish Wild Atlantics Fines De Claires Oysters Nr.3",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€30",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["menu-1-menu-item"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Dégustation des Huîtres",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "'Dégustation des Huîtres' Mix of 12 oysters",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€55",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-4", "col-lg-4", "menu-1-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h2",
                classes: ["menu-1-column-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Mains",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "block",
                htmlTagName: "div",
                classes: ["menu-1-wrapper-menu-items"],
                styles: [],
                attributes: [{ property: "data-name", value: "block" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["menu-1-menu-item"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Entrecôte",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Entrecôte with French fries, vegetables and Béarnaise sauce or Madeira gravy",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€25",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["menu-1-menu-item"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Gougère",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content:
                              "Gougère with cream of red beetroot, green asparagus, King boleet, pickled Chioggia beet, pistachio and Comté cheese",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€30",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["menu-1-menu-item"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lobster",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Whole lobster (700 grams) served lukewarm with green salad, parsley mayonaise and fries",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€55",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-4", "col-lg-4", "menu-1-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h2",
                classes: ["menu-1-column-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Desserts",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "block",
                htmlTagName: "div",
                classes: ["menu-1-wrapper-menu-items"],
                styles: [],
                attributes: [{ property: "data-name", value: "block" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["menu-1-menu-item"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Assortment of French cheeses",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Assortment of French cheeses",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€25",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["menu-1-menu-item"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Crème brûlée with vanilla and ice cream",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Crème brûlée with vanilla and ice cream",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€30",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["menu-1-menu-item"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Dessert of the day",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Dessert of the day",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-1-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€55",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.menu-1-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.menu-1-component", pseudo: "hover", rules: [] },
      {
        className: "menu-1-col",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      { className: "menu-1-col", pseudo: "hover", rules: [] },
      {
        className: "menu-1-menu-item",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      { className: "menu-1-menu-item", pseudo: "hover", rules: [] },
      {
        className: "menu-1-column-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
          { property: "font-weight", value: "bold" },
        ],
      },
      {
        className: "menu-1-column-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "menu-1-line1",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 0 0.25rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
          { property: "font-weight", value: "bold" },
          { property: "color", value: "rgba(108, 117, 125, 1)" },
        ],
      },
      {
        className: "menu-1-line1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "menu-1-line2",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.25rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
          { property: "font-style", value: "italic" },
          { property: "color", value: "rgba(108, 117, 125, 1)" },
        ],
      },
      {
        className: "menu-1-line2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "menu-1-line3",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 1rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
          { property: "color", value: "rgba(108, 117, 125, 1)" },
        ],
      },
      {
        className: "menu-1-line3",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
