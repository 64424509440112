import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const Width = ({ sb: { startStyles } }) => {
  return (
    <>
      <Slider
        label={translate("cEditForms.elementWidthMobile", false, null)}
        id={"widthMobile"}
        min={10}
        max={100}
        step={10}
        startValue={formGetStartValue(startStyles, "widthMobile")}
        tooltipText={translate("cEditForms.ttElementWidthMobile", false, null)}
      />
      <Slider
        label={translate("cEditForms.elementWidthTablet", false, null)}
        id={"widthTablet"}
        min={10}
        max={100}
        step={10}
        startValue={formGetStartValue(startStyles, "widthTablet")}
        tooltipText={translate("cEditForms.ttElementWidthTablet", false, null)}
      />
      <Slider
        label={translate("cEditForms.elementWidthDesktop", false, null)}
        id={"widthDesktop"}
        min={10}
        max={100}
        step={10}
        startValue={formGetStartValue(startStyles, "widthDesktop")}
        tooltipText={translate("cEditForms.ttElementWidthDesktop", false, null)}
      />
    </>
  );
};

Width.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Width);
