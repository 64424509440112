export const template15 = {
  id: "64234f7d51cffc1f0b5454a0",
  name: { en: "Hair salon", nl: "Kapper" },
  desc: { en: "Website for a hair salon", nl: "Een website voor een kapper" },
  categories: ["beauty"],
  sbPages: [
    {
      pageId: "7828f806-7cd5-461d-ab5e-9fc06072f438",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "Xj3EFC",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-Xj3EFC", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "VEPxgb",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "SCmUhG",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "NaOOzX",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-Xj3EFC"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-hair-specialists.png" },
                        { property: "alt", value: "The Hair Specialists" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "arhATq",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-Xj3EFC", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                    { property: "type", value: "button" },
                    { property: "aria-expanded", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "FxgHBT",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "qI673X",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "ogetO2",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ZlWP7L",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-Xj3EFC"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#about-us" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "About us",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "JjjSv0",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-Xj3EFC"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#services" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Our services",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ZaZHrh",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-Xj3EFC"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#appointment" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Make an appointment",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "eDrf4R",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-eDrf4R"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "about-us" },
            { property: "data-aos", value: "fade-left" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "rqCqkm",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-eDrf4R", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "We are the Hair Specialists",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "s6aaJL",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-s6aaJL"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-left" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Tybo4u",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-s6aaJL", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "B3dBDe",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-3-component-B3dBDe"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-left" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "tTOS5j",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-3-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics1" }],
              content: "",
              children: [
                {
                  childId: "MDkaNq",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-3-col-B3dBDe"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "qdTXvt",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-3-img-B3dBDe"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/hairsalon-10.jpg" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "pg3Euw",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-pg3Euw"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "services" },
            { property: "data-aos", value: "fade-right" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "AdEnZ1",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-pg3Euw", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Our services", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "gJhTnk",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-10-component-gJhTnk"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "QqtfNw",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "xsw0YN",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-gJhTnk"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade-right" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "CDTTfG",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-gJhTnk"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "tcNEkW",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-gJhTnk"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/hairsalon-2.jpg" },
                            { property: "alt", value: "Washing" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "JpiTk2",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-gJhTnk", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Washing",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "srYDSD",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-gJhTnk", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "PjzVC2",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-gJhTnk"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade-right" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "geaFcf",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-gJhTnk"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "foNbF6",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-gJhTnk"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/hairsalon-9.jpg" },
                            { property: "alt", value: "Coloring" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "y7Udg9",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-gJhTnk", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Coloring",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "j1auNY",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-gJhTnk", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "EKJTni",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-gJhTnk"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade-right" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "WXTeTR",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-gJhTnk"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "gUx6GJ",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-gJhTnk"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/hairsalon-4.jpg" },
                            { property: "alt", value: "Styling" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "QsuiyI",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-gJhTnk", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Styling",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "XzU72k",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-gJhTnk", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "JlfIhC",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-7-component-JlfIhC"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-right" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "v2QtRT",
              type: "",
              htmlTagName: "a",
              classes: ["basics-7-link-readmore-JlfIhC"],
              styles: [],
              attributes: [
                { property: "href", value: "#!" },
                { property: "data-target", value: "_self" },
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "button" },
                { property: "data-href", value: "/index.html#appointment" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Make an appointment!",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "wSv144",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-4-component-wSv144"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-up" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "UbtFsa",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-4-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "XVfZoo",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-first", "basics-4-col-wSv144"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "pfTF3y",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-4-img-wSv144"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/hairsalon-8.jpg" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "KQN9sl",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-4-col-wSv144"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "jHFuOE",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["basics-4-title-wSv144", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Your hair is in good hands",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "L5ziyQ",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-4-text-wSv144", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "Idwq8K",
                      type: "",
                      htmlTagName: "a",
                      classes: ["basics-4-link-readmore-wSv144"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                        { property: "data-href", value: "/index.html#services" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "See our services",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "yIAHBo",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-3-component-yIAHBo"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-down" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "OYeHe5",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-3-wrapper-yIAHBo"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "q1bzNK",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-3-overlaywrapper-yIAHBo"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "RoZHZ3",
                      type: "",
                      htmlTagName: "div",
                      classes: ["container", "parallax-3-containercontent-yIAHBo"],
                      styles: [],
                      attributes: [{ property: "data-parallax-containercontent", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "g8iGqi",
                          type: "",
                          htmlTagName: "div",
                          classes: ["parallax-3-content-yIAHBo", "w-100", "w-md-80", "w-lg-60"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "parallax3_content" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "AnpQnx",
                              type: "",
                              htmlTagName: "h1",
                              classes: ["parallax-3-title-yIAHBo", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Make an appointment now and get a free premium shampoo!",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "qzB8Wm",
                              type: "",
                              htmlTagName: "p",
                              classes: ["parallax-3-text-yIAHBo", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "XAl6Qc",
                              type: "",
                              htmlTagName: "a",
                              classes: ["parallax-3-button-yIAHBo"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-target", value: "_self" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "button" },
                                { property: "data-href", value: "/index.html#appointment" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Make an appointment",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "DoIcuG",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-8-component-DoIcuG"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "appointment" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "VbJZmG",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-8-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "vrFoCv",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-DoIcuG"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-DoIcuG"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-DoIcuG"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "name" },
                            { property: "placeholder", value: "Your name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-DoIcuG"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-DoIcuG"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-DoIcuG"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-DoIcuG"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-DoIcuG"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-DoIcuG"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-DoIcuG"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-DoIcuG"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-8-inputfield-DoIcuG"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-8-buttonwrapper-DoIcuG"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-8-button-DoIcuG"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "aTiy9e",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-aTiy9e"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "aqUh5L",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-aTiy9e", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Follow us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "T1Y0wi",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-T1Y0wi"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "nb0xu2",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-T1Y0wi", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Follow us for the latest hair trends and get inspired",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "kOxP7d",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "imggallery-1-component-kOxP7d"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "YrH4ba",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "imggallery-1-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "U1VHL4",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "imggallery-1-col-kOxP7d", "col-lg-4"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "OZgJs7",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-kOxP7d"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "a4D3F6",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-kOxP7d"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/hairsalon-7.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "bb7z32",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-kOxP7d"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "LxPiz8",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-kOxP7d", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "RulLJ6",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-kOxP7d", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "LmkQ1s",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "imggallery-1-col-kOxP7d", "col-lg-4"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "eSxNqj",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-kOxP7d"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "FGwKYO",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-kOxP7d"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/hairsalon-5.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "bqINBP",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-kOxP7d"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "ukftji",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-kOxP7d", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "KfuHZR",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-kOxP7d", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "yvKqxP",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "imggallery-1-col-kOxP7d", "col-lg-4"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "pj9Rs0",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-kOxP7d"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "TrE5AN",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-kOxP7d"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/hairsalon-6.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "tNLkhv",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-kOxP7d"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "XaVLG8",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-kOxP7d", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "LQPdP2",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-kOxP7d", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "OqqdQw",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "imggallery-1-col-kOxP7d", "col-lg-4"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "T6x5D2",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-kOxP7d"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "j33TWo",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-kOxP7d"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/hairsalon-6.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "UHLSki",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-kOxP7d"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "qTKX7H",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-kOxP7d", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "dIc6X3",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-kOxP7d", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "N8rDlf",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "imggallery-1-col-kOxP7d", "col-lg-4"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "FaZ64Y",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-kOxP7d"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "BqE10L",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-kOxP7d"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/hairsalon-7.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "pwcHDb",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-kOxP7d"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "Hb5g6w",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-kOxP7d", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "I3o3pm",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-kOxP7d", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "u3rA6u",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "imggallery-1-col-kOxP7d", "col-lg-4"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "BAYo3V",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-kOxP7d"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "G4mYEa",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-kOxP7d"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/hairsalon-5.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "H1Jk8t",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-kOxP7d"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "CGsONn",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-kOxP7d", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "qtPy7F",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-kOxP7d", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "TOW0SU",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-3-component-TOW0SU"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-left" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "UQmguT",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-3-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics1" }],
              content: "",
              children: [
                {
                  childId: "Ds3aCb",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-3-col-TOW0SU"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "YEOObs",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-3-img-TOW0SU"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-hair-specialists.png" },
                        { property: "alt", value: "The Hair Specialists" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "rmxo0O",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "footer-4-component-rmxo0O"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade-right" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "qrTnBH",
              type: "",
              htmlTagName: "div",
              classes: ["footer-4-wrapper-rmxo0O", "px-sm-0", "px-md-4"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "footer4_wrapper" },
              ],
              content: "",
              children: [
                {
                  childId: "oIsR7K",
                  type: "",
                  htmlTagName: "div",
                  classes: ["footer-4-line-rmxo0O", "mx-n3", "mx-md-0"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "footer4" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "rF7b1h",
                  type: "",
                  htmlTagName: "div",
                  classes: ["row", "align-items-top", "justify-content-center", "footer-4-row"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "sYwm9Q",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "fLaKX7",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-rmxo0O"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Phone",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "aH6jFM",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-rmxo0O"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "123 456 7890",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "kUUitb",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "Aib9Ym",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-rmxo0O"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "IXGD51",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-rmxo0O"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "email@site.com",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "WZZGC9",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "XAWArW",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-rmxo0O"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Follow me",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "ehkZim",
                          type: "block",
                          htmlTagName: "div",
                          classes: [],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "hEQPes",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-rmxo0O"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "ciFwNb",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-twitter"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "MVuPvQ",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-rmxo0O"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "JXwTxw",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-linkedin"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "J9ybMq",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-rmxo0O"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "ErjMR7",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-facebook-f"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "hPWjRE",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "JNQP80",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-rmxo0O"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "© 2022 By The Hair Specialists",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "64234a965ba82e43c86aaff1",
    },
  ],
  sbCustomCss: [
    {
      componentId: "Xj3EFC",
      classes: [
        {
          className: "scrolledpast-Xj3EFC.navbar-1-wrapper-Xj3EFC",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "rem" },
            { property: "padding-bottom", value: "rem" },
            { property: "background", value: "" },
          ],
        },
        { className: "scrolledpast-Xj3EFC .navbar-1-logo-Xj3EFC", pseudo: "", rules: [{ property: "max-height", value: "px" }] },
        {
          className: "navbar-1-wrapper-Xj3EFC",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "navbar-1-logo-Xj3EFC",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "50px" },
          ],
        },
        { className: "navbar-1-logo-Xj3EFC", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-Xj3EFC",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(229, 229, 229, 0)" },
            { property: "color", value: "rgba(55, 88, 209, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-Xj3EFC",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(178, 178, 178, 0)" },
            { property: "color", value: "rgba(83, 162, 253, 1)" },
          ],
        },
        {
          className: "navbar-1-link-Xj3EFC",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(70, 70, 70, 1)" },
            { property: "font-family", value: "var(--font-body)" },
            { property: "font-size", value: "1rem" },
            { property: "text-decoration", value: "none" },
            { property: "font-weight", value: "inherit" },
          ],
        },
        { className: "navbar-1-link-Xj3EFC", pseudo: "hover", rules: [{ property: "color", value: "rgba(55, 88, 209, 1)" }] },
      ],
    },
    {
      componentId: "eDrf4R",
      classes: [
        {
          className: "section.basics-1-component-eDrf4R",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-1-component-eDrf4R", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-eDrf4R",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-eDrf4R", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-eDrf4R",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(58, 91, 211, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-eDrf4R", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "s6aaJL",
      classes: [
        {
          className: "section.basics-2-component-s6aaJL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "2rem" },
          ],
        },
        { className: "section.basics-2-component-s6aaJL", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-s6aaJL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-s6aaJL", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-s6aaJL",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-s6aaJL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "B3dBDe",
      classes: [
        {
          className: "section.basics-3-component-B3dBDe",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-3-component-B3dBDe", pseudo: "hover", rules: [] },
        {
          className: "basics-3-col-B3dBDe",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
          ],
        },
        {
          className: "basics-3-img-B3dBDe",
          pseudo: "",
          rules: [
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "400px" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 14px 6px rgba(101, 110, 117, 0.68)" },
            { property: "border-radius", value: "1rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-3-img-B3dBDe",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 14px 6px rgba(101, 110, 117, 0.68)" },
          ],
        },
      ],
    },
    {
      componentId: "pg3Euw",
      classes: [
        {
          className: "section.basics-1-component-pg3Euw",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-pg3Euw", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-pg3Euw",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-pg3Euw", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-pg3Euw",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(58, 91, 211, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "4.5rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-pg3Euw", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "gJhTnk",
      classes: [
        {
          className: "section.card-10-component-gJhTnk",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-10-component-gJhTnk", pseudo: "hover", rules: [] },
        {
          className: "card-10-text-gJhTnk",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-10-text-gJhTnk", pseudo: "hover", rules: [] },
        {
          className: "card-10-avatar-gJhTnk",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "350px" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-avatar-gJhTnk",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-10-title-gJhTnk",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(58, 91, 211, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0.5rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-10-title-gJhTnk", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-10-wrapper-gJhTnk",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-items", value: "center" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-wrapper-gJhTnk",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-10-col-gJhTnk",
          pseudo: "",
          rules: [
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "1rem 0rem 0rem 0rem" },
          ],
        },
        { className: "card-10-col-gJhTnk", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "JlfIhC",
      classes: [
        {
          className: "section.basics-7-component-JlfIhC",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-7-component-JlfIhC", pseudo: "hover", rules: [] },
        {
          className: "basics-7-component-JlfIhC",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-7-component-JlfIhC", pseudo: "hover", rules: [] },
        {
          className: "basics-7-link-readmore-JlfIhC",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(55, 88, 209, 1)" },
            { property: "background", value: "rgba(55, 88, 209, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(58, 91, 211, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-7-link-readmore-JlfIhC",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(55, 88, 209, 1)" },
            { property: "border-color", value: "rgba(58, 91, 211, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "caw4ik",
      classes: [
        {
          className: "section.card-8-component-caw4ik",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-8-component-caw4ik", pseudo: "hover", rules: [] },
        { className: "card-8-row-caw4ik", pseudo: "", rules: [] },
        {
          className: "card-8-col-caw4ik",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(214, 214, 214, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-8-col-caw4ik", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }] },
        {
          className: "card-8-img-caw4ik",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "card-8-img-caw4ik", pseudo: "hover", rules: [] },
        {
          className: "card-8-title-caw4ik",
          pseudo: "",
          rules: [
            { property: "font-family", value: '"Montserrat"' },
            { property: "color", value: "rgba(58, 91, 211, 1)" },
            { property: "margin", value: "0 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-8-title-caw4ik", pseudo: "hover", rules: [] },
        {
          className: "card-8-desc-text-caw4ik",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "dSZzn3",
      classes: [
        {
          className: "section.basics-5-component-dSZzn3",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-5-component-dSZzn3", pseudo: "hover", rules: [] },
        {
          className: "basics-5-col-dSZzn3",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-5-img-dSZzn3",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "basics-5-img-dSZzn3", pseudo: "hover", rules: [] },
        {
          className: "basics-5-title-dSZzn3",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(58, 91, 211, 1)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "basics-5-title-dSZzn3", pseudo: "hover", rules: [] },
        {
          className: "basics-5-text-dSZzn3",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "basics-5-text-dSZzn3", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "wSv144",
      classes: [
        {
          className: "section.basics-4-component-wSv144",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-4-component-wSv144", pseudo: "hover", rules: [] },
        {
          className: "basics-4-col-wSv144",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-4-text-wSv144",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "basics-4-text-wSv144", pseudo: "hover", rules: [] },
        {
          className: "basics-4-title-wSv144",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(58, 91, 211, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-4-title-wSv144", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-4-link-readmore-wSv144",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(58, 91, 211, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(58, 91, 211, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-4-link-readmore-wSv144",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(58, 91, 211, 1)" },
            { property: "border-color", value: "rgba(58, 91, 211, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-4-img-wSv144",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "1rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-4-img-wSv144",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "yIAHBo",
      classes: [
        {
          className: "section.parallax-3-component-yIAHBo",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-3-component-yIAHBo", pseudo: "hover", rules: [] },
        {
          className: "parallax-3-component-yIAHBo",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-3-wrapper-yIAHBo",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/hairsalon-1.jpg")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-3-containercontent-yIAHBo",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "padding", value: "1.5rem 1.5rem 1.5rem 1.5rem" },
          ],
        },
        {
          className: "parallax-3-text-yIAHBo",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "margin", value: "1.5rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "parallax-3-text-yIAHBo", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-3-overlaywrapper-yIAHBo",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "parallax-3-content-yIAHBo",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-self", value: "start" },
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "1rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "parallax-3-content-yIAHBo",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "parallax-3-title-yIAHBo",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "4.5rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-3-title-yIAHBo", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }] },
        {
          className: "parallax-3-button-yIAHBo",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(40, 40, 40, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(40, 40, 40, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1.5rem 0rem 4.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "parallax-3-button-yIAHBo",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(40, 40, 40, 1)" },
            { property: "border-color", value: "rgba(40, 40, 40, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "aTiy9e",
      classes: [
        {
          className: "section.basics-1-component-aTiy9e",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-aTiy9e", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-aTiy9e",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-aTiy9e", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-aTiy9e",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(58, 91, 211, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-aTiy9e", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "T1Y0wi",
      classes: [
        {
          className: "section.basics-2-component-T1Y0wi",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-T1Y0wi", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-T1Y0wi",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-T1Y0wi", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-T1Y0wi",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-T1Y0wi", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "kOxP7d",
      classes: [
        {
          className: "section.imggallery-1-component-kOxP7d",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.imggallery-1-component-kOxP7d", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-col-kOxP7d",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "stretch" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "imggallery-1-img-kOxP7d",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "imggallery-1-img-kOxP7d", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-title-kOxP7d",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "imggallery-1-title-kOxP7d", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-text-kOxP7d",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(230, 230, 230, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "imggallery-1-text-kOxP7d", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-wrapper-kOxP7d",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(55, 88, 209, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.2)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "imggallery-1-wrapper-kOxP7d",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(55, 88, 209, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "imggallery-1-wrapper-kOxP7d",
          pseudo: "hover .imggallery-1-overlay-content-kOxP7d",
          rules: [
            { property: "visibility", value: "visible" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "imggallery-1-overlay-content-kOxP7d",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "visibility", value: "hidden" },
            { property: "opacity", value: "0" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "background", value: "rgba(83, 162, 253, 0.3)" },
          ],
        },
        { className: "imggallery-1-overlay-content-kOxP7d", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "TOW0SU",
      classes: [
        {
          className: "section.basics-3-component-TOW0SU",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-3-component-TOW0SU", pseudo: "hover", rules: [] },
        {
          className: "basics-3-col-TOW0SU",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
          ],
        },
        {
          className: "basics-3-img-TOW0SU",
          pseudo: "",
          rules: [
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "400px" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "margin", value: "4.5rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-3-img-TOW0SU",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "DCE6DR",
      classes: [
        {
          className: "section.contactus-6-component-DCE6DR",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-6-component-DCE6DR", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-col-DCE6DR",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-6-contacttitle-DCE6DR",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
          ],
        },
        { className: "contactus-6-contacttitle-DCE6DR", pseudo: "hover", rules: [] },
        { className: "contactus-6-contacttext-DCE6DR", pseudo: "", rules: [{ property: "margin", value: "0 0 2rem 0" }] },
        { className: "contactus-6-contacttext-DCE6DR", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-subtitle-DCE6DR",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
          ],
        },
        { className: "contactus-6-subtitle-DCE6DR", pseudo: "hover", rules: [] },
        { className: "contactus-6-subtext-DCE6DR", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        { className: "contactus-6-subtext-DCE6DR", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-button-DCE6DRwrapper-DCE6DR",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-6-button-DCE6DRwrapper-DCE6DR", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-button-DCE6DR",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "font-weight", value: "bold" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-6-button-DCE6DR",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
        {
          className: "contactus-6-inputfield-DCE6DR",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(58, 91, 211, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(58, 91, 211, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-6-inputfield-DCE6DR",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(58, 91, 211, 1)" },
            { property: "border-color", value: "rgba(58, 91, 211, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(58, 91, 211, 0.2)" },
          ],
        },
        {
          className: "contactus-6-inputfield-DCE6DR",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-6-inputfield-DCE6DR", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "DoIcuG",
      classes: [
        {
          className: "section.contactus-8-component-DoIcuG",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-8-component-DoIcuG", pseudo: "hover", rules: [] },
        {
          className: "contactus-8-col-DoIcuG",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-8-buttonwrapper-DoIcuG",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "contactus-8-buttonwrapper-DoIcuG",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-8-button-DoIcuG",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(58, 91, 211, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(58, 91, 211, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "contactus-8-button-DoIcuG",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(58, 91, 211, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "contactus-8-inputwrapper-DoIcuG",
          pseudo: "",
          rules: [{ property: "margin", value: "0 0 1rem 0" }],
        },
        {
          className: "contactus-8-inputfield-DoIcuG",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "border-style", value: "none none solid none" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-8-inputfield-DoIcuG",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(106, 115, 122, 1)" },
            { property: "border-color", value: "rgba(58, 91, 211, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-8-inputfield-DoIcuG",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "contactus-8-inputfield-DoIcuG",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-8-label-DoIcuG",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        {
          className: "contactus-8-label-DoIcuG",
          pseudo: "hover",
          rules: [],
        },
      ],
    },
    {
      componentId: "rmxo0O",
      classes: [
        {
          className: "section.footer-4-component-rmxo0O",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.footer-4-component-rmxo0O", pseudo: "hover", rules: [] },
        { className: "footer-4-wrapper-rmxo0O", pseudo: "", rules: [] },
        {
          className: "footer-4-text-rmxo0O",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "footer-4-title-rmxo0O",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(58, 91, 211, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "footer-4-social-link-rmxo0O",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(55, 88, 209, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 0.5rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "align-self", value: "start" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        {
          className: "footer-4-social-link-rmxo0O",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(83, 162, 253, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "footer-4-social-link-rmxo0O", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-4-line-rmxo0O",
          pseudo: "",
          rules: [
            { property: "border-style", value: "solid none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(83, 162, 253, 1)" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-4-line-rmxo0O", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(83, 162, 253, 1)" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Quicksand:300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Poppins:100,300,700&display=swap",
    fontHeadersName: "Quicksand",
    fontHeadersWeight: "700",
    fontBodyName: "Poppins",
    fontBodyWeight: "300",
    color1: "rgba(58, 91, 211, 1)",
    color2: "rgba(86, 162, 254, 1)",
    color3: "rgba(43, 43, 43, 1)",
    color4: "rgba(135, 135, 135, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
