import { translate } from "../translations/translations";

export const ATTR_SB_TYPE = "data-sbtype";
export const TAGNAME_TEXT_NODE = "textNode";
// Component buttons
export const EDIT_COMPONENT_ACTIONS_TYPE = "component-actions";
export const EDIT_COMPONENT_ACTIONS_CLASS = "componentActions";
export const COMPONENT_MOVEUP_TYPE = "component-moveup";
export const COMPONENT_MOVEUP_CLASS = "moveup";
export const COMPONENT_MOVEDOWN_TYPE = "component-movedown";
export const COMPONENT_MOVEDOWN_CLASS = "movedown";
export const COMPONENT_EDIT_TYPE = "component-edit";
export const COMPONENT_EDIT_CLASS = "edit";
export const COMPONENT_DELETE_TYPE = "component-delete";
export const COMPONENT_DELETE_CLASS = "delete";
// Element buttons
export const EDIT_ELEMENT_ACTIONS_TYPE = "element-actions";
export const EDIT_ELEMENT_ACTIONS_CLASS = "elementActions";
export const ELEMENT_DUPLICATE_TYPE = "element-duplicate";
export const ELEMENT_DUPLICATE_CLASS = "duplicate";
export const ELEMENT_EDIT_TYPE = "element-edit";
export const ELEMENT_EDIT_CLASS = "edit";
export const ELEMENT_DELETE_TYPE = "element-delete";
export const ELEMENT_DELETE_CLASS = "delete";
// Col reorder buttons
export const COL_REORDER_TYPE = "col-reorder";
export const COL_REORDER_CLASS = "reorder";
// Both component & element buttons:
export const EDIT_COMPONENT_ACTION_ICON_TYPE = "edit-action";
// Forms / input fields
export const EDIT_INPUTFIELD_ACTIONS_TYPE = "inputfield-actions";
export const EDIT_INPUTFIELD_ACTIONS_CLASS = "inputfieldActions";
export const INPUTFIELD_MOVEUP_TYPE = "inputfield-moveup";
export const INPUTFIELD_MOVEUP_CLASS = "moveup";
export const INPUTFIELD_MOVEDOWN_TYPE = "inputfield-movedown";
export const INPUTFIELD_MOVEDOWN_CLASS = "movedown";
export const INPUTFIELD_DUPLICATE_TYPE = "inputfield-duplicate";
export const INPUTFIELD_DUPLICATE_CLASS = "duplicate";
export const INPUTFIELD_DELETE_TYPE = "inputfield-delete";
export const INPUTFIELD_DELETE_CLASS = "delete";

// export const XXX = "AAA"
// export const XXX = "AAA"
// export const XXX = "AAA"

// Site color var names
export const SITE_COLOR_VAR_NAMES = ["color1", "color2", "color3", "color4", "color5", "color6", "color7", "color8", "color9", "color10"];

// Font weights
export const FONT_WEIGHT_VALUES = ["100", "300", "regular", "700"];
export const FONT_WEIGHT_CSS = ["100", "300", "400", "700"];
export const FONT_WEIGHT_NAMES = [
  translate("lGeneralVars.thin", false, null),
  translate("lGeneralVars.light", false, null),
  translate("lGeneralVars.normal", false, null),
  translate("lGeneralVars.bold", false, null),
];

// mySatonda return URL
// export const MYSATONDA_RETURN_URL = "http://localhost:3000/sitebuilder/manage";
export const MYSATONDA_RETURN_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:3000/sitebuilder/manage" : "https://my.satonda.com/sitebuilder/manage";

// axios
export const POST_CONFIG = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const API_URL = "https://api.satonda.com/api"; // No trailing "/"
// export const API_URL = "http://localhost:5000/api"; //  // No trailing "/"
