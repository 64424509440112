export const card3 = {
  id: "card-3",
  name: "Card 3",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "card-3-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "card-3-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "card-3-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card3" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-3-overlay-wrapper"],
                styles: [],
                attributes: [{ property: "data-overlaywrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-3-avatar"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/img/profilePic1.jpg" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-3-desc"],
                    styles: [],
                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h4",
                        classes: ["card-3-title"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Name", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-3-subtitle"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-3-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h6",
                        classes: ["card-3-overlay-title"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Connect with me",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "block",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [{ property: "data-name", value: "block" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-solid", "fa-envelope"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-twitter"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-linkedin"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-facebook-f"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-3-overlay-subtitle"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non autem nisi, ex in enim tempora.",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "card-3-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card3" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-3-overlay-wrapper"],
                styles: [],
                attributes: [{ property: "data-overlaywrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-3-avatar"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/img/profilePic1.jpg" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-3-desc"],
                    styles: [],
                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h4",
                        classes: ["card-3-title"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Name", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-3-subtitle"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-3-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h6",
                        classes: ["card-3-overlay-title"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Connect with me",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "block",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [{ property: "data-name", value: "block" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-solid", "fa-envelope"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-twitter"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-linkedin"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-facebook-f"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-3-overlay-subtitle"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non autem nisi, ex in enim tempora.",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "card-3-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card3" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-3-overlay-wrapper"],
                styles: [],
                attributes: [{ property: "data-overlaywrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-3-avatar"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/img/profilePic1.jpg" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-3-desc"],
                    styles: [],
                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h4",
                        classes: ["card-3-title"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Name", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-3-subtitle"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-3-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h6",
                        classes: ["card-3-overlay-title"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Connect with me",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "block",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [{ property: "data-name", value: "block" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-solid", "fa-envelope"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-twitter"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-linkedin"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-facebook-f"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-3-overlay-subtitle"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non autem nisi, ex in enim tempora.",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "card-3-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card3" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-3-overlay-wrapper"],
                styles: [],
                attributes: [{ property: "data-overlaywrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-3-avatar"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/img/profilePic1.jpg" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-3-desc"],
                    styles: [],
                    attributes: [{ property: "data-elementgetter1", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h4",
                        classes: ["card-3-title"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Name", children: [] },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-3-subtitle"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-3-overlay-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h6",
                        classes: ["card-3-overlay-title"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Connect with me",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "block",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [{ property: "data-name", value: "block" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-solid", "fa-envelope"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-twitter"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-linkedin"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-3-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-facebook-f"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-3-overlay-subtitle"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non autem nisi, ex in enim tempora.",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.card-3-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.card-3-component", pseudo: "hover", rules: [] },
      {
        className: "card-3-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
        ],
      },
      {
        className: "card-3-overlay-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0.4rem" },
          { property: "padding", value: "0 0 0 0" },
          { property: "width", value: "100%" },
        ],
      },
      { className: "card-3-overlay-wrapper", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" }] },
      {
        className: "card-3-overlay-wrapper",
        pseudo: "hover .card-3-overlay-content",
        rules: [
          { property: "visibility", value: "visible" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "card-3-overlay-content",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "start" },
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "background", value: "rgba(0, 0, 0, 0.7)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "visibility", value: "hidden" },
          { property: "opacity", value: "0" },
          { property: "padding", value: "2rem 1rem 0 1rem" },
        ],
      },
      {
        className: "card-3-overlay-wrapper",
        pseudo: "hover .card-3-desc",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(250, 250, 250, 1)" },
        ],
      },
      {
        className: "card-3-overlay-wrapper",
        pseudo: "hover .card-3-title",
        rules: [{ property: "color", value: "rgba(250, 250, 250, 1)" }],
      },
      {
        className: "card-3-overlay-wrapper",
        pseudo: "hover .card-3-subtitle",
        rules: [{ property: "color", value: "rgba(250, 250, 250, 1)" }],
      },
      {
        className: "card-3-avatar",
        pseudo: "",
        rules: [
          { property: "width", value: "100%" },
          { property: "height", value: "auto" },
        ],
      },
      {
        className: "card-3-avatar",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-3-desc",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 0 1rem 0" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "z-index", value: "9" },
          { property: "position", value: "relative" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "card-3-desc",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-3-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "text-align", value: "center" },
          { property: "color", value: "rgba(50, 50, 50, 1)" },
        ],
      },
      {
        className: "card-3-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-3-overlay-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "margin", value: "1rem 0 1rem 0" },
        ],
      },
      {
        className: "card-3-overlay-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-3-overlay-subtitle",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(200, 200, 200, 1)" },
          { property: "font-size", value: "13px" },
          { property: "text-align", value: "center" },
          { property: "margin", value: "1rem 0 1rem 0" },
        ],
      },
      {
        className: "card-3-overlay-subtitle",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-3-subtitle",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "color", value: "rgba(50, 50, 50, 1)" },
        ],
      },
      {
        className: "card-3-subtitle",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-3-social-link",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0.5rem 0 0" },
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "text-decoration", value: "none" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      { className: "card-3-social-link", pseudo: "hover", rules: [{ property: "color", value: "rgba(229, 42, 5, 1)" }] },
      { className: "card-3-social-link", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
    ],
  },
};
