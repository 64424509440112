export const footer1 = {
  id: "footer-1",
  name: "Footer 1",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "footer-1-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "footer-1-row"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "footer-1-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["footer-1-title1"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Company name", children: [] },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["footer-1-text1"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content:
                      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod laborum libero vitae maxime distinctio optio, saepe nemo? Modi nam adarchitecto repudiandae, odit similique soluta!",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "footer-1-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h6",
                classes: ["footer-1-title2"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "USEFUL LINKS", children: [] },
                ],
              },
              {
                childId: "",
                type: "block",
                htmlTagName: "ul",
                classes: ["footer-1-list-wrapper"],
                styles: [],
                attributes: [{ property: "data-name", value: "block" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "li",
                    classes: ["footer-1-list-item"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "footer1_list" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "a",
                        classes: ["footer-1-list-link"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_blank" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-caret-right"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["footer-1-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Home",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "li",
                    classes: ["footer-1-list-item"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "footer1_list" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "a",
                        classes: ["footer-1-list-link"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_blank" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-caret-right"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["footer-1-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "About",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "li",
                    classes: ["footer-1-list-item"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "footer1_list" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "a",
                        classes: ["footer-1-list-link"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_blank" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-caret-right"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["footer-1-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Services",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "li",
                    classes: ["footer-1-list-item"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "footer1_list" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "a",
                        classes: ["footer-1-list-link"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_blank" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-caret-right"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["footer-1-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Team",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "li",
                    classes: ["footer-1-list-item"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "footer1_list" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "a",
                        classes: ["footer-1-list-link"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_blank" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-caret-right"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["footer-1-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Clients",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "footer-1-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h6",
                classes: ["footer-1-title2"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "OUR NEWSLETTER",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["footer-1-text1"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content:
                      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod laborum libero vitae maxime distinctio optio, saepe nemo? Modi nam adarchitecto repudiandae, odit similique soluta!",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["d-flex", "align-items-end"],
                styles: [],
                attributes: [
                  { property: "data-sbform", value: "true" },
                  { property: "data-sbformaction", value: "" },
                  { property: "data-sbformmsg", value: "Your message has been received!" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["w-100", "w-md-60", "footer-1-subscribeinputwrapper"],
                    styles: [],
                    attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "label",
                        classes: ["form-control", "footer-1-subscribelabel"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "inputlabel" },
                          { property: "data-editable", value: "true" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Your email",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "input",
                        classes: ["form-control", "footer-1-subscribeinputfield"],
                        styles: [],
                        attributes: [
                          { property: "type", value: "email" },
                          { property: "name", value: "email" },
                          { property: "placeholder", value: "Your email address" },
                          { property: "data-name", value: "inputfield" },
                          { property: "data-required", value: "true" },
                          { property: "data-editable", value: "true" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["footer-1-subscribebuttonwrapper", "w-100", "w-md-40"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "button",
                        classes: ["footer-1-subscribe-button"],
                        styles: [],
                        attributes: [
                          { property: "data-sbformbtn", value: "true" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Subscribe",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "footer-1-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h6",
                classes: ["footer-1-title2"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "CONTACT US", children: [] },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["footer-1-text2"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Street name and number",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["footer-1-text2"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Postal code and city",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["footer-1-text2"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Country", children: [] },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["footer-1-text2"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "strong",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Phone: ", children: [] },
                    ],
                  },
                  { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "100 200 3000", children: [] },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["footer-1-text2"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "strong",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Email: ", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "email@address.com",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "block",
                htmlTagName: "div",
                classes: ["footer-1-socialwrapper"],
                styles: [],
                attributes: [{ property: "data-name", value: "block" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "a",
                    classes: ["footer-1-social-link"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_blank" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-envelope"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "a",
                    classes: ["footer-1-social-link"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_blank" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-brands", "fa-twitter"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "a",
                    classes: ["footer-1-social-link"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_blank" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-brands", "fa-linkedin"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "a",
                    classes: ["footer-1-social-link"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_blank" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-brands", "fa-facebook-f"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.footer-1-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.footer-1-component", pseudo: "hover", rules: [] },
      { className: "footer-1-col", pseudo: "", rules: [{ property: "padding", value: "1rem 1rem 1rem 1rem" }] },
      {
        className: "footer-1-title1",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "font-size", value: "13px" },
        ],
      },
      {
        className: "footer-1-title1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-1-text1",
        pseudo: "",
        rules: [
          { property: "font-size", value: "13px" },
          { property: "text-align", value: "justify" },
        ],
      },
      {
        className: "footer-1-text1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-1-text2",
        pseudo: "",
        rules: [
          { property: "font-size", value: "13px" },
          { property: "margin", value: "0 0 0 0" },
        ],
      },
      {
        className: "footer-1-text2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-1-title2",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0.5rem 0" },
        ],
      },
      {
        className: "footer-1-title2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-1-list-wrapper",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "list-style", value: "none" },
        ],
      },
      {
        className: "footer-1-list-link",
        pseudo: "",
        rules: [
          { property: "border-style", value: "none none solid none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(108, 117, 125, 1)" },
          { property: "padding", value: "0.25rem 0 0.25rem 0" },
          { property: "text-decoration", value: "none" },
          { property: "color", value: "rgba(0, 0, 0, 1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-radius", value: "0rem" },
        ],
      },
      {
        className: "footer-1-list-link",
        pseudo: "hover",
        rules: [
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(108, 117, 125, 1)" },
        ],
      },
      {
        className: "footer-1-list-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0 0.5rem" },
          { property: "font-size", value: "13px" },
        ],
      },
      {
        className: "footer-1-list-text",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-1-social-link",
        pseudo: "",
        rules: [
          { property: "margin", value: "0.5rem 0.25rem 0 0" },
          { property: "background", value: "rgba(108, 117, 125, 1)" },
          { property: "height", value: "35px" },
          { property: "aspect-ratio", value: "1" },
          { property: "display", value: "inline-flex" },
          { property: "justify-content", value: "center" },
          { property: "align-items", value: "center" },
          { property: "border-radius", value: "50%" },
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "text-decoration", value: "none" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "footer-1-social-link",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(250, 250, 250, 1)" },
        ],
      },
      { className: "footer-1-social-link", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      {
        className: "footer-1-subscribebuttonwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "footer-1-subscribebuttonwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-1-subscribe-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "0.25rem 0.25rem 0.25rem 0.25rem" },
          { property: "width", value: "100%" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
          { property: "font-size", value: "0.8rem" },
        ],
      },
      {
        className: "footer-1-subscribe-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "footer-1-subscribeinputwrapper",
        pseudo: "",
        rules: [{ property: "margin", value: "0 0 0 0" }],
      },
      {
        className: "footer-1-subscribeinputfield",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(206, 212, 218, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.2s ease" },
          { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
          { property: "font-size", value: "0.8rem" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
          { property: "outline", value: "0" },
        ],
      },
      {
        className: "footer-1-subscribeinputfield",
        pseudo: "focus",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
        ],
      },
      {
        className: "footer-1-subscribeinputfield",
        pseudo: ":placeholder",
        rules: [
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "footer-1-subscribeinputfield",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-1-subscribelabel",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.25rem 0" },
          { property: "display", value: "none" },
        ],
      },
      {
        className: "footer-1-subscribelabel",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
