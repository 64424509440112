export const lTemplates = {
  descTemplate1: {
    en: "A personal resume site",
    nl: "Een website voor een persoonlijk CV",
  },
  descTemplate2: {
    en: "A professional services template",
    nl: "Template voor een aanbieder van professionele services",
  },
  descTemplate3: {
    en: "[Description]",
    nl: "[Description]",
  },
  descTemplate4: {
    en: "[Description]",
    nl: "[Description]",
  },
  descTemplate5: {
    en: "[Description]",
    nl: "[Description]",
  },
  descTemplate6: {
    en: "[Description]",
    nl: "[Description]",
  },
  descTemplate7: {
    en: "[Description]",
    nl: "[Description]",
  },
  descTemplate8: {
    en: "Construction company 1 pager",
    nl: "Bouwbedrijf (1 pagina)",
  },
  descTemplate9: {
    en: "Tech company 1 pager",
    nl: "Techbedrijf (1 pagina)",
  },
  descTemplate10: {
    en: "Consulting company 1 pager",
    nl: "Adviesbedrijf (1 pagina)",
  },
  descTemplate11: {
    en: "Coaching 1 pager",
    nl: "Coaching (1 pagina)",
  },
  descTemplate12: {
    en: "Conference 1 pager",
    nl: "Conferentie (1 pagina)",
  },
  descTemplate13: {
    en: "Consulting 1 pager",
    nl: "Advies (1 pagina)",
  },
  descTemplate14: {
    en: "Dog hotel 1 pager",
    nl: "Hondenhotel (1 pagina)",
  },
  descTemplate15: {
    en: "Hair salon 1 pager",
    nl: "Kapsalon (1 pagina)",
  },
  descTemplate16: {
    en: "Car garage 1 pager",
    nl: "Autogarage (1 pagina)",
  },
  // descTemplate1: {
  //   en: "yyyyyyyy",
  //   nl: "zzzzzzzzz",
  // },
};
