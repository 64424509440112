import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ColorPicker from "./formComponents/ColorPicker";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const OverlayColor = ({ sb: { startStyles } }) => {
  return (
    <>
      <ColorPicker
        label={translate("cEditForms.overlayColor", false, null)}
        id={"overlayColor"}
        startValue={formGetStartValue(startStyles, "overlayColor")}
        tooltipText={translate("cEditForms.ttOverlayColor", false, null)}
      />
    </>
  );
};

OverlayColor.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(OverlayColor);
