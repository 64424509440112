import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Custom from "./formComponents/Custom";
import Divider from "./formComponents/Divider";

import { getTargetElement } from "../../../../lib/domFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { copyComponentToOtherPage } from "../../../../actions/sb";
import { translate } from "../../../../translations/translations";

const ElementSettings = ({ sb: { selectedElement, sbPages, activePageId }, copyComponentToOtherPage }) => {
  const ElementId = () => {
    const [value, setValue] = useState("");

    useEffect(() => {
      let target = getTargetElement(selectedElement);
      setValue(target === null ? "" : target.id);
    }, [selectedElement]);

    const onType = (val) => {
      // 1st char needs to be a letter
      // no spaces/special chars
      setValue(val.replace(/^\d/, "").replace(/[^A-Za-z0-9_-]/g, ""));
    };

    return (
      <>
        <input
          type="text"
          className="form-control form-control-sm"
          id={`${EDIT_FORM_ID_PREFIX}elementId`}
          value={value}
          onChange={(e) => onType(e.target.value)}
        />
      </>
    );
  };

  const CopyComponentAcrossPages = () => {
    const [pageIdToCopyTo, setPageIdToCopyTo] = useState("");

    return (
      <>
        <div className="input-group input-group-sm">
          <select className="form-select form-select-sm" value={pageIdToCopyTo} onChange={(e) => setPageIdToCopyTo(e.target.value)}>
            <option value="">{translate("cEditForms.selectPage", false, null)}</option>
            {sbPages
              .filter((page) => page.pageId !== activePageId)
              .map((page) => (
                <option key={page.pageId} value={page.pageId}>
                  {page.pageLink}
                </option>
              ))}
          </select>
          <button
            className="btn btn-primary"
            disabled={pageIdToCopyTo === ""}
            onClick={() => copyComponentToOtherPage(selectedElement.split("-"), pageIdToCopyTo)}
          >
            {translate("cEditForms.copy", false, null)}
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <Custom
        label={translate("cEditForms.elementId", false, null)}
        column={true}
        customComponent={ElementId}
        tooltipText={translate("cEditForms.ttElementId", false, null)}
      />
      {!selectedElement.includes("-") && (
        <>
          <Divider />
          <Custom
            label={translate("cEditForms.componentComponentToOtherPages", false, null)}
            column={true}
            customComponent={CopyComponentAcrossPages}
            tooltipText={translate("cEditForms.ttCopyComponent", false, null)}
          />
        </>
      )}
    </>
  );
};

ElementSettings.propTypes = {
  sb: PropTypes.object.isRequired,
  copyComponentToOtherPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, { copyComponentToOtherPage })(ElementSettings);
