export const lProductTour = {
  step1_intro: {
    en: "In this tour, we will walk you through the main areas of our sitebuilder to serve as a first introduction to its functionality.",
    nl: "Met deze tour lopen we uw door de belangrijkste delen van de sitebuilder, als een eerste introductie tot haar functionaliteiten.",
  },
  step2_menu: {
    en: "This is the menu area for the sitebuilder. Let's see what it consists of next.",
    nl: "Dit is het sitebuilder menu.",
  },
  step3_exit: {
    en: "Exit sitebuilder and go back to mySatonda. Make sure to save your project first.",
    nl: "Sluit sitebuilder en ga terug naar mySatonda. Sla uw wijzigingen op voordat u de sitebuilder sluit.",
  },
  step4_name: {
    en: "This shows the name of the project you're working on, the page name and the version of the page you're editing.",
    nl: "Dit is de naam van het project waarin u werkt en de naam en versie van de pagina die u op dit moment bewerkt.",
  },
  step5_siteOptions: {
    en: "Open this menu to set options for your overall website (site language, favicon and Google Analytics tracking code), select another page you want to edit, create a new page or page version, or remove page or page version.",
    nl: "Open dit menu om de instellingen van uw website (zoals taal, favicon en Google Analytics tracking code) bij te werken, de pagina die u wilt bewerken te selecteren, een nieuwe pagina of paginaversie te maken of een pagina/versie te verwijderen.",
  },
  step6_screenSize: {
    en: "Select various screen sizes (desktop, tablet or mobile phone) to see how your site looks like on different devices.",
    nl: "Bepaal de schermgrootte om uw website te kunnen bekijken zoals ie eruit ziet op verschillende apparaten (desktop, tablet of mobiele telefoon).",
  },
  step7_undoRedo: {
    en: "Undo a change or redo a prior undo",
    nl: "Veranderingen ongedaan maken of veranderingen terugzetten",
  },
  step8_save: {
    en: "Save your project",
    nl: "Project opslaan",
  },
  step9_editPane: {
    en: "In this menu, you will be able to select new components to add and edit existing ones. Let's walk through its different parts.",
    nl: "In dit menu kunt u nieuwe componenten toevoegen aan uw site en bestaande bijwerken.",
  },
  step10_browseTemplates: {
    en: "Browse available templates you can use as starting points to further customize to your needs.",
    nl: "Bekijk beschikbare templates die u kunt gebruiken als startpunt om verder te personaliseren.",
  },
  step11_imgGallery: {
    en: "Open your image gallery and add new images you want to use in this project.",
    nl: "Open de afbeeldingen gallerij en voeg nieuwe afbeeldingen toe die u wilt gebruiken in dit project.",
  },
  step12_appSettings: {
    en: "Set Sitebuilder settings, such as the language of the application.",
    nl: "Applicatie instellingen zoals de taal",
  },
  step13_styles: {
    en: "Set site-wide styles to ensure consistency across pages:",
    nl: "Bepaal stijl die toegepast worden door de gehele site:",
  },
  step13_colors: {
    en: "Site color palette: Set your standard colors to be used across your site",
    nl: "Kleurschema: Bepaal standaardkleuren die door de gehele site gebruikt worden",
  },
  step13_fonts: {
    en: "Site fonts: Set the fonts to be used for your text headers and normal text",
    nl: "Lettertypen: Bepaal de lettertypen voor koppen en normale tekst",
  },
  step14_component: {
    en: `A "component" is a collection of one or more "elements". "Building blocks" are simple components that you can use to have the most flexibility. Other components are more comprehensive collections to be further customized by you`,
    nl: `Een "component" is een verzameling van een of meerdere "elementen". "Bouwstenen" zijn simpele componenten die het meest flexibel zijn. De andere componenten zijn bredere verzamelingen van elementen, die verder gepersonaliseerd kunnen worden`,
  },
  step15_editComponent: {
    en: `This shows the available styles and settings that can be customized of the active component or element. In the editor area, you can click on an element or on the "edit" icon of the component to see which styles and settings can be edited. Each style has a tooltip that explains the options you have to edit it.`,
    nl: `Dit laat de beschikbare stijlen en instellingen zien van het actieve component of element die u kunt aanpassen. U kunt op een element of op een "bewerken" icoon klikken om te zien welke stijlen en instellingen bewerkbaar zijn. Elke stijl heeft een tooltip met verdere uitleg wat de bewerkingsopties zijn.`,
  },
  step16_editorCanvas: {
    en: "This is the area that shows the current version of your site as it looks on the screen size you selected.",
    nl: "Dit is de editor, waarin uw website wordt getoond hoe die er op dat moment uitzien afhankelijk van de schermgrootte die u geselecteerd heeft.",
  },
  step16_selectElement: {
    en: "You can click anywhere on the page to select that element. In the editor menu, it will show which styles can be edited for that element",
    nl: "U kunt op elke plek op de pagina klikken om een element te selecteren. In het menu aan de rechterkant zal worden getoond welke stijlen aangepast kunnen worden voor dat element",
  },
  step16_componentButtons: {
    en: "Component buttons",
    nl: "Componentknoppen",
  },
  step16_reorder1: {
    en: "Reorder",
    nl: "Verschuiven",
  },
  step16_reorder2: {
    en: "Click to move a component up or down within your page",
    nl: "Verschuif een component naar boven of beneden",
  },
  step16_edit1: {
    en: "Edit",
    nl: "Bewerken",
  },
  step16_edit2: {
    en: "Click to edit the component rather than a specific element",
    nl: "Bewerk de component ipv een speciefiek element binnen de component",
  },
  step16_delete1: {
    en: "Delete",
    nl: "Verwijderen",
  },
  step16_delete2: {
    en: "Click to remove the component from your page",
    nl: "Verwijder het component",
  },
  step16_elementButtons: {
    en: `Element buttons: Some elements are part of a "block", which is a collection of similar elements. You can use blocks to create multiple elements with similar style by clicking the "duplicate" button`,
    nl: `Elementknoppen: Sommige element maken onderdeel uit van een "block", wat een verzameling is van meerdere elementen. Alle elementen binnen een block hebben eenzelfde stijl. U kunt extra elementen toevoegen door de "dupliceren" knop`,
  },
  step16_elementRemove: {
    en: `within the element or remove the element by clicking the "remove" button`,
    nl: `binnen het element te klikken of een element te verwijderen door de "verwijderen" knop te klikken`,
  },
  //   Step 17
  step17_experiment: {
    en: "There are a lot of options available in this sitebuilder. Our suggestion is to get started and experiment with it to design a website that fits your needs!",
    nl: "Er zijn veel opties beschikbaar in de sitebuilder. Onze suggestie is om er mee te beginnen en te experimenteren! Daarmee zult u een website kunnen maken die perfect bij u past!",
  },
};
