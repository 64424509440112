export const cEditForms = {
  // BgGradientColor
  gradientColor: {
    en: "Gradient color",
    nl: "Kleurverloop",
  },
  tooltipGradientStart: {
    en: "Select the first gradient color you want to use as well as position the gradient will start (from 0 to 100). A higher starting position means that the gradient into the second color will start later. For example, putting it at 50, means that half of your background will be of the first color you select, after which it will start to transform into the second color.",
    nl: "Selecteer de startkleur en de positie wanneer het verloop begint (van 0 tot 100). Een hogere startpositie betekent dat het verloop naar de tweede kleur later zal beginnen. Bijvoorbeeld, een waarde van 50 betekent dat de helft van de achtergrond de eerste kleur heeft, waarna het verloopt richting de tweede kleur.",
  },
  selectColor: {
    en: "Select color",
    nl: "Selecteer kleur",
  },
  tooltipGradientEnd: {
    en: "Select the second gradient color you want to use as well as position the gradient will end (from 0 to 100). A lower ending position means that the gradient from the first color will end earlier. For example, putting it at 50, means that half of your background will be of the second color you select.",
    nl: "Selecteer de eindkleur en de positie wanneer het verloop stopt (van 0 tot 100). Een lagere eindpositie betekent dat het verloop van de eerste kleur sneller stopt. Bijvoorbeeld, een waarde van 50 betekent dat de helft van de achtergrond de tweede kleur heeft.",
  },
  gradientDirection: {
    en: "Gradient direction",
    nl: "Verlooprichting",
  },
  tooltipGradientDirection: {
    en: "Direction the gradient moves into, in degrees (0 to 359)",
    nl: "Richting waarin de kleuren verlopen, in grades (0 tot 359)",
  },
  explainGradient: {
    en: "A gradient background has two colors that transform into each other",
    nl: "Een achtergrond met kleurverloop heeft twee kleuren die in elkaar transformeren",
  },
  useSingleBg: {
    en: "Use a single background color",
    nl: "Gebruik een uniforme achtergrondskleur",
  },
  selectSingleBg: {
    en: "Select the single, uniform color you want to use as a background",
    nl: "Selecteer de uniforme kleur die u als achtergrond wilt gebruiken",
  },
  makeBgGradient: {
    en: "Make background gradient",
    nl: "Gebruik een kleurverloop als achtergrond",
  },
  // LinkDestTarget
  linkDest: {
    en: "Link destination",
    nl: "Link bestemming",
  },
  tooltipLinkDest: {
    en: "The webpage the link points to. Select an internal page or type an external link.",
    nl: "De website bestemming van de link. Selecteer een pagina van uw website of type een externe link",
  },
  notValidUrl: {
    en: "Not a valid URL",
    nl: "Geen geldige URL",
  },
  linkTarget: {
    en: "Link opening target",
    nl: "Link open doel",
  },
  currWindowTab: {
    en: "Current window",
    nl: "Huidige scherm",
  },
  newWindowTab: {
    en: "New tab/window",
    nl: "Nieuwe tab/scherm",
  },
  tooltipLinkTarget: {
    en: "Whether the link opens within the current tab/window or in a new tab/window",
    nl: "Bepaal dat de link in het huidige scherm opent of in een nieuwe tab/scherm",
  },
  // AlignCol
  horiAlignItemsInCol: {
    en: "Horizontally align items in column",
    nl: "Lijn items in kolom horizontaal uit",
  },
  left: {
    en: "Left",
    nl: "Links",
  },
  center: {
    en: "Center",
    nl: "Gecentreerd",
  },
  right: {
    en: "Right",
    nl: "Rechts",
  },
  stretch: {
    en: "Stretch",
    nl: "Uitvullen",
  },
  // AlignHori
  alignHori: {
    en: "Horizontally align item",
    nl: "Lijn item horizontaal uit",
  },
  // AlignRow
  alignColsVert: {
    en: "Vertically align columns",
    nl: "Lijn kolommen verticaal uit",
  },
  top: {
    en: "Top",
    nl: "Boven",
  },
  bottom: {
    en: "Bottom",
    nl: "Onder",
  },
  tooltipAlignColsVert: {
    en: "Set vertical alignment of all columns within the component",
    nl: "Zet de verticale uitlijning van alle kolommen in het component",
  },
  alignColHor: {
    en: "Horizontally align columns",
    nl: "Lijn kolommen horizontaal uit",
  },
  between: {
    en: "Between",
    nl: "Tussen",
  },
  around: {
    en: "Around",
    nl: "Omheen",
  },
  evenly: {
    en: "Evenly",
    nl: "Gelijkmatig",
  },
  tooltipAlignColsHor: {
    en: "Set horizontal alignment of all columns within the component",
    nl: "Zet de horizontale uitlining van alle kolommen in het component",
  },
  // Animate
  animationType: {
    en: "Animation type",
    nl: "Animatie type",
  },
  noAnimation: {
    en: "No animation",
    nl: "Geen animatie",
  },
  fadein: {
    en: "Fade in",
    nl: "Faseer in",
  },
  fadeinSlideUp: {
    en: "Fade in & slide up",
    nl: "Faseer in & glijd omhoog",
  },
  fadeinSlideDown: {
    en: "Fade in & slide down",
    nl: "Faseer in & glijd omlaag",
  },
  fadeinSlideLeft: {
    en: "Fade in & slide left",
    nl: "Faseer in & glijd links",
  },
  fadeinSlideRight: {
    en: "Fade in & slide right",
    nl: "Faseer in & glijd rechts",
  },
  fadeinSlideUpRight: {
    en: "Fade in & slide up/right",
    nl: "Faseer in & glijd rechts omhoog",
  },
  fadeinSlideUpLeft: {
    en: "Fade in & slide up/left",
    nl: "Faseer in & glijd links omhoog",
  },
  fadeinSlideDownRight: {
    en: "Fade in & slide down/right",
    nl: "Faseer in & glijd rechts omlaag",
  },
  fadeinSlideDownLeft: {
    en: "Fade in & slide down/left",
    nl: "Faseer in & glijd liks omlaag",
  },
  flipUp: {
    en: "Flip up",
    nl: "Draai omhoog",
  },
  flipDown: {
    en: "Flip down",
    nl: "Draai omlaag",
  },
  flipLeft: {
    en: "Flip left",
    nl: "Draai links",
  },
  flipRight: {
    en: "Flip right",
    nl: "Draai rechts",
  },
  slideUp: {
    en: "Slide up",
    nl: "Glijd omhoog",
  },
  slideDown: {
    en: "Slide down",
    nl: "Glijd omlaag",
  },
  slideLeft: {
    en: "Slide left",
    nl: "Glijd links",
  },
  slideRight: {
    en: "Slide right",
    nl: "Glijd rechts",
  },
  zoomin: {
    en: "Zoom in",
    nl: "Zoom in",
  },
  zoominUp: {
    en: "Zoom in - upwards",
    nl: "Zoom in omhoog",
  },
  zoominDown: {
    en: "Zoom in - downwards",
    nl: "Zoom in omlaag",
  },
  zoominLeft: {
    en: "Zoom in - leftwards",
    nl: "Zoom in links",
  },
  zoominRight: {
    en: "Zoom in - rightwards",
    nl: "Zoom in rechts",
  },
  zoomout: {
    en: "Zoom out",
    nl: "Zoom out",
  },
  zoomoutUp: {
    en: "Zoom out - upwards",
    nl: "Zoom out omhoog",
  },
  zoomoutDown: {
    en: "Zoom out - downwards",
    nl: "Zoom out omlaag",
  },
  zoomoutLeft: {
    en: "Zoom out - leftwards",
    nl: "Zoom out links",
  },
  zoomoutRight: {
    en: "Zoom out - rightwards",
    nl: "Zoom out rechts",
  },
  tooltipAnimationType: {
    en: "Type of animation when component in scrolled into view",
    nl: "Type van de animatie wanneer het component in beeld van de bezoeker komt",
  },
  repeat: {
    en: "Repeat",
    nl: "Herhaling",
  },
  once: {
    en: "Once",
    nl: "Eenmalig",
  },
  tooltipRepeat: {
    en: "Specify whether to repeat the animation if the visitor scrolls down the same page multiple times",
    nl: "Bepaal dat de animatie eenmalig plaatsvindt wanneer de bezoeker voor de eerste keer het component in beeld scrollt, of wordt herhaald als de bezoeker meerdere keren door dezelfde pagina scrollt",
  },
  delaySeconds: {
    en: "Delay (seconds)",
    nl: "Vertraging (seconden)",
  },
  tooltipDelay: {
    en: "Time before animation starts after visitor has scrolled the component into view",
    nl: "De tijd voordat de animatie start wanneer de bezoeker het component in beeld krijgt",
  },
  durationSeconds: {
    en: "Duration (seconds)",
    nl: "Duur (seconden)",
  },
  tooltipDuration: {
    en: "The duration of the animation",
    nl: "De duur van de animatie",
  },
  staggeredDelay: {
    en: "Staggered delay (seconds)",
    nl: "Gespreide vertraging (seconden)",
  },
  tooltipStaggeredDelay: {
    en: "For components that consist of multiple elements, you can specify the time between when each elements gets animated",
    nl: "Als het component bestaat uit meerdere elementen, kunt u de vertraging tussen wanneer elk element wordt geanimeerd aangeven",
  },
  // BackToTop
  btp_btnPosition: {
    en: "Button position",
    nl: "Knop positie",
  },
  topLeft: {
    en: "Top left",
    nl: "Linksboven",
  },
  topRight: {
    en: "Top right",
    nl: "Rechtsboven",
  },
  bottomLeft: {
    en: "Bottom left",
    nl: "Linksonder",
  },
  bottomRight: {
    en: "Bottom right",
    nl: "Rechtsonder",
  },
  btp_tooltipBtnPos: {
    en: "Position of the button",
    nl: "De positie van de knop op de pagina",
  },
  btp_btnSize: {
    en: "Button size",
    nl: "Knop grootte",
  },
  btp_tooltipBtnSize: {
    en: "Size of the button",
    nl: "De grootte van de knop",
  },
  btp_iconSize: {
    en: "Icon size",
    nl: "Icoon grootte",
  },
  btp_tooltipIconSize: {
    en: "Size of the icon within the button",
    nl: "De grootte van de icoon binnen de knop",
  },
  btp_iconColor: {
    en: "Icon color",
    nl: "Icoon kleur",
  },
  btp_tooltipIconColor: {
    en: "Color of the icon in the button",
    nl: "De kleur van de icoon binnen de knop",
  },
  btp_iconColorHover: {
    en: "Icon color on hover",
    nl: "Icoon kleur wanneer muis over",
  },
  btp_tooltipIconColorHover: {
    en: "Color of the icon when the visitor hovers its mouse on the button",
    nl: "De kleur van de icoon wanneer de bezoeker zijn/haar muis over de knop houdt",
  },
  // BgColor
  bgColor: {
    en: "Background color",
    nl: "Achtergrondskleur",
  },
  tooltipBgColor: {
    en: "Set background color of the element",
    nl: "Zet achtergrondskleur van het element",
  },
  changeBgColorOnHover: {
    en: "Change background color on mouse hover",
    nl: "Verander achtergrondskleur wanneer muis over",
  },
  bgColorHover: {
    en: "Background color on hover",
    nl: "Achtergrondskleur wanneer muis over",
  },
  tooltipBgColorHover: {
    en: "Set background color of the element when the visitor hovers it mouse on it",
    nl: "Zet de achtergrondskleur van het element wanneer de bezoeker zijn/haar muis erover houdt",
  },
  // Border
  borderSides: {
    en: "Border sides",
    nl: "Rand kanten",
  },
  noBorder: {
    en: "No border",
    nl: "Geen rand",
  },
  allSides: {
    en: "All sides",
    nl: "Alle kanten",
  },
  topAndBottom: {
    en: "Top and bottom",
    nl: "Boven en onder",
  },
  leftAndRight: {
    en: "Left and right",
    nl: "Links en rechts",
  },
  // TODO: Emailbuilder also has the below options:
  // allExceptLeft: {
  //   en: "All except left",
  //   nl: "Alle behalve links",
  // },
  // allExceptRight: {
  //   en: "All except right",
  //   nl: "Alle behalve rechts",
  // },
  // allExceptTop: {
  //   en: "All except top",
  //   nl: "Alle behalve boven",
  // },
  // allExceptBottom: {
  //   en: "All except bottom",
  //   nl: "Alle behalve onder",
  // },
  tooltipBorderSides: {
    en: "Set the border sides of the element, if any",
    nl: "Zet de kanten van de rand van het element",
  },
  borderType: {
    en: "Border type",
    nl: "Type rand",
  },
  hidden: {
    en: "Hidden",
    nl: "Verborgen",
  },
  dotted: {
    en: "Dotted",
    nl: "Punten",
  },
  dashed: {
    en: "Dashed",
    nl: "Strepen",
  },
  solid: {
    en: "Solid",
    nl: "Doorgetrokken",
  },
  double: {
    en: "Double",
    nl: "Dubbel",
  },
  groove: {
    en: "Groove",
    nl: "Groef",
  },
  ridge: {
    en: "Ridge",
    nl: "Richel",
  },
  inset: {
    en: "Inset",
    nl: "Binnenkant",
  },
  outset: {
    en: "Outset",
    nl: "Buitenkant",
  },
  tooltipBorderType: {
    en: "Set the type of the border",
    nl: "Zet het type rand",
  },
  borderSize: {
    en: "Border size",
    nl: "Rand grootte",
  },
  tooltipBorderSize: {
    en: "Set the width of the border",
    nl: "Zet de breedte van de rand",
  },
  borderColor: {
    en: "Border color",
    nl: "Rand kleur",
  },
  tooltipBorderColor: {
    en: "Set the color of the border",
    nl: "Zet de kleur van de rand",
  },
  changeBorderColorOnHover: {
    en: "Change border color on mouse hover",
    nl: "Verander rand kleur wanneer muis over",
  },
  borderColorHover: {
    en: "Border color on hover",
    nl: "Rand kleur wanneer muis over",
  },
  tooltipBorderColorHover: {
    en: "Set the color of the border when visitor hovers its mouse on it",
    nl: "Zet de kleur van de rand wanneer de bezoeker zijn/haar muis over het element beweegt",
  },
  // BorderRadius
  borderRadius: {
    en: "Border radius",
    nl: "Vorm",
  },
  square: {
    en: "Square",
    nl: "Vierkant",
  },
  roundedSmall: {
    en: "Rounded - small",
    nl: "Kleine ronding",
  },
  roundedMedium: {
    en: "Rounded - medium",
    nl: "Middelgrote ronding",
  },
  roundedLarge: {
    en: "Rounded - large",
    nl: "Grote ronding",
  },
  roundedExtraLarge: {
    en: "Rounded - extra large",
    nl: "Extra grote ronding",
  },
  pill: {
    en: "Pill",
    nl: "Ovaal",
  },
  circle: {
    en: "Circle",
    nl: "Cirkel",
  },
  tooltipBorderRadius: {
    en: "Set the roundness of the element's corner",
    nl: "Zet de mate van ronding van de element's hoek",
  },
  // BoxShadow
  shadowHoriLength: {
    en: "Horizontal length",
    nl: "Horizontale afstand",
  },
  tooltipShadowHoriLength: {
    en: "Horizontal distance of the shadow from the element (negative values for more to the left, positive values for to the right)",
    nl: "De horizontale afstand van de schaduw van het element (negatieve waarde om meer naar links te zetten, positieve waarde naar rechts)",
  },
  shadowVerticalLength: {
    en: "Vertical length",
    nl: "Verticale afstand",
  },
  tooltipShadowVerticalLength: {
    en: "Vertical distance of the shadow from the element (negative values for above, positive values for below)",
    nl: "De verticale afstand van de schaduw van het element (negatieve waarde om meer naar boven te zetten, positieve waarde naar onder)",
  },
  blurRadius: {
    en: "Blur radius",
    nl: "Vervaging",
  },
  shadowBlur: {
    en: "Size of the shadow (a higher value makes it larger and lighter)",
    nl: "Grootte van de schaduw (een hogere waarde maakt de schaduw groter maar lichter)",
  },
  spreadRadius: {
    en: "Spread radius",
    nl: "Spreiding",
  },
  shadowSpread: {
    en: "Size of the shadow (a higher value makes it larger while retaining darkness)",
    nl: "Grootte van de schaduw (een hogere waarde maakt de schaduw groter terwijl het haar kleurdichtheid behoudt)",
  },
  shadowColorOpacity: {
    en: "Shadow color and opacity",
    nl: "Schaduwkleur en doorzichtigheid",
  },
  shadowColor: {
    en: "Color of the shadow",
    nl: "Kleur van de schaduw",
  },
  changeBoxShadowHover: {
    en: "Change box shadow on mouse hover",
    nl: "Verander schaduw wanneer muis over",
  },
  shadowHoriDistanceHover: {
    en: "Horizontal distance of the shadow from the element when visitor hovers its mouse on the element",
    nl: "De horizontale afstand van de schaduw van het element wanneer de bezoeker zijn/haar muis over het element beweegt",
  },
  shadowVerticalDistanceHover: {
    en: "Vertical distance of the shadow from the element when visitor hovers its mouse on the element",
    nl: "De verticale afstand van de schaduw van het element wanneer de bezoeker zijn/haar muis over het element beweegt",
  },
  shadowSizeHover: {
    en: "Size of the shadow when visitor hovers its mouse on the element",
    nl: "Grootte van de schaduw wanneer de bezoeker zijn/haar muis over het element beweegt",
  },
  // BtnEdit
  btnText: {
    en: "Button text",
    nl: "Knop tekst",
  },
  btnTextStyle: {
    en: "Text style",
    nl: "Tekststijl",
  },
  btnTextColor: {
    en: "Text color",
    nl: "Tekstkleur",
  },
  tooltipBtnTextColor: {
    en: "Color of the button text",
    nl: "Kleur van de knop tekst",
  },
  btnTextColorHover: {
    en: "Text color on hover",
    nl: "Tekstkleur wanneer muis over",
  },
  tooltipBtnTextColorHover: {
    en: "Color of the button text when the visitor hovers its mouse on the button",
    nl: "Verander tekstkleur wanneer de bezoeker zijn/haar muis over de knop beweegt",
  },
  btnFullWidth: {
    en: "Full width",
    nl: "Volledige breedte",
  },
  tooltipBtnFullWidth: {
    en: "Set button to be full width or not. If yes, you may have to set margin left and right to 0.",
    nl: "Zet de knop op volledige breedte of niet. Als gezet op volledige breedte, moet u mogelijk de marge aan de linkter- en rechterkant op 0 zetten.",
  },
  // CalloutTestimonial
  calloutTest_bgColor: {
    en: "Testimonial background color",
    nl: "Recensie achtergrondskleur",
  },
  calloutTest_tooltipBgColor: {
    en: "Background color of the component",
    nl: "De achtergrondskleur van het component",
  },
  calloutTest_pos: {
    en: "Callout position",
    nl: "Tekstbox positie",
  },
  calloutTest_tooltipPos: {
    en: "Position of the quote callout. 50% is in the middle, lower than 50 is toward the left, higher to the right",
    nl: "Positie van de tekstbox met de quote. 50% is in het midden, lager dan 50 is meer naar links, hoger meer naar rechts",
  },
  calloutTest_shadowColor: {
    en: "Shadow color",
    nl: "Schaduwkleur",
  },
  calloutTest_tooltipShadowColor: {
    en: "Color of the quote's shadow (set transparant color if no shadow to be shown)",
    nl: "Kleur van de schaduw van de quote tekstbox (zet transparant als u geen schaduw wilt laten zien)",
  },
  // Card3
  card3_footerColor: {
    en: "Footer color",
    nl: "Footer kleur",
  },
  card3_tooltipFooterColor: {
    en: "Color of the text box below the picture",
    nl: "Kleur van de tekstbox onder de afbeelding",
  },
  card3_footerColorHover: {
    en: "Footer color on hover",
    nl: "Footer kleur wanneer muis over",
  },
  card3_tooltipFooterColorHover: {
    en: "Color of the text box below the picture when visitor hovers its mouse on the component",
    nl: "De kleur van de tekstbox onder de afbeelding wanneer de bezoeker zijn/haar muis over het component beweegt",
  },
  // Card4
  card4_height: {
    en: "Popup height normal",
    nl: "Normale popup hoogte",
  },
  card4_tooltipHeight: {
    en: "Height of the overlay containing the text",
    nl: "Hoogte van de overlapping met de tekst",
  },
  card4_heightHover: {
    en: "Popup height on hover",
    nl: "Popup hoogte wanneer muis over",
  },
  card4_tooltipHeightHover: {
    en: "Height of the overlay containing the text when visitor hovers its mouse on the component",
    nl: "Hoogte van de overlapping met de tekst wanneer de bezoeker zijn/haar muis over het element beweegt",
  },
  // Card6
  card6_linePos: {
    en: "Line position",
    nl: "Lijn positie",
  },
  card6_tooltipLinePos: {
    en: "Position of the colored line",
    nl: "Positie van de gekleurde lijn",
  },
  card6_lineSize: {
    en: "Line size",
    nl: "Lijn grootte",
  },
  card6_tooltipLineSize: {
    en: "Size of the line",
    nl: "De grootte van de lijn",
  },
  card6_lineColor: {
    en: "Line color",
    nl: "Lijn kleur",
  },
  card6_tooltipLineColor: {
    en: "Color of the line",
    nl: "De kleur van de lijn",
  },
  // Card23
  card23_overlayColor: {
    en: "Overlay color",
    nl: "Overlapping kleur",
  },
  card23_tooltipOverlayColor: {
    en: "Set the color overlaying the element",
    nl: "Zet de kleur van de overlapping van het element",
  },
  // CarouselNavStyles
  carouselNav_includeNavBtns: {
    en: "Include navigation buttons",
    nl: "Laat navigatieknoppen zien",
  },
  carouselNav_btnPos: {
    en: "Button position",
    nl: "Knop positie",
  },
  inside: {
    en: "Inside",
    nl: "Binnenkant",
  },
  outside: {
    en: "Outside",
    nl: "Buitenkant",
  },
  carouselNav_ttNavPos: {
    en: "Set the position of the navigation buttons: inside to overlap with the image gallery or outside for external",
    nl: "Zet de positie van de navigatieknoppen: aan de binnenkant (overlappend aan de afbeelding) of aan de buitenkant",
  },
  carouselNav_btnSize: {
    en: "Button size",
    nl: "Knop grootte",
  },
  carouselNav_ttBtnSize: {
    en: "Set the size of the navigation buttons",
    nl: "Zet de grootte van de navigatieknoppen",
  },
  carouselNav_iconColor: {
    en: "Icon color",
    nl: "Icoon kleur",
  },
  carouselNav_ttIconColor: {
    en: "Color of the arrow icon in the navigation buttons",
    nl: "Kleur van de pijl in de knop",
  },
  carouselNav_iconColorHover: {
    en: "Icon color on hover",
    nl: "Icoon kleur wanneer muis over",
  },
  carouselNav_ttIconColorHover: {
    en: "Color of the arrow icon in the navigation buttons on mouse hover",
    nl: "Kleur van de pijl wanneer de bezoeker zijn/haar muis over de knop beweegt",
  },
  carouselNav_bgColor: {
    en: "Background color",
    nl: "Achtergrondskleur",
  },
  carouselNav_ttBgColor: {
    en: "Background color of the navigation button",
    nl: "Achtergrondskleur van de navigatieknop",
  },
  carouselNav_bgColorHover: {
    en: "Background color on hover",
    nl: "Achtergrondskleur wanneer muis over",
  },
  carouselNav_ttBgColorHover: {
    en: "Background color of the navigation button on mouse hover",
    nl: "Achtergrondskleur wanneer de bezoeker zijn/haar muis over de knop beweegt",
  },
  carouselNav_ttBorder: {
    en: "Set the border sides of the navigation button",
    nl: "Zet de kanten van de knop die een rand moeten hebben",
  },
  carouselNav_speed: {
    en: "Transition speed (seconds)",
    nl: "Transitie snelheid (seconden)",
  },
  carouselNav_ttSpeed: {
    en: "Speed of any style changes of the element when the user hovers its mouse on it",
    nl: "Snelheid waarmee stijlen veranderen wanneer de bezoeker zijn/haar muis over het element beweegt",
  },
  // CarouselPagStyles
  carouselPag_btnForm: {
    en: "Button form",
    nl: "Knop vorm",
  },
  squareOrCircle: {
    en: "Square or circle",
    nl: "Vierkant of cirkel",
  },
  bar: {
    en: "Bar",
    nl: "Lijn",
  },
  carouselPag_ttBtnForm: {
    en: `Set the form of the pagination buttons: a square (or a circle when border radius is set at "circle") or a bar`,
    nl: `Zet de vorm van de paginatieknoppen: een vierkant (of cirkel wanneer ronding op "cirkel" gezet is) of een lijn`,
  },
  carouselPag_ttBtnSize: {
    en: "Set the size of the pagination buttons",
    nl: "Zet de grootte van de paginatieknoppen",
  },
  carouselPag_ttBgColor: {
    en: "Background color of the pagination button",
    nl: "Achtergrondskleur van de paginatieknop",
  },
  carouselPag_ttBgColorHover: {
    en: "Background color of the pagination button on mouse hover",
    nl: "Achtergrondskleur van de paginatieknop wanneer de bezoeker zijn/haar muis over de knop beweegt",
  },
  // CarouselSettings
  carouselSettings_size: {
    en: "Size",
    nl: "Grootte",
  },
  carouselSettings_autoSize: {
    en: "Automatic size",
    nl: "Automatische grootte",
  },
  carouselSettings_fullScreen: {
    en: "Full screen",
    nl: "Volledige scherm",
  },
  carouselSettings_ttSize: {
    en: "Set size of the carousel to full screen or not. Also check the component's container width setting to ensure it takes up full page width, if so wanted. Is ignored if slide width/height are set.",
    nl: "Bepaal of de carousel het volledige scherm moet opnemen of niet. Als u wilt dat het het volledige scherm opneemt, zet dan ook het component's container breedte naar volledig.",
  },
  carouselSettings_autoplay: {
    en: "Autoplay",
    nl: "Automatisch afspelen",
  },
  carouselSettings_manualClicks: {
    en: "No (manual clicks)",
    nl: "Nee (handmatig klikken)",
  },
  carouselSettings_contAutoScroll: {
    en: "Continuous autoscroll",
    nl: "Doorlopend",
  },
  carouselSettings_ttAutoplay: {
    en: "Determine whether the carousel should only move slides manually, automatically or continuously",
    nl: "Bepaal of de carousel slides automatisch, handmatig of doorlopend moet afspelen",
  },
  carouselSettings_transType: {
    en: "Slide transition type",
    nl: "Slide transitie type",
  },
  carouselSettings_slideRewind: {
    en: "Slide and rewind after last slide",
    nl: "Schuif en draai terug na laatste slide",
  },
  carouselSettings_slideLoop: {
    en: "Slide and loop after last slide",
    nl: "Schuif en blijf doordraaien na laatste slide",
  },
  carouselSettings_fade: {
    en: "Fade in/out",
    nl: "Vervaag in elkaar",
  },
  carouselSettings_ttTransType: {
    en: "Determine transition type of the carousel's slides",
    nl: "Zet het transitie type van de carousel's slides",
  },
  carouselSettings_transSpeed: {
    en: "Slide transition speed (seconds)",
    nl: "Slide transitie snelheid (seconden)",
  },
  carouselSettings_ttTransSpeed: {
    en: "Speed of the slide transition",
    nl: "De snelheid waarmee de slide overgaat naar de volgende",
  },
  carouselSettings_rewindSpeed: {
    en: "Carousel rewind speed (seconds)",
    nl: "Carousel terugdraai snelheid (seconden)",
  },
  carouselSettings_ttRewindSpeed: {
    en: `Speed of rewinding to the first slide (relevant if slide transition type is "slide and rewind")`,
    nl: `Snelheid waarmee wordt teruggedraaid naar de eerste slide (alleen relevant als slide transitie type "Schuif en draai terug" is`,
  },
  carouselSettings_autoplaySpeed: {
    en: "Autoplay speed (seconds)",
    nl: "Snelheid automatisch afspelen (seconden)",
  },
  carouselSettings_ttAutoplaySpeed: {
    en: "Speed of moving to the next slide when autoplay is on",
    nl: "Snelheid waarmee de carousel automatisch doorgaat naar de volgende slide als automatisch afspelen aanstaat",
  },
  carouselSettings_autoScrollSpeed: {
    en: "Continuous scroll speed",
    nl: "Doorlopende scroll snelheid",
  },
  carouselSettings_ttAutoScrollSpeed: {
    en: "Speed of the continuous scroll, ranging from 1 (slow) to 10 (fast) when continuous scroll is on",
    nl: "Snelheid van het doorlopend scrollen, van 1 (langzaam) tot 10 (snel) wanneer doorlopend afspelen aanstaat",
  },
  carouselSettings_slidesShown: {
    en: "Number of slides shown",
    nl: "Aantal slides laten zien",
  },
  carouselSettings_ttSlidesShown: {
    en: "Number of slides shown at each moment. If transition type is set to continuous autoscroll, this is not relevant.",
    nl: "Het aantal slides dat per keer wordt laten zien. Niet relevant als transitie type op doorlopend afspelen staat.",
  },
  carouselSettings_slidesMoved: {
    en: "Number of slides moved",
    nl: "Aantal slides veranderd",
  },
  carouselSettings_ttSlidesMoved: {
    en: "Number of slides moved per transition. If transition type is set to continuous autoscroll, this is not relevant.",
    nl: "Het aantal slides dat per transitie wordt veranderd. Niet relevant als transitie type op doorlopend afspelen staat.",
  },
  carouselSettings_height: {
    en: "Slide height",
    nl: "Slide hoogte",
  },
  carouselSettings_ttHeight: {
    en: "Set fixed height of each slide. Set to 0 for automatic size.",
    nl: "Zet de vaste hoogte van elke slide. Gebruik 0 om hoogte automatisch te bepalen.",
  },
  carouselSettings_space: {
    en: "Space between slides",
    nl: "Ruimte tussen slides",
  },
  carouselSettings_ttSpace: {
    en: `Size between slides if slide width/height are set to non-zero values. Else, space between slides is determined automatically based on the "number of slides shown per page" setting.`,
    nl: `De ruimte tussen slides als slide hoogte/breedte handmatig gezet zijn (i.e., als de waardes anders dan 0 zijn). Anders wordt de ruimte tussen slides automatisch bepaald gebaseerd op de "Aantal slides laten zien" instelling.`,
  },
  // CarouselThumbnailSettings
  carouselThumbnail_autoplay: {
    en: "Determine whether the carousel thumbnails should move slides manually only or automatically",
    nl: "Bepaal of de carousel thumbnails automatisch afspeelt of niet",
  },
  // Component
  componentBgColor: {
    en: "Component background color",
    nl: "Component achtergrondskleur",
  },
  ttComponentBgColor: {
    en: "Background color of the component itself",
    nl: "Achtergrondskleur van het component",
  },
  spaceTop: {
    en: "Space top",
    nl: "Ruimte boven",
  },
  ttSpaceTopComponent: {
    en: "Space on top of the component",
    nl: "Ruimte aan de bovenkant van het component",
  },
  spaceBottom: {
    en: "Space bottom",
    nl: "Ruimte onder",
  },
  ttSpaceBottomComponent: {
    en: "Space on the bottom of the component",
    nl: "Ruimte aan de onderkant van het component",
  },
  // ContainerWidth
  containerWidth: {
    en: "Container width",
    nl: "Container breedte",
  },
  container: {
    en: "Container",
    nl: "Container",
  },
  fullPageWidth: {
    en: "Full page width",
    nl: "Volledig scherm",
  },
  ttContainerWidth: {
    en: "Width of the component. Container means centering the content on a screen, which is preferable for most components as it will look better on larger screens (like desktops)",
    nl: "Breedte van het component. Een container centreert de inhoud op het scherm, wat voor de meeste componenten aangeraden is aangezien het beter eruit ziet op grotere schermen (zoals desktops)",
  },
  // ElementSettings
  selectPage: {
    en: "Select page",
    nl: "Selecteer pagina",
  },
  copy: {
    en: "Copy",
    nl: "Kopieer",
  },
  elementId: {
    en: "Element ID",
    nl: "Element ID",
  },
  ttElementId: {
    en: "Set the ID of the element. Set an ID if you want to refer to this element, for example to track clicks in page analytics such as Google Analytics or to make it a link destination",
    nl: "Zet het ID van het element. Hiermee kan je dit element als bestemming voor een link maken of het bijhouden van kliks in een paginaanalyse programma zoals Google Analytics",
  },
  componentComponentToOtherPages: {
    en: "Copy component to other pages",
    nl: "Kopieer component naar andere paginas",
  },
  ttCopyComponent: {
    en: "Select another page to copy the selected component to, including all of its styles.",
    nl: "Selecteer pagina waar u het component (inclusief stijl) naartoe wilt kopieren.",
  },
  // FormActions
  formActions_sendMsgCf: {
    en: "Send message to contact form",
    nl: "Verstuur bericht via contact formulier",
  },
  formActions_subToAudience: {
    en: "Subscribe user to audience",
    nl: "Intekenen bij audience",
  },
  formActions_sendEmail: {
    en: "Send an email",
    nl: "Verstuur een email",
  },
  formActions_redirectToPage: {
    en: "Redirect user to page",
    nl: "Verwijs bezoeker door naar een andere pagina",
  },
  formActions_actionsOnFormSubmission: {
    en: "Actions on form submission",
    nl: "Acties na formulier indienen",
  },
  formActions_noActionsSet: {
    en: "No actions have been set yet",
    nl: "Geen acties zijn bepaald",
  },
  formActions_formAction: {
    en: "Form action",
    nl: "Formulier actie",
  },
  formActions_removeAction: {
    en: "Remove action",
    nl: "Verwijder actie",
  },
  action: {
    en: "Action",
    nl: "Actie",
  },
  formActions_ttSelectAction: {
    en: "Select what to do after the user submits the form",
    nl: "Selecteer wat te doen wanneer de bezoeker het formulier heeft ingediend",
  },
  formActions_selectAction: {
    en: "Select an action",
    nl: "Selecteer een actie",
  },
  formActions_selectCf: {
    en: "Select contact form",
    nl: "Selecteer contact formulier",
  },
  formActions_ttSelectCf: {
    en: "Select the contact form you want the user to submit the message to. You can create a new contact form on mySatonda.",
    nl: "Selecteer het contact formulier waar u het bericht van de bezoeker wilt bewaren. U kunt contact formulieren aanmaken op mySatonda.",
  },
  formActions_selectAudience: {
    en: "Select audience",
    nl: "Selecteer audience",
  },
  formActions_ttSelectAudience: {
    en: "Select the audience you want the user to subscribe to. You can create a new audience on mySatonda.",
    nl: "Selecteer de audience waar u de bezoeker wilt intekenen. U kunt audiences aanmaken op mySatonda.",
  },
  formActions_selectEmailCamp: {
    en: "Select email campaign",
    nl: "Selecteer email campagne",
  },
  formActions_ttSelectEmailCamp: {
    en: "Select the email from the campaign you want to send the user. You can create a new email campaign on mySatonda.",
    nl: "Selecteer de email campagne die u aan de bezoeker wilt versturen. U kunt reactieve email campagnes aanmaken op mySatonda.",
  },
  formActions_confirmDynVars: {
    en: "Confirm that your form's inputfield names match the dynamic content in selected email",
    nl: "Bevestig dat de namen van de invoervelden in het formulier overeenkomen met de dynamische inhoud in de geselecteerde email",
  },
  formActions_dynVarFromInputfieldName: {
    en: "from inputfield name",
    nl: "van invoerveld naam",
  },
  destination: {
    en: "Destination",
    nl: "Bestemming",
  },
  formActions_ttDest: {
    en: "Provide the link to the page you want to redirect the user to",
    nl: "Zet de link van de pagina waar u de gebruiker naartoe wilt verwijzen",
  },
  formActions_addAction: {
    en: "Add action",
    nl: "Voeg actie toe",
  },
  formActions_msgAfterSubmission: {
    en: "Message after form submission",
    nl: "Bericht na het indienen van het formulier",
  },
  formActions_ttMsgAfterSubmission: {
    en: "Set a confirmation message for the user after the form has been submitted",
    nl: "Zet een bevestigingsbericht om te laten zien nadat de bezoeker het formulier heeft ingediend",
  },
  // GoogleMaps
  googleMapsLink: {
    en: "Google Maps link",
    nl: "Google Maps link",
  },
  ttGoogleMapsLink: {
    en: `Open Google Maps in a new tab and find the location you want to show. Then click on "share". In the popup window that opens, click on "Embed a map" and copy the link shown between <iframe src="[link]"`,
    nl: `Open Google Maps in een nieuwe tab en zoek de locatie die u wilt laten zien. Klik daarna op "delen". In het popup scherm wat daarna opent, klik op "Een kaart insluiten" en kopieer de link tussen <iframe src="[link]"`,
  },
  // HeadingLine
  headingLine_height: {
    en: "Line height",
    nl: "Lijn hoogte",
  },
  headingLine_ttHeight: {
    en: "Set the height of the heading line",
    nl: "Zet de hoogte van de koplijn",
  },
  headingLine_width: {
    en: "Line width",
    nl: "Lijn breedte",
  },
  headingLine_ttWidth: {
    en: "Set the width of the heading line",
    nl: "Zet de breedte van de koplijn",
  },
  color: {
    en: "Color",
    nl: "Kleur",
  },
  headingLine_ttColor: {
    en: "Color of the heading line",
    nl: "Kleur van de koplijn",
  },
  headingLine_ttBorder: {
    en: "Set the border sides of the heading line, if any",
    nl: "Zet de kanten van de rand van de koplijn",
  },
  headingLine_ttBorderRadius: {
    en: "Set the roundness of the heading line's corner",
    nl: "Zet de hoekvorm van de koplijn",
  },
  // SectionLine
  sectionLine_height: {
    en: "Line height",
    nl: "Lijn hoogte",
  },
  sectionLine_ttHeight: {
    en: "Set the height of the section line",
    nl: "Zet de hoogte van de sectielijn",
  },
  sectionLine_width: {
    en: "Line width",
    nl: "Lijn breedte",
  },
  sectionLine_ttWidth: {
    en: "Set the width of the section line",
    nl: "Zet de breedte van de sectielijn",
  },
  sectionLine_ttColor: {
    en: "Color of the section line",
    nl: "Kleur van de sectielijn",
  },
  sectionLine_ttBorder: {
    en: "Set the border sides of the section line, if any",
    nl: "Zet de kanten van de rand van de sectielijn",
  },
  sectionLine_ttBorderRadius: {
    en: "Set the roundness of the section line's corner",
    nl: "Zet de hoekvorm van de sectielijn",
  },
  // Hero1
  height: {
    en: "Height",
    nl: "Hoogte",
  },
  ttHero1Height: {
    en: "Set the height of the hero component (as a % of screen)",
    nl: "Zet de hoogte van het hero component (als % van scherm)",
  },
  // IconEditor
  iconEditor_selected: {
    en: "Selected icon",
    nl: "Geselecteerd icoon",
  },
  iconEditor_selectOther: {
    en: "Select other icon",
    nl: "Selecteer ander icoon",
  },
  iconEditor_color: {
    en: "Icon color",
    nl: "Icoon kleur",
  },
  iconEditor_ttColor: {
    en: "Set icon color",
    nl: "Zet icoon kleur",
  },
  iconEditor_size: {
    en: "Icon size",
    nl: "Icoon grootte",
  },
  iconEditor_ttSize: {
    en: "Size of the icon",
    nl: "Zet grootte van het icoon",
  },
  // ImgAdjust
  opacity: {
    en: "Opacity",
    nl: "Doorzichtigheid",
  },
  ttOpacity: {
    en: "Opacity of the image",
    nl: "Doorzichtigheid van de afbeelding",
  },
  blur: {
    en: "Blur",
    nl: "Vervaging",
  },
  ttBlur: {
    en: "Blur the image (0 = normal)",
    nl: "Vervaging van de afbeelding (0 = normaal)",
  },
  brightNess: {
    en: "Brightness",
    nl: "Helderheid",
  },
  ttBrightness: {
    en: "Brightness of the image (100 = normal)",
    nl: "Helderheid van de afbeelding (100 = normaal)",
  },
  contrast: {
    en: "Contrast",
    nl: "Contrast",
  },
  ttContrast: {
    en: "Contrast of the image (100 = normal)",
    nl: "Contrast van de afbeelding (100 = normaal)",
  },
  grayscale: {
    en: "Grayscale",
    nl: "Zwart/wit verhouding",
  },
  ttGrayscale: {
    en: "Grayscale of the image (0 = colored)",
    nl: "Zwart/wit verhouding van de afbeelding (0 = volledig in kleur)",
  },
  hue: {
    en: "Hue rotate",
    nl: "Tint",
  },
  ttHue: {
    en: "Set degrees to shift hues of the image",
    nl: "Zet de mate van tint van de afbeelding",
  },
  invert: {
    en: "Invert",
    nl: "Negatief",
  },
  ttInvert: {
    en: "Invert the image (0 = normal)",
    nl: "Maak een negatief van de afbeelding (0 = normaal)",
  },
  saturate: {
    en: "Saturate",
    nl: "Doordrenken",
  },
  ttSaturate: {
    en: "Saturate the image (100 = normal)",
    nl: "Doordrenk de afbeelding (100 = normaal)",
  },
  sepia: {
    en: "Sepia",
    nl: "Warmte",
  },
  ttSepia: {
    en: "Set sepia of the image (0 = normal)",
    nl: "Zet de warmte van de afbeelding (0 = normaal)",
  },
  imgAdjustChangeOnHover: {
    en: "Change effects on mouse hover",
    nl: "Verander effecten wanneer muis over",
  },
  ttOpacityHover: {
    en: "Opacity of the image when visitor hovers its mouse on the image",
    nl: "Doorzichtigheid van de afbeelding wanneer de bezoeker zijn/haar muis over de afbeelding beweegt",
  },
  ttBlurHover: {
    en: "Blur the image when visitor hovers its mouse on the image",
    nl: "Vervaging van de afbeelding wanneer de bezoeker zijn/haar muis over de afbeelding beweegt",
  },
  ttBrightnessHover: {
    en: "Brightness of the image when visitor hovers its mouse on the image",
    nl: "Helderheid van de afbeelding wanneer de bezoeker zijn/haar muis over de afbeelding beweegt",
  },
  ttContrastHover: {
    en: "Contrast of the image when visitor hovers its mouse on the image",
    nl: "Contrast van de afbeelding wanneer de bezoeker zijn/haar muis over de afbeelding beweegt",
  },
  ttGrayscaleHover: {
    en: "Grayscale of the image when visitor hovers its mouse on the image",
    nl: "Zwart/wit verhouding van de afbeelding wanneer de bezoeker zijn/haar muis over de afbeelding beweegt",
  },
  ttHueHover: {
    en: "Set degrees to shift hues of the image when visitor hovers its mouse on the image",
    nl: "Tint van de afbeelding wanneer de bezoeker zijn/haar muis over de afbeelding beweegt",
  },
  ttInvertHover: {
    en: "Invert the image when visitor hovers its mouse on the image",
    nl: "Maak een negatief van de afbeelding wanneer de bezoeker zijn/haar muis over de afbeelding beweegt",
  },
  ttSaturateHover: {
    en: "Saturate the image when visitor hovers its mouse on the image",
    nl: "Doordrenk de afbeelding wanneer de bezoeker zijn/haar muis over de afbeelding beweegt",
  },
  ttSepiaHover: {
    en: "Set sepia of the image when visitor hovers its mouse on the image",
    nl: "Verander warmte van de afbeelding wanneer de bezoeker zijn/haar muis over de afbeelding beweegt",
  },
  shrinkOrExpand: {
    en: "Shrink or expand",
    nl: "Krimp of groei",
  },
  ttShrinkOrExpand: {
    en: "Shrink or expand the image size when visitor hovers its mouse on the image",
    nl: "Krimp of groei de afbeelding wanneer de bezoeker zijn/haar muis over de afbeelding beweegt",
  },
  // ImgSize
  imgWidth: {
    en: "Image width",
    nl: "Afbeelding breedte",
  },
  ttImgWidth: {
    en: "Set width of the image",
    nl: "Zet de breedte van de afbeelding",
  },
  explainerImgWidth: {
    en: "Use 0 to automatically size based on image height",
    nl: "Gebruik 0 om de breedte automatisch te laten bepalen door grootte",
  },
  imgHeight: {
    en: "Image height",
    nl: "Afbeelding hoogte",
  },
  ttImgHeight: {
    en: "Set height of the image",
    nl: "Zet de hoogte van de afbeelding",
  },
  explainerImgHeight: {
    en: "Use 0 to automatically size based on image width",
    nl: "Gebruik 0 om de grootte automatisch te laten bepalen door breedte",
  },
  // ImgSource
  selectFromGallery: {
    en: "Select from gallery",
    nl: "Selecteer uit gallerij",
  },
  imgSource: {
    en: "Image source",
    nl: "Afbeelding bron",
  },
  ttImgSource: {
    en: "Set link to the image you would like to use. Can either be selected from your gallery or be an external resource",
    nl: "Zet link naar de afbeelding die u wilt gebruiken. Kan geselecteerd worden uit uw gallerij of een externe link zijn",
  },
  imgDesc: {
    en: "Image description",
    nl: "Afbeelding omschrijving",
  },
  ttImgDesc: {
    en: "Provide a meaningful description of the image or leave empty otherwise. This is primarily relevant for improving your site's SEO score.",
    nl: "Geef een betekenisvolle beschrijving van de afbeelding (optioneel).",
  },
  // InputfieldSettings
  inputfield_ddOption: {
    en: "Dropdown option",
    nl: "Selectie optie",
  },
  inputfield_text: {
    en: "Text",
    nl: "Tekst",
  },
  inputfield_multiline: {
    en: "Multi-line text",
    nl: "Meerdere lijnen tekstbox",
  },
  inputfield_number: {
    en: "Number",
    nl: "Getal",
  },
  inputfield_pw: {
    en: "Password",
    nl: "Wachtwoord",
  },
  inputfield_emailaddr: {
    en: "Email address",
    nl: "Emailadres",
  },
  inputfield_datepicker: {
    en: "Date picker",
    nl: "Datumkiezer",
  },
  inputfield_ddMenu: {
    en: "Drop down menu",
    nl: "Selectie menu",
  },
  inputfield_noDdOptionsYet: {
    en: "No dropdown options added yet",
    nl: "Nog geen selectie opties toegevoegd",
  },
  inputfield_removeOption: {
    en: "Remove option",
    nl: "Verwijder optie",
  },
  inputfield_value: {
    en: "Value",
    nl: "Waarde",
  },
  inputfield_ttValue: {
    en: `This is the value that will be saved to your audience's data. Only letters, numbers or an underscore ("_") are allowed.`,
    nl: `Dit is de waarde die zal worden opgeslagen in de audience's data. Alleen letters, getallen of een laag streepje ("_") zijn toegestaan.`,
  },
  inputfield_label: {
    en: "Label",
    nl: "Label",
  },
  inputfield_ttLabel: {
    en: "This is the text that is shown to the user in the dropdown box.",
    nl: "Dit is de tekst die de gebruiker ziet in de selectiebox",
  },
  inputfield_addOption: {
    en: "Add option",
    nl: "Voeg optie toe",
  },
  inputfield_name: {
    en: "Input field name",
    nl: "Invoerveld naam",
  },
  inputfield_ttName: {
    en: "Set the name of the input field. This will become a data field in the audience dataset you've selected.",
    nl: "Zet de naam van het invoerveld. Dit zal dan een dataveld worden in de audience die u gebruikt.",
  },
  inputfield_setRequired: {
    en: "Input field is required to be provided by user",
    nl: "Invoerveld is een verplicht veld",
  },
  inputfield_type: {
    en: "Input field type",
    nl: "Invoerveld type",
  },
  inputfield_ttType: {
    en: `Set the type of the input field. Use "text" for the most common input field.`,
    nl: `Zet het type van het invoerveld. "text" is het meest voorkomende type.`,
  },
  inputfield_showLabel: {
    en: "Show a label",
    nl: "Laat een label zien",
  },
  inputfield_labelText: {
    en: "Label text",
    nl: "Label tekst",
  },
  inputfield_ttLabelText: {
    en: "Label to indicate to user what type of data to fill in. If you don't want to show a label, uncheck the switch button above.",
    nl: "Label om aan te geven aan de bezoeker wat voor data in dit veld ingevuld dient te worden. Vink de knop hierboven uit als u geen label wilt laten zien.",
  },
  inputfield_placeholder: {
    en: "Placeholder text",
    nl: "Placeholder tekst",
  },
  inputfield_ttPlaceholder: {
    en: "Text to show the user when the input field is empty",
    nl: "Tekst in het invoerveld wanneer deze nog leeg is",
  },
  // InputfieldStyles
  inputfield_textSize: {
    en: "Text size",
    nl: "Tekst grootte",
  },
  inputfield_ttTextSize: {
    en: "Size of the input field's text",
    nl: "Grootte van de tekst van het invoerveld",
  },
  inputfield_textColor: {
    en: "Form text color",
    nl: "Formulier tekstkleur",
  },
  inputfield_ttTextColor: {
    en: "Color of the input field's text",
    nl: "Kleur van de tekst in het invoerveld",
  },
  inputfield_textColorFocus: {
    en: "Form text color on focus",
    nl: "Tekstkleur tijdens focus",
  },
  inputfield_ttTextColorFocus: {
    en: "Color of the input field's text when visitor is typing",
    nl: "Kleur van de tekst van het invoerveld wanneer de bezoeker het aan het invullen is",
  },
  inputfield_placeholderColor: {
    en: "Placeholder text color",
    nl: "Placeholder tekstkleur",
  },
  inputfield_ttPlaceholderColor: {
    en: "Color of the input field's placeholder text",
    nl: "Kleur van de placeholder tekst van het invoerveld",
  },
  inputfield_ttBgColor: {
    en: "Background color of the input field",
    nl: "Invoerveld achtergrondskleur",
  },
  inputfield_bgColorFocus: {
    en: "Background color on focus",
    nl: "Achtergrondskleur tijdens focus",
  },
  inputfield_ttBgColorFocus: {
    en: "Background color of the input field when user has clicked on the field",
    nl: "Achtergrondskleur van het invoerveld wanneer de bezoeker aan het invullen is",
  },
  inputfield_borderColorFocus: {
    en: "Border color on focus",
    nl: "Kleur van de rand tijdens focus",
  },
  inputfield_ttBorderColorFocus: {
    en: "Color of the border of the input field when user has clicked on the field",
    nl: "Kleur van de rand van het invoerveld wanneer de gebruiker erop geklikt heeft",
  },
  inputfield_shadowHoriLengthFocus: {
    en: "Shadow horizontal length on focus",
    nl: "Horizontale afstand van de schaduw tijdens focus",
  },
  inputfield_shadowVertLengthFocus: {
    en: "Shadow vertical length on focus",
    nl: "Verticale afstand van de schaduw tijdens focus",
  },
  inputfield_shadowBlurFocus: {
    en: "Shadow blur radius on focus",
    nl: "Vervaging tijdens focus",
  },
  inputfield_shadowSpreadFocus: {
    en: "Shadow spread radius on focus",
    nl: "Spreiding van de schaduw tijdens focus",
  },
  inputfield_shadowColorFocus: {
    en: "Shadow color on focus",
    nl: "Kleur van de schaduw tijdens focus",
  },
  // LinkEditor
  linkColorNormal: {
    en: "Link color",
    nl: "Link kleur",
  },
  ttLinkColorNormal: {
    en: "Text color of the link when the visitor's mouse is not on it",
    nl: "Kleur van de tekst van de link wanneer de gebruiker zijn/haar muis er niet over beweegt",
  },
  linkStyleNormal: {
    en: "Link style",
    nl: "Link stijl",
  },
  none: {
    en: "None",
    nl: "Geen",
  },
  underlined: {
    en: "Underlined",
    nl: "Onderstreept",
  },
  ttLinkStyleNormal: {
    en: "Style of the link when the visitor's mouse is not on it",
    nl: "Stijl van de link wanneer de gebruiker zijn/haar muis er niet over beweegt",
  },
  linkColorHover: {
    en: "Hover link color",
    nl: "Link kleur wanneer muis over",
  },
  ttLinkColorHover: {
    en: "Text color of the link when the visitor hovers its mouse on it",
    nl: "Kleur van de tekst van de link wanneer de gebruiker zijn/haar muis erover beweegt",
  },
  linkStyleHover: {
    en: "Hover link style",
    nl: "Link stijl wanneer muis over",
  },
  ttLinkStyleHover: {
    en: "Style of the link when the visitor hovers its mouse on it",
    nl: "Stijl van de link wanneer de gebruiker zijn/haar muis erover beweegt",
  },
  // Listmarker2
  markerStyle: {
    en: "Marker style",
    nl: "Punt stijl",
  },
  noMarker: {
    en: "No marker",
    nl: "Geen teken",
  },
  openSquare: {
    en: "Open square",
    nl: "Open vierkant",
  },
  closedSquare: {
    en: "Closed square",
    nl: "Gevuld vierkant",
  },
  openCircle: {
    en: "Open circle",
    nl: "Open cirkel",
  },
  filledCircle: {
    en: "Filled circle",
    nl: "Gevuld cirkel",
  },
  line: {
    en: "Line",
    nl: "Lijn",
  },
  ttMarkerStyle: {
    en: "Style of the marker of each list item",
    nl: "Stijl van de punt van elk item in de lijst",
  },
  markerColor: {
    en: "Marker color",
    nl: "Punt kleur",
  },
  ttMarkerColor: {
    en: "Color of the marker of each list item",
    nl: "Kleur van de punt van elk item in de lijst",
  },
  spaceRightMarker: {
    en: "Space right",
    nl: "Ruimte rechts",
  },
  ttSpaceRightMarker: {
    en: "Space between the marker and the text",
    nl: "Ruimte tussen het punt en de tekst",
  },
  // Margin
  marginLeft: {
    en: "Margin left",
    nl: "Marge links",
  },
  marginRight: {
    en: "Margin right",
    nl: "Marge rechts",
  },
  marginTop: {
    en: "Margin top",
    nl: "Marge boven",
  },
  marginBottom: {
    en: "Margin bottom",
    nl: "Marge onder",
  },
  ttMarginLeft: {
    en: "External space left of the component",
    nl: "Ruimte aan de linkerbuitenkant van het component",
  },
  ttMarginRight: {
    en: "External space right of the component",
    nl: "Ruimte aan de rechterbuitenkant van het component",
  },
  ttMarginTop: {
    en: "External space on top of the component",
    nl: "Ruimte aan de bovenbuitenkant van het component",
  },
  ttMarginBottom: {
    en: "External space on bottom of the component",
    nl: "Ruimte aan de onderbuitenkant van het component",
  },
  // Navbarlinks
  navbar_linkText: {
    en: "Link text",
    nl: "Link tekst",
  },
  text: {
    en: "Text",
    nl: "Tekst",
  },
  navbar_ttSelectDest: {
    en: "Select an internal page (eg /abc.html) or page section (eg /abc.html#section) or type an external link",
    nl: "Selecteer een project pagna (bijv. /abc.html), een pagina sectie (bijv. /abc.html#section) of type een link naar een externe pagina",
  },
  link: {
    en: "Link",
    nl: "Link",
  },
  removeLink: {
    en: "Remove link",
    nl: "Verwijder link",
  },
  addLink: {
    en: "Add link",
    nl: "Voeg link toe",
  },
  navbar_linkFont: {
    en: "Link font",
    nl: "Link lettertype",
  },
  navbar_ttLinkFont: {
    en: "Font name of the links",
    nl: "Lettertype van de links",
  },
  navbar_linkSize: {
    en: "Link text size",
    nl: "Link tekst grootte",
  },
  navbar_ttLinkSize: {
    en: "Font size of the links",
    nl: "Grootte van de tekst van de links",
  },
  navbar_linkStyle: {
    en: "Link style",
    nl: "Link stijl",
  },
  normal: {
    en: "Normal",
    nl: "Normaal",
  },
  bold: {
    en: "Bold",
    nl: "Dikgedrukt",
  },
  navbar_ttLinkStyle: {
    en: "Style of the link",
    nl: "Stijl van de link",
  },
  navbar_linkDeco: {
    en: "Link decoration",
    nl: "Link opmaak",
  },
  // NavbarLogo
  navbar_logoHeight: {
    en: "Logo max height",
    nl: "Maximale hoogte logo",
  },
  navbar_ttLogoHeight: {
    en: "Max height of the logo (if image is smaller, it will retain that smaller size)",
    nl: "De maximale hoogte van het logo (als de afbeelding kleiner is, dan zal het die kleinere maat behouden)",
  },
  // NavbarMain
  navbar_navbarPos: {
    en: "Navbar position",
    nl: "Navbar positie",
  },
  navbar_inline: {
    en: "Inline",
    nl: "Gealigneerd",
  },
  navbar_fixedTop: {
    en: "Fixed to top",
    nl: "Vastgezet aan bovenkant",
  },
  navbar_fixedScroll: {
    en: "Fixed to top after scroll past",
    nl: "Vastgezet aan bovenkant nadat bezoeker er langs is gescrold",
  },
  navbar_ttPos: {
    en: "Position of the navbar on the visitor's screen",
    nl: "De positie van de navbar op het scherm van de bezoeker",
  },
  navbar_showLinks: {
    en: "Show links",
    nl: "Laat links zien",
  },
  navbar_never: {
    en: "Never",
    nl: "Nooit",
  },
  navbar_tablet: {
    en: "From tablet screen size",
    nl: "Vanaf tablet schermgrootte",
  },
  navbar_desktop: {
    en: "From desktop screen size",
    nl: "Vanaf desktop schermgrootte",
  },
  navbar_ttShowLinks: {
    en: "From which screen size onwards, if ever, to show the links rather than a toggle button",
    nl: "Vanaf welke grootte van een scherm moeten links worden laten zien ipv een schakelknop",
  },
  navbar_size: {
    en: "Navbar size",
    nl: "Navbar grootte",
  },
  navbar_ttSize: {
    en: "Set the height of the navbar",
    nl: "Zet de hoogte van de navbar",
  },
  navbar_ttBgColor: {
    en: "Set the background color of the navbar",
    nl: "Zet de achtergrondskleur van de navbar",
  },
  navbar_transSpeed: {
    en: "Transition speed (seconds)",
    nl: "Transitie snelheid (seconden)",
  },
  navbar_ttTransSpeed: {
    en: "Speed of any transitions of the elements within the navbar",
    nl: "Snelheid van transities van de elementen binnen de navbar",
  },
  // NavbarScrolledpast
  navbar_changeScrollPast: {
    en: "Change navbar styles after visitor has scrolled past it",
    nl: "Verander navbar stijl nadat bezoeker er langs is gescrold",
  },
  navbar_scrollPastSize: {
    en: "Size",
    nl: "Grootte",
  },
  navbar_ttScrollpastSize: {
    en: "Height of the navbar after visitor has scrolled past it (relevant for fixed to top positioned navbars)",
    nl: "Hoogte van de navbar nadat bezoeker er langs is gescrold (relevant wanneer vastgesteld aan bovenkant)",
  },
  navbar_ttScrollpastBgColor: {
    en: "Background color of the navbar after visitor has scrolled past it (relevant for fixed to top positioned navbars)",
    nl: "Achtergrondskleur van de navbar nadat bezoeker er langs is gescrold (relevant wanneer vastgesteld aan bovenkant)",
  },
  navbar_ttScrollpastHeight: {
    en: "Max height of the logo after visitor has scrolled past it (relevant for fixed to top positioned navbars)",
    nl: "Grootte van het logo nadat bezoeker er langs is gescrold (relevant wanneer vastgesteld aan bovenkant)",
  },
  // NavbarToggler
  navbar_togglerSize: {
    en: "Toggler size",
    nl: "Schakelknop grootte",
  },
  navbar_ttTogglerSize: {
    en: "Size of the toggle icon",
    nl: "Grootte van het schakelicoon",
  },
  navbar_togglerColor: {
    en: "Toggler color",
    nl: "Schakelaar kleur",
  },
  navbar_ttTogglerColor: {
    en: "Color of the toggle icon",
    nl: "Kleur van het schakelicoon",
  },
  navbar_togglerColorHover: {
    en: "Toggler color on hover",
    nl: "Schakelaar kleur wanneer muis over",
  },
  navbar_ttTogglerColorHover: {
    en: "Color of the toggle icon when visitor hovers its mouse on it",
    nl: "Kleur van het schakelicoon wanneer de bezoeker zijn/haar muis erover beweegt",
  },
  navbar_togglerBgColor: {
    en: "Toggler background color",
    nl: "Schakelaar achtergrondskleur",
  },
  navbar_ttTogglerBgColor: {
    en: "Background color of the toggle button",
    nl: "Achtergrondskleur van het schakelicoon",
  },
  navbar_togglerBgColorHover: {
    en: "Toggler background color on hover",
    nl: "Schakelaar achtergrondskleur wanneer muis over",
  },
  navbar_ttTogglerBgColorHover: {
    en: "Background color of the toggle button when visitor hovers its mouse on it",
    nl: "Achtergrondskleur van het schakelicoon wanneer de bezoeker zijn/haar muis erover beweegt",
  },
  navbar_togglerBorder: {
    en: "Toggler border sides",
    nl: "Schakelknop randen",
  },
  navbar_togglerBorderRadius: {
    en: "Toggler border radius",
    nl: "Schakelknop hoekvorm",
  },
  // OverlayColor
  overlayColor: {
    en: "Overlay color",
    nl: "Overlappingskleur",
  },
  ttOverlayColor: {
    en: "Set the color overlaying the element",
    nl: "Zet de kleur van de overlapping van het element",
  },
  // Padding
  paddingLeft: {
    en: "Padding left",
    nl: "Opvulling links",
  },
  ttPaddingLeft: {
    en: "Internal space within the left side of the component",
    nl: "Lege ruimte aan de linkerkant van het component",
  },
  paddingRight: {
    en: "Padding right",
    nl: "Opvulling rechts",
  },
  ttPaddingRight: {
    en: "Internal space within the right side of the component",
    nl: "Lege ruimte aan de rechterkant van het component",
  },
  paddingTop: {
    en: "Padding top",
    nl: "Opvulling boven",
  },
  ttPaddingTop: {
    en: "Internal space within the top side of the component",
    nl: "Lege ruimte aan de bovenkant van het component",
  },
  paddingBottom: {
    en: "Padding bottom",
    nl: "Opvulling onder",
  },
  ttPaddingBottom: {
    en: "Internal space within the bottom side of the component",
    nl: "Lege ruimte aan de onderkant van het component",
  },
  // Parallax
  ttSelectFromGallery: {
    en: "Set link to the parallax image you would like to use. Can either be selected from your gallery or be an external resource",
    nl: "Zet link naar de afbeelding die u wilt gebruiken voor de parallax. Kan geselecteerd worden uit uw gallerij of een externe link zijn",
  },
  ttParallaxHeight: {
    en: "Set the height of the parallax image (as a % of screen)",
    nl: "Zet de hoogte van de parallax afbeelding (als een % van het scherm)",
  },
  alignParallaxContent: {
    en: "Align parallax content",
    nl: "Lijn inhoud van parallax uit",
  },
  middle: {
    en: "Middle",
    nl: "Midden",
  },
  ttAlignParallaxContent: {
    en: "Set vertical alignment of the parallax's content",
    nl: "Zet de verticale uitlijning van de inhoud van de parallax",
  },
  // Process1
  process1_color: {
    en: "Arrow & icon color",
    nl: "Pijl en icoon kleur",
  },
  process1_ttColor: {
    en: "Color of the arrow and icon",
    nl: "Kleur van de pijl en het icoon",
  },
  // Process2
  process2_lineColor: {
    en: "Line & circle color",
    nl: "Lijn en cirkel kleur",
  },
  process2_ttLineColor: {
    en: "Color of the line and circle",
    nl: "Kleur van de lijn en de cirkel",
  },
  process2_iconColor: {
    en: "Icon color",
    nl: "Icoon kleur",
  },
  process2_ttIconColor: {
    en: "Color of the icon within the circle",
    nl: "Kleur van het icoon binnen de cirkel",
  },
  // ResponsiveLayout
  colWidthMob: {
    en: "Column width on mobile",
    nl: "Kolom breedte op mobiel scherm",
  },
  ttColWidthMob: {
    en: "Size of each column on mobile screen sizes (12 = full page width)",
    nl: "Breedte van elke kolom op een mobiele telefoonscherm (12 = volledige breedte)",
  },
  colWidthTab: {
    en: "Column width on tablet",
    nl: "Kolom breedte op tablet scherm",
  },
  ttColWidthTab: {
    en: "Size of each column on tablet screen sizes (12 = full page width, for example size of 3 shows 12 / 3 = 4 columns per row)",
    nl: "Breedte van elke kolom op een tablet cherm (12 = volledige breedte; een grootte van 3 geeft 12 / 3 = 4 kolommen per rij)",
  },
  colWidthDesk: {
    en: "Column width on desktop",
    nl: "Kolom breedte op desktop scherm",
  },
  ttColWidthDesk: {
    en: "Size of each column on desktop screen sizes (12 = full page width, for example size of 3 shows 12 / 3 = 4 columns per row)",
    nl: "Breedte van elke kolom op een desktop scherm (12 = volledige breedte; een grootte van 3 geeft 12 / 3 = 4 kolommen per rij)",
  },
  // SectionDivider
  flipVert: {
    en: "Flip vertically",
    nl: "Verticaal omdraaien",
  },
  flipHori: {
    en: "Flip horizontally",
    nl: "Horizontaal omdraaien",
  },
  ttSdSpaceTop: {
    en: "Space on top of the section divider",
    nl: "Ruimte aan de bovenkant van de sectie afscheiding",
  },
  ttSdSpaceBottom: {
    en: "Space on the bottom of the section divider",
    nl: "Ruimte aan de onderkant van de sectie afscheiding",
  },
  sdSyncColors: {
    en: "Sync colors from components",
    nl: "Synchroneer kleuren van de component boven en onder de afscheiding",
  },
  swapColors: {
    en: "Swap colors",
    nl: "Wissel kleuren",
  },
  // TextColor
  textColor: {
    en: "Text color",
    nl: "Tekstkleur",
  },
  setTextColor: {
    en: "Set text color",
    nl: "Zet tekstkleur",
  },
  changeTextColorHover: {
    en: "Change text color on mouse hover",
    nl: "Verander tekstkleur wanneer muis over",
  },
  textColorHover: {
    en: "Text color on hover",
    nl: "Tekstkleur wanneer muis over",
  },
  setTextColorHover: {
    en: "Set text color when the visitor hovers it mouse on it",
    nl: "Zet de tekstkleur wanneer de bezoeker zijn/haar muis erover beweegt",
  },
  // TextEditor
  editText: {
    en: "Edit text",
    nl: "Bewerk tekst",
  },
  // TextShadow
  txtShadow_ttHorLen: {
    en: "Horizontal distance of the shadow from the text (negative values for more to the left, positive values for to the right)",
    nl: "De horizontale afstand van de schaduw van de tekst (negatieve waarde om meer naar links te zetten, positieve waarde naar rechts)",
  },
  txtShadow_ttVerLen: {
    en: "Vertical distance of the shadow from the text (negative values for above, positive values for below)",
    nl: "De verticale afstand van de schaduw van de tekst (negatieve waarde om meer naar boven te zetten, positieve waarde naar onder)",
  },
  txtShadow_ttSize: {
    en: "Size of the shadow (a higher value makes it larger and lighter)",
    nl: "Grootte van de schaduw (een hogere waarde maakt de schaduw groter maar lichter)",
  },
  txtShadow_ttChangeHover: {
    en: "Change text shadow on mouse hover",
    nl: "Verander schaduw wanneer muis over",
  },
  txtShadow_ttHorLenHover: {
    en: "Horizontal distance of the shadow from the text when visitor hovers its mouse on the text",
    nl: "De horizontale afstand van de schaduw van de tekst wanneer de bezoeker zijn/haar muis over de tekst beweegt",
  },
  txtShadow_ttVerLenHover: {
    en: "Vertical distance of the shadow from the text when visitor hovers its mouse on the text",
    nl: "De verticale afstand van de schaduw van de tekst wanneer de bezoeker zijn/haar muis over de tekst beweegt",
  },
  txtShadow_ttSizeHover: {
    en: "Size of the shadow when visitor hovers its mouse on the text",
    nl: "Grootte van de schaduw wanneer de bezoeker zijn/haar muis over de tekst beweegt",
  },
  txtShadow_ttColorHover: {
    en: "Color of the shadow when visitor hovers its mouse on the text",
    nl: "Kleur van de schaduw wanneer de bezoeker zijn/haar muis over de tekst beweegt",
  },
  // Transition
  transitionSpeed: {
    en: "Transition speed (seconds)",
    nl: "Transitie snelheid (seconden)",
  },
  ttTransSpeed: {
    en: "Speed of any style changes of the element when the user hovers its mouse on it",
    nl: "Snelheid van stijlveranderingen van het element wanneer de bezoeker zijn/haar muis erover beweegt",
  },
  // Width
  elementWidthMobile: {
    en: "Element width on mobile",
    nl: "Element breedte op mobiele scherm",
  },
  ttElementWidthMobile: {
    en: "Width of the element as a % of its parent element's total width on a mobile screen size",
    nl: "Breedte van het element als % van moederelement's totale breedte op een mobiele telefoonscherm",
  },
  elementWidthTablet: {
    en: "Element width on tablet",
    nl: "Element breedte op tablet scherm",
  },
  ttElementWidthTablet: {
    en: "Width of the element as a % of its parent element's total width on a tablet screen size",
    nl: "Breedte van het element als % van moederelement's totale breedte op een tablet scherm",
  },
  elementWidthDesktop: {
    en: "Element width on desktop",
    nl: "Element breedte op desktop scherm",
  },
  ttElementWidthDesktop: {
    en: "Width of the element as a % of its parent element's total width on a desktop screen size",
    nl: "Breedte van het element als % van moederelement's totale breedte op een desktop cherm",
  },
};
