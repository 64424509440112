import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ImgSizeInput from "./formComponents/ImgSizeInput";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const ImgSize = ({ sb: { startStyles } }) => {
  return (
    <>
      <ImgSizeInput
        label={translate("cEditForms.imgWidth", false, null)}
        id={"imgResizeWidth"}
        startValue={formGetStartValue(startStyles, "imgResizeWidth")}
        tooltipText={translate("cEditForms.ttImgWidth", false, null)}
        infoText={translate("cEditForms.explainerImgWidth", false, null)}
      />
      <ImgSizeInput
        label={translate("cEditForms.imgHeight", false, null)}
        id={"imgResizeHeight"}
        startValue={formGetStartValue(startStyles, "imgResizeHeight")}
        tooltipText={translate("cEditForms.ttImgHeight", false, null)}
        infoText={translate("cEditForms.explainerImgHeight", false, null)}
      />
    </>
  );
};

ImgSize.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(ImgSize);
