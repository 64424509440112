export const template18 = {
  id: "65fdf57870bb2bca77a20e5f",
  name: { en: "Photography site", nl: "Fotografie website" },
  desc: { en: "Personal photography portfolio website", nl: "Persoonlijke fotografie portfolio website" },
  categories: ["hobby"],
  sbPages: [
    {
      pageId: "6172e016-11d2-489c-a13a-9f6dae138261",
      version: "A",
      pageLink: "bio",
      pageTitle: "bio",
      pageDesc: "",
      pagePreview: "",
      components: [
        {
          componentId: "K6dt0o",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-K6dt0o", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "sticky-top" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "WtPAEi",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "BTdmhX",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "xTch3y",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-K6dt0o"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-jsphoto.png" },
                        { property: "alt", value: "John Smith Photography" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "tDk5PJ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-K6dt0o", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                    { property: "type", value: "button" },
                    { property: "aria-expanded", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "UE0BAG",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "d8qWD8",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "gRDdi0",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "vlEw2Y",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-K6dt0o"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/bio.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Biography",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "EysRuc",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-K6dt0o"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/gallery.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Gallery",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Tg4EEI",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-K6dt0o"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/contact.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "fRKHPj",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-fRKHPj"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "uwwqTh",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-fRKHPj", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Biography", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "Qvdv9q",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-6-component-Qvdv9q"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "RjGFf4",
              type: "",
              htmlTagName: "div",
              classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [
                {
                  childId: "ywANVq",
                  type: "",
                  htmlTagName: "div",
                  classes: ["basics-6-headingline-Qvdv9q"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "yupOUS",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-yupOUS"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "kyTs1x",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-yupOUS", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "qU3f9F",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-qU3f9F"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "DPvE52",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-qU3f9F", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "ZPIxGU",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "statistics-3-component-ZPIxGU"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "StbQPX",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-evenly", "align-items-stretch", "statistics-3-statswrapper", "w-100"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "Xu8BB8",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-3-col"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "statistics3a" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "A3fPrm",
                      type: "",
                      htmlTagName: "div",
                      classes: ["statistics-3-statwrapper-ZPIxGU"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "zCcup1",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-3-stat-caption-ZPIxGU", "w-100", "w-md-90", "w-lg-70"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Nature trips",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Wdy41R",
                          type: "",
                          htmlTagName: "div",
                          classes: ["statistics-3-stat-numbercircle-ZPIxGU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "statistics3b" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "zYzDms",
                              type: "",
                              htmlTagName: "p",
                              classes: ["statistics-3-stat-numbertext-ZPIxGU", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "112",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "t9l193",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-3-col"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "statistics3a" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "vsXxL8",
                      type: "",
                      htmlTagName: "div",
                      classes: ["statistics-3-statwrapper-ZPIxGU"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "c0sVRM",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-3-stat-caption-ZPIxGU", "w-100", "w-md-90", "w-lg-70"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Published photos",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "gySslA",
                          type: "",
                          htmlTagName: "div",
                          classes: ["statistics-3-stat-numbercircle-ZPIxGU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "statistics3b" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "hO6uoR",
                              type: "",
                              htmlTagName: "p",
                              classes: ["statistics-3-stat-numbertext-ZPIxGU", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "791",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "HBypJH",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-3-col"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "statistics3a" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "FDceCq",
                      type: "",
                      htmlTagName: "div",
                      classes: ["statistics-3-statwrapper-ZPIxGU"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "Bsfwrm",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-3-stat-caption-ZPIxGU", "w-100", "w-md-90", "w-lg-70"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Awards won",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "pxtoYb",
                          type: "",
                          htmlTagName: "div",
                          classes: ["statistics-3-stat-numbercircle-ZPIxGU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "statistics3b" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "cblqaa",
                              type: "",
                              htmlTagName: "p",
                              classes: ["statistics-3-stat-numbertext-ZPIxGU", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "17",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Q5rfNY",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-2-component-Q5rfNY"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "JGxVMT",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-2-wrapper-Q5rfNY"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "P1R4Yz",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-2-content-Q5rfNY"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      _id: "65fdf581ce19f27d76704546",
    },
    {
      pageId: "36063cf0-39fc-4980-bc91-851a6761f035",
      version: "A",
      pageLink: "contact",
      pageTitle: "contact",
      pageDesc: "",
      pagePreview: "",
      components: [
        {
          componentId: "K6dt0o",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-K6dt0o", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "sticky-top" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "WtPAEi",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "BTdmhX",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "xTch3y",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-K6dt0o"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-jsphoto.png" },
                        { property: "alt", value: "John Smith Photography" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "tDk5PJ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-K6dt0o", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                    { property: "type", value: "button" },
                    { property: "aria-expanded", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "UE0BAG",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "d8qWD8",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "gRDdi0",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "vlEw2Y",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-K6dt0o"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/bio.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Biography",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "EysRuc",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-K6dt0o"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/gallery.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Gallery",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Tg4EEI",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-K6dt0o"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/contact.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "dU4d93",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-dU4d93"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "oUzCaS",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-dU4d93", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact me", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "GUHlH2",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-6-component-GUHlH2"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "LQRGDo",
              type: "",
              htmlTagName: "div",
              classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [
                {
                  childId: "TCcTkh",
                  type: "",
                  htmlTagName: "div",
                  classes: ["basics-6-headingline-GUHlH2"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "dI68BW",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-8-component-dI68BW"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "enoCio",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-8-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "GfonHQ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ua08SQ",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-dI68BW"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "a2YKpV",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-dI68BW"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "LdLMte",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-dI68BW"],
                          styles: [],
                          attributes: [
                            { property: "name", value: "name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                            { property: "type", value: "text" },
                            { property: "placeholder", value: "Your name" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "Ghg6vY",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-dI68BW"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "MBEQv7",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-dI68BW"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "dnqVFD",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-dI68BW"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "N1Cwz2",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-dI68BW"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "mzZkMx",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-dI68BW"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "rjMoxt",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-dI68BW"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "A3aGzX",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-dI68BW"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "BReawI",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-dI68BW"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "vJZFeF",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-8-inputfield-dI68BW"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "leCrqB",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-8-button-dI68BWwrapper-dI68BW"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "gXLeiy",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-8-button-dI68BW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "65fdf581ce19f27d76704547",
    },
    {
      pageId: "8d461eac-502e-4292-a023-2482001401f3",
      version: "A",
      pageLink: "gallery",
      pageTitle: "gallery",
      pageDesc: "",
      pagePreview: "",
      components: [
        {
          componentId: "K6dt0o",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-K6dt0o", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "sticky-top" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "WtPAEi",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "BTdmhX",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "xTch3y",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-K6dt0o"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-jsphoto.png" },
                        { property: "alt", value: "John Smith Photography" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "tDk5PJ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-K6dt0o", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                    { property: "type", value: "button" },
                    { property: "aria-expanded", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "UE0BAG",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "d8qWD8",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "gRDdi0",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "vlEw2Y",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-K6dt0o"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/bio.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Biography",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "EysRuc",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-K6dt0o"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/gallery.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Gallery",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Tg4EEI",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-K6dt0o"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/contact.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "t0PBG0",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-t0PBG0"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "obwfeA",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-t0PBG0", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Gallery", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "JHryGB",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-6-component-JHryGB"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "HXCzna",
              type: "",
              htmlTagName: "div",
              classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "headingline" },
              ],
              content: "",
              children: [
                {
                  childId: "vIDzqW",
                  type: "",
                  htmlTagName: "div",
                  classes: ["basics-6-headingline-JHryGB"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "mb0zEn",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "imggallery-5-component-mb0zEn"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "fC5jdr",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "imggallery-5-row"],
              styles: [],
              attributes: [
                { property: "data-name", value: "block" },
                { property: "data-masonry", value: '{"percentPosition":true,"itemSelector":".imggallery-5-col-mb0zEn"}' },
              ],
              content: "",
              children: [
                {
                  childId: "m1qTSV",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-5-col-mb0zEn"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "mPvQPk",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-5-img-mb0zEn"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/mountain1.png" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "gTdp7r",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-5-col-mb0zEn"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "BScqEO",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-5-img-mb0zEn"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/nature1.png" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "HA6BWb",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-5-col-mb0zEn"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "B16miI",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-5-img-mb0zEn"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/nature3.png" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "R9ORyS",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-5-col-mb0zEn"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "WnZHJJ",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-5-img-mb0zEn"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/nature5.png" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "BBRNdh",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-5-col-mb0zEn"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "IqsPw1",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-5-img-mb0zEn"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/nature9.png" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "kSttSe",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-5-col-mb0zEn"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "TIizqN",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-5-img-mb0zEn"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/nature12.png" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "qqmA7U",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-5-col-mb0zEn"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "u4KZoG",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-5-img-mb0zEn"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/beach14.jpg" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "nhzuY4",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-5-col-mb0zEn"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ZMS5OY",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-5-img-mb0zEn"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/nature10.png" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "gqstR7",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-5-col-mb0zEn"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "aJ3lGl",
                      type: "",
                      htmlTagName: "img",
                      classes: ["imggallery-5-img-mb0zEn"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/mountain4.png" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "65fdf581ce19f27d76704548",
    },
    {
      pageId: "9bdcc5be-c0ec-4602-ab32-197ce176980d",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "K6dt0o",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-K6dt0o", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "sticky-top" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "WtPAEi",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "BTdmhX",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "xTch3y",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-K6dt0o"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-jsphoto.png" },
                        { property: "alt", value: "John Smith Photography" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "tDk5PJ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-K6dt0o", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                    { property: "type", value: "button" },
                    { property: "aria-expanded", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "UE0BAG",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "d8qWD8",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "gRDdi0",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "vlEw2Y",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-K6dt0o"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/bio.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Biography",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "EysRuc",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-K6dt0o"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/gallery.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Gallery",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Tg4EEI",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-K6dt0o"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/contact.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "enwxdG",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-6-component-enwxdG"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "HpifxG",
              type: "",
              htmlTagName: "div",
              classes: ["hero-6-wrapper-enwxdG"],
              styles: [],
              attributes: [{ property: "data-herowrapper", value: "true" }],
              content: "",
              children: [
                {
                  childId: "NXf1wA",
                  type: "",
                  htmlTagName: "img",
                  classes: ["hero-6-img-enwxdG"],
                  styles: [],
                  attributes: [
                    { property: "alt", value: "Image" },
                    { property: "src", value: "https://cdn.satonda.com/eb/assets/photography6.jpg" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "ico27h",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-6-content-enwxdG"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "hero1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "CA1XXk",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-6-title-enwxdG", "w-lg-90", "w-90", "w-md-90"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "John Smith",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "wfwKII",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-6-text-enwxdG", "w-90", "w-md-90", "w-lg-90"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nature photography",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "65fdf581ce19f27d76704549",
    },
  ],
  sbCustomCss: [
    {
      componentId: "K6dt0o",
      classes: [
        {
          className: "scrolledpast-K6dt0o.navbar-1-wrapper-K6dt0o",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "rem" },
            { property: "padding-bottom", value: "rem" },
            { property: "background", value: "" },
          ],
        },
        { className: "scrolledpast-K6dt0o .navbar-1-logo-K6dt0o", pseudo: "", rules: [{ property: "max-height", value: "px" }] },
        {
          className: "navbar-1-wrapper-K6dt0o",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(211, 146, 146, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "navbar-1-logo-K6dt0o",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "25px" },
          ],
        },
        { className: "navbar-1-logo-K6dt0o", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-K6dt0o",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(181, 181, 181, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "background", value: "rgba(230, 230, 230, 0)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-K6dt0o",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(181, 181, 181, 0)" },
            { property: "color", value: "rgba(232, 232, 232, 1)" },
          ],
        },
        {
          className: "navbar-1-link-K6dt0o",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "font-family", value: "var(--font-body)" },
            { property: "font-size", value: "1rem" },
            { property: "text-decoration", value: "none" },
            { property: "font-weight", value: "inherit" },
          ],
        },
        { className: "navbar-1-link-K6dt0o", pseudo: "hover", rules: [{ property: "color", value: "rgba(255, 255, 255, 1)" }] },
      ],
    },
    {
      componentId: "enwxdG",
      classes: [
        {
          className: "section.hero-6-component-enwxdG",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-6-component-enwxdG", pseudo: "hover", rules: [] },
        {
          className: "hero-6-component-enwxdG",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-6-component-enwxdG", pseudo: "hover", rules: [] },
        {
          className: "hero-6-wrapper-enwxdG",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
          ],
        },
        {
          className: "hero-6-img-enwxdG",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-6-content-enwxdG",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "hero-6-title-enwxdG",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-6-title-enwxdG", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-6-text-enwxdG",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-6-text-enwxdG", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
      ],
    },
    {
      componentId: "ZPIxGU",
      classes: [
        {
          className: "section.statistics-3-component-ZPIxGU",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.statistics-3-component-ZPIxGU", pseudo: "hover", rules: [] },
        {
          className: "statistics-3-component-ZPIxGU",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-3-component-ZPIxGU", pseudo: "hover", rules: [] },
        {
          className: "statistics-3-statwrapper-ZPIxGU",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "width", value: "100%" },
            { property: "aspect-ratio", value: "1" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(211, 146, 146, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "position", value: "relative" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "statistics-3-statwrapper-ZPIxGU",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(211, 146, 146, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "statistics-3-stat-numbercircle-ZPIxGU",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "right", value: "0" },
            { property: "transform", value: "translate(25%, -25%)" },
            { property: "width", value: "40%" },
            { property: "aspect-ratio", value: "1" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(211, 146, 146, 1)" },
            { property: "background", value: "rgba(211, 146, 146, 1)" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        {
          className: "statistics-3-stat-numbercircle-ZPIxGU",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(211, 146, 146, 1)" },
            { property: "background", value: "rgba(211, 146, 146, 1)" },
          ],
        },
        {
          className: "statistics-3-stat-numbertext-ZPIxGU",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "statistics-3-stat-numbertext-ZPIxGU",
          pseudo: "hover",
          rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }],
        },
        {
          className: "statistics-3-stat-caption-ZPIxGU",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(211, 146, 146, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "statistics-3-stat-caption-ZPIxGU",
          pseudo: "hover",
          rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }],
        },
      ],
    },
    {
      componentId: "fRKHPj",
      classes: [
        {
          className: "section.basics-1-component-fRKHPj",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-fRKHPj", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-fRKHPj",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-fRKHPj", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-fRKHPj",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(211, 146, 146, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-fRKHPj", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Qvdv9q",
      classes: [
        {
          className: "section.basics-6-component-Qvdv9q",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "0rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-6-component-Qvdv9q", pseudo: "hover", rules: [] },
        {
          className: "basics-6-component-Qvdv9q",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-6-component-Qvdv9q", pseudo: "hover", rules: [] },
        {
          className: "basics-6-headingline-Qvdv9q",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(211, 146, 146, 1)" },
            { property: "width", value: "50px" },
            { property: "height", value: "3px" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "basics-6-headingline-Qvdv9q", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "yupOUS",
      classes: [
        {
          className: "section.basics-2-component-yupOUS",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-2-component-yupOUS", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-yupOUS",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-yupOUS", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-yupOUS",
          pseudo: "",
          rules: [
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "basics-2-text-yupOUS", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "qU3f9F",
      classes: [
        {
          className: "section.basics-2-component-qU3f9F",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-2-component-qU3f9F", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-qU3f9F",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-qU3f9F", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-qU3f9F",
          pseudo: "",
          rules: [
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "basics-2-text-qU3f9F", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "Q5rfNY",
      classes: [
        {
          className: "section.parallax-2-component-Q5rfNY",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.parallax-2-component-Q5rfNY", pseudo: "hover", rules: [] },
        {
          className: "parallax-2-component-Q5rfNY",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-2-wrapper-Q5rfNY",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "50vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/mountain3.png")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-2-content-Q5rfNY",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "justify-content", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "dI68BW",
      classes: [
        {
          className: "section.contactus-8-component-dI68BW",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.contactus-8-component-dI68BW", pseudo: "hover", rules: [] },
        {
          className: "contactus-8-col-dI68BW",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-8-button-dI68BWwrapper-dI68BW",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-8-button-dI68BWwrapper-dI68BW", pseudo: "hover", rules: [] },
        {
          className: "contactus-8-label-dI68BW",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-8-label-dI68BW", pseudo: "hover", rules: [] },
        {
          className: "contactus-8-inputfield-dI68BW",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1rem" },
            { property: "outline", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(211, 146, 146, 1)" },
            { property: "border-style", value: "none none solid none" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.2s ease-in-out" },
          ],
        },
        {
          className: "contactus-8-inputfield-dI68BW",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(194, 107, 107, 1)" },
            { property: "border-color", value: "rgba(211, 146, 146, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-8-inputfield-dI68BW",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-8-inputfield-dI68BW", pseudo: "hover", rules: [] },
        { className: "contactus-8-inputwrapper-dI68BW", pseudo: "", rules: [{ property: "margin", value: "0rem 0rem 1rem 0rem" }] },
        {
          className: "contactus-8-button-dI68BW",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(211, 146, 146, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "contactus-8-button-dI68BW",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(199, 117, 117, 1)" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "dU4d93",
      classes: [
        {
          className: "section.basics-1-component-dU4d93",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-dU4d93", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-dU4d93",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-dU4d93", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-dU4d93",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(211, 146, 146, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-dU4d93", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "GUHlH2",
      classes: [
        {
          className: "section.basics-6-component-GUHlH2",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "0rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-6-component-GUHlH2", pseudo: "hover", rules: [] },
        {
          className: "basics-6-component-GUHlH2",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-6-component-GUHlH2", pseudo: "hover", rules: [] },
        {
          className: "basics-6-headingline-GUHlH2",
          pseudo: "",
          rules: [
            { property: "align-self", value: "center" },
            { property: "height", value: "3px" },
            { property: "width", value: "50px" },
            { property: "background", value: "rgba(211, 146, 146, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-6-headingline-GUHlH2",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(211, 146, 146, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "t0PBG0",
      classes: [
        {
          className: "section.basics-1-component-t0PBG0",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-t0PBG0", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-t0PBG0",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-t0PBG0", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-t0PBG0",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(211, 146, 146, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-t0PBG0", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "JHryGB",
      classes: [
        {
          className: "section.basics-6-component-JHryGB",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "0rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-6-component-JHryGB", pseudo: "hover", rules: [] },
        {
          className: "basics-6-component-JHryGB",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-6-component-JHryGB", pseudo: "hover", rules: [] },
        {
          className: "basics-6-headingline-JHryGB",
          pseudo: "",
          rules: [
            { property: "align-self", value: "center" },
            { property: "height", value: "3px" },
            { property: "width", value: "50px" },
            { property: "background", value: "rgba(211, 146, 146, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-6-headingline-JHryGB",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(211, 146, 146, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "mb0zEn",
      classes: [
        {
          className: "section.imggallery-5-component-mb0zEn",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.imggallery-5-component-mb0zEn", pseudo: "hover", rules: [] },
        {
          className: "imggallery-5-col-mb0zEn",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
          ],
        },
        {
          className: "imggallery-5-img-mb0zEn",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "box-shadow", value: "0px 0px 6px 2px rgba(0, 0, 0, 0.2)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "imggallery-5-img-mb0zEn",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "box-shadow", value: "0px 0px 8px 2px rgba(0, 0, 0, 0.3)" },
          ],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Nunito+Sans:300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Nunito Sans",
    fontHeadersWeight: "700",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(211, 146, 146, 1)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
