export const card2 = {
  id: "card-2",
  name: "Card 2",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "card-2-component"],
    styles: [],
    attributes: [
      {
        property: "data-name",
        value: "component",
      },
    ],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "card-2-row"],
        styles: [],
        attributes: [
          {
            property: "data-name",
            value: "block",
          },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "card-2-col"],
            styles: [],
            attributes: [
              {
                property: "data-name",
                value: "card2",
              },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-2-overlay-wrapper"],
                styles: [],
                attributes: [
                  {
                    property: "data-overlaywrapper",
                    value: "true",
                  },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-2-avatar"],
                    styles: [],
                    attributes: [
                      {
                        property: "src",
                        value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg",
                      },
                      {
                        property: "data-editable",
                        value: "true",
                      },
                      {
                        property: "data-name",
                        value: "img",
                      },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-2-overlay-content"],
                    styles: [],
                    attributes: [
                      {
                        property: "data-overlaycontent",
                        value: "true",
                      },
                      {
                        property: "data-elementalignmentwrapper",
                        value: "true",
                      },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["card-2-title"],
                        styles: [],
                        attributes: [
                          {
                            property: "data-editable",
                            value: "true",
                          },
                          {
                            property: "data-texteditable",
                            value: "true",
                          },
                          {
                            property: "data-name",
                            value: "h",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Name",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-2-subtitle"],
                        styles: [],
                        attributes: [
                          {
                            property: "data-editable",
                            value: "true",
                          },
                          {
                            property: "data-texteditable",
                            value: "true",
                          },
                          {
                            property: "data-name",
                            value: "p",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "block",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [
                          {
                            property: "data-name",
                            value: "block",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-2-social-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_blank",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-iconwrapper",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "false",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-solid", "fa-envelope"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-checkparent",
                                    value: "true",
                                  },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-2-social-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_blank",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-iconwrapper",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "false",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-twitter"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-checkparent",
                                    value: "true",
                                  },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-2-social-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_blank",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-iconwrapper",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "false",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-linkedin"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-checkparent",
                                    value: "true",
                                  },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-2-social-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_blank",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-iconwrapper",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "false",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-facebook-f"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-checkparent",
                                    value: "true",
                                  },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "card-2-col"],
            styles: [],
            attributes: [
              {
                property: "data-name",
                value: "card2",
              },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-2-overlay-wrapper"],
                styles: [],
                attributes: [
                  {
                    property: "data-overlaywrapper",
                    value: "true",
                  },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-2-avatar"],
                    styles: [],
                    attributes: [
                      {
                        property: "src",
                        value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg",
                      },
                      {
                        property: "data-editable",
                        value: "true",
                      },
                      {
                        property: "data-name",
                        value: "img",
                      },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-2-overlay-content"],
                    styles: [],
                    attributes: [
                      {
                        property: "data-overlaycontent",
                        value: "true",
                      },
                      {
                        property: "data-elementalignmentwrapper",
                        value: "true",
                      },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["card-2-title"],
                        styles: [],
                        attributes: [
                          {
                            property: "data-editable",
                            value: "true",
                          },
                          {
                            property: "data-texteditable",
                            value: "true",
                          },
                          {
                            property: "data-name",
                            value: "h",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Name",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-2-subtitle"],
                        styles: [],
                        attributes: [
                          {
                            property: "data-editable",
                            value: "true",
                          },
                          {
                            property: "data-texteditable",
                            value: "true",
                          },
                          {
                            property: "data-name",
                            value: "p",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "block",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [
                          {
                            property: "data-name",
                            value: "block",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-2-social-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_blank",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-iconwrapper",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "false",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-solid", "fa-envelope"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-checkparent",
                                    value: "true",
                                  },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-2-social-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_blank",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-iconwrapper",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "false",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-twitter"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-checkparent",
                                    value: "true",
                                  },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-2-social-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_blank",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-iconwrapper",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "false",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-linkedin"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-checkparent",
                                    value: "true",
                                  },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-2-social-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_blank",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-iconwrapper",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "false",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-facebook-f"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-checkparent",
                                    value: "true",
                                  },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "card-2-col"],
            styles: [],
            attributes: [
              {
                property: "data-name",
                value: "card2",
              },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-2-overlay-wrapper"],
                styles: [],
                attributes: [
                  {
                    property: "data-overlaywrapper",
                    value: "true",
                  },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-2-avatar"],
                    styles: [],
                    attributes: [
                      {
                        property: "src",
                        value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg",
                      },
                      {
                        property: "data-editable",
                        value: "true",
                      },
                      {
                        property: "data-name",
                        value: "img",
                      },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-2-overlay-content"],
                    styles: [],
                    attributes: [
                      {
                        property: "data-overlaycontent",
                        value: "true",
                      },
                      {
                        property: "data-elementalignmentwrapper",
                        value: "true",
                      },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["card-2-title"],
                        styles: [],
                        attributes: [
                          {
                            property: "data-editable",
                            value: "true",
                          },
                          {
                            property: "data-texteditable",
                            value: "true",
                          },
                          {
                            property: "data-name",
                            value: "h",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Name",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-2-subtitle"],
                        styles: [],
                        attributes: [
                          {
                            property: "data-editable",
                            value: "true",
                          },
                          {
                            property: "data-texteditable",
                            value: "true",
                          },
                          {
                            property: "data-name",
                            value: "p",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "block",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [
                          {
                            property: "data-name",
                            value: "block",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-2-social-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_blank",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-iconwrapper",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "false",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-solid", "fa-envelope"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-checkparent",
                                    value: "true",
                                  },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-2-social-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_blank",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-iconwrapper",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "false",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-twitter"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-checkparent",
                                    value: "true",
                                  },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-2-social-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_blank",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-iconwrapper",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "false",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-linkedin"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-checkparent",
                                    value: "true",
                                  },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-2-social-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_blank",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-iconwrapper",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "false",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-facebook-f"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-checkparent",
                                    value: "true",
                                  },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.card-2-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.card-2-component", pseudo: "hover", rules: [] },
      {
        className: "card-2-col",
        pseudo: "",
        rules: [
          {
            property: "padding",
            value: "1rem 1rem 1rem 1rem",
          },
          {
            property: "display",
            value: "flex",
          },
          {
            property: "align-items",
            value: "center",
          },
          {
            property: "justify-content",
            value: "center",
          },
        ],
      },
      {
        className: "card-2-overlay-wrapper",
        pseudo: "",
        rules: [
          {
            property: "position",
            value: "relative",
          },
          {
            property: "overflow",
            value: "hidden",
          },
          {
            property: "border-radius",
            value: "50%",
          },
          {
            property: "height",
            value: "250px",
          },
          {
            property: "width",
            value: "250px",
          },
        ],
      },
      {
        className: "card-2-overlay-wrapper",
        pseudo: "hover .card-2-overlay-content",
        rules: [
          {
            property: "visibility",
            value: "visible",
          },
          {
            property: "opacity",
            value: "1",
          },
        ],
      },
      {
        className: "card-2-overlay-content",
        pseudo: "",
        rules: [
          {
            property: "display",
            value: "flex",
          },
          {
            property: "flex-direction",
            value: "column",
          },
          {
            property: "align-items",
            value: "center",
          },
          {
            property: "justify-content",
            value: "center",
          },
          {
            property: "position",
            value: "absolute",
          },
          {
            property: "top",
            value: "0",
          },
          {
            property: "bottom",
            value: "0",
          },
          {
            property: "left",
            value: "0",
          },
          {
            property: "right",
            value: "0",
          },
          {
            property: "background",
            value: "rgba(0, 0, 0, 0.5)",
          },
          {
            property: "transition",
            value: "all 0.3s ease",
          },
          {
            property: "visibility",
            value: "hidden",
          },
          {
            property: "opacity",
            value: "0",
          },
        ],
      },
      {
        className: "card-2-avatar",
        pseudo: "",
        rules: [
          {
            property: "height",
            value: "100%",
          },
          {
            property: "width",
            value: "100%",
          },
        ],
      },
      {
        className: "card-2-avatar",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-2-title",
        pseudo: "",
        rules: [
          {
            property: "margin",
            value: "0 0 0.5rem 0",
          },
          {
            property: "text-align",
            value: "center",
          },
          {
            property: "font-weight",
            value: "bold",
          },
          {
            property: "color",
            value: "rgba(250, 250, 250, 1)",
          },
        ],
      },
      {
        className: "card-2-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-2-subtitle",
        pseudo: "",
        rules: [
          {
            property: "margin",
            value: "0 0 0.5rem 0",
          },
          {
            property: "text-align",
            value: "center",
          },
          {
            property: "color",
            value: "rgba(230, 230, 230, 1)",
          },
        ],
      },
      {
        className: "card-2-subtitle",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-2-social-link",
        pseudo: "",
        rules: [
          {
            property: "margin",
            value: "0 0.5rem 0 0",
          },
          {
            property: "color",
            value: "rgba(250, 250, 250, 1)",
          },
          {
            property: "text-decoration",
            value: "none",
          },
          {
            property: "transition",
            value: "all 0.3s ease",
          },
        ],
      },
      {
        className: "card-2-social-link",
        pseudo: "hover",
        rules: [
          {
            property: "color",
            value: "rgba(200, 200, 200, 1)",
          },
        ],
      },
      {
        className: "card-2-social-link",
        pseudo: "last-child",
        rules: [{ property: "margin-right", value: "0" }],
      },
    ],
  },
};
