import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Tooltip from "./Tooltip";

import { EDIT_FORM_ID_PREFIX } from "../../../../../lib/editFunctions";
import { getInternalLinksToSelectFrom } from "../../../../../lib/stateFunctions";

const LinkDestForm = ({ sb: { selectedElement, sbPages }, label, id, startValue, tooltipText, onTypeCheck, errMsgText }) => {
  const [value, setValue] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [linksToSelectFrom, setLinksToSelectFrom] = useState([]);

  useEffect(() => {
    setValue(startValue);
    setLinksToSelectFrom(getInternalLinksToSelectFrom(sbPages));
  }, [startValue, selectedElement]);

  const onType = (text) => {
    setValue(text);
    if (typeof onTypeCheck !== "undefined") {
      onTypeCheck(text) ? setErrMsg("") : setErrMsg(errMsgText);
    }
  };

  return (
    <div className="w-100 d-flex align-items-center my-3">
      <div className="w-50">
        {label}
        <Tooltip text={tooltipText} />
      </div>
      <div className="w-50 d-flex flex-column justify-content-center" style={{ position: "relative" }}>
        <input
          type="text"
          className={`form-control form-control-sm ${errMsg === "" ? "is-valid" : "is-invalid"}`}
          id={EDIT_FORM_ID_PREFIX + id}
          value={value}
          onChange={(e) => onType(e.target.value)}
        />
        <div className="invalid-feedback fontSize08">{errMsg}</div>
        <i
          className="fa-solid fa-chevron-down cursorPointer"
          style={{ position: "absolute", top: "7px", right: "35px" }}
          id={`LinkDest`}
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />
        <div className="dropdown-menu p-0" aria-labelledby={`LinkDest`}>
          {linksToSelectFrom.map((selectableLink) => (
            <p key={selectableLink} className="px-2 py-1 m-0 fontSize08 bgHover-light trans-3 cursorPointer" onClick={() => onType(selectableLink)}>
              {selectableLink}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

LinkDestForm.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(LinkDestForm);
