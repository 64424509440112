import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ImgSource from "./ImgSource";
import ColorPicker from "./formComponents/ColorPicker";
import Divider from "./formComponents/Divider";
import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const Hero1 = ({ sb: { startStyles } }) => {
  return (
    <>
      <ImgSource />
      <Divider />
      <Slider
        label={translate("cEditForms.height", false, null)}
        id={"heroHeightVh"}
        min={1}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "heroHeightVh")}
        tooltipText={translate("cEditForms.ttHero1Height", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.overlayColor", false, null)}
        id={"hero1_overlay"}
        startValue={formGetStartValue(startStyles, "hero1_overlay")}
        tooltipText={translate("cEditForms.ttOverlayColor", false, null)}
      />
    </>
  );
};

Hero1.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Hero1);
