export const sectiondividers57 = {
  id: "sectiondividers-57",
  name: "Section dividers 57",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "svg",
        classes: [],
        styles: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scaleY(-1)" },
        ],
        attributes: [
          { property: "xmlns", value: "http://www.w3.org/2000/svg" },
          { property: "width", value: "100%" },
          { property: "height", value: "200" },
          { property: "viewBox", value: "0 0 1000 100" },
          { property: "preserveAspectRatio", value: "none" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              {
                property: "d",
                value:
                  "M194,99c186.7,0.7,305-78.3,306-97.2c1,18.9,119.3,97.9,306,97.2c114.3-0.3,194,0.3,194,0.3s0-91.7,0-100c0,0,0,0,0-0 L0,0v99.3C0,99.3,79.7,98.7,194,99z",
              },
            ],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
