export const sectiondividers88 = {
  id: "sectiondividers-88",
  name: "Section dividers 88",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "svg",
        classes: [],
        styles: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scaleY(-1)" },
        ],
        attributes: [
          { property: "xmlns", value: "http://www.w3.org/2000/svg" },
          { property: "version", value: "1.0" },
          { property: "width", value: "100%" },
          { property: "height", value: "40" },
          { property: "viewBox", value: "0 0 1280 40" },
          { property: "preserveAspectRatio", value: "none" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              {
                property: "d",
                value:
                  "M 0 40 C -5 20 45 20 40 0 C 35 20 85 20 80 40 C 75 20 125 20 120 0 C 115 20 165 20 160 40 C 155 20 205 20 200 0 C 195 20 245 20 240 40 C 235 20 285 20 280 0 C 275 20 325 20 320 40 C 315 20 365 20 360 0 C 355 20 405 20 400 40 C 395 20 445 20 440 0 C 435 20 485 20 480 40 C 475 20 525 20 520 0 C 515 20 565 20 560 40 C 555 20 605 20 600 0 C 595 20 645 20 640 40 C 635 20 685 20 680 0 C 675 20 725 20 720 40 C 715 20 765 20 760 0 C 755 20 805 20 800 40 C 795 20 845 20 840 0 C 835 20 885 20 880 40 C 875 20 925 20 920 0 C 915 20 965 20 960 40 C 955 20 1005 20 1000 0 C 995 20 1045 20 1040 40 C 1035 20 1085 20 1080 0 C 1075 20 1125 20 1120 40 C 1115 20 1165 20 1160 0 C 1155 20 1205 20 1200 40 C 1195 20 1245 20 1240 0 C 1235 20 1285 20 1280 40 Z",
              },
            ],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
