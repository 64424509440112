import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AlignHori from "./AlignHori";
import Divider from "./formComponents/Divider";
import ColorPicker from "./formComponents/ColorPicker";
import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const IconEditor = ({ sb: { selectedElement, startStyles } }) => {
  const [iconClassname, setIconClassname] = useState("");
  useEffect(() => {
    setIconClassname(formGetStartValue(startStyles, "selectedIcon"));
  }, [selectedElement]);

  return (
    <>
      <div className="my-3">
        <div className="mb-3 d-flex align-items-center">
          <span className="me-2">{translate("cEditForms.iconEditor_selected", false, null)}:</span>
          <span className="fontSize15">
            <span id={`${EDIT_FORM_ID_PREFIX}selectedIcon`} data-editform="iconEditor" className={iconClassname}></span>
          </span>
        </div>
        <button className="btn btn-light" data-bs-toggle="modal" data-bs-target="#ModalSelectIcon">
          {translate("cEditForms.iconEditor_selectOther", false, null)}
        </button>
      </div>
      <Divider />
      <ColorPicker
        label={translate("cEditForms.iconEditor_color", false, null)}
        id={"textColor"}
        startValue={formGetStartValue(startStyles, "textColor")}
        tooltipText={translate("cEditForms.iconEditor_ttColor", false, null)}
      />
      <Divider />
      <Slider
        label={translate("cEditForms.iconEditor_size", false, null)}
        id={"fontSize"}
        min={0.5}
        max={4}
        step={0.1}
        startValue={formGetStartValue(startStyles, "fontSize")}
        tooltipText={translate("cEditForms.iconEditor_ttSize", false, null)}
      />
      <Divider />
      <AlignHori />
    </>
  );
};

IconEditor.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(IconEditor);
