import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";
import Select from "./formComponents/Select";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const Animate = ({ sb: { startStyles } }) => {
  return (
    <>
      <Select
        label={translate("cEditForms.animationType", false, null)}
        id={"animationType"}
        options={[
          { val: "", label: translate("cEditForms.noAnimation", false, null) },
          // Fade
          { val: "fade", label: translate("cEditForms.fadein", false, null) },
          { val: "fade-up", label: translate("cEditForms.fadeinSlideUp", false, null) },
          { val: "fade-down", label: translate("cEditForms.fadeinSlideDown", false, null) },
          { val: "fade-left", label: translate("cEditForms.fadeinSlideLeft", false, null) },
          { val: "fade-right", label: translate("cEditForms.fadeinSlideRight", false, null) },
          { val: "fade-up-right", label: translate("cEditForms.fadeinSlideUpRight", false, null) },
          { val: "fade-up-left", label: translate("cEditForms.fadeinSlideUpLeft", false, null) },
          { val: "fade-down-right", label: translate("cEditForms.fadeinSlideDownRight", false, null) },
          { val: "fade-down-left", label: translate("cEditForms.fadeinSlideDownLeft", false, null) },
          // Flip
          { val: "flip-up", label: translate("cEditForms.flipUp", false, null) },
          { val: "flip-down", label: translate("cEditForms.flipDown", false, null) },
          { val: "flip-left", label: translate("cEditForms.flipLeft", false, null) },
          { val: "flip-right", label: translate("cEditForms.flipRight", false, null) },
          // Slide
          { val: "slide-up", label: translate("cEditForms.slideUp", false, null) },
          { val: "slide-down", label: translate("cEditForms.slideDown", false, null) },
          { val: "slide-left", label: translate("cEditForms.slideLeft", false, null) },
          { val: "slide-right", label: translate("cEditForms.slideRight", false, null) },
          // Zoom
          { val: "zoom-in", label: translate("cEditForms.zoomin", false, null) },
          { val: "zoom-in-up", label: translate("cEditForms.zoominUp", false, null) },
          { val: "zoom-in-down", label: translate("cEditForms.zoominDown", false, null) },
          { val: "zoom-in-left", label: translate("cEditForms.zoominLeft", false, null) },
          { val: "zoom-in-right", label: translate("cEditForms.zoominRight", false, null) },
          { val: "zoom-out", label: translate("cEditForms.zoomout", false, null) },
          { val: "zoom-out-up", label: translate("cEditForms.zoomoutUp", false, null) },
          { val: "zoom-out-down", label: translate("cEditForms.zoomoutDown", false, null) },
          { val: "zoom-out-left", label: translate("cEditForms.zoomoutLeft", false, null) },
          { val: "zoom-out-right", label: translate("cEditForms.zoomoutRight", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "animationType")}
        tooltipText={translate("cEditForms.tooltipAnimationType", false, null)}
      />
      <Select
        label={translate("cEditForms.repeat", false, null)}
        id={"animationRepeat"}
        options={[
          { val: "false", label: translate("cEditForms.once", false, null) },
          { val: "true", label: translate("cEditForms.repeat", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "animationRepeat")}
        tooltipText={translate("cEditForms.tooltipRepeat", false, null)}
      />
      <Slider
        label={translate("cEditForms.delaySeconds", false, null)}
        id={"animationDelay"}
        min={0}
        max={2}
        step={0.1}
        startValue={formGetStartValue(startStyles, "animationDelay")}
        tooltipText={translate("cEditForms.tooltipDelay", false, null)}
      />
      <Slider
        label={translate("cEditForms.durationSeconds", false, null)}
        id={"animationDuration"}
        min={0}
        max={3}
        step={0.1}
        startValue={formGetStartValue(startStyles, "animationDuration")}
        tooltipText={translate("cEditForms.tooltipDuration", false, null)}
      />
      <Slider
        label={translate("cEditForms.staggeredDelay", false, null)}
        id={"animationStaggeredDelay"}
        min={0}
        max={1}
        step={0.1}
        startValue={formGetStartValue(startStyles, "animationStaggeredDelay")}
        tooltipText={translate("cEditForms.tooltipStaggeredDelay", false, null)}
      />
    </>
  );
};

Animate.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Animate);
