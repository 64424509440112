export const statistics2 = {
  id: "statistics-2",
  name: "Statistics 2",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "statistics-2-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "statistics-2-statswrapper", "w-100"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
            styles: [],
            attributes: [{ property: "data-name", value: "statistics2" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "statistics-2-stat-col"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["statistics-2-stat-number", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "4", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["toGrow", "statistics-2-stat-caption", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Years active",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
            styles: [],
            attributes: [{ property: "data-name", value: "statistics2" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "statistics-2-stat-col"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["statistics-2-stat-number", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "27", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["toGrow", "statistics-2-stat-caption", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Employees", children: [] },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
            styles: [],
            attributes: [{ property: "data-name", value: "statistics2" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "statistics-2-stat-col"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["statistics-2-stat-number", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "200+", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["toGrow", "statistics-2-stat-caption", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Projects delivered",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
            styles: [],
            attributes: [{ property: "data-name", value: "statistics2" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "statistics-2-stat-col"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["statistics-2-stat-number", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "€20m", children: [] },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["toGrow", "statistics-2-stat-caption", "w-100", "w-md-100", "w-lg-100"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Funding", children: [] },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.statistics-2-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.statistics-2-component", pseudo: "hover", rules: [] },
      {
        className: "statistics-2-component",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "statistics-2-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "statistics-2-stat-col",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "statistics-2-stat-col",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "statistics-2-stat-number",
        pseudo: "",
        rules: [
          { property: "font-size", value: "20px" },
          { property: "font-family", value: '"Montserrat"' },
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "statistics-2-stat-caption",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
    ],
  },
};
