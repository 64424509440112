export const card11 = {
  id: "card-11",
  name: "Card 11",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "card-11-component"],
    styles: [],
    attributes: [
      { property: "data-name", value: "carousel" },
      { property: "data-editable", value: "true" },
    ],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["splide", "carousel-slides", "card-11-splide"],
        styles: [],
        attributes: [
          { property: "aria-label", value: "Carousel" },
          {
            property: "data-splide",
            value:
              '{"type":"loop","rewindSpeed":1000,"speed":500,"easing":"linear","drag":"free","perPage":3,"arrows":true,"pagination":true,"breakpoints":{"640":{"perPage":1,"perMove":1}},"perMove":3,"keyboard":true,"autoplay":true,"interval":2000,"pauseOnHover":true,"classes":{"arrow":"splide__arrow card-11-arrow","prev":"splide__arrow--prev card-11-prev","next":"splide__arrow--next card-11-next","page":"splide__pagination__page card-11-pagination"}}',
          },
          { property: "data-carousel-slidesetup", value: "image&text" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["splide__track"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["splide__list"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "card-11-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "card-11-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-12", "col-lg-12", "card-11-col"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "card6" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["flexSameHeight", "card-11-wrapper"],
                                styles: [],
                                attributes: [{ property: "data-elementgetter1", value: "true" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["card-11-border"],
                                    styles: [],
                                    attributes: [{ property: "data-elementgetter2", value: "true" }],
                                    content: "",
                                    children: [],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "h4",
                                    classes: ["card-11-title", "toGrow"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "h" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "This is a great title",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["card-11-text"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "p" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content:
                                          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod ex, optio repudiandae voluptates magni minus.",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "card-11-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "card-11-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-12", "col-lg-12", "card-11-col"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "card6" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["flexSameHeight", "card-11-wrapper"],
                                styles: [],
                                attributes: [{ property: "data-elementgetter1", value: "true" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["card-11-border"],
                                    styles: [],
                                    attributes: [{ property: "data-elementgetter2", value: "true" }],
                                    content: "",
                                    children: [],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "h4",
                                    classes: ["card-11-title", "toGrow"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "h" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "This is a great title",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["card-11-text"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "p" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content:
                                          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod ex, optio repudiandae voluptates magni minus.",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "card-11-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "card-11-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-12", "col-lg-12", "card-11-col"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "card6" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["flexSameHeight", "card-11-wrapper"],
                                styles: [],
                                attributes: [{ property: "data-elementgetter1", value: "true" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["card-11-border"],
                                    styles: [],
                                    attributes: [{ property: "data-elementgetter2", value: "true" }],
                                    content: "",
                                    children: [],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "h4",
                                    classes: ["card-11-title", "toGrow"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "h" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "This is a great title",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["card-11-text"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "p" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content:
                                          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod ex, optio repudiandae voluptates magni minus.",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "card-11-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "card-11-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-12", "col-lg-12", "card-11-col"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "card6" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["flexSameHeight", "card-11-wrapper"],
                                styles: [],
                                attributes: [{ property: "data-elementgetter1", value: "true" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["card-11-border"],
                                    styles: [],
                                    attributes: [{ property: "data-elementgetter2", value: "true" }],
                                    content: "",
                                    children: [],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "h4",
                                    classes: ["card-11-title", "toGrow"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "h" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "This is a great title",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["card-11-text"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "p" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content:
                                          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod ex, optio repudiandae voluptates magni minus.",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "card-11-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "card-11-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-12", "col-lg-12", "card-11-col"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "card6" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["flexSameHeight", "card-11-wrapper"],
                                styles: [],
                                attributes: [{ property: "data-elementgetter1", value: "true" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["card-11-border"],
                                    styles: [],
                                    attributes: [{ property: "data-elementgetter2", value: "true" }],
                                    content: "",
                                    children: [],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "h4",
                                    classes: ["card-11-title", "toGrow"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "h" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "This is a great title",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["card-11-text"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "p" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content:
                                          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod ex, optio repudiandae voluptates magni minus.",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "card-11-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "card-11-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-12", "col-lg-12", "card-11-col"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "card6" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["flexSameHeight", "card-11-wrapper"],
                                styles: [],
                                attributes: [{ property: "data-elementgetter1", value: "true" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "div",
                                    classes: ["card-11-border"],
                                    styles: [],
                                    attributes: [{ property: "data-elementgetter2", value: "true" }],
                                    content: "",
                                    children: [],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "h4",
                                    classes: ["card-11-title", "toGrow"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "h" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "This is a great title",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["card-11-text"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "p" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content:
                                          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod ex, optio repudiandae voluptates magni minus.",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.card-11-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.card-11-component", pseudo: "hover", rules: [] },
      {
        className: "card-11-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "2rem 2rem 2rem 2rem" },
          { property: "display", value: "flex" },
        ],
      },
      {
        className: "card-11-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0.75rem" },
          { property: "padding", value: "0 0 0 0" },
        ],
      },
      { className: "card-11-wrapper", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" }] },
      {
        className: "card-11-border",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "left", value: "0" },
          { property: "height", value: "10px" },
          { property: "width", value: "100%" },
          { property: "background", value: "rgba(229, 42, 5, 1)" },
        ],
      },
      {
        className: "card-11-border",
        pseudo: "hover",
        rules: [{ property: "background", value: "rgba(229, 42, 5, 1)" }],
      },
      {
        className: "card-11-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "1.5rem 1rem 0.5rem 1rem" },
          { property: "font-weight", value: "bold" },
          { property: "color", value: "rgba(109, 118, 126, 1)" },
        ],
      },
      {
        className: "card-11-title",
        pseudo: "hover",
        rules: [],
      },
      { className: "card-11-text", pseudo: "", rules: [{ property: "margin", value: "0 1rem 1.5rem 1rem" }] },
      { className: "card-11-text", pseudo: "hover", rules: [] },
      // Pagination & navigation
      {
        className: "card-11-arrow",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.5)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-radius", value: "50%" },
          { property: "height", value: "2rem" },
          { property: "width", value: "2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "card-11-arrow",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "card-11-arrow svg",
        pseudo: "",
        rules: [
          { property: "fill", value: "rgba(0, 0, 0, 0.7)" },
          { property: "height", value: "1.2rem" }, // (size - 0.8)rem
          { property: "width", value: "1.2rem" }, // (size - 0.8)rem
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "card-11-arrow svg",
        pseudo: "hover",
        rules: [{ property: "fill", value: "rgba(0, 0, 0, 1)" }],
      },
      {
        className: "card-11-prev",
        pseudo: "",
        rules: [
          { property: "left", value: "1rem" }, // Inside: 1rem || Outside: -(size + 1)rem
        ],
      },
      {
        className: "card-11-next",
        pseudo: "",
        rules: [
          { property: "right", value: "1rem" }, // Inside: 1rem, outside: -(size+1)rem
        ],
      },
      {
        className: "card-11-pagination",
        pseudo: "",
        rules: [
          { property: "height", value: "0.7rem" },
          { property: "width", value: "0.7rem" },
          { property: "background", value: "rgba(255, 255, 255, 0.5)" },
          { property: "margin", value: "0.25rem" },
          { property: "border-radius", value: "50%" },
          { property: "display", value: "inline-block" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "card-11-pagination",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.8)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scale(1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "is-active.card-11-pagination",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.8)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scale(1)" },
          { property: "opacity", value: "1" },
        ],
      },
    ],
  },
};
