import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Border from "./Border";
import BorderRadius from "./BorderRadius";
import ImgAdjust from "./ImgAdjust";
import BoxShadow from "./BoxShadow";
import Transition from "./Transition";
import Divider from "./formComponents/Divider";
import ImgSource from "./ImgSource";

const ImgGallery2 = ({ sb: { startStyles } }) => {
  return (
    <>
      {/* Image */}
      <ImgSource />
      <Divider />
      {/* Wrapper */}
      <Border />
      <Divider />
      <BorderRadius />
      <Divider />
      <BoxShadow />
      <Divider />
      <Transition />
      <Divider />
      {/* Img */}
      <ImgAdjust />
    </>
  );
};

ImgGallery2.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(ImgGallery2);
