export const contactus3 = {
  id: "contactus-3",
  name: "Contact us 3",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "contactus-3-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "justify-content-center"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "p-2", "contactus-3-googlemaps-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["contactus-3-googlemaps-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-name", value: "googlemaps_wrapper" },
                  { property: "data-editable", value: "true" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "iframe",
                classes: [],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "googlemaps" },
                  {
                    property: "src",
                    value:
                      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8680.352904042613!2d4.899406522082844!3d52.37329199453203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609b70dd81623%3A0xcae71b8d3adfd142!2sAmsterdam%20Centraal!5e0!3m2!1sen!2snl!4v1662818615871!5m2!1sen!2snl",
                  },
                  { property: "scrolling", value: "no" },
                  { property: "loading", value: "lazy" },
                  { property: "referrerpolicy", value: "no-referrer-when-downgrade" },
                  { property: "width", value: "100%" },
                  { property: "height", value: "100%" },
                  { property: "frameborder", value: "0" },
                ],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "p-2"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "block",
                htmlTagName: "div",
                classes: ["row", "justify-content-center", "align-items-stretch", "contactus-3-row"],
                styles: [],
                attributes: [{ property: "data-name", value: "block" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-4", "col-lg-4", "contactus-3-col"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "element_col" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "button",
                        classes: ["contactus-3-iconwrapper"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-location-dot"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["contactus-3-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Street 1337",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["contactus-3-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Postal code, City",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-4", "col-lg-4", "contactus-3-col"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "element_col" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "button",
                        classes: ["contactus-3-iconwrapper"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-envelope"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["contactus-3-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "email@domain.com",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-4", "col-lg-4", "contactus-3-col"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "element_col" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "button",
                        classes: ["contactus-3-iconwrapper"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-phone"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["contactus-3-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "02013 37 000",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["col-12", "p-2"],
                    styles: [],
                    attributes: [
                      { property: "data-sbform", value: "true" },
                      { property: "data-sbformaction", value: "" },
                      { property: "data-sbformmsg", value: "Your message has been received!" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["w-100", "contactus-3-inputwrapper"],
                        styles: [],
                        attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "label",
                            classes: ["form-control", "contactus-3-label"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "inputlabel" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Your name",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "input",
                            classes: ["form-control", "contactus-3-inputfield"],
                            styles: [],
                            attributes: [
                              { property: "type", value: "text" },
                              { property: "name", value: "name" },
                              { property: "placeholder", value: "Your name" },
                              { property: "data-name", value: "inputfield" },
                              { property: "data-required", value: "true" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["w-100", "contactus-3-inputwrapper"],
                        styles: [],
                        attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "label",
                            classes: ["form-control", "contactus-3-label"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "inputlabel" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Your email",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "input",
                            classes: ["form-control", "contactus-3-inputfield"],
                            styles: [],
                            attributes: [
                              { property: "type", value: "email" },
                              { property: "name", value: "email" },
                              { property: "placeholder", value: "Your email address" },
                              { property: "data-name", value: "inputfield" },
                              { property: "data-required", value: "true" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["w-100", "contactus-3-inputwrapper"],
                        styles: [],
                        attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "label",
                            classes: ["form-control", "contactus-3-label"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "inputlabel" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Subject",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "input",
                            classes: ["form-control", "contactus-3-inputfield"],
                            styles: [],
                            attributes: [
                              { property: "type", value: "text" },
                              { property: "name", value: "subject" },
                              { property: "placeholder", value: "Subject" },
                              { property: "data-name", value: "inputfield" },
                              { property: "data-required", value: "true" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["w-100", "contactus-3-inputwrapper"],
                        styles: [],
                        attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "label",
                            classes: ["form-control", "contactus-3-label"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "inputlabel" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Your message",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textarea",
                            classes: ["form-control", "contactus-3-inputfield"],
                            styles: [],
                            attributes: [
                              { property: "rows", value: "3" },
                              { property: "name", value: "message" },
                              { property: "placeholder", value: "Message" },
                              { property: "data-name", value: "inputfield" },
                              { property: "data-required", value: "true" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["contactus-3-buttonwrapper"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "button",
                            classes: ["contactus-3-button"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "button" },
                              { property: "data-sbformbtn", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Send message!",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.contactus-3-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.contactus-3-component", pseudo: "hover", rules: [] },
      {
        className: "contactus-3-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "align-items", value: "center" },
          { property: "text-align", value: "center" },
          { property: "margin", value: "0 0 2rem 0" },
        ],
      },
      {
        className: "contactus-3-iconwrapper",
        pseudo: "",
        rules: [
          { property: "height", value: "65px" },
          { property: "width", value: "65px" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "font-size", value: "1.5rem" },
          { property: "border-radius", value: "50%" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
          { property: "margin", value: "0 0 1rem 0" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "cursor", value: "default !important" },
        ],
      },
      {
        className: "contactus-3-iconwrapper",
        pseudo: "hover",
        rules: [
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      { className: "contactus-3-text", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
      { className: "contactus-3-text", pseudo: "hover", rules: [] },
      {
        className: "contactus-3-googlemaps-col",
        pseudo: "",
        rules: [
          { property: "overflow", value: "hidden" },
          { property: "position", value: "relative" },
        ],
      },
      {
        className: "contactus-3-googlemaps-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "bottom", value: "0" },
        ],
      },
      {
        className: "contactus-3-buttonwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "contactus-3-buttonwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "contactus-3-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
          { property: "width", value: "auto" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "contactus-3-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "contactus-3-inputwrapper",
        pseudo: "",
        rules: [{ property: "margin", value: "0 0 1rem 0" }],
      },
      {
        className: "contactus-3-inputfield",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(206, 212, 218, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.2s ease" },
          { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
          { property: "font-size", value: "1rem" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
          { property: "outline", value: "0" },
        ],
      },
      {
        className: "contactus-3-inputfield",
        pseudo: "focus",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
        ],
      },
      {
        className: "contactus-3-inputfield",
        pseudo: ":placeholder",
        rules: [
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "contactus-3-inputfield",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "contactus-3-label",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.25rem 0" },
          { property: "display", value: "none" },
        ],
      },
      {
        className: "contactus-3-label",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
