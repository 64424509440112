export const sectiondividers36 = {
  id: "sectiondividers-36",
  name: "Section dividers 36",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "svg",
        classes: [],
        styles: [
          { property: "transform", value: "scaleY(-1)" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
        ],
        attributes: [
          { property: "xmlns", value: "http://www.w3.org/2000/svg" },
          { property: "height", value: "150px" },
          { property: "width", value: "100%" },
          { property: "viewBox", value: "0 0 1000 407.65" },
          { property: "preserveAspectRatio", value: "none" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              { property: "opacity", value: "0.25" },
              {
                property: "d",
                value:
                  "M751.32 187.47C613 235 462.72 218.9 318.61 225 200.89 230 48.86 251.53 0 407.65h1000V0c-64.23 92.19-152 154.24-248.68 187.47z",
              },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              {
                property: "d",
                value:
                  "M777.61 210.75c-123.72 42.52-258.09 28.11-387 33.57-105.24 4.46-241.2 23.68-284.89 163.33H1000V43.1C942.56 125.54 864.09 181 777.61 210.75z",
              },
            ],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
