import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { startDragFromMenu, endDragFromMenu } from "../../../actions/sb";

const PreviewTemplate = ({ component, startDragFromMenu, endDragFromMenu }) => {
  const onDragStartFromMenu = (e, componentId) => {
    // Specify it is a copy, not a move
    e.dataTransfer.effectAllowed = "copy";
    // Make it work with firefox
    e.dataTransfer.setData("text", "");
    // Set state vars so componentList can receive data
    startDragFromMenu(componentId);
  };

  const onDragEndFromMenu = (e) => {
    endDragFromMenu();
  };

  return (
    <>
      <img
        src={`/assets/componentPreviews/${component.id}.png`}
        alt={component.name}
        title={component.name}
        className="templatePreview"
        draggable="true"
        onDragStart={(e) => onDragStartFromMenu(e, component.id)}
        onDragEnd={(e) => onDragEndFromMenu(e)}
      />
    </>
  );
};

PreviewTemplate.propTypes = {
  //   sb: PropTypes.object.isRequired,
  startDragFromMenu: PropTypes.func.isRequired,
  endDragFromMenu: PropTypes.func.isRequired,
};

// const mapStateToProps = (state) => ({
//   sb: state.sb,
// });

export default connect(null, { startDragFromMenu, endDragFromMenu })(PreviewTemplate);
