export const template10 = {
  id: "6398d380245ed23e48d53eb8",
  name: { en: "Consultancy agency", nl: "Adviesbureay" },
  desc: { en: "Single-page site for a consultancy agency", nl: "Een-pagina site voor een adviesbureau" },
  categories: ["services", "coaching"],
  sbPages: [
    {
      pageId: "b20d703e-c7eb-404e-bde9-01262c560b24",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "JypXLr",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-4-component-JypXLr"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "IlYf1Q",
              type: "",
              htmlTagName: "div",
              classes: ["hero-4-wrapper-JypXLr"],
              styles: [],
              attributes: [{ property: "data-herowrapper", value: "true" }],
              content: "",
              children: [
                {
                  childId: "lhViSS",
                  type: "",
                  htmlTagName: "img",
                  classes: ["hero-4-img-JypXLr"],
                  styles: [],
                  attributes: [
                    { property: "alt", value: "Image" },
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "img" },
                    { property: "data-imgresizable", value: "false" },
                    { property: "src", value: "https://cdn.satonda.com/eb/assets/mountain4.png" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "wPVgxX",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-4-content-JypXLr"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "hero4_contentwrapper" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "qvINeB",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-4-title-JypXLr", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Reach the top with us!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "F9NUUH",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-4-text-JypXLr", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "pvGuTN",
                      type: "",
                      htmlTagName: "a",
                      classes: ["hero-4-button-JypXLr"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Read more",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Fi2Rr2",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-19-component-Fi2Rr2"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "EKxQlj",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "f0y1zT",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-19-col-Fi2Rr2"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "NwpTPz",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-19-wrapper-Fi2Rr2"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "gYWQZt",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["toGrow", "card-19-title-Fi2Rr2"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Services",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "PyvSsF",
                          type: "",
                          htmlTagName: "p",
                          classes: ["card-19-subtitle-Fi2Rr2", "w-90", "w-md-90", "w-lg-90"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget eros dictum, semper enim id, vulputate turpis. Etiam sapien risus, ullamcorper in metus quis, imperdiet tristique felis.",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "KGRWmC",
                          type: "",
                          htmlTagName: "a",
                          classes: ["card-19-button-Fi2Rr2"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_self" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "More info",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "eO6r0N",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-19-col-Fi2Rr2"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "g19cyw",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-19-wrapper-Fi2Rr2"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "skMhpy",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["toGrow", "card-19-title-Fi2Rr2"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Projects",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "ff7EOX",
                          type: "",
                          htmlTagName: "p",
                          classes: ["card-19-subtitle-Fi2Rr2", "w-90", "w-md-90", "w-lg-90"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget eros dictum, semper enim id, vulputate turpis. Etiam sapien risus, ullamcorper in metus quis, imperdiet tristique felis.",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "t9bYdz",
                          type: "",
                          htmlTagName: "a",
                          classes: ["card-19-button-Fi2Rr2"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_self" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "More info",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "DShDPM",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "card-19-col-Fi2Rr2"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "aYcdJr",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-19-wrapper-Fi2Rr2"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "q5Tl8O",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["toGrow", "card-19-title-Fi2Rr2"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Team",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "fIptqE",
                          type: "",
                          htmlTagName: "p",
                          classes: ["card-19-subtitle-Fi2Rr2", "w-90", "w-md-90", "w-lg-90"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget eros dictum, semper enim id, vulputate turpis. Etiam sapien risus, ullamcorper in metus quis, imperdiet tristique felis.",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Hu0YbN",
                          type: "",
                          htmlTagName: "a",
                          classes: ["card-19-button-Fi2Rr2"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_self" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "More info",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "I6czqZ",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-1-component-I6czqZ"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "T6mJuq",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-1-wrapper-I6czqZ"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "CiwmT2",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-1-content-I6czqZ"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "bRZXWm",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["parallax-1-title-I6czqZ", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "About us",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "Izp3SI",
                      type: "",
                      htmlTagName: "p",
                      classes: ["parallax-1-text-I6czqZ", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "FYEVqH",
                      type: "",
                      htmlTagName: "p",
                      classes: ["qTIB8e1c5Sk9w5QxK1gUiHLkHF88zR", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum. ",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "qpaykk",
                      type: "",
                      htmlTagName: "a",
                      classes: ["parallax-1-button-I6czqZ"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: " Read more ",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ldLW3m",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-ldLW3m"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Kl9wvA",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-ldLW3m", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Clients", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "OCU6I0",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "clients-2-component-OCU6I0"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "DrW8Ur",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-center", "justify-content-center", "clients-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "NzLHW2",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper-OCU6I0"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "vjHhFM",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-OCU6I0"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "zzYkuB",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper-OCU6I0"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "frOMXM",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-OCU6I0"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "EDuKk2",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper-OCU6I0"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "xXiNvI",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-OCU6I0"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-3.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "FCBh9g",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper-OCU6I0"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "UuiXpi",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-OCU6I0"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-4.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "nINjz6",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-nINjz6"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "CRw4XG",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-nINjz6", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Testimonials", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "fd9ZEq",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-22-component-fd9ZEq"],
          styles: [],
          attributes: [
            { property: "data-name", value: "carousel" },
            { property: "data-editable", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "mD6G4Z",
              type: "",
              htmlTagName: "div",
              classes: ["splide", "carousel-slides", "card-22-splide"],
              styles: [],
              attributes: [
                { property: "aria-label", value: "Carousel" },
                {
                  property: "data-splide",
                  value:
                    '{"type":"loop","rewindSpeed":1000,"speed":500,"easing":"linear","drag":"free","perPage":1,"arrows":true,"pagination":false,"breakpoints":{"640":{"perPage":1,"perMove":1}},"perMove":1,"keyboard":true,"autoplay":false,"interval":2000,"pauseOnHover":true,"classes":{"arrow":"splide__arrow card-22-arrow-fd9ZEq","prev":"splide__arrow--prev card-22-prev-fd9ZEq","next":"splide__arrow--next card-22-next-fd9ZEq","page":"splide__pagination__page card-22-pagination-fd9ZEq"}}',
                },
                { property: "data-carousel-slidesetup", value: "image&text" },
              ],
              content: "",
              children: [
                {
                  childId: "zE6LZG",
                  type: "",
                  htmlTagName: "div",
                  classes: ["splide__track"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "caEKog",
                      type: "",
                      htmlTagName: "div",
                      classes: ["splide__list"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "UwW86h",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "card-22-slide-wrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "bwWj9y",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "align-items-start", "justify-content-center"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "j4mQhs",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-4", "col-lg-4", "card-22-col-fd9ZEq"],
                                  styles: [],
                                  attributes: [{ property: "data-name", value: "element_col" }],
                                  content: "",
                                  children: [
                                    {
                                      childId: "r9bgl8",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "card-22-wrapper-fd9ZEq"],
                                      styles: [],
                                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "hJ8NXC",
                                          type: "",
                                          htmlTagName: "h6",
                                          classes: ["toGrow", "card-22-title-fd9ZEq"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "strong",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: '"',
                                                  children: [],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "strong",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "em",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content:
                                                        "Etiam odio mi, condimentum vel elit id, dapibus rhoncus eros. In tincidunt maximus massa sit amet malesuada.",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "strong",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: '"',
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "u4GTPj",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["card-22-subtitle-fd9ZEq"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Robert Lahey",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "rvLgW5",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["mcYLEry3L8sEI5CwAL6QD3oWdQqXxx"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Key account manager",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "wH8JgD",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "card-22-slide-wrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "HwGR7C",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "align-items-start", "justify-content-center"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "ErjWln",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-4", "col-lg-4", "card-22-col-fd9ZEq"],
                                  styles: [],
                                  attributes: [{ property: "data-name", value: "element_col" }],
                                  content: "",
                                  children: [
                                    {
                                      childId: "fJgAEK",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "card-22-wrapper-fd9ZEq"],
                                      styles: [],
                                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "F3TQb9",
                                          type: "",
                                          htmlTagName: "h6",
                                          classes: ["toGrow", "card-22-title-fd9ZEq"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "anDDqM",
                                              type: "",
                                              htmlTagName: "strong",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: '"',
                                                  children: [],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "rPu4XX",
                                              type: "",
                                              htmlTagName: "strong",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "n2NHPB",
                                                  type: "",
                                                  htmlTagName: "em",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Donec condimentum scelerisque est, ut blandit ex porta nec.",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "RNGvcl",
                                              type: "",
                                              htmlTagName: "strong",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: '"',
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "h4M9Gt",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["card-22-subtitle-fd9ZEq"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Alicia Jane",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "uWH9NF",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["mcYLEry3L8sEI5CwAL6QD3oWdQqXxx"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "SEO expert",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "kzq5BE",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "card-22-slide-wrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "cOTPFl",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "align-items-start", "justify-content-center"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "hMHaY4",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-4", "col-lg-4", "card-22-col-fd9ZEq"],
                                  styles: [],
                                  attributes: [{ property: "data-name", value: "element_col" }],
                                  content: "",
                                  children: [
                                    {
                                      childId: "bDk3Hg",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "card-22-wrapper-fd9ZEq"],
                                      styles: [],
                                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "G9lFLh",
                                          type: "",
                                          htmlTagName: "h6",
                                          classes: ["toGrow", "card-22-title-fd9ZEq"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "J0Pb6E",
                                              type: "",
                                              htmlTagName: "strong",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: '"',
                                                  children: [],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "wdIqBn",
                                              type: "",
                                              htmlTagName: "strong",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "tcHrIt",
                                                  type: "",
                                                  htmlTagName: "em",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Sed varius erat eget luctus vulputate. Donec posuere rhoncus tortor.",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "xJ6SVj",
                                              type: "",
                                              htmlTagName: "strong",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: '"',
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "MkrwwM",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["card-22-subtitle-fd9ZEq"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "David Johnson",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "jtlKPW",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["mcYLEry3L8sEI5CwAL6QD3oWdQqXxx"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Product marketeer",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "tdSl3f",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-tdSl3f"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "nN6Kig",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-tdSl3f", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "R1LJgW",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-8-component-R1LJgW"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "otlRXJ",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-8-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "YYTt41",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-R1LJgW"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-R1LJgW"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-R1LJgW"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "name" },
                            { property: "placeholder", value: "Your name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-R1LJgW"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-R1LJgW"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-R1LJgW"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-R1LJgW"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-R1LJgW"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-R1LJgW"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-R1LJgW"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-R1LJgW"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-8-inputfield-R1LJgW"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-8-buttonwrapper-R1LJgW"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-8-button-R1LJgW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "fFRsfG",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "footer-5-component-fFRsfG"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "x4kugY",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "footer-5-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "PJzqdw",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "footer-5-col-fFRsfG"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Ds0eFo",
                      type: "",
                      htmlTagName: "h4",
                      classes: ["footer-5-title-fFRsfG"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "COMPANY NAME",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "qtPzgF",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-5-text-fFRsfG"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Street name and number",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "a7vWsF",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-5-text-fFRsfG"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Postal code and city",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "C6UczP",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-5-text-fFRsfG"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Country", children: [] },
                      ],
                    },
                    {
                      childId: "fo3sKQ",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-5-text-fFRsfG"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "kYOqhu",
                          type: "",
                          htmlTagName: "strong",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Phone: ",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "100 200 3000",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "wNs92H",
                      type: "",
                      htmlTagName: "p",
                      classes: ["footer-5-text-fFRsfG"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "qiJtFg",
                          type: "",
                          htmlTagName: "strong",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email: ",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "email@address.com",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "xOGTFZ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "footer-5-col-fFRsfG"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "hMKtGG",
                      type: "",
                      htmlTagName: "h4",
                      classes: ["footer-5-title-fFRsfG"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "MENU", children: [] },
                      ],
                    },
                    {
                      childId: "R5wFmf",
                      type: "",
                      htmlTagName: "a",
                      classes: ["footer-5-menu-link-fFRsfG"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_blank" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "a" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Home", children: [] },
                      ],
                    },
                    {
                      childId: "qKFxYF",
                      type: "",
                      htmlTagName: "a",
                      classes: ["footer-5-menu-link-fFRsfG"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_blank" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "a" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "About us",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "bV2Atp",
                      type: "",
                      htmlTagName: "a",
                      classes: ["footer-5-menu-link-fFRsfG"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_blank" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "a" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Projects",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "n2z2hg",
                      type: "",
                      htmlTagName: "a",
                      classes: ["footer-5-menu-link-fFRsfG"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_blank" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "a" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Clients", children: [] },
                      ],
                    },
                  ],
                },
                {
                  childId: "pLvoIP",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "footer-5-col-fFRsfG"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "giKLwG",
                      type: "",
                      htmlTagName: "h4",
                      classes: ["footer-5-title-fFRsfG"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "SOCIALS", children: [] },
                      ],
                    },
                    {
                      childId: "pHMRwe",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["footer-5-socialwrapper"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "sxHQF3",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["footer-5-social-link-fFRsfG"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "a" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "Pr7Or5",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-twitter"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "cBFKoz",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["footer-5-social-link-fFRsfG"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "a" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "hx9ZuI",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-linkedin"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "SnNOa0",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["footer-5-social-link-fFRsfG"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "a" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "tjUmWM",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-facebook-f"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "6398c2dd2bd1e7e85faa1ce4",
    },
  ],
  sbCustomCss: [
    {
      componentId: "JypXLr",
      classes: [
        {
          className: "section.hero-4-component-JypXLr",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "section.hero-4-component-JypXLr", pseudo: "hover", rules: [] },
        {
          className: "hero-4-component-JypXLr",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "hero-4-wrapper-JypXLr",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "80vh" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "hero-4-content-JypXLr",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "bottom", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-4-content-JypXLr",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-4-img-JypXLr",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "hero-4-img-JypXLr",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-4-title-JypXLr",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 107, 223, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "2rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "hero-4-button-JypXLr",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(5, 108, 218, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(5, 108, 218, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-4-button-JypXLr",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(5, 108, 218, 1)" },
            { property: "border-color", value: "rgba(5, 108, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-4-text-JypXLr",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 4.5rem 2rem 4.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
      ],
    },
    {
      componentId: "Fi2Rr2",
      classes: [
        {
          className: "section.card-19-component-Fi2Rr2",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "3rem" },
          ],
        },
        { className: "section.card-19-component-Fi2Rr2", pseudo: "hover", rules: [] },
        {
          className: "card-19-wrapper-Fi2Rr2",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 211, 217, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-items", value: "center" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-19-wrapper-Fi2Rr2",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-color", value: "rgba(206, 211, 217, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-19-col-Fi2Rr2",
          pseudo: "",
          rules: [
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
          ],
        },
        { className: "card-19-col-Fi2Rr2", pseudo: "hover", rules: [] },
        {
          className: "card-19-title-Fi2Rr2",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 107, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-19-title-Fi2Rr2", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-19-button-Fi2Rr2",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(5, 107, 223, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(5, 107, 223, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "card-19-button-Fi2Rr2",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(5, 107, 223, 1)" },
            { property: "border-color", value: "rgba(5, 107, 223, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-19-subtitle-Fi2Rr2",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-19-subtitle-Fi2Rr2", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "I6czqZ",
      classes: [
        {
          className: "section.parallax-1-component-I6czqZ",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-1-component-I6czqZ", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-component-I6czqZ",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-1-wrapper-I6czqZ",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "50vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/mountain3.png")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-1-title-I6czqZ",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 107, 223, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-1-title-I6czqZ", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "parallax-1-content-I6czqZ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 0.6)" },
          ],
        },
        {
          className: "parallax-1-button-I6czqZ",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(5, 108, 218, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(5, 108, 218, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(5, 108, 218, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1.5rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "parallax-1-button-I6czqZ",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(5, 108, 218, 1)" },
            { property: "border-color", value: "rgba(5, 108, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 6px 3px rgba(5, 109, 213, 0.35)" },
          ],
        },
        {
          className: "qTIB8e1c5Sk9w5QxK1gUiHLkHF88zR",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 107, 223, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0.25rem 0rem 1.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "qTIB8e1c5Sk9w5QxK1gUiHLkHF88zR", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "parallax-1-text-I6czqZ",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 107, 223, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1.5rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-1-text-I6czqZ", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "ldLW3m",
      classes: [
        {
          className: "section.basics-1-component-ldLW3m",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-1-component-ldLW3m", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-ldLW3m",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-ldLW3m", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-ldLW3m",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 107, 223, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-ldLW3m", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "OCU6I0",
      classes: [
        {
          className: "section.clients-2-component-OCU6I0",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.clients-2-component-OCU6I0", pseudo: "hover", rules: [] },
        {
          className: "clients-2-wrapper-OCU6I0",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(194, 197, 199)" },
            { property: "height", value: "75px" },
          ],
        },
        {
          className: "clients-2-logo-OCU6I0",
          pseudo: "",
          rules: [
            { property: "max-height", value: "50px" },
            { property: "max-width", value: "100px" },
            { property: "height", value: "auto" },
            { property: "width", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "clients-2-logo-OCU6I0", pseudo: "hover", rules: [{ property: "transform", value: "scale(1.15)" }] },
      ],
    },
    {
      componentId: "nINjz6",
      classes: [
        {
          className: "section.basics-1-component-nINjz6",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-1-component-nINjz6", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-nINjz6",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-nINjz6", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-nINjz6",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 107, 223, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-nINjz6", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "fd9ZEq",
      classes: [
        {
          className: "section.card-22-component-fd9ZEq",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-22-component-fd9ZEq", pseudo: "hover", rules: [] },
        { className: "card-22-col-fd9ZEq", pseudo: "", rules: [] },
        { className: "card-22-col-fd9ZEq", pseudo: "hover", rules: [] },
        { className: "card-22-wrapper-fd9ZEq", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
        { className: "card-22-wrapper-fd9ZEq", pseudo: "hover", rules: [] },
        {
          className: "card-22-arrow-fd9ZEq",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "height", value: "2rem" },
            { property: "width", value: "2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "card-22-arrow-fd9ZEq",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "card-22-arrow-fd9ZEq svg",
          pseudo: "",
          rules: [
            { property: "fill", value: "rgba(0, 0, 0, 0.7)" },
            { property: "height", value: "1.2rem" },
            { property: "width", value: "1.2rem" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "card-22-arrow-fd9ZEq svg", pseudo: "hover", rules: [{ property: "fill", value: "rgba(0, 0, 0, 1)" }] },
        { className: "card-22-prev-fd9ZEq", pseudo: "", rules: [{ property: "left", value: "1rem" }] },
        { className: "card-22-next-fd9ZEq", pseudo: "", rules: [{ property: "right", value: "1rem" }] },
        {
          className: "card-22-pagination-fd9ZEq",
          pseudo: "",
          rules: [
            { property: "height", value: "0.7rem" },
            { property: "width", value: "0.7rem" },
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "margin", value: "0.25rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "inline-block" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "card-22-pagination-fd9ZEq",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "is-active.card-22-pagination-fd9ZEq",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "v0Lfb9Y0tc7EqQueK94QXkMDZB6h8p", pseudo: "", rules: [] },
        { className: "v0Lfb9Y0tc7EqQueK94QXkMDZB6h8p", pseudo: "hover", rules: [] },
        {
          className: "mcYLEry3L8sEI5CwAL6QD3oWdQqXxx",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "mcYLEry3L8sEI5CwAL6QD3oWdQqXxx", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-22-subtitle-fd9ZEq",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 107, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0.125rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-22-subtitle-fd9ZEq", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-22-title-fd9ZEq",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-22-title-fd9ZEq", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "R1LJgW",
      classes: [
        {
          className: "section.contactus-8-component-R1LJgW",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-8-component-R1LJgW", pseudo: "hover", rules: [] },
        {
          className: "contactus-8-col-R1LJgW",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-8-buttonwrapper-R1LJgW",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "contactus-8-buttonwrapper-R1LJgW",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-8-button-R1LJgW",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(5, 107, 223, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(5, 107, 223, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "contactus-8-button-R1LJgW",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(5, 107, 223, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "contactus-8-inputwrapper-R1LJgW",
          pseudo: "",
          rules: [{ property: "margin", value: "0 0 1rem 0" }],
        },
        {
          className: "contactus-8-inputfield-R1LJgW",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "border-style", value: "none none solid none" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-8-inputfield-R1LJgW",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(106, 115, 122, 1)" },
            { property: "border-color", value: "rgba(5, 107, 223, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-8-inputfield-R1LJgW",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "contactus-8-inputfield-R1LJgW",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-8-label-R1LJgW",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        {
          className: "contactus-8-label-R1LJgW",
          pseudo: "hover",
          rules: [],
        },
      ],
    },
    {
      componentId: "tdSl3f",
      classes: [
        {
          className: "section.basics-1-component-tdSl3f",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-tdSl3f", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-tdSl3f",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-tdSl3f", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-tdSl3f",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(5, 107, 223, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-tdSl3f", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "fFRsfG",
      classes: [
        {
          className: "section.footer-5-component-fFRsfG",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "2rem" },
          ],
        },
        { className: "section.footer-5-component-fFRsfG", pseudo: "hover", rules: [] },
        { className: "footer-5-col-fFRsfG", pseudo: "", rules: [{ property: "padding", value: "1rem 1rem 1rem 1rem" }] },
        {
          className: "footer-5-text-fFRsfG",
          pseudo: "",
          rules: [
            { property: "text-align", value: "justify" },
            { property: "margin", value: "0 0 0 0" },
            { property: "font-size", value: "15px" },
          ],
        },
        {
          className: "footer-5-menu-link-fFRsfG",
          pseudo: "",
          rules: [
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "font-size", value: "15px" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "display", value: "block" },
          ],
        },
        { className: "footer-5-menu-link-fFRsfG", pseudo: "hover", rules: [{ property: "color", value: "rgba(5, 107, 223, 1)" }] },
        {
          className: "footer-5-title-fFRsfG",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(5, 107, 223, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "footer-5-social-link-fFRsfG",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "color", value: "rgba(5, 108, 218, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0.5rem 0.5rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "align-self", value: "start" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        {
          className: "footer-5-social-link-fFRsfG",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(114, 178, 227, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "footer-5-social-link-fFRsfG", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Inter:100,300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Inter",
    fontHeadersWeight: "700",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(5, 107, 223, 1)",
    color2: "rgba(75, 199, 170, 1)",
    color3: "rgba(118, 180, 228, 1)",
    color4: "rgba(169, 228, 213, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
