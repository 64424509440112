export const hero1 = {
  id: "hero-1",
  name: "Hero 1",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "hero-1-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["hero-1-wrapper"],
        styles: [],
        attributes: [{ property: "data-herowrapper", value: "true" }],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "img",
            classes: ["hero-1-img"],
            styles: [],
            attributes: [
              {
                property: "src",
                value: "https://cdn.satonda.com/eb/assets/hero-1.png",
              },
              { property: "alt", value: "Image" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["hero-1-content"],
            styles: [],
            attributes: [
              { property: "data-name", value: "hero1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["hero-1-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Some appealing title!",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["hero-1-text", "w-100", "w-md-70", "w-lg-50"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "a",
                classes: ["hero-1-button"],
                styles: [],
                attributes: [
                  { property: "href", value: "#!" },
                  { property: "data-href", value: "#!" },
                  { property: "data-target", value: "_self" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "button" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Read more",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.hero-1-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      {
        className: "section.hero-1-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-1-component",
        pseudo: "",
        rules: [
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "hero-1-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-1-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "height", value: "100vh" },
        ],
      },
      {
        className: "hero-1-img",
        pseudo: "",
        rules: [
          { property: "display", value: "block" },
          { property: "height", value: "100%" },
          { property: "width", value: "100%" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "hero-1-content",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "background", value: "rgba(0, 0, 0, 0.3)" },
        ],
      },
      {
        className: "hero-1-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
          { property: "margin", value: "0 0 1rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "hero-1-title",
        pseudo: "hover",
        rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }],
      },
      {
        className: "hero-1-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
          { property: "margin", value: "0 0 2rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "hero-1-text",
        pseudo: "hover",
        rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }],
      },
      {
        className: "hero-1-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-radius", value: "1000px" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
          { property: "text-decoration", value: "none" },
        ],
      },
      {
        className: "hero-1-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(0, 0, 0, 1)" },
        ],
      },
    ],
  },
};
