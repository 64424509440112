export const cv2 = {
  id: "cv-2",
  name: "CV 2",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "resume-2-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "resume-2-row"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-10", "col-lg-6", "resume-2-wrappercol"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["resume-2-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-name", value: "h" },
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                ],
                content: "",
                children: [
                  { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Resume", children: [] },
                ],
              },
              {
                childId: "",
                type: "block",
                htmlTagName: "div",
                classes: ["resume-2-blockwrapper"],
                styles: [],
                attributes: [{ property: "data-name", value: "block" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["resume-2-elementwrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["resume-2-blocktitle", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "h" },
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Experience",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "div",
                        classes: ["resume-2-div-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "resume2" },
                          { property: "data-editable", value: "true" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "h4",
                            classes: ["resume-2-itemtitle", "w-100", "w-md-100", "w-lg-100"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "h" },
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "2021 - Present",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["row", "resume-2-itemrow"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["col-12", "col-md-4", "col-lg-4", "resume-2-itemcol"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-position1", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "JOB POSITION",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-position2", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Company name",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-position3", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Location",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["col-12", "col-md-8", "col-lg-8", "resume-2-itemcol"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-itemdesc", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content:
                                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur similique itaque quos, natus dolorum eaque!",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-itemdesc", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem, cupiditate!",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "div",
                        classes: ["resume-2-div-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "resume2" },
                          { property: "data-editable", value: "true" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "h4",
                            classes: ["resume-2-itemtitle", "w-100", "w-md-100", "w-lg-100"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "h" },
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "2018 - 2021",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["row", "resume-2-itemrow"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["col-12", "col-md-4", "col-lg-4", "resume-2-itemcol"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-position1", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "JOB POSITION",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-position2", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Company name",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-position3", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Location",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["col-12", "col-md-8", "col-lg-8", "resume-2-itemcol"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-itemdesc", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content:
                                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur similique itaque quos, natus dolorum eaque!",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-itemdesc", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem, cupiditate!",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["resume-2-elementwrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["resume-2-blocktitle", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "h" },
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Education",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "div",
                        classes: ["resume-2-div-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "resume2" },
                          { property: "data-editable", value: "true" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "h4",
                            classes: ["resume-2-itemtitle", "w-100", "w-md-100", "w-lg-100"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "h" },
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "2017 - 2018",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["row", "resume-2-itemrow"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["col-12", "col-md-4", "col-lg-4", "resume-2-itemcol"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-position1", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "INSTITUTION NAME",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-position2", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Degree",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-position3", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Location",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["col-12", "col-md-8", "col-lg-8", "resume-2-itemcol"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-itemdesc", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content:
                                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur similique itaque quos, natus dolorum eaque!",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-itemdesc", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem, cupiditate!",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "div",
                        classes: ["resume-2-div-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "resume2" },
                          { property: "data-editable", value: "true" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "h4",
                            classes: ["resume-2-itemtitle", "w-100", "w-md-100", "w-lg-100"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "h" },
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "2014 - 2017",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["row", "resume-2-itemrow"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["col-12", "col-md-4", "col-lg-4", "resume-2-itemcol"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-position1", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "INSTITUTION NAME",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-position2", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Degree",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-position3", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Location",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["col-12", "col-md-8", "col-lg-8", "resume-2-itemcol"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-itemdesc", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content:
                                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur similique itaque quos, natus dolorum eaque!",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["resume-2-itemdesc", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "p" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem, cupiditate!",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["resume-2-elementwrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "div",
                        classes: ["resume-2-div-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "resume2" },
                          { property: "data-editable", value: "true" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "block",
                            htmlTagName: "div",
                            classes: ["resume-2-bulletitemwrapper"],
                            styles: [],
                            attributes: [{ property: "data-name", value: "block" }],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "element",
                                htmlTagName: "div",
                                classes: ["resume-2-bulletitem"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "h3",
                                    classes: ["resume-2-bullettitle", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "h" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Skills",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "block",
                                    htmlTagName: "div",
                                    classes: ["row", "resume-2-itemrow"],
                                    styles: [],
                                    attributes: [{ property: "data-name", value: "block" }],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "element",
                                        htmlTagName: "div",
                                        classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["resume-2-bullettext"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-name", value: "p" },
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-listmarker", value: "true" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Lorem ipsum dolor sit amet",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        childId: "",
                                        type: "element",
                                        htmlTagName: "div",
                                        classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["resume-2-bullettext"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-name", value: "p" },
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-listmarker", value: "true" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Lorem ipsum dolor sit amet",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        childId: "",
                                        type: "element",
                                        htmlTagName: "div",
                                        classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["resume-2-bullettext"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-name", value: "p" },
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-listmarker", value: "true" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Lorem ipsum dolor sit amet",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        childId: "",
                                        type: "element",
                                        htmlTagName: "div",
                                        classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["resume-2-bullettext"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-name", value: "p" },
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-listmarker", value: "true" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Lorem ipsum dolor sit amet",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "element",
                                htmlTagName: "div",
                                classes: ["resume-2-bulletitem"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "h3",
                                    classes: ["resume-2-bullettitle", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-name", value: "h" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Languages",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "block",
                                    htmlTagName: "div",
                                    classes: ["row", "resume-2-itemrow"],
                                    styles: [],
                                    attributes: [{ property: "data-name", value: "block" }],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "element",
                                        htmlTagName: "div",
                                        classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["resume-2-bullettext"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-name", value: "p" },
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-listmarker", value: "true" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "English",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        childId: "",
                                        type: "element",
                                        htmlTagName: "div",
                                        classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["resume-2-bullettext"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-name", value: "p" },
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-listmarker", value: "true" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "Spanish",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        childId: "",
                                        type: "element",
                                        htmlTagName: "div",
                                        classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol"],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "p",
                                            classes: ["resume-2-bullettext"],
                                            styles: [],
                                            attributes: [
                                              { property: "data-name", value: "p" },
                                              { property: "data-editable", value: "true" },
                                              { property: "data-texteditable", value: "true" },
                                              { property: "data-listmarker", value: "true" },
                                            ],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "textNode",
                                                classes: [],
                                                styles: [],
                                                attributes: [],
                                                content: "French",
                                                children: [],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.resume-2-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.resume-2-component", pseudo: "hover", rules: [] },
      {
        className: "resume-2-wrappercol",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "resume-2-wrappercol",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-2-elementwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "resume-2-elementwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-2-itemcol",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "resume-2-itemcol",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-2-title",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 4.5rem 0" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "resume-2-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-2-blocktitle",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "3rem 0 2rem 0" },
        ],
      },
      {
        className: "resume-2-blocktitle",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-2-div-item",
        pseudo: "",
        rules: [
          { property: "padding", value: "3rem 2rem 3rem 2rem" },
          { property: "margin", value: "0 0 2rem 0" },
          { property: "background", value: "rgba(250, 250, 250, 1)" },
          { property: "box-shadow", value: "0px 0px 20px 4px rgba(0, 0, 0, 0.25)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0.4rem" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(214, 214, 214, 1)" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "resume-2-div-item",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(250, 250, 250, 1)" },
          { property: "box-shadow", value: "0px 0px 20px 4px rgba(0, 0, 0, 0.25)" },
          { property: "border-color", value: "rgba(214, 214, 214, 1)" },
        ],
      },
      {
        className: "resume-2-itemtitle",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 1rem 0" },
          { property: "color", value: "rgba(22, 145, 114, 1)" },
        ],
      },
      {
        className: "resume-2-itemtitle",
        pseudo: "hover",
        rules: [],
      },
      { className: "resume-2-position1", pseudo: "", rules: [{ property: "margin", value: "0 0 0.5rem 0" }] },
      { className: "resume-2-position1", pseudo: "hover", rules: [] },
      {
        className: "resume-2-position2",
        pseudo: "",
        rules: [
          { property: "font-size", value: "15px" },
          { property: "margin", value: "0 0 0.5rem 0" },
        ],
      },
      {
        className: "resume-2-position2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-2-position3",
        pseudo: "",
        rules: [
          { property: "font-size", value: "15px" },
          { property: "margin", value: "0 0 0.5rem 0" },
        ],
      },
      {
        className: "resume-2-position3",
        pseudo: "hover",
        rules: [],
      },
      { className: "resume-2-itemdesc", pseudo: "", rules: [{ property: "margin", value: "0 0 0.5rem 0" }] },
      { className: "resume-2-itemdesc", pseudo: "hover", rules: [] },
      { className: "resume-2-bulletitem", pseudo: "not(:first-child)", rules: [{ property: "margin", value: "2rem 0 0 0" }] },
      {
        className: "resume-2-bullettitle",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 1.5rem 0" },
        ],
      },
      {
        className: "resume-2-bullettitle",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-2-skillitemcol",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "align-items", value: "flex-start" },
        ],
      },
      {
        className: "resume-2-skillitemcol",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "resume-2-bullettext",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 1rem 0" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
        ],
      },
      { className: "resume-2-bullettext", pseudo: "hover", rules: [] },
      {
        className: "resume-2-bullettext",
        pseudo: "before",
        rules: [
          { property: "height", value: "13px" },
          { property: "width", value: "13px" },
          { property: "background", value: "rgba(22, 145, 114, 1)" },
          { property: "margin-right", value: "0.5rem" },
          { property: "border-radius", value: "0rem" },
          { property: "content", value: '""' },
          { property: "display", value: "inline-block" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(22, 145, 114, 1)" },
        ],
      },
    ],
  },
};
