export const card12 = {
  id: "card-12",
  name: "Card 12",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "card-12-component"],
    styles: [],
    attributes: [
      { property: "data-name", value: "carousel" },
      { property: "data-editable", value: "true" },
    ],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["splide", "carousel-slides", "card-12-splide"],
        styles: [],
        attributes: [
          { property: "aria-label", value: "Carousel" },
          {
            property: "data-splide",
            value:
              '{"type":"loop","rewindSpeed":1000,"speed":500,"easing":"linear","drag":"free","perPage":1,"arrows":true,"pagination":false,"breakpoints":{"640":{"perPage":1,"perMove":1}},"perMove":1,"keyboard":true,"autoplay":false,"interval":2000,"pauseOnHover":true,"classes":{"arrow":"splide__arrow card-12-arrow","prev":"splide__arrow--prev card-12-prev","next":"splide__arrow--next card-12-next","page":"splide__pagination__page card-12-pagination"}}',
          },
          { property: "data-carousel-slidesetup", value: "image&text" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["splide__track"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["splide__list"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "card-12-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "card-12-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-6", "col-lg-4", "card-12-col"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "card7" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "h2",
                                classes: ["card-12-title", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "p" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "This is a title",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["card-12-desc-text", "w-90", "w-md-90", "w-lg-90"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "p" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-6", "col-lg-4", "order-last", "card-12-col"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "card7" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "img",
                                classes: ["card-12-img"],
                                styles: [],
                                attributes: [
                                  { property: "src", value: "https://cdn.satonda.com/eb/assets/work-1.png" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-name", value: "img" },
                                  { property: "data-imgresizable", value: "false" },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "card-12-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "card-12-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-6", "col-lg-4", "card-12-col"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "card7" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "h2",
                                classes: ["card-12-title", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "p" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "This is a title",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["card-12-desc-text", "w-90", "w-md-90", "w-lg-90"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "p" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-6", "col-lg-4", "order-last", "card-12-col"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "card7" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "img",
                                classes: ["card-12-img"],
                                styles: [],
                                attributes: [
                                  { property: "src", value: "https://cdn.satonda.com/eb/assets/work-2.png" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-name", value: "img" },
                                  { property: "data-imgresizable", value: "false" },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "card-12-slide-wrapper"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["row", "justify-content-center", "align-items-stretch", "card-12-row"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-6", "col-lg-4", "card-12-col"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "card7" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "h2",
                                classes: ["card-12-title", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "p" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "This is a title",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["card-12-desc-text", "w-90", "w-md-90", "w-lg-90"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "p" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["col-12", "col-md-6", "col-lg-4", "order-last", "card-12-col"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "card7" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "img",
                                classes: ["card-12-img"],
                                styles: [],
                                attributes: [
                                  { property: "src", value: "https://cdn.satonda.com/eb/assets/work-3.png" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-name", value: "img" },
                                  { property: "data-imgresizable", value: "false" },
                                ],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.card-12-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.card-12-component", pseudo: "hover", rules: [] },
      {
        className: "card-12-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0.75rem" },
          { property: "padding", value: "0 0 0 0" },
        ],
      },
      { className: "card-12-wrapper", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" }] },
      {
        className: "card-12-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "overflow", value: "hidden" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(214, 214, 214, 1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0rem" },
        ],
      },
      {
        className: "card-12-col",
        pseudo: "hover",
        rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }],
      },
      {
        className: "card-12-img",
        pseudo: "",
        rules: [
          { property: "width", value: "100%" },
          { property: "max-height", value: "350px" },
          { property: "height", value: "auto" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "card-12-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-12-title",
        pseudo: "",
        rules: [
          { property: "font-family", value: '"Montserrat"' },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "margin", value: "0 0.5rem 0.5rem 1.5rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      { className: "card-12-title", pseudo: "hover", rules: [] },
      {
        className: "card-12-desc-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(10, 10, 10, 1)" },
          { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      // Pagination & navigation
      {
        className: "card-12-arrow",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.5)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-radius", value: "50%" },
          { property: "height", value: "2rem" },
          { property: "width", value: "2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "card-12-arrow",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "card-12-arrow svg",
        pseudo: "",
        rules: [
          { property: "fill", value: "rgba(0, 0, 0, 0.7)" },
          { property: "height", value: "1.2rem" }, // (size - 0.8)rem
          { property: "width", value: "1.2rem" }, // (size - 0.8)rem
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "card-12-arrow svg",
        pseudo: "hover",
        rules: [{ property: "fill", value: "rgba(0, 0, 0, 1)" }],
      },
      {
        className: "card-12-prev",
        pseudo: "",
        rules: [
          { property: "left", value: "1rem" }, // Inside: 1rem || Outside: -(size + 1)rem
        ],
      },
      {
        className: "card-12-next",
        pseudo: "",
        rules: [
          { property: "right", value: "1rem" }, // Inside: 1rem, outside: -(size+1)rem
        ],
      },
      {
        className: "card-12-pagination",
        pseudo: "",
        rules: [
          { property: "height", value: "0.7rem" },
          { property: "width", value: "0.7rem" },
          { property: "background", value: "rgba(255, 255, 255, 0.5)" },
          { property: "margin", value: "0.25rem" },
          { property: "border-radius", value: "50%" },
          { property: "display", value: "inline-block" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "card-12-pagination",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.8)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scale(1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "is-active.card-12-pagination",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.8)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scale(1)" },
          { property: "opacity", value: "1" },
        ],
      },
    ],
  },
};
