export const sectiondividers42 = {
  id: "sectiondividers-42",
  name: "Section dividers 42",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: [],
        styles: [
          { property: "position", value: "relative" },
          { property: "height", value: "232px" },
        ],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "svg",
            classes: [],
            styles: [
              { property: "position", value: "absolute" },
              { property: "top", value: "0" },
              { property: "left", value: "0" },
              { property: "width", value: "100%" },
              { property: "height", value: "232px" },
              { property: "transform", value: "scaleY(-1)" },
              { property: "background", value: "rgba(255, 255, 255, 1)" },
            ],
            attributes: [
              { property: "xmlns", value: "http://www.w3.org/2000/svg" },
              { property: "viewBox", value: "0 0 4200 512" },
              { property: "preserveAspectRatio", value: "xMidYMin slice" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [{ property: "isolation", value: "isolate" }],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M200 500l100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100V400l-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100L0 400v100l100-100z",
                  },
                  { property: "opacity", value: "0.75" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M4200 500l-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100L0 500v12h4200z",
                  },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [{ property: "isolation", value: "isolate" }],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M200 400l100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100V300l-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100L0 300v100l100-100z",
                  },
                  { property: "opacity", value: "0.5" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [{ property: "isolation", value: "isolate" }],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M200 300l100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100V200l-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100-100 100-100-100L0 200v100l100-100z",
                  },
                  { property: "opacity", value: "0.25" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "path",
                classes: [],
                styles: [{ property: "isolation", value: "isolate" }],
                attributes: [
                  { property: "fill", value: "rgba(204, 204, 204, 1)" },
                  {
                    property: "d",
                    value:
                      "M200 200l100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100 100-100 100 100V100L4100 0l-100 100L3900 0l-100 100L3700 0l-100 100L3500 0l-100 100L3300 0l-100 100L3100 0l-100 100L2900 0l-100 100L2700 0l-100 100L2500 0l-100 100L2300 0l-100 100L2100 0l-100 100L1900 0l-100 100L1700 0l-100 100L1500 0l-100 100L1300 0l-100 100L1100 0l-100 100L900 0 800 100 700 0 600 100 500 0 400 100 300 0 200 100 100 0 0 100v100l100-100z",
                  },
                  { property: "opacity", value: "0.12" },
                ],
                content: "",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
