export const parallax3 = {
  id: "parallax-3",
  name: "Parallax 3",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "parallax-3-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["parallax-3-wrapper"],
        styles: [],
        attributes: [
          { property: "data-name", value: "parallax" },
          { property: "data-editable", value: "true" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["parallax-3-overlaywrapper"],
            styles: [],
            attributes: [{ property: "data-parallaxcontent", value: "true" }], // this is the name that is caught in getstylefunctions
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["container", "parallax-3-containercontent"],
                styles: [],
                attributes: [{ property: "data-parallax-containercontent", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["parallax-3-content", "w-100", "w-md-60", "w-lg-40"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "parallax3_content" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h1",
                        classes: ["parallax-3-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Some appealing title!",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["parallax-3-text", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "a",
                        classes: ["parallax-3-button"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_self" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Read more",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.parallax-3-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      {
        className: "section.parallax-3-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "parallax-3-component",
        pseudo: "",
        rules: [
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "parallax-3-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "height", value: "100vh" },
          { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/parallax-3.png")' },
          { property: "background-size", value: "cover" },
          { property: "background-position", value: "center" },
          { property: "background-attachment", value: "fixed" },
          { property: "background-repeat", value: "no-repeat" },
        ],
      },
      {
        className: "parallax-3-overlaywrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "align-items", value: "flex-start" },
          { property: "justify-content", value: "center" },
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "background", value: "rgba(0, 0, 0, 0)" },
        ],
      },
      {
        className: "parallax-3-containercontent",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "padding", value: "1.5rem 1.5rem 1.5rem 1.5rem" },
        ],
      },
      {
        className: "parallax-3-content",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "border-radius", value: "1rem" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "parallax-3-content",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(0, 0, 0, 0)" },
        ],
      },
      {
        className: "parallax-3-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(40, 40, 40, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
          { property: "margin", value: "4.5rem 0rem 1rem 0rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "parallax-3-title",
        pseudo: "hover",
        rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }],
      },
      {
        className: "parallax-3-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(40, 40, 40, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" },
          { property: "margin", value: "1.5rem 0rem 1rem 0rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "parallax-3-text",
        pseudo: "hover",
        rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0)" }],
      },
      {
        className: "parallax-3-button",
        pseudo: "",
        rules: [
          { property: "display", value: "inline-block" },
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(40, 40, 40, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(40, 40, 40, 1)" },
          { property: "border-radius", value: "1000px" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "1.5rem 0rem 4.5rem 0rem" },
          { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
          { property: "text-decoration", value: "none" },
          { property: "align-self", value: "start" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "parallax-3-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(40, 40, 40, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(40, 40, 40, 1)" },
        ],
      },
    ],
  },
};
