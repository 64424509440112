export const template19 = {
  id: "65fe08f18dce5e4b9d7f4855",
  name: { en: "Bridal beauty salon", nl: "Bruiloft schoonheidsspecialist" },
  desc: { en: "Website for a bridal beauty salon", nl: "Website voor een schoonheidsspecialist" },
  categories: ["beauty"],
  sbPages: [
    {
      pageId: "47b68343-1e1a-4082-952a-4f006c93ab5d",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "LmCoxu",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-4-component-LmCoxu"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "rOO2Si",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-4-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "OjJ4IV",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "basics-4-col-LmCoxu"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "ywSykP",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-4-img-LmCoxu"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/beauty5.jpg" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "A7Xe2z",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-4-col-LmCoxu"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "YmgOxt",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-4-title-LmCoxu", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Hair & make-up studio",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "h2rJzM",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["basics-4-text-LmCoxu", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "It's your special day",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "UBNcbX",
                      type: "",
                      htmlTagName: "a",
                      classes: ["basics-4-link-readmore-LmCoxu"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                        { property: "data-href", value: "/index.html#contact" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Get in touch",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "oM36CW",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-5-component-oM36CW"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "oTpJnU",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-5-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "WAVVi1",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-first", "basics-5-col-oM36CW"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "C1S32m",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-5-img-oM36CW"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/beauty3.jpg" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "gPb9nI",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-5-col-oM36CW"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "I7ByxK",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["basics-5-title-oM36CW", "w-100", "w-md-100", "w-lg-80"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "We'll make sure you feel your best",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "DmW4Qo",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-5-text-oM36CW", "w-100", "w-md-100", "w-lg-80"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "em",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                              children: [],
                            },
                          ],
                        },
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: " ", children: [] },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "rmuSKW",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-rmuSKW"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "RoT5cJ",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-rmuSKW", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Featured in", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "oJh0nv",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "clients-2-component-oJh0nv"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ip8Xvp",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-center", "justify-content-center", "clients-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "e4a46M",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper-oJh0nv"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "T6FBou",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-oJh0nv"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        {
                          property: "src",
                          value: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/VOGUE_LOGO.svg/2560px-VOGUE_LOGO.svg.png",
                        },
                        { property: "alt", value: "logo" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "hCBszv",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper-oJh0nv"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "L9gA7P",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-oJh0nv"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://www.lifestylemag.com/wp-content/themes/criaturo-lifestyle/assets/images/logo.svg" },
                        { property: "alt", value: "logo" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "TrZG7e",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper-oJh0nv"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "U1Uzeg",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-oJh0nv"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        {
                          property: "src",
                          value: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Cosmopolitan_logo.svg/2560px-Cosmopolitan_logo.svg.png",
                        },
                        { property: "alt", value: "logo" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "faOL1B",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-3", "col-lg-2", "clients-2-wrapper-oJh0nv"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ieOutG",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-2-logo-oJh0nv"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        {
                          property: "src",
                          value: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/ELLE_Magazine_Logo.svg/1280px-ELLE_Magazine_Logo.svg.png",
                        },
                        { property: "alt", value: "logo" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "IgRjws",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-IgRjws"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "bSXfTP",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-IgRjws", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "How we take care of you",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "UTfra7",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-10-component-UTfra7"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "hyMMXi",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "OrUjL1",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-UTfra7"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "IqgcLa",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-UTfra7"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "Ob6vje",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-UTfra7"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/beauty8.png" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "RxFRLz",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-UTfra7", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Hair",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "wc7y7M",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-UTfra7", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "lqrGdd",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-UTfra7"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "HtsPLp",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-UTfra7"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "uEDm7i",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-UTfra7"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/beauty6.png" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "BR5s2h",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-UTfra7", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Styling",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "YcZz4g",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-UTfra7", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "YgL8Eg",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-UTfra7"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "A97otN",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-UTfra7"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "HiiaUC",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-UTfra7"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/beauty9.png" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "L1OWON",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-UTfra7", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Make-up",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "iEkv6d",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-UTfra7", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "N6LHIn",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-UTfra7"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "zug4FV",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-UTfra7"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "vXUMWE",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-UTfra7"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/beauty10.png" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "y6jrmE",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-UTfra7", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Nails",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "ZIU88Z",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-UTfra7", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Ep6GfC",
          type: "component",
          htmlTagName: "div",
          classes: ["imggallery-2-component-Ep6GfC", "container-fluid"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "bWIFg5",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "imggallery-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "OLDSpd",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-Ep6GfC"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "BCnlOR",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-Ep6GfC"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "B5MhfN",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-Ep6GfC"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/beauty1.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "ZenV8L",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-Ep6GfC"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "gAOwRn",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-Ep6GfC"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Odvrj2",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-Ep6GfC"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/beauty13.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Lt2CFo",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-Ep6GfC"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "vv6wdg",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-Ep6GfC"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "kKwGqz",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-Ep6GfC"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/beauty14.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "E8IyEF",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-Ep6GfC"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "X77CGe",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-Ep6GfC"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "LGnrJH",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-Ep6GfC"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/beauty11.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "bByGeu",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-bByGeu"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Dj8XJC",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-bByGeu", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact us", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "CDnaMy",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-1-component-CDnaMy"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "contact" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Cn8EHj",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-1-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "SaunDS",
                  type: "block",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "contactus-1-col-CDnaMy"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "block" }],
                  content: "",
                  children: [
                    {
                      childId: "SLE5sU",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-1-contact-info-block-CDnaMy"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "c3z1hr",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-icon-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "w5Mo0J",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-location-dot"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "YbYuoP",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-content-CDnaMy"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "a7GqSH",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-CDnaMy-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Street 1337",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "ZS40Ss",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-CDnaMy-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Postal code, City",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "haBcwi",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-CDnaMy-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Country",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "MGhIEd",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-1-contact-info-block-CDnaMy"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "Kpy9CM",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-icon-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "kuBWKr",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-envelope"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "YAjKRa",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-content-CDnaMy"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "c22uwL",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-CDnaMy-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "email@domain.com",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "HdHwPw",
                      type: "element",
                      htmlTagName: "div",
                      classes: ["contactus-1-contact-info-block-CDnaMy"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "S5KO5z",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-icon-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "div" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "kFTQo2",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-phone"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "u4DhU9",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-1-contact-info-content-CDnaMy"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "yKLg1B",
                              type: "",
                              htmlTagName: "p",
                              classes: ["contactus-1-contact-info-content-CDnaMy-text"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "020 13 37 000",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "iJM3dF",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["d-flex", "flex-wrap"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "MrdX1K",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "yKRaU4",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-envelope"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "DEtX85",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "kTAxKR",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-twitter"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "oKsV5V",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "VpMM6G",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-linkedin"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "X1XgaS",
                          type: "element",
                          htmlTagName: "a",
                          classes: ["contactus-1-social-link-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "href", value: "#!" },
                            { property: "data-href", value: "#!" },
                            { property: "data-target", value: "_blank" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                            { property: "data-name", value: "button" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "NhVtPK",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-brands", "fa-facebook-f"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "BraBvH",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "O5QHAt",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-CDnaMy"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "avw8Hu",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "bmuTRh",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-1-inputfield-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "name" },
                            { property: "placeholder", value: "Your name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "b9dIK8",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-CDnaMy"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "llZ9Hm",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "A9uolQ",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-1-inputfield-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "eUGzO5",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-CDnaMy"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "XkOIGJ",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "zz5FYr",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-1-inputfield-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "F9DMUq",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-1-inputwrapper-CDnaMy"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "KSCEvM",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-1-label-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "CVzDr3",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-1-inputfield-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "bZ4tps",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-1-button-CDnaMywrapper-CDnaMy"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "Lvmv49",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-1-button-CDnaMy"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "65fe0847ce19f27d76704628",
    },
  ],
  sbCustomCss: [
    {
      componentId: "LmCoxu",
      classes: [
        {
          className: "section.basics-4-component-LmCoxu",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-4-component-LmCoxu", pseudo: "hover", rules: [] },
        {
          className: "basics-4-col-LmCoxu",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-4-img-LmCoxu",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-4-img-LmCoxu",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-4-text-LmCoxu",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-4-text-LmCoxu", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-4-link-readmore-LmCoxu",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(217, 185, 155, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "1rem 2rem 1rem 2rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1.5rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-4-link-readmore-LmCoxu",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(217, 185, 155, 1)" },
            { property: "border-color", value: "rgba(217, 185, 155, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-4-title-LmCoxu",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-4-title-LmCoxu", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "oM36CW",
      classes: [
        {
          className: "section.basics-5-component-oM36CW",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", pseudo: "", value: "rgba(251, 248, 244, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(251, 248, 244, 1)" },
          ],
        },
        { className: "section.basics-5-component-oM36CW", pseudo: "hover", rules: [] },
        {
          className: "basics-5-col-oM36CW",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-5-img-oM36CW",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-5-img-oM36CW",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-5-title-oM36CW",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-5-title-oM36CW", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-5-text-oM36CW",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-5-text-oM36CW", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "oJh0nv",
      classes: [
        {
          className: "section.clients-2-component-oJh0nv",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "3rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.clients-2-component-oJh0nv", pseudo: "hover", rules: [] },
        {
          className: "clients-2-logo-oJh0nv",
          pseudo: "",
          rules: [
            { property: "max-height", value: "50px" },
            { property: "max-width", value: "100px" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "clients-2-logo-oJh0nv",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1.15)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "clients-2-wrapper-oJh0nv",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "height", value: "75px" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(228, 214, 185, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
      ],
    },
    {
      componentId: "rmuSKW",
      classes: [
        {
          className: "section.basics-1-component-rmuSKW",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-rmuSKW", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-rmuSKW",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-rmuSKW", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-rmuSKW",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-rmuSKW", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "IgRjws",
      classes: [
        {
          className: "section.basics-1-component-IgRjws",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", pseudo: "", value: "rgba(251, 248, 244, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(251, 248, 244, 1)" },
          ],
        },
        { className: "section.basics-1-component-IgRjws", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-IgRjws",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-IgRjws", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-IgRjws",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-IgRjws", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "UTfra7",
      classes: [
        {
          className: "section.card-10-component-UTfra7",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "background", pseudo: "", value: "rgba(251, 248, 244, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(251, 248, 244, 1)" },
          ],
        },
        { className: "section.card-10-component-UTfra7", pseudo: "hover", rules: [] },
        { className: "card-10-col-UTfra7", pseudo: "", rules: [] },
        { className: "card-10-col-UTfra7", pseudo: "hover", rules: [] },
        { className: "card-10-wrapper-UTfra7", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
        { className: "card-10-wrapper-UTfra7", pseudo: "hover", rules: [] },
        {
          className: "card-10-avatar-UTfra7",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "64px" },
            { property: "height", value: "64px" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-avatar-UTfra7",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-10-title-UTfra7",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0.5rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-10-title-UTfra7", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-10-text-UTfra7",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-10-text-UTfra7", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Ep6GfC",
      classes: [
        {
          className: "section.imggallery-2-component-Ep6GfC",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.imggallery-2-component-Ep6GfC", pseudo: "hover", rules: [] },
        {
          className: "imggallery-2-col-Ep6GfC",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "stretch" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "imggallery-2-wrapper-Ep6GfC",
          pseudo: "",
          rules: [
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.4s ease-in-out" },
          ],
        },
        {
          className: "imggallery-2-wrapper-Ep6GfC",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "imggallery-2-img-Ep6GfC",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "transition", value: "all 0.4s ease-in-out" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
          ],
        },
        {
          className: "imggallery-2-img-Ep6GfC",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
          ],
        },
      ],
    },
    {
      componentId: "CDnaMy",
      classes: [
        {
          className: "section.contactus-1-component-CDnaMy",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.25rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.contactus-1-component-CDnaMy", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-col-CDnaMy",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "start" },
            { property: "justify-content", value: "center" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-1-contact-info-block-CDnaMy",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "margin", value: "0 0 1.5rem 0" },
          ],
        },
        {
          className: "contactus-1-contact-info-icon-CDnaMy",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "height", value: "25px" },
            { property: "width", value: "25px" },
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "contactus-1-contact-info-icon-CDnaMy", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-contact-info-content-CDnaMy",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 1.5rem" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "contactus-1-contact-info-content-CDnaMy > *", pseudo: "", rules: [{ property: "margin", value: "0" }] },
        {
          className: "contactus-1-button-CDnaMywrapper-CDnaMy",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-1-button-CDnaMywrapper-CDnaMy", pseudo: "hover", rules: [] },
        { className: "contactus-1-inputwrapper-CDnaMy", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        {
          className: "contactus-1-inputfield-CDnaMy",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(217, 185, 155, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-1-inputfield-CDnaMy",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "border-color", value: "rgba(217, 185, 155, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(217, 185, 155, 0.2)" },
          ],
        },
        {
          className: "contactus-1-inputfield-CDnaMy",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-1-inputfield-CDnaMy", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-label-CDnaMy",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-1-label-CDnaMy", pseudo: "hover", rules: [] },
        {
          className: "contactus-1-social-link-CDnaMy",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "height", value: "45px" },
            { property: "aspect-ratio", value: "1" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "background", value: "rgba(217, 185, 155, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0.5rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "font-size", value: "1rem" },
          ],
        },
        {
          className: "contactus-1-social-link-CDnaMy",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "background", value: "rgba(228, 214, 185, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "contactus-1-social-link-CDnaMy", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "contactus-1-button-CDnaMy",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(217, 185, 155, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(217, 185, 155, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "contactus-1-button-CDnaMy",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(228, 214, 185, 1)" },
            { property: "border-color", value: "rgba(217, 185, 155, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "bByGeu",
      classes: [
        {
          className: "section.basics-1-component-bByGeu",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-bByGeu", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-bByGeu",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-bByGeu", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-bByGeu",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(217, 185, 155, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-bByGeu", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Montserrat",
    fontHeadersWeight: "300",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(217, 185, 155, 1)",
    color2: "rgba(228, 214, 185, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
