import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Custom from "./formComponents/Custom";
import TextInput from "./formComponents/TextInput";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

// When image is selected from modal, startStyles.imgSource is updated to the selected image source

const ImgSource = ({ sb: { startStyles } }) => {
  const [imgDescValue, setImgDescValue] = useState("");
  useEffect(() => {
    setImgDescValue(formGetStartValue(startStyles, "imgDesc"));
  }, [formGetStartValue(startStyles, "imgDesc")]);

  const ImgSrc = () => {
    const [value, setValue] = useState("");
    useEffect(() => {
      setValue(formGetStartValue(startStyles, "imgSource"));
    }, [formGetStartValue(startStyles, "imgSource")]);

    return (
      <>
        <div className="d-flex mb-2">
          <span className="editPane-btn trans-3 cursorPointer px-3 py-2 rounded-4" data-bs-toggle="modal" data-bs-target="#ModalSelectImgFromGallery">
            <i className="fa-solid fa-images me-2"></i> {translate("cEditForms.selectFromGallery", false, null)}
          </span>
        </div>
        <input
          type="text"
          className="form-control form-control-sm"
          id={`${EDIT_FORM_ID_PREFIX}imgSource`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </>
    );
  };

  return (
    <>
      <Custom
        label={translate("cEditForms.imgSource", false, null)}
        column={true}
        customComponent={ImgSrc}
        tooltipText={translate("cEditForms.ttImgSource", false, null)}
      />
      <TextInput
        label={translate("cEditForms.imgDesc", false, null)}
        id={"imgDesc"}
        // startValue={formGetStartValue(startStyles, "imgDesc")}
        startValue={imgDescValue}
        tooltipText={translate("cEditForms.ttImgDesc", false, null)}
      />
    </>
  );
};

ImgSource.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(ImgSource);
