export const hero9 = {
  id: "hero-9",
  name: "Hero 9",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "hero-9-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "justify-content-center"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "order-first", "p-3", "hero-9-imgwrapper"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["hero-9-imgwrapper2"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["hero-9-img1"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/city3.jpg" },
                      { property: "alt", value: "Image" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["hero-9-img2"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/nature1.jpg" },
                      { property: "alt", value: "Image" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["hero-9-img3"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/city10.jpg" },
                      { property: "alt", value: "Image" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["hero-9-contentcol", "col-12", "col-md-6", "col-lg-4", "p-3"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["hero-9-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["hero-9-text1", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content:
                      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos saepe expedita nemo molestiae repudiandae, dolor aperiam velit esse veronam!",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["hero-9-text2", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content:
                      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos saepe expedita nemo molestiae repudiandae, dolor aperiam velit esse veronam!",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.hero-9-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.hero-9-component", pseudo: "hover", rules: [] },
      {
        className: "hero-9-contentcol",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "end" },
        ],
      },
      {
        className: "hero-9-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "margin", value: "0 0 2rem 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "hero-9-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-9-text1",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "margin", value: "0 0 1.5rem 0" },
          { property: "font-style", value: "italic" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "hero-9-text1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-9-text2",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "margin", value: "0 0 2rem 0" },
          { property: "font-style", value: "italic" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "hero-9-text2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-9-imgwrapper2",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "width", value: "100%" },
          { property: "aspect-ratio", value: "0.6" },
        ],
      },
      {
        className: "hero-9-imgwrapper2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-9-img1",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "left", value: "0" },
          { property: "width", value: "75%" },
          { property: "aspect-ratio", value: "0.7" },
          { property: "object-fit", value: "cover" },
          { property: "border-radius", value: "0rem" },
        ],
      },
      {
        className: "hero-9-img1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-9-img2",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "54%" },
          { property: "right", value: "0" },
          { property: "width", value: "80%" },
          { property: "aspect-ratio", value: "1.5" },
          { property: "object-fit", value: "cover" },
          { property: "border-radius", value: "0rem" },
        ],
      },
      {
        className: "hero-9-img2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-9-img3",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "74%" },
          { property: "left", value: "10%" },
          { property: "width", value: "65%" },
          { property: "aspect-ratio", value: "1.5" },
          { property: "object-fit", value: "cover" },
          { property: "border-radius", value: "0rem" },
        ],
      },
      {
        className: "hero-9-img3",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
