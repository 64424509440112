export const foodmenu3 = {
  id: "foodmenu-3",
  name: "Menu 3",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "menu-3-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "align-items-start", "justify-content-center"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-6", "menu-3-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["menu-3-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["menu-3-menu-textwrapper", "w-70"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-3-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Fines De Claires",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-3-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "French Fines De Claires Oysters Nr.3",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-3-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€25",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["menu-3-menu-imgwrapper", "w-30"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["menu-3-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/food-2.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "img" },
                          { property: "data-imgresizable", value: "false" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-6", "menu-3-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["menu-3-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["menu-3-menu-textwrapper", "w-70"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-3-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Entrecôte",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-3-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Entrecôte with French fries, vegetables and Béarnaise sauce or Madeira gravy",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-3-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€40",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["menu-3-menu-imgwrapper", "w-30"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["menu-3-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/food-5.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "img" },
                          { property: "data-imgresizable", value: "false" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-6", "menu-3-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["menu-3-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["menu-3-menu-textwrapper", "w-70"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-3-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lobster",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-3-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Whole lobster (700 grams) served lukewarm with green salad, parsley mayonaise and fries",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-3-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€50",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["menu-3-menu-imgwrapper", "w-30"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["menu-3-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/food-6.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "img" },
                          { property: "data-imgresizable", value: "false" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-6", "menu-3-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["menu-3-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["menu-3-menu-textwrapper", "w-70"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-3-line1", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Dessert",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-3-line2", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Hazelnut creme cake",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["menu-3-line3", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "€25",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["menu-3-menu-imgwrapper", "w-30"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "img",
                        classes: ["menu-3-img"],
                        styles: [],
                        attributes: [
                          { property: "src", value: "https://cdn.satonda.com/eb/assets/food-9.png" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "img" },
                          { property: "data-imgresizable", value: "false" },
                        ],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.menu-3-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.menu-3-component", pseudo: "hover", rules: [] },
      {
        className: "menu-3-col",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
        ],
      },
      { className: "menu-3-col", pseudo: "hover", rules: [] },
      {
        className: "menu-3-wrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(206, 211, 217, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0rem 0rem 0rem 0rem" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          { property: "overflow", value: "hidden" },
        ],
      },
      {
        className: "menu-3-wrapper",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "border-color", value: "rgba(206, 211, 217, 1)" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
        ],
      },
      {
        className: "menu-3-menu-textwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
        ],
      },
      { className: "menu-3-menu-textwrapper", pseudo: "hover", rules: [] },
      {
        className: "menu-3-column-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 1.5rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
          { property: "font-weight", value: "bold" },
        ],
      },
      {
        className: "menu-3-column-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "menu-3-line1",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.25rem 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
          { property: "font-weight", value: "bold" },
          { property: "color", value: "rgba(108, 117, 125, 1)" },
        ],
      },
      {
        className: "menu-3-line1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "menu-3-line2",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.25rem 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
          { property: "font-style", value: "italic" },
          { property: "color", value: "rgba(108, 117, 125, 1)" },
        ],
      },
      {
        className: "menu-3-line2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "menu-3-line3",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
          { property: "color", value: "rgba(108, 117, 125, 1)" },
        ],
      },
      {
        className: "menu-3-line3",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "menu-3-menu-imgwrapper",
        pseudo: "",
        rules: [{ property: "height", value: "160px" }],
      },
      {
        className: "menu-3-img",
        pseudo: "",
        rules: [
          { property: "width", value: "100%" },
          { property: "height", value: "100%" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "menu-3-img",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
