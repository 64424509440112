export const reorderArray = (array, from, to) => {
  if (to < array.length && to >= 0 && from !== to) {
    const temp = array[from];
    array = array.filter((element, i) => i !== from);
    array = [...array.slice(0, to), temp, ...array.slice(to)];
  }
  return array;
};

export const isValidDomain = (str) => {
  // eslint-disable-next-line
  return str.match(/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{2,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/) !== null;
};

export const isValidURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?(\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(str) || str.match(/^#\w+$/i) !== null || str.match(/mailto:.+@.+/) !== null;
};

export const arrayEquals = (a, b) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, i) => val === b[i]);
};

export const getNumberOfPages = (sbPages) => {
  try {
    // return sbPages.filter((page) => page.version === "A").length;
    return sbPages.length;
  } catch (error) {
    console.error(error);
    return 1;
  }
};

export const getSubscriptionMaxPages = (subs) => {
  try {
    let sbSub = getSbSubscription(subs);
    return parseInt(sbSub.packageDetails.filter((detail) => detail.name === "sbPages")[0].value);
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getSbSubscription = (subs) => {
  return subs.filter((sub) => sub.productName === "Sitebuilder" && typeof sub.cancelDate === "undefined")[0] || null;
};

export const getGalleryAssetLink = (projectId, fileName) => {
  if (projectId === "" || fileName === "") {
    return "";
  }
  return `https://cdn.satonda.com/sb/${projectId}/${fileName}`;
  // return `https://sitebuilder.satonda.com/${projectId}/${fileName}`;
};

export const getVersionNameFromIndex = (index) => {
  // index = integer => translate from index number to letter
  let firstLetter = Math.floor(index / 26);
  let secondLetter = index - firstLetter * 26;
  return `${firstLetter > 0 ? String.fromCharCode(firstLetter + 65 - 1) : ""}${String.fromCharCode(secondLetter + 65 - 1)}`;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};
