export const sectiondividers37 = {
  id: "sectiondividers-37",
  name: "Section dividers 37",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "svg",
        classes: [],
        styles: [
          { property: "transform", value: "scaleY(-1)" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
        ],
        attributes: [
          { property: "xmlns", value: "http://www.w3.org/2000/svg" },
          { property: "height", value: "150px" },
          { property: "width", value: "100%" },
          { property: "viewBox", value: "0 0 1000 206.86" },
          { property: "preserveAspectRatio", value: "none" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              { property: "opacity", value: "0.15" },
              {
                property: "d",
                value:
                  "M475.75 65c85.1-33.38 176.3-53 268-48.16a485.87 485.87 0 0 1 122.69 22.3A620.49 620.49 0 0 0 769 11.3c-166-32.36-329.9 9.06-482 69.91-98.73 39.51-191.5 86.25-287 125.65h167c65.37-30.67 129.71-65 197.67-94.61C400.93 96.47 438 79.79 475.75 65z",
              },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              {
                property: "d",
                value:
                  "M741.62 52.76c-129.82-27.54-258 7.7-376.92 59.49-68 29.59-132.3 63.94-197.67 94.61h833v-9.09C930.63 126.88 832.81 72.1 741.62 52.76z",
              },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              { property: "opacity", value: "0.3" },
              {
                property: "d",
                value:
                  "M866.44 39.14a485.87 485.87 0 0 0-122.66-22.31C652.05 12 560.85 31.61 475.75 65c-37.73 14.8-74.82 31.48-111 47.26 118.93-51.79 247.1-87 376.92-59.49 91.19 19.34 189 74.12 258.38 145v-84.5a329.47 329.47 0 0 0-50-36.65 723 723 0 0 0-83.61-37.48z",
              },
            ],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
