import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./Select";
import LinkDestForm from "./LinkDestForm";

import { formGetStartValue } from "../../../../../lib/editFunctions";
import { isValidURL } from "../../../../../lib/generalFunctions";
import { translate } from "../../../../../translations/translations";

const LinkDestTarget = ({ sb: { startStyles } }) => {
  return (
    <>
      <LinkDestForm
        label={translate("cEditForms.linkDest", false, null)}
        id={"linkDest"}
        startValue={formGetStartValue(startStyles, "linkDest")}
        tooltipText={translate("cEditForms.tooltipLinkDest", false, null)}
        onTypeCheck={isValidURL}
        errMsgText={translate("cEditForms.notValidUrl", false, null)}
      />
      <Select
        label={translate("cEditForms.linkTarget", false, null)}
        id={"linkTarget"}
        options={[
          { val: "_self", label: translate("cEditForms.currWindowTab", false, null) },
          { val: "_blank", label: translate("cEditForms.newWindowTab", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "linkTarget")}
        tooltipText={translate("cEditForms.tooltipLinkTarget", false, null)}
      />
    </>
  );
};

LinkDestTarget.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(LinkDestTarget);
