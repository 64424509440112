export const template13 = {
  id: "63a0e250ff49a52c9c683369",
  name: { en: "Data analysis firm", nl: "Data analyse bedrijf" },
  desc: { en: "Single-page site for a data analysis firm", nl: "Een-pagina site voor een data analyse bedrijf" },
  categories: ["services"],
  sbPages: [
    {
      pageId: "859f5860-ee8c-4d57-a8d4-fb67d4fb6cad",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "KiA6cF",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-KiA6cF", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "sticky-top" },
            { property: "id", value: "" },
          ],
          content: "",
          children: [
            {
              childId: "QqRQmu",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "QXsgfL",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "LJxeZh",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-KiA6cF"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Logo" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo1.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "s9ZUZy",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-KiA6cF", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "D3ADQe",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "kE5jJI",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "yzsOiB",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "jKHv4g",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-KiA6cF"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#home" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Home",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "e4VEOm",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-KiA6cF"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#services" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Services",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "lWyRfc",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-KiA6cF"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#about" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "About",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "OgYiaj",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-KiA6cF"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#contact" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact us",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "wYZnCe",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "hero-2-component-wYZnCe"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "home" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Ok6ggo",
              type: "",
              htmlTagName: "div",
              classes: ["row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "BEGQsf",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-2-contentcol-wYZnCe", "col-12", "col-md-6", "col-lg-6", "p-3"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "ggw608",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-2-title-wYZnCe", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Gain actionable insights",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "fbTRT5",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-2-text-wYZnCe", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos saepe expedita nemo molestiae repudiandae, dolor aperiam velit esse veronam!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "LmAW1U",
                      type: "",
                      htmlTagName: "a",
                      classes: ["hero-2-button-wYZnCe"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Learn more",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Nu9VCb",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "p-3", "hero-2-img-wYZnCewrapper"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "d4McW5",
                      type: "",
                      htmlTagName: "img",
                      classes: ["hero-2-img-wYZnCe"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Image" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/chart8.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "KkLFwo",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-KkLFwo"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Aa9uvv",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-KkLFwo", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Services", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "CpGCIW",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-CpGCIW"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "services" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "DOZWn1",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-CpGCIW", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "We help you reach the next phase in your growth",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "ec5OxS",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-10-component-ec5OxS"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "aeQrnG",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "gHMVRZ",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-ec5OxS"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "URZpRe",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-ec5OxS"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "AXQDHv",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-ec5OxS"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/chart3.png" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "iRCP73",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-ec5OxS", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem Ipsum",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "bYDBdD",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-ec5OxS", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "oCJaKv",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-ec5OxS"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "etP6xN",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-ec5OxS"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "zLk06t",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-ec5OxS"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/chart5.png" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "TaJA48",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-ec5OxS", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem Ipsum",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "yqvJS8",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-ec5OxS", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "dfau3e",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-10-col-ec5OxS"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "B9uuIZ",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-ec5OxS"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "Y8Ba5e",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-ec5OxS"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/chart7.png" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "RLtcmS",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-ec5OxS", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem Ipsum",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "mdDGBv",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-ec5OxS", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "OOjayE",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-OOjayE"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "XF5SpK",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-OOjayE", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Some key numbers",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "gUphwh",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "statistics-2-component-gUphwh"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "xwrwUJ",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "statistics-2-statswrapper", "w-100"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "wqaLbY",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "statistics-2-stat", "col-lg-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "statistics2" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "j3hn71",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-gUphwh"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "pblfcz",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-2-stat-number-gUphwh", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "51", children: [] },
                          ],
                        },
                        {
                          childId: "kSK1Ea",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-gUphwh", "w-100", "w-md-70", "w-lg-50"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Cumulative years of experience",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "wgJeGj",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "statistics-2-stat", "col-lg-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "statistics2" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "OdYm2n",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-gUphwh"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "B8DAZt",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-2-stat-number-gUphwh", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "5", children: [] },
                          ],
                        },
                        {
                          childId: "LYNI8n",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-gUphwh", "w-100", "w-md-70", "w-lg-50"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Dedicated partners",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "TMJkGr",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "statistics-2-stat", "col-lg-3"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "statistics2" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "OgzLct",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-gUphwh"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "GVaCk5",
                          type: "",
                          htmlTagName: "p",
                          classes: ["statistics-2-stat-number-gUphwh", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "200+",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "mYoqMz",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-gUphwh", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Customers served",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "dV0cRp",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-5-component-dV0cRp"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "about" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "djF3Kn",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-5-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "hSEfCk",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-first", "basics-5-col-dV0cRp"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "XnyIUH",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-5-img-dV0cRp"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/chart4.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "hSyjra",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-5-col-dV0cRp"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "vOGZM2",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-5-title-dV0cRp", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "About", children: [] },
                      ],
                    },
                    {
                      childId: "rdOs9a",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["TwHbq61S4ElNXBco5xL4dxovP7lpaS", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "h" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Our dedicated partners stand ready to help",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "y5IpIg",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-5-text-dV0cRp", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde. ",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "hjiUb5",
                      type: "",
                      htmlTagName: "p",
                      classes: ["KYMhxPyJ3DU0oBI7a8Rub7iQdeoGn5", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde. ",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "YN1okZ",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-YN1okZ"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "XS3cbW",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-YN1okZ", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Client success stories",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "NFpumV",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-22-component-NFpumV"],
          styles: [],
          attributes: [
            { property: "data-name", value: "carousel" },
            { property: "data-editable", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "uw21Lw",
              type: "",
              htmlTagName: "div",
              classes: ["splide", "carousel-slides", "card-22-splide"],
              styles: [],
              attributes: [
                { property: "aria-label", value: "Carousel" },
                { property: "data-carousel-slidesetup", value: "image&text" },
                {
                  property: "data-splide",
                  value:
                    '{"keyboard":true,"autoplay":false,"interval":2000,"pauseOnHover":true,"breakpoints":{"640":{"perPage":1,"perMove":1}},"drag":"free","easing":"linear","arrows":true,"pagination":false,"type":"loop","speed":500,"rewindSpeed":1000,"perPage":1,"perMove":1,"classes":{"arrow":"splide__arrow card-22-arrow-NFpumV","prev":"splide__arrow--prev card-22-prev-NFpumV","next":"splide__arrow--next card-22-next-NFpumV","page":"splide__pagination__page card-22-pagination-NFpumV"},"fixedHeightCss":"auto"}',
                },
              ],
              content: "",
              children: [
                {
                  childId: "NXwZ3L",
                  type: "",
                  htmlTagName: "div",
                  classes: ["splide__track"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "O0W41L",
                      type: "",
                      htmlTagName: "div",
                      classes: ["splide__list"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "LnUgAg",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "card-22-slide-wrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "eJRREg",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "align-items-start", "justify-content-center"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "RBwSIR",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-4", "col-lg-4", "card-22-col-NFpumV"],
                                  styles: [],
                                  attributes: [{ property: "data-name", value: "element_col" }],
                                  content: "",
                                  children: [
                                    {
                                      childId: "yOjPdN",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "card-22-wrapper-NFpumV"],
                                      styles: [],
                                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "KdtiTH",
                                          type: "",
                                          htmlTagName: "h3",
                                          classes: ["toGrow", "card-22-title-NFpumV"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "This is a very positive testimonial!",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "S8PUwZ",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["card-22-subtitle-NFpumV"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Julia Escartes",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "gs1YAB",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["dv7cxArItnOlvtr5tcE3Z1tfeb9rnQ"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Head of Marketing",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "KQ8VEy",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "card-22-slide-wrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "CR6P4t",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "align-items-start", "justify-content-center"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "gFJxP3",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-4", "col-lg-4", "card-22-col-NFpumV"],
                                  styles: [],
                                  attributes: [{ property: "data-name", value: "element_col" }],
                                  content: "",
                                  children: [
                                    {
                                      childId: "naBRZ9",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "card-22-wrapper-NFpumV"],
                                      styles: [],
                                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "HFsTL3",
                                          type: "",
                                          htmlTagName: "h3",
                                          classes: ["toGrow", "card-22-title-NFpumV"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "This is a very positive testimonial!",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "j7YkgM",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["card-22-subtitle-NFpumV"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Robert Pliasz",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "ZZjZUQ",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["dv7cxArItnOlvtr5tcE3Z1tfeb9rnQ"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Finance director",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "XSlxXo",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "card-22-slide-wrapper"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "lxkfEA",
                              type: "",
                              htmlTagName: "div",
                              classes: ["row", "align-items-start", "justify-content-center"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "V41xiA",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["col-12", "col-md-4", "col-lg-4", "card-22-col-NFpumV"],
                                  styles: [],
                                  attributes: [{ property: "data-name", value: "element_col" }],
                                  content: "",
                                  children: [
                                    {
                                      childId: "XlL0Fq",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["flexSameHeight", "card-22-wrapper-NFpumV"],
                                      styles: [],
                                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "ALF623",
                                          type: "",
                                          htmlTagName: "h3",
                                          classes: ["toGrow", "card-22-title-NFpumV"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "This is a very positive testimonial!",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "nEFKlg",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["card-22-subtitle-NFpumV"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Patrice Manaham",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "pu8Vtf",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["dv7cxArItnOlvtr5tcE3Z1tfeb9rnQ"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Founder and CEO",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "hwilfm",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-hwilfm"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "s5KUck",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-hwilfm", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Some of our happy clients",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "u0SEUf",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "clients-1-component-u0SEUf"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "PExhy7",
              type: "block",
              htmlTagName: "div",
              classes: ["d-flex", "flex-wrap", "align-items-center", "justify-content-center", "clients-1-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "Jfzfad",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "YWWWJ2",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-u0SEUf"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "OKneTC",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "OpVEX1",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-u0SEUf"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "eMhh6w",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "GlAoaU",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-u0SEUf"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-3.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "bnv2Xv",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "I58496",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-u0SEUf"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-4.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "GXouUQ",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-GXouUQ"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "contact" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "dE32Ez",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-GXouUQ", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Let's work together!",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "dl8jEu",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-2-component-dl8jEu"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "CRN6zT",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "eoYOP3",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "contactus-2-col-dl8jEu"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "Dj3cRT",
                      type: "",
                      htmlTagName: "button",
                      classes: ["contactus-2-iconwrapper-dl8jEu"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "vt2ztA",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-solid", "fa-location-dot"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "qJFJMr",
                      type: "",
                      htmlTagName: "div",
                      classes: [],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "VgIJ9u",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-2-text-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Street 1337",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "HsHhtz",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-2-text-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Postal code,    City",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Vsn2nF",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "contactus-2-col-dl8jEu"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "dn5NfS",
                      type: "",
                      htmlTagName: "button",
                      classes: ["contactus-2-iconwrapper-dl8jEu"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Nsu6oy",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-solid", "fa-envelope"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "D1dpwO",
                      type: "",
                      htmlTagName: "div",
                      classes: [],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "xx9HeE",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-2-text-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "    email@domain.com",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "RD4SRe",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "contactus-2-col-dl8jEu"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "fe1ZCP",
                      type: "",
                      htmlTagName: "button",
                      classes: ["contactus-2-iconwrapper-dl8jEu"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "FYoxTh",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-solid", "fa-phone"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "JwbxiO",
                      type: "",
                      htmlTagName: "div",
                      classes: [],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "AhIptT",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-2-text-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "020 13 37 000",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              childId: "ygpP8X",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "wG4GmW",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "p-2", "contactus-2-googlemaps-col-dl8jEu"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "JCGhl5",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-2-googlemaps-wrapper-dl8jEu"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "googlemaps_wrapper" },
                        { property: "data-editable", value: "true" },
                      ],
                      content: "",
                      children: [],
                    },
                    {
                      childId: "R3M8rL",
                      type: "",
                      htmlTagName: "iframe",
                      classes: [],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "googlemaps" },
                        {
                          property: "src",
                          value:
                            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8680.352904042613!2d4.899406522082844!3d52.37329199453203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609b70dd81623%3A0xcae71b8d3adfd142!2sAmsterdam%20Centraal!5e0!3m2!1sen!2snl!4v1662818615871!5m2!1sen!2snl",
                        },
                        { property: "scrolling", value: "no" },
                        { property: "loading", value: "lazy" },
                        { property: "referrerpolicy", value: "no-referrer-when-downgrade" },
                        { property: "width", value: "100%" },
                        { property: "height", value: "100%" },
                        { property: "frameborder", value: "0" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "AWyugr",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "p-2"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-2-inputwrapper-dl8jEu"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-2-label-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-2-inputfield-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "name" },
                            { property: "placeholder", value: "Your name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-2-inputwrapper-dl8jEu"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-2-label-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-2-inputfield-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-2-inputwrapper-dl8jEu"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-2-label-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-2-inputfield-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-2-inputwrapper-dl8jEu"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-2-label-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-2-inputfield-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-2-buttonwrapper-dl8jEu"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-2-button-dl8jEu"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "stYQcY",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-3-component-stYQcY"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "Bnljh9",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-3-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics1" }],
              content: "",
              children: [
                {
                  childId: "N5t0Ru",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-3-col-stYQcY"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "ZnMDq8",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-3-img-stYQcY"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo1.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "nnEQvd",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-nnEQvd"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "a4PjhQ",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-nnEQvd", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "© 2022 - BizPlus",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      _id: "63a0d8731f4eeb4fe5a76bb9",
    },
  ],
  sbCustomCss: [
    {
      componentId: "KiA6cF",
      classes: [
        {
          className: "scrolledpast-KiA6cF.navbar-1-wrapper-KiA6cF",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "rem" },
            { property: "padding-bottom", value: "rem" },
            { property: "background", value: "" },
          ],
        },
        { className: "scrolledpast-KiA6cF .navbar-1-logo-KiA6cF", pseudo: "", rules: [{ property: "max-height", value: "px" }] },
        {
          className: "navbar-1-wrapper-KiA6cF",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 5px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "navbar-1-logo-KiA6cF",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "50px" },
          ],
        },
        { className: "navbar-1-logo-KiA6cF", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-KiA6cF",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(229, 229, 229, 0)" },
            { property: "color", value: "rgba(19, 133, 42, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-KiA6cF",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(178, 178, 178, 0)" },
            { property: "color", value: "rgba(199, 233, 206, 1)" },
          ],
        },
        {
          className: "navbar-1-link-KiA6cF",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "navbar-1-link-KiA6cF", pseudo: "hover", rules: [{ property: "color", value: "rgba(19, 133, 42, 1)" }] },
      ],
    },
    {
      componentId: "Ew9RuL",
      classes: [
        {
          className: "section.hero-1-component-Ew9RuL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-1-component-Ew9RuL", pseudo: "hover", rules: [] },
        {
          className: "hero-1-component-Ew9RuL",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-1-component-Ew9RuL", pseudo: "hover", rules: [] },
        {
          className: "hero-1-wrapper-Ew9RuL",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
          ],
        },
        {
          className: "hero-1-img-Ew9RuL",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-1-content-Ew9RuL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "hero-1-title-Ew9RuL",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "hero-1-title-Ew9RuL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-1-text-Ew9RuL",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "hero-1-text-Ew9RuL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-1-button-Ew9RuL",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "none" },
          ],
        },
        {
          className: "hero-1-button-Ew9RuL",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
          ],
        },
      ],
    },
    {
      componentId: "wYZnCe",
      classes: [
        {
          className: "section.hero-2-component-wYZnCe",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-2-component-wYZnCe", pseudo: "hover", rules: [] },
        {
          className: "hero-2-contentcol-wYZnCe",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "hero-2-img-wYZnCe",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0.75rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "hero-2-img-wYZnCe",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-2-title-wYZnCe",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-2-title-wYZnCe", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "hero-2-button-wYZnCe",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(20, 137, 45, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0.75rem" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-2-button-wYZnCe",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(19, 133, 42, 1)" },
            { property: "background", value: "rgba(196, 231, 203, 0)" },
            { property: "border-color", value: "rgba(19, 133, 42, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-2-text-wYZnCe",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 3rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-2-text-wYZnCe", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "KkLFwo",
      classes: [
        {
          className: "section.basics-2-component-KkLFwo",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-KkLFwo", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-KkLFwo",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-KkLFwo", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-KkLFwo",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(17, 237, 61, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-KkLFwo", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "CpGCIW",
      classes: [
        {
          className: "section.basics-1-component-CpGCIW",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0rem" },
            { property: "padding-bottom", value: "1rem" },
          ],
        },
        { className: "section.basics-1-component-CpGCIW", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-CpGCIW",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-CpGCIW", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-CpGCIW",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-CpGCIW", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "ec5OxS",
      classes: [
        {
          className: "section.card-10-component-ec5OxS",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "2rem" },
          ],
        },
        { className: "section.card-10-component-ec5OxS", pseudo: "hover", rules: [] },
        { className: "card-10-col-ec5OxS", pseudo: "", rules: [] },
        { className: "card-10-col-ec5OxS", pseudo: "hover", rules: [] },
        { className: "card-10-wrapper-ec5OxS", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
        { className: "card-10-wrapper-ec5OxS", pseudo: "hover", rules: [] },
        {
          className: "card-10-title-ec5OxS",
          pseudo: "",
          rules: [
            { property: "margin", value: "0.5rem 0 0 0" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-10-title-ec5OxS", pseudo: "hover", rules: [] },
        {
          className: "card-10-text-ec5OxS",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-10-text-ec5OxS", pseudo: "hover", rules: [] },
        {
          className: "card-10-avatar-ec5OxS",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "200px" },
            { property: "height", value: "200px" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-avatar-ec5OxS",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "KndKYk",
      classes: [
        {
          className: "section.statistics-2-component-KndKYk",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.statistics-2-component-KndKYk", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-component-KndKYk",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-2-component-KndKYk", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-stat-col-KndKYk",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-2-stat-col-KndKYk", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-stat-number-KndKYk",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-size", value: "20px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "statistics-2-stat-caption-KndKYk",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
      ],
    },
    {
      componentId: "OOjayE",
      classes: [
        {
          className: "section.basics-1-component-OOjayE",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", value: "rgba(242, 242, 242, 1)" },
          ],
        },
        { className: "section.basics-1-component-OOjayE", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-OOjayE",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-OOjayE", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-OOjayE",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-OOjayE", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "gUphwh",
      classes: [
        {
          className: "section.statistics-2-component-gUphwh",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "background", value: "rgba(242, 242, 242, 1)" },
          ],
        },
        { className: "section.statistics-2-component-gUphwh", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-component-gUphwh",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-2-component-gUphwh", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-stat-col-gUphwh",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-2-stat-col-gUphwh", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-stat-number-gUphwh",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "font-size", value: "20px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "statistics-2-stat-caption-gUphwh",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
      ],
    },
    {
      componentId: "dV0cRp",
      classes: [
        {
          className: "section.basics-5-component-dV0cRp",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-5-component-dV0cRp", pseudo: "hover", rules: [] },
        {
          className: "basics-5-col-dV0cRp",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "TwHbq61S4ElNXBco5xL4dxovP7lpaS",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "TwHbq61S4ElNXBco5xL4dxovP7lpaS", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-5-img-dV0cRp",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-5-img-dV0cRp",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-5-title-dV0cRp",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(17, 237, 61, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-5-title-dV0cRp", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-5-text-dV0cRp",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-5-text-dV0cRp", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "KYMhxPyJ3DU0oBI7a8Rub7iQdeoGn5",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "KYMhxPyJ3DU0oBI7a8Rub7iQdeoGn5", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "NFpumV",
      classes: [
        {
          className: "section.card-22-component-NFpumV",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "background", value: "rgba(218, 240, 222, 1)" },
          ],
        },
        { className: "section.card-22-component-NFpumV", pseudo: "hover", rules: [] },
        { className: "card-22-col-NFpumV", pseudo: "", rules: [] },
        { className: "card-22-col-NFpumV", pseudo: "hover", rules: [] },
        { className: "card-22-wrapper-NFpumV", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
        { className: "card-22-wrapper-NFpumV", pseudo: "hover", rules: [] },
        {
          className: "card-22-arrow-NFpumV",
          pseudo: "",
          rules: [
            { property: "opacity", value: "1" },
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "height", value: "2.7rem" },
            { property: "width", value: "2.7rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "card-22-arrow-NFpumV",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: "1" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "card-22-arrow-NFpumV svg",
          pseudo: "",
          rules: [
            { property: "fill", value: "rgba(0, 0, 0, 0.7)" },
            { property: "height", value: "1.2rem" },
            { property: "width", value: "1.2rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        { className: "card-22-arrow-NFpumV svg", pseudo: "hover", rules: [{ property: "fill", value: "rgba(0, 0, 0, 1)" }] },
        { className: "card-22-prev-NFpumV", pseudo: "", rules: [{ property: "left", value: "1rem" }] },
        { className: "card-22-next-NFpumV", pseudo: "", rules: [{ property: "right", value: "1rem" }] },
        {
          className: "card-22-pagination-NFpumV",
          pseudo: "",
          rules: [
            { property: "height", value: "0.7rem" },
            { property: "width", value: "0.7rem" },
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "margin", value: "0.25rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "inline-block" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "card-22-pagination-NFpumV",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "is-active.card-22-pagination-NFpumV",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "card-22-title-NFpumV",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-22-title-NFpumV", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-22-subtitle-NFpumV",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-22-subtitle-NFpumV", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "dv7cxArItnOlvtr5tcE3Z1tfeb9rnQ",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "dv7cxArItnOlvtr5tcE3Z1tfeb9rnQ", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "YN1okZ",
      classes: [
        {
          className: "section.basics-2-component-YN1okZ",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", value: "rgba(218, 240, 222, 1)" },
          ],
        },
        { className: "section.basics-2-component-YN1okZ", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-YN1okZ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-YN1okZ", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-YN1okZ",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(17, 237, 61, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-YN1okZ", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "hwilfm",
      classes: [
        {
          className: "section.basics-1-component-hwilfm",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-hwilfm", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-hwilfm",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-hwilfm", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-hwilfm",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-hwilfm", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "u0SEUf",
      classes: [
        {
          className: "section.clients-1-component-u0SEUf",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.clients-1-component-u0SEUf", pseudo: "hover", rules: [] },
        {
          className: "clients-1-logo-img-u0SEUf",
          pseudo: "",
          rules: [
            { property: "max-height", value: "50px" },
            { property: "max-width", value: "100px" },
            { property: "opacity", value: 0.5 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(100%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 1.5rem 1.5rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "clients-1-logo-img-u0SEUf",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(100%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "dl8jEu",
      classes: [
        {
          className: "section.contactus-2-component-dl8jEu",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-2-component-dl8jEu", pseudo: "hover", rules: [] },
        {
          className: "contactus-2-col-dl8jEu",
          pseudo: "",
          rules: [
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "text-align", value: "center" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        {
          className: "contactus-2-iconwrapper-dl8jEu",
          pseudo: "",
          rules: [
            { property: "height", value: "65px" },
            { property: "width", value: "65px" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(20, 137, 45, 1)" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "cursor", value: "default !important" },
          ],
        },
        {
          className: "contactus-2-iconwrapper-dl8jEu",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(20, 137, 45, 1)" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "contactus-2-text-dl8jEu", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "contactus-2-text-dl8jEu", pseudo: "hover", rules: [] },
        {
          className: "contactus-2-googlemaps-col-dl8jEu",
          pseudo: "",
          rules: [
            { property: "overflow", value: "hidden" },
            { property: "position", value: "relative" },
          ],
        },
        {
          className: "contactus-2-googlemaps-wrapper-dl8jEu",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "bottom", value: "0" },
          ],
        },
        {
          className: "contactus-2-buttonwrapper-dl8jEu",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "contactus-2-buttonwrapper-dl8jEu",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-2-button-dl8jEu",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(20, 137, 45, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-2-button-dl8jEu",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(20, 137, 45, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "contactus-2-inputwrapper-dl8jEu",
          pseudo: "",
          rules: [{ property: "margin", value: "0 0 1rem 0" }],
        },
        {
          className: "contactus-2-inputfield-dl8jEu",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(20, 137, 45, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-2-inputfield-dl8jEu",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "border-color", value: "rgba(20, 137, 45, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(20, 137, 45, 0.2)" },
          ],
        },
        {
          className: "contactus-2-inputfield-dl8jEu",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "contactus-2-inputfield-dl8jEu",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-2-label-dl8jEu",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        {
          className: "contactus-2-label-dl8jEu",
          pseudo: "hover",
          rules: [],
        },
      ],
    },
    {
      componentId: "GXouUQ",
      classes: [
        {
          className: "section.basics-1-component-GXouUQ",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-GXouUQ", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-GXouUQ",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-GXouUQ", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-GXouUQ",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(20, 137, 45, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-GXouUQ", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "UyF3mB",
      classes: [
        {
          className: "section.footer-3-component-UyF3mB",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.footer-3-component-UyF3mB", pseudo: "hover", rules: [] },
        {
          className: "footer-3-component-UyF3mB",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "footer-3-component-UyF3mB", pseudo: "hover", rules: [] },
        {
          className: "footer-3-title-UyF3mB",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 3rem 0" },
            { property: "font-size", value: "48px" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "footer-3-title-UyF3mB", pseudo: "hover", rules: [] },
        {
          className: "footer-3-text1-UyF3mB",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "footer-3-text1-UyF3mB", pseudo: "hover", rules: [] },
        {
          className: "footer-3-text2-UyF3mB",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 3rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "footer-3-text2-UyF3mB", pseudo: "hover", rules: [] },
        {
          className: "footer-3-text3-UyF3mB",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 1rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "footer-3-text3-UyF3mB", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "stYQcY",
      classes: [
        {
          className: "section.basics-3-component-stYQcY",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "2rem" },
            { property: "background", value: "rgba(225, 243, 228, 1)" },
          ],
        },
        { className: "section.basics-3-component-stYQcY", pseudo: "hover", rules: [] },
        {
          className: "basics-3-col-stYQcY",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-3-img-stYQcY",
          pseudo: "",
          rules: [
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "400px" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-3-img-stYQcY",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "nnEQvd",
      classes: [
        {
          className: "section.basics-2-component-nnEQvd",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(225, 243, 228, 1)" },
          ],
        },
        { className: "section.basics-2-component-nnEQvd", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-nnEQvd",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-nnEQvd", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-nnEQvd",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-nnEQvd", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Source+Code+Pro:300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Source Code Pro",
    fontHeadersWeight: "700",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(20, 137, 45, 1)",
    color2: "rgba(17, 237, 61, 1)",
    color3: "rgba(203, 235, 209, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
