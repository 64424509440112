import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const ResponsiveLayout = ({ sb: { startStyles } }) => {
  return (
    <>
      <Slider
        label={translate("cEditForms.colWidthMob", false, null)}
        id={"colMobile"}
        min={1}
        max={12}
        step={1}
        startValue={formGetStartValue(startStyles, "colMobile")}
        tooltipText={translate("cEditForms.ttColWidthMob", false, null)}
      />
      <Slider
        label={translate("cEditForms.colWidthTab", false, null)}
        id={"colTablet"}
        min={1}
        max={12}
        step={1}
        startValue={formGetStartValue(startStyles, "colTablet")}
        tooltipText={translate("cEditForms.ttColWidthTab", false, null)}
      />
      <Slider
        label={translate("cEditForms.colWidthDesk", false, null)}
        id={"colDesktop"}
        min={1}
        max={12}
        step={1}
        startValue={formGetStartValue(startStyles, "colDesktop")}
        tooltipText={translate("cEditForms.ttColWidthDesk", false, null)}
      />
    </>
  );
};

ResponsiveLayout.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(ResponsiveLayout);
