import { v4 as uuidv4 } from "uuid";

export const POST_CONFIG = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const loadProjectInitSbPagesArray = (data) => {
  // data = res.data from loadProject action
  // Check whether res.data.sbPages already has content. If not, add a first page placeholder.
  if (data.sbPages.length === 0) {
    return {
      ...data,
      sbPages: [
        {
          pageId: uuidv4(),
          version: "A",
          pageLink: "index",
          pageTitle: "Template website built with SiteBuilder",
          pageDesc: "Template website built with SiteBuilder",
          pageType: "website",
          pagePreview: "",
          components: [],
        },
      ],
    };
  }
  return data;
};

export const inputfield_getDropdownOptionsObjects = (arrOptions) => {
  // arrOptions = [ { val, label }, ... ]
  try {
    return arrOptions.map((option) => ({
      childId: "",
      type: "",
      htmlTagName: "option",
      classes: [],
      styles: [],
      attributes: [{ property: "value", value: option.val }],
      content: "",
      children: [
        {
          childId: "",
          type: "",
          htmlTagName: "textNode",
          classes: [],
          styles: [],
          attributes: [],
          content: option.label,
          children: [],
        },
      ],
    }));
  } catch (error) {
    return {
      childId: "",
      type: "",
      htmlTagName: "option",
      classes: [],
      styles: [],
      attributes: [{ property: "value", value: "" }],
      content: "",
      children: [
        {
          childId: "",
          type: "",
          htmlTagName: "textNode",
          classes: [],
          styles: [],
          attributes: [],
          content: "Select a value",
          children: [],
        },
      ],
    };
  }
};

export const inputfield_getCheckboxObjects = (labelObj) => {
  return [
    {
      childId: "",
      type: "",
      htmlTagName: "input",
      classes: ["form-check-input"],
      styles: [],
      attributes: [
        { property: "type", value: "checkbox" },
        { property: "data-checkparent", value: "true" },
      ],
      content: "",
      children: [],
    },
    { ...labelObj, classes: [...labelObj.classes.filter((className) => className !== "form-control"), "form-check-label"] },
  ];
};

export const inputfield_getNonCheckStructure = (divCheckObj, labelObj) => {
  // Don't allow changing from checkbox to another type of input

  // selectedElement is the { property: "data-name", value: "inputfield" } = div.form-check
  // transform this to a text input = [ labelObj, inputObj ]
  return [
    { ...labelObj, classes: [...labelObj.filter((className) => className !== "form-check-label"), "form-control"] },
    { ...divCheckObj, htmlTagName: "input" },
  ];
  // from label:
  // {
  //   childId: "",
  //   type: "",
  //   htmlTagName: "label",
  //   classes: ["form-check-label", "forms-1-label"],
  //   styles: [],
  //   attributes: [
  //     { property: "data-name", value: "inputlabel" },
  //     { property: "data-editable", value: "true" },
  //   ],
  //   content: "",
  //   children: [],
  // },
  // to label:
  // {
  //   childId: "",
  //   type: "",
  //   htmlTagName: "label",
  //   classes: ["form-control", "forms-1-label"],
  //   styles: [],
  //   attributes: [
  //     { property: "data-name", value: "inputlabel" },
  //     { property: "data-editable", value: "true" },
  //   ],
  //   content: "",
  //   children: [],
  // }

  // from { property: "data-name", value: "inputfield" }
  // {
  //   childId: "",
  //   type: "",
  //   htmlTagName: "div",
  //   classes: ["form-check", "forms-1-check"],
  //   styles: [],
  //   attributes: [
  //     { property: "data-name", value: "inputfield" },
  //     { property: "data-editable", value: "true" },
  //     { property: "data-formcheckwrapper", value: "true" },
  //     { property: "name", value: "subscribe" },
  //   ],
  //   content: "",
  //   children: [...]
  // }
  // to { property: "data-name", value: "inputfield" }
  // {
  //   childId: "",
  //   type: "",
  //   htmlTagName: "input",
  //   classes: ["form-control", "forms-1-inputfield"],
  //   styles: [],
  //   attributes: [
  //     { property: "type", value: "text" },
  //     { property: "name", value: "name" },
  //     { property: "placeholder", value: "Your name" },
  //     { property: "data-name", value: "inputfield" },
  //     { property: "data-editable", value: "true" },
  //   ],
  //   content: "",
  //   children: [],
  // }
};
