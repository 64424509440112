import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ColorPicker from "./formComponents/ColorPicker";
import Select from "./formComponents/Select";
import Divider from "./formComponents/Divider";
import Tooltip from "./formComponents/Tooltip";
import Slider from "./formComponents/Slider";

import { formGetStartValue, EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { getRandomId } from "../../../../lib/domFunctions";
import { getInternalLinksToSelectFrom } from "../../../../lib/stateFunctions";
import { translate } from "../../../../translations/translations";

const NavbarLinks = ({ sb: { startStyles, sbPages, sbCssVars } }) => {
  const LinkData = () => {
    const [links, setLinks] = useState([]);
    const [linksToSelectFrom, setLinksToSelectFrom] = useState([]);
    useEffect(() => {
      setLinks(formGetStartValue(startStyles, "navbarLinkData"));
      setLinksToSelectFrom(getInternalLinksToSelectFrom(sbPages));
    }, [formGetStartValue(startStyles, "navbarLinkData")]);

    const typeText = (i, val) => {
      setLinks((prev) => prev.map((link, j) => (j === i ? { ...link, text: val } : link)));
    };

    const typeDest = (i, val) => {
      setLinks((prev) => prev.map((link, j) => (j === i ? { ...link, dest: val } : link)));
    };

    const removeLink = (i) => {
      if (i > 0) {
        setLinks((prev) => prev.filter((link, j) => j !== i));
      }
    };

    const addLink = () => {
      setLinks((prev) => [...prev, { childId: getRandomId(), text: translate("cEditForms.navbar_linkText", false, null), dest: "#!" }]);
    };

    return (
      <div className="my-3">
        <div className="w-100 d-flex align-items-center mb-1">
          <div className="m-0" style={{ width: "26.4%" }}></div>
          <div className="m-0" style={{ width: "33.3%" }}>
            {translate("cEditForms.text", false, null)}
          </div>
          <div className="m-0" style={{ width: "33.3%" }}>
            {translate("cEditForms.destination", false, null)} <Tooltip text={translate("cEditForms.navbar_ttSelectDest", false, null)} />
          </div>
        </div>
        {links.length > 0 &&
          links.map((link, i) => (
            <div key={`link${i}`} className="w-100 d-flex align-items-center mb-2">
              <div className="m-0" style={{ width: "26.4%" }}>
                {translate("cEditForms.link", false, null)} {i + 1}
              </div>
              <div className="m-0 pe-1" style={{ width: "33.3%" }}>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id={`${EDIT_FORM_ID_PREFIX}navbarLinkText${i}`}
                  value={link.text}
                  onChange={(e) => typeText(i, e.target.value)}
                  data-childid={link.childId}
                />
              </div>
              <div className="m-0 ps-1" style={{ position: "relative", width: "33.3%" }}>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id={`${EDIT_FORM_ID_PREFIX}navbarLinkDest${i}`}
                  value={link.dest}
                  onChange={(e) => typeDest(i, e.target.value)}
                />
                <i
                  className="fa-solid fa-chevron-down cursorPointer"
                  style={{ position: "absolute", top: "7px", right: "10px" }}
                  id={`NavbarLinks${i}`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                />
                <div className="dropdown-menu p-0" aria-labelledby={`NavbarLinks${i}`}>
                  {linksToSelectFrom.map((selectableLink) => (
                    <p
                      key={selectableLink}
                      className="px-2 py-1 m-0 fontSize08 bgHover-light trans-3 cursorPointer"
                      onClick={() => typeDest(i, selectableLink)}
                    >
                      {selectableLink}
                    </p>
                  ))}
                </div>
              </div>
              {i > 0 && (
                <div className="m-0 d-flex justify-content-end" style={{ width: "7%" }}>
                  <i
                    className="fa-regular fa-trash-can text-danger cursorPointer"
                    title={translate("cEditForms.removeLink", false, null)}
                    onClick={() => removeLink(i)}
                  ></i>
                </div>
              )}
            </div>
          ))}
        <span className="editPane-btn trans-3 cursorPointer px-3 py-2 rounded-4 mt-3" onClick={() => addLink()}>
          <i className="fa-solid fa-plus me-2"></i> {translate("cEditForms.addLink", false, null)}
        </span>
      </div>
    );
  };

  return (
    <>
      <ColorPicker
        label={translate("cEditForms.linkColorNormal", false, null)}
        id={"navbarLinkColor"}
        startValue={formGetStartValue(startStyles, "navbarLinkColor")}
        tooltipText={translate("cEditForms.ttLinkColorNormal", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.linkColorHover", false, null)}
        id={"navbarLinkColorHover"}
        startValue={formGetStartValue(startStyles, "navbarLinkColorHover")}
        tooltipText={translate("cEditForms.ttLinkColorHover", false, null)}
      />
      <Select
        label={translate("cEditForms.navbar_linkFont", false, null)}
        id={"navbarLinkFontName"}
        options={[
          { val: "var(--font-headers)", label: sbCssVars.fontHeadersName },
          { val: "var(--font-body)", label: sbCssVars.fontBodyName },
        ]}
        startValue={formGetStartValue(startStyles, "navbarLinkFontName")}
        tooltipText={translate("cEditForms.navbar_ttLinkFont", false, null)}
      />
      <Slider
        label={translate("cEditForms.navbar_linkSize", false, null)}
        id={"navbarLinkFontSize"}
        min={0.7}
        max={2.5}
        step={0.1}
        startValue={formGetStartValue(startStyles, "navbarLinkFontSize")}
        tooltipText={translate("cEditForms.navbar_ttLinkSize", false, null)}
      />
      <Select
        label={translate("cEditForms.navbar_linkStyle", false, null)}
        id={"navbarLinkBold"}
        options={[
          { val: "inherit", label: translate("cEditForms.normal", false, null) },
          { val: "bold", label: translate("cEditForms.bold", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "navbarLinkBold")}
        tooltipText={translate("cEditForms.navbar_ttLinkStyle", false, null)}
      />
      <Select
        label={translate("cEditForms.navbar_linkDeco", false, null)}
        id={"navbarLinkDeco"}
        options={[
          { val: "none", label: translate("cEditForms.none", false, null) },
          { val: "underline", label: translate("cEditForms.underlined", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "navbarLinkDeco")}
        tooltipText={translate("cEditForms.navbar_ttLinkStyle", false, null)}
      />
      <Divider />
      <LinkData />
    </>
  );
};

NavbarLinks.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(NavbarLinks);
