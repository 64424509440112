export const template2 = {
  id: "ao2a78bsk421481244eb667d",
  name: { en: "Consultancy agency", nl: "Adviesbureay" },
  desc: { en: "Multi-page site for a consultancy agency", nl: "Multi-pagina site voor een adviesbureau" },
  categories: ["services"],
  sbPages: [
    {
      pageId: "IoO8uTeXgK",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "FtkNxu",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-5-component-FtkNxu"],
          styles: [],
          attributes: [
            { property: "data-name", value: "carousel" },
            { property: "data-editable", value: "true" },
            { property: "id", value: "home" },
          ],
          content: "",
          children: [
            {
              childId: "cWOmPw",
              type: "",
              htmlTagName: "div",
              classes: ["splide", "carousel-slides", "hero-5-splide"],
              styles: [],
              attributes: [
                { property: "aria-label", value: "Carousel" },
                { property: "data-carousel-slidesetup", value: "image" },
                {
                  property: "data-splide",
                  value:
                    '{"keyboard":true,"autoplay":true,"interval":2000,"pauseOnHover":true,"breakpoints":{"640":{"perPage":1,"perMove":1}},"drag":"free","easing":"linear","arrows":true,"pagination":false,"type":"fade","speed":500,"rewindSpeed":1000,"perPage":1,"perMove":1,"classes":{"arrow":"splide__arrow hero-5-arrow-FtkNxu","prev":"splide__arrow--prev hero-5-prev-FtkNxu","next":"splide__arrow--next hero-5-next-FtkNxu","page":"splide__pagination__page hero-5-pagination-FtkNxu"},"width":"100vw","height":"100vh","heightRatio":0.5,"focus":"center","rewind":true,"fixedHeightCss":"auto"}',
                },
              ],
              content: "",
              children: [
                {
                  childId: "D14thz",
                  type: "",
                  htmlTagName: "div",
                  classes: ["splide__track"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Q7rLs2",
                      type: "",
                      htmlTagName: "div",
                      classes: ["splide__list"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "KuMdwA",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "hero-5-slide"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "EO7yvM",
                              type: "",
                              htmlTagName: "div",
                              classes: ["hero-5-wrapper-FtkNxu"],
                              styles: [],
                              attributes: [{ property: "data-herowrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "YABHbI",
                                  type: "",
                                  htmlTagName: "img",
                                  classes: ["hero-5-img-FtkNxu"],
                                  styles: [],
                                  attributes: [
                                    { property: "src", value: "https://cdn.satonda.com/eb/assets/nature1.png" },
                                    { property: "alt", value: "Image" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                                {
                                  childId: "IzNH64",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["hero-5-content-FtkNxu"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "hero1" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ernz5j",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["hero-5-title-FtkNxu", "w-100", "w-md-100", "w-lg-100"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Some appealing title!",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "R0mjFS",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["hero-5-text-FtkNxu", "w-100", "w-md-100", "w-lg-100"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "FZfznU",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["hero-5-button-FtkNxu"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_self" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Read more",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "StImVC",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "hero-5-slide"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mCxyAQ",
                              type: "",
                              htmlTagName: "div",
                              classes: ["hero-5-wrapper-FtkNxu"],
                              styles: [],
                              attributes: [{ property: "data-herowrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "G1dppa",
                                  type: "",
                                  htmlTagName: "img",
                                  classes: ["hero-5-img-FtkNxu"],
                                  styles: [],
                                  attributes: [
                                    { property: "src", value: "https://cdn.satonda.com/eb/assets/nature2.png" },
                                    { property: "alt", value: "Image" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                                {
                                  childId: "VBvGME",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["hero-5-content-FtkNxu"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "hero1" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "gx5m4N",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["hero-5-title-FtkNxu", "w-100", "w-md-100", "w-lg-100"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Some appealing title!",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "KmZkUF",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["hero-5-text-FtkNxu", "w-100", "w-md-100", "w-lg-100"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "zW5X8I",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["hero-5-button-FtkNxu"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_self" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Read more",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "QUZJK1",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["splide__slide", "hero-5-slide"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "z8JcOA",
                              type: "",
                              htmlTagName: "div",
                              classes: ["hero-5-wrapper-FtkNxu"],
                              styles: [],
                              attributes: [{ property: "data-herowrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "JNDGeg",
                                  type: "",
                                  htmlTagName: "img",
                                  classes: ["hero-5-img-FtkNxu"],
                                  styles: [],
                                  attributes: [
                                    { property: "src", value: "https://cdn.satonda.com/eb/assets/nature3.png" },
                                    { property: "alt", value: "Image" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                                {
                                  childId: "zHLJhJ",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["hero-5-content-FtkNxu"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "hero1" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "B16W1v",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["hero-5-title-FtkNxu", "w-100", "w-md-100", "w-lg-100"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Some appealing title!",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "fqtFGj",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["hero-5-text-FtkNxu", "w-100", "w-md-100", "w-lg-100"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "zYxSX6",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["hero-5-button-FtkNxu"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_self" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Read more",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "VjBPfK",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-VjBPfK", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "true" },
            { property: "data-navbarposition", value: "sticky-top" },
          ],
          content: "",
          children: [
            {
              childId: "LUEg64",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "yFdDKK",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "yxZeun",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-VjBPfK"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Logo" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-company-name-white.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "EqstP9",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-VjBPfK", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "eCASm3",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "GSj1OF",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "bEDAPq",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "geLyOe",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-VjBPfK"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#home" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Home",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "k2aJPo",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-VjBPfK"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#about" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "About",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "oulVVw",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-VjBPfK"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#services" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Services",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "oOV2VF",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-VjBPfK"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#team" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Team",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "R09SFm",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-R09SFm"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "about" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "JdhbNv",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-R09SFm", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "ABOUT US", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "Mc2CCI",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-Mc2CCI"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "wsyA4F",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-Mc2CCI", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "A0vAGz",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-6-component-A0vAGz"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Su6XKM",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-6-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "e57fvN",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-6-col-A0vAGz"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "LXgkZH",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-6-img-A0vAGz"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/work-1.png" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "ZKNhhW",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-6-col-A0vAGz"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "CU4W9D",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["basics-6-title-A0vAGz", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elite",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "V0fOMW",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-6-text-A0vAGz", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "lPUE9o",
                      type: "",
                      htmlTagName: "p",
                      classes: ["A3QZomdlpgzIs4JrB9MxBVuKE38eG4", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum Libero justo laoreet sit amet cursus sit amet dictum sit.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "xUq1ee",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-1-component-xUq1ee"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "MYZ8pa",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-1-wrapper-xUq1ee"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "K4553C",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-1-content-xUq1ee"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "jpKk4G",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["parallax-1-title-xUq1ee", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Some appealing title!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "rlYKSP",
                      type: "",
                      htmlTagName: "p",
                      classes: ["parallax-1-text-xUq1ee", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "jbsGtl",
                      type: "",
                      htmlTagName: "a",
                      classes: ["parallax-1-button-xUq1ee"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: " Read more ",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "WmgCAW",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-WmgCAW"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "services" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "uCMy6i",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-WmgCAW", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "OUR SERVICES", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "nXn7lu",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-nXn7lu"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Xox16z",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-nXn7lu", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "RYuVVt",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-9-component-RYuVVt"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "yNwwg4",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-9-row-RYuVVt"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "qOybjl",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-9-col-RYuVVt"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "gax3Bb",
                      type: "",
                      htmlTagName: "img",
                      classes: ["card-9-img-RYuVVt"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/work-2.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "mVTyZ5",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-9-col-RYuVVt"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "NaSaR2",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["card-9-title-RYuVVt", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "This is a title",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "C1m9fN",
                      type: "",
                      htmlTagName: "p",
                      classes: ["card-9-desc-text-RYuVVt", "w-90", "w-md-90", "w-lg-90"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "w2JEk7",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-10-component-w2JEk7"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "stJ5rW",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-10-row-w2JEk7"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "cGqEal",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-10-col-w2JEk7"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "G52h6y",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["card-10-title-w2JEk7", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "This is a title",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "WRcRtM",
                      type: "",
                      htmlTagName: "p",
                      classes: ["card-10-desc-text-w2JEk7", "w-90", "w-md-90", "w-lg-90"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "XhKD1K",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-10-col-w2JEk7"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "lAuHwo",
                      type: "",
                      htmlTagName: "img",
                      classes: ["card-10-img-w2JEk7"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/work-3.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "VbSM9C",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-9-component-VbSM9C"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "LJvZ1S",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-9-row-VbSM9C"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "JBKwmp",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-9-col-VbSM9C"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "YTp8tZ",
                      type: "",
                      htmlTagName: "img",
                      classes: ["card-9-img-VbSM9C"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/work-4.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "cLoh1F",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-9-col-VbSM9C"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "WyKAoG",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["card-9-title-VbSM9C", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "This is a title",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "gpS9ME",
                      type: "",
                      htmlTagName: "p",
                      classes: ["card-9-desc-text-VbSM9C", "w-90", "w-md-90", "w-lg-90"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "voGGO5",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "clients-3-component-voGGO5"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "fQul68",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "clients-3-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "KB7quf",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "clients-3-col-voGGO5"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "jpv0ER",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["clients-3-title-voGGO5", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "OUR CUSTOMERS",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "g9JWfN",
                      type: "",
                      htmlTagName: "p",
                      classes: ["clients-3-text-voGGO5", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugit, aliquam! Corporis consequatur, saepe provident nulla at iste, vero neque reprehenderit reiciendis ex, officia enim. Maiores.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "ipKJn9",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "clients-3-col-voGGO5"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "b5fsRb",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["row"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "uZXSf3",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-6", "col-md-6", "col-lg-6", "p-3", "d-flex", "align-items-stretch"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mVd4jU",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "clients-3-clientcol-voGGO5"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "uPLn4T",
                                  type: "",
                                  htmlTagName: "img",
                                  classes: ["clients-3-logo-voGGO5", "toGrow"],
                                  styles: [],
                                  attributes: [
                                    { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                                    { property: "alt", value: "logo" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "img" },
                                    { property: "data-imgresizable", value: "false" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "yIfSBv",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-6", "col-md-6", "col-lg-6", "p-3", "d-flex", "align-items-stretch"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "GMRD1j",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "clients-3-clientcol-voGGO5"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "xxVpAg",
                                  type: "",
                                  htmlTagName: "img",
                                  classes: ["clients-3-logo-voGGO5", "toGrow"],
                                  styles: [],
                                  attributes: [
                                    { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                                    { property: "alt", value: "logo" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "img" },
                                    { property: "data-imgresizable", value: "false" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "rya94c",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-6", "col-md-6", "col-lg-6", "p-3", "d-flex", "align-items-stretch"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "zHTtTx",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "clients-3-clientcol-voGGO5"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "mWuaAX",
                                  type: "",
                                  htmlTagName: "img",
                                  classes: ["clients-3-logo-voGGO5", "toGrow"],
                                  styles: [],
                                  attributes: [
                                    { property: "src", value: "https://cdn.satonda.com/eb/assets/client-3.png" },
                                    { property: "alt", value: "logo" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "img" },
                                    { property: "data-imgresizable", value: "false" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "nAbRCo",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-6", "col-md-6", "col-lg-6", "p-3", "d-flex", "align-items-stretch"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "qvWDOg",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "clients-3-clientcol-voGGO5"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "JcvlPq",
                                  type: "",
                                  htmlTagName: "img",
                                  classes: ["clients-3-logo-voGGO5", "toGrow"],
                                  styles: [],
                                  attributes: [
                                    { property: "src", value: "https://cdn.satonda.com/eb/assets/client-4.png" },
                                    { property: "alt", value: "logo" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "img" },
                                    { property: "data-imgresizable", value: "false" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Jng5Ue",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-Jng5Ue"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "IrhoET",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-Jng5Ue", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
                { property: "id", value: "team" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "OUR TEAM", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "J32VGT",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-J32VGT"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "EAZHlh",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-J32VGT", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "RLL3ap",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-2-component-RLL3ap"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "VQz7yZ",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "W0Swp1",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-2-col-RLL3ap"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card2" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "o4Ao4q",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-2-overlay-wrapper-RLL3ap"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "KbEzjI",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-2-avatar-RLL3ap"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "SzmlZW",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-2-overlay-content-RLL3ap"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "XC9Mio",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-2-title-RLL3ap"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "xZ3jAI",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-2-subtitle-RLL3ap"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "cybXo0",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "SW4d0l",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-RLL3ap"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "xf4DF1",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "gBjo6T",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-RLL3ap"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "fuwkYo",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "oqlybB",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-RLL3ap"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "G3LI4K",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "gM2wmb",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-RLL3ap"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "y3yGMm",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "sUNEbE",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-2-col-RLL3ap"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card2" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "eSsDBc",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-2-overlay-wrapper-RLL3ap"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "hA0b0Y",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-2-avatar-RLL3ap"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "grwhA8",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-2-overlay-content-RLL3ap"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "EgT3XM",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-2-title-RLL3ap"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "MuE5I5",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-2-subtitle-RLL3ap"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Iy9QZZ",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "Uhm3lG",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-RLL3ap"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "EF46js",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "LBtpCp",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-RLL3ap"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ycPoCx",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "lH8u07",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-RLL3ap"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "JMBX0a",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "MjxjlC",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-RLL3ap"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "uJurKQ",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "kJVP0z",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-2-col-RLL3ap"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card2" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "iL4cpg",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-2-overlay-wrapper-RLL3ap"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "YaXUiE",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-2-avatar-RLL3ap"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "dS1OfN",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-2-overlay-content-RLL3ap"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "nJRTP4",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-2-title-RLL3ap"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Vuxarw",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-2-subtitle-RLL3ap"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "ycW1Z0",
                              type: "block",
                              htmlTagName: "div",
                              classes: [],
                              styles: [],
                              attributes: [{ property: "data-name", value: "block" }],
                              content: "",
                              children: [
                                {
                                  childId: "JV5Pr8",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-RLL3ap"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "I5RC23",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-solid", "fa-envelope"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "vP6u6W",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-RLL3ap"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "R0Gi9e",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-twitter"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "ekyn48",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-RLL3ap"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "znnKIi",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-linkedin"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "Bnp6Ff",
                                  type: "element",
                                  htmlTagName: "a",
                                  classes: ["card-2-social-link-RLL3ap"],
                                  styles: [],
                                  attributes: [
                                    { property: "href", value: "#!" },
                                    { property: "data-href", value: "#!" },
                                    { property: "data-target", value: "_blank" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-iconwrapper", value: "true" },
                                    { property: "data-texteditable", value: "false" },
                                    { property: "data-name", value: "a" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Mf9Os9",
                                      type: "",
                                      htmlTagName: "span",
                                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                                      styles: [],
                                      attributes: [{ property: "data-checkparent", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "pX5WY1",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-pX5WY1"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "GnOEvk",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-pX5WY1", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "CONTACT US", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "z8Dqe6",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-z8Dqe6"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ZUF7g6",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-z8Dqe6", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "HSy5WG",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-8-component-HSy5WG"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "XRdaPn",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-8-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "AkvCtz",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "JYzBB3",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-HSy5WG"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "eak9bP",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-HSy5WG"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "wuZpiu",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-HSy5WG"],
                          styles: [],
                          attributes: [
                            { property: "name", value: "name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                            { property: "type", value: "text" },
                            { property: "placeholder", value: "Your name" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "YHqxEh",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-HSy5WG"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "EVGJUh",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-HSy5WG"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "zwTUQX",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-HSy5WG"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "ucGYjh",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-HSy5WG"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "dweTLw",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-HSy5WG"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "pP2E9h",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-8-inputfield-HSy5WG"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "v7raAv",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-8-inputwrapper-HSy5WG"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "ofEKoB",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-8-label-HSy5WG"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "jljXKR",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-8-inputfield-HSy5WG"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "T8m7bv",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-8-button-HSy5WGwrapper-HSy5WG"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "l6m5r2",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-8-button-HSy5WG"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "nrZQ88",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-7-component-nrZQ88"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "D9GPmB",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-7-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "zQxYbS",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "contactus-7-col-nrZQ88"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "uxyZ3c",
                      type: "",
                      htmlTagName: "button",
                      classes: ["contactus-7-iconwrapper-nrZQ88"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "LGvOpf",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-solid", "fa-location-dot"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "ygOyJj",
                      type: "",
                      htmlTagName: "div",
                      classes: [],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "rOOZJ8",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["contactus-7-title-nrZQ88"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Visit us",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "kLUGzM",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-7-text-nrZQ88"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Street 1337",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "ua2NsS",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-7-text-nrZQ88"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Postal code, City",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "cfShtA",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "contactus-7-col-nrZQ88"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "vegLLx",
                      type: "",
                      htmlTagName: "button",
                      classes: ["contactus-7-iconwrapper-nrZQ88"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "n0xvLm",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-solid", "fa-phone"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "mye99c",
                      type: "",
                      htmlTagName: "div",
                      classes: [],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "isI2nT",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["contactus-7-title-nrZQ88"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Call us",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "dcUzCo",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-7-text-nrZQ88"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "020 13 37 000",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "hAwetD",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "contactus-7-col-nrZQ88"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ZG6XLh",
                      type: "",
                      htmlTagName: "button",
                      classes: ["contactus-7-iconwrapper-nrZQ88"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-iconwrapper", value: "true" },
                        { property: "data-texteditable", value: "false" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Lk0M55",
                          type: "",
                          htmlTagName: "span",
                          classes: ["icon", "fa-solid", "fa-envelope"],
                          styles: [],
                          attributes: [{ property: "data-checkparent", value: "true" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "c9Nbg9",
                      type: "",
                      htmlTagName: "div",
                      classes: [],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "MP3MMj",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["contactus-7-title-nrZQ88"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email us",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "ooyfnz",
                          type: "",
                          htmlTagName: "p",
                          classes: ["contactus-7-text-nrZQ88"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "email@domain.com",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Q5sOho",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "footer-3-component-Q5sOho"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "qK2t6e",
              type: "",
              htmlTagName: "p",
              classes: ["footer-3-text3-Q5sOho", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "© Copyright Company name. All rights reserved.",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      _id: "63827a972afe57f21041002d",
    },
  ],
  sbCustomCss: [
    {
      componentId: "VjBPfK",
      classes: [
        {
          className: "scrolledpast-VjBPfK.navbar-1-wrapper-VjBPfK",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(0, 81, 122, 0.75)" },
          ],
        },
        { className: "scrolledpast-VjBPfK .navbar-1-logo-VjBPfK", pseudo: "", rules: [{ property: "max-height", value: "40px" }] },
        {
          className: "navbar-1-wrapper-VjBPfK",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(0, 81, 122, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "navbar-1-logo-VjBPfK",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "40px" },
          ],
        },
        { className: "navbar-1-logo-VjBPfK", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-VjBPfK",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(137, 215, 255, 0)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-VjBPfK",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(178, 178, 178, 0)" },
            { property: "color", value: "rgba(234, 234, 234, 1)" },
          ],
        },
        {
          className: "navbar-1-link-VjBPfK",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "text-decoration", value: "none" },
          ],
        },
        { className: "navbar-1-link-VjBPfK", pseudo: "hover", rules: [{ property: "color", value: "rgba(239, 239, 239, 1)" }] },
      ],
    },
    {
      componentId: "FtkNxu",
      classes: [
        {
          className: "section.hero-5-component-FtkNxu",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-5-component-FtkNxu", pseudo: "hover", rules: [] },
        {
          className: "hero-5-component-FtkNxu",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-5-component-FtkNxu", pseudo: "hover", rules: [] },
        {
          className: "hero-5-wrapper-FtkNxu",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
          ],
        },
        {
          className: "hero-5-img-FtkNxu",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-5-content-FtkNxu",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "hero-5-arrow-FtkNxu",
          pseudo: "",
          rules: [
            { property: "opacity", value: "1" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "height", value: "3.5rem" },
            { property: "width", value: "3.5rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-5-arrow-FtkNxu",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: "1" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "hero-5-arrow-FtkNxu svg",
          pseudo: "",
          rules: [
            { property: "fill", value: "rgba(231, 234, 237, 1)" },
            { property: "height", value: "2rem" },
            { property: "width", value: "2rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        { className: "hero-5-arrow-FtkNxu svg", pseudo: "hover", rules: [{ property: "fill", value: "rgba(255, 255, 255, 1)" }] },
        { className: "hero-5-prev-FtkNxu", pseudo: "", rules: [{ property: "left", value: "1rem" }] },
        { className: "hero-5-next-FtkNxu", pseudo: "", rules: [{ property: "right", value: "1rem" }] },
        {
          className: "hero-5-pagination-FtkNxu",
          pseudo: "",
          rules: [
            { property: "height", value: "0.7rem" },
            { property: "width", value: "0.7rem" },
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "margin", value: "0.25rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "inline-block" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "hero-5-pagination-FtkNxu",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "is-active.hero-5-pagination-FtkNxu",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "hero-5-title-FtkNxu",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-5-title-FtkNxu", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(255, 255, 255, 1)" }] },
        {
          className: "hero-5-text-FtkNxu",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-5-text-FtkNxu", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-5-button-FtkNxu",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(255, 255, 255, 0.39)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-5-button-FtkNxu",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 6px 3px rgba(255, 255, 255, 0.39)" },
          ],
        },
      ],
    },
    {
      componentId: "R09SFm",
      classes: [
        {
          className: "section.basics-1-component-R09SFm",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-R09SFm", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-R09SFm",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-R09SFm", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-R09SFm",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-R09SFm", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Mc2CCI",
      classes: [
        {
          className: "section.basics-2-component-Mc2CCI",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-Mc2CCI", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-Mc2CCI",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-Mc2CCI", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-Mc2CCI",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-Mc2CCI", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "A0vAGz",
      classes: [
        {
          className: "section.basics-6-component-A0vAGz",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-6-component-A0vAGz", pseudo: "hover", rules: [] },
        {
          className: "basics-6-col-A0vAGz",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-6-img-A0vAGz",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "basics-6-img-A0vAGz", pseudo: "hover", rules: [] },
        {
          className: "basics-6-text-A0vAGz",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-6-text-A0vAGz", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "A3QZomdlpgzIs4JrB9MxBVuKE38eG4",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "A3QZomdlpgzIs4JrB9MxBVuKE38eG4", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-6-title-A0vAGz",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-6-title-A0vAGz", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "xUq1ee",
      classes: [
        {
          className: "section.parallax-1-component-xUq1ee",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-1-component-xUq1ee", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-component-xUq1ee",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-1-wrapper-xUq1ee",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "50vh" },
            {
              property: "background-image",
              value: 'url("https://s1.travix.com/no/north-america-america-new-york-bridge-and-river-tree-medium.jpg")',
            },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-1-content-xUq1ee",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "parallax-1-title-xUq1ee",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 1rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "parallax-1-title-xUq1ee", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-text-xUq1ee",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "parallax-1-text-xUq1ee", pseudo: "hover", rules: [] },
        {
          className: "parallax-1-button-xUq1ee",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "parallax-1-button-xUq1ee",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "WmgCAW",
      classes: [
        {
          className: "section.basics-1-component-WmgCAW",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-WmgCAW", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-WmgCAW",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-WmgCAW", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-WmgCAW",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-WmgCAW", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "nXn7lu",
      classes: [
        {
          className: "section.basics-2-component-nXn7lu",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-nXn7lu", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-nXn7lu",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-nXn7lu", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-nXn7lu",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-nXn7lu", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "DInGU2",
      classes: [
        {
          className: "section.card-7-component-DInGU2",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-7-component-DInGU2", pseudo: "hover", rules: [] },
        { className: "card-7-row-DInGU2", pseudo: "", rules: [] },
        {
          className: "card-7-col-DInGU2",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(214, 214, 214, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-7-col-DInGU2", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }] },
        {
          className: "card-7-img-DInGU2",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "card-7-img-DInGU2", pseudo: "hover", rules: [] },
        {
          className: "card-7-title-link-DInGU2",
          pseudo: "",
          rules: [
            { property: "font-family", value: '"Montserrat"' },
            { property: "font-size", value: "25px" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-7-title-link-DInGU2", pseudo: "hover", rules: [{ property: "color", value: "rgba(0, 84, 127, 1)" }] },
        {
          className: "card-7-desc-text-DInGU2",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        {
          className: "card-7-link-readmore-DInGU2",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 169, 255, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "padding", value: "0.5rem 1.5rem 0.5rem 1.5rem" },
            { property: "width", value: "auto" },
            { property: "text-decoration", value: "none" },
            { property: "align-self", value: "start" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "card-7-link-readmore-DInGU2",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(0, 169, 255, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 169, 255, 1)" },
          ],
        },
      ],
    },
    {
      componentId: "kwdUnF",
      classes: [
        {
          className: "section.card-8-component-kwdUnF",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-8-component-kwdUnF", pseudo: "hover", rules: [] },
        { className: "card-8-row-kwdUnF", pseudo: "", rules: [] },
        {
          className: "card-8-col-kwdUnF",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(214, 214, 214, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-8-col-kwdUnF", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }] },
        {
          className: "card-8-img-kwdUnF",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "card-8-img-kwdUnF", pseudo: "hover", rules: [] },
        {
          className: "card-8-title-link-kwdUnF",
          pseudo: "",
          rules: [
            { property: "font-family", value: '"Montserrat"' },
            { property: "font-size", value: "25px" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-8-title-link-kwdUnF", pseudo: "hover", rules: [{ property: "color", value: "rgba(0, 84, 127, 1)" }] },
        {
          className: "card-8-desc-text-kwdUnF",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        {
          className: "card-8-link-readmore-kwdUnF",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 169, 255, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "padding", value: "0.5rem 1.5rem 0.5rem 1.5rem" },
            { property: "width", value: "auto" },
            { property: "text-decoration", value: "none" },
            { property: "align-self", value: "start" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "card-8-link-readmore-kwdUnF",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(0, 169, 255, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 169, 255, 1)" },
          ],
        },
      ],
    },
    {
      componentId: "me4gu8",
      classes: [
        {
          className: "section.card-7-component-me4gu8",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-7-component-me4gu8", pseudo: "hover", rules: [] },
        { className: "card-7-row-me4gu8", pseudo: "", rules: [] },
        {
          className: "card-7-col-me4gu8",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(214, 214, 214, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-7-col-me4gu8", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }] },
        {
          className: "card-7-img-me4gu8",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "card-7-img-me4gu8", pseudo: "hover", rules: [] },
        {
          className: "card-7-title-link-me4gu8",
          pseudo: "",
          rules: [
            { property: "font-family", value: '"Montserrat"' },
            { property: "font-size", value: "25px" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-7-title-link-me4gu8", pseudo: "hover", rules: [{ property: "color", value: "rgba(0, 84, 127, 1)" }] },
        {
          className: "card-7-desc-text-me4gu8",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        {
          className: "card-7-link-readmore-me4gu8",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 169, 255, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "padding", value: "0.5rem 1.5rem 0.5rem 1.5rem" },
            { property: "width", value: "auto" },
            { property: "text-decoration", value: "none" },
            { property: "align-self", value: "start" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "card-7-link-readmore-me4gu8",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(0, 169, 255, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 169, 255, 1)" },
          ],
        },
      ],
    },
    {
      componentId: "mDkDyy",
      classes: [
        {
          className: "section.clients-1-component-mDkDyy",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.clients-1-component-mDkDyy", pseudo: "hover", rules: [] },
        {
          className: "clients-1-logo-img-mDkDyy",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1.5rem 1.5rem 0" },
            { property: "max-height", value: "50px" },
            { property: "max-width", value: "100px" },
            { property: "height", value: "auto" },
            { property: "width", value: "auto" },
            { property: "filter", value: "grayscale(100%)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "0.5" },
          ],
        },
        {
          className: "clients-1-logo-img-mDkDyy",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: "1" },
            { property: "filter", value: "grayscale(0%)" },
          ],
        },
      ],
    },
    {
      componentId: "Jng5Ue",
      classes: [
        {
          className: "section.basics-1-component-Jng5Ue",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-Jng5Ue", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-Jng5Ue",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-Jng5Ue", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-Jng5Ue",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-Jng5Ue", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "J32VGT",
      classes: [
        {
          className: "section.basics-2-component-J32VGT",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-J32VGT", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-J32VGT",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-J32VGT", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-J32VGT",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-J32VGT", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "RLL3ap",
      classes: [
        {
          className: "section.card-2-component-RLL3ap",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-2-component-RLL3ap", pseudo: "hover", rules: [] },
        {
          className: "card-2-col-RLL3ap",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "card-2-overlay-wrapper-RLL3ap",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "border-radius", value: "50%" },
            { property: "height", value: "250px" },
            { property: "width", value: "250px" },
          ],
        },
        {
          className: "card-2-overlay-wrapper-RLL3ap",
          pseudo: "hover .card-2-overlay-content-RLL3ap",
          rules: [
            { property: "visibility", value: "visible" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "card-2-overlay-content-RLL3ap",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.5)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "visibility", value: "hidden" },
            { property: "opacity", value: "0" },
          ],
        },
        {
          className: "card-2-avatar-RLL3ap",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
          ],
        },
        { className: "card-2-avatar-RLL3ap", pseudo: "hover", rules: [] },
        {
          className: "card-2-title-RLL3ap",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
          ],
        },
        { className: "card-2-title-RLL3ap", pseudo: "hover", rules: [] },
        {
          className: "card-2-subtitle-RLL3ap",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(230, 230, 230, 1)" },
          ],
        },
        { className: "card-2-subtitle-RLL3ap", pseudo: "hover", rules: [] },
        {
          className: "card-2-social-link-RLL3ap",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0.5rem 0 0" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "card-2-social-link-RLL3ap", pseudo: "hover", rules: [{ property: "color", value: "rgba(200, 200, 200, 1)" }] },
        { className: "card-2-social-link-RLL3ap", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      ],
    },
    {
      componentId: "zDA7Uf",
      classes: [
        {
          className: "section.contactus-8-component-zDA7Uf",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-8-component-zDA7Uf", pseudo: "hover", rules: [] },
        {
          className: "contactus-8-col-zDA7Uf",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-8-buttonwrapper-zDA7Uf",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-8-buttonwrapper-zDA7Uf", pseudo: "hover", rules: [] },
        {
          className: "contactus-8-button-zDA7Uf",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "contactus-8-button-zDA7Uf",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(229, 42, 5, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "contactus-8-inputwrapper-zDA7Uf", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        {
          className: "contactus-8-inputfield-zDA7Uf",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "border-style", value: "none none solid none" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-8-inputfield-zDA7Uf",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(106, 115, 122, 1)" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-8-inputfield-zDA7Uf",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-8-inputfield-zDA7Uf", pseudo: "hover", rules: [] },
        {
          className: "contactus-8-label-zDA7Uf",
          pseudo: "",
          rules: [
            { property: "display", value: "none" },
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-style", value: "none none solid none" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(0, 85, 128, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "contactus-8-label-zDA7Uf", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "pX5WY1",
      classes: [
        {
          className: "section.basics-1-component-pX5WY1",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-pX5WY1", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-pX5WY1",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-pX5WY1", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-pX5WY1",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-pX5WY1", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "z8Dqe6",
      classes: [
        {
          className: "section.basics-2-component-z8Dqe6",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-z8Dqe6", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-z8Dqe6",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-z8Dqe6", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-z8Dqe6",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-z8Dqe6", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "nrZQ88",
      classes: [
        {
          className: "section.contactus-7-component-nrZQ88",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-7-component-nrZQ88", pseudo: "hover", rules: [] },
        {
          className: "contactus-7-col-nrZQ88",
          pseudo: "",
          rules: [
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "center" },
            { property: "text-align", value: "center" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        {
          className: "contactus-7-iconwrapper-nrZQ88",
          pseudo: "",
          rules: [
            { property: "height", value: "65px" },
            { property: "width", value: "65px" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 169, 255, 1)" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0 0 0 0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "cursor", value: "default !important" },
          ],
        },
        {
          className: "contactus-7-iconwrapper-nrZQ88",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(0, 169, 255, 1)" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "contactus-7-title-nrZQ88",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "contactus-7-title-nrZQ88", pseudo: "hover", rules: [] },
        { className: "contactus-7-text-nrZQ88", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "contactus-7-text-nrZQ88", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "Q5sOho",
      classes: [
        {
          className: "section.footer-3-component-Q5sOho",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.footer-3-component-Q5sOho", pseudo: "hover", rules: [] },
        {
          className: "footer-3-component-Q5sOho",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "footer-3-component-Q5sOho", pseudo: "hover", rules: [] },
        {
          className: "footer-3-title-Q5sOho",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 3rem 0" },
            { property: "font-size", value: "48px" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "footer-3-title-Q5sOho", pseudo: "hover", rules: [] },
        {
          className: "footer-3-text1-Q5sOho",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "footer-3-text1-Q5sOho", pseudo: "hover", rules: [] },
        {
          className: "footer-3-text2-Q5sOho",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 3rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "footer-3-text2-Q5sOho", pseudo: "hover", rules: [] },
        {
          className: "footer-3-text3-Q5sOho",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 1rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "footer-3-text3-Q5sOho", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "voGGO5",
      classes: [
        {
          className: "section.clients-3-component-voGGO5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.clients-3-component-voGGO5", pseudo: "hover", rules: [] },
        {
          className: "clients-3-col-voGGO5",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "start" },
          ],
        },
        { className: "clients-3-clientcol-voGGO5", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
        {
          className: "clients-3-logo-voGGO5",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 1rem 0" },
            { property: "max-height", value: "50px" },
            { property: "max-width", value: "100px" },
            { property: "height", value: "auto" },
            { property: "width", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "clients-3-logo-voGGO5", pseudo: "hover", rules: [] },
        { className: "clients-3-clientname-voGGO5", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
        { className: "clients-3-clientname-voGGO5", pseudo: "hover", rules: [] },
        {
          className: "clients-3-title-voGGO5",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "clients-3-title-voGGO5", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "clients-3-text-voGGO5",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "clients-3-text-voGGO5", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "RYuVVt",
      classes: [
        {
          className: "section.card-9-component-RYuVVt",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-9-component-RYuVVt", pseudo: "hover", rules: [] },
        { className: "card-9-row-RYuVVt", pseudo: "", rules: [] },
        {
          className: "card-9-col-RYuVVt",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(214, 214, 214, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-9-col-RYuVVt", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }] },
        {
          className: "card-9-desc-text-RYuVVt",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        {
          className: "card-9-img-RYuVVt",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-9-img-RYuVVt",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-9-title-RYuVVt",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-9-title-RYuVVt", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "w2JEk7",
      classes: [
        {
          className: "section.card-10-component-w2JEk7",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-10-component-w2JEk7", pseudo: "hover", rules: [] },
        { className: "card-10-row-w2JEk7", pseudo: "", rules: [] },
        {
          className: "card-10-col-w2JEk7",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(214, 214, 214, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-10-col-w2JEk7", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }] },
        {
          className: "card-10-desc-text-w2JEk7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        {
          className: "card-10-img-w2JEk7",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-img-w2JEk7",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-10-title-w2JEk7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-10-title-w2JEk7", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "VbSM9C",
      classes: [
        {
          className: "section.card-9-component-VbSM9C",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-9-component-VbSM9C", pseudo: "hover", rules: [] },
        { className: "card-9-row-VbSM9C", pseudo: "", rules: [] },
        {
          className: "card-9-col-VbSM9C",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0 0 0" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(214, 214, 214, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-9-col-VbSM9C", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }] },
        {
          className: "card-9-desc-text-VbSM9C",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        {
          className: "card-9-img-VbSM9C",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-9-img-VbSM9C",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-9-title-VbSM9C",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(0, 169, 255, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0.5rem 0.5rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-9-title-VbSM9C", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "HSy5WG",
      classes: [
        {
          className: "section.contactus-8-component-HSy5WG",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-8-component-HSy5WG", pseudo: "hover", rules: [] },
        {
          className: "contactus-8-col-HSy5WG",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-8-button-HSy5WGwrapper-HSy5WG",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-8-button-HSy5WGwrapper-HSy5WG", pseudo: "hover", rules: [] },
        {
          className: "contactus-8-label-HSy5WG",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-8-label-HSy5WG", pseudo: "hover", rules: [] },
        {
          className: "contactus-8-inputfield-HSy5WG",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1rem" },
            { property: "outline", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "border-style", value: "none none solid none" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.2s ease-in-out" },
          ],
        },
        {
          className: "contactus-8-inputfield-HSy5WG",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(106, 115, 122, 1)" },
            { property: "border-color", value: "rgba(0, 85, 128, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-8-inputfield-HSy5WG",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-8-inputfield-HSy5WG", pseudo: "hover", rules: [] },
        { className: "contactus-8-inputwrapper-HSy5WG", pseudo: "", rules: [{ property: "margin", value: "0rem 0rem 1rem 0rem" }] },
        {
          className: "contactus-8-button-HSy5WG",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(0, 85, 128, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 85, 128, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "contactus-8-button-HSy5WG",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(0, 85, 128, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Nunito:300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Nunito",
    fontHeadersWeight: "700",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(0, 169, 255, 1)",
    color2: "rgba(0, 84, 127, 1)",
    color3: "rgba(76, 195, 254, 1)",
    color4: "rgba(0, 118, 178, 1)",
    color5: "rgba(153, 220, 255, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
