export const sectiondividers21 = {
  id: "sectiondividers-21",
  name: "Section dividers 21",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "svg",
        classes: [],
        styles: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scaleY(-1)" },
        ],
        attributes: [
          { property: "xmlns", value: "http://www.w3.org/2000/svg" },
          { property: "version", value: "1.1" },
          { property: "width", value: "100%" },
          { property: "height", value: "150" },
          { property: "viewBox", value: "0 0 100 150" },
          { property: "preserveAspectRatio", value: "none" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              { property: "opacity", value: "0.5" },
              { property: "d", value: "M0 150 L100 150 L100 0 Z" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              { property: "d", value: "M0 150 L100 150 L100 50 Z" },
            ],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
