import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";
import ColorPicker from "./formComponents/ColorPicker";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const BoxShadow = ({ sb: { startStyles } }) => {
  const [bool, setBool] = useState(false);
  useEffect(() => {
    setBool(formGetStartValue(startStyles, "boxShadowChangeOnHover"));
  }, [startStyles]);

  return (
    <>
      <Slider
        label={translate("cEditForms.shadowHoriLength", false, null)}
        id={"boxShadowHori"}
        min={-100}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "boxShadowHori")}
        tooltipText={translate("cEditForms.tooltipShadowHoriLength", false, null)}
      />
      <Slider
        label={translate("cEditForms.shadowVerticalLength", false, null)}
        id={"boxShadowVert"}
        min={-100}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "boxShadowVert")}
        tooltipText={translate("cEditForms.tooltipShadowVerticalLength", false, null)}
      />
      <Slider
        label={translate("cEditForms.blurRadius", false, null)}
        id={"boxShadowBlur"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "boxShadowBlur")}
        tooltipText={translate("cEditForms.shadowBlur", false, null)}
      />
      <Slider
        label={translate("cEditForms.spreadRadius", false, null)}
        id={"boxShadowSpread"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "boxShadowSpread")}
        tooltipText={translate("cEditForms.shadowSpread", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.shadowColorOpacity", false, null)}
        id={"boxShadowRgba"}
        startValue={formGetStartValue(startStyles, "boxShadowRgba")}
        tooltipText={translate("cEditForms.shadowColor", false, null)}
      />

      <div className={`form-check form-switch mt-3 ${bool ? "mb-n2" : "mb-3"}`}>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="switchBoxShadowHover"
          value={bool}
          checked={bool}
          onChange={(e) => setBool(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="switchBoxShadowHover">
          {translate("cEditForms.changeBoxShadowHover", false, null)}
        </label>
      </div>
      {bool && (
        <>
          <Slider
            label={translate("cEditForms.shadowHoriLength", false, null)}
            id={"boxShadowHoriHover"}
            min={-100}
            max={100}
            step={1}
            startValue={formGetStartValue(startStyles, "boxShadowHoriHover")}
            tooltipText={translate("cEditForms.shadowHoriDistanceHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.shadowVerticalLength", false, null)}
            id={"boxShadowVertHover"}
            min={-100}
            max={100}
            step={1}
            startValue={formGetStartValue(startStyles, "boxShadowVertHover")}
            tooltipText={translate("cEditForms.shadowVerticalDistanceHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.blurRadius", false, null)}
            id={"boxShadowBlurHover"}
            min={0}
            max={100}
            step={1}
            startValue={formGetStartValue(startStyles, "boxShadowBlurHover")}
            tooltipText={translate("cEditForms.shadowSizeHover", false, null)}
          />
          <Slider
            label={translate("cEditForms.spreadRadius", false, null)}
            id={"boxShadowSpreadHover"}
            min={0}
            max={100}
            step={1}
            startValue={formGetStartValue(startStyles, "boxShadowSpreadHover")}
            tooltipText={translate("cEditForms.shadowSizeHover", false, null)}
          />
          <ColorPicker
            label={translate("cEditForms.shadowColorOpacity", false, null)}
            id={"boxShadowRgbaHover"}
            startValue={formGetStartValue(startStyles, "boxShadowRgbaHover")}
            tooltipText={translate("cEditForms.shadowSizeHover", false, null)}
          />
        </>
      )}
    </>
  );
};

BoxShadow.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(BoxShadow);
