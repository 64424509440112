export const card4 = {
  id: "card-4",
  name: "Card 4",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "card-4-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-center", "card-4-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "card-4-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card4" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-4-element-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-4-avatar"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-4-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h5",
                        classes: ["card-4-title"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Name Lastname",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-4-subtitle"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "block",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [{ property: "data-name", value: "block" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-solid", "fa-envelope"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-twitter"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-linkedin"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-facebook-f"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "card-4-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card4" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-4-element-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-4-avatar"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-4-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h5",
                        classes: ["card-4-title"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Name Lastname",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-4-subtitle"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "block",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [{ property: "data-name", value: "block" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-solid", "fa-envelope"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-twitter"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-linkedin"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-facebook-f"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "card-4-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card4" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-4-element-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-4-avatar"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-4-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h5",
                        classes: ["card-4-title"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Name Lastname",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-4-subtitle"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "block",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [{ property: "data-name", value: "block" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-solid", "fa-envelope"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-twitter"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-linkedin"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-facebook-f"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "card-4-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card4" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-4-element-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-4-avatar"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-4-content"],
                    styles: [],
                    attributes: [
                      { property: "data-overlaycontent", value: "true" },
                      { property: "data-elementalignmentwrapper", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h5",
                        classes: ["card-4-title"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Name Lastname",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-4-subtitle"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "block",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [{ property: "data-name", value: "block" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-solid", "fa-envelope"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-twitter"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-linkedin"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "element",
                            htmlTagName: "a",
                            classes: ["card-4-social-link"],
                            styles: [],
                            attributes: [
                              { property: "href", value: "#!" },
                              { property: "data-href", value: "#!" },
                              { property: "data-target", value: "_blank" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                              { property: "data-name", value: "a" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-brands", "fa-facebook-f"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.card-4-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.card-4-component", pseudo: "hover", rules: [] },
      {
        className: "card-4-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
        ],
      },
      {
        className: "card-4-element-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0.4rem" },
          { property: "padding", value: "0 0 0 0" },
          { property: "width", value: "100%" },
        ],
      },
      { className: "card-4-element-wrapper", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" }] },
      { className: "card-4-element-wrapper", pseudo: "hover .card-4-content", rules: [{ property: "height", value: "120px" }] },
      {
        className: "card-4-content",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "start" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "bottom", value: "0" },
          { property: "height", value: "80px" },
          { property: "background", value: "rgba(0, 0, 0, 0.5)" },
          { property: "padding", value: "0 0 0 0" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "card-4-avatar",
        pseudo: "",
        rules: [
          { property: "width", value: "100%" },
          { property: "height", value: "auto" },
        ],
      },
      {
        className: "card-4-avatar",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-4-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "1rem 0 0.5rem 0" },
          { property: "text-align", value: "center" },
          { property: "color", value: "rgba(250, 250, 250, 1)" },
        ],
      },
      {
        className: "card-4-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-4-subtitle",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 1rem 0" },
          { property: "text-align", value: "center" },
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "font-size", value: "13px" },
        ],
      },
      {
        className: "card-4-subtitle",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-4-social-link",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0.5rem 0 0" },
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "text-decoration", value: "none" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      { className: "card-4-social-link", pseudo: "hover", rules: [{ property: "color", value: "rgba(180, 180, 180, 1)" }] },
      { className: "card-4-social-link", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
    ],
  },
};
