export const card9 = {
  id: "card-9",
  name: "Card 9",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "card-9-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "card-9-row"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-6", "order-first", "card-9-col"],
            styles: [],
            attributes: [
              { property: "data-editable", value: "true" },
              { property: "data-name", value: "card7" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["card-9-img"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/work-4.png" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                  { property: "data-imgresizable", value: "false" },
                ],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-6", "card-9-col"],
            styles: [],
            attributes: [
              { property: "data-editable", value: "true" },
              { property: "data-name", value: "card7" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h4",
                classes: ["card-9-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["card-9-text", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "block",
                htmlTagName: "div",
                classes: ["row", "align-items-start", "justify-content-center"],
                styles: [],
                attributes: [{ property: "data-name", value: "block" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-4", "col-lg-4", "card-9-icon-col"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "element_col" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["flexSameHeight", "card-9-icon-wrapper"],
                        styles: [],
                        attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["d-flex"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["card-9-iconwrapper"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "div" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-iconwrapper", value: "true" },
                                  { property: "data-texteditable", value: "false" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "span",
                                    classes: ["icon", "fa-regular", "fa-user"],
                                    styles: [],
                                    attributes: [{ property: "data-checkparent", value: "true" }],
                                    content: "",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["toGrow"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "h6",
                                    classes: ["card-9-icon-title", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "h" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Lorem Ipsum",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["toGrow", "card-9-icon-text", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "p" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Lorem ipsum dolor sit amet.",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-4", "col-lg-4", "card-9-icon-col"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "element_col" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["flexSameHeight", "card-9-icon-wrapper"],
                        styles: [],
                        attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["d-flex"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["card-9-iconwrapper"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "div" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-iconwrapper", value: "true" },
                                  { property: "data-texteditable", value: "false" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "span",
                                    classes: ["icon", "fa-regular", "fa-comment"],
                                    styles: [],
                                    attributes: [{ property: "data-checkparent", value: "true" }],
                                    content: "",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["toGrow"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "h6",
                                    classes: ["card-9-icon-title", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "h" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Lorem Ipsum",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["toGrow", "card-9-icon-text", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "p" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Lorem ipsum dolor sit amet.",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-4", "col-lg-4", "card-9-icon-col"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "element_col" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["flexSameHeight", "card-9-icon-wrapper"],
                        styles: [],
                        attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["d-flex"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["card-9-iconwrapper"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "div" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-iconwrapper", value: "true" },
                                  { property: "data-texteditable", value: "false" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "span",
                                    classes: ["icon", "fa-regular", "fa-bell"],
                                    styles: [],
                                    attributes: [{ property: "data-checkparent", value: "true" }],
                                    content: "",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["toGrow"],
                                styles: [],
                                attributes: [],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "h6",
                                    classes: ["card-9-icon-title", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "h" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Lorem Ipsum",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "p",
                                    classes: ["toGrow", "card-9-icon-text", "w-100", "w-md-100", "w-lg-100"],
                                    styles: [],
                                    attributes: [
                                      { property: "data-editable", value: "true" },
                                      { property: "data-texteditable", value: "true" },
                                      { property: "data-name", value: "p" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Lorem ipsum dolor sit amet.",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.card-9-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.card-9-component", pseudo: "hover", rules: [] },
      {
        className: "card-9-row",
        pseudo: "",
        rules: [],
      },
      {
        className: "card-9-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0.5rem 0 0.5rem" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "overflow", value: "hidden" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0rem" },
        ],
      },
      {
        className: "card-9-col",
        pseudo: "hover",
        rules: [{ property: "border-color", value: "rgba(0, 0, 0, 0)" }],
      },
      {
        className: "card-9-img",
        pseudo: "",
        rules: [
          { property: "width", value: "100%" },
          { property: "max-height", value: "350px" },
          { property: "height", value: "auto" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "card-9-img",
        pseudo: "hover",
        rules: [],
      },
      { className: "card-9-card-col", pseudo: "", rules: [{ property: "padding", value: "0.5rem 0 0.5rem 0" }] },
      { className: "card-9-card-col", pseudo: "hover", rules: [] },
      { className: "card-9-card-wrapper", pseudo: "", rules: [] },
      { className: "card-9-card-wrapper", pseudo: "hover", rules: [] },
      {
        className: "card-9-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 1.5rem 0" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "font-weight", value: "bold" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "card-9-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-9-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 2rem 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "card-9-text",
        pseudo: "hover",
        rules: [],
      },
      { className: "card-9-icon-col", pseudo: "", rules: [] },
      { className: "card-9-icon-col", pseudo: "hover", rules: [] },
      { className: "card-9-icon-wrapper", pseudo: "", rules: [] },
      { className: "card-9-icon-wrapper", pseudo: "hover", rules: [] },
      {
        className: "card-9-iconwrapper",
        pseudo: "",
        rules: [
          { property: "font-size", value: "2rem" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "card-9-iconwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-9-icon-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0 1rem" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "font-weight", value: "bold" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "card-9-icon-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-9-icon-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "0.5rem 0 0 1rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "card-9-icon-text",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
