export const imggallery2 = {
  id: "imggallery-2",
  name: "Image gallery 2",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "imggallery-2-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "imggallery-2-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "element_col" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-2-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img_gallery2" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-2-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/mountain1.png" },
                      { property: "data-imgresizable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "element_col" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-2-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img_gallery2" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-2-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/mountain2.png" },
                      { property: "data-imgresizable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "element_col" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-2-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img_gallery2" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-2-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/mountain3.png" },
                      { property: "data-imgresizable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "element_col" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["imggallery-2-wrapper"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img_gallery2" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["imggallery-2-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/mountain4.png" },
                      { property: "data-imgresizable", value: "false" },
                      { property: "data-checkparent", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.imggallery-2-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.imggallery-2-component", pseudo: "hover", rules: [] },
      {
        className: "imggallery-2-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "0rem 0rem 0rem 0rem" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "stretch" },
          { property: "justify-content", value: "center" },
        ],
      },
      {
        className: "imggallery-2-wrapper",
        pseudo: "",
        rules: [
          { property: "overflow", value: "hidden" },
          { property: "border-radius", value: "0rem" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(0, 0, 0, 1)" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          { property: "transition", value: "all 0.4s ease" },
        ],
      },
      {
        className: "imggallery-2-wrapper",
        pseudo: "hover",
        rules: [
          { property: "border-color", value: "rgba(0, 0, 0, 1)" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
        ],
      },
      {
        className: "imggallery-2-img",
        pseudo: "",
        rules: [
          { property: "width", value: "100%" },
          { property: "height", value: "100%" },
          { property: "object-fit", value: "cover" },
          { property: "transition", value: "all 0.4s ease" },
        ],
      },
      {
        className: "imggallery-2-img",
        pseudo: "hover",
        rules: [{ property: "transform", value: "scale(1.15)" }],
      },
    ],
  },
};
