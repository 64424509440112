import React from "react";

import SiteFonts from "./components/SiteFonts";

const ModalSiteFontsBody = () => {
  return (
    <div className="modal fade" id="ModalSiteFontsBody" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
      <SiteFonts target={"body"} />
    </div>
  );
};

export default ModalSiteFontsBody;
