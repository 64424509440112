export const parallax4 = {
  id: "parallax-4",
  name: "Parallax 4",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "parallax-4-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["parallax-4-wrapper"],
        styles: [],
        attributes: [
          { property: "data-name", value: "parallax" },
          { property: "data-editable", value: "true" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["parallax-4-content"],
            styles: [],
            attributes: [{ property: "data-parallaxcontent", value: "true" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["parallax-4-title", "w-100", "w-md-70", "w-lg-50"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Some appealing title!",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["parallax-4-text", "w-100", "w-md-70", "w-lg-50"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.parallax-4-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      {
        className: "section.parallax-4-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "parallax-4-component",
        pseudo: "",
        rules: [
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "parallax-4-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "height", value: "70vh" },
          { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/city1.jpg")' },
          { property: "background-size", value: "cover" },
          { property: "background-position", value: "center" },
          { property: "background-attachment", value: "fixed" },
          { property: "background-repeat", value: "no-repeat" },
        ],
      },
      {
        className: "parallax-4-content",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "background", value: "rgba(0, 0, 0, 0.3)" },
        ],
      },
      {
        className: "parallax-4-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
          { property: "margin", value: "0 0 3rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
          { property: "font-weight", value: "bold" },
        ],
      },
      {
        className: "parallax-4-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "parallax-4-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
          { property: "margin", value: "0 0 2rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "parallax-4-text",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
