export const hero4 = {
  id: "hero-4",
  name: "Hero 4",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "hero-4-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["hero-4-wrapper"],
        styles: [],
        attributes: [{ property: "data-herowrapper", value: "true" }],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "img",
            classes: ["hero-4-img"],
            styles: [],
            attributes: [
              { property: "src", value: "https://cdn.satonda.com/eb/assets/hero-4.png" },
              { property: "alt", value: "Image" },
              { property: "data-editable", value: "true" },
              { property: "data-name", value: "img" },
              { property: "data-imgresizable", value: "false" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["hero-4-content"],
            styles: [],
            attributes: [
              { property: "data-editable", value: "true" },
              { property: "data-name", value: "hero4_contentwrapper" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["hero-4-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Some appealing title!",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["hero-4-text", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "a",
                classes: ["hero-4-button"],
                styles: [],
                attributes: [
                  { property: "href", value: "#!" },
                  { property: "data-href", value: "#!" },
                  { property: "data-target", value: "_self" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "button" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Read more",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.hero-4-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      { className: "section.hero-4-component", pseudo: "hover", rules: [] },
      {
        className: "hero-4-component",
        pseudo: "",
        rules: [
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "hero-4-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "height", value: "80vh" },
          { property: "display", value: "flex" },
          { property: "justify-content", value: "center" },
        ],
      },
      {
        className: "hero-4-img",
        pseudo: "",
        rules: [
          { property: "display", value: "block" },
          { property: "height", value: "100%" },
          { property: "width", value: "100%" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "hero-4-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-4-content",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "position", value: "absolute" },
          { property: "bottom", value: "0" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          { property: "border-radius", value: "0rem" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
        ],
      },
      {
        className: "hero-4-content",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
        ],
      },
      {
        className: "hero-4-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(0, 0, 0, 1)" },
          { property: "margin", value: "2rem 0 1rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "hero-4-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(0, 0, 0, 1)" },
          { property: "margin", value: "0 4.5rem 2rem 4.5rem" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "hero-4-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(0, 0, 0, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(0, 0, 0, 1)" },
          { property: "border-radius", value: "0rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
          { property: "text-decoration", value: "none" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "hero-4-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(0, 0, 0, 1)" },
        ],
      },
    ],
  },
};
