export const sectiondividers91 = {
  id: "sectiondividers-91",
  name: "Section dividers 91",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "svg",
        classes: [],
        styles: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scaleY(-1)" },
        ],
        attributes: [
          { property: "xmlns", value: "http://www.w3.org/2000/svg" },
          { property: "version", value: "1.0" },
          { property: "width", value: "100%" },
          { property: "height", value: "140" },
          { property: "viewBox", value: "0 0 1280 140" },
          { property: "preserveAspectRatio", value: "none" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "defs",
            classes: [],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "linearGradient",
                classes: [],
                styles: [],
                attributes: [
                  { property: "id", value: "svgSmoothFade1" },
                  { property: "x1", value: "0" },
                  { property: "x2", value: "0" },
                  { property: "y1", value: "0" },
                  { property: "y2", value: "1" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "stop",
                    classes: [],
                    styles: [],
                    attributes: [
                      { property: "offset", value: "0%" },
                      { property: "stop-color", value: "rgba(204, 204, 204, 1)" },
                      { property: "stop-opacity", value: "0" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "stop",
                    classes: [],
                    styles: [],
                    attributes: [
                      { property: "offset", value: "100%" },
                      { property: "stop-color", value: "rgba(204, 204, 204, 1)" },
                      { property: "stop-opacity", value: "1" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "d", value: "M 0 0 L 0 140 L 1280 140 L 1280 0 Z" },
              { property: "fill", value: "url(#svgSmoothFade1)" },
            ],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
