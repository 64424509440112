export const template8 = {
  id: "6392527bbd4b4de0e754b70a",
  name: { en: "Construction company", nl: "Bouwbedrijf" },
  desc: { en: "Single-page site for a construction company", nl: "Een-pagina site voor een bouwbedrijf" },
  categories: ["physicalProducts"],
  sbPages: [
    {
      pageId: "ad5150c9-8bd8-4788-9797-5b1d05bef7d4",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "VzJqZm",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-VzJqZm", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "sticky-top" },
          ],
          content: "",
          children: [
            {
              childId: "qnlpy5",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "vl9ErM",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "ZlMW0i",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-VzJqZm"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Logo" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-companyname-orbitron-white.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "RDxlZw",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-VzJqZm", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "Gpfp6s",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "ZzCG0j",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "yZTRPN",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "m2xA7M",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-VzJqZm"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Home",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Pzvo0t",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-VzJqZm"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#services" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Services",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "pME3vU",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-VzJqZm"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#about" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "About",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "EglI81",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-VzJqZm"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#projects" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Projects",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "aicYxb",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-VzJqZm"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#clients" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Clients",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "rzguQN",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-VzJqZm"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#contact" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Contact",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "TR7lOw",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-6-component-TR7lOw"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "CDHpzj",
              type: "",
              htmlTagName: "div",
              classes: ["hero-6-wrapper-TR7lOw"],
              styles: [],
              attributes: [{ property: "data-herowrapper", value: "true" }],
              content: "",
              children: [
                {
                  childId: "Cl2OYC",
                  type: "",
                  htmlTagName: "img",
                  classes: ["hero-6-img-TR7lOw"],
                  styles: [],
                  attributes: [
                    { property: "alt", value: "Image" },
                    { property: "src", value: "https://cdn.satonda.com/eb/assets/construction-1.png" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "Jclxbo",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-6-content-TR7lOw"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "hero1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "xr0sXK",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-6-title-TR7lOw", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "C8Z1RL",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-6-text-TR7lOw", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "mP7MlB",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-mP7MlB"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "qPV5n3",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-mP7MlB", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
                { property: "id", value: "services" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Our services", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "I6cGIX",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-1-component-I6cGIX"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "o7IIxy",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "mh6vY5",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-1-col-I6cGIX"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "AcFIyn",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-1-wrapper-I6cGIX"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "B0AB68",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-1-avatar-I6cGIX"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/construction-2.png" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "f1vtZ1",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["toGrow", "card-1-title-I6cGIX"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Planning",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "C7ENvA",
                          type: "",
                          htmlTagName: "p",
                          classes: ["card-1-subtitle-I6cGIX"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vel nisl gravida, placerat turpis at, ornare purus. Aenean in facilisis elit. Duis eget erat maximus, feugiat elit sit amet, consectetur mi.",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "lyljNl",
                          type: "block",
                          htmlTagName: "div",
                          classes: [],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "yTvf50",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-1-col-I6cGIX"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "qNJaVo",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-1-wrapper-I6cGIX"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "wb6Emr",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-1-avatar-I6cGIX"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/construction-4.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "h4Nnsf",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["toGrow", "card-1-title-I6cGIX"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Modelling",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "KJ1Ty4",
                          type: "",
                          htmlTagName: "p",
                          classes: ["card-1-subtitle-I6cGIX"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vel nisl gravida, placerat turpis at, ornare purus. Aenean in facilisis elit. Duis eget erat maximus, feugiat elit sit amet, consectetur mi.",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "bbUgzb",
                          type: "block",
                          htmlTagName: "div",
                          classes: [],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "gJdUoh",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-1-col-I6cGIX"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "dHoMQY",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-1-wrapper-I6cGIX"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "qYKTub",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-1-avatar-I6cGIX"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/construction-3.png" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "T7kpXS",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["toGrow", "card-1-title-I6cGIX"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Construction management",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "pvCpyr",
                          type: "",
                          htmlTagName: "p",
                          classes: ["card-1-subtitle-I6cGIX"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vel nisl gravida, placerat turpis at, ornare purus. Aenean in facilisis elit. Duis eget erat maximus, feugiat elit sit amet, consectetur mi.",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "JPJzFV",
                          type: "block",
                          htmlTagName: "div",
                          classes: [],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Wkk8hD",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-5-component-Wkk8hD"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "about" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "R6Di5P",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-5-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "hRpulG",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "basics-5-col-Wkk8hD"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "B6lwNu",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-5-img-Wkk8hD"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/construction-5.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "zFOAlp",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-5-col-Wkk8hD"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "K1tOGm",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["basics-5-title-Wkk8hD", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "About", children: [] },
                      ],
                    },
                    {
                      childId: "ymjXfd",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-5-text-Wkk8hD", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vel nisl gravida, placerat turpis at, ornare purus. Aenean in facilisis elit. Duis eget erat maximus, feugiat elit sit amet, consectetur mi. Maecenas feugiat commodo ipsum, non porttitor diam tincidunt ac. ",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "ghNl00",
                      type: "",
                      htmlTagName: "p",
                      classes: ["fjcUKnTaw7RnDESAH3McqtTUVRftv4", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Cras eu massa diam. Duis leo neque, faucibus in consequat sit amet, aliquam vitae lacus. Quisque iaculis nibh sit amet dolor pellentesque pretium. Fusce eleifend urna ac vestibulum egestas. Vestibulum tempor faucibus lobortis. Aenean fermentum, diam id blandit facilisis, turpis ante cursus tellus, eget fringilla nulla elit id tortor. ",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "uKbEmM",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "statistics-2-component-uKbEmM"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "iEuzQM",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "statistics-2-statswrapper", "w-100"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "NKKENg",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "CFeeCW",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-uKbEmM"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "BEhYMc",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["statistics-2-stat-number-uKbEmM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "4", children: [] },
                          ],
                        },
                        {
                          childId: "zcvhNj",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-uKbEmM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Years active",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "QHTJpd",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "MXjF4S",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-uKbEmM"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "sEpCAS",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["statistics-2-stat-number-uKbEmM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "27", children: [] },
                          ],
                        },
                        {
                          childId: "HXWqYm",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-uKbEmM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Employees",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "YeoGVO",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ET7PM2",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-uKbEmM"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "JGZdof",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["statistics-2-stat-number-uKbEmM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "200+",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "kMvOig",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-uKbEmM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Projects delivered",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "NrbAuJ",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-6", "col-md-4", "col-lg-2", "statistics-2-stat"],
                  styles: [],
                  attributes: [
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "WkkEHF",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "statistics-2-stat-col-uKbEmM"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "utwz1k",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["statistics-2-stat-number-uKbEmM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "€20m",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "B3MZx2",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "statistics-2-stat-caption-uKbEmM", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Funding",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "SLgoiV",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-SLgoiV"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "projects" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "un9yZc",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-SLgoiV", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Projects", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "aTMoZ5",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "imggallery-1-component-aTMoZ5"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "GNHnup",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "imggallery-1-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "S2j6ju",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-1-col-aTMoZ5"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ekG6a0",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-aTMoZ5"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "YEamAA",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-aTMoZ5"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/construction-6.png" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "xqIrHc",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-aTMoZ5"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "LReobl",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-aTMoZ5", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "VLndI1",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-1-col-aTMoZ5"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "vUqIOP",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-aTMoZ5"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Lz9mWC",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-aTMoZ5"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/construction-7.png" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "w73Pcq",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-aTMoZ5"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "vaxwyQ",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-aTMoZ5", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "vtusOZ",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-1-col-aTMoZ5"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "wew6vz",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-aTMoZ5"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "jiyTDt",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-aTMoZ5"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/construction-8.png" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "NUYp21",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-aTMoZ5"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "fNb72x",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-aTMoZ5", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Tem7Xo",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-1-col-aTMoZ5"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "yAVT70",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-aTMoZ5"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "o1kqza",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-aTMoZ5"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/construction-3.png" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "gxBJd6",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-aTMoZ5"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "JaDCXa",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-aTMoZ5", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "CFF1Gv",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-CFF1Gv"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "clients" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "fZN9Wy",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-CFF1Gv", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Clients", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "f2rUd1",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "clients-1-component-f2rUd1"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "to72lg",
              type: "block",
              htmlTagName: "div",
              classes: ["d-flex", "flex-wrap", "align-items-center", "justify-content-center", "clients-1-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "d7QlQ6",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "oFrOW6",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-f2rUd1"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "JSY2B0",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "thRQS5",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-f2rUd1"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "w9X3mP",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Z6HliX",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-f2rUd1"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-3.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "ryEyUe",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["clients-1-logo-wrapper"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "lIjNvo",
                      type: "",
                      htmlTagName: "img",
                      classes: ["clients-1-logo-img-f2rUd1"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-4.png" },
                        { property: "alt", value: "logo" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ASi31Q",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-ASi31Q"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "contact" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "RiI0br",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-ASi31Q", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "jDj4Ei",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "contactus-5-component-jDj4Ei"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "FOvOtL",
              type: "",
              htmlTagName: "div",
              classes: ["contactus-5-googlemaps-wrapper-jDj4Ei"],
              styles: [],
              attributes: [
                { property: "data-name", value: "googlemaps_wrapper" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [],
            },
            {
              childId: "HYiH4n",
              type: "",
              htmlTagName: "iframe",
              classes: [],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "googlemaps" },
                {
                  property: "src",
                  value:
                    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8680.352904042613!2d4.899406522082844!3d52.37329199453203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609b70dd81623%3A0xcae71b8d3adfd142!2sAmsterdam%20Centraal!5e0!3m2!1sen!2snl!4v1662818615871!5m2!1sen!2snl",
                },
                { property: "scrolling", value: "no" },
                { property: "loading", value: "lazy" },
                { property: "referrerpolicy", value: "no-referrer-when-downgrade" },
                { property: "width", value: "100%" },
                { property: "height", value: "100%" },
                { property: "frameborder", value: "0" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "RnOcqE",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-6-component-RnOcqE"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "vMMC6v",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-6-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "v0Qyoq",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "contactus-6-col-RnOcqE"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "hJ1qwT",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["contactus-6-contacttitle-RnOcqE"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "h" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact", children: [] },
                      ],
                    },
                    {
                      childId: "UPiE9c",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-contacttext-RnOcqE"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Looking forward to hearing from you!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "CFPsQO",
                      type: "",
                      htmlTagName: "h5",
                      classes: ["contactus-6-subtitle-RnOcqE"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Phone", children: [] },
                      ],
                    },
                    {
                      childId: "H7l6iX",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-subtext-RnOcqE"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "123 456 7890",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "YpnZ7k",
                      type: "",
                      htmlTagName: "h5",
                      classes: ["contactus-6-subtitle-RnOcqE"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Email", children: [] },
                      ],
                    },
                    {
                      childId: "DAbYZh",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-subtext-RnOcqE"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "email@site.com",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "suP2r3",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-RnOcqE"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-RnOcqE"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-RnOcqE"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "name" },
                            { property: "placeholder", value: "Your name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-RnOcqE"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-RnOcqE"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-RnOcqE"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-RnOcqE"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-RnOcqE"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-RnOcqE"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-RnOcqE"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-RnOcqE"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-6-inputfield-RnOcqE"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-6-buttonwrapper-RnOcqE"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-6-button-RnOcqE"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "OPEtxw",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "footer-3-component-OPEtxw"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "e331Je",
              type: "",
              htmlTagName: "p",
              classes: ["footer-3-text3-OPEtxw", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "© Copyright Company name. All rights reserved.",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  sbCustomCss: [
    {
      componentId: "VzJqZm",
      classes: [
        {
          className: "scrolledpast-VzJqZm.navbar-1-wrapper-VzJqZm",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "rem" },
            { property: "padding-bottom", value: "rem" },
            { property: "background", value: "" },
          ],
        },
        { className: "scrolledpast-VzJqZm .navbar-1-logo-VzJqZm", pseudo: "", rules: [{ property: "max-height", value: "px" }] },
        {
          className: "navbar-1-wrapper-VzJqZm",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "background", value: "rgba(90, 97, 103, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 5px 7px 3px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "navbar-1-logo-VzJqZm",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "35px" },
          ],
        },
        { className: "navbar-1-logo-VzJqZm", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-VzJqZm",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "font-size", value: "1.5rem" },
            { property: "background", value: "rgba(229, 229, 229, 0)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-VzJqZm",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
            { property: "background", value: "rgba(178, 178, 178, 0)" },
            { property: "color", value: "rgba(234, 234, 234, 1)" },
          ],
        },
        {
          className: "navbar-1-link-VzJqZm",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "font-weight", value: "bold" },
          ],
        },
        { className: "navbar-1-link-VzJqZm", pseudo: "hover", rules: [{ property: "color", value: "rgba(244, 183, 0, 1)" }] },
      ],
    },
    {
      componentId: "TR7lOw",
      classes: [
        {
          className: "section.hero-6-component-TR7lOw",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-6-component-TR7lOw", pseudo: "hover", rules: [] },
        {
          className: "hero-6-component-TR7lOw",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-6-component-TR7lOw", pseudo: "hover", rules: [] },
        {
          className: "hero-6-wrapper-TR7lOw",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
          ],
        },
        {
          className: "hero-6-img-TR7lOw",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-6-content-TR7lOw",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.4)" },
          ],
        },
        {
          className: "hero-6-text-TR7lOw",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "hero-6-text-TR7lOw", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-6-title-TR7lOw",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(249, 187, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 4px rgba(244, 183, 0, 0.25)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-6-title-TR7lOw", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 4px rgba(244, 183, 0, 0.25)" }] },
      ],
    },
    {
      componentId: "I6cGIX",
      classes: [
        {
          className: "section.card-1-component-I6cGIX",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.card-1-component-I6cGIX", pseudo: "hover", rules: [] },
        { className: "card-1-col-I6cGIX", pseudo: "", rules: [] },
        { className: "card-1-col-I6cGIX", pseudo: "hover", rules: [] },
        { className: "card-1-wrapper-I6cGIX", pseudo: "", rules: [{ property: "align-items", value: "center" }] },
        { className: "card-1-wrapper-I6cGIX", pseudo: "hover", rules: [] },
        {
          className: "card-1-social-link-I6cGIX",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0.5rem 0 0" },
            { property: "color", value: "rgba(246, 129, 0, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "card-1-social-link-I6cGIX", pseudo: "hover", rules: [{ property: "color", value: "rgba(249, 187, 0, 1)" }] },
        { className: "card-1-social-link-I6cGIX", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "card-1-avatar-I6cGIX",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "250px" },
            { property: "height", value: "200px" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-1-avatar-I6cGIX",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-1-title-I6cGIX",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-1-title-I6cGIX", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-1-subtitle-I6cGIX",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(33, 37, 41, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-1-subtitle-I6cGIX", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Wkk8hD",
      classes: [
        {
          className: "section.basics-5-component-Wkk8hD",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-5-component-Wkk8hD", pseudo: "hover", rules: [] },
        {
          className: "basics-5-col-Wkk8hD",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-5-title-Wkk8hD",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(249, 187, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-5-title-Wkk8hD", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-5-img-Wkk8hD",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-5-img-Wkk8hD",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-5-text-Wkk8hD",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(33, 37, 41, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-5-text-Wkk8hD", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "fjcUKnTaw7RnDESAH3McqtTUVRftv4",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(33, 37, 41, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "fjcUKnTaw7RnDESAH3McqtTUVRftv4", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "uKbEmM",
      classes: [
        {
          className: "section.statistics-2-component-uKbEmM",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.statistics-2-component-uKbEmM", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-component-uKbEmM",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-2-component-uKbEmM", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-stat-col-uKbEmM",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "statistics-2-stat-col-uKbEmM", pseudo: "hover", rules: [] },
        {
          className: "statistics-2-stat-number-uKbEmM",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "font-size", value: "24px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "statistics-2-stat-caption-uKbEmM",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(33, 37, 41, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
      ],
    },
    {
      componentId: "SLgoiV",
      classes: [
        {
          className: "section.basics-1-component-SLgoiV",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-SLgoiV", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-SLgoiV",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-SLgoiV", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-SLgoiV",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(249, 187, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-SLgoiV", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "aTMoZ5",
      classes: [
        {
          className: "section.imggallery-1-component-aTMoZ5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.imggallery-1-component-aTMoZ5", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-col-aTMoZ5",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "stretch" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "imggallery-1-img-aTMoZ5",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "imggallery-1-img-aTMoZ5", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-title-aTMoZ5",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "imggallery-1-title-aTMoZ5", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-text-aTMoZ5",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(230, 230, 230, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "imggallery-1-text-aTMoZ5", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-wrapper-aTMoZ5",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(244, 183, 0, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(244, 183, 0, 0.2)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "imggallery-1-wrapper-aTMoZ5",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(244, 183, 0, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(244, 183, 0, 0.3)" },
          ],
        },
        {
          className: "imggallery-1-wrapper-aTMoZ5",
          pseudo: "hover .imggallery-1-overlay-content-aTMoZ5",
          rules: [
            { property: "visibility", value: "visible" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "imggallery-1-overlay-content-aTMoZ5",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "visibility", value: "hidden" },
            { property: "opacity", value: "0" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "background", value: "rgba(0, 0, 0, 0.5)" },
          ],
        },
        { className: "imggallery-1-overlay-content-aTMoZ5", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "CFF1Gv",
      classes: [
        {
          className: "section.basics-1-component-CFF1Gv",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-CFF1Gv", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-CFF1Gv",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-CFF1Gv", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-CFF1Gv",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(249, 187, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-CFF1Gv", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "f2rUd1",
      classes: [
        {
          className: "section.clients-1-component-f2rUd1",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.clients-1-component-f2rUd1", pseudo: "hover", rules: [] },
        {
          className: "clients-1-logo-img-f2rUd1",
          pseudo: "",
          rules: [
            { property: "max-height", value: "50px" },
            { property: "max-width", value: "100px" },
            { property: "opacity", value: 0.5 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(100%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 3rem 1.5rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "clients-1-logo-img-f2rUd1",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "ASi31Q",
      classes: [
        {
          className: "section.basics-1-component-ASi31Q",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-ASi31Q", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-ASi31Q",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-ASi31Q", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-ASi31Q",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(249, 187, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-ASi31Q", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "jDj4Ei",
      classes: [
        {
          className: "section.contactus-5-component-jDj4Ei",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "section.contactus-5-component-jDj4Ei", pseudo: "hover", rules: [] },
        {
          className: "contactus-5-component-jDj4Ei",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "50vh" },
            { property: "position", value: "relative" },
          ],
        },
        {
          className: "contactus-5-googlemaps-wrapper-jDj4Ei",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "bottom", value: "0" },
          ],
        },
      ],
    },
    {
      componentId: "RnOcqE",
      classes: [
        {
          className: "section.contactus-6-component-RnOcqE",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-6-component-RnOcqE", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-col-RnOcqE",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-6-contacttitle-RnOcqE",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        {
          className: "contactus-6-contacttitle-RnOcqE",
          pseudo: "hover",
          rules: [{ property: "color", value: "rgba(108, 117, 125, 1)" }],
        },
        { className: "contactus-6-contacttext-RnOcqE", pseudo: "", rules: [{ property: "margin", value: "0 0 2rem 0" }] },
        { className: "contactus-6-contacttext-RnOcqE", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-subtitle-RnOcqE",
          pseudo: "",
          rules: [
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        {
          className: "contactus-6-subtitle-RnOcqE",
          pseudo: "hover",
          rules: [{ property: "color", value: "rgba(108, 117, 125, 1)" }],
        },
        { className: "contactus-6-subtext-RnOcqE", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        { className: "contactus-6-subtext-RnOcqE", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-buttonwrapper-RnOcqE",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "contactus-6-buttonwrapper-RnOcqE",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-6-button-RnOcqE",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(108, 117, 125, 0)" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(108, 117, 125, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "font-weight", value: "bold" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "contactus-6-button-RnOcqE",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(108, 117, 125, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(108, 117, 125, 1)" },
          ],
        },
        {
          className: "contactus-6-inputwrapper-RnOcqE",
          pseudo: "",
          rules: [{ property: "margin", value: "0 0 1rem 0" }],
        },
        {
          className: "contactus-6-inputfield-RnOcqE",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(108, 117, 125, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-6-inputfield-RnOcqE",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "border-color", value: "rgba(108, 117, 125, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(108, 117, 125, 0.2)" },
          ],
        },
        {
          className: "contactus-6-inputfield-RnOcqE",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "contactus-6-inputfield-RnOcqE",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-6-label-RnOcqE",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        {
          className: "contactus-6-label-RnOcqE",
          pseudo: "hover",
          rules: [],
        },
      ],
    },
    {
      componentId: "OPEtxw",
      classes: [
        {
          className: "section.footer-3-component-OPEtxw",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.footer-3-component-OPEtxw", pseudo: "hover", rules: [] },
        {
          className: "footer-3-component-OPEtxw",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "footer-3-component-OPEtxw", pseudo: "hover", rules: [] },
        {
          className: "footer-3-title-OPEtxw",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 3rem 0" },
            { property: "font-size", value: "48px" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "footer-3-title-OPEtxw", pseudo: "hover", rules: [] },
        {
          className: "footer-3-text1-OPEtxw",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "footer-3-text1-OPEtxw", pseudo: "hover", rules: [] },
        {
          className: "footer-3-text2-OPEtxw",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 3rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "footer-3-text2-OPEtxw", pseudo: "hover", rules: [] },
        {
          className: "footer-3-text3-OPEtxw",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(33, 37, 41, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-3-text3-OPEtxw", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "mP7MlB",
      classes: [
        {
          className: "section.basics-1-component-mP7MlB",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-mP7MlB", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-mP7MlB",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-mP7MlB", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-mP7MlB",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(249, 187, 0, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-mP7MlB", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Montserrat",
    fontHeadersWeight: "300",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(249, 187, 0, 1)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
