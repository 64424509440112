export const card8 = {
  id: "card-8",
  name: "Card 8",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "card-8-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "card-8-row"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "order-first", "card-8-col"],
            styles: [],
            attributes: [
              { property: "data-editable", value: "true" },
              { property: "data-name", value: "card7" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["card-8-img"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/work-1.png" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                  { property: "data-imgresizable", value: "false" },
                ],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-8-col"],
            styles: [],
            attributes: [
              { property: "data-editable", value: "true" },
              { property: "data-name", value: "card7" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h2",
                classes: ["card-8-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "This is a title",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["card-8-desc-text", "w-90", "w-md-90", "w-lg-90"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.card-8-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.card-8-component", pseudo: "hover", rules: [] },
      {
        className: "card-8-row",
        pseudo: "",
        rules: [],
      },
      {
        className: "card-8-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "overflow", value: "hidden" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(214, 214, 214, 1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0rem" },
        ],
      },
      {
        className: "card-8-col",
        pseudo: "hover",
        rules: [{ property: "border-color", value: "rgba(214, 214, 214, 1)" }],
      },
      {
        className: "card-8-img",
        pseudo: "",
        rules: [
          { property: "width", value: "100%" },
          { property: "max-height", value: "350px" },
          { property: "height", value: "auto" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "card-8-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-8-title",
        pseudo: "",
        rules: [
          { property: "font-family", value: '"Montserrat"' },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "margin", value: "0 0.5rem 0.5rem 1.5rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      { className: "card-8-title", pseudo: "hover", rules: [] },
      {
        className: "card-8-desc-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(10, 10, 10, 1)" },
          { property: "margin", value: "1rem 0.5rem 0.5rem 1.5rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
    ],
  },
};
