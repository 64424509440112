import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import setWorkspace from "../utils/setWorkspace";
import setBrandId from "../utils/setBrandId";
import { USER_LOADED, AUTH_ERROR, SET_LANG_CODE } from "./types";
import { API_URL } from "../lib/generalVars";

export const loadUser = () => async (dispatch) => {
  localStorage.token && setAuthToken(localStorage.token);
  localStorage.workspace && setWorkspace(localStorage.workspace);
  try {
    const res = await axios.get(`${API_URL}/auth`);
    // res.data = { user: { ... }, workspaces: [ ... ] }
    const { user, workspaces } = res.data;
    setBrandId(user.brandId);
    const wsAudiences = await axios.get(`${API_URL}/audiences/all`);
    const reactiveEmailCampaigns = await axios.get(`${API_URL}/email/reactiveCampaigns`);
    dispatch({
      type: USER_LOADED,
      payload: {
        user,
        langCode: user.language,
        workspaces,
        audiences: wsAudiences.data,
        reactiveEmailCampaigns: reactiveEmailCampaigns.data,
      },
    });
  } catch (error) {
    console.error("loadUser", error);
    dispatch({ type: AUTH_ERROR });
  }
};

export const authError = () => async (dispatch) => {
  dispatch({ type: AUTH_ERROR });
};

export const setLangCode = (newLangCode) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LANG_CODE,
      payload: newLangCode,
    });
  } catch (error) {
    console.error(error);
  }
};
