import store from "../store";
import { getState } from "./stateFunctions";
import { compileCssVarsObj } from "./css/CssVars";
import { parseComponentHtml, getSectionClassname, parseCss } from "./parse";
import { FixedCss } from "./css/FixedCss";
import { API_URL, EDIT_COMPONENT_ACTIONS_CLASS, EDIT_ELEMENT_ACTIONS_CLASS, POST_CONFIG } from "./generalVars";
import { getGalleryAssetLink } from "./generalFunctions";
import { getRandomString } from "./domFunctions";
import { setGlobalLoading, removeGlobalLoading } from "../actions/globalLoading";
import axios from "axios";
import { translate } from "../translations/translations";
// import { minify as minifyJs } from "terser";
import { minify as minifyCss } from "csso";

// Multer field   Site structure:
// css            /assets/css/css.css                          (Includes relative links to import fontawesome, aos & splide)
// fa             /assets/css/fontawesome/css/all.css
// fa             /assets/css/fontawesome/webfonts/fa-brands-400.ttf
// fa             /assets/css/fontawesome/webfonts/fa-brands-400.woff2
// fa             /assets/css/fontawesome/webfonts/fa-regular-400.ttf
// fa             /assets/css/fontawesome/webfonts/fa-regular-400.woff2
// fa             /assets/css/fontawesome/webfonts/fa-solid-900.ttf
// fa             /assets/css/fontawesome/webfonts/fa-solid-900.woff2
// fa             /assets/css/fontawesome/webfonts/fa-v4compatibility.ttf
// fa             /assets/css/fontawesome/webfonts/fa-v4compatibility.woff2
// aos            /assets/aos/aos.css
// aos            /assets/aos/aos.js                           defer
// splide         /assets/splide/splide.min.css
// splide         /assets/splide/splide.min.js                 defer
// splide         /assets/splide/splide-autoscroll.min.js      defer
// js             /assets/js/bootstrap.bundle.min.js           defer
// js             /assets/js/masonry.js                        defer
// js             /assets/js/animate.js        type="module"   defer
// js             /assets/js/backToTop.js      type="module"   defer
// js             /assets/js/bodyPadding.js    type="module"   defer
// js             /assets/js/carousel.js       type="module"   defer
// js             /assets/js/navbar.js         type="module"   defer
// js             /assets/js/sbForms.js        type="module"   defer
// root           /[xx].html

// TODO: Optional:
// - Remove unused fixedCss portions

// const MIMETYPE_CSS = "text/css";
// const MIMETYPE_JS = "text/javascript";
// const MIMETYPE_HTML = "text/html";
// const MIMETYPE_TTF = "font/ttf";
// const MIMETYPE_WOFF2 = "font/woff2";
// const MIMETYPES = {
//   css: MIMETYPE_CSS,
//   js: MIMETYPE_JS,
//   html: MIMETYPE_HTML,
//   ttf: MIMETYPE_TTF,
//   woff2: MIMETYPE_WOFF2,
// };

// Path to CDN
const CDN_PATH_BASE = "https://cdn.satonda.com/sb/assets/";
const FA_DIR_NAME = "fontawesome_6-1-1";
export const CDN_PATH_FA = `${CDN_PATH_BASE}${FA_DIR_NAME}/css/all.css`;
const SPLIDE_DIR_NAME = "splide_4-1-2";
const AOS_DIR_NAME = "aos_2-3-1";
export const CDN_PATH_SPLIDE_CSS = `${CDN_PATH_BASE}${SPLIDE_DIR_NAME}/splide.min.css`;
export const CDN_PATH_AOS_CSS = `${CDN_PATH_BASE}${AOS_DIR_NAME}/aos.css`;

// What to look for to decide whether the JS file is needed or not
// const JS_ANCHORS = {
//   aos: "data-aos",
//   splide: "carousel-",
//   splideAutoscroll: "carousel-autoscroll",
//   masonry: "data-masonry",
//   animate: "data-aos",
//   backToTop: "backtotop",
//   // bodyPadding: "",
//   carousel: "carousel-",
//   navbar: "navbar",
//   sbForms: "data-sbformbtn",
//   version: "data-version",
// };

const CDN_ASSETS = [
  {
    dir: [FA_DIR_NAME, "css"],
    fileName: "all.css",
    fileType: "css",
    location: "head",
    attributes: {},
  },
  { dir: [AOS_DIR_NAME], fileName: "aos.css", fileType: "css", location: "head", attributes: {} },
  { dir: [SPLIDE_DIR_NAME], fileName: "splide.min.css", fileType: "css", location: "head", attributes: {} },
  {
    dir: ["js"],
    fileName: "bootstrap_5-1-3.bundle.min.js",
    fileType: "js",
    location: "body",
    attributes: { defer: true },
  },
  {
    dir: [AOS_DIR_NAME],
    fileName: "aos.js",
    fileType: "js",
    location: "body",
    attributes: { defer: true },
  },
  {
    dir: [SPLIDE_DIR_NAME],
    fileName: "splide.min.js",
    fileType: "js",
    location: "body",
    attributes: { defer: true },
  },
  {
    dir: [SPLIDE_DIR_NAME],
    fileName: "splide-autoscroll.min.js",
    fileType: "js",
    location: "body",
    attributes: { defer: true },
  },
  {
    dir: ["js"],
    fileName: "masonry_1-0.js",
    fileType: "js",
    location: "body",
    attributes: { defer: true },
  },
  {
    dir: ["js"],
    fileName: "animate_1-0.js",
    fileType: "js",
    location: "body",
    attributes: { defer: true, type: "module" },
  },
  {
    dir: ["js"],
    fileName: "backToTop_1-0.js",
    fileType: "js",
    location: "body",
    attributes: { defer: true, type: "module" },
  },
  // {
  //   dir: ["js"],
  //   fileName: "bodyPadding_1-0.js",
  //   fileType: "js",
  //   location: "body",
  //   attributes: { defer: true, type: "module" },
  // },
  {
    dir: ["js"],
    fileName: "carousel_1-0.js",
    fileType: "js",
    location: "body",
    attributes: { defer: true, type: "module" },
  },
  {
    dir: ["js"],
    fileName: "navbar_1-0.js",
    fileType: "js",
    location: "body",
    attributes: { defer: true, type: "module" },
  },
  {
    dir: ["js"],
    fileName: "sbForms_1-0.js",
    fileType: "js",
    location: "body",
    attributes: { defer: true, type: "module" },
  },
  {
    dir: ["js"],
    fileName: "v_1-0.js",
    fileType: "js",
    location: "body",
    attributes: { defer: true, type: "module" },
  },
];

// Dirs to create on user's server. Include [""] & ["assets", "css"] so that this gets caught in getFilesByDir()
// const DIRS_TO_CREATE = [[""], ["assets", "css"]];

// ========================
// == Exported functions ==
// ========================
// publishSite calls backend with a post { projectId, domainName, filesToUpload }
// filesToUpload = [ { filePath: "/httpdocs/path/to/file.html", fileContent: "..." }, ... ]
export const publishSite = async (projectId, domainName, dirToPublishTo) => {
  // dirToPublishTo = directory to publish site on as provided by the user ("/" or "/path/to/location") No trailing "/". Need to add "/httpdocs"

  const loadingId = store.dispatch(setGlobalLoading(translate("lPublishSite.publishSite", false, null)));
  try {
    // console.log(`publishSite // domainName: ${domainName} // dir: ${dirToPublishTo}`);
    // Get project data
    const project = getState("sb", "project");
    const sbPages = getState("sb", "sbPages");
    const sbCustomCss = getState("sb", "sbCustomCss");
    const sbCssVars = getState("sb", "sbCssVars");

    // Get files for site's css/js assets
    let siteStructure = [getMainCssFile(dirToPublishTo, sbCssVars, sbCustomCss)];

    // Get css/js tags for head and body
    let { headTags, bodyTags } = getCssJsTags(siteStructure[0]);
    const gaSnippet = getGoogleAnalyticsCode(project.siteMeta.gaId);
    headTags += gaSnippet;

    // Create site's html pages
    siteStructure = [
      ...siteStructure,
      ...getSiteHtml(project._id, domainName, dirToPublishTo, project.projectName, project.siteMeta, sbPages, headTags, bodyTags),
    ];

    // Finalize siteStructure to [ { dir: "/httpdocs/path/to", filePath: "/httpdocs/path/to/file.html", fileContent: "..." }, ... ]
    siteStructure = siteStructure.map((file) => ({
      dir: `/httpdocs${file.filePath}`,
      filePath: `/httpdocs${file.filePath}/${file.fileName}`,
      fileContent: file.fileContent,
    }));

    // Upload file contents to backend and set status to published
    await axios.post(`${API_URL}/sitebuilder/manage/publish`, JSON.stringify({ projectId, domainName, filesToUpload: siteStructure }), POST_CONFIG);
    store.dispatch(removeGlobalLoading(loadingId));
    return { success: true, msg: translate("lPublishSite.websiteDeployedTo", false, { domainName }) };
  } catch (error) {
    console.error(error);
    store.dispatch(removeGlobalLoading(loadingId));
    return { success: false, msg: translate("lPublishSite.errorWhileDeploying", false, null) };
  }
};

// =============
// == Helpers ==
// =============
const getMainCssFile = (dirToPublishTo, sbCssVars, sbCustomCss) => {
  // let cssFileName = `css_${getRandomString(6)}.css`;
  return {
    filePath: `${dirToPublishTo}/assets/css`,
    fileName: `css_${getRandomString(6)}.css`,
    fileContent: getMainCssFileContent(sbCssVars, sbCustomCss),
    location: "head",
  };
};

const getMainCssFileContent = (sbCssVars, sbCustomCss) => {
  // Compile css
  let css = compileCssVarsObj(sbCssVars) + FixedCss + parseCss(sbCustomCss);
  // Compile all site html in a single string
  // let htmlContent = "<html><head></head><body>";
  // sbPages.forEach((pageObj) => {
  //   htmlContent += pageObj.components
  //     .map((component) => sanitizeHtml(`<section class="${getSectionClassname(component)}">${parseComponentHtml(component, false)}</section>`))
  //     .join("");
  // });
  // htmlContent += "</body></html>";
  // Remove unused css
  // Minify css
  return minifyCss(css).css;
};

const getCssJsTags = (mainCssFile) => {
  // mainCssFile = { filePath, fileName, fileContent, location } from getMainCssFile
  // CDN assets
  let headTags = CDN_ASSETS.filter((asset) => asset.location === "head")
    .map((asset) => getCssJsTag(asset))
    .join("");
  let bodyTags = CDN_ASSETS.filter((asset) => asset.location === "body")
    .map((asset) => getCssJsTag(asset))
    .join("");
  // Project-specific CSS file
  headTags += `<link href="${mainCssFile.filePath}/${mainCssFile.fileName}" rel="stylesheet" />`;
  return { headTags, bodyTags };
};

const getCssJsTag = (assetObj) => {
  return assetObj.fileType === "css"
    ? `<link href="${CDN_PATH_BASE}${assetObj.dir.join("/")}/${assetObj.fileName}" rel="stylesheet" ${Object.keys(assetObj.attributes)
        .map((key) => `${key}="${assetObj.attributes[key]}"`)
        .join(" ")} />`
    : assetObj.fileType === "js"
    ? `<script src="${CDN_PATH_BASE}${assetObj.dir.join("/")}/${assetObj.fileName}" ${Object.keys(assetObj.attributes)
        .map((key) => `${key}="${assetObj.attributes[key]}"`)
        .join(" ")}></script>`
    : "";
};

const getGoogleAnalyticsCode = (gaId) => {
  if (gaId === "" || gaId === null || typeof gaId === "undefined") {
    return "";
  }
  return `<script async src="https://www.googletagmanager.com/gtag/js?id=${gaId}"></script>
        <script>
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${gaId}');
        </script>`;
};

const getSiteHtml = (projectId, domainName, dirToPublishTo, projectName, siteMeta, sbPages, headTags, bodyTags) => {
  return sbPages
    .filter((page) => page.version === "A")
    .map((page) => ({
      filePath: dirToPublishTo,
      fileName: `${page.pageLink}.html`,
      fileContent: getPageHtml(projectId, domainName, projectName, siteMeta, page, headTags, bodyTags, page.pageLink, sbPages),
    }));
};

const getPageHtml = (projectId, domainName, projectName, siteMeta, pageObj, headTags, bodyTags, pageLink, sbPages) => {
  // getPageHtml is called for each pageLink's version==="A"

  // Old -- single version page's htmlContent
  // let htmlContent = pageObj.components
  //   .map((component) => sanitizeHtml(`<section class="${getSectionClassname(component)}">${parseComponentHtml(component, false)}</section>`))
  //   .join("");

  let htmlContent = sbPages
    .filter((page) => page.pageLink === pageLink)
    .map(
      (page) =>
        `<div data-version="${page.version}" class="${page.version === "A" ? "" : "d-none"}">${page.components
          .map((component) =>
            sanitizeHtml(appendIdsVersion(`<section class="${getSectionClassname(component)}">${parseComponentHtml(component, false)}</section>`))
          )
          .join("")}</div>`
    )
    .join("");
  let metaTags = getPageMetaTags(projectId, domainName, projectName, siteMeta, pageObj);
  // console.log("#### Check whether the below includes any data- attributes that should be deleted");
  // console.log([...new Set([...htmlContent.matchAll(/data-(.+?)=/g)].map((match) => match[1]))]);
  return `<!DOCTYPE html><html lang="${siteMeta.langCode}"><head>${metaTags}${headTags}</head><body>${htmlContent}${bodyTags}</body></html>`;
};

const getPageMetaTags = (projectId, domainName, projectName, siteMeta, pageObj) => {
  // siteMeta = { langCode, favicon, gaId }
  // TODO: make favicon icon as a different image file (.ico, right width/height)? Also add other icons (https://developer.mozilla.org/en-US/docs/Learn/HTML/Introduction_to_HTML/The_head_metadata_in_HTML#adding_custom_icons_to_your_site)?
  return (
    `<meta charset="utf-8" />` +
    `<link rel="icon" href="${getGalleryAssetLink(projectId, siteMeta.favicon)}" />` +
    `<meta name="viewport" content="width=device-width, initial-scale=1.0" />` +
    `<meta name="description" content="${pageObj.pageDesc}" />` +
    `<title>${pageObj.pageTitle}</title>` +
    `<meta property="og:title" content="${pageObj.pageTitle}" />` +
    `<meta property="og:site_name" content="${projectName}" />` +
    `<meta property="og:description" content="${pageObj.pageDesc}" />` +
    `<meta property="og:type" content="${pageObj.pageType}" />` +
    `<meta property="og:url" content="https://${domainName}/${pageObj.pageLink}.html" />` +
    `<meta property="og:image" content="${getGalleryAssetLink(projectId, pageObj.pagePreview)}" />`
  );
  // return `<meta charset="utf-8" /><link rel="icon" href="/favicon.ico" /><meta name="viewport" content="width=device-width, initial-scale=1.0" /><meta name="description" content="${pageObj.pageDesc}" /><title>${pageObj.pageTitle}</title>`;
};

const appendIdsVersion = (html, version) => {
  if (version === "A") {
    return html;
  }
  let ids = [...html.matchAll(/<a .+?>/g)].map((match) => match[1]);
  ids.forEach((id) => {
    let re = new RegExp(` id="${id}"`, "g");
    html = html.replace(re, ` id="${id}_v${version}"`);
    re = new RegExp(`#${id}`, "g");
    html = html.replace(re, `#${id}_v${version}`);
  });
  return html;
};

const sanitizeHtml = (html) => {
  html = removeSbDataAttrs(html);
  html = removeEditButtons(html);
  html = enableLinks(html);
  // Remove empty closing tags (weird leftover from parse function..)
  html = html.replaceAll(/<\/>/g, "");
  // Remove unnecesary spaces
  html = html.replaceAll(/"\s+>/g, `">`);
  html = html.replaceAll(/="\s+/g, `="`);
  html = html.replaceAll(/\s+"/g, `"`);
  html = html.replaceAll(/\s+>/g, `>`);
  html = html.replaceAll(/\s{2,}/g, ` `);
  return html;
};

const removeSbDataAttrs = (html) => {
  const SB_DATA_ATTRS = [
    // Core attributes
    "data-sbtype",
    "data-componentid",
    "data-id",
    "data-name",
    "data-editable",
    "data-texteditable",
    "data-imgresizable",
    "data-checkparent",
    // Getters/wrappers
    "data-elementgetter1",
    "data-elementgetter2",
    "data-elementgetter3",
    "data-elementgetter4",
    "data-elementgetter5",
    "data-herowrapper",
    "data-elementalignmentwrapper",
    "data-iconwrapper",
    "data-parallaxcontent",
    // Other
  ];
  SB_DATA_ATTRS.forEach((attr) => {
    let re = new RegExp(`${attr}=".*?"`, "g");
    html = html.replace(re, "");
  });
  return html;
};

const removeEditButtons = (html) => {
  // Ensure to 1) account for strange spaces after having deleted data- attributes before and 2) end with 2 closing div tags to capture the full block
  let re = new RegExp(`<div\\s+class="${EDIT_COMPONENT_ACTIONS_CLASS}"\\s+>.+?<\\/div><\\/div>`, "g");
  html = html.replace(re, "");
  re = new RegExp(`<div\\s+class="${EDIT_ELEMENT_ACTIONS_CLASS}"\\s+>.+?<\\/div><\\/div>`, "g");
  html = html.replace(re, "");
  return html;
};

const enableLinks = (html) => {
  let linkTags = [...html.matchAll(/<a .+?>/g)].map((match) => match[0]);
  linkTags.forEach((link) => {
    // Find destination
    let dest = (link.match(/data-href="(.+?)"/) || ["#!", "#!"])[1];
    // Update link
    let replace = link
      .replace(/data-href=".+?"/, "")
      .replace(/href=".+?"/, `href="${dest}"`)
      .replace(/data-target="/, `target="`);
    html = html.replace(link, replace);
  });
  return html;
};
