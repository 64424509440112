export const mModalPageOptions = {
  // SiteLangCode
  langCodeUpdated: {
    en: "Language code successfully updated!",
    nl: "Taal bijgewerkt!",
  },
  setSiteLangCode: {
    en: "Set site languade code",
    nl: "Site taal instellen",
  },
  searchLanguage: {
    en: "Search language by name (in English)",
    nl: "Zoek taal bij naam (in het Engels)",
  },
  selectYourLang: {
    en: "Select your language",
    nl: "Selecteer uw taal",
  },
  noSpecificLang: {
    en: "No specific language",
    nl: "Geen specifieke taal",
  },
  updateLangCode: {
    en: "Update language code",
    nl: "Taal bijwerken",
  },
  // SiteFavicon
  faviconUpdated: {
    en: "Favicon successfully updated!",
    nl: "Favicon bijgewerkt!",
  },
  setFavicon: {
    en: "Set your site's favicon",
    nl: "Stel uw site's favicon in",
  },
  currentFavicon: {
    en: "Current favicon",
    nl: "Huidige favicon",
  },
  noFaviconSelected: {
    en: "No favicon selected",
    nl: "Geen favicon geselecteerd",
  },
  selectNewFaviconFromGallery: {
    en: "Select new favicon from your gallery",
    nl: "Selecteer nieuwe favicon uit uw gallerij",
  },
  confirmSelectedImage: {
    en: "Confirm selected image",
    nl: "Bevestig geselecteerde afbeelding",
  },
  // Google Analytics
  gaIdUpdated: {
    en: "Google Analytics ID successfully updated!",
    nl: "Google Analytics ID bijgewerkt!",
  },
  setSiteGaId: {
    en: "Set site Google Analytics ID",
    nl: "Site Google Analytics ID instellen",
  },
  gaId: {
    en: "Google Analytics ID",
    nl: "Google Analytics ID",
  },
  updateGaId: {
    en: "Update Google Analytics ID",
    nl: "Google Analytics ID bijwerken",
  },
  // SelectPage
  selectPageToEditInEditor: {
    en: "Select page to edit in the editor",
    nl: "Selecteer pagina om aan te werken",
  },
  page: {
    en: "Page",
    nl: "Pagina",
  },
  version: {
    en: "Version",
    nl: "Versie",
  },
  pleaseEnterPageName: {
    en: "Please enter a page name",
    nl: "Specificeer aub een pagina naam",
  },
  pageNameAlreadyExists: {
    en: "A page with this name already exists",
    nl: "Een pagina met deze naam bestaat al",
  },
  pleaseEnterPageTitle: {
    en: "Please enter a page title",
    nl: "Specificeer aub een pagina titel",
  },
  pageInfoUpdated: {
    en: "Page information has been updated!",
    nl: "Paginainformatie bijgewerkt!",
  },
  editPageInfo: {
    en: "Edit page information",
    nl: "Bijwerken paginainformatie",
  },
  selectPageToEdit: {
    en: "Select page to edit",
    nl: "Selecteer pagina om bij te werken",
  },
  pageName: {
    en: "Page name",
    nl: "Pagina naam",
  },
  newPageName: {
    en: "New page name",
    nl: "Nieuwe pagina naam",
  },
  pageTitle: {
    en: "Page title",
    nl: "Pagina titel",
  },
  ttPageTitle: {
    en: "A short title describing the page. Aim for 40-60 characters so it remains visible even on mobile screens.",
    nl: "Een korte beschrijving van de pagina. Richt op 40-60 karakters zodat het ook op mobiele telefoonschermen zichtbaar blijft.",
  },
  chars: {
    en: "characters",
    nl: "karakters",
  },
  newPageTitle: {
    en: "New page title",
    nl: "Nieuwe pagina titel",
  },
  pageDesc: {
    en: "Page description",
    nl: "Pagina omschrijving",
  },
  ttPageDesc: {
    en: "A longer description of the page. Aim for 2-4 sentences.",
    nl: "Een langere omschrijving van de pagina. Richt op 2-4 zinnen.",
  },
  newPageDesc: {
    en: "New page desc",
    nl: "Nieuwe omschrijving",
  },
  pageType: {
    en: "Page type",
    nl: "Pagina type",
  },
  ttPageType: {
    en: "Most pages will be either a website or an article",
    nl: "Meeste paginas zullen een website of een artikel zijn",
  },
  pageType_website: {
    en: "Website",
    nl: "Website",
  },
  pageType_article: {
    en: "Article",
    nl: "Artikel",
  },
  pageType_book: {
    en: "Book",
    nl: "Boek",
  },
  pageType_profile: {
    en: "Profile",
    nl: "Profiel",
  },
  socialPagePreview: {
    en: "Social page preview",
    nl: "Social preview",
  },
  ttSocialPagePreview: {
    en: "The page's preview image that is shown for example when the page is shared on Facebook or Twitter. For optimal display on all devices, use an image dimensions of 1200x630 and a 1.90:1 aspect ratio.",
    nl: "De preview van de pagina zoals gebruikt op bijv. Facebook of Twitter. Voor een optimale weergave op alle soorten toestellen gebruikt u een afbeelding met afmetingen van 1200x630 en een 1.90:1 verhouding.",
  },
  noPreviewSet: {
    en: "No preview set",
    nl: "Geen preview ingesteld",
  },
  browseAvailableImages: {
    en: "Browse available images",
    nl: "Bekijk beschikbare afbeeldingen",
  },
  cancel: {
    en: "Cancel",
    nl: "Annuleren",
  },
  // DeletePage
  confirmDeletePageVersion: {
    en: `Are you sure you want to delete page "%pageLink%" (version %version%)?`,
    nl: `Weet u zeker dat u de pagina "%pageLink%" (versie %version%) wilt verwijderen?`,
  },
  pageDeleted: {
    en: "Page has been deleted!",
    nl: "Pagina verwijderd!",
  },
  deletePage: {
    en: "Delete page",
    nl: "Verwijder pagina",
  },
  selectPageToDelete: {
    en: "Select page to delete",
    nl: "Selecteer pagina om te verwijderen",
  },
  // AddNewPage
  newPageAdded: {
    en: "New page has been added!",
    nl: "Nieuwe pagina gecreerd!",
  },
  pleaseUpgradeSub: {
    en: "You've reached the maximum number of pages per project in your subscription package. Please upgrade to be able to have more pages.",
    nl: "U heeft het maximale aantal paginas per project in uw pakket bereikt. Upgrade aub uw pakket om meer paginas te kunnen maken.",
  },
  addANewPage: {
    en: "Add a new page",
    nl: "Creer nieuwe pagina",
  },
  addNewPage: {
    en: "Add new page",
    nl: "Creer nieuwe pagina",
  },
  // BrowsePageSocialPreview
  pageSocialPreviewUpdated: {
    en: "Page social preview has been updated!",
    nl: "Pagina's social preview is bijgewerkt!",
  },
  selectSocialPreviewFor: {
    en: "Select social preview for",
    nl: "Selecteer preview voor",
  },
  goBack: {
    en: "Go back",
    nl: "Ga terug",
  },
  selectPreviewImage: {
    en: "Select preview image",
    nl: "Selecteer preview afbeelding",
  },
  // PageVersions
  newVersionAdded: {
    en: "New version has been added!",
    nl: "Nieuwe versie gecreerd!",
  },
  addNewPageVersion: {
    en: "Add new page version",
    nl: "Creer nieuwe pagina versie",
  },
  selectVersionToDuplicate: {
    en: "Select version to use as a base for new version",
    nl: "Selecteer een versie om te gebruiken als basis voor de nieuwe versie",
  },
  addAdditionalVersion: {
    en: "Add additional version",
    nl: "Maak extra versie",
  },
  // Modal
  sitePageOptions: {
    en: "Site and page options",
    nl: "Site en pagina opties",
  },
  setSiteLang: {
    en: "Set site language meta data",
    nl: "Stel site taal in",
  },
  setSiteFavicon: {
    en: "Set site favicon",
    nl: "Stel site favicon in",
  },
  editPageNameMetaData: {
    en: "Edit page name and meta data",
    nl: "Verander pagina naam en meta data",
  },
  managePageVersions: {
    en: "Manage page versions",
    nl: "Beheer pagina versies",
  },
  deleteAPage: {
    en: "Delete a page",
    nl: "Verwijder een pagina",
  },
  close: {
    en: "Close",
    nl: "Sluiten",
  },
};
