import store from "../store";
import {
  EDIT_COMPONENT_ACTION_ICON_TYPE,
  COMPONENT_MOVEUP_TYPE,
  COMPONENT_MOVEDOWN_TYPE,
  COMPONENT_EDIT_TYPE,
  COMPONENT_DELETE_TYPE,
  ELEMENT_DUPLICATE_TYPE,
  ELEMENT_EDIT_TYPE,
  ELEMENT_DELETE_TYPE,
  COL_REORDER_TYPE,
  INPUTFIELD_MOVEUP_TYPE,
  INPUTFIELD_MOVEDOWN_TYPE,
  INPUTFIELD_DUPLICATE_TYPE,
  INPUTFIELD_DELETE_TYPE,
} from "./generalVars";
import { determineEditFormsToShow } from "./editFunctions";
import {
  reorderComponent,
  deleteComponent,
  setSelectedElement,
  duplicateElement,
  deleteElement,
  reorderColumn,
  reorderInputfield,
  toggleCheckbox,
} from "../actions/sb";

export const getIframeWindow = () => {
  try {
    return document.getElementById("iframe").contentWindow;
  } catch (error) {
    return null;
  }
};

export const getTargetElement = (elementId) => {
  try {
    // return getIframeWindow().document.getElementById(elementId);
    return getIframeWindow().document.querySelector(`[data-id="${elementId}"]`);
  } catch (error) {
    return null;
  }
};

export const elementIsEditableTextElement = (element) => {
  try {
    return element.dataset.texteditable === "true";
  } catch (error) {
    return false;
  }
};

export const getRandomId = () => {
  const idLength = 6;
  let generatedId = [];
  const allowedChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const allowedCharsLength = allowedChars.length;
  let uniqueId = false;
  while (!uniqueId) {
    for (let i = 0; i < idLength; i++) {
      if (i === 0) {
        // First character of the id needs to be a letter, else CSS id selector doesn't work
        generatedId.push(allowedChars.charAt(Math.floor(Math.random() * (allowedCharsLength - 10))));
      } else {
        generatedId.push(allowedChars.charAt(Math.floor(Math.random() * allowedCharsLength)));
      }
    }
    uniqueId = randomIdIsUnique(generatedId.join(""));
  }
  return generatedId.join("");
};

const randomIdIsUnique = (randomId) => {
  return getTargetElement(randomId) === null;
};

export const getRandomString = (strLength) => {
  let generatedStr = [];
  const allowedChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const allowedCharsLength = allowedChars.length;
  for (let i = 0; i < strLength; i++) {
    if (i === 0) {
      // Ensure first char is a letter
      generatedStr.push(allowedChars.charAt(Math.floor(Math.random() * (allowedCharsLength - 10))));
    } else {
      generatedStr.push(allowedChars.charAt(Math.floor(Math.random() * allowedCharsLength)));
    }
  }
  return generatedStr.join("");
};

export const clickIframeElement = (target) => {
  try {
    // console.log("clickIframeElement");
    // console.log(target);
    // Update UI as expected for user, to prevent confusion of something not working correctly..
    if (target.className.includes("form-check-input")) {
      store.dispatch(toggleCheckbox(target.dataset.id.split("-"), target.checked));
    }
    // Decide what to do when a user clicks somewhere in the iframe
    // If it's an edit-action, perform that action
    if (target.dataset.sbtype === EDIT_COMPONENT_ACTION_ICON_TYPE) {
      // Get the selected action from the target's parent and perform action
      target = target.parentElement;
      // console.log(target);
      // let componentId = target.parentElement.parentElement.dataset.id;
      let componentId = target.dataset.id.split("-")[0];
      // Component actions
      if (target.dataset.sbtype === COMPONENT_MOVEUP_TYPE) {
        store.dispatch(reorderComponent(componentId, -1));
        store.dispatch(setSelectedElement(""));
      }
      if (target.dataset.sbtype === COMPONENT_MOVEDOWN_TYPE) {
        store.dispatch(reorderComponent(componentId, 1));
        store.dispatch(setSelectedElement(""));
      }
      if (target.dataset.sbtype === COMPONENT_EDIT_TYPE) {
        store.dispatch(setSelectedElement(componentId));
        determineEditFormsToShow(getParentBySbType(target, "component"));
      }
      if (target.dataset.sbtype === COMPONENT_DELETE_TYPE) {
        store.dispatch(deleteComponent(componentId));
        store.dispatch(setSelectedElement(""));
      }
      // Element actions
      if (target.dataset.sbtype === ELEMENT_EDIT_TYPE) {
        let element = getParentBySbType(target, "element");
        store.dispatch(setSelectedElement(element.dataset.id));
        determineEditFormsToShow(element);
      }
      if (target.dataset.sbtype === ELEMENT_DUPLICATE_TYPE) {
        let element = getParentBySbType(target, "element");
        store.dispatch(duplicateElement(element.dataset.id));
        if (element.className.includes("splide__slide") && element.className.includes("-main-")) {
          // If the element being duplicated is a carousel slide part of a thumbnail carousel, then duplicate also its thumbnail
          store.dispatch(duplicateElement(carousel_getThumbnailSlideId(element)));
        }
        store.dispatch(setSelectedElement(""));
      }
      if (target.dataset.sbtype === ELEMENT_DELETE_TYPE) {
        let element = getParentBySbType(target, "element");
        store.dispatch(deleteElement(element.dataset.id));
        if (element.className.includes("splide__slide") && element.className.includes("-main-")) {
          // If the element being duplicated is a carousel slide part of a thumbnail carousel, then duplicate also its thumbnail
          store.dispatch(deleteElement(carousel_getThumbnailSlideId(element)));
        }
        store.dispatch(setSelectedElement(""));
      }
      // Reorder column
      if (target.dataset.sbtype === COL_REORDER_TYPE) {
        let element = getParentByClassnameRegex(target, /order-(first|last)/gi);
        store.dispatch(reorderColumn(element.dataset.id));
        store.dispatch(setSelectedElement(""));
      }
      // Form/input field actions
      if (target.dataset.sbtype === INPUTFIELD_MOVEUP_TYPE) {
        let element = getParentByAttribute(target, "inputfieldwrapper", "true");
        store.dispatch(reorderInputfield(element.dataset.id.split("-"), -1));
        store.dispatch(setSelectedElement(""));
      }
      if (target.dataset.sbtype === INPUTFIELD_MOVEDOWN_TYPE) {
        let element = getParentByAttribute(target, "inputfieldwrapper", "true");
        store.dispatch(reorderInputfield(element.dataset.id.split("-"), 1));
        store.dispatch(setSelectedElement(""));
      }
      if (target.dataset.sbtype === INPUTFIELD_DUPLICATE_TYPE) {
        let element = getParentByAttribute(target, "inputfieldwrapper", "true");
        store.dispatch(duplicateElement(element.dataset.id));
        store.dispatch(setSelectedElement(""));
      }
      if (target.dataset.sbtype === INPUTFIELD_DELETE_TYPE) {
        let element = getParentByAttribute(target, "inputfieldwrapper", "true");
        store.dispatch(deleteElement(element.dataset.id));
        store.dispatch(setSelectedElement(""));
      }
      // Reload iframe js
      injectIframeJS();
      return;
    }
    // If the element that is clicked is a text styling element, go to its parent
    const TEXT_STYLING_ELEMENTS = ["strong", "em", "ins", "del"];
    if (TEXT_STYLING_ELEMENTS.includes(target.localName)) {
      target = target.parentElement;
    }
    // Get parentElement if specified
    if (target.dataset.checkparent === "true" || typeof target.dataset.name === "undefined") {
      target = getParentForCheckParentElements(target);
    }
    // If target is a googlemaps_wrapper, get its next sibling
    if (target.dataset.name === "googlemaps_wrapper") {
      target = target.nextElementSibling;
    }
    // If target is a footer4, get its child element
    if (target.dataset.name === "footer4_wrapper") {
      target = target.firstElementChild;
    }
    // Check whether clicked element is editable. If so, set selectedElement to clicked element's ID. If not, don't do anything
    if (target.dataset.editable === "true") {
      store.dispatch(setSelectedElement(target.dataset.id));
      determineEditFormsToShow(target);
      return;
    }
    // If not editable, reset selectedElement
    store.dispatch(setSelectedElement(""));
    return;
  } catch (error) {
    console.error(error);
    return;
  }
};

const getParentForCheckParentElements = (element) => {
  if (
    element.parentElement !== null &&
    (element.parentElement.dataset.checkparent === "true" || typeof element.parentElement.dataset.name === "undefined")
  ) {
    return getParentForCheckParentElements(element.parentElement);
  }
  return element.parentElement;
};

const getParentBySbType = (target, sbType) => {
  try {
    if (target.dataset.sbtype === sbType) {
      return target;
    }
    return getParentBySbType(target.parentElement, sbType);
  } catch (error) {
    return null;
  }
};

const getParentByAttribute = (target, attrProp, attrVal) => {
  try {
    if (target.dataset[attrProp] === attrVal) {
      return target;
    }
    return getParentByAttribute(target.parentElement, attrProp, attrVal);
  } catch (error) {
    return null;
  }
};

const getParentByClassnameRegex = (target, regex) => {
  try {
    if (target.className.match(regex) !== null) {
      return target;
    }
    return getParentByClassnameRegex(target.parentElement, regex);
  } catch (error) {
    return null;
  }
};

export const injectIframeJS = (iframeBody = null) => {
  try {
    iframeBody === null && (iframeBody = getIframeWindow().document.body);
    [...iframeBody.childNodes].forEach((childNode) => childNode.localName === "script" && childNode.remove());
    const SCRIPTS_TO_ADD = [
      { src: "/assets/js/bootstrap.bundle.min.js", type: "" },
      { src: "/assets/js/masonry.js", type: "" },
      { src: "/assets/splide/splide.min.js", type: "" },
      { src: "/assets/splide/splide-autoscroll.min.js", type: "" },
      { src: "/assets/aos/aos.js", type: "" },
      { src: "/assets/js/bodyPadding.js", type: "module" },
      { src: "/assets/js/navbar.js", type: "module" },
      { src: "/assets/js/carousel.js", type: "module" },
      // { src: "/assets/js/backToTop.js", type: "module"},
      { src: "/assets/js/animate.js", type: "module" },
      // { src: "/assets/js/sbForms.js", type: "module" },
    ];
    SCRIPTS_TO_ADD.forEach((script) => {
      let scriptElement = document.createElement("script");
      script.type !== "" && (scriptElement.type = script.type);
      script.defer && (scriptElement.defer = true);
      scriptElement.src = `${script.src}?id=${getRandomString(6)}`;
      iframeBody.append(scriptElement);
    });
  } catch (error) {
    console.error(error);
  }
};

const carousel_getThumbnailSlideId = (elMainSlide) => {
  try {
    // elMainSlide has format like "splide01-slide01"
    // Corresponding thumbnail slide id will be "splide02-slide01"
    let carouselNum = parseInt(elMainSlide.id.split("-")[0].replace("splide", "")) + 1;
    carouselNum = carouselNum < 10 ? `0${carouselNum}` : carouselNum;
    let thumbnailSlideId = `splide${carouselNum}-${elMainSlide.id.split("-")[1]}`;
    let thumbnailSlide = getIframeWindow().document.getElementById(thumbnailSlideId);
    return thumbnailSlide.dataset.id;
  } catch (error) {
    console.error(error);
    return "";
  }
};
