export const latestnews1 = {
  id: "latestnews-1",
  name: "Latest news 1",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "latestnews-1-component"],
    styles: [],
    attributes: [
      {
        property: "data-name",
        value: "component",
      },
    ],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-center", "latestnews-1-row"],
        styles: [],
        attributes: [
          {
            property: "data-name",
            value: "block",
          },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-12", "latestnews-1-col"],
            styles: [],
            attributes: [
              {
                property: "data-name",
                value: "element_col",
              },
              {
                property: "data-checkalignmentwrapper",
                value: "true",
              },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["row", "justify-content-center", "align-items-stretch"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-8", "col-lg-6", "d-flex", "latestnews-1-wrapper"],
                    styles: [],
                    attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["me-3"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["latestnews-1-date1"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "SEP",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["latestnews-1-date2"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "6",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "a",
                            classes: ["latestnews-1-title-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_self",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "true",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum, animi.",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["mt-2"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["latestnews-1-desc-text"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-editable",
                                    value: "true",
                                  },
                                  {
                                    property: "data-texteditable",
                                    value: "true",
                                  },
                                  {
                                    property: "data-name",
                                    value: "p",
                                  },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content:
                                      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita a similique dignissimos molestias debitis iste id architecto eveniet vel accusamus.",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "a",
                                classes: ["latestnews-1-link-readmore"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "href",
                                    value: "#!",
                                  },
                                  {
                                    property: "data-href",
                                    value: "#!",
                                  },
                                  {
                                    property: "data-target",
                                    value: "_self",
                                  },
                                  {
                                    property: "data-texteditable",
                                    value: "true",
                                  },
                                  {
                                    property: "data-editable",
                                    value: "true",
                                  },
                                  {
                                    property: "data-name",
                                    value: "a",
                                  },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Read more",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-12", "latestnews-1-col"],
            styles: [],
            attributes: [
              {
                property: "data-name",
                value: "element_col",
              },
              {
                property: "data-checkalignmentwrapper",
                value: "true",
              },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["row", "justify-content-center", "align-items-stretch"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-8", "col-lg-6", "d-flex", "latestnews-1-wrapper"],
                    styles: [],
                    attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["me-3"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["latestnews-1-date1"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "SEP",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["latestnews-1-date2"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "6",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "a",
                            classes: ["latestnews-1-title-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_self",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "true",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum, animi.",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["mt-2"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["latestnews-1-desc-text"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-editable",
                                    value: "true",
                                  },
                                  {
                                    property: "data-texteditable",
                                    value: "true",
                                  },
                                  {
                                    property: "data-name",
                                    value: "p",
                                  },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content:
                                      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita a similique dignissimos molestias debitis iste id architecto eveniet vel accusamus.",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "a",
                                classes: ["latestnews-1-link-readmore"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "href",
                                    value: "#!",
                                  },
                                  {
                                    property: "data-href",
                                    value: "#!",
                                  },
                                  {
                                    property: "data-target",
                                    value: "_self",
                                  },
                                  {
                                    property: "data-texteditable",
                                    value: "true",
                                  },
                                  {
                                    property: "data-editable",
                                    value: "true",
                                  },
                                  {
                                    property: "data-name",
                                    value: "a",
                                  },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Read more",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-12", "col-lg-12", "latestnews-1-col"],
            styles: [],
            attributes: [
              {
                property: "data-name",
                value: "element_col",
              },
              {
                property: "data-checkalignmentwrapper",
                value: "true",
              },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["row", "justify-content-center", "align-items-stretch"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["col-12", "col-md-8", "col-lg-6", "d-flex", "latestnews-1-wrapper"],
                    styles: [],
                    attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["me-3"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["latestnews-1-date1"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "SEP",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["latestnews-1-date2"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "6",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "a",
                            classes: ["latestnews-1-title-link"],
                            styles: [],
                            attributes: [
                              {
                                property: "href",
                                value: "#!",
                              },
                              {
                                property: "data-href",
                                value: "#!",
                              },
                              {
                                property: "data-target",
                                value: "_self",
                              },
                              {
                                property: "data-editable",
                                value: "true",
                              },
                              {
                                property: "data-texteditable",
                                value: "true",
                              },
                              {
                                property: "data-name",
                                value: "a",
                              },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum, animi.",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["mt-2"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["latestnews-1-desc-text"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "data-editable",
                                    value: "true",
                                  },
                                  {
                                    property: "data-texteditable",
                                    value: "true",
                                  },
                                  {
                                    property: "data-name",
                                    value: "p",
                                  },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content:
                                      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita a similique dignissimos molestias debitis iste id architecto eveniet vel accusamus.",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "a",
                                classes: ["latestnews-1-link-readmore"],
                                styles: [],
                                attributes: [
                                  {
                                    property: "href",
                                    value: "#!",
                                  },
                                  {
                                    property: "data-href",
                                    value: "#!",
                                  },
                                  {
                                    property: "data-target",
                                    value: "_self",
                                  },
                                  {
                                    property: "data-texteditable",
                                    value: "true",
                                  },
                                  {
                                    property: "data-editable",
                                    value: "true",
                                  },
                                  {
                                    property: "data-name",
                                    value: "a",
                                  },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Read more",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.latestnews-1-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.latestnews-1-component", pseudo: "hover", rules: [] },
      {
        className: "latestnews-1-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "justify-content", value: "center" },
        ],
      },
      {
        className: "latestnews-1-wrapper",
        pseudo: "",
        rules: [{ property: "padding", value: "0.25rem 0.25rem 0.25rem 0.25rem" }],
      },
      {
        className: "latestnews-1-wrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "latestnews-1-date1",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0 0" },
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "width", value: "100px" },
          { property: "padding", value: "0.25rem 0 0.25rem 0" },
          { property: "text-align", value: "center" },
          { property: "font-size", value: "13px" },
        ],
      },
      {
        className: "latestnews-1-date1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "latestnews-1-date2",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0 0" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "currentcolor" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "width", value: "100px" },
          { property: "padding", value: "0.25rem 0 0.25rem 0" },
          { property: "text-align", value: "center" },
          { property: "font-size", value: "13px" },
        ],
      },
      {
        className: "latestnews-1-date2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "latestnews-1-title-link",
        pseudo: "",
        rules: [
          { property: "font-family", value: '"Montserrat"' },
          { property: "font-size", value: "20px" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "text-decoration", value: "none" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0.5rem 0" },
        ],
      },
      { className: "latestnews-1-title-link", pseudo: "hover", rules: [{ property: "color", value: "rgba(246, 129, 0, 1)" }] },
      {
        className: "latestnews-1-desc-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(10, 10, 10, 1)" },
          { property: "margin", value: "0.5rem 0 0 0" },
          { property: "display", value: "inline" },
        ],
      },
      {
        className: "latestnews-1-desc-text",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "latestnews-1-link-readmore",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "display", value: "inline" },
        ],
      },
      { className: "latestnews-1-link-readmore", pseudo: "hover", rules: [{ property: "color", value: "rgba(246, 129, 0, 1)" }] },
    ],
  },
};
