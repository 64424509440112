export const TEMPLATE_CATEGORIES = [
  {
    val: "services",
    label: {
      en: "Services",
      nl: "Services",
    },
  },
  {
    val: "tech",
    label: {
      en: "Technology",
      nl: "Technologie",
    },
  },
  {
    val: "physicalProducts",
    label: {
      en: "Physical products",
      nl: "Fysieke producten",
    },
  },
  {
    val: "hospitality",
    label: {
      en: "Hospitality",
      nl: "Horeca",
    },
  },
  {
    val: "beauty",
    label: {
      en: "Beauty & wellness",
      nl: "Schoonheid & welzijn",
    },
  },
  {
    val: "sports",
    label: {
      en: "Sport & health",
      nl: "Sport & gezondheid",
    },
  },
  {
    val: "personal",
    label: {
      en: "Personal / resume sites",
      nl: "Persoonlijke / CV sites",
    },
  },
  {
    val: "coaching",
    label: {
      en: "Coaching & personal advice",
      nl: "Coaching & persoonlijk advies",
    },
  },
  {
    val: "event",
    label: {
      en: "Event",
      nl: "Evenement",
    },
  },
  {
    val: "pets",
    label: {
      en: "Pets",
      nl: "Huisdieren",
    },
  },
  {
    val: "hobby",
    label: {
      en: "Hobbies",
      nl: "Hobbies",
    },
  },
  {
    val: "underConstruction",
    label: {
      en: "Site under construction",
      nl: "Website in de maak",
    },
  },
  {
    val: "downloadResource",
    label: {
      en: "Download a resource",
      nl: "Download een bestand",
    },
  },
];
