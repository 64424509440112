import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import BuilderAreaTop from "./BuilderAreaTop";
import BuilderAreaCanvas from "./BuilderAreaCanvas";

const BuilderArea = ({ sb: { screen } }) => {
  return (
    <>
      <BuilderAreaTop />
      <div className={`editResult trans-3 ${screen} toGrow rounded-4 overflow-hidden`} data-tour="editor-canvas">
        <BuilderAreaCanvas />
      </div>
    </>
  );
};

BuilderArea.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(BuilderArea);
