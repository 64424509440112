export const basics3 = {
  id: "basics-3",
  name: "Basic building blocks - Image",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "basics-3-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "img",
        classes: ["basics-3-img"],
        styles: [],
        attributes: [
          { property: "src", value: "https://cdn.satonda.com/eb/assets/work-3.png" },
          { property: "data-editable", value: "true" },
          { property: "data-name", value: "img" },
        ],
        content: "",
        children: [],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.basics-3-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.basics-3-component", pseudo: "hover", rules: [] },
      {
        className: "basics-3-component",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "basics-3-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "basics-3-img",
        pseudo: "",
        rules: [
          { property: "width", value: "400px" },
          { property: "height", value: "auto" },
          { property: "object-fit", value: "cover" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "basics-3-img",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
