export const contactus7 = {
  id: "contactus-7",
  name: "Contact us 7",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "contactus-7-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "contactus-7-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-4", "col-lg-4", "contactus-7-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "button",
                classes: ["contactus-7-iconwrapper"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-iconwrapper", value: "true" },
                  { property: "data-texteditable", value: "false" },
                  { property: "data-name", value: "button" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "span",
                    classes: ["icon", "fa-solid", "fa-location-dot"],
                    styles: [],
                    attributes: [{ property: "data-checkparent", value: "true" }],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: [],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h4",
                    classes: ["contactus-7-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Visit us",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["contactus-7-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Street 1337",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["contactus-7-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Postal code, City",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-4", "col-lg-4", "contactus-7-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "button",
                classes: ["contactus-7-iconwrapper"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-iconwrapper", value: "true" },
                  { property: "data-texteditable", value: "false" },
                  { property: "data-name", value: "button" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "span",
                    classes: ["icon", "fa-solid", "fa-phone"],
                    styles: [],
                    attributes: [{ property: "data-checkparent", value: "true" }],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: [],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h4",
                    classes: ["contactus-7-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Call us",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["contactus-7-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "020 13 37 000",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-4", "col-lg-4", "contactus-7-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "button",
                classes: ["contactus-7-iconwrapper"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-iconwrapper", value: "true" },
                  { property: "data-texteditable", value: "false" },
                  { property: "data-name", value: "button" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "span",
                    classes: ["icon", "fa-solid", "fa-envelope"],
                    styles: [],
                    attributes: [{ property: "data-checkparent", value: "true" }],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: [],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h4",
                    classes: ["contactus-7-title"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Email us",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["contactus-7-text"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "email@domain.com",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.contactus-7-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.contactus-7-component", pseudo: "hover", rules: [] },
      {
        className: "contactus-7-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "align-items", value: "center" },
          { property: "text-align", value: "center" },
          { property: "margin", value: "0 0 2rem 0" },
        ],
      },
      {
        className: "contactus-7-iconwrapper",
        pseudo: "",
        rules: [
          { property: "height", value: "65px" },
          { property: "width", value: "65px" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "font-size", value: "1.5rem" },
          { property: "border-radius", value: "50%" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
          { property: "margin", value: "0 0 0 0" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "cursor", value: "default !important" },
        ],
      },
      {
        className: "contactus-7-iconwrapper",
        pseudo: "hover",
        rules: [
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "contactus-7-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.5rem 0" },
          { property: "text-align", value: "center" },
          { property: "font-weight", value: "bold" },
        ],
      },
      { className: "contactus-7-title", pseudo: "hover", rules: [] },
      { className: "contactus-7-text", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0" }] },
      { className: "contactus-7-text", pseudo: "hover", rules: [] },
    ],
  },
};
