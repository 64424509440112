export const template12 = {
  id: "639f7d6cbab472a442b69e7e",
  name: { en: "Architecture conference", nl: "Architectuur conferentie" },
  desc: { en: "Single-page site for a conference", nl: "Een-pagina site voor een conferentie" },
  categories: ["event"],
  sbPages: [
    {
      pageId: "2ae798e8-1b29-46e0-9dc7-6d463dc0233d",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      components: [
        {
          componentId: "efdRUz",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "hero-2-component-efdRUz"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "tDHdGT",
              type: "",
              htmlTagName: "div",
              classes: ["row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "Iqut0O",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-2-contentcol-efdRUz", "col-12", "col-md-6", "col-lg-6", "p-3"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "oiBf1X",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-2-title-efdRUz", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "The year ahead in architecture",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "EaHp7t",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-2-text-efdRUz", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos saepe expedita nemo molestiae repudiandae, dolor aperiam velit esse veronam!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "UyX9MN",
                      type: "",
                      htmlTagName: "a",
                      classes: ["hero-2-button-efdRUz"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Register now!",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "rV43Ub",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "p-3", "hero-2-img-efdRUzwrapper"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "hsY8jB",
                      type: "",
                      htmlTagName: "img",
                      classes: ["hero-2-img-efdRUz"],
                      styles: [],
                      attributes: [
                        { property: "alt", value: "Image" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/conference1.jpg" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "cpAjAi",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "hero-9-component-cpAjAi"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "gzBaxT",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "vgFM9p",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "order-first", "p-3", "hero-9-imgwrapper"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "lE5cb2",
                      type: "",
                      htmlTagName: "div",
                      classes: ["hero-9-imgwrapper2-cpAjAi"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "rkeh2B",
                          type: "",
                          htmlTagName: "img",
                          classes: ["hero-9-img1-cpAjAi"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/city3.jpg" },
                            { property: "alt", value: "Image" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "aLqxHx",
                          type: "",
                          htmlTagName: "img",
                          classes: ["hero-9-img2-cpAjAi"],
                          styles: [],
                          attributes: [
                            { property: "alt", value: "Image" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/work-16.png" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "y23zHQ",
                          type: "",
                          htmlTagName: "img",
                          classes: ["hero-9-img3-cpAjAi"],
                          styles: [],
                          attributes: [
                            { property: "alt", value: "Image" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior1.png" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "MIIYE1",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-9-contentcol-cpAjAi", "col-12", "col-md-6", "col-lg-4", "p-3"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "sRDwZZ",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-9-title-cpAjAi", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "About the conference",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "P69fGx",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-9-text1-cpAjAi", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos saepe expedita nemo molestiae repudiandae, dolor aperiam velit esse veronam!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "AfWwI2",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-9-text2-cpAjAi", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content:
                            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos saepe expedita nemo molestiae repudiandae, dolor aperiam velit esse veronam!",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ajVVGg",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-ajVVGg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "SbY6lL",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-ajVVGg", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Meet the speakers",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "GQFJ9L",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-23-component-GQFJ9L"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "XkqmQ6",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-23-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "mBwLWA",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-23-col-GQFJ9L"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card23" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "j5Xe0R",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-23-overlay-wrapper-GQFJ9L"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "It1AXU",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-23-avatar-GQFJ9L"],
                          styles: [],
                          attributes: [
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg" },
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "vwP7FQ",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-23-overlay-content-GQFJ9L"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "sZHnlM",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-23-title-GQFJ9L", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "UyRZAJ",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-23-subtitle-GQFJ9L", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "MHpbWr",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-23-col-GQFJ9L"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card23" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "lioDtn",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-23-overlay-wrapper-GQFJ9L"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "i07c2p",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-23-avatar-GQFJ9L"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-3.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "Aufoao",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-23-overlay-content-GQFJ9L"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "jlxzVz",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-23-title-GQFJ9L", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "kYAXnc",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-23-subtitle-GQFJ9L", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "R3DsJb",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "card-23-col-GQFJ9L"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card23" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "axSrVt",
                      type: "",
                      htmlTagName: "div",
                      classes: ["card-23-overlay-wrapper-GQFJ9L"],
                      styles: [],
                      attributes: [{ property: "data-overlaywrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "pRp7q2",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-23-avatar-GQFJ9L"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "data-imgresizable", value: "false" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-2.jpg" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "VPfuYF",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-23-overlay-content-GQFJ9L"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "fqaeIj",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["card-23-title-GQFJ9L", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Name",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "sjbooR",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-23-subtitle-GQFJ9L", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Function",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "jkMYVL",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-jkMYVL"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "RrjWnl",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-jkMYVL", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Key topics to be discussed",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "bI9xg9",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-13-component-bI9xg9"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "K76NSL",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "ArI3tw",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-bI9xg9"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "cKQevP",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-bI9xg9"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "xBaTAA",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-bI9xg9"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "NR2WmV",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-chevron-right"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "nyu5pT",
                          type: "",
                          htmlTagName: "h1",
                          classes: ["card-13-title-bI9xg9", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "01", children: [] },
                          ],
                        },
                        {
                          childId: "o4tGOP",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-bI9xg9", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "RWN1qn",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-bI9xg9"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "jPtYpW",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-bI9xg9"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "l8HItS",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-bI9xg9"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "kFuERb",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-chevron-right"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "blOtY0",
                          type: "",
                          htmlTagName: "h1",
                          classes: ["card-13-title-bI9xg9", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "02", children: [] },
                          ],
                        },
                        {
                          childId: "r4bZKa",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-bI9xg9", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "fLFKob",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "card-13-col-bI9xg9"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "K657rR",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-13-wrapper-bI9xg9"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "wj6D2o",
                          type: "",
                          htmlTagName: "div",
                          classes: ["card-13-iconwrapper-bI9xg9"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "div" },
                            { property: "data-editable", value: "true" },
                            { property: "data-iconwrapper", value: "true" },
                            { property: "data-texteditable", value: "false" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "zNHUT1",
                              type: "",
                              htmlTagName: "span",
                              classes: ["icon", "fa-solid", "fa-chevron-right"],
                              styles: [],
                              attributes: [{ property: "data-checkparent", value: "true" }],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "AlQfJL",
                          type: "",
                          htmlTagName: "h1",
                          classes: ["card-13-title-bI9xg9", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "03", children: [] },
                          ],
                        },
                        {
                          childId: "De8TwP",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-13-text-bI9xg9", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "I4jqdc",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "cta-2-component-I4jqdc"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "pwKPu0",
              type: "",
              htmlTagName: "h1",
              classes: ["cta-2-title-I4jqdc", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Ready to set yourself up for success next year?",
                  children: [],
                },
              ],
            },
            {
              childId: "UAmiIM",
              type: "",
              htmlTagName: "a",
              classes: ["cta-2-button-I4jqdc"],
              styles: [],
              attributes: [
                { property: "href", value: "#!" },
                { property: "data-href", value: "#!" },
                { property: "data-target", value: "_self" },
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "button" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Register now!", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "CqTNb6",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-9-component-CqTNb6"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "VGq7eJ",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-9-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "TISujm",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "px-2", "py-3"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "QdSmUH",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["contactus-9-title-CqTNb6", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Stay up to date",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "m25bO9",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "px-2", "py-3"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "U0zkeu",
                      type: "",
                      htmlTagName: "h3",
                      classes: ["contactus-9-subtitle-CqTNb6", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Join our mailing list",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "sa3uuz",
                      type: "",
                      htmlTagName: "div",
                      classes: ["d-flex", "align-items-end"],
                      styles: [],
                      attributes: [
                        { property: "data-sbform", value: "true" },
                        { property: "data-sbformaction", value: "" },
                        { property: "data-sbformmsg", value: "Your message has been received!" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "div",
                          classes: ["w-100", "w-md-60", "contactus-9-inputwrapper-CqTNb6"],
                          styles: [],
                          attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "label",
                              classes: ["form-control", "contactus-9-label-CqTNb6"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "inputlabel" },
                                { property: "data-editable", value: "true" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Your email",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "input",
                              classes: ["form-control", "contactus-9-inputfield-CqTNb6"],
                              styles: [],
                              attributes: [
                                { property: "type", value: "email" },
                                { property: "name", value: "email" },
                                { property: "placeholder", value: "Your email address" },
                                { property: "data-name", value: "inputfield" },
                                { property: "data-required", value: "true" },
                                { property: "data-editable", value: "true" },
                              ],
                              content: "",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "div",
                          classes: ["contactus-9-buttonwrapper-CqTNb6", "w-100", "w-md-40"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "button",
                              classes: ["contactus-9-button-CqTNb6"],
                              styles: [],
                              attributes: [
                                { property: "data-sbformbtn", value: "true" },
                                { property: "data-editable", value: "true" },
                                { property: "data-name", value: "button" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Send message!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ia1Mcx",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "footer-4-component-ia1Mcx"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "NH5Pwb",
              type: "",
              htmlTagName: "div",
              classes: ["footer-4-wrapper-ia1Mcx", "px-sm-0", "px-md-4"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "footer4_wrapper" },
              ],
              content: "",
              children: [
                {
                  childId: "bEaETR",
                  type: "",
                  htmlTagName: "div",
                  classes: ["footer-4-line-ia1Mcx", "mx-n3", "mx-md-0"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "footer4" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "EuvyI0",
                  type: "",
                  htmlTagName: "div",
                  classes: ["row", "align-items-top", "justify-content-center", "footer-4-row"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "VrKukN",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "cwNiF4",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-ia1Mcx"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Phone",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "Y1IM4L",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-ia1Mcx"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "123 456 7890",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "nRTwVR",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "ddBoQN",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-ia1Mcx"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "N5Bc9p",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-ia1Mcx"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "email@site.com",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "LSIuxf",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "gBHxvZ",
                          type: "block",
                          htmlTagName: "div",
                          classes: [],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "BroJZI",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-ia1Mcx"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "aYJZOl",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-twitter"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "KVxC7s",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-ia1Mcx"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "hZYyC3",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-linkedin"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "xXhVuk",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-ia1Mcx"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "jOSeii",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-facebook-f"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "zlpWk1",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "rZmSKG",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-ia1Mcx"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "© 2022 The Architecture Company",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "639f7417203d6a814c7fe9dd",
    },
  ],
  sbCustomCss: [
    {
      componentId: "GQFJ9L",
      classes: [
        {
          className: "section.card-23-component-GQFJ9L",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(56, 56, 56, 1)" },
          ],
        },
        { className: "section.card-23-component-GQFJ9L", pseudo: "hover", rules: [] },
        {
          className: "card-23-avatar-GQFJ9L",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "transition", value: "all 0.7s ease-in-out" },
          ],
        },
        { className: "card-23-avatar-GQFJ9L", pseudo: "hover", rules: [] },
        {
          className: "card-23-title-GQFJ9L",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "left" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
          ],
        },
        { className: "card-23-title-GQFJ9L", pseudo: "hover", rules: [] },
        {
          className: "card-23-subtitle-GQFJ9L",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(230, 230, 230, 1)" },
          ],
        },
        { className: "card-23-subtitle-GQFJ9L", pseudo: "hover", rules: [] },
        {
          className: "card-23-social-link-GQFJ9L",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0.5rem 0 0" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "card-23-social-link-GQFJ9L", pseudo: "hover", rules: [{ property: "color", value: "rgba(200, 200, 200, 1)" }] },
        { className: "card-23-social-link-GQFJ9L", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "card-23-col-GQFJ9L",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
          ],
        },
        {
          className: "card-23-overlay-wrapper-GQFJ9L",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "width", value: "100%" },
            { property: "aspect-ratio", value: "1" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "card-23-overlay-wrapper-GQFJ9L",
          pseudo: "hover .card-23-avatar-GQFJ9L",
          rules: [{ property: "transform", value: "scale(1.1)" }],
        },
        {
          className: "card-23-overlay-content-GQFJ9L",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "end" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "align-items", value: "center" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "background", value: "linear-gradient(rgba(28, 139, 183, 0.20), rgba(28, 139, 183, 0.6))" },
          ],
        },
      ],
    },
    {
      componentId: "cpAjAi",
      classes: [
        {
          className: "section.hero-9-component-cpAjAi",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "background", value: "rgba(56, 56, 56, 1)" },
          ],
        },
        { className: "section.hero-9-component-cpAjAi", pseudo: "hover", rules: [] },
        {
          className: "hero-9-contentcol-cpAjAi",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "end" },
          ],
        },
        {
          className: "hero-9-imgwrapper2-cpAjAi",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "width", value: "100%" },
            { property: "aspect-ratio", value: "0.6" },
          ],
        },
        { className: "hero-9-imgwrapper2-cpAjAi", pseudo: "hover", rules: [] },
        {
          className: "hero-9-img1-cpAjAi",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "width", value: "75%" },
            { property: "aspect-ratio", value: "0.7" },
            { property: "object-fit", value: "cover" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "hero-9-img1-cpAjAi", pseudo: "hover", rules: [] },
        {
          className: "hero-9-title-cpAjAi",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(231, 234, 237, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-9-title-cpAjAi", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "hero-9-img3-cpAjAi",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "74%" },
            { property: "left", value: "10%" },
            { property: "width", value: "65%" },
            { property: "aspect-ratio", value: "1.5" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "hero-9-img3-cpAjAi",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-9-img2-cpAjAi",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "54%" },
            { property: "right", value: "0" },
            { property: "width", value: "80%" },
            { property: "aspect-ratio", value: "1.5" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-9-img2-cpAjAi",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-9-text1-cpAjAi",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-9-text1-cpAjAi", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "hero-9-text2-cpAjAi",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-9-text2-cpAjAi", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "efdRUz",
      classes: [
        {
          className: "section.hero-2-component-efdRUz",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "background", value: "rgba(56, 56, 56, 1)" },
          ],
        },
        { className: "section.hero-2-component-efdRUz", pseudo: "hover", rules: [] },
        {
          className: "hero-2-contentcol-efdRUz",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "hero-2-title-efdRUz",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(231, 234, 237, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "2rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-2-title-efdRUz", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "hero-2-text-efdRUz",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 3rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-2-text-efdRUz", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "hero-2-img-efdRUz",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0.75rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "hero-2-img-efdRUz",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-2-button-efdRUz",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(24, 91, 133, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 6rem 0.5rem 6rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-2-button-efdRUz",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(0, 74, 117, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "ajVVGg",
      classes: [
        {
          className: "section.basics-1-component-ajVVGg",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(56, 56, 56, 1)" },
          ],
        },
        { className: "section.basics-1-component-ajVVGg", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-ajVVGg",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-ajVVGg", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-ajVVGg",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(231, 234, 237, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "6rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-ajVVGg", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "jkMYVL",
      classes: [
        {
          className: "section.basics-1-component-jkMYVL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(56, 56, 56, 1)" },
          ],
        },
        { className: "section.basics-1-component-jkMYVL", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-jkMYVL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-jkMYVL", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-jkMYVL",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(231, 234, 237, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "6rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-jkMYVL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "bI9xg9",
      classes: [
        {
          className: "section.card-13-component-bI9xg9",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "4.5rem" },
            { property: "background", value: "rgba(56, 56, 56, 1)" },
          ],
        },
        { className: "section.card-13-component-bI9xg9", pseudo: "hover", rules: [] },
        { className: "card-13-col-bI9xg9", pseudo: "", rules: [] },
        { className: "card-13-col-bI9xg9", pseudo: "hover", rules: [] },
        { className: "card-13-wrapper-bI9xg9", pseudo: "", rules: [] },
        { className: "card-13-wrapper-bI9xg9", pseudo: "hover", rules: [] },
        {
          className: "card-13-iconwrapper-bI9xg9",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "color", value: "rgba(231, 234, 237, 1)" },
            { property: "font-size", value: "2rem" },
          ],
        },
        { className: "card-13-iconwrapper-bI9xg9", pseudo: "hover", rules: [] },
        {
          className: "card-13-title-bI9xg9",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(231, 234, 237, 1)" },
            { property: "font-size", value: "60px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-13-title-bI9xg9", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-13-text-bI9xg9",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-13-text-bI9xg9", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "I4jqdc",
      classes: [
        {
          className: "section.cta-2-component-I4jqdc",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "4.5rem" },
            { property: "background", value: "rgba(25, 94, 137, 1)" },
          ],
        },
        { className: "section.cta-2-component-I4jqdc", pseudo: "hover", rules: [] },
        {
          className: "cta-2-component-I4jqdc",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "cta-2-component-I4jqdc", pseudo: "hover", rules: [] },
        {
          className: "cta-2-title-I4jqdc",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(231, 234, 237, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "cta-2-title-I4jqdc", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "cta-2-button-I4jqdc",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(24, 91, 133, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(200, 32, 3, 0)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 4.5rem 0.5rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 4px 2px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "cta-2-button-I4jqdc",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(24, 91, 133, 1)" },
            { property: "background", value: "rgba(228, 232, 235, 1)" },
            { property: "border-color", value: "rgba(200, 32, 3, 0)" },
            { property: "box-shadow", value: "0px 0px 4px 2px rgba(225, 230, 233, 0.39)" },
          ],
        },
      ],
    },
    {
      componentId: "CqTNb6",
      classes: [
        {
          className: "section.contactus-9-component-CqTNb6",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-9-component-CqTNb6", pseudo: "hover", rules: [] },
        {
          className: "contactus-9-title-CqTNb6",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(25, 94, 137, 1)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "center" },
          ],
        },
        {
          className: "contactus-9-title-CqTNb6",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-9-subtitle-CqTNb6",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(25, 94, 137, 1)" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "center" },
          ],
        },
        {
          className: "contactus-9-subtitle-CqTNb6",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-9-col-CqTNb6",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-9-buttonwrapper-CqTNb6",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        {
          className: "contactus-9-buttonwrapper-CqTNb6",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-9-button-CqTNb6",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(25, 94, 137, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(25, 94, 137, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "auto" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "contactus-9-button-CqTNb6",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(25, 94, 137, 1)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "contactus-9-inputwrapper-CqTNb6",
          pseudo: "",
          rules: [{ property: "margin", value: "0 0 0 0" }],
        },
        {
          className: "contactus-9-inputfield-CqTNb6",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(32, 35, 39, 1)" },
            { property: "border-style", value: "none none solid none" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-9-inputfield-CqTNb6",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(106, 115, 122, 1)" },
            { property: "border-color", value: "rgba(25, 94, 137, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-9-inputfield-CqTNb6",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "contactus-9-inputfield-CqTNb6",
          pseudo: "hover",
          rules: [],
        },
        {
          className: "contactus-9-label-CqTNb6",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        {
          className: "contactus-9-label-CqTNb6",
          pseudo: "hover",
          rules: [],
        },
      ],
    },
    {
      componentId: "ia1Mcx",
      classes: [
        {
          className: "section.footer-4-component-ia1Mcx",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "2rem" },
            { property: "background", value: "rgba(56, 56, 56, 1)" },
          ],
        },
        { className: "section.footer-4-component-ia1Mcx", pseudo: "hover", rules: [] },
        { className: "footer-4-wrapper-ia1Mcx", pseudo: "", rules: [] },
        {
          className: "footer-4-line-ia1Mcx",
          pseudo: "",
          rules: [
            { property: "border-style", value: "solid none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(200, 200, 200, 1)" },
            { property: "margin", value: "0 0 2rem 0" },
          ],
        },
        { className: "footer-4-line-ia1Mcx", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(200, 200, 200, 1)" }] },
        {
          className: "footer-4-title-ia1Mcx",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(231, 234, 237, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "footer-4-text-ia1Mcx",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(231, 234, 237, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "footer-4-social-link-ia1Mcx",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(228, 232, 235, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 0.5rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "align-self", value: "start" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        {
          className: "footer-4-social-link-ia1Mcx",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "footer-4-social-link-ia1Mcx", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Quicksand:300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Quicksand",
    fontHeadersWeight: "700",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(231, 234, 237, 1)",
    color2: "rgba(255, 255, 255, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
