import React from "react";

import SiteColorscheme from "./SiteColorscheme";
import SiteFonts from "./SiteFonts";

const SiteStyle = () => {
  return (
    <>
      <SiteColorscheme />
      <SiteFonts />
    </>
  );
};

export default SiteStyle;
