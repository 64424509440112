export const hero5 = {
  id: "hero-5",
  name: "Hero 5",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "hero-5-component"],
    styles: [],
    attributes: [
      { property: "data-name", value: "carousel" },
      { property: "data-editable", value: "true" },
    ],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["splide", "carousel-slides", "hero-5-splide"],
        styles: [],
        attributes: [
          { property: "aria-label", value: "Carousel" },
          {
            property: "data-splide",
            value:
              '{"width":"100vw","height":"100vh","heightRatio":0.5,"type":"fade","rewind":true,"speed":500,"rewindSpeed":1000,"easing":"linear","perPage":1,"breakpoints":{"640":{"perPage":1,"perMove":1}},"perMove":1,"keyboard":true,"autoplay":true,"interval":2000,"pauseOnHover":true,"arrows":true,"pagination":true,"drag":"free","focus":"center","classes":{"arrow":"splide__arrow hero-5-arrow","prev":"splide__arrow--prev hero-5-prev","next":"splide__arrow--next hero-5-next","page":"splide__pagination__page hero-5-pagination"}}',
          },
          { property: "data-carousel-slidesetup", value: "image" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["splide__track"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["splide__list"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "hero-5-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["hero-5-wrapper"],
                        styles: [],
                        attributes: [{ property: "data-herowrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "img",
                            classes: ["hero-5-img"],
                            styles: [],
                            attributes: [
                              {
                                property: "src",
                                value: "https://cdn.satonda.com/eb/assets/nature1.png",
                              },
                              { property: "alt", value: "Image" },
                            ],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["hero-5-content"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "hero1" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "h1",
                                classes: ["hero-5-title", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "h" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Some appealing title!",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["hero-5-text", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "p" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "a",
                                classes: ["hero-5-button"],
                                styles: [],
                                attributes: [
                                  { property: "href", value: "#!" },
                                  { property: "data-href", value: "#!" },
                                  { property: "data-target", value: "_self" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-name", value: "button" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Read more",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "hero-5-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["hero-5-wrapper"],
                        styles: [],
                        attributes: [{ property: "data-herowrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "img",
                            classes: ["hero-5-img"],
                            styles: [],
                            attributes: [
                              {
                                property: "src",
                                value: "https://cdn.satonda.com/eb/assets/nature2.png",
                              },
                              { property: "alt", value: "Image" },
                            ],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["hero-5-content"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "hero1" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "h1",
                                classes: ["hero-5-title", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "h" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Some appealing title!",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["hero-5-text", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "p" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "a",
                                classes: ["hero-5-button"],
                                styles: [],
                                attributes: [
                                  { property: "href", value: "#!" },
                                  { property: "data-href", value: "#!" },
                                  { property: "data-target", value: "_self" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-name", value: "button" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Read more",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "element",
                    htmlTagName: "div",
                    classes: ["splide__slide", "hero-5-slide"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["hero-5-wrapper"],
                        styles: [],
                        attributes: [{ property: "data-herowrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "img",
                            classes: ["hero-5-img"],
                            styles: [],
                            attributes: [
                              {
                                property: "src",
                                value: "https://cdn.satonda.com/eb/assets/nature3.png",
                              },
                              { property: "alt", value: "Image" },
                            ],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["hero-5-content"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "hero1" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "h1",
                                classes: ["hero-5-title", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "h" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Some appealing title!",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["hero-5-text", "w-100", "w-md-100", "w-lg-100"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "p" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "a",
                                classes: ["hero-5-button"],
                                styles: [],
                                attributes: [
                                  { property: "href", value: "#!" },
                                  { property: "data-href", value: "#!" },
                                  { property: "data-target", value: "_self" },
                                  { property: "data-editable", value: "true" },
                                  { property: "data-name", value: "button" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Read more",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.hero-5-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      {
        className: "section.hero-5-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-5-component",
        pseudo: "",
        rules: [
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "hero-5-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-5-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "height", value: "100vh" },
        ],
      },
      {
        className: "hero-5-img",
        pseudo: "",
        rules: [
          { property: "display", value: "block" },
          { property: "height", value: "100%" },
          { property: "width", value: "100%" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "hero-5-content",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "background", value: "rgba(0, 0, 0, 0.3)" },
        ],
      },
      {
        className: "hero-5-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
          { property: "margin", value: "0 0 1rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "hero-5-title",
        pseudo: "hover",
        rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }],
      },
      {
        className: "hero-5-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
          { property: "margin", value: "0 0 2rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "hero-5-text",
        pseudo: "hover",
        rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }],
      },
      {
        className: "hero-5-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-radius", value: "1000px" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
          { property: "text-decoration", value: "none" },
        ],
      },
      {
        className: "hero-5-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(0, 0, 0, 1)" },
        ],
      },

      {
        className: "hero-5-arrow",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.5)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-radius", value: "50%" },
          { property: "height", value: "2rem" },
          { property: "width", value: "2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "hero-5-arrow",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "hero-5-arrow svg",
        pseudo: "",
        rules: [
          { property: "fill", value: "rgba(0, 0, 0, 0.7)" },
          { property: "height", value: "1.2rem" }, // (size - 0.8)rem
          { property: "width", value: "1.2rem" }, // (size - 0.8)rem
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "hero-5-arrow svg",
        pseudo: "hover",
        rules: [{ property: "fill", value: "rgba(0, 0, 0, 1)" }],
      },
      {
        className: "hero-5-prev",
        pseudo: "",
        rules: [
          { property: "left", value: "1rem" }, // Inside: 1rem || Outside: -(size + 1)rem
        ],
      },
      {
        className: "hero-5-next",
        pseudo: "",
        rules: [
          { property: "right", value: "1rem" }, // Inside: 1rem, outside: -(size+1)rem
        ],
      },
      {
        className: "hero-5-pagination",
        pseudo: "",
        rules: [
          { property: "height", value: "0.7rem" },
          { property: "width", value: "0.7rem" },
          { property: "background", value: "rgba(255, 255, 255, 0.5)" },
          { property: "margin", value: "0.25rem" },
          { property: "border-radius", value: "50%" },
          { property: "display", value: "inline-block" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "hero-5-pagination",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.8)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scale(1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "is-active.hero-5-pagination",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 0.8)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scale(1)" },
          { property: "opacity", value: "1" },
        ],
      },
    ],
  },
};
