import store from "../store";
import { getState } from "./stateFunctions";
import { startEditingStyles, updateCss, updateClasses, updateAttributes, saveTrackChanges, updateCustoms } from "../actions/sb";
import { MARGIN_VALUES, PADDING_VALUES, CAROUSEL_STANDARD_OPTIONS } from "./editStyleVars";
import { getTargetElement, injectIframeJS } from "./domFunctions";
import { ensureRgba, ensureRgba_values } from "./colorFunctions";
import { getStartStyles } from "./getStyleFunctions";
import {
  getTargetObj,
  getTargetParent,
  getFirstChildWithAttr,
  getConcatElementId,
  getComponentRelativeToCurrent,
  getFirstChildByTagname,
  getTargetCustomClassname,
  getCustomCss,
  getFirstChildByClassname,
  getAnimateElements,
  getParentWithAttr,
  getInputfieldLabel,
} from "./componentObjectFunctions";

// =================================================================================================================
// Process to add a new special component or new elementAttribute
// 1) Check whether the special component is set to data-editable=true in the component template
// 2) Add the special component to the elementAttributes array
// 3) Add to getStartStyles and make the getStyle function
// 4) Make the editForm
// 5) Make the setStyle function
//    a) Add to getFormValue (if needed) and getUnsetValue functions
//    b) Add to applyChanges function's CHAMGE_CUSTOMS arrays (if the style is not a "normal" property that goes via classname or css rule)
//    c) Add to applyChanges SPECIALS array (= the data-name of the special element, not the style property from 5b)
//    d) Add to applyChangesSpecials function
//    e) Add to updateCustoms redux actions function
//    f) Add to reducer
// =================================================================================================================

// =====================
// == Editable styles ==
// =====================

export const elementAttributes = {
  // Divs
  // component: ["containerWidth", "bgColor", "boxShadow", "borderRadius", "transition"], // no margin and padding, as these are used in .container class already for centering the div
  component: ["component", "animate"],
  bgdiv: ["bgColor", "border"],
  // block: ["bgColor", "border", "boxShadow", "borderRadius", "margin", "padding", "alignRow", "responsiveLayout", "transition"], // No specific styling for blocks. If needed, to be done on its element children
  element_col: ["bgColor", "border", "boxShadow", "borderRadius", "margin", "padding", "alignCol", "alignRow", "responsiveLayout", "transition"],
  hero1: ["hero1"],
  hero4_contentwrapper: ["bgColor", "border", "boxShadow", "transition"],
  card2: ["card2"],
  card3: ["card3"],
  card4: ["card4"],
  card6: ["card6"],
  card7: ["border", "transition"],
  card16: ["card16"],
  card23: ["card23"],
  footer4: ["border", "margin", "transition"],
  parallax: ["parallax"], // for height, overlaycolor & imgsrc
  parallax3_content: ["width", "alignHori", "bgColor", "border", "boxShadow", "borderRadius", "padding", "transition"],
  process1: ["process1"],
  process2: ["process2"],
  callout_testimonial: ["callout_testimonial"],
  section_divider: ["section_divider"],
  navbar: ["navbar"],
  resume1: ["bgColor", "border", "boxShadow", "borderRadius", "padding", "margin", "transition"],
  resume2: ["bgColor", "border", "boxShadow", "borderRadius", "padding", "margin", "transition"],
  quote7: ["bgColor", "border", "boxShadow", "borderRadius", "padding", "margin", "transition"],
  img_gallery1: ["img_gallery1"],
  img_gallery2: ["img_gallery2"],
  carousel: ["carousel"],
  carousel_thumbnail: ["carousel_thumbnail"],
  backtotop: ["backtotop"],
  headingline: ["headingline"],
  sectionline: ["sectionline"],
  statistics2: ["responsiveLayout"],
  statistics3a: ["bgColor", "border", "borderRadius", "alignRow", "responsiveLayout", "transition"],
  statistics3b: ["bgColor", "border", "borderRadius", "transition"],
  // div: ["bgColor", "border", "boxShadow", "borderRadius", "margin", "padding", "transition"],
  // Text elemennts
  h: ["width", "alignHori", "margin", "textShadow", "transition"],
  p: ["width", "alignHori", "margin", "textShadow", "transition"],
  span: ["width", "alignHori", "margin", "textShadow", "transition"],
  u: ["width", "alignHori", "margin", "textShadow", "transition"],
  strong: ["width", "alignHori", "margin", "textShadow", "transition"],
  b: ["width", "alignHori", "margin", "textShadow", "transition"],
  i: ["iconEditor", "margin", "textShadow", "transition"],
  em: ["width", "alignHori", "margin", "textShadow", "transition"],
  s: ["width", "alignHori", "margin", "textShadow", "transition"],
  sup: ["width", "alignHori", "margin", "textShadow", "transition"],
  sub: ["width", "alignHori", "margin", "textShadow", "transition"],
  font: ["width", "alignHori", "margin", "textShadow", "transition"],
  a: ["linkEditor", "margin", "textShadow", "transition"],
  ul: ["listMarker", "margin", "transition"], // removed border
  ol: ["listMarker", "margin", "transition"], // removed border
  li: ["listMarker", "margin", "transition"], // removed border
  // Img elements
  img: ["imgSource", "imgAdjust", "imgResize", "margin", "border", "boxShadow", "borderRadius", "overlay", "alignHori", "transition"],
  imgSrcOnly: ["imgSource"],
  // Form elements
  button: ["buttonEditor", "boxShadow", "alignHori", "margin", "transition"],
  // inputfield: ["inputfield", "margin", "padding", "borderRadius", "transition"],
  inputfield: ["inputfield"],
  // Other
  googlemaps: ["googlemaps"],
};

// =========================
// == Edit form variables ==
// =========================
export const EDIT_FORM_ID_PREFIX = "editForm_";

// Determine which editForms to show (element settings, text editor, general styles (margin, padding, display, ...) and specific styles)
// target = the element that was selected through clickIframeElement in domFunctions
// All html should have a data-name attr, which can then be used to get the attributes that are editable: editForms = elementAttributes[target.dataset.name]
//    If target doesn't have a data-name attr, move to its parent (e.g., an inserted <b> through textEditor should be editable through its parent <p>)
// The burden to make styling work should be on the HTML. The functions to determine which styles to edit should be very simple
//    This is especially important for more complex components to be added later, such as navbar or parallax
//    User should be able to click anywhere, which then selects the full e.g. navbar/parallax component and all the right forms should be shown. Then editing should again be easy
export const determineEditFormsToShow = (target) => {
  // console.log(`determineEditFormsToShow`, target);
  let editFormsToShow =
    typeof elementAttributes[target.dataset.name] === "undefined"
      ? ["elementSettings"]
      : ["elementSettings", ...elementAttributes[target.dataset.name]];
  // Loop through specific data-[] attributes and add those editForms
  if (target.dataset.texteditable === "true") {
    editFormsToShow.unshift("textEditor");
  }
  if (target.dataset.iconwrapper === "true") {
    editFormsToShow.push("iconEditor");
  }
  if (target.dataset.listmarker === "true") {
    editFormsToShow.push("listMarker2");
  }
  if (target.dataset.imgresizable === "false") {
    // Handle images that are not resizable
    editFormsToShow = editFormsToShow.filter((editForm) => editForm !== "imgResize");
  }
  // Get startStyles and save to redux
  let startStyles = getStartStyles(editFormsToShow);
  // console.log("======= startStyles");
  // console.log(startStyles);
  store.dispatch(startEditingStyles(editFormsToShow, startStyles));
};

export const updateEditFormColorpickerValue = (id, color) => {
  try {
    document.getElementById(id).style.backgroundColor = color;
  } catch (error) {
    console.error(error);
  }
};

export const formGetStartValue = (startStyles, prop) => {
  try {
    return startStyles[prop];
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const clickApplyStyleChanges = (applyTextEditorChanges) => {
  // Get all form values
  let startStyles = getState("sb", "startStyles");
  let selectedElement = getState("sb", "selectedElement");
  // console.log("startStyles", startStyles);
  // console.log("==================");
  // Save track changes to the history stack
  store.dispatch(saveTrackChanges());
  // Get values from all editForms
  let changedStyles = getStyleChangesMade(startStyles);
  // console.log("changedStyles", changedStyles);
  // Take out certain properties used to manage local states of editForms
  const IGNORE_PROPERTIES = ["boxShadowChangeOnHover", "textShadowChangeOnHover", "imgAdjustChangeOnHover", "btnIsLink", "sdScaleHor", "sdScaleVer"];
  changedStyles = changedStyles.filter((formProperty) => !IGNORE_PROPERTIES.includes(Object.keys(formProperty)[0]));
  // Apply text editor changes - Before applyChanges() so that text color on links from linkEditor are leading
  applyTextEditorChanges !== null && applyTextEditorChanges.current.click();
  // Apply changed styles
  applyChanges(startStyles, changedStyles);
  // Reset all startStyles, to be sure that changes that were made are picked up in the comparison for changedStyles if user doesn't select another element
  determineEditFormsToShow(getTargetElement(selectedElement));
  // Reload iframe js
  injectIframeJS();
};

// ========================
// == Make style changes ==
// ========================

const getStyleChangesMade = (startStyles) => {
  try {
    // Check certain properties that may have a start value of null. If so, remove them
    const PROPERTIES_TO_CHECK = ["formDiv_successMsg", "formDiv_action"];
    const START_NULL_VALUES_TO_REMOVE = PROPERTIES_TO_CHECK.filter((prop) => typeof startStyles[prop] !== "undefined" && startStyles[prop] === null);
    let arrStartStyles = Object.keys(startStyles).filter((prop) => !START_NULL_VALUES_TO_REMOVE.includes(prop));
    let changedStyles = arrStartStyles.map((formProperty) => ({ [formProperty]: getFormValue(`${EDIT_FORM_ID_PREFIX}${formProperty}`) }));
    return changedStyles;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getFormValue = (id) => {
  // Get value from the editForm by its id
  try {
    if (id === `${EDIT_FORM_ID_PREFIX}sdColors`) {
      let sdColors = [];
      let i = 0;
      let target = document.getElementById(`${id}${i}`);
      while (target !== null) {
        sdColors.push(ensureRgba(target.style.backgroundColor));
        i++;
        target = document.getElementById(`${id}${i}`);
      }
      return sdColors;
    }
    if (id === `${EDIT_FORM_ID_PREFIX}navbarLinkData`) {
      let navbarLinkData = [];
      let i = 0;
      let linkText = document.getElementById(`${EDIT_FORM_ID_PREFIX}navbarLinkText${i}`);
      let linkDest = document.getElementById(`${EDIT_FORM_ID_PREFIX}navbarLinkDest${i}`);
      while (linkText !== null) {
        navbarLinkData.push({ childId: linkText.dataset.childid || "", text: linkText.value || "", dest: linkDest.value || "" });
        i++;
        linkText = document.getElementById(`${EDIT_FORM_ID_PREFIX}navbarLinkText${i}`);
        linkDest = document.getElementById(`${EDIT_FORM_ID_PREFIX}navbarLinkDest${i}`);
      }
      return navbarLinkData;
    }
    if (id === `${EDIT_FORM_ID_PREFIX}inputfield_dropdownOptions`) {
      let options = [];
      let i = 0;
      let optionVal = document.getElementById(`${EDIT_FORM_ID_PREFIX}inputfield_dropdownOptions${i}_val`);
      let optionLabel = document.getElementById(`${EDIT_FORM_ID_PREFIX}inputfield_dropdownOptions${i}_label`);
      while (optionVal !== null && optionLabel !== null) {
        options.push({ val: optionVal.value, label: optionLabel.value });
        i++;
        optionVal = document.getElementById(`${EDIT_FORM_ID_PREFIX}inputfield_dropdownOptions${i}_val`);
        optionLabel = document.getElementById(`${EDIT_FORM_ID_PREFIX}inputfield_dropdownOptions${i}_label`);
      }
      return JSON.stringify(options);
    }
    if (id === `${EDIT_FORM_ID_PREFIX}formDiv_action`) {
      let actions = [];
      let i = 0;
      let actionType = document.getElementById(`${EDIT_FORM_ID_PREFIX}formDiv_action${i}actionType`);
      let actionData = document.getElementById(`${EDIT_FORM_ID_PREFIX}formDiv_action${i}actionData`);
      while (actionType !== null && actionData !== null) {
        actions.push({ actionType: actionType.value, actionData: actionData.value });
        i++;
        actionType = document.getElementById(`${EDIT_FORM_ID_PREFIX}formDiv_action${i}actionType`);
        actionData = document.getElementById(`${EDIT_FORM_ID_PREFIX}formDiv_action${i}actionData`);
      }
      return JSON.stringify(actions);
    }
    if (id === `${EDIT_FORM_ID_PREFIX}carouselOptions`) {
      let carouselSize = document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_size`).value;
      let carouselAutoplay = document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_autoplay`).value;
      let type = document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_transitionType`).value;
      let speed = parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_transitionSpeed`).value) * 1000;
      let rewindSpeed = parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_rewindSpeed`).value) * 1000;
      let interval = parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_interval`).value) * 1000;
      let autoscrollSpeed = parseInt(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_autoscrollSpeed`).value);
      let perPage = parseInt(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_perPage`).value);
      let perMove = parseInt(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_perMove`).value);
      let fixedHeight = parseInt(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_fixedHeight`).value);
      let gap = parseInt(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_gap`).value);
      let classes = JSON.parse(document.getElementById(`${EDIT_FORM_ID_PREFIX}carouselClasses`).value);
      // Navigation & pagination
      let arrows = document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_includeNavButtons`).value === "true";
      let pagination = document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_pag_includePagButtons`).value === "true";
      // Prepare full options object
      let carouselOptions = { ...CAROUSEL_STANDARD_OPTIONS, arrows, pagination, type, speed, rewindSpeed, perPage, perMove, classes };
      if (carouselSize === "fullPage") {
        carouselOptions = { ...carouselOptions, width: "100vw", height: "100vh", heightRatio: 0.5, focus: "center" };
      }
      if (carouselAutoplay === "autoscroll") {
        // Autoscroll only works with type = loop
        carouselOptions = {
          ...carouselOptions,
          autoScroll: {
            speed: autoscrollSpeed,
          },
          type: "loop",
          rewind: false,
          focus: "center",
        };
      }
      if (carouselAutoplay === "autoplay") {
        carouselOptions = { ...carouselOptions, autoplay: true, interval };
      }
      if (type === "slide" || type === "fade") {
        carouselOptions = { ...carouselOptions, rewind: true };
      }
      if (carouselAutoplay === "autoscroll" && fixedHeight > 0) {
        // Also set type to loop. Fixed size doesn't work with fade and slide is a bit awkard
        carouselOptions = {
          ...carouselOptions,
          type: "loop",
          rewind: false,
          fixedWidth: "auto",
          fixedHeight: fixedHeight === 0 ? "auto" : `${fixedHeight}px`,
          gap,
          breakpoints: { 640: { ...640, fixedWidth: 60, fixedHeight: 44 } },
        };
      } else {
        carouselOptions = {
          ...carouselOptions,
          fixedHeightCss: fixedHeight === 0 ? "auto" : `${fixedHeight}px`,
        };
      }
      return carouselOptions;
    }
    if (id === `${EDIT_FORM_ID_PREFIX}carouselOptionsThumbnail`) {
      let carouselOptionsThumbnail = JSON.parse(document.getElementById(`${EDIT_FORM_ID_PREFIX}carouselOptionsThumbnail`).value);
      let carouselAutoplay = document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_thumbnail_autoplay`).value;
      let speed = parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_thumbnail_transitionSpeed`).value) * 1000;
      let rewindSpeed = parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_thumbnail_rewindSpeed`).value) * 1000;
      let interval = parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_thumbnail_interval`).value) * 1000;
      // Navigation & pagination
      let arrows = document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_includeNavButtons`).value === "true";
      // Prepare full options object
      carouselOptionsThumbnail = {
        ...carouselOptionsThumbnail,
        arrows,
        speed,
        rewindSpeed,
        autoplay: carouselAutoplay === "autoplay",
        interval,
      };
      return carouselOptionsThumbnail;
    }
    if (id === `${EDIT_FORM_ID_PREFIX}carouselOptionsMain`) {
      let carouselOptionsMain = JSON.parse(document.getElementById(`${EDIT_FORM_ID_PREFIX}carouselOptionsMain`).value);
      let speed = parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_thumbnail_transitionSpeed`).value) * 1000;
      let rewindSpeed = parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_thumbnail_rewindSpeed`).value) * 1000;
      // Prepare full options object
      carouselOptionsMain = { ...carouselOptionsMain, speed, rewindSpeed };
      return carouselOptionsMain;
    }
    if (id === `${EDIT_FORM_ID_PREFIX}carouselNavStyles`) {
      return {
        pos: document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_pos`).value,
        iconSize: parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_iconSize`).value),
        bgColor: ensureRgba(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_bgColor`).style.backgroundColor),
        bgColorHover: ensureRgba(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_bgColorHover`).style.backgroundColor),
        borderRadius: parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_borderRadius`).value),
        borderSide: document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_borderSide`).value,
        borderType: document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_borderType`).value,
        borderWidth: parseInt(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_borderWidth`).value),
        borderColor: ensureRgba(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_borderColor`).style.backgroundColor),
        borderColorHover: ensureRgba(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_borderColorHover`).style.backgroundColor),
        transition: parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_transition`).value),
        fillColor: ensureRgba(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_fillColor`).style.backgroundColor),
        fillColorHover: ensureRgba(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_nav_fillColorHover`).style.backgroundColor),
      };
    }
    if (id === `${EDIT_FORM_ID_PREFIX}carouselPagStyles`) {
      return {
        btnForm: document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_pag_btnForm`).value,
        borderRadius: parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_pag_borderRadius`).value),
        btnSize: parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_pag_btnSize`).value),
        bgColor: ensureRgba(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_pag_bgColor`).style.backgroundColor),
        bgColorHover: ensureRgba(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_pag_bgColorHover`).style.backgroundColor),
        borderSide: document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_pag_borderSide`).value,
        borderType: document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_pag_borderType`).value,
        borderWidth: parseInt(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_pag_borderWidth`).value),
        borderColor: ensureRgba(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_pag_borderColor`).style.backgroundColor),
        borderColorHover: ensureRgba(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_pag_borderColorHover`).style.backgroundColor),
        transition: parseFloat(document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_pag_transition`).value),
      };
    }
    if (id === `${EDIT_FORM_ID_PREFIX}carouselFormat`) {
      // Possible values: "carousel-slides", "carousel-thumbnail-main", "carousel-autoscroll"
      // Only possible to move from "carousel-slides" to "carousel-autoscroll" or other way around
      let prevCarouselFormat = document.getElementById(`${EDIT_FORM_ID_PREFIX}carouselFormat`).value;
      let currAutoscroll = document.getElementById(`${EDIT_FORM_ID_PREFIX}carousel_autoplay`).value === "autoscroll";
      if (prevCarouselFormat === "carousel-slides" && currAutoscroll) {
        return "carousel-autoscroll";
      }
      if (prevCarouselFormat === "carousel-autoscroll" && !currAutoscroll) {
        return "carousel-slides";
      }
      return prevCarouselFormat;
    }
    let target = document.getElementById(id);
    if (target === null) {
      // If editForm is not found (e.g. because onHover part is hidden via bool toggle)
      return getUnsetValue(id);
    }
    if (target.dataset.editform === "colorpicker") {
      // For colors, get their color strings
      return ensureRgba(target.style.backgroundColor);
    }
    if (target.dataset.editform === "iconEditor") {
      // For iconEditor, get its classname
      return target.className;
    }
    if (target.dataset.editform === "boolToggle") {
      // For button text styles, get their data-active attribute
      return target.dataset.active === "true";
    }
    if (id === `${EDIT_FORM_ID_PREFIX}componentBoolBgIsGradient`) {
      // boolIsGradient is set via data-isgradient
      return target.dataset.isgradient === "true";
    }
    if (
      id === `${EDIT_FORM_ID_PREFIX}navbarScrolledpastBool` ||
      id === `${EDIT_FORM_ID_PREFIX}inputfield_required` ||
      id === `${EDIT_FORM_ID_PREFIX}inputfield_showLabel`
    ) {
      return target.value === "true";
    }
    if (id.includes("googlemaps")) {
      // For Google Maps input, get the value but check it is a valid maps link
      if ((target.value.match(/^https:\/\/www.google.com\/maps\/embed(.+?)$/) || ["", ""])[1] !== "") {
        return target.value;
      }
      return "";
    }
    // For all others, get their form values
    // Transform to numbers if needed
    const PARSE_FLOAT = [
      "colMobile",
      "colTablet",
      "colDesktop",
      "widthMobile",
      "widthTablet",
      "widthDesktop",
      "borderWidth",
      "borderRadius",
      "boxShadowHori",
      "boxShadowHoriHover",
      "boxShadowVert",
      "boxShadowVertHover",
      "boxShadowBlur",
      "boxShadowBlurHover",
      "boxShadowSpread",
      "boxShadowSpreadHover",
      "textShadowHori",
      "textShadowHoriHover",
      "textShadowVert",
      "textShadowVertHover",
      "textShadowBlur",
      "textShadowBlurHover",
      "paddingTop",
      "paddingRight",
      "paddingBottom",
      "paddingLeft",
      "marginTop",
      "marginRight",
      "marginBottom",
      "marginLeft",
      "markerMarginRight",
      "imgOpacity",
      "imgOpacityHover",
      "imgBlur",
      "imgBrightness",
      "imgContrast",
      "imgGrayscale",
      "imgHueRotate",
      "imgInvert",
      "imgSaturate",
      "imgSepia",
      "imgBlurHover",
      "imgBrightnessHover",
      "imgContrastHover",
      "imgGrayscaleHover",
      "imgHueRotateHover",
      "imgInvertHover",
      "imgSaturateHover",
      "imgSepiaHover",
      "imgTransformHover",
      "imgResizeWidth",
      "imgResizeHeight",
      "transition",
      "card6_size",
      "sdSpaceTop",
      "sdSpaceBottom",
      "navbarSize",
      "navbarTransition",
      "navbarBoxShadowHori",
      "navbarBoxShadowVert",
      "navbarBoxShadowBlur",
      "navbarBoxShadowSpread",
      "navbarScrolledpastSize",
      "navbarLogoMaxHeight",
      "navbarScrolledpastLogoMaxHeight",
      "navbarTogglerFontSize",
      "navbarTogglerBorderRadius",
      "navbarLinkFontSize",
      "componentPaddingTop",
      "componentPaddingBottom",
      "backToTop_sizeBtn",
      "backToTop_sizeIcon",
      "inputfield_fontSize",
      "height",
      "width",
      "fontSize",
      "componentBgGradientDirection",
      "componentBgGradientStop1",
      "componentBgGradientStop2",
    ];
    let formProp = id.replace(EDIT_FORM_ID_PREFIX, "");
    let val = target.value;
    if (PARSE_FLOAT.includes(formProp)) {
      val = parseFloat(val);
    }
    // Margin & padding: return their rem values
    if (["marginTop", "marginBottom", "marginLeft", "marginRight"].includes(formProp)) {
      return MARGIN_VALUES[val + 10];
    }
    if (
      [
        "paddingTop",
        "paddingBottom",
        "paddingLeft",
        "paddingRight",
        "markerMarginRight",
        "sdSpaceTop",
        "sdSpaceBottom",
        "componentPaddingTop",
        "componentPaddingBottom",
        "navbarSize",
        "navbarScrolledpastSize",
      ].includes(formProp)
    ) {
      return PADDING_VALUES[val];
    }
    // Times 1000 (miliseconds to seconds)
    const TIMES_1000 = ["animationDelay", "animationDuration", "animationStaggeredDelay"];
    if (TIMES_1000.includes(formProp)) {
      val = parseFloat(val) * 1000;
    }
    return val;
  } catch (error) {
    console.error(error);
    return getUnsetValue(id);
  }
};

const getUnsetValue = (id) => {
  // Get standardized value of selected editForm
  // For onHover properties, check first the value of its normal style property
  // Otherwise, get the default values as set via their getStyle functions
  try {
    const UNSET_ONLY_ON_HOVER = {
      // Note: the 2nd check below (getFormValue(`${EDIT_FORM_ID_PREFIX}${formProperty.replace("Hover", "")}`)) removes "Hover" from form props
      //   This is an issue for props that only exist on hover
      //   Therefore, check those first
      imgTransformHover: 100,
    };
    const UNSET_VALUES_ZEROS = [
      "borderWidth",
      "borderRadius",
      "boxShadowHori",
      "boxShadowHoriHover",
      "boxShadowHoriFocus",
      "boxShadowVert",
      "boxShadowVertHover",
      "boxShadowVertFocus",
      "boxShadowBlur",
      "boxShadowBlurHover",
      "boxShadowBlurFocus",
      "boxShadowSpread",
      "boxShadowSpreadHover",
      "boxShadowSpreadFocus",
      "textShadowHori",
      "textShadowHoriHover",
      "textShadowVert",
      "textShadowVertHover",
      "textShadowBlur",
      "textShadowBlurHover",
      "paddingTop",
      "paddingRight",
      "paddingBottom",
      "paddingLeft",
      "marginTop",
      "marginRight",
      "marginBottom",
      "marginLeft",
      "markerMarginRight",
      "imgBlur",
      "imgBrightness",
      "imgContrast",
      "imgGrayscale",
      "imgHueRotate",
      "imgInvert",
      "imgSaturate",
      "imgSepia",
      "imgBlurHover",
      "imgBrightnessHover",
      "imgContrastHover",
      "imgGrayscaleHover",
      "imgHueRotateHover",
      "imgInvertHover",
      "imgSaturateHover",
      "imgSepiaHover",
      "imgResizeWidth",
      "imgResizeHeight",
      "transition",
      "sdSpaceTop",
      "sdSpaceBottom",
      "componentPaddingTop",
      "componentPaddingBottom",
      "componentBgGradientDirection",
      "componentBgGradientStop1",
      "componentBgGradientStop2",
    ];
    const UNSET_VALUES_BLACK_TRANSPARENT = [
      "borderColor",
      "borderColorHover",
      "borderColorFocus",
      "boxShadowRgba",
      "boxShadowRgbaHover",
      "boxShadowRgbaFocus",
      "textShadowRgba",
      "textShadowRgbaHover",
      "markerBorderColor",
      // "listMarkerColor",
    ];
    const UNSET_VALUES_NONE = ["borderSide", "linkDeco", "linkDecoHover", "markerForm"];
    const UNSET_VALUES_CUSTOM = {
      containerWidth: "container-fluid",
      colMobile: 12,
      colTablet: 12,
      colDesktop: 12,
      widthMobile: 100,
      widthTablet: 100,
      widthDesktop: 100,
      alignCol: "start",
      alignRowAlign: "start",
      alignRowJustify: "start",
      alignHori: "start",
      bgColor: "rgba(255, 255, 255, 0)",
      bgColorHover: "rgba(255, 255, 255, 0)",
      bgColorFocus: "rgba(255, 255, 255, 0)",
      borderType: "solid",
      textColor: "rgba(0, 0, 0, 1)",
      textColorHover: "rgba(0, 0, 0, 1)",
      textColorFocus: "rgba(0, 0, 0, 1)",
      linkDest: "#!",
      linkTarget: "_self",
      display: "block",
      imgSource: "",
      imgDesc: "",
      imgOpacity: 1,
      imgOpacityHover: 1,
      // listMarkerStyle: "disc",
      btnIsBold: false,
      btnIsItalic: false,
      btnIsUnderlined: false,
      btnFullWidth: false,
      googlemaps: "",
      hero1_overlay: "rgba(0, 0, 0, 0.3)",
      parallaxHeightVh: 50,
      heroHeightVh: 100,
      parallaxImgSrc: "",
      parallaxVertAlign: "center",
      process1_arrow_color: "rgba(108, 117, 125, 1)",
      process2_lineColor: "rgba(0, 0, 0, 1)",
      process2_iconColor: "rgba(255, 255, 255, 1)",
      callout_testimonial_bgColor: "rgba(250, 250, 250, 1)",
      callout_testimonial_shadowColor: "rgba(0, 0, 0, 0.3)",
      callout_testimonial_posLeft: 50,
      sdColors: [],
      componentBgColor: "rgba(255, 255, 255, 0)",
      btnText: null,
      carouselFormat: "carousel-slides",
      backToTop_pos: "bottom-right",
      backToTop_sizeBtn: 3,
      backToTop_sizeIcon: 1,
      formDiv_successMsg: "",
      inputfield_name: "text",
      inputfield_required: false,
      inputfield_showLabel: true,
      inputfield_type: "text",
      inputfield_dropdownOptions: JSON.stringify([]),
      formDiv_action: JSON.stringify([]),
      inputfield_placeholderText: "",
      inputfield_placeholderColor: "rgba(108, 117, 125, 1)",
      animationType: "",
      animationDelay: 100,
      animationDuration: 500,
      animationRepeat: "false",
      animationStaggeredDelay: 200,
      width: 25,
      height: 3,
      fontSize: 1,
      inputfield_labelText: "",
      componentBgGradientColor1: "",
      componentBgGradientColor2: "",
      componentBoolBgIsGradient: false,
    };
    let formProperty = id.replace(EDIT_FORM_ID_PREFIX, "");
    if (UNSET_ONLY_ON_HOVER.hasOwnProperty(formProperty)) {
      return UNSET_ONLY_ON_HOVER[formProperty];
    }
    if (formProperty.includes("Hover")) {
      return getFormValue(`${EDIT_FORM_ID_PREFIX}${formProperty.replace("Hover", "")}`);
    }
    if (UNSET_VALUES_ZEROS.includes(formProperty)) {
      return 0;
    }
    if (UNSET_VALUES_BLACK_TRANSPARENT.includes(formProperty)) {
      return "rgba(0, 0, 0, 0)";
    }
    if (UNSET_VALUES_NONE.includes(formProperty)) {
      return "none";
    }
    if (UNSET_VALUES_CUSTOM.hasOwnProperty(formProperty)) {
      return UNSET_VALUES_CUSTOM[formProperty];
    }
    return "";
  } catch (error) {
    return "";
  }
};

const applyChanges = (startStyles, changedStyles) => {
  // Group all changedStyles into categories:
  // - Change selectedElement classes
  // - Change selectedElement attributes
  // - Customs
  // - Change selectedElement css rules (all remaining items that don't fall in the above categories)
  const CHANGE_CLASSES = ["containerWidth", "carouselFormat"];
  const CHANGE_ATTRS = [
    "linkDest",
    "linkTarget",
    "imgSource",
    "imgDesc",
    "elementId",
    "googlemaps",
    "inputfield_name",
    "inputfield_required",
    "formDiv_successMsg",
    "formDiv_action",
  ];
  const ATTRS_TO_CHANGE = {
    linkDest: "data-href",
    linkTarget: "data-target",
    imgSource: "src",
    imgDesc: "alt",
    elementId: "id",
    googlemaps: "src",
    inputfield_name: "name",
    inputfield_required: "data-required",
    formDiv_successMsg: "data-sbformmsg",
    formDiv_action: "data-sbformaction",
  };
  // Need to always keep imgSource & imgDesc, as startStyles is updated when user selects an image from gallery
  const ALWAYS_INCLUDE_ATTRS = ["src", "alt"];
  const NAVBAR_CUSTOMS = [
    // navbar
    "navbarPosition",
    "navbarExpand",
    "navbarSize",
    "navbarBgColor",
    "navbarTransition",
    "navbarBoxShadowHori",
    "navbarBoxShadowVert",
    "navbarBoxShadowBlur",
    "navbarBoxShadowSpread",
    "navbarBoxShadowRgba",
    // scrolledpast
    "navbarScrolledpastBool",
    "navbarScrolledpastSize",
    "navbarScrolledpastBgColor",
    // logo
    "navbarLogoMaxHeight",
    "navbarScrolledpastLogoMaxHeight",
    // toggler
    "navbarTogglerFontSize",
    "navbarTogglerBgColor",
    "navbarTogglerBorderRadius",
    "navbarTogglerColor",
    "navbarTogglerBgColorHover",
    "navbarTogglerColorHover",
    // links
    "navbarLinkColor",
    "navbarLinkColorHover",
    "navbarLinkFontName",
    "navbarLinkFontSize",
    "navbarLinkDeco",
    "navbarLinkBold",
    "navbarLinkData",
  ];
  const CHAMGE_CUSTOMS = [
    "alignRowAlign",
    "alignRowJustify",
    "colMobile",
    "colTablet",
    "colDesktop",
    "widthMobile",
    "widthTablet",
    "widthDesktop",
    "selectedIcon",
    "markerForm",
    "markerBorderColor",
    "markerMarginRight",
    "hero1_overlay",
    "card3_desc_bgColor",
    "card3_desc_bgColorHover",
    "card4_popupHeight",
    "card4_popupHeightHover",
    "card6_size",
    "card6_pos",
    "parallaxHeightVh",
    "heroHeightVh",
    "parallaxImgSrc",
    "process1_arrow_color",
    "process2_lineColor",
    "process2_iconColor",
    "callout_testimonial_bgColor",
    "callout_testimonial_shadowColor",
    "callout_testimonial_posLeft",
    "sdColors",
    "sdSpaceTop",
    "sdSpaceBottom",
    "componentBgColor",
    "componentPaddingTop",
    "componentPaddingBottom",
    "btnText",
    ...NAVBAR_CUSTOMS,
    "carouselOptions",
    "carouselOptionsMain",
    "carouselOptionsThumbnail",
    "carouselNavStyles",
    "carouselPagStyles",
    "backToTop_pos",
    "backToTop_sizeBtn",
    "backToTop_sizeIcon",
    "inputfield_fontSize",
    "formDiv_successMsg",
    "inputfield_type",
    "inputfield_dropdownOptions",
    "inputfield_name",
    "inputfield_showLabel",
    "inputfield_labelText",
    "inputfield_placeholderText",
    "inputfield_placeholderColor",
    "animationType",
    "animationDelay",
    "animationDuration",
    "animationRepeat",
    "animationStaggeredDelay",
    "componentBgColor",
    "componentBoolBgIsGradient",
    "componentBgGradientDirection",
    "componentBgGradientColor1",
    "componentBgGradientColor2",
    "componentBgGradientStop1",
    "componentBgGradientStop2",
  ];
  let changeClasses = changedStyles
    .filter((formProperty) => CHANGE_CLASSES.includes(Object.keys(formProperty)[0]))
    .map((formProperty) => ({
      property: Object.keys(formProperty)[0],
      oldClassname: startStyles[Object.keys(formProperty)[0]],
      newClassname: Object.values(formProperty)[0],
    }))
    .filter((item) => item.oldClassname !== item.newClassname);
  let changeAttrs = changedStyles
    .filter((formProperty) => CHANGE_ATTRS.includes(Object.keys(formProperty)[0]))
    .map((formProperty) => ({
      attr: ATTRS_TO_CHANGE[Object.keys(formProperty)[0]],
      oldVal: startStyles[Object.keys(formProperty)[0]],
      newVal: Object.values(formProperty)[0],
    }))
    .filter((item) => ALWAYS_INCLUDE_ATTRS.includes(item.attr) || item.oldVal !== item.newVal);
  let changeCss = changedStyles.filter(
    (formProperty) =>
      !CHANGE_CLASSES.includes(Object.keys(formProperty)[0]) &&
      !CHANGE_ATTRS.includes(Object.keys(formProperty)[0]) &&
      !CHAMGE_CUSTOMS.includes(Object.keys(formProperty)[0])
  );
  const ALWAYS_INCLUDE_CUSTOMS = [
    "parallaxImgSrc",
    "card6_size",
    "card6_pos",
    "sdColors",
    "markerForm",
    "markerBorderColor",
    "markerMarginRight",
    ...NAVBAR_CUSTOMS,
    "carouselOptions",
    "carouselOptionsMain",
    "carouselOptionsThumbnail",
    "carouselNavStyles",
    "carouselPagStyles",
    "animationType",
    "animationDelay",
    "animationDuration",
    "animationRepeat",
    "animationStaggeredDelay",
    "inputfield_placeholderText",
    "inputfield_type",
    "inputfield_dropdownOptions",
    "componentBgColor",
    "componentBoolBgIsGradient",
    "componentBgGradientDirection",
    "componentBgGradientColor1",
    "componentBgGradientColor2",
    "componentBgGradientStop1",
    "componentBgGradientStop2",
  ]; // Always keep these customs, even if the same as startStyles
  let changeCustoms = changedStyles
    .filter((formProperty) => CHAMGE_CUSTOMS.includes(Object.keys(formProperty)[0]))
    .map((formProperty) => ({
      property: Object.keys(formProperty)[0],
      oldVal: startStyles[Object.keys(formProperty)[0]],
      newVal: Object.values(formProperty)[0],
    }))
    .filter((item) => ALWAYS_INCLUDE_CUSTOMS.includes(item.property) || item.oldVal !== item.newVal);
  // Get updated CSS rules
  let changedStylesObj = {};
  changedStyles.forEach((item) => {
    changedStylesObj = { ...changedStylesObj, [Object.keys(item)[0]]: Object.values(item)[0] };
  });
  changeCss = getUpdatedCssRules(startStyles, changeCss, changedStylesObj);
  // Apply the style changes to the correct target element
  // =====
  // =====
  // console.log("=======================");
  // console.log("=======================");
  // console.log("=======================");
  // Get selected element
  let selectedElement = getState("sb", "selectedElement");
  let splitSelectedElementId = selectedElement.split("-");
  let target = getTargetObj(splitSelectedElementId);
  // console.log(target);
  // Check whether target.data-name is a special component
  const SPECIALS = [
    "component",
    "animate",
    "element_col",
    "statistics2",
    "statistics3a",
    "hero1",
    "card2",
    "card3",
    "card4",
    "card6",
    "card16",
    "card23",
    "parallax",
    // "parallax3_content",
    "process1",
    "process2",
    "callout_testimonial",
    "section_divider",
    "navbar",
    "img_gallery1",
    "img_gallery2",
    "carousel",
    "carousel_thumbnail",
    "backtotop",
    "inputfield",
    "headingline",
    "sectionline",
  ];
  let targetName = target.attributes.filter((attr) => attr.property === "data-name")[0].value;
  if (SPECIALS.includes(targetName)) {
    applyChangesSpecials(target, targetName, changeCss, changeClasses, changeAttrs, changeCustoms);
    // console.log("=======================");
    // console.log("=======================");
    // console.log("=======================");
  } else {
    // Normal component => update just the selectedElement
    splitSelectedElementId = selectedElement.split("-");
    // Update CSS in redux
    changeCss.length > 0 && store.dispatch(updateCss(splitSelectedElementId, changeCss));
    // Update target's classes
    changeClasses.length > 0 && store.dispatch(updateClasses(splitSelectedElementId, changeClasses));
    // Update target's attributes
    changeAttrs.length > 0 && store.dispatch(updateAttributes(splitSelectedElementId, changeAttrs));
    // Update customs
    changeCustoms.length > 0 && store.dispatch(updateCustoms(splitSelectedElementId, changeCustoms));
  }
};

const getUpdatedCssRules = (startStyles, changeCss, changedStylesObj) => {
  // changeCss = [ { marginTop: 2 }, ... ]
  // Group changeCss by css property in format { property: "margin", pseudo: "" }
  let cssPropertyGroups = groupCssProperties(changeCss);
  // For each cssPropertyGroup, get the updated css rule: { property: "margin", pseudo: "", value: "0 0 0.5rem 0" }
  //    Updated css rule is based on the changed styles if available, or elese the starting styles if user didn't make changes
  cssPropertyGroups = cssPropertyGroups.map((cssPropGroup) => getUpdatedCssRule(cssPropGroup, startStyles, changedStylesObj));
  // Return the updated css rules grouped by css property
  return cssPropertyGroups;
};

const groupCssProperties = (changeCss) => {
  // changeCss format: [ { marginTop: 2 }, ... ]
  try {
    // Prepare an array in form of: [ { property: "margin", pseudo: "" } ]
    let cssPropertyGroups = changeCss.map((item) => getCssPropsAndPseudoFromEditForm(Object.keys(item)[0]));
    // Filter duplicates
    return [...new Set(cssPropertyGroups.map((cssProperty) => `${cssProperty.property}%${cssProperty.pseudo}`))].map((item) => ({
      property: item.split("%")[0],
      pseudo: item.split("%")[1] || "",
    }));
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getCssPropsAndPseudoFromEditForm = (editFormProp) => {
  const MAPPING = {
    alignCol: { property: "align-items", pseudo: "" },
    alignRowAlign: { property: "align-items", pseudo: "" },
    alignRowJustify: { property: "justify-content", pseudo: "" },
    parallaxVertAlign: { property: "justify-content", pseudo: "" },
    alignHori: { property: "align-self", pseudo: "" },
    bgColor: { property: "background", pseudo: "" },
    bgColorHover: { property: "background", pseudo: "hover" },
    bgColorFocus: { property: "background", pseudo: "focus" },
    borderSide: { property: "border-style", pseudo: "" },
    borderType: { property: "border-style", pseudo: "" },
    borderWidth: { property: "border-width", pseudo: "" },
    borderColor: { property: "border-color", pseudo: "" },
    borderColorHover: { property: "border-color", pseudo: "hover" },
    borderColorFocus: { property: "border-color", pseudo: "focus" },
    borderRadius: { property: "border-radius", pseudo: "" },
    boxShadowHori: { property: "box-shadow", pseudo: "" },
    boxShadowVert: { property: "box-shadow", pseudo: "" },
    boxShadowBlur: { property: "box-shadow", pseudo: "" },
    boxShadowSpread: { property: "box-shadow", pseudo: "" },
    boxShadowRgba: { property: "box-shadow", pseudo: "" },
    boxShadowHoriHover: { property: "box-shadow", pseudo: "hover" },
    boxShadowVertHover: { property: "box-shadow", pseudo: "hover" },
    boxShadowBlurHover: { property: "box-shadow", pseudo: "hover" },
    boxShadowSpreadHover: { property: "box-shadow", pseudo: "hover" },
    boxShadowRgbaHover: { property: "box-shadow", pseudo: "hover" },
    boxShadowHoriFocus: { property: "box-shadow", pseudo: "focus" },
    boxShadowVertFocus: { property: "box-shadow", pseudo: "focus" },
    boxShadowBlurFocus: { property: "box-shadow", pseudo: "focus" },
    boxShadowSpreadFocus: { property: "box-shadow", pseudo: "focus" },
    boxShadowRgbaFocus: { property: "box-shadow", pseudo: "focus" },
    textShadowHori: { property: "text-shadow", pseudo: "" },
    textShadowVert: { property: "text-shadow", pseudo: "" },
    textShadowBlur: { property: "text-shadow", pseudo: "" },
    textShadowRgba: { property: "text-shadow", pseudo: "" },
    textShadowHoriHover: { property: "text-shadow", pseudo: "hover" },
    textShadowVertHover: { property: "text-shadow", pseudo: "hover" },
    textShadowBlurHover: { property: "text-shadow", pseudo: "hover" },
    textShadowRgbaHover: { property: "text-shadow", pseudo: "hover" },
    linkDeco: { property: "text-decoration", pseudo: "" },
    linkDecoHover: { property: "text-decoration", pseudo: "hover" },
    paddingTop: { property: "padding", pseudo: "" },
    paddingRight: { property: "padding", pseudo: "" },
    paddingBottom: { property: "padding", pseudo: "" },
    paddingLeft: { property: "padding", pseudo: "" },
    marginTop: { property: "margin", pseudo: "" },
    marginRight: { property: "margin", pseudo: "" },
    marginBottom: { property: "margin", pseudo: "" },
    marginLeft: { property: "margin", pseudo: "" },
    display: { property: "display", pseudo: "" },
    imgOpacity: { property: "opacity", pseudo: "" },
    imgOpacityHover: { property: "opacity", pseudo: "hover" },
    imgBlur: { property: "filter", pseudo: "" },
    imgBrightness: { property: "filter", pseudo: "" },
    imgContrast: { property: "filter", pseudo: "" },
    imgGrayscale: { property: "filter", pseudo: "" },
    imgHueRotate: { property: "filter", pseudo: "" },
    imgInvert: { property: "filter", pseudo: "" },
    imgSaturate: { property: "filter", pseudo: "" },
    imgSepia: { property: "filter", pseudo: "" },
    imgBlurHover: { property: "filter", pseudo: "hover" },
    imgBrightnessHover: { property: "filter", pseudo: "hover" },
    imgContrastHover: { property: "filter", pseudo: "hover" },
    imgGrayscaleHover: { property: "filter", pseudo: "hover" },
    imgHueRotateHover: { property: "filter", pseudo: "hover" },
    imgInvertHover: { property: "filter", pseudo: "hover" },
    imgSaturateHover: { property: "filter", pseudo: "hover" },
    imgSepiaHover: { property: "filter", pseudo: "hover" },
    imgTransformHover: { property: "transform", pseudo: "hover" },
    imgResizeWidth: { property: "width", pseudo: "" },
    imgResizeHeight: { property: "height", pseudo: "" },
    listMarkerStyle: { property: "list-style", pseudo: "" },
    listMarkerColor: { property: "color", pseudo: "marker" },
    btnIsBold: { property: "font-weight", pseudo: "" },
    btnIsItalic: { property: "font-style", pseudo: "" },
    btnIsUnderlined: { property: "text-decoration", pseudo: "" },
    btnFullWidth: { property: "width", pseudo: "" },
    textColor: { property: "color", pseudo: "" },
    textColorHover: { property: "color", pseudo: "hover" },
    textColorFocus: { property: "color", pseudo: "focus" },
    transition: { property: "transition", pseudo: "" },
    overlayColor: { property: "background", pseudo: "" },
    card23_overlayGradient: { property: "background", pseudo: "" },
    sdSpaceTop: { property: "padding-top", pseudo: "" },
    sdSpaceBottom: { property: "padding-bottom", pseudo: "" },
    width: { property: "width", pseudo: "" },
    height: { property: "height", pseudo: "" },
    fontSize: { property: "font-size", pseudo: "" },
  };
  return MAPPING[editFormProp] || { property: "", pseudo: "" };
};

const getUpdatedCssRule = (cssPropGroup, startStyles, changedStylesObj) => {
  try {
    let { property, pseudo } = cssPropGroup;
    // cssPropGroup format: { property: "margin", pseudo: "" }
    if (property === "align-items" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.alignCol, startStyles.alignCol) };
    }
    if (property === "align-items" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.alignRowAlign, startStyles.alignRowAlign) };
    }
    if (property === "justify-content" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.parallaxVertAlign, startStyles.parallaxVertAlign) };
    }
    if (property === "align-self" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.alignHori, startStyles.alignHori) };
    }
    if (property === "background" && pseudo === "") {
      // Can either be bgColor, overlayColor or card23_overlayGradient
      let val = getUpdCss(changedStylesObj.bgColor, startStyles.bgColor);
      if (typeof val === "undefined") {
        val = getUpdCss(changedStylesObj.overlayColor, startStyles.overlayColor);
      }
      if (typeof val === "undefined") {
        let rgba = ensureRgba_values(
          typeof changedStylesObj.card23_overlayGradient === "undefined"
            ? startStyles.card23_overlayGradient
            : changedStylesObj.card23_overlayGradient
        );
        val = `linear-gradient(rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${(rgba[3] / 3).toFixed(2)}), rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${
          rgba[3]
        }))`;
        // val = getUpdCss(changedStylesObj.card23_overlayGradient, startStyles.card23_overlayGradient);
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "background" && pseudo === "hover") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.bgColorHover, startStyles.bgColorHover) };
    }
    if (property === "background" && pseudo === "focus") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.bgColorFocus, startStyles.bgColorFocus) };
    }
    if (property === "border-style" && pseudo === "") {
      let borderSide = getUpdCss(changedStylesObj.borderSide, startStyles.borderSide);
      let borderType = getUpdCss(changedStylesObj.borderType, startStyles.borderType);
      let val = "none none none none";
      if (borderSide === "all") {
        val = `${borderType} ${borderType} ${borderType} ${borderType}`;
      }
      if (borderSide === "left") {
        val = `none none none ${borderType}`;
      }
      if (borderSide === "right") {
        val = `none ${borderType} none none`;
      }
      if (borderSide === "top") {
        val = `${borderType} none none none`;
      }
      if (borderSide === "bottom") {
        val = `none none ${borderType} none`;
      }
      if (borderSide === "topbottom") {
        val = `${borderType} none ${borderType} none`;
      }
      if (borderSide === "leftright") {
        val = `none ${borderType} none ${borderType}`;
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "border-width" && pseudo === "") {
      return { ...cssPropGroup, value: `${getUpdCss(changedStylesObj.borderWidth, startStyles.borderWidth)}px` };
    }
    if (property === "border-color" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.borderColor, startStyles.borderColor) };
    }
    if (property === "border-color" && pseudo === "hover") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.borderColorHover, startStyles.borderColorHover) };
    }
    if (property === "border-color" && pseudo === "focus") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.borderColorFocus, startStyles.borderColorFocus) };
    }
    if (property === "border-radius" && pseudo === "") {
      let val = getUpdCss(changedStylesObj.borderRadius, startStyles.borderRadius);
      if (val < 50) {
        return { ...cssPropGroup, value: `${val}rem` };
      }
      if (val < 100) {
        return { ...cssPropGroup, value: `${val}%` };
      }
      return { ...cssPropGroup, value: `${val}px` };
    }
    if (property === "box-shadow" && pseudo === "") {
      return {
        ...cssPropGroup,
        value: `${getUpdCss(changedStylesObj.boxShadowHori, startStyles.boxShadowHori)}px ${getUpdCss(
          changedStylesObj.boxShadowVert,
          startStyles.boxShadowVert
        )}px ${getUpdCss(changedStylesObj.boxShadowBlur, startStyles.boxShadowBlur)}px ${getUpdCss(
          changedStylesObj.boxShadowSpread,
          startStyles.boxShadowSpread
        )}px ${getUpdCss(changedStylesObj.boxShadowRgba, startStyles.boxShadowRgba)}`,
      };
    }
    if (property === "box-shadow" && pseudo === "hover") {
      return {
        ...cssPropGroup,
        value: `${getUpdCss(changedStylesObj.boxShadowHoriHover, startStyles.boxShadowHoriHover)}px ${getUpdCss(
          changedStylesObj.boxShadowVertHover,
          startStyles.boxShadowVertHover
        )}px ${getUpdCss(changedStylesObj.boxShadowBlurHover, startStyles.boxShadowBlurHover)}px ${getUpdCss(
          changedStylesObj.boxShadowSpreadHover,
          startStyles.boxShadowSpreadHover
        )}px ${getUpdCss(changedStylesObj.boxShadowRgbaHover, startStyles.boxShadowRgbaHover)}`,
      };
    }
    if (property === "box-shadow" && pseudo === "focus") {
      return {
        ...cssPropGroup,
        value: `${getUpdCss(changedStylesObj.boxShadowHoriFocus, startStyles.boxShadowHoriFocus)}px ${getUpdCss(
          changedStylesObj.boxShadowVertFocus,
          startStyles.boxShadowVertFocus
        )}px ${getUpdCss(changedStylesObj.boxShadowBlurFocus, startStyles.boxShadowBlurFocus)}px ${getUpdCss(
          changedStylesObj.boxShadowSpreadFocus,
          startStyles.boxShadowSpreadFocus
        )}px ${getUpdCss(changedStylesObj.boxShadowRgbaFocus, startStyles.boxShadowRgbaFocus)}`,
      };
    }
    if (property === "text-shadow" && pseudo === "") {
      return {
        ...cssPropGroup,
        value: `${getUpdCss(changedStylesObj.textShadowHori, startStyles.textShadowHori)}px ${getUpdCss(
          changedStylesObj.textShadowVert,
          startStyles.textShadowVert
        )}px ${getUpdCss(changedStylesObj.textShadowBlur, startStyles.textShadowBlur)}px ${getUpdCss(
          changedStylesObj.textShadowRgba,
          startStyles.textShadowRgba
        )}`,
      };
    }
    if (property === "text-shadow" && pseudo === "hover") {
      return {
        ...cssPropGroup,
        value: `${getUpdCss(changedStylesObj.textShadowHoriHover, startStyles.textShadowHoriHover)}px ${getUpdCss(
          changedStylesObj.textShadowVertHover,
          startStyles.textShadowVertHover
        )}px ${getUpdCss(changedStylesObj.textShadowBlurHover, startStyles.textShadowBlurHover)}px ${getUpdCss(
          changedStylesObj.textShadowRgbaHover,
          startStyles.textShadowRgbaHover
        )}`,
      };
    }
    if (property === "text-decoration" && pseudo === "") {
      // Can either be linkDeco or btnIsUnderlined
      let val = getUpdCss(changedStylesObj.linkDeco, startStyles.linkDeco);
      if (typeof val === "undefined") {
        val = getUpdCss(changedStylesObj.btnIsUnderlined, startStyles.btnIsUnderlined) ? "underline" : "none";
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "text-decoration" && pseudo === "hover") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.linkDecoHover, startStyles.linkDecoHover) };
    }
    if (property === "padding" && pseudo === "") {
      return {
        ...cssPropGroup,
        value: `${getUpdCss(changedStylesObj.paddingTop, startStyles.paddingTop)}rem ${getUpdCss(
          changedStylesObj.paddingRight,
          startStyles.paddingRight
        )}rem ${getUpdCss(changedStylesObj.paddingBottom, startStyles.paddingBottom)}rem ${getUpdCss(
          changedStylesObj.paddingLeft,
          startStyles.paddingLeft
        )}rem`,
      };
    }
    if (property === "margin" && pseudo === "") {
      return {
        ...cssPropGroup,
        value: `${getUpdCss(changedStylesObj.marginTop, startStyles.marginTop)}rem ${getUpdCss(
          changedStylesObj.marginRight,
          startStyles.marginRight
        )}rem ${getUpdCss(changedStylesObj.marginBottom, startStyles.marginBottom)}rem ${getUpdCss(
          changedStylesObj.marginLeft,
          startStyles.marginLeft
        )}rem`,
      };
    }
    if (property === "display" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.display, startStyles.display) };
    }
    if (property === "opacity" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.imgOpacity, startStyles.imgOpacity) };
    }
    if (property === "opacity" && pseudo === "hover") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.imgOpacityHover, startStyles.imgOpacityHover) };
    }
    if (property === "filter" && pseudo === "") {
      return {
        ...cssPropGroup,
        value: `brightness(${getUpdCss(changedStylesObj.imgBrightness, startStyles.imgBrightness)}%) blur(${getUpdCss(
          changedStylesObj.imgBlur,
          startStyles.imgBlur
        )}px) contrast(${getUpdCss(changedStylesObj.imgContrast, startStyles.imgContrast)}%) grayscale(${getUpdCss(
          changedStylesObj.imgGrayscale,
          startStyles.imgGrayscale
        )}%) sepia(${getUpdCss(changedStylesObj.imgSepia, startStyles.imgSepia)}%) saturate(${getUpdCss(
          changedStylesObj.imgSaturate,
          startStyles.imgSaturate
        )}%) hue-rotate(${getUpdCss(changedStylesObj.imgHueRotate, startStyles.imgHueRotate)}deg) invert(${getUpdCss(
          changedStylesObj.imgInvert,
          startStyles.imgInvert
        )}%)`,
      };
    }
    if (property === "filter" && pseudo === "hover") {
      return {
        ...cssPropGroup,
        value: `brightness(${getUpdCss(changedStylesObj.imgBrightnessHover, startStyles.imgBrightnessHover)}%) blur(${getUpdCss(
          changedStylesObj.imgBlurHover,
          startStyles.imgBlurHover
        )}px) contrast(${getUpdCss(changedStylesObj.imgContrastHover, startStyles.imgContrastHover)}%) grayscale(${getUpdCss(
          changedStylesObj.imgGrayscaleHover,
          startStyles.imgGrayscaleHover
        )}%) sepia(${getUpdCss(changedStylesObj.imgSepiaHover, startStyles.imgSepiaHover)}%) saturate(${getUpdCss(
          changedStylesObj.imgSaturateHover,
          startStyles.imgSaturateHover
        )}%) hue-rotate(${getUpdCss(changedStylesObj.imgHueRotateHover, startStyles.imgHueRotateHover)}deg) invert(${getUpdCss(
          changedStylesObj.imgInvertHover,
          startStyles.imgInvertHover
        )}%)`,
      };
    }
    if (property === "transform" && pseudo === "hover") {
      return { ...cssPropGroup, value: `scale(${getUpdCss(changedStylesObj.imgTransformHover, startStyles.imgTransformHover) / 100})` };
    }
    if (property === "width" && pseudo === "") {
      // Can either imgResizeWidth, width or btnFullWidth
      let val = "";
      if (typeof changedStylesObj.imgResizeWidth !== "undefined" && typeof startStyles.imgResizeWidth !== "undefined") {
        val = changedStylesObj.imgResizeWidth === 0 ? "auto" : `${getUpdCss(changedStylesObj.imgResizeWidth, startStyles.imgResizeWidth)}px`;
      }
      if (typeof changedStylesObj.width !== "undefined" && typeof startStyles.width !== "undefined") {
        val = `${getUpdCss(changedStylesObj.width, startStyles.width)}px`;
      }
      if (typeof changedStylesObj.btnFullWidth !== "undefined" && typeof startStyles.btnFullWidth !== "undefined") {
        val = getUpdCss(changedStylesObj.btnFullWidth, startStyles.btnFullWidth) ? "100%" : "auto";
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "height" && pseudo === "") {
      // Can be either imgResizeHeight or height
      let val = "";
      if (typeof changedStylesObj.height !== "undefined" && typeof startStyles.height !== "undefined") {
        val = `${getUpdCss(changedStylesObj.height, startStyles.height)}px`;
      }
      if (typeof changedStylesObj.imgResizeHeight !== "undefined" && typeof startStyles.imgResizeHeight !== "undefined") {
        val = changedStylesObj.imgResizeHeight === 0 ? "auto" : `${getUpdCss(changedStylesObj.imgResizeHeight, startStyles.imgResizeHeight)}px`;
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "list-style" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.listMarkerStyle, startStyles.listMarkerStyle) };
    }
    if (property === "font-weight" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.btnIsBold, startStyles.btnIsBold) ? "bold" : "normal" };
    }
    if (property === "font-style" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.btnIsItalic, startStyles.btnIsItalic) ? "italic" : "normal" };
    }
    if (property === "font-size" && pseudo === "") {
      return { ...cssPropGroup, value: `${getUpdCss(changedStylesObj.fontSize, startStyles.fontSize)}rem` };
    }
    if (property === "color" && pseudo === "") {
      // Can either be textColor or listMarkerColor
      let val = getUpdCss(changedStylesObj.textColor, startStyles.textColor);
      if (typeof val === "undefined") {
        val = getUpdCss(changedStylesObj.listMarkerColor, startStyles.listMarkerColor);
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "color" && pseudo === "hover") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.textColorHover, startStyles.textColorHover) };
    }
    if (property === "color" && pseudo === "focus") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.textColorFocus, startStyles.textColorFocus) };
    }
    if (property === "transition" && pseudo === "") {
      return { ...cssPropGroup, value: `all ${getUpdCss(changedStylesObj.transition, startStyles.transition)}s ease-in-out` };
    }
  } catch (error) {
    console.error(error);
    return { ...cssPropGroup, value: "" };
  }
};

const getUpdCss = (changedStyle, startStyle) => {
  return typeof changedStyle === "undefined" ? startStyle : changedStyle;
};

// ===============================================
// == Apply style changes on special components ==
// ===============================================

const applyChangesSpecials = (target, targetName, changeCss, changeClasses, changeAttrs, changeCustoms) => {
  // console.log("changeCss");
  // console.log(changeCss);
  // console.log("changeClasses");
  // console.log(changeClasses);
  // console.log("changeAttrs");
  // console.log(changeAttrs);
  // console.log("changeCustoms");
  // console.log(changeCustoms);
  // Get the changes to apply to each element in format [ { splitElementId: [], css: [], classes: [], attrs: [], customs: [] } ]
  let elementsAndChanges = [];
  if (targetName === "component") {
    // Component styles
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_CLASSES = ["containerWidth"];
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = [
      "componentBgColor",
      "componentBoolBgIsGradient",
      "componentBgGradientDirection",
      "componentBgGradientColor1",
      "componentBgGradientColor2",
      "componentBgGradientStop1",
      "componentBgGradientStop2",
      "componentPaddingTop",
      "componentPaddingBottom",
    ];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: [],
      classes: changeClasses.filter((item) => TARGET_CLASSES.includes(item.property)),
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    // Animate settings
    const ANIMATE_CUSTOMS = ["animationType", "animationDelay", "animationDuration", "animationRepeat", "animationStaggeredDelay"];
    let animateElements = getAnimateElements(target);
    animateElements.forEach((animateElement) => {
      elementsAndChanges.push({
        splitElementId: animateElements.length === 1 ? selectedElement.split("-") : getConcatElementId(animateElement.childId).split("-"),
        css: [],
        classes: [],
        attrs: [],
        customs: changeCustoms.filter((item) => ANIMATE_CUSTOMS.includes(item.property)),
      });
    });
  }
  if (targetName === "element_col" || targetName === "statistics3a") {
    // Get elementalignmentwrapper
    let alignmentWrapper = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    // If alignmentWrapper exists, add specific styles to that wrapper. If not, put it all on the element_col
    const WRAPPER_CSS_PROPS =
      typeof alignmentWrapper !== "undefined"
        ? ["border-style", "border-width", "border-color", "border-radius", "box-shadow", "background", "transition", "align-items"]
        : [];
    if (typeof alignmentWrapper !== "undefined") {
      elementsAndChanges.push({
        splitElementId: getConcatElementId(alignmentWrapper.childId).split("-"),
        css: changeCss.filter((item) => WRAPPER_CSS_PROPS.includes(item.property)),
        classes: [],
        attrs: [],
        customs: [],
      });
    }
    // Keep everything on the element_col except, if needed, the css styles and .col-[x] customs to be added on the alignmentWrapper
    let selectedElement = getState("sb", "selectedElement");
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => !WRAPPER_CSS_PROPS.includes(item.property)),
      classes: changeClasses,
      attrs: changeAttrs,
      customs: changeCustoms,
    });
  }
  if (targetName === "statistics2") {
    let selectedElement = getState("sb", "selectedElement");
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss,
      classes: changeClasses,
      attrs: changeAttrs,
      customs: changeCustoms,
    });
  }
  if (targetName === "hero1") {
    // Get all related elements
    let hero1Wrapper = getTargetParent(target.childId);
    let hero1Img = getFirstChildByTagname(hero1Wrapper, "img");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["hero1_overlay"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: [],
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const IMG_ATTRS = ["src"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(hero1Img.childId).split("-"),
      css: [],
      classes: [],
      attrs: changeAttrs.filter((item) => IMG_ATTRS.includes(item.attr)),
      customs: [],
    });
    const WRAPPER_CUSTOMS = ["heroHeightVh"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(hero1Wrapper.childId).split("-"),
      css: [],
      classes: [],
      attrs: [],
      customs: changeCustoms.filter((item) => WRAPPER_CUSTOMS.includes(item.property)),
    });
  }
  if (targetName === "card2") {
    // Get all related elements
    let overlayWrapper = getFirstChildWithAttr(target, "data-overlaywrapper", "true");
    let overlayContent = getFirstChildWithAttr(target, "data-overlaycontent", "true");
    let img = getFirstChildByTagname(overlayWrapper, "img");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_CSS_PROPS = ["margin", "padding"];
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["alignRowAlign", "alignRowJustify", "colMobile", "colTablet", "colDesktop"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const WRAPPER_CSS_PROPS = ["border-style", "border-width", "border-color", "border-radius", "width", "height", "box-shadow", "transition"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(overlayWrapper.childId).split("-"),
      css: changeCss.filter((item) => WRAPPER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    const CONTENT_CSS_PROPS = ["background", "align-items", "transition"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(overlayContent.childId).split("-"),
      css: changeCss.filter((item) => CONTENT_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    const IMG_ATTRS = ["src", "alt"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(img.childId).split("-"),
      css: [],
      classes: [],
      attrs: changeAttrs.filter((item) => IMG_ATTRS.includes(item.attr)),
      customs: [],
    });
  }
  if (targetName === "card3") {
    // Get all related elements
    let overlayWrapper = getFirstChildWithAttr(target, "data-overlaywrapper", "true");
    let descWrapper = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    let overlayContent = getFirstChildWithAttr(target, "data-overlaycontent", "true");
    let img = getFirstChildByTagname(overlayWrapper, "img");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_CSS_PROPS = ["margin", "padding"];
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["alignRowAlign", "alignRowJustify", "colMobile", "colTablet", "colDesktop"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const WRAPPER_CSS_PROPS = ["border-style", "border-width", "border-color", "border-radius", "box-shadow", "transition"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(overlayWrapper.childId).split("-"),
      css: changeCss.filter((item) => WRAPPER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    const CONTENT_CSS_PROPS = ["background", "align-items", "transition"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(overlayContent.childId).split("-"),
      css: changeCss.filter((item) => CONTENT_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    const DESC_CSS_PROPS = ["transition"];
    const DESC_CUSTOMS = ["card3_desc_bgColor", "card3_desc_bgColorHover"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(descWrapper.childId).split("-"),
      css: changeCss.filter((item) => DESC_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: changeCustoms.filter((item) => DESC_CUSTOMS.includes(item.property)),
    });
    const IMG_ATTRS = ["src", "alt"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(img.childId).split("-"),
      css: [],
      classes: [],
      attrs: changeAttrs.filter((item) => IMG_ATTRS.includes(item.attr)),
      customs: [],
    });
  }
  if (targetName === "card4") {
    // Get all related elements
    let overlayWrapper = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    let overlayContent = getFirstChildWithAttr(target, "data-overlaycontent", "true");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_CSS_PROPS = ["margin", "padding"];
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["alignRowAlign", "alignRowJustify", "colMobile", "colTablet", "colDesktop"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const WRAPPER_CSS_PROPS = ["border-style", "border-width", "border-color", "border-radius", "box-shadow", "transition"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(overlayWrapper.childId).split("-"),
      css: changeCss.filter((item) => WRAPPER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    const CONTENT_CSS_PROPS = ["background", "align-items", "transition"];
    const CONTENT_CUSTOMS = ["card4_popupHeight", "card4_popupHeightHover"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(overlayContent.childId).split("-"),
      css: changeCss.filter((item) => CONTENT_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: changeCustoms.filter((item) => CONTENT_CUSTOMS.includes(item.property)),
    });
  }
  if (targetName === "card6") {
    // Get all related elements
    let cardWrapper = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    let cardBorder = getFirstChildWithAttr(target, "data-elementgetter2", "true");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_CSS_PROPS = ["margin", "padding"];
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["alignRowAlign", "alignRowJustify", "colMobile", "colTablet", "colDesktop"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const WRAPPER_CSS_PROPS = ["border-style", "border-width", "border-color", "border-radius", "box-shadow", "transition"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(cardWrapper.childId).split("-"),
      css: changeCss.filter((item) => WRAPPER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    const CARD_BORDER_CSS_PROPS = ["background"];
    const CARD_BORDER_CUSTOMS = ["card6_size", "card6_pos"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(cardBorder.childId).split("-"),
      css: changeCss.filter((item) => CARD_BORDER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: changeCustoms.filter((item) => CARD_BORDER_CUSTOMS.includes(item.property)),
    });
  }
  if (targetName === "card16") {
    // Get all related elements
    let cardElement = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    // For each related element keep only those style updates that should be applied to that element
    const TARGET_ATTRS = ["id"];
    const TARGET_CSS_PROPS = ["background", "padding", "border-style", "border-width", "border-color", "border-radius", "box-shadow", "transition"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(cardElement.childId).split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: [],
    });
  }
  if (targetName === "card23") {
    // Get all related elements
    let overlayWrapper = getFirstChildWithAttr(target, "data-overlaywrapper", "true");
    let overlayContent = getFirstChildWithAttr(target, "data-overlaycontent", "true");
    let img = getFirstChildByTagname(overlayWrapper, "img");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_CSS_PROPS = ["margin", "padding"];
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["alignRowAlign", "alignRowJustify", "colMobile", "colTablet", "colDesktop"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const WRAPPER_CSS_PROPS = ["border-style", "border-width", "border-color", "border-radius", "box-shadow", "transition"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(overlayWrapper.childId).split("-"),
      css: changeCss.filter((item) => WRAPPER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    const CONTENT_CSS_PROPS = ["background", "align-items", "transition"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(overlayContent.childId).split("-"),
      css: changeCss.filter((item) => CONTENT_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    const IMG_ATTRS = ["src", "alt"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(img.childId).split("-"),
      css: [],
      classes: [],
      attrs: changeAttrs.filter((item) => IMG_ATTRS.includes(item.attr)),
      customs: [],
    });
  }
  if (targetName === "parallax") {
    // Get all related elements
    let parallaxContent = getFirstChildWithAttr(target, "data-parallaxcontent", "true");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["parallaxHeightVh", "parallaxImgSrc"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: [],
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const CONTENT_CSS_PROPS = ["background", "justify-content"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(parallaxContent.childId).split("-"),
      css: changeCss.filter((item) => CONTENT_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
  }
  if (targetName === "process1") {
    // Get all related elements
    let alignmentWrapper = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    // const TARGET_CSS_PROPS = ["margin", "padding"];
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["alignRowAlign", "alignRowJustify", "colMobile", "colTablet", "colDesktop", "process1_arrow_color"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: [],
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const WRAPPER_CSS_PROPS = ["align-items"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(alignmentWrapper.childId).split("-"),
      css: changeCss.filter((item) => WRAPPER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
  }
  if (targetName === "process2") {
    // Get all related elements
    let alignmentWrapper = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["alignRowAlign", "alignRowJustify", "colMobile", "colTablet", "colDesktop", "process2_lineColor", "process2_iconColor"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: [],
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const WRAPPER_CSS_PROPS = ["align-items"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(alignmentWrapper.childId).split("-"),
      css: changeCss.filter((item) => WRAPPER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
  }
  if (targetName === "callout_testimonial") {
    // Get all related elements
    let calloutDiv = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_CSS_PROPS = ["margin", "padding"];
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["alignRowAlign", "alignRowJustify", "colMobile", "colTablet", "colDesktop"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const CALLOUT_CSS_PROPS = ["border-radius"];
    const CALLOUT_CUSTOMS = ["callout_testimonial_bgColor", "callout_testimonial_shadowColor", "callout_testimonial_posLeft"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(calloutDiv.childId).split("-"),
      css: changeCss.filter((item) => CALLOUT_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: changeCustoms.filter((item) => CALLOUT_CUSTOMS.includes(item.property)),
    });
  }
  if (targetName === "section_divider") {
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["sdColors"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: [],
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    let componentBefore = getComponentRelativeToCurrent(selectedElement.split("-")[0], -1);
    if (componentBefore !== null) {
      const COMPONENT_BEFORE_CUSTOMS = ["sdSpaceTop"];
      elementsAndChanges.push({
        splitElementId: [componentBefore.componentId],
        css: [],
        classes: [],
        attrs: [],
        customs: changeCustoms.filter((item) => COMPONENT_BEFORE_CUSTOMS.includes(item.property)),
      });
    }
    let componentAfter = getComponentRelativeToCurrent(selectedElement.split("-")[0], 1);
    if (componentAfter !== null) {
      const COMPONENT_AFTER_CUSTOMS = ["sdSpaceBottom"];
      elementsAndChanges.push({
        splitElementId: [componentAfter.componentId],
        css: [],
        classes: [],
        attrs: [],
        customs: changeCustoms.filter((item) => COMPONENT_AFTER_CUSTOMS.includes(item.property)),
      });
    }
  }
  if (targetName === "navbar") {
    // Navbar
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["navbarPosition", "navbarScrolledpastBool", "navbarScrolledpastSize", "navbarScrolledpastBgColor"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: getNavbarCssRules(changeCustoms),
      classes: getNavbarExpandClassnames(changeCustoms, "navbar"),
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    // Logo img
    let navbarLogoImg = getFirstChildByTagname(target, "img");
    const LOGO_IMG_CUSTOMS = ["navbarScrolledpastLogoMaxHeight"];
    const LOGO_IMG_ATTRS = ["src", "alt"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(navbarLogoImg.childId).split("-"),
      css: getNavbarLogoImgCssRules(changeCustoms),
      classes: [],
      attrs: changeAttrs.filter((item) => LOGO_IMG_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => LOGO_IMG_CUSTOMS.includes(item.property)),
    });
    // Toggler
    let navbarToggler = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    const TOGGLER_CSS_PROPS = ["border-style", "border-width", "border-color"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(navbarToggler.childId).split("-"),
      css: [...changeCss.filter((item) => TOGGLER_CSS_PROPS.includes(item.property)), ...getNavbarTogglerCssRules(changeCustoms)],
      classes: getNavbarExpandClassnames(changeCustoms, "toggler"),
      attrs: [],
      customs: [],
    });
    // Link tag
    let navbarLinkWrapper = getFirstChildWithAttr(target, "data-elementgetter2", "true");
    let navbarLinkTag = getFirstChildByTagname(navbarLinkWrapper, "a");
    elementsAndChanges.push({
      splitElementId: getConcatElementId(navbarLinkTag.childId).split("-"),
      css: getNavbarLinkCssRules(changeCustoms),
      classes: [],
      attrs: [],
      customs: [],
    });
    // Link data
    elementsAndChanges.push({
      splitElementId: getConcatElementId(navbarLinkWrapper.childId).split("-"),
      css: [],
      classes: [],
      attrs: [],
      customs: getNavbarLinkData(changeCustoms, getTargetCustomClassname(navbarLinkTag.classes, getCustomCss(selectedElement.split("-")[0]))),
    });
    // Animate settings
    const ANIMATE_CUSTOMS = ["animationType", "animationDelay", "animationDuration", "animationRepeat", "animationStaggeredDelay"];
    let animateElements = getAnimateElements(target);
    animateElements.forEach((animateElement) => {
      elementsAndChanges.push({
        splitElementId: animateElements.length === 1 ? selectedElement.split("-") : getConcatElementId(animateElement.childId).split("-"),
        css: [],
        classes: [],
        attrs: [],
        customs: changeCustoms.filter((item) => ANIMATE_CUSTOMS.includes(item.property)),
      });
    });
  }
  if (targetName === "img_gallery1") {
    // Get all related elements
    let overlayWrapper = getFirstChildWithAttr(target, "data-overlaywrapper", "true");
    let overlayContent = getFirstChildWithAttr(target, "data-overlaycontent", "true");
    let img = getFirstChildByTagname(target, "img");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["colMobile", "colTablet", "colDesktop"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: [],
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const WRAPPER_CSS_PROPS = ["margin", "border-style", "border-width", "border-color", "border-radius", "box-shadow", "transition"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(overlayWrapper.childId).split("-"),
      css: changeCss.filter((item) => WRAPPER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    const CONTENT_CSS_PROPS = ["background", "transition"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(overlayContent.childId).split("-"),
      css: changeCss.filter((item) => CONTENT_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    const IMG_ATTRS = ["src", "alt"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(img.childId).split("-"),
      css: [],
      classes: [],
      attrs: changeAttrs.filter((item) => IMG_ATTRS.includes(item.attr)),
      customs: [],
    });
  }
  if (targetName === "img_gallery2") {
    // Get all related elements
    let img = getFirstChildByTagname(target, "img");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_CSS_PROPS = ["border-style", "border-width", "border-color", "border-radius", "box-shadow", "transition"];
    const TARGET_ATTRS = ["id"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: [],
    });
    const IMG_CSS_PROPS = ["opacity", "filter", "transform", "transition"];
    const IMG_ATTRS = ["src", "alt"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(img.childId).split("-"),
      css: changeCss.filter((item) => IMG_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => IMG_ATTRS.includes(item.attr)),
      customs: [],
    });
  }
  if (targetName === "carousel") {
    // target is the sbtype=component. Also get the carousel
    let carousel = getFirstChildByClassname(target, "splide");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_CLASSES = ["containerWidth"];
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = [
      "componentBgColor",
      "componentBoolBgIsGradient",
      "componentBgGradientDirection",
      "componentBgGradientColor1",
      "componentBgGradientColor2",
      "componentBgGradientStop1",
      "componentBgGradientStop2",
      "componentPaddingTop",
      "componentPaddingBottom",
    ];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: [],
      classes: changeClasses.filter((item) => TARGET_CLASSES.includes(item.property)),
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const CAROUSEL_CSS_PROPS = [""];
    const CAROUSEL_CLASSES = ["carouselFormat"];
    const CAROUSEL_ATTRS = [""];
    const CAROUSEL_CUSTOMS = ["carouselOptions", "carouselNavStyles", "carouselPagStyles"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(carousel.childId).split("-"),
      css: changeCss.filter((item) => CAROUSEL_CSS_PROPS.includes(item.property)),
      classes: changeClasses.filter((item) => CAROUSEL_CLASSES.includes(item.property)),
      attrs: changeAttrs.filter((item) => CAROUSEL_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => CAROUSEL_CUSTOMS.includes(item.property)),
    });
  }
  if (targetName === "carousel_thumbnail") {
    // target is the sbtype=component. Also get the carousel
    let carouselMain = getFirstChildByClassname(target, "carousel-thumbnail-main");
    let carouselThumbnail = getFirstChildByClassname(target, "carousel-thumbnail-thumbnail");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_CLASSES = ["containerWidth"];
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = [
      "componentBgColor",
      "componentBoolBgIsGradient",
      "componentBgGradientDirection",
      "componentBgGradientColor1",
      "componentBgGradientColor2",
      "componentBgGradientStop1",
      "componentBgGradientStop2",
      "componentPaddingTop",
      "componentPaddingBottom",
    ];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: [],
      classes: changeClasses.filter((item) => TARGET_CLASSES.includes(item.property)),
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const CAROUSEL_THUMBNAIL_CUSTOMS = ["carouselOptionsThumbnail", "carouselNavStyles"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(carouselThumbnail.childId).split("-"),
      css: [],
      classes: [],
      attrs: [],
      customs: changeCustoms.filter((item) => CAROUSEL_THUMBNAIL_CUSTOMS.includes(item.property)),
    });
    const CAROUSEL_MAIN_CUSTOMS = ["carouselOptionsMain"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(carouselMain.childId).split("-"),
      css: [],
      classes: [],
      attrs: [],
      customs: changeCustoms.filter((item) => CAROUSEL_MAIN_CUSTOMS.includes(item.property)),
    });
  }
  if (targetName === "backtotop") {
    // Get all related elements
    let btnBackToTop = getFirstChildWithAttr(target, "data-name", "backtotop_btn");
    // For each related element keep only those style updates that should be applied to that element
    const TARGET_CSS_PROPS = ["color", "background", "border-style", "border-width", "border-color", "border-radius", "box-shadow", "transition"];
    const TARGET_ATTRS = ["id"];
    const TARGET_CUSTOMS = ["backToTop_pos", "backToTop_sizeBtn", "backToTop_sizeIcon"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(btnBackToTop.childId).split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
  }
  if (targetName === "inputfield") {
    // Get related elements
    let inputFieldWrapper = getParentWithAttr("data-inputfieldwrapper", "true");
    let label = getInputfieldLabel();
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    // target = .form-control
    const TARGET_CSS_PROPS = [
      "padding",
      "color",
      "background",
      "border-style",
      "border-width",
      "border-color",
      "border-radius",
      "box-shadow",
      "transition",
    ];
    const TARGET_ATTRS = ["id", "name", "data-required"];
    const TARGET_CUSTOMS = [
      "inputfield_fontSize",
      "inputfield_placeholderText",
      "inputfield_placeholderColor",
      "inputfield_type",
      "inputfield_dropdownOptions",
    ];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    // inputfieldWrapper
    const INPUTFIELD_WRAPPER_CSS_PROPS = ["margin"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(inputFieldWrapper.childId).split("-"),
      css: changeCss.filter((item) => INPUTFIELD_WRAPPER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    // label
    const LABEL_CUSTOMS = ["inputfield_labelText", "inputfield_showLabel"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(label.childId).split("-"),
      css: [],
      classes: [],
      attrs: [],
      customs: changeCustoms.filter((item) => LABEL_CUSTOMS.includes(item.property)),
    });
    // form wrapper div
    let sbFormDiv = getParentWithAttr("data-sbform", "true");
    if (sbFormDiv !== null) {
      const FORM_WRAPPER_ATTRS = ["data-sbformmsg", "data-sbformaction"];
      elementsAndChanges.push({
        splitElementId: getConcatElementId(sbFormDiv.childId).split("-"),
        css: [],
        classes: [],
        attrs: changeAttrs.filter((item) => FORM_WRAPPER_ATTRS.includes(item.attr)),
        customs: [],
      });
    }
  }
  if (targetName === "headingline" || targetName === "sectionline") {
    // Get the heading/section line div
    let divHeadingLine = target.children[0];
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("sb", "selectedElement");
    const TARGET_CUSTOMS = ["widthMobile", "widthTablet", "widthDesktop"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: [],
      classes: [],
      attrs: [],
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const HEADINGLINE_CSS_PROPS = ["width", "height", "background", "border-style", "border-width", "border-color", "border-radius", "align-self"];
    const HEADINGLINE_ATTRS = ["id"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(divHeadingLine.childId).split("-"),
      css: changeCss.filter((item) => HEADINGLINE_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => HEADINGLINE_ATTRS.includes(item.attr)),
      customs: [],
    });
  }
  // Apply the changes for each element
  // console.log("elementsAndChanges");
  // console.log(elementsAndChanges);
  elementsAndChanges.forEach((element, i) => {
    element.css.length > 0 && store.dispatch(updateCss(element.splitElementId, element.css));
    element.classes.length > 0 && store.dispatch(updateClasses(element.splitElementId, element.classes));
    element.attrs.length > 0 && store.dispatch(updateAttributes(element.splitElementId, element.attrs));
    element.customs.length > 0 && store.dispatch(updateCustoms(element.splitElementId, element.customs, i));
  });
};

// ====================
// == Navbar helpers ==
// ====================

const getNavbarCssRules = (changeCustoms) => {
  const getVal = (prop) => {
    try {
      return changeCustoms.filter((item) => item.property === prop)[0].newVal;
    } catch (error) {
      return "";
    }
  };
  try {
    // Prepare css rules in same format as changeCss would give, to enable usage of the standard css updating redux functions
    // Example css rule format: { property: "border-style", pseudo: "", value: "solid solid solid solid" }
    let changeCss = [];
    changeCss.push({ property: "padding-top", pseudo: "", value: `${getVal("navbarSize")}rem` });
    changeCss.push({ property: "padding-bottom", pseudo: "", value: `${getVal("navbarSize")}rem` });
    changeCss.push({ property: "background", pseudo: "", value: getVal("navbarBgColor") });
    changeCss.push({ property: "transition", pseudo: "", value: `all ${getVal("navbarTransition")}s ease-in-out` });
    changeCss.push({
      property: "box-shadow",
      pseudo: "",
      value: `${getVal("navbarBoxShadowHori")}px ${getVal("navbarBoxShadowVert")}px ${getVal("navbarBoxShadowBlur")}px ${getVal(
        "navbarBoxShadowSpread"
      )}px ${getVal("navbarBoxShadowRgba")}`,
    });
    return changeCss;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getNavbarLogoImgCssRules = (changeCustoms) => {
  const getVal = (prop) => {
    try {
      return changeCustoms.filter((item) => item.property === prop)[0].newVal;
    } catch (error) {
      return "";
    }
  };
  try {
    // Prepare css rules in same format as changeCss would give, to enable usage of the standard css updating redux functions
    // Example css rule format: { property: "border-style", pseudo: "", value: "solid solid solid solid" }
    let changeCss = [];
    changeCss.push({ property: "max-height", pseudo: "", value: `${getVal("navbarLogoMaxHeight")}px` });
    return changeCss;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getNavbarTogglerCssRules = (changeCustoms) => {
  const getVal = (prop) => {
    try {
      return changeCustoms.filter((item) => item.property === prop)[0].newVal;
    } catch (error) {
      return "";
    }
  };
  try {
    // Prepare css rules in same format as changeCss would give, to enable usage of the standard css updating redux functions
    // Example css rule format: { property: "border-style", pseudo: "", value: "solid solid solid solid" }
    let changeCss = [];
    changeCss.push({ property: "font-size", pseudo: "", value: `${getVal("navbarTogglerFontSize")}rem` });
    changeCss.push({ property: "background", pseudo: "", value: getVal("navbarTogglerBgColor") });
    changeCss.push({ property: "background", pseudo: "hover", value: getVal("navbarTogglerBgColorHover") });
    changeCss.push({ property: "color", pseudo: "", value: getVal("navbarTogglerColor") });
    changeCss.push({ property: "color", pseudo: "hover", value: getVal("navbarTogglerColorHover") });
    let navbarTogglerBorderRadius = getVal("navbarTogglerBorderRadius");
    if (navbarTogglerBorderRadius < 50) {
      changeCss.push({ property: "border-radius", pseudo: "", value: `${navbarTogglerBorderRadius}rem` });
    } else if (navbarTogglerBorderRadius < 100) {
      changeCss.push({ property: "border-radius", pseudo: "", value: `${navbarTogglerBorderRadius}%` });
    } else {
      changeCss.push({ property: "border-radius", pseudo: "", value: `${navbarTogglerBorderRadius}px` });
    }
    return changeCss;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getNavbarLinkCssRules = (changeCustoms) => {
  const getVal = (prop) => {
    try {
      return changeCustoms.filter((item) => item.property === prop)[0].newVal;
    } catch (error) {
      return "";
    }
  };
  try {
    // Prepare css rules in same format as changeCss would give, to enable usage of the standard css updating redux functions
    // Example css rule format: { property: "border-style", pseudo: "", value: "solid solid solid solid" }
    let changeCss = [];
    changeCss.push({ property: "color", pseudo: "", value: getVal("navbarLinkColor") });
    changeCss.push({ property: "color", pseudo: "hover", value: getVal("navbarLinkColorHover") });
    changeCss.push({ property: "font-family", pseudo: "", value: getVal("navbarLinkFontName") });
    changeCss.push({ property: "font-size", pseudo: "", value: `${getVal("navbarLinkFontSize")}rem` });
    changeCss.push({ property: "text-decoration", pseudo: "", value: getVal("navbarLinkDeco") });
    changeCss.push({ property: "font-weight", pseudo: "", value: getVal("navbarLinkBold") });
    return changeCss;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getNavbarExpandClassnames = (changeCustoms, navbarOrToggler) => {
  const getItem = (prop) => {
    try {
      return changeCustoms.filter((item) => item.property === prop)[0];
    } catch (error) {
      return "";
    }
  };
  try {
    // Format: [ { oldClassname: "container", newClassname: "container-fluid" }, ... ]
    let changeClasses = [];
    let { oldVal, newVal } = getItem("navbarExpand");
    if (navbarOrToggler === "navbar") {
      changeClasses.push({
        oldClassname: oldVal === "" ? "" : `navbar-expand-${oldVal}`,
        newClassname: newVal === "" ? "" : `navbar-expand-${newVal}`,
      });
    } else {
      changeClasses.push({ oldClassname: oldVal === "" ? "" : `d-${oldVal}-none`, newClassname: newVal === "" ? "" : `d-${newVal}-none` });
    }
    return changeClasses;
  } catch (error) {
    console.error(error);
    return "";
  }
};

const getNavbarLinkData = (changeCustoms, targetClassname) => {
  const getVal = (prop) => {
    try {
      return changeCustoms.filter((item) => item.property === prop)[0].newVal;
    } catch (error) {
      return "";
    }
  };
  const getLiElement = (linkData) => {
    return {
      childId: "",
      type: "",
      htmlTagName: "li",
      classes: ["nav-item"],
      styles: [],
      attributes: [],
      content: "",
      children: [
        {
          childId: linkData.childId,
          type: "",
          htmlTagName: "a",
          classes: ["nav-link", targetClassname],
          styles: [],
          attributes: [
            { property: "href", value: "#!" },
            { property: "data-href", value: linkData.dest },
            { property: "data-target", value: "_self" },
          ],
          content: "",
          children: [
            { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: linkData.text, children: [] },
          ],
        },
      ],
    };
  };
  try {
    // Prepare an array that can be inserted as the ul[data-elementgetter2] children
    let navbarLinkData = getVal("navbarLinkData");
    // Return an array in changeCustoms format ( [ { property, oldVal, newVal } ] ) (skipping oldVal as it is not relevant)
    return [{ property: "navbarLinkData", newVal: navbarLinkData.map((linkData) => getLiElement(linkData)) }];
  } catch (error) {
    console.error(error);
    return null;
  }
};

// =============
// == Helpers ==
// =============

export const getBorderStyleCssString = (borderSide, borderType) => {
  let val = "none none none none";
  if (borderSide === "all") {
    val = `${borderType} ${borderType} ${borderType} ${borderType}`;
  }
  if (borderSide === "left") {
    val = `none none none ${borderType}`;
  }
  if (borderSide === "right") {
    val = `none ${borderType} none none`;
  }
  if (borderSide === "top") {
    val = `${borderType} none none none`;
  }
  if (borderSide === "bottom") {
    val = `none none ${borderType} none`;
  }
  if (borderSide === "topbottom") {
    val = `${borderType} none ${borderType} none`;
  }
  if (borderSide === "leftright") {
    val = `none ${borderType} none ${borderType}`;
  }
  return val;
};
