export const sectiondividers78 = {
  id: "sectiondividers-78",
  name: "Section dividers 78",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "svg",
        classes: [],
        styles: [{ property: "background", value: "rgba(255, 255, 255, 1)" }],
        attributes: [
          { property: "xmlns", value: "http://www.w3.org/2000/svg" },
          { property: "version", value: "1.0" },
          { property: "width", value: "100%" },
          { property: "height", value: "63" },
          { property: "viewBox", value: "0 0 1920 63" },
          { property: "preserveAspectRatio", value: "none" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              {
                property: "d",
                value:
                  "M1949,25l-62,23-28-6-43,9-59-21-74,14-41-10-83,11-25-15-73,17-30-19-24,12h-47l-24,13-23-9-45,8-39-8-28,9-41-14-65,1-30-4-21,20L986,45,942,63,910,45,836,62,781,41,734,53,706,38l-98,7-20-6L502,56l-56-9L402,61,363,51l-57,8-22-7L244,63,220,42l-37,9s-26.452-11.727-31-12-51,19-51,19L82,53,43,43,11,61-15,0H1953Z",
              },
            ],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
