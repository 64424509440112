import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Margin from "./Margin";
import Padding from "./Padding";
import AlignRow from "./AlignRow";
import ResponsiveLayout from "./ResponsiveLayout";
import BorderRadius from "./BorderRadius";
import Divider from "./formComponents/Divider";
import ColorPicker from "./formComponents/ColorPicker";
import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const CalloutTestimonial = ({ sb: { startStyles } }) => {
  return (
    <>
      {/* Layout */}
      <ResponsiveLayout />
      <Divider />
      <AlignRow />
      <Divider />
      <Padding />
      <Divider />
      <Margin />
      <Divider />
      {/* Style */}
      <BorderRadius />
      <Divider />
      {/* Component */}
      <ColorPicker
        label={translate("cEditForms.calloutTest_bgColor", false, null)}
        id={"callout_testimonial_bgColor"}
        startValue={formGetStartValue(startStyles, "callout_testimonial_bgColor")}
        tooltipText={translate("cEditForms.calloutTest_tooltipBgColor", false, null)}
      />
      <Slider
        label={translate("cEditForms.calloutTest_pos", false, null)}
        id={"callout_testimonial_posLeft"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "callout_testimonial_posLeft")}
        tooltipText={translate("cEditForms.calloutTest_tooltipPos", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.calloutTest_shadowColor", false, null)}
        id={"callout_testimonial_shadowColor"}
        startValue={formGetStartValue(startStyles, "callout_testimonial_shadowColor")}
        tooltipText={translate("cEditForms.calloutTest_tooltipShadowColor", false, null)}
      />
    </>
  );
};

CalloutTestimonial.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(CalloutTestimonial);
