export const parallax2 = {
  id: "parallax-2",
  name: "Parallax 2",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "parallax-2-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["parallax-2-wrapper"],
        styles: [],
        attributes: [
          { property: "data-name", value: "parallax" },
          { property: "data-editable", value: "true" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["parallax-2-content"],
            styles: [],
            attributes: [{ property: "data-parallaxcontent", value: "true" }],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.parallax-2-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      {
        className: "section.parallax-2-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "parallax-2-component",
        pseudo: "",
        rules: [
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "parallax-2-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "height", value: "100vh" },
          { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/parallax-2.png")' },
          { property: "background-size", value: "cover" },
          { property: "background-position", value: "center" },
          { property: "background-attachment", value: "fixed" },
          { property: "background-repeat", value: "no-repeat" },
        ],
      },
      {
        className: "parallax-2-content",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "background", value: "rgba(0, 0, 0, 0.4)" },
        ],
      },
    ],
  },
};
