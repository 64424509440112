// TODO: Allow changing of border lines
export const quotes13 = {
  id: "quotes-13",
  name: "Quotes 13",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "quote-13-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "quote-13-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "quote-13-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card6" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-13-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "span",
                    classes: ["quote-13-quoteicon"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "span" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-quote-left"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["quote-13-text", "toGrow"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content:
                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["quote-13-quote"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "em",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Sandra, 33",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "quote-13-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card6" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-13-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "span",
                    classes: ["quote-13-quoteicon"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "span" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-quote-left"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["quote-13-text", "toGrow"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content:
                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["quote-13-quote"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "em",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Jenny, 41",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-3", "quote-13-col"],
            styles: [],
            attributes: [
              { property: "data-name", value: "card6" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "quote-13-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementgetter1", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "span",
                    classes: ["quote-13-quoteicon"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "span" },
                      { property: "data-editable", value: "true" },
                      { property: "data-iconwrapper", value: "true" },
                      { property: "data-texteditable", value: "false" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "span",
                        classes: ["icon", "fa-solid", "fa-quote-left"],
                        styles: [],
                        attributes: [{ property: "data-checkparent", value: "true" }],
                        content: "",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["quote-13-text", "toGrow"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content:
                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["quote-13-quote"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "em",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Jerome, 39",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.quote-13-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.quote-13-component", pseudo: "hover", rules: [] },
      {
        className: "quote-13-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "display", value: "flex" },
          { property: "border-style", value: "none solid none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(180, 180, 180, 1)" },
        ],
      },
      { className: "quote-13-col", pseudo: "last-child", rules: [{ property: "border", value: "none" }] },
      {
        className: "quote-13-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0" },
          { property: "padding", value: "0 0 0 0" },
        ],
      },
      { className: "quote-13-wrapper", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)" }] },
      {
        className: "quote-13-border",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "left", value: "0" },
          { property: "height", value: "100%" },
          { property: "width", value: "10px" },
          { property: "background", value: "rgba(229, 42, 5, 1)" },
        ],
      },
      {
        className: "quote-13-border",
        pseudo: "hover",
        rules: [{ property: "background", value: "rgba(229, 42, 5, 1)" }],
      },

      {
        className: "quote-13-quoteicon",
        pseudo: "",
        rules: [
          { property: "display", value: "block" },
          { property: "font-size", value: "2rem" },
          { property: "color", value: "rgba(100, 100, 100, 1)" },
          { property: "margin", value: "1rem 1rem 0.25rem 1rem" },
        ],
      },
      {
        className: "quote-13-quoteicon",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-13-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "0.25rem 1rem 1rem 1rem" },
          { property: "color", value: "rgba(109, 118, 126, 1)" },
        ],
      },
      {
        className: "quote-13-text",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "quote-13-quote",
        pseudo: "",
        rules: [
          { property: "margin", value: "0rem 1rem 1rem 1rem" },
          { property: "color", value: "rgba(109, 118, 126, 1)" },
        ],
      },
      {
        className: "quote-13-quote",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
