export const template26 = {
  id: "66003e64e05fc2d9444cf76b",
  name: { en: "Site under construction", nl: "Website in de maak" },
  desc: { en: "Placeholder for a website under construction", nl: "Placeholder voor een website in opbouw" },
  categories: ["underConstruction"],
  sbPages: [
    {
      pageId: "04549419-6216-4352-87e6-ecfe4fe8eafe",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "WP7QPq",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-12-component-WP7QPq"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ZUgttv",
              type: "",
              htmlTagName: "div",
              classes: ["hero-12-wrapper-WP7QPq"],
              styles: [],
              attributes: [{ property: "data-herowrapper", value: "true" }],
              content: "",
              children: [
                {
                  childId: "xdDyfH",
                  type: "",
                  htmlTagName: "img",
                  classes: ["hero-12-img-WP7QPq"],
                  styles: [],
                  attributes: [
                    { property: "alt", value: "Image" },
                    { property: "src", value: "https://cdn.satonda.com/eb/assets/abstract10.jpg" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "NZLXvS",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-12-content-WP7QPq"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "hero1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ZJ8nXT",
                      type: "",
                      htmlTagName: "img",
                      classes: ["hero-12-logo-img-WP7QPq"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/under-construction-white.png" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                    {
                      childId: "knB0io",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-12-title-WP7QPq", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "UNDER CONSTRUCTION",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "MgcUmL",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-12-text-WP7QPq", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Website coming soon",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  sbCustomCss: [
    {
      componentId: "WP7QPq",
      classes: [
        {
          className: "section.hero-12-component-WP7QPq",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-12-component-WP7QPq", pseudo: "hover", rules: [] },
        {
          className: "hero-12-component-WP7QPq",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-12-component-WP7QPq", pseudo: "hover", rules: [] },
        {
          className: "hero-12-wrapper-WP7QPq",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "100vh" },
          ],
        },
        {
          className: "hero-12-img-WP7QPq",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-12-content-WP7QPq",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 0.3)" },
          ],
        },
        {
          className: "hero-12-title-WP7QPq",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "font-size", value: "60px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(220, 126, 24, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-12-title-WP7QPq", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(220, 126, 24, 0.4)" }] },
        {
          className: "hero-12-logo-img-WP7QPq",
          pseudo: "",
          rules: [
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "128px" },
            { property: "margin", value: "0rem 0rem 4.5rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(229, 42, 5, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-self", value: "center" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-12-logo-img-WP7QPq",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "hero-12-text-WP7QPq",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(220, 126, 24, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-12-text-WP7QPq", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(220, 126, 24, 0.4)" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Fjalla One",
    fontHeadersWeight: "400",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(229, 42, 5, 1)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
