import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Tooltip from "./formComponents/Tooltip";
import Divider from "./formComponents/Divider";
import TextInput from "./formComponents/TextInput";
import Custom from "./formComponents/Custom";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const InputFieldSettings = ({ sb: { startStyles, selectedElement } }) => {
  const [fieldIsRequired, setFieldIsRequired] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  useEffect(() => {
    setFieldIsRequired(formGetStartValue(startStyles, "inputfield_required"));
    setShowLabel(formGetStartValue(startStyles, "inputfield_showLabel"));
  }, [startStyles]);

  const InputFieldType = () => {
    const OPTION_OBJ = { val: "option", label: translate("cEditForms.inputfield_ddOption", false, null) };

    const [valueType, setValueType] = useState("text");
    const [dropdownOptions, setDropdownOptions] = useState([OPTION_OBJ]);

    useEffect(() => {
      setValueType(formGetStartValue(startStyles, "inputfield_type"));
    }, [formGetStartValue(startStyles, "inputfield_type"), selectedElement]);

    useEffect(() => {
      setDropdownOptions(JSON.parse(formGetStartValue(startStyles, "inputfield_dropdownOptions")));
    }, [formGetStartValue(startStyles, "inputfield_dropdownOptions"), selectedElement]);

    const options = [
      { val: "text", label: translate("cEditForms.inputfield_text", false, null) },
      { val: "textarea", label: translate("cEditForms.inputfield_multiline", false, null) },
      { val: "number", label: translate("cEditForms.inputfield_number", false, null) },
      { val: "password", label: translate("cEditForms.inputfield_pw", false, null) },
      { val: "email", label: translate("cEditForms.inputfield_emailaddr", false, null) },
      { val: "date", label: translate("cEditForms.inputfield_datepicker", false, null) },
      { val: "select", label: translate("cEditForms.inputfield_ddMenu", false, null) },
      // { val: "check", label: "Checkbox" },
    ];

    const clickAddOption = () => {
      setDropdownOptions((prev) => [...prev, OPTION_OBJ]);
    };

    const onTypeOptionValue = (text, i) => {
      setDropdownOptions((prev) => prev.map((p, j) => (j === i ? { ...p, val: text.replace(/[^A-Za-z0-9_]/g, "") } : p)));
    };

    const onTypeOptionLabel = (text, i) => {
      setDropdownOptions((prev) => prev.map((p, j) => (j === i ? { ...p, label: text } : p)));
    };

    const clickRemoveOption = (i) => {
      setDropdownOptions((prev) => prev.filter((p, j) => j !== i));
    };

    return (
      <>
        <select
          className="form-select form-select-sm"
          id={`${EDIT_FORM_ID_PREFIX}inputfield_type`}
          value={valueType}
          onChange={(e) => setValueType(e.target.value)}
        >
          {options.map((option) => (
            <option key={option.val} value={option.val}>
              {option.label}
            </option>
          ))}
        </select>
        {valueType === "select" && (
          <div className="fontSize09">
            {/* Map options */}
            {dropdownOptions.length === 0 ? (
              <p className="mt-2 mb-0 text-italic fontSize08">{translate("cEditForms.inputfield_noDdOptionsYet", false, null)}</p>
            ) : (
              <>
                {dropdownOptions.map((option, i) => (
                  <div key={`dropdownOptions${i}`}>
                    <div className="mt-2 mb-1 d-flex justify-content-between align-items-center fontSize08">
                      <p className="m-0">
                        {translate("cEditForms.inputfield_ddOption", false, null)} {i + 1}
                      </p>
                      <i
                        className="text-danger cursorPointer fa-regular fa-trash-can"
                        onClick={() => clickRemoveOption(i)}
                        title={translate("cEditForms.inputfield_removeOption", false, null)}
                      ></i>
                    </div>
                    <div className="d-flex align-items-center w-100 mb-1">
                      <div className="w-30">
                        {translate("cEditForms.inputfield_value", false, null)}:
                        <Tooltip text={translate("cEditForms.inputfield_ttValue", false, null)} />
                      </div>
                      <div className="w-70">
                        <input
                          type="text"
                          className={`form-control form-control-sm`}
                          value={dropdownOptions[i].val}
                          onChange={(e) => onTypeOptionValue(e.target.value, i)}
                          id={`${EDIT_FORM_ID_PREFIX}inputfield_dropdownOptions${i}_val`}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center w-100">
                      <div className="w-30">
                        {translate("cEditForms.inputfield_label", false, null)}:
                        <Tooltip text={translate("cEditForms.inputfield_ttLabel", false, null)} />
                      </div>
                      <div className="w-70">
                        <input
                          type="text"
                          className={`form-control form-control-sm`}
                          value={dropdownOptions[i].label}
                          onChange={(e) => onTypeOptionLabel(e.target.value, i)}
                          id={`${EDIT_FORM_ID_PREFIX}inputfield_dropdownOptions${i}_label`}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            {/* Add additional option */}
            <button className="btn btn-sm btn-outline-success mt-2 w-100" onClick={clickAddOption}>
              {translate("cEditForms.inputfield_addOption", false, null)}
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {/* Input field */}
      <TextInput
        label={translate("cEditForms.inputfield_name", false, null)}
        id={"inputfield_name"}
        startValue={formGetStartValue(startStyles, "inputfield_name")}
        tooltipText={translate("cEditForms.inputfield_ttName", false, null)}
      />
      {formGetStartValue(startStyles, "inputfield_type") !== "check" && (
        <div className={`form-check form-switch mt-3 mb-3`}>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`${EDIT_FORM_ID_PREFIX}inputfield_required`}
            value={fieldIsRequired}
            checked={fieldIsRequired}
            onChange={(e) => setFieldIsRequired(e.target.checked)}
          />
          <label className="form-check-label" htmlFor={`${EDIT_FORM_ID_PREFIX}inputfield_required`}>
            {translate("cEditForms.inputfield_setRequired", false, null)}
          </label>
        </div>
      )}
      <Divider />
      {/* Don't allow changing of input type when it is a checkbox. Has a completely different DOM structure so simplifies to not allow changing */}
      {formGetStartValue(startStyles, "inputfield_type") !== "check" && (
        <>
          <Custom
            label={translate("cEditForms.inputfield_type", false, null)}
            tooltipText={translate("cEditForms.inputfield_ttType", false, null)}
            column={true}
            customComponent={InputFieldType}
          />
          <Divider />
        </>
      )}
      {formGetStartValue(startStyles, "inputfield_type") !== "check" && (
        <div className={`form-check form-switch mt-3 mb-3`}>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`${EDIT_FORM_ID_PREFIX}inputfield_showLabel`}
            value={showLabel}
            checked={showLabel}
            onChange={(e) => setShowLabel(e.target.checked)}
          />
          <label className="form-check-label" htmlFor={`${EDIT_FORM_ID_PREFIX}inputfield_showLabel`}>
            {translate("cEditForms.inputfield_showLabel", false, null)}
          </label>
        </div>
      )}
      <TextInput
        label={translate("cEditForms.inputfield_labelText", false, null)}
        id={"inputfield_labelText"}
        startValue={formGetStartValue(startStyles, "inputfield_labelText")}
        tooltipText={translate("cEditForms.inputfield_ttLabelText", false, null)}
      />
      {/* Checkboxes don't have placeholders */}
      {formGetStartValue(startStyles, "inputfield_type") !== "check" && (
        <>
          <Divider />
          <TextInput
            label={translate("cEditForms.inputfield_placeholder", false, null)}
            id={"inputfield_placeholderText"}
            startValue={formGetStartValue(startStyles, "inputfield_placeholderText")}
            tooltipText={translate("cEditForms.inputfield_ttPlaceholder", false, null)}
          />
        </>
      )}
    </>
  );
};

InputFieldSettings.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(InputFieldSettings);
