import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { clickApplyStyleChanges } from "../../../lib/editFunctions";

import { translate } from "../../../translations/translations";

import EditComponentAccordionItem from "./editForms/formComponents/EditComponentAccordionItem";
import ElementSettings from "./editForms/ElementSettings";
import AlignCol from "./editForms/AlignCol";
import AlignHori from "./editForms/AlignHori";
import Width from "./editForms/Width";
import AlignRow from "./editForms/AlignRow";
import BgColor from "./editForms/BgColor";
import Border from "./editForms/Border";
import BorderRadius from "./editForms/BorderRadius";
import BoxShadow from "./editForms/BoxShadow";
import BtnEdit from "./editForms/BtnEdit";
import ContainerWidth from "./editForms/ContainerWidth";
import IconEditor from "./editForms/IconEditor";
import ImgSource from "./editForms/ImgSource";
import ImgAdjust from "./editForms/ImgAdjust";
import ImgSize from "./editForms/ImgSize";
import LinkEditor from "./editForms/LinkEditor";
import ListMarker2 from "./editForms/ListMarker2";
import Margin from "./editForms/Margin";
import Padding from "./editForms/Padding";
import ResponsiveLayout from "./editForms/ResponsiveLayout";
import TextShadow from "./editForms/TextShadow";
import TextColor from "./editForms/TextColor";
import Transition from "./editForms/Transition";
import TextEditor from "./editForms/TextEditor";
import GoogleMaps from "./editForms/GoogleMaps";
// Special components
import Hero1 from "./editForms/Hero1";
import Card2 from "./editForms/Card2";
import Card3 from "./editForms/Card3";
import Card4 from "./editForms/Card4";
import Card6 from "./editForms/Card6";
import Card16 from "./editForms/Card16";
import Card23 from "./editForms/Card23";
import Parallax from "./editForms/Parallax";
import Process1 from "./editForms/Process1";
import Process2 from "./editForms/Process2";
import CalloutTestimonial from "./editForms/CalloutTestimonial";
import SectionDivider from "./editForms/SectionDivider";
import Component from "./editForms/Component";
import Animate from "./editForms/Animate";
import NavbarMain from "./editForms/NavbarMain";
import NavbarLogo from "./editForms/NavbarLogo";
import NavbarScrolledpast from "./editForms/NavbarScrolledpast";
import NavbarToggler from "./editForms/NavbarToggler";
import NavbarLinks from "./editForms/NavbarLinks";
import ImgGallery1 from "./editForms/ImgGallery1";
import ImgGallery2 from "./editForms/ImgGallery2";
import CarouselSettings from "./editForms/CarouselSettings";
import CarouselThumbnailSettings from "./editForms/CarouselThumbnailSettings";
import CarouselNavStyles from "./editForms/CarouselNavStyles";
import CarouselPagStyles from "./editForms/CarouselPagStyles";
import BackToTop from "./editForms/BackToTop";
import FormActions from "./editForms/FormActions";
import InputFieldSettings from "./editForms/InputFieldSettings";
import InputFieldStyles from "./editForms/InputFieldStyles";
import HeadingLine from "./editForms/HeadingLine";
import SectionLine from "./editForms/SectionLine";

const EditComponent = ({ sb: { selectedElement, editFormsToShow } }) => {
  const textEditorRef = useRef();

  return editFormsToShow.length > 0 ? (
    <>
      <p className="mb-3">
        Selected element: <span className="text-italic">{selectedElement}</span>
      </p>
      <div className="accordion accordion-flush trans-3 border rounded-4 overflow-hidden" id="accordion-EditComponent">
        <EditComponentAccordionItem title={translate("cEditComponent.elementSettings", false, null)} key="ElementSettings">
          <ElementSettings />
        </EditComponentAccordionItem>
        {editFormsToShow.map((editForm) => {
          return editForm === "textEditor" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.textEditor", false, null)} key="TextEditor">
              <TextEditor textEditorRef={textEditorRef} />
            </EditComponentAccordionItem>
          ) : editForm === "alignCol" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.alignColItems", false, null)} key="AlignCol">
              <AlignCol />
            </EditComponentAccordionItem>
          ) : editForm === "alignHori" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.horiAlignment", false, null)} key="AlignHori">
              <AlignHori />
            </EditComponentAccordionItem>
          ) : editForm === "width" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.elementWidth", false, null)} key="Width">
              <Width />
            </EditComponentAccordionItem>
          ) : editForm === "alignRow" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.alignColsWithinRow", false, null)} key="AlignRow">
              <AlignRow />
            </EditComponentAccordionItem>
          ) : editForm === "bgColor" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.bgColor", false, null)} key="BgColor">
              <BgColor />
            </EditComponentAccordionItem>
          ) : editForm === "border" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.border", false, null)} key="Border">
              <Border />
            </EditComponentAccordionItem>
          ) : editForm === "borderRadius" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.borderRadius", false, null)} key="BorderRadius">
              <BorderRadius />
            </EditComponentAccordionItem>
          ) : editForm === "boxShadow" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.boxShadow", false, null)} key="BoxShadow">
              <BoxShadow />
            </EditComponentAccordionItem>
          ) : editForm === "buttonEditor" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.btnStyles", false, null)} key="ButtonEditor">
              <BtnEdit />
            </EditComponentAccordionItem>
          ) : editForm === "containerWidth" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.containerWidth", false, null)} key="ContainerWidth">
              <ContainerWidth />
            </EditComponentAccordionItem>
          ) : // editForm === "display" ? (
          //   <EditComponentAccordionItem title="Display" key="Display">
          //     <Display />
          //   </EditComponentAccordionItem>
          // ) :
          editForm === "iconEditor" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.selectIcon", false, null)} key="IconEditor">
              <IconEditor />
            </EditComponentAccordionItem>
          ) : editForm === "imgSource" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.imgSrc", false, null)} key="ImgSource">
              <ImgSource />
            </EditComponentAccordionItem>
          ) : editForm === "imgAdjust" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.imgFilters", false, null)} key="ImgAdjust">
              <ImgAdjust />
            </EditComponentAccordionItem>
          ) : editForm === "imgResize" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.imgSize", false, null)} key="ImgSize">
              <ImgSize />
            </EditComponentAccordionItem>
          ) : editForm === "linkEditor" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.linkSettingsStyles", false, null)} key="LinkEditor">
              <LinkEditor />
            </EditComponentAccordionItem>
          ) : editForm === "listMarker2" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.listMarker", false, null)} key="ListMarker2">
              <ListMarker2 />
            </EditComponentAccordionItem>
          ) : editForm === "margin" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.margin", false, null)} key="Margin">
              <Margin />
            </EditComponentAccordionItem>
          ) : editForm === "padding" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.padding", false, null)} key="Padding">
              <Padding />
            </EditComponentAccordionItem>
          ) : editForm === "responsiveLayout" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.layout", false, null)} key="ResponsiveLayout">
              <ResponsiveLayout />
            </EditComponentAccordionItem>
          ) : editForm === "textShadow" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.textShadow", false, null)} key="TextShadow">
              <TextShadow />
            </EditComponentAccordionItem>
          ) : editForm === "textColor" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.textColor", false, null)} key="TextColor">
              <TextColor />
            </EditComponentAccordionItem>
          ) : editForm === "transition" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.hoverTransitionSpeed", false, null)} key="Transition">
              <Transition />
            </EditComponentAccordionItem>
          ) : editForm === "googlemaps" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.googleMaps", false, null)} key="GoogleMaps">
              <GoogleMaps />
            </EditComponentAccordionItem>
          ) : editForm === "hero1" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.hero", false, null)} key="Hero1">
              <Hero1 />
            </EditComponentAccordionItem>
          ) : editForm === "card2" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.card", false, null)} key="Card2">
              <Card2 />
            </EditComponentAccordionItem>
          ) : editForm === "card3" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.card", false, null)} key="Card3">
              <Card3 />
            </EditComponentAccordionItem>
          ) : editForm === "card4" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.card", false, null)} key="Card4">
              <Card4 />
            </EditComponentAccordionItem>
          ) : editForm === "card6" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.card", false, null)} key="Card6">
              <Card6 />
            </EditComponentAccordionItem>
          ) : editForm === "card16" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.card", false, null)} key="Card16">
              <Card16 />
            </EditComponentAccordionItem>
          ) : editForm === "card23" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.card", false, null)} key="Card23">
              <Card23 />
            </EditComponentAccordionItem>
          ) : editForm === "parallax" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.parallax", false, null)} key="Parallax">
              <Parallax />
            </EditComponentAccordionItem>
          ) : editForm === "process1" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.process", false, null)} key="Process1">
              <Process1 />
            </EditComponentAccordionItem>
          ) : editForm === "process2" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.process", false, null)} key="Process2">
              <Process2 />
            </EditComponentAccordionItem>
          ) : editForm === "callout_testimonial" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.testimonial", false, null)} key="CalloutTestimonial">
              <CalloutTestimonial />
            </EditComponentAccordionItem>
          ) : editForm === "section_divider" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.section", false, null)} key="SectionDivider">
              <SectionDivider />
            </EditComponentAccordionItem>
          ) : editForm === "component" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.component", false, null)} key="Component">
              <Component />
            </EditComponentAccordionItem>
          ) : editForm === "animate" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.animate", false, null)} key="Animate">
              <Animate />
            </EditComponentAccordionItem>
          ) : editForm === "navbar" ? (
            <Fragment key="Navbar">
              <EditComponentAccordionItem title={translate("cEditComponent.navbarMain", false, null)}>
                <NavbarMain />
              </EditComponentAccordionItem>
              <EditComponentAccordionItem title={translate("cEditComponent.navbarLogo", false, null)}>
                <NavbarLogo />
              </EditComponentAccordionItem>
              <EditComponentAccordionItem title={translate("cEditComponent.navbarScrolledPast", false, null)}>
                <NavbarScrolledpast />
              </EditComponentAccordionItem>
              <EditComponentAccordionItem title={translate("cEditComponent.navbarToggler", false, null)}>
                <NavbarToggler />
              </EditComponentAccordionItem>
              <EditComponentAccordionItem title={translate("cEditComponent.navbarLinks", false, null)}>
                <NavbarLinks />
              </EditComponentAccordionItem>
              <EditComponentAccordionItem title={translate("cEditComponent.animate", false, null)} key="Animate">
                <Animate />
              </EditComponentAccordionItem>
            </Fragment>
          ) : editForm === "img_gallery1" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.imgGallery", false, null)} key="img_gallery1">
              <ImgGallery1 />
            </EditComponentAccordionItem>
          ) : editForm === "img_gallery2" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.imgGallery", false, null)} key="img_gallery2">
              <ImgGallery2 />
            </EditComponentAccordionItem>
          ) : editForm === "carousel" ? (
            <Fragment key="Carousel">
              <EditComponentAccordionItem title={translate("cEditComponent.component", false, null)}>
                <Component />
              </EditComponentAccordionItem>
              <EditComponentAccordionItem title={translate("cEditComponent.carouselSettings", false, null)}>
                <CarouselSettings />
              </EditComponentAccordionItem>
              <EditComponentAccordionItem title={translate("cEditComponent.carouselNav", false, null)}>
                <CarouselNavStyles />
              </EditComponentAccordionItem>
              <EditComponentAccordionItem title={translate("cEditComponent.carouselPag", false, null)}>
                <CarouselPagStyles />
              </EditComponentAccordionItem>
            </Fragment>
          ) : editForm === "carousel_thumbnail" ? (
            <Fragment key="Carousel">
              <EditComponentAccordionItem title={translate("cEditComponent.component", false, null)}>
                <Component />
              </EditComponentAccordionItem>
              <EditComponentAccordionItem title={translate("cEditComponent.carouselSettings", false, null)}>
                <CarouselThumbnailSettings />
              </EditComponentAccordionItem>
              <EditComponentAccordionItem title={translate("cEditComponent.carouselNav", false, null)}>
                <CarouselNavStyles />
              </EditComponentAccordionItem>
            </Fragment>
          ) : editForm === "backtotop" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.backToTop", false, null)} key="backtotop">
              <BackToTop />
            </EditComponentAccordionItem>
          ) : editForm === "inputfield" ? (
            <Fragment key="inputfield">
              <EditComponentAccordionItem title={translate("cEditComponent.formActions", false, null)}>
                <FormActions />
              </EditComponentAccordionItem>
              <EditComponentAccordionItem title={translate("cEditComponent.inputFieldSettings", false, null)}>
                <InputFieldSettings />
              </EditComponentAccordionItem>
              <EditComponentAccordionItem title={translate("cEditComponent.inputFieldStyles", false, null)}>
                <InputFieldStyles />
              </EditComponentAccordionItem>
            </Fragment>
          ) : editForm === "headingline" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.headingLine", false, null)} key="headingline">
              <HeadingLine />
            </EditComponentAccordionItem>
          ) : editForm === "sectionline" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.sectionLine", false, null)} key="sectionline">
              <SectionLine />
            </EditComponentAccordionItem>
          ) : null;
        })}
      </div>
      <button
        className="btn btn-success mt-4 w-100"
        onClick={() => clickApplyStyleChanges(editFormsToShow.includes("textEditor") ? textEditorRef : null)}
      >
        {translate("cEditComponent.applyChanges", false, null)}
      </button>
    </>
  ) : (
    <p className="m-0 text-italic">{translate("cEditComponent.noEditableElementSelected", false, null)}</p>
  );
};

EditComponent.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(EditComponent);
