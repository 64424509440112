// Auth
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const SET_LANG_CODE = "SET_LANG_CODE";

// Global loading
export const SET_LOADING = "SET_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

// Manage project
export const LOAD_PROJECT = "LOAD_PROJECT";
export const LOAD_TEMPLATE = "LOAD_TEMPLATE";
export const UPDATE_PROJECT_GALLERY = "UPDATE_PROJECT_GALLERY";
export const UPDATE_GALLERY_FILE_DESC = "UPDATE_GALLERY_FILE_DESC";
export const UPDATE_SITEMETA = "UPDATE_SITEMETA";
export const CLEAN_CUSTOM_CSS = "CLEAN_CUSTOM_CSS";

// Product tour
export const OPEN_PRODUCT_TOUR = "OPEN_PRODUCT_TOUR";
export const CLOSE_PRODUCT_TOUR = "CLOSE_PRODUCT_TOUR";

// Drag & drop
export const LOAD_COMPONENTS = "LOAD_COMPONENTS";
export const RESET_TRACK_CHANGES = "RESET_TRACK_CHANGES";
export const RESET_STATE_VARS_DRAGDROPDELETE = "RESET_STATE_VARS_DRAGDROPDELETE";
export const START_DRAG_FROM_MENU = "START_DRAG_FROM_MENU";
export const END_DRAG_FROM_MENU = "END_DRAG_FROM_MENU";
export const START_DRAG_IN_IFRAME = "START_DRAG_IN_IFRAME";
export const END_DRAG_IN_IFRAME = "END_DRAG_IN_IFRAME";
export const DROP_COMPONENT = "DROP_COMPONENT";
export const RESET_NEWLY_ADDED_COMPONENT = "RESET_NEWLY_ADDED_COMPONENT";
export const REINSERT_COMPONENT_VIA_REDO = "REINSERT_COMPONENT_VIA_REDO";
export const TOGGLE_DRAGGABLE = "TOGGLE_DRAGGABLE";
export const CREATE_NEW_SECTION = "CREATE_NEW_SECTION";
export const ADD_TO_SECTION = "ADD_TO_SECTION";
export const PULL_OUT_OF_SECTION = "PULL_OUT_OF_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";
export const CHANGE_SECTION_NAME = "CHANGE_SECTION_NAME";
export const CLICK_CHANGE_SECTION_NAME = "CLICK_CHANGE_SECTION_NAME";
export const ADD_TO_NAVBAR = "ADD_TO_NAVBAR";
export const REMOVE_FROM_NAVBAR = "REMOVE_FROM_NAVBAR";
export const ADD_TRACK_CHANGE = "ADD_TRACK_CHANGE";
export const UPDATE_COMPONENT_HTML = "UPDATE_COMPONENT_HTML";
export const UPDATE_POS_TRACK_CHANGES = "UPDATE_POS_TRACK_CHANGES";
export const UPDATE_COMPONENT_CLASSNAME = "UPDATE_COMPONENT_CLASSNAME";
// export const UPDATE_COMPONENT_ATTRIBUTES = "UPDATE_COMPONENT_ATTRIBUTES";
export const RESET_ITEMIDS_STRUCTURE_UNDOREDO = "RESET_ITEMIDS_STRUCTURE_UNDOREDO";
export const RESTORE_ITEMIDS = "RESTORE_ITEMIDS";
export const SET_COPIED_ACROSS_PAGES = "SET_COPIED_ACROSS_PAGES";

// Edit component
export const REORDER_COMPONENT = "REORDER_COMPONENT";
export const DELETE_COMPONENT = "DELETE_COMPONENT";
export const SET_SELECTED_ELEMENT = "SET_SELECTED_ELEMENT";

// Manage elements
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const DUPLICATE_ELEMENT = "DUPLICATE_ELEMENT";
export const REORDER_COL = "REORDER_COL";

// Input fields
export const REORDER_INPUTFIELD = "REORDER_INPUTFIELD";
export const TOGGLE_CHECKBOX = "TOGGLE_CHECKBOX";

// Manage pages
export const ADD_NEW_PAGE = "ADD_NEW_PAGE";
export const ADD_NEW_VERSION = "ADD_NEW_VERSION";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const EDIT_PAGE_INFO = "EDIT_PAGE_INFO";
export const EDIT_PAGE_SOCIAL_PREVIEW = "EDIT_PAGE_SOCIAL_PREVIEW";
export const DELETE_PAGE = "DELETE_PAGE";
export const COPY_COMPONENT_TO_OTHER_PAGE = "COPY_COMPONENT_TO_OTHER_PAGE";

// Track changes
export const SAVE_TRACK_CHANGES = "SAVE_TRACK_CHANGES";
export const UNDO_REDO = "UNDO_REDO";

// Edit styles
export const START_EDITING = "START_EDITING";
export const OPEN_COLOR_PICKER = "OPEN_COLOR_PICKER";
export const UPDATE_CSS_VAR = "UPDATE_CSS_VAR";
export const CONFIRM_COLOR_SCHEME = "CONFIRM_COLOR_SCHEME";
export const CHANGE_SITE_COLORS = "CHANGE_SITE_COLORS";
export const UPDATE_COMPONENT_CSS = "UPDATE_COMPONENT_CSS";
export const UPDATE_COMPONENT_CLASSES = "UPDATE_COMPONENT_CLASSES";
export const UPDATE_COMPONENT_ATTRIBUTES = "UPDATE_COMPONENT_ATTRIBUTES";
export const UPDATE_COMPONENT_HTMLTAGNAME = "UPDATE_COMPONENT_HTMLTAGNAME";
export const UPDATE_EXISTING_TEXT_ELEMENT = "UPDATE_EXISTING_TEXT_ELEMENT";
export const ADD_NEW_TEXT_ELEMENT = "ADD_NEW_TEXT_ELEMENT";
export const UPDATE_LIST_MARKER2 = "UPDATE_LIST_MARKER2";
export const SELECT_IMG_FROM_GALLERY = "SELECT_IMG_FROM_GALLERY";
// Apply custom updates
export const UPDATE_CUSTOMS_ICON_EDITOR = "UPDATE_CUSTOMS_ICON_EDITOR";
export const UPDATE_CUSTOMS_ROW_ALIGN_JUSTIFY = "UPDATE_CUSTOMS_ROW_ALIGN_JUSTIFY";
export const UPDATE_CUSTOMS_ROW_LAYOUT = "UPDATE_CUSTOMS_ROW_LAYOUT";
export const UPDATE_CUSTOMS_ELEMENT_WIDTH = "UPDATE_CUSTOMS_ELEMENT_WIDTH";
export const UPDATE_CUSTOMS_HERO1_OVERLAY = "UPDATE_CUSTOMS_HERO1_OVERLAY";
export const UPDATE_CUSTOMS_CARD3_DESC_BGCOLOR = "UPDATE_CUSTOMS_CARD3_DESC_BGCOLOR";
export const UPDATE_CUSTOMS_CARD3_DESC_BGCOLORHOVER = "UPDATE_CUSTOMS_CARD3_DESC_BGCOLORHOVER";
export const UPDATE_CUSTOMS_CARD4_POPUPHEIGHT = "UPDATE_CUSTOMS_CARD4_POPUPHEIGHT";
export const UPDATE_CUSTOMS_CARD4_POPUPHEIGHTHOVER = "UPDATE_CUSTOMS_CARD4_POPUPHEIGHTHOVER";
export const UPDATE_CUSTOMS_CARD6_POS_SIZE = "UPDATE_CUSTOMS_CARD6_POS_SIZE";
export const UPDATE_CUSTOMS_HERO_PARALLAX_HEIGHT = "UPDATE_CUSTOMS_HERO_PARALLAX_HEIGHT";
export const UPDATE_CUSTOMS_PARALLAX_SRC = "UPDATE_CUSTOMS_PARALLAX_SRC";
export const UPDATE_CUSTOMS_PROCESS1_ARROW_COLOR = "UPDATE_CUSTOMS_PROCESS1_ARROW_COLOR";
export const UPDATE_CUSTOMS_PROCESS2_LINE_COLOR = "UPDATE_CUSTOMS_PROCESS2_LINE_COLOR";
export const UPDATE_CUSTOMS_PROCESS2_ICON_COLOR = "UPDATE_CUSTOMS_PROCESS2_ICON_COLOR";
export const UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_BGCOLOR = "UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_BGCOLOR";
export const UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_SHADOWCOLOR = "UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_SHADOWCOLOR";
export const UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_POSLEFT = "UPDATE_CUSTOMS_TESTIMONIAL_CALLOUT_POSLEFT";
export const FLIP_SECTION_DIVIDER = "FLIP_SECTION_DIVIDER";
export const UPDATE_CUSTOMS_SECTIONDIVIDER_COLORS = "UPDATE_CUSTOMS_SECTIONDIVIDER_COLORS";
export const UPDATE_COMPONENT_BGCOLOR = "UPDATE_COMPONENT_BGCOLOR";
export const UPDATE_CUSTOMS_COMPONENT_SPACE_TOP = "UPDATE_CUSTOMS_COMPONENT_SPACE_TOP";
export const UPDATE_CUSTOMS_COMPONENT_SPACE_BOTTOM = "UPDATE_CUSTOMS_COMPONENT_SPACE_BOTTOM";
export const UPDATE_TEXTCONTENT = "UPDATE_TEXTCONTENT";
export const UPDATE_NAVBAR_POSITION = "UPDATE_NAVBAR_POSITION";
export const UPDATE_NAVBAR_SCROLLEDPAST_BOOL = "UPDATE_NAVBAR_SCROLLEDPAST_BOOL";
export const UPDATE_NAVBAR_SCROLLEDPAST_SIZE_BGCOLOR = "UPDATE_NAVBAR_SCROLLEDPAST_SIZE_BGCOLOR";
export const UPDATE_NAVBAR_SCROLLEDPAST_LOGOHEIGHT = "UPDATE_NAVBAR_SCROLLEDPAST_LOGOHEIGHT";
export const UPDATE_NAVBAR_LINK_DATA = "UPDATE_NAVBAR_LINK_DATA";
export const UPDATE_CAROUSEL_NAV_STYLES = "UPDATE_CAROUSEL_NAV_STYLES";
export const UPDATE_CAROUSEL_PAG_STYLES = "UPDATE_CAROUSEL_PAG_STYLES";
export const UPDATE_CUSTOMS_BACKTOTOP_POS = "UPDATE_CUSTOMS_BACKTOTOP_POS";
export const UPDATE_CUSTOMS_BACKTOTOP_SIZE_ICON = "UPDATE_CUSTOMS_BACKTOTOP_SIZE_ICON";
export const UPDATE_CUSTOMS_BACKTOTOP_SIZE_BTN = "UPDATE_CUSTOMS_BACKTOTOP_SIZE_BTN";
export const UPDATE_INPUTFIELD_FONTSIZE = "UPDATE_INPUTFIELD_FONTSIZE";
export const UPDATE_INPUTFIELD_PLACEHOLDER_COLOR = "UPDATE_INPUTFIELD_PLACEHOLDER_COLOR";
export const UPDATE_INPUTFIELD_TYPE_SELECT = "UPDATE_INPUTFIELD_TYPE_SELECT";
export const UPDATE_INPUTFIELD_TYPE_CHECK = "UPDATE_INPUTFIELD_TYPE_CHECK";
export const UPDATE_CAROUSEL_SLIDE_CSS = "UPDATE_CAROUSEL_SLIDE_CSS";
export const OBJECT_REMOVE_CHILDREN = "OBJECT_REMOVE_CHILDREN";
// export const XXXXX = "XXXXX";
// export const XXXXX = "XXXXX";
// export const XXXXX = "XXXXX";
// export const XXXXX = "XXXXX";

// Other
export const CHANGE_SCREEN_SIZE = "CHANGE_SCREEN_SIZE";
