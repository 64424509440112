export const pricetables3 = {
  id: "pricetables-3",
  name: "Price tables 3",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "pricetable-3-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "pricetable-3-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "pricetable-3-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "pricetable-3-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h2",
                    classes: ["pricetable-3-title1"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Very", children: [] },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "strong",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Basic", children: [] },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["pricetable-3-price"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "€35 / month",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "block",
                    htmlTagName: "ul",
                    classes: ["toGrow", "pricetable-3-list-wrapper"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "block" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem, ipsum dolor sit amet consectetur adipisicing",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet consectetur",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "a",
                    classes: ["pricetable-3-button"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_self" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Get started!",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "pricetable-3-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "pricetable-3-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h2",
                    classes: ["pricetable-3-title2"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Good", children: [] },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "strong",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Choice",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["pricetable-3-price"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "€50 / month",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "block",
                    htmlTagName: "ul",
                    classes: ["toGrow", "pricetable-3-list-wrapper"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "block" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem, ipsum dolor sit amet consectetur adipisicing",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet consectetur",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "a",
                    classes: ["pricetable-3-button"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_self" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Get started!",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "pricetable-3-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "pricetable-3-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "h2",
                    classes: ["pricetable-3-title3"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "h" },
                    ],
                    content: "",
                    children: [
                      { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Just", children: [] },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "strong",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Perfect",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["pricetable-3-price"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "€75 / month",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "block",
                    htmlTagName: "ul",
                    classes: ["toGrow", "pricetable-3-list-wrapper"],
                    styles: [],
                    attributes: [{ property: "data-name", value: "block" }],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem, ipsum dolor sit amet consectetur adipisicing",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet consectetur",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "element",
                        htmlTagName: "li",
                        classes: ["pricetable-3-list-item"],
                        styles: [],
                        attributes: [
                          { property: "data-name", value: "li" },
                          { property: "data-editable", value: "true" },
                          { property: "data-iconwrapper", value: "true" },
                          { property: "data-texteditable", value: "false" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "span",
                            classes: ["icon", "fa-solid", "fa-check"],
                            styles: [],
                            attributes: [{ property: "data-checkparent", value: "true" }],
                            content: "",
                            children: [],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["pricetable-3-list-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Lorem ipsum dolor sit amet",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "a",
                    classes: ["pricetable-3-button"],
                    styles: [],
                    attributes: [
                      { property: "href", value: "#!" },
                      { property: "data-href", value: "#!" },
                      { property: "data-target", value: "_self" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "button" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Get started!",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.pricetable-3-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.pricetable-3-component", pseudo: "hover", rules: [] },
      {
        className: "pricetable-3-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "display", value: "flex" },
        ],
      },
      {
        className: "pricetable-3-wrapper",
        pseudo: "",
        rules: [
          { property: "overflow", value: "hidden" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0" },
          { property: "padding", value: "0 0 0 0" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(180, 180, 180, 1)" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "align-items", value: "center" },
        ],
      },
      {
        className: "pricetable-3-wrapper",
        pseudo: "hover",
        rules: [
          { property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(180, 180, 180, 1)" },
        ],
      },
      {
        className: "pricetable-3-title1",
        pseudo: "",
        rules: [
          { property: "text-align", value: "center" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "2.5rem 0 2.5rem 0" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "width", value: "100%" },
        ],
      },
      {
        className: "pricetable-3-title1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "pricetable-3-title2",
        pseudo: "",
        rules: [
          { property: "text-align", value: "center" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "2.5rem 0 2.5rem 0" },
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "width", value: "100%" },
        ],
      },
      {
        className: "pricetable-3-title2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "pricetable-3-title3",
        pseudo: "",
        rules: [
          { property: "text-align", value: "center" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "2.5rem 0 2.5rem 0" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "width", value: "100%" },
        ],
      },
      {
        className: "pricetable-3-title3",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "pricetable-3-price",
        pseudo: "",
        rules: [
          { property: "text-align", value: "center" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "2.5rem 0 2.5rem 0" },
          { property: "background", value: "rgba(50, 50, 50, 1)" },
          { property: "width", value: "100%" },
          { property: "margin", value: "0 0 2rem 0" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "font-size", value: "20px" },
        ],
      },
      {
        className: "pricetable-3-price",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "pricetable-3-list-wrapper",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "list-style", value: "none" },
          { property: "margin", value: "0 0 1rem 0" },
          { property: "width", value: "100%" },
        ],
      },
      {
        className: "pricetable-3-list-wrapper li",
        pseudo: "",
        rules: [
          { property: "border-style", value: "none none solid none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(108, 117, 125, 1)" },
          { property: "padding", value: "0.5rem 1rem 0.5rem 1rem" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
        ],
      },
      { className: "pricetable-3-list-wrapper span", pseudo: "", rules: [{ property: "color", value: "rgba(229, 42, 5, 1)" }] },
      { className: "pricetable-3-list-wrapper p", pseudo: "", rules: [{ property: "margin", value: "0 0 0 0.5rem" }] },
      {
        className: "pricetable-3-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "1000px" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
          { property: "margin", value: "1.5rem 0 1rem 0" },
          { property: "text-decoration", value: "none" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "pricetable-3-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgb(201, 38, 5)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
        ],
      },
    ],
  },
};
