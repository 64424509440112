import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";
import ColorPicker from "./formComponents/ColorPicker";
import Select from "./formComponents/Select";

import { formGetStartValue } from "../../../../lib/editFunctions";

import { PADDING_VALUES } from "../../../../lib/editStyleVars";
import { translate } from "../../../../translations/translations";

const NavbarMain = ({ sb: { startStyles } }) => {
  return (
    <>
      <Select
        label={translate("cEditForms.navbar_navbarPos", false, null)}
        id={"navbarPosition"}
        options={[
          { val: "", label: translate("cEditForms.navbar_inline", false, null) },
          { val: "fixed-top", label: translate("cEditForms.navbar_fixedTop", false, null) },
          { val: "sticky-top", label: translate("cEditForms.navbar_fixedScroll", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "navbarPosition")}
        tooltipText={translate("cEditForms.navbar_ttPos", false, null)}
      />
      <Select
        label={translate("cEditForms.navbar_showLinks", false, null)}
        id={"navbarExpand"}
        options={[
          { val: "", label: translate("cEditForms.navbar_never", false, null) },
          { val: "md", label: translate("cEditForms.navbar_tablet", false, null) },
          { val: "lg", label: translate("cEditForms.navbar_desktop", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "navbarExpand")}
        tooltipText={translate("cEditForms.navbar_ttShowLinks", false, null)}
      />
      <Slider
        label={translate("cEditForms.navbar_size", false, null)}
        id={"navbarSize"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "navbarSize"))}
        tooltipText={translate("cEditForms.navbar_ttSize", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.bgColor", false, null)}
        id={"navbarBgColor"}
        startValue={formGetStartValue(startStyles, "navbarBgColor")}
        tooltipText={translate("cEditForms.navbar_ttBgColor", false, null)}
      />
      <Slider
        label={translate("cEditForms.shadowHoriLength", false, null)}
        id={"navbarBoxShadowHori"}
        min={-100}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "navbarBoxShadowHori")}
        tooltipText={translate("cEditForms.tooltipShadowHoriLength", false, null)}
      />
      <Slider
        label={translate("cEditForms.shadowVerticalLength", false, null)}
        id={"navbarBoxShadowVert"}
        min={-100}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "navbarBoxShadowVert")}
        tooltipText={translate("cEditForms.tooltipShadowVerticalLength", false, null)}
      />
      <Slider
        label={translate("cEditForms.blurRadius", false, null)}
        id={"navbarBoxShadowBlur"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "navbarBoxShadowBlur")}
        tooltipText={translate("cEditForms.shadowBlur", false, null)}
      />
      <Slider
        label={translate("cEditForms.spreadRadius", false, null)}
        id={"navbarBoxShadowSpread"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "navbarBoxShadowSpread")}
        tooltipText={translate("cEditForms.shadowSpread", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.shadowColorOpacity", false, null)}
        id={"navbarBoxShadowRgba"}
        startValue={formGetStartValue(startStyles, "navbarBoxShadowRgba")}
        tooltipText={translate("cEditForms.shadowColor", false, null)}
      />
      <Slider
        label={translate("cEditForms.navbar_transSpeed", false, null)}
        id={"navbarTransition"}
        min={0}
        max={3}
        step={0.1}
        startValue={formGetStartValue(startStyles, "navbarTransition")}
        tooltipText={translate("cEditForms.navbar_ttTransSpeed", false, null)}
      />
    </>
  );
};

NavbarMain.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(NavbarMain);
