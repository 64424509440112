export const sectiondividers87 = {
  id: "sectiondividers-87",
  name: "Section dividers 87",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "section-divider"],
    styles: [],
    attributes: [{ property: "data-name", value: "section_divider" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "svg",
        classes: [],
        styles: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "transform", value: "scaleY(-1)" },
        ],
        attributes: [
          { property: "xmlns", value: "http://www.w3.org/2000/svg" },
          { property: "version", value: "1.0" },
          { property: "width", value: "100%" },
          { property: "height", value: "70" },
          { property: "viewBox", value: "0 80 1280 70" },
          { property: "preserveAspectRatio", value: "none" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "path",
            classes: [],
            styles: [],
            attributes: [
              { property: "fill", value: "rgba(204, 204, 204, 1)" },
              {
                property: "d",
                value:
                  "M 2 101 L 21 98 L 35 103 L 52 92 L 64 101 L 87 92 L 97 103 L 120 101 L 134 92 L 145 99 L 169 99 L 176 95 L 191 100 L 204 98 L 218 102 L 226 92 L 241 99 L 259 99 L 271 103 L 282 97 L 296 102 L 312 96 L 327 102 L 340 100 L 345 92 L 353 99 L 369 105 L 385 97 L 396 104 L 407 94 L 416 99 L 424 97 L 433 102 L 450 92 L 460 104 L 471 97 L 480 100 L 489 96 L 505 100 L 515 96 L 521 103 L 535 96 L 549 100 L 563 94 L 565 106 L 583 94 L 587 101 L 597 91 L 612 99 L 632 105 L 642 96 L 653 103 L 671 96 L 684 102 L 696 94 L 704 100 L 726 97 L 735 105 L 745 98 L 757 108 L 783 96 L 791 100 L 802 94 L 812 100 L 840 99 L 855 104 L 868 94 L 888 101 L 905 93 L 905 93 L 919 105 L 934 96 L 955 104 L 969 96 L 984 108 L 997 99 L 1023 106 L 1041 94 L 1061 98 L 1071 96 L 1084 99 L 1104 92 L 1112 100 L 1130 94 L 1143 101 L 1159 92 L 1176 100 L 1193 93 L 1207 99 L 1223 93 L 1232 97 L 1245 94 L 1256 99 L 1267 94 L 1300 100 L 1300 150 L 1300 150 L 1250 150 L 0 150 L 0 100 L 0 100 Z",
              },
            ],
            content: "",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section-divider",
        pseudo: "",
        rules: [
          { property: "padding", value: "0 0 0 0" },
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
          { property: "width", value: "100%" },
        ],
      },
    ],
  },
};
