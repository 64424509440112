export const card23 = {
  id: "card-23",
  name: "Card 23",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "card-23-component"],
    styles: [],
    attributes: [
      {
        property: "data-name",
        value: "component",
      },
    ],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "card-23-row"],
        styles: [],
        attributes: [
          {
            property: "data-name",
            value: "block",
          },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-23-col"],
            styles: [],
            attributes: [
              {
                property: "data-name",
                value: "card23",
              },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-23-overlay-wrapper"],
                styles: [],
                attributes: [
                  {
                    property: "data-overlaywrapper",
                    value: "true",
                  },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-23-avatar"],
                    styles: [],
                    attributes: [
                      {
                        property: "src",
                        value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg",
                      },
                      {
                        property: "data-editable",
                        value: "true",
                      },
                      {
                        property: "data-name",
                        value: "img",
                      },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-23-overlay-content"],
                    styles: [],
                    attributes: [
                      {
                        property: "data-overlaycontent",
                        value: "true",
                      },
                      {
                        property: "data-elementalignmentwrapper",
                        value: "true",
                      },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["card-23-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          {
                            property: "data-editable",
                            value: "true",
                          },
                          {
                            property: "data-texteditable",
                            value: "true",
                          },
                          {
                            property: "data-name",
                            value: "h",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Name",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-23-subtitle", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          {
                            property: "data-editable",
                            value: "true",
                          },
                          {
                            property: "data-texteditable",
                            value: "true",
                          },
                          {
                            property: "data-name",
                            value: "p",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-23-col"],
            styles: [],
            attributes: [
              {
                property: "data-name",
                value: "card23",
              },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-23-overlay-wrapper"],
                styles: [],
                attributes: [
                  {
                    property: "data-overlaywrapper",
                    value: "true",
                  },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-23-avatar"],
                    styles: [],
                    attributes: [
                      {
                        property: "src",
                        value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg",
                      },
                      {
                        property: "data-editable",
                        value: "true",
                      },
                      {
                        property: "data-name",
                        value: "img",
                      },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-23-overlay-content"],
                    styles: [],
                    attributes: [
                      {
                        property: "data-overlaycontent",
                        value: "true",
                      },
                      {
                        property: "data-elementalignmentwrapper",
                        value: "true",
                      },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["card-23-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          {
                            property: "data-editable",
                            value: "true",
                          },
                          {
                            property: "data-texteditable",
                            value: "true",
                          },
                          {
                            property: "data-name",
                            value: "h",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Name",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-23-subtitle", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          {
                            property: "data-editable",
                            value: "true",
                          },
                          {
                            property: "data-texteditable",
                            value: "true",
                          },
                          {
                            property: "data-name",
                            value: "p",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-23-col"],
            styles: [],
            attributes: [
              {
                property: "data-name",
                value: "card23",
              },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["card-23-overlay-wrapper"],
                styles: [],
                attributes: [
                  {
                    property: "data-overlaywrapper",
                    value: "true",
                  },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-23-avatar"],
                    styles: [],
                    attributes: [
                      {
                        property: "src",
                        value: "https://cdn.satonda.com/eb/assets/profilePic1.jpg",
                      },
                      {
                        property: "data-editable",
                        value: "true",
                      },
                      {
                        property: "data-name",
                        value: "img",
                      },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-23-overlay-content"],
                    styles: [],
                    attributes: [
                      {
                        property: "data-overlaycontent",
                        value: "true",
                      },
                      {
                        property: "data-elementalignmentwrapper",
                        value: "true",
                      },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["card-23-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          {
                            property: "data-editable",
                            value: "true",
                          },
                          {
                            property: "data-texteditable",
                            value: "true",
                          },
                          {
                            property: "data-name",
                            value: "h",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Name",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-23-subtitle", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          {
                            property: "data-editable",
                            value: "true",
                          },
                          {
                            property: "data-texteditable",
                            value: "true",
                          },
                          {
                            property: "data-name",
                            value: "p",
                          },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Function",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.card-23-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.card-23-component", pseudo: "hover", rules: [] },
      {
        className: "card-23-col",
        pseudo: "",
        rules: [
          {
            property: "padding",
            value: "1rem 1rem 1rem 1rem",
          },
          {
            property: "display",
            value: "flex",
          },
          {
            property: "align-items",
            value: "center",
          },
          {
            property: "justify-content",
            value: "center",
          },
        ],
      },
      {
        className: "card-23-overlay-wrapper",
        pseudo: "",
        rules: [
          {
            property: "position",
            value: "relative",
          },
          {
            property: "overflow",
            value: "hidden",
          },
          {
            property: "border-radius",
            value: "0",
          },
          {
            property: "width",
            value: "100%",
          },
          {
            property: "aspect-ratio",
            value: "1",
          },
        ],
      },
      {
        className: "card-23-overlay-wrapper",
        pseudo: "hover .card-23-avatar",
        rules: [
          {
            property: "transform",
            value: "scale(1.1)",
          },
        ],
      },
      {
        className: "card-23-overlay-content",
        pseudo: "",
        rules: [
          {
            property: "display",
            value: "flex",
          },
          {
            property: "flex-direction",
            value: "column",
          },
          {
            property: "align-items",
            value: "center",
          },
          {
            property: "justify-content",
            value: "end",
          },
          {
            property: "position",
            value: "absolute",
          },
          {
            property: "top",
            value: "0",
          },
          {
            property: "bottom",
            value: "0",
          },
          {
            property: "left",
            value: "0",
          },
          {
            property: "right",
            value: "0",
          },
          {
            property: "background",
            value: "linear-gradient(rgba(28, 139, 183, 0.2), rgba(28, 139, 183, 0.6))",
          },
          {
            property: "transition",
            value: "all 0.3s ease",
          },
          {
            property: "padding",
            value: "1rem 1rem 1rem 1rem",
          },
        ],
      },
      {
        className: "card-23-avatar",
        pseudo: "",
        rules: [
          {
            property: "width",
            value: "100%",
          },
          {
            property: "height",
            value: "100%",
          },
          { property: "object-fit", value: "cover" },
          {
            property: "transition",
            value: "all 0.7s ease-in-out",
          },
        ],
      },
      {
        className: "card-23-avatar",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-23-title",
        pseudo: "",
        rules: [
          {
            property: "margin",
            value: "0 0 0.5rem 0",
          },
          {
            property: "text-align",
            value: "left",
          },
          {
            property: "font-weight",
            value: "bold",
          },
          {
            property: "color",
            value: "rgba(250, 250, 250, 1)",
          },
        ],
      },
      {
        className: "card-23-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-23-subtitle",
        pseudo: "",
        rules: [
          {
            property: "margin",
            value: "0 0 0.5rem 0",
          },
          {
            property: "text-align",
            value: "left",
          },
          {
            property: "color",
            value: "rgba(230, 230, 230, 1)",
          },
        ],
      },
      {
        className: "card-23-subtitle",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-23-social-link",
        pseudo: "",
        rules: [
          {
            property: "margin",
            value: "0 0.5rem 0 0",
          },
          {
            property: "color",
            value: "rgba(250, 250, 250, 1)",
          },
          {
            property: "text-decoration",
            value: "none",
          },
          {
            property: "transition",
            value: "all 0.3s ease",
          },
        ],
      },
      {
        className: "card-23-social-link",
        pseudo: "hover",
        rules: [
          {
            property: "color",
            value: "rgba(200, 200, 200, 1)",
          },
        ],
      },
      {
        className: "card-23-social-link",
        pseudo: "last-child",
        rules: [{ property: "margin-right", value: "0" }],
      },
    ],
  },
};
