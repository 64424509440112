export const hero12 = {
  id: "hero-12",
  name: "Hero 12",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "hero-12-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["hero-12-wrapper"],
        styles: [],
        attributes: [{ property: "data-herowrapper", value: "true" }],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "img",
            classes: ["hero-12-img"],
            styles: [],
            attributes: [
              {
                property: "src",
                value: "https://cdn.satonda.com/eb/assets/mountain3.png",
              },
              { property: "alt", value: "Image" },
            ],
            content: "",
            children: [],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["hero-12-content"],
            styles: [],
            attributes: [
              { property: "data-name", value: "hero1" },
              { property: "data-editable", value: "true" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "img",
                classes: ["hero-12-logo-img"],
                styles: [],
                attributes: [
                  { property: "src", value: "https://cdn.satonda.com/eb/assets/logo2.png" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "img" },
                ],
                content: "",
                children: [],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["hero-12-title", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Go forward with us",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["hero-12-text", "w-100", "w-md-70", "w-lg-50"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.hero-12-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      {
        className: "section.hero-12-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-12-component",
        pseudo: "",
        rules: [
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "hero-12-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-12-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "height", value: "100vh" },
        ],
      },
      {
        className: "hero-12-img",
        pseudo: "",
        rules: [
          { property: "display", value: "block" },
          { property: "height", value: "100%" },
          { property: "width", value: "100%" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "hero-12-content",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "background", value: "rgba(255, 255, 255, 0.3)" },
        ],
      },
      {
        className: "hero-12-logo-img",
        pseudo: "",
        rules: [
          { property: "width", value: "auto" },
          { property: "height", value: "50px" },
          { property: "object-fit", value: "cover" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0rem 0rem 4.5rem 0rem" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "hero-12-logo-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-12-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(220, 126, 24, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(220, 126, 24, 0.4)" },
          { property: "margin", value: "0 0 1rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
          { property: "font-weight", value: "bold" },
        ],
      },
      {
        className: "hero-12-title",
        pseudo: "hover",
        rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(220, 126, 24, 0.4)" }],
      },
      {
        className: "hero-12-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(220, 126, 24, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(220, 126, 24, 0.4)" },
          { property: "margin", value: "0 0 2rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "hero-12-text",
        pseudo: "hover",
        rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(220, 126, 24, 0.4)" }],
      },
    ],
  },
};
