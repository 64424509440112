export const statistics3 = {
  id: "statistics-3",
  name: "Statistics 3",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "statistics-3-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-evenly", "align-items-stretch", "statistics-3-statswrapper", "w-100"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-6", "col-md-4", "col-lg-2", "statistics-3-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "statistics3a" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["statistics-3-statwrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["statistics-3-stat-caption", "w-100", "w-md-90", "w-lg-70"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Satisfied customers",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["statistics-3-stat-numbercircle"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "statistics3b" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["statistics-3-stat-numbertext", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "379", children: [] },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-6", "col-md-4", "col-lg-2", "statistics-3-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "statistics3a" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["statistics-3-statwrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["statistics-3-stat-caption", "w-100", "w-md-90", "w-lg-70"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Released projects",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["statistics-3-stat-numbercircle"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "statistics3b" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["statistics-3-stat-numbertext", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "582", children: [] },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-6", "col-md-4", "col-lg-2", "statistics-3-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "statistics3a" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["statistics-3-statwrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "p",
                    classes: ["statistics-3-stat-caption", "w-100", "w-md-90", "w-lg-70"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-texteditable", value: "true" },
                      { property: "data-name", value: "p" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Hours of support ",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["statistics-3-stat-numbercircle"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "statistics3b" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["statistics-3-stat-numbertext", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "4,410", children: [] },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.statistics-3-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.statistics-3-component", pseudo: "hover", rules: [] },
      {
        className: "statistics-3-component",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "statistics-3-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "statistics-3-statwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "width", value: "100%" },
          { property: "aspect-ratio", value: "1" },
          { property: "border-radius", value: "50%" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "2px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "position", value: "relative" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
        ],
      },
      {
        className: "statistics-3-statwrapper",
        pseudo: "hover",
        rules: [
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
        ],
      },
      {
        className: "statistics-3-stat-numbercircle",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "right", value: "0" },
          { property: "transform", value: "translate(25%, -25%)" },
          { property: "width", value: "40%" },
          { property: "aspect-ratio", value: "1" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "border-radius", value: "50%" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "statistics-3-stat-numbercircle",
        pseudo: "hover",
        rules: [
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "background", value: "rgba(229, 42, 5, 1)" },
        ],
      },
      {
        className: "statistics-3-stat-numbertext",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
          { property: "margin", value: "0 0 0 0" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "statistics-3-stat-numbertext",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "statistics-3-stat-caption",
        pseudo: "",
        rules: [
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
          { property: "margin", value: "0 0 0 0" },
        ],
      },
      {
        className: "statistics-3-stat-caption",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
