export const lParse = {
  moveUpComponent: {
    en: "Move component up",
    nl: "Verplaats component omhoog",
  },
  moveDownComponent: {
    en: "Move component down",
    nl: "Verplaats component omlaag",
  },
  editComponent: {
    en: "Edit component",
    nl: "Bewerk component",
  },
  removeComponent: {
    en: "Remove component",
    nl: "Verwijder component",
  },
  editElement: {
    en: "Edit element",
    nl: "Bewerk element",
  },
  duplicateElement: {
    en: "Duplicate element",
    nl: "Kopieer element",
  },
  removeElement: {
    en: "Remove element",
    nl: "Verwijder element",
  },
  reorderColumns: {
    en: "Reorder columns",
    nl: "Herschik kolommen",
  },
  moveUpInputField: {
    en: "Move input field up",
    nl: "Verplaats invoerveld omhoog",
  },
  moveDownInputField: {
    en: "Move input field down",
    nl: "Verplaats invoerveld omlaag",
  },
  duplicateInputField: {
    en: "Duplicate input field",
    nl: "Kopieer invoerveld",
  },
  removeInputField: {
    en: "Remove input field",
    nl: "Verwijder invoerveld",
  },
};
