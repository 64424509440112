import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ColorPicker from "./formComponents/ColorPicker";
import Select from "./formComponents/Select";
import Slider from "./formComponents/Slider";
import Divider from "./formComponents/Divider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const CarouselPagStyles = ({ sb: { startStyles } }) => {
  const [carouselPagStyles, setCarouselPagStyles] = useState(null);
  const [boolIncludePagButtons, setBoolIncludePagButtons] = useState(false);

  useEffect(() => {
    try {
      let pagStyles = formGetStartValue(startStyles, "carouselPagStyles");
      if (pagStyles !== null && typeof pagStyles !== "undefined" && Object.keys(pagStyles).length > 0) {
        setCarouselPagStyles(pagStyles);
        setBoolIncludePagButtons(pagStyles.includePagButtons);
      } else {
        setCarouselPagStyles(null);
      }
    } catch (error) {
      setCarouselPagStyles(null);
    }
  }, [startStyles]);

  const getCarouselStartVal = (varName) => {
    try {
      return carouselPagStyles[varName];
    } catch (error) {
      return "";
    }
  };

  return (
    <>
      {/* Show  */}
      <div className={`form-check form-switch my-3`}>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id={`${EDIT_FORM_ID_PREFIX}carousel_pag_includePagButtons`}
          value={boolIncludePagButtons}
          checked={boolIncludePagButtons}
          onChange={(e) => setBoolIncludePagButtons(e.target.checked)}
        />
        <label className="form-check-label" htmlFor={`${EDIT_FORM_ID_PREFIX}carousel_pag_includePagButtons`}>
          Include pagination
        </label>
      </div>
      {boolIncludePagButtons && (
        <>
          <Divider />
          {/* Form */}
          <Select
            label={translate("cEditForms.carouselPag_btnForm", false, null)}
            id={"carousel_pag_btnForm"}
            options={[
              { val: "square", label: translate("cEditForms.squareOrCircle", false, null) },
              { val: "bar", label: translate("cEditForms.bar", false, null) },
            ]}
            startValue={getCarouselStartVal("btnForm")}
            tooltipText={translate("cEditForms.carouselPag_ttBtnForm", false, null)}
          />
          <Select
            label={translate("cEditForms.borderRadius", false, null)}
            id={"carousel_pag_borderRadius"}
            options={[
              { val: 0, label: translate("cEditForms.square", false, null) },
              { val: 0.2, label: translate("cEditForms.roundedSmall", false, null) },
              { val: 0.4, label: translate("cEditForms.roundedMedium", false, null) },
              { val: 0.75, label: translate("cEditForms.roundedLarge", false, null) },
              { val: 1, label: translate("cEditForms.roundedExtraLarge", false, null) },
              { val: 1000, label: translate("cEditForms.pill", false, null) },
              { val: 50, label: translate("cEditForms.circle", false, null) },
            ]}
            startValue={getCarouselStartVal("borderRadius")}
            tooltipText={translate("cEditForms.tooltipBorderRadius", false, null)}
          />
          <Divider />
          {/* Size */}
          <Slider
            label={translate("cEditForms.carouselNav_btnSize", false, null)}
            id={"carousel_pag_btnSize"}
            min={0.5}
            max={3}
            step={0.1}
            startValue={getCarouselStartVal("btnSize")}
            tooltipText={translate("cEditForms.carouselPag_ttBtnSize", false, null)}
          />
          <Divider />
          {/* Bg color */}
          <ColorPicker
            label={translate("cEditForms.carouselNav_bgColor", false, null)}
            id={"carousel_pag_bgColor"}
            startValue={getCarouselStartVal("bgColor")}
            tooltipText={translate("cEditForms.carouselPag_ttBgColor", false, null)}
          />
          <ColorPicker
            label={translate("cEditForms.carouselNav_bgColorHover", false, null)}
            id={"carousel_pag_bgColorHover"}
            startValue={getCarouselStartVal("bgColorHover")}
            tooltipText={translate("cEditForms.carouselPag_ttBgColorHover", false, null)}
          />
          <Divider />
          {/* Border */}
          <Select
            label={translate("cEditForms.borderSides", false, null)}
            id={"carousel_pag_borderSide"}
            options={[
              { val: "none", label: translate("cEditForms.noBorder", false, null) },
              { val: "all", label: translate("cEditForms.allSides", false, null) },
              { val: "left", label: translate("cEditForms.left", false, null) },
              { val: "right", label: translate("cEditForms.right", false, null) },
              { val: "top", label: translate("cEditForms.top", false, null) },
              { val: "bottom", label: translate("cEditForms.bottom", false, null) },
              { val: "topbottom", label: translate("cEditForms.topAndBottom", false, null) },
              { val: "leftright", label: translate("cEditForms.leftAndRight", false, null) },
            ]}
            startValue={getCarouselStartVal("borderSide")}
            tooltipText={translate("cEditForms.tooltipBorderSides", false, null)}
          />
          <Select
            label={translate("cEditForms.borderType", false, null)}
            id={"carousel_pag_borderType"}
            options={[
              { val: "hidden", label: translate("cEditForms.hidden", false, null) },
              { val: "dotted", label: translate("cEditForms.dotted", false, null) },
              { val: "dashed", label: translate("cEditForms.dashed", false, null) },
              { val: "solid", label: translate("cEditForms.solid", false, null) },
              { val: "double", label: translate("cEditForms.double", false, null) },
              { val: "groove", label: translate("cEditForms.groove", false, null) },
              { val: "ridge", label: translate("cEditForms.ridge", false, null) },
              { val: "inset", label: translate("cEditForms.inset", false, null) },
              { val: "outset", label: translate("cEditForms.outset", false, null) },
            ]}
            startValue={getCarouselStartVal("borderType")}
            tooltipText={translate("cEditForms.tooltipBorderType", false, null)}
          />
          <Slider
            label={translate("cEditForms.borderSize", false, null)}
            id={"carousel_pag_borderWidth"}
            min={0}
            max={10}
            step={1}
            startValue={getCarouselStartVal("borderWidth")}
            tooltipText={translate("cEditForms.tooltipBorderSize", false, null)}
          />
          <ColorPicker
            label={translate("cEditForms.borderColor", false, null)}
            id={"carousel_pag_borderColor"}
            startValue={getCarouselStartVal("borderColor")}
            tooltipText={translate("cEditForms.tooltipBorderColor", false, null)}
          />
          <ColorPicker
            label={translate("cEditForms.borderColorHover", false, null)}
            id={"carousel_pag_borderColorHover"}
            startValue={getCarouselStartVal("borderColorHover")}
            tooltipText={translate("cEditForms.tooltipBorderColorHover", false, null)}
          />
          <Divider />
          {/* Transition */}
          <Slider
            label={translate("cEditForms.carouselNav_speed", false, null)}
            id={"carousel_pag_transition"}
            min={0}
            max={3}
            step={0.1}
            startValue={getCarouselStartVal("transition")}
            tooltipText={translate("cEditForms.carouselNav_ttSpeed", false, null)}
          />
        </>
      )}
    </>
  );
};

CarouselPagStyles.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(CarouselPagStyles);
