export const pMain = {
  errorLoadingProject: {
    en: "Error loading project. Please try again.",
    nl: "Er is een fout opgetreden tijdens het laten van het project. Probeer het aub opnieuw.",
  },
  useLargerScreenSize: {
    en: "Sitebuilder requires a larger screen size to run optimally",
    nl: "Sitebuilder heeft een groter scherm nodig om optimaal te kunnen werken",
  },
};
