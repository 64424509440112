import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { confirmColorScheme } from "../../actions/sb";

import { hslToRGB } from "../../lib/colorFunctions";
import { translate } from "../../translations/translations";

const ModalColorSchemeGenerator = ({ sb: { colorPicker, sbCssVars }, confirmColorScheme }) => {
  const closeBtn = useRef();
  const sliderSat = useRef();
  const sliderLum = useRef();
  const analog = useRef();
  const mono = useRef();
  const compl = useRef();
  const triad = useRef();
  const compound = useRef();
  const shades = useRef();

  const getRuleRef = (rule) => {
    return rule === "Analogous"
      ? analog
      : rule === "Monochromatic"
      ? mono
      : rule === "Complementary"
      ? compl
      : rule === "Triad"
      ? triad
      : rule === "Compound"
      ? compound
      : shades;
  };

  const [state, setState] = useState({
    hue: 360,
    sat: 100,
    lum: 50,
    calculatedColors: [
      {
        rule: "Analogous",
        hslColors: [
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
        ],
      },
      {
        rule: "Monochromatic",
        hslColors: [
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
        ],
      },
      {
        rule: "Complementary",
        hslColors: [
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
        ],
      },
      {
        rule: "Triad",
        hslColors: [
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
        ],
      },
      {
        rule: "Compound",
        hslColors: [
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
        ],
      },
      {
        rule: "Shades",
        hslColors: [
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
          [360, 100, 50],
        ],
      },
    ],
    selectedRuleRow: null,
  });
  const { hue, sat, lum, calculatedColors, selectedRuleRow } = state;

  const onChange = (e) => {
    let recalculatedColors = [];
    if (e.target.name === "hue") {
      updateSat(e.target.value);
      updateLum(e.target.value, sat);
      recalculatedColors = calculateColors(e.target.value, sat, lum);
    }
    if (e.target.name === "sat") {
      updateLum(hue, e.target.value);
      recalculatedColors = calculateColors(hue, e.target.value, lum);
    }
    if (e.target.name === "lum") {
      recalculatedColors = calculateColors(hue, sat, e.target.value);
    }
    setState({ ...state, [e.target.name]: e.target.value, calculatedColors: recalculatedColors });
  };

  const calculateColors = (h, s, l) => {
    let recalculatedColors = calculatedColors;
    recalculatedColors.forEach((colorRow) => {
      if (colorRow.rule === "Analogous") {
        let analogousS = 0;
        parseInt(s) > 95 ? (analogousS = parseInt(s) - 5) : (analogousS = parseInt(s) + 5);
        colorRow.hslColors = [
          [parseInt(h), parseInt(s), parseInt(l)],
          [parseInt(h) - 15 >= 0 ? parseInt(h) - 15 : parseInt(h) - 15 + 360, analogousS, parseInt(l) + 5],
          [parseInt(h) - 30 >= 0 ? parseInt(h) - 30 : parseInt(h) - 30 + 360, analogousS, parseInt(l) - 5],
          [parseInt(h) + 15 <= 360 ? parseInt(h) + 15 : parseInt(h) + 15 - 360, analogousS, parseInt(l) + 5],
          [parseInt(h) + 30 <= 360 ? parseInt(h) + 30 : parseInt(h) + 30 - 360, analogousS, parseInt(l) - 5],
        ];
      }
      if (colorRow.rule === "Monochromatic") {
        let monoS = parseInt(s) - 30;
        monoS < 35 && (monoS = parseInt(s) + 30);
        colorRow.hslColors = [
          [parseInt(h), parseInt(s), parseInt(l)],
          [parseInt(h), monoS, parseInt(l) + 8 <= 80 ? parseInt(l) + 8 : parseInt(l) + 8 - 50],
          [parseInt(h), parseInt(s), parseInt(l) - 40 >= 30 ? parseInt(l) - 40 : parseInt(l) - 40 + 50],
          [parseInt(h), monoS, parseInt(l) - 20 >= 30 ? parseInt(l) - 20 : parseInt(l) - 20 + 50],
          [parseInt(h), parseInt(s), parseInt(l) - 20 >= 30 ? parseInt(l) - 20 : parseInt(l) - 20 + 50],
        ];
      }
      if (colorRow.rule === "Complementary") {
        let complHue = parseInt(h) + 140;
        complHue > 360 && (complHue -= 360);
        colorRow.hslColors = [
          [h, s, l],
          [h, Math.min(parseInt(s) + 10, 100), 70],
          [complHue, Math.max(parseInt(s) - 10, 0), l],
          [h, Math.min(parseInt(s) + 20, 100), l],
          [complHue, s, 70],
        ];
      }
      if (colorRow.rule === "Triad") {
        colorRow.hslColors = [
          [parseInt(h), parseInt(s), parseInt(l)],
          [
            parseInt(h),
            parseInt(s) + 10 <= 100 ? parseInt(s) + 10 : parseInt(s) + 10 - 30,
            parseInt(l) + 10 <= 80 ? parseInt(l) + 10 : parseInt(l) + 10 - 50,
          ],
          [
            parseInt(h) + 120 <= 360 ? parseInt(h) + 120 : parseInt(h) + 120 - 360,
            parseInt(s) - 10 >= 40 ? parseInt(s) - 10 : parseInt(s) - 10 + 50,
            parseInt(l) + 5 <= 80 ? parseInt(l) + 5 : parseInt(l) + 5 - 50,
          ],
          [
            parseInt(h) + 240 <= 360 ? parseInt(h) + 240 : parseInt(h) + 240 - 360,
            parseInt(s) + 15 <= 100 ? parseInt(s) + 15 : parseInt(s) + 15 - 30,
            parseInt(l) - 5 >= 30 ? parseInt(l) - 5 : parseInt(l) - 5 + 50,
          ],
          [
            parseInt(h) + 240 <= 360 ? parseInt(h) + 240 : parseInt(h) + 240 - 360,
            parseInt(s) + 5 <= 100 ? parseInt(s) + 5 : parseInt(s) + 5 - 30,
            parseInt(l) + 5 <= 80 ? parseInt(l) + 5 : parseInt(l) + 5 - 50,
          ],
        ];
      }
      if (colorRow.rule === "Compound") {
        colorRow.hslColors = [
          [parseInt(h), parseInt(s), parseInt(l)],
          [parseInt(h) + 45 <= 360 ? parseInt(h) + 45 : parseInt(h) + 45 - 360, parseInt(s), parseInt(l)],
          [
            parseInt(h) + 45 <= 360 ? parseInt(h) + 45 : parseInt(h) + 45 - 360,
            parseInt(s) - 15 >= 30 ? parseInt(s) - 15 : parseInt(s) - 15 + 70,
            parseInt(l) - 10 >= 30 ? parseInt(l) - 10 : parseInt(l) - 10 + 50,
          ],
          [parseInt(h) - 165 >= 0 ? parseInt(h) - 165 : parseInt(h) - 165 + 360, parseInt(s), parseInt(l)],
          [
            parseInt(h) - 155 >= 0 ? parseInt(h) - 155 : parseInt(h) - 155 + 360,
            parseInt(s) - 5 >= 30 ? parseInt(s) - 5 : parseInt(s) - 5 + 70,
            parseInt(l) - 5 >= 30 ? parseInt(l) - 5 : parseInt(l) - 5 + 50,
          ],
        ];
      }
      if (colorRow.rule === "Shades") {
        colorRow.hslColors = [
          [h, s, 50],
          [h, s, 25],
          [h, s, 65],
          [h, s, 35],
          [h, s, 80],
        ];
      }
    });
    return recalculatedColors;
  };

  const updateSat = (targetHue) => {
    let css = `background: linear-gradient(left, hsl(${targetHue}, 0%, 50%) 0%, hsl(${targetHue}, 100%, 50%) 100%);
      background: -webkit-linear-gradient(left, hsl(${targetHue}, 0%, 50%) 0%, hsl(${targetHue}, 100%, 50%) 100%);
      background: -moz-linear-gradient(left, hsl(${targetHue}, 0%, 50%) 0%, hsl(${targetHue}, 100%, 50%) 100%);`;
    sliderSat.current.style.cssText = css;
  };

  const updateLum = (targetHue, targetSat) => {
    let css = `background: linear-gradient(left, hsl(${targetHue}, ${targetSat}%, 0%) 0%, hsl(${targetHue}, ${targetSat}%, 50%) 50%, hsl(${targetHue}, ${targetSat}%, 100%) 100%);
    background: -webkit-linear-gradient(left, hsl(${targetHue}, ${targetSat}%, 0%) 0%, hsl(${targetHue}, ${targetSat}%, 50%) 50%, hsl(${targetHue}, ${targetSat}%, 100%) 100%);
    background: -moz-linear-gradient(left, hsl(${targetHue}, ${targetSat}%, 0%) 0%, hsl(${targetHue}, ${targetSat}%, 50%) 50%, hsl(${targetHue}, ${targetSat}%, 100%) 100%);`;
    sliderLum.current.style.cssText = css;
  };

  const rgbColor = (h, s, l) => {
    let rgb = hslToRGB(h, s, l);
    return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
  };

  const selectRuleRow = (e) => {
    let target = e.currentTarget;
    // Get selected rule name
    let selectedRuleName = target.dataset.rule;
    // Remove selector classes from all rules
    removeSelectorClasses();
    // Update state
    if (selectedRuleName !== null) {
      if (selectedRuleName === selectedRuleRow) {
        // If selectedRuleName equals selectedRuleRow, it is being de-selected -> set state to null
        setState({ ...state, selectedRuleRow: null });
      } else {
        // If current click was to select a rule, add the selector class to this rule
        target.classList.add("CsGeneratorSelected");
        setState({ ...state, selectedRuleRow: selectedRuleName });
      }
    }
  };

  const removeSelectorClasses = () => {
    analog.current.classList.remove("CsGeneratorSelected");
    mono.current.classList.remove("CsGeneratorSelected");
    compl.current.classList.remove("CsGeneratorSelected");
    triad.current.classList.remove("CsGeneratorSelected");
    compound.current.classList.remove("CsGeneratorSelected");
    shades.current.classList.remove("CsGeneratorSelected");
  };

  const clickSelectColorScheme = () => {
    try {
      // Get array of hsl colors
      let arrColorSchemeRgbValues = calculatedColors
        .filter((color) => color.rule === selectedRuleRow)[0]
        .hslColors.map((hslColor, i) => hslToRGB(parseInt(hslColor[0]), parseInt(hslColor[1]), parseInt(hslColor[2])))
        .map((rgba) => `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3]})`);
      // Send to redux
      confirmColorScheme(arrColorSchemeRgbValues);
    } catch (error) {
      return;
    }
  };

  return (
    <div
      className="modal fade"
      id="ModalColorSchemeGenerator"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="ModalColorSchemeGeneratorLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="ModalColorSchemeGeneratorLabel">
              {translate("mModalColorSchemeGenerator.generateScheme", false, null)}
            </h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeBtn}></button>
          </div>
          <div className="modal-body">
            <div className="d-flex flex-column align-items-center">
              <h5 className="mb-3">{translate("mModalColorSchemeGenerator.chooseBaseColor", false, null)}</h5>
              <input
                type="range"
                min="0"
                max="360"
                step="1"
                id={`sliderHue`}
                name="hue"
                value={hue}
                onChange={onChange}
                className="cpSlider cpHue mb-2"
              />
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                id={`sliderSat`}
                name="sat"
                value={sat}
                onChange={onChange}
                className="cpSlider cpSat mb-2"
                ref={sliderSat}
              />
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                id={`sliderLum`}
                name="lum"
                value={lum}
                onChange={onChange}
                className="cpSlider cpLum mb-2"
                ref={sliderLum}
              />
              <h5 className="mt-4 mb-3">{translate("mModalColorSchemeGenerator.selectColorScheme", false, null)}</h5>
              {calculatedColors.map((colorRow) => (
                <div
                  className="d-flex px-4 py-2 CsGeneratorRuleRow"
                  id={`CsGenerator-${colorRow.rule}`}
                  key={colorRow.rule}
                  onClick={(e) => selectRuleRow(e)}
                  ref={getRuleRef(colorRow.rule)}
                  data-rule={colorRow.rule}
                >
                  <span className="CsGeneratorRuleName">{colorRow.rule}</span>
                  <div className="CsGeneratorColorRow">
                    {colorRow.hslColors.map((color, i) => (
                      <div className="CsGeneratorColor" style={{ backgroundColor: rgbColor(color[0], color[1], color[2]) }} key={i} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button type="button" className="btn btn-gray px-4" data-bs-dismiss="modal">
              {translate("mModalColorSchemeGenerator.close", false, null)}
            </button>
            <button
              type="button"
              className="btn btn-success px-4"
              data-bs-dismiss="modal"
              onClick={clickSelectColorScheme}
              disabled={selectedRuleRow === null}
            >
              {translate("mModalColorSchemeGenerator.confirmColorScheme", false, null)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalColorSchemeGenerator.propTypes = {
  sb: PropTypes.object.isRequired,
  confirmColorScheme: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, { confirmColorScheme })(ModalColorSchemeGenerator);
