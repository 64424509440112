import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Border from "./Border";
import BgColor from "./BgColor";
import Padding from "./Padding";
import BorderRadius from "./BorderRadius";
import BoxShadow from "./BoxShadow";
import Transition from "./Transition";
import Divider from "./formComponents/Divider";

const Card16 = ({ sb: { startStyles } }) => {
  return (
    <>
      <Padding />
      <Divider />
      <BgColor />
      <Divider />
      <Border />
      <Divider />
      <BorderRadius />
      <Divider />
      <BoxShadow />
      <Divider />
      <Transition />
    </>
  );
};

Card16.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Card16);
