import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Divider from "./formComponents/Divider";
import TextInput from "./formComponents/TextInput";
import Tooltip from "./formComponents/Tooltip";

import { formGetStartValue, EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { isValidURL } from "../../../../lib/generalFunctions";
import { translate } from "../../../../translations/translations";

const FormActions = ({ auth: { workspace, audiences, reactiveEmailCampaigns }, sb: { startStyles, selectedElement } }) => {
  const ACTION_OBJ = { actionType: "", actionData: "", errMsg: "" };
  const actions = [
    { actionType: "1", label: translate("cEditForms.formActions_sendMsgCf", false, null) },
    { actionType: "2", label: translate("cEditForms.formActions_subToAudience", false, null) },
    { actionType: "3", label: translate("cEditForms.formActions_sendEmail", false, null) },
    { actionType: "4", label: translate("cEditForms.formActions_redirectToPage", false, null) },
  ];

  const [formActions, setFormActions] = useState([]);

  useEffect(() => {
    setFormActions(JSON.parse(formGetStartValue(startStyles, "formDiv_action")));
  }, [formGetStartValue(startStyles, "formDiv_action"), selectedElement]);

  const clickAddAction = () => {
    setFormActions((prev) => [...prev, ACTION_OBJ]);
  };

  const clickRemoveAction = (i) => {
    setFormActions((prev) => prev.filter((a, j) => j !== i));
  };

  const onSelectActionType = (actionType, i) => {
    setFormActions((prev) => prev.map((a, j) => (j === i ? { ...a, actionType } : a)));
  };

  const onTypeRedirectDest = (dest, i) => {
    setFormActions((prev) =>
      prev.map((a, j) => (j === i ? { ...a, actionData: dest, errMsg: isValidURL(dest) ? "" : translate("cEditForms.notValidUrl", false, null) } : a))
    );
  };

  const onSelectActionData = (actionData, i) => {
    setFormActions((prev) => prev.map((a, j) => (j === i ? { ...a, actionData, errMsg: "" } : a)));
  };

  return (
    <>
      {/* <TextInput
            label={"Form name"}
            id={"formDiv_name"}
            startValue={formGetStartValue(startStyles, "formDiv_name")}
            tooltipText={`Set the name of the form`}
          /> */}
      <div className="fontSize09 mb-3">
        <p className="my-2 text-bold">{translate("cEditForms.formActions_actionsOnFormSubmission", false, null)}</p>
        {formActions.length === 0 ? (
          <p className="my-2 text-italic">{translate("cEditForms.formActions_noActionsSet", false, null)}</p>
        ) : (
          formActions.map((formAction, i) => (
            <div key={`formAction${i}`}>
              {/* Action header */}
              <div className="mt-2 mb-1 d-flex justify-content-between align-items-center fontSize08">
                <p className="m-0">
                  {translate("cEditForms.formActions_formAction", false, null)} {i + 1}
                </p>
                <i
                  className="text-danger cursorPointer fa-regular fa-trash-can"
                  onClick={() => clickRemoveAction(i)}
                  title={translate("cEditForms.formActions_removeAction", false, null)}
                ></i>
              </div>
              {/* Set action type */}
              <div className="d-flex align-items-center w-100 mb-1">
                <div className="w-50">
                  {translate("cEditForms.action", false, null)}:
                  <Tooltip text={translate("cEditForms.formActions_ttSelectAction", false, null)} />
                </div>
                <div className="w-50">
                  <select
                    className="form-select form-select-sm"
                    id={`${EDIT_FORM_ID_PREFIX}formDiv_action${i}actionType`}
                    value={formActions[i].actionType}
                    onChange={(e) => onSelectActionType(e.target.value, i)}
                  >
                    <option value="">{translate("cEditForms.formActions_selectAction", false, null)}</option>
                    {actions.map((action) => (
                      <option key={action.actionType} value={action.actionType}>
                        {action.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Set action data */}
              <div className="d-flex align-items-center w-100 mb-1">
                {formActions[i].actionType === "" ? (
                  <p className="text-italic">{translate("cEditForms.formActions_selectAction", false, null)}</p>
                ) : formActions[i].actionType === "1" ? (
                  <>
                    {/* Action - Add msg to contact form */}
                    <div className="w-50">
                      {translate("cEditForms.formActions_selectCf", false, null)}:
                      <Tooltip text={translate("cEditForms.formActions_ttSelectCf", false, null)} />
                    </div>
                    <div className="w-50">
                      <select
                        className="form-select form-select-sm"
                        value={formActions[i].actionData}
                        onChange={(e) => onSelectActionData(e.target.value, i)}
                        id={`${EDIT_FORM_ID_PREFIX}formDiv_action${i}actionData`}
                      >
                        <option value="">{translate("cEditForms.formActions_selectCf", false, null)}</option>
                        {workspace.contactForms.map((cf) => (
                          <option key={cf.formId} value={cf.formId}>
                            {cf.formName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : formActions[i].actionType === "2" ? (
                  <>
                    {/* Action - Subscribe to audience */}
                    <div className="w-50">
                      {translate("cEditForms.formActions_selectAudience", false, null)}:
                      <Tooltip text={translate("cEditForms.formActions_ttSelectAudience", false, null)} />
                    </div>
                    <div className="w-50">
                      <select
                        className="form-select form-select-sm"
                        value={formActions[i].actionData}
                        onChange={(e) => onSelectActionData(e.target.value, i)}
                        id={`${EDIT_FORM_ID_PREFIX}formDiv_action${i}actionData`}
                      >
                        <option value="">{translate("cEditForms.formActions_selectAudience", false, null)}</option>
                        {audiences.map((audience) => (
                          <option key={audience._id} value={audience._id}>
                            {audience.audienceName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : formActions[i].actionType === "3" ? (
                  <>
                    {/* Action - Send email */}
                    <div className="d-flex w-100">
                      <div className="d-flex flex-column w-100">
                        <div className="d-flex align-items-center w-100">
                          <div className="w-50">
                            {translate("cEditForms.formActions_selectEmailCamp", false, null)}:
                            <Tooltip text={translate("cEditForms.formActions_ttSelectEmailCamp", false, null)} />
                          </div>
                          <div className="w-50">
                            <select
                              className="form-select form-select-sm"
                              value={formActions[i].actionData}
                              onChange={(e) => onSelectActionData(e.target.value, i)}
                              id={`${EDIT_FORM_ID_PREFIX}formDiv_action${i}actionData`}
                            >
                              <option value="">{translate("cEditForms.formActions_selectEmailCamp", false, null)}</option>
                              {reactiveEmailCampaigns.map((campaign) => (
                                <option key={campaign._id} value={campaign._id}>
                                  {campaign.campaignName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {formActions[i].actionData !== "" &&
                          reactiveEmailCampaigns.filter((campaign) => campaign._id === formActions[i].actionData)[0].dynamicContentData.length >
                            0 && (
                            <div className="d-flex align-items-center w-100 mt-1">
                              <p className="m-0 fontSize08">
                                {translate("cEditForms.formActions_confirmDynVars", false, null)}:{" "}
                                {reactiveEmailCampaigns
                                  .filter((campaign) => campaign._id === formActions[i].actionData)[0]
                                  .dynamicContentData.map((dynVar, i) => (
                                    <span key={i}>
                                      {i > 0 && ", "}
                                      {dynVar.dynamicVar} {translate("cEditForms.formActions_dynVarFromInputfieldName", false, null)} "{dynVar.key}"
                                    </span>
                                  ))}
                              </p>
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {/* Action - Redirect */}
                    <div className="w-50">
                      {translate("cEditForms.destination", false, null)}:
                      <Tooltip text={translate("cEditForms.formActions_ttDest", false, null)} />
                    </div>
                    <div className="w-50">
                      <input
                        type="text"
                        className={`form-control form-control-sm ${formActions[i].errMsg === "" ? "is-valid" : "is-invalid"}`}
                        value={formActions[i].actionData}
                        onChange={(e) => onTypeRedirectDest(e.target.value, i)}
                        id={`${EDIT_FORM_ID_PREFIX}formDiv_action${i}actionData`}
                      />
                      <div className="invalid-feedback fontSize08">{formActions[i].errMsg}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))
        )}
        {/* Add additional action */}
        <button className="btn btn-sm btn-outline-success mt-2 w-100" onClick={clickAddAction}>
          {translate("cEditForms.formActions_addAction", false, null)}
        </button>
      </div>
      <Divider />
      <TextInput
        label={translate("cEditForms.formActions_msgAfterSubmission", false, null)}
        id={"formDiv_successMsg"}
        startValue={formGetStartValue(startStyles, "formDiv_successMsg")}
        tooltipText={translate("cEditForms.formActions_ttMsgAfterSubmission", false, null)}
      />
    </>
  );
};

FormActions.propTypes = {
  auth: PropTypes.object.isRequired,
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sb: state.sb,
});

export default connect(mapStateToProps, null)(FormActions);
