export const template25 = {
  id: "660030336fa6d2823b9e2c8e",
  name: { en: "Web developer personal resume", nl: "Webontwikkelaar CV website" },
  desc: { en: "Personal resume website for a web developer", nl: "CV website voor een webontwikkelaar" },
  categories: ["tech", "services"],
  sbPages: [
    {
      pageId: "8d9446ca-de75-41b2-9d59-b2cc9a886afc",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "pcJ1NN",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-6-component-pcJ1NN"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "N0tKcY",
              type: "",
              htmlTagName: "div",
              classes: ["hero-6-wrapper-pcJ1NN"],
              styles: [],
              attributes: [{ property: "data-herowrapper", value: "true" }],
              content: "",
              children: [
                {
                  childId: "Hhy0DI",
                  type: "",
                  htmlTagName: "img",
                  classes: ["hero-6-img-pcJ1NN"],
                  styles: [],
                  attributes: [
                    { property: "alt", value: "Image" },
                    { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-6.jpg" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "yOU0H6",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-6-content-pcJ1NN"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "hero1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "M8qRIT",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-6-title-pcJ1NN", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "span",
                          classes: [],
                          styles: [{ property: "color", value: "rgb(250,250,250)" }],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "strong",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "I'm David Pato",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "span",
                          classes: [],
                          styles: [{ property: "color", value: "rgba(149, 30, 200, 1)" }],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "strong",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: ".",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "iePaiX",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-6-text-pcJ1NN", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Experienced web developer open for freelance projects",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "zGYFLU",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-10-component-zGYFLU"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "fO3Q8z",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "DxsgcM",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "card-10-col-zGYFLU", "col-md-9", "col-lg-6"],
                  styles: [],
                  attributes: [{ property: "data-name", value: "element_col" }],
                  content: "",
                  children: [
                    {
                      childId: "NyfmWY",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-10-wrapper-zGYFLU"],
                      styles: [],
                      attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "xvgMy7",
                          type: "",
                          htmlTagName: "img",
                          classes: ["card-10-avatar-zGYFLU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-dp-purple.png" },
                            { property: "alt", value: "DP" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "FdtI6r",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-10-title-zGYFLU", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem Ipsum",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "PC5E4o",
                          type: "",
                          htmlTagName: "p",
                          classes: ["toGrow", "card-10-text-zGYFLU", "w-100", "w-md-100", "w-lg-100"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "lfN6IR",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-7-component-lfN6IR"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "KZeG70",
              type: "",
              htmlTagName: "a",
              classes: ["basics-7-link-readmore-lfN6IR"],
              styles: [],
              attributes: [
                { property: "href", value: "#!" },
                { property: "data-href", value: "#!" },
                { property: "data-target", value: "_self" },
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "button" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "See my resume", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "tb1GAA",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-9-component-tb1GAA"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "fpcUVf",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-9-row-tb1GAA"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "wyv2vV",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-first", "card-9-col-tb1GAA"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "mbR6cP",
                      type: "",
                      htmlTagName: "img",
                      classes: ["card-9-img-tb1GAA"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/coding1.jpg" },
                        { property: "alt", value: "" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "F5KgjX",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "card-9-col-tb1GAA"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "card7" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "rPfvIn",
                      type: "",
                      htmlTagName: "h4",
                      classes: ["card-9-title-tb1GAA", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Main software skills & experiences",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "bAIIoJ",
                      type: "",
                      htmlTagName: "p",
                      classes: ["card-9-text-tb1GAA", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis ipsum nam odit, necessitatibus unde.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "T3ohyY",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["row", "align-items-start", "justify-content-center"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "R21OnV",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "card-9-icon-col-tb1GAA"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "R9WKns",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-9-icon-wrapper-tb1GAA"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "CYS9fZ",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["d-flex"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "lPmleh",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["card-9-iconwrapper-tb1GAA"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "div" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-iconwrapper", value: "true" },
                                        { property: "data-texteditable", value: "false" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "eAo91t",
                                          type: "",
                                          htmlTagName: "span",
                                          classes: ["icon", "fa-brands", "fa-js"],
                                          styles: [],
                                          attributes: [{ property: "data-checkparent", value: "true" }],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "dYF5OL",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["toGrow"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "NXXPCM",
                                          type: "",
                                          htmlTagName: "h6",
                                          classes: ["card-9-icon-title-tb1GAA", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "JavaScript",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "g1o66N",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["toGrow", "card-9-icon-text-tb1GAA", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Lorem ipsum dolor sit amet.",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "Ao2Fl5",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "card-9-icon-col-tb1GAA"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "yHvgUh",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-9-icon-wrapper-tb1GAA"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "dnyBAO",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["d-flex"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "dwXKan",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["card-9-iconwrapper-tb1GAA"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "div" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-iconwrapper", value: "true" },
                                        { property: "data-texteditable", value: "false" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "hqmB88",
                                          type: "",
                                          htmlTagName: "span",
                                          classes: ["icon", "fa-brands", "fa-css3-alt"],
                                          styles: [],
                                          attributes: [{ property: "data-checkparent", value: "true" }],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "lSzJPL",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["toGrow"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "Zq1DMo",
                                          type: "",
                                          htmlTagName: "h6",
                                          classes: ["card-9-icon-title-tb1GAA", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "HTML & CSS",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "yfpgU5",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["toGrow", "card-9-icon-text-tb1GAA", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Lorem ipsum dolor sit amet.",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "InFNDn",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-4", "col-lg-4", "card-9-icon-col-tb1GAA"],
                          styles: [],
                          attributes: [{ property: "data-name", value: "element_col" }],
                          content: "",
                          children: [
                            {
                              childId: "Bnhxxt",
                              type: "",
                              htmlTagName: "div",
                              classes: ["flexSameHeight", "card-9-icon-wrapper-tb1GAA"],
                              styles: [],
                              attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "W91wrv",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["d-flex"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ihXmCV",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["card-9-iconwrapper-tb1GAA"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "div" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-iconwrapper", value: "true" },
                                        { property: "data-texteditable", value: "false" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "dYj1wc",
                                          type: "",
                                          htmlTagName: "span",
                                          classes: ["icon", "fa-brands", "fa-wordpress-simple"],
                                          styles: [],
                                          attributes: [{ property: "data-checkparent", value: "true" }],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "nRaxzY",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["toGrow"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "aeSi2d",
                                          type: "",
                                          htmlTagName: "h6",
                                          classes: ["card-9-icon-title-tb1GAA", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "h" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "WordPress",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "HLmASh",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["toGrow", "card-9-icon-text-tb1GAA", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Lorem ipsum dolor sit amet.",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "qGiGlp",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-qGiGlp"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "mi1OMx",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-qGiGlp", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Portfolio", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "cGfwbh",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "imggallery-1-component-cGfwbh"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Rhr3sV",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "imggallery-1-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "GrTohB",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-1-col-cGfwbh"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "zEcgJa",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-cGfwbh"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "hWUoc1",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-cGfwbh"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/65fdf57870bb2bca77a20e5f.png" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "lGgxSI",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-cGfwbh"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "TDcj4k",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-cGfwbh", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "VqOwa7",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-cGfwbh", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "t3zMqs",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-1-col-cGfwbh"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "VJUiFb",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-cGfwbh"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "wQKFm8",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-cGfwbh"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/625a78b9f821481e4aeb667d.png" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "Ldtbdf",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-cGfwbh"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "B4rDMy",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-cGfwbh", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "DsNKsV",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-cGfwbh", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "nixVHz",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-1-col-cGfwbh"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "MO5rEI",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-cGfwbh"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "WGO55w",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-cGfwbh"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/638b2ed6fc67a1df0b9474e6.png" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "R7HHIB",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-cGfwbh"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "ID2X4n",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-cGfwbh", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "H2bU5q",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-cGfwbh", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "N527KL",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-1-col-cGfwbh"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ctxvo5",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-1-wrapper-cGfwbh"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "H1rLEG",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-1-img-cGfwbh"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/638cc577340b4dc84daf0f6a.png" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "Gx8ChF",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-1-overlay-content-cGfwbh"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "XDbqbL",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-1-title-cGfwbh", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "OW0bL9",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-1-text-cGfwbh", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "nEye10",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "resume-2-component-nEye10"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "resume" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "KvFtAa",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "resume-2-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "owtg17",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-10", "col-lg-6", "resume-2-wrappercol-nEye10"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "H31nQd",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["resume-2-title-nEye10", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "h" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Resume", children: [] },
                      ],
                    },
                    {
                      childId: "UosK2h",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["resume-2-blockwrapper"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "QKaAsj",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["resume-2-elementwrapper-nEye10"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "QPGMbo",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["resume-2-blocktitle-nEye10", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "h" },
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Experience",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "vJrU93",
                              type: "element",
                              htmlTagName: "div",
                              classes: ["resume-2-div-item-nEye10"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "resume2" },
                                { property: "data-editable", value: "true" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "wvxkdh",
                                  type: "",
                                  htmlTagName: "h4",
                                  classes: ["resume-2-itemtitle-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "h" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "2021 - Present",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "YTCZk9",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["row", "resume-2-itemrow"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "FQQ1mf",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["col-12", "col-md-4", "col-lg-4", "resume-2-itemcol-nEye10"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "iBe46R",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-position1-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "JOB POSITION",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "jCtGzq",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-position2-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Company name",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "c3irNc",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-position3-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Location",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "NLYhAg",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["col-12", "col-md-8", "col-lg-8", "resume-2-itemcol-nEye10"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "PMLPCY",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-itemdesc-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content:
                                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur similique itaque quos, natus dolorum eaque!",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "cULLt3",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-itemdesc-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem, cupiditate!",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "ET8M3d",
                              type: "element",
                              htmlTagName: "div",
                              classes: ["resume-2-div-item-nEye10"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "resume2" },
                                { property: "data-editable", value: "true" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "V6qR9s",
                                  type: "",
                                  htmlTagName: "h4",
                                  classes: ["resume-2-itemtitle-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "h" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "2018 - 2021",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "pO9fxK",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["row", "resume-2-itemrow"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "kBED2a",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["col-12", "col-md-4", "col-lg-4", "resume-2-itemcol-nEye10"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "ZZ5IZg",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-position1-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "JOB POSITION",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "vJMKDn",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-position2-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Company name",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "D0DjEp",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-position3-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Location",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "atnZBU",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["col-12", "col-md-8", "col-lg-8", "resume-2-itemcol-nEye10"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "fpkIXX",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-itemdesc-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content:
                                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur similique itaque quos, natus dolorum eaque!",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "pDo2Ag",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-itemdesc-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem, cupiditate!",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "aAwO8R",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["resume-2-elementwrapper-nEye10"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "XkpQBN",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["resume-2-blocktitle-nEye10", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "h" },
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Education",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "azlj7b",
                              type: "element",
                              htmlTagName: "div",
                              classes: ["resume-2-div-item-nEye10"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "resume2" },
                                { property: "data-editable", value: "true" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "yBVlzC",
                                  type: "",
                                  htmlTagName: "h4",
                                  classes: ["resume-2-itemtitle-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "h" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "2017 - 2018",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "vcXGmV",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["row", "resume-2-itemrow"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "eGOhtX",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["col-12", "col-md-4", "col-lg-4", "resume-2-itemcol-nEye10"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "gjZCYN",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-position1-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "INSTITUTION NAME",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "HoaX5I",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-position2-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Degree",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "qKQgnN",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-position3-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Location",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "q7mSC3",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["col-12", "col-md-8", "col-lg-8", "resume-2-itemcol-nEye10"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "QJu1KP",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-itemdesc-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content:
                                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur similique itaque quos, natus dolorum eaque!",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "mNezgp",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-itemdesc-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem, cupiditate!",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "KNmi4L",
                              type: "element",
                              htmlTagName: "div",
                              classes: ["resume-2-div-item-nEye10"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "resume2" },
                                { property: "data-editable", value: "true" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "gI4SMI",
                                  type: "",
                                  htmlTagName: "h4",
                                  classes: ["resume-2-itemtitle-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "h" },
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "2014 - 2017",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "scVDh5",
                                  type: "",
                                  htmlTagName: "div",
                                  classes: ["row", "resume-2-itemrow"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Hx0EEm",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["col-12", "col-md-4", "col-lg-4", "resume-2-itemcol-nEye10"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "tw4Mao",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-position1-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "INSTITUTION NAME",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "Zgsnka",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-position2-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Degree",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "EXnz2X",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-position3-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Location",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "w7QJty",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["col-12", "col-md-8", "col-lg-8", "resume-2-itemcol-nEye10"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "ndUYV6",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-itemdesc-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content:
                                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur similique itaque quos, natus dolorum eaque!",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "eduO8X",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["resume-2-itemdesc-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "p" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem, cupiditate!",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "ngI4Dq",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["resume-2-elementwrapper-nEye10"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "uohtTb",
                              type: "element",
                              htmlTagName: "div",
                              classes: ["resume-2-div-item-nEye10"],
                              styles: [],
                              attributes: [
                                { property: "data-name", value: "resume2" },
                                { property: "data-editable", value: "true" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "oVIBnU",
                                  type: "block",
                                  htmlTagName: "div",
                                  classes: ["resume-2-bulletitem-nEye10wrapper"],
                                  styles: [],
                                  attributes: [{ property: "data-name", value: "block" }],
                                  content: "",
                                  children: [
                                    {
                                      childId: "KW7mzA",
                                      type: "element",
                                      htmlTagName: "div",
                                      classes: ["resume-2-bulletitem-nEye10"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "Fqj3TJ",
                                          type: "",
                                          htmlTagName: "h3",
                                          classes: ["resume-2-bullettitle-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "h" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Skills",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "mhQUUF",
                                          type: "block",
                                          htmlTagName: "div",
                                          classes: ["row", "resume-2-itemrow"],
                                          styles: [],
                                          attributes: [{ property: "data-name", value: "block" }],
                                          content: "",
                                          children: [
                                            {
                                              childId: "dJCnme",
                                              type: "element",
                                              htmlTagName: "div",
                                              classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol-nEye10"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "gcIdUm",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["resume-2-bullettext-nEye10"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-name", value: "p" },
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-listmarker", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Lorem ipsum dolor sit amet",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "DAu2Ik",
                                              type: "element",
                                              htmlTagName: "div",
                                              classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol-nEye10"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "QEwmGg",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["resume-2-bullettext-nEye10"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-name", value: "p" },
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-listmarker", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Lorem ipsum dolor sit amet",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "BIV5bb",
                                              type: "element",
                                              htmlTagName: "div",
                                              classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol-nEye10"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "ZkC1Jr",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["resume-2-bullettext-nEye10"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-name", value: "p" },
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-listmarker", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Lorem ipsum dolor sit amet",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "HrQW0b",
                                              type: "element",
                                              htmlTagName: "div",
                                              classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol-nEye10"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "dZtwJa",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["resume-2-bullettext-nEye10"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-name", value: "p" },
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-listmarker", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Lorem ipsum dolor sit amet",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "eyh2JT",
                                      type: "element",
                                      htmlTagName: "div",
                                      classes: ["resume-2-bulletitem-nEye10"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "EW1wTp",
                                          type: "",
                                          htmlTagName: "h3",
                                          classes: ["resume-2-bullettitle-nEye10", "w-100", "w-md-100", "w-lg-100"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-name", value: "h" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Languages",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "TDR6EV",
                                          type: "block",
                                          htmlTagName: "div",
                                          classes: ["row", "resume-2-itemrow"],
                                          styles: [],
                                          attributes: [{ property: "data-name", value: "block" }],
                                          content: "",
                                          children: [
                                            {
                                              childId: "ltkK8d",
                                              type: "element",
                                              htmlTagName: "div",
                                              classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol-nEye10"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "IS1vaE",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["resume-2-bullettext-nEye10"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-name", value: "p" },
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-listmarker", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "English",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "uxcmNl",
                                              type: "element",
                                              htmlTagName: "div",
                                              classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol-nEye10"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "QiL5DO",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["resume-2-bullettext-nEye10"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-name", value: "p" },
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-listmarker", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "Spanish",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "ON1F0j",
                                              type: "element",
                                              htmlTagName: "div",
                                              classes: ["col-12", "col-md-6", "col-lg-6", "resume-2-skillitemcol-nEye10"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "Dg4g3m",
                                                  type: "",
                                                  htmlTagName: "p",
                                                  classes: ["resume-2-bullettext-nEye10"],
                                                  styles: [],
                                                  attributes: [
                                                    { property: "data-name", value: "p" },
                                                    { property: "data-editable", value: "true" },
                                                    { property: "data-texteditable", value: "true" },
                                                    { property: "data-listmarker", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "French",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "strn2g",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-6-component-strn2g"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "CpGGrB",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-6-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "l2J6di",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "contactus-6-col-strn2g"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Lp1aae",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["contactus-6-contacttitle-strn2g"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "h" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact", children: [] },
                      ],
                    },
                    {
                      childId: "DFyGaS",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-contacttext-strn2g"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Looking forward to hearing from you!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "JqJ39j",
                      type: "",
                      htmlTagName: "h5",
                      classes: ["contactus-6-subtitle-strn2g"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Phone", children: [] },
                      ],
                    },
                    {
                      childId: "UgCzsu",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-subtext-strn2g"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "123 456 7890",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "p2bJwo",
                      type: "",
                      htmlTagName: "h5",
                      classes: ["contactus-6-subtitle-strn2g"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Email", children: [] },
                      ],
                    },
                    {
                      childId: "rdKWgK",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-subtext-strn2g"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "email@site.com",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "sVY0pJ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "iSRFnU",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-strn2g"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "ZI56LC",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-strn2g"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "tOzsp0",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-strn2g"],
                          styles: [],
                          attributes: [
                            { property: "name", value: "name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                            { property: "type", value: "text" },
                            { property: "placeholder", value: "Your name" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "sREc94",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-strn2g"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "TSnC4x",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-strn2g"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "aOwct3",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-strn2g"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "BmWdom",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-strn2g"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "SKDnoM",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-strn2g"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "tLNPFB",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-strn2g"],
                          styles: [],
                          attributes: [
                            { property: "name", value: "subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                            { property: "type", value: "text" },
                            { property: "placeholder", value: "Subject" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "VIhCFW",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-strn2g"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "KoLSXs",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-strn2g"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "w8hPaR",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-6-inputfield-strn2g"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                            { property: "placeholder", value: "Message" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "qB5nRK",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-6-button-strn2gwrapper-strn2g"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "ehT3Sm",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-6-button-strn2g"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "GtzNB8",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-3-component-GtzNB8"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "uVEqAi",
              type: "",
              htmlTagName: "img",
              classes: ["basics-3-img-GtzNB8"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "img" },
                { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-dp-purple.png" },
                { property: "alt", value: "DP Logo" },
              ],
              content: "",
              children: [],
            },
          ],
        },
      ],
      _id: "6600276fce19f27d76705a1b",
    },
  ],
  sbCustomCss: [
    {
      componentId: "pcJ1NN",
      classes: [
        {
          className: "section.hero-6-component-pcJ1NN",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-6-component-pcJ1NN", pseudo: "hover", rules: [] },
        {
          className: "hero-6-component-pcJ1NN",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-6-component-pcJ1NN", pseudo: "hover", rules: [] },
        {
          className: "hero-6-wrapper-pcJ1NN",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "75vh" },
          ],
        },
        {
          className: "hero-6-img-pcJ1NN",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-6-content-pcJ1NN",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "hero-6-text-pcJ1NN",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-6-text-pcJ1NN", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-6-title-pcJ1NN",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-6-title-pcJ1NN", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
      ],
    },
    {
      componentId: "zGYFLU",
      classes: [
        {
          className: "section.card-10-component-zGYFLU",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.card-10-component-zGYFLU", pseudo: "hover", rules: [] },
        {
          className: "card-10-title-zGYFLU",
          pseudo: "",
          rules: [
            { property: "margin", value: "0.5rem 0 0 0" },
            { property: "color", value: "rgba(149, 30, 200, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-10-title-zGYFLU", pseudo: "hover", rules: [] },
        {
          className: "card-10-text-zGYFLU",
          pseudo: "",
          rules: [
            { property: "margin", value: "1rem 0 0 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "card-10-text-zGYFLU", pseudo: "hover", rules: [] },
        {
          className: "card-10-wrapper-zGYFLU",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-items", value: "center" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-wrapper-zGYFLU",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-10-col-zGYFLU",
          pseudo: "",
          rules: [
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        { className: "card-10-col-zGYFLU", pseudo: "hover", rules: [] },
        {
          className: "card-10-avatar-zGYFLU",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "100px" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-self", value: "center" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-10-avatar-zGYFLU",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "lfN6IR",
      classes: [
        {
          className: "section.basics-7-component-lfN6IR",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-7-component-lfN6IR", pseudo: "hover", rules: [] },
        {
          className: "basics-7-component-lfN6IR",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-7-component-lfN6IR", pseudo: "hover", rules: [] },
        {
          className: "basics-7-link-readmore-lfN6IR",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(149, 30, 200, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(149, 30, 200, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "1rem 4.5rem 1rem 4.5rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-7-link-readmore-lfN6IR",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(206, 137, 236, 1)" },
            { property: "border-color", value: "rgba(149, 30, 200, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "tb1GAA",
      classes: [
        {
          className: "section.card-9-component-tb1GAA",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "2rem" },
            { property: "background", pseudo: "", value: "rgba(246, 233, 252, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(246, 233, 252, 1)" },
          ],
        },
        { className: "section.card-9-component-tb1GAA", pseudo: "hover", rules: [] },
        { className: "card-9-row-tb1GAA", pseudo: "", rules: [] },
        {
          className: "card-9-col-tb1GAA",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 0.5rem 0 0.5rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        { className: "card-9-col-tb1GAA", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(0, 0, 0, 0)" }] },
        { className: "card-9-card-col-tb1GAA", pseudo: "", rules: [{ property: "padding", value: "0.5rem 0 0.5rem 0" }] },
        { className: "card-9-card-col-tb1GAA", pseudo: "hover", rules: [] },
        { className: "card-9-card-wrapper-tb1GAA", pseudo: "", rules: [] },
        { className: "card-9-card-wrapper-tb1GAA", pseudo: "hover", rules: [] },
        {
          className: "card-9-text-tb1GAA",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-9-text-tb1GAA", pseudo: "hover", rules: [] },
        { className: "card-9-icon-col-tb1GAA", pseudo: "", rules: [] },
        { className: "card-9-icon-col-tb1GAA", pseudo: "hover", rules: [] },
        { className: "card-9-icon-wrapper-tb1GAA", pseudo: "", rules: [] },
        { className: "card-9-icon-wrapper-tb1GAA", pseudo: "hover", rules: [] },
        {
          className: "card-9-icon-text-tb1GAA",
          pseudo: "",
          rules: [
            { property: "margin", value: "0.5rem 0 0 1rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "card-9-icon-text-tb1GAA", pseudo: "hover", rules: [] },
        {
          className: "card-9-iconwrapper-tb1GAA",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "color", value: "rgba(149, 30, 200, 1)" },
            { property: "font-size", value: "2rem" },
          ],
        },
        { className: "card-9-iconwrapper-tb1GAA", pseudo: "hover", rules: [] },
        {
          className: "card-9-icon-title-tb1GAA",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(149, 30, 200, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 1rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-9-icon-title-tb1GAA", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "card-9-img-tb1GAA",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "card-9-img-tb1GAA",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "card-9-title-tb1GAA",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(149, 30, 200, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 1.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-9-title-tb1GAA", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "cGfwbh",
      classes: [
        {
          className: "section.imggallery-1-component-cGfwbh",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.imggallery-1-component-cGfwbh", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-col-cGfwbh",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "stretch" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "imggallery-1-img-cGfwbh",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "imggallery-1-img-cGfwbh", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-title-cGfwbh",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "imggallery-1-title-cGfwbh", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-text-cGfwbh",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(230, 230, 230, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "imggallery-1-text-cGfwbh", pseudo: "hover", rules: [] },
        {
          className: "imggallery-1-wrapper-cGfwbh",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(149, 30, 200, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "imggallery-1-wrapper-cGfwbh",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(149, 30, 200, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)" },
          ],
        },
        {
          className: "imggallery-1-wrapper-cGfwbh",
          pseudo: "hover .imggallery-1-overlay-content-cGfwbh",
          rules: [
            { property: "visibility", value: "visible" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "imggallery-1-overlay-content-cGfwbh",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "visibility", value: "hidden" },
            { property: "opacity", value: "0" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "background", value: "rgba(206, 137, 236, 0.5)" },
          ],
        },
        { className: "imggallery-1-overlay-content-cGfwbh", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "qGiGlp",
      classes: [
        {
          className: "section.basics-1-component-qGiGlp",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-qGiGlp", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-qGiGlp",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-qGiGlp", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-qGiGlp",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(149, 30, 200, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-qGiGlp", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "strn2g",
      classes: [
        {
          className: "section.contactus-6-component-strn2g",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "2rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.contactus-6-component-strn2g", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-col-strn2g",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        { className: "contactus-6-contacttext-strn2g", pseudo: "", rules: [{ property: "margin", value: "0 0 2rem 0" }] },
        { className: "contactus-6-contacttext-strn2g", pseudo: "hover", rules: [] },
        { className: "contactus-6-subtext-strn2g", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        { className: "contactus-6-subtext-strn2g", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-button-strn2gwrapper-strn2g",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-6-button-strn2gwrapper-strn2g", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-label-strn2g",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-6-label-strn2g", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-contacttitle-strn2g",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(149, 30, 200, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "contactus-6-contacttitle-strn2g",
          pseudo: "hover",
          rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }],
        },
        {
          className: "contactus-6-subtitle-strn2g",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(149, 30, 200, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "contactus-6-subtitle-strn2g", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "contactus-6-button-strn2g",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(149, 30, 200, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "padding", value: "1rem 2rem 1rem 2rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "contactus-6-button-strn2g",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(206, 137, 236, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "contactus-6-inputfield-strn2g",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1rem" },
            { property: "outline", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(149, 30, 200, 1)" },
            { property: "border-style", value: "none none solid none" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(246, 233, 252, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(149, 30, 200, 0)" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.2s ease-in-out" },
          ],
        },
        {
          className: "contactus-6-inputfield-strn2g",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(149, 30, 200, 1)" },
            { property: "border-color", value: "rgba(149, 30, 200, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(149, 30, 200, 0.2)" },
          ],
        },
        {
          className: "contactus-6-inputfield-strn2g",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-6-inputfield-strn2g", pseudo: "hover", rules: [] },
        { className: "contactus-6-inputwrapper-strn2g", pseudo: "", rules: [{ property: "margin", value: "0rem 0rem 1rem 0rem" }] },
      ],
    },
    {
      componentId: "vY3o5J",
      classes: [
        {
          className: "section.basics-3-component-vY3o5J",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-3-component-vY3o5J", pseudo: "hover", rules: [] },
        {
          className: "basics-3-col-vY3o5J",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
          ],
        },
        {
          className: "basics-3-img-vY3o5J",
          pseudo: "",
          rules: [
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "400px" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "basics-3-img-vY3o5J", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "lqkMTP",
      classes: [
        {
          className: "section.basics-3-component-lqkMTP",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-3-component-lqkMTP", pseudo: "hover", rules: [] },
        {
          className: "basics-3-component-lqkMTP",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-3-component-lqkMTP", pseudo: "hover", rules: [] },
        {
          className: "basics-3-img-lqkMTP",
          pseudo: "",
          rules: [
            { property: "width", value: "400px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(149, 30, 200, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "margin", value: "0 0 0 0" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "basics-3-img-lqkMTP", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "GtzNB8",
      classes: [
        {
          className: "section.basics-3-component-GtzNB8",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "2rem" },
            { property: "background", pseudo: "", value: "rgba(246, 233, 252, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(246, 233, 252, 1)" },
          ],
        },
        { className: "section.basics-3-component-GtzNB8", pseudo: "hover", rules: [] },
        {
          className: "basics-3-component-GtzNB8",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-3-component-GtzNB8", pseudo: "hover", rules: [] },
        {
          className: "basics-3-img-GtzNB8",
          pseudo: "",
          rules: [
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "100px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(149, 30, 200, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-self", value: "center" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-3-img-GtzNB8",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "nEye10",
      classes: [
        {
          className: "section.resume-2-component-nEye10",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.resume-2-component-nEye10", pseudo: "hover", rules: [] },
        {
          className: "resume-2-wrappercol-nEye10",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "resume-2-wrappercol-nEye10", pseudo: "hover", rules: [] },
        {
          className: "resume-2-elementwrapper-nEye10",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "resume-2-elementwrapper-nEye10", pseudo: "hover", rules: [] },
        {
          className: "resume-2-itemcol-nEye10",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "resume-2-itemcol-nEye10", pseudo: "hover", rules: [] },
        { className: "resume-2-position1-nEye10", pseudo: "", rules: [{ property: "margin", value: "0 0 0.5rem 0" }] },
        { className: "resume-2-position1-nEye10", pseudo: "hover", rules: [] },
        {
          className: "resume-2-position2-nEye10",
          pseudo: "",
          rules: [
            { property: "font-size", value: "15px" },
            { property: "margin", value: "0 0 0.5rem 0" },
          ],
        },
        { className: "resume-2-position2-nEye10", pseudo: "hover", rules: [] },
        {
          className: "resume-2-position3-nEye10",
          pseudo: "",
          rules: [
            { property: "font-size", value: "15px" },
            { property: "margin", value: "0 0 0.5rem 0" },
          ],
        },
        { className: "resume-2-position3-nEye10", pseudo: "hover", rules: [] },
        { className: "resume-2-itemdesc-nEye10", pseudo: "", rules: [{ property: "margin", value: "0 0 0.5rem 0" }] },
        { className: "resume-2-itemdesc-nEye10", pseudo: "hover", rules: [] },
        { className: "resume-2-bulletitem-nEye10", pseudo: "not(:first-child)", rules: [{ property: "margin", value: "2rem 0 0 0" }] },
        {
          className: "resume-2-skillitemcol-nEye10",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "flex-start" },
          ],
        },
        { className: "resume-2-skillitemcol-nEye10", pseudo: "hover", rules: [] },
        {
          className: "resume-2-title-nEye10",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(149, 30, 200, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "resume-2-title-nEye10", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "resume-2-blocktitle-nEye10",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(206, 137, 236, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0.5rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "resume-2-blocktitle-nEye10", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "resume-2-itemtitle-nEye10",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(149, 30, 200, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "resume-2-itemtitle-nEye10", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "resume-2-bullettitle-nEye10",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(206, 137, 236, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "resume-2-bullettitle-nEye10", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "resume-2-bullettext-nEye10",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "resume-2-bullettext-nEye10", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "resume-2-bullettext-nEye10",
          pseudo: "before",
          rules: [
            { property: "content", value: '""' },
            { property: "display", value: "inline-block" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "height", value: "13px" },
            { property: "width", value: "13px" },
            { property: "border-radius", value: "0rem" },
            { property: "background", value: "rgba(206, 137, 236, 1)" },
            { property: "border-color", value: "rgba(206, 137, 236, 1)" },
            { property: "margin-right", value: "0.5rem" },
          ],
        },
        {
          className: "resume-2-div-item-nEye10",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "background", value: "rgba(253, 250, 254, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgba(149, 30, 200, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(206, 137, 236, 0.35)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "3rem 2rem 3rem 2rem" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "resume-2-div-item-nEye10",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(253, 250, 254, 1)" },
            { property: "border-color", value: "rgba(149, 30, 200, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(206, 137, 236, 0.35)" },
          ],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Roboto+Mono:100,300,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Roboto Mono",
    fontHeadersWeight: "700",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(149, 30, 200, 1)",
    color2: "rgba(206, 137, 236, 1)",
    color3: "rgba(246, 233, 252, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
