export const cta2 = {
  id: "cta-2",
  name: "Call to action 2",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "cta-2-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "h1",
        classes: ["cta-2-title", "w-100", "w-md-70", "w-lg-50"],
        styles: [],
        attributes: [
          { property: "data-editable", value: "true" },
          { property: "data-texteditable", value: "true" },
          { property: "data-name", value: "h" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "textNode",
            classes: [],
            styles: [],
            attributes: [],
            content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
            children: [],
          },
        ],
      },
      {
        childId: "",
        type: "",
        htmlTagName: "a",
        classes: ["cta-2-button"],
        styles: [],
        attributes: [
          { property: "href", value: "#!" },
          { property: "data-href", value: "#!" },
          { property: "data-target", value: "_self" },
          { property: "data-editable", value: "true" },
          { property: "data-name", value: "button" },
        ],
        content: "",
        children: [{ childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact us", children: [] }],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.cta-2-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.cta-2-component", pseudo: "hover", rules: [] },
      {
        className: "cta-2-component",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
        ],
      },
      { className: "cta-2-component", pseudo: "hover", rules: [] },
      {
        className: "cta-2-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "margin", value: "0 0 2rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
          { property: "font-weight", value: "bold" },
        ],
      },
      {
        className: "cta-2-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "cta-2-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(201, 36, 3, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(201, 36, 3, 1)" },
          { property: "border-radius", value: "1000px" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
          { property: "text-decoration", value: "none" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "cta-2-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(201, 36, 3, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(201, 36, 3, 1)" },
        ],
      },
    ],
  },
};
