export const contactus6 = {
  id: "contactus-6",
  name: "Contact us 6",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "contactus-6-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "contactus-6-row"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-4", "col-lg-3", "contactus-6-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["contactus-6-contacttitle"],
                styles: [],
                attributes: [
                  { property: "data-name", value: "h" },
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                ],
                content: "",
                children: [
                  { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact", children: [] },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["contactus-6-contacttext"],
                styles: [],
                attributes: [
                  { property: "data-name", value: "p" },
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Looking forward to hearing from you!",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "h5",
                classes: ["contactus-6-subtitle"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Phone", children: [] },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["contactus-6-subtext"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "123 456 7890", children: [] },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "h5",
                classes: ["contactus-6-subtitle"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Email", children: [] },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["contactus-6-subtext"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "email@site.com",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
            styles: [],
            attributes: [
              { property: "data-sbform", value: "true" },
              { property: "data-sbformaction", value: "" },
              { property: "data-sbformmsg", value: "Your message has been received!" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["w-100", "contactus-6-inputwrapper"],
                styles: [],
                attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "label",
                    classes: ["form-control", "contactus-6-label"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "inputlabel" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Your name",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "input",
                    classes: ["form-control", "contactus-6-inputfield"],
                    styles: [],
                    attributes: [
                      { property: "type", value: "text" },
                      { property: "name", value: "name" },
                      { property: "placeholder", value: "Your name" },
                      { property: "data-name", value: "inputfield" },
                      { property: "data-required", value: "true" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["w-100", "contactus-6-inputwrapper"],
                styles: [],
                attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "label",
                    classes: ["form-control", "contactus-6-label"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "inputlabel" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Your email",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "input",
                    classes: ["form-control", "contactus-6-inputfield"],
                    styles: [],
                    attributes: [
                      { property: "type", value: "email" },
                      { property: "name", value: "email" },
                      { property: "placeholder", value: "Your email address" },
                      { property: "data-name", value: "inputfield" },
                      { property: "data-required", value: "true" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["w-100", "contactus-6-inputwrapper"],
                styles: [],
                attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "label",
                    classes: ["form-control", "contactus-6-label"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "inputlabel" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Subject",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "input",
                    classes: ["form-control", "contactus-6-inputfield"],
                    styles: [],
                    attributes: [
                      { property: "type", value: "text" },
                      { property: "name", value: "subject" },
                      { property: "placeholder", value: "Subject" },
                      { property: "data-name", value: "inputfield" },
                      { property: "data-required", value: "true" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["w-100", "contactus-6-inputwrapper"],
                styles: [],
                attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "label",
                    classes: ["form-control", "contactus-6-label"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "inputlabel" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Your message",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textarea",
                    classes: ["form-control", "contactus-6-inputfield"],
                    styles: [],
                    attributes: [
                      { property: "rows", value: "3" },
                      { property: "name", value: "message" },
                      { property: "placeholder", value: "Message" },
                      { property: "data-name", value: "inputfield" },
                      { property: "data-required", value: "true" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["contactus-6-buttonwrapper"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "button",
                    classes: ["contactus-6-button"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "button" },
                      { property: "data-sbformbtn", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Send message!",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.contactus-6-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.contactus-6-component", pseudo: "hover", rules: [] },
      {
        className: "contactus-6-col",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "align-items", value: "flex-start" },
          { property: "justify-content", value: "flex-start" },
          { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
        ],
      },
      {
        className: "contactus-6-contacttitle",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0.5rem 0" },
        ],
      },
      {
        className: "contactus-6-contacttitle",
        pseudo: "hover",
        rules: [],
      },
      { className: "contactus-6-contacttext", pseudo: "", rules: [{ property: "margin", value: "0 0 2rem 0" }] },
      { className: "contactus-6-contacttext", pseudo: "hover", rules: [] },
      {
        className: "contactus-6-subtitle",
        pseudo: "",
        rules: [
          { property: "font-weight", value: "bold" },
          { property: "margin", value: "0 0 0.5rem 0" },
        ],
      },
      {
        className: "contactus-6-subtitle",
        pseudo: "hover",
        rules: [],
      },
      { className: "contactus-6-subtext", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
      { className: "contactus-6-subtext", pseudo: "hover", rules: [] },
      {
        className: "contactus-6-buttonwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "contactus-6-buttonwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "contactus-6-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(0, 0, 0, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(0, 0, 0, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
          { property: "font-weight", value: "bold" },
          { property: "width", value: "auto" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "contactus-6-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(0, 0, 0, 1)" },
        ],
      },
      {
        className: "contactus-6-inputwrapper",
        pseudo: "",
        rules: [{ property: "margin", value: "0 0 1rem 0" }],
      },
      {
        className: "contactus-6-inputfield",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(206, 212, 218, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.2s ease" },
          { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
          { property: "font-size", value: "1rem" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
          { property: "outline", value: "0" },
        ],
      },
      {
        className: "contactus-6-inputfield",
        pseudo: "focus",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
        ],
      },
      {
        className: "contactus-6-inputfield",
        pseudo: ":placeholder",
        rules: [
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "contactus-6-inputfield",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "contactus-6-label",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.25rem 0" },
          { property: "display", value: "none" },
        ],
      },
      {
        className: "contactus-6-label",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
