export const template20 = {
  id: "65feb4ee2cacd7f587b5a944",
  name: { en: "Coffee bar", nl: "Koffiebar" },
  desc: { en: "Website for a coffee bar", nl: "Website voor een koffiebar" },
  categories: ["hospitality"],
  sbPages: [
    {
      pageId: "27a846ca-3667-48d5-8777-05bef10fbe67",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "nHCrbt",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-nHCrbt", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "MW7cxo",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "zvvW3j",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "mRTYvI",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-nHCrbt"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-happycoffee-white.png" },
                        { property: "alt", value: "Logo" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "JpNRJI",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-nHCrbt", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                    { property: "type", value: "button" },
                    { property: "aria-expanded", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "bvzlXd",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "zGHwdY",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "H7IBlO",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "b4xUeG",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-nHCrbt"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/menu.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Menu",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "SAmr5v",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-nHCrbt"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#visit" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Visit us",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "F30XEB",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "hero-1-component-F30XEB"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "QbPUk4",
              type: "",
              htmlTagName: "div",
              classes: ["hero-1-wrapper-F30XEB"],
              styles: [],
              attributes: [{ property: "data-herowrapper", value: "true" }],
              content: "",
              children: [
                {
                  childId: "Vmfhhg",
                  type: "",
                  htmlTagName: "img",
                  classes: ["hero-1-img-F30XEB"],
                  styles: [],
                  attributes: [
                    { property: "alt", value: "Image" },
                    { property: "src", value: "https://cdn.satonda.com/eb/assets/coffee1.jpg" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "T9FK3z",
                  type: "",
                  htmlTagName: "div",
                  classes: ["hero-1-content-F30XEB"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "hero1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "EGZcdT",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["hero-1-title-F30XEB", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Your favorite coffee spot",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "ZUjWkt",
                      type: "",
                      htmlTagName: "p",
                      classes: ["hero-1-text-F30XEB", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "afm8Kh",
                      type: "",
                      htmlTagName: "a",
                      classes: ["hero-1-button-F30XEB"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                        { property: "data-href", value: "/menu.html" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "See our menu",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "KyT2lh",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-4-component-KyT2lh"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "IoR0z3",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-4-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "hN7eVP",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "basics-4-col-KyT2lh"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "qnxXWy",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-4-img-KyT2lh"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/meal2.jpg" },
                        { property: "alt", value: "food" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "F1MSe7",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-4-col-KyT2lh"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "OOQhLS",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["basics-4-title-KyT2lh", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Amazing Food",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "aWGh4x",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-4-text-KyT2lh", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "span",
                          classes: [],
                          styles: [{ property: "color", value: "rgb(108,117,125)" }],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "em",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "We offer a wide variety of healthy breakfast and lunch options",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: " ", children: [] },
                      ],
                    },
                    {
                      childId: "Xkf2qM",
                      type: "",
                      htmlTagName: "a",
                      classes: ["basics-4-link-readmore-KyT2lh"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                        { property: "data-href", value: "/menu.html" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "See more",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "FZBDIs",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-4-component-FZBDIs"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "IwjsIE",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-4-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "FHy3nv",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-first", "basics-4-col-FZBDIs"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "yhTqFj",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-4-img-FZBDIs"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/coffee3.jpg" },
                        { property: "alt", value: "coffee" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "GbJ3SP",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-4-col-FZBDIs"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "kHcpCx",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["basics-4-title-FZBDIs", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Coffee", children: [] },
                      ],
                    },
                    {
                      childId: "KmWm0p",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-4-text-FZBDIs", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse, mollitia.",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "vHV6AO",
                      type: "",
                      htmlTagName: "a",
                      classes: ["basics-4-link-readmore-FZBDIs"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                        { property: "data-href", value: "/menu.html" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "See more",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "BbzM8y",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-4-component-BbzM8y"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "PkFkiE",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-4-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics4" }],
              content: "",
              children: [
                {
                  childId: "aRFgoq",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "basics-4-col-BbzM8y"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "GVt47F",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-4-img-BbzM8y"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/coffee9.jpg" },
                        { property: "alt", value: "company" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "KQYWAk",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-4-col-BbzM8y"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "aaLBmO",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["basics-4-title-BbzM8y", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Enjoy each other's company",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "GJEyPc",
                      type: "",
                      htmlTagName: "p",
                      classes: ["basics-4-text-BbzM8y", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "span",
                          classes: [],
                          styles: [{ property: "color", value: "rgb(108,117,125)" }],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "em",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Our barristas love to make the best coffee",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: " ", children: [] },
                      ],
                    },
                    {
                      childId: "ObM8rt",
                      type: "",
                      htmlTagName: "a",
                      classes: ["basics-4-link-readmore-BbzM8y"],
                      styles: [],
                      attributes: [
                        { property: "href", value: "#!" },
                        { property: "data-target", value: "_self" },
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "button" },
                        { property: "data-href", value: "/index.html#visit" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Come visit us",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "K0yXpg",
          type: "component",
          htmlTagName: "div",
          classes: ["imggallery-2-component-K0yXpg", "container-fluid"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "qxaOKE",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "imggallery-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "i1VOuS",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-K0yXpg"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "qymq95",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-K0yXpg"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "R9iFA8",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-K0yXpg"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/coffee5.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "wVQyrY",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-K0yXpg"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "bHnjKc",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-K0yXpg"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "h5zlBO",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-K0yXpg"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/coffee7.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "xH90k6",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-K0yXpg"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "B5WHQ4",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-K0yXpg"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "jQ63gL",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-K0yXpg"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/pastry2.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "tOUTxW",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-K0yXpg"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "tWSuWy",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-K0yXpg"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "m04ktx",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-K0yXpg"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/coffee6.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "YljzUO",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-YljzUO"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "visit" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "Mb6dhZ",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-YljzUO", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "We'd love to welcome you",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "xcxFKc",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-24-component-xcxFKc"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "vcJ2x5",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-24-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "vOWqdD",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-24-col-xcxFKc"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "rOWIWB",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-24-wrapper-xcxFKc"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "DIMdoe",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-24-title-xcxFKc"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Location",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "qdiLXQ",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "tPBsCe",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-24-text-xcxFKc"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "X5qgBw",
                                  type: "",
                                  htmlTagName: "em",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Maasstraat 17",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "rejfzJ",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-24-text-xcxFKc"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "Q7sN1o",
                                  type: "",
                                  htmlTagName: "em",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "1000 LX Amsterdam",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "xOLLJa",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-24-col-xcxFKc"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "V3XQ4O",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-24-wrapper-xcxFKc"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "xGqxnd",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-24-title-xcxFKc"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Opening hours",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "tjL3ie",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ONnyzg",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-24-text-xcxFKc"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "n8Q61D",
                                  type: "",
                                  htmlTagName: "em",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Mon-Fri 7.00 - 15.00",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "bJq6gi",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-24-text-xcxFKc"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "aHMViK",
                                  type: "",
                                  htmlTagName: "em",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Sat-Sun 7.00 - 18.00",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "fQOXsU",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "footer-4-component-fQOXsU"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "T0Bt2T",
              type: "",
              htmlTagName: "div",
              classes: ["footer-4-wrapper-fQOXsU", "px-sm-0", "px-md-4"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "footer4_wrapper" },
              ],
              content: "",
              children: [
                {
                  childId: "KSD3uz",
                  type: "",
                  htmlTagName: "div",
                  classes: ["footer-4-line-fQOXsU", "mx-n3", "mx-md-0"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "footer4" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "PmHNlj",
                  type: "",
                  htmlTagName: "div",
                  classes: ["row", "align-items-top", "justify-content-center", "footer-4-row"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "GQ1CdC",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "ZlGyD1",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-fQOXsU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Phone",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "UKvD2U",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-fQOXsU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "123 456 7890",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "YG56gm",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "pnEO5r",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-fQOXsU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "g3aawv",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-fQOXsU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "email@site.com",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "uHdkat",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "qSHWbz",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-fQOXsU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Follow me",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "lt95nA",
                          type: "block",
                          htmlTagName: "div",
                          classes: [],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "gTGnpx",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-fQOXsU"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "v0ipYr",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-twitter"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Xbjx6C",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-fQOXsU"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "BdgX1i",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-facebook-f"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "fboavm",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-fQOXsU"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "fG17O2",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-instagram"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "bR57Is",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "rpFaz0",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-fQOXsU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "© 2024 Happy Coffee",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "65feab0ece19f27d76704b57",
    },
    {
      pageId: "1dfc5aa5-084b-46ba-a9e9-2d8249f36b96",
      version: "A",
      pageLink: "menu",
      pageTitle: "menu",
      pageDesc: "",
      components: [
        {
          componentId: "nHCrbt",
          type: "component",
          htmlTagName: "div",
          classes: ["navbar", "navbar-1-wrapper-nHCrbt", "navbar-expand-lg"],
          styles: [],
          attributes: [
            { property: "data-name", value: "navbar" },
            { property: "data-editable", value: "true" },
            { property: "data-changeonscrollpast", value: "false" },
            { property: "data-navbarposition", value: "" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "MW7cxo",
              type: "",
              htmlTagName: "div",
              classes: ["container"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "zvvW3j",
                  type: "",
                  htmlTagName: "div",
                  classes: ["navbar-brand"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "mRTYvI",
                      type: "",
                      htmlTagName: "img",
                      classes: ["navbar-1-logo-nHCrbt"],
                      styles: [],
                      attributes: [
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-happycoffee-white.png" },
                        { property: "alt", value: "Logo" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "JpNRJI",
                  type: "",
                  htmlTagName: "div",
                  classes: ["d-block", "navbar-1-toggler-nHCrbt", "d-lg-none"],
                  styles: [],
                  attributes: [
                    { property: "data-bs-toggle", value: "collapse" },
                    { property: "data-bs-target", value: "#navbarCollapse-1" },
                    { property: "data-elementgetter1", value: "true" },
                    { property: "type", value: "button" },
                    { property: "aria-expanded", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "bvzlXd",
                      type: "",
                      htmlTagName: "i",
                      classes: ["fa-solid", "fa-bars"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "zGHwdY",
                  type: "",
                  htmlTagName: "div",
                  classes: ["collapse", "navbar-collapse"],
                  styles: [],
                  attributes: [{ property: "id", value: "navbarCollapse-1" }],
                  content: "",
                  children: [
                    {
                      childId: "H7IBlO",
                      type: "",
                      htmlTagName: "ul",
                      classes: ["navbar-nav", "ms-auto"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter2", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "b4xUeG",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-nHCrbt"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/menu.html" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Menu",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "li",
                          classes: ["nav-item"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "SAmr5v",
                              type: "",
                              htmlTagName: "a",
                              classes: ["nav-link", "navbar-1-link-nHCrbt"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "/index.html#visit" },
                                { property: "data-target", value: "_self" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Visit us",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "eHYUTU",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-eHYUTU"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "JoQ16I",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-eHYUTU", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Have a look at our menu",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "dcdktm",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "menu-2-component-dcdktm"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "F8rPtz",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "align-items-start", "justify-content-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "yJxhP0",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "menu-2-col-dcdktm"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "LpCciW",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["menu-2-col-dcdktmumn-title", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Coffee", children: [] },
                      ],
                    },
                    {
                      childId: "InThpB",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["menu-2-wrapper-menu-items"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "qHcyVs",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-dcdktm"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "bBuwXz",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "rgm2F0",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Espresso",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "IJEk7Q",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-dcdktm", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€0.99",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "fMmgCO",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "Ac1tLC",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-dcdktm"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "DhJtTc",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "zzjmTH",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Cortado",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "d5MFVk",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-dcdktm", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€1.49",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "x102k7",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "SBJCyr",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-dcdktm"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "HekrPs",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "YHmAzZ",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Latte Machiato",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "c0OH2V",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-dcdktm", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€1.99",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "aq9n0j",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "Cpdbq6",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-dcdktm"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "m8eutw",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "s1JZ23",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Cappuchino",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "jOdOz6",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-dcdktm", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€1.99",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "sDXh3h",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "jlKhml",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "menu-2-col-dcdktm"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "skGL4f",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["menu-2-col-dcdktmumn-title", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Pastries",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "w4F1oz",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["menu-2-wrapper-menu-items"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "zjXmXd",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-dcdktm"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "onrCvp",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "sPGPbu",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Croissant",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "RVy29U",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-dcdktm", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€2.49",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "wMaFGr",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "k0Upv9",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-dcdktm"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Q0Wjug",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "N8S42s",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Pain aux raisins",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "IEog6A",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-dcdktm", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€2.49",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "N04k4U",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "cfJVbc",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-dcdktm"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "w3Ffaz",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Dbzwtb",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Oranais",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "J9JvQb",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-dcdktm", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€2.49",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "p9voke",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "X3t93k",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-4", "menu-2-col-dcdktm"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "uKEz0b",
                      type: "",
                      htmlTagName: "h2",
                      classes: ["menu-2-col-dcdktmumn-title", "w-100", "w-md-100", "w-lg-100"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Sandwiches",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "SU0d6i",
                      type: "block",
                      htmlTagName: "div",
                      classes: ["menu-2-wrapper-menu-items"],
                      styles: [],
                      attributes: [{ property: "data-name", value: "block" }],
                      content: "",
                      children: [
                        {
                          childId: "RgagPa",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-dcdktm"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "nDhqWp",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "k8O2mL",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Avocado toast and eggs",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "QZg6NK",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-dcdktm", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€8.99",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "vy7dOL",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "gC8Kv1",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-dcdktm"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "WLAkyW",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "e213tD",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Smoked salmon",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "DrXn0t",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-dcdktm", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€8.99",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "bd4Dxo",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          childId: "gGWzOW",
                          type: "element",
                          htmlTagName: "div",
                          classes: ["menu-2-menu-item-dcdktm"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "WyY0ca",
                              type: "",
                              htmlTagName: "div",
                              classes: ["d-flex", "justify-content-between", "align-items-start"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "LRKUkC",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line1-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Tuna melt",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "fj4Vxq",
                                  type: "",
                                  htmlTagName: "p",
                                  classes: ["menu-2-line3-dcdktm", "w-50", "w-md-40", "w-lg-30"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-texteditable", value: "true" },
                                    { property: "data-name", value: "p" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "€8.99",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "zW7wPs",
                              type: "",
                              htmlTagName: "p",
                              classes: ["menu-2-line2-dcdktm", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "K0yXpg",
          type: "component",
          htmlTagName: "div",
          classes: ["imggallery-2-component-K0yXpg", "container-fluid"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "qxaOKE",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "imggallery-2-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "i1VOuS",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-K0yXpg"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "qymq95",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-K0yXpg"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "R9iFA8",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-K0yXpg"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/coffee5.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "wVQyrY",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-K0yXpg"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "bHnjKc",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-K0yXpg"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "h5zlBO",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-K0yXpg"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/coffee7.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "xH90k6",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-K0yXpg"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 500 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "B5WHQ4",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-K0yXpg"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "jQ63gL",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-K0yXpg"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/pastry2.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "tOUTxW",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "imggallery-2-col-K0yXpg"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "element_col" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 700 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "tWSuWy",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-2-wrapper-K0yXpg"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img_gallery2" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "m04ktx",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-2-img-K0yXpg"],
                          styles: [],
                          attributes: [
                            { property: "data-imgresizable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/coffee6.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "YljzUO",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-YljzUO"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "id", value: "visit" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "Mb6dhZ",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-YljzUO", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "We'd love to welcome you",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "xcxFKc",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "card-24-component-xcxFKc"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "vcJ2x5",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "card-24-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "vOWqdD",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-24-col-xcxFKc"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "rOWIWB",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-24-wrapper-xcxFKc"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "DIMdoe",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-24-title-xcxFKc"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Location",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "qdiLXQ",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "tPBsCe",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-24-text-xcxFKc"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "X5qgBw",
                                  type: "",
                                  htmlTagName: "em",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Maasstraat 17",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "rejfzJ",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-24-text-xcxFKc"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "Q7sN1o",
                                  type: "",
                                  htmlTagName: "em",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "1000 LX Amsterdam",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "xOLLJa",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "card-24-col-xcxFKc"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                    { property: "data-aos", value: "fade" },
                    { property: "data-aos-delay", value: 300 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "false" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "V3XQ4O",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "card-24-wrapper-xcxFKc"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "xGqxnd",
                          type: "",
                          htmlTagName: "h3",
                          classes: ["card-24-title-xcxFKc"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Opening hours",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "tjL3ie",
                          type: "",
                          htmlTagName: "div",
                          classes: ["toGrow"],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ONnyzg",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-24-text-xcxFKc"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "n8Q61D",
                                  type: "",
                                  htmlTagName: "em",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Mon-Fri 7.00 - 15.00",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              childId: "bJq6gi",
                              type: "",
                              htmlTagName: "p",
                              classes: ["card-24-text-xcxFKc"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "aHMViK",
                                  type: "",
                                  htmlTagName: "em",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Sat-Sun 7.00 - 18.00",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "fQOXsU",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "footer-4-component-fQOXsU"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "fade" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "false" },
          ],
          content: "",
          children: [
            {
              childId: "T0Bt2T",
              type: "",
              htmlTagName: "div",
              classes: ["footer-4-wrapper-fQOXsU", "px-sm-0", "px-md-4"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "footer4_wrapper" },
              ],
              content: "",
              children: [
                {
                  childId: "KSD3uz",
                  type: "",
                  htmlTagName: "div",
                  classes: ["footer-4-line-fQOXsU", "mx-n3", "mx-md-0"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "footer4" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "PmHNlj",
                  type: "",
                  htmlTagName: "div",
                  classes: ["row", "align-items-top", "justify-content-center", "footer-4-row"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "GQ1CdC",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "ZlGyD1",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-fQOXsU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Phone",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "UKvD2U",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-fQOXsU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "123 456 7890",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "YG56gm",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "pnEO5r",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-fQOXsU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "g3aawv",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-fQOXsU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "email@site.com",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "uHdkat",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "qSHWbz",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-fQOXsU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Follow me",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "lt95nA",
                          type: "block",
                          htmlTagName: "div",
                          classes: [],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "gTGnpx",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-fQOXsU"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "v0ipYr",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-twitter"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Xbjx6C",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-fQOXsU"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "BdgX1i",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-facebook-f"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "fboavm",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-fQOXsU"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "fG17O2",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-instagram"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "bR57Is",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "rpFaz0",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-fQOXsU"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "© 2024 Happy Coffee",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  sbCustomCss: [
    {
      componentId: "F30XEB",
      classes: [
        {
          className: "section.hero-1-component-F30XEB",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.hero-1-component-F30XEB", pseudo: "hover", rules: [] },
        {
          className: "hero-1-component-F30XEB",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        { className: "hero-1-component-F30XEB", pseudo: "hover", rules: [] },
        {
          className: "hero-1-wrapper-F30XEB",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "90vh" },
          ],
        },
        {
          className: "hero-1-img-F30XEB",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
          ],
        },
        {
          className: "hero-1-content-F30XEB",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "hero-1-text-F30XEB",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "margin", value: "0 0 2rem 0" },
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "hero-1-text-F30XEB", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" }] },
        {
          className: "hero-1-title-F30XEB",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-size", value: "36px" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.7)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "hero-1-title-F30XEB", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.7)" }] },
        {
          className: "hero-1-button-F30XEB",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "hero-1-button-F30XEB",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "KyT2lh",
      classes: [
        {
          className: "section.basics-4-component-KyT2lh",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-4-component-KyT2lh", pseudo: "hover", rules: [] },
        {
          className: "basics-4-col-KyT2lh",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-4-img-KyT2lh",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-4-img-KyT2lh",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-4-text-KyT2lh",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-4-text-KyT2lh", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-4-link-readmore-KyT2lh",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(133, 133, 133, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-4-link-readmore-KyT2lh",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(133, 133, 133, 1)" },
            { property: "border-color", value: "rgba(133, 133, 133, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-4-title-KyT2lh",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-4-title-KyT2lh", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "FZBDIs",
      classes: [
        {
          className: "section.basics-4-component-FZBDIs",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", pseudo: "", value: "rgba(242, 245, 248, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(242, 245, 248, 1)" },
          ],
        },
        { className: "section.basics-4-component-FZBDIs", pseudo: "hover", rules: [] },
        {
          className: "basics-4-col-FZBDIs",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-4-text-FZBDIs",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(10, 10, 10, 1)" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "start" },
          ],
        },
        { className: "basics-4-text-FZBDIs", pseudo: "hover", rules: [] },
        {
          className: "basics-4-title-FZBDIs",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-4-title-FZBDIs", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-4-img-FZBDIs",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-4-img-FZBDIs",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-4-link-readmore-FZBDIs",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(133, 133, 133, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-4-link-readmore-FZBDIs",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(133, 133, 133, 1)" },
            { property: "border-color", value: "rgba(133, 133, 133, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "BbzM8y",
      classes: [
        {
          className: "section.basics-4-component-BbzM8y",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-4-component-BbzM8y", pseudo: "hover", rules: [] },
        {
          className: "basics-4-col-BbzM8y",
          pseudo: "",
          rules: [
            { property: "padding", value: "2rem 2rem 2rem 2rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "overflow", value: "hidden" },
          ],
        },
        {
          className: "basics-4-title-BbzM8y",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-4-title-BbzM8y", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-4-img-BbzM8y",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "max-height", value: "350px" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-4-img-BbzM8y",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-4-text-BbzM8y",
          pseudo: "",
          rules: [
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-4-text-BbzM8y", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "basics-4-link-readmore-BbzM8y",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(133, 133, 133, 1)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 0rem 0.5rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-4-link-readmore-BbzM8y",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(133, 133, 133, 1)" },
            { property: "border-color", value: "rgba(133, 133, 133, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "K0yXpg",
      classes: [
        {
          className: "section.imggallery-2-component-K0yXpg",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.imggallery-2-component-K0yXpg", pseudo: "hover", rules: [] },
        {
          className: "imggallery-2-col-K0yXpg",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "align-items", value: "stretch" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "imggallery-2-wrapper-K0yXpg",
          pseudo: "",
          rules: [
            { property: "overflow", value: "hidden" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.4s ease-in-out" },
          ],
        },
        {
          className: "imggallery-2-wrapper-K0yXpg",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "imggallery-2-img-K0yXpg",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "transition", value: "all 0.4s ease-in-out" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(75%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
          ],
        },
        {
          className: "imggallery-2-img-K0yXpg",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(75%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
          ],
        },
      ],
    },
    {
      componentId: "YljzUO",
      classes: [
        {
          className: "section.basics-1-component-YljzUO",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(242, 245, 248, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(242, 245, 248, 1)" },
          ],
        },
        { className: "section.basics-1-component-YljzUO", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-YljzUO",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-YljzUO", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-YljzUO",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-YljzUO", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "xcxFKc",
      classes: [
        {
          className: "section.card-24-component-xcxFKc",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "2rem" },
            { property: "background", pseudo: "", value: "rgba(242, 245, 248, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(242, 245, 248, 1)" },
          ],
        },
        { className: "section.card-24-component-xcxFKc", pseudo: "hover", rules: [] },
        {
          className: "card-24-col-xcxFKc",
          pseudo: "",
          rules: [
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
            { property: "display", value: "flex" },
            { property: "border-style", value: "none solid none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 1)" },
          ],
        },
        { className: "card-24-col-xcxFKc", pseudo: "last-child", rules: [{ property: "border", value: "none" }] },
        {
          className: "card-24-wrapper-xcxFKc",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "border-radius", value: "0" },
            { property: "padding", value: "0 0 0 0" },
          ],
        },
        { className: "card-24-wrapper-xcxFKc", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)" }] },
        {
          className: "card-24-border-xcxFKc",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "height", value: "100%" },
            { property: "width", value: "10px" },
            { property: "background", value: "rgba(133, 133, 133, 1)" },
          ],
        },
        { className: "card-24-border-xcxFKc", pseudo: "hover", rules: [{ property: "background", value: "rgba(133, 133, 133, 1)" }] },
        {
          className: "card-24-text-xcxFKc",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0 0" },
            { property: "color", value: "rgba(109, 118, 126, 1)" },
            { property: "align-self", value: "center" },
            { property: "text-align", value: "center" },
          ],
        },
        { className: "card-24-text-xcxFKc", pseudo: "hover", rules: [] },
        {
          className: "card-24-title-xcxFKc",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "card-24-title-xcxFKc", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "fQOXsU",
      classes: [
        {
          className: "section.footer-4-component-fQOXsU",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        { className: "section.footer-4-component-fQOXsU", pseudo: "hover", rules: [] },
        { className: "footer-4-wrapper-fQOXsU", pseudo: "", rules: [] },
        {
          className: "footer-4-social-link-fQOXsU",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 0.5rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "align-self", value: "start" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        {
          className: "footer-4-social-link-fQOXsU",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(60, 60, 60, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "footer-4-social-link-fQOXsU", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-4-title-fQOXsU",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "footer-4-text-fQOXsU",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "footer-4-line-fQOXsU",
          pseudo: "",
          rules: [
            { property: "border-style", value: "solid none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-4-line-fQOXsU", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(255, 255, 255, 1)" }] },
      ],
    },
    {
      componentId: "nHCrbt",
      classes: [
        {
          className: "scrolledpast-nHCrbt.navbar-1-wrapper-nHCrbt",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "rem" },
            { property: "padding-bottom", value: "rem" },
            { property: "background", value: "" },
          ],
        },
        { className: "scrolledpast-nHCrbt .navbar-1-logo-nHCrbt", pseudo: "", rules: [{ property: "max-height", value: "px" }] },
        {
          className: "navbar-1-wrapper-nHCrbt",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", value: "rgba(0, 0, 0, 1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "navbar-1-logo-nHCrbt",
          pseudo: "",
          rules: [
            { property: "max-width", value: "33vw" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "max-height", value: "50px" },
          ],
        },
        { className: "navbar-1-logo-nHCrbt", pseudo: "hover", rules: [] },
        {
          className: "navbar-1-toggler-nHCrbt",
          pseudo: "",
          rules: [
            { property: "cursor", value: "pointer" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "padding", value: "0.25rem 0.5rem 0.25rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(180, 180, 180, 0)" },
            { property: "font-size", value: "2rem" },
            { property: "background", value: "rgba(230, 230, 230, 0)" },
            { property: "color", value: "rgba(240, 240, 240, 1)" },
            { property: "border-radius", value: "0.2rem" },
          ],
        },
        {
          className: "navbar-1-toggler-nHCrbt",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(180, 180, 180, 0)" },
            { property: "background", value: "rgba(180, 180, 180, 0)" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
          ],
        },
        {
          className: "navbar-1-link-nHCrbt",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 0 0" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "font-family", value: "var(--font-body)" },
            { property: "font-size", value: "1rem" },
            { property: "text-decoration", value: "none" },
            { property: "font-weight", value: "inherit" },
          ],
        },
        { className: "navbar-1-link-nHCrbt", pseudo: "hover", rules: [{ property: "color", value: "rgba(240, 240, 240, 1)" }] },
      ],
    },
    {
      componentId: "dcdktm",
      classes: [
        {
          className: "section.menu-2-component-dcdktm",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.menu-2-component-dcdktm", pseudo: "hover", rules: [] },
        {
          className: "menu-2-col-dcdktm",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "menu-2-col-dcdktm", pseudo: "hover", rules: [] },
        {
          className: "menu-2-menu-item-dcdktm",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "menu-2-menu-item-dcdktm", pseudo: "hover", rules: [] },
        {
          className: "menu-2-col-dcdktmumn-title",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "menu-2-col-dcdktmumn-title", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "menu-2-line2-dcdktm",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "menu-2-line2-dcdktm", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "menu-2-line3-dcdktm",
          pseudo: "",
          rules: [
            { property: "text-align", value: "end" },
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "menu-2-line3-dcdktm", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "menu-2-line1-dcdktm",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "menu-2-line1-dcdktm", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "eHYUTU",
      classes: [
        {
          className: "section.basics-1-component-eHYUTU",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-eHYUTU", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-eHYUTU",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-eHYUTU", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-eHYUTU",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(133, 133, 133, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-eHYUTU", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Dancing+Script:700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Dancing Script",
    fontHeadersWeight: "700",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(133, 133, 133, 1)",
    color2: "rgba(242, 245, 248, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
