import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Tooltip from "./Tooltip";

import { EDIT_FORM_ID_PREFIX } from "../../../../../lib/editFunctions";

const TextInput = ({ sb: { selectedElement }, label, id, startValue, tooltipText, onTypeCheck, errMsgText }) => {
  const [value, setValue] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setValue(startValue);
  }, [startValue, selectedElement]);

  const onType = (text) => {
    setValue(text);
    if (typeof onTypeCheck !== "undefined") {
      onTypeCheck(text) ? setErrMsg("") : setErrMsg(errMsgText);
    }
  };

  return (
    <div className="w-100 d-flex align-items-center my-3">
      <div className="w-50">
        {label}
        <Tooltip text={tooltipText} />
      </div>
      <div className="w-50 d-flex flex-column justify-content-center">
        <input
          type="text"
          className={`form-control form-control-sm ${errMsg === "" ? "is-valid" : "is-invalid"}`}
          id={EDIT_FORM_ID_PREFIX + id}
          value={value}
          onChange={(e) => onType(e.target.value)}
        />
        <div className="invalid-feedback fontSize08">{errMsg}</div>
      </div>
    </div>
  );
};

TextInput.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(TextInput);
