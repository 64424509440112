export const aSb = {
  loadingProject: {
    en: "Loading project..",
    nl: "Project laden..",
  },
  savingProject: {
    en: "Saving project..",
    nl: "Project opslaan..",
  },
};
