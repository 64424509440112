export const parallax1 = {
  id: "parallax-1",
  name: "Parallax 1",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container-fluid", "parallax-1-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["parallax-1-wrapper"],
        styles: [],
        attributes: [
          { property: "data-name", value: "parallax" },
          { property: "data-editable", value: "true" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["parallax-1-content"],
            styles: [],
            attributes: [{ property: "data-parallaxcontent", value: "true" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["parallax-1-title", "w-100", "w-md-70", "w-lg-50"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Some appealing title!",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["parallax-1-text", "w-100", "w-md-70", "w-lg-50"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "a",
                classes: ["parallax-1-button"],
                styles: [],
                attributes: [
                  { property: "href", value: "#!" },
                  { property: "data-href", value: "#!" },
                  { property: "data-target", value: "_self" },
                  { property: "data-editable", value: "true" },
                  { property: "data-name", value: "button" },
                ],
                content: "",
                children: [
                  { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: " Read more ", children: [] },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.parallax-1-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      {
        className: "section.parallax-1-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "parallax-1-component",
        pseudo: "",
        rules: [
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "parallax-1-wrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "overflow", value: "hidden" },
          { property: "height", value: "50vh" },
          { property: "background-image", value: 'url("https://s1.travix.com/no/north-america-america-new-york-bridge-and-river-tree-medium.jpg")' },
          { property: "background-size", value: "cover" },
          { property: "background-position", value: "center" },
          { property: "background-attachment", value: "fixed" },
          { property: "background-repeat", value: "no-repeat" },
        ],
      },
      {
        className: "parallax-1-content",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "right", value: "0" },
          { property: "background", value: "rgba(0, 0, 0, 0.3)" },
        ],
      },
      {
        className: "parallax-1-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
          { property: "margin", value: "0 0 1rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "parallax-1-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "parallax-1-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(250, 250, 250, 1)" },
          { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
          { property: "margin", value: "0 0 2rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
        ],
      },
      {
        className: "parallax-1-text",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "parallax-1-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-radius", value: "1000px" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "0.5rem 3rem 0.5rem 3rem" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
          { property: "text-decoration", value: "none" },
        ],
      },
      {
        className: "parallax-1-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(0, 0, 0, 1)" },
          { property: "border-color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
    ],
  },
};
