export const template23 = {
  id: "65ff43dc8afec5a5c3a800cb",
  name: { en: "Interior designer", nl: "Binnenhuis architect" },
  desc: { en: "Website for an interior designer", nl: "Website voor een binnenhuis architect" },
  categories: ["services"],
  sbPages: [
    {
      pageId: "d806128c-dff2-486e-a703-d475ad2e54c9",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "fglMvr",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "parallax-4-component-fglMvr"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Y1LUTg",
              type: "",
              htmlTagName: "div",
              classes: ["parallax-4-wrapper-fglMvr"],
              styles: [],
              attributes: [
                { property: "data-name", value: "parallax" },
                { property: "data-editable", value: "true" },
              ],
              content: "",
              children: [
                {
                  childId: "ypt4EI",
                  type: "",
                  htmlTagName: "div",
                  classes: ["parallax-4-content-fglMvr"],
                  styles: [],
                  attributes: [{ property: "data-parallaxcontent", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "SGWMRX",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["parallax-4-title-fglMvr", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "JESSICA CLARKSON",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "iur5A0",
                      type: "",
                      htmlTagName: "p",
                      classes: ["parallax-4-text-fglMvr", "w-100", "w-md-70", "w-lg-50"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Interior designer",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ZFQkLb",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-ZFQkLb"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "MghpsA",
              type: "",
              htmlTagName: "div",
              classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "sectionline" },
              ],
              content: "",
              children: [
                {
                  childId: "gBb9Pm",
                  type: "",
                  htmlTagName: "div",
                  classes: ["basics-8-sectionline-ZFQkLb"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "OGlBj1",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-OGlBj1"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "IlPXHS",
              type: "",
              htmlTagName: "h6",
              classes: ["basics-1-title-OGlBj1", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "About me", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "W5HiVG",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-W5HiVG"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "w12YFT",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-W5HiVG", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid placeat voluptatibus doloremque maiores. Consequatur reiciendis ipsum in fuga qui! Laboriosam quaerat dolorum distinctio doloribus ex?",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "cgvKzH",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-cgvKzH"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "rgdYc1",
              type: "",
              htmlTagName: "div",
              classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "sectionline" },
              ],
              content: "",
              children: [
                {
                  childId: "X1ZDlt",
                  type: "",
                  htmlTagName: "div",
                  classes: ["basics-8-sectionline-cgvKzH"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "y7J71d",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-y7J71d"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "xwjlWc",
              type: "",
              htmlTagName: "h6",
              classes: ["basics-1-title-y7J71d", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "My portfolio", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "quBela",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "imggallery-6-component-quBela"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "sSk6dc",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "imggallery-6-row"],
              styles: [],
              attributes: [
                { property: "data-name", value: "block" },
                { property: "data-masonry", value: '{"percentPosition":true,"itemSelector":".imggallery-6-col-quBela"}' },
              ],
              content: "",
              children: [
                {
                  childId: "mWYcEr",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col-quBela"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "LrtrPk",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-6-wrapper-quBela"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "yvzVKS",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-6-img-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior1.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "x24uEv",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-6-overlay-content-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "aN2jNC",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-6-title-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "PTwOe9",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-6-text-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "eIkllj",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col-quBela"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "MVI2fS",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-6-wrapper-quBela"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "N5cfJV",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-6-img-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior5.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "ypSZEs",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-6-overlay-content-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "be1HMX",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-6-title-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "dPANvp",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-6-text-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Z2cxv3",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col-quBela"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "I6QXg2",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-6-wrapper-quBela"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "RaS3rJ",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-6-img-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior3.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "pBOueb",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-6-overlay-content-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "WYGBHp",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-6-title-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "cUOVrv",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-6-text-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "uAAUkT",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col-quBela"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "YmGkmZ",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-6-wrapper-quBela"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "scZ8rR",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-6-img-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior11.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "UqsoTl",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-6-overlay-content-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "QsVkxO",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-6-title-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "u3wf2F",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-6-text-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "a5FvH0",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col-quBela"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "Tog22i",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-6-wrapper-quBela"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "fpRf6V",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-6-img-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior2.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "rcxUK9",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-6-overlay-content-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "YMUuhM",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-6-title-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "gLAiFv",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-6-text-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "TVaAJr",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col-quBela"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "gaK8kf",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-6-wrapper-quBela"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Mlj2Zq",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-6-img-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior17.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "qTkgRp",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-6-overlay-content-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "D0IjVT",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-6-title-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "DeaCK1",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-6-text-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "CKRm4A",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col-quBela"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "ukvzov",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-6-wrapper-quBela"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "xLJ2Lo",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-6-img-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior14.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "YY4TIX",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-6-overlay-content-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "kbHxKS",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-6-title-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "O7rS0w",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-6-text-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "kdj0kf",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col-quBela"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "O13tsh",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-6-wrapper-quBela"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "kOHxjV",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-6-img-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior7.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "QVdFxX",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-6-overlay-content-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "sxaf6W",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-6-title-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "Z184da",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-6-text-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "M6pJVb",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-4", "imggallery-6-col-quBela"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "img_gallery1" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "Ubio6P",
                      type: "",
                      htmlTagName: "div",
                      classes: ["imggallery-6-wrapper-quBela"],
                      styles: [],
                      attributes: [
                        { property: "data-overlaywrapper", value: "true" },
                        { property: "data-checkparent", value: "true" },
                        { property: "data-editable", value: "false" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "BHjDN6",
                          type: "",
                          htmlTagName: "img",
                          classes: ["imggallery-6-img-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                            { property: "data-name", value: "img" },
                            { property: "src", value: "https://cdn.satonda.com/eb/assets/interior15.jpg" },
                            { property: "alt", value: "" },
                          ],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "majXDT",
                          type: "",
                          htmlTagName: "div",
                          classes: ["imggallery-6-overlay-content-quBela"],
                          styles: [],
                          attributes: [
                            { property: "data-overlaycontent", value: "true" },
                            { property: "data-elementalignmentwrapper", value: "true" },
                            { property: "data-editable", value: "false" },
                            { property: "data-checkparent", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "tUBYXZ",
                              type: "",
                              htmlTagName: "h3",
                              classes: ["imggallery-6-title-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "h" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Title",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "LrKolg",
                              type: "",
                              htmlTagName: "p",
                              classes: ["imggallery-6-text-quBela", "w-100", "w-md-100", "w-lg-100"],
                              styles: [],
                              attributes: [
                                { property: "data-editable", value: "true" },
                                { property: "data-texteditable", value: "true" },
                                { property: "data-name", value: "p" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, ducimus!",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "is49y5",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-is49y5"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "EXxeUK",
              type: "",
              htmlTagName: "div",
              classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "sectionline" },
              ],
              content: "",
              children: [
                {
                  childId: "yhbEUA",
                  type: "",
                  htmlTagName: "div",
                  classes: ["basics-8-sectionline-is49y5"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "DYVLtt",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-DYVLtt"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "kCP6tR",
              type: "",
              htmlTagName: "h6",
              classes: ["basics-1-title-DYVLtt", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "What my previous clients have to say",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "qbWFBW",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "quote-1-component-qbWFBW"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "z3JIuk",
              type: "block",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "quote-1-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "QjOjec",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-1-col-qbWFBW"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "bdsSZ1",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-1-wrapper-qbWFBW"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "dwGh7F",
                          type: "",
                          htmlTagName: "div",
                          classes: ["quote-1-border-qbWFBW"],
                          styles: [],
                          attributes: [{ property: "data-elementgetter2", value: "true" }],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "MKUJ8K",
                          type: "",
                          htmlTagName: "h5",
                          classes: ["quote-1-text-qbWFBW", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, nisi expedita. Illo, quas. Voluptatum, nesciunt?",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "qu85JQ",
                          type: "",
                          htmlTagName: "h6",
                          classes: ["quote-1-quote-qbWFBW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "d3vzlh",
                              type: "",
                              htmlTagName: "em",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "— Someone said somewhere",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Pb5QfX",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-1-col-qbWFBW"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "fauYbN",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-1-wrapper-qbWFBW"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "sGEzfE",
                          type: "",
                          htmlTagName: "div",
                          classes: ["quote-1-border-qbWFBW"],
                          styles: [],
                          attributes: [{ property: "data-elementgetter2", value: "true" }],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "tCUhQl",
                          type: "",
                          htmlTagName: "h5",
                          classes: ["quote-1-text-qbWFBW", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora rerum rem ducimus illum!",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "BF9yvW",
                          type: "",
                          htmlTagName: "h6",
                          classes: ["quote-1-quote-qbWFBW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "reP9Th",
                              type: "",
                              htmlTagName: "em",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "— Someone said somewhere",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "mw7fIR",
                  type: "element",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-3", "quote-1-col-qbWFBW"],
                  styles: [],
                  attributes: [
                    { property: "data-name", value: "card6" },
                    { property: "data-editable", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "IYjLnc",
                      type: "",
                      htmlTagName: "div",
                      classes: ["flexSameHeight", "quote-1-wrapper-qbWFBW"],
                      styles: [],
                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "eGPz4j",
                          type: "",
                          htmlTagName: "div",
                          classes: ["quote-1-border-qbWFBW"],
                          styles: [],
                          attributes: [{ property: "data-elementgetter2", value: "true" }],
                          content: "",
                          children: [],
                        },
                        {
                          childId: "FHhXbD",
                          type: "",
                          htmlTagName: "h5",
                          classes: ["quote-1-text-qbWFBW", "toGrow"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores iste porro facere beatae totam.",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "DyeBFO",
                          type: "",
                          htmlTagName: "h6",
                          classes: ["quote-1-quote-qbWFBW"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "itckWz",
                              type: "",
                              htmlTagName: "em",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "",
                                  type: "",
                                  htmlTagName: "textNode",
                                  classes: [],
                                  styles: [],
                                  attributes: [],
                                  content: "— Someone said somewhere",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "BRf9Oa",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-8-component-BRf9Oa"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "zAtW7X",
              type: "",
              htmlTagName: "div",
              classes: ["d-flex", "flex-column", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "sectionline" },
              ],
              content: "",
              children: [
                {
                  childId: "K0nFte",
                  type: "",
                  htmlTagName: "div",
                  classes: ["basics-8-sectionline-BRf9Oa"],
                  styles: [],
                  attributes: [{ property: "data-checkparent", value: "true" }],
                  content: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "de7FAz",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-de7FAz"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ljF5b7",
              type: "",
              htmlTagName: "h6",
              classes: ["basics-1-title-de7FAz", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Any questions?", children: [] },
              ],
            },
          ],
        },
        {
          componentId: "FQ4YZH",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "contactus-6-component-FQ4YZH"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Z1fSWV",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "contactus-6-row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "PFRJiQ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-4", "col-lg-3", "contactus-6-col-FQ4YZH"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "UT9ljZ",
                      type: "",
                      htmlTagName: "h1",
                      classes: ["contactus-6-contacttitle-FQ4YZH"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "h" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Contact", children: [] },
                      ],
                    },
                    {
                      childId: "F0bzdH",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-contacttext-FQ4YZH"],
                      styles: [],
                      attributes: [
                        { property: "data-name", value: "p" },
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "Looking forward to hearing from you!",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "tCqUc1",
                      type: "",
                      htmlTagName: "h5",
                      classes: ["contactus-6-subtitle-FQ4YZH"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Phone", children: [] },
                      ],
                    },
                    {
                      childId: "gaVRZO",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-subtext-FQ4YZH"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "123 456 7890",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "ElYm89",
                      type: "",
                      htmlTagName: "h5",
                      classes: ["contactus-6-subtitle-FQ4YZH"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "h" },
                      ],
                      content: "",
                      children: [
                        { childId: "", type: "", htmlTagName: "textNode", classes: [], styles: [], attributes: [], content: "Email", children: [] },
                      ],
                    },
                    {
                      childId: "Al1adz",
                      type: "",
                      htmlTagName: "p",
                      classes: ["contactus-6-subtext-FQ4YZH"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-texteditable", value: "true" },
                        { property: "data-name", value: "p" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "",
                          type: "",
                          htmlTagName: "textNode",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "email@site.com",
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "V3bD4n",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-5", "px-2", "py-3"],
                  styles: [],
                  attributes: [
                    { property: "data-sbform", value: "true" },
                    { property: "data-sbformaction", value: "" },
                    { property: "data-sbformmsg", value: "Your message has been received!" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "XjrGVO",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-FQ4YZH"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "hgZraq",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-FQ4YZH"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your name",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "vSaET8",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-FQ4YZH"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "name" },
                            { property: "placeholder", value: "Your name" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "DOSKMc",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-FQ4YZH"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "XGW8Dl",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-FQ4YZH"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your email",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "vM9J8a",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-FQ4YZH"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "email" },
                            { property: "name", value: "email" },
                            { property: "placeholder", value: "Your email address" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "XVR41s",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-FQ4YZH"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "Db8ULv",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-FQ4YZH"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Subject",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "KW4Dm6",
                          type: "",
                          htmlTagName: "input",
                          classes: ["form-control", "contactus-6-inputfield-FQ4YZH"],
                          styles: [],
                          attributes: [
                            { property: "type", value: "text" },
                            { property: "name", value: "subject" },
                            { property: "placeholder", value: "Subject" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "aJL0qE",
                      type: "",
                      htmlTagName: "div",
                      classes: ["w-100", "contactus-6-inputwrapper-FQ4YZH"],
                      styles: [],
                      attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                      content: "",
                      children: [
                        {
                          childId: "vZ5fbH",
                          type: "",
                          htmlTagName: "label",
                          classes: ["form-control", "contactus-6-label-FQ4YZH"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "inputlabel" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Your message",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "dN9mvZ",
                          type: "",
                          htmlTagName: "textarea",
                          classes: ["form-control", "contactus-6-inputfield-FQ4YZH"],
                          styles: [],
                          attributes: [
                            { property: "rows", value: "3" },
                            { property: "name", value: "message" },
                            { property: "placeholder", value: "Message" },
                            { property: "data-name", value: "inputfield" },
                            { property: "data-required", value: "true" },
                            { property: "data-editable", value: "true" },
                          ],
                          content: "",
                          children: [],
                        },
                      ],
                    },
                    {
                      childId: "zfBGmW",
                      type: "",
                      htmlTagName: "div",
                      classes: ["contactus-6-button-FQ4YZHwrapper-FQ4YZH"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "m4UPx7",
                          type: "",
                          htmlTagName: "button",
                          classes: ["contactus-6-button-FQ4YZH"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-name", value: "button" },
                            { property: "data-sbformbtn", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Send message!",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "QvUQ2m",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "footer-6-component-QvUQ2m"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "dZDWuF",
              type: "block",
              htmlTagName: "div",
              classes: ["d-flex", "flex-wrap", "justify-content-center", "align-items-center"],
              styles: [],
              attributes: [{ property: "data-name", value: "block" }],
              content: "",
              children: [
                {
                  childId: "otddsH",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-QvUQ2m"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "E3KlE7",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-solid", "fa-envelope"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "YTW2EC",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-QvUQ2m"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "c2PD6N",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-brands", "fa-twitter"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "Q8YErJ",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-QvUQ2m"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "TShHFR",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-brands", "fa-instagram"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
                {
                  childId: "mZFLoc",
                  type: "element",
                  htmlTagName: "a",
                  classes: ["footer-6-social-link-QvUQ2m"],
                  styles: [],
                  attributes: [
                    { property: "href", value: "#!" },
                    { property: "data-href", value: "#!" },
                    { property: "data-target", value: "_blank" },
                    { property: "data-editable", value: "true" },
                    { property: "data-iconwrapper", value: "true" },
                    { property: "data-texteditable", value: "false" },
                    { property: "data-name", value: "button" },
                    { property: "data-aos", value: "" },
                    { property: "data-aos-delay", value: 100 },
                    { property: "data-aos-duration", value: 500 },
                    { property: "data-aos-once", value: "true" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "zppom9",
                      type: "",
                      htmlTagName: "span",
                      classes: ["icon", "fa-brands", "fa-facebook-f"],
                      styles: [],
                      attributes: [{ property: "data-checkparent", value: "true" }],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      _id: "65ff3c02ce19f27d76705206",
    },
  ],
  sbCustomCss: [
    {
      componentId: "fglMvr",
      classes: [
        {
          className: "section.parallax-4-component-fglMvr",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.parallax-4-component-fglMvr", pseudo: "hover", rules: [] },
        {
          className: "parallax-4-component-fglMvr",
          pseudo: "",
          rules: [
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "parallax-4-wrapper-fglMvr",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "height", value: "70vh" },
            { property: "background-image", value: 'url("https://cdn.satonda.com/eb/assets/interior4.jpg")' },
            { property: "background-size", value: "cover" },
            { property: "background-position", value: "center" },
            { property: "background-attachment", value: "fixed" },
            { property: "background-repeat", value: "no-repeat" },
          ],
        },
        {
          className: "parallax-4-title-fglMvr",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-4-title-fglMvr", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "parallax-4-text-fglMvr",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 3px rgba(250, 250, 250, 0.4)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "parallax-4-text-fglMvr", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "parallax-4-content-fglMvr",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "background", value: "rgba(0, 0, 0, 0.3)" },
            { property: "justify-content", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "ZFQkLb",
      classes: [
        {
          className: "section.basics-8-component-ZFQkLb",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-8-component-ZFQkLb", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-ZFQkLb",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-ZFQkLb", pseudo: "hover", rules: [] },
        {
          className: "basics-8-sectionline-ZFQkLb",
          pseudo: "",
          rules: [
            { property: "align-self", value: "center" },
            { property: "height", value: "80px" },
            { property: "width", value: "1px" },
            { property: "background", value: "rgba(52, 112, 157, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-sectionline-ZFQkLb",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(52, 112, 157, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "OGlBj1",
      classes: [
        {
          className: "section.basics-1-component-OGlBj1",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-OGlBj1", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-OGlBj1",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-OGlBj1", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-OGlBj1",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 146, 196, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-OGlBj1", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "W5HiVG",
      classes: [
        {
          className: "section.basics-2-component-W5HiVG",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-W5HiVG", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-W5HiVG",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-W5HiVG", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-W5HiVG",
          pseudo: "",
          rules: [
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "left" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "basics-2-text-W5HiVG", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "cgvKzH",
      classes: [
        {
          className: "section.basics-8-component-cgvKzH",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-8-component-cgvKzH", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-cgvKzH",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-cgvKzH", pseudo: "hover", rules: [] },
        {
          className: "basics-8-sectionline-cgvKzH",
          pseudo: "",
          rules: [
            { property: "align-self", value: "center" },
            { property: "height", value: "80px" },
            { property: "width", value: "1px" },
            { property: "background", value: "rgba(52, 112, 157, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-sectionline-cgvKzH",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(52, 112, 157, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "y7J71d",
      classes: [
        {
          className: "section.basics-1-component-y7J71d",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-y7J71d", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-y7J71d",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-y7J71d", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-y7J71d",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 146, 196, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-y7J71d", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "Gj5MZr",
      classes: [
        {
          className: "section.imggallery-3-component-Gj5MZr",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.imggallery-3-component-Gj5MZr", pseudo: "hover", rules: [] },
        {
          className: "imggallery-3-img-Gj5MZr",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "imggallery-3-img-Gj5MZr", pseudo: "hover", rules: [] },
        {
          className: "imggallery-3-arrow-Gj5MZr",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "border-radius", value: "50%" },
            { property: "height", value: "2rem" },
            { property: "width", value: "2rem" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "imggallery-3-arrow-Gj5MZr",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "imggallery-3-arrow-Gj5MZr svg",
          pseudo: "",
          rules: [
            { property: "fill", value: "rgba(0, 0, 0, 0.7)" },
            { property: "height", value: "1.2rem" },
            { property: "width", value: "1.2rem" },
            { property: "transition", value: "all 0.3s ease" },
          ],
        },
        { className: "imggallery-3-arrow-Gj5MZr svg", pseudo: "hover", rules: [{ property: "fill", value: "rgba(0, 0, 0, 1)" }] },
        { className: "imggallery-3-prev-Gj5MZr", pseudo: "", rules: [{ property: "left", value: "1rem" }] },
        { className: "imggallery-3-next-Gj5MZr", pseudo: "", rules: [{ property: "right", value: "1rem" }] },
        {
          className: "imggallery-3-pagination-Gj5MZr",
          pseudo: "",
          rules: [
            { property: "height", value: "0.7rem" },
            { property: "width", value: "0.7rem" },
            { property: "background", value: "rgba(255, 255, 255, 0.5)" },
            { property: "margin", value: "0.25rem" },
            { property: "border-radius", value: "50%" },
            { property: "display", value: "inline-block" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "imggallery-3-pagination-Gj5MZr",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "is-active.imggallery-3-pagination-Gj5MZr",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 0.8)" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "transform", value: "scale(1)" },
            { property: "opacity", value: "1" },
          ],
        },
      ],
    },
    {
      componentId: "quBela",
      classes: [
        {
          className: "section.imggallery-6-component-quBela",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.imggallery-6-component-quBela", pseudo: "hover", rules: [] },
        {
          className: "imggallery-6-col-quBela",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
          ],
        },
        {
          className: "imggallery-6-img-quBela",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "object-fit", value: "cover" },
          ],
        },
        { className: "imggallery-6-img-quBela", pseudo: "hover", rules: [] },
        {
          className: "imggallery-6-title-quBela",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgba(250, 250, 250, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "imggallery-6-title-quBela", pseudo: "hover", rules: [] },
        {
          className: "imggallery-6-text-quBela",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(230, 230, 230, 1)" },
            { property: "align-self", value: "center" },
          ],
        },
        { className: "imggallery-6-text-quBela", pseudo: "hover", rules: [] },
        {
          className: "imggallery-6-wrapper-quBela",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "border-radius", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.2)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "imggallery-6-wrapper-quBela",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(0, 0, 0, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.3)" },
          ],
        },
        {
          className: "imggallery-6-wrapper-quBela",
          pseudo: "hover .imggallery-6-overlay-content-quBela",
          rules: [
            { property: "visibility", value: "visible" },
            { property: "opacity", value: "1" },
          ],
        },
        {
          className: "imggallery-6-overlay-content-quBela",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "position", value: "absolute" },
            { property: "top", value: "0" },
            { property: "bottom", value: "0" },
            { property: "left", value: "0" },
            { property: "right", value: "0" },
            { property: "visibility", value: "hidden" },
            { property: "opacity", value: "0" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "background", value: "rgba(0, 0, 0, 0.5)" },
          ],
        },
        { className: "imggallery-6-overlay-content-quBela", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "DYVLtt",
      classes: [
        {
          className: "section.basics-1-component-DYVLtt",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-DYVLtt", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-DYVLtt",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-DYVLtt", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-DYVLtt",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 146, 196, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-DYVLtt", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "is49y5",
      classes: [
        {
          className: "section.basics-8-component-is49y5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-8-component-is49y5", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-is49y5",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-is49y5", pseudo: "hover", rules: [] },
        {
          className: "basics-8-sectionline-is49y5",
          pseudo: "",
          rules: [
            { property: "align-self", value: "center" },
            { property: "height", value: "80px" },
            { property: "width", value: "1px" },
            { property: "background", value: "rgba(52, 112, 157, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-sectionline-is49y5",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(52, 112, 157, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "qbWFBW",
      classes: [
        {
          className: "section.quote-1-component-qbWFBW",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.quote-1-component-qbWFBW", pseudo: "hover", rules: [] },
        {
          className: "quote-1-quote-qbWFBW",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 1rem 1rem 1.5rem" },
            { property: "color", value: "rgba(109, 118, 126, 1)" },
          ],
        },
        { className: "quote-1-quote-qbWFBW", pseudo: "hover", rules: [] },
        {
          className: "quote-1-text-qbWFBW",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(79, 146, 196, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "1rem 1rem 1rem 1.5rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "quote-1-text-qbWFBW", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "quote-1-col-qbWFBW",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "1rem 1rem 1rem 1rem" },
          ],
        },
        {
          className: "quote-1-wrapper-qbWFBW",
          pseudo: "",
          rules: [
            { property: "position", value: "relative" },
            { property: "overflow", value: "hidden" },
            { property: "padding", value: "0 0 0 0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0.75rem" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "quote-1-wrapper-qbWFBW",
          pseudo: "hover",
          rules: [
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" },
          ],
        },
        {
          className: "quote-1-border-qbWFBW",
          pseudo: "",
          rules: [
            { property: "position", value: "absolute" },
            { property: "background", value: "rgba(163, 198, 224, 1)" },
            { property: "top", value: "0" },
            { property: "left", value: "0" },
            { property: "width", value: "100%" },
            { property: "height", value: "10px" },
          ],
        },
        { className: "quote-1-border-qbWFBW", pseudo: "hover", rules: [{ property: "background", value: "rgba(163, 198, 224, 1)" }] },
      ],
    },
    {
      componentId: "BRf9Oa",
      classes: [
        {
          className: "section.basics-8-component-BRf9Oa",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-8-component-BRf9Oa", pseudo: "hover", rules: [] },
        {
          className: "basics-8-component-BRf9Oa",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "justify-content", value: "center" },
          ],
        },
        { className: "basics-8-component-BRf9Oa", pseudo: "hover", rules: [] },
        {
          className: "basics-8-sectionline-BRf9Oa",
          pseudo: "",
          rules: [
            { property: "align-self", value: "center" },
            { property: "height", value: "80px" },
            { property: "width", value: "1px" },
            { property: "background", value: "rgba(52, 112, 157, 1)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
        {
          className: "basics-8-sectionline-BRf9Oa",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(52, 112, 157, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "de7FAz",
      classes: [
        {
          className: "section.basics-1-component-de7FAz",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-1-component-de7FAz", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-de7FAz",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-de7FAz", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-de7FAz",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(79, 146, 196, 1)" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-de7FAz", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "FQ4YZH",
      classes: [
        {
          className: "section.contactus-6-component-FQ4YZH",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.contactus-6-component-FQ4YZH", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-col-FQ4YZH",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "align-items", value: "flex-start" },
            { property: "justify-content", value: "flex-start" },
            { property: "padding", value: "1rem 0.5rem 1rem 0.5rem" },
          ],
        },
        {
          className: "contactus-6-button-FQ4YZHwrapper-FQ4YZH",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "contactus-6-button-FQ4YZHwrapper-FQ4YZH", pseudo: "hover", rules: [] },
        { className: "contactus-6-inputwrapper-FQ4YZH", pseudo: "", rules: [{ property: "margin", value: "0 0 1rem 0" }] },
        {
          className: "contactus-6-inputfield-FQ4YZH",
          pseudo: "",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(79, 146, 196, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(206, 212, 218, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "transition", value: "all 0.2s ease" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "font-size", value: "1rem" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(79, 146, 196, 0)" },
            { property: "outline", value: "0" },
          ],
        },
        {
          className: "contactus-6-inputfield-FQ4YZH",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(79, 146, 196, 1)" },
            { property: "border-color", value: "rgba(79, 146, 196, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(79, 146, 196, 0.2)" },
          ],
        },
        {
          className: "contactus-6-inputfield-FQ4YZH",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(108, 117, 125, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "contactus-6-inputfield-FQ4YZH", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-label-FQ4YZH",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "contactus-6-label-FQ4YZH", pseudo: "hover", rules: [] },
        {
          className: "contactus-6-contacttitle-FQ4YZH",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(79, 146, 196, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "contactus-6-contacttitle-FQ4YZH",
          pseudo: "hover",
          rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }],
        },
        {
          className: "contactus-6-subtitle-FQ4YZH",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(79, 146, 196, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "contactus-6-subtitle-FQ4YZH", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "contactus-6-contacttext-FQ4YZH",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(52, 112, 157, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "contactus-6-contacttext-FQ4YZH", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "contactus-6-subtext-FQ4YZH",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(52, 112, 157, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "contactus-6-subtext-FQ4YZH", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "contactus-6-button-FQ4YZH",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(79, 146, 196, 1)" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(79, 146, 196, 1)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "contactus-6-button-FQ4YZH",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(79, 146, 196, 1)" },
            { property: "border-color", value: "rgba(79, 146, 196, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "QvUQ2m",
      classes: [
        {
          className: "section.footer-6-component-QvUQ2m",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "4.5rem" },
            { property: "padding-bottom", value: "4.5rem" },
            { property: "background", pseudo: "", value: "rgba(163, 198, 224, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(163, 198, 224, 1)" },
          ],
        },
        { className: "section.footer-6-component-QvUQ2m", pseudo: "hover", rules: [] },
        {
          className: "footer-6-component-QvUQ2m",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "footer-6-component-QvUQ2m", pseudo: "hover", rules: [] },
        {
          className: "footer-6-social-link-QvUQ2m",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "align-items", value: "center" },
            { property: "justify-content", value: "center" },
            { property: "height", value: "45px" },
            { property: "aspect-ratio", value: "1" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(79, 146, 196, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "50%" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "width", value: "auto" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "font-size", value: "1.3rem" },
          ],
        },
        {
          className: "footer-6-social-link-QvUQ2m",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(52, 112, 157, 1)" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "footer-6-social-link-QvUQ2m", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-6-text-QvUQ2m",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(233, 236, 239, 1)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "3rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-6-text-QvUQ2m", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Nanum+Gothic+Coding:700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Nanum Gothic Coding",
    fontHeadersWeight: "400",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(79, 146, 196, 1)",
    color2: "rgba(90, 186, 206, 1)",
    color3: "rgba(52, 112, 157, 1)",
    color4: "rgba(50, 149, 169, 1)",
    color5: "rgba(163, 198, 224, 1)",
    color6: "rgba(157, 214, 226, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
