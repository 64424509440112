export const footer6 = {
  id: "footer-6",
  name: "Footer 6",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "footer-6-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["d-flex", "flex-wrap", "justify-content-center", "align-items-center"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "a",
            classes: ["footer-6-social-link"],
            styles: [],
            attributes: [
              { property: "href", value: "#!" },
              { property: "data-href", value: "#!" },
              { property: "data-target", value: "_blank" },
              { property: "data-editable", value: "true" },
              { property: "data-iconwrapper", value: "true" },
              { property: "data-texteditable", value: "false" },
              { property: "data-name", value: "button" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "span",
                classes: ["icon", "fa-solid", "fa-envelope"],
                styles: [],
                attributes: [{ property: "data-checkparent", value: "true" }],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "a",
            classes: ["footer-6-social-link"],
            styles: [],
            attributes: [
              { property: "href", value: "#!" },
              { property: "data-href", value: "#!" },
              { property: "data-target", value: "_blank" },
              { property: "data-editable", value: "true" },
              { property: "data-iconwrapper", value: "true" },
              { property: "data-texteditable", value: "false" },
              { property: "data-name", value: "button" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "span",
                classes: ["icon", "fa-brands", "fa-twitter"],
                styles: [],
                attributes: [{ property: "data-checkparent", value: "true" }],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "a",
            classes: ["footer-6-social-link"],
            styles: [],
            attributes: [
              { property: "href", value: "#!" },
              { property: "data-href", value: "#!" },
              { property: "data-target", value: "_blank" },
              { property: "data-editable", value: "true" },
              { property: "data-iconwrapper", value: "true" },
              { property: "data-texteditable", value: "false" },
              { property: "data-name", value: "button" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "span",
                classes: ["icon", "fa-brands", "fa-linkedin"],
                styles: [],
                attributes: [{ property: "data-checkparent", value: "true" }],
                content: "",
                children: [],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "a",
            classes: ["footer-6-social-link"],
            styles: [],
            attributes: [
              { property: "href", value: "#!" },
              { property: "data-href", value: "#!" },
              { property: "data-target", value: "_blank" },
              { property: "data-editable", value: "true" },
              { property: "data-iconwrapper", value: "true" },
              { property: "data-texteditable", value: "false" },
              { property: "data-name", value: "button" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "span",
                classes: ["icon", "fa-brands", "fa-facebook-f"],
                styles: [],
                attributes: [{ property: "data-checkparent", value: "true" }],
                content: "",
                children: [],
              },
            ],
          },
        ],
      },
      {
        childId: "",
        type: "",
        htmlTagName: "p",
        classes: ["footer-6-text", "w-100", "w-md-100", "w-lg-100"],
        styles: [],
        attributes: [
          { property: "data-editable", value: "true" },
          { property: "data-texteditable", value: "true" },
          { property: "data-name", value: "p" },
        ],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "textNode",
            classes: [],
            styles: [],
            attributes: [],
            content: "© Copyright Company name. All rights reserved.",
            children: [],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.footer-6-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "4.5rem" },
          { property: "padding-bottom", value: "4.5rem" },
          { property: "background", value: "rgba(70, 70, 70, 1)" },
        ],
      },
      { className: "section.footer-6-component", pseudo: "hover", rules: [] },
      {
        className: "footer-6-component",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "footer-6-component",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-6-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "3rem 0 1rem 0" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "center" },
          { property: "color", value: "rgba(233, 236, 239, 1)" },
        ],
      },
      {
        className: "footer-6-text",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "footer-6-social-link",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 1rem 0 0" },
          { property: "color", value: "rgba(70, 70, 70, 1)" },
          { property: "background", value: "rgba(233, 236, 239, 1)" },
          { property: "display", value: "flex" },
          { property: "align-items", value: "center" },
          { property: "justify-content", value: "center" },
          { property: "height", value: "45px" },
          { property: "aspect-ratio", value: "1" },
          { property: "width", value: "45px" },
          { property: "border-radius", value: "50%" },
          { property: "text-decoration", value: "none" },
          { property: "transition", value: "all 0.3s ease" },
        ],
      },
      {
        className: "footer-6-social-link",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(70, 70, 70, 1)" },
        ],
      },
      { className: "footer-6-social-link", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
    ],
  },
};
