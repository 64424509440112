export const welcomeToSb = {
  id: "64f241cb1cd6b28a574fde5d",
  name: "Welcome to Sitebuilder",
  desc: "Welcome to Sitebuilder",
  categories: ["welcome"],
  sbPages: [
    {
      pageId: "efd973fe-b24d-4d8c-bdee-798e0372f9e5",
      version: "A",
      pageLink: "welcome",
      pageTitle: "welcome",
      pageDesc: "",
      pagePreview: "",
      components: [
        {
          componentId: "zm1Rg5",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-3-component-zm1Rg5"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "XjX0qk",
              type: "",
              htmlTagName: "div",
              classes: ["row", "justify-content-center", "align-items-stretch", "basics-3-row"],
              styles: [],
              attributes: [{ property: "data-name", value: "basics1" }],
              content: "",
              children: [
                {
                  childId: "RFwla9",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "basics-3-col-zm1Rg5"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "cZQV2w",
                      type: "",
                      htmlTagName: "img",
                      classes: ["basics-3-img-zm1Rg5"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/satonda_gradient.png" },
                        { property: "alt", value: "Satonda" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "E109bu",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-E109bu"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "H6FobV",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-E109bu", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "Welcome to Sitebuilder!",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "ocVXiD",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-2-component-ocVXiD"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "tSfF29",
              type: "",
              htmlTagName: "p",
              classes: ["basics-2-text-ocVXiD", "w-100", "w-md-70", "w-lg-50"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "p" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content:
                    "This tour will give you a first look at Satonda's Sitebuilder and its main functionality. Afterwards, you can either start experimenting and designing your unique website or follow specific tutorials on our knowledge base.",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      _id: "64eba1174ca4cc9fd0c7ba2f",
    },
  ],
  sbCustomCss: [
    {
      componentId: "zm1Rg5",
      classes: [
        {
          className: "section.basics-3-component-zm1Rg5",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-3-component-zm1Rg5", pseudo: "hover", rules: [] },
        {
          className: "basics-3-col-zm1Rg5",
          pseudo: "",
          rules: [
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
            { property: "align-items", value: "center" },
          ],
        },
        {
          className: "basics-3-img-zm1Rg5",
          pseudo: "",
          rules: [
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "400px" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "40px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "basics-3-img-zm1Rg5",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "E109bu",
      classes: [
        {
          className: "section.basics-1-component-E109bu",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-E109bu", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-E109bu",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-E109bu", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-E109bu",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-E109bu", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "ocVXiD",
      classes: [
        {
          className: "section.basics-2-component-ocVXiD",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
          ],
        },
        { className: "section.basics-2-component-ocVXiD", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-ocVXiD",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-2-component-ocVXiD", pseudo: "hover", rules: [] },
        {
          className: "basics-2-text-ocVXiD",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-2-text-ocVXiD", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Montserrat",
    fontHeadersWeight: "300",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(229, 42, 5, 1)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(128, 0, 128, 1)",
    color9: "rgba(0, 128, 128, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
