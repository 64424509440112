export const template28 = {
  id: "6600493f6ae0c3224db4cca7",
  name: { en: "RVSP for an event", nl: "Bevestig deelname evenement" },
  desc: { en: "Reserve your spot for an event", nl: "Reserveer jouw plek voor een evenement" },
  categories: ["event"],
  sbPages: [
    {
      pageId: "77f7d169-e028-4441-aeb5-eef6a55dd371",
      version: "A",
      pageLink: "index",
      pageTitle: "Template website built with SiteBuilder",
      pageDesc: "Template website built with SiteBuilder",
      pageType: "website",
      pagePreview: "",
      components: [
        {
          componentId: "hkR4yS",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-3-component-hkR4yS"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "kTgm5z",
              type: "",
              htmlTagName: "img",
              classes: ["basics-3-img-hkR4yS"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "img" },
                { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-happycoffee-white.png" },
                { property: "alt", value: "Happy Coffee" },
              ],
              content: "",
              children: [],
            },
          ],
        },
        {
          componentId: "swJLVL",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "basics-1-component-swJLVL"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "qA6erx",
              type: "",
              htmlTagName: "h1",
              classes: ["basics-1-title-swJLVL", "w-100", "w-md-100", "w-lg-100"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-texteditable", value: "true" },
                { property: "data-name", value: "h" },
              ],
              content: "",
              children: [
                {
                  childId: "",
                  type: "",
                  htmlTagName: "textNode",
                  classes: [],
                  styles: [],
                  attributes: [],
                  content: "RVSP now for our annual coffee tasting event!",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          componentId: "OnuXLW",
          type: "component",
          htmlTagName: "div",
          classes: ["container", "forms-4-component-OnuXLW"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "vFY3jk",
              type: "",
              htmlTagName: "div",
              classes: ["row"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "zLWSTg",
                  type: "",
                  htmlTagName: "div",
                  classes: ["forms-4-contentcol-OnuXLW", "col-12", "col-md-6", "col-lg-6", "p-3"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "eeQLXn",
                      type: "",
                      htmlTagName: "div",
                      classes: ["row", "justify-content-center", "align-items-start"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "z5IHbH",
                          type: "",
                          htmlTagName: "div",
                          classes: ["col-12", "col-md-9"],
                          styles: [],
                          attributes: [
                            { property: "data-sbform", value: "true" },
                            { property: "data-sbformaction", value: "" },
                            { property: "data-sbformmsg", value: "Your message has been received!" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "bH1aXQ",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "forms-4-inputwrapper-OnuXLW"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "LaRwUv",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "forms-4-label-OnuXLW"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your name",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "ejVbCZ",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "forms-4-inputfield-OnuXLW"],
                                  styles: [],
                                  attributes: [
                                    { property: "name", value: "name" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                    { property: "type", value: "text" },
                                    { property: "placeholder", value: "Name" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "JEE2Rv",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "forms-4-inputwrapper-OnuXLW"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "yO2Wi4",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "forms-4-label-OnuXLW"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your email address",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "EXlvM8",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "forms-4-inputfield-OnuXLW"],
                                  styles: [],
                                  attributes: [
                                    { property: "type", value: "email" },
                                    { property: "name", value: "email" },
                                    { property: "placeholder", value: "Email address" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "pPNhww",
                              type: "",
                              htmlTagName: "div",
                              classes: ["w-100", "forms-4-inputwrapper-OnuXLW"],
                              styles: [],
                              attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                              content: "",
                              children: [
                                {
                                  childId: "A6hZkd",
                                  type: "",
                                  htmlTagName: "label",
                                  classes: ["form-control", "forms-4-label-OnuXLW"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "inputlabel" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Your phone number",
                                      children: [],
                                    },
                                  ],
                                },
                                {
                                  childId: "yoPGwz",
                                  type: "",
                                  htmlTagName: "input",
                                  classes: ["form-control", "forms-4-inputfield-OnuXLW"],
                                  styles: [],
                                  attributes: [
                                    { property: "type", value: "text" },
                                    { property: "name", value: "phone" },
                                    { property: "placeholder", value: "Phone number" },
                                    { property: "data-name", value: "inputfield" },
                                    { property: "data-required", value: "true" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "ymM80C",
                              type: "",
                              htmlTagName: "div",
                              classes: ["forms-4-button-OnuXLWwrapper-OnuXLW"],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "AmTUHC",
                                  type: "",
                                  htmlTagName: "button",
                                  classes: ["forms-4-button-OnuXLW"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-editable", value: "true" },
                                    { property: "data-name", value: "button" },
                                    { property: "data-sbformbtn", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "",
                                      type: "",
                                      htmlTagName: "textNode",
                                      classes: [],
                                      styles: [],
                                      attributes: [],
                                      content: "Reserve your spot!",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "vf0fo6",
                  type: "",
                  htmlTagName: "div",
                  classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "p-3", "forms-4-img-OnuXLWwrapper"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "NGJacP",
                      type: "",
                      htmlTagName: "img",
                      classes: ["forms-4-img-OnuXLW"],
                      styles: [],
                      attributes: [
                        { property: "data-editable", value: "true" },
                        { property: "data-name", value: "img" },
                        { property: "data-imgresizable", value: "false" },
                        { property: "src", value: "https://cdn.satonda.com/eb/assets/coffee1.jpg" },
                        { property: "alt", value: "Image" },
                      ],
                      content: "",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "XGSBVf",
          type: "component",
          htmlTagName: "div",
          classes: ["container-fluid", "footer-4-component-XGSBVf"],
          styles: [],
          attributes: [
            { property: "data-name", value: "component" },
            { property: "data-aos", value: "" },
            { property: "data-aos-delay", value: 100 },
            { property: "data-aos-duration", value: 500 },
            { property: "data-aos-once", value: "true" },
          ],
          content: "",
          children: [
            {
              childId: "aeH0jJ",
              type: "",
              htmlTagName: "div",
              classes: ["footer-4-wrapper-XGSBVf", "px-sm-0", "px-md-4"],
              styles: [],
              attributes: [
                { property: "data-editable", value: "true" },
                { property: "data-name", value: "footer4_wrapper" },
              ],
              content: "",
              children: [
                {
                  childId: "USTIiK",
                  type: "",
                  htmlTagName: "div",
                  classes: ["footer-4-line-XGSBVf", "mx-n3", "mx-md-0"],
                  styles: [],
                  attributes: [
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "footer4" },
                  ],
                  content: "",
                  children: [],
                },
                {
                  childId: "TSfaaP",
                  type: "",
                  htmlTagName: "div",
                  classes: ["row", "align-items-top", "justify-content-center", "footer-4-row"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "sOPqqP",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "bxJklG",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-XGSBVf"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Phone",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "PjR8mh",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-XGSBVf"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "123 456 7890",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "RChrwt",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "tu6dVH",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-XGSBVf"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Address",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "WJhAnz",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-XGSBVf"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Street 17",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "pp2EPn",
                          type: "",
                          htmlTagName: "p",
                          classes: ["crSsmkBjCKmjCHFPwnt3QZpT4YUiQu"],
                          styles: [],
                          attributes: [
                            { property: "data-name", value: "p" },
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "1000LX, Amsterdam",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "Q9zzqp",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "lzqDva",
                          type: "",
                          htmlTagName: "h4",
                          classes: ["footer-4-title-XGSBVf"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "h" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "Follow us",
                              children: [],
                            },
                          ],
                        },
                        {
                          childId: "kFyEXD",
                          type: "block",
                          htmlTagName: "div",
                          classes: [],
                          styles: [],
                          attributes: [{ property: "data-name", value: "block" }],
                          content: "",
                          children: [
                            {
                              childId: "WRASfl",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-XGSBVf"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "eb3ams",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-twitter"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "JlpVMX",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-XGSBVf"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "yaIShJ",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-linkedin"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                            {
                              childId: "sbUQta",
                              type: "element",
                              htmlTagName: "a",
                              classes: ["footer-4-social-link-XGSBVf"],
                              styles: [],
                              attributes: [
                                { property: "href", value: "#!" },
                                { property: "data-href", value: "#!" },
                                { property: "data-target", value: "_blank" },
                                { property: "data-editable", value: "true" },
                                { property: "data-iconwrapper", value: "true" },
                                { property: "data-texteditable", value: "false" },
                                { property: "data-name", value: "a" },
                              ],
                              content: "",
                              children: [
                                {
                                  childId: "fshxlr",
                                  type: "",
                                  htmlTagName: "span",
                                  classes: ["icon", "fa-brands", "fa-facebook-f"],
                                  styles: [],
                                  attributes: [{ property: "data-checkparent", value: "true" }],
                                  content: "",
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      childId: "jR3KXG",
                      type: "",
                      htmlTagName: "div",
                      classes: ["col-12", "col-md-3", "col-lg-3", "px-4", "px-md-3", "pt-0", "pb-4", "pb-md-3"],
                      styles: [],
                      attributes: [],
                      content: "",
                      children: [
                        {
                          childId: "If0YZs",
                          type: "",
                          htmlTagName: "p",
                          classes: ["footer-4-text-XGSBVf"],
                          styles: [],
                          attributes: [
                            { property: "data-editable", value: "true" },
                            { property: "data-texteditable", value: "true" },
                            { property: "data-name", value: "p" },
                          ],
                          content: "",
                          children: [
                            {
                              childId: "",
                              type: "",
                              htmlTagName: "textNode",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "© 2025 Happy Coffee",
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  sbCustomCss: [
    {
      componentId: "hkR4yS",
      classes: [
        {
          className: "section.basics-3-component-hkR4yS",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "background", pseudo: "", value: "rgba(145, 125, 85, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(145, 125, 85, 1)" },
          ],
        },
        { className: "section.basics-3-component-hkR4yS", pseudo: "hover", rules: [] },
        {
          className: "basics-3-component-hkR4yS",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-3-component-hkR4yS", pseudo: "hover", rules: [] },
        {
          className: "basics-3-img-hkR4yS",
          pseudo: "",
          rules: [
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "width", value: "auto" },
            { property: "height", value: "38px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(145, 125, 85, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "align-self", value: "start" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "basics-3-img-hkR4yS",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "swJLVL",
      classes: [
        {
          className: "section.basics-1-component-swJLVL",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "3rem" },
            { property: "padding-bottom", value: "0" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.basics-1-component-swJLVL", pseudo: "hover", rules: [] },
        {
          className: "basics-1-component-swJLVL",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "basics-1-component-swJLVL", pseudo: "hover", rules: [] },
        {
          className: "basics-1-title-swJLVL",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgba(145, 125, 85, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "italic" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "basics-1-title-swJLVL", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
      ],
    },
    {
      componentId: "OnuXLW",
      classes: [
        {
          className: "section.forms-4-component-OnuXLW",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "4.5rem" },
            { property: "background", pseudo: "", value: "rgba(255, 255, 255, 0)" },
            { property: "background-color", pseudo: "", value: "rgba(255, 255, 255, 0)" },
          ],
        },
        { className: "section.forms-4-component-OnuXLW", pseudo: "hover", rules: [] },
        {
          className: "forms-4-contentcol-OnuXLW",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
            { property: "justify-content", value: "center" },
          ],
        },
        {
          className: "forms-4-button-OnuXLWwrapper-OnuXLW",
          pseudo: "",
          rules: [
            { property: "display", value: "flex" },
            { property: "flex-direction", value: "column" },
          ],
        },
        { className: "forms-4-button-OnuXLWwrapper-OnuXLW", pseudo: "hover", rules: [] },
        {
          className: "forms-4-label-OnuXLW",
          pseudo: "",
          rules: [
            { property: "margin", value: "0 0 0.25rem 0" },
            { property: "display", value: "none" },
          ],
        },
        { className: "forms-4-label-OnuXLW", pseudo: "hover", rules: [] },
        {
          className: "forms-4-img-OnuXLW",
          pseudo: "",
          rules: [
            { property: "height", value: "100%" },
            { property: "width", value: "100%" },
            { property: "object-fit", value: "cover" },
            { property: "opacity", value: 1 },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 15px 4px rgba(145, 125, 85, 0.5)" },
            { property: "border-radius", value: "0.75rem" },
            { property: "align-self", value: "start" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "forms-4-img-OnuXLW",
          pseudo: "hover",
          rules: [
            { property: "opacity", value: 1 },
            { property: "transform", value: "scale(1)" },
            {
              property: "filter",
              value: "brightness(100%) blur(0px) contrast(100%) grayscale(0%) sepia(0%) saturate(100%) hue-rotate(0deg) invert(0%)",
            },
            { property: "border-color", value: "rgba(0, 0, 0, 0)" },
            { property: "box-shadow", value: "0px 0px 15px 4px rgba(145, 125, 85, 0.5)" },
          ],
        },
        {
          className: "forms-4-inputfield-OnuXLW",
          pseudo: "",
          rules: [
            { property: "font-size", value: "1rem" },
            { property: "outline", value: "0" },
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(145, 125, 85, 1)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(191, 176, 146, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(145, 125, 85, 0.25)" },
            { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
            { property: "border-radius", value: "0.4rem" },
            { property: "transition", value: "all 0.2s ease-in-out" },
          ],
        },
        {
          className: "forms-4-inputfield-OnuXLW",
          pseudo: "focus",
          rules: [
            { property: "background", value: "rgba(255, 255, 255, 1)" },
            { property: "color", value: "rgba(145, 125, 85, 1)" },
            { property: "border-color", value: "rgba(145, 125, 85, 1)" },
            { property: "box-shadow", value: "0px 0px 12px 2px rgba(145, 125, 85, 0.25)" },
          ],
        },
        {
          className: "forms-4-inputfield-OnuXLW",
          pseudo: ":placeholder",
          rules: [
            { property: "color", value: "rgba(191, 176, 146, 1)" },
            { property: "opacity", value: "1" },
          ],
        },
        { className: "forms-4-inputfield-OnuXLW", pseudo: "hover", rules: [] },
        { className: "forms-4-inputwrapper-OnuXLW", pseudo: "", rules: [{ property: "margin", value: "0rem 0rem 1rem 0rem" }] },
        {
          className: "forms-4-button-OnuXLW",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(145, 125, 85, 1)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(145, 125, 85, 1)" },
            { property: "border-radius", value: "0.4rem" },
            { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
            { property: "width", value: "100%" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "align-self", value: "center" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "transition", value: "all 0.3s ease-in-out" },
          ],
        },
        {
          className: "forms-4-button-OnuXLW",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "background", value: "rgba(191, 176, 146, 1)" },
            { property: "border-color", value: "rgba(145, 125, 85, 1)" },
            { property: "box-shadow", value: "0px 0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
      ],
    },
    {
      componentId: "qoSLXg",
      classes: [
        {
          className: "section.footer-5-component-qoSLXg",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "2rem" },
            { property: "background", pseudo: "", value: "rgba(145, 125, 85, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(145, 125, 85, 1)" },
          ],
        },
        { className: "section.footer-5-component-qoSLXg", pseudo: "hover", rules: [] },
        { className: "footer-5-col-qoSLXg", pseudo: "", rules: [{ property: "padding", value: "1rem 1rem 1rem 1rem" }] },
        {
          className: "footer-5-text-qoSLXg",
          pseudo: "",
          rules: [
            { property: "text-align", value: "justify" },
            { property: "margin", value: "0 0 0 0" },
            { property: "font-size", value: "15px" },
          ],
        },
        {
          className: "footer-5-menu-link-qoSLXg",
          pseudo: "",
          rules: [
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "font-size", value: "15px" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "display", value: "block" },
          ],
        },
        { className: "footer-5-menu-link-qoSLXg", pseudo: "hover", rules: [{ property: "color", value: "rgba(229, 42, 5, 1)" }] },
        {
          className: "footer-5-social-link-qoSLXg",
          pseudo: "",
          rules: [
            { property: "margin", value: "0.5rem 0.5rem 0 0" },
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "color", value: "rgba(0, 0, 0, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "transition", value: "all 0.3s ease" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        {
          className: "footer-5-social-link-qoSLXg",
          pseudo: "hover",
          rules: [
            { property: "background", value: "rgba(0, 0, 0, 0)" },
            { property: "color", value: "rgba(229, 42, 5, 1)" },
          ],
        },
        { className: "footer-5-social-link-qoSLXg", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-5-title-qoSLXg",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
      ],
    },
    {
      componentId: "XGSBVf",
      classes: [
        {
          className: "section.footer-4-component-XGSBVf",
          pseudo: "",
          rules: [
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "2rem" },
            { property: "background", pseudo: "", value: "rgba(145, 125, 85, 1)" },
            { property: "background-color", pseudo: "", value: "rgba(145, 125, 85, 1)" },
          ],
        },
        { className: "section.footer-4-component-XGSBVf", pseudo: "hover", rules: [] },
        { className: "footer-4-wrapper-XGSBVf", pseudo: "", rules: [] },
        {
          className: "footer-4-line-XGSBVf",
          pseudo: "",
          rules: [
            { property: "border-style", value: "solid none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgba(255, 255, 255, 1)" },
            { property: "margin", value: "0rem 0rem 2rem 0rem" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "footer-4-line-XGSBVf", pseudo: "hover", rules: [{ property: "border-color", value: "rgba(255, 255, 255, 1)" }] },
        {
          className: "crSsmkBjCKmjCHFPwnt3QZpT4YUiQu",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        { className: "crSsmkBjCKmjCHFPwnt3QZpT4YUiQu", pseudo: "hover", rules: [{ property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" }] },
        {
          className: "footer-4-social-link-XGSBVf",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 0.5rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0.3s ease-in-out" },
            { property: "align-self", value: "start" },
            { property: "font-size", value: "1.5rem" },
          ],
        },
        {
          className: "footer-4-social-link-XGSBVf",
          pseudo: "hover",
          rules: [
            { property: "color", value: "rgba(212, 201, 181, 1)" },
            { property: "text-decoration", value: "none" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
          ],
        },
        { className: "footer-4-social-link-XGSBVf", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-4-text-XGSBVf",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
        {
          className: "footer-4-title-XGSBVf",
          pseudo: "",
          rules: [
            { property: "color", value: "rgba(255, 255, 255, 1)" },
            { property: "font-weight", value: "bold" },
            { property: "align-self", value: "start" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-shadow", value: "0px 0px 0px rgba(0, 0, 0, 0)" },
            { property: "transition", value: "all 0s ease-in-out" },
          ],
        },
      ],
    },
  ],
  sbCssVars: {
    fontHeadersLink: "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap",
    fontBodyLink: "https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap",
    fontHeadersName: "Montserrat",
    fontHeadersWeight: "300",
    fontBodyName: "Roboto",
    fontBodyWeight: "300",
    color1: "rgba(145, 125, 85, 1)",
    color2: "rgba(191, 176, 146, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
