export const card5 = {
  id: "card-5",
  name: "Card 5",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "card-5-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "card-5-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-5-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "card-5-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-5-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/card-5-1.png" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-5-paddingwrapper", "toGrow"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-5-subheader"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "3 September 2022",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "a",
                        classes: ["card-5-title-link"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_self" },
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "a" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum, animi.",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["toGrow", "card-5-desc-wrapper"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["card-5-desc-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content:
                                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod odio sed possimus assumenda reprehenderit optio, vero fuga? Voluptate  recusandae corrupti facere, consectetur necessitatibus exercitationem laborum inventore odio nemo veritatis quos quis doloribus  deserunt impedit assumenda.",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "a",
                        classes: ["card-5-link-readmore"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_self" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Read more",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-5-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "card-5-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-5-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/card-5-2.png" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-5-paddingwrapper", "toGrow"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-5-subheader"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "3 September 2022",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "a",
                        classes: ["card-5-title-link"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_self" },
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "a" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum, animi.",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["toGrow", "card-5-desc-wrapper"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["card-5-desc-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content:
                                  "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quibusdam impedit dicta non minima soluta labore quae voluptate excepturi esse laudantium!",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "a",
                        classes: ["card-5-link-readmore"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_self" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Read more",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-5-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "card-5-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-5-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/card-5-3.png" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-5-paddingwrapper", "toGrow"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "p",
                        classes: ["card-5-subheader"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "p" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "3 September 2022",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "a",
                        classes: ["card-5-title-link"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_self" },
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "a" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum, animi.",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["toGrow", "card-5-desc-wrapper"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["card-5-desc-text"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content:
                                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod odio sed possimus assumenda reprehenderit optio, vero fuga? Voluptate recusandae corrupti facere, consectetur necessitatibus exercitationem laborum inventore odio nemo veritatis quos quis doloribus deserunt impedit assumenda.",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "a",
                        classes: ["card-5-link-readmore"],
                        styles: [],
                        attributes: [
                          { property: "href", value: "#!" },
                          { property: "data-href", value: "#!" },
                          { property: "data-target", value: "_self" },
                          { property: "data-editable", value: "true" },
                          { property: "data-name", value: "button" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Read more",
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.card-5-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.card-5-component", pseudo: "hover", rules: [] },
      {
        className: "card-5-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "display", value: "flex" },
        ],
      },
      {
        className: "card-5-wrapper",
        pseudo: "",
        rules: [
          { property: "overflow", value: "hidden" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0.75rem" },
          { property: "padding", value: "0 0 0 0" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(180, 180, 180, 0.1)" },
          // { property: "align-items", value: "stretch" },
        ],
      },
      { className: "card-5-wrapper", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" }] },
      {
        className: "card-5-wrapper > img",
        pseudo: "",
        rules: [
          { property: "width", value: "100% !important" },
          { property: "height", value: "200px !important" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "card-5-img",
        pseudo: "",
        rules: [],
      },
      {
        className: "card-5-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-5-paddingwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "padding", value: "0 0.5rem 0 0.5rem" },
        ],
      },
      {
        className: "card-5-paddingwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-5-subheader",
        pseudo: "",
        rules: [
          { property: "font-size", value: "13px" },
          { property: "color", value: "rgba(109, 118, 126, 1)" },
          { property: "margin", value: "0.5rem 0rem 0 0rem" },
          { property: "text-align", value: "end" },
          { property: "font-style", value: "italic" },
        ],
      },
      {
        className: "card-5-subheader",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-5-title-link",
        pseudo: "",
        rules: [
          { property: "font-family", value: '"Montserrat"' },
          { property: "font-size", value: "20px" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "text-decoration", value: "none" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0.5rem 0rem 0.5rem 0rem" },
        ],
      },
      { className: "card-5-title-link", pseudo: "hover", rules: [{ property: "color", value: "rgba(246, 129, 0, 1)" }] },
      {
        className: "card-5-desc-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(10, 10, 10, 1)" },
          { property: "margin", value: "0 0rem 0.5rem 0rem" },
        ],
      },
      {
        className: "card-5-desc-text",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-5-link-readmore",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0.5rem 0rem 0.5rem 0rem" },
          { property: "padding", value: "0.5rem 1rem 0.5rem 1rem" },
          { property: "width", value: "100%" },
          { property: "text-decoration", value: "none" },
          { property: "align-self", value: "start" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "card-5-link-readmore",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
        ],
      },
    ],
  },
};
