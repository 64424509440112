export const cEditPane = {
  browseTemplates: {
    en: "Browse templates",
    nl: "Bekijk templates",
  },
  openImgGallery: {
    en: "Open image gallery",
    nl: "Open fotogallerij",
  },
  siteStyle: {
    en: "Site style settings",
    nl: "Site stijl",
  },
  addNewComponent: {
    en: "Add new component",
    nl: "Voeg nieuw component toe",
  },
  editComponent: {
    en: "Edit component",
    nl: "Bewerk component",
  },
  sbSettings: {
    en: "Settings",
    nl: "Instellingen",
  },
  takeTour: {
    en: "Take product tour",
    nl: "Neem rondleiding",
  },
  selectLanguage: {
    en: "Select language",
    nl: "Selecteer taal",
  },
};
