import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Border from "./Border";
import Margin from "./Margin";
import Padding from "./Padding";
import AlignCol from "./AlignCol";
import AlignRow from "./AlignRow";
import ResponsiveLayout from "./ResponsiveLayout";
import BorderRadius from "./BorderRadius";
import BoxShadow from "./BoxShadow";
import Transition from "./Transition";
import OverlayColor from "./OverlayColor";
import Slider from "./formComponents/Slider";
import Divider from "./formComponents/Divider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const Card4 = ({ sb: { startStyles } }) => {
  return (
    <>
      {/* Layout */}
      <ResponsiveLayout />
      <Divider />
      <AlignCol />
      <Divider />
      <AlignRow />
      <Divider />
      <Padding />
      <Divider />
      <Margin />
      <Divider />
      {/* Style */}
      <Border />
      <Divider />
      <BorderRadius />
      <Divider />
      <BoxShadow />
      <Divider />
      {/* Component */}
      <Transition />
      <Divider />
      <OverlayColor />
      <Divider />
      <Slider
        label={translate("cEditForms.card4_height", false, null)}
        id={"card4_popupHeight"}
        min={0}
        max={250}
        step={1}
        startValue={formGetStartValue(startStyles, "card4_popupHeight")}
        tooltipText={translate("cEditForms.card4_tooltipHeight", false, null)}
      />
      <Slider
        label={translate("cEditForms.card4_heightHover", false, null)}
        id={"card4_popupHeightHover"}
        min={0}
        max={250}
        step={1}
        startValue={formGetStartValue(startStyles, "card4_popupHeightHover")}
        tooltipText={translate("cEditForms.card4_tooltipHeightHover", false, null)}
      />
    </>
  );
};

Card4.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Card4);
