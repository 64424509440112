export const forms5 = {
  id: "forms-5",
  name: "Form 5",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "forms-5-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "justify-content-center"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "order-first", "p-3", "forms-5-imgwrapper1"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["forms-5-imgwrapper2"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["forms-5-img1"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/book2.jpg" },
                      { property: "alt", value: "Image" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["forms-5-img2"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/book1.jpg" },
                      { property: "alt", value: "Image" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["forms-5-contentcol", "col-12", "col-md-6", "col-lg-5", "p-3"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["row", "justify-content-center", "align-items-stretch"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["col-12"],
                    styles: [],
                    attributes: [
                      { property: "data-sbform", value: "true" },
                      { property: "data-sbformaction", value: "" },
                      { property: "data-sbformmsg", value: "Your message has been received!" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h2",
                        classes: ["forms-5-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Get your copy now!",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["w-100", "forms-5-inputwrapper"],
                        styles: [],
                        attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "label",
                            classes: ["form-control", "forms-5-label"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "inputlabel" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Your name",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "input",
                            classes: ["form-control", "forms-5-inputfield"],
                            styles: [],
                            attributes: [
                              { property: "type", value: "text" },
                              { property: "name", value: "name" },
                              { property: "placeholder", value: "Name" },
                              { property: "data-name", value: "inputfield" },
                              { property: "data-required", value: "true" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["w-100", "forms-5-inputwrapper"],
                        styles: [],
                        attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "label",
                            classes: ["form-control", "forms-5-label"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "inputlabel" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Your email address",
                                children: [],
                              },
                            ],
                          },
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "input",
                            classes: ["form-control", "forms-5-inputfield"],
                            styles: [],
                            attributes: [
                              { property: "type", value: "email" },
                              { property: "name", value: "email" },
                              { property: "placeholder", value: "Email address" },
                              { property: "data-name", value: "inputfield" },
                              { property: "data-required", value: "true" },
                              { property: "data-editable", value: "true" },
                            ],
                            content: "",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["w-100", "forms-5-inputwrapper"],
                        styles: [],
                        attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["form-check", "forms-5-check"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "inputfield" },
                              { property: "data-required", value: "true" },
                              { property: "data-editable", value: "true" },
                              { property: "data-formcheckwrapper", value: "true" },
                              { property: "name", value: "subscribe" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "input",
                                classes: ["form-check-input"],
                                styles: [],
                                attributes: [
                                  { property: "type", value: "checkbox" },
                                  { property: "data-checkparent", value: "true" },
                                  { property: "checked", value: "true" },
                                ],
                                content: "",
                                children: [],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "label",
                                classes: ["form-check-label", "forms-5-label"],
                                styles: [],
                                attributes: [
                                  { property: "data-name", value: "inputlabel" },
                                  { property: "data-editable", value: "true" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Subscribe to our newsletter",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["forms-5-buttonwrapper"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "button",
                            classes: ["forms-5-button"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-name", value: "button" },
                              { property: "data-sbformbtn", value: "true" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content: "Download copy!",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.forms-5-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.forms-5-component", pseudo: "hover", rules: [] },
      {
        className: "forms-5-contentcol",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "justify-content", value: "center" },
        ],
      },
      {
        className: "forms-5-imgwrapper2",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "width", value: "100%" },
          { property: "aspect-ratio", value: "0.65" },
        ],
      },
      {
        className: "forms-5-imgwrapper2",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-5-img1",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "right", value: "0" },
          { property: "width", value: "70%" },
          { property: "aspect-ratio", value: "0.8" },
          { property: "object-fit", value: "cover" },
          { property: "transform", value: "scale(1)" },
          { property: "border-radius", value: "0rem" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "3px" },
          { property: "border-color", value: "rgba(0, 0, 0, 1)" },
        ],
      },
      {
        className: "forms-5-img1",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-5-img2",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "bottom", value: "0" },
          { property: "left", value: "0" },
          { property: "width", value: "70%" },
          { property: "aspect-ratio", value: "0.8" },
          { property: "object-fit", value: "cover" },
          { property: "transform", value: "scale(1)" },
          { property: "border-radius", value: "0rem" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "3px" },
          { property: "border-color", value: "rgba(0, 0, 0, 1)" },
        ],
      },
      {
        className: "forms-5-img2",
        pseudo: "hover",
        rules: [],
      },

      {
        className: "forms-5-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "margin", value: "0 0 3rem 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
          { property: "font-weight", value: "bold" },
        ],
      },
      {
        className: "forms-5-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-5-buttonwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "forms-5-buttonwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-5-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
          { property: "width", value: "100%" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "forms-5-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "forms-5-inputwrapper",
        pseudo: "",
        rules: [{ property: "margin", value: "0 0 1.5rem 0" }],
      },
      {
        className: "forms-5-inputfield",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(206, 212, 218, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.2s ease" },
          { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
          { property: "font-size", value: "1rem" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
          { property: "outline", value: "0" },
        ],
      },
      {
        className: "forms-5-inputfield",
        pseudo: "focus",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
        ],
      },
      {
        className: "forms-5-inputfield",
        pseudo: ":placeholder",
        rules: [
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "forms-5-inputfield",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-5-label",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.25rem 0" },
          { property: "display", value: "none" },
        ],
      },
      {
        className: "forms-5-label",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-5-check",
        pseudo: "",
        rules: [],
      },
      {
        className: "forms-5-check",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-5-check > label",
        pseudo: "",
        rules: [{ property: "display", value: "inline-block !important" }],
      },
    ],
  },
};
