export const hero8 = {
  id: "hero-8",
  name: "Hero 8",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "hero-8-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-6", "p-3", "hero-8-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "h1",
                classes: ["hero-8-title", "w-100", "w-md-80", "w-lg-60"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "h" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "Etiam ut ipsum sed ipsum luctus rutrum finibus.",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-6", "order-last", "p-3", "hero-8-col"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "p",
                classes: ["hero-8-text", "w-100", "w-md-100", "w-lg-100"],
                styles: [],
                attributes: [
                  { property: "data-editable", value: "true" },
                  { property: "data-texteditable", value: "true" },
                  { property: "data-name", value: "p" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "textNode",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content:
                      "Etiam est odio, volutpat eu aliquam non, condimentum et odio. Suspendisse potenti. Nam euismod porttitor dui ac dignissim. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam sodales, lectus sed sagittis feugiat, massa dui volutpat magna, et luctus turpis arcu ac enim.",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.hero-8-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.hero-8-component", pseudo: "hover", rules: [] },
      {
        className: "hero-8-col",
        pseudo: "",
        rules: [],
      },
      {
        className: "hero-8-col",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-8-title",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "hero-8-title",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "hero-8-text",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0 0" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "hero-8-text",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
