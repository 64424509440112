export const forms1 = {
  id: "forms-1",
  name: "Forms 1",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "forms-1-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6"],
            styles: [],
            attributes: [
              { property: "data-sbform", value: "true" },
              { property: "data-sbformaction", value: "" },
              { property: "data-sbformmsg", value: "Your message has been received!" },
            ],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["w-100", "forms-1-inputwrapper"],
                styles: [],
                attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "label",
                    classes: ["form-control", "forms-1-label"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "inputlabel" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Your name",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "input",
                    classes: ["form-control", "forms-1-inputfield"],
                    styles: [],
                    attributes: [
                      { property: "type", value: "text" },
                      { property: "name", value: "name" },
                      { property: "placeholder", value: "Name" },
                      { property: "data-name", value: "inputfield" },
                      { property: "data-required", value: "true" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["w-100", "forms-1-inputwrapper"],
                styles: [],
                attributes: [{ property: "data-inputfieldwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "label",
                    classes: ["form-control", "forms-1-label"],
                    styles: [],
                    attributes: [
                      { property: "data-name", value: "inputlabel" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Your email address",
                        children: [],
                      },
                    ],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "input",
                    classes: ["form-control", "forms-1-inputfield"],
                    styles: [],
                    attributes: [
                      { property: "type", value: "email" },
                      { property: "name", value: "email" },
                      { property: "placeholder", value: "Email address" },
                      { property: "data-name", value: "inputfield" },
                      { property: "data-required", value: "true" },
                      { property: "data-editable", value: "true" },
                    ],
                    content: "",
                    children: [],
                  },
                ],
              },
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["forms-1-buttonwrapper"],
                styles: [],
                attributes: [],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "button",
                    classes: ["forms-1-button"],
                    styles: [],
                    attributes: [
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "button" },
                      { property: "data-sbformbtn", value: "true" },
                    ],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "textNode",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "Subscribe!",
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.forms-1-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.forms-1-component", pseudo: "hover", rules: [] },
      {
        className: "forms-1-buttonwrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "forms-1-buttonwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-1-button",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(0, 0, 0, 0)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0 0 0 0" },
          { property: "padding", value: "0.5rem 2rem 0.5rem 2rem" },
          { property: "width", value: "100%" },
          { property: "align-self", value: "center" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "forms-1-button",
        pseudo: "hover",
        rules: [
          { property: "background", value: "rgba(229, 42, 5, 1)" },
          { property: "color", value: "rgba(255, 255, 255, 1)" },
        ],
      },
      {
        className: "forms-1-inputwrapper",
        pseudo: "",
        rules: [{ property: "margin", value: "0 0 1rem 0" }],
      },
      {
        className: "forms-1-inputfield",
        pseudo: "",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(206, 212, 218, 1)" },
          { property: "border-radius", value: "0.2rem" },
          { property: "transition", value: "all 0.2s ease" },
          { property: "padding", value: "0.25rem 1rem 0.25rem 1rem" },
          { property: "font-size", value: "1rem" },
          { property: "box-shadow", value: "0px 0px 0px 0px rgba(229, 42, 5, 0)" },
          { property: "outline", value: "0" },
        ],
      },
      {
        className: "forms-1-inputfield",
        pseudo: "focus",
        rules: [
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "border-color", value: "rgba(229, 42, 5, 1)" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(229, 42, 5, 0.2)" },
        ],
      },
      {
        className: "forms-1-inputfield",
        pseudo: ":placeholder",
        rules: [
          { property: "color", value: "rgba(108, 117, 125, 1)" },
          { property: "opacity", value: "1" },
        ],
      },
      {
        className: "forms-1-inputfield",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "forms-1-label",
        pseudo: "",
        rules: [
          { property: "margin", value: "0 0 0.25rem 0" },
          { property: "display", value: "none" },
        ],
      },
      {
        className: "forms-1-label",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
