import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AlignCol from "./AlignCol";
import ResponsiveLayout from "./ResponsiveLayout";
import ColorPicker from "./formComponents/ColorPicker";
import Divider from "./formComponents/Divider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const Process1 = ({ sb: { startStyles } }) => {
  return (
    <>
      {/* Layout */}
      <ResponsiveLayout />
      <Divider />
      {/* <AlignRow /> */}
      {/* <Divider /> */}
      <AlignCol />
      <Divider />
      {/* Component */}
      <ColorPicker
        label={translate("cEditForms.process1_color", false, null)}
        id={"process1_arrow_color"}
        startValue={formGetStartValue(startStyles, "process1_arrow_color")}
        tooltipText={translate("cEditForms.process1_ttColor", false, null)}
      />
    </>
  );
};

Process1.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Process1);
