export const card17 = {
  id: "card-17",
  name: "Card 17",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["container", "card-17-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "block",
        htmlTagName: "div",
        classes: ["row", "justify-content-center", "align-items-stretch", "card-17-row"],
        styles: [],
        attributes: [{ property: "data-name", value: "block" }],
        content: "",
        children: [
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-17-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "card-17-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-17-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/work-1.png" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-17-paddingwrapper", "toGrow"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["card-17-icondiv"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["card-17-iconwrapper"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "div" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-regular", "fa-heart"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["card-17-spacer"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["card-17-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Lorem ipsum dolor",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["toGrow", "card-17-desc-wrapper"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["card-17-desc-text", "w-100", "w-md-100", "w-lg-100"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content:
                                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod odio sed possimus assumenda reprehenderit optio, vero fuga?",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-17-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "card-17-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-17-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/work-2.png" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-17-paddingwrapper", "toGrow"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["card-17-icondiv"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["card-17-iconwrapper"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "div" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-regular", "fa-thumbs-up"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["card-17-spacer"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["card-17-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Dolor sit amet",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["toGrow", "card-17-desc-wrapper"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["card-17-desc-text", "w-100", "w-md-100", "w-lg-100"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content:
                                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod odio sed possimus assumenda reprehenderit optio, vero fuga?",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "element",
            htmlTagName: "div",
            classes: ["col-12", "col-md-6", "col-lg-4", "card-17-col"],
            styles: [],
            attributes: [{ property: "data-name", value: "element_col" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "div",
                classes: ["flexSameHeight", "card-17-wrapper"],
                styles: [],
                attributes: [{ property: "data-elementalignmentwrapper", value: "true" }],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "img",
                    classes: ["card-17-img"],
                    styles: [],
                    attributes: [
                      { property: "src", value: "https://cdn.satonda.com/eb/assets/work-3.png" },
                      { property: "data-editable", value: "true" },
                      { property: "data-name", value: "img" },
                      { property: "data-imgresizable", value: "false" },
                    ],
                    content: "",
                    children: [],
                  },
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "div",
                    classes: ["card-17-paddingwrapper", "toGrow"],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["card-17-icondiv"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "div",
                            classes: ["card-17-iconwrapper"],
                            styles: [],
                            attributes: [
                              { property: "data-name", value: "div" },
                              { property: "data-editable", value: "true" },
                              { property: "data-iconwrapper", value: "true" },
                              { property: "data-texteditable", value: "false" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "span",
                                classes: ["icon", "fa-regular", "fa-handshake"],
                                styles: [],
                                attributes: [{ property: "data-checkparent", value: "true" }],
                                content: "",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["card-17-spacer"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "h3",
                        classes: ["card-17-title", "w-100", "w-md-100", "w-lg-100"],
                        styles: [],
                        attributes: [
                          { property: "data-editable", value: "true" },
                          { property: "data-texteditable", value: "true" },
                          { property: "data-name", value: "h" },
                        ],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "textNode",
                            classes: [],
                            styles: [],
                            attributes: [],
                            content: "Excepteur sint occaecat",
                            children: [],
                          },
                        ],
                      },
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "div",
                        classes: ["toGrow", "card-17-desc-wrapper"],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "p",
                            classes: ["card-17-desc-text", "w-100", "w-md-100", "w-lg-100"],
                            styles: [],
                            attributes: [
                              { property: "data-editable", value: "true" },
                              { property: "data-texteditable", value: "true" },
                              { property: "data-name", value: "p" },
                            ],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "textNode",
                                classes: [],
                                styles: [],
                                attributes: [],
                                content:
                                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod odio sed possimus assumenda reprehenderit optio, vero fuga?",
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "section.card-17-component",
        pseudo: "",
        rules: [
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
        ],
      },
      { className: "section.card-17-component", pseudo: "hover", rules: [] },
      {
        className: "card-17-col",
        pseudo: "",
        rules: [
          { property: "padding", value: "1rem 1rem 1rem 1rem" },
          { property: "display", value: "flex" },
        ],
      },
      {
        className: "card-17-wrapper",
        pseudo: "",
        rules: [
          { property: "overflow", value: "hidden" },
          { property: "box-shadow", value: "0px 0px 12px 2px rgba(0, 0, 0, 0.1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "border-radius", value: "0.75rem" },
          { property: "padding", value: "0 0 0 0" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgba(180, 180, 180, 0.1)" },
        ],
      },
      { className: "card-17-wrapper", pseudo: "hover", rules: [{ property: "box-shadow", value: "0px 0px 12px 3px rgba(0, 0, 0, 0.2)" }] },
      {
        className: "card-17-wrapper > img",
        pseudo: "",
        rules: [
          { property: "width", value: "100% !important" },
          { property: "height", value: "200px !important" },
          { property: "object-fit", value: "cover" },
        ],
      },
      {
        className: "card-17-img",
        pseudo: "",
        rules: [],
      },
      {
        className: "card-17-img",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-17-paddingwrapper",
        pseudo: "",
        rules: [
          { property: "position", value: "relative" },
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
          { property: "padding", value: "0 0.5rem 0 0.5rem" },
        ],
      },
      {
        className: "card-17-paddingwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-17-desc-wrapper",
        pseudo: "",
        rules: [
          { property: "display", value: "flex" },
          { property: "flex-direction", value: "column" },
        ],
      },
      {
        className: "card-17-desc-wrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-17-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(229, 42, 5, 1)" },
          { property: "transition", value: "all 0.3s ease" },
          { property: "margin", value: "0.5rem 0rem 0.5rem 0rem" },
          { property: "text-align", value: "center" },
          { property: "align-self", value: "start" },
          { property: "font-weight", value: "bold" },
        ],
      },
      { className: "card-17-title", pseudo: "hover", rules: [] },
      {
        className: "card-17-desc-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgba(10, 10, 10, 1)" },
          { property: "margin", value: "0 0rem 0.5rem 0rem" },
          { property: "text-align", value: "left" },
          { property: "align-self", value: "start" },
        ],
      },
      {
        className: "card-17-desc-text",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-17-iconwrapper",
        pseudo: "",
        rules: [
          { property: "height", value: "4rem" },
          { property: "aspect-ratio", value: "1" },
          { property: "background", value: "rgba(255, 255, 255, 1)" },
          { property: "display", value: "flex" },
          { property: "justify-content", value: "center" },
          { property: "align-items", value: "center" },
          { property: "font-size", value: "2.5rem" },
          { property: "border-radius", value: "50%" },
          { property: "color", value: "rgba(229, 42, 5, 1)" },
        ],
      },
      {
        className: "card-17-iconwrapper",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-17-icondiv",
        pseudo: "",
        rules: [
          { property: "position", value: "absolute" },
          { property: "top", value: "0" },
          { property: "left", value: "50%" },
          { property: "transform", value: "translate(-50%, -50%)" },
        ],
      },
      {
        className: "card-17-icondiv",
        pseudo: "hover",
        rules: [],
      },
      {
        className: "card-17-spacer",
        pseudo: "",
        rules: [{ property: "height", value: "2.5rem" }],
      },
      {
        className: "card-17-spacer",
        pseudo: "hover",
        rules: [],
      },
    ],
  },
};
