import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const Transition = ({ sb: { startStyles } }) => {
  return (
    <>
      <Slider
        label={translate("cEditForms.transitionSpeed", false, null)}
        id={"transition"}
        min={0}
        max={3}
        step={0.1}
        startValue={formGetStartValue(startStyles, "transition")}
        tooltipText={translate("cEditForms.ttTransSpeed", false, null)}
      />
    </>
  );
};

Transition.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Transition);
