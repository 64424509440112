import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ContainerWidth from "./ContainerWidth";
import ColorPicker from "./formComponents/ColorPicker";
import Slider from "./formComponents/Slider";
import BgGradientColor from "./formComponents/BgGradientColor";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { PADDING_VALUES } from "../../../../lib/editStyleVars";
import { translate } from "../../../../translations/translations";

const Card6 = ({ sb: { startStyles } }) => {
  return (
    <>
      <ContainerWidth />
      {/* <ColorPicker
        label={"Background color"}
        id={"componentBgColor"}
        startValue={formGetStartValue(startStyles, "componentBgColor")}
        tooltipText={`Background color of the complete component`}
      /> */}
      <BgGradientColor
        label={translate("cEditForms.componentBgColor", false, null)}
        arrBgVarNames={[
          "componentBgColor",
          "componentBoolBgIsGradient",
          "componentBgGradientDirection",
          "componentBgGradientColor1",
          "componentBgGradientColor2",
          "componentBgGradientStop1",
          "componentBgGradientStop2",
        ]}
        tooltipText={translate("cEditForms.ttComponentBgColor", false, null)}
      />
      <Slider
        label={translate("cEditForms.spaceTop", false, null)}
        id={"componentPaddingTop"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "componentPaddingTop"))}
        tooltipText={translate("cEditForms.ttSpaceTopComponent", false, null)}
      />
      <Slider
        label={translate("cEditForms.spaceBottom", false, null)}
        id={"componentPaddingBottom"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "componentPaddingBottom"))}
        tooltipText={translate("cEditForms.ttSpaceBottomComponent", false, null)}
      />
    </>
  );
};

Card6.propTypes = {
  sb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sb: state.sb,
});

export default connect(mapStateToProps, null)(Card6);
